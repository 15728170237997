import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { ValidationMessage } from 'interfaces/components/common/ValidationMessage';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { datePickerSlotProps } from 'shared/utils/helper/muiSlotProps';

import FormLabel from './FormLabel';

const InputDate = React.memo(function InputDate({
  invalidMessage,
  required,
  label,
  state,
  setState,
}) {
  const [open, setOpen] = useState(false);
  return (
    <div className="kmanager-row">
      <div className="kmanager-col-4">
        <FormLabel label={label} required={required} />
      </div>
      <div className="kmanager-col form-area flex-column">
        <div
          style={{ position: 'relative' }}
          className={'form-input'}
          data-testid="input-date">
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              value={DateTime.fromJSDate(new Date(state))}
              onChange={(e) => setState(e.toJSDate())}
              format="yyyy-MM-dd"
              slotProps={datePickerSlotProps(state, setOpen)}
              open={open}
              onClose={() => setOpen(false)}
            />
          </LocalizationProvider>
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
      </div>
    </div>
  );
});
export default InputDate;
