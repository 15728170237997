
export class EmailMessage {
    #emailMessage

    constructor(emailMessage) {
        this.#emailMessage = emailMessage;
    }

    get() {
        return this.#emailMessage
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
