import { useEffect, useState } from 'react';

import { findById as findProperty } from '../../../../domain/Property/services/Property.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { GeneralEditContext } from '../entities/GeneralEditContext.js';

const GeneralEditProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [propertyId, setPropertyId] = useState();
  const [propertyDetail, setPropertyDetail] = useState({});

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {}, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      setPropertyId(location.state?.propertyId);
    }, []);

    useEffect(() => {
      findProperty(type, propertyId).then((result) => {
        setPropertyDetail(result);
      });
    }, [propertyId]);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <GeneralEditContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        propertyDetail,
      }}>
      {children}
    </GeneralEditContext.Provider>
  );
};

export default GeneralEditProvider;
