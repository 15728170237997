
export class AplCountAuctionDisplay {
  #aplCountAuctionDisplay

  constructor(aplCountAuctionDisplay) {
    this.#aplCountAuctionDisplay = aplCountAuctionDisplay;
  }

  get() {
    return this.#aplCountAuctionDisplay
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
