import TabComponent from './Tab.jsx';
import 'interfaces/css/common/switcher.css';

const Switcher = ({ labels, activeTabIndex, setActiveTab, children }) => {
  return (
    <div className="switcher">
      <div className="switcher-tabs">
        {labels.map((label, index) => (
          <TabComponent
            key={index}
            tabIndex={index}
            label={label}
            isActive={index === activeTabIndex}
            setActiveTab={setActiveTab}
          />
        ))}
      </div>
      <hr className="switcher-divider divider" />
      {children && children[activeTabIndex]}
    </div>
  );
};

export default Switcher;
