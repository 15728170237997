import MenuLink from "interfaces/components/common/MenuLink.jsx";
import { useNavigate } from "react-router-dom";
import { useLogout } from "shared/hooks/useLogout.js";
import { ServiceTypes } from "shared/utils/constants/service.js";

const USER_MANAGE_URL = '/kmanager/settings';
const SERVICE_PROVIDER_MANAGE_URL = '/kmanager/service_provider';

const RightSideMenuList = ({ handleClose }) => {
  const navigation = useNavigate();
  const { logout } = useLogout();
  const RightSideMenuListData = [
    {
      text: 'ログアウト',
      handleClick: () => {
        logout(ServiceTypes.KMANAGER.string)
        navigation('/kmanager/login')
      },
    },
    {
      text: 'ユーザー管理',
      handleClick: () => {
        handleClose()
        navigation(USER_MANAGE_URL)
      },
    },
    {
      text: 'サービス提供会社管理',
      handleClick: () => {
        handleClose()
        navigation(SERVICE_PROVIDER_MANAGE_URL)
      },
    }
  ]

  return (
    <div data-testid="kmanager-right-side-menu-list">
      {
        RightSideMenuListData.map((data, index) =>
          <MenuLink key={index} text={data.text} onClick={data.handleClick}/>
        )
      }
    </div>
  )
}

export default RightSideMenuList