import { ReactComponent as ArrowRightIcon } from 'interfaces/assets/svg/arrow_back_icon.svg';
import BackButton from 'interfaces/components/common/BackButton';
import Button from 'interfaces/components/common/Button';
import PageTitle from 'interfaces/components/common/PageTitle';
import GovernmentDetailTable from 'interfaces/components/Government/Detail/GovernmentDetailTable';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//import { useGovernmentDetail } from "shared/hooks/useGovernmentDetail";
//import { GovernmentTypeToJP } from "shared/utils/helper/convert/governmentTypeToJp";
//import { PrefectureToJP } from "shared/utils/helper/convert/prefectureToJp";
//import { AreaToJP } from "shared/utils/helper/convert/areaToJp";
import { GovernmentDetailContext } from '../../../pages/GovernmentDetail/entities/GovernmentDetailContext';

const BACK_PATH = '/kmanager/gov_list';

const GovernmentDetail = () => {
  const { government, divisions, documentSendPages, publicSaleUsers, assetSaleUsers, error } = useContext(
    GovernmentDetailContext,
  );
  const { governmentId } = useParams();

  const navigation = useNavigate();
  //const [error, setError] = useState({})
  //const [governmentDetail, setGovernmentDetail] = useState({})
  //const [isLoading, setIsLoading] = useState(true)

  //function handleError(err) {
  //  setError(err)
  //  setTimeout(() => {
  //    setError({})
  //  }, 2000)
  //}

  //const result = useGovernmentDetail(governmentId, handleError)

  //useEffect(() => {
  //  if (!result.loading) {

  //    result.governmentDetail.governmentType = GovernmentTypeToJP(result.governmentDetail.governmentType)
  //    result.governmentDetail.location = AreaToJP(result.governmentDetail.location)
  //    result.governmentDetail.prefecture = PrefectureToJP(result.governmentDetail.prefecture)
  //    result.governmentDetail.divisions.map((division) => {
  //      division.prefecture = PrefectureToJP(division.prefectureId)
  //    })
  //    result.governmentDetail.certDocumentInfo.map((certInfo) => {
  //      certInfo.certDocumentArea = PrefectureToJP(certInfo.certDocumentArea)
  //    })
  //    result.governmentDetail.systemFeeInfo.map((feeInfo) => {
  //      feeInfo.invoiceArea = PrefectureToJP(feeInfo.invoiceArea)
  //    })
  //    console.log(result.governmentDetail)
  //    setGovernmentDetail(result.governmentDetail)
  //    setIsLoading(false)
  //  }
  //}, [result]);

  return (
    <div
      className="kmanager-government-detail kmanager-auction-operation"
      data-testid="kmanager-government-detail">
      <div className="kmanager-row">
        <div className="kmanager-col kmanager-col-12">
          <BackButton
            text="行政機関管理"
            svg={<ArrowRightIcon title="back-to-page" />}
            handleClick={() => navigation(BACK_PATH)}
          />
        </div>
        <div className="kmanager-col kmanager-col-6">
          <PageTitle text="行政機関詳細" />
        </div>
        <div className="kmanager-col kmanager-col-12">
          <div className="box-shadow" style={{ maxWidth: '960px' }}>
            {government.procedureType !== 'ASSET_SALE' && (
              <Button
                className="primary"
                text="公売 管理者ユーザー発行"
                clickHandler={() =>
                  navigation(
                    `/kmanager/gov_list/${governmentId}/user_entry/PublicSale`,
                  )
                }
              />
            )}
            {government.procedureType !== 'PUBLIC_SALE' && (
              <Button
                className="primary"
                text="公有 管理者ユーザー発行"
                clickHandler={() =>
                  navigation(
                    `/kmanager/gov_list/${governmentId}/user_entry/AssetSale`,
                  )
                }
              />
            )}
            {error?.code && <PageTitle text={error.message} />}
            {
              <GovernmentDetailTable
                government={government}
                divisions={divisions}
                documentSendPages={documentSendPages}
                publicSaleUsers={publicSaleUsers}
                assetSaleUsers={assetSaleUsers}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default GovernmentDetail;
