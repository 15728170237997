import { useState } from 'react';
import * as Validation from 'shared/utils/helper/managerValidators.js';

export const useOrganizationInputsValidation = (isEdit = false) => {
  const [divisionNameValid, setDivisionNameValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [divisionNameKanaValid, setDivisionNameKanaValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactNameValid, setContactNameValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactTelValid, setContactTelValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactExtensionValid, setContactExtensionValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactEmailValid, setContactEmailValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [useCreditCardValid, setUseCreditCardValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [financialInstitutionNameValid, setFinancialInstitutionNameValid] =
    useState(isEdit ? 'TRUE' : null);
  const [branchNameValid, setBranchNameValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [accountTypeValid, setAccountTypeValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [accountNumberValid, setAccountNumberValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [accountNameValid, setAccountNameValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [accountNameKanaValid, setAccountNameKanaValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [postalCodeValid, setPostalCodeValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [postalCode1Valid, setPostalCode1Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [postalCode2Valid, setPostalCode2Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [areaValid, setAreaValid] = useState(isEdit ? 'TRUE' : null);
  const [cityValid, setCityValid] = useState(isEdit ? 'TRUE' : null);
  const [addressValid, setAddressValid] = useState(isEdit ? 'TRUE' : null);
  const [buildNameValid, setBuildNameValid] = useState(isEdit ? 'TRUE' : null);
  const [destinationValid, setDestinationValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [writtenDestinationValid, setWrittenDestinationValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [tel1Valid, setTel1Valid] = useState(isEdit ? 'TRUE' : null);
  const [tel2Valid, setTel2Valid] = useState(isEdit ? 'TRUE' : null);
  const [tel3Valid, setTel3Valid] = useState(isEdit ? 'TRUE' : null);

  function handleDivisionNameValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setDivisionNameValid(invalid);
      return;
    }
    invalid = Validation.validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setDivisionNameValid(invalid);
      return;
    }
    setDivisionNameValid(invalid);
  }

  function handleDivisionNameKanaValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setDivisionNameKanaValid(invalid);
      return;
    }
    invalid = Validation.validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setDivisionNameKanaValid(invalid);
      return;
    }

    setDivisionNameKanaValid(invalid);
  }

  function handleContactNameValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setContactNameValid(invalid);
      return;
    }
    invalid = Validation.validateRangeLength(e.target.value, 1, 30);
    if (invalid !== 'TRUE') {
      setContactNameValid(invalid);
      return;
    }

    setContactNameValid(invalid);
  }

  function handleContactTelValid(tel) {
    let invalid = null;
    const telAll = tel.telNum1 + tel.telNum2 + tel.telNum3;
    invalid = Validation.validateRangeLength(telAll, 10, 11);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    setContactTelValid(invalid); // エラーメッセージのリセット
  }

  function handleTel1Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setTel1Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setTel1Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setTel1Valid(invalid);
      return;
    }
    setTel1Valid(invalid); // エラーメッセージのリセット
  }

  function handleTel2Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setTel2Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setTel2Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setTel2Valid(invalid);
      return;
    }
    setTel2Valid(invalid); // エラーメッセージのリセット
  }

  function handleTel3Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setTel3Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setTel3Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setTel3Valid(invalid);
      return;
    }
    setTel3Valid(invalid); // エラーメッセージのリセット
  }

  function handleContactExtensionValid(e) {
    let invalid = null;
    invalid = Validation.validateRangeLength(e.target.value, 0, 10);
    if (invalid !== 'TRUE') {
      setContactExtensionValid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(e.target.value);
    if (invalid !== 'TRUE') {
      setContactExtensionValid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setContactExtensionValid(invalid);
      return;
    }
    setContactExtensionValid(invalid); // エラーメッセージのリセット
  }

  function handleContactEmailValid(e) {
    let invalid = null;
    invalid = Validation.validateEmailFormat(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    invalid = Validation.validateEmailLength(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    setContactEmailValid(invalid); // エラーメッセージのリセット
  }

  function handleUseCreditCardValid() {
    let invalid = null;
    setUseCreditCardValid(invalid);
  }

  function handleFinancialInstitutionNameValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setFinancialInstitutionNameValid(invalid);
      return;
    }
    invalid = Validation.validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setFinancialInstitutionNameValid(invalid);
      return;
    }

    setFinancialInstitutionNameValid(invalid);
  }

  function handleBranchNameValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setBranchNameValid(invalid);
      return;
    }
    invalid = Validation.validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setBranchNameValid(invalid);
      return;
    }
    setBranchNameValid(invalid);
  }

  function handleAccountTypeValid() {
    let invalid = null;
    setAccountTypeValid(invalid);
  }

  function handleAccountNumberValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setAccountNumberValid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setAccountNumberValid(invalid);
      return;
    }
    setAccountNumberValid(invalid);
  }

  function handleAccountNameValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setAccountNameValid(invalid);
      return;
    }

    invalid = Validation.validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setAccountNameValid(invalid);
      return;
    }

    setAccountNameValid(invalid);
  }

  function handleAccountNameKanaValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setAccountNameKanaValid(invalid);
      return;
    }

    invalid = Validation.validateFullKatakana(e.target.value);
    if (invalid !== 'TRUE') {
      setAccountNameKanaValid(invalid);
      return;
    }

    invalid = Validation.validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setAccountNameKanaValid(invalid);
      return;
    }

    setAccountNameKanaValid(invalid);
  }

  function handlePostalCodeValid(postalCode) {
    let invalid = null;
    const postCodeAll = postalCode.postalCode1 + postalCode.postalCode2;
    invalid = Validation.validateRequired(postCodeAll);
    if (invalid !== 'TRUE') {
      setPostalCodeValid(invalid);
      return;
    }
    setPostalCodeValid(invalid);
  }

  function handlePostalCode1Valid(postalCode) {
    let invalid = null;
    invalid = Validation.validateRangeLength(postalCode, 0, 3);
    if (invalid !== 'TRUE') {
      setPostalCode1Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(postalCode);
    if (invalid !== 'TRUE') {
      setPostalCode1Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(postalCode);
    if (invalid !== 'TRUE') {
      setPostalCode1Valid(invalid);
      return;
    }

    setPostalCode1Valid(invalid);
  }

  function handlePostalCode2Valid(postalCode) {
    let invalid = null;
    invalid = Validation.validateRangeLength(postalCode, 0, 4);
    if (invalid !== 'TRUE') {
      setPostalCode2Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(postalCode);
    if (invalid !== 'TRUE') {
      setPostalCode2Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(postalCode);
    if (invalid !== 'TRUE') {
      setPostalCode2Valid(invalid);
      return;
    }

    setPostalCode2Valid(invalid);
  }

  function handleAreaValid() {
    let invalid = null;
    setAreaValid(invalid);
  }

  function handleCityValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setCityValid(invalid);
      return;
    }

    invalid = Validation.validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setCityValid(invalid);
      return;
    }

    setCityValid(invalid);
  }

  function handleAddressValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setAddressValid(invalid);
      return;
    }

    invalid = Validation.validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setAddressValid(invalid);
      return;
    }

    setAddressValid(invalid);
  }

  function handleBuildNameValid(e) {
    let invalid = null;
    invalid = Validation.validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setBuildNameValid(invalid);
      return;
    }

    setBuildNameValid(invalid);
  }

  function handleDestinationValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setDestinationValid(invalid);
      return;
    }

    invalid = Validation.validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setDestinationValid(invalid);
      return;
    }

    setDestinationValid(invalid);
  }

  function handleWrittenDestinationValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setWrittenDestinationValid(invalid);
      return;
    }

    invalid = Validation.validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setWrittenDestinationValid(invalid);
      return;
    }

    setWrittenDestinationValid(invalid);
  }

  return {
    setters: {
      divisionName: handleDivisionNameValid,
      divisionNameKana: handleDivisionNameKanaValid,
      contactName: handleContactNameValid,
      contactTel: handleContactTelValid,
      telNum1: handleTel1Valid,
      telNum2: handleTel2Valid,
      telNum3: handleTel3Valid,
      contactExtension: handleContactExtensionValid,
      contactEmail: handleContactEmailValid,
      useCreditCard: handleUseCreditCardValid,
      financialInstitutionName: handleFinancialInstitutionNameValid,
      branchName: handleBranchNameValid,
      accountType: handleAccountTypeValid,
      accountNumber: handleAccountNumberValid,
      accountName: handleAccountNameValid,
      accountNameKana: handleAccountNameKanaValid,
      postalCode: handlePostalCodeValid,
      postalCode1: handlePostalCode1Valid,
      postalCode2: handlePostalCode2Valid,
      area: handleAreaValid,
      city: handleCityValid,
      address: handleAddressValid,
      buildName: handleBuildNameValid,
      destination: handleDestinationValid,
      writtenDestination: handleWrittenDestinationValid,
    },
    validationMessage: {
      divisionName: divisionNameValid,
      divisionNameKana: divisionNameKanaValid,
      contactName: contactNameValid,
      contactTel: contactTelValid,
      telNum1: tel1Valid,
      telNum2: tel2Valid,
      telNum3: tel3Valid,
      contactExtension: contactExtensionValid,
      contactEmail: contactEmailValid,
      useCreditCard: useCreditCardValid,
      financialInstitutionName: financialInstitutionNameValid,
      branchName: branchNameValid,
      accountType: accountTypeValid,
      accountNumber: accountNumberValid,
      accountName: accountNameValid,
      accountNameKana: accountNameKanaValid,
      postalCode: postalCodeValid,
      postalCode1: postalCode1Valid,
      postalCode2: postalCode2Valid,
      area: areaValid,
      city: cityValid,
      address: addressValid,
      buildName: buildNameValid,
      destination: destinationValid,
      writtenDestination: writtenDestinationValid,
    },
  };
};
