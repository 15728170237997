import GrandChildCategories from './KmanagerGrandChildCategories';
import CategoryAccordion from '../../common/Category/CategoryAccordion';

const ChildCategories = ({ categories, parentId, handleAdd, setInputs }) => {
  return (
    categories &&
    Object.entries(categories).map(([id, category]) => (
      <CategoryAccordion
        key={id}
        id="child-category"
        marginLeft={55}
        category={category}
        handleAdd={handleAdd}
        parentId={category.id}
        grandParentId={parentId}
        setInputs={setInputs}
        depth={2}
        contents={
          <GrandChildCategories
            categories={category.children}
            setInputs={setInputs}
            parentId={category.id}
          />
        }
      />
    ))
  );
};

export default ChildCategories;
