// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-email-modal-contents {
  width: 100%;
  padding: 8px 8px 16px 8px;
  border-top: solid 8px var(--primary-base);
  border-radius: 5px;
  pointer-events: auto;
}

.reset-email-modal-contents-title {
  font-size: 1.25rem;
  text-align: center;
  padding: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/components/MyPage/ResetEmail/css/reset-email-modal-contents.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,yCAAyC;EACzC,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".reset-email-modal-contents {\n  width: 100%;\n  padding: 8px 8px 16px 8px;\n  border-top: solid 8px var(--primary-base);\n  border-radius: 5px;\n  pointer-events: auto;\n}\n\n.reset-email-modal-contents-title {\n  font-size: 1.25rem;\n  text-align: center;\n  padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
