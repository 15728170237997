export const DATA_EMPTY = 'DATA_EMPTY';

export const SERVICE = 'SERVICE';
export const NAVI = 'NAVI';
export const KMANAGER = 'KMANAGER';

export const CAR = 'CAR';
export const ESTATE = 'ESTATE';
export const REAL_ESTATE = 'REAL_ESTATE';
export const isImportant = 'isImportant';
export const TRUE = 'TRUE';
export const DISPLAY = 'DISPLAY';
export const BID = 'BID';
export const NO_PARTICIPATION = 'NO_PARTICIPATION';
export const NORMAL = 'NORMAL';
export const PUBLIC_SALE = 'PUBLIC_SALE';
export const ASSET_SALE = 'ASSET_SALE';

export const PREFECTURE = [
  { id: 1, name: '北海道' },
  { id: 2, name: '青森県' },
  { id: 3, name: '岩手県' },
  { id: 4, name: '宮城県' },
  { id: 5, name: '秋田県' },
  { id: 6, name: '山形県' },
  { id: 7, name: '福島県' },
  { id: 8, name: '茨城県' },
  { id: 9, name: '栃木県' },
  { id: 10, name: '群馬県' },
  { id: 11, name: '埼玉県' },
  { id: 12, name: '千葉県' },
  { id: 13, name: '東京都' },
  { id: 14, name: '神奈川県' },
  { id: 15, name: '新潟県' },
  { id: 16, name: '富山県' },
  { id: 17, name: '石川県' },
  { id: 18, name: '福井県' },
  { id: 19, name: '山梨県' },
  { id: 20, name: '長野県' },
  { id: 21, name: '岐阜県' },
  { id: 22, name: '静岡県' },
  { id: 23, name: '愛知県' },
  { id: 24, name: '三重県' },
  { id: 25, name: '滋賀県' },
  { id: 26, name: '京都府' },
  { id: 27, name: '大阪府' },
  { id: 28, name: '兵庫県' },
  { id: 29, name: '奈良県' },
  { id: 30, name: '和歌山県' },
  { id: 31, name: '鳥取県' },
  { id: 32, name: '島根県' },
  { id: 33, name: '岡山県' },
  { id: 34, name: '広島県' },
  { id: 35, name: '山口県' },
  { id: 36, name: '徳島県' },
  { id: 37, name: '香川県' },
  { id: 38, name: '愛媛県' },
  { id: 39, name: '高知県' },
  { id: 40, name: '福岡県' },
  { id: 41, name: '佐賀県' },
  { id: 42, name: '長崎県' },
  { id: 43, name: '熊本県' },
  { id: 44, name: '大分県' },
  { id: 45, name: '宮崎県' },
  { id: 46, name: '鹿児島県' },
  { id: 47, name: '沖縄県' },
];

export const PREFECTURE_JP = {
  1: '北海道',
  2: '青森県',
  3: '岩手県',
  4: '宮城県',
  5: '秋田県',
  6: '山形県',
  7: '福島県',
  8: '茨城県',
  9: '栃木県',
  10: '群馬県',
  11: '埼玉県',
  12: '千葉県',
  13: '東京都',
  14: '神奈県川',
  15: '新潟県',
  16: '富山県',
  17: '石川県',
  18: '福井県',
  19: '山梨県',
  20: '長野県',
  21: '岐阜県',
  22: '静岡県',
  23: '愛知県',
  24: '三重県',
  25: '滋賀県',
  26: '京都府',
  27: '大阪府',
  28: '兵庫県',
  29: '奈良県',
  30: '和歌県山',
  31: '鳥取県',
  32: '島根県',
  33: '岡山県',
  34: '広島県',
  35: '山口県',
  36: '徳島県',
  37: '香川県',
  38: '愛媛県',
  39: '高知県',
  40: '福岡県',
  41: '佐賀県',
  42: '長崎県',
  43: '熊本県',
  44: '大分県',
  45: '宮崎県',
  46: '鹿児県島',
  47: '沖縄県',
};

export const PROCEDURE_TYPE = [
  { id: 1, label: '公売', value: 'PUBLIC_SALE' },
  { id: 2, label: '公有', value: 'ASSET_SALE' },
];

export const USER_TYPE = [
  { id: 1, label: '親', value: 'PARENT' },
  { id: 2, label: '子', value: 'CHILD' },
  { id: 3, label: '管理者', value: 'MANAGER' },
];

export const PROCEDURE_TYPE_JP = {
  PUBLIC_SALE: 'インターネット公売',
  ASSET_SALE: '公有財産売却',
};

export const PUBLIC_SALE_TYPE_JP = {
  AUCTION: 'せり売',
  BID: '入札',
};

export const PUBLIC_SALE_TYPE = {
  AUCTION: 'せりのみ',
  BID: '入札のみ',
  BOTH: 'せりと入札',
};

export const GOVERNMENT_TYPE_GROUP_JP = {
  NATIONAL_TAX_AGENCY: '国税',
  LOCAL_GOVERNMENT: '地方公共団体など',
  NATIONAL_AGENCY: '国の機関',
};

export const GOVERNMENT_TYPE_JP = {
  NATIONAL_TAX_AGENCY: '国税庁',
  PREFECTURE: '都道府県',
  MUNICIPALITY: '市区町村',
  NATIONAL_ASSET: '国_公有財産売却',
  OTHER: 'その他の行政機関',
};

export const AREA_JP = {
  1: '北海道',
  2: '東北',
  3: '関東',
  4: '信越・北陸',
  5: '東海',
  6: '近畿',
  7: '中国',
  8: '四国',
  9: '九州・沖縄',
};

export const PROPERTY_TYPE_JP = {
  REAL_ESTATE: '一般',
  CAR: '自動車',
  ESTATE: '不動産',
};

export const displayConvert = {
  DISPLAY: '表示する',
  HIDDEN: '表示しない',
};
export const mailReductionConvert = {
  NO_MAIL: 'オンライン申請のみ',
  MAIL: '押印して郵送する',
};
export const mailConvert = {
  NO_MAIL: '希望しない',
  MAIL: '希望する',
};
export const bidEndTypeConvert = {
  AUTO: '行う',
  MANUAL: '行わない',
};

export const RATIO = 'RATIO';
export const YEN = '円';
export const PERCENT = '%';
export const paymentMethodOn = { on: 'オン納付', off: '' };
export const paymentMethodOff = { on: 'オフ納付', off: '' };
export const paymentMethodNone = { on: '保証金なし', off: '' };
