export const  organization_register_description1= [
  {id: 1, text: "50文字以内で入力してください。半角カタカナは利用できません。"},
  {id: 2, text: "こちらに登録された内容が物件情報登録画面にプルダウン選択肢として表示されます。"},
]
export const  organization_register_description2= [
  {id: 1, text: "全角カタカナで入力してください。半角カタカナを入力すると自動的に全角カタカナに変換されます。"},
  {id: 2, text: "50文字以内で入力してください。"},
]
export const  organization_register_description3= [
  {id: 1, text: "例）○○市", marked: false},
  {id: 2, text: "50文字以内で入力してください。"},
]
export const  organization_register_description4= [
  {id: 1, text: "例）○○町○ー○ー○", marked: false},
  {id: 2, text: "50文字以内で入力してください。"},
]
export const  organization_register_description5= [
  {id: 1, text: "例）○○庁舎", marked: false},
  {id: 2, text: "50文字以内で入力してください。"},
]
export const  organization_register_description6= [
  {id: 1, text: "例）○○市○○部○○課官公庁オークション担当者", marked: false},
  {id: 2, text: "50文字以内で入力してください。"},
]
export const  organization_register_description7= [
  {id: 1, text: "例）○○市長", marked: false},
  {id: 2, text: "50文字以内で入力してください。"},
]