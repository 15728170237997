import CalcMethodDetails from 'interfaces/components/Billing/CalcMethodDetails';
import SystemUsages from 'interfaces/components/Billing/SystemUsages';
import TotalAmountBreakdown from "interfaces/components/Billing/TotalAmountBreakdown";
import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import {breadcrumbs} from "interfaces/pages/Billing/entities/breadcrumbs";
import { useContext } from "react";
import { useParams } from 'react-router-dom';

import {BillingContext} from "../entities/BillingContext";

// TODO: 減額申請時の処理
const NaviBilling = () => {
  const { invitation_id } = useParams();
  const { invitation } = useContext(BillingContext)

  const title = (name) => {
    return `${name} / システム利用料額確認`;
  }

  return (
    <div className='confirm-system-usage' data-testid="confirm-system-usage">
      <Breadcrumb breadcrumbs={breadcrumbs(invitation_id)}/>
      <Heading2 title={title(invitation.name)}/>
      <CalcMethodDetails/>
      <SystemUsages/>
      <TotalAmountBreakdown />
    </div>
  );
};

export default NaviBilling;