import { useEffect, useState } from 'react';

import { get as getAssetSaleCautionPage } from '../../../../domain/Setting/services/AssetSaleCaution.js';
import { get as getPublicSaleCautionPages } from '../../../../domain/Setting/services/PublicSaleCaution.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { LocalGovernmentManagementAfterWinningBidContext } from '../entities/LocalGovernmentManagementAfterWinningBidContext.js';
const LocalGovernmentManagementAfterWinningBidProvider = ({
  type,
  children,
}) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [assetSaleCautionPage, setAssetSaleCautionPage] = useState([]);
  const [publicSaleCautionPages, setPublicSaleCautionPages] = useState([]);
  const { localStorageProcedureType } = useLocalStorage();

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getPublicSaleCautionPages(type).then((result) => {
        setPublicSaleCautionPages(result);
      });
    }, []);
    useEffect(() => {
      getAssetSaleCautionPage(type).then((result) => {
        setAssetSaleCautionPage(result);
      });
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      getPublicSaleCautionPages(type).then((result) => {
        setPublicSaleCautionPages(result);
      });
    }, [localStorageProcedureType]);
    useEffect(() => {
      getAssetSaleCautionPage(type).then((result) => {
        setAssetSaleCautionPage(result);
      });
    }, [localStorageProcedureType]);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getPublicSaleCautionPages(type).then((result) => {
        setPublicSaleCautionPages(result);
      });
    }, []);
    useEffect(() => {
      getAssetSaleCautionPage(type).then((result) => {
        setAssetSaleCautionPage(result);
      });
    }, []);
  }

  return (
    <LocalGovernmentManagementAfterWinningBidContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        assetSaleCautionPage,
        publicSaleCautionPages,
      }}>
      {children}
    </LocalGovernmentManagementAfterWinningBidContext.Provider>
  );
};

export default LocalGovernmentManagementAfterWinningBidProvider;
