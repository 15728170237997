
export class Note {
    #note

    constructor(note) {
        this.#note = note;
    }

    get() {
        return this.#note
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
