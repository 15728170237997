import PropertyFormInputText from './PropertyFormInputText.jsx';
import PropertyInfoFormCautionDescription from './PropertyInfoFormCautionDescription.jsx';
import PropertyInfoFormLabel from './PropertyInfoFormLabel.jsx';

import 'interfaces/css/common/property-info-general-form-input-email.css';

const PropertyInfoGeneralFormInputText = ({
  caution_description,
  label,
  number_remaining_characters,
  required,
  validationType,
  data = '',
  width = '100',
  handler1 = () => {},
  handler2 = () => {},
  invalidMessage = 'TRUE',
  disabled = false,
}) => {
  return (
    <div
      data-testid="property-info-general-form-input-text"
      className="property-info-general-form-input-text property-info-general-form-table-row">
      <PropertyInfoFormLabel label={label} required={required} />
      <div className="property-info-general-form-table-cell">
        <PropertyFormInputText
          number_remaining_characters={number_remaining_characters}
          validationType={validationType}
          data={data}
          handler1={handler1}
          handler2={handler2}
          invalidMessage={invalidMessage}
          className={`property-input-text-size-${width}`}
          disabled={disabled}
        />
        <PropertyInfoFormCautionDescription data={caution_description} />
      </div>
    </div>
  );
};
export default PropertyInfoGeneralFormInputText;
