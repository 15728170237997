
export class MailCertDocument {
  #mailCertDocument

  constructor(mailCertDocument) {
    this.#mailCertDocument = mailCertDocument;
  }

  get() {
    return this.#mailCertDocument
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
