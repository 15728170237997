import pino from 'pino';

import { BASE_URL_SERVICE } from "../../../config/envConstants.js";
import { fetchData } from "../../../shared/utils/helper/fetchData.js";
const logger = pino();

const CURRENT_PROMOTION = `${BASE_URL_SERVICE}/storage/current/promotion_properties/data.json`;
const PREVIOUS_PROMOTION = `${BASE_URL_SERVICE}/storage/previous/promotion_properties/data.json`;

const fetchPromotionPropertiesStorage = async () => {
  try {
    const response = await fetchPromotionProperties(CURRENT_PROMOTION)
    return extractProperties(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ promotion property ）を取得できませんでした");
    try {
      const response = await fetchPromotionProperties(PREVIOUS_PROMOTION)
      return extractProperties(response);
    } catch (error) {
      logger.warn({ error }, "以前の通知（ promotion property ）を取得できませんでした");
      return [];
    }
  }
}

export const fetchPromotionProperties = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractProperties = (response) => {
  return response.map(property => property);
}

export default fetchPromotionPropertiesStorage;