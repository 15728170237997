import { useEffect } from 'react';

import { FORGET_PASSWORD, LOGIN, SIGN_UP } from "../../../../shared/utils/helper/authState.js";
import { SERVICE, NAVI, KMANAGER } from "../../../../shared/utils/helper/constants.js";
import { AuctionNewContext } from '../entities/AuctionNewContext.js';

const AuctionNewProvider = ({ type, children }) => {
  const authState = { LOGIN: LOGIN, SIGN_UP: SIGN_UP, FORGET_PASSWORD: FORGET_PASSWORD };

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
    }, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {
    }, []);
  }

  return (
    <AuctionNewContext.Provider value={{
      authState,
    }}>
      {children}
    </AuctionNewContext.Provider>
  );
};

export default AuctionNewProvider;
