import ManualMarkedList from './ManualMarkedList.jsx';
import 'interfaces/css/common/caution-manual-marked-list.css'

const CautionManualMarkedList = ({ title, data, className }) => {
  return (
    <div className={`caution-manual-marked-list ${className ? className : ''}`} data-testid="caution-manual-marked-list">
      <div className="caution-manual-marked-list-title pb-2" data-testid="caution-manual-marked-list-title">{ title }</div>
      <ManualMarkedList data={data}/>
    </div>
  );
};
export default CautionManualMarkedList;