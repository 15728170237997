import axios from 'axios';

import {API_BASE_URL} from "../../config/envConstants.js";

export const createNaviBlockListsData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/navi/block-lists`,
    body, {headers: headers}
  );

};
export const updateNaviBlockListsData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/navi/block-lists/${id}`,
    body, {headers: headers}
  );
};
export const deleteNaviBlockListsData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/navi/block-lists/${id}`, {
      headers: headers,
    },);
};