// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-system-usage .system-usages-table {
  width: 100%;
  margin: 40px 0;
}

.confirm-system-usage .system-usages-total-record {
  background-color: var(--tfoot);
}

.confirm-system-usage .blank-field {
  background-color: var(--white);
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/ConfirmSystemUsage/system-usages-table.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".confirm-system-usage .system-usages-table {\n  width: 100%;\n  margin: 40px 0;\n}\n\n.confirm-system-usage .system-usages-total-record {\n  background-color: var(--tfoot);\n}\n\n.confirm-system-usage .blank-field {\n  background-color: var(--white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
