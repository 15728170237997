import Switcher from 'interfaces/components/common/Switcher';
import { useState } from 'react';

import BothForm from './BothForm.jsx';
import 'interfaces/css/common/property-info-general-form.css';

const IndividualForm = ({ auctionData, bidData }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const labels = ['せり売形式', '入札形式'];

  return (
    <form data-testid="individual-form">
      <Switcher
        labels={labels}
        activeTabIndex={activeTabIndex}
        setActiveTab={setActiveTabIndex}
      />
      {activeTabIndex === 0 && <BothForm auctionData={auctionData} />}
      {activeTabIndex === 1 && <BothForm auctionData={bidData} />}
    </form>
  );
};

export default IndividualForm;
