
// pagination
export const NOTICE_PAGE_SIZE = 10;
export const PROPERTY_LIST_SIZE = 30;
export const BLOCK_LIST_SIZE = 30;
export const PUBLISHED_OBJECT_PAGE_SIZE = 30;
export const PUBLISHED_OBJECT_STATUS_PAGE_SIZE = 30;
export const APPLICATION_LIST_SIZE = 30;
export const BIDDER_LIST_SIZE = 30;

// file_path
// MEMO: パスは直接記述？
export const CURRENT_PROPERTIES_PATH = '/storage/current/navi/properties/properties.json';
export const OLD_PROPERTIES_PATH = '/storage/previous/navi/properties/properties.json';

export const CURRENT_BIDS_PATH = '/storage/current/navi/properties/bid_info.json';
export const OLD_BIDS_PATH = '/storage/previous/navi/properties/bid_info.json';

export const CURRENT_GOVERNMENTS_PATH = '/storage/current/navi/governments/governments.json';
export const OLD_GOVERNMENTS_PATH = '/storage/previous/navi/governments/governments.json';

export const CURRENT_INVITATIONS_PATH = (gov_id) => {
    return `/storage/current/navi/invitations/${gov_id}/invitations.json`
}
export const OLD_INVITATIONS_PATH = (gov_id) => {
    return `/storage/previous/navi/invitations/${gov_id}invitations.json`
}

export const CURRENT_AUCTIONS_PATH = '/storage/current/navi/auctions/auctions.json';
export const OLD_AUCTIONS_PATH = '/storage/previous/navi/auctions/auctions.json';

export const CURRENT_NOTICES_PATH = '/storage/current/navi/notices/notices.json';
export const OLD_NOTICES_PATH = '/storage/previous/navi/notices/notices.json';

// export const CURRENT_MESSAGES_PATH = '/storage/current/navi/messages/messages.json';
// export const OLD_MESSAGES_PATH = '/storage/previous/navi/messages/messages.json';

export const CURRENT_GOVERNMENT_TOP_PAGES_PATH = '/storage/current/navi/government_top_pages/government_top_pages.json'
export const PREVIOUS_GOVERNMENT_TOP_PAGES_PATH = '/storage/previous/navi/government_top_pages/government_top_pages.json'

export const CURRENT_CREDIT_CARD_PAGES_PATH = '/storage/current/navi/credit_card_pages/credit_card_pages.json'
export const PREVIOUS_CREDIT_CARD_PAGES_PATH = '/storage/previous/navi/credit_card_pages/credit_card_pages.json'

export const CURRENT_BANK_TRANSFER_PAGES_PATH = '/storage/current/navi/bank_transfer_pages/bank_transfer_pages.json'
export const PREVIOUS_BANK_TRANSFER_PAGES_PATH = '/storage/previous/navi/bank_transfer_pages/bank_transfer_pages.json'

export const CURRENT_PUBLIC_SALE_CAUTION_PAGES_PATH = (governmentId) => `/storage/current/navi/public_sale_caution_pages/${governmentId}/public_sale_caution_pages.json`
export const PREVIOUS_PUBLIC_SALE_CAUTION_PAGES_PATH = (governmentId) => `/storage/previous/navi/public_sale_caution_pages/${governmentId}/public_sale_caution_pages.json`

export const CURRENT_GUIDELINES_PATH = (governmentId) => `/storage/current/navi/guidelines/${governmentId}/guidelines.json`
export const PREVIOUS_GUIDELINES_PATH = (governmentId) => `/storage/previous/navi/guidelines/${governmentId}/guidelines.json`
