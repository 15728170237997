// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sample-guideline .guideline-modal-caution-list-wrap {
  color: #101010;
  font-size: 14px;
  padding-bottom: 20px;
  margin-top: 16px;
  line-height: 1.375rem;
}


.sample-guideline .guideline-modal-caution-list-inner {
  padding: 10px;
  background-color: var(--thead);
}

.sample-guideline .guideline-modal-caution-list {
  list-style-type: disc;
}

.sample-guideline .guideline-modal-caution-list-title {
  font-size: 16px;
  font-weight: bold;
}

.sample-guideline .guideline-modal-caution-list-item {
  list-style-type: inherit;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/SampleGuideline/guideline-modal-caution-list.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,oBAAoB;EACpB,gBAAgB;EAChB,qBAAqB;AACvB;;;AAGA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".sample-guideline .guideline-modal-caution-list-wrap {\n  color: #101010;\n  font-size: 14px;\n  padding-bottom: 20px;\n  margin-top: 16px;\n  line-height: 1.375rem;\n}\n\n\n.sample-guideline .guideline-modal-caution-list-inner {\n  padding: 10px;\n  background-color: var(--thead);\n}\n\n.sample-guideline .guideline-modal-caution-list {\n  list-style-type: disc;\n}\n\n.sample-guideline .guideline-modal-caution-list-title {\n  font-size: 16px;\n  font-weight: bold;\n}\n\n.sample-guideline .guideline-modal-caution-list-item {\n  list-style-type: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
