import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
// 動作確認用
const CURRENT_CREDIT_CARD_PAGES = `${BASE_URL_NAVIGATION}/storage/current/credit_card_pages/data.json`;
const PREVIOUS_CREDIT_CARD_PAGES = `${BASE_URL_NAVIGATION}/storage/previous/credit_card_pages/data.json`;

export const fetchCreditCardPagesStorage = async () => {
  try {
    const response = await fetchCreditCardPages(CURRENT_CREDIT_CARD_PAGES);
    return extractCreditCardPages(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ credit_card_pages ）を取得できませんでした");

    try {
      const response = await fetchCreditCardPages(PREVIOUS_CREDIT_CARD_PAGES);
      return extractCreditCardPages(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ credit_card_pages ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchCreditCardPages = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractCreditCardPages = (response) => {
  return response.map(credit_card_page => credit_card_page);
}