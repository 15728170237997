import UploadImagesImagesGeneralProvider from './UploadImagesImagesGeneralProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { uploadImagesImagesGeneral } from "../entities/componentMapping.js";

const UploadImagesImagesGeneral = ({ type }) => {
  return (
    <UploadImagesImagesGeneralProvider type={type}>
      {getComponent(uploadImagesImagesGeneral, type)}
    </UploadImagesImagesGeneralProvider>
  );
};

export default UploadImagesImagesGeneral;
