import styled from 'styled-components';

const Wrapper = styled.span`
  color: var(--required);
`;

const RequiredLabel = () => {
  return <Wrapper data-testid="required-label">*</Wrapper>;
};

export default RequiredLabel;
