import { fetchBidderHistoryStorage } from "../../../infrastructure/api/storage/bidder_history.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { BidderHistoryGet } from "../entities/Bid.js";

// getメソッドの実装
export async function getBidderHistory(type) {
  // SAMPLE
  let response = [];
  if (type === SERVICE) {
    response = await fetchBidderHistoryStorage()
  }
  if (type === NAVI) {
    response = await fetchBidderHistoryStorage()
  }
  if (type === KMANAGER) {
    response = await fetchBidderHistoryStorage()
  }

  if (response instanceof Error) {
    throw new CustomError('Error get Bid', response);
  }

  let results = [];
  response.map((data) => {
    const result = new BidderHistoryGet(data);
    results.push(result)
  })

  return results
}
