import { ReactComponent as CheckBoxCheckedIcon } from 'interfaces/assets/svg/checkbox-marked.svg';
import { ReactComponent as CheckBoxIcon } from 'interfaces/assets/svg/checkbox_outline_blank.svg';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParamController } from 'shared/hooks/useQueryParamController';
import { sortTypeToQueryParam } from 'shared/utils/constants/mylist_search_conditions';
import { ORDER, PAGE, STATUS } from 'shared/utils/constants/queryParam';
import 'interfaces/css/Mylist/mylist-sort-checkbox.css';

const MylistSortCheckBox = ({ keyName, label, value }) => {
  const [searchParams] = useSearchParams();
  const [isChecked, setIsChecked] = useState(false);
  const {
    applyQueryParam,
    appendQueryParam,
    applyCommaSeparatedQueryParam,
    removeCommaSeparatedQueryParam,
  } = useQueryParamController();
  const clickHandle = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (searchParams.has(keyName)) {
      const queryParams = searchParams.get(keyName);
      const queryParamsArray = queryParams.split(',');
      if (queryParamsArray.includes(value)) {
        setIsChecked(true);
      }
    }
  }, []);

  return (
    <div data-testid="mylist-sort-checkbox" className="mylist-sort-checkbox">
      <div className="mylist-sort-checkbox-body">
        {isChecked ? (
          <CheckBoxCheckedIcon
            data-testid="mylist-sort-checkbox-checked-icon"
            className="mylist-sort-checkbox-icon"
          />
        ) : (
          <CheckBoxIcon
            data-testid="mylist-sort-checkbox-icon"
            className="mylist-sort-checkbox-icon"
          />
        )}
        <input
          onClick={() => {
            clickHandle();
            appendQueryParam(STATUS, 'all');
            applyQueryParam(PAGE, 1);
            appendQueryParam(
              ORDER,
              sortTypeToQueryParam['マイリスト登録の新しい順'],
            );
            isChecked
              ? removeCommaSeparatedQueryParam(keyName, value)
              : applyCommaSeparatedQueryParam(keyName, value);
          }}
          aria-checked="false"
          role="checkbox"
          type="checkbox"
          value={value}
          className="mylist-sort-checkbox-input"
          data-testid="mylist-sort-checkbox-input"
        />
      </div>
      <label
        data-testid="mylist-sort-checkbox-label"
        className="mylist-sort-checkbox-label">
        {label}
      </label>
    </div>
  );
};
export default MylistSortCheckBox;
