import ApplicantList from 'interfaces/pages/ApplicantList/services/ApplicantList.jsx';
import AuctionSchedule from 'interfaces/pages/AuctionSchedule/services/AuctionSchedule.jsx';
import BidderList from 'interfaces/pages/BidderList/services/BidderList.jsx';
import Billing from 'interfaces/pages/Billing/services/Billing.jsx';
import BlockList from 'interfaces/pages/BlockList/services/BlockList.jsx';
import Dashboard from 'interfaces/pages/Dashboard/services/Dashboard.jsx';
import Faq from 'interfaces/pages/Faq/services/Faq.jsx';
import FaqOther from 'interfaces/pages/FaqOther/services/FaqOther.jsx';
import GeneralEdit from 'interfaces/pages/GeneralEdit/services/GeneralEdit.jsx';
import LocalGovernmentManagement from 'interfaces/pages/LocalGovernmentManagement/services/LocalGovernmentManagement.jsx';
import LocalGovernmentManagementAfterWinningBid from 'interfaces/pages/LocalGovernmentManagementAfterWinningBid/services/LocalGovernmentManagementAfterWinningBid.jsx';
import LocalGovernmentManagementBankTransfer from 'interfaces/pages/LocalGovernmentManagementBankTransfer/services/LocalGovernmentManagementBankTransfer.jsx';
import LocalGovernmentManagementCredit from 'interfaces/pages/LocalGovernmentManagementCredit/services/LocalGovernmentManagementCredit.jsx';
import LocalGovernmentManagementEmblem from 'interfaces/pages/LocalGovernmentManagementEmblem/services/LocalGovernmentManagementEmblem.jsx';
import LocalGovernmentManagementGuidelines from 'interfaces/pages/LocalGovernmentManagementGuidelines/services/LocalGovernmentManagementGuidelines.jsx';
import LocalGovernmentManagementSendDocumentsTo from 'interfaces/pages/LocalGovernmentManagementSendDocumentsTo/services/LocalGovernmentManagementSendDocumentsTo.jsx';
import LocalGovernmentManagementTop from 'interfaces/pages/LocalGovernmentManagementTop/services/LocalGovernmentManagementTop.jsx';
import Login from 'interfaces/pages/Login/services/Login.jsx';
import MailNotificationConfigure from 'interfaces/pages/MailNotificationConfigure/services/MailNotificationConfigure.jsx';
import Manual from 'interfaces/pages/Manual/services/Manual.jsx';
import NewEntry from 'interfaces/pages/NewEntry/services/NewEntry.jsx';
import News from 'interfaces/pages/News/services/News.jsx';
import Organization from 'interfaces/pages/Organization/services/Organization.jsx';
import OrganizationEdit from 'interfaces/pages/OrganizationEdit/services/OrganizationEdit';
import OrganizationRegister from 'interfaces/pages/OrganizationRegister/services/OrganizationRegister.jsx';
import PropertiesManagement from 'interfaces/pages/PropertiesManagement/services/PropertiesManagement.jsx';
import PropertyInfoCar from 'interfaces/pages/PropertyInfoCar/services/PropertyInfoCar.jsx';
import PropertyInfoEstate from 'interfaces/pages/PropertyInfoEstate/services/PropertyInfoEstate.jsx';
import PropertyInfoRealEstate from 'interfaces/pages/PropertyInfoRealEstate/services/PropertyInfoRealEstate.jsx';
import PropertyList from 'interfaces/pages/PropertyList/services/PropertyList.jsx';
import PublishedObject from 'interfaces/pages/PublishedObject/services/PublishedObject.jsx';
import PublishedObjectStatus from 'interfaces/pages/PublishedObjectStatus/services/PublishedObjectStatus.jsx';
import RegistrationUser from 'interfaces/pages/RegistrationUser/services/RegistrationUser.jsx';
import SampleAfterWinningBid from 'interfaces/pages/SampleAfterWinningBid/services/SampleAfterWinningBid.jsx';
import SampleAfterWinningBidCaution from 'interfaces/pages/SampleAfterWinningBidCaution/services/SampleAfterWinningBidCaution.jsx';
import SampleGuideline from 'interfaces/pages/SampleGuideline/services/SampleGuideline.jsx';
import SampleGuidelineCaution from 'interfaces/pages/SampleGuidelineCaution/services/SampleGuidelineCaution.jsx';
import UploadImagesImagesGeneral from 'interfaces/pages/UploadImagesImagesGeneral/services/UploadImagesImagesGeneral.jsx';
import User from 'interfaces/pages/User/services/User.jsx';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

import NaviHeader from '../../interfaces/components/common/Header/NaviHeader';
import NaviFooter from '../../interfaces/components/common/NaviFooter';

import 'interfaces/css/common/navi-common.css';

const appRouter = (type) => [
  { path: '/', element: <Dashboard type={type} /> },
  //  { path: '/login', element: <Login type={type}/> },
  { path: '/notice', element: <News type={type} /> },
  { path: '/manual', element: <Manual type={type} /> },
  { path: '/auction-schedule', element: <AuctionSchedule type={type} /> },
  { path: '/faq', element: <Faq type={type} /> },
  { path: '/faq/other', element: <FaqOther type={type} /> },
  { path: '/faq/sample-guideline', element: <SampleGuideline type={type} /> },
  {
    path: '/faq/sample-guideline-caution',
    element: <SampleGuidelineCaution type={type} />,
  },
  {
    path: '/faq/sample-after-winning-public',
    element: <SampleAfterWinningBid type={type} />,
  },
  {
    path: '/faq/sample-after-winning-bid',
    element: <SampleAfterWinningBidCaution type={type} />,
  },
  {
    path: '/faq/sample-after-winning-bid-caution',
    element: <SampleGuidelineCaution type={type} />,
  },
  { path: '/user', element: <User type={type} /> },
  { path: '/user/new', element: <RegistrationUser type={type} /> },
  { path: '/user/detail/:id', element: <RegistrationUser type={type} /> },
  { path: '/organization', element: <Organization type={type} /> },
  {
    path: '/organization/new',
    element: <OrganizationRegister type={type} target={'new'} />,
  },
  {
    path: '/organization/confirm',
    element: <OrganizationRegister type={type} target={'confirm'} />,
  },
  {
    path: '/organization/new/complete',
    element: <OrganizationRegister type={type} target={'complete'} />,
  },
  {
    path: '/organization/:id/edit',
    element: <OrganizationEdit type={type} target={'edit'} />,
  },
  {
    path: '/organization/:id/edit/complete',
    element: <OrganizationEdit type={type} target={'complete'} />,
  },
  {
    path: '/organization/:id/confirm',
    element: <OrganizationEdit type={type} target={'confirm'} />,
  },
  {
    path: '/organization/:id/complete',
    element: <OrganizationEdit type={type} target={'complete'} />,
  },
  { path: '/block-list', element: <BlockList type={type} /> },
  { path: '/new-entry', element: <NewEntry type={type} /> },
  { path: '/new-entry/:auction_id/edit', element: <NewEntry type={type} /> },
  {
    path: '/mail-notification/configure',
    element: <MailNotificationConfigure type={type} />,
  },
  {
    path: '/object/:auction_id',
    element: <PropertiesManagement type={type} />,
  },
  { path: '/object/:auction_id/list', element: <PropertyList type={type} /> },
  { path: '/object/:auction_id/billing', element: <Billing type={type} /> },
  {
    path: '/object/:auction_id/published-object',
    element: <PublishedObject type={type} />,
  },
  {
    path: '/object/:auction_id/published-object/status',
    element: <PublishedObjectStatus type={type} />,
  },
  {
    path: '/object/:auction_id/detail/:property_id/applicant-list',
    element: <ApplicantList type={type} />,
  },
  {
    path: '/object/:auction_id/detail/:property_id/bidder-list',
    element: <BidderList type={type} />,
  },
  {
    path: '/object/:auction_id/detail/:property_id/general/edit',
    element: <GeneralEdit type={type} />,
  },
  {
    path: '/object/:auction_id/create/real-estate',
    element: <PropertyInfoRealEstate type={type} />,
  },
  {
    path: '/object/:auction_id/detail/:property_id/real-estate/edit',
    element: <PropertyInfoRealEstate type={type} />,
  },
  {
    path: '/object/:auction_id/create/car',
    element: <PropertyInfoCar type={type} />,
  },
  {
    path: '/object/:auction_id/detail/:property_id/car/edit',
    element: <PropertyInfoCar type={type} />,
  },
  {
    path: '/object/:auction_id/create/estate',
    element: <PropertyInfoEstate type={type} />,
  },
  {
    path: '/object/:auction_id/detail/:property_id/estate/edit',
    element: <PropertyInfoEstate type={type} />,
  },
  {
    path: '/object/:auction_id/create/upload-images/general',
    element: <UploadImagesImagesGeneral type={type} />,
  },
  {
    path: '/local-government-management',
    element: <LocalGovernmentManagement type={type} />,
  },
  {
    path: '/local-government-management/credit',
    element: <LocalGovernmentManagementCredit type={type} />,
  },
  {
    path: '/local-government-management/bank-transfer',
    element: <LocalGovernmentManagementBankTransfer type={type} />,
  },
  {
    path: '/local-government-management/top',
    element: <LocalGovernmentManagementTop type={type} />,
  },
  {
    path: '/local-government-management/guideline',
    element: <LocalGovernmentManagementGuidelines type={type} />,
  },
  {
    path: '/local-government-management/after-winning-public',
    element: <LocalGovernmentManagementAfterWinningBid type={type} />,
  },
  {
    path: '/local-government-management/send-documents-to',
    element: <LocalGovernmentManagementSendDocumentsTo type={type} />,
  },
  {
    path: '/local-government-management/emblem',
    element: <LocalGovernmentManagementEmblem type={type} />,
  },
];

const NaviRoutes = ({ type }) => {
  const { localStorageUserId, localStorageGovernmentId } = useLocalStorage();

  return (
    <>
      <Routes>
        <>
          <Route
            key="0"
            path="/login"
            element={
              <>
                <span className="navi-wrap">
                  <span className="navi">
                    <Login type={type} />
                    <NaviFooter />
                  </span>
                </span>
              </>
            }
          />
        </>
        <>
          {appRouter(type).map((route, index) => (
            <Route
              key={index + 1}
              path={route.path}
              element={
                localStorageUserId != null &&
                localStorageGovernmentId != null ? (
                  <span className="navi-wrap new-style_navi_container">
                    <span className="navi">
                      <NaviHeader />
                      {route.element}
                      <NaviFooter />
                    </span>
                  </span>
                ) : (
                  <Navigate to={'/navi/login'} />
                )
              }
            />
          ))}
        </>
      </Routes>
    </>
  );
};

export default NaviRoutes;
