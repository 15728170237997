import KmanagerTableColumn from './KmanagerTableColumn.jsx';
import KmanagerTableRecord from './KmanagerTableRecord.jsx';
import 'interfaces/css/common/kmanager-table.css';
import KmanagerTableEmptyRecord from './KmanagerTableEmptyRecord.jsx';
import { useSortableData } from 'shared/hooks/useSort.js';
// KmanagerTableからPaginationを削除しただけ。リファクタ希望
//const KmanagerSimpleTable = ({ data, headers }) => {
const KmanagerSimpleTable = ({ data, headers, column }) => {
  const displyNotSettingWhenEmptyData =
    ['publicSaleUsers', 'assetSaleUsers'].includes(column) && data.length === 0;

  const { items: sortedData, requestSort, sortConfig } = useSortableData(data);
  return displyNotSettingWhenEmptyData ? (
    <div>未設定</div>
  ) : (
    <div>
      <div className="kmanager-table-wrap">
        <table
          className="kmanager-table kmanager-table-hover"
          data-testid="kmanager-table">
          <KmanagerTableColumn
            headers={headers}
            requestSort={requestSort}
            sortConfig={sortConfig}
          />
          <tbody>
            {data.length === 0 ? (
              <KmanagerTableEmptyRecord />
            ) : (
              sortedData.map((item, count) => (
                <KmanagerTableRecord
                  key={count}
                  data={item}
                  headers={headers}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default KmanagerSimpleTable;
