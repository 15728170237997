import UserProvider from './UserProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { user } from "../entities/componentMapping.js";

const User = ({ type }) => {
  return (
    <UserProvider type={type}>
      {getComponent(user, type)}
    </UserProvider>
  );
};

export default User;
