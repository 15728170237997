
export class WantReceiveEmail {
  #wantReceiveEmail

  constructor(wantReceiveEmail) {
    this.#wantReceiveEmail = wantReceiveEmail;
  }

  get() {
    return this.#wantReceiveEmail
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
