import NewEntryProvider from './NewEntryProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { newEntry } from "../entities/componentMapping.js";

const NewEntry = ({ type }) => {
  return (
    <NewEntryProvider type={type}>
      {getComponent(newEntry, type)}
    </NewEntryProvider>
  );
};

export default NewEntry;
