import { validateNumber, validateRangeLength, validateDecimal } from "../../../../application/validations.js";

export class CarMileage {
  #carMileage

  constructor(carMileage) {
    this.#carMileage = carMileage;
  }

  get() {
    return this.#carMileage
  }

  get isValid() {
    return [
      this.validateNumber,
      this.validateRangeLength,
      this.validateDecimal,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateNumber() {
    return validateNumber(this.#carMileage)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#carMileage, 1, 10)
  }

  // バリデーションルールの適用
  get validateDecimal() {
    return validateDecimal(this.#carMileage, 0)
  }
}
