import { createContext } from 'react';

import { breadcrumbs } from "./breadcrumbs.js";
import { FORGET_PASSWORD, LOGIN, SIGN_UP } from "../../../../shared/utils/helper/authState.js";
import { DATA_EMPTY } from "../../../../shared/utils/helper/constants.js";

export const OrganizationContext = createContext({
  // Default Value
  responsive: DATA_EMPTY,
  authState: {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD
  },
  breadcrumbs: breadcrumbs,
  managementMessages: DATA_EMPTY,
  governmentId: DATA_EMPTY,
  governmentType: DATA_EMPTY,
  procedureType: DATA_EMPTY,
  queryParams: DATA_EMPTY,
  divisions: DATA_EMPTY,
  invitations: DATA_EMPTY,
  auctions: DATA_EMPTY,
  governmentCategory: DATA_EMPTY,
  governmentSubCategory: DATA_EMPTY,
  isNotEditable: DATA_EMPTY,
});
