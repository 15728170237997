import {
  BidCountBody,
  CurrentTopUserBody,
  DepositBody,
  DepositPaymentBody,
  MyBidPriceBody,
  PaymentBody,
} from './PropertyCardCaption.jsx';
import { isFalsy } from '../../helper/isFalsy.js';

const WaitOpenBidPropertyCardCaptions = ({ data }) => {
  return (
    <>
      {data.publicSaleType === 'せり売形式' && (
        <>
          <CurrentTopUserBody currentTopUser={data.currentTopUser} />
          <BidCountBody bidCount={data.bidCount} />
        </>
      )}
      {!isFalsy(data.myBidPrice) && (
        <MyBidPriceBody myBidPrice={data.myBidPrice} />
      )}
      <DepositBody deposit={data.deposit} />
      <DepositPaymentBody depositPayment={data.depositPayment} />
      <PaymentBody payments={data.payments} />
    </>
  );
};
export default WaitOpenBidPropertyCardCaptions;
