import ApplicationInfoFormCheckBox from './ApplicationInfoFormCheckBox.jsx';
import ApplicationInfoSectionHeader from './ApplicationInfoSectionHeader.jsx';
import CallUpUserInfoButton from './CallUpUserInfoButton.jsx';
import EditUserInfoBody from './EditUserInfo/EditUserInfoBody.jsx';
//import { useApplicationInfo } from '../../hooks/useApplicationInfo.js';

const DelegatorInfoForm = ({
  delegatorInfo,
  registeredInfo,
  setDelegatorInformation,
  callUpInfo,
}) => {
  //const { handleDelegatorInfoChange, validationMessages } =
  //  useApplicationInfo();
  // mock data
  const handleDelegatorInfoChange = (params) => {
    setDelegatorInformation({
      ...delegatorInfo,
      ...params,
    });
  };
  const validationMessages = {};

  const handleCallUp = async () => {
    setDelegatorInformation({
      ...delegatorInfo,
      ...callUpInfo(registeredInfo),
    });
  };

  return (
    <div data-testid="delegator-info-form">
      <ApplicationInfoSectionHeader
        title="委任者情報"
        caption="参加申込者が代理人の場合は、委任者（手続きや入札を代理人に頼む人）の情報を入力してください。"
      />
      <div data-testid="info-form">
        <EditUserInfoBody
          prefix={
            registeredInfo && (
              <CallUpUserInfoButton handleClick={handleCallUp} />
            )
          }
          userInfo={delegatorInfo}
          validationMessages={validationMessages.delegatorInfo}
          handleUserInfoChange={handleDelegatorInfoChange}
          suffix={
            <ApplicationInfoFormCheckBox
              value={delegatorInfo.isUpdateMypage}
              label="この情報をマイページに上書き保存する"
              handleClick={() =>
                handleDelegatorInfoChange({
                  isUpdateMypage: !delegatorInfo.isUpdateMypage,
                })
              }
            />
          }
          isDelegator={true}
        />
      </div>
    </div>
  );
};

export default DelegatorInfoForm;
