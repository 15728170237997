// カテゴリ
export const CAR = 'CAR';
export const ESTATE = 'ESTATE';
export const REAL_ESTATE = 'REAL_ESTATE';
export const HYPHEN_SYMBOL = '-';
export const DATA_EMPTY = 'DATA_EMPTY';

//publicSaleType
export const BID = '入札形式';
export const AUCTION = 'せり売形式';
export const BOTH = '両方';

//procedureType
export const PUBLIC_SALE = 'インターネット公売';
export const ASSET_SALE = '公有財産売却';

//物件の状態
export const APPLYING = '申し込み受付中';
export const WAIT_START_BID = '入札開始待ち';
export const DURING_BIDDING = '入札受付中';
export const WAIT_OPEN_BID = '開札準備中';
export const ADDITIONAL_DURING_BIDDING = '追加入札中';
export const END_BIDDING = '入札終了';
export const CANCEL = '中止';

//物件詳細のボタンの内容
export const APPLY_BTN = '参加申し込み';
export const APPLY_DEADLINE_BTN = '申し込み締切';
export const BIDDING_BTN = '入札する';
export const END_BIDDING_BTN = '入札は終了しました';
export const APPLY_FIN_BTN = '申し込み済み';
export const TEMPORARY_APPLY_FIN_BTN = '申し込み完了'; //仮申し込みが本申し込みになって完了時に出る
export const BID_END_BTN = '入札済み';

//申込状態
export const TEMPORARY_APPLY = 'TRIAL';
export const FINAL_APPLY = 'APPLICATION';
export const APL_TYPE_CANCEL = 'CANCEL';
export const APL_TYPE_CONFISCATION = 'CONFISCATION';
export const CANCEL_APPLY = '取消';

export const DISPLAY = 'DISPLAY';

export const condition = ['', undefined, DATA_EMPTY];
