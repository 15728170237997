import {BID, DATA_EMPTY, NO_PARTICIPATION, PERCENT, RATIO, YEN} from "./constants.js";

export const publicSaleTypeConvert = (type) => {
  if (type === BID) {
    return '入札';
  }
  return 'せり売';
}

export const participationStatusConvert = (status) => {
  if (status === NO_PARTICIPATION) {
    return '不参加';
  }
  return '参加';
}

export const convertEmptyCharacters = (value) => {
  const emptyCondition = [DATA_EMPTY, "-9999", "1000-01-01 00:00:00", "1000-01-01", "00:00:00", null, undefined]
  if (emptyCondition.includes(value)) {
    return ""
  }
  return value;
};
export const typeOfAlert = (value) => {
  if (value === "NORMAL") {
    return 'unimportant-alert';
  }
  if (value === "EMERGENCY") {
    return 'important-alert';
  }
  return 'normal-alert';
};

export const getUnit = (value) => {
  if (value === RATIO) {
    return PERCENT;
  }
  return YEN;
}
