import { useState } from 'react';

export function useNaviUserRegisterInputs() {
  const [familyName, setFamilyName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [familyNameKana, setFamilyNameKana] = useState('');
  const [firstNameKana, setFirstNameKana] = useState('');
  const [department, setDepartment] = useState('');
  const [officePosition, setOfficePosition] = useState('');
  const [tel, setTel] = useState({
    telNum1: '',
    telNum2: '',
    telNum3: '',
  });
  const [telNum1, setTelNum1] = useState('');
  const [telNum2, setTelNum2] = useState('');
  const [telNum3, setTelNum3] = useState('');
  const [extension, setExtension] = useState('');
  const [userType, setUserType] = useState('PARENT');
  const [email, setEmail] = useState('');
  const [accessPersonalInfo, setAccessPersonalInfo] = useState('なし');
  const [wantReceiveEmail, setWantReceiveEmail] = useState('受信する');

  const inputs = {
    data: {
      familyName,
      firstName,
      familyNameKana,
      firstNameKana,
      department,
      officePosition,
      tel,
      telNum1,
      telNum2,
      telNum3,
      extension,
      userType,
      email,
      accessPersonalInfo,
      wantReceiveEmail,
    },
    setters: {
      setFamilyName,
      setFirstName,
      setFamilyNameKana,
      setFirstNameKana,
      setDepartment,
      setOfficePosition,
      setTel,
      setTelNum1,
      setTelNum2,
      setTelNum3,
      setExtension,
      setUserType,
      setEmail,
      setAccessPersonalInfo,
      setWantReceiveEmail,
    },
  };
  return inputs;
}
