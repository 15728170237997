import GeneralEditProvider from './GeneralEditProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { generalEdit } from "../entities/componentMapping.js";

const GeneralEdit = ({ type }) => {
  return (
    <GeneralEditProvider type={type}>
      {getComponent(generalEdit, type)}
    </GeneralEditProvider>
  );
};

export default GeneralEdit;
