import PropertiesProvider from './PropertiesProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { properties } from "../entities/componentMapping.js";

const Properties = ({ type }) => {
  return (
    <PropertiesProvider type={type}>
      {getComponent(properties, type)}
    </PropertiesProvider>
  );
};

export default Properties;
