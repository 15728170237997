import Pagination from 'interfaces/components/common/Pagination';

import AuctionDepositTableColumn from './AuctionDepositTableColumn';
import AuctionDepositTableRecord from './AuctionDepositTableRecord';
import usePagination from 'shared/hooks/usePagination';
import { useSortableData } from 'shared/hooks/useSort.js';
import { useState } from 'react';

import 'interfaces/css/Dashboard/kmanager-auction-table.css';

const AuctionDepositTable = ({
  auction,
  auctionDeposits = [],
  checked,
  checkedList,
  allCheck,
  clearCheck,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    items: sortedAuctionDeposits,
    requestSort,
    sortConfig,
  } = useSortableData(auctionDeposits);

  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(sortedAuctionDeposits, rowsPerPage);

  return (
    <div className="kmanager-auction-operation-table">
      <div className="kmanager-table-wrap">
        <table
          className="kmanager-table kmanager-table-hover"
          data-testid="kmanager-auction-table">
          <AuctionDepositTableColumn
            auction={auction}
            auctionDeposits={auctionDeposits}
            requestSort={requestSort}
            sortConfig={sortConfig}
            checked={checked}
            allCheck={allCheck}
            clearCheck={clearCheck}
          />
          <tbody>
            {currentItems &&
              currentItems.map((deposit, index) => (
                <AuctionDepositTableRecord
                  auction={auction}
                  deposit={deposit}
                  key={index}
                  checked={checked}
                  checkedList={checkedList}
                />
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        DATA_COUNT={auctionDeposits.length}
      />
    </div>
  );
};

export default AuctionDepositTable;
