import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { findById as getAuction } from '../../../../domain/Auction/services/Auction';
import { findByAuctionId as getInvitations } from '../../../../domain/Auction/services/Invitation';
import { get as getGovernment } from '../../../../domain/Government/services/Government';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState';
import { KMANAGER } from '../../../../shared/utils/helper/constants';
import { AuctionGovernmentContext } from '../entities/AuctionGovernmentContext';

const AuctionGovernmentProvider = ({ type, children }) => {
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const { auctionId } = useParams();

  const [auction, setAuction] = useState([]);
  const [invitationCounts, setInvitationCount] = useState({});
  const [invitations, setInvitations] = useState([]);
  const [governments, setGovernments] = useState([]);

  if (type === KMANAGER) {
    useEffect(() => {
      getAuction(type, auctionId).then((auction) => {
        setAuction(auction);
      });
    }, []);

    useEffect(() => {
      getInvitations(type, auctionId).then((invitations) => {
        setInvitations(invitations);
        let participation = invitations.filter(
          (invitation) => invitation.participationStatus === 'PARTICIPATION',
        );
        let noParticipation = invitations.filter(
          (invitation) => invitation.participationStatus === 'NO_PARTICIPATION',
        );
        setInvitationCount({
          registration: invitations.length,
          participation: participation.length,
          noParticipation: noParticipation.length,
        });
      });
    }, [auctionId]);

    useEffect(() => {
      getInvitations(type, auctionId).then((invitations) => {
        getGovernment(type).then((governments) => {
          let auctionGovernments = [];
          governments.forEach((government) => {
            let auctionGovernment = {};
            let invitation = invitations.find(
              (invitation) =>
                invitation.auctionId.toString() === auctionId.toString() &&
                invitation.governmentId.toString() === government.id,
            );
            if (invitation !== undefined) {
              auctionGovernment.id = government.id;
              auctionGovernment.location = government.location;
              auctionGovernment.region = government.region;
              auctionGovernment.governmentSubCategoryId =
                government.governmentSubCategoryId;
              auctionGovernment.name = government.name;
              auctionGovernment.participationStatus =
                invitation.participationStatus;
              auctionGovernment.bidEndButtonDownAuctionDate =
                invitation.bidEndButtonDownAuctionDate;
              auctionGovernment.bidEndButtonDownBidDate =
                invitation.bidEndButtonDownBidDate;
              auctionGovernment.mailCertDocument = invitation.mailCertDocument;
              auctionGovernments.push(auctionGovernment);
            }
          });
          setGovernments(auctionGovernments);
        });
      });
    }, [auctionId]);
  }

  return (
    <AuctionGovernmentContext.Provider
      value={{
        authState,
        auction,
        invitationCounts,
        governments,
        invitations,
      }}>
      {children}
    </AuctionGovernmentContext.Provider>
  );
};

export default AuctionGovernmentProvider;
