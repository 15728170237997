import { Link } from 'react-router-dom';

const PopUpIcon = ({ link, svg, label, handleClose }) => {
  return (
    <Link to={link} onClick={handleClose} className="popup-icon flex align-items-center" data-testid="popup-icon">
      <div className="popup-icon-svg">
        {svg}
      </div>
      <div className="popup-icon-label-container flex align-items-center flex-wrap-wrap">
        <span className="popup-icon-label">{label}</span>
      </div>
    </Link>
  );
};

export default PopUpIcon;
