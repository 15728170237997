import { validateSelected } from "../../../../application/validations.js";

export class IsDisplayLocalGov {
  #isDisplayLocalGov

  constructor(isDisplayLocalGov) {
    this.#isDisplayLocalGov = isDisplayLocalGov;
  }

  get() {
    return this.#isDisplayLocalGov
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#isDisplayLocalGov)
  }
}
