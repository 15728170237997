import 'interfaces/css/SampleGuideline/platform-dependent-characters.css';
import No01 from 'interfaces/assets/image/machine-dependent/no-01.png';
import No02 from 'interfaces/assets/image/machine-dependent/no-02.png';
import No03 from 'interfaces/assets/image/machine-dependent/no-03.png';
import No04 from 'interfaces/assets/image/machine-dependent/no-04.png';
import No05 from 'interfaces/assets/image/machine-dependent/no-05.png';
import No06 from 'interfaces/assets/image/machine-dependent/no-06.png';
import No07 from 'interfaces/assets/image/machine-dependent/no-07.png';
import No08 from 'interfaces/assets/image/machine-dependent/no-08.png';
import No09 from 'interfaces/assets/image/machine-dependent/no-09.png';
import No10 from 'interfaces/assets/image/machine-dependent/no-10.png';
import No11 from 'interfaces/assets/image/machine-dependent/no-11.png';
import No12 from 'interfaces/assets/image/machine-dependent/no-12.png';
import No13 from 'interfaces/assets/image/machine-dependent/no-13.png';
import No14 from 'interfaces/assets/image/machine-dependent/no-14.png';
import No15 from 'interfaces/assets/image/machine-dependent/no-15.png';
import No16 from 'interfaces/assets/image/machine-dependent/no-16.png';
import No17 from 'interfaces/assets/image/machine-dependent/no-17.png';
import No18 from 'interfaces/assets/image/machine-dependent/no-18.png';
import No19 from 'interfaces/assets/image/machine-dependent/no-19.png';
import No20 from 'interfaces/assets/image/machine-dependent/no-20.png';
import Noab01 from 'interfaces/assets/image/machine-dependent/no-ab-01.png';
import Noab02 from 'interfaces/assets/image/machine-dependent/no-ab-02.png';
import Noab03 from 'interfaces/assets/image/machine-dependent/no-ab-03.png';
import Noab04 from 'interfaces/assets/image/machine-dependent/no-ab-04.png';
import Noab05 from 'interfaces/assets/image/machine-dependent/no-ab-05.png';
import Noab06 from 'interfaces/assets/image/machine-dependent/no-ab-06.png';
import Noab07 from 'interfaces/assets/image/machine-dependent/no-ab-07.png';
import Noab08 from 'interfaces/assets/image/machine-dependent/no-ab-08.png';
import Noab09 from 'interfaces/assets/image/machine-dependent/no-ab-09.png';
import Noab10 from 'interfaces/assets/image/machine-dependent/no-ab-10.png';
import Noas01 from 'interfaces/assets/image/machine-dependent/no-as-01.png';
import Noas02 from 'interfaces/assets/image/machine-dependent/no-as-02.png';
import Noas03 from 'interfaces/assets/image/machine-dependent/no-as-03.png';
import Noas04 from 'interfaces/assets/image/machine-dependent/no-as-04.png';
import Noas05 from 'interfaces/assets/image/machine-dependent/no-as-05.png';
import Noas06 from 'interfaces/assets/image/machine-dependent/no-as-06.png';
import Noas07 from 'interfaces/assets/image/machine-dependent/no-as-07.png';
import Noas08 from 'interfaces/assets/image/machine-dependent/no-as-08.png';
import Noas09 from 'interfaces/assets/image/machine-dependent/no-as-09.png';
import Noas10 from 'interfaces/assets/image/machine-dependent/no-as-10.png';
import Mmja from 'interfaces/assets/image/machine-dependent/mm-ja.png';
import Kgja from 'interfaces/assets/image/machine-dependent/kg-ja.png';
import Cmja from 'interfaces/assets/image/machine-dependent/cm-ja.png';
import Mja from 'interfaces/assets/image/machine-dependent/m-ja.png';
import Gja from 'interfaces/assets/image/machine-dependent/g-ja.png';
import Tja from 'interfaces/assets/image/machine-dependent/t-ja.png';
import Rja from 'interfaces/assets/image/machine-dependent/r-ja.png';
import Haja from 'interfaces/assets/image/machine-dependent/ha-ja.png';
import Lja from 'interfaces/assets/image/machine-dependent/l-ja.png';
import Wja from 'interfaces/assets/image/machine-dependent/w-ja.png';
import Calja from 'interfaces/assets/image/machine-dependent/cal-ja.png';
import Dollja from 'interfaces/assets/image/machine-dependent/doll-ja.png';
import Centja from 'interfaces/assets/image/machine-dependent/cent-ja.png';
import Percentja from 'interfaces/assets/image/machine-dependent/percent-ja.png';
import Mbja from 'interfaces/assets/image/machine-dependent/mb-ja.png';
import Page from 'interfaces/assets/image/machine-dependent/page.png';
import Mm from 'interfaces/assets/image/machine-dependent/mm.png';
import Cm from 'interfaces/assets/image/machine-dependent/cm.png';
import Km from 'interfaces/assets/image/machine-dependent/km.png';
import Mg from 'interfaces/assets/image/machine-dependent/mg.png';
import Kg from 'interfaces/assets/image/machine-dependent/kg.png';
import Cc from 'interfaces/assets/image/machine-dependent/cc.png';
import M2 from 'interfaces/assets/image/machine-dependent/m2.png';
import No from 'interfaces/assets/image/machine-dependent/no.png';
import Kk from 'interfaces/assets/image/machine-dependent/kk.png';
import Tel from 'interfaces/assets/image/machine-dependent/tel.png';
import Top from 'interfaces/assets/image/machine-dependent/top.png';
import Middle from 'interfaces/assets/image/machine-dependent/middle.png';
import Bottom from 'interfaces/assets/image/machine-dependent/bottom.png';
import Left from 'interfaces/assets/image/machine-dependent/left.png';
import Right from 'interfaces/assets/image/machine-dependent/right.png';
import Meiji from 'interfaces/assets/image/machine-dependent/meiji.png';
import Taisho from 'interfaces/assets/image/machine-dependent/taisho.png';
import Showa from 'interfaces/assets/image/machine-dependent/showa.png';
import Heisei from 'interfaces/assets/image/machine-dependent/heisei.png';
import Kabu from 'interfaces/assets/image/machine-dependent/kabu.png';
import Yu from 'interfaces/assets/image/machine-dependent/yu.png';
import Dai from 'interfaces/assets/image/machine-dependent/dai.png';
import Symbol01 from 'interfaces/assets/image/machine-dependent/symbol-01.png';
import Symbol02 from 'interfaces/assets/image/machine-dependent/symbol-02.png';
import Symbol03 from 'interfaces/assets/image/machine-dependent/symbol-03.png';
import Symbol04 from 'interfaces/assets/image/machine-dependent/symbol-04.png';
import Symbol05 from 'interfaces/assets/image/machine-dependent/symbol-05.png';
import Symbol06 from 'interfaces/assets/image/machine-dependent/symbol-06.png';
import Symbol07 from 'interfaces/assets/image/machine-dependent/symbol-07.png';
import Symbol08 from 'interfaces/assets/image/machine-dependent/symbol-08.png';
import Symbol09 from 'interfaces/assets/image/machine-dependent/symbol-09.png';
import Arrowleft from 'interfaces/assets/image/machine-dependent/arrow-left.png';
import Arrowboth from 'interfaces/assets/image/machine-dependent/arrow-both.png';
import Arrowright from 'interfaces/assets/image/machine-dependent/arrow-right.png';
import Symbol10 from 'interfaces/assets/image/machine-dependent/symbol-10.png';
import Symbol11 from 'interfaces/assets/image/machine-dependent/symbol-11.png';
import Symbol12 from 'interfaces/assets/image/machine-dependent/symbol-12.png';
import Symbol13 from 'interfaces/assets/image/machine-dependent/symbol-13.png';
import Symbol14 from 'interfaces/assets/image/machine-dependent/symbol-14.png';
import Symbol15 from 'interfaces/assets/image/machine-dependent/symbol-15.png';
import Symbol16 from 'interfaces/assets/image/machine-dependent/symbol-16.png';
import Symbol17 from 'interfaces/assets/image/machine-dependent/symbol-17.png';
import Symbol18 from 'interfaces/assets/image/machine-dependent/symbol-18.png';

const machineDependentImages = [
  No01,
  No02,
  No03,
  No04,
  No05,
  No06,
  No07,
  No08,
  No09,
  No10,
  No11,
  No12,
  No13,
  No14,
  No15,
  No16,
  No17,
  No18,
  No19,
  No20,
  Noab01,
  Noab02,
  Noab03,
  Noab04,
  Noab05,
  Noab06,
  Noab07,
  Noab08,
  Noab09,
  Noab10,
  Noas01,
  Noas02,
  Noas03,
  Noas04,
  Noas05,
  Noas06,
  Noas07,
  Noas08,
  Noas09,
  Noas10,
  Mmja,
  Kgja,
  Cmja,
  Mja,
  Gja,
  Tja,
  Rja,
  Haja,
  Lja,
  Wja,
  Calja,
  Dollja,
  Centja,
  Percentja,
  Mbja,
  Page,
  Mm,
  Cm,
  Km,
  Mg,
  Kg,
  Cc,
  M2,
  No,
  Kk,
  Tel,
  Top,
  Middle,
  Bottom,
  Left,
  Right,
  Meiji,
  Taisho,
  Showa,
  Heisei,
  Kabu,
  Yu,
  Dai,
  Symbol01,
  Symbol02,
  Symbol03,
  Symbol04,
  Symbol05,
  Symbol06,
  Symbol07,
  Symbol08,
  Symbol09,
  Arrowleft,
  Arrowboth,
  Arrowright,
  Symbol10,
  Symbol11,
  Symbol12,
  Symbol13,
  Symbol14,
  Symbol15,
  Symbol16,
  Symbol17,
  Symbol18,
];

const PlatformDependentCharacters = () => {
  return (
    <div
      data-testid="platform-dependent-characters"
      className="platform-dependent-characters pt-4">
      <div className="font-weight-bold pb-4">〔機種依存文字の一例〕</div>
      {machineDependentImages.map((imageName, index) => (
        <>
          <span
            key={imageName}
            className="platform-dependent-characters-image-wrap d-inline">
            <img src={imageName} alt={imageName} />
            <span>、</span>
          </span>
          {(index + 1) % 24 === 0 && <br />}
        </>
      ))}
      など
    </div>
  );
};
export default PlatformDependentCharacters;
