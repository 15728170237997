import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle.jsx';
import BasicInfoTable from 'interfaces/components/Property/service/PropertyDetail/BasicInfo/BasicInfoTable.jsx';

const ParticipantInformationTable = ({ data, isRepresentative = 'FALSE' }) => {
  return (
    <div data-testid="participant-information-table">
      <ArticleDefaultTitle
        title={`${isRepresentative === 'TRUE' ? '委任者' : '参加者'}情報`}
      />
      <ArticleDefaultBody>
        <BasicInfoTable title="" data={data} />
      </ArticleDefaultBody>
    </div>
  );
};

export default ParticipantInformationTable;
