import Pagination from 'interfaces/components/common/Pagination.jsx';
import KmanagerTableEmptyRecord from 'interfaces/components/common/Table/KmanagerTableEmptyRecord';

import 'interfaces/css/common/kmanager-table.css';
import 'interfaces/css/common/kmanager-table-th.css';
import { useSortableData } from 'shared/hooks/useSort.js';
import { SortableTh } from 'interfaces/components/common/Table/Th/SortableTh';
import usePagination from 'shared/hooks/usePagination';
import { useState } from 'react';
import {
  ProcedureTypes,
  GovernmentTypes,
} from 'shared/utils/constants/government';
import styled from 'styled-components';

const Enphasis = styled.div`
  font-weight: bold;
  color: #f44336 !important;
`;

const MessageTable = ({ auction, governmentSettings }) => {
  const headers = [
    { id: 1, column: 'region', text: '地域' },
    { id: 2, column: 'location', text: '都道府県' },
    { id: 3, column: 'name', text: '行政機関名' },
    { id: 4, column: 'propertyCount', text: '物件登録数' },
    { id: 5, column: 'topPage', text: '自治体トップページ' },
    { id: 6, column: 'creditCard', text: 'クレジットカード\n仮申込完了画面' },
    { id: 7, column: 'bank', text: '銀行振込など\n仮申込完了画面' },
    { id: 8, column: 'guideline', text: 'ガイドライン' },
    { id: 9, column: 'caution', text: '落札後の注意事項' },
    ...(auction.governmentTypeGroup !==
    GovernmentTypes.NATIONAL_TAX_AGENCY.string
      ? [
          {
            id: 10,
            column: 'systemFeeInvoice',
            text: 'システム利用料\n請求書送付先',
          },
          { id: 11, column: 'certDocument', text: '認証書面\n送付先' },
        ]
      : []),
    { id: 12, column: 'emblem', text: '公章' },
    { id: 13, column: 'isMatchDeposit', text: '保証金納付方法\n不整合' },
    ...(auction.procedureType !== ProcedureTypes.ASSET_SALE.string &&
    auction.governmentTypeGroup !== GovernmentTypes.NATIONAL_TAX_AGENCY.string
      ? [
          {
            id: 14,
            column: 'depositAccount',
            text: '保証金納付口座\n返還請求書送付先',
          },
        ]
      : []),

    { id: 15, column: 'division', text: '物件執行機関' },
  ];

  const {
    items: sortedGovernmentSettings,
    requestSort,
    sortConfig,
  } = useSortableData(governmentSettings);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(sortedGovernmentSettings, rowsPerPage);

  return (
    <div className="kmanager-auction-operation">
      <div className="kmanager-table-wrap">
        <table
          className="kmanager-table kmanager-table-hover"
          data-testid="kmanager-table">
          <thead data-testid="kmanager-table-column">
            <tr data-testid="kmanager-tr">
              {headers.map((header) => {
                return (
                  <SortableTh
                    key={header.id}
                    header={header}
                    onClick={() => requestSort(header.column)}
                    sortConfig={sortConfig}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((setting, index) => {
                return (
                  <tr
                    key={index}
                    className="kmanager-table-record"
                    data-testid="kmanager-table-record">
                    <td data-testid="kmanager-table-td">
                      <div>
                        {setting.region === undefined
                          ? '未設定'
                          : setting.region}
                      </div>
                    </td>
                    <td>
                      <div>{setting.location}</div>
                    </td>
                    <td>
                      <div>{setting.name}</div>
                    </td>
                    <td>
                      <div>{setting.propertyCount}</div>
                    </td>
                    <td>
                      <Enphasis>
                        {setting.topPage === 0 ? '未登録' : ''}
                      </Enphasis>
                    </td>
                    <td>
                      <Enphasis>
                        {auction.procedureType ===
                        ProcedureTypes.PUBLIC_SALE.string
                          ? ''
                          : setting.creditCard === 0
                            ? '未登録'
                            : ''}
                      </Enphasis>
                    </td>
                    <td>
                      <Enphasis>{setting.bank === 0 ? '未登録' : ''}</Enphasis>
                    </td>
                    <td>
                      <Enphasis>
                        {setting.guideline === 0 ? '未登録' : ''}
                      </Enphasis>
                    </td>
                    <td>
                      <Enphasis>
                        {setting.caution === 0 ? '未登録' : ''}
                      </Enphasis>
                    </td>
                    {auction.governmentTypeGroup !==
                      GovernmentTypes.NATIONAL_TAX_AGENCY.string && (
                      <>
                        <td>
                          <Enphasis>
                            {setting.systemFeeInvoice === 0 ? '未登録' : ''}
                          </Enphasis>
                        </td>
                        <td>
                          <Enphasis>
                            {setting.certDocument === 0 ? '未登録' : ''}
                          </Enphasis>
                        </td>
                      </>
                    )}
                    <td>
                      <Enphasis>
                        {setting.emblem === 0 ? '未登録' : ''}
                      </Enphasis>
                    </td>
                    <td>
                      <Enphasis>
                        {setting.isMatchDeposit === 0 ? '未登録' : ''}
                      </Enphasis>
                    </td>
                    {auction.procedureType !==
                      ProcedureTypes.ASSET_SALE.string &&
                      auction.governmentTypeGroup !==
                        GovernmentTypes.NATIONAL_TAX_AGENCY.string && (
                        <td>
                          <Enphasis>
                            {setting.division === 0 ? '未登録' : ''}
                          </Enphasis>
                        </td>
                      )}
                    <td>
                      <Enphasis>
                        {setting.division === 0 ? '未登録' : ''}
                      </Enphasis>
                    </td>
                  </tr>
                );
              })
            ) : (
              <KmanagerTableEmptyRecord />
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        DATA_COUNT={governmentSettings.length}
      />
    </div>
  );
};

export default MessageTable;
