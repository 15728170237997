import { validateRangeLength, removeBlankSymbols } from "../../../../application/validations.js";

export class Tel {
  #tel

  constructor(tel) {
    this.#tel = tel;
  }

  get() {
    return this.#tel
  }

  get isValid() {
    const result = [
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
    return result
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.removeBlankSymbols, 10, 11)
  }

  // バリデーションルールの適用
  get removeBlankSymbols() {
    return removeBlankSymbols(this.#tel)
  }
}
