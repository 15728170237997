
export class Position {
    #position

    constructor(position) {
        this.#position = position;
    }

    get() {
        return this.#position
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
