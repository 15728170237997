import { ReactComponent as DetailListIcon } from 'interfaces/assets/svg/detail_list_icon.svg';
import { ReactComponent as TilesIcon } from 'interfaces/assets/svg/tiles_icon.svg';
import 'interfaces/css/PropertyList/display-toggle.css';

const DisplayToggle = ({ isImageList, setIsImageList }) => {
  return (
    <div
      className="search-display-toggle d-inline-flex mb-4 item-group btn-toggle"
      data-testid="search-display-toggle">
      <button
        onClick={() => setIsImageList(!isImageList)}
        type="button"
        value="image"
        className={
          isImageList
            ? 'search-display-toggle-button search-display-toggle-button--active'
            : 'search-display-toggle-button'
        }
        disabled={isImageList}>
        <span className="search-display-toggle-button-content flex align-center">
          <div className="flex align-center">
            <TilesIcon />
            <span className="text-caption">画像一覧</span>
          </div>
        </span>
      </button>
      <button
        onClick={() => setIsImageList(!isImageList)}
        type="button"
        value="detail"
        className={
          !isImageList
            ? 'search-display-toggle-button search-display-toggle-button--active'
            : 'search-display-toggle-button'
        }
        disabled={!isImageList}>
        <span className="search-display-toggle-button-content flex align-center">
          <div className="flex align-center">
            <DetailListIcon />
            <span className="text-caption">詳細表示</span>
          </div>
        </span>
      </button>
    </div>
  );
};
export default DisplayToggle;
