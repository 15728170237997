export const radio_label = [
  { id: 1, value: 'NATIONAL_TAX_AGENCY', label: '国税' },
  { id: 2, value: 'LOCAL_GOVERNMENT', label: '地方公共団体など' },
  { id: 3, value: 'NATIONAL_AGENCY', label: '国の機関' },
];

export const government_type_groups = [
  { id: 1, value: 'NATIONAL_TAX_AGENCY', label: '国税' },
  { id: 2, value: 'LOCAL_GOVERNMENT', label: '地方公共団体など' },
  { id: 3, value: 'NATIONAL_AGENCY', label: '国の機関' },
];

export const government_types = [
  { id: 1, value: 'NATIONAL_TAX_AGENCY', label: '国税庁' },
  { id: 2, value: 'NATIONAL_ASSET', label: '国_公有財産売却' },
  { id: 3, value: 'PREFECTURE', label: '都道府県' },
  { id: 4, value: 'MUNICIPALITY', label: '市区町村' },
  { id: 5, value: 'OTHER', label: 'その他の行政機関' },
];

export const procedure_types = [
  { id: 1, value: 'PUBLIC_SALE', label: '公売' },
  { id: 2, value: 'ASSET_SALE', label: '公有財産売却' },
];

export const gov_procedure_types = [
  { id: 1, value: 'PUBLIC_SALE', label: '公売' },
  { id: 2, value: 'ASSET_SALE', label: '公有財産売却' },
  { id: 3, value: 'BOTH', label: '両方' },
];

export const gov_procedure_types_for_national_tax_agency = [
  { id: 1, value: 'PUBLIC_SALE', label: '公売' },
];

export const public_sale_types = [
  { id: 1, value: 'AUCTION', label: 'せりのみ' },
  { id: 2, value: 'BID', label: '入札のみ' },
  { id: 3, value: 'BOTH', label: 'せりと入札' },
];

export const public_sale_types_for_national_tax_agency = [
  { id: 1, value: 'AUCTION', label: 'せりのみ' },
];

export const fee_types = [
  { id: 1, value: 'RATIO', label: '割合' },
  { id: 2, value: 'VALUE', label: '固定値' },
];

export const tax_types = [
  { id: 1, value: 'TOTAL_TAXATION', label: '合算一括課税' },
  { id: 2, value: 'INDIVIDUAL_TAXATION', label: '個別課税' },
  { id: 3, value: 'INDIVIDUAL_CONTRACT_TAXATION', label: '個別契約課税' },
];

export const use_emblems = [
  { id: 1, value: 'NONE', label: '未使用' },
  { id: 2, value: 'EMBLEM', label: '公章' },
  { id: 3, value: 'CHARACTER', label: 'キャラクター' },
  { id: 4, value: 'BOTH', label: '両方' },
];

export const auto_bid_end = [
  { id: 1, value: 'MANUAL', label: '行う' },
  { id: 2, value: 'AUTO', label: '行わない' },
];

export const mail_cert_document = [
  { id: 1, value: 'MAIL', label: '希望する' },
  { id: 2, value: 'NO_MAIL', label: '希望しない' },
];

export const display_apl_count = [
  { id: 1, value: 'DISPLAY', label: '表示する' },
  { id: 2, value: 'HIDDEN', label: '表示しない' },
];

export const display_target = [
  { id: 1, value: 'SAME_WINDOW', label: '同一ウインドウ' },
  { id: 2, value: 'NEW_WINDOW', label: '新規ウインドウ' },
];
export const display_active = [
  { id: 1, value: 'NO_ACTIVE', label: 'ノンアクティブ' },
  { id: 2, value: 'ACTIVE', label: 'アクティブ' },
];
export const display_deadline = [
  { id: 1, value: 'HIDDEN', label: '設定しない' },
  { id: 2, value: 'DISPLAY', label: '設定する' },
];

export const get_radio_label = (dataSet, key, condition) => {
  return dataSet.filter((data) => data[key] === condition)[0].label;
};
export const get_radio_value = (dataSet, key, condition) => {
  return dataSet.find((data) => data[key] === condition)?.value;
};

export const is_admin = [
  { id: 1, value: 'ADMIN', label: '管理職' },
  { id: 2, value: 'GENERAL', label: '一般' },
];

// 以降、旧 react/src/navi/helper/radio_label.js よりコピー
// export const radio_label = [
//   {id: 1, text: "下書き", value: "TRUE"},
//   {id: 2, text: "公開", value: "FALSE"},
// ]
export const radio_label2 = [
  { id: 1, text: 'せり売り形式', value: 'AUCTION' },
  { id: 2, text: '入札形式', value: 'BID' },
];
export const radio_label3 = [
  { id: 1, text: '必要', value: 'TRUE' },
  { id: 2, text: '不要', value: 'FALSE' },
];
export const radio_label4 = [
  { id: 1, text: '発行可能' },
  { id: 2, text: '発行不可' },
];
export const car_radio_label1 = [
  { id: 1, text: '自家用', value: 'PRIVATE' },
  { id: 2, text: '事業用', value: 'BUSINESS' },
  { id: 3, text: '不明', value: 'UNKNOWN' },
];
export const car_radio_label2 = [
  { id: 1, text: 'あり' },
  { id: 2, text: '期限切れ' },
];
export const car_radio_label3 = [
  { id: 1, text: 'マニュアル' },
  { id: 2, text: 'オートマチック' },
];
export const car_radio_label4 = [
  { id: 1, text: '右ハンドル' },
  { id: 2, text: '左ハンドル' },
];
export const car_radio_label5 = [
  { id: 1, text: '記録簿なし' },
  { id: 2, text: '記録簿あり' },
];
export const estate_radio_label1 = [
  { id: 1, text: '防火' },
  { id: 2, text: '準防火' },
  { id: 3, text: '不明' },
];
export const estate_radio_label2 = [
  { id: 1, text: '登録簿' },
  { id: 2, text: '実測' },
  { id: 3, text: '不明' },
];
export const local_government_management_radio1 = [
  { id: 1, text: '表示する', value: 'DISPLAY' },
  { id: 2, text: '表示しない', value: 'HIDDEN' },
];
export const local_government_management_radio2 = [
  { id: 1, text: '使用する' },
  { id: 2, text: '使用しない' },
];
export const new_entry_radio1 = [
  { id: 1, text: '行う', value: 'AUTO' },
  { id: 2, text: '行わない', value: 'MANUAL' },
];
export const new_entry_radio2 = [
  { id: 1, text: '希望する', value: 'MAIL' },
  { id: 2, text: '希望しない', value: 'NO_MAIL' },
];
export const new_entry_radio3 = [
  { id: 1, text: '押印して郵送する', value: 'MAIL' },
  { id: 2, text: 'オンライン申請のみ', value: 'NO_MAIL' },
];
export const new_entry_radio4 = [
  { id: 1, text: '表示する', value: 'DISPLAY' },
  { id: 2, text: '表示しない', value: 'HIDDEN' },
];
export const new_entry_radio5 = [
  { id: 1, text: '表示する', value: 'DISPLAY' },
  { id: 2, text: '表示しない', value: 'HIDDEN' },
];
export const new_entry_radio6 = [
  { id: 1, text: '表示する', value: 'DISPLAY' },
  { id: 2, text: '表示しない', value: 'HIDDEN' },
];
export const organization_register_radio = [
  { id: 1, text: '利用する' },
  { id: 2, text: '利用しない' },
];
export const navi_user_register_radio1 = [
  { id: 1, text: '親', value: 'PARENT' },
  { id: 2, text: '子', value: 'CHILD' },
];
export const navi_user_register_radio2 = [
  { id: 1, text: 'あり', value: 'AUTHORIZED' },
  { id: 2, text: 'なし', value: 'NO_AUTHORIZED' },
];
export const navi_user_register_radio3 = [
  { id: 1, text: '受信する', value: 'RECEIVE' },
  { id: 2, text: '受信しない', value: 'NO_RECEIVE' },
];
export const exhibit_radio = [
  { id: 1, text: '対象にする' },
  { id: 2, text: '対象にしない' },
];
export const procedure_pub = [
  { id: 1, text: 'クレジットカード、銀行振込など両方', value: 'BOTH' },
  { id: 2, text: 'クレジットカード', value: 'CREDIT_CARD' },
  { id: 3, text: '銀行振込など', value: 'BANK_TRANSFER' },
  { id: 4, text: '公売保証金なし', value: 'NONE' },
];
export const procedure_asset = [
  { id: 1, text: 'クレジットカード、銀行振込など両方', value: 'BOTH' },
  { id: 2, text: 'クレジットカード', value: 'CREDIT_CARD' },
  { id: 3, text: '銀行振込など', value: 'BANK_TRANSFER' },
  { id: 4, text: '入札保証金なし', value: 'NONE' },
];
