import { ReactComponent as DeleteIcon } from 'interfaces/assets/svg/delete.svg';
import { ReactComponent as PencilIcon } from 'interfaces/assets/svg/pencil.svg';
import Button from 'interfaces/components/common/Button';
import Confirm from 'interfaces/components/common/Confirm';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//import {useDeleteSubmit} from "shared/hooks/useSubmit";

import 'interfaces/css/common/kmanager-table-record.css';

const BASE_PATH = '/kmanager/gov_list';

const GovernmentListTableRecord = ({ data, headers, id, handler }) => {
  const navigation = useNavigate();

  const createUserUrl = (id, type) => {
    if (type === 'isAsset') {
      return `${BASE_PATH}/${id}/user_entry/AssetSale`;
    } else if (type === 'isPublic') {
      return `${BASE_PATH}/${id}/user_entry/PublicSale`;
    }
  };

  const createDetailUrl = (id) => {
    return `${BASE_PATH}/${id}`;
  };

  const KmanagerTableCellRegister = (header, id) => {
    return (
      <td data-testid="kmanager-table-td">
        <div className="d-flex justify-center">
          <Button
            className="kmanager-common-button small text-no-wrap primary"
            text="登録"
            isOutline={true}
            clickHandler={() => navigation(createUserUrl(id, header.column))}
          />
        </div>
      </td>
    );
  };

  const KmanagerTableCellData = (header, data) => {
    return (
      <td data-testid="kmanager-table-td">
        <div>{data[header.column]}</div>
      </td>
    );
  };

  const KmanagerTableCellDetail = (header, data) => {
    return (
      <td data-testid="kmanager-table-td">
        <div>
          <Link to={createDetailUrl(data.id)}>{data[header.column]}</Link>
        </div>
      </td>
    );
  };

  const deleteHandler = (id) => {
    console.log('delete');
    console.log(id);
  };

  const KmanagerTableCellEdit = (data) => {
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const navigation = useNavigate();
    const URL = `/kmanager/gov_list/${data.id}/edit`;
    //const submitHandler = useDeleteSubmit(data, () => setIsDeleteOpen(false));
    return (
      <td data-testid="kmanager-table-td">
        {/*
          <Confirm type="delete" isOpen={isDeleteOpen} closeModal={() => setIsDeleteOpen(false)} Submit={submitHandler}/>
        */}
        <Confirm
          type="delete"
          isOpen={isDeleteOpen}
          closeModal={() => setIsDeleteOpen(false)}
          Submit={() => {
            deleteHandler(data.id);
            setIsDeleteOpen(false);
          }}
        />
        <div className="d-flex justify-center">
          <Button
            className="kmanager-common-button small text-no-wrap edit"
            text={<PencilIcon />}
            clickHandler={() => navigation(URL, { state: { data: data } })}
          />
          <Button
            className="kmanager-common-button small text-no-wrap warning"
            text={<DeleteIcon />}
            clickHandler={() => setIsDeleteOpen(true)}
          />
        </div>
      </td>
    );
  };
  const KmanagerTableCell = ({ header, data, id, handler }) => {
    if (header.text === '編集/削除') {
      return KmanagerTableCellEdit(data, handler);
    }
    if (data[header.column] === '登録') {
      return KmanagerTableCellRegister(header, id);
    } else if (header.column === 'name') {
      return KmanagerTableCellDetail(header, data, id);
    } else {
      return KmanagerTableCellData(header, data);
    }
  };

  return (
    <tr className="kmanager-table-record" data-testid="kmanager-table-record">
      {headers.map((header) => {
        return (
          <KmanagerTableCell
            key={header.id}
            header={header}
            data={data}
            id={id}
            handler={handler}
          />
        );
      })}
    </tr>
  );
};

export default GovernmentListTableRecord;
