// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.anchor-text .btn-content {
  color: var(--anchor-base);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/components/Guidelines/GuidelineAgreement/css/horizontal-pair-links.css.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B","sourcesContent":[".anchor-text .btn-content {\n  color: var(--anchor-base);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
