import SingleOpenAccordion from 'interfaces/components/common/Accordion/SingleOpenAccordion.jsx';
import RadioInput from 'interfaces/components/common/Input/RadioInput.jsx';
import { memo } from 'react';

import DeliveryScheduleForm from './DeliveryScheduleForm.jsx';

export const deliveryScheduleStatus = [
  { id: 1, label: 'お届け日指定なし', value: 'NO_SPECIFY' },
  { id: 2, label: 'お届け日を指定する', value: 'SPECIFY' },
];

const SelectDeliveryScheduleInput = memo(function SelectDeliveryScheduleInput({
  deliverySchedule,
  setDeliverySchedule,
  paymentData,
  carrier,
}) {
  const handleClick = (item) => {
    setDeliverySchedule({ ...deliverySchedule, target: item.value });
  };

  const items = [
    {
      id: 1,
      title: (
        <RadioInput
          item={deliveryScheduleStatus[0]}
          label={deliveryScheduleStatus[0].label}
          value={deliverySchedule.target}
          handleClick={() => handleClick(deliveryScheduleStatus[0])}
          className="accordion-label-radio"
        />
      ),
      isInvisibleIcon: true,
    },
    {
      id: 2,
      title: (
        <RadioInput
          item={deliveryScheduleStatus[1]}
          label={deliveryScheduleStatus[1].label}
          value={deliverySchedule.target}
          handleClick={() => handleClick(deliveryScheduleStatus[1])}
          className="accordion-label-radio"
        />
      ),
      body: (
        <DeliveryScheduleForm
          deliverySchedule={deliverySchedule}
          setDeliverySchedule={setDeliverySchedule}
          paymentData={paymentData}
          carrier={carrier}
        />
      ),
    },
  ];

  return (
    <div>
      <SingleOpenAccordion
        items={items}
        className="shipping-single-open-accordion"
      />
      <div className="topic-input-caption">
        <p>【留意事項】</p>
        <p>
          1.
          配達希望日時は、納付予定日の5日後以降の日時から選択可能となります。ただし、公売財産の運送業者への引き渡しは、入力された配達希望日時にかかわらず、買受代金の納付確認後になります。
        </p>
        <p>
          2.
          「1.運送業者の選択」で「その他」を選択された場合は、希望する運送業者の配達可能日時あらかじめご確認のうえ、配達希望日時を選択してください。
        </p>
        <p>
          3.
          税務署等における公売物件の保管状況により配達準備に時間を要する場合や交通事情などのやむを得ない事情により、指定された配達希望日に配達できない場合があります。
        </p>
      </div>
    </div>
  );
});

export default SelectDeliveryScheduleInput;
