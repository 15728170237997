import { createContext } from 'react';

import { breadcrumbs } from "./breadcrumbs.js";
import { FORGET_PASSWORD, LOGIN, SIGN_UP } from "../../../../shared/utils/helper/authState.js";
import { DATA_EMPTY } from "../../../../shared/utils/helper/constants.js";

export const BlockListContext = createContext({
  // Default Value
  responsive: DATA_EMPTY,
  authState: {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD
  },
  breadcrumbs: breadcrumbs,
  governmentId: DATA_EMPTY,
  governmentType: DATA_EMPTY,
  procedureType: DATA_EMPTY,
  queryParams: DATA_EMPTY,
  blockList: DATA_EMPTY,
  setSearchParam: () => {},
});
