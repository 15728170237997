
export class SummaryCarCategories {
    #summaryCarCategories

    constructor(summaryCarCategories) {
        this.#summaryCarCategories = summaryCarCategories;
    }

    get() {
        return this.#summaryCarCategories
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
