
export class TransRightDateDescription {
  #transRightDateDescription

  constructor(transRightDateDescription) {
    this.#transRightDateDescription = transRightDateDescription;
  }

  get() {
    return this.#transRightDateDescription
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
