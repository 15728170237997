import styled from 'styled-components';

const InvalidMessage = styled.div`
    color: #ff5252 !important;
    caret-color: #ff5252 !important;
    font-size: 12px;
    min-width: 1px;
`

const ValidationMessage = ({invalidMessage}) => {
    if (invalidMessage !== "TRUE") {
        return <InvalidMessage>{invalidMessage}</InvalidMessage>
    }
}

export default ValidationMessage;
