
export class EstateBidSystemFee {
  #estateBidSystemFee

  constructor(estateBidSystemFee) {
    this.#estateBidSystemFee = estateBidSystemFee;
  }

  get() {
    return this.#estateBidSystemFee
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
