import Login from "../../../components/Login/kmanager/Login";

const KmanagerLogin = () => {
    return (
        <div data-testid="kmanager-auction-government">
            <Login/>
        </div>
    );
}

export default KmanagerLogin;