import { notInclude, validateRangeLength } from "../../../../application/validations.js";

export class HouseTel {
  #houseTel

  constructor(houseTel) {
    this.#houseTel = houseTel;
  }

  get() {
    return this.#houseTel
  }

  get isValid() {
    return [
      this.notIncludeHyphen,
      this.validateRangeLength,
      this.notIncludeSpace,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get notIncludeHyphen() {
    return notInclude(this.#houseTel, ['-'])
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#houseTel, 10, 11)
  }

  // バリデーションルールの適用
  get notIncludeSpace() {
    return notInclude(this.#houseTel, [' '])
  }
}
