import {
  APPLYING,
  AUCTION,
  BID,
  DATA_EMPTY,
  END_BIDDING,
  FINAL_APPLY,
  TEMPORARY_APPLY,
} from 'domain/Property/entities/constants/property.js';

import {
  depositFlagEnToJp,
  searchStatusEnToJp,
} from 'interfaces/components/Property/service/helper/generalSearchConditions';
import { queryParamToDepositPayment } from 'shared/utils/constants/mylist_search_conditions.js';
import { BOTH, IS_ONLINE } from 'shared/utils/constants/queryParam.js';
// 実施状況でのフィルタリング
export const filteringSearchStatus = (target, searchStatusArray) => {
  let tempResult = [];
  searchStatusArray.forEach((status) => {
    if (status !== DATA_EMPTY && status !== '') {
      tempResult.push(
        ...target.filter((item) => item.status === searchStatusEnToJp[status]),
      );
    }
  });
  return Array.from(new Set(tempResult));
};

// 保証金でのフィルタリング
export const filteringDepositFlag = (target, depositFlagArray) => {
  let tempResult = [];
  depositFlagArray.forEach((status) => {
    if (status !== DATA_EMPTY && status !== '') {
      tempResult.push(
        ...target.filter(
          (item) => item.depositPayment === depositFlagEnToJp[status],
        ),
      );
      if (status === IS_ONLINE) {
        tempResult.push(
          ...target.filter(
            (item) => item.depositPayment === depositFlagEnToJp[BOTH],
          ),
        );
      }
    }
  });
  return Array.from(new Set(tempResult));
};

// 最低金額でのフィルタリング
export const filteringSearchPriceLow = (target, searchPriceLow, bidInfo) => {
  return target.filter((item) => {
    const bidInfoObj = bidInfo.find((element) => element.id === item.id);
    if (
      bidInfoObj.data.biddedPrice !== 0 &&
      bidInfoObj.data.biddedPrice !== ''
    ) {
      return searchPriceLow <= bidInfoObj.data.biddedPrice;
    }
    if (
      bidInfoObj.data.currentTopPrice !== 0 &&
      bidInfoObj.data.currentTopPrice !== ''
    ) {
      return searchPriceLow <= bidInfoObj.data.currentTopPrice;
    }
    return searchPriceLow <= item.estimatePrice;
  });
};

// 最高金額でのフィルタリング
export const filteringSearchPriceHigh = (target, searchPriceHigh, bidInfo) => {
  return target.filter((item) => {
    const bidInfoObj = bidInfo.find((element) => element.id === item.id);
    if (
      bidInfoObj.data.biddedPrice !== 0 &&
      bidInfoObj.data.biddedPrice !== ''
    ) {
      return bidInfoObj.data.biddedPrice <= searchPriceHigh;
    }
    if (
      bidInfoObj.data.currentTopPrice !== 0 &&
      bidInfoObj.data.currentTopPrice !== ''
    ) {
      return bidInfoObj.data.currentTopPrice <= searchPriceHigh;
    }
    return item.estimatePrice <= searchPriceHigh;
  });
};

export const filteringPopUpInput = (
  target,
  searchKey,
  category,
  queryParamValue,
  valueToQueryParamTable,
) => {
  const obj = { ...valueToQueryParamTable };
  const key = Object.keys(obj).find((key) => obj[key] === queryParamValue);
  return target.filter(
    (item) => item['propertyType'] === category && item[searchKey] === key,
  );
};

// 初度登録年月用の変換
export const convertToISO8601 = (str) => {
  const yearMatch = str.match(/\((\d{4})年\)/);
  const monthMatch = str.match(/(\d+)月/);
  const year = yearMatch ? parseInt(yearMatch[1], 10) : null;
  const month = monthMatch ? parseInt(monthMatch[1], 10) - 1 : null;
  const day = 1;

  if (year !== null && month !== null) {
    const date = new Date(year, month, day);
    return date.toISOString();
  }
};

// 初度登録年月下限でのフィルタリング
export const filteringFirstRegistDateFrom = (target, firstRegistDateFrom) => {
  let converted;
  return target.filter((item) => {
    if (item.firstRegisterDate !== DATA_EMPTY) {
      converted = convertToISO8601(item.firstRegisterDate);
      return firstRegistDateFrom <= converted;
    }
  });
};

// 初度登録年月上限でのフィルタリング
export const filteringFirstRegistDateTo = (target, firstRegistDateTo) => {
  let converted;
  return target.filter((item) => {
    if (item.firstRegisterDate !== DATA_EMPTY) {
      converted = convertToISO8601(item.firstRegisterDate);
      return converted <= firstRegistDateTo;
    }
  });
};

// カテゴリでのフィルタリング
export const filteringCategory = (target, searchPropertyCategoryId) => {
  const searchPropertyCategoryIdArr = searchPropertyCategoryId?.split(',');
  return target.filter((item) =>
    searchPropertyCategoryIdArr.includes(item.categoryId),
  );
};

// マイリストの保証金でのフィルタリング
export const filteringMyListDeposit = (target, filterDeposit) => {
  let tempResult = [];
  const queryParamArray = filterDeposit?.split(',');
  queryParamArray?.forEach((status) => {
    tempResult.push(
      ...target.filter((property) => {
        return (
          property.data.depositPayment === queryParamToDepositPayment[status]
        );
      }),
    );
    if (status === 'NEED_DEPOSIT') {
      tempResult.push(
        ...target.filter((property) => {
          return (
            property.data.depositPayment === queryParamToDepositPayment['BOTH']
          );
        }),
      );
    }
  });
  return Array.from(new Set(tempResult));
};

// マイリストのオークション状態でのフィルタリング
export const filteringAuctionResultStatus = (
  target,
  filterAuctionResultStatus,
  bidInfo,
  membershipId,
) => {
  let tempResult = [];
  const queryParamArray = filterAuctionResultStatus?.split(',');
  queryParamArray?.forEach((status) => {
    if (status === 'SUCCESSFUL_BID') {
      tempResult.push(
        ...target.filter((property) => {
          const bidInfoObj = bidInfo.find(
            (element) => element.id === property.id,
          );
          return bidInfoObj?.data.biddedUser === membershipId;
        }),
      );
    }
    if (status === 'NEXT_HIGHEST_BID') {
      tempResult.push(
        ...target.filter((property) => {
          const bidInfoObj = bidInfo.find(
            (element) => element.id === property.id,
          );
          return bidInfoObj?.data.nextWinner === membershipId;
        }),
      );
    }
    if (status === 'ADDITIONAL_BID') {
      tempResult.push(
        ...target.filter((property) => {
          const bidInfoObj = bidInfo.find(
            (element) => element.id === property.id,
          );
          return bidInfoObj?.data.bidHistory.find(
            (element) =>
              element.bidUser === membershipId &&
              element.bidStatus === 'ADD_APPLICABLE',
          );
        }),
      );
    }
  });
  return Array.from(new Set(tempResult));
};

export const filteringAuctionProgressStatus = (
  target,
  filterAuctionProgressStatus,
  applicantPropertyIds,
  proposals,
  bidInfo,
  myUserInfo,
) => {
  let tempResult = [];
  const queryParamArray = filterAuctionProgressStatus?.split(',');

  if (queryParamArray.includes('BEFORE_APPLICATION')) {
    tempResult.push(
      ...target.filter((property) => {
        return isApplying(property, applicantPropertyIds);
      }),
    );
  }
  if (queryParamArray.includes('TEMPORARY_APPLICATION')) {
    tempResult.push(
      ...target.filter((property) => {
        return isProvisionalApplication(property, proposals);
      }),
    );
  }
  if (queryParamArray.includes('AFTER_APPLICATION')) {
    tempResult.push(
      ...target.filter((property) => {
        return isMainApplication(property, proposals);
      }),
    );
  }
  if (queryParamArray.includes('BEFORE_BIDDING')) {
    tempResult.push(
      ...target.filter((property) => {
        return isBeforeBidding(property, proposals, bidInfo, myUserInfo);
      }),
    );
  }
  if (queryParamArray.includes('BEFORE_BIDDING')) {
    tempResult.push(
      ...target.filter((property) => {
        return isBeforeBidding(property, proposals, bidInfo, myUserInfo);
      }),
    );
  }
  if (queryParamArray.includes('CURRENT_BIDDING')) {
    tempResult.push(
      ...target.filter((property) => {
        return isBidding(property, proposals, bidInfo, myUserInfo);
      }),
    );
  }
  if (queryParamArray.includes('AFTER_BIDDING')) {
    tempResult.push(
      ...target.filter((property) => {
        return isCompletionBidding(property, proposals, bidInfo, myUserInfo);
      }),
    );
  }
  if (queryParamArray.includes('AFTER_RESULT')) {
    tempResult.push(
      ...target.filter((property) => {
        return isResultBidding(property);
      }),
    );
  }

  return Array.from(new Set(tempResult));
};

// マイリストのフィルタリング（申し込み可能）
export const isApplying = (property, applicantPropertyIds) => {
  return (
    property.data.status === APPLYING &&
    applicantPropertyIds.find((id) => id === property.id) === undefined
  );
};

// マイリストのフィルタリング（仮申込状態）
export const isProvisionalApplication = (property, proposals) => {
  const provisionalApplicationProposals = proposals.filter(
    (proposal) => proposal.aplStatus === TEMPORARY_APPLY,
  );
  return !!provisionalApplicationProposals.find(
    (proposals) => proposals.data.id === property.id,
  );
};

// マイリストのフィルタリング（本申込状態）
export const isMainApplication = (property, proposals) => {
  const provisionalApplicationProposals = proposals.filter(
    (proposal) => proposal.aplStatus === FINAL_APPLY,
  );
  return !!provisionalApplicationProposals.find(
    (proposals) => proposals.id === property.id,
  );
};

// マイリストのフィルタリング（入札前）
export const isBeforeBidding = (property, proposals, bidInfo, myUserInfo) => {
  const applyingForParticipationProperty = proposals.filter(
    (proposal) => property.id === proposal.id,
  );
  if (applyingForParticipationProperty.length === 0) {
    return false;
  }
  const relevantBidInfo = bidInfo.find(
    (bidInfo) => bidInfo.id === applyingForParticipationProperty[0].id,
  );
  return !relevantBidInfo.data.bidHistory.find(
    (bidInfo) => bidInfo.bidUser === myUserInfo.info.membershipId,
  );
};

// マイリストのフィルタリング（入札中）
export const isBidding = (property, proposals, bidInfo, myUserInfo) => {
  if (property.data.publicSaleType !== AUCTION) {
    return false;
  }
  const applyingForParticipationProperty = proposals.filter(
    (proposal) => property.id === proposal.id,
  );
  if (applyingForParticipationProperty.length === 0) {
    return false;
  }
  const relevantBidInfo = bidInfo.find(
    (bidInfo) => bidInfo.id === applyingForParticipationProperty[0].id,
  );
  return !!relevantBidInfo.data.bidHistory.find(
    (bidInfo) => bidInfo.bidUser === myUserInfo.info.membershipId,
  );
};

// マイリストのフィルタリング（入札完了）
export const isCompletionBidding = (
  property,
  proposals,
  bidInfo,
  myUserInfo,
) => {
  if (property.data.publicSaleType !== BID) {
    return false;
  }
  const applyingForParticipationProperty = proposals.filter(
    (proposal) => property.id === proposal.id,
  );
  if (applyingForParticipationProperty.length === 0) {
    return false;
  }
  const relevantBidInfo = bidInfo.find(
    (bidInfo) => bidInfo.id === applyingForParticipationProperty[0].id,
  );
  return !!relevantBidInfo.data.bidHistory.find(
    (bidInfo) => bidInfo.bidUser === myUserInfo.info.membershipId,
  );
};

// マイリストのフィルタリング（開札結果）
export const isResultBidding = (property) => {
  return property.data.status === END_BIDDING;
};
