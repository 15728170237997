import { DATA_EMPTY } from 'shared/utils/helper/constants';

const isEmpty = [DATA_EMPTY, undefined, ''];

export const bidded_contact_info = (data) => [
  {
    text: '連絡先機関名',
    state: isEmpty.includes(data?.contactName) ? '未入力' : data?.contactName,
  },
  {
    text: '担当部署名',
    state: isEmpty.includes(data?.contactOffice)
      ? '未入力'
      : data?.contactOffice,
  },
  {
    text: 'メールアドレス',
    state: isEmpty.includes(data?.contactEmail) ? '未入力' : data?.contactEmail,
  },
  {
    text: '電話番号',
    state:
      data?.contactTel.telNum1 &&
      data?.contactTel.telNum2 &&
      data?.contactTel.telNum3
        ? `${data?.contactTel.telNum1}-${data?.contactTel.telNum2}-${data?.contactTel.telNum3}${data?.extension == 'DATA_EMPTY' ? '' : data?.contactExtension ? `  内線: ${data?.contactExtension}` : ''}`
        : '未入力',
  },
  {
    text: '電話受付時間',
    state: isEmpty.includes(data?.contactAccept)
      ? '未入力'
      : data?.contactAccept,
  },
];
