import { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const SuspensionImage = styled.div`
  height: 100%;
  width: 100%;
  color: var(--warning-base);
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoImage = styled.div`
  height: 100%;
  width: 100%;
  color: var(--text-base);
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PropertyImage = styled.img`
  max-width: 100%;
`;

const PropertyCardImage = ({ isSuspension, thumbnail }) => {
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true);
  };

  return (
    <Wrapper data-testid="property-card-image">
      {isSuspension ? (
        <SuspensionImage data-testid="suspension-image">中 止</SuspensionImage>
      ) : !thumbnail || hasError ? (
        <NoImage data-testid="no-image">画像はありません</NoImage>
      ) : (
        <PropertyImage
          src={thumbnail}
          alt="物件サムネイル"
          onError={handleError}
          data-testid="property-image"
        />
      )}
    </Wrapper>
  );
};

export default PropertyCardImage;
