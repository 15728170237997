// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.properties .category-btn-content {
  color: inherit;
  justify-content: inherit;
  flex: 1 0 auto;
  line-height: normal;
  position: relative;
}

.properties .category-btn {
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: .0892857143em;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: uppercase;
  -webkit-user-select: none;
          user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.properties .category-btn:not(.category-btn--round).category-btn-size--default {
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
}

.properties .category-btn--shadow {
  box-shadow: 0 0 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.1)!important;
}

.properties .category-btn--outlined {
  background-color: var(--white);
}

.properties .category-btn.category-btn--disabled {
  color: rgba(0,0,0,.26);
  pointer-events: none;
  box-shadow: none!important;
}

.properties .svg-wrapper--primary {
  fill: var(--primary-base);
}

.properties .svg-wrapper svg {
  width: 30px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Properties/category-button.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,wBAAwB;EACxB,cAAc;EACd,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,cAAc;EACd,gBAAgB;EAChB,6BAA6B;EAC7B,UAAU;EACV,kBAAkB;EAClB,qBAAqB;EACrB,2BAA2B;EAC3B,yBAAyB;EACzB,yBAAiB;UAAjB,iBAAiB;EACjB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yEAAyE;AAC3E;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb","sourcesContent":[".properties .category-btn-content {\n  color: inherit;\n  justify-content: inherit;\n  flex: 1 0 auto;\n  line-height: normal;\n  position: relative;\n}\n\n.properties .category-btn {\n  border-radius: 4px;\n  display: inline-flex;\n  flex: 0 0 auto;\n  font-weight: 500;\n  letter-spacing: .0892857143em;\n  outline: 0;\n  position: relative;\n  text-decoration: none;\n  text-indent: 0.0892857143em;\n  text-transform: uppercase;\n  user-select: none;\n  vertical-align: middle;\n  white-space: nowrap;\n}\n\n.properties .category-btn:not(.category-btn--round).category-btn-size--default {\n  height: 36px;\n  min-width: 64px;\n  padding: 0 16px;\n}\n\n.properties .category-btn--shadow {\n  box-shadow: 0 0 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.1)!important;\n}\n\n.properties .category-btn--outlined {\n  background-color: var(--white);\n}\n\n.properties .category-btn.category-btn--disabled {\n  color: rgba(0,0,0,.26);\n  pointer-events: none;\n  box-shadow: none!important;\n}\n\n.properties .svg-wrapper--primary {\n  fill: var(--primary-base);\n}\n\n.properties .svg-wrapper svg {\n  width: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
