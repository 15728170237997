import { findById as findAuction } from 'domain/Auction/services/Auction';
import { findByGovernmentId as getDivisions } from 'domain/Division/services/Division';
import { findById as findGovernment } from 'domain/Government/services/Government';
import { findByAuctionIdAndGovernmentId as getProperties } from 'domain/Property/services/Property';
import { findByPropertyId as findProposals } from 'domain/Applicant/services/Proposal';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FORGET_PASSWORD, LOGIN, SIGN_UP } from 'shared/utils/helper/authState';
import { KMANAGER } from 'shared/utils/helper/constants';
import { isEmpty } from 'shared/utils/constants/emptyData';
import { GovernmentDepositContext } from '../entities/GovernmentDepositContext';

const GovernmentDepositProvider = ({ type, children }) => {
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [auction, setAuction] = useState({});
  const [government, setGovernment] = useState({});
  const [properties, setProperties] = useState([]);

  const { auctionId, governmentId } = useParams();

  if (type === KMANAGER) {
    useEffect(() => {
      findGovernment(type, governmentId).then((result) => {
        setGovernment(result);
      });
    }, [governmentId]);
    useEffect(() => {
      findAuction(type, auctionId).then((result) => {
        setAuction(result);
      });
    }, [auctionId]);
    useEffect(() => {
      getProperties(type, auctionId, governmentId).then((resultProperties) => {
        getDivisions(type, governmentId).then((resultDivisions) => {
          let deposits = [];
          resultProperties.forEach((property) => {
            let deposit = property;
            let division = resultDivisions.find(
              (division) =>
                division.id.toString() === property.divisionId.toString(),
            );
            deposit.divisionName = division?.name || '';
            deposit.deposit = isEmpty(property.deposit)
              ? 0
              : Number(property.deposit);
            findProposals(property.id).then((proposals) => {
              deposit.paymentOnlineCount = proposals.filter(
                (proposal) => proposal.aplType === 'ONLINE',
              ).length;
            });
            deposit.paymentScheduledPrice = isEmpty(
              property.paymentScheduledPrice,
            )
              ? 0
              : Number(property.paymentScheduledPrice);
            deposit.restitutionScheduledPrice = isEmpty(
              property.restitutionScheduledPrice,
            )
              ? 0
              : Number(property.restitutionScheduledPrice);
            deposits.push(deposit);
          });
          setProperties(deposits);
        });
      });
    }, [governmentId, auctionId]);
  }

  return (
    <GovernmentDepositContext.Provider
      value={{
        authState,
        auction,
        government,
        properties,
      }}>
      {children}
    </GovernmentDepositContext.Provider>
  );
};

export default GovernmentDepositProvider;
//  const [deposits, setDeposits] = useState([]);
//    useEffect(() => {
//      getDeposits(type).then(result => {
//        setDeposits(result);
//      });
//    }, []);
//  const [divisions, setDivisions] = useState([]);
//    useEffect(() => {
//      getDivisions(type).then(result => {
//        setDivisions(result);
//      });
//    }, []);
