const ReceptionCompletionBidsMessage = ({property_farmat,email}) => {
    return (
        <div data-testid="reception-completion-bids-message" className="flex justify-center">
            <div className="my-10 text-center">
                <p className="mb-5"><span className="warning-text">下記の{property_farmat}物件への入札が完了しました。</span></p>
                <p className="mb-5">入力内容の確認メールを、入札時の登録メールアドレス({email}) 宛にお送りしています。</p>
            </div>
        </div>
    )
}
export default ReceptionCompletionBidsMessage