import { ReactComponent as SearchIcon } from 'interfaces/assets/svg/search_icon.svg';
import 'interfaces/css/common/search-input.css';
const SearchInput = ({ onChange }) => {
  return (
    <div data-testid="search-input" className="search-input">
      <div>
        <input
          type="text"
          placeholder="Search"
          onChange={(e) => {
            onChange(e);
          }}
        />
        <SearchIcon />
      </div>
    </div>
  );
};

export default SearchInput;
