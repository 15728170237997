import Button from 'interfaces/components/common/Button/Button.jsx';
import { useNavigate } from 'react-router-dom';

import ExhibitingGovernmentInfos from './ExhibitingGovernmentInfos.jsx';
import ShippingInfos from '../ConfirmShippingInfo/ShippingInfos.jsx';
import PropertyInfos from '../PointsToNoteAgreement/PropertyInfos.jsx';
import './css/shipping-info-registration-complete.css';

const ShippingInfoRegistrationComplete = ({ data, shippingInfo, step }) => {
  const navigation = useNavigate();

  return (
    <div
      className="shipping-info-registration-complete"
      data-testid="shipping-info-registration-complete">
      <div data-testid="complete-note">
        <p className="important text-center mt-7">
          引渡方法の登録が完了しました。
        </p>
        <p className="text-center mt-4 mb-10">
          買受代金の納付方法や権利移転手続きなどについての説明を受けていない場合は、以下の出品機関へ連絡をしてください
        </p>
      </div>
      <ExhibitingGovernmentInfos info={data} />
      <PropertyInfos info={data} step={step} />
      <ShippingInfos shippingInfo={shippingInfo} step={step} />
      <div className="flex justify-center mt-12">
        <Button
          text="物件詳細に戻る"
          height="52px"
          width="200px"
          onClick={() => navigation(`/items/${data.data?.id}`)}
        />
      </div>
    </div>
  );
};

export default ShippingInfoRegistrationComplete;
