import userIdForm from 'interfaces/css/Login/user-id-form.module.css'

const UserIdForm = ({ state, setState }) => {
  return (
    <div className={userIdForm.user_id_form} data-testid="user-id-form">
      <div data-testid="user-id-label">ナビユーザーID</div>
      <input
          type="text"
          className={userIdForm.user_id_input}
          data-testid="user-id-input"
          value={state} onChange={(e => setState(e.target.value))}
      />
    </div>
  );
};

export default UserIdForm;
