const dayOfTheWeekConvertRule = {
  0: '日',
  1: '月',
  2: '火',
  3: '水',
  4: '木',
  5: '金',
  6: '土',
};

export const dateFormat = (date, format) => {
  const isDateType = date instanceof Date;
  const isTimestamp = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.?\d{0,6}$/.test(
    date,
  );
  const isTime = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(date);
  const isDate = /^\d{4}-\d{2}-\d{2}$/.test(date);

  if (!isDateType && !isTimestamp && !isTime && !isDate) {
    return '';
  }

  let target = new Date();
  if (isDateType) {
    target = date;
  }
  if (isTimestamp) {
    target = new Date(date);
  }

  if (isTime) {
    target = new Date(date);
  }
  if (isDate) {
    target = new Date(date);
  }

  if (format === 'MM-DD') {
    const month = (target.getMonth() + 1).toString().padStart(2, '0');
    const day = target.getDate().toString().padStart(2, '0');
    return `${month}月${day}日`;
  }
  if (format === 'MM-DD-hh') {
    const month = (target.getMonth() + 1).toString().padStart(2, '0');
    const day = target.getDate().toString().padStart(2, '0');
    const hours = target.getHours().toString().padStart(2, '0');
    return `${month}月${day}日${hours}時`;
  }
  if (format === 'YYYY-MM-DD') {
    const year = target.getFullYear();
    const month = (target.getMonth() + 1).toString().padStart(2, '0');
    const day = target.getDate().toString().padStart(2, '0');
    return `${year}年${month}月${day}日`;
  }
  if (format === 'Y-M-D') {
    const year = target.getFullYear();
    const month = (target.getMonth() + 1).toString().padStart(2, '0');
    const day = target.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  if (format === 'Y-M-D hh:mm') {
    const year = target.getFullYear();
    const month = (target.getMonth() + 1).toString().padStart(2, '0');
    const day = target.getDate().toString().padStart(2, '0');
    const hours = target.getHours().toString().padStart(2, '0');
    const minutes = target.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  if (format === 'HH:mm') {
    const hours = target.getHours().toString().padStart(2, '0');
    const minutes = target.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  if (format === 'YYYY-MM-DD-DAYOFTHEWEEK-hh') {
    const year = target.getFullYear();
    const month = (target.getMonth() + 1).toString().padStart(2, '0');
    const day = target.getDate().toString().padStart(2, '0');
    const dayOfTheWeek = target.getDay();
    const hours = target.getHours().toString().padStart(2, '0');
    return `${year}年${month}月${day}日 (${dayOfTheWeekConvertRule[dayOfTheWeek]}) ${hours}時`;
  }
  if (format === 'YYYY-MM-DD-DAYOFTHEWEEK-hh-mm') {
    const year = target.getFullYear();
    const month = (target.getMonth() + 1).toString().padStart(2, '0');
    const day = target.getDate().toString().padStart(2, '0');
    const dayOfTheWeek = target.getDay();
    const hours = target.getHours().toString().padStart(2, '0');
    const minutes = target.getMinutes().toString().padStart(2, '0');
    return `${year}年${month}月${day}日 (${dayOfTheWeekConvertRule[dayOfTheWeek]}) ${hours}:${minutes}`;
  }
  if (format === 'YYYY-MM-DD-hh-mm') {
    const year = target.getFullYear();
    const month = (target.getMonth() + 1).toString().padStart(2, '0');
    const day = target.getDate().toString().padStart(2, '0');
    const hours = target.getHours().toString().padStart(2, '0');
    const minutes = target.getMinutes().toString().padStart(2, '0');
    return `${year}年${month}月${day}日 ${hours}時${minutes}分`;
  }
  if (format === 'weekdayJS') {
    return `${dayOfTheWeekConvertRule[target.getDay()]}`;
  }
  const hours = target.getHours().toString().padStart(2, '0');
  const minutes = target.getMinutes().toString().padStart(2, '0');
  return `${target.getFullYear()}年${target.getMonth() + 1}月${target.getDate()}日${hours}時${minutes}分`;
};
