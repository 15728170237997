
export class SummaryEstateType {
    #summaryEstateType

    constructor(summaryEstateType) {
        this.#summaryEstateType = summaryEstateType;
    }

    get() {
        return this.#summaryEstateType
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
