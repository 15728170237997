import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel.jsx';

import ApplicationInfoSectionHeader from './ApplicationInfoSectionHeader.jsx';
import CreditCardInfoFormBody from './CreditCardInfoFormBody.jsx';

const CreditCardInfoForm = ({ creditCardInfo, handleChange }) => {
  return (
    <div data-testid="credit-card-info-form">
      <ApplicationInfoSectionHeader
        title="クレジットカード情報の入力"
        caption={
          <p>
            - クレジットカード会社に登録した情報を入力してください。（
            <RequiredLabel />
            は必須項目）
          </p>
        }
      />
      <CreditCardInfoFormBody
        creditCardInfo={creditCardInfo}
        handleChange={handleChange}
      />
    </div>
  );
};

export default CreditCardInfoForm;
