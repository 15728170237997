import { validateSelected } from "../../../../application/validations.js";

export class AddBidDuration {
  #addBidDuration

  constructor(addBidDuration) {
    this.#addBidDuration = addBidDuration;
  }

  get() {
    return this.#addBidDuration
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#addBidDuration)
  }
}
