import PageNumberButtonsComponent from "./PageNumberButtonsComponent.jsx";

const PageNumberButtons = ({ pageCount, currentPage, setCurrentPage }) => {
  const isEdgeCase = !pageCount || !currentPage || pageCount < 1 || currentPage < 1;

  if (isEdgeCase) {
    return;
  }

  if (pageCount <= 7) {
    const pageNumbers = Array.from({ length: pageCount }, (_, i) => i + 1);
    return <PageNumberButtonsComponent currentPage={currentPage} pageNumbers={pageNumbers} setCurrentPage={setCurrentPage}/>;
  }

  if (currentPage <= 2 || currentPage >= pageCount - 1) {
    const pageNumbers = [1, 2, 3, null, pageCount - 2, pageCount - 1, pageCount];
    return <PageNumberButtonsComponent currentPage={currentPage} pageNumbers={pageNumbers} setCurrentPage={setCurrentPage}/>;
  }

  if (currentPage === 3) {
    const pageNumbers = [1, 2, 3, 4, 5, null, pageCount];
    return <PageNumberButtonsComponent currentPage={currentPage} pageNumbers={pageNumbers} setCurrentPage={setCurrentPage}/>;
  }

  if (currentPage === pageCount - 2) {
    const pageNumbers = [1, null, pageCount - 4, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
    return <PageNumberButtonsComponent currentPage={currentPage} pageNumbers={pageNumbers} setCurrentPage={setCurrentPage}/>;
  }

  const pageNumbers = [1, null, currentPage - 1, currentPage, currentPage + 1, null, pageCount];
  return <PageNumberButtonsComponent currentPage={currentPage} pageNumbers={pageNumbers} setCurrentPage={setCurrentPage}/>;
};

export default PageNumberButtons;
