/** @format */

// App コンポーネントが多くの責任を負って Single Responsibility Principle (SRP) に反していた
// Route をこのコンポーネントで行う

import {Route, Routes} from 'react-router-dom';

import KManagerRoutes from "./infrastructure/routes/KManagerRoutes.jsx";
import NaviRoutes from "./infrastructure/routes/NaviRoutes.jsx";
import AppRouter from "./infrastructure/routes/ServiceRoutes.jsx";
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<AppRouter type="SERVICE"/>}/>
      <Route path="/navi/*" element={<NaviRoutes type="NAVI"/>}/>
      <Route path="/kmanager/*" element={<KManagerRoutes type="KMANAGER"/>}/>
    </Routes>
  );
};

export default AppRoutes;
