import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class ImportantAttention {
  #importantAttention

  constructor(importantAttention) {
    this.#importantAttention = importantAttention;
  }

  get() {
    return this.#importantAttention
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#importantAttention, 0, 1000)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#importantAttention)
  }
}
