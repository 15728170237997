export const sortType = [
  'マイリスト登録の新しい順',
  'マイリスト登録の古い順',
  '物件価格の高い順',
  '物件価格の低い順',
  '保証金の高い順',
  '保証金の低い順',
  '締切が近い順',
  '締切が遠い順',
  '入札数の多い順',
  '入札数の少ない順',
];

export const sortTypeToQueryParam = {
  マイリスト登録の新しい順: 'MY_LIST_REGISTER_NEWER',
  マイリスト登録の古い順: 'MY_LIST_REGISTER_OLDER',
  物件価格の高い順: 'PROPERTY_PRICE_MAXIMUM',
  物件価格の低い順: 'PROPERTY_PRICE_MINIMUM',
  保証金の高い順: 'DEPOSIT_PRICE_MAXIMUM',
  保証金の低い順: 'DEPOSIT_PRICE_MINIMUM',
  締切が近い順: 'DEADLINE_NEAREST',
  締切が遠い順: 'DEAD_LINE_FARTHEST',
  入札数の多い順: 'BID_IS_MAXIMUM',
  入札数の少ない順: 'BID_IS_MINIMUM',
};

export const auctionType = ['すべて', 'せり売形式', '入札形式'];

export const auctionTypeToQueryParam = {
  すべて: '',
  せり売形式: 'AUCTION',
  入札形式: 'BID',
};

export const queryParamToAuctionType = {
  AUCTION: 'せり売形式',
  BID: '入札形式',
};

export const procedureNo = ['すべて', 'インターネット公売', '公有財産売却'];

export const procedureNoToQueryParam = {
  すべて: '',
  インターネット公売: 'INTERNET',
  公有財産売却: 'PUBLIC_PROPERTY',
};

export const queryParamToProcedureNo = {
  INTERNET: 'インターネット公売',
  PUBLIC_PROPERTY: '公有財産売却',
};

export const filterDeposit = [
  {
    id: 1,
    keyName: 'filterDeposit',
    label: '保証金不要',
    value: 'NO_NEED_DEPOSIT',
  },
  {
    id: 2,
    keyName: 'filterDeposit',
    label: '保証金あり（クレカ納付OK）',
    value: 'NEED_DEPOSIT',
  },
];

export const queryParamToDepositPayment = {
  NO_NEED_DEPOSIT: '-',
  NEED_DEPOSIT: 'クレジットカード',
  BOTH: 'クレジットカードまたは銀行振込など',
};

export const filterAuctionResultStatus = [
  {
    id: 1,
    keyName: 'filterAuctionResultStatus',
    label: '落札',
    value: 'SUCCESSFUL_BID',
  },
  {
    id: 2,
    keyName: 'filterAuctionResultStatus',
    label: '次順位落札',
    value: 'NEXT_HIGHEST_BID',
  },
  {
    id: 3,
    keyName: 'filterAuctionResultStatus',
    label: '追加入札',
    value: 'ADDITIONAL_BID',
  },
];

export const filterAuctionProgressStatus = [
  {
    id: 1,
    keyName: 'filterAuctionProgressStatus',
    label: '申し込み可能',
    value: 'BEFORE_APPLICATION',
  },
  {
    id: 2,
    keyName: 'filterAuctionProgressStatus',
    label: '仮申し込み状態',
    value: 'TEMPORARY_APPLICATION',
  },
  {
    id: 3,
    keyName: 'filterAuctionProgressStatus',
    label: '本申し込み完了',
    value: 'AFTER_APPLICATION',
  },
  {
    id: 4,
    keyName: 'filterAuctionProgressStatus',
    label: '入札前',
    value: 'BEFORE_BIDDING',
  },
  {
    id: 5,
    keyName: 'filterAuctionProgressStatus',
    label: '入札中（せり売）',
    value: 'CURRENT_BIDDING',
  },
  {
    id: 6,
    keyName: 'filterAuctionProgressStatus',
    label: '入札完了',
    value: 'AFTER_BIDDING',
  },
  {
    id: 7,
    keyName: 'filterAuctionProgressStatus',
    label: '開札結果',
    value: 'AFTER_RESULT',
  },
];
