import FaqOtherProvider from './FaqOtherProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { faqOther } from "../entities/componentMapping.js";

const FaqOther = ({ type }) => {
  return (
    <FaqOtherProvider type={type}>
      {getComponent(faqOther, type)}
    </FaqOtherProvider>
  );
};

export default FaqOther;
