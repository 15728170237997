// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-nav_footer_nav__0cC3O {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0 auto;
}

.footer-nav_footer_nav_item__lRiMF {
    font-size: 14px;
    line-height: 1.571;
}

.footer-nav_footer_nav_item__lRiMF:not(:last-child):after {
    content: "|";
    margin: 0 8px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Footer/footer-nav.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".footer_nav {\n    display: flex;\n    justify-content: center;\n    list-style: none;\n    padding: 0;\n    margin: 0 auto;\n}\n\n.footer_nav_item {\n    font-size: 14px;\n    line-height: 1.571;\n}\n\n.footer_nav_item:not(:last-child):after {\n    content: \"|\";\n    margin: 0 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer_nav": `footer-nav_footer_nav__0cC3O`,
	"footer_nav_item": `footer-nav_footer_nav_item__lRiMF`
};
export default ___CSS_LOADER_EXPORT___;
