// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-detail .items-action-area-prices__main-list {
  width: 100%;
}

.property-detail .items-hero-action-area .items-action-area-prices.is--pc .items-action-area-price {
  width: 100%;
}

.property-detail .items-hero-action-area .items-action-area-prices .items-action-area-price {
  border-bottom: 1px solid #e8e6e6;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.property-detail .items-action-area-prices.is--text-right .price {
  text-align: right;
}

.property-detail .items-hero-action-area .items-action-area-prices .price {
  width: 100%;
  font-size: 1.25rem;
}

.property-detail .price small {
  font-size: 80%;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertyDetail/auction-item.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gCAAgC;EAChC,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".property-detail .items-action-area-prices__main-list {\n  width: 100%;\n}\n\n.property-detail .items-hero-action-area .items-action-area-prices.is--pc .items-action-area-price {\n  width: 100%;\n}\n\n.property-detail .items-hero-action-area .items-action-area-prices .items-action-area-price {\n  border-bottom: 1px solid #e8e6e6;\n  padding-bottom: 8px;\n  margin-bottom: 16px;\n}\n\n.property-detail .items-action-area-prices.is--text-right .price {\n  text-align: right;\n}\n\n.property-detail .items-hero-action-area .items-action-area-prices .price {\n  width: 100%;\n  font-size: 1.25rem;\n}\n\n.property-detail .price small {\n  font-size: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
