// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-sp {
    background-color: #fff;
    border-top: 1px solid #e8e6e6;
}

.footer-sp-accordion-label {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 16px 24px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/footer-sp.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".footer-sp {\n    background-color: #fff;\n    border-top: 1px solid #e8e6e6;\n}\n\n.footer-sp-accordion-label {\n    width: 100%;\n    height: 56px;\n    display: flex;\n    align-items: center;\n    padding: 16px 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
