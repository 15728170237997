import PropertyInfoFormCautionDescription from 'interfaces/components/common/PropertyInfoForm/PropertyInfoFormCautionDescription';
import { useEffect, useState } from 'react';

import FormText from '../../../Form/navi/FormText';
import Label from '../../../Form/navi/Label';

import 'interfaces/css/PropertyInfoCar/property-info-car-form-input-number.css';
import FormCheckbox from '../../../Form/navi/FormCheckbox';

const FormNumberWithCheckBox = ({
  caution_description,
  data = '',
  handler1 = () => {},
  handler2 = () => {},
  invalidMessage = 'TRUE',
  label,
  maxCharacters = null,
  unit,
  width = 20,
}) => {
  const [checkboxStates, setCheckBoxStates] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleCheckboxChange = (e) => {
    handler2(e, !checkboxStates);
    setCheckBoxStates(!checkboxStates);
    setIsDisabled(!isDisabled);
  };

  useEffect(() => {
    if (data === 'NULL') {
      setCheckBoxStates(true);
      setIsDisabled(true);
    }
  }, [data]);

  return (
    <div className="property-info-general-form-input-text property-info-general-form-table-row">
      <Label label={label} required={true} />
      <div className="property-info-general-form-table-cell">
        <div className="property-info-car-form-input-number">
          <FormText
            className={`property-input-text-size-${width}`}
            data={data !== 'NULL' ? data : ''}
            handler1={handler1}
            isDisable={isDisabled}
            invalidMessage={invalidMessage}
            maxCharacters={maxCharacters}
          />
          <div className="ml-2 property-info-car-form-unit-margin">{unit}</div>
          <div className="property-info-car-form-input-checkbox">
            <FormCheckbox
              id={'car_mileage_check_box'}
              handleCheckboxChange={handleCheckboxChange}
              data={checkboxStates}
            />
            <label>不明</label>
          </div>
        </div>
        <PropertyInfoFormCautionDescription data={caution_description} />
      </div>
    </div>
  );
};
export default FormNumberWithCheckBox;
