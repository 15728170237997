import EditUserInfoProvider from './EditUserInfoProvider';
import { getComponent } from '../../../../shared/utils/helper/get_component';
import { editUserInfo } from '../entities/componentMapping.js';

const EditUserInfo = ({ type, target }) => {
  return (
    <EditUserInfoProvider type={type} target={target}>
      {getComponent(editUserInfo, type)}
    </EditUserInfoProvider>
  );
};

export default EditUserInfo;
