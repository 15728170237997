
export class CarBidSystemFee {
  #carBidSystemFee

  constructor(carBidSystemFee) {
    this.#carBidSystemFee = carBidSystemFee;
  }

  get() {
    return this.#carBidSystemFee
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
