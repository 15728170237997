import { DATA_EMPTY } from 'shared/utils/helper/constants';

const isEmpty = [DATA_EMPTY, undefined, ''];

export const estate_build_info = (data) => [
  {
    text: '建物の名称',
    state: isEmpty.includes(data?.buildName) ? '未入力' : data?.buildName,
  },
  {
    text: '建物面積',
    state: isEmpty.includes(data?.buildSpace) ? '未入力' : data?.buildSpace,
  },
  {
    text: '専有面積',
    state: isEmpty.includes(data?.exclusiveSpace)
      ? '未入力'
      : data?.exclusiveSpace,
  },
  {
    text: '延床面積',
    state: isEmpty.includes(data?.floorSpace) ? '未入力' : data?.floorSpace,
  },
  {
    text: '構造',
    state: isEmpty.includes(data?.structureType)
      ? '未入力'
      : data?.structureType,
  },
  {
    text: '建築年月',
    state: isEmpty.includes(data?.buildDate)
      ? '未入力'
      : `${data?.buildDate.year}年${data?.buildDate.month + 1}月${data?.buildDate.day}日`,
  },
  {
    text: '建築年月 その他',
    state: isEmpty.includes(data?.buildDateOther)
      ? '未入力'
      : data?.buildDateOther,
  },
  {
    text: '階建/階',
    state: isEmpty.includes(data?.floor) ? '未入力' : data?.floor,
  },
  {
    text: '総戸数',
    state: isEmpty.includes(data?.totalHouses) ? '未入力' : data?.totalHouses,
  },
  {
    text: '間取り',
    state: isEmpty.includes(data?.layout) ? '未入力' : data?.layout,
  },
  {
    text: 'その他建物に関する物件情報',
    state: isEmpty.includes(data?.buildOther) ? '未入力' : data?.buildOther,
  },
];
