export const ProcedureType = {
  ASSET_SALE: { string: 'ASSET_SALE', jp: '公有財産売却' },
  PUBLIC_SALE: { string: 'PUBLIC_SALE', jp: 'インターネット公売' },
};

export const PublicSaleType = {
  AUCTION: { string: 'AUCTION', jp: 'せり売形式' },
  BID: { string: 'BID', jp: '入札形式' },
  BOTH: { string: 'BOTH', jp: 'せり売形式と入札形式' },
};
