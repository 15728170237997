import { Link } from 'react-router-dom';

import FooterBottomModalContents from './FooterBottomModalContents.jsx';
import useModalControl from '../../../../../../shared/hooks/useModalControl.js';
import Modal2 from '../../../../common/Modal/Modal2.jsx';

const FooterBottomModal = ({ linkLabel, iframeLink }) => {
  const { isOpen, handleOpen, handleClose } = useModalControl();

  return (
    <div data-testid="footer-bottom-modal">
      <Link
        onClick={handleOpen}
        className="font-weight-bold footer-bottom-link-item"
        data-testid="footer-bottom-modal-link">
        {linkLabel}
      </Link>
      <Modal2
        isOpen={isOpen}
        handleClose={handleClose}
        scroll="initial"
        maxHeight="initial">
        <FooterBottomModalContents
          iframeLink={iframeLink}
          handleClose={handleClose}
        />
      </Modal2>
    </div>
  );
};

export default FooterBottomModal;
