import React from "react";

const FormLabel = React.memo(function FormLabel({ label, required }) {
  return (
    <div>
      <span data-testid="form-label" className="form-label">{label}</span>
      {required ? <span data-testid="form-asterisk" style={{ color: "red" }}>*</span> : ""}
    </div>
  );
})
export default FormLabel