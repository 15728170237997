// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.applicant-list .applicant-list-table .applicant-list-table-inner table > tbody > tr > td .applicant-info-table th.block-target-user-info-th {
  width: auto;
  height: 32px;
  background-color: rgb(251, 226, 202);
  font-size: .75rem;
  font-weight: 700;
  color: rgba(0,0,0,.6);
}


.applicant-list .applicant-list-table .applicant-list-table-inner table > tbody > tr > td table.applicant-info-table td.block-target-user-info-td {
  height: 32px;
  font-size: .875rem;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/ApplicantList/block-target-user-info.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;AACvB;;;AAGA;EACE,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".applicant-list .applicant-list-table .applicant-list-table-inner table > tbody > tr > td .applicant-info-table th.block-target-user-info-th {\n  width: auto;\n  height: 32px;\n  background-color: rgb(251, 226, 202);\n  font-size: .75rem;\n  font-weight: 700;\n  color: rgba(0,0,0,.6);\n}\n\n\n.applicant-list .applicant-list-table .applicant-list-table-inner table > tbody > tr > td table.applicant-info-table td.block-target-user-info-td {\n  height: 32px;\n  font-size: .875rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
