import { Link } from 'react-router-dom';

import logoSVG from "../../../../assets/svg/logo.svg";

const GovernmentLogo = () => {
  return (
    <h1 className="logo" data-testid="logo">
      <Link to="/navi" className="header-logo" data-testid="header-logo">
        <img src={logoSVG} alt="KSI 官公庁オークション" width="190" height="27" data-testid="logo-svg"/>
      </Link>
    </h1>
  );
}

export default GovernmentLogo;
