// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-other .faq-other-list-item {
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #ccc;
}

.faq-other .faq-other-list-item:last-child {
  padding-bottom: 16px;
  border-bottom: none;
}

.faq-other .faq-other-list-item-text-box {
  flex: 1 0 100%;
}

.faq-other .faq-other-list-item-text-box-question-icon {
  color: #ff5252;
}

.faq-other .faq-other-list-item-text-box-separator {
  padding-left: 10px;
  padding-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/FaqOther/faq-other-list-item.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,6BAA6B;AAC/B;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".faq-other .faq-other-list-item {\n  padding-top: 24px;\n  padding-bottom: 24px;\n  border-bottom: 1px solid #ccc;\n}\n\n.faq-other .faq-other-list-item:last-child {\n  padding-bottom: 16px;\n  border-bottom: none;\n}\n\n.faq-other .faq-other-list-item-text-box {\n  flex: 1 0 100%;\n}\n\n.faq-other .faq-other-list-item-text-box-question-icon {\n  color: #ff5252;\n}\n\n.faq-other .faq-other-list-item-text-box-separator {\n  padding-left: 10px;\n  padding-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
