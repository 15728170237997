import { useEffect, useState } from 'react';

import { findById as findAuctionUser } from 'domain/User/services/ServiceUser.js';
import { whereByUserId as findPreregistrationApplicant } from 'domain/Applicant/services/PreregistrationApplicantInformation.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { EditUserInfoContext } from '../entities/EditUserInfoContext.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage.js';

const EditUserInfoProvider = ({ type, target, children }) => {
  const [auctionUser, setAuctionUser] = useState(undefined);
  const [
    preregistrationApplicantInformation,
    setPreregistrationApplicantInformation,
  ] = useState(undefined);

  const { localStorageUserId } = useLocalStorage();

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      findAuctionUser(type, localStorageUserId).then((auctionUser) => {
        setAuctionUser(auctionUser);
      });

      findPreregistrationApplicant(type, localStorageUserId).then(
        (applicants) => {
          let applicant = applicants.find(
            (applicant) =>
              applicant.type === (target === 'user' ? 'APPLICANT' : 'CLIENT'),
          );
          setPreregistrationApplicantInformation(applicant);
        },
      );
    }, [localStorageUserId]);
  }

  if (type === NAVI) {
    useEffect(() => {}, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <EditUserInfoContext.Provider
      value={{
        auctionUser,
        breadcrumbs,
        preregistrationApplicantInformation,
      }}>
      {children}
    </EditUserInfoContext.Provider>
  );
};

export default EditUserInfoProvider;
