import { useNavigate } from "react-router-dom";
import { useLogout } from "shared/hooks/useLogout.js";
import { ServiceTypes } from "shared/utils/constants/service.js";
const LOGIN_URL = "/navi/login";

const LogoutLink = () => {
  const navigation = useNavigate();
  const { logout } = useLogout();

  function clickHandler() {
    logout(ServiceTypes.NAVI.string)
    navigation(LOGIN_URL)
  }

  return (
    <a data-testid="logout-link" onClick={clickHandler}>ログアウト</a>
  );
}

export default LogoutLink;