import { useEffect, useState } from 'react';
import { useError } from 'shared/hooks/useError.js';
//import {getMyUserInfo} from "../read_storage/myuserinfo.js";

export const useMyUserInfo = (userId) => {
  const { setError } = useError();
  const [myUserInfo, setMyUserInfo] = useState({});

  useEffect(() => {
    const myUserInfo = async (userId) => {
      console.log(userId);
      try {
        //const myUserInfo = await getMyUserInfo(userId);
        //setMyUserInfo(myUserInfo);
        setMyUserInfo({});
      } catch (error) {
        setError(error);
      }
    };
    myUserInfo(userId).then();
  }, []);

  return { myUserInfo };
};
