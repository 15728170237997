import {
  DepositBody,
  DepositPaymentBody,
  PaymentBody,
  RemainingLabel,
} from './PropertyCardCaption.jsx';
import { bidPrice } from '../../helper/bidPrice.js';
import { isFalsy } from '../../helper/isFalsy.js';
import { useCalcDeadline } from '../../hooks/useCalcDeadline.js';

const AdditionalDuringBiddingPropertyCardCaptions = ({ data }) => {
  const { deadline } = useCalcDeadline(data);

  return (
    <>
      {!isFalsy(data.myAdditionalPrice) && (
        <div
          className="detail-item box nogrow flex pa-0 mb-0 gaps"
          data-testid="my-additional-price">
          <div className="box text-no-wrap no-bg property-sheet theme-light">
            あなたの追加入札後の入札額
          </div>
          <div className="box no-bg property-sheet theme-light">
            <p className="mb-0">
              <b>{bidPrice(data.myAdditionalPrice)}</b>
            </p>
          </div>
          <div className="box mr-4 property-sheet theme-light"> |</div>
        </div>
      )}
      {!isFalsy(data.myBidPrice) && (
        <div
          className="detail-item box nogrow flex pa-0 mb-0 gaps"
          data-testid="my-bid-price">
          <div className="box text-no-wrap no-bg property-sheet theme-light">
            あなたの追加入札前の入札額
          </div>
          <div className="box no-bg property-sheet theme-light">
            <p className="mb-0">
              <b>{bidPrice(data.myBidPrice)}</b>
            </p>
          </div>
          <div className="box mr-4 property-sheet theme-light"> |</div>
        </div>
      )}
      <DepositBody deposit={data.deposit} />
      <RemainingLabel
        remainingLabel={data.remainingLabel}
        deadline={deadline}
      />
      <DepositPaymentBody depositPayment={data.depositPayment} />
      <PaymentBody payments={data.payments} />
    </>
  );
};

export default AdditionalDuringBiddingPropertyCardCaptions;
