import { useCalcDeadline } from '../../hooks/useCalcDeadline';

import {
  PropertyInfo,
  PropertyInfoLabel,
  PropertyInfoValue,
} from 'interfaces/components/common/PropertyInfo/index';

const Deadline = ({ data }) => {
  const { deadline } = useCalcDeadline(data);

  const remainingLabel = {
    APPLYING: '申込締切まであと',
    WAIT_START_BID: '入札開始まであと',
    DURING_BIDDING: '入札締切まであと',
    ADDITIONAL_DURING_BIDDING: '追加入札締切まであと',
  };

  return (
    <div data-testid="deadline">
      <hr role="separator" aria-orientation="horizontal" className="divider" />
      <PropertyInfo className="flex justify-space-between align-center py-1">
        <PropertyInfoLabel className="ma-0">
          {remainingLabel[data.status]}
        </PropertyInfoLabel>
        <PropertyInfoValue className="ma-0 text-right text-caption">
          {deadline}
        </PropertyInfoValue>
      </PropertyInfo>
    </div>
  );
};

export default Deadline;
