import { useEffect, useState } from 'react';

import ShippingInfoFormInput from './ShippingInfoFormInput.jsx';
import RequestInput from './ShippingInfoRegistrationFormInputs/RequestInput.jsx';
import SelectAddressTypeInput from './ShippingInfoRegistrationFormInputs/SelectAddressTypeInput.jsx';
import SelectArrangementInput from './ShippingInfoRegistrationFormInputs/SelectArrangementInput.jsx';
import SelectCarrierInput from './ShippingInfoRegistrationFormInputs/SelectCarrierInput.jsx';
import SelectDeliveryScheduleInput from './ShippingInfoRegistrationFormInputs/SelectDeliveryScheduleInput.jsx';
import SelectPackingMethodInput from './ShippingInfoRegistrationFormInputs/SelectPackingMethodInput.jsx';
import SelectPaymentStatusInput from './ShippingInfoRegistrationFormInputs/SelectPaymentStatusInput.jsx';

import './css/shipping-info-form.css';
import Button from 'interfaces/components/common/Button/Button.jsx';

import { initShippingInfo } from '../ShippingStepComponent.jsx';

// 1. 運送業者の選択
export const initCarrier = {
  target: '',
  carrierName: '',
  carrierTel: '',
};

// 4. お届け先住所
export const initAddress = {
  target: '',
  applicant: {
    name: '',
    postalCode: '',
    prefectureId: null,
    address: '',
    houseTel: '',
    mobileTel: '',
  },
  receiver: {
    name: {
      name: '',
      label: '',
      role: '',
    },
    postalCode1: '',
    postalCode2: '',
    prefectureId: null,
    address: '',
    tel: '',
    deliveryReason: '',
  },
};

export const initPaymentData = {
  target: '',
  paymentScheduledDate: new Date(),
};

export const initDeliverySchedule = {
  target: '',
  deliveryDate: '',
  deliveryTime: '',
};

const ShippingInfoForm = ({
  propertyData,
  previousStep,
  nextStep,
  shippingInfo,
  setShippingInfo,
}) => {
  const [carrier, setCarrier] = useState(initCarrier);
  const [targetArrangement, setTargetArrangement] = useState('');
  const [targetPackingMethod, setTargetPackingMethod] = useState('');
  const [address, setAddress] = useState(initAddress);
  const [paymentData, setPaymentData] = useState(initPaymentData);
  const [deliverySchedule, setDeliverySchedule] =
    useState(initDeliverySchedule);
  const [deliveryRequest, setDeliveryRequest] = useState('');

  //const [isValid, setIsValid] = useState(false);

  const infoMapper = (info) => {
    setCarrier({ ...info.carrier });
    setTargetArrangement(info.targetArrangement);
    setTargetPackingMethod(info.targetPackingMethod);
    setAddress({ ...info.address });
    setPaymentData({ ...info.paymentData });
    setDeliverySchedule({ ...info.deliverySchedule });
    setDeliveryRequest(info.deliveryRequest);
  };

  useEffect(() => {
    if (JSON.stringify(shippingInfo) !== JSON.stringify(initShippingInfo)) {
      infoMapper(shippingInfo);
      return;
    }

    if (propertyData.deliveryInfo) {
      infoMapper(propertyData.deliveryInfo);
    }
  }, []);

  console.log(carrier);
  console.log(targetArrangement);
  console.log(targetPackingMethod);
  console.log(address);
  console.log(paymentData);
  console.log(deliverySchedule);
  console.log(deliveryRequest);

  const inputs = [
    {
      title: '運送業者の選択',
      topicCaption: '運送の手配を依頼する運送業者を以下より選択してください。',
      children: (
        <SelectCarrierInput
          carrier={carrier}
          setCarrier={setCarrier}
          carrierTelCaption="※同名の業者が複数存在する場合に特定できるよう本社の電話番号をご入力ください。"
        />
      ),
    },
    {
      title: '運送業者の手配について',
      topicCaption: '運送業者の手配をする人を以下より選択してください。',
      children: (
        <SelectArrangementInput
          targetArrangement={targetArrangement}
          setTargetArrangement={setTargetArrangement}
        />
      ),
    },
    {
      title: '梱包について',
      topicCaption: null,
      children: (
        <SelectPackingMethodInput
          targetPackingMethod={targetPackingMethod}
          setTargetPackingMethod={setTargetPackingMethod}
        />
      ),
    },
    {
      title: 'お届け先住所',
      topicCaption:
        'お届け先を変更する場合は「その他の住所へ送る」を選択してお届け先の住所と変更理由を入力してください。',
      children: (
        <SelectAddressTypeInput address={address} setAddress={setAddress} />
      ),
    },
    {
      title: '買受代金納付予定日',
      topicCaption:
        '買受代金の納付予定日を入力してください。すでに納付済みの場合は「納付済み」を選択してください。',
      children: (
        <SelectPaymentStatusInput
          paymentData={paymentData}
          setPaymentData={setPaymentData}
          paymentDeadline={propertyData?.data.paymentDeadline}
        />
      ),
    },
    {
      title: '配送希望日時',
      topicCaption: null,
      children: (
        <SelectDeliveryScheduleInput
          deliverySchedule={deliverySchedule}
          setDeliverySchedule={setDeliverySchedule}
          paymentData={paymentData}
          carrier={carrier.target}
        />
      ),
    },
    {
      title: 'ご要望欄',
      topicCaption:
        '配達方法等についてご希望やご質問等ございましたら、ご入力ください（例：ワレモノシールを貼って送ってください）。',
      children: (
        <RequestInput
          deliveryRequest={deliveryRequest}
          setDeliveryRequest={setDeliveryRequest}
        />
      ),
    },
  ];

  const handleClick = () => {
    setShippingInfo({
      carrier: { ...carrier },
      targetArrangement: targetArrangement,
      targetPackingMethod: targetPackingMethod,
      address: { ...address },
      paymentData: { ...paymentData },
      deliverySchedule: { ...deliverySchedule },
      deliveryRequest: deliveryRequest,
    });

    nextStep();
  };

  return (
    <div className="shipping-info-form mt-10" data-testid="shipping-info-form">
      {inputs.map((input, index) => (
        <ShippingInfoFormInput
          key={index}
          topicNum={index + 1}
          title={input.title}
          topicCaption={input.topicCaption}>
          {input.children}
        </ShippingInfoFormInput>
      ))}
      <div className="flex justify-center mb-14">
        <Button
          text="確認"
          height="52px"
          width="200px"
          onClick={handleClick}
          className="mr-4"
        />
        {!propertyData?.step && (
          <Button
            text="前に戻る"
            height="52px"
            width="120px"
            onClick={previousStep}
            className="cancel-common-button"
          />
        )}
      </div>
    </div>
  );
};

export default ShippingInfoForm;
