import { useValidate } from "../../../../shared/hooks/useValidate";
import CommonInput from '../Input/CommonInput.jsx';
import RequiredLabel from '../RequiredLabel/RequiredLabel.jsx';

const TelForm = ({ tel, handleChange }) => {
  const { handleValidates } = useValidate();
  const telValidateMessage = handleValidates(tel, ['no_message_required', 'tel']);

  return (
    <tr data-testid="tel-form">
      <th data-testid="tel-form-label">電話番号<RequiredLabel/></th>
      <td>
        <div className="w-expand">
          <CommonInput
            type="text"
            name="tel"
            value={tel}
            placeholder="（例）0312345678"
            handleChange={handleChange}
            validateMessage={telValidateMessage}
          />
        </div>
      </td>
    </tr>
  );
};

export default TelForm;
