import Heading3 from 'interfaces/components/common/Heading/Heading3';
import AfterWinningBidCautionContactFrom from 'interfaces/components/LocalGovernmentManagement/AfterWinningBidCautionContactFrom';
import {
  after_winning_bid_caution_description_1,
  after_winning_bid_caution_description_10,
  after_winning_bid_caution_description_11,
  after_winning_bid_caution_description_12,
  after_winning_bid_caution_description_2,
  after_winning_bid_caution_description_3,
  after_winning_bid_caution_description_6,
  after_winning_bid_caution_description_7,
  after_winning_bid_caution_description_8,
  after_winning_bid_caution_description_9,
} from 'shared/utils/helper/DummyAfterWinningBid';
import { local_government_management_radio1 } from 'shared/utils/helper/radio_labels';

import AuctionAfterWinningPublicSaleBidCautionFormRequireDocument from './AuctionAfterWinningPublicSaleBidCautionFormRequireDocument';
import FormRadioWithCaution from '../../../Form/navi/FormRadioWithCaution';
import FormTextAreaWithCaution from '../../../Form/navi/FormTextAreaWithCaution';

const BothForm = ({ validations }) => {
  return (
    <form data-testid="both-form">
      <div className="pt-9">
        <Heading3 text="1. 権利移転手続き" />
        <div className="property-info-general-form-table">
          <FormTextAreaWithCaution
            label={'権利移転手続きについての説明'}
            maxCharacters={300}
            caution_description={after_winning_bid_caution_description_1}
            data={validations.data.auctionTransRightDescription}
            handler1={validations.setters.handleAuctionTransRightDescription}
            invalidMessage={
              validations.validationMessage.auctionManagementTransValid
            }
          />
        </div>
      </div>
      <div className="pt-9">
        <Heading3 text="必要な費用" />
        <div className="property-info-general-form-table">
          <FormRadioWithCaution
            label={'動産の表示'}
            radio_label={local_government_management_radio1}
            data={validations.data.auctionCostPropertyDisplay}
            handler1={(e) => {
              validations.setters.handleAuctionCostPropertyDisplay(e);
              validations.setters.handleAuctionCostPropertyDescription(
                {
                  target: {
                    value: validations.data.auctionCostPropertyDescription,
                  },
                },
                e.target.value === 'DISPLAY',
              );
            }}
          />
          <FormTextAreaWithCaution
            label={'動産の説明'}
            maxCharacters={500}
            required={validations.data.auctionCostPropertyDisplay === 'DISPLAY'}
            caution_description={after_winning_bid_caution_description_2}
            data={validations.data.auctionCostPropertyDescription}
            handler1={(e) =>
              validations.setters.handleAuctionCostPropertyDescription(
                e,
                validations.data.auctionCostPropertyDisplay === 'DISPLAY',
              )
            }
            invalidMessage={
              validations.validationMessage.auctionMovableDescriptionValid
            }
          />
          <FormRadioWithCaution
            label={'自動車の表示'}
            radio_label={local_government_management_radio1}
            data={validations.data.auctionCostCarDisplay}
            handler1={(e) => {
              validations.setters.handleAuctionCostCarDisplay(e);
              validations.setters.handleAuctionCostCarDescription(
                {
                  target: {
                    value: validations.data.auctionCostCarDescription,
                  },
                },
                e.target.value === 'DISPLAY',
              );
            }}
          />
          <FormTextAreaWithCaution
            label={'自動車の説明'}
            maxCharacters={500}
            required={validations.data.auctionCostCarDisplay === 'DISPLAY'}
            caution_description={after_winning_bid_caution_description_2}
            data={validations.data.auctionCostCarDescription}
            handler1={(e) =>
              validations.setters.handleAuctionCostCarDescription(
                e,
                validations.data.auctionCostCarDisplay === 'DISPLAY',
              )
            }
            invalidMessage={
              validations.validationMessage.auctionCarDescriptionValid
            }
          />
          <FormRadioWithCaution
            label={'不動産の表示'}
            radio_label={local_government_management_radio1}
            data={validations.data.auctionCostEstateDisplay}
            handler1={(e) => {
              validations.setters.handleAuctionCostEstateDisplay(e);
              validations.setters.handleAuctionCostEstateDescription(
                {
                  target: {
                    value: validations.data.auctionCostEstateDescription,
                  },
                },
                e.target.value === 'DISPLAY',
              );
            }}
          />
          <FormTextAreaWithCaution
            label={'不動産の説明'}
            maxCharacters={500}
            required={validations.data.auctionCostEstateDisplay === 'DISPLAY'}
            caution_description={after_winning_bid_caution_description_2}
            data={validations.data.auctionCostEstateDescription}
            handler1={(e) =>
              validations.setters.handleAuctionCostEstateDescription(
                e,
                validations.data.auctionCostEstateDisplay === 'DISPLAY',
              )
            }
            invalidMessage={
              validations.validationMessage.auctionEstateDescriptionValid
            }
          />
          <FormTextAreaWithCaution
            label={'ご注意'}
            maxCharacters={500}
            caution_description={after_winning_bid_caution_description_3}
            data={validations.data.auctionCostAttention}
            handler1={validations.setters.handleAuctionCostAttention}
            invalidMessage={validations.validationMessage.auctionAttentionValid}
          />
        </div>
      </div>
      <div className="pt-9">
        <Heading3 text="必要な書類" />
        <AuctionAfterWinningPublicSaleBidCautionFormRequireDocument
          label={'必要な書類の案内ページ'}
          validations={validations}
        />
        <div className="after-winning-bid-caution-form-require-document-table">
          <FormRadioWithCaution
            label={'動産の表示'}
            radio_label={local_government_management_radio1}
            data={validations.data.auctionDocumentPropertyDisplay}
            handler1={(e) => {
              validations.setters.handleAuctionDocumentPropertyDisplay(e);
              validations.setters.handleAuctionDocumentPropertyDescription(
                {
                  target: {
                    value: validations.data.auctionDocumentPropertyDescription,
                  },
                },
                e.target.value === 'DISPLAY',
              );
            }}
          />
        </div>
        <div className="after-winning-bid-caution-form-require-document-table">
          <FormTextAreaWithCaution
            label={'動産の説明'}
            maxCharacters={1000}
            required={
              validations.data.auctionDocumentPropertyDisplay === 'DISPLAY'
            }
            caution_description={after_winning_bid_caution_description_6}
            data={validations.data.auctionDocumentPropertyDescription}
            handler1={(e) =>
              validations.setters.handleAuctionDocumentPropertyDescription(
                e,
                validations.data.auctionDocumentPropertyDisplay === 'DISPLAY',
              )
            }
            invalidMessage={
              validations.validationMessage
                .auctionDocumentPropertyDescriptionValid
            }
          />
        </div>
        <div className="after-winning-bid-caution-form-require-document-table">
          <FormRadioWithCaution
            label={'自動車の表示'}
            radio_label={local_government_management_radio1}
            data={validations.data.auctionDocumentCarDisplay}
            handler1={(e) => {
              validations.setters.handleAuctionDocumentCarDisplay(e);
              validations.setters.handleAuctionDocumentCarDescription(
                {
                  target: {
                    value: validations.data.auctionDocumentCarDescription,
                  },
                },
                e.target.value === 'DISPLAY',
              );
            }}
          />
        </div>
        <div className="after-winning-bid-caution-form-require-document-table">
          <FormTextAreaWithCaution
            label={'自動車の説明'}
            maxCharacters={1000}
            required={validations.data.auctionDocumentCarDisplay === 'DISPLAY'}
            caution_description={after_winning_bid_caution_description_6}
            data={validations.data.auctionDocumentCarDescription}
            handler1={(e) =>
              validations.setters.handleAuctionDocumentCarDescription(
                e,
                validations.data.auctionDocumentCarDisplay === 'DISPLAY',
              )
            }
            invalidMessage={
              validations.validationMessage.auctionDocumentCarDescriptionValid
            }
          />
        </div>
        <div className="after-winning-bid-caution-form-require-document-table">
          <FormRadioWithCaution
            label={'不動産の表示'}
            radio_label={local_government_management_radio1}
            data={validations.data.auctionDocumentEstateDisplay}
            handler1={(e) => {
              validations.setters.handleAuctionDocumentEstateDisplay(e);
              validations.setters.handleAuctionDocumentEstateDescription(
                {
                  target: {
                    value: validations.data.auctionDocumentEstateDescription,
                  },
                },
                e.target.value === 'DISPLAY',
              );
            }}
          />
        </div>
        <div className="after-winning-bid-caution-form-require-document-table">
          <FormTextAreaWithCaution
            label={'不動産の説明'}
            maxCharacters={1000}
            required={
              validations.data.auctionDocumentEstateDisplay === 'DISPLAY'
            }
            caution_description={after_winning_bid_caution_description_6}
            data={validations.data.auctionDocumentEstateDescription}
            handler1={(e) =>
              validations.setters.handleAuctionDocumentEstateDescription(
                e,
                validations.data.auctionDocumentEstateDisplay === 'DISPLAY',
              )
            }
            invalidMessage={
              validations.validationMessage
                .auctionDocumentEstateDescriptionValid
            }
          />
        </div>
        <div className="after-winning-bid-caution-form-require-document-table">
          <FormTextAreaWithCaution
            label={'ご注意'}
            maxCharacters={500}
            caution_description={after_winning_bid_caution_description_7}
            data={validations.data.auctionDocumentAttention}
            handler1={validations.setters.handleAuctionDocumentAttention}
            invalidMessage={
              validations.validationMessage.auctionDocumentAttentionValid
            }
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="物件の権利移転について" />
        <div className="property-info-general-form-table">
          <FormRadioWithCaution
            label={'動産の表示'}
            radio_label={local_government_management_radio1}
            data={validations.data.auctionTransRightPropertyDisplay}
            handler1={(e) => {
              validations.setters.handleAuctionTransRightPropertyDisplay(e);
              validations.setters.handleAuctionTransRightPropertyDescription(
                {
                  target: {
                    value:
                      validations.data.auctionTransRightPropertyDescription,
                  },
                },
                e.target.value === 'DISPLAY',
              );
            }}
          />
          <FormTextAreaWithCaution
            label={'動産の説明'}
            maxCharacters={1000}
            required={
              validations.data.auctionTransRightPropertyDisplay === 'DISPLAY'
            }
            caution_description={after_winning_bid_caution_description_6}
            data={validations.data.auctionTransRightPropertyDescription}
            handler1={(e) =>
              validations.setters.handleAuctionTransRightPropertyDescription(
                e,
                validations.data.auctionTransRightPropertyDisplay === 'DISPLAY',
              )
            }
            invalidMessage={
              validations.validationMessage
                .auctionTransRightPropertyDescriptionValid
            }
          />
          <FormRadioWithCaution
            label={'自動車の表示'}
            radio_label={local_government_management_radio1}
            data={validations.data.auctionTransRightCarDisplay}
            handler1={(e) => {
              validations.setters.handleAuctionTransRightCarDisplay(e);
              validations.setters.handleAuctionTransRightCarDescription(
                {
                  target: {
                    value: validations.data.auctionTransRightCarDescription,
                  },
                },
                e.target.value === 'DISPLAY',
              );
            }}
          />
          <FormTextAreaWithCaution
            label={'自動車の説明'}
            required={
              validations.data.auctionTransRightCarDisplay === 'DISPLAY'
            }
            maxCharacters={1000}
            caution_description={after_winning_bid_caution_description_6}
            data={validations.data.auctionTransRightCarDescription}
            handler1={(e) =>
              validations.setters.handleAuctionTransRightCarDescription(
                e,
                validations.data.auctionTransRightCarDisplay === 'DISPLAY',
              )
            }
            invalidMessage={
              validations.validationMessage.auctionTransRightCarDescriptionValid
            }
          />
          <FormRadioWithCaution
            label={'不動産の表示'}
            radio_label={local_government_management_radio1}
            data={validations.data.auctionTransRightEstateDisplay}
            handler1={(e) => {
              validations.setters.handleAuctionTransRightEstateDisplay(e);
              validations.setters.handleAuctionTransRightEstateDescription(
                {
                  target: {
                    value: validations.data.auctionTransRightEstateDescription,
                  },
                },
                e.target.value === 'DISPLAY',
              );
            }}
          />
          <FormTextAreaWithCaution
            label={'不動産の説明'}
            maxCharacters={1000}
            required={
              validations.data.auctionTransRightEstateDisplay === 'DISPLAY'
            }
            caution_description={after_winning_bid_caution_description_6}
            data={validations.data.auctionTransRightEstateDescription}
            handler1={(e) =>
              validations.setters.handleAuctionTransRightEstateDescription(
                e,
                validations.data.auctionTransRightEstateDisplay === 'DISPLAY',
              )
            }
            invalidMessage={
              validations.validationMessage
                .auctionTransRightEstateDescriptionValid
            }
          />
          <FormTextAreaWithCaution
            label={'ご注意'}
            maxCharacters={500}
            caution_description={after_winning_bid_caution_description_8}
            data={validations.data.auctionTransRightAttention}
            handler1={validations.setters.handleAuctionTransRightAttention}
            invalidMessage={
              validations.validationMessage.auctionTransRightAttentionValid
            }
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="落札者（落札者が法人の場合は代表者）以外の方が権利移転手続きを行う場合" />
        <div className="property-info-general-form-table">
          <FormTextAreaWithCaution
            label={'説明'}
            maxCharacters={500}
            caution_description={after_winning_bid_caution_description_9}
            data={validations.data.auctionOtherTransRightDescription}
            handler1={
              validations.setters.handleAuctionOtherTransRightDescription
            }
            invalidMessage={
              validations.validationMessage
                .auctionOtherTransRightDescriptionValid
            }
          />
          <FormTextAreaWithCaution
            label={'ご注意'}
            maxCharacters={500}
            caution_description={after_winning_bid_caution_description_10}
            data={validations.data.auctionOtherTransRightAttention}
            handler1={validations.setters.handleAuctionOtherTransRightAttention}
            invalidMessage={
              validations.validationMessage.auctionOtherTransRightAttentionValid
            }
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="権利移転の時期" />
        <div className="property-info-general-form-table">
          <FormTextAreaWithCaution
            label={'説明'}
            maxCharacters={500}
            caution_description={after_winning_bid_caution_description_9}
            data={validations.data.auctionTransRightDateDescription}
            handler1={
              validations.setters.handleAuctionTransRightDateDescription
            }
            invalidMessage={
              validations.validationMessage
                .auctionOtherTransRightDateDescriptionValid
            }
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="2. 重要事項" />
        <div className="property-info-general-form-table">
          <FormTextAreaWithCaution
            label={'危険負担'}
            maxCharacters={1000}
            caution_description={after_winning_bid_caution_description_11}
            data={validations.data.auctionImportantRiskBearing}
            handler1={validations.setters.handleAuctionImportantRiskBearing}
            invalidMessage={
              validations.validationMessage.auctionImportantRiskBearingValid
            }
          />
          <FormTextAreaWithCaution
            label={'契約不適合責任'}
            maxCharacters={1000}
            caution_description={after_winning_bid_caution_description_11}
            data={
              validations.data.auctionImportantNonConformingContractsLiability
            }
            handler1={
              validations.setters
                .handleAuctionImportantNonConformingContractsLiability
            }
            invalidMessage={
              validations.validationMessage
                .auctionImportantNonConformingContractsLiabilityValid
            }
          />
          <FormTextAreaWithCaution
            label={'引き渡し条件'}
            maxCharacters={1000}
            caution_description={after_winning_bid_caution_description_11}
            data={validations.data.auctionImportantTermsDelivery}
            handler1={validations.setters.handleAuctionImportantTermsDelivery}
            invalidMessage={
              validations.validationMessage.auctionImportantTermsDeliveryValid
            }
          />
          <FormTextAreaWithCaution
            label={'執行機関の引き渡し義務'}
            maxCharacters={1000}
            caution_description={after_winning_bid_caution_description_11}
            data={validations.data.auctionImportantObligationDelivery}
            handler1={
              validations.setters.handleAuctionImportantObligationDelivery
            }
            invalidMessage={
              validations.validationMessage
                .auctionImportantObligationDeliveryValid
            }
          />
          <FormTextAreaWithCaution
            label={'返品、交換'}
            maxCharacters={1000}
            caution_description={after_winning_bid_caution_description_11}
            data={validations.data.auctionImportantReturnItems}
            handler1={validations.setters.handleAuctionImportantReturnItems}
            invalidMessage={
              validations.validationMessage.auctionImportantReturnItemsValid
            }
          />
          <FormTextAreaWithCaution
            label={'保管費用'}
            maxCharacters={1000}
            caution_description={after_winning_bid_caution_description_11}
            data={validations.data.auctionImportantStorageCost}
            handler1={validations.setters.handleAuctionImportantStorageCost}
            invalidMessage={
              validations.validationMessage.auctionImportantStorageCostValid
            }
          />
          <FormTextAreaWithCaution
            label={'落札者（最高価申込者）決定後、公売保証金が返還される場合'}
            maxCharacters={1000}
            caution_description={after_winning_bid_caution_description_11}
            data={validations.data.auctionImportantReturnDeposit}
            handler1={validations.setters.handleAuctionImportantReturnDeposit}
            invalidMessage={
              validations.validationMessage.auctionImportantReturnDepositValid
            }
          />
          <FormTextAreaWithCaution
            label={'その他'}
            maxCharacters={1000}
            caution_description={after_winning_bid_caution_description_11}
            data={validations.data.auctionImportantOther}
            handler1={validations.setters.handleAuctionImportantOther}
            invalidMessage={
              validations.validationMessage.auctionImportantOtherValid
            }
          />
          <FormTextAreaWithCaution
            label={'ご注意'}
            maxCharacters={500}
            caution_description={after_winning_bid_caution_description_12}
            data={validations.data.auctionImportantAttention}
            handler1={validations.setters.handleAuctionImportantAttention}
            invalidMessage={
              validations.validationMessage.auctionImportantAttentionValid
            }
          />
        </div>
      </div>

      {/* お問い合わせ先 */}
      <AfterWinningBidCautionContactFrom validations={validations} />
    </form>
  );
};

export default BothForm;
