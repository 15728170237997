/** @format */

import AppProviders from './AppProviders';
import AppRoutes from './AppRoutes.jsx';
import 'interfaces/css/common/normalize.css';

// ロガーをインポート
// import logger from "./logger.ts";
// import metrics from "./metrics.ts";

const App = (props) => {
  return (
    <div data-testid="app">
      <AppProviders {...props}>
        <AppRoutes {...props} />
      </AppProviders>
    </div>
  );
};

export default App;
