
export class Handle {
    #handle

    constructor(handle) {
        this.#handle = handle;
    }

    get() {
        return this.#handle
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
