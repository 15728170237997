import Switcher from "interfaces/components/common/Switcher";
import Both from "interfaces/components/LocalGovernmentManagement/guideline/Both";
import {useState} from "react";

const IndividualForm = ({auctionData, bidData, procedureType}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const labels = [
    'せり売形式',
    '入札形式'
  ];

  return (
    <form data-testid="individual-form">
      <Switcher data-testid="switcher" labels={labels} activeTabIndex={activeTabIndex} setActiveTab={setActiveTabIndex}/>
      { activeTabIndex === 0 && <Both data-testid="auction-form" data={auctionData} procedureType={procedureType}/>}
      { activeTabIndex === 1 && <Both data-testid="bid-form" data={bidData} procedureType={procedureType}/>}
    </form>
  )
}

export default IndividualForm;