import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import PrimaryButton from 'interfaces/components/common/Button/PrimaryButton';
import OrganizationTable from 'interfaces/components/Division/navi/Organizations/OrganizationTable';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import { OrganizationContext } from 'interfaces/pages/Organization/entities/OrganizationContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { breadcrumbs } from '../../../../pages/Organization/entities/breadcrumbs';
import 'interfaces/css/Organization/organization.css';

const Organizations = () => {
  const { divisions, procedureType, governmentType, isNotEditable } =
    useContext(OrganizationContext);
  const navigate = useNavigate();

  return (
    <div className="organization">
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <PropertyInfoGeneralPageDescription
        title={'執行機関管理'}
        content={
          '執行機関を登録、管理します。こちらに登録された執行機関は物件情報登録時に「執行機関名称」欄に表示されます。行政機関様ごとに1つ以上の執行機関登録が必須となります。登録した執行機関情報を編集する場合は編集ボタンをクリックしてください。'
        }
      />
      <div className="new-registration-wrap">
        <PrimaryButton onClick={() => navigate('/navi/organization/new')}>
          新規登録
        </PrimaryButton>
      </div>
      <div className="organization-table-wrap">
        <OrganizationTable
          tableData={divisions}
          procedureType={procedureType}
          governmentType={governmentType}
          isNotEditable={isNotEditable}
        />
      </div>
    </div>
  );
};

export default Organizations;
