// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createNaviDocumentSendPagesData,
  updateNaviDocumentSendPagesData,
  deleteNaviDocumentSendPagesData
} from "../../../infrastructure/api/NaviDocumentSendPages.js";
import { compareData } from "../../../shared/utils/helper/compareData.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { SettingDocumentSendCreate } from "../entities/Setting.js";
import { getDocumentSendPages } from "../repositories/DocumentSendPages.js";

export async function get(type) {
  return await getDocumentSendPages(type)
}

export async function findById(type, id) {
  const DocumentSendPages = await getDocumentSendPages(type);
  return DocumentSendPages.filter(data => data.governmentId.toString() === id.toString())
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (DocumentSendPages)');
  }
  if (type === NAVI) {
    const documentSendPages = new SettingDocumentSendCreate(data)
    response = await createNaviDocumentSendPagesData(documentSendPages);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (DocumentSendPages)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create DocumentSendPages', response);
  }
  return response
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (DocumentSendPages)');
  }
  if (type === NAVI) {
    const documentSendPages = new SettingDocumentSendCreate(data)
    response = await updateNaviDocumentSendPagesData(documentSendPages);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (DocumentSendPages)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update DocumentSendPages', response);
  }
  return response
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (DocumentSendPages)');
  }
  if (type === NAVI) {
    response = await deleteNaviDocumentSendPagesData(data.id);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (DocumentSendPages)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete DocumentSendPages', response);
  }
  return response
}

// function createDocumentSendPagesArray(data) {
//   let DocumentSendPages = []
//   data.forEach((DocumentSendPages) => {
//     DocumentSendPages.push(new DocumentSendPages(DocumentSendPages))
//   })
//   return DocumentSendPages
// }

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]))
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]))
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value)
}
