import Button from 'interfaces/components/common/Button';
//import axios from "axios";
import { useSortableData } from 'shared/hooks/useSort.js';
import { SortableTh } from 'interfaces/components/common/Table/Th/SortableTh';
//import {useDownloadFiles} from "../../../../../shared/hooks/useDownloadFeeFiles";
// TODO: 請求日をYYYY年MM月DD日に変更する実装をする必要あり

const UsageFeeGovernmentsTable = ({
  auction,
  usageFees,
  handleOpenModal,
  checked,
  checkedList,
  allCheck,
  clearCheck,
}) => {
  const {
    items: sortedUsageFees,
    requestSort,
    sortConfig,
  } = useSortableData(usageFees);

  // インボイス対応後のテーブル
  let headerColumns = [
    { id: 1, column: 'location', text: '地域' },
    { id: 2, column: 'region', text: '都道府県' },
    { id: 3, column: 'name', text: '行政機関' },
    { id: 4, column: 'applyReductionCount', text: '減額申請件数' },
    { id: 5, column: 'systemUsageFeeAuction', text: 'システム利用料(せり)' },
    { id: 6, column: 'systemUsageFeeBid', text: 'システム利用料(入札)' },
    { id: 7, column: 'systemUsageFeeAuctionTax', text: '消費税' },
    { id: 8, column: 'systemUsageFeeTotal', text: '請求額' },
    { id: 9, column: 'estimatedSystemUsagePaymentDate', text: '請求日' },
    { id: 10, column: 'systemUsagePaymentDate', text: '入金予定日' },
    { id: 11, column: 'billingDate', text: '入金日' },
  ];
  if (auction.aplStart <= '2023-10-01') {
    // インボイス対応前のテーブル（ auctions.apl_start が 2023/10/01 以前はこちら
    headerColumns = [
      { id: 1, column: 'location', text: '地域' },
      { id: 2, column: 'region', text: '都道府県' },
      { id: 3, column: 'name', text: '行政機関' },
      { id: 4, column: 'applyReductionCount', text: '減額申請件数' },
      { id: 5, column: 'systemUsageFeeAuction', text: 'システム利用料(せり)' },
      { id: 6, column: 'systemUsageFeeAuctionTax', text: '消費税(せり)' },
      { id: 7, column: 'systemUsageFeeBid', text: 'システム利用料(入札)' },
      { id: 8, column: 'systemUsageFeeBidTax', text: '消費税(入札)' },
      { id: 9, column: 'systemUsageFeeTotal', text: '請求額' },
      { id: 10, column: 'estimatedSystemUsagePaymentDate', text: '請求日' },
      { id: 11, column: 'systemUsagePaymentDate', text: '入金予定日' },
      { id: 12, column: 'billingDate', text: '入金日' },
    ];
  }

  //  const submitHandler = async (e) => {
  //    e.preventDefault();
  //const headers = {
  //  'Content-Type': 'application/json',
  //};
  //const body = {
  //  "system_usage_payment_date": date.toLocaleDateString('sv-SE')
  //}
  //try {
  //  for (const id of checked) {
  //    await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/v1/management/auctions/${auctionId}/${id}/system-usage-payment-dates`, body, {headers: headers})
  //  }
  //} catch (error) {
  //  return error
  //}
  //handleClose();
  //  };

  //  const handleChange = (selectDate) => {
  //    setDate(selectDate)
  //  }
  //  const [date, setDate] = useState("");

  const checkBoxChange = () => {
    if (
      JSON.stringify(checked) ===
      JSON.stringify(usageFees.map((row) => row.governmentId))
    ) {
      clearCheck();
    } else {
      console.log(allCheck);
      allCheck();
    }
  };

  return (
    <div className="kmanager-auction-operation">
      <table
        data-testid="kmanager-usage-fee-governments-table"
        className="usage-fee-governments-table kmanager-table kmanager-table-hover">
        <thead>
          <tr
            data-testid="kmanager-usage-fee-governments-table-tr"
            className="kmanager-table-tr">
            <th
              data-testid="kmanager-usage-fee-governments-table-th"
              className="kmanager-table-th text-center text-no-wrap">
              <div className="d-flex align-center justify-center kmanager-table-th-check-box-icon">
                <input
                  type="checkbox"
                  value={checked}
                  onChange={checkBoxChange}
                  checked={
                    JSON.stringify(checked) ===
                    JSON.stringify(usageFees.map((row) => row.governmentId))
                  }
                />
              </div>
            </th>
            {headerColumns.map((header) => (
              <SortableTh
                key={header.id}
                header={header}
                onClick={() => requestSort(header.column)}
                sortConfig={sortConfig}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedUsageFees?.map((usageFee, index) => (
            <tr
              data-testid="kmanager-usage-fee-governments-table-tr"
              className="kmanager-table-tr"
              key={index}>
              <td
                data-testid="kmanager-usage-fee-governments-table-td"
                className="kmanager-table-td text-no-wrap">
                <div className="d-flex align-center justify-center kmanager-table-th-check-box-icon">
                  <input
                    type="checkbox"
                    value={usageFee.governmentId}
                    onChange={() => checkedList(usageFee.governmentId)}
                    checked={checked.includes(usageFee.governmentId)}
                  />
                </div>
              </td>
              <td
                data-testid="kmanager-usage-fee-governments-table-td"
                className="kmanager-table-td text-no-wrap">
                {usageFee.region}
              </td>
              <td
                data-testid="kmanager-usage-fee-governments-table-td"
                className="kmanager-table-td text-no-wrap">
                {usageFee.location}
              </td>
              <td
                data-testid="kmanager-usage-fee-governments-table-td"
                className="kmanager-table-td text-no-wrap">
                {usageFee.name}
              </td>
              <td
                data-testid="kmanager-usage-fee-governments-table-td"
                className="kmanager-table-td text-left text-no-wrap">
                {usageFee.applyReductionCount}件
              </td>

              <td
                data-testid="kmanager-usage-fee-governments-table-td"
                className="kmanager-table-td text-right text-no-wrap">
                {usageFee.systemUsageFeeAuction.toLocaleString()}円
              </td>
              {auction.aplStart <= '2023-10-01' ? (
                <td
                  data-testid="kmanager-usage-fee-governments-table-td"
                  className="kmanager-table-td text-right text-no-wrap">
                  {usageFee.systemUsageFeeAuctionTax.toLocaleString()}円
                </td>
              ) : (
                ''
              )}
              <td
                data-testid="kmanager-usage-fee-governments-table-td"
                className="kmanager-table-td text-right text-no-wrap">
                {usageFee.systemUsageFeeBid.toLocaleString()}円
              </td>
              {auction.aplStart <= '2023-10-01' ? (
                <td
                  data-testid="kmanager-usage-fee-governments-table-td"
                  className="kmanager-table-td text-right text-no-wrap">
                  {usageFee.systemUsageFeeBidTax.toLocaleString()}円
                </td>
              ) : (
                <td
                  data-testid="kmanager-usage-fee-governments-table-td"
                  className="kmanager-table-td text-right text-no-wrap">
                  {(
                    usageFee.systemUsageFeeAuctionTax +
                    usageFee.systemUsageFeeBidTax
                  ).toLocaleString()}
                  円
                </td>
              )}
              <td
                data-testid="kmanager-usage-fee-governments-table-td"
                className="kmanager-table-td text-right text-no-wrap">
                {usageFee.systemUsageFeeTotal.toLocaleString()}円
              </td>
              <td
                data-testid="kmanager-usage-fee-governments-table-td"
                className="kmanager-table-td text-no-wrap">
                {usageFee.estimatedSystemUsagePaymentDate}
              </td>
              <td
                data-testid="kmanager-usage-fee-governments-table-td"
                className="kmanager-table-td text-no-wrap">
                {usageFee.systemUsagePaymentDate}
              </td>
              <td
                data-testid="kmanager-usage-fee-governments-table-td"
                className="kmanager-table-td text-no-wrap">
                {/*
                <ReturnDateModal isOpen={isOpen} handleClose={handleClose} submitHandler={submitHandler}
                                handleChange={handleChange} date={date} label={"入金日登録"}/>
                */}
                {usageFee.billingDate === undefined ? (
                  <Button
                    className="kmanager-common-button kmanager-payment-date-button small text-no-wrap primary"
                    text="入金日登録"
                    isOutline={true}
                    clickHandler={handleOpenModal}
                  />
                ) : (
                  usageFee.billingDate
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsageFeeGovernmentsTable;
