import CautionTopicRecord from './CautionTopicRecord.jsx';
import 'interfaces/css/common/caution-topic-table.css'

const CautionTopicTable = ({ caption, className, hasCaptionBgColor = false, recordData }) => {
  return (
    <table
      className={`caution-topic-table ${className ? className : ''}`}
      data-testid="caution-topic-table"
    >
      {
        caption && <caption className={`caution-topic-caption ${ hasCaptionBgColor ? 'caution-topic-caption-has-bg-color' : '' }`} data-testid="caution-topic-caption">{ caption }</caption>
      }
      <tbody>
        {
          recordData.map((record, index) =>
            <CautionTopicRecord headline={record.headline} content={record.content} isBgGray={record.isBgGray} key={index}/>
          )
        }
      </tbody>
    </table>
  );
};
export default CautionTopicTable;