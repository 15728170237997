
export class Remarks {
    #remarks

    constructor(remarks) {
        this.#remarks = remarks;
    }

    get() {
        return this.#remarks
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
