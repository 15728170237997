import { ReactComponent as ArrowDropDownIcon } from 'interfaces/assets/svg/arrow_drop_down_icon.svg';
import { useEffect, useRef, useState } from 'react';
import 'interfaces/css/Mylist/mylist-sort-selector.css';

const MylistSortSelector = ({
  items,
  label,
  selected_value,
  className,
  handleSelect,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const menuRef = useRef(null);

  const openMenu = () => {
    if (inputRef.current) {
      setIsOpen(true);
    }
  };

  const selectItem = (item) => {
    handleSelect(item);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !inputRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={
        'mylist-sort-selector-wrap mylist-sort' + `${className || ''}`
      }>
      <div data-testid="mylist-sort-selector" className="mylist-sort-selector">
        <div className="mylist-sort-label-input-wrap">
          <label
            data-testid="mylist-sort-selector-label"
            className="mylist-sort-selector-label">
            {label}
          </label>
          <input
            ref={inputRef}
            readOnly
            type="text"
            onClick={openMenu}
            value={selected_value}
            placeholder={placeholder}
            data-testid="mylist-sort-input"
            className="mylist-sort-input"
          />
        </div>
        <ArrowDropDownIcon
          data-testid="mylist-sort-selector-icon"
          className={
            'mylist-sort-selector-icon' +
            ' ' +
            'mylist-sort-selector-icon' +
            `${className || ''}`
          }
        />
      </div>
      {isOpen && (
        <div data-testid="mylist-sort-menu" className="mylist-sort-menu">
          <ul className="mylist-sort-menu-item-wrap">
            {items?.map((item, index) => (
              <li
                key={index}
                onClick={() => selectItem(item)}
                className={`mylist-sort-menu-item ${selected_value === item ? 'active' : ''}`}
                data-testid="mylist-sort-menu-item">
                {item.label ?? item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
export default MylistSortSelector;
