// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading4-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    position: relative;
    font-weight: 700;
    padding-left: 15px;
    font-size: 16px;
    line-height: 1.222;
    max-width: 100%;
}

.heading4-wrap:before {
    content: "";
    position: absolute;
    left: 0;
    width: 10px;
    height: 10px;
    background: #ff7f00;
}

.heading4 {
    width: 100%;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.heading4-wrap .right-box {
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/heading4.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".heading4-wrap {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 8px;\n    position: relative;\n    font-weight: 700;\n    padding-left: 15px;\n    font-size: 16px;\n    line-height: 1.222;\n    max-width: 100%;\n}\n\n.heading4-wrap:before {\n    content: \"\";\n    position: absolute;\n    left: 0;\n    width: 10px;\n    height: 10px;\n    background: #ff7f00;\n}\n\n.heading4 {\n    width: 100%;\n    font-weight: 400;\n    margin: 0;\n    padding: 0;\n}\n\n.heading4-wrap .right-box {\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
