export const carManufactureToJp = {
  TOYOTA: 'トヨタ',
  LEXUS: 'レクサス',
  HONDA: 'ホンダ',
  NISSAN: '日産',
  SUZUKI: 'スズキ',
  DAIHATSU: 'ダイハツ',
  MATSUDA: 'マツダ',
  SUBARU: 'スバル',
  MITSUBISHI: '三菱',
  YAMAHA: 'ヤマハ',
  HINO: '日野',
  MERCEDES_BENZ: 'メルセデス',
  BMW: 'BMW',
  VOLKSWAGEN: 'フォルクスワーゲン',
  AUDI: 'アウディ',
  MINI: 'ミニ',
  PORSCHE: 'ポルシェ',
  VOLVO: 'ボルボ',
  PEUGEOT: 'プジョー',
  LAND_ROVER: 'ランドローバ',
  OTHER: 'その他',
};

export const carPurposeToJp = {
  PASSENGER: '乗用',
  SPECIAL: '特殊',
  FREIGHT: '貨物',
  BUS: '乗合',
  OTHER: 'その他',
};

export const carTransmissionTypeToJp = {
  MT: 'マニュアル',
  AT: 'オートマチック',
};

export const estateTypeToJp = {
  BUILDING_WITH_LAND: '土地付き建物',
  SECTIONAL_OWNERSHIP_BUILDING: '区分所有建物',
  RESIDENTIAL_LAND: '宅地',
  MOUNTAIN_FOREST: '山林',
  OTHER_LAND: 'その他土地',
  BOTTOM_LAND: '底地',
  OTHER: 'その他',
};

export const privateOrBusinessToJp = {
  PRIVATE: '自家用',
  BUSINESS: '事業用',
  UNKNOWN: '不明',
};

export const handleTypeToJp = {
  RIGHT: '右ハンドル',
  LEFT: '左ハンドル',
};

export const existsCarMaintenanceSheetToJp = {
  EXISTS: '記録簿あり',
  NO_EXISTS: '記録簿なし',
};

export const DepositPayment = {
  NONE: { string: 'NONE', jp: '保証金なし' },
  BANK_TRANSFER: { string: 'BANK_TRANSFER', jp: '銀行振込' },
  CREDIT_CARD: { string: 'CREDIT_CARD', jp: 'クレジットカード' },
  BOTH: { string: 'BOTH', jp: 'クレジットカードまたは銀行振込' },
};
