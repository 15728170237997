// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ripple {
    position: absolute;
    border-radius: 50%;
    background: rgba(255,255,255,0.3);
    transform: scale(0.5);
    animation: ripple-animation 0.4s linear;
}

@keyframes ripple-animation {
    to {
        transform: scale(2);
        opacity: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/ripple-effect.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,iCAAiC;IACjC,qBAAqB;IACrB,uCAAuC;AAC3C;;AAEA;IACI;QACI,mBAAmB;QACnB,UAAU;IACd;AACJ","sourcesContent":[".ripple {\n    position: absolute;\n    border-radius: 50%;\n    background: rgba(255,255,255,0.3);\n    transform: scale(0.5);\n    animation: ripple-animation 0.4s linear;\n}\n\n@keyframes ripple-animation {\n    to {\n        transform: scale(2);\n        opacity: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
