import { ValidationMessage } from 'interfaces/components/common/ValidationMessage';
import React from 'react';

import CheckBox from './CheckBox.jsx';
import FormLabel from './FormLabel.jsx';

const InputCheckBox = React.memo(function InputCheckBox({
  invalidMessage,
  required,
  label,
  check_labels,
  state,
  setState,
  describe,
}) {
  const handleChange = (event) => {
    setState(event);
  };
  return (
    <div className="kmanager-row">
      <div className="kmanager-col-4">
        <FormLabel label={label} required={required} />
      </div>
      <div className="kmanager-col">
        {check_labels &&
          check_labels.map((label) => {
            return (
              <div
                key={label.id}
                className="flex form-list"
                data-testid="input-check-box">
                <CheckBox
                  id={label.id}
                  name={label.name ?? ''}
                  data={state[label.name] ?? false}
                  handleChange={handleChange}
                />
                <label
                  htmlFor={label.name}
                  data-testid="property-info-general-form-input-check-label">
                  {label.text}
                </label>
              </div>
            );
          })}
        <ValidationMessage invalidMessage={invalidMessage} />
        <p className={'input-checkbox-describe'}>{describe}</p>
      </div>
    </div>
  );
});
export default InputCheckBox;
