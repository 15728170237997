// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-form-input-wrap {
  margin-bottom: 16px;
}

.auth-form-input-label {
  font-size: 0.875rem;
  color: var(--text-base);
  line-height: 1.375rem;
  margin-bottom: 4px;
}

.auth-form-input {
  width: 100%;
  height: 40px;
  font-size: 1rem;
  padding: 0 12px;
  background: rgba(0,0,0,.06);
  border-radius: 5px 5px 0 0;
}

.auth-form-input-normal-border {
  border-bottom: solid 1px var(--text-base);
}

.auth-form-input-focus-border {
  border-bottom: solid 2px var(--primary-base);
}

.auth-form-input-focus-error-border {
  border-bottom: solid 2px var(--error-base);
}

.auth-form-input-blur-error-border {
  border-bottom: solid 1px var(--error-base);
}

.auth-form-input-message {
  min-height: 20px;
  font-size: 12px;
  line-height: 1.4em;
  margin-top: 4px;
}

.auth-form-input-caption-message {
  color: rgba(0,0,0,.6);
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/auth-form-input.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,eAAe;EACf,2BAA2B;EAC3B,0BAA0B;AAC5B;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".auth-form-input-wrap {\n  margin-bottom: 16px;\n}\n\n.auth-form-input-label {\n  font-size: 0.875rem;\n  color: var(--text-base);\n  line-height: 1.375rem;\n  margin-bottom: 4px;\n}\n\n.auth-form-input {\n  width: 100%;\n  height: 40px;\n  font-size: 1rem;\n  padding: 0 12px;\n  background: rgba(0,0,0,.06);\n  border-radius: 5px 5px 0 0;\n}\n\n.auth-form-input-normal-border {\n  border-bottom: solid 1px var(--text-base);\n}\n\n.auth-form-input-focus-border {\n  border-bottom: solid 2px var(--primary-base);\n}\n\n.auth-form-input-focus-error-border {\n  border-bottom: solid 2px var(--error-base);\n}\n\n.auth-form-input-blur-error-border {\n  border-bottom: solid 1px var(--error-base);\n}\n\n.auth-form-input-message {\n  min-height: 20px;\n  font-size: 12px;\n  line-height: 1.4em;\n  margin-top: 4px;\n}\n\n.auth-form-input-caption-message {\n  color: rgba(0,0,0,.6);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
