import 'interfaces/css/SampleGuideline/usage-caution-list.css';
const UsageCautionListAssetSale = () => {
  return (
    <div className="pt-6" data-testid="usage-caution-list-asset-sale-wrap">
      <div className="usage-caution-list-title">ご注意</div>
      <ul className="usage-caution-list pl-6">
        <li className="usage-caution-list-item">
          落札後の注意事項を作成する場合は●●部分に各行政機関様の名称を入れてください。
        </li>
        <li className="usage-caution-list-item">
          あくまでサンプルとなりますので、各行政機関様で追記、編集などをしていただいてご利用ください。
        </li>
        <li className="usage-caution-list-item">
          ただし、青字部分は自動で挿入されます。修正はできません。
        </li>
      </ul>
    </div>
  );
};
export default UsageCautionListAssetSale;
