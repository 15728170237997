import SmallTopicTitle from '../../../common/Heading/SmallTopicTitle.jsx';

const RightsTransferTimingAndRequiredPeriodAuction = () => {
  return (
    <div className="pt-9" data-testid="rights-transfer-timing-and-required-period">
      <SmallTopicTitle text='権利移転の時期' isFontBold={true}/>
      <p className="sample-after-winning-bid-lead-text pt-2" data-testid="sample-after-winning-bid-lead-text">買受代金を納付した時点で、その物件の所有権などの権利は落札者に移転します。<br/>※ただし、公売物件が農地の場合は都道府県知事などの許可などを受けた時点となります。</p>
    </div>
  );
};
export default RightsTransferTimingAndRequiredPeriodAuction;