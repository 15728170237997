// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-info-car-form-reg-number-checkbox{
    display: flex;
    padding-top: 8px;
}
.property-info-car-form-reg-number-checkbox .input-checkbox-slot{
    margin: 0;
}
.property-info-car-form-reg-number-checkbox .input-checkbox{
    padding:0;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/property-info-car-form-reg-number.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;AACpB;AACA;IACI,SAAS;AACb;AACA;IACI,SAAS;IACT,SAAS;AACb","sourcesContent":[".property-info-car-form-reg-number-checkbox{\n    display: flex;\n    padding-top: 8px;\n}\n.property-info-car-form-reg-number-checkbox .input-checkbox-slot{\n    margin: 0;\n}\n.property-info-car-form-reg-number-checkbox .input-checkbox{\n    padding:0;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
