// ロジックを含まない処理の実装

import { fetchGovernmentTopPagesStorage } from '../../../infrastructure/api/storage/government_top_pages.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { SettingGovernmentTopPageGet } from '../entities/Setting.js';

// getメソッドの実装
export async function getGovernmentTopPage(type) {
  // SAMPLE
  let response = [];
  if (type === SERVICE) {
    response = await fetchGovernmentTopPagesStorage();
  }
  if (type === NAVI) {
    response = await fetchGovernmentTopPagesStorage();
  }
  if (type === KMANAGER) {
    response = await fetchGovernmentTopPagesStorage();
  }

  if (response instanceof Error) {
    throw new CustomError('Error get Government', response);
  }

  let results = [];
  response.map((data) => {
    const result = new SettingGovernmentTopPageGet(data);
    results.push(result);
  });

  return results;
}
