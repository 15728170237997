import { notInclude, validateNumber, validateRangeLength } from "../../../../application/validations.js";

export class InquiryTel {
  #inquiryTel

  constructor(inquiryTel) {
    this.#inquiryTel = inquiryTel;
  }

  get() {
    return this.#inquiryTel
  }

  get isValid() {
    return [
      this.notIncludeSpace,
      this.validateNumber,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get notIncludeSpace() {
    return notInclude(this.#inquiryTel, [' '])
  }

  // バリデーションルールの適用
  get validateNumber() {
    return validateNumber(this.#inquiryTel)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#inquiryTel, 10, 11)
  }
}
