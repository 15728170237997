import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class ImportantDescription1 {
  #importantDescription1

  constructor(importantDescription1) {
    this.#importantDescription1 = importantDescription1;
  }

  get() {
    return this.#importantDescription1
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#importantDescription1, 0, 1000)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#importantDescription1)
  }
}
