import { validateNumber, validateRangeLength, validateDecimal } from "../../../../application/validations.js";

export class LandShare {
  #landShare

  constructor(landShare) {
    this.#landShare = landShare;
  }

  get() {
    return this.#landShare
  }

  get isValid() {
    return [
      this.validateNumber,
      this.validateRangeLength,
      this.validateDecimal,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateNumber() {
    return validateNumber(this.#landShare)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#landShare, 0, 10)
  }

  // バリデーションルールの適用
  get validateDecimal() {
    return validateDecimal(this.#landShare, 0)
  }
}
