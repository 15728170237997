import SmallTopicTitle from '../../../common/Heading/SmallTopicTitle.jsx';
import CautionTopicTable from '../../CautionTopicTable.jsx';
import ManualMarkedList from '../../ManualMarkedList.jsx';
import 'interfaces/css/common/caution-manual-marked-list.css';
import CautionManualMarkedList from '../../CautionManualMarkedList.jsx';

const ContractDepositData = [
  {
    mark: '・',
    text: '契約保証金は落札価格の10％以上の納付が必要です。',
  },
  {
    mark: '・',
    text: 'すでに納付済みの入札保証金を契約保証金とすることができます。その場合、入札保証金のほかに契約保証金を支払う必要はありません。',
  },
]

const RemainingAmountOfSalesProceedsData = [
  {
    mark: '・',
    text: '落札金額－契約保証金額（入札保証金額）',
  },
  {
    mark: '・',
    text: '落札金額残金は、執行機関の定める売払代金納付期限までに納付する必要があります。',
  },
]

const contractConclusionDeadlineData = [
  {
    headline: '契約保証金',
    content: <ManualMarkedList data={ContractDepositData}/>,
  },
  {
    headline: '収入印紙',
    content: '不動産売買契約書に貼付する収入印紙代',
  },
]

const paymentDeadlineData = [
  {
    headline: '売払代金の残金',
    content: <ManualMarkedList data={RemainingAmountOfSalesProceedsData}/>,
  },
  {
    headline: '登録免許税',
    content: '不動産登記の際にかかる国税',
  },
  {
    headline: '登記印紙代',
    content: '登記済み不動産登記簿謄本交付申請用登記印紙代',
  },
]

const NecessaryExpensesAssetSale = () => {
  return (
    <div data-testid="necessary-expenses" className="pt-9">
      <SmallTopicTitle text='必要な費用' isFontBold={true}/>
      <CautionTopicTable recordData={contractConclusionDeadlineData} caption="契約締結期限までに"/>
      <CautionTopicTable recordData={paymentDeadlineData} caption="代金納付期限までに"/>
      <CautionManualMarkedList title="ご注意" className="pt-3" data={[
        {
          text: '上記費用は、それぞれ必要な期限までに一括で納付してください。',
        },
        {
          text: 'なお、自動車の権利移転に伴う費用（自動車検査登録印紙および自動車審査証紙、自動車税環境性能割など）は落札者の負担となります。',
        },
        {
          text: '上記以外に書類の郵送料、振込手数料が必要になることがあります。',
        },
      ]} />

    </div>
  );
};
export default NecessaryExpensesAssetSale;