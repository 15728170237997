import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { findById as getDocumentSendPages } from '../../../../domain/Setting/services/DocumentSendPages.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { SERVICE, NAVI } from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { LocalGovernmentManagementSendDocumentsToContext } from '../entities/LocalGovernmentManagementSendDocumentsToContext.js';
const LocalGovernmentManagementSendDocumentsToProvider = ({
  type,
  children,
}) => {
  const { governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [documentSendPages, setDocumentSendPages] = useState([]);
  const { localStorageGovernmentId } = useLocalStorage();
  const { governmentId } = useParams();

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getDocumentSendPages(type, governmentId).then((result) => {
        setDocumentSendPages(result);
      });
    }, [governmentId]);
  }

  if (type === NAVI) {
    useEffect(() => {
      getDocumentSendPages(type, localStorageGovernmentId).then((result) => {
        setDocumentSendPages(result);
      });
    }, [localStorageGovernmentId]);
  }

  return (
    <LocalGovernmentManagementSendDocumentsToContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        documentSendPages,
      }}>
      {children}
    </LocalGovernmentManagementSendDocumentsToContext.Provider>
  );
};

export default LocalGovernmentManagementSendDocumentsToProvider;
