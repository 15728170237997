import KmanagerSimpleTable from './KmanagerSimpleTable.jsx';

const KmanagerTableInTable = ({ header, data, data_header }) => {
  return (
    <tr
      className="kmanager-table-record"
      data-testid="kmanager-table-record-table">
      <td data-testid="kmanager-table-td" style={{ width: '30%' }}>
        {header.text}
      </td>
      <td data-testid="kmanager-table-td" style={{ padding: 0 }}>
        <div style={{ maxWidth: '700px' }}>
          <KmanagerSimpleTable
            data={data}
            headers={data_header}
            column={header.column}
          />
        </div>
      </td>
    </tr>
  );
};

export default KmanagerTableInTable;
