import RequiredLongLabel from 'interfaces/components/common/RequiredLabel/RequiredLongLabel.jsx';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault.jsx';
//import DatePickerForm from 'interfaces/components/Form/service/DatePickerForm.jsx';
import DatePickerForm from 'interfaces/components/common/Form/DatePickerForm.jsx';
import { useEffect, useState } from 'react';

import DeliveryTimeScheduleForm from './DeliveryTimeScheduleForm.jsx';
import { paymentStatuses } from './SelectPaymentStatusInput.jsx';

const DeliveryScheduleForm = ({
  deliverySchedule,
  setDeliverySchedule,
  paymentData,
  carrier,
}) => {
  const [dateRange, setDateRange] = useState({});

  useEffect(() => {
    if (paymentData.target === paymentStatuses[1].value) {
      const paymentDate = new Date(paymentData.paymentScheduledDate);
      setDateRange({
        minDateTime: new Date(paymentDate.setDate(paymentDate.getDate() + 5)),
        maxDateTime: new Date(paymentDate.setDate(paymentDate.getDate() + 6)),
      });
      return;
    }

    const today = new Date(new Date().setHours(0, 0, 0, 0));
    setDateRange({
      minDateTime: new Date(today.setDate(today.getDate() + 5)),
      maxDateTime: new Date(today.setDate(today.getDate() + 6)),
    });
  }, [paymentData]);

  const handler = (obj) => {
    setDeliverySchedule({ ...deliverySchedule, ...obj });
  };

  console.log(deliverySchedule);

  return (
    <CommonTableDefault>
      <DatePickerForm
        date={deliverySchedule?.deliveryDate}
        handleChange={(selectDate) => handler({ deliveryDate: selectDate })}
        label={
          <p>
            希望日 <RequiredLongLabel />
          </p>
        }
        minDateTime={dateRange.minDateTime}
        maxDateTime={dateRange.maxDateTime}
      />
      <DeliveryTimeScheduleForm
        carrier={carrier}
        deliveryTime={deliverySchedule?.deliveryTime}
        handleSelect={(item) => handler({ deliveryTime: item })}
      />
    </CommonTableDefault>
  );
};

export default DeliveryScheduleForm;
