import { Link } from 'react-router-dom';
import { procedureTypeParam } from 'shared/utils/helper/procedure_type_param.js';

import styled from 'styled-components';

const LinksPartition = styled.span`
  width: 2px;
  background-color: var(--bgcolor-darken2);
  margin: 4px 24px;
`;

const RegistrationInfoPageFooter = ({ data }) => {
  return (
    <div className="flex justify-center">
      <Link
        to={`/gov/${data.governmentId}?p=${procedureTypeParam(data.procedureType)}`}>
        {data.governmentName}物件一覧
      </Link>
      <LinksPartition />
      <Link to="/">KSI官公庁オークショントップ</Link>
    </div>
  );
};

export default RegistrationInfoPageFooter;
