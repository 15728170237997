import "interfaces/css/SampleGuideline/guideline-usage-caution-list.css"

const GuidelineUsageCautionListAssetSale = () => {
  return (
    <div className="pt-6" data-testid="guideline-usage-caution-list-asset-sale">
      <div className="guideline-usage-caution-list-title">＜ご説明・ご注意事項＞</div>
      <ul className="guideline-usage-caution-list pl-6">
        <li className="guideline-usage-caution-list-item">表示されたサンプルページの内容をコピーし貼り付け利用することができます。</li>
        <li className="guideline-usage-caution-list-item">青字部分は自動で挿入されます。修正はできません。</li>
        <li className="guideline-usage-caution-list-item">ガイドライン文末の青字部分ガイドライン文末の「インターネット公有財産売却における個人情報について」、「クレジットカードで入札保証金を納付する場合」は、サービスの運営上必要な文章となりますので、削除をせずにそのまま掲載ください。</li>
        <li className="guideline-usage-caution-list-item">ガイドラインを作成する場合は●●部分に各行政機関様の名称などを入れてください。（青字部分の●●は行政機関名が自動で挿入されます。修正はできません。）</li>
        <li className="guideline-usage-caution-list-item">「ガイドラインサンプル」内の提出文書名、規則名、手続きに要する日数などは、一例として記載しております。必要に応じて各行政機関様ごとに追記、修正、編集をしてご利用ください。</li>
        <li className="guideline-usage-caution-list-item">各行政機関様のホームページ等でご利用いただけるバナーは、官公庁ナビの中でご案内しております。</li>
      </ul>
    </div>
  );
};
export default GuidelineUsageCautionListAssetSale;