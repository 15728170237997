import "../../css/common/property-info-form-label.css"

const PropertyInfoFormLabel = ({label, required}) => {
    return (
        <div className="property-info-form-label">
            <span data-testid="property-info-form-label">{label}</span>
            {required ? <span data-testid="form-asterisk" style={{color: "red"}}>*</span> : ""}
        </div>
    );
}
export default PropertyInfoFormLabel
