import { useEffect, useState } from 'react';
import { useError } from 'shared/hooks/useError.js';
//import { getApplicantPropertyIds } from '../read_storage/applicant_property_ids.js';

export const useApplicantPropertyIds = (userId) => {
  const { setError } = useError();
  const [applicantPropertyIds, setApplicantPropertyIds] = useState([]);

  useEffect(() => {
    //const applicantPropertyIds = async (userId) => {
    const applicantPropertyIds = async () => {
      try {
        //const applicantPropertyIds = await getApplicantPropertyIds(userId);
        //setApplicantPropertyIds(applicantPropertyIds);
        setApplicantPropertyIds([]);
      } catch (error) {
        setError(error);
      }
    };
    applicantPropertyIds(userId).then();
  }, []);

  return applicantPropertyIds;
};
