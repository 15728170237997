import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class ContactName {
  #contactName

  constructor(contactName) {
    this.#contactName = contactName;
  }

  get() {
    return this.#contactName
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#contactName, 1, 50)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#contactName)
  }
}
