import AfterWinningBidModal from './AfterWinningBidModal.jsx';
// providerに持っていく
//import useAfterWinningBid from './hooks/useAfterWinningBid.js';

const AfterWinningBidLink = ({
  governmentId = '2',
  governmentName = 'mock government',
  procedureType = 'PUBLIC_SALE',
  //publicSaleType,
  afterWinningBids = [
    // mock
    {
      data: {
        costAttention: 'テスト',
        costContractDescription1: 'テスト',
        costContractDescription2: 'テスト',
        costContractDescription3: 'テスト',
        costContractTitle1: 'テスト',
        costContractTitle2: 'テスト',
        costContractTitle3: 'テスト',
        costPaymentDescription1: 'テスト',
        costPaymentDescription2: 'テスト',
        costPaymentDescription3: 'テスト',
        costPaymentDescription4: 'テスト',
        costPaymentDescription5: 'テスト',
        costPaymentTitle1: 'テスト',
        costPaymentTitle2: 'テスト',
        costPaymentTitle3: 'テスト',
        costPaymentTitle4: 'テスト',
        costPaymentTitle5: 'テスト',
        documentDescription: 'テスト',
        documentPageDisplay: 'DISPLAY',
        documentPageName: 'テスト',
        documentPageUrl: 'https://example.com/',
        importantAttention: 'テスト',
        importantDescription1: 'テスト',
        importantDescription2: 'テスト',
        importantDescription3: 'テスト',
        importantHandlingBidDeposit: 'テスト',
        importantHandlingContractDeposit: 'テスト',
        importantOther1: 'テスト',
        importantOther2: 'テスト',
        importantOther3: 'テスト',
        importantRestrictionUse: 'テスト',
        importantRestrictionUseBefore: 'テスト',
        importantRiskBearing: 'テスト',
        importantTermsDelivery: 'テスト',
        inquiryAccept: '午前8:00 〜　午後17:01',
        inquiryDisplay: 'DISPLAY',
        inquiryEmail: 'app-check-001@kankocho.jp',
        inquiryExtension: '123',
        inquiryName: 'テスト',
        inquiryTel: '987-654-321',
        otherTransRightAttention: 'テスト',
        otherTransRightDescription: 'テスト',
        transRightCompletedTime: 'テスト',
        transRightDate: 'テスト',
        transRightDescription: 'テスト',
      },
      governmentId: 2,
      procedureType: '公有財産売却',
      publicSaleType: 'せり売形式',
    },
    {
      data: {
        costAttention: 'テスト',
        costContractDescription1: 'テスト',
        costContractDescription2: 'テスト',
        costContractDescription3: 'テスト',
        costContractTitle1: 'テスト',
        costContractTitle2: 'テスト',
        costContractTitle3: 'テスト',
        costPaymentDescription1: 'テスト',
        costPaymentDescription2: 'テスト',
        costPaymentDescription3: 'テスト',
        costPaymentDescription4: 'テスト',
        costPaymentDescription5: 'テスト',
        costPaymentTitle1: 'テスト',
        costPaymentTitle2: 'テスト',
        costPaymentTitle3: 'テスト',
        costPaymentTitle4: 'テスト',
        costPaymentTitle5: 'テスト',
        documentDescription: 'テスト',
        documentPageDisplay: 'DISPLAY',
        documentPageName: 'テスト',
        documentPageUrl: 'https://example.com/',
        importantAttention: 'テスト',
        importantDescription1: 'テスト',
        importantDescription2: 'テスト',
        importantDescription3: 'テスト',
        importantHandlingBidDeposit: 'テスト',
        importantHandlingContractDeposit: 'テスト',
        importantOther1: 'テスト',
        importantOther2: 'テスト',
        importantOther3: 'テスト',
        importantRestrictionUse: 'テスト',
        importantRestrictionUseBefore: 'テスト',
        importantRiskBearing: 'テスト',
        importantTermsDelivery: 'テスト',
        inquiryAccept: '午前8:00 〜　午後17:01',
        inquiryDisplay: 'DISPLAY',
        inquiryEmail: 'app-check-001@kankocho.jp',
        inquiryExtension: '123',
        inquiryName: 'テスト',
        inquiryTel: '987-654-321',
        otherTransRightAttention: 'テスト',
        otherTransRightDescription: 'テスト',
        transRightCompletedTime: 'テスト',
        transRightDate: 'テスト',
        transRightDescription: 'テスト',
      },
      governmentId: 2,
      procedureType: '公有財産売却',
      publicSaleType: 'せり売形式',
    },
  ],
}) => {
  if (!afterWinningBids || afterWinningBids?.length === 0) {
    return;
  }
  if (afterWinningBids?.length === 1) {
    return (
      <div className="after-winning-bid-link">
        <AfterWinningBidModal
          afterWinningBid={afterWinningBids[0]}
          governmentId={governmentId}
          governmentName={governmentName}
          procedureType={procedureType}
          label="落札後の注意事項"
        />
      </div>
    );
  }
  return (
    <div className="after-winning-bid-link">
      <div className="flex">
        <span>落札後の注意事項&emsp;[&emsp;</span>
        <span>
          <AfterWinningBidModal
            afterWinningBid={afterWinningBids[0]}
            governmentId={governmentId}
            governmentName={governmentName}
            procedureType={procedureType}
            label="せり売形式"
          />
        </span>
        <span>&emsp;/&emsp;</span>
        <span>
          <AfterWinningBidModal
            afterWinningBid={afterWinningBids[1]}
            governmentId={governmentId}
            governmentName={governmentName}
            procedureType={procedureType}
            label="入札形式"
          />
        </span>
        <span>&emsp;]</span>
      </div>
    </div>
  );
};
export default AfterWinningBidLink;
