import PropertyInfoCarProvider from './PropertyInfoCarProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { propertyInfoCar } from "../entities/componentMapping.js";

const PropertyInfoCar = ({ type }) => {
  return (
    <PropertyInfoCarProvider type={type}>
      {getComponent(propertyInfoCar, type)}
    </PropertyInfoCarProvider>
  );
};

export default PropertyInfoCar;
