import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_CAR_PROPERTIES = `${BASE_URL_NAVIGATION}/storage/current/car_properties/data.json`;
const PREVIOUS_CAR_PROPERTIES = `${BASE_URL_NAVIGATION}/storage/previous/car_properties/data.json`;

export const fetchCarPropertiesStorage = async () => {
  try {
    const response = await fetchCurrentCarProperties();
    return extractCarProperties(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ car_properties ）を取得できませんでした");

    try {
      const response = await fetchPreviousCarProperties();
      return extractCarProperties(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ car_properties ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchCurrentCarProperties = async () => {
  return fetchCarProperties(CURRENT_CAR_PROPERTIES);
};

export const fetchPreviousCarProperties = async () => {
  return fetchCarProperties(PREVIOUS_CAR_PROPERTIES);
};

export const fetchCarProperties = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractCarProperties = (response) => {
  return response.map(car_property => car_property);
}