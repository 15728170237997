import { ReactComponent as ArrowRightIcon } from 'interfaces/assets/svg/arrow_back_icon.svg';
import BackButton from 'interfaces/components/common/BackButton';
import Button from 'interfaces/components/common/Button';
import ButtonList from 'interfaces/components/common/ButtonList';
import GovernmentFilter from 'interfaces/components/common/GovernmentFilter';
import ManagementDataList from 'interfaces/components/common/ManagementDataList';
import PageTitle from 'interfaces/components/common/PageTitle';
import { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AuctionGovernmentTable from './AuctionGovernmentTable';
import { AuctionGovernmentContext } from '../../../../pages/AuctionGovernment/entities/AuctionGovernmentContext';

import 'interfaces/css/common/kmanager-common.css';

import Toolbar from 'interfaces/components/common/Toolbar';
import AuctionSwitcher from 'interfaces/components/common/Switcher/AuctionSwitcher';
import { GovernmentTypes } from 'shared/utils/constants/government';
//import KmanagerExpandTable from "interfaces/components/common/Table/KmanagerExpandTable";

const TOP_PATH = '/kmanager';

const AuctionGovernments = () => {
  const { auctionId } = useParams();
  const navigation = useNavigate();
  const { auction, invitationCounts, governments } = useContext(
    AuctionGovernmentContext,
  );

  const [filteredGovernments, setFilteredGovernments] = useState(governments);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setFilteredGovernments(
      governments.filter((government) => government.name.includes(searchInput)),
    );
  }, [searchInput, governments]);

  const JOIN_PATH = () => {
    return `/kmanager/auction/${auctionId}/entry`;
  };

  const managementData = [
    [
      {
        value: '参加申込行政機関',
        colSize: 2,
        colMdSize: null,
      },
      {
        value: invitationCounts?.registration,
        colSize: 2,
        colMdSize: null,
      },
      {
        value: '参加行政機関',
        colSize: 2,
        colMdSize: null,
      },
      {
        value: invitationCounts?.participation,
        colSize: 2,
        colMdSize: null,
      },
      {
        value: '不参加行政機関',
        colSize: 2,
        colMdSize: null,
      },
      {
        value: invitationCounts?.noParticipation,
        colSize: 2,
        colMdSize: null,
      },
    ],
  ];

  //  useEffect(() => {
  //      let government = [];
  //      let auctionData = {
  //        registrationGovernments: "-",
  //        participationGovernments: "-",
  //        noParticipationGovernments: "-"
  //      };
  //
  //      (async () => {
  //
  //        // registration : invitation の数
  //        // participation : invitation.participationStatus == PARTICIPATION の数
  //        // noParticipation : inviaation.participationStatus == NO_PARTICIPATION の数
  //        const notGovernmentRequests = [fetchProcedureType(id), fetchRegistrationGovernments(id), fetchParticipationGovernments(id), fetchNoParticipationGovernments(id)]
  //
  //        const allRequests = [fetchLocations(id, "ASC"), fetchPrefectures(id, "ASC"), fetchNames(id, "ASC"), fetchParticipationStatus(id, "ASC"), fetchProceduresAuction(id, "ASC"), fetchProceduresBid(id, "ASC"), fetchMailingAuthPapers(id, "ASC")]
  //
  //        Promise.all(notGovernmentRequests)
  //          .then((notGovernmentResults) => {
  //            notGovernmentResults.map((result) => {
  //              auctionData = Object.assign(auctionData, result.data.data)
  //            })
  //          })
  //          .catch(err => {
  //            handleError(err)
  //          });
  //
  //        await Promise.all(allRequests)
  //          .then((allResults) => {
  //            allResults.map((result) => {
  //              government = mergeObjects({ "data": government }, result.data)
  //            })
  //          })
  //          .catch(err => {
  //            handleError(err)
  //          });
  //
  //        try {
  //          const auctionName = await fetchAuctionData(id, "name")
  //          setAuctionName(auctionName.data.data.name)
  //        } catch (err) {
  //          handleError(err);
  //        }
  //
  //        const managementData = [
  //          [
  //            {
  //              value: '参加申込行政機関',
  //              colSize: 2,
  //              colMdSize: null,
  //            },
  //            {
  //              value: auctionData.registrationGovernments,
  //              colSize: 2,
  //              colMdSize: null,
  //            },
  //            {
  //              value: '参加行政機関',
  //              colSize: 2,
  //              colMdSize: null,
  //            },
  //            {
  //              value: auctionData.participationGovernments,
  //              colSize: 2,
  //              colMdSize: null,
  //            },
  //            {
  //              value: '不参加行政機関',
  //              colSize: 2,
  //              colMdSize: null,
  //            },
  //            {
  //              value: auctionData.noParticipationGovernments,
  //              colSize: 2,
  //              colMdSize: null,
  //            },
  //          ]
  //        ]
  //        setManagementData(managementData);
  //
  //        try {
  //          const auctionGovernmentTypeGroup = await fetchAuctionData(id, "government-type-group")
  //          setGovernmentTypeGroup(auctionGovernmentTypeGroup.data.data.governmentTypeGroup)
  //          auctionGovernmentTypeGroup.data.data.governmentTypeGroup === 'NATIONAL_TAX_AGENCY' && setIsNationalTaxAgency(true);
  //          getHeader(auctionGovernmentTypeGroup.data.data.governmentTypeGroup);
  //
  //          if (auctionGovernmentTypeGroup.data.data.governmentTypeGroup === "NATIONAL_TAX_AGENCY") {
  //            setGovernments(government.filter((item) => item.column !== 'callUpButtonDownBidDate' && item.column !== 'mailReductionDocument'))
  //            return;
  //          }
  //          setGovernments(government)
  //        } catch (err) {
  //          handleError(err);
  //        }
  //      })()
  //    }, []
  //  );

  // eslint-disable-next-line no-unused-vars
  //const { downloadCertificates, downloadCertificateDestinations, loading } = useDownloadFiles(handleError);

  const joinAuction = () => {
    navigation(JOIN_PATH(auctionId));
  };

  const FETCH_BUTTONS_DATA = [
    {
      text: '認証書面(保証契約書)表紙一括ダウンロード',
      //clickHandler: () => downloadCertificates('認証書面(保証契約書)表紙一括.json'),
      clickHandler: () => {},
      className: 'small primary',
    },
    {
      text: '認証書面送付先CSV一括ダウンロード',
      //clickHandler: () => downloadCertificateDestinations('認証書面送付先CSV一括.json'),
      clickHandler: () => {},
      className: 'small primary',
    },
    {
      text: 'オークション参加申込登録',
      clickHandler: joinAuction,
      className: 'small primary',
      isDisabled: true,
    },
  ];

  const FETCH_NATIONAL_TAX_AGENCY_BUTTONS_DATA = [
    {
      text: '公売保証金納付保証契約証明書ダウンロード',
      //clickHandler: () => downloadCertificates('公売保証金納付保証契約証明書.json'),
      clickHandler: () => {},
      className: 'small primary',
    },
    {
      text: 'オークション参加申込登録',
      clickHandler: joinAuction,
      className: 'small primary',
      isDisabled: false,
    },
  ];

  return (
    <div
      className="kmanager-auction-government kmanager-auction-operation"
      data-testid="kmanager-auction-government">
      <div className="kmanager-row">
        <div className="kmanager-col kmanager-col-6">
          <BackButton
            text="オークション実施一覧"
            svg={<ArrowRightIcon title="back-to-page" />}
            handleClick={() => navigation(TOP_PATH)}
          />
          <PageTitle text={auction.name ?? ''} />
          <Toolbar>
            <AuctionSwitcher
              id={auction.id}
              activeIndex={1}
              auctionGovernmentTypeGroup={auction?.governmentTypeGroup}
            />
          </Toolbar>
        </div>

        <div className="kmanager-col kmanager-col-6">
          <GovernmentFilter
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
        </div>

        <div className="kmanager-col kmanager-col-12">
          <div className="box-shadow">
            {/*error?.code && <PageTitle text={error.message}/>*/}

            <div data-testid="kmanager-fetch-button-list">
              <ButtonList>
                {auction.governmentTypeGroup ===
                  GovernmentTypes.NATIONAL_TAX_AGENCY.string &&
                  FETCH_NATIONAL_TAX_AGENCY_BUTTONS_DATA.map((item, index) => (
                    <Button
                      key={index}
                      text={item.text}
                      clickHandler={item.clickHandler}
                      isOutline={item.isOutline}
                      isDisabled={item.isDisabled}
                      className={item.className}
                    />
                  ))}
                {auction.governmentTypeGroup !==
                  GovernmentTypes.NATIONAL_TAX_AGENCY.string &&
                  FETCH_BUTTONS_DATA.map((item, index) => (
                    <Button
                      key={index}
                      text={item.text}
                      clickHandler={item.clickHandler}
                      isOutline={item.isOutline}
                      isDisabled={item.isDisabled}
                      className={item.className}
                    />
                  ))}
              </ButtonList>
            </div>

            <div className="mb-5">
              <ManagementDataList data={managementData} />
            </div>
            <AuctionGovernmentTable
              auction={auction}
              governments={filteredGovernments}
            />
            {/*
            <KmanagerExpandTable headers={header} data={governments} links={dummyExpandItem} auctionId={id}/>
            */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionGovernments;
