import { validateRangeLength, notInclude, validateExtensionFormat } from "../../../../application/validations.js";

export class InvoiceExtension {
  #invoiceExtension

  constructor(invoiceExtension) {
    this.#invoiceExtension = invoiceExtension;
  }

  get() {
    return this.#invoiceExtension
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.notIncludeSpace,
      this.validateExtensionFormat,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#invoiceExtension, 0, 10)
  }

  // バリデーションルールの適用
  get notIncludeSpace() {
    return notInclude(this.#invoiceExtension, [' '])
  }

  // バリデーションルールの適用
  get validateExtensionFormat() {
    return validateExtensionFormat(this.#invoiceExtension)
  }
}
