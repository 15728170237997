import { useState } from 'react';
//import { logInput } from "../../utils/log/logInputs.js";
import * as Validation from 'shared/utils/helper/naviValidators';

export const BankProvisionalApplyCompletionForm = () => {
  const [auctionBankTransferUrlRequiredValid, setAuctionBankTransferUrlRequiredValid] = useState(null);
  const [auctionJointBiddingUrlValid, setAuctionJointBiddingUrlValid] = useState(null);
  const [bidBankTransferUrlRequiredValid, setBidBankTransferUrlRequiredValid] = useState(null);
  const [bidJointBiddingUrlValid, setBidJointBiddingUrlValid] = useState(null);

  function handleAuctionBankTransferUrlValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionBankTransferUrlRequiredValid(invalid);
      return;
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateBankTransferUrlRequiredValidUrl(
        e.target.value,
      );
      if (invalid !== 'TRUE') {
        setAuctionBankTransferUrlRequiredValid(invalid);
        return;
      }
      invalid = Validation.validateBankTransferUrlRequiredLength(
        e.target.value,
      );
      if (invalid !== 'TRUE') {
        setAuctionBankTransferUrlRequiredValid(invalid);
        return;
      }
    }
    setAuctionBankTransferUrlRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionJointBiddingUrlValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateJointBiddingUrlValidUrl1(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionJointBiddingUrlValid(invalid);
        return;
      }
      invalid = Validation.validateJointBiddingUrlLength1(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionJointBiddingUrlValid(invalid);
        return;
      }
    }
    setAuctionJointBiddingUrlValid(invalid); // エラーメッセージのリセット
  }

  function handleBidBankTransferUrlValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setBidBankTransferUrlRequiredValid(invalid);
      return;
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateBankTransferUrlRequiredValidUrl(
        e.target.value,
      );
      if (invalid !== 'TRUE') {
        setBidBankTransferUrlRequiredValid(invalid);
        return;
      }
      invalid = Validation.validateBankTransferUrlRequiredLength(
        e.target.value,
      );
      if (invalid !== 'TRUE') {
        setBidBankTransferUrlRequiredValid(invalid);
        return;
      }
    }
    setBidBankTransferUrlRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleBidJointBiddingUrlValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateJointBiddingUrlValidUrl1(e.target.value);
      if (invalid !== 'TRUE') {
      setBidJointBiddingUrlValid(invalid);
      return;
      }
      invalid = Validation.validateJointBiddingUrlLength1(e.target.value);
      if (invalid !== 'TRUE') {
        setBidJointBiddingUrlValid(invalid);
        return;
      }
    }
    setBidJointBiddingUrlValid(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      handleAuctionBankTransferUrlValid,
      handleAuctionJointBiddingUrlValid,
      handleBidBankTransferUrlValid,
      handleBidJointBiddingUrlValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      auctionBankTransferUrlRequiredValid,
      auctionJointBiddingUrlValid,
      bidBankTransferUrlRequiredValid,
      bidJointBiddingUrlValid,
    },
  };

  return validations;
};
