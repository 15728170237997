// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-detail .items-bottom-action-area .items-action-area-prices .items-action-area-prices__main-list .items-action-area-price {
    flex-grow: 1;
}
.property-detail .items-bottom-action-area .items-action-area-prices .items-action-area-price {
    border-bottom: 1px solid #e8e6e6;
    padding-bottom: 8px;
    margin-bottom: 16px;
    margin-left: 8px;
    margin-right: 8px;
    padding-right: 8px;
    width: auto;
}
.property-detail .items-bottom-action-area .items-action-area-prices .items-action-area-prices__main-list .price {
    font-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertyDetail/action-item.css"],"names":[],"mappings":"AAAA;IAGI,YAAY;AAChB;AACA;IACI,gCAAgC;IAChC,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".property-detail .items-bottom-action-area .items-action-area-prices .items-action-area-prices__main-list .items-action-area-price {\n    -webkit-box-flex: 1;\n    -ms-flex-positive: 1;\n    flex-grow: 1;\n}\n.property-detail .items-bottom-action-area .items-action-area-prices .items-action-area-price {\n    border-bottom: 1px solid #e8e6e6;\n    padding-bottom: 8px;\n    margin-bottom: 16px;\n    margin-left: 8px;\n    margin-right: 8px;\n    padding-right: 8px;\n    width: auto;\n}\n.property-detail .items-bottom-action-area .items-action-area-prices .items-action-area-prices__main-list .price {\n    font-size: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
