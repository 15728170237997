
const TextCounter = ({ count, isOverCount }) => {
  return (
    <div
      className={`text-counter ${isOverCount ? 'over-count' : ''}`}
      data-testid="text-counter"
    >
      残り{count}文字
    </div>
  );
}
export default TextCounter;