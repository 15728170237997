const Tab = ({ tabIndex, label, isActive, setActiveTab, className }) => {
  let toggleClass = isActive ? 'active-tab' : 'inactive-tab';

  return (
    <div
      className={className ? className : `tab ${toggleClass} flex align-center`}
      onClick={() => setActiveTab ? setActiveTab(tabIndex) : null}
      data-testid="tab"
    >
      {label}
    </div>
  );
};

export default Tab;
