import { ReactComponent as BeginnerIcon } from 'interfaces/assets/svg/detail_beginner_icon.svg';
import URL_LINKS from 'interfaces/pages/Dashboard/entities/externalLinks.js';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { calc_dead_line } from 'shared/utils/helper/calc_dead_line.js';

import 'interfaces/css/common/hero-area.css';
import { AuctionHelpBtnBranch } from './AuctionHelpBtnBranch.jsx';
import { AuctionItem } from './AuctionItem.jsx';
import { HeroAreaIcon } from './HeroAreaIcon.jsx';
import { MylistBtnBranch } from './MylistBtnBranch.jsx';
import { AuctionBtnBranch } from '../ActionArea/AuctionBtnBranch.jsx';
import { useLocalStorage } from 'shared/hooks/useLocalStorage.js';

import { FINAL_APPLY } from 'domain/Property/entities/constants/property.js';

const HeroArea = ({ data, isContainBlockList }) => {
  const [deadline, setDeadline] = useState('');
  const [isRemainingAreaDisable, setIsRemainingAreaDisable] = useState(false);
  const [isMyListBtn, setIsMyListBtn] = useState(false);
  const { localStorageUserId } = useLocalStorage();

  useEffect(() => {
    if (
      data?.remainingLabel === '入札締切まであと' ||
      data?.remainingLabel === '追加入札締切まであと' ||
      data?.remainingLabel === '申込締切まであと' ||
      data?.remainingLabel === '入札開始まであと'
    ) {
      setIsRemainingAreaDisable(true);
    }
    if (data?.aplStatus === FINAL_APPLY) {
      setIsMyListBtn(true);
    } else {
      setIsMyListBtn(false);
    }
    if (data?.deadline) {
      setDeadline(calc_dead_line(data?.deadline));
      const intervalId = setInterval(() => {
        setDeadline(calc_dead_line(data?.deadline));
      }, 60 * 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [data, localStorageUserId]);

  return (
    <div
      className="items-hero-action-area noshrink nogrow pa-4 v-sheet theme--light ml-1"
      style={{
        minWidth: '300px',
        maxWidth: '340px',
        width: '25%',
        border: '1px solid #e8e6e6',
      }}>
      <div className="items-action-area-prices is--pc is--text-right">
        <AuctionItem data={data} userId={localStorageUserId} />
      </div>
      {isRemainingAreaDisable && deadline && (
        <div className="items-action-area-remaining mb-6">
          <div className="v-subheader pa-0 label theme--light">
            {data?.remainingLabel}
          </div>
          <div className={'hero-area-bid-deadline'}>{deadline}</div>
        </div>
      )}
      <AuctionBtnBranch
        data={data}
        isContainBlockList={isContainBlockList}
        userId={localStorageUserId}
      />
      <MylistBtnBranch
        myListBtn={isMyListBtn}
        className={'hero-area-mylist-button'}>
        <span>マイリストに登録</span>
      </MylistBtnBranch>
      <div className="flex gaps">
        <Link
          to={URL_LINKS.BEGINNERS_URL}
          target="_blank"
          className="btn--frame pa-0 mt-3 grow text-caption btn--frame v-btn v-btn--depressed v-btn--flat v-btn--outlined v-size--large text--text"
          style={{ minWidth: '0px', width: 'auto' }}>
          <span className="v-btn__content">
            <BeginnerIcon />
            <span>初めての方へ</span>
          </span>
        </Link>
        <AuctionHelpBtnBranch data={data} />
      </div>
      <HeroAreaIcon data={data} />
    </div>
  );
};
export default HeroArea;
