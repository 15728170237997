import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_BIDDER = `${BASE_URL_NAVIGATION}/storage/current/bidders/data.json`;
const PREVIOUS_BIDDER = `${BASE_URL_NAVIGATION}/storage/previous/bidders/data.json`;

export const fetchBidderStorage = async () => {
  try {
    const response = await fetchBidder(CURRENT_BIDDER);
    return extractBidder(response);
  } catch (error) {
    logger.warn({ error }, "現在のデータ（ bidder ）を取得できませんでした");

    try {
      const response = await fetchBidder(PREVIOUS_BIDDER);
      return extractBidder(response);
    } catch (error) {
      logger.error({ error }, "以前のデータ（ bidder ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchBidder = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractBidder = (response) => {
  return response.map(bidder => bidder);
}