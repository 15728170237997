import { validatePrefecture } from "../../../../application/validations.js";

export class CertDocumentArea {
  #certDocumentArea

  constructor(certDocumentArea) {
    this.#certDocumentArea = certDocumentArea;
  }

  get() {
    return this.#certDocumentArea
  }

  get isValid() {
    return [
      this.validatePrefecture,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validatePrefecture() {
    return validatePrefecture(this.#certDocumentArea)
  }
}
