import Heading3 from 'interfaces/components/common/Heading/Heading3';
import AnchorButton from 'interfaces/components/common/AnchorButton';
import ImportantCaution from 'interfaces/components/common/ImportantCaution';

const TemplateGuidelineAssetSale = ({openModal}) => {
  return (
    <div>
      <div className="py-9">
        <ImportantCaution
          text={
            '●●●インターネット公有財産売却（以下「公有財産売却」といいます）をご利用いただくには、以下の「誓約書」および「●●●インターネット公有財産売却ガイドライン（以下「本ガイドライン」といいます）」をよくお読みいただき、同意していただくことが必要です。また、公有財産売却の手続きなどに関して、本ガイドラインとKSI官公庁オークションに関連する規約・ガイドラインなどとの間に差異がある場合は、本ガイドラインが優先して適用されます。'
          }
        />
        <Heading3
          text="誓約書"
          isBgGray={true}
          hasCopyButton={true}
          clickHandler={() => openModal('writtenOathTemplateGuidelineAssetSale')}
        />
        <p className="template-guideline-body pt-4">
          以下を誓約いたします。
          <br />
          今般、●●●の公有財産売却に参加するに当たっては、以下の事項に相違ない旨確約のうえ、公有財産売却ガイドラインおよび貴庁における入札、契約などに係る諸規定を厳守し、公正な入札をいたします。もし、これらに違反するようなことが生じた場合には、直ちに貴庁の指示に従い、当該執行機関に損害が発生したときは補償その他一切の責任をとることはもちろん、貴庁に対し一切異議、苦情などは申しません。
        </p>
        <p className="template-guideline-body pt-6">
          1.
          私は、地方自治法施行令（昭和22年政令第16号）第167条の4第1項に規定する一般競争入札に参加させることができない者および同条第2項各号に該当すると認められる者のいずれにも該当しません。
        </p>
        <p className="template-guideline-body pt-4">
          2. 私は、次に掲げる不当な行為は行いません。
          <br />
          （1）正当な理由がなく、当該入札に参加しないこと。
          （2）入札において、その公正な執行を妨げ、または公正な価格の成立を害し、もしくは不正な利益を得るために連合すること。
          （3）落札者が契約を締結することまたは契約者が契約を履行することを妨げること。
          （4）契約の履行をしないこと。
          （5）契約に違反し、契約の相手方として不適当と●●●に認められること。
          （6）入札に関し贈賄などの刑事事件を起こすこと。
          （7）社会的信用を失墜する行為をなし、契約の相手方として不適当と認められること。
          （8）天災その他不可抗力の事由がなく、履行遅延をすること。
        </p>
        <p className="template-guideline-body pt-4">
          3.
          私は、貴庁の公有財産売却に係る「公有財産売却ガイドライン」、「入札説明書」、「入札公告」、「売買契約書」の各条項を熟覧し、および貴庁の現地説明、入札説明などを傾聴し、これらについてすべて承知のうえ参加しますので、後日これらの事柄について貴庁に対し一切異議、苦情などは申しません。
        </p>
        <AnchorButton text="▲ 誓約書の先頭に戻る" />
      </div>
      <div data-testid="template-guideline-asset-sale1" className="pt-6">
        <Heading3
          text="●●●インターネット公有財産売却 ガイドライン"
          isBgGray={true}
        />
      </div>
      <Heading3
        text="第1 公有財産売却の参加条件など"
        isLeftBorderStyle={true}
        hasCopyButton={true}
        clickHandler={() => openModal('templateGuidelineAssetSale1')}
      />
      <div data-testid="template-guideline-asset-sale1_1" className="pt-4">
        <div className="template-guideline-section-title">
          1. 公有財産売却の参加条件
        </div>
        <p className="template-guideline-body pt-2">
          （以下のいずれかに該当する方は、公有財産売却へ参加することができません）
          <br />
          （1）地方自治法施行令第167条の4第1項各号または第2項各号該当すると認められる方
          <br />
          （参考：地方自治法施行令（抄））
          <br />
          （一般競争入札の参加者の資格）
          <br />
          第百六十七条の四
          普通地方公共団体は、特別の理由がある場合を除くほか、一般競争入札に次の各号のいずれかに該当する者を参加させることができない。
          <br />
          一 当該入札に係る契約を締結する能力を有しない者
          <br />
          二 破産手続開始の決定を受けて復権を得ない者
          <br />
          三
          暴力団員による不当な行為の防止等に関する法律（平成三年法律第七十七号）第三十二条第一項各号に掲げる者
          <br />
          ２
          普通地方公共団体は、一般競争入札に参加しようとする者が次の各号のいずれかに該当すると認められるときは、その者について三年以内の期間を定めて一般競争入札に参加させないことができる。その者を代理人、支配人その他の使用人又は入札代理人として使用する者についても、また同様とする。
          <br />
          一
          契約の履行に当たり、故意に工事若しくは製造その他役務を粗雑に行い、又は物件の品質若しくは数量に関して不正の行為をしたとき。
          <br />
          二
          競争入札又はせり売りにおいて、その公正な執行を妨げたとき又は公正な価格の成立を害し、若しくは不正の利益を得るために連合したとき。
          <br />
          三
          落札者が契約を締結すること又は契約者が契約を履行することを妨げたとき。
          <br />
          四
          地方自治法第二百三十四条の二第一項の規定による監督又は検査の実施に当たり職員の職務の執行を妨げたとき。
          <br />
          五 正当な理由がなくて契約を履行しなかつたとき。
          <br />
          六
          契約により、契約の後に代価の額を確定する場合において、当該代価の請求を故意に虚偽の事実に基づき過大な額で行つたとき。
          <br />
          七
          この項（この号を除く。）の規定により一般競争入札に参加できないこととされている者を契約の締結又は契約の履行に当たり代理人、支配人その他の使用人として使用したとき。
          <br />
          （2）日本語を完全に理解できない方
          <br />
          （3）●●●が定める本ガイドラインおよびKSI官公庁オークションに関連する規約・ガイドラインの内容を承諾せず、順守できない方
          <br />
          （4）公有財産の買受について一定の資格、その他の条件を必要とする場合でこれらの資格などを有していない方
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale1_2" className="pt-6">
        <div className="template-guideline-section-title">
          2. 公有財産売却の参加に当たっての注意事項
        </div>
        <p className="template-guideline-body pt-2">
          （1）公有財産売却は、地方自治法などの規定にのっとって●●●が執行する一般競争入札およびせり売り（以下「入札」という）の手続きの一部です。
          <br />
          （2）売払代金の残金の納付期限までにその代金を正当な理由なく納付しない落札者は、地方自治法施行令第167条の4第2項第5号に該当すると見なされ、一定期間●●●の実施する入札に参加できなくなることがあります。
          <br />
          （3）公有財産売却に参加される方は入札保証金を納付してください。
          <br />
          （4）公有財産売却に参加される方は、あらかじめインターネット公有財産売却システム（以下「売却システム」といいます）上の公有財産売却の物件詳細画面や●●●において閲覧に供されている入札の公告などを確認し、関係公簿などの閲覧などにより十分に調査を行ったうえで公有財産売却に参加してください。
          <br />
          また、入札の前に●●●が実施する現地説明会において、購入希望の財産を確認してください。
          <br />
          （5）売却システムは、紀尾井町戦略研究所株式会社の提供する売却システムを採用しています。公有財産売却の参加者は、売却システムの画面上で公有財産売却の参加申し込みなど一連の手続きを行ってください。
          <br />
          ア．参加仮申し込み
          <br />
          売却システムの売却物件詳細画面より公有財産売却の参加仮申し込みを行ってください。
          <br />
          イ．参加申し込み（本申し込み）
          <br />
          売却システムの公有財産売却の物件詳細画面より仮申し込みを行った後、●●●のホームページより「公有財産売却一般競争入札参加申込書兼入札保証金返還請求書兼口座振替依頼書（以下「申込書」といいます）」を印刷し、必要事項を記入・押印後、次のいずれかの書類（以下「必要書類」という）を添付のうえ、●●●に送付または持参してください。（郵送の場合は、申込締切日の消印有効）
          <br />
          （必要書類）
          <br />
          ※動産・自動車の場合：住民票の写し（法人の場合は商業登記簿謄本の写し）、印鑑登録証明書（印鑑証明書）の写し、免許証のコピー、住民基本台帳カードのコピー、パスポートのコピーのうちいずれか1通
          <br />
          ※不動産の場合：住民票（参加者が法人の場合は、商業登記簿謄本）および印鑑登録証明書
          <br />
          ・公有財産売却の各物件について入札保証金の納付方法をご確認のうえ、申込書の入札保証金納付方法欄にある「クレジットカード」「銀行振込」「その他」のうちご希望の方法いずれか一つに「○」をしてください。
          <br />
          ・複数の物件について申し込みをされる場合、公有財産売却の物件ごとに申込書が必要になりますが、添付書類である住民票および印鑑登録証明書などは1通のみ提出してください。
          <br />
          （6）公有財産売却においては、特定の物件（売却区分）の売却が中止になること、もしくは公有財産売却の全体が中止になることがあります。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale1_3" className="pt-6">
        <div className="template-guideline-section-title">
          3. 公有財産売却の財産の権利移転などについての注意事項
        </div>
        <p className="template-guideline-body pt-2">
          （1）落札後、契約を締結した時点で、落札者に公有財産売却の財産にかかる危険負担が移転します。したがって、契約締結後に発生した財産の破損、焼失など●●●の責に帰すことのできない損害の負担は、落札者が負うこととなり、売払代金の減額を請求することはできません。
          <br />
          （2）落札者が売払代金の残金を納付した時点で、所有権は落札者に移転します。
          <br />
          （動産・自動車の場合）
          <br />
          （3）公有財産が動産、自動車などである場合、●●●はその公有財産の引渡しを売払代金納付時の現状有姿で行います。
          <br />
          （4）公有財産が自動車の場合、落札者は「使用の本拠の位置」を管轄する運輸支局または自動車検査登録事務所に当該自動車を持ち込み、移転登録（名義変更）の手続き等を行ってください。
          <br />
          （不動産の場合）
          <br />
          （5）●●●は、売払代金の残金を納付した落札者の請求により、権利移転の登記を関係機関に嘱託します。
          <br />
          （6）原則として、物件にかかわる調査、土壌調査およびアスベスト調査などは行っておりません
          。また、開発など（建築など）に当たっては、都市計画法、建築基準法および条例などの法令により、規制がある場合があるので、事前に関係機関にご確認ください。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale1_4" className="pt-6">
        <div className="template-guideline-section-title">
          4. 個人情報の取り扱いについて
        </div>
        <p className="template-guideline-body pt-2">
          （1）公有財産売却に参加される方は、以下のすべてに同意するものとします。
          <br />
          ア．公有財産売却の参加申し込みを行う際に、住民登録などのされている住所、氏名など（参加者が法人の場合は、商業登記簿謄本に登記されている所在地、名称、代表者氏名）を公有財産売却の参加者情報として登録すること。
          <br />
          イ．入札者の公有財産売却の参加者情報およびログインIDに登録されているメールアドレスを●●●に開示され、かつ●●●がこれらの情報を●●●公文書管理規程に基づき、5年間保管すること。
          <br />
          ・●●●から公有財産売却の参加者に対し、ログインIDで認証されているメールアドレスに、公有財産売却の財産に関するお知らせなどを電子メールにて送信することがあります。
          <br />
          ウ．落札者に決定された公有財産売却の参加者のログインIDに紐づく会員識別番号を売却システム上において一定期間公開されること。
          <br />
          エ．●●●は収集した個人情報を地方自治法施行令第167条の4第1項に定める参加条件の確認または同条第2項に定める一般競争入札の参加者の資格審査のための措置などを行うことを目的として利用します。（地方自治法施行令第167条の14で準用する「せり売り」の場合も含みます）
          <br />
          （2）公有財産売却の参加者情報の登録内容が住民登録や商業登記簿謄本の内容などと異なる場合は、落札者となっても所有権移転などの権利移転登記を行うことができません。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale1_5" className="pt-6">
        <div className="template-guideline-section-title">
          5. 共同入札について
        </div>
        <p className="template-guideline-body font-weight-bold pt-4">
          （1）共同入札とは
        </p>
        <p className="template-guideline-body">
          一つの財産（不動産）を複数の者で共有する目的で入札することを共同入札といいます。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）共同入札における注意事項
        </p>
        <p className="template-guideline-body">
          ア．共同入札する場合は、共同入札者のなかから1名の代表者を決める必要があります。実際の公有財産売却の参加申し込み手続きおよび入札手続きをすることができるのは、当該代表者のみです。したがって、公有財産売却の参加申し込み手続きおよび入札手続きなどについては、代表者のログインIDで行うこととなります。手続きの詳細については、「第2
          公有財産売却の参加申し込みおよび入札保証金の納付について」および「第3
          入札形式で行う公有財産売却の手続き」をご覧ください。
          <br />
          イ．共同入札する場合は、共同入札者全員の印鑑登録証明書および共同入札者全員の住所（所在地）と氏名（名称）を連署した申込書を入札開始までに●●●に提出することが必要です。なお、申込書は●●●のホームページより印刷することができます。
          <br />
          ウ．申込書などに記載された内容が共同入札者の住民登録や商業登記簿の内容などと異なる場合は、共同入札者が落札者となっても権利移転登記を行うことができません。
        </p>
      </div>
      <AnchorButton text="▲ 第1の先頭に戻る" />
      <div data-testid="template-guideline-asset-sale2" className="pt-9">
        <Heading3
          text="第2 公有財産売却の参加申し込みおよび入札保証金の納付について"
          isLeftBorderStyle={true}
          hasCopyButton={true}
          clickHandler={() => openModal('templateGuidelineAssetSale2')}
        />
        <p
          data-testid="template-guideline-lead-text"
          className="template-guideline-lead-text pt-4">
          入札するには、公有財産売却の参加申し込みと入札保証金の納付が必要です。公有財産売却の参加申し込みと入札保証金の納付が確認できたログインIDでのみ入札できます。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale2_1" className="pt-6">
        <div className="template-guideline-section-title">
          1. 公有財産売却の参加申し込みについて
        </div>
        <p className="template-guideline-body pt-2">
          売却システムの画面上で、住民登録などのされている住所、氏名など（参加者が法人の場合は、商業登記簿謄本に登記されている所在地、名称、代表者氏名）を公有財産売却の参加者情報として登録してください。
          <br />
          ・法人で公有財産売却の参加申し込みする場合は、法人代表者名でログインIDを取得する必要があります。
          <br />
          ・共同入札する場合は、売却システムの画面上で、共同入札の欄の「する」を選択し、公有財産売却の参加申し込みを行ってください。また、共同入札者全員の印鑑登録証明書および申込書を入札開始2開庁日前までに●●●に提出することが必要です。原則として、入札開始2開庁日前までに●●●が提出を確認できない場合、入札をすることができません。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale2_2" className="pt-6">
        <div className="template-guideline-section-title">
          2. 入札保証金の納付について
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）入札保証金とは
        </p>
        <p className="template-guideline-body">
          地方自治法施行令第167条の7で定められている、入札する前に納付しなければならない金員です。入札保証金は、●●●が売却区分（公有財産売却の財産の出品区分）ごとに予定価格（最低落札価格）の〇分の〇以上の金額を定めます。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）入札保証金の納付方法
        </p>
        <p className="template-guideline-body">
          入札保証金の納付は、売却区分ごとに必要です。入札保証金は、●●●が売却区分ごとに指定する方法で納付してください。指定する方法は、下記のアのみ、イのみ、ウのみ、ア、イまたはウの3通りです。売却区分ごとに、売却システムの公有財産売却の物件詳細画面でどの方法が指定されているかを確認してください。
          <br />
          ・入札保証金には利息を付しません。
          <br />
          ・原則として、入札開始2開庁日前までに●●●が入札保証金の納付を確認できない場合、入札することができません。
          <br />
          ア．クレジットカードによる納付
          <br />
          クレジットカードで入札保証金を納付する場合は、売却システムの売却物件詳細画面より公有財産売却の参加仮申し込みを行い、入札保証金を所定の手続きに従って、クレジットカードにて納付してください。クレジットカードにより入札保証金を納付する公有財産売却の参加申込者は、紀尾井町戦略研究所株式会社に対し、クレジットカードによる入札保証金納付および返還事務に関する代理権を付与し、クレジットカードによる請求処理をSBペイメントサービス株式会社に委託することを承諾します。公有財産売却の参加申込者は、公有財産売却が終了し、入札保証金の返還が終了するまでこの承諾を取り消せないことに同意するものとします。
          <br />
          また、公有財産売却の参加申込者は、紀尾井町戦略研究所株式会社が入札保証金取り扱い事務に必要な範囲で、公有財産売却の参加申込者の個人情報をSBペイメントサービス株式会社に開示することに同意するものとします。
          <br />
          売却システムの公有財産売却の物件詳細画面より仮申し込みを行った後、●●●のホームページより申込書を印刷し、必要事項を記入・押印後、必要書類を添付のうえ、●●●に送付または持参してください。（郵送の場合は申込締切日の消印有効）
          <br />
          ・申込書の入札保証金納付方法欄の「クレジット」に「○」をしてください。
          <br />
          ・VISA、マスターカード、JCB、ダイナースカード、アメリカンエキスプレスカードの各クレジットカードを利用できます。（各クレジットカードでもごく一部利用できないクレジットカードがあります）
          <br />
          ・法人で公有財産売却に参加する場合、当該法人の代表者名義のクレジットカードをご使用ください。
          <br />
          イ．銀行振込による納付
          <br />
          銀行振込などで入札保証金を納付する場合は、売却システムの公有財産売却の物件詳細画面より公有財産売却の参加仮申し込みを行ってください。売却システムの公有財産売却の物件詳細画面より仮申し込みを行った後、●●●のホームページより申込書を印刷し、必要事項を記載・押印後、必要書類を添付のうえ、●●●に送付または持参してください。（郵送の場合は申込締切日の消印有効）
          <br />
          なお、銀行振込の場合は、公有財産売却の参加者より必要書類が●●●に到着後、●●●から「歳入歳出外現金収納済通知書」を送付しますので、必要事項を記入のうえ、●●●が指定する金融機関に入札保証金を納付してください。
          <br />
          ・銀行振込の際の振込手数料は公有財産売却の参加申込者の負担となります。
          <br />
          ・銀行口座への振込により入札保証金を納付する場合は、●●●が納付を確認できるまで3開庁日程度要することがあります。
          <br />
          ・申込書の入札保証金納付方法欄の「銀行振込」に「○」をしてください。
          <br />
          ・●●●が指定する金融機関については、下記を参照してください。
          <br />
          1 指定金融機関
          <br />
          ●●●
          <br />
          2 指定代理金融機関
          <br />
          ●●●
          <br />
          3 収納代理金融機関
          <br />
          株式会社●●●銀行、株式会社●●●銀行、株式会社●●●銀行
          <br />
          ※いずれも日本国内で業務を営むすべての店舗（代理店を除く）
          <br />
          ウ．その他の方法による納付
          <br />
          納付方法はクレジットカードおよび銀行振込以外に、現金書留、郵便為替（ゆうちょ銀行が取り扱う普通為替および定額小為替をいう。以下同じ）および直接持参があります。（物件ごとに指定されている場合があります）
          <br />
          ※売却システムで参加仮申し込みされる際には「銀行振り込みなど」を選択していただくことになります。
          <br />
          売却システムの公有財産売却の物件詳細画面より仮申し込みを行った後、●●●のホームページより申込書を印刷し、必要事項を記入・押印後、住民票（法人の場合は商業登記簿謄本）および印鑑登録証明書を添付のうえ、●●●に送付または持参してください。（郵送の場合は、申込締切日の消印有効）
          <br />
          （ア）現金書留または郵便為替の場合
          <br />
          最寄りのゆうちょ銀行で納付してください。
          <br />
          （イ）直接持参の場合
          <br />
          ●●●に直接持参してください。
          <br />
          ・申込書の入札保証金納付方法欄の「その他」に「○」をしてください。
          <br />
          ・現金書留による送付または直接持参により入札保証金を納付する場合、現金もしくは銀行振出の小切手（電子交換所に加入する銀行が振り出し、振出日より5日以内のもので、受取人は持参人払いとしたものに限ります）
          で●●●に納付してください。
          <br />
          ・郵便為替により入札保証金を納付する場合、郵便為替証書は、発行日から起算して175日を経過していないものに限ります。
          <br />
          ・現金書留の郵送料または為替手数料などについては、公有財産売却の参加申込者の負担となります。
          <br />
          ・直接持参の場合は、持ち込んだその日に金融機関に納入手続を行いますので、14時までに持ち込む必要があります。
          <br />
          ・郵便為替または現金書留により入札保証金を納付する場合は、原則として申込書、必要書類を同封してください。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）入札保証金の没収
        </p>
        <p className="template-guideline-body">
          公有財産売却の参加申込者が納付した入札保証金は、落札者が契約締結期限までに●●●の定める契約を締結しない場合は没収し、返還しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （4）入札保証金の契約保証金への充当
        </p>
        <p className="template-guideline-body">
          公有財産売却の参加申込者が納付した入札保証金は、落札者が契約を締結した場合、申請書に基づき、地方自治法施行令第167条の16に定める契約保証金に全額充当します。
        </p>
      </div>
      <AnchorButton text="▲ 第2の先頭に戻る" />
      <div data-testid="template-guideline-asset-sale3" className="pt-9">
        <Heading3
          text="第3 入札形式で行う公有財産売却の手続き"
          isLeftBorderStyle={true}
          hasCopyButton={true}
          clickHandler={() => openModal('templateGuidelineAssetSale3')}
        />
        <p
          data-testid="template-guideline-lead-text"
          className="template-guideline-lead-text pt-4">
          本章における入札とは、売却システム上で入札価格を登録することをいいます。この登録は、一度しか行うことができません。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale3_1" className="pt-6">
        <div className="template-guideline-section-title">
          1. 公有財産売却への入札
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）入札
        </p>
        <p className="template-guideline-body">
          入札保証金の納付が完了したログインIDでのみ、入札が可能です。入札は一度のみ可能です。一度行った入札は、入札者の都合による取り消しや変更はできませんので、ご注意ください。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）入札をなかったものとする取り扱い
        </p>
        <p className="template-guideline-body">
          ●●●は、地方自治法施行令第167条の4第1項などに規定する一般競争入札に参加できない要件に該当する者が行った入札について、当該入札を取り消し、なかったものとして取り扱うことがあります。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale3_2" className="pt-6">
        <div className="template-guideline-section-title">2. 落札者の決定</div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）落札者の決定
        </p>
        <p className="template-guideline-body">
          入札期間終了後、●●●は開札を行い、売却区分（公有財産売却の財産の出品区分）ごとに、売却システム上の入札において、入札価格が予定価格（最低落札価格）以上でかつ最高価格である入札者を落札者として決定します。ただし、最高価格での入札者が複数存在する場合は、くじ（自動抽選）で落札者を決定します。
          <br />
          なお、落札者の決定に当たっては、落札者のログインIDに紐づく会員識別番号を落札者の氏名（名称）とみなします。
          <br />
          ア．落札者の告知
          <br />
          落札者のログインIDに紐づく会員識別番号と落札価格については、売却システム上に一定期間公開します。
          <br />
          イ．●●●から落札者への連絡
          <br />
          落札者には、●●●から入札終了後、あらかじめログインIDで認証されたメールアドレスに、落札者として決定された旨の電子メールを送信します。共同入札者が落札者となった場合は、代表者にのみ落札者として決定された旨の電子メールを送信します。
          <br />
          ・●●●が落札者に送信した電子メールが、落札者によるメールアドレスの変更やプロバイダの不調などの理由により到着しないために、●●●が落札者による売払代金の残金の納付を売払代金の残金納付期限までに確認できない場合、その原因が落札者の責に帰すべきものであるか否かを問わず、契約保証金を没収し、返還しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）落札者決定の取り消し
        </p>
        <p className="template-guideline-body">
          入札金額の入力間違いなどの場合は、落札者の決定が取り消されることがあります。この場合、売却物件の所有権は落札者に移転しません。また、納付された入札保証金は原則返還しません。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale3_3" className="pt-6">
        <div className="template-guideline-section-title">3. 売却の決定</div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）落札者に対する売却の決定
        </p>
        <p className="template-guideline-body">
          ●●●は、落札後、落札者に対し電子メールなどにより契約締結に関する案内を行い、落札者と契約を交わします。
          <br />
          契約の際には●●●より契約書を送付しますので、落札者は必要事項を記入・押印のうえ、次の書類などを添付して●●●に直接持参または郵送してください。
          <br />
          ア．必要な書類
          <br />
          （動産の場合）
          <br />
          （ア）○○○○
          <br />
          （イ）○○○○
          <br />
          ・・・・
          <br />
          （自動車の場合）
          <br />
          （ア）○○○○
          <br />
          （イ）○○○○
          <br />
          ・・・・
          <br />
          （不動産の場合）
          <br />
          （ア）○○○○
          <br />
          （イ）○○○○
          <br />
          ・・・・
          <br />
          イ．売却の決定金額
          <br />
          落札者が入札した金額を売却の決定金額とします。
          <br />
          ウ．落札者が契約を締結しなかった場合
          <br />
          落札者が契約締結期限までに契約を締結しなかった場合、落札者が納付した入札保証金は返還しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）売却の決定の取り消し
        </p>
        <p className="template-guideline-body">
          落札者が契約締結期限までに契約しなかったときおよび落札者が公有財産売却の参加仮申込みの時点で18歳未満の方など公有財産売却に参加できない者の場合に、売却の決定が取り消されます。
          <br />
          この場合、公有財産売却の財産の所有権は落札者に移転しません。また、納付された入札保証金は返還されません。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale3_4" className="pt-6">
        <div className="template-guideline-section-title">
          4. 売払代金の残金の納付
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）売払代金の残金の金額
        </p>
        <p className="template-guideline-body">
          売払代金の残金は、落札金額から事前に納付した契約保証金（契約保証金に充当した入札保証金）を差し引いた金額となります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）売払代金の残金納付期限について
        </p>
        <p className="template-guideline-body">
          落札者は、売払代金の残金納付期限までに●●●が納付を確認できるよう売払代金の残金を一括で納付してください。
          <br />
          売払代金の残金が納付された時点で、公有財産売却の財産の所有権が落札者に移転します。売払代金の残金納付期限までに売払代金の残金全額の納付が確認できない場合、事前に納付された契約保証金を没収し、返還しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）売払代金の残金の納付方法
        </p>
        <p className="template-guideline-body">
          売払代金の残金は次の方法で納付してください。なお、売払代金の残金の納付にかかる費用は、落札者の負担となります。また、売払代金の残金納付期限までに●●●が納付を確認できることが必要です。
          <br />
          ア．●●●が用意する納付書による納付
          <br />
          イ．現金書留による送付（金額が50万円以下の場合のみ）
          <br />
          ウ．郵便為替による納付
          <br />
          ・発行日から起算して175日を経過していないものに限ります。
          <br />
          エ．現金もしくは銀行振出の小切手を●●●へ直接持参
          <br />
          ・銀行振出の小切手は、電子交換所に加入する銀行が振り出し、振出日より5日以内のもので、受取人は持参人払いとしたものに限ります。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale3_5" className="pt-4 pb-6">
        <div className="template-guideline-section-title">
          5. 入札保証金の返還
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）落札者以外への入札保証金の返還
        </p>
        <p className="template-guideline-body">
          落札者以外の納付した入札保証金は、入札終了後全額返還します。
          <br />
          なお、公有財産売却の参加申し込みを行ったものの入札を行わない場合にも、入札保証金の返還は入札終了後となります。
          <br />
          入札保証金返還の方法および返還に要する期間は次のとおりです。
          <br />
          ア．クレジットカードによる納付の場合
          <br />
          SBペイメントサービス株式会社は、クレジットカードにより納付された入札保証金を返還する場合、クレジットカードからの入札保証金の引き落としを行いません。
          <br />
          ただし、公有財産売却の参加者などのクレジットカードの引き落としの時期などの関係上、いったん実際に入札保証金の引き落としを行い、翌月以降に返還を行う場合がありますので、ご了承ください。
          <br />
          イ．銀行振込などによる納付の場合
          <br />
          入札保証金の返還方法は、公有財産売却の参加者が指定する銀行口座への振込のみとなります。公有財産売却の参加者（入札保証金返還請求者）名義の口座のみ指定可能です。共同入札の場合は、仮申し込みを行った代表者名義の口座のみ指定可能です。
          <br />
          なお、入札保証金の返還には、入札期間終了後4週間程度要することがあります。
        </p>
      </div>
      <AnchorButton text="▲ 第3の先頭に戻る" />
      <div data-testid="template-guideline-asset-sale4" className="pt-9">
        <Heading3
          text="第4 せり売形式で行う公有財産売却の手続き"
          isLeftBorderStyle={true}
          hasCopyButton={true}
          clickHandler={() => openModal('templateGuidelineAssetSale4')}
        />
        <p
          data-testid="template-guideline-lead-text"
          className="template-guideline-lead-text pt-4">
          せり売形式の売却システムは、紀尾井町戦略研究所株式会社の提供する自動入札システムおよび入札単位を使用しています。
          <br />
          本章における入札とは、売却システム上の「入札額」欄へ希望落札金額の上限を入力することおよび入力した上限以下の範囲で行われる自動入札をいいます。また、本章においては、「入札」はせり売形式の入札を、「入札者」はせり売りの参加申込者を、「入札期間」はせり売期間を指します。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale4_1" className="pt-6">
        <div className="template-guideline-section-title">
          1. 公有財産売却への入札
        </div>
        <p className="template-guideline-body font-weight-bold pt-4">
          （1）入札
        </p>
        <p className="template-guideline-body">
          入札保証金の納付が完了したログインIDでのみ、入札が可能です。入札は、入札期間中であれば何回でも可能です。ただし、売却システム上の「現在価格」または一度「入札額」欄に入力した金額を下回る金額を「入札額」欄に入力することはできません。一度行った入札は、入札参加者などの都合による取り消しや変更はできませんので、ご注意ください。なお、入札期間の自動延長は行いません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）入札をなかったものとする取り扱い
        </p>
        <p className="template-guideline-body">
          ●●●は、地方自治法施行令第167条の4第1項などに規定する一般競争入札に参加できない要件に該当する者が行った入札について、当該入札を取り消し、なかったものとして取り扱うことがあります。入札期間中にその時点における最高価格の入札をなかったものとした場合、当該入札に次ぐ価格の入札を最高価格の入札とし、せり売りを続行します。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale4_2" className="pt-6">
        <div className="template-guideline-section-title">
          2. 落札者の決定など
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）落札者の決定
        </p>
        <p className="template-guideline-body">
          入札期間終了後、●●●は開札を行い、売却区分（公有財産売却の財産の出品区分）ごとに、売却システム上の入札において、入札価格が予定価格（最低落札価格）以上でかつ最高価格である入札者を落札者として決定します。また、売却システム上では、2人以上が同額の入札価格（上限）を設定した場合、先に設定した人を落札者として決定します。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）せり売終了の告知など
        </p>
        <p className="template-guideline-body">
          ●●●は、落札者を決定したときは、落札者のログインIDに紐づく会員識別番号と落札価格を売却システム上に一定期間公開することによって告げ、せり売終了を告知します。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）●●●から落札者への連絡
        </p>
        <p className="template-guideline-body">
          落札者には、●●●から入札終了後、あらかじめログインIDで認証されたメールアドレスに、落札者として決定された旨の電子メールを送信します。共同入札者が落札者となった場合は、代表者にのみ落札者として決定された旨の電子メールを送信します。
          <br />
          ・●●●が落札者に送信した電子メールが、落札者によるメールアドレスの変更やプロバイダの不調などの理由により到着しないために、●●●が落札者による売払代金の残金の納付を売払代金の残金納付期限までに確認できない場合、その原因が落札者の責に帰すべきものであるか否かを問わず、保証金を没収し、返還しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （4）落札者決定の取り消し
        </p>
        <p className="template-guideline-body">
          入札金額の入力間違いなどの場合は、落札者の決定が取り消されることがあります。この場合、売却物件の所有権は落札者に移転しません。また、納付された入札保証金は原則返還しません。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale4_3" className="pt-6">
        <div className="template-guideline-section-title">3. 売却の決定</div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）落札者に対する売却の決定
        </p>
        <p className="template-guideline-body">
          ●●●は、落札後、落札者に対し電子メールなどにより契約締結に関する案内を行い、落札者と契約を交わします。
          <br />
          契約の際には●●●より契約書を送付しますので、落札者は必要事項を記入・押印のうえ、次の書類などを添付して●●●に直接持参または郵送してください。
          <br />
          ア．必要な書類
          <br />
          （ア）〇〇〇〇
          <br />
          （イ）○○○○
          <br />
          ・・・・
          <br />
          イ．売却の決定金額
          <br />
          落札者が入札した金額を売却の決定金額とします。
          <br />
          ウ．落札者が契約を締結しなかった場合
          <br />
          落札者が契約締結期限までに契約を締結しなかった場合、落札者が納付した入札保証金は返還しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）売却の決定の取り消し
        </p>
        <p className="template-guideline-body">
          落札者が契約締結期限までに契約しなかったときおよび落札者が公有財産売却の参加仮申込みの時点で18歳未満の方など公有財産売却に参加できない者の場合に、売却の決定が取り消されます。
          <br />
          この場合、公有財産売却の財産の所有権は落札者に移転しません。また、納付された入札保証金は返還されません。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale4_4" className="pt-6">
        <div className="template-guideline-section-title">
          4. 売払代金の残金の納付
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）売払代金の残金の金額
        </p>
        <p className="template-guideline-body">
          売払代金の残金は、落札金額から事前に納付した契約保証金（契約保証金に充当した入札保証金）を差し引いた金額となります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）売払代金の残金納付期限について
        </p>
        <p className="template-guideline-body">
          落札者は、売払代金の残金納付期限までに●●●が納付を確認できるよう売払代金の残金を一括で納付してください。
          <br />
          売払代金の残金が納付された時点で、公有財産売却の財産の所有権が落札者に移転します。売払代金の残金納付期限までに売払代金の残金全額の納付が確認できない場合、事前に納付された保証金を没収し、返還しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）売払代金の残金の納付方法
        </p>
        <p className="template-guideline-body">
          売払代金の残金は次の方法で納付してください。なお、売払代金の残金の納付にかかる費用は、落札者の負担となります。また、売払代金の残金納付期限までに●●●が納付を確認できることが必要です。
          <br />
          ア．●●●が用意する納付書による納付
          <br />
          イ．現金書留による送付（金額が50万円以下の場合のみ）
          <br />
          ウ．郵便為替による納付
          <br />
          ・発行日から起算して175日を経過していないものに限ります。
          <br />
          エ．現金もしくは銀行振出の小切手を●●●へ直接持参
          <br />
          ・銀行振出の小切手は、電子交換所に加入する銀行が振り出し、振出日より5日以内のもので、受取人は持参人払いとしたものに限ります。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale4_5" className="pt-6">
        <div className="template-guideline-section-title">
          5. 入札保証金の返還
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）落札者以外への入札保証金の返還
        </p>
        <p className="template-guideline-body">
          落札者以外の納付した入札保証金は、入札終了後全額返還します。
          <br />
          なお、公有財産売却の参加申し込みを行ったものの入札を行わない場合にも、入札保証金の返還は入札終了後となります。
          <br />
          入札保証金返還の方法および返還に要する期間は次のとおりです。
          <br />
          ア．クレジットカードによる納付の場合
          <br />
          SBペイメントサービス株式会社は、クレジットカードにより納付された入札保証金を返還する場合、クレジットカードからの入札保証金の引き落としを行いません。
          <br />
          ただし、公有財産売却の参加者などのクレジットカードの引き落としの時期などの関係上、いったん実際に入札保証金の引き落としを行い、翌月以降に返還を行う場合がありますので、ご了承ください。
          <br />
          イ．銀行振込などによる納付の場合
          <br />
          入札保証金の返還方法は、公有財産売却の参加者が指定する銀行口座への振込のみとなります。公有財産売却の参加者（入札保証金返還請求者）名義の口座のみ指定可能です。共同入札の場合は、仮申し込みを行った代表者名義の口座のみ指定可能です。
          <br />
          なお、入札保証金の返還には、入札期間終了後4週間程度要することがあります。
        </p>
      </div>
      <AnchorButton text="▲ 第4の先頭に戻る" />
      <div data-testid="template-guideline-asset-sale5" className="pt-9">
        <Heading3
          text="第5 公有財産売却の財産の権利移転および引き渡しについて"
          isLeftBorderStyle={true}
          hasCopyButton={true}
          clickHandler={() => openModal('templateGuidelineAssetSale5')}
        />
        <p
          data-testid="template-guideline-lead-text"
          className="template-guideline-lead-text pt-4">
          ●●●は、落札後、落札者と売買契約を交わします。
          <br />
          契約の際には●●●より契約書を送付しますので、落札者は必要事項を記入・押印のうえ、契約金額に応じた収入印紙を貼付し、直接持参または郵送してください。（自動車の場合は、収入印紙は不要です。）
          <br />
          自動車・物品は、売払代金の残金納付確認後、売却代金納付時の現状のままで、●●●が指定する場所において直接引渡します。指定場所まで来られない場合は、落札者負担で対応してください。
          <br />
          不動産は、権利移転登記完了後、登記完了を証明する書類をお渡しします。現地での引渡しは行いません。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale5_1" className="pt-6">
        <div className="template-guideline-section-title">
          1. 権利移転の時期
        </div>
        <p className="template-guideline-body pt-2">
          公有財産売却の財産は、売払代金の残金を納付したときに権利移転します。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale5_2" className="pt-6">
        <div className="template-guideline-section-title">
          2. 権利移転の手続きについて
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）不動産の場合
        </p>
        <p className="template-guideline-body">
          ア．売払代金の残金納付確認後、落札者の請求に基づいて●●●が不動産登記簿上の権利移転のみを行いますので、●●●のホームページより「所有権移転登記請求書」を印刷した後、必要事項を記入・押印して、売払代金の残金納付期限までに提出してください。
          <br />
          なお、売払代金の残金納付期限は●●●が指定する日となります。
          <br />
          イ．共同入札の場合は、共同入札者全員が記入・押印した「所有権移転登記請求書」および共同入札者全員の住民票（法人の場合は、法人登記事項証明書（現在事項証明書又は代表者事項証明書））の提出が必要です。また、公有財産売却の財産の持分割合は、移転登記前に●●●に対して任意の書式にて申請してください。
          <br />
          ウ．所有権移転の登記が完了するまで、所有権移転登記請求書提出後２週間程度の期間を要することがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）自動車の場合
        </p>
        <p className="template-guideline-body">
          ア．落札者は、「使用の本拠の位置」を管轄する運輸支局または自動車検査登録事務所に当該自動車を持ち込んでいただくことが必要です。
          <br />
          イ．譲渡証明書に記載する譲受人の名義は、落札者本人となります。落札者本人以外の名義にはできません。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale5_3" className="pt-6">
        <div className="template-guideline-section-title">3. 注意事項</div>
        <p className="template-guideline-body pt-2">
          落札後、契約を締結した時点で、落札者に公有財産売却の財産にかかる危険負担は落札者に移転します。したがって、契約締結後に発生した財産の破損、焼失など●●●の責に帰すことのできない損害の負担は、落札者が負うこととなり、売払代金の減額を請求することはできません。
          <br />
          なお、落札代金の残金を納付した時点で所有権は落札者に移転します。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale5_4" className="pt-6">
        <div className="template-guideline-section-title">
          4. 引き渡しおよび権利移転に伴う費用について
        </div>
        <p className="template-guideline-body pt-2">
          （不動産の場合）
          <br />
          （1）権利移転に伴う費用（移転登記の登録免許税、登記嘱託書の郵送料など）は落札者の負担となります。
          <br />
          （2）所有権移転などの登記を行う際は、登録免許税法に定める登録免許税相当分の収入印紙または登録免許税を納付したことを証する領収証書が必要となります。
          <br />
          ア．売払代金の残金を銀行振込で納付する場合
          <br />
          売払代金の残金を納付後、収入印紙などを●●●に送付してください。
          <br />
          イ．売払代金の残金を持参する場合
          <br />
          収入印紙などを併せて持参してください。
          <br />
          共同入札者が落札者となった場合、登録免許税相当分の収入印紙または登録免許税を納付したことを証する領収証書は、共同入札者の人数分だけ必要となります。共同入札者は、各々の持分に応じた登録免許税相当額を納付してください。（実際に持参または送付する場合は全共同入札者の合計で構いません）
          <br />
          ・所有権移転登記を行う際に、●●●と所管の法務局との間で登記嘱託書などの書類を送付するために郵送料（切手1500円程度）が必要です。
          <br />
          （自動車の場合）
          <br />
          （1）権利移転に伴う費用（自動車検査登録印紙および自動車審査証紙、自動車税環境性能割など）は落札者の負担となります。
          <br />
          ア.
          移転登録などの手数料として自動車検査登録印紙および自動車審査証紙が必要です。
          <br />
          イ.
          自動車税環境性能割及び自動車税は落札者が自ら申告、納税してください。
        </p>
      </div>
      <AnchorButton text="▲ 第5の先頭に戻る" />
      <div data-testid="template-guideline-asset-sale6" className="pt-9">
        <Heading3
          text="第6 注意事項"
          isLeftBorderStyle={true}
          hasCopyButton={true}
          clickHandler={() => openModal('templateGuidelineAssetSale6')}
        />
      </div>
      <div data-testid="template-guideline-asset-sale6_1" className="pt-4">
        <div className="template-guideline-section-title">
          1. 売却システムに不具合などが生じた場合の対応
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）公有財産売却の参加申し込み期間中
        </p>
        <p className="template-guideline-body">
          売却システムに不具合などが生じたために、以下の状態となった場合は公有財産売却の手続きを中止することがあります。
          <br />
          ア．公有財産売却の参加申し込み受付が開始されない場合
          <br />
          イ．公有財産売却の参加申し込み受付ができない状態が相当期間継続した場合
          <br />
          ウ．公有財産売却の参加申し込み受付が入札開始までに終了しない場合
          <br />
          エ．公有財産売却の参加申し込み受付終了時間後になされた公有財産売却の参加申し込みを取り消すことができない場合
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）入札期間中
        </p>
        <p className="template-guideline-body">
          売却システムに不具合などが生じたために、以下の状態となった場合は公有財産売却の手続きを中止することがあります。
          <br />
          ア．入札の受付が開始されない場合
          <br />
          イ．入札できない状態が相当期間継続した場合
          <br />
          ウ．入札の受付が入札期間終了時刻に終了しない場合
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）入札期間終了後
        </p>
        <p className="template-guideline-body">
          売却システムに不具合などが生じたために、以下の状態となった場合は公有財産売却の手続きを中止することがあります。
          <br />
          ア．一般競争入札形式において入札期間終了後相当期間経過後も開札ができない場合
          <br />
          イ．くじ（自動抽選）が必要な場合でくじ（自動抽選）が適正に行えない場合
          <br />
          ウ．せり売形式において入札終了後相当期間経過後も落札者を決定できない場合
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale6_2" className="pt-6">
        <div className="template-guideline-section-title">
          2. 公有財産売却の中止
        </div>
        <p className="template-guideline-body pt-2">
          公有財産売却の参加申し込み開始後に公有財産売却を中止することがあります。公有財産売却の財産の公開中であっても、やむを得ない事情により、公有財産売却を中止することがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （1）特定の公有財産売却の特定の売却区分（売却財産の出品区分）の中止時の入札保証金の返還
        </p>
        <p className="template-guideline-body">
          特定の公有財産売却の物件の公有財産売却が中止となった場合、当該公有財産売却の物件について納付された入札保証金は中止後返還します。なお、銀行振込などにより入札保証金を納付した場合、返還まで中止後4週間程度要することがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）公有財産売却の中止時の入札保証金の返還
        </p>
        <p className="template-guideline-body">
          公有財産売却の全体が中止となった場合、入札保証金は中止後返還します。なお、銀行振込などにより入札保証金を納付した場合、返還まで中止後4週間程度要することがあります。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale6_3" className="pt-4">
        <div className="template-guideline-section-title">
          3.
          公有財産売却の参加を希望する者、公有財産売却の参加申込者および入札者など（以下「入札者など」という）に損害などが発生した場合
        </div>
        <p className="template-guideline-body pt-2">
          （1）公有財産売却が中止になったことにより、入札者などに損害が発生した場合、●●●は損害の種類・程度にかかわらず責任を負いません。
          <br />
          （2）売却システムの不具合などにより、入札者などに損害が発生した場合、●●●は損害の種類・程度にかかわらず責任を負いません。
          <br />
          （3）入札者などの使用する機器および公有財産売却の参加者などの使用するネットワークなどの不備、不調その他の理由により、公有財産売却の参加申し込みまたは入札に参加できない事態が生じた場合においても、●●●は代替手段を提供せず、それに起因して生じた損害について責任を負いません。
          <br />
          （4）公有財産売却に参加したことに起因して、入札者などが使用する機器およびネットワークなどに不備、不調などが生じたことにより入札者などに損害が発生した場合、●●●は損害の種類・程度にかかわらず責任を負いません。
          <br />
          （5）公有財産売却の参加者などが入札保証金を自己名義（法人の場合は当該法人代表者名義）のクレジットカードで納付する場合で、クレジットカード決済システムの不備により、入札保証金の納付ができず公有財産売却の参加申し込みができないなどの事態が発生したとき、それに起因して入札者などに生じた損害について、●●●は損害の種類・程度にかかわらず責任を負いません。
          <br />
          （6）公有財産売却の参加者などの発信もしくは受信するデータが不正アクセスおよび改変などを受け、公有財産売却の参加続行が不可能となるなどの被害を受けた場合、その被害の種類・程度にかかわらず、●●●は責任を負いません。
          <br />
          （7）公有財産売却の参加者などが、自身のログインIDおよびパスワードなどを紛失もしくは、ログインIDおよびパスワードなどが第三者に漏えいするなどして被害を受けた場合、その被害の種類・程度にかかわらず●●●は責任を負いません。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale6_4" className="pt-4">
        <div className="template-guideline-section-title">
          4. 公有財産売却の参加申し込み期間および入札期間
        </div>
        <p className="template-guideline-body pt-2">
          公有財産売却の参加申し込み期間および入札期間は、売却システム上の公有財産売却の物件詳細画面上に示された期間となります。ただし、システムメンテナンスなどの期間を除きます。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale6_5" className="pt-6">
        <div className="template-guideline-section-title">
          5. リンクの制限など
        </div>
        <p className="template-guideline-body pt-2">
          ●●●が売却システム上に情報を掲載しているウェブページへのリンクについては、●●●物件一覧のページ以外のページへの直接のリンクはできません。
          <br />
          また、売却システム上において、●●●が公開している情報（文章、写真、図面など）について、●●●に無断で転載・転用することは一切できません。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale6_6" className="pt-6">
        <div className="template-guideline-section-title">
          6. システム利用における禁止事項
        </div>
        <p className="template-guideline-body pt-2">
          売却システムの利用にあたり、次に掲げる行為を禁止します。
          <br />
          （1）売却システムをインターネット公有財産売却の手続き以外の目的で不正に利用すること。
          <br />
          （2）売却システムに不正にアクセスをすること。
          <br />
          （3）売却システムの管理および運営を故意に妨害すること。
          <br />
          （4）売却システムにウイルスに感染したファイルを故意に送信すること。
          <br />
          （5）法令もしくは公序良俗に違反する行為またはそのおそれのある行為をすること。
          <br />
          （6）その他売却システムの運用に支障を及ぼす行為またはそのおそれのある行為をすること。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale6_7" className="pt-6">
        <div className="template-guideline-section-title">7. 準拠法</div>
        <p className="template-guideline-body pt-2">
          このガイドラインには、日本法が適用されるものとします。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale6_8" className="pt-6">
        <div className="template-guideline-section-title">
          8. インターネット公有財産売却において使用する通貨、言語、時刻など
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）インターネット公有財産売却の手続きにおいて使用する通貨
        </p>
        <p className="template-guideline-body pt-2">
          インターネット公有財産売却の手続きにおいて使用する通貨は、日本国通貨に限り、入札価格などの金額は、日本国通貨により表記しなければならないものとします。
        </p>
        <p className="template-guideline-body font-weight-bold pt-2">
          （2）インターネット公有財産売却の手続きにおいて使用する言語
        </p>
        <p className="template-guideline-body pt-2">
          インターネット公有財産売却の手続きにおいて使用する言語は、日本語に限ります。売却システムにおいて使用する文字は、JIS第1第2水準漢字（JIS（産業標準化法（昭和24年法律第185号）第20条第1項の日本産業規格）X0208をいいます）であるため、不動産登記簿上の表示などと異なることがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-2">
          （3）インターネット公有財産売却の手続きにおいて使用する時刻
        </p>
        <p className="template-guideline-body pt-2">
          インターネット公有財産売却の手続きにおいて使用する時刻は、日本国の標準時によります。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale6_9" className="pt-6">
        <div className="template-guideline-section-title">
          9. ●●●インターネット公有財産売却ガイドラインの改正
        </div>
        <p className="template-guideline-body pt-2">
          ●●●は、必要があると認めるときは、このガイドラインを改正することができるものとします。
          <br />
          なお、改正を行った場合には、●●●は売却システム上に掲載することにより公表します。改正後のガイドラインは、公表した日以降に売却参加申し込みの受付を開始するインターネット公有財産売却から適用します。
        </p>
      </div>
      <div data-testid="template-guideline-asset-sale6_10" className="pt-6">
        <div className="template-guideline-section-title">10. その他</div>
        <p className="template-guideline-body pt-2">
          官公庁オークションサイトに掲載されている情報で、●●●が掲載したものでない情報については、●●●インターネット公有財産売却に関係する情報ではありません。
        </p>
      </div>
      <AnchorButton text="▲ 第6の先頭に戻る" />
      <div data-testid="template-guideline-auction" className="pt-9">
        <ImportantCaution
          title="インターネット公売における個人情報について"
          text="行政機関が紀尾井町戦略研究所株式会社の提供する官公庁オークションシステムを利用して行うインターネット公売における個人情報の収集主体は行政機関になります。"
        />
        <ImportantCaution
          title="クレジットカードで公売保証金を納付する場合"
          text="クレジットカードにより公売保証金を納付する公売参加者およびその代理人（以下、「公売参加者など」という）は、紀尾井町戦略研究所株式会社に対し、クレジットカードによる公売保証金納付および返還事務に関する代理権を付与し、クレジットカードによる請求処理をSBペイメントサービス株式会社に委託することを承諾します。公売参加者などは、インターネット公売が終了し、公売保証金の返還が終了するまでこの承諾を取り消せないことに同意するものとします。 また、公売参加者などは、紀尾井町戦略研究所株式会社が公売保証金取り扱い事務に必要な範囲で、公売参加者などの個人情報をSBペイメントサービス株式会社に開示することに同意するものとします。"
        />
      </div>
    </div>
  );
};

export default TemplateGuidelineAssetSale;
