import FooterBottomModal from './FooterBottomModal.jsx';

const footerBottomModalList = [
  {
    id: 1,
    linkLabel: '運営会社について',
    iframeLink: 'https://www.pages.kankocho.jp/company',
  },
  {
    id: 2,
    linkLabel: '利用規約',
    iframeLink: 'https://www.pages.kankocho.jp/terms',
  },
  {
    id: 3,
    linkLabel: 'プライバシーポリシー',
    iframeLink: 'https://www.pages.kankocho.jp/privacy',
  },
  {
    id: 4,
    linkLabel: '特定商取引法の表示',
    iframeLink: 'https://www.pages.kankocho.jp/tokushoho',
  },
];

const FooterBottom = () => {
  return (
    <div data-testid="footer-bottom" className="footer-bottom mx-auto">
      <div className="flex justify-center">
        {footerBottomModalList.map((contents) => (
          <FooterBottomModal
            key={contents.id}
            linkLabel={contents.linkLabel}
            iframeLink={contents.iframeLink}
          />
        ))}
      </div>
      <div data-testid="copyright" className="copyright">
        © 2024 - KSI官公庁オークション
      </div>
    </div>
  );
};

export default FooterBottom;
