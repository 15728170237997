import { useGovernmentUserInputs } from "shared/hooks/useGovernmentUserInputs";

import { GovernmentUserForm } from "../GovernmentUserForm";

export const validateInputsGovernmentUserForm = () => {
  const formInputs = useGovernmentUserInputs();
  const validate = GovernmentUserForm();

  // 【KM】GovernmentUserCreate
  function handleFamilyName(e) {
    validate.setters.familyName(e)
    formInputs.setters.familyName(e.target.value)
  }

  function handleFamilyNameKana(e) {
    validate.setters.familyNameKana(e)
    formInputs.setters.familyNameKana(e.target.value)
  }

  function handleFirstName(e) {
    validate.setters.firstName(e)
    formInputs.setters.firstName(e.target.value)
  }

  function handleFirstNameKana(e) {
    validate.setters.firstNameKana(e)
    formInputs.setters.firstNameKana(e.target.value)
  }

  function handleDepartment(e) {
    validate.setters.department(e)
    formInputs.setters.department(e.target.value)
  }

  function handleOfficePosition(e) {
    validate.setters.officePosition(e)
    formInputs.setters.officePosition(e.target.value)
  }

  function handleTel(e) {
    validate.setters.tel(e)
    formInputs.setters.tel(e.target.value)
  }

  function handleExtension(e) {
    validate.setters.extension(e)
    formInputs.setters.extension(e.target.value)
  }

  function handleEmail(e) {
    validate.setters.email(e)
    formInputs.setters.email(e.target.value)
  }

  const validateInputs = [
    {
      type: formInputs.type.familyName,
      column: 'familyName',
      text: '担当者名（姓）',
      validationMessage: validate.validationMessage.familyName,
      setValidationMessage: validate.setters.familyName,
      required: true,
      state: formInputs.data.familyName,
      init: formInputs.setters.familyName,
      setters: handleFamilyName
    },
    {
      type: formInputs.type.familyNameKana,
      column: 'familyNameKana',
      text: '担当者名（姓）カナ',
      validationMessage: validate.validationMessage.familyNameKana,
      setValidationMessage: validate.setters.familyNameKana,
      required: true,
      state: formInputs.data.familyNameKana,
      init: formInputs.setters.familyNameKana,
      setters: handleFamilyNameKana
    },
    {
      type: formInputs.type.firstName,
      column: 'firstName',
      text: '担当者名（名）',
      validationMessage: validate.validationMessage.firstName,
      setValidationMessage: validate.setters.firstName,
      required: true,
      state: formInputs.data.firstName,
      init: formInputs.setters.firstName,
      setters: handleFirstName
    },
    {
      type: formInputs.type.firstNameKana,
      column: 'firstNameKana',
      text: '担当者名（名）カナ',
      validationMessage: validate.validationMessage.firstNameKana,
      setValidationMessage: validate.setters.firstNameKana,
      required: true,
      state: formInputs.data.firstNameKana,
      init: formInputs.setters.firstNameKana,
      setters: handleFirstNameKana
    },
    {
      type: formInputs.type.department,
      column: 'department',
      text: '担当部署名',
      validationMessage: validate.validationMessage.department,
      setValidationMessage: validate.setters.department,
      required: true,
      state: formInputs.data.department,
      init: formInputs.setters.department,
      setters: handleDepartment
    },
    {
      type: formInputs.type.officePosition,
      column: 'officePosition',
      text: '役職名',
      validationMessage: validate.validationMessage.officePosition,
      setValidationMessage: validate.setters.officePosition,
      required: false,
      state: formInputs.data.officePosition,
      init: formInputs.setters.officePosition,
      setters: handleOfficePosition
    },
    {
      type: formInputs.type.tel,
      column: 'tel',
      text: '電話番号',
      validationMessage: validate.validationMessage.tel,
      setValidationMessage: validate.setters.tel,
      required: true,
      state: formInputs.data.tel,
      init: formInputs.setters.tel,
      setters: handleTel
    },
    {
      type: formInputs.type.extension,
      column: 'extension',
      text: '内線',
      validationMessage: validate.validationMessage.extension,
      setValidationMessage: validate.setters.extension,
      required: false,
      state: formInputs.data.extension,
      init: formInputs.setters.extension,
      setters: handleExtension
    },
    {
      type: formInputs.type.email,
      column: 'email',
      text: 'メール',
      validationMessage: validate.validationMessage.email,
      setValidationMessage: validate.setters.email,
      required: true,
      state: formInputs.data.email,
      init: formInputs.setters.email,
      setters: handleEmail
    }
  ];

  return validateInputs;
}