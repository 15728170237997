import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { dateFormat } from '../../../../shared/utils/helper/date_format';

export const slotProps = (date, setOpen) => {
  const inputValue = () => {
    const formatDate = dateFormat(date, 'Y-M-D');
    if (formatDate === 'DATA_EMPTY') {
      return '';
    }
    return formatDate;
  };

  return {
    inputAdornment: {
      position: 'start',
    },
    textField: {
      readOnly: true,
      onClick: () => setOpen(true),
      InputProps: {
        placeholder: '',
        value: inputValue(),
      },
      sx: {
        maxWidth: '200px',
        '& .MuiOutlinedInput-root': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.38)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f78c23',
          },
        },
        '& .MuiInputAdornment-root .MuiSvgIcon-root': {
          color: '#f78c23',
        },
      },
    },
  };
};

const DatePickerForm = ({
  date,
  handleChange,
  label,
  minDateTime,
  maxDateTime,
}) => {
  const [open, setOpen] = useState(false);

  const isDateDisabled = (date) => {
    return date < minDateTime || date > maxDateTime;
  };

  return (
    <tr>
      <th>{label}</th>
      <td>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            value={DateTime.fromJSDate(new Date(date))}
            onChange={(selectDate) => handleChange(selectDate.toJSDate())}
            shouldDisableDate={isDateDisabled}
            format="yyyy-MM-dd"
            slotProps={slotProps(date, setOpen)}
            open={open}
            onClose={() => setOpen(false)}
          />
        </LocalizationProvider>
      </td>
    </tr>
  );
};

export default DatePickerForm;
