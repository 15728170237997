import ServiceProviderProvider from './ServiceProviderProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { serviceProvider } from "../entities/componentMapping.js";

const ServiceProvider = ({ type }) => {
  return (
    <ServiceProviderProvider type={type}>
      {getComponent(serviceProvider, type)}
    </ServiceProviderProvider>
  );
};

export default ServiceProvider;
