import { Link } from 'react-router-dom';

export const AddBidded = ({ data, setIsNoMessage }) => {
  if (data.status === 'ADDITIONAL_DURING_BIDDING') {
    return (
      <p className="ma-0" data-testid="announce-message">
        追加入札は完了しました。
        <br />
        開札までしばらくお待ちください。
      </p>
    );
  }

  if (data.status === 'END_BIDDING') {
    if (data.bidderUser === data.membershipId) {
      return (
        <p className="ma-0" data-testid="announce-message">
          <span>おめでとうございます！ あなたが落札者です。</span>
          <br />
          今後のお手続きについては、お客様から電話で下記連絡先に、問い合わせください。
          <br />
          ※売払代金納付期限までに売払代金の納付を執行機関が確認できない場合、公売保証金は没収となります。
        </p>
      );
    }
    if (data.nextWinner === data.membershipId) {
      return (
        <p className="ma-0" data-testid="announce-message">
          あなたは次順位買受申し込み者となりました。
          <br />
          最高価申し込み者が買受代金を納付しなかった場合などに、あなたは落札者となります。
        </p>
      );
    }
    return (
      <p className="ma-0" data-testid="announce-message">
        残念ながら落札できませんでした。またのご利用をお待ちしております。
        <br />
        今後の開催予定は<Link to={'/schedules'}>こちら</Link>
        からご確認ください。
      </p>
    );
  }

  setIsNoMessage(true);
};
