export class PaymentMethodGet {
    constructor(paymentMethod) {
        this.id = paymentMethod.id
        this.propertyId = paymentMethod.propertyId
        this.payment = paymentMethod.payment
        this.createdUser = paymentMethod.createdUser
        this.createdAt = paymentMethod.createdAt
        this.updatedUser = paymentMethod.updatedUser
        this.updatedAt = paymentMethod.updatedAt
    }
}
