// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login .kmanager-login-wrap {
  display: flex;
  width: 100%;
  margin-top: 5px;
}

.login .kmanager-login-form {
  max-width: 350px;
  width: 100%;
  margin: 5px auto 5px;
  padding: 20px;
  border-color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
}

.login .kmanager-login-form .form-area input {
  width: 100%;
  padding: 15px 0;
  border: solid 1px lightgrey;
}

.login .kmanager-login-form .form-area {
  margin: 10px auto;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Login/login-form.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,oBAAoB;EACpB,aAAa;EACb,kBAAkB;EAClB,4GAA4G;AAC9G;;AAEA;EACE,WAAW;EACX,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".login .kmanager-login-wrap {\n  display: flex;\n  width: 100%;\n  margin-top: 5px;\n}\n\n.login .kmanager-login-form {\n  max-width: 350px;\n  width: 100%;\n  margin: 5px auto 5px;\n  padding: 20px;\n  border-color: #fff;\n  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);\n}\n\n.login .kmanager-login-form .form-area input {\n  width: 100%;\n  padding: 15px 0;\n  border: solid 1px lightgrey;\n}\n\n.login .kmanager-login-form .form-area {\n  margin: 10px auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
