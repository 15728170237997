// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading3 {
  font-size: 18px;
  line-height: 1.222;
  font-weight: 700;
}

.heading3-left-border {
  position: relative;
  padding: 8px 0 8px 15px;
}

.heading3-left-border:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background: #ff7f00;
}

.heading3-bg-gray {
  padding: 15px;
  background-color: var(--heading);
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/heading3.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,OAAO;EACP,UAAU;EACV,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,gCAAgC;AAClC","sourcesContent":[".heading3 {\n  font-size: 18px;\n  line-height: 1.222;\n  font-weight: 700;\n}\n\n.heading3-left-border {\n  position: relative;\n  padding: 8px 0 8px 15px;\n}\n\n.heading3-left-border:before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  width: 5px;\n  height: 100%;\n  background: #ff7f00;\n}\n\n.heading3-bg-gray {\n  padding: 15px;\n  background-color: var(--heading);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
