import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  SEARCH_PROPERTY_CATEGORY_ID,
  SEARCH_GOVERNMENT_SUB_CATEGORY_ID,
  DIRECTION,
  FIELD,
  PAGE_SIZE,
  PAGE,
} from 'shared/utils/constants/queryParam.js';

import CheckBox from './CheckBox.jsx';
import 'interfaces/css/common/accordion-with-checkbox.css';

const AccordionWithCheckBox = ({
  id,
  categoryData,
  marginLeft,
  style,
  onClick,
  checkBoxState,
  showCount,
  showCheckBox,
  checkboxActive,
  contents,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const contentRef = useRef(null);

  // const label = showCheckBox ? categoryData?.name : <Link to={`${useSearchLink(userId, categoryData.id)}`}>{categoryData?.name}</Link>;
  const label = categoryData?.name;
  const count = categoryData?.propertyIds?.length;
  const depth = categoryData?.depth;
  const childCategoryVal = categoryData?.childCategory?.length;

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    contentRef.current.style.display = isOpen ? 'none' : 'inline-block';
  };
  const appendQueryParam = (queryParamName, queryParamValue) => {
    if (!searchParams.has(queryParamName)) {
      searchParams.append(queryParamName, queryParamValue);
    }
  };

  const getCategoryIds = (categoryData) => {
    let categoryIds = [];

    // すでにクエリパラメータが設定されているかを確認
    if (searchParams.has(SEARCH_PROPERTY_CATEGORY_ID)) {
      const currentQueryParam = searchParams
        .get(SEARCH_PROPERTY_CATEGORY_ID)
        .split(',');
      if (currentQueryParam[0] === '') {
        currentQueryParam.shift();
      }
      categoryIds.push(...currentQueryParam);
    }
    categoryIds.push(categoryData?.id.toString());

    // 子・孫要素のカテゴリIDをすべて取得
    categoryData?.childCategory?.forEach((childCategory) => {
      childCategory?.childCategory?.forEach((grandChildCategory) => {
        categoryIds.push(grandChildCategory.id.toString());
      });
      categoryIds.push(childCategory.id.toString());
    });

    // カテゴリID一覧の重複を排除
    categoryIds = Array.from(new Set(categoryIds));
    // 昇順にソート
    categoryIds.sort((a, b) => a - b);
    return categoryIds;
  };
  const getAfterExclusionCategoryIds = (categoryData) => {
    // すでにクエリパラメータが設定されているかを確認
    if (!searchParams.has(SEARCH_PROPERTY_CATEGORY_ID)) {
      return;
    }

    let removeTarget = [];
    removeTarget.push(categoryData?.id.toString());
    categoryData?.childCategory?.forEach((childCategory) => {
      childCategory?.childCategory?.forEach((grandChildCategory) => {
        removeTarget.push(grandChildCategory.id.toString());
      });
      removeTarget.push(childCategory.id.toString());
    });

    return searchParams
      .get(SEARCH_PROPERTY_CATEGORY_ID)
      .split(',')
      .filter((el) => !removeTarget.includes(el));
  };
  const toggleCheckBox = () => {
    setIsChecked(!isChecked);
    onClick && onClick();

    // チェックを外すときは外したカテゴリのID一覧を除外したものをセットする
    if (isChecked) {
      searchParams.set(
        SEARCH_PROPERTY_CATEGORY_ID,
        getAfterExclusionCategoryIds(categoryData),
      );
      setSearchParams(searchParams);
      return;
    }

    // クリックしたチェックボックスが持つカテゴリIDとその子・孫カテゴリのID一覧を取得
    const categoryIds = getCategoryIds(categoryData);
    // カンマ区切りの文字列に変換
    let searchPropertyCategoryId = categoryIds.join(',');

    // すでにクエリパラメータがセットされていたら新しいデータをセットする
    if (searchParams.has(SEARCH_PROPERTY_CATEGORY_ID)) {
      searchParams.set(SEARCH_PROPERTY_CATEGORY_ID, searchPropertyCategoryId);
    } else {
      searchParams.append(
        SEARCH_PROPERTY_CATEGORY_ID,
        searchPropertyCategoryId,
      );
    }
    appendQueryParam(SEARCH_GOVERNMENT_SUB_CATEGORY_ID, '');
    appendQueryParam(DIRECTION, 'ASC');
    appendQueryParam(FIELD, 'PRICE');
    appendQueryParam(PAGE, 1);
    appendQueryParam(PAGE_SIZE, 50);
    setSearchParams(searchParams);
  };

  const toggleAccordionButton =
    childCategoryVal > 0 ? (
      <button onClick={toggleAccordion} className="accordion-toggle-button" />
    ) : (
      ''
    );

  const checkBox = showCheckBox ? (
    <CheckBox id={id} isChecked={isChecked} onClick={toggleCheckBox} />
  ) : (
    ''
  );

  const styleWithoutButton =
    childCategoryVal > 0 || depth === '3' ? {} : { marginLeft: '24px' };

  useEffect(() => {
    checkboxActive ? setIsChecked(true) : null;
  }, [checkboxActive]);

  useEffect(() => {
    setIsChecked(checkBoxState);
  }, [checkBoxState]);

  return (
    <div id={id} style={style}>
      <div
        className="accordion-panel flex align-center"
        style={styleWithoutButton}>
        {toggleAccordionButton}
        {checkBox}
        <div
          className={`accordion-label ${showCheckBox ? 'text-decoration-none' : ''}`}>
          <span className={count === 0 ? 'disabled' : ''}>
            {label}
            {showCount ? `(${count})` : ''}
          </span>
        </div>
      </div>
      <div
        ref={contentRef}
        style={{ display: 'none', marginLeft: `${marginLeft ?? 0}px` }}>
        {contents}
      </div>
    </div>
  );
};

export default AccordionWithCheckBox;
