// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mypage .reset-password-form {
  margin: 0 16px 16px 16px;
}

.mypage .cancel-button {
  color: var(--anchor-base);
  background-color: var(--white);
  box-shadow: none;
  margin: 0 8px;
  padding: 0 4px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/MyPage/reset-password-form.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;EACzB,8BAA8B;EAC9B,gBAAgB;EAChB,aAAa;EACb,cAAc;AAChB","sourcesContent":[".mypage .reset-password-form {\n  margin: 0 16px 16px 16px;\n}\n\n.mypage .cancel-button {\n  color: var(--anchor-base);\n  background-color: var(--white);\n  box-shadow: none;\n  margin: 0 8px;\n  padding: 0 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
