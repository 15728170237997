import Button from 'interfaces/components/common/Button/Button.jsx';
import URL_LINKS from 'interfaces/pages/Dashboard/entities/externalLinks.js';
import { useEffect, useState } from 'react';
import { useError } from 'shared/hooks/useError.js';
import { useInput } from 'shared/hooks/useInput.js';
import { useValidate } from 'shared/hooks/useValidate.js';

import PersonalInformationCaption from './PersonalInformationCaption.jsx';
import AuthFormInput from '../AuthFormInput.jsx';
import 'interfaces/css/common/sign-up-form.css';

const termsOfUseLink = (
  <a href={URL_LINKS.TERM_URL} target="_blank" rel="noopener noreferrer">
    利用規約
  </a>
);
const privacyPolicyLink = (
  <a
    href={URL_LINKS.PRIVACY_POLICY_URL}
    target="_blank"
    rel="noopener noreferrer">
    プライバシーポリシー
  </a>
);

const emailValidates = ['no_message_required', 'email'];
const passwordValidates = ['no_message_required', 'password'];
const confirmPasswordValidates = ['confirm_password'];

const SignUpForm = () => {
  const { setError } = useError();
  const [email, handleEmailChange, validateEmailMessage] =
    useInput(emailValidates);
  const [password, handlePasswordChange, validatePasswordMessage] =
    useInput(passwordValidates);
  const [
    confirmPassword,
    handleConfirmPasswordChange,
    validateConfirmPasswordMessage,
  ] = useInput(confirmPasswordValidates, password);
  const [isDisabled, setIsDisabled] = useState(true);
  const { handleValidates } = useValidate();

  const checkAllValidation = () => {
    const emailValidateMessage = handleValidates(email, emailValidates);
    const passwordValidateMessage = handleValidates(
      password,
      passwordValidates,
    );
    const confirmPasswordValidateMessage = handleValidates(
      confirmPassword,
      confirmPasswordValidates,
      password,
    );
    setIsDisabled(
      emailValidateMessage ||
        passwordValidateMessage ||
        confirmPasswordValidateMessage,
    );
  };

  useEffect(() => {
    checkAllValidation();
  }, [email, password, confirmPassword]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isDisabled) {
      return;
    }

    try {
      // TODO: CreateのAPIを叩く
      console.log(email);
      console.log(password);
      console.log(confirmPassword);
    } catch (e) {
      setError(e);
    }
  };

  const serviceLoginFormInputs = [
    {
      id: 1,
      label: 'メールアドレス(ログインID)を入力してください。',
      type: 'email',
      handleChange: handleEmailChange,
      validateMessage: validateEmailMessage,
      caption:
        'ドメイン受信制限をしている場合は @kankocho.jp からのメールの受信を許可してください。',
    },
    {
      id: 2,
      label: 'ログインパスワードを入力してください。',
      type: 'password',
      handleChange: handlePasswordChange,
      validateMessage: validatePasswordMessage,
      caption:
        '半角のアルファベットの小文字と大文字、半角数字を８文字以上組み合わせる必要があります。',
    },
    {
      id: 3,
      label: '確認のため、もう一度パスワードを入力してください。',
      type: 'password',
      handleChange: handleConfirmPasswordChange,
      validateMessage: validateConfirmPasswordMessage,
      password: password,
    },
  ];

  return (
    <form onSubmit={handleSubmit} data-testid="sign-up-form">
      {serviceLoginFormInputs.map((input) => (
        <AuthFormInput
          key={input.id}
          label={input.label}
          type={input.type}
          handleChange={input.handleChange}
          validateMessage={input.validateMessage}
          caption={input.caption}
        />
      ))}
      <p className="agreement-caption" data-testid="agreement-caption">
        会員登録には {termsOfUseLink} と {privacyPolicyLink}{' '}
        とウェブサイト上の個人情報の取扱いについてへの同意が必要です。
      </p>
      <PersonalInformationCaption />
      <div className="agreement-button-wrap">
        <Button
          text="同意して確認コードを送信"
          type="submit"
          height="44px"
          isDisabled={isDisabled}
        />
      </div>
    </form>
  );
};

export default SignUpForm;
