
export class ReceiverPostalCode {
  #receiverPostalCode

  constructor(receiverPostalCode) {
    this.#receiverPostalCode = receiverPostalCode;
  }

  get() {
    return this.#receiverPostalCode
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
