import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import ApplicationInfoRegistration from './ApplicationInfoRegistration.jsx';
import ConfirmApplicationInfo from './ConfirmApplicationInfo.jsx';
import GuidelineAgreement from './GuidelineAgreement.jsx';

const ApplicationStepComponent = ({
  locationState,
  step,
  previousStep,
  nextStep,
}) => {
  const { id } = useParams();
  const [applicantInformation, setApplicantInformation] = useState({
    corporateIndividual: 'CORPORATE',
    isUpdateMypage: false,
    isDelegatorProcedure: false,
  });
  const [delegatorInformation, setDelegatorInformation] = useState({
    corporateIndividual: 'CORPORATE',
    isUpdateMypage: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  if (step === 1) {
    return (
      <GuidelineAgreement
        data={locationState.data}
        previousStep={previousStep}
        nextStep={nextStep}
      />
    );
  }
  if (step === 2) {
    return (
      <ApplicationInfoRegistration
        data={locationState.data}
        applicantInformation={applicantInformation}
        delegatorInformation={delegatorInformation}
        setApplicantInformation={setApplicantInformation}
        setDelegatorInformation={setDelegatorInformation}
        previousStep={previousStep}
        nextStep={nextStep}
      />
    );
  }
  if (step === 3) {
    return (
      <ConfirmApplicationInfo
        data={locationState.data}
        applicantInformation={applicantInformation}
        delegatorInformation={delegatorInformation}
        previousStep={previousStep}
        nextStep={nextStep}
      />
    );
  }

  return <Navigate replace to={`/items/${id}`} />;
};

export default ApplicationStepComponent;
