// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mylist .mylist-narrow-down {
  font-size: .75rem;
  white-space: nowrap;
  border-radius: 0 !important;
  padding: 16px 16px 0 !important;
  margin-bottom: 20px !important;
  margin-top: 24px !important;
  border: thin solid rgba(0, 0, 0, .12);
  background-color: #fff;

}

.mylist .mylist-sort-checkbox-list {
  display: flex;
  margin-bottom: 15px;
  align-items: flex-end;
  margin-top: 6px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Mylist/mylist-box.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,2BAA2B;EAC3B,+BAA+B;EAC/B,8BAA8B;EAC9B,2BAA2B;EAC3B,qCAAqC;EACrC,sBAAsB;;AAExB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":[".mylist .mylist-narrow-down {\n  font-size: .75rem;\n  white-space: nowrap;\n  border-radius: 0 !important;\n  padding: 16px 16px 0 !important;\n  margin-bottom: 20px !important;\n  margin-top: 24px !important;\n  border: thin solid rgba(0, 0, 0, .12);\n  background-color: #fff;\n\n}\n\n.mylist .mylist-sort-checkbox-list {\n  display: flex;\n  margin-bottom: 15px;\n  align-items: flex-end;\n  margin-top: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
