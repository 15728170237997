
export class TransRightEstateDisplay {
  #transRightEstateDisplay

  constructor(transRightEstateDisplay) {
    this.#transRightEstateDisplay = transRightEstateDisplay;
  }

  get() {
    return this.#transRightEstateDisplay
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
