import { validateNumber, validateRangeLength, validateDecimal } from "../../../../application/validations.js";

export class Height {
  #height

  constructor(height) {
    this.#height = height;
  }

  get() {
    return this.#height
  }

  get isValid() {
    return [
      this.validateNumber,
      this.validateRangeLength,
      this.validateDecimal,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateNumber() {
    return validateNumber(this.#height)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#height, 0, 4)
  }

  // バリデーションルールの適用
  get validateDecimal() {
    return validateDecimal(this.#height, 0)
  }
}
