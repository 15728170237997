import { validateRangeLength, validateNumberLength } from "../../../../application/validations.js";

export class RegisterNumber {
  #registerNumber

  constructor(registerNumber) {
    this.#registerNumber = registerNumber;
  }

  get() {
    return this.#registerNumber
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.validateNumberLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#registerNumber, 0, 30)
  }

  // バリデーションルールの適用
  get validateNumberLength() {
    return validateNumberLength(this.#registerNumber, 4)
  }
}
