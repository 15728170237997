
export class MinistryRegion {
    #ministryRegion

    constructor(ministryRegion) {
        this.#ministryRegion = ministryRegion;
    }

    get() {
        return this.#ministryRegion
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
