import { useManagementUserInputs } from './hook/useManagementUserInputs';
import { UseManagementUserInputsValidation } from './hook/useManagementUserInputsValidation';

export const is_admin = [
  { id: 1, value: 'ADMIN', label: '管理職' },
  { id: 2, value: 'GENERAL', label: '一般' },
];

export const validateInputsManagementUserForm = (isEdit) => {
  const formInputs = useManagementUserInputs();
  const validate = UseManagementUserInputsValidation(isEdit);

  // 【KM】User
  function handleUserType(e) {
    formInputs.setters.userType(e.target.value);
  }

  function handleFamilyName(e) {
    validate.setters.familyName(e);
    formInputs.setters.familyName(e.target.value);
    console.log(validate.validationMessage.familyName);
  }

  function handleFamilyNameKana(e) {
    validate.setters.familyNameKana(e);
    formInputs.setters.familyNameKana(e.target.value);
    console.log(validate.validationMessage.familyNameKana);
  }

  function handleFirstName(e) {
    validate.setters.firstName(e);
    formInputs.setters.firstName(e.target.value);
    console.log(validate.validationMessage.firstName);
  }

  function handleFirstNameKana(e) {
    validate.setters.firstNameKana(e);
    formInputs.setters.firstNameKana(e.target.value);
    console.log(validate.validationMessage.firstNameKana);
  }

  function handleDepartment(e) {
    validate.setters.department(e);
    formInputs.setters.department(e.target.value);
    console.log(validate.validationMessage.department);
  }

  function handleOfficePosition(e) {
    validate.setters.officePosition(e);
    formInputs.setters.officePosition(e.target.value);
    console.log(validate.validationMessage.officePosition);
  }

  function handleTel(e) {
    validate.setters.tel(e);
    formInputs.setters.tel(e.target.value);
    console.log(validate.validationMessage.tel);
  }

  function handleExtension(e) {
    validate.setters.extension(e);
    formInputs.setters.extension(e.target.value);
    console.log(validate.validationMessage.extension);
  }

  function handleEmail(e) {
    validate.setters.email(e);
    formInputs.setters.email(e.target.value);
    console.log(validate.validationMessage.email);
  }

  return [
    {
      type: formInputs.type.familyName,
      column: 'familyName',
      text: '担当者名（姓）',
      validationMessage: validate.validationMessage.familyName,
      required: true,
      state: formInputs.data.familyName,
      init: (value) => formInputs.setters.familyName(value),
      setters: handleFamilyName,
    },
    {
      type: formInputs.type.firstName,
      column: 'firstName',
      text: '担当者名（名）',
      validationMessage: validate.validationMessage.firstName,
      required: true,
      state: formInputs.data.firstName,
      init: (value) => formInputs.setters.firstName(value),
      setters: handleFirstName,
    },
    {
      type: formInputs.type.familyNameKana,
      column: 'familyNameKana',
      text: '担当者名（セイ）',
      validationMessage: validate.validationMessage.familyNameKana,
      required: true,
      state: formInputs.data.familyNameKana,
      init: (value) => formInputs.setters.familyNameKana(value),
      setters: handleFamilyNameKana,
    },
    {
      type: formInputs.type.firstNameKana,
      column: 'firstNameKana',
      text: '担当者名（メイ）',
      validationMessage: validate.validationMessage.firstNameKana,
      required: true,
      state: formInputs.data.firstNameKana,
      init: (value) => formInputs.setters.firstNameKana(value),
      setters: handleFirstNameKana,
    },
    {
      type: formInputs.type.department,
      column: 'department',
      text: '部署名',
      validationMessage: validate.validationMessage.department,
      required: true,
      state: formInputs.data.department,
      init: (value) => formInputs.setters.department(value),
      setters: handleDepartment,
    },
    {
      type: formInputs.type.officePosition,
      column: 'officePosition',
      text: '役職名',
      validationMessage: validate.validationMessage.officePosition,
      state: formInputs.data.officePosition,
      init: (value) => formInputs.setters.officePosition(value),
      setters: handleOfficePosition,
    },
    {
      type: formInputs.type.tel,
      column: 'tel',
      text: '電話番号',
      validationMessage: validate.validationMessage.tel,
      required: true,
      state: formInputs.data.tel,
      init: (value) => formInputs.setters.tel(value),
      setters: handleTel,
    },
    {
      type: formInputs.type.extension,
      column: 'extension',
      text: '内線',
      validationMessage: validate.validationMessage.extension,
      state: formInputs.data.extension,
      init: (value) => formInputs.setters.extension(value),
      setters: handleExtension,
    },
    {
      type: formInputs.type.email,
      column: 'email',
      text: 'メール',
      validationMessage: validate.validationMessage.email,
      required: true,
      state: formInputs.data.email,
      init: (value) => formInputs.setters.email(value),
      setters: handleEmail,
    },
    {
      type: formInputs.type.userType,
      column: 'userType',
      text: '区分',
      list: is_admin,
      required: true,
      state: formInputs.data.userType ?? 'ADMIN',
      init: (value) => {
        formInputs.setters.userType(value);
      },
      setters: handleUserType,
    },
  ];
};
