
export class IsCopied {
  #isCopied

  constructor(isCopied) {
    this.#isCopied = isCopied;
  }

  get() {
    return this.#isCopied
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
