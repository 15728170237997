import ShippingProvider from './ShippingProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { shipping } from "../entities/componentMapping.js";

const Shipping = ({ type }) => {
  return (
    <ShippingProvider type={type}>
      {getComponent(shipping, type)}
    </ShippingProvider>
  );
};

export default Shipping;
