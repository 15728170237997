import {
  card_provisional_apply_completion_description1,
  card_provisional_apply_completion_description2,
} from 'shared/utils/helper/DummyEditFormData';

import FormTextWithCaution from '../../Form/navi/FormTextWithCaution';

const BidForm = ({ validations, procedureType, governmentType }) => {
  return (
    <div className="pt-9">
      <div className="property-info-general-form-table">
        <FormTextWithCaution
          label={'必要書類提出の案内ページURL'}
          required={
            procedureType == 'ASSET_SALE' ||
            governmentType == 'NATIONAL_TAX_AGENCY'
          }
          caution_description={card_provisional_apply_completion_description1(procedureType)}
          data={validations.data.bidDocumentSubmissionUrl}
          handler1={(e) =>
            validations.setters.handleBidDocumentSubmissionUrl(
              e,
              procedureType == 'ASSET_SALE' ||
                governmentType == 'NATIONAL_TAX_AGENCY',
            )
          }
          invalidMessage={
            validations.validationMessage.bidDocumentSubmissionUrlRequiredValid
          }
        />
        <FormTextWithCaution
          label={'共同入札案内ページURL'}
          caution_description={card_provisional_apply_completion_description2(
            governmentType,
          )}
          data={validations.data.bidJointBiddingUrl}
          handler1={validations.setters.handleBidJointBiddingUrl}
          invalidMessage={validations.validationMessage.bidJointBiddingUrlValid}
        />
      </div>
    </div>
  );
};

export default BidForm;
