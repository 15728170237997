// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-input-number-size-100 {
  width: 100%;
}

.property-input-number-size-40 {
  width: 40%;
}

.property-input-number-size-6em {
  width: 6em;
}

.property-input-number-size-10em {
  width: 10em;
}

.property-input-number-size-12em {
  width: 12em;
}

.property-input-number-size-16em {
  width: 16em;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/property-info-general-form-input-number.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":[".property-input-number-size-100 {\n  width: 100%;\n}\n\n.property-input-number-size-40 {\n  width: 40%;\n}\n\n.property-input-number-size-6em {\n  width: 6em;\n}\n\n.property-input-number-size-10em {\n  width: 10em;\n}\n\n.property-input-number-size-12em {\n  width: 12em;\n}\n\n.property-input-number-size-16em {\n  width: 16em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
