// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.government-term-row {
  height: 70px;
  display: flex;
  align-items: center;
  border-right: 1px solid var(--line-base);
  border-bottom: 1px solid var(--line-base);
}

.government-schedule-term {
  white-space: nowrap;
  overflow: visible;
  padding: 6px 10px;
  border-radius: 8px;
  position: absolute;
}

.government-apl-term {
  background-color: var(--schedule-1);
}

.government-bid-term {
  background-color: var(--schedule-2);
}

.government-auction-term {
  background-color: var(--schedule-3);
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/government-term-row.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,wCAAwC;EACxC,yCAAyC;AAC3C;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":[".government-term-row {\n  height: 70px;\n  display: flex;\n  align-items: center;\n  border-right: 1px solid var(--line-base);\n  border-bottom: 1px solid var(--line-base);\n}\n\n.government-schedule-term {\n  white-space: nowrap;\n  overflow: visible;\n  padding: 6px 10px;\n  border-radius: 8px;\n  position: absolute;\n}\n\n.government-apl-term {\n  background-color: var(--schedule-1);\n}\n\n.government-bid-term {\n  background-color: var(--schedule-2);\n}\n\n.government-auction-term {\n  background-color: var(--schedule-3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
