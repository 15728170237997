import ServiceLoginForm from './ServiceLoginForm.jsx';
import 'interfaces/css/common/login-modal-contents.css';

const LoginModalContents = ({ switchAuthStatus, handleClose }) => {
  return (
    <div className="login-modal-contents" data-testid="login-modal-contents">
      <p
        className="login-modal-contents-title"
        data-testid="login-modal-contents-title">
        ログイン
      </p>
      <ServiceLoginForm
        switchAuthStatus={switchAuthStatus}
        handleClose={handleClose}
      />
    </div>
  );
};

export default LoginModalContents;
