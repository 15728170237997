import {
  carPurposeToJp,
  carManufactureToJp,
  privateOrBusinessToJp,
} from 'shared/utils/constants/property';

export const createConfirmSectionData = (
  formData,
  division,
  category,
  procedureType,
) => {
  const isEmpty = [undefined, '', 'NO_EXISTS', '-9999'];

  let depositPaymentValue = '保証金なし';
  if (formData.data.depositPayment === 'BOTH') {
    depositPaymentValue = 'クレジットカードまたは銀行振込';
  } else if (formData.data.depositPayment === 'CREDIT') {
    depositPaymentValue = 'クレジットカード';
  } else if (formData.data.depositPayment === 'BANK_TRANSFER') {
    depositPaymentValue = '銀行振込';
  }

  const yearText = (year) => {
    if (isEmpty.includes(year)) {
      return '';
    }
    let tmpYear = Number(year);
    if (isNaN(tmpYear)) {
      return '';
    }

    let tmp = new Date(year, 0, 1);
    let wareki = tmp.toLocaleString('ja-JP-u-ca-japanese', {
      era: 'short',
      year: 'numeric',
    });
    return `${wareki}(${tmpYear}年)`;
  };

  const paymentString = (paymentMethod) => {
    let paymentString = [];
    if (paymentMethod.bankTransfer !== undefined) {
      if (
        paymentMethod.bankTransfer === true ||
        paymentMethod.bankTransfer === 'TRUE'
      ) {
        paymentString.push('銀行振込');
      }
    }
    if (paymentMethod.registeredMail !== undefined) {
      if (
        paymentMethod.registeredMail === true ||
        paymentMethod.registeredMail === 'TRUE'
      ) {
        paymentString.push('現金書留');
      }
    }
    if (paymentMethod.check !== undefined) {
      if (paymentMethod.check === true || paymentMethod.check === 'TRUE') {
        paymentString.push('小切手');
      }
    }
    if (paymentMethod.postalMoneyOrder !== undefined) {
      if (
        paymentMethod.postalMoneyOrder === true ||
        paymentMethod.postalMoneyOrder === 'TRUE'
      ) {
        paymentString.push('郵便為替');
      }
    }

    if (paymentMethod.carry !== undefined) {
      if (paymentMethod.carry === true || paymentMethod.carry === 'TRUE') {
        paymentString.push('直接持参');
      }
    }

    return paymentString.join('、');
  };

  const section3PublicContents = [
    {
      text: '出品形式',
      state: formData.data.publicSaleType === 'BID' ? '入札形式' : 'せり売形式',
    },
    {
      text: '掲載順位',
      state: isEmpty.includes(formData.data.printOrder)
        ? '未入力'
        : formData.data.printOrder,
    },
    {
      text: 'カテゴリ',
      state:
        category.grandchild === undefined
          ? '未入力'
          : `${category?.parent.name} ＞ ${category?.child.name} ＞ ${category?.grandchild.name}`,
    },
    {
      text: 'イチ押し設定',
      state: '設定する',
    },
    {
      text: '執行機関名称',
      state: isEmpty.includes(division?.text) ? '未入力' : division?.text,
    },
    {
      text: '売却区分番号',
      state: isEmpty.includes(formData.data.saleNumber)
        ? '未入力'
        : formData.data.saleNumber,
    },
    {
      text: 'タイトル',
      state: isEmpty.includes(formData.data.name)
        ? '未入力'
        : formData.data.name,
    },
    {
      text: '支払い方法',
      state: paymentString(formData?.data.paymentMethod),
    },
    {
      text: '買受代金納付期限',
      state: isEmpty.includes(formData.data.paymentLimit)
        ? '未入力'
        : formData.data.paymentLimit,
    },
    {
      text: '動画URL',
      state: isEmpty.includes(formData.data.videoUrl)
        ? '未入力'
        : formData.data.videoUrl,
    },
    {
      text: '説明',
      state: isEmpty.includes(formData.data.description)
        ? '未入力'
        : formData.data.description,
    },
  ];

  const section3AssetContents = [
    {
      text: '出品形式',
      state: formData.data.publicSaleType === 'BID' ? '入札形式' : 'せり売形式',
    },
    {
      text: '掲載順位',
      state: isEmpty.includes(formData.data.printOrder)
        ? '未入力'
        : formData.data.printOrder,
    },
    {
      text: 'カテゴリ',
      state:
        category.grandchild === undefined
          ? '未入力'
          : `${category?.parent.name} ＞ ${category?.child.name} ＞ ${category?.grandchild.name}`,
    },
    {
      text: 'イチ押し設定',
      state: '設定する',
    },
    {
      text: '執行機関名称',
      state: isEmpty.includes(division?.text) ? '未入力' : division?.text,
    },
    {
      text: '売却区分番号',
      state: isEmpty.includes(formData.data.saleNumber)
        ? '未入力'
        : formData.data.saleNumber,
    },
    {
      text: 'タイトル',
      state: isEmpty.includes(formData.data.name)
        ? '未入力'
        : formData.data.name,
    },
    {
      text: '支払い方法',
      state: paymentString(formData?.data.paymentMethod),
    },
    {
      text: '議決日',
      state: isEmpty.includes(formData.data.resolutionDate)
        ? '未入力'
        : formData.data.resolutionDate,
    },
    {
      text: '売払代金納付期限',
      state: isEmpty.includes(formData.data.paymentLimit)
        ? '未入力'
        : formData.data.paymentLimit,
    },
    {
      text: '契約締結期限',
      state: isEmpty.includes(formData.data.contractedLimit)
        ? '未入力'
        : formData.data.contractedLimit,
    },
    {
      text: '動画URL',
      state: isEmpty.includes(formData.data.videoUrl)
        ? '未入力'
        : formData.data.videoUrl,
    },
    {
      text: '説明',
      state: isEmpty.includes(formData.data.description)
        ? '未入力'
        : formData.data.description,
    },
  ];

  const section7PublicContents = [
    {
      text: '保証金納付手続き',
      state: depositPaymentValue,
    },
    {
      text: '参加申し込み承認',
      state: formData.data.isApprovalRequired !== 'TRUE' ? '不要' : '必要',
    },
    {
      text: '見積価額',
      state: isEmpty.includes(formData.data.estimatePrice)
        ? '未入力'
        : `${formData.data.estimatePrice} 円`,
    },
    {
      text: '公売保証金',
      state: isEmpty.includes(formData.data.deposit)
        ? '未入力'
        : `${formData.data.deposit} 円`,
    },
    {
      text: '適格請求書',
      state:
        formData.data.qualifiedInvoice !== 'FALSE' ? '発行可能' : '発行不可',
    },
  ];

  const section7AssetContents = [
    {
      text: '保証金納付手続き',
      state: depositPaymentValue,
    },
    {
      text: '見積価額',
      state: isEmpty.includes(formData.data.estimatePrice)
        ? '未入力'
        : `${formData.data.estimatePrice} 円`,
    },
    {
      text: '公売保証金',
      state: isEmpty.includes(formData.data.deposit)
        ? '未入力'
        : `${formData.data.deposit} 円`,
    },
    {
      text: '適格請求書',
      state:
        formData.data.qualifiedInvoice !== 'FALSE' ? '発行可能' : '発行不可',
    },
  ];

  return {
    section1: [
      {
        text: '公開設定',
        state: formData.data.isDraft === 'DRAFT' ? '下書き' : '公開',
      },
    ],
    section2: [
      {
        text: '画像データ',
        state: '画像が登録されていません',
      },
    ],
    section3:
      procedureType === 'PUBLIC_SALE'
        ? section3PublicContents
        : section3AssetContents,
    section4: [
      {
        text: '証明書発行日',
        state: `${yearText(formData.data.effectiveDate.year)}${formData.data.effectiveDate.month}月${formData.data.effectiveDate.day}日`,
      },
      {
        text: '自動車登録番号',
        state: `${isEmpty.includes(formData.data.registerNumber.first) ? '未入力' : formData.data.registerNumber.first} ${isEmpty.includes(formData.data.registerNumber.latter) ? '未入力' : formData.data.registerNumber.latter}\n閲覧者に対し自動車登録番号の下4桁を＊＊＊＊と隠して${formData.data.displayRegisterNumber === 'DISPLAY' ? '表示する' : '表示しない'}`,
      },
      {
        text: '登録年月日',
        state: `${yearText(formData.data.registerDate.year)}${formData.data.registerDate.month}月${formData.data.registerDate.day}日`,
      },
      {
        text: '初度登録年月',
        state: `${yearText(formData.data.firstRegisterDate.year)}${formData.data.firstRegisterDate.month}月${formData.data.firstRegisterDate.day}日`,
      },
      {
        text: '自動車の種別',
        state: isEmpty.includes(formData.data.type)
          ? '未入力'
          : formData.data.type,
      },
      {
        text: '用途',
        state: isEmpty.includes(formData.data.purpose)
          ? '未入力'
          : carPurposeToJp[formData.data.purpose],
      },
      {
        text: '自家用、事業用の別',
        state: isEmpty.includes(formData.data.privateOrBusiness)
          ? '未入力'
          : privateOrBusinessToJp[formData.data.privateOrBusiness],
      },
      {
        text: '車体の形状',
        state: isEmpty.includes(formData.data.shape)
          ? '未入力'
          : formData.data.shape,
      },
      {
        text: '車名（メーカー）',
        state: isEmpty.includes(formData.data.manufacture)
          ? '未入力'
          : carManufactureToJp[formData.data.manufacture],
      },
      {
        text: '型式',
        state: isEmpty.includes(formData.data.model)
          ? '未入力'
          : formData.data.model,
      },
      {
        text: '乗車定員',
        state: isEmpty.includes(formData.data.seatingCapacity)
          ? '未入力'
          : formData.data.seatingCapacity,
      },
      {
        text: '車両重量',
        state: isEmpty.includes(formData.data.weight)
          ? '未入力'
          : formData.data.weight,
      },
      {
        text: '車両総重量',
        state: isEmpty.includes(formData.data.totalWeight)
          ? '未入力'
          : formData.data.totalWeight,
      },
      {
        text: '車台番号',
        state: `${formData.data.productNumber.first}-${formData.data.productNumber.latter}\nハイフンの後を＊＊＊＊と${formData.data.displayProductNumber === 'DISPLAYA' ? '隠さない' : '隠す'}`,
      },
      {
        text: '原動機の型式',
        state: isEmpty.includes(formData.data.engineModel)
          ? '未入力'
          : formData.data.engineModel,
      },
      {
        text: '長さ',
        state: isEmpty.includes(formData.data.length)
          ? '未入力'
          : `${formData.data.length} cm`,
      },
      {
        text: '幅',
        state: isEmpty.includes(formData.data.width)
          ? '未入力'
          : `${formData.data.width} cm`,
      },
      {
        text: '高さ',
        state: isEmpty.includes(formData.data.height)
          ? '未入力'
          : `${formData.data.height} cm`,
      },
      {
        text: '総排気量',
        state: isEmpty.includes(formData.data.displacement)
          ? '未入力'
          : `${formData.data.displacement} リットル`,
      },
      {
        text: '燃料の種類',
        state: isEmpty.includes(formData.data.fuelType)
          ? '未入力'
          : formData.data.fuelType,
      },
      {
        text: '前前軸重',
        state: isEmpty.includes(formData.data.frontAxelWeight)
          ? '未入力'
          : formData.data.frontAxelWeight,
      },
      {
        text: '後後軸重',
        state: isEmpty.includes(formData.data.rearAxelWeight)
          ? '未入力'
          : formData.data.rearAxelWeight,
      },
      {
        text: '自動車検査証有効期限',
        state: `${formData.data.statusSafetyTerm === 'VALID' ? 'あり' : '有効期限切れ'} ${
          formData.data.statusSafetyTerm === 'TRUE'
            ? `${yearText(formData.data.safetyTerm.year)}${formData.data.safetyTerm.month}月${formData.data.safetyTerm.day}日`
            : ''
        }`,
      },
      {
        text: '型式指定番号',
        state: isEmpty.includes(formData.data.formalNumber)
          ? '未入力'
          : formData.data.formalNumber,
      },
      {
        text: '類型区分番号',
        state: isEmpty.includes(formData.data.classificationNumber)
          ? '未入力'
          : formData.data.classificationNumber,
      },
    ],
    section5: [
      {
        text: 'ミッション',
        state:
          formData.data.transmissionType === 'MT'
            ? 'マニュアル'
            : 'オートマチック',
      },
      {
        text: 'ハンドル',
        state:
          formData.data.handleType === 'RIGHT' ? '右ハンドル' : '左ハンドル',
      },
      {
        text: '定期点検記録簿',
        state: isEmpty.includes(formData.data.existsCarMaintenanceSheet)
          ? '未入力'
          : formData.data.existsCarMaintenanceSheet,
      },
      {
        text: '車体の色',
        state: isEmpty.includes(formData.data.carBodyColor)
          ? '未入力'
          : formData.data.carBodyColor,
      },
      {
        text: '走行距離',
        state:
          formData.data.statusCarMileage === 'TRUE'
            ? '不明'
            : isEmpty.includes(formData.data.carMileage)
              ? '未入力'
              : `${formData.data.carMileage} km`,
      },
      {
        text: '引渡時保管場所',
        state: `住所：${formData.data.inventoryLocation} \n地図URL：${formData.data.mapUrl}`,
      },
      {
        text: '車両等の状況',
        state: isEmpty.includes(formData.data.carStatus)
          ? '未入力'
          : formData.data.carStatus,
      },
      {
        text: '引き渡し条件',
        state: isEmpty.includes(formData.data.deliveryTerms)
          ? '未入力'
          : formData.data.deliveryTerms,
      },
      {
        text: 'その他',
        state: isEmpty.includes(formData.data.other)
          ? '未入力'
          : formData.data.other,
      },
      {
        text: '説明',
        state: isEmpty.includes(formData.data.description)
          ? '未入力'
          : formData.data.description,
      },
    ],
    section6: [
      {
        text: '出品行政機関名',
        state: isEmpty.includes(formData.data.inquiryName)
          ? '未入力'
          : formData.data.inquiryName,
      },
      {
        text: '担当部署名',
        state: isEmpty.includes(formData.data.inquiryOffice)
          ? '未入力'
          : formData.data.inquiryOffice,
      },
      {
        text: 'メールアドレス',
        state: isEmpty.includes(formData.data.inquiryEmail)
          ? '未入力'
          : formData.data.inquiryEmail,
      },
      {
        text: '電話番号',
        state:
          formData.data?.inquiryTel.telNum1 &&
          formData.data?.inquiryTel.telNum2 &&
          formData.data?.inquiryTel.telNum3
            ? `${formData.data?.inquiryTel.telNum1}-${formData.data?.inquiryTel.telNum2}-${formData.data?.inquiryTel.telNum3}${formData.data?.extension == 'DATA_EMPTY' ? '' : formData.data?.inquiryExtension ? `  内線: ${formData.data?.inquiryExtension}` : ''}`
            : '未入力',
      },
      {
        text: '電話受付時間',
        state: isEmpty.includes(formData.data.inquiryAccept)
          ? '未入力'
          : formData.data.inquiryAccept,
      },
      {
        text: '備考',
        state: isEmpty.includes(formData.data.inquiryNote)
          ? '未入力'
          : formData.data.inquiryNote,
      },
    ],
    section7:
      procedureType === 'PUBLIC_SALE'
        ? section7PublicContents
        : section7AssetContents,
    section8: [
      {
        text: '連絡先機関名',
        state: isEmpty.includes(formData.data.contactName)
          ? '未入力'
          : formData.data.contactName,
      },
      {
        text: '担当部署名',
        state: isEmpty.includes(formData.data.contactOffice)
          ? '未入力'
          : formData.data.contactOffice,
      },
      {
        text: 'メールアドレス',
        state: isEmpty.includes(formData.data.contactEmail)
          ? '未入力'
          : formData.data.contactEmail,
      },
      {
        text: '電話番号',
        state:
          formData.data?.contactTel.telNum1 &&
          formData.data?.contactTel.telNum2 &&
          formData.data?.contactTel.telNum3
            ? `${formData.data?.contactTel.telNum1}-${formData.data?.contactTel.telNum2}-${formData.data?.contactTel.telNum3}${formData.data?.extension == 'DATA_EMPTY' ? '' : formData.data?.contactExtension ? `  内線: ${formData.data?.contactExtension}` : ''}`
            : '未入力',
      },
      {
        text: '電話受付時間',
        state: isEmpty.includes(formData.data.contactAccept)
          ? '未入力'
          : formData.data.contactAccept,
      },
    ],
  };
};
