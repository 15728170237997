import { HYPHEN_SYMBOL } from 'domain/Property/entities/constants/property';

import { isFalsy } from './isFalsy';
const NUMBER = {
  MINUS_ONE: -1,
};

const municipalitiesChars = {
  CITY: '市',
  WARD: '区',
  TOWN: '町',
  VILLAGE: '村',
};

const prefectureChars = {
  CAPITAL: '都',
  ROAD: '道',
  PREFECTURE_1: '府',
  PREFECTURE_2: '県',
};

const hasMunicipalitiesChars = (str) => {
  return (
    Object.values(municipalitiesChars).filter((char) => char === str).length > 0
  );
};

const hasPrefectureChars = (str) => {
  return (
    Object.values(prefectureChars).filter((char) => char === str).length > 0
  );
};

export const governmentName = (data) => {
  const governmentName = data?.governmentName;

  if (isFalsy(governmentName)) {
    return HYPHEN_SYMBOL;
  }

  // governmentNameの末尾に市, 区, 町, 村を含まない場合はgovernmentNameを返す
  const lastChar = governmentName.slice(NUMBER.MINUS_ONE);
  if (!hasMunicipalitiesChars(lastChar)) {
    return governmentName;
  }

  const subCategoryName = data.subCategoryName;
  // governmentNameの末尾に市, 区, 町, 村を含むけどsubCategoryNameがFaultyならgovernmentNameを返す
  if (isFalsy(subCategoryName)) {
    return governmentName;
  }

  // subCategoryNameの末尾に都, 道, 府, 県を含まない場合は、末尾に都, 道, 府, 県を結合して、governmentNameと結合して返す
  const lastPrefectureChar = subCategoryName.slice(NUMBER.MINUS_ONE);
  if (!hasPrefectureChars(lastPrefectureChar)) {
    // TODO: 現行コードのattributeに当たるものがJSONに含まれていないので仮でprefectureChars.CAPITALを返す
    return `${subCategoryName}${prefectureChars.CAPITAL}${governmentName}`;
  }

  return `${subCategoryName}${governmentName}`;
};
