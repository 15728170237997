import { useState } from 'react';
// import { logInput } from "../../utils/log/logInputs.js";
import * as Validation from 'shared/utils/helper/naviValidators';

export const GuidelinePublicSaleForm = () => {
  const [guidelinePledgeBodyValid, setGuidelinePledgeBodyValid] = useState(null);
  const [guidelineBodyValid1, setGuidelineBodyValid1] = useState(null);
  const [guidelineTitleRequiredValid1, setGuidelineTitleRequiredValid1] = useState(null);
  const [guidelineBodyValid2, setGuidelineBodyValid2] = useState(null);
  const [guidelineTitleRequiredValid2, setGuidelineTitleRequiredValid2] = useState(null);
  const [guidelineBodyValid3, setGuidelineBodyValid3] = useState(null);
  const [guidelineTitleRequiredValid3, setGuidelineTitleRequiredValid3] = useState(null);
  const [guidelineBodyValid4, setGuidelineBodyValid4] = useState(null);
  const [guidelineTitleRequiredValid4, setGuidelineTitleRequiredValid4] = useState(null);
  const [guidelineBodyValid5, setGuidelineBodyValid5] = useState(null);
  const [guidelineTitleRequiredValid5, setGuidelineTitleRequiredValid5] = useState(null);
  const [guidelineBodyValid6, setGuidelineBodyValid6] = useState(null);
  const [guidelineTitleRequiredValid6, setGuidelineTitleRequiredValid6] = useState(null);
  const [guidelineBodyValid7, setGuidelineBodyValid7] = useState(null);
  const [guidelineTitleRequiredValid7, setGuidelineTitleRequiredValid7] = useState(null);
  const [guidelineBodyValid8, setGuidelineBodyValid8] = useState(null);
  const [guidelineTitleRequiredValid8, setGuidelineTitleRequiredValid8] = useState(null);

  function handleGuidelineTitleRequiredValid1(e, required) {
    console.log('handleGuidelineTitleRequiredValid1', e);
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid1(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid1(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid1(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid1(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineTitleRequiredValid2(e, required) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid2(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid2(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid2(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid2(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineTitleRequiredValid3(e, required) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid3(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid3(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid3(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid3(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineTitleRequiredValid4(e, required) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid4(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid4(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid4(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid4(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineTitleRequiredValid5(e, required) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid5(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid5(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid5(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid5(invalid); // エラーメッセージのリセット
  }

  function handleGuidelineTitleRequiredValid6(e, required) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid6(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid6(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid6(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid6(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid6(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineTitleRequiredValid7(e, required) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid7(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid7(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid7(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid7(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid7(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineTitleRequiredValid8(e, required) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid8(invalid);
        return;
      }
    }
    console.log(e.target.value.length);
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid8(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid8(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid8(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid8(invalid); // エラーメッセージのリセット
  }

  function handleGuidelinePledgeBodyValid(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelinePledgeBodyValid(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateRangeLength(text, 0, 2000);
      if (invalid !== 'TRUE') {
        setGuidelinePledgeBodyValid(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelinePledgeBodyValid(invalid);
        return;
      }
    }
    setGuidelinePledgeBodyValid(invalid); //
  }

  function handleGuidelineBodyValid1(text, required) {
    console.log(text);
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid1(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid1(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid1(invalid);
        return;
      }
    }
    setGuidelineBodyValid1(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineBodyValid2(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid2(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid2(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid2(invalid);
        return;
      }
    }
    setGuidelineBodyValid2(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineBodyValid3(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid3(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid3(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid3(invalid);
        return;
      }
    }
    setGuidelineBodyValid3(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineBodyValid4(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid4(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid4(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid4(invalid);
        return;
      }
    }
    setGuidelineBodyValid4(invalid); // エラーメッセージのリセット
  }

  function handleGuidelineBodyValid5(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid5(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid5(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid5(invalid);
        return;
      }
    }
    setGuidelineBodyValid5(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineBodyValid6(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid6(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid6(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid6(invalid);
        return;
      }
    }
    setGuidelineBodyValid6(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineBodyValid7(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid7(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid7(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid7(invalid);
        return;
      }
    }
    setGuidelineBodyValid7(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineBodyValid8(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid8(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid8(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid8(invalid);
        return;
      }
    }
    setGuidelineBodyValid8(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      handleGuidelineTitleRequiredValid1,
      handleGuidelineTitleRequiredValid2,
      handleGuidelineTitleRequiredValid3,
      handleGuidelineTitleRequiredValid4,
      handleGuidelineTitleRequiredValid5,
      handleGuidelineTitleRequiredValid6,
      handleGuidelineTitleRequiredValid7,
      handleGuidelineTitleRequiredValid8,
      handleGuidelinePledgeBodyValid,
      handleGuidelineBodyValid1,
      handleGuidelineBodyValid2,
      handleGuidelineBodyValid3,
      handleGuidelineBodyValid4,
      handleGuidelineBodyValid5,
      handleGuidelineBodyValid6,
      handleGuidelineBodyValid7,
      handleGuidelineBodyValid8,
    },
    // invalidの時のメッセージ
    validationMessage: {
      guidelineTitleRequiredValid1,
      guidelineTitleRequiredValid2,
      guidelineTitleRequiredValid3,
      guidelineTitleRequiredValid4,
      guidelineTitleRequiredValid5,
      guidelineTitleRequiredValid6,
      guidelineTitleRequiredValid7,
      guidelineTitleRequiredValid8,
      guidelinePledgeBodyValid,
      guidelineBodyValid1,
      guidelineBodyValid2,
      guidelineBodyValid3,
      guidelineBodyValid4,
      guidelineBodyValid5,
      guidelineBodyValid6,
      guidelineBodyValid7,
      guidelineBodyValid8,
    },
  };

  return validations;
};
