import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import PropertyInfoEstateComplete from 'interfaces/components/Property/PropertyInfo/Estate/PropertyInfoEstateComplete';
import PropertyInfoEstateConfirm from 'interfaces/components/Property/PropertyInfo/Estate/PropertyInfoEstateConfirm';
import PropertyInfoEstateForm from 'interfaces/components/Property/PropertyInfo/Estate/PropertyInfoEstateForm';
import { breadcrumbs } from 'interfaces/pages/PropertyInfoEstate/entities/breadcrumbs';
import { PropertyInfoEstateContext } from 'interfaces/pages/PropertyInfoEstate/entities/PropertyInfoEstateContext';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { EstateFormData } from './EstateFormData';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

const Estate = () => {
  const [onConfirm, setOnConfirm] = useState(false);
  const [onComplete, setOnComplete] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { estateProperty, invitation, paymentMethods, property } = useContext(
    PropertyInfoEstateContext,
  );
  const { auction_id, property_id } = useParams();
  const search = useLocation().search;
  let queryParams = new URLSearchParams(search);
  let openWay = queryParams.get('open-way');
  let isEdit = false;

  if (property_id !== undefined) {
    isEdit = true;
  }
  let formData = EstateFormData({ isEdit: isEdit });
  const { localStorageProcedureType } = useLocalStorage();

  useEffect(() => {
    if (property_id === undefined) {
      formData.init.setPublicSaleType(openWay);
      return;
    }

    // 公開設定
    formData.init.setIsDraft(property?.isDraft);

    // 2. 物件情報
    formData.init.setPrintOrder(property?.printOrder);
    formData.init.setCategoryId(property?.categoryId);
    //formData.init.setRecommendProperties({ recommend: recommend_property ?? true, })
    formData.init.setDivisionId(property?.divisionId);
    formData.init.setSaleNumber(property?.saleNumber);
    formData.init.setName(property?.name);
    let methods = {};
    paymentMethods?.map((method) => {
      if (method.payment === 'BANK_TRANSFER') {
        methods.bankTransfer = 'TRUE';
      }
      if (method.payment === 'REGISTERED_MAIL') {
        methods.registeredMail = 'TRUE';
      }
      if (method.payment === 'CHECK') {
        methods.check = 'TRUE';
      }
      if (method.payment === 'POSTAL_MONEY_ORDER') {
        methods.postalMoneyOrder = 'TRUE';
      }
      if (method.payment === 'CARRY') {
        methods.carry = 'TRUE';
      }
    });
    formData.init.setPaymentMethod(methods);
    formData.init.setVideoUrl(property?.videoUrl);
    formData.init.setType(estateProperty?.type); // 物件の種類
    formData.init.setGovernmentSubCategoryId(property?.governmentSubCategoryId); // 物件の所在(properties)
    formData.init.setResolutionDate(property?.resolutionDate); // 物件の所在(properties)
    formData.init.setPaymentLimit(property?.paymentLimit); // 納付期限
    formData.init.setContractedLimit(property?.contractedLimit); // 契約締結期限（公有）

    // 3. 基本情報
    formData.init.setPropertyPrint(estateProperty?.propertyPrint); // 登記簿の表示
    formData.init.setLocationGovernmentSubCategoryId(
      estateProperty?.locationGovernmentSubCategoryId,
    ); // 所在地
    formData.init.setLocationText(estateProperty?.locationText); // 所在地(テキスト)
    formData.init.setResidenceGovernmentSubCategoryId(
      estateProperty?.residenceGovernmentSubCategoryId,
    ); // 住居表示
    formData.init.setResidenceLocation(estateProperty?.residenceLocation); // 住居表示(テキスト)
    formData.init.setNearestStation(estateProperty?.nearestStation); // 交通、最寄りの駅など
    formData.init.setLocationUrl(estateProperty?.locationUrl); // 所在図
    formData.init.setEstateStatus(estateProperty?.estateStatus); // 現況
    formData.init.setOther(estateProperty?.other); // その他事項
    formData.init.setSketchUrl(estateProperty?.sketchUrl); // 見取り図など
    formData.init.setDescription(estateProperty?.description); // 説明

    // 4. 土地の情報
    formData.init.setLandSpace(estateProperty?.landSpace); // 土地面積
    formData.init.setLandSpaceType(estateProperty?.landSpaceType); // 土地面積種別
    formData.init.setLandRights(estateProperty?.landRights); // 土地権利

    let tmpLandShare = {};
    if (estateProperty?.landShare !== undefined) {
      if (estateProperty.landShare.includes('/')) {
        let landShare = estateProperty.landShare.split('/');
        tmpLandShare.first = landShare[0];
        tmpLandShare.latter = landShare[1];
      } else {
        tmpLandShare.first = undefined;
        tmpLandShare.latter = undefined;
      }
    } else {
      tmpLandShare.first = undefined;
      tmpLandShare.latter = undefined;
    }
    formData.init.setLandShare(tmpLandShare); // 土地持分

    formData.init.setLandCategory(estateProperty?.landCategory); // 地目
    formData.init.setCityPlanningArea(estateProperty?.cityPlanningArea); // 都市計画地域
    formData.init.setRestrictedArea(estateProperty?.restrictedArea); // 用途地域
    formData.init.setBuildRatio(estateProperty?.buildRatio); // 建ぺい率
    formData.init.setFloorAreaRatio(estateProperty?.floorAreaRatio); // 容積率
    formData.init.setHighDistrict(estateProperty?.highDistrict); // 高度地区
    formData.init.setFirebreakArea(estateProperty?.firebreakArea); // 防火地域
    formData.init.setShadeRegulation(estateProperty?.shadeRegulation); // 日影規制
    formData.init.setLawRegulation(estateProperty?.lawRegulation); // その他法令などの規制
    formData.init.setGeographical(estateProperty?.geographical); // 地勢など
    formData.init.setNeighboringRoad(estateProperty?.neighboringRoad); // 幅員、接道状況
    formData.init.setPrivateRoad(estateProperty?.privateRoad); // 私道の負担など
    formData.init.setProvideEquipment(estateProperty?.provideEquipment); // 供給処理施設
    formData.init.setLandOther(estateProperty?.landOther); // その他土地に関する物件情報

    // 5. 建物の情報
    formData.init.setBuildName(estateProperty?.buildName); // 建物の名称
    formData.init.setBuildSpace(estateProperty?.buildSpace); // 建物面積
    formData.init.setExclusiveSpace(estateProperty?.exclusiveSpace); // 専有面積
    formData.init.setFloorSpace(estateProperty?.floorSpace); // 延床面積
    formData.init.setStructureType(estateProperty?.structureType); // 構造

    let tmpBuildDate = {};
    if (estateProperty?.buildDate !== undefined) {
      let targetDate = new Date(estateProperty.buildDate);
      tmpBuildDate.year = targetDate.getFullYear().toString();
      tmpBuildDate.month = (targetDate.getMonth() + 1).toString();
      tmpBuildDate.day = '1';
    }
    formData.init.setBuildDate(tmpBuildDate); // 建築年月

    formData.init.setBuildDateOther(estateProperty?.buildDateOther); // 建築年月（その他を選択した時出るフォーム）
    formData.init.setFloor(estateProperty?.floor); // 階建/階
    formData.init.setTotalHouses(estateProperty?.totalHouses); // 総戸数
    formData.init.setLayout(estateProperty?.layout); // 間取り
    formData.init.setBuildOther(estateProperty?.buildOther); // その他建物に関する物件情報

    // 6. 物件に関するお問い合わせ先
    formData.init.setInquiryName(property?.inquiryName);
    formData.init.setInquiryOffice(property?.inquiryOffice);
    formData.init.setInquiryEmail(property?.inquiryEmail);
    formData.init.setInquiryTel(
      property?.inquiryTel !== undefined
        ? {
            telNum1: property?.inquiryTel.split('-')[0],
            telNum2: property?.inquiryTel.split('-')[1],
            telNum3: property?.inquiryTel.split('-')[2],
          }
        : {
            telNum1: '',
            telNum2: '',
            telNum3: '',
          },
    );
    formData.init.setInquiryExtension(property?.inquiryExtension);
    formData.init.setInquiryAccept(property?.inquiryAccept);
    formData.init.setInquiryNote(property?.inquiryNote);

    // 7. 物件の詳細設定
    formData.init.setDepositPayment(property?.depositPayment);
    formData.init.setIsApprovalRequired(property?.isApprovalRequired);
    formData.init.setEstimatePrice(property?.estimatePrice);
    formData.init.setDeposit(property?.deposit);
    formData.init.setQualifiedInvoice(property?.qualifiedInvoice);

    // 8. 落札後の連絡先
    formData.init.setContactName(property?.contactName);
    formData.init.setContactOffice(property?.contactOffice);
    formData.init.setContactEmail(property?.contactEmail);
    formData.init.setContactTel(
      property?.contactTel !== undefined
        ? {
            telNum1: property?.contactTel.split('-')[0],
            telNum2: property?.contactTel.split('-')[1],
            telNum3: property?.contactTel.split('-')[2],
          }
        : {
            telNum1: '',
            telNum2: '',
            telNum3: '',
          },
    );
    formData.init.setContactExtension(property?.contactExtension);
    formData.init.setContactAccept(property?.contactAccept);
  }, [estateProperty, paymentMethods, property]);

  // validation制御
  useEffect(() => {
    // 必須ではないフォーム
    const baseNotRequiredKeys = [
      // 2. 物件情報
      'printOrderValid',
      'categoryNamesValid',
      'recommendPropertiesValid',
      'videoUrlValid',
      'contractedLimitValid',

      // 3.基本情報
      'propertyPrintValid',
      'residenceGovernmentSubCategoryIdValid',
      'residenceLocationValid',
      'nearestStationValid',
      'locationUrlValid',
      'estateStatusValid',
      'sketchUrlValid',
      'otherValid',
      'descriptionValid',

      // 4.土地の情報
      'landSpaceValid',
      'landSpaceTypeValid',
      'landRightsValid',
      'landShareValid',
      'landCategoryValid',
      'cityPlanningAreaValid',
      'restrictedAreaValid',
      'buildRatioValid',
      'floorAreaRatioValid',
      'highDistrictValid',
      'firebreakAreaValid',
      'shadeRegulationValid',
      'lawRegulationValid',
      'geographicalValid',
      'neighboringRoadValid',
      'privateRoadValid',
      'provideEquipmentValid',
      'landOtherValid',

      // 5.建物の情報
      'buildNameValid',
      'buildSpaceValid',
      'exclusiveSpaceValid',
      'floorSpaceValid',
      'structureTypeValid',
      'buildDateValid',
      'buildDateOtherValid',
      'floorValid',
      'totalHousesValid',
      'layoutValid',
      'buildOtherValid',

      // 6. 物件に関するお問い合わせ先
      'inquiryNameValid',
      'inquiryOfficeValid',
      'inquiryEmailValid',
      'inquiryTelValid',
      'inquiryTelNum1Valid',
      'inquiryTelNum2Valid',
      'inquiryTelNum3Valid',
      'inquiryExtensionValid',
      'inquiryAcceptValid',
      'inquiryNoteValid',

      // 8. 落札後の連絡先
      'contactOfficeValid',
      'contactEmailValid',
      'contactTelValid',
      'contactTelNum1Valid',
      'contactTelNum2Valid',
      'contactTelNum3Valid',
      'contactExtensionValid',
      'contactAcceptValid',
    ];

    const isPublicSale = localStorageProcedureType === 'PUBLIC_SALE';
    const conditionNotRequiredKeys = isPublicSale
      ? ['resolutionDateValid']
      : [];

    const notRequiredKeys = [
      ...baseNotRequiredKeys,
      ...conditionNotRequiredKeys,
      //...(includeAddressKeys ? addressRelatedKeys : []),
    ];
    const validationResults = Object.entries(formData.validationMessage).map(
      ([key, validationMessage]) => {
        const isValid = notRequiredKeys.includes(key)
          ? validationMessage === null || validationMessage === 'TRUE'
          : validationMessage === 'TRUE';
        return { key, validationMessage, isValid };
      },
    );

    const allValid = validationResults.every(({ isValid }) => isValid);
    setIsValid(allValid);
  }, [formData]);

  const handleConfirm = (e) => {
    e.preventDefault();
    setOnConfirm(true);
  };

  const handleBackForm = (e) => {
    e.preventDefault();
    setOnConfirm(false);
  };

  const handleComplete = (e) => {
    e.preventDefault();
    setOnComplete(true);
  };

  const getMethod = () => {
    if (property_id !== undefined) {
      return 'update';
    }
    return 'create';
  };

  const getTitle = () => {
    if (getMethod() === 'create') {
      return `物件情報（不動産）登録：${invitation?.name}`;
    }
    return `物件登録情報編集：${invitation?.name}`;
  };

  return (
    <div
      className="new-style_navi_form new-style_navi_object_create_real-estate"
      data-testid="property-info-estate">
      {onComplete ? (
        <PropertyInfoEstateComplete method={getMethod()} openWay={openWay} />
      ) : onConfirm ? (
        <PropertyInfoEstateConfirm
          formData={formData}
          handleComplete={handleComplete}
          handleBackForm={handleBackForm}
          openWay={openWay}
        />
      ) : (
        <>
          <Breadcrumb
            breadcrumbs={breadcrumbs(
              `${getMethod()}`,
              'form',
              invitation?.name,
              auction_id,
              openWay,
            )}
          />
          <PropertyInfoGeneralPageDescription
            title={getTitle()}
            content={
              property_id === undefined
                ? '物件の登録を行います。入力が終わったら「確認する」ボタンをクリックしてください。'
                : '物件の編集を行います。入力が終わったら「確認画面」ボタンを押してください。'
            }
          />
          <PropertyInfoEstateForm
            formData={formData}
            handleConfirm={handleConfirm}
            openWay={openWay}
            isValid={isValid}
          />
        </>
      )}
    </div>
  );
};

export default Estate;
