import { HYPHEN_SYMBOL } from 'domain/Property/entities/constants/property';

import { isNaturalNumber } from 'shared/utils/helper/isNaturalNumber';
import toYenFrom from 'shared/utils/helper/toYenString';

const ZERO_YEN = 0;

export const price = (property) => {
  const status = property?.status;
  const publicSaleType = property?.publicSaleType;
  const estimatePrice = isNaturalNumber(property?.estimatePrice)
    ? toYenFrom(property?.estimatePrice)
    : ZERO_YEN;
  const currentTopPrice = isNaturalNumber(property?.currentTopPrice)
    ? toYenFrom(property?.currentTopPrice)
    : estimatePrice;
  const biddedPrice = isNaturalNumber(property?.biddedPrice)
    ? toYenFrom(property?.biddedPrice)
    : HYPHEN_SYMBOL;

  // 申込開始〜入札開始まで
  if (status === 'APPLYING' || status === 'WAIT_START_BID') {
    return `${estimatePrice}`;
  }

  // 入札開始〜開札まで
  if (
    status === 'DURING_BIDDING' ||
    status === 'WAIT_OPEN_BID' ||
    status === 'DONE_BIDDING'
  ) {
    if (publicSaleType === 'せり売形式') {
      return `${currentTopPrice}`;
    } else {
      return `${estimatePrice}`;
    }
  }

  // 開札後
  if (status === 'END_BIDDING') {
    return `${biddedPrice}`;
  }
  return `${ZERO_YEN}`;
};
