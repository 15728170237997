
export class ExistsCarMaintenanceSheet {
  #existsCarMaintenanceSheet

  constructor(existsCarMaintenanceSheet) {
    this.#existsCarMaintenanceSheet = existsCarMaintenanceSheet;
  }

  get() {
    return this.#existsCarMaintenanceSheet
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
