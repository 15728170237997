// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createPropertiesImageData,
  deletePropertiesImageData,
  updatePropertiesImageData
} from "../../../infrastructure/api/PropertiesImage.js";
import { compareData } from "../../../shared/utils/helper/compareData.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { PropertyFilesCreate } from "../entities/Files.js";
import { getPropertyFiles } from "../repositories/PropertyFiles.js";
//import {
//  createPropertyFilesData,
//  updatePropertyFilesData,
//  deletePropertyFilesData
//} from "../../../infrastructure/api/PropertyFiles.js";

export async function get(type) {
  return await getPropertyFiles(type)
}

export async function findById(type, id) {
  const Files = await getPropertyFiles(type);
  return Files.find(data => data.propertyId === id)
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Files)');
  }
  if (type === NAVI) {
    const propertiesImages = new PropertyFilesCreate(data)
    response = await createPropertiesImageData(propertiesImages);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Files)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Files', response);
  }
  return response
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Files)');
  }
  if (type === NAVI) {
    const propertiesImages = new PropertyFilesCreate(data)
    response = await updatePropertiesImageData(propertiesImages);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Files)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Files', response);
  }
  return response
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Files)');
  }
  if (type === NAVI) {
    response = deletePropertiesImageData(data.id);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Files)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Files', response);
  }
  return response
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]))
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]))
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value)
}
