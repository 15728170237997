import { ReactComponent as ExpandMoreIcon } from 'interfaces/assets/svg/expand_more_icon.svg';
import { ReactComponent as ExpandLessIcon } from 'interfaces/assets/svg/expand_less_icon.svg';

import 'interfaces/css/Dashboard/kmanager-auction-table-record.css';
import { isEmpty } from 'shared/utils/constants/emptyData';

const AuctionGovernmentTableRecord = ({
  auction,
  government,
  onClick,
  isVisibleRow,
}) => {
  return (
    <tr
      className="kmanager-auction-table-record"
      data-testid="kmanager-auction-table-record">
      <td
        className="kmanager-auction-table-control"
        data-testid="kmanager-auction-table-td">
        {isVisibleRow ? (
          <ExpandLessIcon title="expand-less" onClick={onClick} />
        ) : (
          <ExpandMoreIcon title="expand-more" onClick={onClick} />
        )}
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{government?.region}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{government?.location}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{government?.name}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>
          {government?.participationStatus === 'NO_PARTICIPATION'
            ? '不参加'
            : ''}
        </div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>
          {isEmpty(government?.bidEndButtonDownAuctionDate)
            ? '未処理'
            : ''}
        </div>
      </td>
      {auction.governmentTypeGroup !== 'NATIONAL_TAX_AGENCY' && (
        <>
          <td data-testid="kmanager-auction-table-td">
            <div>
              {isEmpty(government?.bidEndButtonDownBidDate)
                ? '未処理'
                : ''}
            </div>
          </td>
          <td data-testid="kmanager-auction-table-td">
            <div>{government?.mailCertDocument === 'NO_MAIL' ? '' : '○'}</div>
          </td>
        </>
      )}
    </tr>
  );
};

export default AuctionGovernmentTableRecord;
