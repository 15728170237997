import Modal2 from 'interfaces/components/common/Modal/Modal2';
import 'interfaces/css/common/modal2.css';
import Button from 'interfaces/components/common/Button.jsx';
import styled from 'styled-components';

export const NaviUserManagerConfirmModal = ({
  disableToShowConfirmModal,
  register,
  toRegister,
  showConfirmModal,
  toUserType,
}) => {
  const CONFIRM_BUTTONS_DATA = [
    {
      text: '「管理者」に変更を実行',
      clickHandler: (e) => {
        console.log('clicked register');
        disableToShowConfirmModal();
        register(e);
      },
      className:
        'property-info-general-form-button new-style_navi_primary-button',
    },
    {
      text: '再編集',
      clickHandler: (e) => {
        console.log('clicked toRegister');
        disableToShowConfirmModal();
        toRegister(e);
      },
      className:
        'property-info-general-form-button new-style_navi_normal-button',
    },
  ];

  const displayUserType = (toUserType) => {
    if (toUserType === 'PARENT') {
      return '親';
    } else if (toUserType === 'CHILD') {
      return '子';
    }
  };

  return (
    <Modal2
      isOpen={showConfirmModal}
      handleClose={disableToShowConfirmModal}
      modalSize={'550px'}
      padding={false}>
      <div className="kmanager-container" data-testid="kmanager-confirm">
        <h2 className="modal2-inner-h2">
          ユーザー権限を「管理者」に変更しようとしています
        </h2>
        <p className="pa-6 ma-0 ls-05">
          ユーザー権限を<b>「管理者」</b>
          に変更すると、現在ご利用のアカウント権限は
          <b>「{displayUserType(toUserType)}」</b>に変更されます
        </p>
        <hr className="ma-0" style={{ color: 'gray' }} />
        <ButtonList>
          {CONFIRM_BUTTONS_DATA.map((item, index) => (
            <Button
              key={index}
              text={item.text}
              clickHandler={item.clickHandler}
              isOutline={item.isOutline}
              className={item.className}
            />
          ))}
        </ButtonList>
      </div>
    </Modal2>
  );
};

const ButtonList = styled.div`
  padding: 10px;
  text-align: end;
`;
