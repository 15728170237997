import CommonInput from 'interfaces/components/common/Input/CommonInput';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel';

const CreditCardSecurityCodeInput = ({ creditCardInfo, handleChange }) => {
  return (
    <tr>
      <th>セキュリティコード<RequiredLabel/></th>
      <td>
        <CommonInput
          placeholder="（例）123"
          value={creditCardInfo.securityCode}
          handleChange={e => handleChange({ securityCode: e.target.value })}
        />
      </td>
    </tr>
  );
};

export default CreditCardSecurityCodeInput;
