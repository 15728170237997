import { useValidate } from "../../../../shared/hooks/useValidate";
import CommonInput from '../Input/CommonInput.jsx';

const TextForm = ({ label, value, handleChange, validates, charLimit }) => {
  const { handleValidates } = useValidate();
  const validateMessage = handleValidates(value, validates);

  return (
    <tr data-testid="principal-reason">
      <th data-testid="text-form-label">{label}</th>
      <td>
        <div className="w-expand">
          <CommonInput
            type="text"
            name="text"
            value={value}
            handleChange={handleChange}
            validateMessage={validateMessage}
          />
          {
            charLimit &&
              <p className="char-limit-countdown" data-testid="char-limit-countdown">
                {`${value?.length ?? 0} / ${charLimit}`}
              </p>
          }
        </div>
      </td>
    </tr>
  );
};

export default TextForm;
