import Modal2 from 'interfaces/components/common/Modal/Modal2';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Guideline from './Guideline';

const GuidelineModal = ({
  guideline,
  governmentName,
  procedureType,
  label,
}) => {
  const location = useLocation();
  const currentQuery = location.search;
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
    document.documentElement.style.overflowY = 'hidden';
  };

  const handleClose = () => {
    setIsOpen(false);
    document.documentElement.style.overflowY = 'scroll';
  };

  return (
    <div data-testid="guideline-modal">
      <Link
        className="noshrink"
        to={{ pathname: location.pathname, search: currentQuery }}
        onClick={() => handleOpen()}
        data-testid="guideline-label">
        {label}
      </Link>
      <Modal2
        isOpen={isOpen}
        closeModal={() => handleClose()}
        isCloseButton={true}>
        <Guideline
          governmentName={governmentName}
          procedureType={procedureType}
          data={guideline}
          closeModal={() => handleClose()}
        />
      </Modal2>
    </div>
  );
};

export default GuidelineModal;
