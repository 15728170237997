
export class RegistrationDate {
  #registrationDate

  constructor(registrationDate) {
    this.#registrationDate = registrationDate;
  }

  get() {
    return this.#registrationDate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
