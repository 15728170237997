
export class QualifiedInvoice {
    #qualifiedInvoice

    constructor(qualifiedInvoice) {
        this.#qualifiedInvoice = qualifiedInvoice;
    }

    get() {
        return this.#qualifiedInvoice
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
