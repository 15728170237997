
export class SystemUsageFeeAuction {
  #systemUsageFeeAuction

  constructor(systemUsageFeeAuction) {
    this.#systemUsageFeeAuction = systemUsageFeeAuction;
  }

  get() {
    return this.#systemUsageFeeAuction
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
