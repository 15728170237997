import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Accordion from './Accordion.jsx';
import { ReactComponent as ExpandMoreIcon } from '../../../assets/svg/expand_more_icon.svg';

const LeftSideMenuList = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);

  const LeftSideMenuListData = [
    {
      text: 'オークション実施一覧',
      handleClick: () => navigation('/kmanager'),
      path: '/kmanager/auction',
    },
    {
      text: '登録行政機関管理',
      handleClick: () => navigation('/kmanager/gov_list'),
      path: '/kmanager/gov_list',
    },
    {
      text: '各種ツール',
      handleClick: () => setIsOpen((prev) => !prev),
      isOpen: isOpen,
      svg: <ExpandMoreIcon title="expand-more" />,
      topics: [
        {
          text: 'カテゴリ管理',
          handleClick: () => navigation('/kmanager/category'),
          path: '/kmanager/category',
        },
        {
          text: 'お知らせ管理',
          handleClick: () => navigation('/kmanager/notice'),
          path: '/kmanager/notice',
        },
        {
          text: 'バナー管理',
          handleClick: () => navigation('/kmanager/banner'),
          path: '/kmanager/banner',
        },
        {
          text: 'プロモーション枠管理',
          handleClick: () => navigation('/kmanager/promotion'),
          path: '/kmanager/promotion',
        },
        {
          text: '緊急メッセージ管理',
          handleClick: () => navigation('/kmanager/message'),
          path: '/kmanager/message',
        },
      ],
    },
  ];

  const isActive = (path) => {
    if (location.pathname === '/kmanager') {
      return path === '/kmanager/auction';
    }

    return location.pathname.startsWith(path);
  };

  return (
    <div
      data-testid="kmanager-left-side-menu-list"
      className="kmanager-left-side-menu-list py-2">
      {LeftSideMenuListData.map((item, index) => (
        <Accordion
          key={index}
          text={item.text}
          handleClick={item.handleClick}
          topics={item.topics}
          isOpen={item.isOpen}
          svg={item.svg}
          className={isActive(item.path) ? 'active-menu-item' : ''}
        />
      ))}
    </div>
  );
};
export default LeftSideMenuList;
