import { useContext } from 'react';
import { Link } from 'react-router-dom';

import SignUpForm from './SignUpForm.jsx';
import SignUpDescriptionImage from '../../../../../../assets/image/sign_up_description.png';
import { DashboardContext } from '../../../../../../pages/Dashboard/entities/DashboardContext';
import Button from '../../../../../common/Button/Button.jsx';

const SignUpModalContents = ({ handleClose, switchAuthStatus }) => {
  const { authState } = useContext(DashboardContext);
  return (
    <div data-testid="sign-up-modal-contents">
      <div className="sign-up-modal-title" data-testid="sign-up-modal-title">
        新規登録
      </div>
      <img
        src={SignUpDescriptionImage}
        alt="新規登録についての説明"
        className="sign-up-description-image"
        data-testid="sign-up-description-image"
      />
      <div className="sign-up-modal-contents-body">
        <SignUpForm />
        <div className="text-center" data-testid="to-login-form">
          登録済みの方は{' '}
          <Link onClick={() => switchAuthStatus(authState.LOGIN)}>こちら</Link>{' '}
          からログインしてください
        </div>
        <div className="close-sign-up-modal-button-wrap">
          <Button
            text="閉じる"
            onClick={handleClose}
            className="close-sign-up-modal-button"
          />
        </div>
      </div>
    </div>
  );
};

export default SignUpModalContents;
