// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-info-general-form-input-check {
  display: flex;
}

.property-info-general-form-input-check .input-checkbox {
  margin: 0;
  padding-top: 0;
}

.property-info-general-form-input-check-payment-method .input-checkbox {
  margin: 0;
  padding-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/property-info-general-form-input-check.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,cAAc;AAChB","sourcesContent":[".property-info-general-form-input-check {\n  display: flex;\n}\n\n.property-info-general-form-input-check .input-checkbox {\n  margin: 0;\n  padding-top: 0;\n}\n\n.property-info-general-form-input-check-payment-method .input-checkbox {\n  margin: 0;\n  padding-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
