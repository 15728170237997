import { validateSelected } from "../../../../application/validations.js";

export class SafetyTerm {
  #safetyTerm

  constructor(safetyTerm) {
    this.#safetyTerm = safetyTerm;
  }

  get() {
    return this.#safetyTerm
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#safetyTerm)
  }
}
