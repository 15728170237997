// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.properties .checkbox-group .checkbox-group-item {
    align-items: center;
    display: flex;
    height: auto;
    outline: none;
    margin: 2px 0 2px 4px;
}

.properties .checkbox-group .checkbox-group-item .svg-and-input-wrap {
    display: inline-flex;
    margin-right: 8px;
}

.properties .checkbox-group .checkbox-group-item .checkbox-svg,
.properties .checkbox-group .checkbox-group-item .checkbox-marked-svg {
    width: 16px;
    height: 16px;
}

.properties .checkbox-group .checkbox-group-item .checkbox-svg {
    color: rgba(0,0,0,.54);
    fill: rgba(0,0,0,.54);
}

.properties .checkbox-group .checkbox-group-item .checkbox-marked-svg {
    color: var(--primary-base);
    fill: var(--primary-base);
}

.properties .checkbox-group .checkbox-group-item .checkbox-input {
    width: 16px;
    height: 16px;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}

.properties .checkbox-group .checkbox-group-item .checkbox-label.active {
    color: var(--primary-base);
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Properties/checkbox-group.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;;IAEI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;IAC1B,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".properties .checkbox-group .checkbox-group-item {\n    align-items: center;\n    display: flex;\n    height: auto;\n    outline: none;\n    margin: 2px 0 2px 4px;\n}\n\n.properties .checkbox-group .checkbox-group-item .svg-and-input-wrap {\n    display: inline-flex;\n    margin-right: 8px;\n}\n\n.properties .checkbox-group .checkbox-group-item .checkbox-svg,\n.properties .checkbox-group .checkbox-group-item .checkbox-marked-svg {\n    width: 16px;\n    height: 16px;\n}\n\n.properties .checkbox-group .checkbox-group-item .checkbox-svg {\n    color: rgba(0,0,0,.54);\n    fill: rgba(0,0,0,.54);\n}\n\n.properties .checkbox-group .checkbox-group-item .checkbox-marked-svg {\n    color: var(--primary-base);\n    fill: var(--primary-base);\n}\n\n.properties .checkbox-group .checkbox-group-item .checkbox-input {\n    width: 16px;\n    height: 16px;\n    position: absolute;\n    opacity: 0;\n    cursor: pointer;\n    user-select: none;\n}\n\n.properties .checkbox-group .checkbox-group-item .checkbox-label.active {\n    color: var(--primary-base);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
