import { useLocation, useNavigate, useParams } from 'react-router-dom';
import toYenFrom from 'shared/utils/helper/toYenString';
import { ReactComponent as EyeIcon } from 'interfaces/assets/svg/eye.svg';
import { ReactComponent as PencilIcon } from 'interfaces/assets/svg/pencil.svg';
import FlatButton from 'interfaces/components/common/Button/FlatButton';
import MediumLightButton from 'interfaces/components/common/Button/MediumLightButton';
import { DepositPaymentToJP } from 'shared/utils/helper/convert/DepositPaymentToJp';

import 'interfaces/css/PublishedObject/published-object-table.css';
import { PublishedObjectContext } from 'interfaces/pages/PublishedObject/entities/PublishedObjectContext';
import { useContext } from 'react';

const PublishedObjectTable = ({
  tableData = {},
  procedureType = 'PUBLIC_SALE',
}) => {
  const navigation = useNavigate();
  const { divisions } = useContext(PublishedObjectContext);
  const { auction_id } = useParams();
  const search = useLocation().search;

  let queryParams = new URLSearchParams(search);
  let openWay = queryParams.get('open-way');

  // 必要なければ削除
  const displayStatusLabel = (strStatus) => {
    switch (strStatus) {
      case 'APPLYING':
        return '申し込み中';
      case 'WAIT_START_BID':
        return '入札開始待ち';
      case 'DURING_BIDDING':
        return '入札中';
      case 'WAIT_OPEN_BID':
        return '開札待ち';
      case 'ADDITIONAL_DURING_BIDDING':
        return '追加入札中';
      case 'DONE_BIDDING':
        return '入札完了';
      case 'END_BIDDING':
        return '入札終了';
      case 'CANCEL':
        return '取消';
      case 'CLOSED':
        return '公開終了';
      default:
        return '-';
    }
  };

  let estimate = 0;
  if (!isNaN(Number(tableData.estimatePrice))) {
    estimate = Number(tableData.estimatePrice);
  }
  let deposit = 0;
  if (!isNaN(Number(tableData.deposit))) {
    deposit = Number(tableData.deposit);
  }
  let bidEndPrice = 0;
  if (!isNaN(Number(tableData.bidEndPrice))) {
    bidEndPrice = Number(tableData.bidEndPrice);
  }

  let divisionName = '';
  let division = divisions.find(
    (division) => division.id.toString() === tableData.divisionId.toString(),
  );
  if (division !== undefined) {
    divisionName = division.name;
  }

  return (
    <div className="published-object-table-wrap">
      <div
        className="published-object-table"
        data-testid="published-object-table">
        <div className="published-object-table-inner">
          <table>
            <thead
              className="published-object-table-header"
              data-testid="published-object-table-header">
              <tr data-testid="header-row">
                <th className="status-column" data-testid="header-col">
                  状況
                </th>
                <th className="info-column" data-testid="header-col">
                  公開物件情報
                </th>
                <th className="actions-column" data-testid="header-col">
                  取引情報
                </th>
                <th className="edit-column" data-testid="header-col"></th>
              </tr>
            </thead>
            <tbody
              className="published-object-table-body"
              data-testid="published-object-table-body">
              <tr data-testid="body-row">
                <td data-testid="body-col">
                  {displayStatusLabel(tableData.status)}
                </td>
                <td className="min" data-testid="body-col">
                  <dl>
                    <dt>{'区分番号'}</dt>
                    <dd>{tableData.saleNumber}</dd>
                  </dl>
                  <dl>
                    <dt>執行機関</dt>
                    <dd>{divisionName}</dd>
                  </dl>
                  <dl>
                    <dt>タイトル</dt>
                    <dd>{tableData.name}</dd>
                  </dl>
                  <dl>
                    <dt>
                      {procedureType === 'PUBLIC_SALE'
                        ? '見積価額'
                        : '予定価格'}
                    </dt>
                    <dd>{toYenFrom(estimate)}</dd>
                  </dl>
                  <dl>
                    <dt>
                      {procedureType === 'PUBLIC_SALE'
                        ? '公売保証金額'
                        : '入札保証金額'}
                    </dt>
                    <dd>{toYenFrom(deposit)}</dd>
                  </dl>
                  <dl>
                    <dt>落札価額</dt>
                    <dd>{toYenFrom(bidEndPrice)}</dd>
                  </dl>
                  <dl>
                    <dt>設定納付方法</dt>
                    <dd>{DepositPaymentToJP(tableData.depositPayment)}</dd>
                  </dl>
                  <dl>
                    <dt>申込数</dt>
                    <dd>
                      {tableData.applicationsNum}件 (仮申込者: 残り
                      {tableData.provisionalApplicationsNum}名)
                    </dd>
                  </dl>
                  <dl>
                    <dt>マイリスト登録数</dt>
                    <dd>{tableData.myListsNum}件</dd>
                  </dl>
                  <dl>
                    <dt>入札者数</dt>
                    <dd>{tableData.biddersNum}人</dd>
                  </dl>
                  <dl>
                    <dt>適格請求書</dt>
                    <dd>
                      {tableData.isInvoiceTarget === 'FALSE'
                        ? '発行不可'
                        : '発行可能'}
                    </dd>
                  </dl>
                </td>
                <td data-testid="body-col">
                  <div className="torihiki-actions">
                    <div className="torihiki-actions-row-column">
                      <MediumLightButton
                        text="申込者一覧"
                        onClick={() =>
                          navigation(
                            `/navi/object/${auction_id}/detail/${tableData.id}/applicant-list`,
                            {
                              state: { openWay: openWay },
                            },
                          )
                        }
                      />
                      <MediumLightButton
                        text="入札者一覧"
                        onClick={() =>
                          navigation(
                            `/navi/object/${auction_id}/detail/${tableData.id}/bidder-list`,
                            {
                              state: { openWay: openWay },
                            },
                          )
                        }
                      />
                    </div>
                    {tableData.status === 'END_BIDDING' ||
                    tableData.status === 'CANCEL' ||
                    tableData.status === 'CLOSED' ? (
                      <></>
                    ) : (
                      <div className="torihiki-actions-row">
                        <MediumLightButton
                          text="物件の中止"
                          textStyle={{ color: 'red' }}
                        />
                      </div>
                    )}
                    <div className="torihiki-actions-row">
                      <MediumLightButton
                        text="追加説明通知メール送信"
                        textStyle={{ color: 'red' }}
                        disabled={
                          tableData.status === 'END_BIDDING' ||
                          tableData.status === 'CANCEL' ||
                          tableData.status === 'CLOSED'
                            ? 'disable'
                            : ''
                        }
                      />
                      <MediumLightButton
                        text="追加説明通知メール送信履歴"
                        textStyle={{ color: 'red' }}
                        disabled={
                          tableData.status === 'END_BIDDING' ||
                          tableData.status === 'CANCEL' ||
                          tableData.status === 'CLOSED'
                            ? 'disable'
                            : ''
                        }
                      />
                    </div>
                  </div>
                </td>
                <td className="action-buttons" data-testid="body-col">
                  <FlatButton text="プレビュー" icon={<EyeIcon />} />
                  <FlatButton
                    text="編集"
                    icon={<PencilIcon />}
                    isDisabled={
                      tableData.status === 'END_BIDDING' ||
                      tableData.status === 'CANCEL' ||
                      tableData.status === 'CLOSED'
                        ? 'disable'
                        : ''
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PublishedObjectTable;
