// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-detail .bid-history-table {
  text-align: left;
  width: 100%;
  border-spacing: 0;
}

.property-detail .bid-history-table tr:first-child th {
  border-top: 1px solid #e8e6e6;
  border-bottom: 1px solid #f78c23;
}

.property-detail .bid-history-table th {
  color: #f78c23;
  font-weight: 500;
  position: relative;
  vertical-align: top;
  padding: 12px 8px 12px 12px;
  width: auto;
  font-size: .9rem;
}

.property-detail .bid-history-table td {
  position: relative;
  vertical-align: top;
  padding: 12px 8px 12px 12px;
  font-weight: 400;
  width: auto;
  border-bottom: 1px solid #e8e6e6;
  font-size: .9rem;
}

.property-detail .bid-history-table td:not(:first-child):before {
  position: absolute;
  display: block;
  content: "";
  width: 0;
  height: calc(100% - 16px);
  top: 8px;
  left: 0;
  border-right: 1px solid #f78c23;
}

.property-detail .bid-history-table td span {
  display: inline-block;
  white-space: nowrap;
  margin-left: 8px;
}

.property-detail .bid-history-table .warning-text {
  color: #ec0803;
  caret-color: #ec0803;
}

.property-detail .bid-history-table td.text-no-wrap {
  white-space: nowrap;
}

.property-detail .bid-history-table td.text-right {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertyDetail/bid-history.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,2BAA2B;EAC3B,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,2BAA2B;EAC3B,gBAAgB;EAChB,WAAW;EACX,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,QAAQ;EACR,yBAAyB;EACzB,QAAQ;EACR,OAAO;EACP,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".property-detail .bid-history-table {\n  text-align: left;\n  width: 100%;\n  border-spacing: 0;\n}\n\n.property-detail .bid-history-table tr:first-child th {\n  border-top: 1px solid #e8e6e6;\n  border-bottom: 1px solid #f78c23;\n}\n\n.property-detail .bid-history-table th {\n  color: #f78c23;\n  font-weight: 500;\n  position: relative;\n  vertical-align: top;\n  padding: 12px 8px 12px 12px;\n  width: auto;\n  font-size: .9rem;\n}\n\n.property-detail .bid-history-table td {\n  position: relative;\n  vertical-align: top;\n  padding: 12px 8px 12px 12px;\n  font-weight: 400;\n  width: auto;\n  border-bottom: 1px solid #e8e6e6;\n  font-size: .9rem;\n}\n\n.property-detail .bid-history-table td:not(:first-child):before {\n  position: absolute;\n  display: block;\n  content: \"\";\n  width: 0;\n  height: calc(100% - 16px);\n  top: 8px;\n  left: 0;\n  border-right: 1px solid #f78c23;\n}\n\n.property-detail .bid-history-table td span {\n  display: inline-block;\n  white-space: nowrap;\n  margin-left: 8px;\n}\n\n.property-detail .bid-history-table .warning-text {\n  color: #ec0803;\n  caret-color: #ec0803;\n}\n\n.property-detail .bid-history-table td.text-no-wrap {\n  white-space: nowrap;\n}\n\n.property-detail .bid-history-table td.text-right {\n  text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
