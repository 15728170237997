import URL_LINKS from 'interfaces/pages/Dashboard/entities/externalLinks';

import { Message, MessageLink } from './WaitOpenBid';

export const NotAplStatus = ({ data, setIsNoMessage }) => {
  if (data?.status === 'WAIT_START_BID') {
    return (
      <MessageLink
        prevText={'この物件への'}
        href={URL_LINKS.ABOUT_PARTICIPATE_URL}
        content={'参加申し込み'}
        laterText={'は締め切られました。'}
      />
    );
  }

  if (data?.status === 'DURING_BIDDING') {
    return (
      <MessageLink
        prevText={'この物件に入札できるのは、事前に'}
        href={URL_LINKS.ABOUT_PARTICIPATE_URL}
        content={'参加申し込み'}
        laterText={'をした方のみとなります。'}
      />
    );
  }

  if (data?.status === 'WAIT_OPEN_BID') {
    return (
      <Message
        content={
          '現在執行機関による入札結果確認中です。落札者は入札結果の確認完了後に確定されます。しばらくお待ちください。'
        }
      />
    );
  }

  if (data?.status === 'END_BIDDING') {
    if (data?.bidCount === 0) {
      return <Message content={'この物件には入札がありませんでした。'} />;
    }
  }

  if (data?.status === 'ADDITIONAL_DURING_BIDDING') {
    return (
      <MessageLink
        prevText={'この物件は現在'}
        href={URL_LINKS.ABOUT_ADD_BID_URL}
        content={'追加入札'}
        laterText={'中です。'}
      />
    );
  }

  setIsNoMessage(true);
};
