// 構造体の定義

import { AccessPersonalInfo } from './GovernmentUsers/accessPersonalInfo';
import { Department } from './GovernmentUsers/department';
import { Email } from './GovernmentUsers/email';
import { Extension } from './GovernmentUsers/extension';
import { FamilyName } from './GovernmentUsers/familyName';
import { FamilyNameKana } from './GovernmentUsers/familyNameKana';
import { FirstName } from './GovernmentUsers/firstName';
import { FirstNameKana } from './GovernmentUsers/firstNameKana';
import { GovernmentId } from './GovernmentUsers/governmentId';
import { OfficePosition } from './GovernmentUsers/officePosition';
import { ProcedureType } from './GovernmentUsers/procedureType';
import { Tel } from './GovernmentUsers/tel';
import { UserType } from './GovernmentUsers/userType';
import { WantReceiveEmail } from './GovernmentUsers/wantReceiveEmail';
import CustomError from '../../../shared/utils/helper/customError.js';
import { NotUndefined } from '../../../shared/utils/helper/NotUndefined.js';

export class GovernmentUserGet {
  constructor(user) {
    this.accessPersonalInfo = user.accessPersonalInfo;
    this.department = user.department;
    this.email = user.email;
    this.extension = user.extension;
    this.familyName = user.familyName;
    this.familyNameKana = user.familyNameKana;
    this.firstName = user.firstName;
    this.firstNameKana = user.firstNameKana;
    this.governmentId = user.governmentId;
    this.id = user.id;
    this.loginId = user.loginId;
    this.officePosition = user.officePosition;
    this.password = user.password;
    this.procedureType = user.procedureType;
    this.tel = user.tel;
    this.userType = user.userType;
    this.wantReceiveEmail = user.wantReceiveEmail;
    this.createdUser = user.createdUser;
    this.name = user.familyName + " " + user.firstName;
    if (this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class GovernmentUserCreate {
  constructor(user) {
    this.accessPersonalInfo = new AccessPersonalInfo(user.accessPersonalInfo);
    this.department = new Department(user.department);
    this.email = new Email(user.email);
    this.extension = new Extension(user.extension);
    this.familyName = new FamilyName(user.familyName);
    this.familyNameKana = new FamilyNameKana(user.familyNameKana);
    this.firstName = new FirstName(user.firstName);
    this.firstNameKana = new FirstNameKana(user.firstNameKana);
    this.officePosition = new OfficePosition(user.officePosition);
    this.governmentId = new GovernmentId(user.governmentId);
    this.procedureType = new ProcedureType(user.procedureType);
    this.tel = new Tel(user.tel.telNum1+user.tel.telNum2+user.tel.telNum3);
    this.userType = new UserType(user.userType);
    this.wantReceiveEmail = new WantReceiveEmail(user.wantReceiveEmail);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.accessPersonalInfo.isValid &&
      this.department.isValid &&
      this.email.isValid &&
      this.extension.isValid &&
      this.familyName.isValid &&
      this.familyNameKana.isValid &&
      this.firstName.isValid &&
      this.firstNameKana.isValid &&
      this.governmentId.isValid &&
      this.officePosition.isValid &&
      this.procedureType.isValid &&
      this.tel.isValid &&
      this.userType.isValid &&
      this.wantReceiveEmail.isValid
    );
  }
}
