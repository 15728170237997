import { validateRangeLength, notInclude, validateNumber } from "../../../../application/validations.js";

export class ContactTel {
  #contactTel

  constructor(contactTel) {
    this.#contactTel = contactTel;
  }

  get() {
    return this.#contactTel
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.notIncludeSpace,
      this.validateNumber,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get notIncludeSpace() {
    return notInclude(this.#contactTel, [' '])
  }

  // バリデーションルールの適用
  get validateNumber() {
    return validateNumber(this.#contactTel)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#contactTel, 10, 11)
  }
}
