import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import { validateInputsNaviUerRegisterForm } from 'interfaces/components/common/validateInputs/ValidationNaviUerRegiterForm.jsx';
import NaviUserConfirm from 'interfaces/components/User/Create/NaviUserConfirm';
import { NaviUserManagerConfirmModal } from 'interfaces/components/User/Create/NaviUserManagerConfirmModal';
import NaviUserRegisterForm from 'interfaces/components/User/Create/NaviUserRegisterForm';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import { breadcrumbs } from 'interfaces/pages/RegistrationUser/entities/breadcrumbs';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { create } from 'domain/User/services/GovernmentUser';
import { RegistrationUserContext } from '../entities/RegistrationUserContext';
import { ServiceTypes } from '../../../../shared/utils/constants/service';

const NaviUserRegister = () => {
  const [onConfirm, setOnConfirm] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { governmentUser, governmentId, procedureType, loginUser } = useContext(
    RegistrationUserContext,
  );
  const isDetail = governmentUser?.loginId ? true : false;
  const inputs = validateInputsNaviUerRegisterForm(isDetail);


  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    setIsValid(
      Object.entries(inputs.validationMessage).every(
        ([key, validationMessage]) => {
          if (key === 'positionValid' || key === 'extensionValid') {
            return validationMessage === null || validationMessage === 'TRUE';
          }
          return validationMessage === 'TRUE';
        },
      ),
    );
  }, [inputs]);


  const permissionPriority = {
    MANAGER: 3,
    PARENT: 2,
    CHILD: 1,
  };

  const enableToUpdate = () => {
    // 新規登録の場合はundefineではなく空の配列になってしまっている。。
    if (!governmentUser) {
      return true;
    }
    const loginUserPriority = permissionPriority[loginUser.userType];
    const governmentUserPriority = permissionPriority[governmentUser.userType];

    return loginUserPriority > governmentUserPriority || isSameUser();
  };

  const enableToDelete = () => {
    if (!governmentUser) {
      return false;
    }

    const loginUserPriority = permissionPriority[loginUser.userType];
    const governmentUserPriority = permissionPriority[governmentUser.userType];

    return loginUserPriority > governmentUserPriority;
  };

  const isSameUser = () => {
    if (!governmentUser) {
      return false;
    }
    if (governmentUser.loginId === undefined) {
      return false;
    }
    return governmentUser.loginId === loginUser.loginId;
  };

  const isEnableToAccessPersonalInfo = () => {
    return loginUser.accessPersonalInfo === 'AUTHORIZED';
  };

  const initUserType = () => {
    if (governmentUser?.userType) {
      return governmentUser?.userType;
    }
    if (isSameUser()) {
      return loginUser.userType;
    }
    return loginUser.userType == 'MANAGER' ? 'PARENT' : 'CHILD';
  };

  function initWantReceiveEmail() {
    if (governmentUser?.wantReceiveEmail) {
      return governmentUser?.wantReceiveEmail;
    }

    if (
      isDetail &&
      governmentUser?.email == '' &&
      governmentUser?.userType == 'CHILD'
    ) {
      return 'NO_RECEIVE';
    }
    return loginUser.userType == 'MANAGER' ? 'RECEIVE' : 'NO_RECEIVE';
  }

  useEffect(() => {
    inputs.init.setFamilyName(governmentUser?.familyName);
    inputs.init.setFirstName(governmentUser?.firstName);
    inputs.init.setFamilyNameKana(governmentUser?.familyNameKana);
    inputs.init.setFirstNameKana(governmentUser?.firstNameKana);
    inputs.init.setDepartment(governmentUser?.department);
    inputs.init.setOfficePosition(governmentUser?.officePosition);
    inputs.init.setTel({
      telNum1: governmentUser?.tel?.split('-')[0],
      telNum2: governmentUser?.tel?.split('-')[1],
      telNum3: governmentUser?.tel?.split('-')[2],
    });
    inputs.init.setExtension(governmentUser?.extension ?? '');
    inputs.init.setUserType(initUserType());
    inputs.init.setEmail(governmentUser?.email);
    inputs.init.setAccessPersonalInfo(
      governmentUser?.accessPersonalInfo ?? 'NO_AUTHORIZED',
    );
    inputs.init.setWantReceiveEmail(initWantReceiveEmail());
  }, [governmentUser, loginUser]);

  const register = async (e) => {
    e.preventDefault();
    inputs.data.governmentId = governmentId;
    inputs.data.procedureType = procedureType;
    try {
      await create(ServiceTypes.NAVI.string, inputs.data);
    } catch (error) {
      console.error(error);
    }
    navigation(`/navi/user/`);
  };

  const toRegister = () => {
    setOnConfirm(false);
  };

  const update = async (e) => {
    e.preventDefault();

  };

  const toUpdate = () => {
    setOnConfirm(false);
  };

  const navigation = useNavigate();
  const toIndex = () => {
    navigation(`/navi/user/`);
  };

  const toConfirm = (e) => {
    e.preventDefault();
    setOnConfirm(true);
  };
  const fullname = inputs.data.familyName + inputs.data.firstName;

  const onRegister = () => {
    if (loginUser.userType === 'MANAGER') {
      if (inputs.data.userType === 'MANAGER') {
        return enableToShowConfirmModal;
      }
    }
    return isDetail ? update : register;
  };
  const enableToShowConfirmModal = () => {
    return setShowConfirmModal(true);
  };
  const disableToShowConfirmModal = () => {
    return setShowConfirmModal(false);
  };
  const toUserType = () => {
    return governmentUser.userType;
  };

  return (
    <div className={'user'} data-testid="navi-user-register">
      <Breadcrumb breadcrumbs={breadcrumbs(onConfirm, isDetail, fullname)} />
      {showConfirmModal && (
        <NaviUserManagerConfirmModal
          disableToShowConfirmModal={disableToShowConfirmModal}
          register={register}
          toRegister={toRegister}
          showConfirmModal={showConfirmModal}
          toUserType={toUserType()}
        />
      )}
      {onConfirm ? (
        <>
          <PropertyInfoGeneralPageDescription
            title={`ナビユーザー管理：${isDetail ? fullname : '登録内容確認'}`}
            content={
              isDetail
                ? '更新するユーザ情報を確認してください。このまま更新する場合は「更新」ボタンを押し、再度編集する場合は「編集」ボタンを押してください。'
                : '登録内容を確認してください。このまま登録する場合は「登録」ボタンを押し、再編集する場合は「編集」ボタンを押してください。'
            }
            displayRequired={false}
          />
          <NaviUserConfirm
            data={inputs}
            type={isDetail ? 'update' : 'create'}
            toRegister={isDetail ? toUpdate : toRegister}
            register={onRegister()}
          />
        </>
      ) : enableToUpdate() ? (
        <>
          <PropertyInfoGeneralPageDescription
            title={`ナビユーザー管理：${isDetail ? fullname : '新規登録'}`}
            content={
              isDetail
                ? `登録済みナビユーザーの編集を行います。入力が終わったら「確認する」ボタンをクリックしてください。${enableToDelete() ? '\nこのナビユーザーを削除する場合は「削除」ボタンを押すことで削除することができます。' : ''}`
                : '新規ナビユーザーを登録します。入力が終わったら「確認する」ボタンをクリックしてください。'
            }
            displayRequired={true}
          />
          <NaviUserRegisterForm
            inputs={inputs}
            toConfirm={toConfirm}
            toIndex={toIndex}
            loginUserType={loginUser.userType}
            enableToDelete={enableToDelete()}
            isSameUser={isSameUser()}
            isValid={isValid}
            isEnableToAccessPersonalInfo={isEnableToAccessPersonalInfo()}
            isDetail={isDetail}
          />
        </>
      ) : (
        <>
          <PropertyInfoGeneralPageDescription
            title={`ナビユーザー管理：${fullname}`}
            content={'※ご利用の権限では編集することができません'}
            displayRequired={false}
          />
          <NaviUserConfirm data={inputs} type={'index'} toIndex={toIndex} />
        </>
      )}
    </div>
  );
};
export default NaviUserRegister;
