
export class StatusSafetyTerm {
  #statusSafetyTerm

  constructor(statusSafetyTerm) {
    this.#statusSafetyTerm = statusSafetyTerm;
  }

  get() {
    return this.#statusSafetyTerm
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
