// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copyright {
    margin-top: 16px;
    padding-bottom: 16px;
    text-align: center;
}

.navi .copyright {
    display: block;
    text-align: center;
    padding: 8px 0 0;
    margin: 0;
    font-size: 14px;
    line-height: 1.571;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/copyright.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,SAAS;IACT,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".copyright {\n    margin-top: 16px;\n    padding-bottom: 16px;\n    text-align: center;\n}\n\n.navi .copyright {\n    display: block;\n    text-align: center;\n    padding: 8px 0 0;\n    margin: 0;\n    font-size: 14px;\n    line-height: 1.571;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
