import { validateSelected } from "../../../../application/validations.js";

export class DisplayGovernmentType {
  #displayGovernmentType

  constructor(displayGovernmentType) {
    this.#displayGovernmentType = displayGovernmentType;
  }

  get() {
    return this.#displayGovernmentType
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#displayGovernmentType)
  }
}
