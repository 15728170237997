import AccordionTopic from './AccordionTopic.jsx';
import 'interfaces/css/common/accordion.css';

const Accordion = ({ text, handleClick, topics, isOpen, svg, className }) => {
  const isActive = (path) => {
    if (path === '/kmanager') {
      return location.pathname === path;
    }

    return location.pathname.startsWith(path);
  };

  return (
    <>
      <div
        data-testid="kmanager-accordion"
        className={`kmanager-accordion-panel ${isOpen && 'kmanager-accordion-panel-active'} d-flex align-center justify-space-between px-4 ${className}`}
        onClick={handleClick}>
        {text}
        {svg}
      </div>
      {topics
        ? isOpen && (
            <div data-testid="kmanager-accordion-topics">
              {topics.map((topic, index) => (
                <AccordionTopic
                  key={index}
                  text={topic.text}
                  handleClick={topic.handleClick}
                  className={isActive(topic.path) ? 'active-menu-item' : ''}
                />
              ))}
            </div>
          )
        : ''}
    </>
  );
};
export default Accordion;
