import styled from 'styled-components';

const ErrorMessageWrapper = styled.div`
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
  min-width: 1px;
  font-size: 16px;
  margin-bottom: 16px;
`;

const ErrorMessage = ({ content }) => {
  return (
    <ErrorMessageWrapper data-testid="error-message" className="error-message">
      {content.split('\n').map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ))}
    </ErrorMessageWrapper>
  );
};
export default ErrorMessage;
