import GuidelineModalHeadline from './GuidelineModalHeadline.jsx';
import GuidelineModalCautionList from './GuidelineModalCautionList.jsx';

const GuidelineModalHeader = ({ text }) => {
  return (
    <div data-testid="guideline-modal-header">
      <GuidelineModalHeadline text={text}/>
      <div
        className="guideline-modal-text pt-4">「コピーする」ボタンを押すと、サンプル内容がコピーされます。コピーした内容を官公庁ナビ行政機関ページ管理のガイドラインの該当箇所に貼り付け、行政機関名や行政機関様の規則により異なる箇所を修正してご利用ください。
      </div>
      <GuidelineModalCautionList/>
    </div>
  );
};
export default GuidelineModalHeader;