import {
  get as getSchedule /*, getProgressSchedule */,
} from 'domain/Auction/services/Auction';

import { useEffect, useState } from 'react';

import { useAuth } from '../../../../shared/hooks/useAuth';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs';
import { queryParams } from '../entities/queryParam';
import { YearlySchedulesContext } from '../entities/YearlySchedulesContext.js';

//import { get as getMessages } from "../../../../domain/News/services/Message.js";

const YearlySchedulesProvider = ({ type, children }) => {
  const { procedureType, governmentType, governmentId } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  //const [managementMessages, setManagementMessages] = useState([]);
  const [yearlySchedules, setYearlySchedule] = useState([]);
  //const [progressSchedule, setProgressSchedule] = useState({});
  const [progressAuctions, setProgressAuctions] = useState([]);

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getSchedule(type).then((result) => {
        const thisFiscalYear = calcFiscalYear();
        const nextFiscalYear = thisFiscalYear + 1;
        const thisFiscalYearAuctionData = filterByFiscalDate(
          result,
          thisFiscalYear,
        );
        const nextFiscalYearAuctionData = filterByFiscalDate(
          result,
          nextFiscalYear,
        );
        setYearlySchedule({
          thisYear: {
            year: thisFiscalYear,
            auctions:
              thisFiscalYearAuctionData.length > 0
                ? thisFiscalYearAuctionData
                : [],
          },
          nextYear: {
            year: nextFiscalYear,
            auctions:
              nextFiscalYearAuctionData.length > 0
                ? nextFiscalYearAuctionData
                : [],
          },
        });
      });
      //getProgressSchedule(type).then(result => {
      //  setProgressSchedule(result);
      //});
      //getMessages(type).then(result => {
      //  setManagementMessages(result)
      //});
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      getSchedule(type).then((result) => {
        setProgressAuctions(result);
      });
    }, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <YearlySchedulesContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        queryParams,
        progressAuctions,
        yearlySchedules,
      }}>
      {children}
    </YearlySchedulesContext.Provider>
  );
};

const calcFiscalYear = () => {
  // 現在の日付を取得
  const today = new Date();
  // 年を取得
  const year = today.getFullYear();
  // 月を取得
  const month = today.getMonth() + 1;

  // 年度を取得
  const fiscalYear = month >= 4 ? year : year - 1;
  return fiscalYear;
};

const filterByFiscalDate = (dataList, fiscalYear) => {
  return dataList.filter((data) => {
    return isIncludeDate(fiscalYear, data['aplStart']);
  });
};

const isIncludeDate = (year, date) => {
  const firstDateFormat = `${year + 1}-04-01 00:00:00`;
  const pastLimitFormat = `${year}-04-01 00:00:00`;

  const firstDate = new Date(firstDateFormat);
  const pastLimitDate = new Date(pastLimitFormat);
  const targetDate = new Date(date);

  if (targetDate.getTime() === pastLimitDate.getTime()) {
    return true;
  }

  return (
    firstDate.getTime() > targetDate.getTime() &&
    targetDate.getTime() > pastLimitDate.getTime()
  );
};

export default YearlySchedulesProvider;
