
export class UserType {
  #userType

  constructor(userType) {
    this.#userType = userType;
  }

  get() {
    return this.#userType
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
