import { useContext } from 'react';

import List from './List';
import { GovernmentsContext } from '../../../../pages/Governments/entities/GovernmentsContext';
//import { getGovernments } from '../read_storage/governments.js';
//import { useError } from '../hooks/useError.js';

const Governments = () => {
  //const [governments, setGovernments] = useState({});
  //const { setError } = useError();

  //useEffect(() => {
  //  const governmentsData = async () => {
  //    try {
  //      const governments = await getGovernments();
  //      setGovernments(governments);
  //    } catch (err) {
  //      setError(err);
  //    }
  //  };
  //  governmentsData().then();
  //}, []);

  const { governments } = useContext(GovernmentsContext);

  return (
    <div className="kservice_governments">
      <div className="common-side-margin-wrap mx-auto">
        <div className="pt-6 pb-10">
          <div className="page-title mb-4">
            <h1 className="pb-2">実施中行政機関一覧</h1>
            <hr
              role="separator"
              aria-orientation="horizontal"
              className="v-divider theme--light"
            />
          </div>
          <List
            title="インターネット公売"
            data={governments['PUBLIC_SALE'] ?? null}
          />
          <List title="公有財産売却" data={governments['ASSET_SALE'] ?? null} />
        </div>
      </div>
    </div>
  );
};

export default Governments;
