// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.published-object-status .sort-toolbar-wrap {
    padding-top: 20px;
}

.published-object-status .sort-toolbar .sort-toolbar-label {
    margin-block-start: initial;
    margin-block-end: initial;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PublishedObjectStatus/sort-toolbar.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;IAC3B,yBAAyB;AAC7B","sourcesContent":[".published-object-status .sort-toolbar-wrap {\n    padding-top: 20px;\n}\n\n.published-object-status .sort-toolbar .sort-toolbar-label {\n    margin-block-start: initial;\n    margin-block-end: initial;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
