import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as SearchIcon } from '../../../../../assets/svg/search_icon.svg';
import { queryParams } from '../../../../../pages/Dashboard/entities/queryParam.js';

const Wrapper = styled.div`
  height: 40px;
`;

const SearchBar = styled.input`
  height: 100%;
  width: 100%;
  border: solid 1px var(--black);
  border-radius: 4px;
  background-color: var(--white);
  padding: 0 11px;

  &::placeholder {
    font-size: 12px;
    color: var(--secondary-darken2);
  }

  &:focus {
    outline: solid 1px var(--text-base);
  }
`;

const ButtonWrapper = styled.div`
  height: 40px;
  width: 40px;
  margin-left: 8px;
`;

const SearchButton = styled.button`
  height: 40px;
  width: 40px;
  background-color: var(--primary-base);
  box-shadow:
    0 0 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  padding: 12px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`;

const FreeSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [inputValue, setInputValue] = useState('');
  const [searchParams] = useSearchParams();

  const handleClick = () => {
    navigate(
      `/search?searchQuery=${inputValue}&${queryParams.QUERY_PARAM_FREE_SEARCH}`,
    );
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (
      searchParams.has(queryParams.SEARCH_QUERY) &&
      location.pathname === '/search'
    ) {
      setInputValue(searchParams.get(queryParams.SEARCH_QUERY));
    }
  }, []);

  return (
    <Wrapper className="flex justify-space-between" data-testid="free-search">
      <SearchBar
        placeholder="掘り出しモノを探す"
        data-testid="search-bar"
        onChange={handleChange}
        value={inputValue}
      />
      <ButtonWrapper>
        <SearchButton data-testid="search-button" onClick={handleClick}>
          <StyledSearchIcon title="search" />
        </SearchButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default FreeSearch;
