import NewsProvider from './NewsProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { news } from "../entities/componentMapping.js";

const News = ({ type }) => {
  return (
    <NewsProvider type={type}>
      {getComponent(news, type)}
    </NewsProvider>
  );
};

export default News;
