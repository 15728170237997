import Button from 'interfaces/components/common/Button.jsx';
import ButtonList from 'interfaces/components/common/ButtonList.jsx';
import KmanagerTableRowHeaderData from 'interfaces/components/common/Table/KmanagerTableRowHeaderData.jsx';
import { useNavigate } from 'react-router-dom';
//import { putApiData } from "../../api/request.js";
//import { bodyToSnake, createBody } from "../../api/convertData.js";

import 'interfaces/css/common/kmanager-table.css';

const TOP_PATH = '/kmanager';

// TODO: ボタンでCreateとトグル
//const AuctionConfirm = ({ type = 'create', data, cancel, id = "0" }) => {
const AuctionConfirm = ({ type = 'create', data, cancel }) => {
  const navigation = useNavigate();
  //const inputBody = createBody(data)

  const Put = async () => {
    console.log('todo: fix create auction');
    //  let response = await putApiData(`auctions/edit/${id}`, bodyToSnake(inputBody));
    //  if (response === undefined) {
    //    navigation(TOP_PATH)
    //  }
    navigation(TOP_PATH);
  };

  const CANCEL_BUTTON_DATA = {
    text: 'キャンセル',
    clickHandler: cancel,
    className: 'edit',
  };

  const CREATE_BUTTON_DATA = {
    text: '作成',
    clickHandler: Put,
    className: 'primary',
  };

  const EDIT_BUTTON_DATA = {
    text: '編集',
    clickHandler: Put,
    className: 'primary',
  };

  const BUTTONS_DATA = {
    cancel: CANCEL_BUTTON_DATA,
    create: CREATE_BUTTON_DATA,
    edit: EDIT_BUTTON_DATA,
  };

  const TitleText = () => {
    if (type === 'create') {
      return '下記内容で作成します。よろしければ作成ボタンをクリックしてください';
    } else if (type === 'edit') {
      return '下記内容で編集します。よろしければ編集ボタンをクリックしてください';
    }
  };

  return (
    <div
      className="kmanager-col-12 pa-5 box-shadow kmanager-confirm kmanager-auction-operation"
      style={{ maxWidth: '1000px' }}>
      <h2 className="font-color-red">{TitleText()}</h2>
      <div className="kmanager-table-wrap">
        <table
          className="kmanager-table kmanager-table-hover"
          data-testid="kmanager-auction-confirm-table">
          <tbody>
            {data
              .filter((data) => data)
              .map((data, index) => {
                return <KmanagerTableRowHeaderData key={index} data={data} />;
              })}
          </tbody>
        </table>
      </div>
      <ButtonList>
        <div className="d-flex justify-center mt-10">
          <Button
            text={BUTTONS_DATA['cancel'].text}
            clickHandler={BUTTONS_DATA['cancel'].clickHandler}
            className={BUTTONS_DATA['cancel'].className}
          />
          <Button
            text={BUTTONS_DATA[type].text}
            clickHandler={BUTTONS_DATA[type].clickHandler}
            className={BUTTONS_DATA[type].className}
          />
        </div>
      </ButtonList>
    </div>
  );
};
export default AuctionConfirm;
