import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useSnackBar } from './SnackBarProvider';

const SnackBarComponent = () => {
  const { isSnackBarOpen, setIsSnackBarOpen, message } = useSnackBar();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isSnackBarOpen}
      autoHideDuration={5000}>
      <Alert icon={false} severity="info" variant="filled" action={false}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '10px' }}>{message}</div>
          <button
            size="large"
            color="secondary"
            onClick={() => setIsSnackBarOpen(false)}
            value="CLOSE"
            >
            CLOSE
          </button>
        </div>
      </Alert>
    </Snackbar>
  );
};

export default SnackBarComponent;
