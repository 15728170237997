import { useContext } from 'react';

import { GovernmentPaymentContext } from '../../../../pages/GovernmentPayment/entities/GovernmentPaymentContext';

export const systemFeeHeader = ['請求金額(税抜)', '(消費税)', '請求金額合計'];

const GovernmentPaymentResultTable = () => {
  const { properties } = useContext(GovernmentPaymentContext);

  let systemUsageFee = 0;
  let systemUsageTax = 0;
  let systemUsageSum = 0;
  properties.forEach((property) => {
    systemUsageFee += property.bidSystemFee;
    systemUsageTax += property.bidSystemFeeTax;
  });
  systemUsageSum = systemUsageFee + systemUsageTax;

  return (
    <table
      data-testid="kmanager-result-table"
      className="kmanager-table kmanager-table-column kmanager-result-table d-flex">
      <thead className="kmanager-table-thead">
        <tr
          data-testid="kmanager-result-table-tr"
          className="kmanager-table-tr kmanager-result-table-tr d-flex">
          {systemFeeHeader.map((headline, index) => (
            <th
              key={index}
              data-testid="kmanager-result-table-th"
              className="kmanager-table-th d-flex align-center text-center text-no-wrap">
              {headline}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="kmanager-table-tbody">
        <tr
          data-testid="kmanager-result-table-tr"
          className="kmanager-table-tr kmanager-result-table-tr d-flex">
          <td
            data-testid="kmanager-result-table-td"
            className="kmanager-table-td d-flex align-center justify-end text-no-wrap">
            {systemUsageFee.toLocaleString()}円
          </td>
          <td
            data-testid="kmanager-result-table-td"
            className="kmanager-table-td d-flex align-center justify-end text-no-wrap">
            {systemUsageTax.toLocaleString()}円
          </td>
          <td
            data-testid="kmanager-result-table-td"
            className="kmanager-table-td d-flex align-center justify-end text-no-wrap">
            {systemUsageSum.toLocaleString()}円
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default GovernmentPaymentResultTable;
