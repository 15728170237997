import Modal2 from 'interfaces/components/common/Modal/Modal2';
import Button from 'interfaces/components/common/Button/Button';
import 'interfaces/css/common/kmanager-table.css';
import 'interfaces/css/Dashboard/kmanager-auction-table-record.css';
import { CautionMessage } from 'interfaces/components/common/CautionMessage/index.jsx';

const AuctionGovernmentNationalTaxAgencyInvitationModal = ({
  invitation,
  government,
  handleClose,
  isStartApply,
}) => {
  return (
    <Modal2 handleClose={handleClose} closeClickOutside={true}>
      <div className="dashboard kmanager-confirm-modal">
        <h2 className="my-5">参加申込フォーム</h2>
        <div className="kmanager-col-12">
          <div className="kmanager-form">
            <div className="kmanager-row ai-flex-start">
              <div className="kmanager-col-4">
                <span className="form-label">公告名</span>
                <span style={{ color: 'red' }}>*</span>
              </div>
              <div className="kmanager-col kmanager-col-wrap">
                <div className="kmanager-text-field">
                  <div className="kmanager-text-field-input">
                    <div className="kmanager-text-field-input-text">
                      {invitation.name}
                    </div>
                  </div>
                  <div className="kmanager-text-field-count">1/30</div>
                  <CautionMessage
                    cautionMessage={
                      '例）第○回、令和○年度公売など\n全角30文字以内で入力してください。\n公告名は官公庁ナビ上のみに表示され、参加者には見えません。行政機関が管理しやすいような文言にしてください。'
                    }
                  />
                </div>
              </div>
            </div>
            <div className="kmanager-row ai-flex-start">
              <div className="kmanager-col-4">
                <span className="form-label">入札自動終了</span>
                <span style={{ color: 'red' }}>*</span>
              </div>
              <div className="kmanager-col kmanager-col-wrap">
                <div className="d-flex form-list new-style_radio-button-wrap kmanager-radio-button">
                  <div
                    className="new-style_radio-button"
                    data-testid="radio-button">
                    <div className="mr-2 d-inline-flex">
                      <label className="d-flex">
                        <i className="radio-button-blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                          </svg>
                        </i>
                        <input type="radio" name="radio" />
                        <p>行う</p>
                      </label>
                    </div>
                  </div>
                  <div
                    className="new-style_radio-button"
                    data-testid="radio-button">
                    <div className="mr-2 d-inline-flex">
                      <label className="d-flex">
                        <i className="radio-button-blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7Z" />
                          </svg>
                        </i>
                        <input type="radio" name="radio" />
                        <p>行わない</p>
                      </label>
                    </div>
                  </div>
                </div>
                <CautionMessage
                  cautionMessage={
                    '※「行う」を選択した場合、参加申し込み者が0人の時に自動で入札終了処理まで行います'
                  }
                />
                {/* {invitation.bidEndType === 'MANUAL' ? 'いいえ' : 'はい'} */}
              </div>
            </div>
            <div className="kmanager-row ai-flex-start">
              <div className="kmanager-col-4">
                <span className="form-label">保証契約証明書の押印希望</span>
                <span style={{ color: 'red' }}>*</span>
              </div>
              <div className="kmanager-col kmanager-col-wrap">
                <div className="d-flex form-list new-style_radio-button-wrap kmanager-radio-button">
                  <div
                    className="new-style_radio-button"
                    data-testid="radio-button">
                    <div className="mr-2 d-inline-flex">
                      <label className="d-flex">
                        <i className="radio-button-blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                          </svg>
                        </i>
                        <input type="radio" name="radio" />
                        <p>希望する</p>
                      </label>
                    </div>
                  </div>
                  <div
                    className="new-style_radio-button"
                    data-testid="radio-button">
                    <div className="mr-2 d-inline-flex">
                      <label className="d-flex">
                        <i className="radio-button-blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7Z" />
                          </svg>
                        </i>
                        <input type="radio" name="radio" />
                        <p>希望しない</p>
                      </label>
                    </div>
                  </div>
                </div>
                <CautionMessage
                  cautionMessage={
                    '※「希望する」を選択した場合、認証書面（表紙）に押印を行い郵送します。'
                  }
                />
                {/* {invitation.mailCertDocument === 'NO_MAIL' ? 'いいえ' : 'はい'} */}
              </div>
            </div>
            <div className="kmanager-row ai-flex-start">
              <div className="kmanager-col-4">
                <span className="form-label">公売保証金の運用</span>
                <span style={{ color: 'red' }}>*</span>
              </div>
              <div className="kmanager-col kmanager-col-wrap">
                <div className="kmanager-col kmanager-col-checkboxes">
                  <div className="flex form-list">
                    <div className="input-checkbox" data-testid="check-box">
                      <div className="input-checkbox-control flex">
                        <div className="input-checkbox-slot">
                          <div className="selection-controls-input">
                            <span className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24">
                                <path d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" />
                              </svg>
                            </span>
                            <input role="checkbox" type="checkbox" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <label className="property-info-general-form-input-check-label">
                      オン納付
                    </label>
                  </div>
                  <div className="flex form-list">
                    <div className="input-checkbox" data-testid="check-box">
                      <div className="input-checkbox-control flex">
                        <div className="input-checkbox-slot">
                          <div className="selection-controls-input">
                            <span className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24">
                                <path d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" />
                              </svg>
                            </span>
                            <input role="checkbox" type="checkbox" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <label className="property-info-general-form-input-check-label">
                      オフ納付
                    </label>
                  </div>
                  <div className="flex form-list">
                    <div className="input-checkbox" data-testid="check-box">
                      <div className="input-checkbox-control flex">
                        <div className="input-checkbox-slot">
                          <div className="selection-controls-input">
                            <span className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24">
                                <path d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" />
                              </svg>
                            </span>
                            <input role="checkbox" type="checkbox" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <label className="property-info-general-form-input-check-label">
                      保証金なし
                    </label>
                  </div>
                </div>
                <CautionMessage cautionMessage={'※複数選択可能です。\n出品物件のうち、公売保証金の納付方法にオン納付（クレジットカード納付）が1件でも含まれる場合は、官公庁ナビより保証金納付口座を必ずご登録ください。\nまた、「オン納付」の選択のみでは公売の入札は利用できません。'} />
                {/* {invitation.noneDeposit === 'TRUE' ? '1' : '0'}:{invitation.paymentCreditCard === 'TRUE' ? '1' : '0'}:{invitation.paymentBankTransfer === 'TRUE' ? '1' : '0'} */}
              </div>
            </div>
            <div className="kmanager-row ai-flex-start">
              <div className="kmanager-col-4">
                <span className="form-label">申し込み総数の表示（せり売）</span>
                <span style={{ color: 'red' }}>*</span>
              </div>
              <div className="kmanager-col kmanager-col-wrap">
                <div className="d-flex form-list new-style_radio-button-wrap kmanager-radio-button">
                  <div
                    className="new-style_radio-button"
                    data-testid="radio-button">
                    <div className="mr-2 d-inline-flex">
                      <label className="d-flex">
                        <i className="radio-button-blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                          </svg>
                        </i>
                        <input type="radio" name="radio" />
                        <p>表示する</p>
                      </label>
                    </div>
                  </div>
                  <div
                    className="new-style_radio-button"
                    data-testid="radio-button">
                    <div className="mr-2 d-inline-flex">
                      <label className="d-flex">
                        <i className="radio-button-blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7Z" />
                          </svg>
                        </i>
                        <input type="radio" name="radio" />
                        <p>表示しない</p>
                      </label>
                    </div>
                  </div>
                </div>
                {/* {invitation.aplCountAuctionDisplay === 'DISPLAY' ? 'はい' : 'いいえ'} */}
              </div>
            </div>
            <div className="kmanager-row">
              <div className="kmanager-col-4">
                <span className="form-label">申し込み総数の表示（入札）</span>
                <span style={{ color: 'red' }}>*</span>
              </div>
              <div className="kmanager-col kmanager-col-wrap">
                <div className="d-flex form-list new-style_radio-button-wrap kmanager-radio-button">
                  <div
                    className="new-style_radio-button"
                    data-testid="radio-button">
                    <div className="mr-2 d-inline-flex">
                      <label className="d-flex">
                        <i className="radio-button-blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                          </svg>
                        </i>
                        <input type="radio" name="radio" />
                        <p>表示する</p>
                      </label>
                    </div>
                  </div>
                  <div
                    className="new-style_radio-button"
                    data-testid="radio-button">
                    <div className="mr-2 d-inline-flex">
                      <label className="d-flex">
                        <i className="radio-button-blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7Z" />
                          </svg>
                        </i>
                        <input type="radio" name="radio" />
                        <p>表示しない</p>
                      </label>
                    </div>
                  </div>
                </div>
                {/* {invitation.aplCountBidDisplay === 'DISPLAY' ? 'はい' : 'いいえ'} */}
              </div>
            </div>
            <div className="kmanager-row">
              <div className="kmanager-col-4">
                <span className="form-label">入札件数の表示（入札）</span>
                <span style={{ color: 'red' }}>*</span>
              </div>
              <div className="kmanager-col kmanager-col-wrap">
                <div className="d-flex form-list new-style_radio-button-wrap kmanager-radio-button">
                  <div
                    className="new-style_radio-button"
                    data-testid="radio-button">
                    <div className="mr-2 d-inline-flex">
                      <label className="d-flex">
                        <i className="radio-button-blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                          </svg>
                        </i>
                        <input type="radio" name="radio" />
                        <p>表示する</p>
                      </label>
                    </div>
                  </div>
                  <div
                    className="new-style_radio-button"
                    data-testid="radio-button">
                    <div className="mr-2 d-inline-flex">
                      <label className="d-flex">
                        <i className="radio-button-blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7Z" />
                          </svg>
                        </i>
                        <input type="radio" name="radio" />
                        <p>表示しない</p>
                      </label>
                    </div>
                  </div>
                </div>
                {/* {invitation.bidCountDisplay === 'DISPLAY' ? 'はい' : 'いいえ'} */}
              </div>
            </div>
          </div>
        </div>
        <h2 className="mt-10 pt-10 mb-3">【基本情報】</h2>
        <div className="kmanager-col-12">
          <div className="kmanager-form">
            <div className="kmanager-row">
              <div className="kmanager-col-4">
                <span className="form-label">行政機関名</span>
                <span style={{ color: 'red' }}>*</span>
              </div>
              <div className="kmanager-col kmanager-col-wrap">
                <div className="kmanager-text-field">
                  <div className="kmanager-text-field-input">
                    <div className="kmanager-text-field-input-text">
                      {government?.name}
                    </div>
                    <div className="kmanager-text-field-input-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24">
                        <path d="M7,10L12,15L17,10H7Z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="kmanager-row ai-flex-start">
              <div className="kmanager-col-4">
                <span className="form-label">地域</span>
                <span style={{ color: 'red' }}>*</span>
              </div>
              <div className="kmanager-col kmanager-col-wrap">
                <div className="kmanager-text-field">
                  <div className="kmanager-text-field-input">
                    <div className="kmanager-text-field-input-text">
                      {invitation.area}
                    </div>
                  </div>
                  <div className="kmanager-text-field-count">1/30</div>
                  <CautionMessage cautionMessage={'全角30文字以内で入力してください。'} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 className="mt-10 pt-10 mb-3">【落札後画面表示情報】</h2>
        <div className="kmanager-form">
          <div className="kmanager-row">
            落札者の物件詳細ページに掲載される内容です（落札者のみが見られます）。
            <br />
            入力した内容は、官公庁ナビの物件登録画面の落札後の連絡先項目にデフォルト表示されます。
          </div>
        </div>
        <div className="kmanager-col-12">
          <div className="kmanager-form kmanager-form_last">
            <div className="kmanager-row ai-flex-start">
              <div className="kmanager-col-4">
                <span className="form-label">担当部署</span>
              </div>
              <div className="kmanager-col kmanager-col-wrap">
                <div className="kmanager-text-field">
                  <div className="kmanager-text-field-input">
                    <div className="kmanager-text-field-input-text">
                      {invitation.contactOffice}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="kmanager-row ai-flex-start">
              <div className="kmanager-col-4">
                <span className="form-label">メールアドレス</span>
              </div>
              <div className="kmanager-col kmanager-col-wrap">
                <div className="kmanager-text-field">
                  <div className="kmanager-text-field-input">
                    <div className="kmanager-text-field-input-text">
                      {invitation.contactEmail}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="kmanager-row ai-flex-start">
              <div className="kmanager-col-4">
                <span className="form-label">電話番号</span>
              </div>
              <div className="kmanager-col kmanager-col-wrap">
                <div className="kmanager-text-field">
                  <div className="kmanager-text-field-input">
                    <div className="kmanager-text-field-input-text">
                      {invitation.contactTel}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kmanager-col-12">
          <div className="kmanager-form">
            <div className="kmanager-row justify-center">
              <button className="back kmanager-button  d-inline-flex align-center justify-center">
                戻る
              </button>
              <Button
                className="primary kmanager-button  d-inline-flex align-center justify-center"
                text="更新"
                isDisabled={isStartApply}
                onClick={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal2>
  );
};
export default AuctionGovernmentNationalTaxAgencyInvitationModal;
