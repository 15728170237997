export const caution_description1 = [
  {
    id: 1,
    text: '公開に設定した物件が一般参加申し込み開始日に公開されます。下書きに設定した物件は公開されませんのでご注意ください。',
  },
];
export const caution_description2 = [
  {
    id: 1,
    text: '官公庁ナビの物件一覧ページに掲載される物件の順番を設定します。半角数字で入力してください。ただし一般画面ではユーザーの指定した並び順でソートされます。',
  },
];
export const caution_description3 = [
  {
    id: 1,
    text: 'イチ押し設定された物件は、KSI官公庁オークション内の各所で貴庁のイチ押しとしてランダムに掲出されます。',
  },
  {
    id: 2,
    text: 'イチ押し設定できる物件は１行政機関様、１開催につき１物件です。すでに他の物件をイチ押しとして設定していた場合、そちらの設定が解除されます。',
  },
];
export const caution_description4 = [
  {
    id: 1,
    text: '執行機関名称を設定するには「執行機関管理」画面よりあらかじめ執行機関の登録が必要です。',
  },
];
export const caution_description5 = [
  {
    id: 1,
    text: '物件の区分番号を30文字以内で入力してください。物件詳細ページに表示されます。',
  },
];

export const caution_description6 = [
  { id: 1, text: '50文字以内で入力してください。' },
  {
    id: 2,
    text: '半角カタカナを入力すると、自動的に全角カタカナに変換され、タイトルが途中で切れる場合がありますのでご注意ください。',
  },
  { id: 3, text: '記号などの特殊文字や機種依存文字は使用しないでください。' },
];
export const caution_description7 = [{ id: 1, text: '複数選択可能です。' }];
export const caution_description8 = [
  { id: 1, text: '本物件の紹介をしている動画のURLのみ掲載が可能です。' },
];
export const caution_description9 = [
  { id: 1, text: '例）〇〇市役所', marked: false },
  { id: 2, text: 'お問い合わせ先の名称を30文字以内で入力してください。' },
];
export const caution_description10 = [
  { id: 1, text: '例）〇〇課  〇〇係', marked: false },
  { id: 2, text: 'お問い合わせ先の部署名を50文字以内で入力してください。' },
];
export const caution_description11 = [
  { id: 1, text: '例）auction@city.prefecture.jp', marked: false },
];
export const caution_description12 = [
  { id: 1, text: '数字は半角で入力してください。' },
];
export const caution_description13 = [
  { id: 1, text: '例）午前〇時～午後〇時', marked: false },
  { id: 2, text: '電話受付時間を50文字以内で入力してください。' },
];
export const caution_description_char_50 = [
  { id: 1, text: '50文字以内で入力してください。' },
];
export const caution_description15 = [
  {
    id: 1,
    text: '必要を選択した場合、物件への参加申し込み者はクレジットカードを利用しても仮申し込み状態となり、本申し込み状態とするためには貴庁の参加承認が必要となります。',
  },
];
export const caution_description16 = [
  {
    id: 1,
    text: '必要を選択した場合、物件への参加申し込み者はクレジットカードを利用しても仮申し込み状態となり、本申し込み状態とするためには貴庁の参加承認が必要となります。',
  },
];
export const caution_description17 = [
  { id: 1, text: '半角数値で入力します。' },
  {
    id: 2,
    text: '保証金納付手続きで「公売保証金なし」を選択した場合は「0」が自動で入力されます。物件詳細ページには「公売保証金なし」と表示されます。',
  },
  {
    id: 3,
    text: 'クレジットカードを選択した場合は保証金額を0円および1円に設定することができません。2円以上での金額設定をお願いいたします。また設定額の上限は100万円とさせていただきます。',
  },
];
export const caution_description18 = [
  {
    id: 1,
    text: '発行可能を選択した場合、落札者に表示する画面に「適格請求書等の発行をご希望の場合は、執行機関にご連絡ください。」と表示されます。発行不可を選択した場合は何も表示されません。',
  },
];
export const caution_description19 = [
  {
    id: 1,
    text: '通常は官公庁ナビでご案内済みの買受代金納付期限をご入力ください。',
  },
];

export const caution_description20 = [
  { id: 1, text: '本文を15000文字以内で入力してください。' },
  {
    id: 2,
    text: 'Markdown記法での記述ができます。HTMLタグは使えません。',
  },
  {
    id: 3,
    text: '半角カタカナ及び記号などの特殊文字や機種依存文字は使用しないでください。',
  },
];
export const car_caution_description1 = [
  { id: 1, text: '登録事項など証明書の発行日です' },
];

export const car_caution_description3 = [
  {
    id: 1,
    text: '該当する選択肢が表示されていない場合、「その他」を選択すると新規入力できます。',
  },
];
export const car_caution_description4 = [
  { id: 1, text: '半角英数字またはハイフン(-)で入力してください。' },
];

export const car_caution_description7 = [
  {
    id: 1,
    text: '20文字以内で入力してください。',
  },
  {
    id: 2,
    text: '半角カタカナは自動的に全角カタカナに変換されます。',
  },
];
export const car_caution_description8 = [
  { id: 1, text: '走行距離が不明な場合は右チェックボックスをチェック。' },
  { id: 2, text: '数字は半角で入力してください。' },
];
export const caution_description_char_100 = [
  {
    id: 1,
    text: '100文字以内で入力してください。',
  },
];
export const caution_description_char_500 = [
  {
    id: 1,
    text: '500文字以内で入力してください。',
  },
];
export const caution_description_char_30 = [
  { id: 1, text: '30文字以内で入力してください。' },
];
export const caution_description_half_number = [
  { id: 1, text: '数字は半角で入力してください。' },
];
export const caution_description_half_char = [
  { id: 1, text: '半角英数字で入力してください。' },
];
export const address_caution_description = [
  { id: 1, text: '60文字以内で入力してください。' },
];
export const url_caution_description = [{ id: 1, text: '地図URL' }];

export const caution_description_char_2000 = [
  {
    id: 1,
    text: '2000文字以内で入力してください。',
  },
];
export const caution_description_char_400 = [
  {
    id: 1,
    text: '400文字以内で入力してください。',
  },
];
export const estate_caution_description3 = [
  {
    id: 1,
    text: 'Yahoo!地図やGoogleマップのURLをいれてください。',
  },
];
export const caution_description_char_200 = [
  {
    id: 1,
    text: '200文字以内で入力してください。',
  },
];
export const estate_caution_description5 = [
  {
    id: 1,
    text: '小数点第2位まで入力できます。',
  },
];
export const caution_description_char_250 = [
  {
    id: 1,
    text: '250文字以内で入力してください。',
  },
];
export const caution_description_char_1000 = [
  {
    id: 1,
    text: '1000文字以内で入力してください。',
  },
];
export const card_provisional_apply_completion_description1 = (procedure_type) => {
  const base = [
    { id: 1, text: '例）https://example.com', marked: false },
    { id: 2, text: '必要書類提出の案内ページのURLを入力してください。' },
  ]
  if (procedure_type !== "ASSET_SALE") {
    base.push(
      { id: 3, text: '「必要書類提出の案内ページURL」が未登録の場合はサービス画面には「承認作業完了までお待ち下さい」のメッセージが表示されます。' },
    ); 
  }
  return base;
};
export const card_provisional_apply_completion_description2 = (
  governmentType,
) => [
  { id: 1, text: '例）https://example.com', marked: false },
  {
    id: 2,
    text:
      governmentType === 'NATIONAL_TAX_AGENCY'
        ? '共同入札の案内ページのURLを入力してください。'
        : '共同入札の案内ページのURLを入力してください（入札形式の場合のみ表示されます）。',
  },
];
export const bank_provisional_apply_completion_description1 = [
  { id: 1, text: '例）https://example.com', marked: false },
  {
    id: 2,
    text: '銀行振込などによる公売保証金納付手続きの案内ページのURLを入力してください。',
  },
];
export const local_government_management_top_description1 = [
  { id: 1, text: '例）https://example.com', marked: false },
  { id: 2, text: '下見会の案内ページのURLを入力してください。' },
  { id: 3, text: '「表示する」を選択した場合は入力必須です。' },
];
export const local_government_management_top_description2 = [
  { id: 1, text: '例）○○県ホームページ', marked: false },
  {
    id: 2,
    text: '行政機関のホームページの名称を30文字以内で入力してください。',
  },
  { id: 3, text: '「表示する」を選択した場合は入力必須です。' },
];
export const local_government_management_top_description3 = [
  { id: 1, text: '例）https://example.com', marked: false },
  { id: 2, text: '行政機関のホームページのURLを入力してください。' },
  { id: 3, text: '「表示する」を選択した場合は入力必須です。' },
];

export const navi_user_register_description1 = [
  { id: 1, text: '全角カタカナで入力してください。' },
  { id: 2, text: '30文字以内で入力してください。' },
];
export const navi_user_register_description_email_example = [
  { id: 1, text: '例）auction@city.prefecture.jp', marked: false },
  { id: 2, text: '権限を「親」に選択した場合は必須です。' },
];
export const navi_user_register_description_access_personal_info = [
  {
    id: 1,
    text: '「あり」を選択された場合、月曜～金曜の開庁日、朝8時～夜7時までの時間帯で申込者・入札者の個人情報を閲覧可能となります。',
  },
];
export const navi_user_register_description_email_receive = [
  {
    id: 1,
    text: '管理者、親権限のナビユーザーIDは運営からの各種ご案内メール（自動送信含む）の受信が必須となります。子権限のナビユーザーIDはメールアドレスの登録と受信が任意となります。',
  },
];
export const property_info_images_description1 = [
  {
    id: 1,
    text: '物件詳細ページの画像部分に表示するコメントを30文字以内で入力してください。',
  },
];

export const guideline_title = [
  { id: 1, text: 'タイトルを50文字以内で入力してください。' },
  { id: 2, text: '「表示する」を選択した場合は入力必須です。' },
];

export const guideline_body = [
  { id: 1, text: '本文を12000文字以内で入力してください。' },
  { id: 2, text: '「表示する」を選択した場合は入力必須です。' },
  { id: 3, text: 'Markdown記法での記述ができます。HTMLタグは使用できません。' },
];
