import LinkGroup from "./FooterSp/LinkGroup.jsx";
import SingleOpenAccordion from "../../../common/Accordion/SingleOpenAccordion.jsx";
import '../../../../css/common/footer-sp.css'

const FooterSp = () => {
  const realEstate = [
    {
      id: 1,
      title: "マンション",
      link: "",
      onClick: () => {}
    },
    {
      id: 2,
      title: "土地",
      link: "",
      onClick: () => {}
    },
    {
      id: 3,
      title: "一戸建て",
      link: "",
      onClick: () => {}
    },
    {
      id: 4,
      title: "その他",
      link: "",
      onClick: () => {}
    }
  ];
  const car = [
    {
      id: 1,
      title: "救急車",
      link: "",
      onClick: () => {}
    },
    {
      id: 2,
      title: "消防車",
      link: "",
      onClick: () => {}
    },
    {
      id: 3,
      title: "バス",
      link: "",
      onClick: () => {}
    },
    {
      id: 4,
      title: "その他",
      link: "",
      onClick: () => {}
    }
  ];
  const popularCategory = [
    {
      id: 1,
      title: "コンピュータ",
      link: "",
      onClick: () => {}
    },
    {
      id: 2,
      title: "家電、AV、カメラ",
      link: "",
      onClick: () => {}
    },
    {
      id: 3,
      title: "音楽",
      link: "",
      onClick: () => {}
    },
    {
      id: 4,
      title: "本、雑誌",
      link: "",
      onClick: () => {}
    },
    {
      id: 5,
      title: "映画、ビデオ",
      link: "",
      onClick: () => {}
    }
  ];
  const help = [
    {
      id: 1,
      title: "初めての方へ",
      link: "",
      onClick: () => {}
    },
    {
      id: 2,
      title: "ヘルプ",
      link: "",
      onClick: () => {}
    },
    {
      id: 3,
      title: "よくある質問",
      link: "",
      onClick: () => {}
    }
  ];
  const profileAndTerms = [
    {
      id: 1,
      title: "運営会社について",
      link: "",
      onClick: () => {}
    },
    {
      id: 2,
      title: "利用規約",
      link: "",
      onClick: () => {}
    },
    {
      id: 3,
      title: "プライバシーポリシー",
      link: "",
      onClick: () => {}
    },
    {
      id: 4,
      title: "特定商取引法の表示",
      link: "",
      onClick: () => {}
    }
  ];
  const items = [
    {
      id: 1,
      title: <p className="footer-accordion-label">不動産を探す</p>,
      body: <LinkGroup items={realEstate}/>,
    },
    {
      id: 2,
      title: <p className="footer-accordion-label">自動車を探す</p>,
      body: <LinkGroup items={car}/>
    },
    {
      id: 3,
      title: <p className="footer-accordion-label">人気のカテゴリ</p>,
      body: <LinkGroup items={popularCategory}/>
    },
    {
      id: 4,
      title: <p className="footer-accordion-label">ヘルプ関連</p>,
      body: <LinkGroup items={help}/>
    },
    {
      id: 5,
      title: <p className="footer-accordion-label">会社概要・規約</p>,
      body: <LinkGroup items={profileAndTerms}/>
    }
  ];

  return (
    <div data-testid="footer-sp" className="footer-sp">
      <SingleOpenAccordion items={items} className="footer-single-open-accordion"/>
      <div data-testid="copy-right" className="text-caption text-center py-4">
        &#169; 2023 - KSI官公庁オークション
      </div>
    </div>
  );
}

export default FooterSp;