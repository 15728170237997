// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kmanager-form {
  margin: 10px;
  padding: 20px;
  border-color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
}

.form-area {
  position: relative;
}

.kmanager-form .form-area input, .kmanager-form .form-area select {
  width: 100%;
  padding: 15px 0;
}

.kmanager-form .form-area > .form-input:after {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  content: "";
  transition: .3s cubic-bezier(.25, .8, .5, 1);
  border-bottom: 1px solid rgba(0, 0, 0, .42);
}

.kmanager-form input[type=radio] {
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/common-form.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,4GAA4G;AAC9G;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,OAAO;EACP,WAAW;EACX,WAAW;EACX,4CAA4C;EAC5C,2CAA2C;AAC7C;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".kmanager-form {\n  margin: 10px;\n  padding: 20px;\n  border-color: #fff;\n  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);\n}\n\n.form-area {\n  position: relative;\n}\n\n.kmanager-form .form-area input, .kmanager-form .form-area select {\n  width: 100%;\n  padding: 15px 0;\n}\n\n.kmanager-form .form-area > .form-input:after {\n  position: absolute;\n  bottom: -1px;\n  left: 0;\n  width: 100%;\n  content: \"\";\n  transition: .3s cubic-bezier(.25, .8, .5, 1);\n  border-bottom: 1px solid rgba(0, 0, 0, .42);\n}\n\n.kmanager-form input[type=radio] {\n  opacity: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
