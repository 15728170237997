import { useEffect, useState } from 'react';
import { calc_dead_line } from 'shared/utils/helper/calc_dead_line';

import { isFalsy } from '../helper/isFalsy';

export const useCalcDeadline = (data) => {
  const [deadline, setDeadline] = useState('');

  useEffect(() => {
    if (isFalsy(data.deadline)) {
      return setDeadline('-');
    }

    const deadline = calc_dead_line(data.deadline);
    if (deadline === '') {
      return setDeadline('-');
    }

    setDeadline(deadline);
    const intervalId = setInterval(() => {
      setDeadline(calc_dead_line(data.deadline));
    }, 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [data.deadline]);

  return { deadline };
};
