import SingleOpenAccordion from 'interfaces/components/common/Accordion/SingleOpenAccordion.jsx';
import RadioInput from 'interfaces/components/common/Input/RadioInput.jsx';
import { memo } from 'react';

import ClientAddressTable from './ClientAddressTable.jsx';
import ReceiverAddressForm from './ReceiverAddressForm.jsx';

const addressTypes = [
  { id: 1, label: '依頼人と同じ', value: 'CLIENT' },
  { id: 2, label: 'その他の住所へ送る', value: 'OTHER' },
];

const SelectAddressTypeInput = memo(function SelectPackingMethodInput({
  address,
  setAddress,
}) {
  const handleClick = (item) => {
    setAddress({ ...address, target: item.value });
  };

  const items = [
    {
      id: 1,
      title: (
        <RadioInput
          item={addressTypes[0]}
          label={addressTypes[0].label}
          value={address.target}
          handleClick={() => handleClick(addressTypes[0])}
          className="accordion-label-radio"
        />
      ),
      body: <ClientAddressTable setAddress={setAddress} />,
    },
    {
      id: 2,
      title: (
        <RadioInput
          item={addressTypes[1]}
          label={addressTypes[1].label}
          value={address.target}
          handleClick={() => handleClick(addressTypes[1])}
          className="accordion-label-radio"
        />
      ),
      body: <ReceiverAddressForm address={address} setAddress={setAddress} />,
    },
  ];

  return (
    <div>
      <SingleOpenAccordion
        items={items}
        className="shipping-single-open-accordion"
      />
      <div className="topic-input-caption" data-testid="topic-input-caption">
        <p>【留意事項】</p>
        <p>
          1.
          お届け先の受取人名が依頼人または委任者と相違する場合は、公売財産の引渡しは行なえません。
        </p>
      </div>
    </div>
  );
});

export default SelectAddressTypeInput;
