import LoginProvider from './LoginProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { login } from "../entities/componentMapping.js";

const Login = ({ type }) => {
  return (
    <LoginProvider type={type}>
      {getComponent(login, type)}
    </LoginProvider>
  );
};

export default Login;
