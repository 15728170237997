import { GovernmentForm } from 'interfaces/components/common/GovernmentForm';
import { useGovernmentInputs } from 'shared/hooks/useGovernmentInputs';
import {
  get_radio_label,
  gov_procedure_types,
  gov_procedure_types_for_national_tax_agency,
  government_types,
} from 'shared/utils/helper/radio_labels.js';
import {
  get_locations,
  get_location_label,
  get_region_label,
  government_regions,
} from 'shared/utils/helper/select_box.js';

export const validateInputsGovernmentCreate = (government) => {
  const formInputs = useGovernmentInputs(government);
  const validate = GovernmentForm();

  function handleName(e) {
    validate.setters.name(e);
    formInputs.setters.name(e.target.value);
  }

  function handleNameKana(e) {
    validate.setters.nameKana(e);
    formInputs.setters.nameKana(e.target.value);
  }

  function handleGovernmentType(e) {
    const governmentType = e.target.value;

    // 国税の場合は、公売のみ選択可能なので国税選択時には手続き種別も合わせて変更する
    if (governmentType === 'NATIONAL_TAX_AGENCY') {
      formInputs.setters.procedureType('PUBLIC_SALE');
    }
    formInputs.setters.governmentType(governmentType);
  }

  function handleProcedureType(e) {
    formInputs.setters.procedureType(e.target.value);
  }

  function handleLocation(e) {
    validate.setters.location(e);
    formInputs.setters.location(e.target.value);
  }

  function handleRegion(e) {
    console.log(e);
    validate.setters.region(e);
    formInputs.setters.region(e.target.value);

    // 地域を変更した場合は、government_sub_categoriesのcategory_idが一致する配列の一番初めの値をセットする
    const locations = get_locations(e.target.value);
    validate.setters.location({ target: { value: locations[0].value } });
    formInputs.setters.location(locations[0].value);
  }

  const validateInputs = [
    {
      type: formInputs.type.name,
      column: 'name',
      text: '行政機関名',
      validationMessage: validate.validationMessage.name,
      state: formInputs.data.name,
      init: formInputs.setters.name,
      setters: handleName,
      required: true,
    },
    {
      type: formInputs.type.nameKana,
      column: 'nameKana',
      text: '行政機関名（カナ）',
      validationMessage: validate.validationMessage.nameKana,
      state: formInputs.data.nameKana,
      init: formInputs.setters.nameKana,
      setters: handleNameKana,
      required: true,
    },
    {
      type: formInputs.type.governmentType,
      column: 'governmentType',
      text: '行政機関種別',
      list: government_types,
      validationMessage: validate.validationMessage.governmentType,
      state: formInputs.data.governmentType,
      init: (val) => formInputs.setters.governmentType(val),
      setters: handleGovernmentType,
      hasLabel: get_radio_label(
        government_types,
        'value',
        formInputs.data.governmentType,
      ),
      required: true,
    },
    {
      type: formInputs.type.procedureType,
      column: 'procedureType',
      text: '手続き種別',
      list:
        formInputs.data.governmentType === 'NATIONAL_TAX_AGENCY'
          ? gov_procedure_types_for_national_tax_agency
          : gov_procedure_types,
      validationMessage: validate.validationMessage.procedureType,
      state: formInputs.data.procedureType,
      init: formInputs.setters.procedureType,
      setters: handleProcedureType,
      hasLabel: get_radio_label(
        gov_procedure_types,
        'value',
        formInputs.data.procedureType,
      ),
      required: true,
    },
    {
      type: formInputs.type.region,
      column: 'region',
      text: '地域',
      list: government_regions,
      validationMessage: validate.validationMessage.region,
      state: formInputs.data.region,
      init: formInputs.setters.region,
      setters: handleRegion,
      hasLabel: get_region_label(formInputs.data.region).label,
      required: true,
    },
    {
      type: formInputs.type.location,
      column: 'location',
      text: '都道府県',
      list: get_locations(formInputs.data.region),
      validationMessage: validate.validationMessage.location,
      state: formInputs.data.location,
      init: formInputs.setters.location,
      setters: handleLocation,
      hasLabel: get_location_label(formInputs.data.location).label,
      required: true,
    },
  ];

  return validateInputs;
};
