
export class MainText {
    #mainText

    constructor(mainText) {
        this.#mainText = mainText;
    }

    get() {
        return this.#mainText
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
