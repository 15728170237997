import { validateSomeSelected } from "../../../../application/validations.js";

export class DepositPayment {
  #depositPayment

  constructor(depositPayment) {
    this.#depositPayment = depositPayment;
  }

  get() {
    return this.#depositPayment
  }

  get isValid() {
    return [
      this.validateSomeSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSomeSelected() {
    return validateSomeSelected(this.#depositPayment)
  }
}
