import Modal2 from "interfaces/components/common/Modal/Modal2";
import {useEffect} from "react";

import ServiceProvidersForm from "./ServiceProvidersForm";
import { validateInputsServiceProvider } from "./validateInputsServiceProvider";

const ServiceProvidersFormModal = ({ newId, isOpen, closeModal, data = {} }) => {
  const createInputs = validateInputsServiceProvider()
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      // 編集時、データの挿入
      Object.keys(data).map((key) => {
        const target = createInputs.find((input) => input.column === key)
        if (target?.init) {
          target.init(data[key])
        }
      })
    }
  }, []);

  return (
    <Modal2 isOpen={isOpen} handleClose={closeModal}>
      <div className="kmanager-container" data-testid="kmanager-user-create-modal">
        <ServiceProvidersForm newId={newId} inputs={createInputs} closeModal={closeModal}/>
      </div>
    </Modal2>
  )
}

export default ServiceProvidersFormModal;
