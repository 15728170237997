
export class BankName {
    #bankName

    constructor(bankName) {
        this.#bankName = bankName;
    }

    get() {
        return this.#bankName
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
