import '../../../../interfaces/css/common/small-topic-title.css'
import {Link} from "react-router-dom";

const SmallTopicTitle = ({ text, isFontBold = false, link, isAboutBlank = false, caption }) => {
  return (
    <div
      className={`small-topic-title-wrap ${ isFontBold ? 'font-weight-bold' : '' }`}
      data-testid="small-topic-title-wrap"
    >
      {
        link
          ? <div className="small-topic-title" data-testid="small-topic-title">
              {
                isAboutBlank
                  ? <a href={ link } target="_blank" rel="noopener noreferrer" className="small-topic-link">
                      {text}
                    </a>
                  : <Link to={ link } className="small-topic-link">
                      {text}
                    </Link>
              }
              <div className="small-topic-caption">{ caption }</div>
            </div>
          : <div className="small-topic-title" data-testid="small-topic-title">
              {text}
              <div className="small-topic-caption">{ caption }</div>
            </div>
      }
    </div>
  );
};

export default SmallTopicTitle;
