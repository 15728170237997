// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personal-information-caption {
  font-size: .8rem;
  border-top: solid 1px rgba(0,0,0,.12);
  margin-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/personal-information-caption.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,qCAAqC;EACrC,eAAe;AACjB","sourcesContent":[".personal-information-caption {\n  font-size: .8rem;\n  border-top: solid 1px rgba(0,0,0,.12);\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
