
export class PropertyId {
  #propertyId

  constructor(propertyId) {
    this.#propertyId = propertyId;
  }

  get() {
    return this.#propertyId
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
