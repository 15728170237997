import SampleGuidelineProvider from './SampleGuidelineProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { sampleGuideline } from "../entities/componentMapping.js";

const SampleGuideline = ({ type }) => {
  return (
    <SampleGuidelineProvider type={type}>
      {getComponent(sampleGuideline, type)}
    </SampleGuidelineProvider>
  );
};

export default SampleGuideline;
