import KmanagerAuctions from "interfaces/components/Auction/kmanager/KmanagerAuctions";

const KmanagerDashboard = () => {
    return (
        <div className='dashboard' data-testid="kmanager-top-page">
            <KmanagerAuctions/>
        </div>
    );
};

export default KmanagerDashboard;
