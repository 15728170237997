import { calcXCoordinate } from 'shared/utils/helper/calcXCoordinate.js';
import handleDataEmpty from 'shared/utils/helper/handleDataEmpty.js';

import NearlyScheduleTerm from './NearlyScheduleTerm.jsx';
import 'interfaces/css/common/neary-schedule-row.css';

const NearlyScheduleRow = ({ termData, magnification, title }) => {
  const aplStart = new Date(termData.aplStart);
  const aplEnd = new Date(termData.aplEnd);
  const bidStart = new Date(termData.bidStart);
  const bidEnd = new Date(termData.bidEnd);
  const auctionEnd = new Date(termData.auctionEnd);

  const aplTermStyle = {
    width: (calcXCoordinate(aplEnd) - calcXCoordinate(aplStart)) * magnification,
    left: calcXCoordinate(aplStart) * magnification
  };
  const waitStartBidStyle = {
    width: (calcXCoordinate(bidStart) - calcXCoordinate(aplEnd)) * magnification,
    left: calcXCoordinate(aplEnd) * magnification
  };
  const bidBidTermStyle = {
    width: (calcXCoordinate(bidEnd) - calcXCoordinate(bidStart)) * magnification,
    left: calcXCoordinate(bidStart) * magnification
  };
  const auctionBidTermStyle = {
    width: (calcXCoordinate(auctionEnd) - calcXCoordinate(bidStart)) * magnification,
    left: calcXCoordinate(bidStart) * magnification
  };

  return (
    <div data-testid="nearly-schedule-row">
      <div className="nearly-term-row" data-testid="nearly-term-row">
        <NearlyScheduleTerm
          style={aplTermStyle}
          termLabel="参加申し込み受付期間"
          startLabel="開始"
          endLabel="終了"
          startDate={termData.aplStart}
          endDate={termData.aplEnd}
          title={title}
          subTitle="参加申し込み受付期間"
        />
      </div>
      <div className="nearly-term-row" data-testid="nearly-term-row">
        <div className="wait-start-bid-term" data-testid="wait-start-bid-term" style={waitStartBidStyle}>入札待ち</div>
      </div>
      {
        handleDataEmpty(termData.auctionEnd) && <div className="nearly-term-row" data-testid="nearly-term-row">
          <NearlyScheduleTerm
            style={auctionBidTermStyle}
            termLabel="入札期間"
            startLabel="せり売開始"
            endLabel="締切"
            startDate={termData.bidStart}
            endDate={termData.auctionEnd}
            title={title}
          />
        </div>
      }
      {
        handleDataEmpty(termData.bidEnd) && <div className="nearly-term-row" data-testid="nearly-term-row">
          <NearlyScheduleTerm
            style={bidBidTermStyle}
            termLabel="入札期間"
            startLabel="入札開始"
            endLabel="締切"
            startDate={termData.bidStart}
            endDate={termData.bidEnd}
            title={title}
          />
        </div>
      }
    </div>
  );
};

export default NearlyScheduleRow;
