// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.governments .page-title {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: .1em;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 8px;
}

.governments .v-divider {
  display: block;
  flex: 1 1;
  max-width: 100%;
  height: 0;
  max-height: 0;
  border: solid;
  border-width: thin 0 0;
  transition: inherit;
}

.governments .theme--light.v-divider {
  border-color: rgba(0,0,0,.12);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Governments/governments.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;EACpB,mBAAmB;EACnB,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,SAAW;EACX,eAAe;EACf,SAAS;EACT,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".governments .page-title {\n  font-size: 1.5rem;\n  font-weight: 700;\n  letter-spacing: .1em;\n  white-space: nowrap;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding-bottom: 8px;\n}\n\n.governments .v-divider {\n  display: block;\n  flex: 1 1 0;\n  max-width: 100%;\n  height: 0;\n  max-height: 0;\n  border: solid;\n  border-width: thin 0 0;\n  transition: inherit;\n}\n\n.governments .theme--light.v-divider {\n  border-color: rgba(0,0,0,.12);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
