import BidCountBody from './ImageDetailPropertyCardBodyBottom/BidCountBody';
import Deadline from './ImageDetailPropertyCardBodyBottom/Deadline';
import DepositBody from './ImageDetailPropertyCardBodyBottom/DepositBody';
import DuringBiddingCardBodyBottom from './ImageDetailPropertyCardBodyBottom/DuringBiddingCardBodyBottom';

const ImageDetailPropertyCardBodyBottom = ({ data }) => {
  const status = data.status;
  const isAuction = data.publicSaleType === 'AUCTION';
  const isBidCountDisplay = data.bidCountDisplay === 'DISPLAY';

  switch (status) {
    case 'APPLYING':
      return <DepositBody data={data} />;
    case 'WAIT_START_BID':
      return <DepositBody data={data} />;
    case 'WAIT_OPEN_BID':
      if (isAuction) {
        return <BidCountBody data={data} />;
      } else {
        return;
      }
    case 'DURING_BIDDING':
      return <DuringBiddingCardBodyBottom data={data} />;
    case 'ADDITIONAL_DURING_BIDDING':
      return <Deadline data={data} />;
    case 'END_BIDDING':
      if (!isAuction && !isBidCountDisplay) {
        return;
      } else {
        return <BidCountBody data={data} />;
      }
    default:
      return;
  }
};

export default ImageDetailPropertyCardBodyBottom;
