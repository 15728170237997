// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-info-car .property-info-car-form-vehicle-Identification-number {
  display: flex;
  align-items: center;
}

.property-info-car .property-info-car-form-vehicle-Identification-number-checkbox {
  display: flex;
  align-items: center;
  padding-top: 16px;
}

.property-info-car .property-info-car-form-vehicle-Identification-number-checkbox .input-checkbox-slot {
  margin: 0;
}

.property-info-car .property-info-car-form-vehicle-Identification-number-checkbox .input-checkbox {
  padding: 0;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertyInfoCar/property-info-car-form-vehicle-Identification-number.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,UAAU;EACV,SAAS;AACX","sourcesContent":[".property-info-car .property-info-car-form-vehicle-Identification-number {\n  display: flex;\n  align-items: center;\n}\n\n.property-info-car .property-info-car-form-vehicle-Identification-number-checkbox {\n  display: flex;\n  align-items: center;\n  padding-top: 16px;\n}\n\n.property-info-car .property-info-car-form-vehicle-Identification-number-checkbox .input-checkbox-slot {\n  margin: 0;\n}\n\n.property-info-car .property-info-car-form-vehicle-Identification-number-checkbox .input-checkbox {\n  padding: 0;\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
