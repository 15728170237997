import Heading3 from 'interfaces/components/common/Heading/Heading3';
import {
  after_winning_bid_caution_description_13,
  after_winning_bid_caution_description_14,
} from 'shared/utils/helper/DummyAfterWinningBid';
import {
  caution_description11,
  caution_description12,
} from 'shared/utils/helper/DummyEditFormData';
import { local_government_management_radio1 } from 'shared/utils/helper/radio_labels';

import FormRadioWithCaution from '../Form/navi/FormRadioWithCaution';
import FormTelWithCaution from '../Form/navi/FormTelWithCaution';
import FormTextWithCaution from '../Form/navi/FormTextWithCaution';

const AfterWinningBidCautionContactFrom = ({ validations }) => {
  return (
    <div className="pt-9">
      <Heading3 text="3. お問い合わせ先" />
      <div className="property-info-general-form-table">
        <FormRadioWithCaution
          label={'お問い合わせ先の表示'}
          radio_label={local_government_management_radio1}
          data={validations.data.auctionInquiryDisplay}
          handler1={(e) => {
            validations.setters.handleAuctionInquiryDisplay(e);
            validations.setters.handleAuctionInquiryName(
              { target: { value: validations.data.auctionInquiryName } },
              e.target.value === 'DISPLAY',
            );
          }}
        />
        <FormTextWithCaution
          label={'お問い合わせ先'}
          maxCharacters={50}
          required={validations.data.auctionInquiryDisplay === 'DISPLAY'}
          caution_description={after_winning_bid_caution_description_13}
          data={validations.data.auctionInquiryName}
          handler1={(e) =>
            validations.setters.handleAuctionInquiryName(
              e,
              validations.data.auctionInquiryDisplay === 'DISPLAY',
            )
          }
          invalidMessage={validations.validationMessage.auctionInquiryNameValid}
        />
        <FormTextWithCaution
          label={'メールアドレス'}
          caution_description={caution_description11}
          data={validations.data.auctionInquiryEmail}
          handler1={validations.setters.handleAuctionInquiryEmail}
          invalidMessage={
            validations.validationMessage.auctionInquiryEmailValid
          }
        />
        <FormTelWithCaution
          label={'電話番号'}
          telData={validations.data.auctionInquiryTel}
          hasExtension={true}
          setTelData={(tel) => validations.setters.handleAuctionInquiryTel(tel)}
          setTel1Data={(tel) =>
            validations.setters.handleAuctionInquiryTelNum1(tel)
          }
          setTel2Data={(tel) =>
            validations.setters.handleAuctionInquiryTelNum2(tel)
          }
          setTel3Data={(tel) =>
            validations.setters.handleAuctionInquiryTelNum3(tel)
          }
          extensionData={validations.data.auctionInquiryExtension}
          setExtensionData={validations.setters.handleAuctionInquiryExtension}
          caution_description={caution_description12}
          invalidMessage={validations.validationMessage.auctionInquiryTelValid}
          invalid1Message={
            validations.validationMessage.auctionInquiryTelNum1Valid
          }
          invalid2Message={
            validations.validationMessage.auctionInquiryTelNum2Valid
          }
          invalid3Message={
            validations.validationMessage.auctionInquiryTelNum3Valid
          }
          extensionMessage={
            validations.validationMessage.auctionInquiryExtensionValid
          }
          telInvalidMessage={
            validations.validationMessage.auctionInquiryTelValid
          }
        />
        <FormTextWithCaution
          label={'電話受付時間'}
          maxCharacters={50}
          caution_description={after_winning_bid_caution_description_14}
          data={validations.data.auctionInquiryAccept}
          handler1={validations.setters.handleAuctionInquiryAccept}
          invalidMessage={
            validations.validationMessage.auctionInquiryAcceptValid
          }
        />
      </div>
    </div>
  );
};

export default AfterWinningBidCautionContactFrom;
