import {
  DATA_EMPTY,
  DISPLAY,
} from 'domain/Property/entities/constants/property';

export const required_costs = (data) => {
  return [
    {
      id: 1,
      title: '動産',
      value:
        data?.costPropertyDisplay === DISPLAY
          ? data?.costPropertyDescription
          : DATA_EMPTY,
    },
    {
      id: 2,
      title: '自動車',
      value:
        data?.costCarDisplay === DISPLAY
          ? data?.costCarDescription
          : DATA_EMPTY,
    },
    {
      id: 3,
      title: '不動産',
      value:
        data?.costEstateDisplay === DISPLAY
          ? data?.costEstateDescription
          : DATA_EMPTY,
    },
  ];
};
