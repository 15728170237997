
export class CurrentTopPrice {
    #currentTopPrice

    constructor(currentTopPrice) {
        this.#currentTopPrice = currentTopPrice;
    }

    get() {
        return this.#currentTopPrice
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
