// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mylist .mylist-headline {
  border-radius: 0;
  margin-bottom: 16px;
  border-color: #fff;
}

.mylist .mylist-headline-name {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: .1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 8px;
}

.mylist .mylist-line {
  display: block;
  flex: 1 1;
  max-width: 100%;
  height: 0;
  max-height: 0;
  transition: inherit;
  border: 0 solid rgba(0, 0, 0, .12);
  border-top-width: thin;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Mylist/mylist-headline.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;EAGpB,aAAa;EAGb,2BAA2B;EAG3B,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EAGd,SAAW;EACX,eAAe;EACf,SAAS;EACT,aAAa;EAEb,mBAAmB;EACnB,kCAAkC;EAClC,sBAAsB;AACxB","sourcesContent":[".mylist .mylist-headline {\n  border-radius: 0;\n  margin-bottom: 16px;\n  border-color: #fff;\n}\n\n.mylist .mylist-headline-name {\n  font-size: 1.5rem;\n  font-weight: 700;\n  letter-spacing: .1em;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: start;\n  -ms-flex-pack: start;\n  justify-content: flex-start;\n  -webkit-box-align: center;\n  -ms-flex-align: center;\n  align-items: center;\n  white-space: nowrap;\n  padding-bottom: 8px;\n}\n\n.mylist .mylist-line {\n  display: block;\n  -webkit-box-flex: 1;\n  -ms-flex: 1 1 0;\n  flex: 1 1 0;\n  max-width: 100%;\n  height: 0;\n  max-height: 0;\n  -webkit-transition: inherit;\n  transition: inherit;\n  border: 0 solid rgba(0, 0, 0, .12);\n  border-top-width: thin;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
