import {
  sortCarItems,
  sortItems,
} from 'domain/Property/entities/constants/popupInput';

import Pagination from 'interfaces/components/common/Pagination/Pagination';
import { useEffect, useState } from 'react';
import usePagination from 'shared/hooks/usePagination';

import DisplayToggle from './DisplayToggle';
import PopUpInputGroup from './PopUpInputGroup';
import SearchPropertyCount from './SearchPropertyCount';
import GeneralDetailPropertyCard from '../PropertyCard/GeneralDetailPropertyCard/GeneralDetailPropertyCard';
import ImageDetailPropertyCard from '../PropertyCard/ImageDetailPropertyCard/ImageDetailPropertyCard';

import 'interfaces/css/PropertyList/property-list.css';

const PropertyList = ({
  category,
  dataList,
  isImageList,
  setIsImageList,
  searchParams,
}) => {
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const initialSortItem = category === 'CAR' ? sortCarItems[1] : sortItems[1];
  const [sortType, setSortType] = useState(initialSortItem);
  const [displayDataList, setDisplayDataList] = useState(dataList);
  //const setFunction = {
  //  setDisplayDataList,
  //  setItemsPerPage,
  //  setSortType,
  //};
  //useQueryParamChangeProcessing(dataList, setFunction);

  // 検索結果が更新されたら再描画
  useEffect(() => {
    setDisplayDataList(dataList);
  }, [dataList]);

  const {
    allItemCount,
    currentPage,
    currentItems,
    pageNumbers,
    setCurrentPage,
  } = usePagination(displayDataList, itemsPerPage);

  useEffect(() => setCurrentPage(1), [searchParams]);

  const PropertyListBottom = () => {
    if (!currentItems?.length) {
      return (
        <div className="flex justify-center text-center">
          ご指定の条件に一致する物件は見つかりませんでした。
          <br />
          別の検索条件やキーワードをお試しください。
        </div>
      );
    }
    return (
      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
      />
    );
  };

  return (
    <div className="property-list mb-5" data-testid="property-list">
      <div className="mb-6">
        {!currentItems?.length ? null : (
          <SearchPropertyCount
            itemCount={allItemCount}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
          />
        )}
        <div className="flex align-center flex-wrap-wrap">
          <PopUpInputGroup
            category={category}
            sortType={sortType}
            setSortType={setSortType}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
          <DisplayToggle
            isImageList={isImageList}
            setIsImageList={setIsImageList}
          />
        </div>
      </div>
      <div className="search-list flex flex-wrap-wrap mr-n2 justify-space-between">
        {isImageList
          ? currentItems?.map((data) => (
              <ImageDetailPropertyCard key={data.id} data={data} />
            ))
          : currentItems?.map((data) => (
              <GeneralDetailPropertyCard
                key={data.id}
                data={data}
                category={category}
              />
            ))}
        <div className="property-card property-card-flat property-sheet property-sheet-outlined"></div>
        <div className="property-card property-card-flat property-sheet property-sheet-outlined"></div>
      </div>
      {PropertyListBottom()}
      <div className="text-center">
        <SearchPropertyCount
          itemCount={allItemCount}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
        />
      </div>
    </div>
  );
};

export default PropertyList;
