import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useError } from 'shared/hooks/useError';
import { useApplicantPropertyIds } from '../hooks/useApplicantPropertyIds';

import PropertyCardImage from '../PropertyCardImage';
import AddMyListButton from '../AddMyListButton';

import { governmentName } from '../helper/governmentName';

import public_emblem from 'interfaces/assets/image/public-emblem-sample.jpeg';

//import { getMylist } from '../../../../read_storage/mylist';
//import { usePropertyImages } from '../../../../hooks/usePropertyImages';

const PropertyImageWrapper = styled.div`
  position: relative;
  border-top: 1px solid var(--line-base);
  border-bottom: 1px solid var(--line-base);
`;

const PropertyImageLink = styled(Link)`
  height: 150px;
  display: block;

  &:hover {
    text-decoration: none;
  }

  .kservice & {
    color: #38342d;
    font-size: 0.75rem;
  }
`;

const AddMyListButtonWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  padding: 8px;
`;

const ImageDetailPropertyCardHead = ({
  isShowGovernmentName,
  isSuspension,
  data,
  userId,
}) => {
  const { setError } = useError();
  // TODO: 画像パスが書かれていONが完成した後結合再開
  //const { propertyImages } = usePropertyImages();
  const thumbnailImgPath =
    `react/public/image_storage/${data.governmentId}/${data.id}/001_thumbnail.jpg`.replace(
      'react/public',
      '',
    );
  const [initialValue, setInitialValue] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const applicantPropertyIds = useApplicantPropertyIds(userId);
  useEffect(() => {
    const fetch = async () => {
      try {
        // todo: マイリスト表示はproviderから取得する
        //const myList = await getMylist(userId);
        //const isFav = myList.filter((item) => item.id === data.id);
        //setInitialValue(isFav.length > 0);
      } catch (err) {
        setError(err);
      }
    };
    fetch().then();
  }, []);

  useEffect(() => {
    if (isSuspension) {
      setIsDisabled(true);
    }

    if (applicantPropertyIds.length > 0) {
      setIsDisabled(applicantPropertyIds.includes(data.id));
      setInitialValue(true);
    }
  }, [applicantPropertyIds, isSuspension]);

  return (
    <>
      {isShowGovernmentName && (
        <div
          data-testid="property-card-header"
          className="option pa-0 ma-0 text-caption property-sheet theme-light">
          <div
            className="flex gaps py-1 align-center v-sheet theme--light px-2"
            style={{ height: '30px' }}>
            <div className="avatar">
              <img
                src={public_emblem}
                alt=""
                data-testid="public-emblem"
                style={{ height: '20px', minWidth: '20px', width: '20px' }}
              />
            </div>
            <div>{governmentName(data)}</div>
          </div>
        </div>
      )}
      <PropertyImageWrapper data-testid="image-detail-property-image-wrap">
        <PropertyImageLink
          to={`/items/${data.id}`}
          data-testid="image-detail-property-card-head-link">
          <PropertyCardImage
            isSuspension={isSuspension}
            thumbnail={thumbnailImgPath}
          />
        </PropertyImageLink>
        <AddMyListButtonWrapper>
          <AddMyListButton
            isSuspension={isSuspension}
            id={data.id}
            userId={userId}
            initialValue={initialValue}
            disabled={isDisabled}
          />
        </AddMyListButtonWrapper>
      </PropertyImageWrapper>
    </>
  );
};

export default ImageDetailPropertyCardHead;
