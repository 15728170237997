import Heading3 from 'interfaces/components/common/Heading/Heading3';
import RightsTransferProcedureAuctionSection from './TemplateAfterWinningBidAuction/RightsTransferProcedureAuctionSection.jsx';
import ImportantMatterAuctionSection from './TemplateAfterWinningBidAuction/ImportantMatterAuctionSection.jsx';

const TemplateAfterWinningBidAuction = () => {
  return (
    <div data-testid="template-after-winning-bid-auction" className="pt-9">
      <Heading3 text="落札後の注意事項" isBgGray={true}/>
      <RightsTransferProcedureAuctionSection/>
      <ImportantMatterAuctionSection/>
    </div>
  );
};

export default TemplateAfterWinningBidAuction;