import ConfirmInputText from "./ConfirmInputText.jsx";
import Heading3 from "interfaces/components/common/Heading/Heading3.jsx";

const renderHeading = (heading, index) => {
  if (heading.type === 'h3') {
    return <Heading3 key={index} text={heading.title}/>
  }

  if (heading.type === 'h4') {
    return <h4 key={index} className="pt-6" data-testid="procedure-new-entry-form-headline">{heading.title}</h4>
  }

  return null;
}

const ConfirmSection = ({ headings, inputsData }) => {
  return (
    <div
      className="pt-9"
      data-testid="confirm-section"
    >
      {
        headings &&
        headings.map((heading, index) =>
          renderHeading(heading, index)
        )
      }
      <div className="property-info-general-form-table">
        {
          inputsData.map((data, index) =>
            <ConfirmInputText
              key={index}
              label={data.text}
              state={data.state}
            />
          )
        }
      </div>
    </div>
  );
}
export default ConfirmSection;