import {
  ADDITIONAL_DURING_BIDDING,
  APPLYING,
  DURING_BIDDING,
  END_BIDDING,
  WAIT_OPEN_BID,
  WAIT_START_BID,
} from 'domain/Property/entities/constants/property';

import URL_LINKS from 'interfaces/pages/Dashboard/entities/externalLinks';

import { AuctionHelpBtn } from './AuctionHelpBtn';

export const AuctionHelpBtnBranch = ({ data }) => {
  if (data?.status === APPLYING) {
    return (
      <AuctionHelpBtn
        label={'参加申し込みとは？'}
        url={URL_LINKS.ABOUT_PARTICIPATE_URL}
      />
    );
  }
  if (data?.status === WAIT_START_BID || data?.status === DURING_BIDDING) {
    return (
      <AuctionHelpBtn label={'入札とは？'} url={URL_LINKS.ABOUT_BID_URL} />
    );
  }
  if (data?.status === WAIT_OPEN_BID || data?.status === END_BIDDING) {
    return (
      <AuctionHelpBtn label={'落札とは？'} url={URL_LINKS.BID_DEADLINE_URL} />
    );
  }
  if (data?.status === ADDITIONAL_DURING_BIDDING) {
    return (
      <AuctionHelpBtn
        label={'追加入札とは？'}
        url={URL_LINKS.ABOUT_ADD_BID_URL}
      />
    );
  }
};
