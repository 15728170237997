import { useState } from 'react';

import CategoryButton from "./CategoryButton.jsx";

const CategoryButtonList = ({title, categoryList}) => {
  const [isDisabled, setIsDisabled] = useState(null);
  const handleClick = (index) => {
    setIsDisabled(index);
  }
  const handleOtherClick = () => {
    setIsDisabled(null);
  }

  return (
    <div>
      <p className="text-body-1 font-weight-bold mb-2">
        { title }
      </p>
      <div className="flex wrap justify-space-between">
        {
          categoryList.map((category, index) =>
            <CategoryButton
              key={index}
              categoryData={category}
              onClick={index < categoryList.length - 1 ? () => { handleClick(index) } : handleOtherClick}
              disabled={isDisabled === index}
            />
          )
        }
      </div>
    </div>
  );
}
export default CategoryButtonList;