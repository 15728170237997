/** @format */

//import * as Sentry from "@sentry/react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App.jsx';

//Sentry.init({
//  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
//  integrations: [
//    new Sentry.BrowserTracing({
//      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//    }),
//    new Sentry.Replay(),
//  ],
//  // Performance Monitoring
//  tracesSampleRate: 1.0, //  Capture 100% of the transactions
//  // Session Replay
//  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//});

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  );
} else {
  console.error('Root element not found.');
}
