import { ValidationMessage } from 'interfaces/components/common/ValidationMessage';
import React from 'react';

import FormLabel from './FormLabel';
import SelectBox from '../../common/SelectBox';

const InputPullDown = React.memo(function InputPullDown({
  invalidMessage,
  required,
  label,
  itemList,
  state,
  setState,
  placeholder = '',
}) {
  return (
    <div className="kmanager-row">
      <div className="kmanager-col-4">
        <FormLabel label={label} required={required} />
      </div>
      <div className="kmanager-col form-area flex-column">
        <div
          className="new-style_input-pulldown"
          data-testid="input-pulldown"
          style={{ position: 'relative' }}>
          <SelectBox
            listItemData={itemList}
            state={state} //使われていない
            formStateHandler={setState}
            placeholder={placeholder}
            selectedData={state}
          />
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
      </div>
    </div>
  );
});
export default InputPullDown;
