// ロジックを含む処理
// フロントから呼ぶ関数

import {
  CAR_MANUFACTURE,
  PURPOSE,
  FIRST_REGIST_DATE_FROM,
  FIRST_REGIST_DATE_TO,
  CAR_MILEAGE_FROM,
  CAR_MILEAGE_TO,
} from 'shared/utils/constants/queryParam';

import {
  createPropertiesData,
  updatePropertiesData,
  deletePropertiesData,
} from '../../../infrastructure/api/Properties.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { CarPropertyCreate, CarPropertyGet } from '../entities/Property.js';
import { getCarProperty } from '../repositories/CarProperty.js';
import {
  carManufactureToJp,
  carPurposeToJp,
  carTransmissionTypeToJp,
} from 'shared/utils/constants/property';

export async function get(type) {
  return await getCarProperty(type);
}

export async function findById(type, id) {
  const properties = await getCarProperty(type);
  return properties.find(
    (data) => data.propertyId.toString() === id.toString(),
  );
}

export async function findByIds(type, ids) {
  const Property = await getCarProperty(type);
  return Property.filter((data) => ids.includes(data.id));
}

export async function findBySearchParams(type, searchParams, properties) {
  const carProperties = await getCarProperty(type);
  let propertyIds = properties?.map((property) => property.id);

  let retCar = carProperties.filter((carProperty) =>
    propertyIds.includes(carProperty.propertyId),
  );

  // メーカーによる検索
  let condCarManufacture = searchParams.get(CAR_MANUFACTURE);
  if (['', null].includes(condCarManufacture) === false) {
    retCar = retCar.filter(
      (property) => condCarManufacture === property.manufacture,
    );
  }

  // 用途による検索
  let condPurpose = searchParams.get(PURPOSE);
  if (['', null].includes(condPurpose) === false) {
    retCar = retCar.filter((property) => condPurpose === property.purpose);
  }

  let condFirstRegistDateFrom = searchParams.get(FIRST_REGIST_DATE_FROM);
  if (['', null].includes(condFirstRegistDateFrom) === false) {
    retCar = retCar.filter((property) =>
      property.firstRegisterDate === 'NULL'
        ? false
        : formatDateString(condFirstRegistDateFrom) <
          property.firstRegisterDate,
    );
  }

  let condFirstRegistDateTo = searchParams.get(FIRST_REGIST_DATE_TO);
  if (['', null].includes(condFirstRegistDateTo) === false) {
    retCar = retCar.filter((property) =>
      property.firstRegisterDate === 'NULL'
        ? false
        : formatDateString(condFirstRegistDateTo) > property.firstRegisterDate,
    );
  }

  let condCarMileageFrom = searchParams.get(CAR_MILEAGE_FROM);
  if (['', null].includes(condCarMileageFrom) === false) {
    retCar = retCar.filter((property) =>
      property.carMileage === 'NULL'
        ? false
        : Number(condCarMileageFrom) < Number(property.carMileage),
    );
  }

  let condCarMileageTo = searchParams.get(CAR_MILEAGE_TO);
  if (['', null].includes(condCarMileageTo) === false) {
    retCar = retCar.filter((property) =>
      property.carMileage === 'NULL'
        ? false
        : Number(condCarMileageTo) > Number(property.carMileage),
    );
  }

  // 車物件情報による検索結果から物件情報の対象を絞る
  let targetPropertyIds = retCar.map((c) => c.propertyId);
  let returnProperties = properties.filter((property) =>
    targetPropertyIds.includes(property.id),
  );

  // 物件情報に車の情報を追加
  returnProperties.forEach((property) => {
    property.carProperty = retCar.find(
      (carProperty) => carProperty.propertyId === property.id,
    );
    property.carProperty.manufactureJp =
      carManufactureToJp[property.carProperty.manufacture];
    property.carProperty.summaryFirstRegisterDate =
      property.carProperty.firstRegisterDate;
    property.carProperty.summarySafetyTerm = property.carProperty.safetyTerm;
    property.carProperty.summaryPurpose =
      carPurposeToJp[property.carProperty.purpose];
    property.carProperty.summaryTransmissionType =
      carTransmissionTypeToJp[property.carProperty.transmissionType];
  });

  return returnProperties;
}

const formatDateString = (dateString) => {
  let formatString = '';
  if (dateString !== null) {
    let tmp = new Date(dateString);
    formatString = `${tmp.getFullYear()}-${('00' + (tmp.getMonth() + 1)).slice(-2)}-${('00' + tmp.getDate()).slice(-2)} ${('00' + tmp.getHours()).slice(-2)}:${('00' + tmp.getMinutes()).slice(-2)}`;
  }
  return formatString;
};

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    const carProperty = new CarPropertyCreate(data);
    response = await createPropertiesData(carProperty);
  }
  if (type === KMANAGER) {
    response = await createPropertiesData(data);
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Property', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    const carProperty = new CarPropertyCreate(data);
    response = await createPropertiesData(carProperty);
  }
  if (type === KMANAGER) {
    response = await updatePropertiesData(data, data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Property', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    response = await deletePropertiesData(data.id);
  }
  if (type === KMANAGER) {
    response = await deletePropertiesData(data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Property', response);
  }
  return response;
}

function createPropertyArray(data) {
  let properties = [];
  data.forEach((property) => {
    properties.push(new CarPropertyGet(property));
  });
  return properties;
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}

export function sortByPriceASC(data) {
  let properties = createPropertyArray(data);
  return properties.sort((a, b) => a.estimatePrice - b.estimatePrice);
}

export function sortByPriceDESC(data) {
  let properties = createPropertyArray(data);
  return properties.sort((a, b) => b.estimatePrice - a.estimatePrice);
}

export function sortByDepositASC(data) {
  let properties = createPropertyArray(data);
  return properties.sort((a, b) => a.deposit - b.deposit);
}

export function sortByDepositDESC(data) {
  let properties = createPropertyArray(data);
  return properties.sort((a, b) => b.deposit - a.deposit);
}

export function sortByBiddersASC(data) {
  let properties = createPropertyArray(data);
  return properties.sort((a, b) => a.bidders.length - b.bidders.length);
}

export function sortByBiddersDESC(data) {
  let properties = createPropertyArray(data);
  return properties.sort((a, b) => b.bidders.length - a.bidders.length);
}

export function sortByIdASC(data) {
  let properties = createPropertyArray(data);
  return properties.sort((a, b) => a.id - b.id);
}

export function sortByIdDESC(data) {
  let properties = createPropertyArray(data);
  return properties.sort((a, b) => b.id - a.id);
}

export function sortBySaleNumberASC(data) {
  let properties = createPropertyArray(data);
  return properties.sort((a, b) =>
    a.commonData.saleNumber.localeCompare(b.commonData.saleNumber),
  );
}

export function sortBySaleNumberDESC(data) {
  let properties = createPropertyArray(data);
  return properties.sort((a, b) =>
    b.commonData.saleNumber.localeCompare(a.commonData.saleNumber),
  );
}

export function sortByNameASC(data) {
  let properties = createPropertyArray(data);
  return properties.sort((a, b) =>
    a.commonData.name.localeCompare(b.commonData.name),
  );
}

export function sortByNameDESC(data) {
  let properties = createPropertyArray(data);
  return properties.sort((a, b) =>
    b.commonData.name.localeCompare(a.commonData.name),
  );
}

export function sortByUpdatedAtASC(data) {
  let properties = createPropertyArray(data);
  return properties.sort((a, b) =>
    a.commonData.updatedAt.localeCompare(b.commonData.updatedAt),
  );
}

export function sortByUpdatedAtDESC(data) {
  let properties = createPropertyArray(data);
  return properties.sort((a, b) =>
    b.commonData.updatedAt.localeCompare(a.commonData.updatedAt),
  );
}

export function filterByName(data, name) {
  let properties = createPropertyArray(data);
  return properties.filter((property) => property.commonData.name === name);
}

export function filterBySaleNumber(data, saleNumber) {
  let properties = createPropertyArray(data);
  return properties.filter(
    (property) => property.commonData.saleNumber === saleNumber,
  );
}

export function filterByDivisionId(data, divisionId) {
  let properties = createPropertyArray(data);
  return properties.filter((property) => property.divisionId === divisionId);
}
