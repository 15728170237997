import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle';
import { Link } from 'react-router-dom';
import { procedureTypeParam } from 'shared/utils/helper/procedure_type_param.js';

import ContactTable from './ContactTable';

const Contact = ({ data, governmentId, procedureType }) => {
  return (
    <section data-testid="property-detail-contact">
      <section className="mb-10">
        <ArticleDefaultTitle title={'お問い合わせ'} />
        <div className="common-article-default-body rounded px-6 py-8 mb-8">
          <ContactTable data={data} />
          <div
            data-testid="to-government-properties"
            className="flex text-caption">
            <Link
              to={`/gov/${governmentId}?p=${procedureTypeParam(procedureType)}`}
              className="box mr-7 noshrink">
              物件一覧
            </Link>
          </div>
        </div>
      </section>
    </section>
  );
};
export default Contact;
