//import SelectButton from "./SelectButton";
import { ESTATE } from 'domain/Property/entities/constants/property';

import PlacesTree from './PlacesTree';

import SingleOpenAccordion from 'interfaces/components/common/Accordion/SingleOpenAccordion';
import Button from 'interfaces/components/common/Button/Button';
import { useContext } from 'react';
import 'interfaces/css/Properties/search-conditions.css';
import {
  DIRECTION,
  FIELD,
  PAGE,
  PAGE_SIZE,
  SEARCH_GOVERNMENT_SUB_CATEGORY_ID,
  SEARCH_PROPERTY_CATEGORY_ID,
} from 'shared/utils/constants/queryParam';

import { PropertiesContext } from '../../../pages/Properties/entities/PropertiesContext';

const SearchEstateConditions = ({ data, category }) => {
  const { searchParams, setSearchParams } = useContext(PropertiesContext);
  const cat = searchParams.get('category') ?? 'DATA_EMPTY';
  const clickHandle = () => {
    const newSearchParams = new URLSearchParams();
    if (cat !== 'DATA_EMPTY' && cat !== '') {
      newSearchParams.append('category', cat);
    }
    newSearchParams.append(SEARCH_PROPERTY_CATEGORY_ID, '');
    newSearchParams.append(SEARCH_GOVERNMENT_SUB_CATEGORY_ID, '');
    newSearchParams.append(DIRECTION, 'ASC');
    newSearchParams.append(FIELD, 'PRICE');
    newSearchParams.append(PAGE, 1);
    newSearchParams.append(PAGE_SIZE, 50);
    setSearchParams(newSearchParams);
  };
  return (
    <div className="search-conditions text-caption flex flex-wrap justify-center">
      {category === ESTATE && <PlacesTree />}
      <SingleOpenAccordion items={data} />
      <Button
        text="条件をクリア"
        isOutline={true}
        className="mt-2 common-button-thin-outline"
        onClick={clickHandle}
      />
    </div>
  );
};
export default SearchEstateConditions;
