// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-form-input-text {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, .42);
  border-radius: 4px;
  padding: 0 12px;
}

.property-form-input-text-input {
  font-size: 16px;
  flex: 1 1 auto;
  line-height: 20px;
  #padding: 8px 0;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, .42);
  border-radius: 4px;
  padding: 8px 12px;
}

.property-form-input-text-absolute-area {
  z-index: 1;
  position: relative;
}

.number-remaining-characters {
  color: #505050;
  font-size: 12px;
  line-height: 1.833;
  margin-bottom: 0;
  text-align: right;
}

.property-input-text-size-100 {
  width: 100%;
}

.property-input-text-size-80 {
  width: 80%;
}

.property-input-text-size-70 {
  width: 70%;
}

.property-input-text-size-50 {
  width: 50%;
}

.property-input-text-size-20 {
  width: 20%;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/property-form-input-text.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,oCAAoC;EACpC,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;EAGf,cAAc;EACd,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,YAAY;EACZ,WAAW;EACX,oCAAoC;EACpC,oCAAoC;EACpC,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".property-form-input-text {\n  background-color: rgb(255, 255, 255);\n  border: 1px solid rgba(0, 0, 0, .42);\n  border-radius: 4px;\n  padding: 0 12px;\n}\n\n.property-form-input-text-input {\n  font-size: 16px;\n  -webkit-box-flex: 1;\n  -ms-flex: 1 1 auto;\n  flex: 1 1 auto;\n  line-height: 20px;\n  #padding: 8px 0;\n  max-width: 100%;\n  min-width: 0;\n  width: 100%;\n  background-color: rgb(255, 255, 255);\n  border: 1px solid rgba(0, 0, 0, .42);\n  border-radius: 4px;\n  padding: 8px 12px;\n}\n\n.property-form-input-text-absolute-area {\n  z-index: 1;\n  position: relative;\n}\n\n.number-remaining-characters {\n  color: #505050;\n  font-size: 12px;\n  line-height: 1.833;\n  margin-bottom: 0;\n  text-align: right;\n}\n\n.property-input-text-size-100 {\n  width: 100%;\n}\n\n.property-input-text-size-80 {\n  width: 80%;\n}\n\n.property-input-text-size-70 {\n  width: 70%;\n}\n\n.property-input-text-size-50 {\n  width: 50%;\n}\n\n.property-input-text-size-20 {\n  width: 20%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
