
export class SummaryFloor {
    #summaryFloor

    constructor(summaryFloor) {
        this.#summaryFloor = summaryFloor;
    }

    get() {
        return this.#summaryFloor
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
