
export class SystemUsageTaxAuction {
  #systemUsageTaxAuction

  constructor(systemUsageTaxAuction) {
    this.#systemUsageTaxAuction = systemUsageTaxAuction;
  }

  get() {
    return this.#systemUsageTaxAuction
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
