import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import PageCautionDescription from 'interfaces/components/common/PageCautionDescription';
import { validateInputsCardProvisionalApplyCompletion } from 'interfaces/components/common/validateInputs/validateInputsCardProvisionalApplyCompletion';
import LocalGovernmentManagementModal from 'interfaces/components/LocalGovernmentManagement/LocalGovernmentManagementModal';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import { breadcrumbs } from 'interfaces/pages/LocalGovernmentManagementCredit/entities/breadcrumbs';
import { LocalGovernmentManagementCreditContext } from 'interfaces/pages/LocalGovernmentManagementCredit/entities/LocalGovernmentManagementCreditContext';
import { useContext, useEffect, useState } from 'react';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

import CreditForm from './CreditForm';
import { textExtractionByProcedureType } from 'shared/utils/helper/TextExtraction';      

import 'interfaces/css/CardProvisionalApplyCompletion/card-provisional-apply-completion.css';

const Credit = () => {
  const [indivisualSetting, setIndivisualSetting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const validations = validateInputsCardProvisionalApplyCompletion();
  const { creditCardPages } = useContext(
    LocalGovernmentManagementCreditContext,
  );
  const { localStorageProcedureType, localStorageGovernmentType } = useLocalStorage();

  // クレジットカード仮申し込み完了画面の設定情報を取得する
  useEffect(() => {
    const auctionCreditCardPage = creditCardPages.find(
      (item) => item.publicSaleType === 'AUCTION',
    );
    const bidCreditCardPage = creditCardPages.find(
      (item) => item.publicSaleType === 'BID',
    );

    // 入力欄の初期値を設定する
    validations.init.setAuctionDocumentSubmissionUrl(
      auctionCreditCardPage?.documentSubmissionUrl ?? '',
    );
    validations.init.setBidDocumentSubmissionUrl(
      bidCreditCardPage?.documentSubmissionUrl ?? '',
    );
    validations.init.setBidJointBiddingUrl(
      bidCreditCardPage?.jointBiddingUrl ?? '',
    );
  }, [creditCardPages]);

  return (
    <>
      {isOpen ? (
        <LocalGovernmentManagementModal
          handleClose={() => setIsOpen(false)}
          procedureType={localStorageProcedureType}
        />
      ) : (
        <></>
      )}
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <PropertyInfoGeneralPageDescription
        title={'行政機関ページ管理：クレジットカード仮申し込み完了画面'}
        content={
          'クレジットカードで公売保証金を納付した仮申し込み状態の参加者への、その後の手続きの案内を設定します。'
        }
        displayRequired={localStorageProcedureType == 'ASSET_SALE' || localStorageGovernmentType == 'NATIONAL_TAX_AGENCY'}
      />
      <div className="card-provisional-apply-completion-page-description">
        下記の場合は設定の必要はありません。
        <br />
        <ul>
          {localStorageProcedureType === 'ASSET_SALE' ? (
            <>
              <li>入札保証金の納付にクレジットカードを利用しない場合</li>
              <li>すべての物件に入札保証金を設定しない場合</li>
            </>
          ) : (
            <>
              <li>公売保証金の納付にクレジットカードを利用しない場合</li>
              <li>
                公売保証金の納付手続きにクレジットカードを利用するが、入札への参加申し込みに行政機関の申し込み承認が必要な物件を出品しない場合
              </li>
              <li>すべての物件に公売保証金を設定しない場合</li>
            </>
          )}
        </ul>
      </div>
      <PageCautionDescription
        content={`※${textExtractionByProcedureType(localStorageProcedureType)}の参加申込開始日以降は掲載終了まで修正ができません。`}
      />
      <CreditForm
        validations={validations}
        indivisualSetting={indivisualSetting}
        setIndivisualSetting={setIndivisualSetting}
        setIsOpen={setIsOpen}
        procedureType={localStorageProcedureType}
        governmentType={localStorageGovernmentType}
      />
    </>
  );
};
export default Credit;
