//import { isDefinedDateTime, isAfterEnd } from "../../../../application/validations.js";
import { isDefinedDateTime } from "../../../../application/validations.js";

export class AplStart {
  #aplStart

  constructor(aplStart) {
    this.#aplStart = aplStart;
  }

  get() {
    return this.#aplStart
  }

  get isValid() {
    return [
      this.isDefinedDateTime,
      this.isAfterEnd,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get isDefinedDateTime() {
    return isDefinedDateTime(this.#aplStart)
  }

  // バリデーションルールの適用
  get isAfterEnd() {
    return 'TRUE'
    // TOOD: endDateTimeが引き渡されていないため一時コメントアウト
    //return isAfterEnd(this.#aplStart, endDateTime, 24)
  }
}
