import GeneralDetailPropertyCardBody from './GeneralDetailPropertyCardBody.jsx';
import AddMyListButton from '../AddMyListButton.jsx';
import PropertyCardImage from '../PropertyCardImage.jsx';
import 'interfaces/css/common/general-detail-property-card.css';
//import { usePropertyImages } from 'shared/hooks/usePropertyImages.js';

const GeneralDetailPropertyCard = ({ data, category, page = 'SEARCH' }) => {
  // TODO: 画像パスが書かれているJSONが完成した後結合再開
  //const { propertyImages } = usePropertyImages();
  const thumbnailImgPath =
    `react/public/image_storage/${data?.governmentId}/${data?.id}/001_thumbnail.jpg`.replace(
      'react/public',
      '',
    );
  const isSuspension = data?.status === '中止';
  return (
    <div className="general-detail-property-card">
      {page === 'MYLIST' && (
        <div className="checkbox-container">
          <input type="checkbox" className="checkbox" />
        </div>
      )}
      <div className="general-detail-property-thumbnail">
        <PropertyCardImage
          isSuspension={isSuspension}
          thumbnail={thumbnailImgPath}
        />
      </div>
      <GeneralDetailPropertyCardBody
        data={data}
        isSuspension={isSuspension}
        category={category}
      />
      <AddMyListButton isSuspension={isSuspension} />
    </div>
  );
};

export default GeneralDetailPropertyCard;
