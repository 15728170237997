
export class ParticipationStatus {
  #participationStatus

  constructor(participationStatus) {
    this.#participationStatus = participationStatus;
  }

  get() {
    return this.#participationStatus
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
