import { createContext } from 'react';

import { breadcrumbs } from './breadcrumbs.js';
import { DATA_EMPTY } from '../../../../shared/utils/helper/constants.js';

export const ShippingContext = createContext({
  // Default Value
  responsive: DATA_EMPTY,
  breadcrumbs: breadcrumbs,
  managementMessages: DATA_EMPTY,
  governmentId: DATA_EMPTY,
  governmentType: DATA_EMPTY,
  procedureType: DATA_EMPTY,
  queryParams: DATA_EMPTY,
  applicationInformation: DATA_EMPTY,
  myUserInformation: DATA_EMPTY,
  property: DATA_EMPTY,
  applicantInformations: DATA_EMPTY,
  auctionUser: DATA_EMPTY,
});
