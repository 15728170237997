import { createContext } from "react";
import { DATA_EMPTY } from "shared/utils/helper/constants.js";

export const PropertyInfoCarContext = createContext({
  responsive: DATA_EMPTY,
  managementMessages: DATA_EMPTY,
  governmentId: DATA_EMPTY,
  governmentType: DATA_EMPTY,
  procedureType: DATA_EMPTY,
  queryParams: DATA_EMPTY,
  carProperty: DATA_EMPTY,
  invitation: DATA_EMPTY,
  property: DATA_EMPTY,
  divisions: DATA_EMPTY,
  paymentMethods: DATA_EMPTY,
  selectedCategory: DATA_EMPTY,
});
