import "interfaces/css/common/copyright.css";

const CopyRight = () => {
  return (
    <div data-testid="copy-right">
      <small className="copyright">© 2024 紀尾井町戦略研究所株式会社</small>
    </div>
  );
}

export default CopyRight;