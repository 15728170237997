// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration-pick-up-delivery-style-modal-body {
  padding: 28px;
  text-align: center;
  pointer-events: auto;
}

.registration-pick-up-delivery-style-modal-button-wrapper {
  margin: 28px 0 8px 0;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/components/Property/service/PropertyDetail/DeliveryStyleSelector/css/registration-pick-up-delivery-style-modal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;EACpB,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".registration-pick-up-delivery-style-modal-body {\n  padding: 28px;\n  text-align: center;\n  pointer-events: auto;\n}\n\n.registration-pick-up-delivery-style-modal-button-wrapper {\n  margin: 28px 0 8px 0;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
