import YearlySchedulesProvider from './YearlySchedulesProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { yearlySchedules } from "../entities/componentMapping.js";

const YearlySchedules = ({ type }) => {
  return (
    <YearlySchedulesProvider type={type}>
      {getComponent(yearlySchedules, type)}
    </YearlySchedulesProvider>
  );
};

export default YearlySchedules;
