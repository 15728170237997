import DisplaySample from "../../../interfaces/components/NewEntry/DisplaySample";
import EntryAuctionSampleImage from '../../assets/image/navi/new_entry/entry-auction.png';
import EntryBidSampleImage from '../../assets/image/navi/new_entry/entry-bid.png';
import NumberOfEntriesSampleImage from '../../assets/image/navi/new_entry/number-of-entries.png';

export const new_entry_description1 = [
    {id: 1, text: "ご参加予定の公売（参加申込開始日）を選択してください。"},
]
export const new_entry_description2 = [
    {id: 1, text: "例）第○回、令和○年度公売など", marked: false},
    {id: 2, text: "30文字以内で入力してください。\n公告名は官公庁ナビ上のみに表示され、参加者には見えません。行政機関が管理しやすいような文言にしてください。"},
]
export const new_entry_description3 = [
    {id: 1, text: "「行う」を選択した場合、参加申し込み者が不在（0人）の場合に自動で入札終了処理を行います。"},
]
export const new_entry_description4 = [
    {id: 1, text: "「希望する」を選択した場合、KSIより貴庁に認証書面（表紙）に押印を行ったうえで郵送します。"},
    {id: 2, text: "KSI官公庁オークションでは、官公庁ナビにて認証書面（表紙）と認証書面（対象者の識別番号リスト）が開催ごとにダウンロードいただけますので、認証書面（表紙）への押印が不要の行政機関様は郵送を省略いただくことが可能です。"},
]
export const new_entry_description5 = [
    {id: 1, text: "「押印して郵送する」を選択した場合、オンライン申請を行った後に減額申請書に貴庁にて押印を行い、KSIへ郵送します。"},
    {id: 2, text: "KSI官公庁オークションでは、管理画面よりオンラインで減額申請をすることが可能です。申請時に押印手続が不要の行政機関様はKSIへの郵送を省略いただくことが可能です。"},
]
export const new_entry_description_public_deposit_delivery_status = [
    {id: 1, text: "複数選択可能です。\n出品物件のうち、公売保証金の納付方法にオン納付（クレジットカード納付）が1件でも含まれる場合は、官公庁ナビより保証金納付口座を必ずご登録ください。\nまた、「オン納付」の選択のみでは公売の入札は利用できません。"},
]
export const new_entry_description_asset_deposit_delivery_status = [
    {id: 1, text: "複数選択可能です。\n出品物件のうち、入札保証金の納付方法にオン納付（クレジットカード納付）が1件でも含まれる場合は、官公庁ナビより保証金納付口座を必ずご登録ください。"},
]
export const new_entry_description7 = [
    {id: 1, text: "例）○○県○○市", marked: false},
    {id: 2, text: "都道府県から入力してください。\n○○県→○○県、○○市→○○県○○市、○○町→○○県○○郡○○町（群がある場合は必ず入力してください）\n30文字以内で入力してください。"},
]
export const new_entry_description8 = [
    {id: 1, text: "例）auction@city.prefecture.jp", marked: false},
    {id: 2, text: "このメールアドレスは、落札者のみ表示されるページに掲載されますが、落札者との連絡用のメールアドレスにもなりますので、フリーメールアドレスはご利用いただけません。"},
]
export const new_entry_description9 = [
    {id: 1, text: "数字は半角で入力してください。\n電話番号に内線番号を入れることはできません。内線番号が必要な場合は、官公庁ナビでご入力いただけます。\n内線番号を除いた番号をご入力ください。"},
]
export const new_entry_description10 = [
    {
        id: 1,
        text: <>「表示する」を選択した場合、官公オークション、行政機関様トップページに申込時から公開終了まで行政機関様で実施しているすべての物件の申し込み総数が掲載されます。（個別の物件ごとの申し込み数は表示されません）。<br/>「申し込み総数の表示」の「表示する・しない」は参加申し込み開始後の変更はできません。<br/>申し込み総数の表示サンプル画面は<DisplaySample triggerText='こちら' imagePath={EntryAuctionSampleImage}/>を確認ください。</>
    }
]
export const new_entry_description11 = [
    {
        id: 1,
        text: <>「表示する」を選択した場合、官公オークション、行政機関様トップページに申込時から公開終了まで行政機関様で実施しているすべての物件の申し込み総数が掲載されます。（個別の物件ごとの申し込み数は表示されません）<br/>「申し込み総数の表示」の「表示する・しない」は参加申し込み開始後の変更はできません。<br/>申し込み総数の表示サンプル画面は<DisplaySample triggerText='こちら' imagePath={EntryBidSampleImage}/>をご確認ください。</>
    }
]
export const new_entry_description12 = [
    {
        id: 1,
        text: <>「表示する」を選択した場合、開札後に各物件の詳細ページに入札件数が開示されます。<br/>「入札件数の表示」の「表示する・しない」は参加申し込み開始後の変更はできません。<br/>入札件数の表示サンプル画面は<DisplaySample triggerText='こちら' imagePath={NumberOfEntriesSampleImage}/>をご確認ください。</>},
]
