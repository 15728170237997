import Button from 'interfaces/components/common/Button/Button';
import Modal2 from 'interfaces/components/common/Modal/Modal2';
import { useState } from 'react';
import { useError } from 'shared/hooks/useError';
import './css/registration-pick-up-delivery-style-modal.css';

const DoneContent = ({ handleClose }) => {
  return (
    <div>
      <div data-testid="registration-caution">
        <p>メールを送信しました。ご確認ください。</p>
      </div>
      <div className="registration-pick-up-delivery-style-modal-button-wrapper">
        <Button text="OK" onClick={handleClose} height="44px" width="100px" />
      </div>
    </div>
  );
};

const UndoneContent = ({ setIsDone, handleClose }) => {
  const { setError } = useError();

  const handleRegistration = () => {
    try {
      // TODO: ここで登録のAPI叩く
      setIsDone(true);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <div>
      <div data-testid="registration-caution">
        <p>引渡方法の登録は変更することが出来ません。</p>
        <p>直接引取で登録しますか？</p>
      </div>
      <div className="registration-pick-up-delivery-style-modal-button-wrapper">
        <Button text="登録完了" height="44px" onClick={handleRegistration} />
        <Button
          text="キャンセル"
          height="44px"
          onClick={handleClose}
          className="cancel-common-button2 ml-3"
        />
      </div>
    </div>
  );
};

const RegistrationPickUpDeliveryStyleModal = ({ isOpen, handleClose }) => {
  const [isDone, setIsDone] = useState(false);

  return (
    <Modal2 isOpen={isOpen} handleClose={() => {}} modalSize={'M'}>
      <div className="registration-pick-up-delivery-style-modal-body">
        {isDone ? (
          <DoneContent handleClose={handleClose} />
        ) : (
          <UndoneContent setIsDone={setIsDone} handleClose={handleClose} />
        )}
      </div>
    </Modal2>
  );
};

export default RegistrationPickUpDeliveryStyleModal;
