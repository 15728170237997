
export class SummaryLayout {
    #summaryLayout

    constructor(summaryLayout) {
        this.#summaryLayout = summaryLayout;
    }

    get() {
        return this.#summaryLayout
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
