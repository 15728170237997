// ロジックを含む処理
// フロントから呼ぶ関数

import { compareData } from '../../../shared/utils/helper/compareData.js';
import { getBidderHistory } from '../repositories/BidderHistory.js';

export async function get(type) {
  return await getBidderHistory(type);
}

export async function findById(type, id) {
  const BidderHistory = await getBidderHistory(type);
  return BidderHistory.find((data) => data.id === id);
}

// 物件の最大入札額・ユーザが入札した最大入札額・ユーザが入札した追加入札の最大入札額を取得
export async function findUserMaxBidPrice(type, propertyId, auctionUserId) {
  const histories = await getBidderHistory(type);

  // 入札期間中の入札履歴
  let maxHistories = histories.filter(
    (history) => history.propertyId.toString() === propertyId.toString(),
  );
  let maxPriceHistory = maxHistories[0];
  maxHistories.forEach((history) => {
    if (Number(history.price) > Number(maxPriceHistory.price)) {
      maxPriceHistory = history;
    }
  });

  // 入札期間中の入札履歴
  let userHistories = histories.filter(
    (history) =>
      history?.propertyId?.toString() === propertyId?.toString() &&
      history?.auctionUserId?.toString() === auctionUserId?.toString() &&
      history?.bidInfo !== 'ADDITION',
  );
  let maxUserHistory = userHistories[0];
  userHistories.forEach((history) => {
    if (Number(history.price) > Number(maxUserHistory.price)) {
      maxUserHistory = history;
    }
  });

  // 追加入札期間の入札履歴
  let addUserHistories = histories.filter(
    (history) =>
      history?.propertyId?.toString() === propertyId?.toString() &&
      history?.auctionUserId?.toString() === auctionUserId?.toString() &&
      history?.bidInfo === 'ADDITION',
  );
  let maxAddUserHistory = addUserHistories[0];
  addUserHistories.forEach((history) => {
    if (Number(history.price) > Number(maxAddUserHistory.price)) {
      maxAddUserHistory = history;
    }
  });

  return {
    maxPriceBidder: maxPriceHistory,
    maxPriceUserBidder: maxUserHistory,
    maxPriceUserAddBidder: maxAddUserHistory,
    bidderCount: maxHistories.length,
    bidderHistories: maxHistories,
  };
}

export async function findByPropertyId(type, propertyId) {
  const histories = await getBidderHistory(type);
  return histories.filter(
    (history) => history.propertyId.toString() === propertyId.toString(),
  );
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
