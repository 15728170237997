
export class CallUpBidStatus {
  #callUpBidStatus

  constructor(callUpBidStatus) {
    this.#callUpBidStatus = callUpBidStatus;
  }

  get() {
    return this.#callUpBidStatus
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
