// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login .password-form {
  margin-bottom: 54px;
}

.login .password-input {
  width: 100%;
  height: 20px;
  padding: 8px 8px;
  font-size: 1rem;
  margin-top: 8px;
  background-color: var(--white);
  border: solid 1px var(--input-border);
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Login/password-form.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,8BAA8B;EAC9B,qCAAqC;EACrC,kBAAkB;AACpB","sourcesContent":[".login .password-form {\n  margin-bottom: 54px;\n}\n\n.login .password-input {\n  width: 100%;\n  height: 20px;\n  padding: 8px 8px;\n  font-size: 1rem;\n  margin-top: 8px;\n  background-color: var(--white);\n  border: solid 1px var(--input-border);\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
