export const MessageLink = ({ prevText, href, content, laterText }) => {
  return (
    <p className="ma-0" data-testid="announce-message">
      {prevText}
      <a href={href} target="_blank" rel="noopener noreferrer">{content}</a>
      {laterText}
    </p>
  );
};

export const Message = ({ content }) => {
  return <p className="ma-0" data-testid="announce-message">{content}</p>;
};
