import PropertyListSelector from 'interfaces/components/common/PropertyInfoForm/PropertyListSelector.jsx';
import { useEffect, useState } from 'react';

import ListBox from './ListBox.jsx';

export const SelectBox = ({
  listItemData,
  hasInitialValue = true,
  formStateHandler,
  width = '350px',
  placeholder = '',
  selectedData,
}) => {
  const [showListBox, setShowListBox] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const selectBoxClickHandler = () => {
    setShowListBox(!showListBox);
  };
  useEffect(() => {
    setSelectedValue(selectedData);
    if (hasInitialValue && selectedData === '') {
      setSelectedValue(listItemData ? listItemData[0]?.text : '');
    }
  }, [selectedData]);

  return (
    <div data-testid="select-box" style={{ position: 'relative' }}>
      <PropertyListSelector
        content={selectedValue}
        onClick={selectBoxClickHandler}
        width={width}
        placeholder={placeholder}
      />
      {showListBox && (
        <ListBox
          data={listItemData}
          setShowListBox={setShowListBox}
          setSelectedValue={setSelectedValue}
          formStateHandler={formStateHandler}
          width={width}
        />
      )}
    </div>
  );
};

export default SelectBox;
