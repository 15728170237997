import CancelButton from 'interfaces/components/common/Button/CancelButton';
import ClearButton from 'interfaces/components/common/Button/ClearButton';
import ConfirmButton from 'interfaces/components/common/Button/ConfirmButton';
import ButtonList from 'interfaces/components/common/ButtonList';
import KmanagerInputType from 'interfaces/components/common/Form/KmanagerInputType';
import { useNavigate } from 'react-router-dom';
// import 'interfaces/css/common/common-form.css';
import 'interfaces/css/common/form.css';

const TOP_PATH = '/kmanager';

const AuctionForm = ({ inputs, toConfirm }) => {
  const navigation = useNavigate();
  const navigateBack = () => {
    navigation(TOP_PATH);
  };

  return (
    <div className="kmanager-col-12">
      <div className="kmanager-form" data-testid="kmanager-auction-form">
        {inputs &&
          inputs.map((input, index) => {
            return KmanagerInputType(input, index);
          })}
        <ButtonList>
          <div className="d-flex justify-center mt-10">
            <CancelButton clickHandler={navigateBack} />
            <ClearButton inputs={inputs} />
            <ConfirmButton inputs={inputs} toConfirm={toConfirm} />
          </div>
        </ButtonList>
      </div>
    </div>
  );
};

export default AuctionForm;

// import configs from "../../../../../work/members_student/project/auction_matching/react/src/kmanager/api/configs/CAMEL_TABLE_NAME";
// import { allFetch } from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/newEntry";
//
// const create = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.create);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const update = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.update);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const destroy = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.destroy);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }

// import configs from "../../../../../work/members_student/project/auction_matching/react/src/kmanager/api/configs/CAMEL_TABLE_NAME";
// import { allFetch } from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/newEntry";
//
// const create = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.create);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const update = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.update);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const destroy = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.destroy);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
