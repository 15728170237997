// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navi-user-management-attention-text {
    font-size: 16px;
    line-height: 1.375;
    margin-bottom: 4px;
}

.navi-user-management .primary-button-wrap {
    text-align: right;
    padding-top: 20px;
}

.navi-user-management .attention-text-wrap {
    padding-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/NaviUserManagement/navi-user-management.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".navi-user-management-attention-text {\n    font-size: 16px;\n    line-height: 1.375;\n    margin-bottom: 4px;\n}\n\n.navi-user-management .primary-button-wrap {\n    text-align: right;\n    padding-top: 20px;\n}\n\n.navi-user-management .attention-text-wrap {\n    padding-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
