import loginBox from 'interfaces/css/Login/login-box.module.css'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PasswordForm from "./PasswordForm.jsx";
import UserIdForm from "./UserIdForm.jsx";
import { login } from "domain/Auth/services/Login";
import Button from "../../common/Button/Button.jsx";
import ErrorMessage from "../../common/ErrorMessage.jsx";
import { ServiceTypes } from 'shared/utils/constants/service.js';

const LoginBox = () => {
  const navigation = useNavigate();
  const TEXT_FAIL_LOGIN = "ログインできませんでした。\n" + "ナビユーザーIDかパスワードをもう一度確認してください。"
  const [loginUserId, setLoginUserId] = useState("")
  const [password, setPassword] = useState("")
  const [message, setMessage] = useState("")
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsDisabled(!(loginUserId && password));
  }, [loginUserId, password]);

  const handlePopstate = () => {
    navigation('/navi/login')
  }

  useEffect(() => {
    if (localStorage.getItem("naviUserId") !== null) { navigation("/navi") }
    window.addEventListener('popstate', handlePopstate, false);
  }, []);

  async function handleClick(e) {
    e.preventDefault();

    if (isDisabled) {
      return;
    }

    setMessage("")
    try {
      const requiredNewPassword = await login(loginUserId, password, ServiceTypes.NAVI.string)
      console.log(requiredNewPassword)

      if (requiredNewPassword) {
        // TODO: パスワード変更画面に遷移するようにする
        navigation("/navi")
      }
      navigation("/navi")
    } catch (error) {
      setMessage(TEXT_FAIL_LOGIN)
    }
    navigation("/navi")
  }

  return (
    <div className={loginBox.login_box} data-testid="login-box">
      <UserIdForm state={loginUserId} setState={setLoginUserId}/>
      <PasswordForm state={password} setState={setPassword}/>
      {message && <ErrorMessage content={message}/>}
      <div className="flex justify-center">
        <Button text="ログイン" height="44px" isDisabled={isDisabled} onClick={handleClick}/>
      </div>
    </div>
  );
};

export default LoginBox;