import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();

export const getPropertyCategories = async () => {
  try {
    const response = await fetchPropertyCategories(`${BASE_URL_NAVIGATION}/storage/current/property_categories/data.json`);
    return extractPropertyCategories(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ property_categories ）を取得できませんでした");

    try {
      const response = await fetchPropertyCategories(`${BASE_URL_NAVIGATION}/storage/previous/property_categories/data.json`);
      return extractPropertyCategories(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ property_categories ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchPropertyCategories = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractPropertyCategories = (response) => {
  return response.map(property_category => property_category);
}