import PropertyInfoFormLabel from "interfaces/components/common/PropertyInfoForm/PropertyInfoFormLabel";
import {Link} from "react-router-dom";

const PropertyInfoEstateFormSketch = ({label}) => {
  return (
    <div data-testid="property-info-estate-form-sketch" className="property-info-general-form-table-row">
      <PropertyInfoFormLabel label={label}/>
      <div className="property-info-general-form-table-cell">
        <p data-testid="property-info-estate-form-sketch-description">
          <Link to={"#pdf"} >こちら</Link>よりPDF形式のファイルをアップロードしてください。
        </p>
      </div>
    </div>
  );
}
export default PropertyInfoEstateFormSketch