// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forget-password-form {
  margin-top: 8px;
  padding: 0 16px 16px 16px;
}

.forget-password-form-submit-button-wrap {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/forget-password-form.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".forget-password-form {\n  margin-top: 8px;\n  padding: 0 16px 16px 16px;\n}\n\n.forget-password-form-submit-button-wrap {\n  display: flex;\n  justify-content: center;\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
