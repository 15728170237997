import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import DeadlineInfoPanel from './DeadlineInfoPanel.jsx';
import { DashboardContext } from '../../../../../../pages/Dashboard/entities/DashboardContext.js';

const DeadlineInfo = () => {
  const { progressSchedules } = useContext(DashboardContext);

  const [publicSaleSchedule, setPublicSaleSchedule] = useState([]);
  const [nationalTaxSchedule, setNationalTaxSchedule] = useState([]);
  const [assetSaleSchedule, setAssetSaleSchedule] = useState([]);

  useEffect(() => {
    let publicSale = [];
    let nationalTax = [];
    let assetSale = [];

    progressSchedules.forEach((schedule) => {
      if (schedule.procedureType === 'PUBLIC_SALE') {
        if (schedule.governmentTypeGroup === 'NATIONAL_TAX_AGENCY') {
          nationalTax.push(schedule);
        } else {
          publicSale.push(schedule);
        }
      } else if (schedule.procedureType === 'ASSET_SALE') {
        assetSale.push(schedule);
      }
    });
    setPublicSaleSchedule(publicSale);
    setNationalTaxSchedule(nationalTax);
    setAssetSaleSchedule(assetSale);
  }, [progressSchedules]);

  return (
    <div data-testid="dead-line-info">
      {publicSaleSchedule?.map((schedule) => {
        return (
          <DeadlineInfoPanel
            key={schedule.id}
            text="インターネット公売"
            auctionName={schedule.name ?? ''}
            aplEnd={schedule.aplEnd ?? ''}
          />
        );
      })}
      {nationalTaxSchedule?.map((schedule) => {
        return (
          <DeadlineInfoPanel
            key={schedule.id}
            text="インターネット公売（国税）"
            auctionName={schedule.name ?? ''}
            aplEnd={schedule.aplEnd ?? ''}
          />
        );
      })}
      {assetSaleSchedule?.map((schedule) => {
        return (
          <DeadlineInfoPanel
            key={schedule.id}
            text="公有財産売却"
            auctionName={schedule.name ?? ''}
            aplEnd={schedule.aplEnd ?? ''}
          />
        );
      })}
      <p className="text-right mb-0">
        <Link to="/schedules" data-testid="schedules-link">
          年間スケジュールへ ＞
        </Link>
      </p>
    </div>
  );
};

export default DeadlineInfo;
