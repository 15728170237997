
export class LeadText {
  #leadText

  constructor(leadText) {
    this.#leadText = leadText;
  }

  get() {
    return this.#leadText
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
