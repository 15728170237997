import EmailSwitcher from '../Email/EmailSwitcher';
import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import Heading4 from 'interfaces/components/common/Heading/Heading4';
import PlatformDependentCharacters from './PlatformDependentCharacters';

import { breadcrumbs } from 'interfaces/pages/SampleGuidelineCaution/entities/breadcrumbs.js';
import { useNavigate, useLocation } from 'react-router-dom';

const SampleGuideline = () => {
  const navigate = useNavigate();
  const { search, state } = useLocation();
  const queryParams = new URLSearchParams(search);
  const from = state?.from || queryParams.get('from');
  const fromPage = from === 'sample-guideline' ? 'ガイドラインサンプル' : from === 'sample-after-winning-bid' ? '落札後の注意事項サンプル' : '';
  const labels = [
    {
      label: fromPage,
      handleClick: () => navigate(`/navi/faq/${from}`),
    },
    {
      label: '記入文言の注意事項',
      handleClick: () => navigate('/navi/faq/sample-guideline-caution'),
    },
  ];
  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <EmailSwitcher labels={labels} activeTabIndex={1} />
      <Heading2 title="記入文言の注意事項" />
      <div className="heading4-wrap pt-6">
        <Heading4 title="機種依存文字を使用しないでください" />
      </div>
      <div className="pt-4">
        WEBページ上に記載する文字列に「機種依存文字」を使用すると、閲覧者のハードウェアやソフトウェアの環境によっては、文字化けしてしまう場合がありますので以下のような機種依存文字は使用しないでください。
      </div>
      <PlatformDependentCharacters />
    </>
  );
};

export default SampleGuideline;
