import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel';

import CreditCardLimitInput from './CreditCardLimitInput';

const monthArray = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

const yearArray = () => {
  const today = new Date();
  const currentYear = today.getFullYear();

  let targetYear = [];
  for (let year = currentYear; year < currentYear + 20; year++) {
    targetYear.push(year);
  }
  return targetYear;
};

const CreditCardLimitInputs = ({ creditCardInfo, handleChange }) => {
  return (
    <tr data-testid="credit-card-limit-inputs">
      <th data-testid="credit-card-limit-input-label">
        有効期限
        <RequiredLabel />
      </th>
      <td className="w-expand" data-testid="credit-card-limit-input-body">
        <div className="flex">
          <CreditCardLimitInput
            items={monthArray}
            value={creditCardInfo.expirationDateMM}
            handleSelect={(item) => handleChange({ expirationDateMM: item })}
            label="月"
          />
          <CreditCardLimitInput
            items={yearArray()}
            value={creditCardInfo.expirationDateYY}
            handleSelect={(item) => handleChange({ expirationDateYY: item })}
            label="年"
          />
        </div>
      </td>
    </tr>
  );
};

export default CreditCardLimitInputs;
