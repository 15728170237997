import SystemUsagesTable from 'interfaces/components/Billing/SystemUsagesTable';
import SmallTopicTitle from 'interfaces/components/common/Heading/SmallTopicTitle';
//import TotalAmountBreakdown from "interfaces/components/Billing/TotalAmountBreakdown";

const BeforeInvoicePublicSaleTypeSystemUsages = ({ publicSaleType, data }) => {
    if (data === null || data?.length === 0) {
        return;
    }

    return (
        <div data-testid="public-sale-type-system-usages">
            <SmallTopicTitle text={`${publicSaleType}形式`}/>
            <SystemUsagesTable data={data}/>
            {/*<TotalAmountBreakdown publicSaleType={publicSaleType}/>*/}
        </div>
    );
};

export default BeforeInvoicePublicSaleTypeSystemUsages;
