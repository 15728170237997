import PropertyInfoRealEstateProvider from './PropertyInfoRealEstateProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { propertyInfoRealEstate } from "../entities/componentMapping.js";

const PropertyInfoRealEstate = ({ type }) => {
  return (
    <PropertyInfoRealEstateProvider type={type}>
      {getComponent(propertyInfoRealEstate, type)}
    </PropertyInfoRealEstateProvider>
  );
};

export default PropertyInfoRealEstate;
