// ロジックを含む処理
// フロントから呼ぶ関数

import {updateServiceProviderData} from "../../../infrastructure/api/ServiceProvider.js";
import { compareData } from "../../../shared/utils/helper/compareData.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import {getServiceProvider} from "../repositories/ServiceProvider.js";

export async function get(type) {
  return await getServiceProvider(type)
}

export async function findById(type, id) {
  const List = await getServiceProvider(type);
  return List.find(data => data.id === id)
}


export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (type === KMANAGER) {
    response = await updateServiceProviderData(data, data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error update List', response);
  }
  return response
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]))
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]))
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value)
}
