import { validateInclude, validateRangeLength } from "../../../../application/validations.js";

export class DocumentPageUrl {
  #documentPageUrl

  constructor(documentPageUrl) {
    this.#documentPageUrl = documentPageUrl;
  }

  get() {
    return this.#documentPageUrl
  }

  get isValid() {
    return [
      this.validateIncludeUrl,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateIncludeUrl() {
    return validateInclude(this.#documentPageUrl, ['https://', 'http://'])
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#documentPageUrl, 0, 2048)
  }
}
