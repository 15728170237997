const BackButton = ({ text, handleClick, svg }) => {
  return (
    <div
      data-testid="kmanager-back-button"
      className="kmanager-button d-inline-flex align-center justify-center kmanager-back-button"
      onClick={handleClick}
    >
      { svg }
      { text }
    </div>
  );
};
export default BackButton;