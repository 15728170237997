
export class GovHpName {
    #govHpName

    constructor(govHpName) {
        this.#govHpName = govHpName;
    }

    get() {
        return this.#govHpName
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
