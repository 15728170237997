import AuctionHeader from "./AuctionHeader/AuctionHeader.jsx";
import GovernmentHeader from "./GovernmentHeader/GovernmentHeader.jsx";
import ManagementHeader from "./ManagementHeader/ManagementHeader.jsx";
import '../../../css/common/header.css'

const HeaderType = (props) => {
  return {
    SERVICE: <AuctionHeader {...props}/>,
    NAVI: <GovernmentHeader {...props}/>,
    KMANAGER: <ManagementHeader {...props}/>
  }
}

export const Header = ({ type, props }) => {
  const header = HeaderType(props)
  return header[type]
};

export default Header