import pino from "pino";

import { BASE_URL_NAVIGATION } from "../../../config/envConstants.js";
import { extractData } from "../../../shared/utils/helper/extractData.js";
import { fetchData } from "../../../shared/utils/helper/fetchData.js";

const logger = pino();

const CURRENT_REMIND_MESSAGES = `${BASE_URL_NAVIGATION}/storage/current/remind_messages/data.json`;
const PREVIOUS_REMIND_MESSAGES = `${BASE_URL_NAVIGATION}/storage/previous/remind_messages/data.json`;
export const fetchRemindMessages = async () => {
  try {
    const response = await fetchData(CURRENT_REMIND_MESSAGES);
    return extractData(response);
  } catch (error) {
    logger.warn(`現在の通知（ remind_messages ）を取得できませんでした ${error.message}`);
    try {
      const response = await fetchData(PREVIOUS_REMIND_MESSAGES);
      return extractData(response);
    } catch (error) {
      logger.warn(`以前の通知（ remind_messages ）を取得できませんでした ${error.message}`);
      return [];
    }
  }
};
