
export class UpdatedAt {
  #updatedAt

  constructor(updatedAt) {
    this.#updatedAt = updatedAt;
  }

  get() {
    return this.#updatedAt
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
