import ValidationMessage from './ValidationMessage';
import 'interfaces/css/common/property-form-textarea.css';
import styled from 'styled-components';

const TextArea = styled.textarea`
  background-color: rgb(255, 255, 255);
  border: ${(props) =>
    props.$validation
      ? '2px solid #ff5252!important'
      : '1px solid rgba(0, 0, 0, .42)'};
  border-radius: 4px;
  caret-color: ${(props) => (props.$validation ? '#ff5252' : '#ff7f00')};
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 16px;
  line-height: 20px;
  max-width: 100%;
  min-width: 0;
  outline: none;
  #padding: 8px 0;
  padding: 8px 12px;
  width: 100%;
`;

const FormTextArea = ({
  number_remaining_characters,
  className,
  rows,
  data = '',
  handler1 = () => {},
  handler2 = () => {},
  invalidMessage = '',
  maxCharacters = undefined,
}) => {
  const handleChange = (event) => {
    handler1(event);
    handler2(event);
  };

  const calcRemainCharacters = () => {
    if (maxCharacters === undefined) {
      return '';
    }
    if (data === undefined) {
      return maxCharacters;
    } else {
      return maxCharacters - data.length;
    }
  };

  return (
    <div data-testid="property-form-textarea-classname" className={className}>
      <TextArea
        value={data}
        onChange={handleChange}
        rows={rows}
        className="property-form-textarea"
        $validation={invalidMessage !== 'TRUE' && invalidMessage !== null}
      />
      <ValidationMessage invalidMessage={invalidMessage} />
      {number_remaining_characters ? (
        <div className="property-form-input-text-absolute-area">
          <p
            data-testid="number-remaining-characters"
            className="number-remaining-characters">
            {'残り' + number_remaining_characters + '文字'}
          </p>
        </div>
      ) : (
        ''
      )}
      {maxCharacters ? (
        <div className="property-form-input-text-absolute-area">
          <p className="number-remaining-characters">
            {'残り' + calcRemainCharacters() + '文字'}
          </p>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default FormTextArea;
