import {
  DEPOSIT_FLAG,
  OPEN_WAY,
  PROCEDURE_NO,
  SEARCH_PRICE_HIGH,
  SEARCH_PRICE_LOW,
  SEARCH_STATUS,
} from 'shared/utils/constants/queryParam';
import { validateLessMinimumAmount } from 'shared/utils/helper/serviceValidators';

import BetweenValueGroup from '../PropertyList/BetweenValueGroup';
import CheckBoxGroup from '../PropertyList/CheckBoxGroup';
import RadioButtonGroup from '../PropertyList/RadioButtonGroup';
import SelectedValue from '../PropertyList/SelectedValue';


export const SearchStatusData = [
  {
    id: 1,
    keyName: 'searchStatus',
    label: '参加申込受付中',
    value: 'APPLYING',
  },
  {
    id: 2,
    keyName: 'searchStatus',
    label: '入札期間中',
    value: 'DURING_BIDDING',
  },
];

export const DepositFlag = [
  {
    id: 1,
    keyName: 'depositFlag',
    label: '保証金なし',
    value: 'NODEPOSIT',
  },
  {
    id: 2,
    keyName: 'depositFlag',
    label: '保証金クレカ払いOK',
    value: 'ISONLINE',
  },
];

export const OpenWayData = [
  {
    id: 1,
    label: 'すべて',
    name: 'openWay',
    value: '',
  },
  {
    id: 2,
    label: 'せり売形式',
    name: 'openWay',
    value: 'AUCTION',
  },
  {
    id: 3,
    label: '入札形式',
    name: 'openWay',
    value: 'BID',
  },
];

export const ProcedureNo = [
  {
    id: 1,
    label: 'すべて',
    name: 'procedureNo',
    value: '',
  },
  {
    id: 2,
    label: 'インターネット公売',
    name: 'procedureNo',
    value: 'PUBLIC_SALE',
  },
  {
    id: 3,
    label: '公有財産売却',
    name: 'procedureNo',
    value: 'ASSET_SALE',
  },
];

export const priceToQueryParamValue = {
  指定なし: '',
  '500円': 500,
  '1000円': 1000,
  '5000円': 5000,
  '1万円': 10000,
  '2.5万円': 25000,
  '5万円': 50000,
  '7.5万円': 75000,
  '10万円': 100000,
  '25万円': 200000,
  '50万円': 500000,
  '75万円': 750000,
  '100万円': 1000000,
  '200万円': 2000000,
  '300万円': 3000000,
  '400万円': 4000000,
  '500万円': 5000000,
  '600万円': 6000000,
  '700万円': 7000000,
  '800万円': 8000000,
  '900万円': 9000000,
  '1000万円': 10000000,
  '2000万円': 20000000,
  '3000万円': 30000000,
  '4000万円': 40000000,
  '5000万円': 50000000,
};

export const priceHighToQueryParamValue = {
  指定なし: '',
  '500円': 500,
  '1000円': 1000,
  '5000円': 5000,
  '1万円': 10000,
  '2.5万円': 25000,
  '5万円': 50000,
  '7.5万円': 75000,
  '10万円': 100000,
  '25万円': 200000,
  '50万円': 500000,
  '75万円': 750000,
  '100万円': 1000000,
  '200万円': 2000000,
  '300万円': 3000000,
  '400万円': 4000000,
  '500万円': 5000000,
  '600万円': 6000000,
  '700万円': 7000000,
  '800万円': 8000000,
  '900万円': 9000000,
  '1000万円': 10000000,
  '2000万円': 20000000,
  '3000万円': 30000000,
  '4000万円': 40000000,
  '5000万円': 50000000,
  '6000万円': 60000000,
  '7000万円': 70000000,
  '8000万円': 80000000,
  '9000万円': 90000000,
  '1億円': 100000000,
};

export const searchPrice = [
  {
    id: 1,
    key: SEARCH_PRICE_LOW,
    label: '最低金額',
    items: [
      '指定なし',
      '500円',
      '1000円',
      '5000円',
      '1万円',
      '2.5万円',
      '5万円',
      '7.5万円',
      '10万円',
      '25万円',
      '50万円',
      '75万円',
      '100万円',
      '200万円',
      '300万円',
      '400万円',
      '500万円',
      '600万円',
      '700万円',
      '800万円',
      '900万円',
      '1000万円',
      '2000万円',
      '3000万円',
      '4000万円',
      '5000万円',
    ],
    queryParam: priceToQueryParamValue,
    value: '指定なし',
  },
  {
    id: 2,
    key: SEARCH_PRICE_HIGH,
    label: '最高金額',
    items: [
      '指定なし',
      '500円',
      '1000円',
      '5000円',
      '1万円',
      '2.5万円',
      '5万円',
      '7.5万円',
      '10万円',
      '25万円',
      '50万円',
      '75万円',
      '100万円',
      '200万円',
      '300万円',
      '400万円',
      '500万円',
      '600万円',
      '700万円',
      '800万円',
      '900万円',
      '1000万円',
      '2000万円',
      '3000万円',
      '4000万円',
      '5000万円',
      '6000万円',
      '7000万円',
      '8000万円',
      '9000万円',
      '1億円',
    ],
    queryParam: priceHighToQueryParamValue,
    value: '指定なし',
  },
];

export const searchStatusEnToJp = {
  APPLYING: '申し込み受付中',
  DURING_BIDDING: '入札受付中',
};

export const depositFlagEnToJp = {
  NODEPOSIT: '-',
  ISONLINE: 'クレジットカード',
  BOTH: 'クレジットカードまたは銀行振込など',
};

export const depositFlagNameToQueryParam = {
  保証金なし: 'NODEPOSIT',
  保証金クレカ払いOK: 'ISONLINE',
};

export const openWayEnToJp = {
  AUCTION: 'せり売形式',
  BID: '入札形式',
};

export const openWayNameToQueryParam = {
  すべて: '',
  せり売形式: 'AUCTION',
  入札形式: 'BID',
};

export const procedureNoEnToJp = {
  AUCTION: 'インターネット公売',
  ASSET_SALE: '公有財産売却',
};

export const procedureNoNameToQueryParam = {
  すべて: '',
  インターネット公売: 'PUBLIC_SALE',
  公有財産売却: 'ASSET_SALE',
};

export const searchStatusToQueryParam = {
  参加申込受付中: 'APPLYING',
  入札期間中: 'DURING_BIDDING',
};

export const GeneralSearchConditionsData = [
  {
    id: 1,
    title: '実施状況',
    selectedValue: (
      <SelectedValue
        displayType="commaSplit"
        getQueryParam={SEARCH_STATUS}
        convertTable={searchStatusToQueryParam}
      />
    ),
    body: <CheckBoxGroup data={SearchStatusData} />,
  },
  {
    id: 2,
    title: '保証金',
    selectedValue: (
      <SelectedValue
        displayType="commaSplit"
        getQueryParam={DEPOSIT_FLAG}
        convertTable={depositFlagNameToQueryParam}
      />
    ),
    body: <CheckBoxGroup data={DepositFlag} />,
  },
  {
    id: 3,
    title: '実施方法',
    selectedValue: (
      <SelectedValue
        displayType="normal"
        getQueryParam={OPEN_WAY}
        convertTable={openWayNameToQueryParam}
      />
    ),
    body: <RadioButtonGroup data={OpenWayData} />,
  },
  {
    id: 4,
    title: 'オークション種別',
    selectedValue: (
      <SelectedValue
        displayType="normal"
        getQueryParam={PROCEDURE_NO}
        convertTable={procedureNoNameToQueryParam}
      />
    ),
    body: <RadioButtonGroup data={ProcedureNo} />,
  },
  {
    id: 5,
    title: '金額範囲',
    selectedValue: (
      <SelectedValue
        displayType="between"
        getQueryParam={[SEARCH_PRICE_LOW, SEARCH_PRICE_HIGH]}
        convertTable={priceHighToQueryParamValue}
      />
    ),
    body: (
      <BetweenValueGroup
        data={searchPrice}
        validation={validateLessMinimumAmount}
      />
    ),
  },
];
