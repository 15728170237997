import { ReactComponent as LeftArrowBtn } from 'interfaces/assets/svg/left_arrow.svg';
import { ReactComponent as RightArrowBtn } from 'interfaces/assets/svg/right_arrow.svg';
import { useState, useEffect, useRef } from 'react';

import Thumbnail from './Thumbnail.jsx';

const ThumbnailSlider = ({
  images,
  currentIndex,
  setCurrentIndex,
  handleArrowClick,
}) => {
  const swiperContainerRef = useRef(null);
  const swiperWrapperRef = useRef(null);
  const [initialPosition, setInitialPosition] = useState(0);
  useEffect(() => {
    // slider-containerの初期位置を計算
    const initPosition = () => {
      const initialPosition =
        swiperContainerRef?.current?.offsetWidth / 2 -
        swiperWrapperRef?.current?.children[currentIndex]?.offsetWidth / 2;
      setInitialPosition(initialPosition);
    };
    initPosition();
    window.addEventListener('resize', initPosition);
    return () => window.removeEventListener('resize', initPosition);
  }, []);
  return (
    <div
      data-testid="thumbnail-slider"
      className="pb-4"
      style={{ width: '100%' }}>
      <div
        ref={swiperContainerRef}
        className="swiper-container thumb-swiper swiper-container-initialized swiper-container-horizontal">
        <button
          className="swiper-arrow be--thumb be--prev btn--shadow v-btn v-btn--contained theme--light v-size--default swiper-button-disabled"
          onClick={() => {
            handleArrowClick('left');
          }}
          data-testid="left-arrow-btn">
          <span className="v_btn__content">
            <LeftArrowBtn />
          </span>
        </button>
        <div
          data-testid="slider-container"
          ref={swiperWrapperRef}
          className="swiper-wrapper"
          style={{
            transitionDuration: '0ms',
            transform: `translate3d(${initialPosition - 100 * currentIndex}px, 0px, 0px)`,
          }}>
          {images.map((data, index) => (
            <Thumbnail
              key={data.id}
              url={data.image_url}
              isActive={index === currentIndex}
              clickHandler={() => setCurrentIndex(index)}
            />
          ))}
        </div>
        <button
          className="swiper-arrow be--thumb be--next btn--shadow v-btn v-btn--contained theme--light v-size--default swiper-button-disabled"
          onClick={() => {
            handleArrowClick('right');
          }}
          data-testid="right-arrow-btn">
          <span className="v_btn__content">
            <RightArrowBtn />
          </span>
        </button>
        <span
          className="swiper-notification"
          aria-live="assertive"
          aria-atomic="true"></span>
      </div>
    </div>
  );
};
export default ThumbnailSlider;
