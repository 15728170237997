import { HYPHEN_SYMBOL } from 'domain/Property/entities/constants/property';
const WHITE_SPACE_SYMBOL = ' ';

export const joinArrayWhiteSpace = (input) => {
  if (!Array.isArray(input)) {
    return HYPHEN_SYMBOL;
  }
  const inputWithoutFaultyVal = input.filter((item) => item);
  if (inputWithoutFaultyVal.length === 0) {
    return HYPHEN_SYMBOL;
  }
  return inputWithoutFaultyVal.join(WHITE_SPACE_SYMBOL);
};
