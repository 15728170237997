
export class ImportantNonConformingContractsLiability {
  #importantNonConformingContractsLiability

  constructor(importantNonConformingContractsLiability) {
    this.#importantNonConformingContractsLiability = importantNonConformingContractsLiability;
  }

  get() {
    return this.#importantNonConformingContractsLiability
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
