import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class CostCarDisplay {
  #costCarDisplay

  constructor(costCarDisplay) {
    this.#costCarDisplay = costCarDisplay;
  }

  get() {
    return this.#costCarDisplay
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#costCarDisplay, 1, 500)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#costCarDisplay)
  }
}
