import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import ConfirmShippingInfo from './ConfirmShippingInfo/ConfirmShippingInfo.jsx';
import PointsToNoteAgreement from './PointsToNoteAgreement/PointsToNoteAgreement.jsx';
import { initAddress, initCarrier, initDeliverySchedule, initPaymentData } from './ShippingInfoRegistration/ShippingInfoForm.jsx';
import ShippingInfoRegistration from './ShippingInfoRegistration/ShippingInfoRegistration.jsx';
import ShippingInfoRegistrationComplete from './ShippingInfoRegistrationComplete/ShippingInfoRegistrationComplete.jsx';

export const initShippingInfo = {
  carrier: initCarrier,  // 1. 運送業者の選択
  targetArrangement: '',  // 2. 運送業者の手配について
  targetPackingMethod: '',  // 3. 梱包について
  address: initAddress,  // 4. お届け先住所
  paymentData: initPaymentData,  // 5. 買受代金納付予定日
  deliverySchedule: initDeliverySchedule,  // 6. 配送希望日時
  deliveryRequest: '',  // 7. ご要望欄
};

export const initFormattedInfo = {
  carrierNameList: '', // 運送行者(YAMATO | SAGAWA | JAPAN_POST | OTHER)
  carrierName: '', // 運送会社名(OTHER以外の場合はDATA_EMPTY)
  carrierTel: '', // 運送会社電話番号(OTHER以外の場合はDATA_EMPTY)
  arrangement: '', // 運送業者手配のステータス(REQUEST |MYSELF)
  packing: '', // 梱包のステータス(REQUEST | MYSELF)
  receiver: '', // お届け先住所ステータス(CLIENT | OTHER)
  receiverName: '', // 氏名(依頼人名 | 委任者名)
  receiverRole: '', // 受取人のロール(CLIENT | DELEGATOR)
  zipCode: '', // 郵便番号(前後をハイフンで結合する)
  receiverGovernmentSubCategoryId: null, // 都道府県名id(カラム名に合わせている)
  receiverAddress: '', // 市区町村番地
  receiverTel: '', // 電話番号
  deliveryReason: '', // 変更理由
  isPaid: '', // 買受代金納付ステータス(PAID | NO_PAID)
  paymentScheduledDate: '', // 買受代金納付予定日
  deliverySchedule: '', // 配送希望ステータス(NO_SPECIFY | SPECIFY)
  deliveryDate: '', // 配送希望日
  deliveryTime: '', // 配送希望時間
  deliveryText: '' // ご要望
};

const ShippingStepComponent = ({ step, previousStep, nextStep, data }) => {
  const { id } = useParams();
  const [shippingInfo, setShippingInfo] = useState(initShippingInfo);
  const [formattedInfo, setFormattedInfo] = useState(initFormattedInfo);

  window.scrollTo(0, 0);

  if (step === 1) {
    return <PointsToNoteAgreement
      previousStep={previousStep}
      nextStep={nextStep}
    />;
  }
  if (step === 2) {
    return <ShippingInfoRegistration
      data={data}
      previousStep={previousStep}
      nextStep={nextStep}
      shippingInfo={shippingInfo}
      setShippingInfo={setShippingInfo}
    />;
  }
  if (step === 3) {
    return <ConfirmShippingInfo
      previousStep={previousStep}
      nextStep={nextStep}
      shippingInfo={shippingInfo}
      formattedInfo={formattedInfo}
      setFormattedInfo={setFormattedInfo}
    />;
  }
  if (step === 4) {
    return <ShippingInfoRegistrationComplete
      data={data}
      shippingInfo={formattedInfo}
      step={step}
    />;
  }
  return <Navigate replace to={`/items/${id}`}/>;
};

export default ShippingStepComponent;
