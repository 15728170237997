import Button from "interfaces/components/common/Button/Button";
import Modal2 from "interfaces/components/common/Modal/Modal2";
import TextCounter from "interfaces/components/common/TextCounter";
import { useError } from "shared/hooks/useError";
import useTextCounter from "shared/hooks/useTextCounter";

import BlockTargetUserInfo from "./BlockTargetUserInfo";

import 'interfaces/css/ApplicantList/blocklist-register-modal.css'

const BlockListRegisterModal = ({ isOpen, closeModal, label, propertyName, maxTextLimit, userData }) => {
  const { text, count, isOverCount, isWithinLimit, changeHandler } = useTextCounter(maxTextLimit);
  const { setError } = useError();

  const submitHandler = (e) => {
    e.preventDefault();

    if (!isWithinLimit(text, maxTextLimit)) {
      return;
    }

    try {
      // TODO: CreateのAPIを叩く
      console.log('ok');
    } catch (e) {
      setError(e);
    }
  };

  return (
    <Modal2
      isOpen={isOpen}
      handleClose={closeModal}
      modalSize={'M'}
    >
      <div className="blocklist-register-modal-content">
        <div className="blocklist-register-modal-header-label" data-testid="blocklist-register-modal-header-label"><p
          className="mb-4">{label}</p></div>

        <div className="blocklist-register-modal-body">
          <div data-testid="confirmation-text-wrap" className="confirmation-text-wrap">
            <p>以下の申し込み者を<span className="emphasize">ブロックリスト登録</span>してもよろしいですか？</p>
            <p className="mt-4">
              {propertyName}<br/>
              に申し込みがあります。
            </p>
          </div>

          <form id="memo-register-form" className="memo-register-form" onSubmit={submitHandler} data-testid="memo-register-form">
            <label htmlFor="memo-register-input" className="memo-register-form-label">メモ</label>
            <div className="memo-register-form-body">
              <input
                id="memo-register-input"
                value={text}
                onChange={changeHandler}
                className="memo-register-input"
              />
              <TextCounter count={count} isOverCount={isOverCount}/>
            </div>
          </form>
          <BlockTargetUserInfo userData={userData}/>
        </div>

        <div className="blocklist-register-modal-button-wrapper">
          <Button
            text='いいえ'
            className="common-button-bg-whitesmoke"
            onClick={closeModal}
          />
          <Button
            form='memo-register-form'
            type='submit'
            text='はい'
            className="ml-2"
            isDisabled={isOverCount}
          />
        </div>
      </div>
    </Modal2>
  );
}
export default BlockListRegisterModal;
// import configs from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/configs/CAMEL_TABLE_NAME";
// import { allFetch } from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/newEntry";
//
// const create = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.create);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const update = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.update);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const destroy = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.destroy);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }

// import configs from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/configs/CAMEL_TABLE_NAME";
// import { allFetch } from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/newEntry";
//
// const create = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.create);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const update = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.update);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const destroy = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.destroy);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
