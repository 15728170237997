// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.published-object-status-description {
  margin-bottom: 0;
  font-size: 14px;
}

.published-object-status .sort-toolbar-inner {
  flex-wrap: wrap;
}
.published-object-status .sort-toolbar-inner .group {
  margin-left: 10px;
}
.published-object-status .property-list .property-list-table table > tbody > tr > td.object-padding {
  color: rgba(0,0,0,.87);
  padding: 0 16px;
  word-break: break-all;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PublishedObjectStatus/published-object-status.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,sBAAsB;EACtB,eAAe;EACf,qBAAqB;AACvB","sourcesContent":[".published-object-status-description {\n  margin-bottom: 0;\n  font-size: 14px;\n}\n\n.published-object-status .sort-toolbar-inner {\n  flex-wrap: wrap;\n}\n.published-object-status .sort-toolbar-inner .group {\n  margin-left: 10px;\n}\n.published-object-status .property-list .property-list-table table > tbody > tr > td.object-padding {\n  color: rgba(0,0,0,.87);\n  padding: 0 16px;\n  word-break: break-all;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
