import naviFooter from "interfaces/css/Footer/footer-nav.module.css"

const FooterNav = () => {
  return (
    <ul className={naviFooter.footer_nav}>
      <li className={naviFooter.footer_nav_item}>
          <a href="https://www.pages.kankocho.jp/privacy" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>
      </li>
      <li className={naviFooter.footer_nav_item}>
          <a href="https://help.kankocho.jp/knowledge" target="_blank" rel="noopener noreferrer">官公庁オークションヘルプ</a>
      </li>
      <li className={naviFooter.footer_nav_item}>
          <a href="https://sites.kankocho.jp/contact-gov" target="_blank" rel="noopener noreferrer">お問い合わせ</a>
      </li>
    </ul>
  );
}

export default FooterNav;