
export class BiddedPrice {
    #biddedPrice

    constructor(biddedPrice) {
        this.#biddedPrice = biddedPrice;
    }

    get() {
        return this.#biddedPrice
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
