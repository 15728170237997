// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-list .input-information-table {
    display: table;
    width: 100%;
    margin-top: 20px;
}

.property-list .input-information-table .table-row {
    display: table-row;
}

.property-list .input-information-table .table-row .thead,
.property-list .input-information-table .table-row .tbody {
    padding: 12px;
    display: table-cell;
    border-bottom: 1px solid #ccc;
}

.property-list .input-information-table .table-row .thead {
    width: 30%;
    max-width: 300px;
    min-width: 200px;
    background-color: var(--secondary-base);
    font-weight: 700;
    vertical-align: middle;
}

.property-list .input-information-table .table-row .tbody {
    width: 70%;
    background-color: var(--heading);
    vertical-align: middle;
}

.property-list .input-information-table .table-row .tbody .property-list-search-input {
    display: inline-block;
}

.property-list .input-information-table .table-row .tbody .property-list-selector {
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertyList/input-infomation-table.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;;IAEI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,uCAAuC;IACvC,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,gCAAgC;IAChC,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".property-list .input-information-table {\n    display: table;\n    width: 100%;\n    margin-top: 20px;\n}\n\n.property-list .input-information-table .table-row {\n    display: table-row;\n}\n\n.property-list .input-information-table .table-row .thead,\n.property-list .input-information-table .table-row .tbody {\n    padding: 12px;\n    display: table-cell;\n    border-bottom: 1px solid #ccc;\n}\n\n.property-list .input-information-table .table-row .thead {\n    width: 30%;\n    max-width: 300px;\n    min-width: 200px;\n    background-color: var(--secondary-base);\n    font-weight: 700;\n    vertical-align: middle;\n}\n\n.property-list .input-information-table .table-row .tbody {\n    width: 70%;\n    background-color: var(--heading);\n    vertical-align: middle;\n}\n\n.property-list .input-information-table .table-row .tbody .property-list-search-input {\n    display: inline-block;\n}\n\n.property-list .input-information-table .table-row .tbody .property-list-selector {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
