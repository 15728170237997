// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.government-filter {
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  width: 100%;
  z-index: 1;
  flex: 1 0 100%;
  max-width: 100%;
  position: relative;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/government-filter.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,qBAAqB;EACrB,UAAU;EACV,WAAW;EACX,UAAU;EACV,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,yCAAyC;AAC3C","sourcesContent":[".government-filter {\n  border-radius: 4px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  list-style-type: none;\n  padding: 0;\n  width: 100%;\n  z-index: 1;\n  flex: 1 0 100%;\n  max-width: 100%;\n  position: relative;\n  transition: .3s cubic-bezier(.25,.8,.5,1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
