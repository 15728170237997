export const UrlLinks = {
  // ヘルプトップ
  KNOWLEDGE_URL: 'https://help.kankocho.jp/knowledge',
  // 初めての方へ
  BEGINNERS_URL: 'https://www.pages.kankocho.jp/guide',
  // ガイドライン
  GUIDE_URL: 'https://www.pages.kankocho.jp/guide',
  // よくある質問
  FAQ_URL:
    'https://help.kankocho.jp/knowledge/%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F',
  // ご利用の流れ
  HOW_TO_URL: 'https://help.kankocho.jp/knowledge/help004',
  // KSI官公庁オークション利用についてのお問い合わせ
  HOW_TO_USE_URL: 'https://help.kankocho.jp/knowledge/contact',
  // 参加申し込みの方法
  HOW_TO_PARTICIPATE_URL: 'https://help.kankocho.jp/knowledge/help015',
  // 用語集
  GLOSSARY_URL: 'https://help.kankocho.jp/knowledge/glossary',
  // 行政機関のガイドラインについて
  GOV_AGENCY_GUIDLINE_URL: 'https://help.kankocho.jp/knowledge/help006',
  // 参加申し込みについて
  ABOUT_PARTICIPATE_URL:
    'https://help.kankocho.jp/knowledge/%E5%8F%82%E5%8A%A0%E7%94%B3%E3%81%97%E8%BE%BC%E3%81%BF%E3%81%99%E3%82%8B',
  // 入札について
  ABOUT_BID_URL:
    'https://help.kankocho.jp/knowledge/%E5%85%A5%E6%9C%AD%E3%81%99%E3%82%8B',
  // 入札後について
  BID_DEADLINE_URL:
    'https://help.kankocho.jp/knowledge/%E8%90%BD%E6%9C%AD-%E7%89%A9%E4%BB%B6%E3%82%92%E5%8F%97%E3%81%91%E5%8F%96%E3%82%8B',
  // 追加入札とは
  ABOUT_ADD_BID_URL:
    'https://help.kankocho.jp/knowledge/%E8%BF%BD%E5%8A%A0%E5%85%A5%E6%9C%AD%E3%81%A8%E3%81%AF',
  // 仮申し込みとは
  ABOUT_TRIAL_APPL_URL: 'https://help.kankocho.jp/knowledge/help017',
  // 物件の中止とは
  ABOUT_CANCEL_URL: 'https://help.kankocho.jp/knowledge/help040',
  // 運営会社
  COMPANY_URL: 'https://www.pages.kankocho.jp/company',
  // 特定商取引法の表示
  TOKUSHOHO_URL: 'https://www.pages.kankocho.jp/tokushoho',
  // プライバシーポリシー
  PRIVACY_POLICY_URL: 'https://www.pages.kankocho.jp/privacy',
  // 利用規約
  TERM_URL: 'https://www.pages.kankocho.jp/terms',
  // KSI ホームページ
  KSI_HP_URL: 'https://ksi-corp.jp/',
  // 株式会社ロックミー ホームページ
  ROCK_ME_HP_LINK: 'https://rockme.co.jp/',
  // 株式会社デザイニウム ホームページ
  DESIGNIUM_HP_LINK: 'https://www.thedesignium.com/',
};
