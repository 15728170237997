import Button from 'interfaces/components/common/Button';
import ButtonList from 'interfaces/components/common/ButtonList';
import Pagination from 'interfaces/components/common/Pagination';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSortableData } from 'shared/hooks/useSort.js';
import usePagination from 'shared/hooks/usePagination';
import { useState } from 'react';

import KmanagerAuctionTableColumn from './KmanagerAuctionTableColumn';
import KmanagerAuctionTableRecord from './KmanagerAuctionTableRecord';

import 'interfaces/css/common/kmanager-table.css';
import 'interfaces/css/common/kmanager-table-th.css';
import 'interfaces/css/common/kmanager-table-record.css';

const KmanagerAuctionTable = ({ auctions }) => {
  const {
    items: sortedAuctions,
    requestSort,
    sortConfig,
  } = useSortableData(auctions);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(sortedAuctions, rowsPerPage);

  const navigate = useNavigate();

  const [visibleRow, setVisibleRow] = useState(null);

  const toggleRowVisibility = (id) => {
    setVisibleRow((prev) => (prev === id ? null : id));
  };

  // TODO: 設定状況確認、行政機関管理、公売(入金)保証金管理、システム利用料管理,統計出力の画面遷移をするように実装する
  function governmentSetting(id) {
    navigate(`/kmanager/auction/${id}/gov_setting`);
  }

  function auctionGovernment(id) {
    navigate(`/kmanager/auction/${id}/gov`);
  }

  function deposit(id) {
    navigate(`/kmanager/auction/${id}/deposit`);
  }

  function usageFee(id) {
    navigate(`/kmanager/auction/${id}/usage_fee`);
  }

  const downloadStatistics = () => {
    console.log('統計出力');
  };

  const CONTROL_BUTTONS_DATA = [
    {
      text: '設定状況確認',
      clickHandler: (id) => governmentSetting(id),
      isOutline: true,
      className: 'small primary',
    },
    {
      text: '行政機関管理',
      clickHandler: (id) => auctionGovernment(id),
      isOutline: true,
      className: 'small primary',
    },
    {
      text: '公売(入金)保証金管理',
      clickHandler: (id) => deposit(id),
      isOutline: true,
      className: 'small primary',
    },
    {
      text: 'システム利用料管理',
      clickHandler: (id) => usageFee(id),
      isOutline: true,
      className: 'small primary',
    },
    {
      text: '統計出力',
      clickHandler: downloadStatistics,
      isOutline: true,
      className: 'small primary',
    },
  ];
  const NATIONAL_TAX_AGENCY_BUTTONS_DATA = [
    {
      text: '設定状況確認',
      clickHandler: (id) => governmentSetting(id),
      isOutline: true,
      className: 'small primary',
    },
    {
      text: '行政機関管理',
      clickHandler: (id) => auctionGovernment(id),
      isOutline: true,
      className: 'small primary',
    },
    {
      text: 'システム利用料管理',
      clickHandler: (id) => usageFee(id),
      isOutline: true,
      className: 'small primary',
    },
    {
      text: '統計出力',
      clickHandler: downloadStatistics,
      isOutline: true,
      className: 'small primary',
    },
  ];

  const headers = [
    { id: 1, column: '', text: '操作など' },
    { id: 2, column: 'name', text: '開催名' },
    { id: 3, column: 'procedureType', text: '手続き種別' },
    { id: 4, column: 'publicSaleType', text: '公売方法' },
    { id: 5, column: 'governmentTypeGroup', text: '行政機関種別' },
    { id: 6, column: 'aplStart', text: '一般参加申込開始日時' },
    { id: 7, column: 'aplEnd', text: '参加申込締切日時' },
    { id: 8, column: 'bidStart', text: '入札開始日時' },
    { id: 9, column: 'auctionEnd', text: '入札終了日時' },
    { id: 10, column: 'openEnd', text: '公開終了日' },
    { id: 11, column: 'edit', text: '編集' },
  ];

  return (
    <div className="kmanager-auction-operation">
      <div className="kmanager-table-wrap">
        <table
          className="kmanager-table kmanager-table-hover"
          data-testid="kmanager-auction-table">
          <KmanagerAuctionTableColumn
            headers={headers}
            sortConfig={sortConfig}
            requestSort={requestSort}
          />
          <tbody>
            {sortedAuctions &&
              currentItems.map((auction) => (
                <Fragment key={auction.id}>
                  <KmanagerAuctionTableRecord
                    data={auction}
                    onClick={() => toggleRowVisibility(auction.id)}
                    isVisibleRow={visibleRow === auction.id}
                  />
                  {visibleRow === auction.id && (
                    <tr className="kmanager-table-record">
                      <td colSpan="11" className="kmanager-table-td">
                        <ButtonList>
                          <div className="d-flex">
                            {auction.governmentTypeGroup ===
                            'NATIONAL_TAX_AGENCY'
                              ? NATIONAL_TAX_AGENCY_BUTTONS_DATA.map(
                                  (item, index) => (
                                    <Button
                                      key={index}
                                      text={item.text}
                                      clickHandler={() =>
                                        item.clickHandler(auction.id)
                                      }
                                      isOutline={item.isOutline}
                                      isDisabled={false}
                                      className={item.className}
                                    />
                                  ),
                                )
                              : CONTROL_BUTTONS_DATA.map((item, index) => (
                                  <Button
                                    key={index}
                                    text={item.text}
                                    clickHandler={() =>
                                      item.clickHandler(auction.id)
                                    }
                                    isOutline={item.isOutline}
                                    isDisabled={false}
                                    className={item.className}
                                  />
                                ))}
                          </div>
                        </ButtonList>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        DATA_COUNT={auctions.length}
      />
    </div>
  );
};

export default KmanagerAuctionTable;
