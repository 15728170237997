import { PrefectureToJP } from 'shared/utils/helper/convert/prefectureToJp.js';

export const OrganizationInfo = (data) => {
  console.log(data.useCreditCard);
  return [
    {
      text: '執行機関名',
      state: data.divisionName !== '' ? data.divisionName : '未入力',
    },
    {
      text: '執行機関名（カナ）',
      state: data.divisionNameKana !== '' ? data.divisionNameKana : '未入力',
    },
    {
      text: '担当者名',
      state: data.contactName !== '' ? data.contactName : '未入力',
    },
    {
      text: '電話番号',
      state: [
        data.contactTel?.telNum1?.toString(),
        data?.contactTel?.telNum2?.toString(),
        data?.contactTel?.telNum3?.toString(),
      ].join('-'),
    },
    {
      text: '担当者メールアドレス',
      state: data.contactEmail !== '' ? data.contactEmail : '未入力',
    },
    {
      text: '保証金の納付にクレジットカードを利用する',
      state: data.useCreditCard === 'YES' ? '利用する' : '利用しない',
    },
  ];
};

export const CreditInfo = (data) => [
  {
    text: '金融機関名',
    state:
      data?.financialInstitutionName !== ''
        ? data.financialInstitutionName
        : '未入力',
  },
  {
    text: '支店名',
    state: data?.branchName !== '' ? data.branchName : '未入力',
  },
  {
    text: '口座種別',
    state: data?.accountType !== '' ? data.accountType : '未入力',
  },
  {
    text: '口座番号',
    state: data?.accountNumber !== '' ? data.accountNumber : '未入力',
  },
  {
    text: '口座名義',
    state: data?.accountName !== '' ? data.accountName : '未入力',
  },
  {
    text: '口座名義（カナ）',
    state: data?.accountNameKana !== '' ? data.accountNameKana : '未入力',
  },
];

export const RefundInfo = (data) => {
  return [
    {
      text: '郵便番号',
      state: [
        data?.postalCode?.postalCode1?.toString(),
        data?.postalCode?.postalCode2?.toString(),
      ].join('-'),
    },
    {
      text: '都道府県',
      state:
        data?.prefectureId !== ''
          ? PrefectureToJP(data.prefectureId)
          : '未入力',
    },
    {
      text: '市区町村',
      state: data?.city !== '' ? data.city : '未入力',
    },
    {
      text: '住所（続き）',
      state: data?.address !== '' ? data.address : '未入力',
    },
    {
      text: '建物名',
      state: data?.buildName !== '' ? data.buildName : '未入力',
    },
    {
      text: 'あて先',
      state: data?.destination !== '' ? data.destination : '未入力',
    },
    {
      text: '書面あて名',
      state:
        data?.writtenDestination !== '' ? data.writtenDestination : '未入力',
    },
  ];
};
