import { useEffect, useState } from 'react';

import { AuctionFooterContext } from '../entities/AuctionFooterContext.js';
import { get } from 'domain/Category/services/PropertyCategory.js';

const AuctionFooterProvider = ({ children }) => {
  const [realEstateLinkItems, setRealEstateLinkItems] = useState([]);
  const [carLinkItems, setCarLinkItems] = useState([]);
  const [generalLinkItems, setGeneralLinkItems] = useState([]);

  useEffect(() => {
    get('SERVICE').then((propertyCategories) => {
      // 不動産
      let realEstateCategory = propertyCategories.find(
        (propertyCategory) => propertyCategory.name === '不 動 産',
      );

      let realEstateLinkItems = {};
      ['マンション', '土地', '一戸建て', 'その他'].forEach((category) => {
        let propertyCategory = realEstateCategory.childCategory.find(
          (cat) => cat.name === category,
        );
        realEstateLinkItems[category] =
          `/search?category=ESTATE&searchPropertyCategoryId=${propertyCategory.relationshipCategoryIds.join(',')}`;
      });
      setRealEstateLinkItems(realEstateLinkItems);

      // 自動車
      let carCategory = propertyCategories.find(
        (propertyCategory) => propertyCategory.name === '自動車、オートバイ',
      );

      let carLinkItems = {};
      ['救急車', '消防車', 'バス', 'その他'].forEach((category) => {
        if (category === 'その他') {
          let propertyCategories = carCategory.childCategory.filter((cat) =>
            ['キャンピングカー', '部品取り車'].includes(cat.name),
          );
          let ids = propertyCategories.map((cat) =>
            cat.relationshipCategoryIds.join(','),
          );
          carLinkItems[category] =
            `/search?category=CAR&searchPropertyCategoryId=${ids.join(',')}`;
        } else {
          let propertyCategory = carCategory.childCategory.find(
            (cat) => cat.name === category,
          );
          carLinkItems[category] =
            `/search?category=CAR&searchPropertyCategoryId=${propertyCategory.relationshipCategoryIds.join(',')}`;
        }
      });
      setCarLinkItems(carLinkItems);

      // 人気のカテゴリ
      let generalLinkItems = {};
      [
        'コンピュータ',
        '家電、AV、カメラ',
        '音楽',
        '本、雑誌',
        '映画、ビデオ',
      ].forEach((category) => {
        const propertyCategory = propertyCategories.find(
          (propertyCategory) => propertyCategory.name === category,
        );
        generalLinkItems[category] =
          `/search?searchPropertyCategoryId=${propertyCategory.relationshipCategoryIds.join(',')}`;
      });
      setGeneralLinkItems(generalLinkItems);
    });
  }, []);

  return (
    <AuctionFooterContext.Provider
      value={{ realEstateLinkItems, carLinkItems, generalLinkItems }}>
      {children}
    </AuctionFooterContext.Provider>
  );
};

export default AuctionFooterProvider;
