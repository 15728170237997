
export class RearWeight {
    #rearWeight

    constructor(rearWeight) {
        this.#rearWeight = rearWeight;
    }

    get() {
        return this.#rearWeight
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
