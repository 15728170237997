import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_BIDDER_HISTORY = `${BASE_URL_NAVIGATION}/storage/current/bidder_histories/data.json`;
const PREVIOUS_BIDDER_HISTORY = `${BASE_URL_NAVIGATION}/storage/previous/bidder_histories/data.json`;

export const fetchBidderHistoryStorage = async () => {
  try {
    const response = await fetchCurrentBidderHistory();
    return extractBidderHistory(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ bidder_history ）を取得できませんでした");

    try {
      const response = await fetchPreviousBidderHistory();
      return extractBidderHistory(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ bidder_history ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchCurrentBidderHistory = async () => {
  return fetchBidderHistory(CURRENT_BIDDER_HISTORY);
};

export const fetchPreviousBidderHistory = async () => {
  return fetchBidderHistory(PREVIOUS_BIDDER_HISTORY);
};

export const fetchBidderHistory = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractBidderHistory = (response) => {
  return response.map(bidder_history => bidder_history);
}