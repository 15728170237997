import { useState } from 'react';
import * as Validation from 'shared/utils/helper/managerValidators';

export const useAuctionInputsValidation = (isEdit) => {
  const [nameValid, setNameValid] = useState(isEdit ? 'TRUE' : null);
  const [govAplStartValid, setGovAplStartValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [govAplEndValid, setGovAplEndValid] = useState(isEdit ? 'TRUE' : null);
  const [aplStartValid, setAplStartValid] = useState(isEdit ? 'TRUE' : null);
  const [aplEndValid, setAplEndValid] = useState(isEdit ? 'TRUE' : null);
  const [bidStartValid, setBidStartValid] = useState(isEdit ? 'TRUE' : null);
  const [auctionEndValid, setAuctionEndValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [bidEndValid, setBidEndValid] = useState(isEdit ? 'TRUE' : null);
  const [addBidDurationValid, setAddBidDurationValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [bidDecisionValid, setBidDecisionValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [openEndValid, setOpenEndValid] = useState(isEdit ? 'TRUE' : null);
  const [nextPaymentLimitValid, setNextPaymentLimitValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [estateNextPaymentLimitValid, setEstateNextPaymentLimitValid] =
    useState(isEdit ? 'TRUE' : null);
  const [depositTypeValid, setDepositTypeValid] = useState(
    isEdit ? 'TRUE' : null,
  );

  function handleAuctionNameValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRangeEventLength(e.target.value);
    if (invalid !== 'TRUE') {
      setNameValid(invalid);
      return;
    }
    invalid = Validation.validateIncludeEmojiEventName(e.target.value);
    if (invalid !== 'TRUE') {
      setNameValid(invalid);
      return;
    }
    setNameValid(invalid); // エラーメッセージのリセット
  }

  function handleGovAplStartValid(datetime) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateSelectGovAplDate(datetime);
    if (invalid !== 'TRUE') {
      setGovAplStartValid(invalid);
      return;
    }
    invalid = Validation.validateSetGovAplAfterCurrentTime(datetime);
    if (invalid !== 'TRUE') {
      setGovAplStartValid(invalid);
      return;
    }
    setGovAplStartValid(invalid); // エラーメッセージのリセット
  }

  function handleGovAplEndValid(datetime, govAplStart) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateSelectGovAplEnd(datetime);
    if (invalid !== 'TRUE') {
      setGovAplEndValid(invalid);
      return;
    }
    invalid = Validation.validateSetAfterGovApl(datetime, govAplStart);
    if (invalid !== 'TRUE') {
      setGovAplEndValid(invalid);
      return;
    }
    setGovAplEndValid(invalid); // エラーメッセージのリセット
  }

  function handleAplStartValid(datetime) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateSelectAplStartDate(datetime);
    if (invalid !== 'TRUE') {
      setAplStartValid(invalid);
      return;
    }
    setAplStartValid(invalid); // エラーメッセージのリセット
  }

  function handleAplEndValid(datetime, aplStart) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateSelectAplEnd(datetime);
    if (invalid !== 'TRUE') {
      setAplEndValid(invalid);
      return;
    }
    invalid = Validation.validateSetAplEndAfterAplStart(datetime, aplStart);
    if (invalid !== 'TRUE') {
      setAplEndValid(invalid);
      return;
    }
    setAplEndValid(invalid); // エラーメッセージのリセット
  }

  function handleBidStartValid(datetime, aplEnd) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateSelectAuctionStart(datetime);
    if (invalid !== 'TRUE') {
      setBidStartValid(invalid);
      return;
    }
    invalid = Validation.validateSetAuctionStartAfterAplEnd(datetime, aplEnd);
    if (invalid !== 'TRUE') {
      setBidStartValid(invalid);
      return;
    }
    setBidStartValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionEndValid(datetime, auctionStart) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateSelectAuctionEnd(datetime);
    if (invalid !== 'TRUE') {
      setAuctionEndValid(invalid);
      return;
    }
    invalid = Validation.validateSetAuctionEndAfterAuctionStart(
      datetime,
      auctionStart,
    );
    if (invalid !== 'TRUE') {
      setAuctionEndValid(invalid);
      return;
    }
    setAuctionEndValid(invalid); // エラーメッセージのリセット
  }

  function handleBidEndValid(datetime, auctionStart) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateSelectBidEnd(datetime);
    if (invalid !== 'TRUE') {
      setBidEndValid(invalid);
      return;
    }
    invalid = Validation.validateSetBidEndAfterAuctionStart(
      datetime,
      auctionStart,
    );
    if (invalid !== 'TRUE') {
      setBidEndValid(invalid);
      return;
    }
    setBidEndValid(invalid); // エラーメッセージのリセット
  }

  function handleAddBidDurationValid(datetime) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateSetAdditionalTime(datetime);
    if (invalid !== 'TRUE') {
      setAddBidDurationValid(invalid);
      return;
    }
    setAddBidDurationValid(invalid); // エラーメッセージのリセット
  }

  function handleBidDecisionValid(datetime, auctionEnd, bidEnd) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateSelectDeciDate(datetime);
    if (invalid !== 'TRUE') {
      setBidDecisionValid(invalid);
      return;
    }
    if (auctionEnd) {
      invalid = Validation.validateSetDeciDateAfterBidEnd(datetime, auctionEnd);
      if (invalid !== 'TRUE') {
        setBidDecisionValid(invalid);
        return;
      }
    }
    if (bidEnd) {
      invalid = Validation.validateSetDeciDateAfterBidEnd(datetime, bidEnd);
      if (invalid !== 'TRUE') {
        setBidDecisionValid(invalid);
        return;
      }
    }
    setBidDecisionValid(invalid); // エラーメッセージのリセット
  }

  function handleOpenEndValid(datetime, auctionEnd, bidEnd) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateSelectOpenEnd(datetime);
    if (invalid !== 'TRUE') {
      setOpenEndValid(invalid);
      return;
    }
    if (auctionEnd) {
      invalid = Validation.validateSetOpenEndAfterBidEnd(datetime, auctionEnd);
      if (invalid !== 'TRUE') {
        setOpenEndValid(invalid);
        return;
      }
    }
    if (bidEnd) {
      invalid = Validation.validateSetOpenEndAfterBidEnd(datetime, bidEnd);
      if (invalid !== 'TRUE') {
        setOpenEndValid(invalid);
        return;
      }
    }
    setOpenEndValid(invalid); // エラーメッセージのリセット
  }

  function handleNextPaymentLimitValid(datetime, decisionDate) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateSetDeadline(datetime);
    if (invalid !== 'TRUE') {
      setNextPaymentLimitValid(invalid);
      return;
    }
    invalid = Validation.validateSetDeadlineAfterDeciDate(
      datetime,
      decisionDate,
    );
    if (invalid !== 'TRUE') {
      setNextPaymentLimitValid(invalid);
      return;
    }
    setNextPaymentLimitValid(invalid); // エラーメッセージのリセット
  }

  function handleEstateNextPaymentLimitValid(datetime, decisionDate) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateSetDeadline2(datetime);
    if (invalid !== 'TRUE') {
      setEstateNextPaymentLimitValid(invalid);
      return;
    }
    invalid = Validation.validateSetDeadlineAfterDeciDate2(
      datetime,
      decisionDate,
    );
    if (invalid !== 'TRUE') {
      setEstateNextPaymentLimitValid(invalid);
      return;
    }
    setEstateNextPaymentLimitValid(invalid); // エラーメッセージのリセット
  }

  function handleDepositTypeValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateSelectHandlingDeposit(e.target.value);
    if (invalid !== 'TRUE') {
      setDepositTypeValid(invalid);
      return;
    }
    setDepositTypeValid(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      name: handleAuctionNameValid,
      govAplStart: handleGovAplStartValid,
      govAplEnd: handleGovAplEndValid,
      aplStart: handleAplStartValid,
      aplEnd: handleAplEndValid,
      bidStart: handleBidStartValid,
      auctionEnd: handleAuctionEndValid,
      bidEnd: handleBidEndValid,
      addBidDuration: handleAddBidDurationValid,
      bidDecision: handleBidDecisionValid,
      openEnd: handleOpenEndValid,
      nextPaymentLimit: handleNextPaymentLimitValid,
      estateNextPaymentLimit: handleEstateNextPaymentLimitValid,
      depositType: handleDepositTypeValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      name: nameValid,
      govAplStart: govAplStartValid,
      govAplEnd: govAplEndValid,
      aplStart: aplStartValid,
      aplEnd: aplEndValid,
      bidStart: bidStartValid,
      auctionEnd: auctionEndValid,
      bidEnd: bidEndValid,
      addBidDuration: addBidDurationValid,
      bidDecision: bidDecisionValid,
      openEnd: openEndValid,
      nextPaymentLimit: nextPaymentLimitValid,
      estateNextPaymentLimit: estateNextPaymentLimitValid,
      depositType: depositTypeValid,
    },
  };

  return validations;
};
