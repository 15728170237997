import "./css/horizontal-pair-links.css.css"
const HorizontalPairLinks = ({data}) => {
  return (
    <div data-testid="horizontal-pair-links" className="flex justify-center align-center">
      {
        data &&
        <a data-testid="left-link" href={data.left_link.link} className="box mb-0 btn btn-flat btn-text theme-light size-default anchor-text">
          <span className="btn-content">{data.left_link.label}</span>
        </a>
      }
      <span className="box">｜</span>
      {
        data &&
        <a data-testid="right-link" href={data.right_link.link} className="box mb-0 btn btn-flat btn-router btn-text theme-light size-default anchor-text">
          <span className="btn-content">{data.right_link.label}</span>
        </a>
      }
    </div>
  );
}

export default HorizontalPairLinks;