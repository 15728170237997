// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category {
  font-size: 0.8rem;
}

.category .kmanager-grand-child-category {
  min-height: 30px;
  cursor: pointer;
}

.category .kmanager-grand-child-category > p {
  font-size: 16px;
  flex-shrink: 0;
}

.category .kmanager-grand-child-category > svg {
  width: 25px;
  height: 25px;
  margin-left: 5px;
  fill: rgba(0,0,0,.54);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Category/category.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".category {\n  font-size: 0.8rem;\n}\n\n.category .kmanager-grand-child-category {\n  min-height: 30px;\n  cursor: pointer;\n}\n\n.category .kmanager-grand-child-category > p {\n  font-size: 16px;\n  flex-shrink: 0;\n}\n\n.category .kmanager-grand-child-category > svg {\n  width: 25px;\n  height: 25px;\n  margin-left: 5px;\n  fill: rgba(0,0,0,.54);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
