//import { validateNumber, validateDeposit } from "../../../../application/validations.js";
import { validateNumber } from "../../../../application/validations.js";

export class Deposit {
  #deposit

  constructor(deposit) {
    this.#deposit = deposit;
  }

  get() {
    return this.#deposit
  }

  get isValid() {
    return [
      this.validateNumber,
      this.validateDeposit,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateNumber() {
    return validateNumber(this.#deposit)
  }

  // バリデーションルールの適用
  get validateDeposit() {
    return 'TRUE'
    // todo: 引数が渡されていないため一時コメントアウト
    // return validateDeposit(this.#deposit, type)
  }
}
