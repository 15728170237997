import React from "react";
import { Link } from 'react-router-dom';

import "../../css/common/breadcrumb.css";
import { ReactComponent as ChevronRight } from "../../assets/svg/chevron-right.svg";

const Breadcrumb = ({ breadcrumbs }) => {
  return (
    <div className="breadcrumbs-wrap" data-testid="breadcrumb">
      <ul className="breadcrumbs">
        {
          breadcrumbs && breadcrumbs.map((breadcrumb, index) => {
            if (breadcrumbs.length - 1 === index) {
              return <li key={index}>{breadcrumb.title}</li>
            } else {
              return (
                <React.Fragment key={index}>
                  {
                      breadcrumb.to === undefined ?
                          <li key={index}>{breadcrumb.title}</li> :
                          <li><Link to={breadcrumb.to}>{breadcrumb.title}</Link></li>
                  }
                  <li className="breadcrumbs-divider">
                    <span className="icon"><ChevronRight/></span>
                  </li>
                </React.Fragment>
              );
            }
          })
        }
      </ul>
    </div>
  );
}

export default Breadcrumb;