import { useContext } from 'react';

import {
  ASSET_SALE,
  AUCTION,
  BID,
  PUBLIC_SALE,
} from 'domain/Property/entities/constants/property';

import AfterWinningBidLink from 'interfaces/components/common/AfterWinningBid/AfterWinningBidLink';
import GuidelineLink from 'interfaces/components/common/Guideline/GuidelineLink';
import { Link, useLocation } from 'react-router-dom';

import 'interfaces/css/Government/government-head.css';
import { GovernmentContext } from 'interfaces/pages/Government/entities/GovernmentContext';
import { mediaQuery, useMediaQuery } from 'shared/hooks/useResponsive.jsx';

const GovernmentHead = ({ governmentId, procedureType }) => {
  const { auction, government, guidelines, cautionPages } =
    useContext(GovernmentContext);
  const Emblem = `${process.env.REACT_APP_BASE_URL_SERVICE}/image_storage/${governmentId}/emblem.jpg`;
  const location = useLocation();

  const procedureTypeJa = () => {
    if (procedureType !== PUBLIC_SALE && procedureType !== ASSET_SALE) {
      return;
    }
    return procedureType;
  };

  let publicSaleType;

  if (government.governmentType == '"NATIONAL_TAX_AGENCY"') {
    publicSaleType = [AUCTION];
  } else {
    if (guidelines.length > 1) {
      publicSaleType = [AUCTION, BID];
    }
    if (guidelines.length === 1) {
      if (guidelines[0].publicSaleType === 'BID') {
        publicSaleType = [BID];
      } else {
        publicSaleType = [AUCTION];
      }
    }
  }

  const isWidthOver720 = useMediaQuery(mediaQuery.widthOver720);

  if (isWidthOver720) {
    return (
      <div className="kservice_government-head">
        <div className="government-head-top flex align-center mb-4">
          <img
            className="government-emblem mr-4 my-2"
            src={Emblem}
            alt="公章"
          />
          <div className="government-name mr-4 my-2">{government?.name}</div>
          <div className="government-info flex my-2">
            <Link
              className="margin-h mr-7"
              to={{ pathname: location.pathname, search: location.search }}>
              物件一覧
            </Link>
            <div className="margin-h flex mr-7">
              <GuidelineLink
                government={government}
                governmentId={governmentId}
                governmentName={government.name}
                procedureType={procedureType}
                guidelines={guidelines}
              />
            </div>
            <AfterWinningBidLink
              governmentId={governmentId}
              governmentName={government.name}
              procedureType={procedureType}
              publicSaleType={publicSaleType}
              afterWinningBids={cautionPages}
            />
          </div>
        </div>
        <div className="government-head-bottom flex align-center mb-4">
          {auction?.auctionStatus ? (
            <div className="government-status-tag mr-2">
              {auction?.auctionStatus}
            </div>
          ) : null}
          {auction?.bidStatus &&
          government.governmentType !== 'NATIONAL_TAX_AGENCY' ? (
            <div className="government-status-tag ml-2">
              {auction?.bidStatus}
            </div>
          ) : null}
          <div className="government-caption ml-2">{procedureTypeJa()}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="kservice_government-head">
      <div className="government-head-top flex align-center mb-4">
        <img className="government-emblem mr-4 my-2" src={Emblem} alt="公章" />
        <div className="government-name mr-4 my-2">{government?.name}</div>
      </div>
      <div className="government-head-bottom flex align-center mb-4">
        {auction?.auctionStatus !== '' ? (
          <div className="government-status-tag mr-2">
            {auction?.auctionStatus}
          </div>
        ) : null}

        <div>{auction.bidStatus}</div>
        {auction?.bidStatus !== '' ? (
          <div className="government-status-tag ml-2">{auction?.bidStatus}</div>
        ) : null}

        <div className="government-caption">{procedureTypeJa()}</div>
      </div>
      <div className="government-info flex column mb-4">
        <Link
          className="margin-h mr-7 mb-3"
          to={{ pathname: location.pathname, search: location.search }}>
          物件一覧
        </Link>
        <div className="margin-h flex mr-7 mb-3">
          <GuidelineLink
            government={government}
            governmentId={governmentId}
            governmentName={government?.name}
            procedureType={procedureType}
            guidelines={guidelines}
          />
        </div>
        <AfterWinningBidLink
          governmentId={governmentId}
          governmentName={government?.name}
          procedureType={procedureType}
          publicSaleType={publicSaleType}
          afterWinningBids={cautionPages}
        />
      </div>
    </div>
  );
};

export default GovernmentHead;
