import Confirm from 'interfaces/components/common/Confirm';
import Form from 'interfaces/components/common/Form/Form';
import Modal2 from 'interfaces/components/common/Modal/Modal2';

// 登録・更新処理
// import { useFarmlandInfoRegister } from '../../hooks/useNotices';
import validateInputFilter from 'interfaces/components/News/kmanager/validateInputFilter';
import { useEffect, useState } from 'react';

const KmanagerNoticeFormModal = ({ closeModal, data }) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const condition = ['openAt', 'closeAt', 'title', 'image', 'body'];
  const input = validateInputFilter(condition, true);

  useEffect(() => {
    console.log('input', input);
    if (data) {
      input.find((object) => object.column === 'openAt').init(data?.openAt);
      input.find((object) => object.column === 'closeAt').init(data?.closeAt);
      input.find((object) => object.column === 'title').init(data?.title);
      input.find((object) => object.column === 'body').init(data?.body);
    }
  }, [data]);

  const Create = () => {
    setIsConfirmOpen(true);
  };

  const register = () => {
    console.log('fix: kmanager advance notice form modal: register');
    // api作成後修正
    //useFarmlandInfoRegister(input, closeModal, data?.id);
    setIsConfirmOpen(false);
    closeModal();
  };

  const FORM_BUTTONS_DATA = [
    {
      text: '確認',
      clickHandler: Create,
      className: 'primary',
    },
    {
      text: 'キャンセル',
      clickHandler: () => closeModal(),
      className: 'warning',
    },
  ];

  return (
    <Modal2 handleClose={closeModal} modalSize={'M'}>
      <Confirm
        type="submit"
        isOpen={isConfirmOpen}
        closeModal={() => setIsConfirmOpen(false)}
        Submit={register}
      />
      <div
        className="kmanager-container kmanager-create-modal"
        data-testid="kmanager-advance-notice-create-modal">
        <h2 className="my-5 pl-5">{data ? '農地事前告知編集' : '農地事前告知新規作成'}</h2>
        <Form inputs={input} buttonData={FORM_BUTTONS_DATA} />
      </div>
    </Modal2>
  );
};

export default KmanagerNoticeFormModal;
