import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_BLOCK_LIST = `${BASE_URL_NAVIGATION}/storage/current/block_lists/data.json`;
const PREVIOUS_BLOCK_LIST = `${BASE_URL_NAVIGATION}/storage/previous/block_lists/data.json`;

export const fetchBlockListStorage = async () => {
  try {
    const response = await fetchBlockList(CURRENT_BLOCK_LIST);
    return extractBlockList(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ block_list ）を取得できませんでした");

    try {
      const response = await fetchBlockList(PREVIOUS_BLOCK_LIST);
      return extractBlockList(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ block_list ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchBlockList = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractBlockList = (response) => {
  return response.map(block_list => block_list);
}