import ReactMarkdown from "react-markdown";

import SmallTopicTitle from "../../common/Heading/SmallTopicTitle.jsx";
import ImportantLabel from "../../common/ImportantLabel";
import "interfaces/css/Notice/notice-item.css"

const NewsItem = ({ date, title, body, isImportant = false }) => {
    return (
        <div data-testid="notice-item" className="notice-item d-flex mt-15">
            <div data-testid="notice-item-date" className="notice-item-date">{ date }</div>
            <div className="d-flex flex-wrap mb-15" style={{ marginLeft: '10px' }}>
                <div className="d-flex align-start mb-2">
                    <SmallTopicTitle text={title}/>
                    { isImportant && <ImportantLabel/> }
                </div>
                <div data-testid="notice-item-body" className="notice-item-body">
                    <ReactMarkdown>{ body }</ReactMarkdown>
                </div>
            </div>
        </div>
    );
};
export default NewsItem;
