import * as Validation from '../../../shared/utils/helper/naviValidators.js';
import { useState } from 'react';

export const ProcedureNewEntryForm = (is_edit) => {
  const [auctionIdValid, setAuctionIdValid] = useState(is_edit ? 'TRUE' : null);
  const [invitationNameRequiredValid, setInvitationNameRequiredValid] =
    useState(is_edit ? 'TRUE' : null);
  const [areaRequiredValid, setAreaRequiredValid] = useState(
    is_edit ? 'TRUE' : null,
  );
  const [departmentValid, setDepartmentValid] = useState(
    is_edit ? 'TRUE' : null,
  );
  const [contactEmailValid, setContactEmailValid] = useState(
    is_edit ? 'TRUE' : null,
  );
  const [contactTelValid, setContactTelValid] = useState(
    is_edit ? 'TRUE' : null,
  );
  const [tel1Valid, setTel1Valid] = useState(is_edit ? 'TRUE' : null);
  const [tel2Valid, setTel2Valid] = useState(is_edit ? 'TRUE' : null);
  const [tel3Valid, setTel3Valid] = useState(is_edit ? 'TRUE' : null);
  const [bidEndTypeValid, setBidEndTypeValid] = useState(
    is_edit ? 'TRUE' : null,
  );
  const [mailCertDocumentValid, setMailCertDocumentValid] = useState(
    is_edit ? 'TRUE' : null,
  );
  const [mailReductionDocumentValid, setMailReductionDocumentValid] = useState(
    is_edit ? 'TRUE' : null,
  );
  const [aplCountAuctionDisplayValid, setAplCountAuctionDisplayValid] =
    useState(is_edit ? 'TRUE' : null);
  const [aplCountBidDisplayValid, setAplCountBidDisplayValid] = useState(
    is_edit ? 'TRUE' : null,
  );
  const [bidCountDisplayValid, setBidCountDisplayValid] = useState(
    is_edit ? 'TRUE' : null,
  );

  function handleNameValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setInvitationNameRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateInvitationNameRequiredRangeLength(
      e.target.value,
    );
    if (invalid !== 'TRUE') {
      setInvitationNameRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateInvitationNameRequiredHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInvitationNameRequiredValid(invalid);
      return;
    }
    setInvitationNameRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleBidEndTypeValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setBidEndTypeValid(invalid);
      return;
    }
    setBidEndTypeValid(invalid); // エラーメッセージのリセット
  }

  function handleMailCertDocumentValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setMailCertDocumentValid(invalid);
      return;
    }
    setMailCertDocumentValid(invalid); // エラーメッセージのリセット
  }

  function handleMailReductionDocumentValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setMailReductionDocumentValid(invalid);
      return;
    }
    setMailReductionDocumentValid(invalid); // エラーメッセージのリセット
  }

  function handleAplCountAuctionDisplayValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setAplCountAuctionDisplayValid(invalid);
      return;
    }
    setAplCountAuctionDisplayValid(invalid); // エラーメッセージのリセット
  }

  function handleAplCountBidDisplayValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setAplCountBidDisplayValid(invalid);
      return;
    }
    setAplCountBidDisplayValid(invalid); // エラーメッセージのリセット
  }

  function handleBidCountDisplayValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setBidCountDisplayValid(invalid);
      return;
    }
    setBidCountDisplayValid(invalid); // エラーメッセージのリセット
  }

  function handleAreaValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateAreaRequiredRangeLength(e.target.value);
    if (invalid !== 'TRUE') {
      setAreaRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateAreaRequiredInclude(e.target.value);
    if (invalid !== 'TRUE') {
      setAreaRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateAreaRequiredHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAreaRequiredValid(invalid);
      return;
    }
    setAreaRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleContactOfficeValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateDepartmentRangeLength(e.target.value);
    if (invalid !== 'TRUE') {
      setDepartmentValid(invalid);
      return;
    }
    invalid = Validation.validateDepartmentHasEmoji2(e.target.value);
    if (invalid !== 'TRUE') {
      setDepartmentValid(invalid);
      return;
    }
    setDepartmentValid(invalid); // エラーメッセージのリセット
  }

  function handleContactEmailValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateEmailFormat(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    invalid = Validation.validateEmailLength(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    setContactEmailValid(invalid); // エラーメッセージのリセット
  }

  function handleContactTelValid(tel) {
    let invalid = null;
    const telAll = tel.telNum1 + tel.telNum2 + tel.telNum3;
    invalid = Validation.validateRangeLength(telAll, 10, 11);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    setContactTelValid(invalid); // エラーメッセージのリセット
  }

  function handleTel1Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setTel1Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setTel1Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setTel1Valid(invalid);
      return;
    }
    setTel1Valid(invalid); // エラーメッセージのリセット
  }

  function handleTel2Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setTel2Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setTel2Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setTel2Valid(invalid);
      return;
    }
    setTel2Valid(invalid); // エラーメッセージのリセット
  }

  function handleTel3Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setTel3Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setTel3Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setTel3Valid(invalid);
      return;
    }
    setTel3Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionIdValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionIdValid(invalid);
      return;
    }
    setAuctionIdValid(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      handleAuctionIdValid,
      handleContactTelValid,
      handleTel1Valid,
      handleTel2Valid,
      handleTel3Valid,
      handleContactEmailValid,
      handleContactOfficeValid,
      handleAreaValid,
      handleNameValid,
      handleBidEndTypeValid,
      handleMailCertDocumentValid,
      handleMailReductionDocumentValid,
      handleAplCountAuctionDisplayValid,
      handleAplCountBidDisplayValid,
      handleBidCountDisplayValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      auctionIdValid,
      contactTelValid,
      tel1Valid,
      tel2Valid,
      tel3Valid,
      contactEmailValid,
      departmentValid,
      areaRequiredValid,
      invitationNameRequiredValid,
      bidEndTypeValid,
      mailCertDocumentValid,
      mailReductionDocumentValid,
      aplCountAuctionDisplayValid,
      aplCountBidDisplayValid,
      bidCountDisplayValid,
    },
  };

  return validations;
};
