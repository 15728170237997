import { useContext, useState, useEffect } from 'react';
import { ApplicationContext } from 'interfaces/pages/Application/entities/ApplicationContext.js';

import { DepositPayment } from 'shared/utils/constants/property.js';

import AplTypeInput from './AplTypeInput.jsx';
import ApplicationInfoFormCheckBox from './ApplicationInfoFormCheckBox.jsx';
import ApplicationInfoSectionHeader from './ApplicationInfoSectionHeader.jsx';
import CallUpUserInfoButton from './CallUpUserInfoButton.jsx';
import EditUserInfoBody from './EditUserInfo/EditUserInfoBody.jsx';
import IsDelegatorProcedureInput from './IsDelegatorStatusList.jsx';

import JointBiddingInput from './JointBiddingInput.jsx';

const ApplicantInfoForm = ({
  data,
  applicantInfo,
  registeredInfo,
  setApplicantInformation,
  callUpInfo,
}) => {
  const [membershipId, setMembershipId] = useState('');
  const { auctionUser } = useContext(ApplicationContext);

  const handleApplicantInfoChange = (params) => {
    setApplicantInformation({
      ...applicantInfo,
      ...params,
    });
  }; // mock

  const validationMessages = {}; // mock

  const handleCallUp = async () => {
    setApplicantInformation({
      ...applicantInfo,
      ...callUpInfo(registeredInfo),
    });
  };

  useEffect(() => {
    setMembershipId(auctionUser.membershipId);
  }, [auctionUser]);

  return (
    <div data-testid="applicant-info-form">
      <ApplicationInfoSectionHeader
        title="参加者情報"
        caption="参加者の情報を入力してください"
      />
      <div>
        <EditUserInfoBody
          prefix={
            registeredInfo && (
              <CallUpUserInfoButton handleClick={handleCallUp} />
            )
          }
          membershipId={membershipId}
          userInfo={applicantInfo}
          validationMessages={validationMessages.applicantInfo}
          handleUserInfoChange={handleApplicantInfoChange}
          suffix={
            <ApplicationInfoFormCheckBox
              value={applicantInfo?.isUpdateMypage}
              label="この情報をマイページに上書き保存する"
              handleClick={() =>
                handleApplicantInfoChange({
                  isUpdateMypage: !applicantInfo.isUpdateMypage,
                })
              }
            />
          }>
          {data.depositPayment !== DepositPayment.NONE.string ? (
            <AplTypeInput
              data={data}
              applicantInfo={applicantInfo}
              setApplicantInformation={setApplicantInformation}
            />
          ) : (
            <></>
          )}
          {data.propertyType === 'REAL_ESTATE' ? (
            <JointBiddingInput
              data={data}
              applicantInfo={applicantInfo}
              setApplicantInformation={setApplicantInformation}
            />
          ) : (
            <></>
          )}
          <IsDelegatorProcedureInput
            applicantInfo={applicantInfo}
            setApplicantInformation={setApplicantInformation}
          />
        </EditUserInfoBody>
      </div>
    </div>
  );
};

export default ApplicantInfoForm;
