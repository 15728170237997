import { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import ChildCategories from './ChildCategories.jsx';
import { DashboardContext } from '../../../../../../pages/Dashboard/entities/DashboardContext';
import { propertyConstants } from '../../../../../../pages/Dashboard/entities/propertyConstants.js';
import { queryParams } from '../../../../../../pages/Dashboard/entities/queryParam.js';
import AccordionWithCheckBox from '../AccordionWithCheckBox.jsx';

const ParentCategories = ({ isSeeAll, showCheckBox, showCount }) => {
  const { allowPropertyCategories } = useContext(DashboardContext);
  const initialArray = new Array(allowPropertyCategories?.length ?? 0).fill(
    false,
  );
  const [parentCheckBoxStates, setParentCheckBoxStates] =
    useState(initialArray);
  const [searchParams] = useSearchParams();
  const searchPropertyCategoryId =
    searchParams.get(queryParams.SEARCH_PROPERTY_CATEGORY_ID) ??
    propertyConstants.DATA_EMPTY;
  const setParentCheckBoxState = (index, value) => {
    const newState = [...parentCheckBoxStates];
    newState[index] = value;
    setParentCheckBoxStates(newState);
  };

  // searchにカテゴリー情報が出ない理由は、useEffectの再描画条件に含まれないため。
  // providerに移行する（対象：dashboard, search
  useEffect(() => {
    const updateArray = [...parentCheckBoxStates];
    if (searchParams.has(queryParams.SEARCH_PROPERTY_CATEGORY_ID)) {
      const searchCategoryIds = searchParams.get(
        queryParams.SEARCH_PROPERTY_CATEGORY_ID,
      );
      if (searchCategoryIds !== '') {
        const searchPropertyCategoryId = searchCategoryIds.split(',');
        allowPropertyCategories.forEach((category, index) => {
          console.log(category);
          searchPropertyCategoryId.includes(category.id.toString())
            ? (updateArray[index] = true)
            : null;
        });
        setParentCheckBoxStates(updateArray);
      }
    }
  }, [allowPropertyCategories, searchPropertyCategoryId]);

  return allowPropertyCategories?.map((category, index) => {
    if (category.displayService !== 'DISPLAY') {
      return null;
    }
    if (index > 4) {
      return (
        <AccordionWithCheckBox
          key={category.id}
          id="parent-category"
          categoryData={category}
          marginLeft={25}
          style={isSeeAll ? {} : { display: 'none' }}
          onClick={() => {
            setParentCheckBoxState(index, !parentCheckBoxStates[index]);
          }}
          showCheckBox={showCheckBox}
          showCount={showCount}
          checkboxActive={parentCheckBoxStates[index]}
          contents={
            <ChildCategories
              categories={category.childCategory}
              isSeeAll={isSeeAll}
              showCheckBox={showCheckBox}
              showCount={showCount}
              checkBoxState={parentCheckBoxStates[index]}
            />
          }
        />
      );
    } else {
      return (
        <AccordionWithCheckBox
          key={category.id}
          id="parent-category"
          categoryData={category}
          marginLeft={25}
          style={{}}
          onClick={() => {
            setParentCheckBoxState(index, !parentCheckBoxStates[index]);
          }}
          showCheckBox={showCheckBox}
          showCount={showCount}
          checkboxActive={parentCheckBoxStates[index]}
          contents={
            <ChildCategories
              categories={category.childCategory}
              isSeeAll={isSeeAll}
              showCheckBox={showCheckBox}
              showCount={showCount}
              checkBoxState={parentCheckBoxStates[index]}
            />
          }
        />
      );
    }
  });
};

export default ParentCategories;
