
export class InvoiceOutputDate {
  #invoiceOutputDate

  constructor(invoiceOutputDate) {
    this.#invoiceOutputDate = invoiceOutputDate;
  }

  get() {
    return this.#invoiceOutputDate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
