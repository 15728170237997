
export class IsDraft {
  #isDraft

  constructor(isDraft) {
    this.#isDraft = isDraft;
  }

  get() {
    return this.#isDraft
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
