
export class EmblemPathName {
    #emblemPathName

    constructor(emblemPathName) {
        this.#emblemPathName = emblemPathName;
    }

    get() {
        return this.#emblemPathName
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
