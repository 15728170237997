// ロジックを含まない処理の実装

import { fetchPropertiesData } from "../../../infrastructure/api/Properties.js";
import { fetchEstatePropertiesStorage } from "../../../infrastructure/api/storage/estate_properties.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { EstatePropertyGet } from "../entities/Property.js";

// getメソッドの実装
export async function getEstateProperty(type) {
  // SAMPLE
  let response = [];
  if (type === SERVICE) {
    response = await fetchEstatePropertiesStorage()
  }
  if (type === NAVI) {
    response = await fetchEstatePropertiesStorage()
  }
  if (type === KMANAGER) {
    response = await fetchPropertiesData()
  }

  if (response instanceof Error) {
    throw new CustomError('Error get EstateProperty', response);
  }

  let results = [];
  response.map((data) => {
    const result = new EstatePropertyGet(data);
    results.push(result)
  })

  return results
}
