import Category from 'interfaces/components/Category/kmanager/Category';
import Button from 'interfaces/components/common/Button/Button';
import PageTitle from 'interfaces/components/common/PageTitle';
import SearchInput from 'interfaces/components/common/SearchInput';
import { useContext, useEffect, useState } from 'react';

import { CategoryContext } from '../../../pages/Category/entities/CategoryContext';
import { useSnackBar } from 'interfaces/components/Auth/kmanager/SnackBarProvider';

//import { useCategory } from "../../hooks/useCategory.jsx";
//import { useEffect, useState } from "react";
//import { bodyToSnake, createBody } from "../../api/convertData.js";
// import { putApiData } from "../../api/request.js";
// import { dummyCategoryData } from "../../helper/test_helper/dummy_kmanager_category_data.js";

const Categories = () => {
  const { propertyCategories } = useContext(CategoryContext);
  //    const [error, setError] = useState({})
  //    const [inputList, setInputList] = useState()
  const [newId, setNewId] = useState(0);

  //    const categories = useCategory(handleError);
  const [categoryList, setCategoryList] = useState(propertyCategories);
  const [searchInputEvent, setSearchInput] = useState('');

  const { openSnackBar } = useSnackBar();

  useEffect(() => {
    console.log(propertyCategories);
    if (searchInputEvent) {
      const searchInput = searchInputEvent.target.value.toLowerCase();
      let filteredCategories = Object.values(propertyCategories)
        .filter((category) => {
          const hasVisibleChildren = category.children && Object.values(category.children).some((child) => {
            const hasVisibleGrandchildren = child.children && Object.values(child.children).some((grandchild) =>
              grandchild.name.toLowerCase().includes(searchInput),
            );
            return child.name.toLowerCase().includes(searchInput) || hasVisibleGrandchildren;
          });
          return category.name.toLowerCase().includes(searchInput) || hasVisibleChildren;
        })
        .map((category) => ({
          ...category,
          children: category.children && Object.values(category.children)
            .filter((child) => {
              const hasVisibleGrandchildren = child.children && Object.values(child.children).some(
                (grandchild) => grandchild.name.toLowerCase().includes(searchInput),
              );
              return (
                child.name.toLowerCase().includes(searchInput) || hasVisibleGrandchildren
              );
            })
            .map((child) => ({
              ...child,
              children: child.children && Object.values(child.children).filter((grandchild) =>
                grandchild.name.toLowerCase().includes(searchInput),
              ),
            })),
        }));

      setCategoryList(filteredCategories);
    } else {
      setCategoryList(propertyCategories);
    }
  }, [searchInputEvent, propertyCategories]);

  const defaultCategory = {
    id: `new_${newId}`,
    name: 'カテゴリー名',
    displayService: 'HIDDEN',
    displayProperty: 'HIDDEN',
    displayEstate: 'HIDDEN',
    displayCar: 'HIDDEN',
    children: [],
  };

  const PutCategories = () => {
    console.log('put category');
    //  if (!inputList) {
    //    return
    //  }
    //  const promises = Object.keys(inputList).map((key) => {
    //      const inputBody = createBody(inputList[key])
    //      let keyUri = ''
    //      if (!isNaN(parseInt(key))) {
    //        keyUri = `/${key}`
    //      }
    //      return putApiData(`property-categories${keyUri}`, bodyToSnake(inputBody))
    //    }
    //  )
    //  await Promise.all(promises).then(() => {
    //  }).catch((err) => {
    //    handleError(err)
    //  }).finally(() => {
    //    setInputList()
    //  })
    openSnackBar('保存しました');
  };

  const addCategory = (parentId = -9999, grandParentId = -9999) => {
    let copyData = { ...categoryList };
    let newCategory = defaultCategory;
    newCategory.parentId = parentId;
    if (parentId === -9999) {
      newCategory.depth = 1;
      newCategory.name = 'カテゴリー名';
      copyData = { ...copyData, newCategory };
    } else if (grandParentId === -9999) {
      let parent = copyData[parentId];
      if (parent) {
        newCategory.depth = 2;
        newCategory.name = '子カテゴリー名';
        parent.children = { ...parent.children, newCategory };
      } else {
        return;
      }
    } else {
      let grandParent = copyData[grandParentId];
      if (grandParent) {
        let parent = grandParent.children[parentId];
        if (parent) {
          newCategory.depth = 3;
          newCategory.name = '子カテゴリー名';
          parent.children = { ...parent.children, newCategory };
        } else {
          return;
        }
      } else {
        return;
      }
    }
    setNewId((prevState) => prevState + 1);
    setCategoryList(copyData);
  };

  //    function handleError(err) {
  //      setError(err)
  //      setTimeout(() => {
  //        setError({})
  //      }, 2000)
  //    }

  //    useEffect(() => {
  //      if (!categories.loading) {
  //        setCategoryList(categories.category);
  //      }
  //:w
  // }, [categories.loading]);

  useEffect(() => {
    setCategoryList(propertyCategories);
  }, [propertyCategories]);

  return (
    <div className="kmanager-categories" data-testid="kmanager-categories">
      <div className="kmanager-row">
        <div className="kmanager-col kmanager-col-6">
          <PageTitle text="カテゴリー管理" />
        </div>
        <div className="kmanager-col">
          <SearchInput onChange={setSearchInput} />
        </div>
        <div className="kmanager-col kmanager-col-12">
          <Button
            className="secondary mr-3"
            text="親カテゴリを追加"
            onClick={() => addCategory(-9999, -9999)}
          />
          <Button className="primary" text="保存" onClick={PutCategories} />
        </div>
        <div className="kmanager-col kmanager-col-12">
          <div className="box-shadow pb-5">
            <div className="flex pt-3">
              <p className="ml-5">カテゴリ</p>
              <p className="mr-5 ml-auto">利用可能な物件登録フォーム</p>
            </div>
            {/* error?.code && <PageTitle text={error.message}/> */}
            <Category
              data={categoryList}
              setInputs={setCategoryList}
              handleAdd={addCategory}
              //setInputs={setInputList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
