import LocalGovernmentManagementSendDocumentsToProvider from './LocalGovernmentManagementSendDocumentsToProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { localGovernmentManagementSendDocumentsTo } from "../entities/componentMapping.js";

const LocalGovernmentManagementSendDocumentsTo = ({ type }) => {
  return (
    <LocalGovernmentManagementSendDocumentsToProvider type={type}>
      {getComponent(localGovernmentManagementSendDocumentsTo, type)}
    </LocalGovernmentManagementSendDocumentsToProvider>
  );
};

export default LocalGovernmentManagementSendDocumentsTo;
