import 'interfaces/css/Mylist/mylist-headline.css';

const MylistHeadline = ({ headline }) => {
  return (
    <div className="mylist-headline">
      <h1 className="mylist-headline-name ">{headline}</h1>
      <hr className="mylist-line" />
    </div>
  );
};
export default MylistHeadline;
