
export class GovernmentId {
    #governmentId

    constructor(governmentId) {
        this.#governmentId = governmentId;
    }

    get() {
        return this.#governmentId
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
