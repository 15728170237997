import Button from 'interfaces/components/common/Button/Button';
import Toggle from 'interfaces/components/common/Toggle/Toggle';
import { validateInputsGuidelinePublicSaleForm } from 'interfaces/components/common/ValidationGuidelinesPublicSaleForm.jsx';
import Both from 'interfaces/components/LocalGovernmentManagement/guideline/Both';
import IndividualForm from 'interfaces/components/LocalGovernmentManagement/guideline/IndividualForm';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LocalGovernmentManagementGuidelinesContext } from '../../../pages/LocalGovernmentManagementGuidelines/entities/LocalGovernmentManagementGuidelinesContext';

const GuidelinesForm = ({ procedureType, governmentType }) => {
  const navigate = useNavigate();
  const [indivisualSetting, setIndivisualSetting] = useState(false);
  const auction = validateInputsGuidelinePublicSaleForm();
  const bid = validateInputsGuidelinePublicSaleForm();
  const { guidelines } = useContext(LocalGovernmentManagementGuidelinesContext);

  async function onRegisterClick(e) {
    e.preventDefault();
    //await allFetch(auctionConfig)
    //await allFetch(bidConfig)
  }
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const validateMessages = (form) => {
      const { validationMessage, data } = form;
      return Object.entries(validationMessage).every(
        ([key, validationMessage]) => {
          const notRequiredKeys = [
            data?.isDisplayPledge !== 'DISPLAY' && 'guidelinePledgeBodyValid',
            ...Array.from({ length: 8 }, (_, i) => i + 1).flatMap((num) => [
              data[`isDisplay${num}`] !== 'DISPLAY' &&
                `guidelineTitleRequiredValid${num}`,
              data[`isDisplay${num}`] !== 'DISPLAY' &&
                `guidelineBodyValid${num}`,
            ]),
          ].filter(Boolean);
          if (notRequiredKeys.includes(key)) {
            return validationMessage === null || validationMessage === 'TRUE';
          }
          return validationMessage === 'TRUE';
        },
      );
    };

    if (indivisualSetting) {
      setIsValid(validateMessages(auction) && validateMessages(bid));
    } else {
      setIsValid(validateMessages(auction));
    }
  }, [auction, bid, indivisualSetting]);

  useEffect(() => {
    let auctionData = guidelines.filter(
      (item) => item.publicSaleType === 'AUCTION',
    );
    auction.init.setTitle1(
      auctionData?.find((object) => object.itemNo === '1')?.title || '',
    );
    auction.init.setTitle2(
      auctionData?.find((object) => object.itemNo === '2')?.title || '',
    );
    auction.init.setTitle3(
      auctionData?.find((object) => object.itemNo === '3')?.title || '',
    );
    auction.init.setTitle4(
      auctionData?.find((object) => object.itemNo === '4')?.title || '',
    );
    auction.init.setTitle5(
      auctionData?.find((object) => object.itemNo === '5')?.title || '',
    );
    auction.init.setTitle6(
      auctionData?.find((object) => object.itemNo === '6')?.title || '',
    );
    auction.init.setTitle7(
      auctionData?.find((object) => object.itemNo === '7')?.title || '',
    );
    auction.init.setTitle8(
      auctionData?.find((object) => object.itemNo === '8')?.title || '',
    );
    auction.init.setPledgeBody(auctionData?.data?.pledgeBody || '');
    auction.init.setBody1(
      auctionData?.find((object) => object.itemNo === '1')?.body || '',
    );
    auction.init.setBody2(
      auctionData?.find((object) => object.itemNo === '2')?.body || '',
    );
    auction.init.setBody3(
      auctionData?.find((object) => object.itemNo === '3')?.body || '',
    );
    auction.init.setBody4(
      auctionData?.find((object) => object.itemNo === '4')?.body || '',
    );
    auction.init.setBody5(
      auctionData?.find((object) => object.itemNo === '5')?.body || '',
    );
    auction.init.setBody6(
      auctionData?.find((object) => object.itemNo === '6')?.body || '',
    );
    auction.init.setBody7(
      auctionData?.find((object) => object.itemNo === '7')?.body || '',
    );
    auction.init.setBody8(
      auctionData?.find((object) => object.itemNo === '8')?.body || '',
    );
    auction.init.setIsDisplayPledge(
      auctionData?.data?.isDisplayPledge || 'DISPLAY',
    );
    auction.init.setIsDisplay1(
      auctionData?.find((object) => object.itemNo === '1')?.isDisplay ||
        'DISPLAY',
    );
    auction.init.setIsDisplay2(
      auctionData?.find((object) => object.itemNo === '2')?.isDisplay ||
        'HIDDEN',
    );
    auction.init.setIsDisplay3(
      auctionData?.find((object) => object.itemNo === '3')?.isDisplay ||
        'HIDDEN',
    );
    auction.init.setIsDisplay4(
      auctionData?.find((object) => object.itemNo === '4')?.isDisplay ||
        'HIDDEN',
    );
    auction.init.setIsDisplay5(
      auctionData?.find((object) => object.itemNo === '5')?.isDisplay ||
        'HIDDEN',
    );
    auction.init.setIsDisplay6(
      auctionData?.find((object) => object.itemNo === '6')?.isDisplay ||
        'HIDDEN',
    );
    auction.init.setIsDisplay7(
      auctionData?.find((object) => object.itemNo === '7')?.isDisplay ||
        'HIDDEN',
    );
    auction.init.setIsDisplay8(
      auctionData?.find((object) => object.itemNo === '8')?.isDisplay ||
        'HIDDEN',
    );

    let bidData = guidelines.filter((item) => item.publicSaleType === 'BID');
    bid.init.setTitle1(bidData?.find((object) => object.itemNo === '1')?.title);
    bid.init.setTitle2(bidData?.find((object) => object.itemNo === '2')?.title);
    bid.init.setTitle3(bidData?.find((object) => object.itemNo === '3')?.title);
    bid.init.setTitle4(bidData?.find((object) => object.itemNo === '4')?.title);
    bid.init.setTitle5(bidData?.find((object) => object.itemNo === '5')?.title);
    bid.init.setTitle6(bidData?.find((object) => object.itemNo === '6')?.title);
    bid.init.setTitle7(bidData?.find((object) => object.itemNo === '7')?.title);
    bid.init.setTitle8(bidData?.find((object) => object.itemNo === '8')?.title);
    bid.init.setPledgeBody(bidData?.data?.pledgeBody || '');
    bid.init.setBody1(bidData?.find((object) => object.itemNo === '1')?.body);
    bid.init.setBody2(bidData?.find((object) => object.itemNo === '2')?.body);
    bid.init.setBody3(bidData?.find((object) => object.itemNo === '3')?.body);
    bid.init.setBody4(bidData?.find((object) => object.itemNo === '4')?.body);
    bid.init.setBody5(bidData?.find((object) => object.itemNo === '5')?.body);
    bid.init.setBody6(bidData?.find((object) => object.itemNo === '6')?.body);
    bid.init.setBody7(bidData?.find((object) => object.itemNo === '7')?.body);
    bid.init.setBody8(bidData?.find((object) => object.itemNo === '8')?.body);
    bid.init.setIsDisplayPledge(bidData?.data?.isDisplayPledge || 'DISPLAY');
    bid.init.setIsDisplay1(
      bidData?.find((object) => object.itemNo === '1')?.isDisplay || 'DISPLAY',
    );
    bid.init.setIsDisplay2(
      bidData?.find((object) => object.itemNo === '2')?.isDisplay || 'HIDDEN',
    );
    bid.init.setIsDisplay3(
      bidData?.find((object) => object.itemNo === '3')?.isDisplay || 'HIDDEN',
    );
    bid.init.setIsDisplay4(
      bidData?.find((object) => object.itemNo === '4')?.isDisplay || 'HIDDEN',
    );
    bid.init.setIsDisplay5(
      bidData?.find((object) => object.itemNo === '5')?.isDisplay || 'HIDDEN',
    );
    bid.init.setIsDisplay6(
      bidData?.find((object) => object.itemNo === '6')?.isDisplay || 'HIDDEN',
    );
    bid.init.setIsDisplay7(
      bidData?.find((object) => object.itemNo === '7')?.isDisplay || 'HIDDEN',
    );
    bid.init.setIsDisplay8(
      bidData?.find((object) => object.itemNo === '8')?.isDisplay || 'HIDDEN',
    );
  }, [guidelines]);

  const backToLocalGovernmentManagement = () => {
    navigate('/navi/local-government-management');
  };

  return (
    <div className="new-style_navi_local-government-management-top-form">
      {governmentType !== 'NATIONAL_TAX_AGENCY' && (
        <Toggle
          message={'「せり売形式」と「入札形式」で個別に情報を登録する'}
          handler={() => setIndivisualSetting(!indivisualSetting)}
        />
      )}
      {!indivisualSetting && (
        <Both data={auction} procedureType={procedureType} />
      )}
      {indivisualSetting && (
        <IndividualForm
          auctionData={auction}
          bidData={bid}
          procedureType={procedureType}
        />
      )}
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'登録'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_primary-button'
              }
              onClick={onRegisterClick}
              isDisabled={!isValid}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button common-button-bg-whitesmoke new-style_navi_normal-button'
              }
              onClick={backToLocalGovernmentManagement}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidelinesForm;
