import { findById as getGovernment } from 'domain/Government/services/Government';

import { useEffect, useState } from 'react';
import { useAuth } from 'shared/hooks/useAuth.js';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from 'shared/utils/helper/authState.js';
import { SERVICE, NAVI, KMANAGER } from 'shared/utils/helper/constants.js';

import { breadcrumbs } from '../entities/breadcrumbs.js';
import { LocalGovernmentManagementEmblemContext } from '../entities/LocalGovernmentManagementEmblemContext.js';

const LocalGovernmentManagementEmblemProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const { localStorageGovernmentId } = useLocalStorage();
  const [government, setGovernment] = useState(undefined);

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {}, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      getGovernment(type, localStorageGovernmentId).then((result) => {
        setGovernment(result);
      });
    }, [localStorageGovernmentId]);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <LocalGovernmentManagementEmblemContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        government,
      }}>
      {children}
    </LocalGovernmentManagementEmblemContext.Provider>
  );
};

export default LocalGovernmentManagementEmblemProvider;
//  const [government, setGovernment] = useState([]);
//    useEffect(() => {
//      getGovernment(type).then(result => {
//        setGovernment(result);
//      });
//    }, []);
