import { DATA_EMPTY } from 'domain/Property/entities/constants/property';

import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle';

import MarkDown from './MarkDown';

const CommonInfo = ({ data, title }) => {
  const condition = [DATA_EMPTY, '', undefined];
  return (
    <div data-testid="common-info">
      {!condition.includes(data) ? (
        <section className="mb-10">
          <ArticleDefaultTitle title={title} />
          <div className="common-article-default-body rounded px-6 py-8 mb-8">
            <div data-testid="common-info-markdown">
              <MarkDown data={data} />
            </div>
          </div>
        </section>
      ) : (
        ''
      )}
    </div>
  );
};
export default CommonInfo;
