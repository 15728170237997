import { validateRangeLength, includesHalftwidthKatakana, hasEmoji } from "../../../../application/validations.js";

export class Name {
  #name

  constructor(name) {
    this.#name = name;
  }

  get() {
    return this.#name
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.includesHalftwidthKatakana,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#name, 1, 50)
  }

  // バリデーションルールの適用
  get includesHalftwidthKatakana() {
    return includesHalftwidthKatakana(this.#name)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#name)
  }
}
