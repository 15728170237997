
export class DescriptionCreatedAt {
    #descriptionCreatedAt

    constructor(descriptionCreatedAt) {
        this.#descriptionCreatedAt = descriptionCreatedAt;
    }

    get() {
        return this.#descriptionCreatedAt
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
