
export class AddDescription {
    #addDescription

    constructor(addDescription) {
        this.#addDescription = addDescription;
    }

    get() {
        return this.#addDescription
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
