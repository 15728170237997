
import Button from 'interfaces/components/common/Button/Button.jsx';
import { useEffect, useState } from 'react';

// todo: api呼び出し部。api完成後作成する
//import { putEmail } from '../../../../api/auctionUsers.js';

//import { useAuth } from 'shared/hooks/useAuth.js';
import { useError } from 'shared/hooks/useError';
import { useInput } from 'shared/hooks/useInput';
import { useValidate } from 'shared/hooks/useValidate';

import ResetFormInput from '../ResetFormInput.jsx';
//import { useLocalStorage } from 'shared/hooks/useLocalStorage.js';

import './css/reset-email-form.css';

const emailValidates = ['no_message_required', 'email'];

const ResetEmailForm = ({ handleClose, setModalType, setTargetEmail }) => {
  //const { userId } = useAuth();
  const { setError } = useError();
  const [email, handleEmailChange, validateEmailMessage] =
    useInput(emailValidates);
  const [isDisabled, setIsDisabled] = useState(true);
  const { handleValidates } = useValidate();
  const EMAIL_INPUT_CAPTION =
    'ドメイン受信制限をしている場合は @kankocho.jp からのメールの受信を許可してください。';
  //const { localStorageUserId } = useLocalStorage();

  const checkAllValidation = () => {
    const emailValidateMessage = handleValidates(email, emailValidates);
    setIsDisabled(emailValidateMessage);
  };

  useEffect(() => {
    checkAllValidation();
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isDisabled) {
      return;
    }

    try {
      //await putEmail(localStorageUserId, { email: email });
      setTargetEmail(email);
      setModalType('CONFIRM');
    } catch (e) {
      setError(e);
    }
  };

  return (
    <div className="reset-email-form">
      <form noValidate onSubmit={handleSubmit}>
        <ResetFormInput
          type="email"
          label={<b>新しいメールアドレスを入力してください。</b>}
          value={email}
          handleChange={handleEmailChange}
          validateMessage={validateEmailMessage}
          caption={EMAIL_INPUT_CAPTION}
        />
        <div className="flex justify-center mt-10">
          <Button
            text="キャンセル"
            height="44px"
            width="100px"
            className="cancel-button"
            type="button"
            onClick={handleClose}
          />
          <Button
            text="次へ"
            height="44px"
            width="100px"
            className="mx-2"
            type="submit"
            isDisabled={isDisabled}
          />
        </div>
      </form>
    </div>
  );
};

export default ResetEmailForm;
