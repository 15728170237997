// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delivery-style-selector {
  width: 100%;
  border: solid 4px var(--important);
  background-color: var(--white);
  padding: 24px;
  margin-bottom: 28px;
}

.delivery-style-radio-icon {
  width: 24px;
  height: 24px;
}

.delivery-style-select-item {
  width: 70%;
  border: solid 1px var(--primary-base);
  margin: 10px auto;
  padding: 8px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.delivery-style-main-label {
  font-size: 1.2rem;
  font-weight: bold;
}

.delivery-style-select-button {
  width: 25%;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/components/Property/service/PropertyDetail/DeliveryStyleSelector/css/delivery-style-selector.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kCAAkC;EAClC,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,qCAAqC;EACrC,iBAAiB;EACjB,iBAAiB;EACjB,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".delivery-style-selector {\n  width: 100%;\n  border: solid 4px var(--important);\n  background-color: var(--white);\n  padding: 24px;\n  margin-bottom: 28px;\n}\n\n.delivery-style-radio-icon {\n  width: 24px;\n  height: 24px;\n}\n\n.delivery-style-select-item {\n  width: 70%;\n  border: solid 1px var(--primary-base);\n  margin: 10px auto;\n  padding: 8px 16px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.delivery-style-main-label {\n  font-size: 1.2rem;\n  font-weight: bold;\n}\n\n.delivery-style-select-button {\n  width: 25%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
