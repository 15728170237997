import BlockListTable from 'interfaces/components/BlockList/navi/BlockListTable';
import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import SmallLightButton from 'interfaces/components/common/Button/SmallLightButton';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import SortToolbar from 'interfaces/components/common/SortToolbar';
import PropertyListSearchInput from 'interfaces/components/Property/List/PropertyListSearchInput';
import { BlockListContext } from 'interfaces/pages/BlockList/entities/BlockListContext';
import { breadcrumbs } from 'interfaces/pages/BlockList/entities/breadcrumbs';
import { useContext, useRef, useState } from 'react';
import DeleteBlockListModal from './DeleteBlockListModal';
import useModalControl from 'shared/hooks/useModalControl';

import 'interfaces/css/BlockList/block-list.css';

const BlockList = () => {
  const { blockList, setSearchParam } = useContext(BlockListContext);
  const [searchUserId, setSearchUserId] = useState('');
  const refSearchUserId = useRef();
  const [deleteIds, setDeleteIds] = useState([]);
  const { isOpen, handleOpen, handleClose } = useModalControl();

  const handleSearch = () => {
    setSearchParam({ auctionUserId: searchUserId });
  };

  const handleSearchParam = (e) => {
    setSearchUserId(e);
  };

  const handleDeleteParam = () => {
    refSearchUserId.current.value = '';
    setSearchParam({ auctionUserId: '' });
  };

  const handleDeleteSelected = () => {
    handleClose();
    console.log('削除するデータ:', deleteIds);
    setDeleteIds([]);
  };

  return (
    <>
      <div className="block-list" data-testid="block-list">
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <Heading2 title="ブロックリスト一覧" />
        <p className="description" data-testid="description">
          ブロックリストに登録した会員識別番号一覧です。
          <br />
          削除する場合は該当の会員識別番号を選択し、｢削除ボタン｣を押してください。メモ登録する場合は「メモ登録」より行います。
        </p>
        <div className="block-list-filter mt-5">
          <PropertyListSearchInput
            inputRef={refSearchUserId}
            label="会員識別番号"
            style={{ width: '200px' }}
            handler={handleSearchParam}
          />
          <SmallLightButton text="絞り込む" onClick={handleSearch} />
          <SmallLightButton text="削除" onClick={handleDeleteParam} />
        </div>
        <div className="block-list-actions">
          <SortToolbar>
            <SmallLightButton
              text="選択したデータを削除"
              onClick={handleOpen}
              disabled={deleteIds.length === 0}
            />
          </SortToolbar>
        </div>
        <BlockListTable
          tableData={blockList}
          setDeleteIds={setDeleteIds}
          deleteIds={deleteIds}
        />
      </div>
      <DeleteBlockListModal
        isOpen={isOpen}
        handleClose={handleClose}
        deleteIds={deleteIds}
        onDelete={handleDeleteSelected}
      />
    </>
  );
};

export default BlockList;
