import SmallTopicTitle from '../../../common/Heading/SmallTopicTitle.jsx';
import CautionTopicTable from '../../CautionTopicTable.jsx';

const timingAndRequiredPeriodData = [
  {
    headline: '権利移転の時期',
    content: '落札者が売払代金を納付した時点で所有権は落札者に移転します。',
  },
  {
    headline: '登記手続き完了までの期間',
    content: '行政機関が売払代金の納付と必要な書類の提出を確認した後、2週間程度を要します。（郵送事情などによって変更することがあります）',
  },
]
const RightsTransferTimingAndRequiredPeriodAssetSale = () => {
  return (
    <div className="pt-9" data-testid="rights-transfer-timing-and-required-period">
      <SmallTopicTitle text='権利移転の時期と要する期間' isFontBold={true}/>
      <CautionTopicTable recordData={ timingAndRequiredPeriodData } className="mt-3"/>
    </div>
  );
};
export default RightsTransferTimingAndRequiredPeriodAssetSale;