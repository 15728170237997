import {
  useBankProvisionalApplyCompletionInputs
} from "shared/utils/helper/useBankProvisionalApplyCompletionInputs.jsx";

import { BankProvisionalApplyCompletionForm } from "../BankProvisionalApplyCompletionForm.jsx";

export const validateInputsBankProvisionalApplyCompletion = () => {
  const formInputs = useBankProvisionalApplyCompletionInputs();
  const validate = BankProvisionalApplyCompletionForm();

  function handleAuctionBankTransferUrl(e) {
    validate.setters.handleAuctionBankTransferUrlValid(e)
    formInputs.setters.setAuctionBankTransferUrl(e.target.value)
  }

  function handleAuctionJointBiddingUrl(e) {
    validate.setters.handleAuctionJointBiddingUrlValid(e)
    formInputs.setters.setAuctionJointBiddingUrl(e.target.value)
  }

  function handleBidBankTransferUrl(e) {
    validate.setters.handleBidBankTransferUrlValid(e)
    formInputs.setters.setBidBankTransferUrl(e.target.value)
  }

  function handleBidJointBiddingUrl(e) {
    validate.setters.handleBidJointBiddingUrlValid(e)
    formInputs.setters.setBidJointBiddingUrl(e.target.value)
  }


  const validateInputs = {
    data: formInputs.data,
    setters: {
      handleAuctionBankTransferUrl,
      handleAuctionJointBiddingUrl,
      handleBidBankTransferUrl,
      handleBidJointBiddingUrl,
    },
    init: formInputs.setters,
    validationMessage: validate.validationMessage,
  }

  return validateInputs
}