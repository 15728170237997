
export class MailReductionDocument {
  #mailReductionDocument

  constructor(mailReductionDocument) {
    this.#mailReductionDocument = mailReductionDocument;
  }

  get() {
    return this.#mailReductionDocument
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
