import AuctionSchedule from './AuctionSchedule.jsx';
import 'interfaces/css/YearlySchedules/monthly-schedule.css'

const MonthlySchedule = ({ year, month, data }) => {
  return (
    <div className="monthly-schedule flex" data-testid="monthly-schedule">
      <div className="month-name">{month}月開催</div>
      <div className="auction-schedule-wrap" data-testid="auction-schedule-wrap">
        {data['nationalTax'].map((item, index) =>
          <AuctionSchedule
            key={index}
            auctionName="インターネット公売（国税）"
            baseColor="accent-base2"
            schedule={item}
            year={year}
          />
        )}
        {data['assetSale'].map((item, index) =>
          <AuctionSchedule
            key={index}
            auctionName="公有財産売却"
            baseColor="accent-base3"
            schedule={item}
            year={year}
          />
        )}
        {data['publicSale'].map((item, index) =>
          <AuctionSchedule
            key={index}
            auctionName="インターネット公売（地方公共団体など）"
            baseColor="accent-base4"
            schedule={item}
            year={year}
          />
        )}
      </div>
    </div>
  );
};

export default MonthlySchedule;
