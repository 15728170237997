import styled from 'styled-components';

const Wrapper = styled.div`
  height: 24px;
  font-size: 0.77rem;
  font-weight: 400;
  margin: 11px 0 16px 0;
  align-items: flex-end;
`;

const Count = styled.span`
  font-size: 1.15rem;
  line-height: 1.5rem;
  letter-spacing: 0.0333333333em;
  font-weight: 700;
  margin: 0 10px;
  font-family: 'Roboto', sans-serif;
`;

const PropertyCount = ({ count }) => {
  return (
    <Wrapper className="flex justify-flex-start" data-testid="property-count">
      出品物件数：<Count>{count}</Count>件
    </Wrapper>
  );
};

export default PropertyCount;
