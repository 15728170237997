import { DATA_EMPTY } from 'domain/Property/entities/constants/property';

const TableItem = (data) => {
  if (data.title === '出品行政機関') {
    return (
      <tr key={data.id}>
        <th>{data.title}</th>
        <td className="flex align-center">
          <div
            className="v-avatar mr-2"
            style={{ height: '24px', width: '24px' }}>
            <img alt="" src={data.image_url} style={{ width: '100%' }} />
          </div>
          {data.value === DATA_EMPTY ? '' : data.value}
        </td>
      </tr>
    );
  }
  if (data.title === 'メールアドレス') {
    return (
      data.value !== DATA_EMPTY && (
        <tr key={data.id}>
          <th>{data.title}</th>
          <td>
            <a href={'mailto:' + data.value}>{data.value}</a>
          </td>
        </tr>
      )
    );
  }
  if (data.title === '備考') {
    return (
      <tr key={data.id}>
        <th>{data.title}</th>
        <td>{data.value === DATA_EMPTY ? '' : data.value}</td>
      </tr>
    );
  }

  if (data.title === '電話') {
    return (
      data.value !== DATA_EMPTY && (
        <tr key={data.id}>
          <th>{data.title}</th>
          <td>
            {data.value === DATA_EMPTY ? '' : data.value}
            <br />
            {data.extension === DATA_EMPTY ? '' : `（内線 ${data.extension}）`}
          </td>
        </tr>
      )
    );
  }

  return (
    data.value !== DATA_EMPTY && (
      <tr key={data.id}>
        <th>{data.title}</th>
        <td>{data.value}</td>
      </tr>
    )
  );
};
export default TableItem;
