export const switchInputClassName = (isFocus, validateMessage, setBorderClassName) => {
  if (isFocus && validateMessage) {
    setBorderClassName('focus-error-border');
    return;
  }
  if (isFocus) {
    setBorderClassName('focus-border');
    return;
  }
  if (validateMessage) {
    setBorderClassName('blur-error-border');
    return;
  }
  setBorderClassName('normal-border');
};

export const switchInputMessage = (validateMessage, setMessage, caption, isVisibleMessage) => {
  if (!validateMessage) {
    setMessage(caption);
    return;
  }
  if (isVisibleMessage) {
    setMessage(validateMessage);
    return;
  }
  setMessage('');
};
