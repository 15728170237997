import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import PropertyInfoImage from 'interfaces/components/Property/UploadImage/PropertyInfoImage';
import { breadcrumbs } from 'interfaces/pages/UploadImagesImagesGeneral/entities/breadcrumbs';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { UploadImagesImagesGeneralContext } from '../entities/UploadImagesImagesGeneralContext';
//const { properties, isPropertiesLoading } = useProperties()
//const { estate_properties, isEstatePropertiesLoading } = useEstateProperties()
//const { car_properties, isCarPropertiesLoading } = useCarProperties()

const NaviUploadImagesGeneral = () => {
  const { auction_id } = useParams();
  const { invitation } = useContext(UploadImagesImagesGeneralContext);

  return (
    <div data-testid="property-info-images">
      <Breadcrumb breadcrumbs={breadcrumbs(auction_id, invitation?.name)} />
      <Heading2 title="画像登録" />
      <p data-testid="property-info-images-caption">
        画像は1物件あたり最大20ファイルまで登録ができます。
        <br />
        登録可能な画像ファイル形式はJPEG、PNG、GIFで、1ファイルあたりの容量は最大5メガバイトまでです。
        <br />
        物件詳細ページの画像部分に表示するコメントを30文字以内で入力してください。
        <br />
        登録するファイルをすべて指定した後、登録ボタンをクリックしてください。
      </p>
      <PropertyInfoImage element={20} />
    </div>
  );
};

export default NaviUploadImagesGeneral;
