import { useState } from 'react';

export const useAfterWinningBidCautionInputs = () => {
  const [auctionTransRightDescription, setAuctionTransRightDescription] =
    useState('');
  const [auctionCostPropertyDisplay, setAuctionCostPropertyDisplay] =
    useState('DISPLAY');
  const [auctionCostPropertyDescription, setAuctionCostPropertyDescription] =
    useState('');
  const [auctionCostCarDisplay, setAuctionCostCarDisplay] = useState('DISPLAY');
  const [auctionCostCarDescription, setAuctionCostCarDescription] =
    useState('');
  const [auctionCostEstateDisplay, setAuctionCostEstateDisplay] =
    useState('DISPLAY');
  const [auctionCostEstateDescription, setAuctionCostEstateDescription] =
    useState('');
  const [auctionCostAttention, setAuctionCostAttention] = useState('');
  const [auctionDocumentPageDisplay, setAuctionDocumentPageDisplay] =
    useState('DISPLAY');
  const [auctionDocumentPageName, setAuctionDocumentPageName] = useState('');
  const [auctionDocumentPageUrl, setAuctionDocumentPageUrl] = useState('');
  const [auctionDocumentPropertyDisplay, setAuctionDocumentPropertyDisplay] =
    useState('DISPLAY');
  const [
    auctionDocumentPropertyDescription,
    setAuctionDocumentPropertyDescription,
  ] = useState('');
  const [auctionDocumentCarDisplay, setAuctionDocumentCarDisplay] =
    useState('DISPLAY');
  const [auctionDocumentCarDescription, setAuctionDocumentCarDescription] =
    useState('');
  const [auctionDocumentEstateDisplay, setAuctionDocumentEstateDisplay] =
    useState('DISPLAY');
  const [
    auctionDocumentEstateDescription,
    setAuctionDocumentEstateDescription,
  ] = useState('');
  const [auctionDocumentAttention, setAuctionDocumentAttention] = useState('');
  const [
    auctionTransRightPropertyDisplay,
    setAuctionTransRightPropertyDisplay,
  ] = useState('DISPLAY');
  const [
    auctionTransRightPropertyDescription,
    setAuctionTransRightPropertyDescription,
  ] = useState('');
  const [auctionTransRightCarDisplay, setAuctionTransRightCarDisplay] =
    useState('DISPLAY');
  const [auctionTransRightCarDescription, setAuctionTransRightCarDescription] =
    useState('');
  const [auctionTransRightEstateDisplay, setAuctionTransRightEstateDisplay] =
    useState('DISPLAY');
  const [
    auctionTransRightEstateDescription,
    setAuctionTransRightEstateDescription,
  ] = useState('');
  const [auctionTransRightAttention, setAuctionTransRightAttention] =
    useState('');
  const [
    auctionOtherTransRightDescription,
    setAuctionOtherTransRightDescription,
  ] = useState('');
  const [auctionOtherTransRightAttention, setAuctionOtherTransRightAttention] =
    useState('');
  const [
    auctionTransRightDateDescription,
    setAuctionTransRightDateDescription,
  ] = useState('');
  const [auctionImportantRiskBearing, setAuctionImportantRiskBearing] =
    useState('');
  const [
    auctionImportantNonConformingContractsLiability,
    setAuctionImportantNonConformingContractsLiability,
  ] = useState('');
  const [auctionImportantTermsDelivery, setAuctionImportantTermsDelivery] =
    useState('');
  const [
    auctionImportantObligationDelivery,
    setAuctionImportantObligationDelivery,
  ] = useState('');
  const [auctionImportantReturnItems, setAuctionImportantReturnItems] =
    useState('');
  const [auctionImportantStorageCost, setAuctionImportantStorageCost] =
    useState('');
  const [auctionImportantReturnDeposit, setAuctionImportantReturnDeposit] =
    useState('');
  const [auctionImportantOther, setAuctionImportantOther] = useState('');
  const [auctionImportantAttention, setAuctionImportantAttention] =
    useState('');
  const [auctionInquiryDisplay, setAuctionInquiryDisplay] = useState('DISPLAY');
  const [auctionInquiryName, setAuctionInquiryName] = useState('');
  const [auctionInquiryEmail, setAuctionInquiryEmail] = useState('');
  const [auctionInquiryTel, setAuctionInquiryTel] = useState({
    telNum1: '',
    telNum2: '',
    telNum3: '',
  });
  const [auctionInquiryTelNum1, setAuctionInquiryTelNum1] = useState(null);
  const [auctionInquiryTelNum2, setAuctionInquiryTelNum2] = useState(null);
  const [auctionInquiryTelNum3, setAuctionInquiryTelNum3] = useState(null);
  const [auctionInquiryExtension, setAuctionInquiryExtension] = useState('');
  const [auctionInquiryAccept, setAuctionInquiryAccept] = useState('');

  const inputs = {
    data: {
      auctionTransRightDescription,
      auctionCostPropertyDisplay,
      auctionCostPropertyDescription,
      auctionCostCarDisplay,
      auctionCostCarDescription,
      auctionCostEstateDisplay,
      auctionCostEstateDescription,
      auctionCostAttention,
      auctionDocumentPageDisplay,
      auctionDocumentPageName,
      auctionDocumentPageUrl,
      auctionDocumentPropertyDisplay,
      auctionDocumentPropertyDescription,
      auctionDocumentCarDisplay,
      auctionDocumentCarDescription,
      auctionDocumentEstateDisplay,
      auctionDocumentEstateDescription,
      auctionDocumentAttention,
      auctionTransRightPropertyDisplay,
      auctionTransRightPropertyDescription,
      auctionTransRightCarDisplay,
      auctionTransRightCarDescription,
      auctionTransRightEstateDisplay,
      auctionTransRightEstateDescription,
      auctionTransRightAttention,
      auctionOtherTransRightDescription,
      auctionOtherTransRightAttention,
      auctionTransRightDateDescription,
      auctionImportantRiskBearing,
      auctionImportantNonConformingContractsLiability,
      auctionImportantTermsDelivery,
      auctionImportantObligationDelivery,
      auctionImportantReturnItems,
      auctionImportantStorageCost,
      auctionImportantReturnDeposit,
      auctionImportantOther,
      auctionImportantAttention,
      auctionInquiryDisplay,
      auctionInquiryName,
      auctionInquiryEmail,
      auctionInquiryTel,
      auctionInquiryTelNum1,
      auctionInquiryTelNum2,
      auctionInquiryTelNum3,
      auctionInquiryExtension,
      auctionInquiryAccept,
    },
    setters: {
      setAuctionTransRightDescription,
      setAuctionCostPropertyDisplay,
      setAuctionCostPropertyDescription,
      setAuctionCostCarDisplay,
      setAuctionCostCarDescription,
      setAuctionCostEstateDisplay,
      setAuctionCostEstateDescription,
      setAuctionCostAttention,
      setAuctionDocumentPageDisplay,
      setAuctionDocumentPageName,
      setAuctionDocumentPageUrl,
      setAuctionDocumentPropertyDisplay,
      setAuctionDocumentPropertyDescription,
      setAuctionDocumentCarDisplay,
      setAuctionDocumentCarDescription,
      setAuctionDocumentEstateDisplay,
      setAuctionDocumentEstateDescription,
      setAuctionDocumentAttention,
      setAuctionTransRightPropertyDisplay,
      setAuctionTransRightPropertyDescription,
      setAuctionTransRightCarDisplay,
      setAuctionTransRightCarDescription,
      setAuctionTransRightEstateDisplay,
      setAuctionTransRightEstateDescription,
      setAuctionTransRightAttention,
      setAuctionOtherTransRightDescription,
      setAuctionOtherTransRightAttention,
      setAuctionTransRightDateDescription,
      setAuctionImportantRiskBearing,
      setAuctionImportantNonConformingContractsLiability,
      setAuctionImportantTermsDelivery,
      setAuctionImportantObligationDelivery,
      setAuctionImportantReturnItems,
      setAuctionImportantStorageCost,
      setAuctionImportantReturnDeposit,
      setAuctionImportantOther,
      setAuctionImportantAttention,
      setAuctionInquiryDisplay,
      setAuctionInquiryName,
      setAuctionInquiryEmail,
      setAuctionInquiryTel,
      setAuctionInquiryTelNum1,
      setAuctionInquiryTelNum2,
      setAuctionInquiryTelNum3,
      setAuctionInquiryExtension,
      setAuctionInquiryAccept,
    },
  };
  return inputs;
};
