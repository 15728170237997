import AuctionLogo from "./AuctionHeader/AuctionLogo.jsx";
import GovernmentLogo from "./GovernmentHeader/GovernmentLogo.jsx";

const LogoType = (props) => {
  return {
    SERVICE: <AuctionLogo {...props}/>,
    NAVI: <GovernmentLogo {...props}/>,
    KMANAGER: <></>
  }
}

export const Logo = ({ type, props }) => {
  const logo = LogoType(props)
  return logo[type]
};

export default Logo