// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import { AuctionUserId } from "./BlockList/auctionUserId.js"
import { GovernmentId } from "./BlockList/governmentId.js"
import { Memo } from "./BlockList/memo.js";
import { ProcedureType } from "./BlockList/procedureType.js"
import {AccountHolder} from "./List/accountHolder";
import {AccountNumber} from "./List/accountNumber";
import {Address} from "./List/address";
import {BankName} from "./List/bankName";
import {BranchName} from "./List/branchName";
import {CeoName} from "./List/ceoName";
import {Confirmer} from "./List/confirmer";
import {ContactDepartment} from "./List/contactDepartment";
import {ContactPaEmail} from "./List/contactPaEmail";
import {ContactPerson} from "./List/contactPerson";
import {ContactPpsEmail} from "./List/contactPpsEmail";
import {DepositType} from "./List/depositType";
import {Id} from "./List/id";
import {IsNextBuy} from "./List/isNextBuy";
import {Issuer} from "./List/issuer";
import {MembershipId} from "./List/membershipId";
import {Name} from "./List/name";
import {Position} from "./List/position";
import {PostalCode} from "./List/postalCode";
import {Price} from "./List/price";
import {PropertyId} from "./List/propertyId";
import {RegistrationNumber} from "./List/registrationNumber";
import {Remarks} from "./List/remarks";
import {Time} from "./List/time";
import {UpdatedAt} from "./List/updatedAt";
import CustomError from "../../../shared/utils/helper/customError.js";
import { NotUndefined } from "../../../shared/utils/helper/NotUndefined.js";

export class BidderListGet {
  constructor(data) {
    this.propertyId = data.propertyId;
    this.auctionUserId = data.auctionUserId;
    this.price = data.price;
    this.time = data.time;
    this.isNextBuy = data.isNextBuy;
    //if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return NotUndefined(this)
  }
}


export class BlockListGet {
  constructor(data) {
    this.governmentId = data.governmentId;
    this.auctionUserId = data.auctionUserId;
    this.procedureType = data.procedureType;
    this.memo = data.memo;
    this.createdAt = data.createdAt;
    //this.updated_at = data.updated_at;
    //this.membership_id = data.membership_id;
    //if (this.isValid) throw new CustomError("block listの値が不正です")
  }

  get isValid() {
    return NotUndefined(this)
  }
}

export class ServiceProviderGet {

  constructor(data) {
    this.id = data.id
    this.postalCode = data.postalCode
    this.address = data.address
    this.name = data.name
    this.position = data.position
    this.ceoName = data.ceoName
    this.registrationNumber = data.registrationNumber
    this.bankName = data.bankName
    this.branchName = data.branchName
    this.depositType = data.depositType
    this.accountNumber = data.accountNumber
    this.accountHolder = data.accountHolder
    this.contactPerson = data.contactPerson
    this.contactDepartment = data.contactDepartment
    this.contactPaEmail = data.contactPaEmail
    this.contactPpsEmail = data.contactPpsEmail
    this.confirmer = data.confirmer
    this.issuer = data.issuer
    this.remarks = data.remarks
    //if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return NotUndefined(this)
  }
}

export class BidderListCreate {

  constructor(data) {
    this.property_id = new PropertyId(data.property_id);
    this.auction_user_id = new AuctionUserId(data.auction_user_id);
    this.price = new Price(data.price);
    this.time = new Time(data.time);
    this.is_next_buy = new IsNextBuy(data.is_next_buy);
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return this.property_id.isValid &&
      this.auction_user_id.isValid &&
      this.price.isValid &&
      this.time.isValid &&
      this.is_next_buy.isValid
  }
}

export class BlockListCreate {

  constructor(data) {
    this.government_id = new GovernmentId(data.government_id);
    this.auction_user_id = new AuctionUserId(data.auction_user_id);
    this.procedure_type = new ProcedureType(data.procedure_type);
    this.memo = new Memo(data.memo);
    this.updated_at = new UpdatedAt(data.updated_at);
    this.membership_id = new MembershipId(data.membership_id);
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return this.government_id.isValid &&
      this.auction_user_id.isValid &&
      this.procedure_type.isValid &&
      this.memo.isValid &&
      this.updated_at.isValid &&
      this.membership_id.isValid
  }
}

export class UpdateServiceProvider {

  constructor(data) {
    this.id = new Id(data.id)
    this.postalCode = new PostalCode(data.postalCode)
    this.address = new Address(data.address)
    this.name = new Name(data.name)
    this.position = new Position(data.position)
    this.ceoName = new CeoName(data.ceoName)
    this.registrationNumber = new RegistrationNumber(data.registrationNumber)
    this.bankName = new BankName(data.bankName)
    this.branchName = new BranchName(data.branchName)
    this.depositType = new DepositType(data.depositType)
    this.accountNumber = new AccountNumber(data.accountNumber)
    this.accountHolder = new AccountHolder(data.accountHolder)
    this.contactPerson = new ContactPerson(data.contactPerson)
    this.contactDepartment = new ContactDepartment(data.contactDepartment)
    this.contactPaEmail = new ContactPaEmail(data.contactPaEmail)
    this.contactPpsEmail = new ContactPpsEmail(data.contactPpsEmail)
    this.confirmer = new Confirmer(data.confirmer)
    this.issuer = new Issuer(data.issuer)
    this.remarks = new Remarks(data.remarks)
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return this.id.isValid &&
      this.postalCode.isValid &&
      this.address.isValid &&
      this.name.isValid &&
      this.position.isValid &&
      this.ceoName.isValid &&
      this.registrationNumber.isValid &&
      this.bankName.isValid &&
      this.branchName.isValid &&
      this.depositType.isValid &&
      this.accountNumber.isValid &&
      this.accountHolder.isValid &&
      this.contactPerson.isValid &&
      this.contactDepartment.isValid &&
      this.contactPaEmail.isValid &&
      this.contactPpsEmail.isValid &&
      this.confirmer.isValid &&
      this.issuer.isValid &&
      this.remarks.isValid
  }
}

export class BlockListMemoCreate {

  constructor(data) {
    this.governmentId = new GovernmentId(data.governmentId);
    this.auctionUserId = new AuctionUserId(data.auctionUserId);
    this.procedureType = new ProcedureType(data.procedureType);
    this.memo = new Memo(data.memo);
    this.updatedAt = new data.updatedAt;
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return this.governmentId.isValid &&
      this.auctionUserId.isValid &&
      this.procedureType.isValid &&
      this.memo.isValid
  }
}
