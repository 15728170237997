import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle.jsx';

import MonthlySchedule from './MonthlySchedule.jsx';

const AnnualSchedule = ({ year, data }) => {
  if (!data) {
    return;
  }

  const monthNumbers = Array.from({ length: 12 }, (_, i) => (i + 1));

  return (
    <div data-testid="annual-schedule">
      <ArticleDefaultTitle title={`${year}年のスケジュール`}/>
      <ArticleDefaultBody>
        {monthNumbers.map((monthNumber, index) =>
          data[monthNumber] &&
            <MonthlySchedule
              key={index}
              year={year}
              month={monthNumber}
              data={data[monthNumber]}
            />
        )}
      </ArticleDefaultBody>
    </div>
  );
};

export default AnnualSchedule;
