import { useEffect, useState } from 'react';

import { findById as getGovernment } from '../../../../domain/Government/services/Government.js';
import { findByGovernmentId as getAssetSaleCautionPage } from '../../../../domain/Setting/services/AssetSaleCaution.js';
import { findById as getBankTransferPage } from '../../../../domain/Setting/services/BankTransferPages.js';
import { findById as getCreditCardPage } from '../../../../domain/Setting/services/CreditCardPage.js';
import { findById as getDocumentSendPage } from '../../../../domain/Setting/services/DocumentSendPages.js';
import { findById as getGovernmentTopPage } from '../../../../domain/Setting/services/GovernmentTopPage.js';
import { findById as getGuideline } from '../../../../domain/Setting/services/Guidelines.js';
import { findByGovernmentId as getPublicSaleCautionPage } from '../../../../domain/Setting/services/PublicSaleCaution.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { NAVI } from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { LocalGovernmentManagementContext } from '../entities/LocalGovernmentManagementContext.js';

const LocalGovernmentManagementProvider = ({ type, children }) => {
  const { procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };

  const [government, setGovernments] = useState(undefined);
  const [governmentTopPages, setGovernmentTopPages] = useState([]);
  const [creditCardPages, setCreditCardPages] = useState([]);
  const [bankTransferPages, setBankTransferPages] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [documentSendPages, setDocumentSendPages] = useState([]);
  const [assetSaleCautionPage, setAssetSaleCautionPage] = useState([]);
  const [publicSaleCautionPages, setPublicSaleCautionPages] = useState([]);

  const { localStorageGovernmentId } = useLocalStorage();

  // データの取得、加工等を行う
  if (type === NAVI) {
    useEffect(() => {
      getGovernment(type, localStorageGovernmentId).then((result) => {
        setGovernments(result);
      });
      getGovernmentTopPage(type, localStorageGovernmentId).then((result) => {
        setGovernmentTopPages(result);
      });
      getCreditCardPage(type, localStorageGovernmentId).then((result) => {
        setCreditCardPages(result);
      });
      getBankTransferPage(type, localStorageGovernmentId).then((result) => {
        setBankTransferPages(result);
      });
      getGuideline(type, localStorageGovernmentId).then((result) => {
        setGuidelines(result);
      });
      getDocumentSendPage(type, localStorageGovernmentId).then((result) => {
        setDocumentSendPages(result);
      });
      getAssetSaleCautionPage(type, localStorageGovernmentId).then((result) => {
        setAssetSaleCautionPage(result);
      });
      getPublicSaleCautionPage(type, localStorageGovernmentId).then(
        (result) => {
          setPublicSaleCautionPages(result);
        },
      );
    }, [localStorageGovernmentId]);
  }

  return (
    <LocalGovernmentManagementContext.Provider
      value={{
        authState,
        breadcrumbs,
        procedureType,
        bankTransferPages,
        creditCardPages,
        governmentTopPages,
        assetSaleCautionPage,
        guidelines,
        publicSaleCautionPages,
        documentSendPages,
        government,
      }}>
      {children}
    </LocalGovernmentManagementContext.Provider>
  );
};

export default LocalGovernmentManagementProvider;
