import LocalGovernmentManagementAfterWinningBidProvider from './LocalGovernmentManagementAfterWinningBidProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { localGovernmentManagementAfterWinningBid } from "../entities/componentMapping.js";

const LocalGovernmentManagementAfterWinningBid = ({ type }) => {
  return (
    <LocalGovernmentManagementAfterWinningBidProvider type={type}>
      {getComponent(localGovernmentManagementAfterWinningBid, type)}
    </LocalGovernmentManagementAfterWinningBidProvider>
  );
};

export default LocalGovernmentManagementAfterWinningBid;
