import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();

export const fetchGovernmentUsersStorage = async () => {
  try {
    const response = await fetchGovernmentUsers(`${BASE_URL_NAVIGATION}/storage/current/government_users/data.json`);
    return extractGovernmentUsers(response);
  } catch (error) {
    logger.warn({ error }, "現在のナビユーザ一覧（ government_users ）を取得できませんでした");

    try {
      const response = await fetchGovernmentUsers(`${BASE_URL_NAVIGATION}/storage/previous/government_users/data.json`);
      return extractGovernmentUsers(response);
    } catch (error) {
      logger.error({ error }, "以前のナビユーザ一覧（ government_users ）の取得にも失敗しました");
      return [];
    }
  }
}

export const fetchGovernmentUsers = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractGovernmentUsers = (response) => {
  return response.map(governmentUser => governmentUser);
}