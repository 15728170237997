// ロジックを含まない処理の実装

import { fetchAuctionUsersStorage } from 'infrastructure/api/storage/auction_users.js';
import { KMANAGER, NAVI, SERVICE } from 'shared/utils/helper/constants.js';
import CustomError from 'shared/utils/helper/customError.js';
import { MyUserInfoDataGet } from '../entities/ServiceUser.js';

// getメソッドの実装
export async function getAuctionUsers(type) {
  let response = [];
  if (type === SERVICE) {
    response = await fetchAuctionUsersStorage();
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (User)');
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (User)');
  }

  if (response instanceof Error) {
    throw new CustomError('Error get Users', response);
  }

  let results = [];
  response.map((data) => {
    const result = new MyUserInfoDataGet(data);
    results.push(result);
  });

  return results;
}
