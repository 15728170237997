import camelizeKeys from "./camelize.js";
import CustomError from './customError.js';
// import pino from "pino";

// const logger = pino();
export const fetchData = async (path) => {
  try {
    // logger.info('Place order start');
    const response = await fetch(path);
    // logger.info('Fetch data', response);
    return camelizeKeys(await response.json());
  } catch (error) {
    // logger.error('Fetch data failed', error);
    throw new CustomError(`Failed to fetch or parse JSON:  ${path}`);
  }
};

export const allFetch = async (paths) => {
  const promises = Object.values(paths).map(fetchData);
  return await Promise.all(promises);
}