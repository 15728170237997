
export class DisplayDeadline {
  #displayDeadline

  constructor(displayDeadline) {
    this.#displayDeadline = displayDeadline;
  }

  get() {
    return this.#displayDeadline
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
