import { useContext } from 'react';
import 'interfaces/css/ConfirmSystemUsage/calc-method-table.css';

const RATIO = 'RATIO';
const YEN = '円';
const PERCENT = '%';

const TAX_TYPE_TO_JP = {
  TOTAL_TAXATION: '合算一括課税',
  INDIVIDUAL_TAXATION: '個別課税',
  INDIVIDUAL_CONTRACT_TAXATION: '個別契約課税',
};

import { BillingContext } from '../../pages/Billing/entities/BillingContext';

const CalcMethodTable = () => {
  const { government } = useContext(BillingContext);

  function getUnit(bidFeeType) {
    if (bidFeeType === RATIO) {
      return PERCENT;
    }
    return YEN;
  }

  function getFeeValue(fee, type) {
    if (getUnit(type) === PERCENT) {
      return `落札合計の${(parseFloat(fee) * 100).toFixed(0)}`;
    }
    return parseFloat(fee);
  }

  return (
    <table className="calc-method-table" data-testid="calc-method-table">
      <thead>
        <tr data-testid="table-row">
          <th>消費税額計算</th>
          <th>種別</th>
          <th>落札システム利用料</th>
        </tr>
      </thead>
      <tbody>
        <tr data-testid="table-row">
          <td rowSpan="3">{TAX_TYPE_TO_JP[government?.taxType]}</td>
          <td>動産</td>
          <td className="text-right">
            {getFeeValue(
              government?.movableBidFee,
              government?.movableBidFeeType,
            )}
            {getUnit(government?.movableBidFeeType)}
          </td>
        </tr>
        <tr data-testid="table-row">
          <td>自動車</td>
          <td className="text-right">
            {getFeeValue(government?.carBidFee, government?.carBidFeeType)}
            {getUnit(government?.carBidFeeType)}
          </td>
        </tr>
        <tr data-testid="table-row">
          <td>不動産</td>
          <td className="text-right">
            {getFeeValue(
              government?.estateBidFee,
              government?.estateBidFeeType,
            )}
            {getUnit(government?.estateBidFeeType)}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CalcMethodTable;
