import { validateArea } from "../../../../application/validations.js";

export class Location {
  #location

  constructor(location) {
    this.#location = location;
  }

  get() {
    return this.#location
  }

  get isValid() {
    return [
      this.validateArea,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateArea() {
    return validateArea(this.#location)
  }
}
