import { useAuctionInputs } from '../AuctionNew/hook/useAuctionInputs.jsx';
import { useAuctionInputsValidation } from '../AuctionNew/hook/useAuctionInputsValidation';
import {
  get_radio_label,
  government_type_groups,
  procedure_types,
  gov_procedure_types_for_national_tax_agency,
  public_sale_types,
  public_sale_types_for_national_tax_agency,
} from 'shared/utils/helper/radio_labels.js';
import { deposit_handing } from 'shared/utils/helper/select_box.js';

export const validateInputsAuctionForm = (auction) => {
  const formInputs = useAuctionInputs(auction);
  const validate = useAuctionInputsValidation(auction !== undefined);

  function handleName(e) {
    validate.setters.name(e);
    formInputs.setters.name(e.target.value);
    console.log(validate.validationMessage.name);
  }

  function handleGovernmentTypeGroup(e) {
    formInputs.setters.governmentTypeGroup(e.target.value);
    console.log(validate.validationMessage.governmentTypeGroup);
  }

  function handleProcedureType(e) {
    formInputs.setters.procedureType(e.target.value);
    console.log(validate.validationMessage.procedureType);
  }

  function handlePublicSaleType(e) {
    formInputs.setters.publicSaleType(e.target.value);
    console.log(validate.validationMessage.publicSaleType);
  }

  function handleGovAplStart(datetime) {
    console.log('datetime', datetime);
    validate.setters.govAplStart(datetime);
    formInputs.setters.govAplStart(datetime);
    console.log(validate.validationMessage.govAplStart);
    console.log(formInputs.data.govAplStart);
  }

  function handleGovAplEnd(datetime) {
    validate.setters.govAplEnd(datetime, formInputs.data.govAplStart);
    formInputs.setters.govAplEnd(datetime);
    console.log(validate.validationMessage.govAplEnd);
  }

  function handleAplStart(datetime) {
    validate.setters.aplStart(datetime);
    formInputs.setters.aplStart(datetime);
    console.log(validate.validationMessage.aplStart);
  }

  function handleAplEnd(datetime) {
    validate.setters.aplEnd(datetime, formInputs.data.aplStart);
    formInputs.setters.aplEnd(datetime);
    console.log(validate.validationMessage.aplEnd);
  }

  function handleBidStart(datetime) {
    validate.setters.bidStart(datetime, formInputs.data.aplEnd);
    formInputs.setters.bidStart(datetime);
    console.log(validate.validationMessage.bidStart);
  }

  function handleAuctionEnd(datetime) {
    validate.setters.auctionEnd(datetime, formInputs.data.bidStart);
    formInputs.setters.auctionEnd(datetime);
    console.log(validate.validationMessage.auctionEnd);
  }

  function handleBidEnd(datetime) {
    validate.setters.bidEnd(datetime, formInputs.data.bidStart);
    formInputs.setters.bidEnd(datetime);
    console.log(validate.validationMessage.bidEnd);
  }

  function handleAddBidDuration(datetime) {
    validate.setters.addBidDuration(datetime);
    formInputs.setters.addBidDuration(datetime);
    console.log(validate.validationMessage.addBidDuration);
  }

  function handleBidDecision(datetime) {
    validate.setters.bidDecision(
      datetime,
      formInputs.data.auctionEnd,
      formInputs.data.bidEnd,
    );
    formInputs.setters.bidDecision(datetime);
    console.log(validate.validationMessage.bidDecision);
  }

  function handleOpenEnd(datetime) {
    validate.setters.openEnd(
      datetime,
      formInputs.data.auctionEnd,
      formInputs.data.bidEnd,
    );
    formInputs.setters.openEnd(datetime);
    console.log(validate.validationMessage.openEnd);
  }

  function handleNextPaymentLimit(datetime) {
    validate.setters.nextPaymentLimit(datetime, formInputs.data.bidDecision);
    formInputs.setters.nextPaymentLimit(datetime);
    console.log(validate.validationMessage.nextPaymentLimit);
  }

  function handleEstateNextPaymentLimit(datetime) {
    validate.setters.estateNextPaymentLimit(
      datetime,
      formInputs.data.bidDecision,
    );
    formInputs.setters.estateNextPaymentLimit(datetime);
    console.log(validate.validationMessage.estateNextPaymentLimit);
  }

  function handleDepositType(e) {
    validate.setters.depositType(e);
    formInputs.setters.depositType(e.target.value);
    console.log(validate.validationMessage.depositType);
  }

  return [
    {
      type: formInputs.type.name,
      column: 'name',
      text: '開催名',
      validationMessage: validate.validationMessage.name,
      state: formInputs.data.name,
      init: formInputs.setters.name,
      setters: handleName,
      required: true,
    },
    {
      type: formInputs.type.governmentTypeGroup,
      column: 'governmentTypeGroup',
      text: '対象行政機関種別',
      validationMessage: 'TRUE',
      list: government_type_groups,
      state: formInputs.data.governmentTypeGroup,
      init: formInputs.setters.governmentTypeGroup,
      setters: handleGovernmentTypeGroup,
      required: true,
    },
    {
      type: formInputs.type.procedureType,
      column: 'procedureType',
      text: '手続き種別',
      validationMessage: 'TRUE',
      list:
        formInputs.data.governmentTypeGroup === 'NATIONAL_TAX_AGENCY'
          ? gov_procedure_types_for_national_tax_agency
          : procedure_types,
      state: formInputs.data.procedureType,
      init: formInputs.setters.procedureType,
      setters: handleProcedureType,
      required: true,
    },
    {
      type: formInputs.type.publicSaleType,
      column: 'publicSaleType',
      text: '公売方法選択',
      validationMessage: 'TRUE',
      list:
        formInputs.data.governmentTypeGroup === 'NATIONAL_TAX_AGENCY'
          ? public_sale_types_for_national_tax_agency
          : public_sale_types,
      state: formInputs.data.publicSaleType,
      init: formInputs.setters.publicSaleType,
      setters: handlePublicSaleType,
      required: true,
    },
    {
      type: formInputs.type.govAplStart,
      column: 'govAplStart',
      text: '行政機関申込開始日時',
      validationMessage: validate.validationMessage.govAplStart,
      state: formInputs.data.govAplStart,
      init: formInputs.setters.govAplStart,
      setters: handleGovAplStart,
      required: true,
    },
    {
      type: formInputs.type.govAplEnd,
      column: 'govAplEnd',
      text: '行政機関申込締切日時',
      validationMessage: validate.validationMessage.govAplEnd,
      state: formInputs.data.govAplEnd,
      init: formInputs.setters.govAplEnd,
      setters: handleGovAplEnd,
      required: true,
    },
    {
      type: formInputs.type.aplStart,
      column: 'aplStart',
      text: '参加申込開始日時',
      validationMessage: validate.validationMessage.aplStart,
      state: formInputs.data.aplStart,
      init: formInputs.setters.aplStart,
      setters: handleAplStart,
      required: true,
    },
    {
      type: formInputs.type.aplEnd,
      column: 'aplEnd',
      text: '参加申込締切日時',
      validationMessage: validate.validationMessage.aplEnd,
      state: formInputs.data.aplEnd,
      init: formInputs.setters.aplEnd,
      setters: handleAplEnd,
      required: true,
    },
    {
      type: formInputs.type.bidStart,
      column: 'bidStart',
      text: '入札開始日時',
      validationMessage: validate.validationMessage.bidStart,
      state: formInputs.data.bidStart,
      init: formInputs.setters.bidStart,
      setters: handleBidStart,
      required: true,
    },
    formInputs.data.publicSaleType !== 'BID' && {
      type: formInputs.type.auctionEnd,
      column: 'auctionEnd',
      text: '入札終了日時(せり)',
      validationMessage: validate.validationMessage.auctionEnd,
      state: formInputs.data.auctionEnd,
      init: formInputs.setters.auctionEnd,
      setters: handleAuctionEnd,
      required: true,
    },
    formInputs.data.publicSaleType !== 'AUCTION' && {
      type: formInputs.type.bidEnd,
      column: 'bidEnd',
      text: '入札終了日時(入札)',
      validationMessage: validate.validationMessage.bidEnd,
      state: formInputs.data.bidEnd,
      init: formInputs.setters.bidEnd,
      setters: handleBidEnd,
      required: true,
    },
    formInputs.data.publicSaleType !== 'AUCTION' && {
      type: formInputs.type.addBidDuration,
      column: 'addBidDuration',
      text: '追加入札時間',
      validationMessage: validate.validationMessage.addBidDuration,
      state: formInputs.data.addBidDuration,
      init: formInputs.setters.addBidDuration,
      setters: handleAddBidDuration,
      required: true,
    },
    {
      type: formInputs.type.bidDecision,
      column: 'bidDecision',
      text: '入札確定処理日時',
      validationMessage: validate.validationMessage.bidDecision,
      state: formInputs.data.bidDecision,
      init: formInputs.setters.bidDecision,
      setters: handleBidDecision,
      required: true,
    },
    formInputs.data.publicSaleType !== 'AUCTION' && {
      type: formInputs.type.nextPaymentLimit,
      column: 'nextPaymentLimit',
      text: '次順位買受代金納付期限（動産・自動車）',
      validationMessage: validate.validationMessage.nextPaymentLimit,
      state: formInputs.data.nextPaymentLimit,
      init: formInputs.setters.nextPaymentLimit,
      setters: handleNextPaymentLimit,
      required: true,
    },
    formInputs.data.publicSaleType !== 'AUCTION' && {
      type: formInputs.type.estateNextPaymentLimit,
      column: 'estateNextPaymentLimit',
      text: '次順位買受代金納付期限（不動産）',
      validationMessage: validate.validationMessage.estateNextPaymentLimit,
      state: formInputs.data.estateNextPaymentLimit,
      init: formInputs.setters.estateNextPaymentLimit,
      setters: handleEstateNextPaymentLimit,
      required: true,
    },
    {
      type: formInputs.type.openEnd,
      column: 'openEnd',
      text: '公開終了日',
      validationMessage: validate.validationMessage.openEnd,
      state: formInputs.data.openEnd,
      init: formInputs.setters.openEnd,
      setters: handleOpenEnd,
      required: true,
    },
    {
      type: formInputs.type.depositType,
      column: 'depositType',
      text: '保証金の取り扱い',
      validationMessage: 'TRUE',
      list: deposit_handing,
      state: formInputs.data.depositType,
      init: formInputs.setters.depositType,
      setters: handleDepositType,
      hasLabel: get_radio_label(
        deposit_handing,
        'value',
        formInputs.data.depositType,
      ),
      required: true,
    },
  ].filter((item) => item);
};
