import styled from 'styled-components';

import ScheduleTopic from './RightSideMenu/ScheduleTopic/ScheduleTopic.jsx';
import NotificationTopic from './RightSideMenu/NotificationTopic/NotificationTopic.jsx';
import HelpTopic from './RightSideMenu/HelpTopic/HelpTopic.jsx';

const Wrapper = styled.span`
  width: 200px;
  flex-shrink: 0;
  margin: 0 0 0 16px;

  @media (max-width: 719px) {
    margin: 0;
    width: 100%;
  }
`;

export const RightSideMenu = () => {
  return (
    <Wrapper data-testid="right-side-menu">
      <ScheduleTopic />
      <NotificationTopic />
      <HelpTopic />
    </Wrapper>
  );
};

export default RightSideMenu;
