
export class IsFarmlandInfo {
  #isFarmlandInfo

  constructor(isFarmlandInfo) {
    this.#isFarmlandInfo = isFarmlandInfo;
  }

  get() {
    return this.#isFarmlandInfo
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
