const isValidEmail = (inputEmail) => {
  // MEMO: @記号の前後に空白がなく、ドメイン名の部分に少なくとも一つの"."が含まれていること
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(inputEmail);
};

const isValidPassword = (inputPassword) => {
  // MEMO: 一つの小文字、大文字、数字を含み、合計で8文字以上であること
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  return pattern.test(inputPassword);
}

const isOnlyNum = (inputValue) => {
  // MEMO: 半角数字のみ含まれていること
  const pattern = /^[0-9]*$/;
  return pattern.test(inputValue);
}

const isValidTel = (inputTel) => {
  const inputRemovedUnwatchedSymbols = inputTel.replace(/[━‐―－\-ー.*]/gi, '');
  const pattern = /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/;
  return pattern.test(inputRemovedUnwatchedSymbols);
}

export const useValidate = () => {
  const handleValidates = (inputValue, validates, password) => {
    // MEMO: validatesの順番が、表示されるメッセージの優先順位順になる
    for (let validate of validates) {
      if (validate === 'no_message_required' && !inputValue) {
        return 'NO_MESSAGE';
      }
      if (validate === 'required' && !inputValue) {
        return '入力してください';
      }
      if (validate === 'email' && !isValidEmail(inputValue)) {
        return 'メールアドレスが正しくありません。'
      }
      if (validate === 'password' && !isValidPassword(inputValue)) {
        return '半角のアルファベットの小文字と大文字、半角数字を８文字以上組み合わせる必要があります。'
      }
      if (validate === 'confirm_password' && inputValue !== password) {
        return 'パスワードが一致しません。'
      }
      if (validate === 'only_num' && !isOnlyNum(inputValue)) {
        return '半角数字で入力してください。'
      }
      if (validate === 'tel' && !isValidTel(inputValue)) {
        return '無効な電話番号です。'
      }
      // TODO: 他のバリデーションルールをここに追加する(優先順位はvalidatesが制御するので、if文の順番は気にしなくて良い)
    }
    return '';
  };

  return { handleValidates };
};
