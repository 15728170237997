import { useEffect, useState } from 'react';
import { pulldown_month } from 'shared/utils/helper/Pulldown';

import SelectBox from './SelectBox';
import CautionDescription from '../../../Form/navi/CautionDescription';
import Label from '../../../Form/navi/Label';

import 'interfaces/css/common/property-info-car-form-date-pulldown.css';

const FormDatePullDown = ({
  caution_description,
  data,
  handler = () => {},
  label,
  noDay = false,
  required = false,
}) => {
  if (data === null || data === undefined) {
    return <></>;
  }

  const lastYear = 1955; // 年のセレクトボックスの最小の年
  const [yearList, setYearList] = useState([]);
  const [dayList, setDayList] = useState([]);
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');

  const yearText = (year) => {
    let tmpYear = Number(year);
    if (isNaN(tmpYear)) {
      return '';
    }

    let tmp = new Date(year, 0, 1);
    let wareki = tmp.toLocaleString('ja-JP-u-ca-japanese', {
      era: 'short',
      year: 'numeric',
    });
    if (year === 2019) {
      wareki = '平成31年/令和元年';
    }
    if (year === 1989) {
      wareki = '昭和64年/平成元年';
    }
    return `${wareki}(${tmpYear}年)`;
  };

  // 初期化
  useEffect(() => {
    if (data && data?.year !== '') {
      setYear(data.year);
      setMonth(data.month);
      setDay(data.day);
    }

    let list = [];
    let today = new Date();

    for (let i = today.getFullYear(); i > lastYear; i--) {
      list.push({ id: `${i}`, text: yearText(i), value: `${i}` });
    }
    setYearList(list);
  }, [data]);

  // 月が変更された時、日付のリストを変更する
  useEffect(() => {
    let list = [];
    let endDate = new Date(Number(year), Number(month), 0);
    for (let i = 1; i <= endDate.getDate(); i++) {
      list.push({ id: `${i}`, text: `${i}`, value: `${i}` });
    }
    setDayList(list);
  }, [year, month]);

  const handlerYear = (e) => {
    setYear(e.target.value);
    let updateParam = { year: e.target.value };
    handlerDate(updateParam);
  };

  const handlerMonth = (e) => {
    setMonth(e.target.value);
    let updateParam = { month: e.target.value };
    handlerDate(updateParam);
  };

  const handlerDay = (e) => {
    setDay(e.target.value);
    let updateParam = { day: e.target.value };
    handlerDate(updateParam);
  };

  const handlerDate = (updateParam) => {
    let hashDate = {
      year: year,
      month: month,
      day: day,
    };

    // setStateではすぐに更新されないため、更新された値をハッシュに追加してhandlerに渡す
    hashDate = { ...hashDate, ...updateParam };

    handler(hashDate);
  };

  return (
    <div className="property-info-general-form-table-row">
      <Label label={label} required={required} />
      <div className="property-info-general-form-table-cell">
        <div className="property-info-car-form-date-pulldown new-style_navi_form-date-pulldown">
          <div className="new-style_navi_form-date-pulldown_year">
            <SelectBox
              handler={handlerYear}
              listItemData={yearList}
              name={'year'}
              selectedTextData={year !== '' ? yearText(year) : ''}
              width={'18em'}
            />
          </div>
          <div className="ml-2">年</div>
          <div className="ml-2 new-style_navi_form-date-pulldown_md">
            <SelectBox
              handler={handlerMonth}
              listItemData={pulldown_month}
              name={'month'}
              selectedTextData={month}
              width={'6em'}
            />
          </div>
          <div className="ml-2">月</div>
          {noDay ? (
            <></>
          ) : (
            <>
              <div className="ml-2 new-style_navi_form-date-pulldown_md">
                <SelectBox
                  handler={handlerDay}
                  name={'day'}
                  listItemData={dayList}
                  selectedTextData={day}
                  width={'6em'}
                />
              </div>
              <div className="ml-2">日</div>
            </>
          )}
        </div>
        <CautionDescription data={caution_description} />
      </div>
    </div>
  );
};

export default FormDatePullDown;
