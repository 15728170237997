// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-up-agreement-caption {
  text-align: center;
}

.sign-up-agreement-button-wrap {
  display: flex;
  justify-content: center;
  padding: 8px;
  margin: 16px 0;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/sign-up-form.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".sign-up-agreement-caption {\n  text-align: center;\n}\n\n.sign-up-agreement-button-wrap {\n  display: flex;\n  justify-content: center;\n  padding: 8px;\n  margin: 16px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
