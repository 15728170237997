import { useMessageInputs } from './hook/useMessageInputs.jsx';
import { useMessageInputsValidation } from './hook/useMessageInputsValidation.jsx';
//import {
//  display_for,
//  display_for_contract,
//  display_for_government,
//  is_important
//} from "../../../kmanager/helper/test_helper/dummy_kmanager_message.js";

export const display_for = [
  { id: 1, text: 'サービス画面', name: 'service' },
  { id: 2, text: '官公庁ナビ', name: 'navi' },
];

export const display_for_contract = [
  { id: 1, text: '公売', name: 'publicSale' },
  { id: 2, text: '公有財産売却', name: 'assetSale' },
];

export const display_for_government = [
  { id: 2, text: '地方自治体', name: 'localGovernment' },
  { id: 1, text: '国税', name: 'nationalTaxAgency' },
];

export const is_important = [
  { id: 1, text: '重要な場合はチェックしてください', name: 'severityType' },
];

export const validateInputsMessageFormModal = (message) => {
  const formInputs = useMessageInputs(message);
  const validate = useMessageInputsValidation();

  function handleDisplayServiceType(e) {
    validate.setters.displayServiceType(e, {
      ...formInputs.data.displayServiceType,
      ...{ [e.target.name]: e.target.checked ? 'TRUE' : '' },
    });
    formInputs.setters.displayServiceType({
      ...formInputs.data.displayServiceType,
      ...{ [e.target.name]: e.target.checked ? 'TRUE' : '' },
    });
  }
  function handleDisplayProcedureType(e) {
    validate.setters.displayProcedureType(e, {
      ...formInputs.data.displayProcedureType,
      ...{ [e.target.name]: e.target.checked ? 'TRUE' : '' },
    });
    formInputs.setters.displayProcedureType({
      ...formInputs.data.displayProcedureType,
      ...{ [e.target.name]: e.target.checked ? 'TRUE' : '' },
    });
  }
  function handleDisplayGovernmentType(e) {
    validate.setters.displayGovernmentType(e, {
      ...formInputs.data.displayGovernmentType,
      ...{ [e.target.name]: e.target.checked ? 'TRUE' : '' },
    });
    formInputs.setters.displayGovernmentType({
      ...formInputs.data.displayGovernmentType,
      ...{ [e.target.name]: e.target.checked ? 'TRUE' : '' },
    });
  }
  function handleOpenAt(e) {
    validate.setters.openAt(e);
    formInputs.setters.openAt(e);
  }
  function handleCloseAt(e) {
    validate.setters.closeAt(e, formInputs.data.openAt);
    formInputs.setters.closeAt(e);
  }
  function handleTitle(e) {
    validate.setters.title(e);
    formInputs.setters.title(e.target.value);
  }
  function handleBody(e) {
    validate.setters.body(e);
    formInputs.setters.body(e.target.value);
  }
  function handleSeverityType(e) {
    // formInputs.setters.severityType(e, { ...formInputs.data.severityType, ...{ [e.target.name]: e.target.checked ? 'TRUE' : '' } })
    formInputs.setters.severityType({
      [e.target.name]: e.target.checked ? 'TRUE' : '',
    });
  }

  const validateInputs = [
    {
      type: formInputs.type.displayServiceType,
      column: 'displayServiceType',
      text: 'お知らせ表示先システム',
      list: display_for,
      validationMessage: validate.validationMessage.displayServiceType,
      state: formInputs.data.displayServiceType,
      init: formInputs.setters.displayServiceType,
      setters: handleDisplayServiceType,
      required: true,
    },
    {
      type: formInputs.type.displayProcedureType,
      column: 'displayProcedureType',
      text: 'お知らせ表示先（契約種別）',
      list: display_for_contract,
      validationMessage: validate.validationMessage.displayProcedureType,
      state: formInputs.data.displayProcedureType,
      init: formInputs.setters.displayProcedureType,
      setters: handleDisplayProcedureType,
      required: true,
      describe:
        '表示先が官公庁ナビの場合は必須です。特に区別しない場合は両方選択してください。',
    },
    {
      type: formInputs.type.displayGovernmentType,
      column: 'displayGovernmentType',
      text: 'お知らせ表示先(行政機関種別)',
      list: display_for_government,
      validationMessage: validate.validationMessage.displayGovernmentType,
      state: formInputs.data.displayGovernmentType,
      init: formInputs.setters.displayGovernmentType,
      setters: handleDisplayGovernmentType,
      required: true,
      describe:
        '表示先が官公庁ナビの場合は必須です。特に区別しない場合は両方選択してください。',
    },
    {
      type: formInputs.type.openAt,
      column: 'openAt',
      text: '公開開始日時',
      validationMessage: validate.validationMessage.openAt,
      state: formInputs.data.openAt,
      init: (e) => formInputs.setters.openAt(e),
      setters: handleOpenAt,
      required: true,
      placeholder: '公開開始日時',
    },
    {
      type: formInputs.type.closeAt,
      column: 'closeAt',
      text: '公開終了日時',
      validationMessage: validate.validationMessage.closeAt,
      state: formInputs.data.closeAt,
      init: (e) => formInputs.setters.closeAt(e),
      setters: handleCloseAt,
      required: true,
      placeholder: '公開終了日時',
    },
    {
      type: formInputs.type.title,
      column: 'title',
      text: 'タイトル',
      validationMessage: validate.validationMessage.title,
      state: formInputs.data.title,
      init: formInputs.setters.title,
      setters: handleTitle,
      required: true,
    },
    {
      type: formInputs.type.body,
      column: 'body',
      text: '本文',
      validationMessage: validate.validationMessage.body,
      state: formInputs.data.body,
      init: formInputs.setters.body,
      setters: handleBody,
      required: true,
    },
    {
      type: formInputs.type.severityType,
      column: 'severityType',
      text: '重要度',
      list: is_important,
      state: formInputs.data.severityType,
      init: formInputs.setters.severityType,
      setters: handleSeverityType,
    },
  ];

  return validateInputs;
};
