import styled from 'styled-components';

import CautionDescription from "./CautionDescription";
import FormText from "./FormText";
import Label from "./Label";


const TableRow = styled.div`
    display: table-row;
`

const TableContents = styled.div`
    padding: 12px;
    display: table-cell;
    width: 70%;
    background-color: var(--heading);
    vertical-align: middle;
    border-bottom: 1px solid #ccc;
    white-space: pre-wrap;
`

const FormTextWithCaution = ({
    caution_description,
    data = "",
    handler1 = () => {},
    handler2 = () => {},
    invalidMessage = 'TRUE',
    label,
    label_class_name = '',
    number_remaining_characters,
    required,
    validationType,
    width = "100",
    maxCharacters = undefined,
    disabled = false,
}) => {
    return (
        <TableRow>
            <Label label={label} required={required} className={label_class_name} />
            <TableContents>
                <FormText
                    isDisable={disabled}
                    number_remaining_characters={number_remaining_characters}
                    maxCharacters={maxCharacters}
                    validationType={validationType}
                    data={data}
                    handler1={handler1}
                    handler2={handler2}
                    invalidMessage={invalidMessage}
                    className={`property-input-text-size-${width}`}
                />
                <CautionDescription data={caution_description}/>
            </TableContents>
        </TableRow>
    );
}
export default FormTextWithCaution
