// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-bottom-modal-contents {
  width: 100%;
  height: 90vh;
  padding: 16px 16px 0 16px;
}

.footer-bottom-modal-contents-body {
  width: 100%;
  height: calc(90vh - 60px - 16px);
  overflow-y: auto;
  pointer-events: auto;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/footer-bottom-modal-contents.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,gCAAgC;EAChC,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":[".footer-bottom-modal-contents {\n  width: 100%;\n  height: 90vh;\n  padding: 16px 16px 0 16px;\n}\n\n.footer-bottom-modal-contents-body {\n  width: 100%;\n  height: calc(90vh - 60px - 16px);\n  overflow-y: auto;\n  pointer-events: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
