import { useAfterWinningBidCautionInputs } from 'shared/utils/helper/useAfterWinningBidCautionInputs';

import { AfterWinningPublicSaleBidCautionForm } from '../AfterWinningPublicSaleBidCautionForm';

export const validateInputsAfterWinningPublicSaleBidCaution = () => {
  const formInputs = useAfterWinningBidCautionInputs();
  const validate = AfterWinningPublicSaleBidCautionForm();

  // せり形式
  // 1. 権利移転手続き
  // 権利移転手続きについての説明
  function handleAuctionTransRightDescription(e) {
    validate.setters.handleAuctionTransRightDescriptionValid(e);
    formInputs.setters.setAuctionTransRightDescription(e.target.value);
  }

  // 必要な費用
  // 動産の表示
  function handleAuctionCostPropertyDisplay(e) {
    formInputs.setters.setAuctionCostPropertyDisplay(e.target.value);
  }

  // 動産の説明
  function handleAuctionCostPropertyDescription(e, required) {
    validate.setters.handleAuctionCostPropertyDescriptionValid(e, required);
    formInputs.setters.setAuctionCostPropertyDescription(e.target.value);
  }

  // 自動車の表示
  function handleAuctionCostCarDisplay(e) {
    formInputs.setters.setAuctionCostCarDisplay(e.target.value);
  }

  // 自動車の説明
  function handleAuctionCostCarDescription(e, required) {
    validate.setters.handleAuctionCostCarDescriptionValid(e, required);
    formInputs.setters.setAuctionCostCarDescription(e.target.value);
  }

  // 不動産の表示
  function handleAuctionCostEstateDisplay(e) {
    formInputs.setters.setAuctionCostEstateDisplay(e.target.value);
  }

  // 不動産の説明
  function handleAuctionCostEstateDescription(e, required) {
    validate.setters.handleAuctionCostEstateDescriptionValid(e, required);
    formInputs.setters.setAuctionCostEstateDescription(e.target.value);
  }

  // ご注意
  function handleAuctionCostAttention(e) {
    validate.setters.handleAuctionCostAttentionValid(e);
    formInputs.setters.setAuctionCostAttention(e.target.value);
  }

  // 必要な書類
  // 必要な書類の案内ページ - ページの表示
  function handleAuctionDocumentPageDisplay(e) {
    formInputs.setters.setAuctionDocumentPageDisplay(e.target.value);
  }

  // 必要な書類の案内ページ - 案内ページ名
  function handleAuctionDocumentPageName(e, required) {
    console.log(required);
    validate.setters.handleAuctionDocumentPageNameValid(e, required);
    formInputs.setters.setAuctionDocumentPageName(e.target.value);
  }

  // 必要な書類の案内ページ - URL
  function handleAuctionDocumentPageUrl(e, required) {
    validate.setters.handleAuctionDocumentPageUrlValid(e, required);
    formInputs.setters.setAuctionDocumentPageUrl(e.target.value);
  }

  // 動産の表示
  function handleAuctionDocumentPropertyDisplay(e) {
    formInputs.setters.setAuctionDocumentPropertyDisplay(e.target.value);
  }

  // 動産の説明
  function handleAuctionDocumentPropertyDescription(e, required) {
    validate.setters.handleAuctionDocumentPropertyDescriptionValid(e, required);
    formInputs.setters.setAuctionDocumentPropertyDescription(e.target.value);
  }

  // 自動車の表示
  function handleAuctionDocumentCarDisplay(e) {
    formInputs.setters.setAuctionDocumentCarDisplay(e.target.value);
  }

  // 自動車の説明
  function handleAuctionDocumentCarDescription(e, required) {
    validate.setters.handleAuctionDocumentCarDescriptionValid(e, required);
    formInputs.setters.setAuctionDocumentCarDescription(e.target.value);
  }

  // 不動産の表示
  function handleAuctionDocumentEstateDisplay(e) {
    formInputs.setters.setAuctionDocumentEstateDisplay(e.target.value);
  }

  // 不動産の説明
  function handleAuctionDocumentEstateDescription(e, required) {
    validate.setters.handleAuctionDocumentEstateDescriptionValid(e, required);
    formInputs.setters.setAuctionDocumentEstateDescription(e.target.value);
  }

  // ご注意
  function handleAuctionDocumentAttention(e) {
    validate.setters.handleAuctionDocumentAttentionValid(e);
    formInputs.setters.setAuctionDocumentAttention(e.target.value);
  }

  // 物件の権利移転について
  // 動産の表示
  function handleAuctionTransRightPropertyDisplay(e) {
    formInputs.setters.setAuctionTransRightPropertyDisplay(e.target.value);
  }

  // 動産の説明
  function handleAuctionTransRightPropertyDescription(e, required) {
    validate.setters.handleAuctionTransRightPropertyDescriptionValid(e, required);
    formInputs.setters.setAuctionTransRightPropertyDescription(e.target.value);
  }

  // 自動車の表示
  function handleAuctionTransRightCarDisplay(e) {
    formInputs.setters.setAuctionTransRightCarDisplay(e.target.value);
  }

  // 自動車の説明
  function handleAuctionTransRightCarDescription(e, required) {
    validate.setters.handleAuctionTransRightCarDescriptionValid(e, required);
    formInputs.setters.setAuctionTransRightCarDescription(e.target.value);
  }

  // 不動産の表示
  function handleAuctionTransRightEstateDisplay(e) {
    formInputs.setters.setAuctionTransRightEstateDisplay(e.target.value);
  }

  // 不動産の説明
  function handleAuctionTransRightEstateDescription(e, required) {
    validate.setters.handleAuctionTransRightEstateDescriptionValid(e, required);
    formInputs.setters.setAuctionTransRightEstateDescription(e.target.value);
  }

  // ご注意
  function handleAuctionTransRightAttention(e) {
    validate.setters.handleAuctionTransRightAttentionValid(e);
    formInputs.setters.setAuctionTransRightAttention(e.target.value);
  }

  // 落札者（落札者が法人の場合は代表者）以外の方が権利移転手続きを行う場合
  // 説明
  function handleAuctionOtherTransRightDescription(e) {
    validate.setters.handleAuctionOtherTransRightDescription(e);
    formInputs.setters.setAuctionOtherTransRightDescription(e.target.value);
  }

  // ご注意
  function handleAuctionOtherTransRightAttention(e) {
    validate.setters.handleAuctionOtherTransRightAttention(e);
    formInputs.setters.setAuctionOtherTransRightAttention(e.target.value);
  }

  // 権利移転の時期
  // 説明
  function handleAuctionTransRightDateDescription(e) {
    validate.setters.handleAuctionOtherTransRightDateDescription(e);
    formInputs.setters.setAuctionTransRightDateDescription(e.target.value);
  }

  // 2. 重要事項
  // 危険負担
  function handleAuctionImportantRiskBearing(e) {
    validate.setters.handleAuctionImportantRiskBearing(e);
    formInputs.setters.setAuctionImportantRiskBearing(e.target.value);
  }

  // 契約不適合責任
  function handleAuctionImportantNonConformingContractsLiability(e) {
    validate.setters.handleAuctionImportantNonConformingContractsLiability(e);
    formInputs.setters.setAuctionImportantNonConformingContractsLiability(
      e.target.value,
    );
  }

  // 引き渡し条件
  function handleAuctionImportantTermsDelivery(e) {
    validate.setters.handleAuctionImportantTermsDelivery(e);
    formInputs.setters.setAuctionImportantTermsDelivery(e.target.value);
  }

  // 執行機関の引き渡し義務
  function handleAuctionImportantObligationDelivery(e) {
    validate.setters.handleAuctionImportantObligationDelivery(e);
    formInputs.setters.setAuctionImportantObligationDelivery(e.target.value);
  }

  // 返品、交換
  function handleAuctionImportantReturnItems(e) {
    validate.setters.handleAuctionImportantReturnItems(e);
    formInputs.setters.setAuctionImportantReturnItems(e.target.value);
  }

  // 保管費用
  function handleAuctionImportantStorageCost(e) {
    validate.setters.handleAuctionImportantStorageCost(e);
    formInputs.setters.setAuctionImportantStorageCost(e.target.value);
  }

  // 落札者（最高価申込者）決定後、公売保証金が変換される場合
  function handleAuctionImportantReturnDeposit(e) {
    validate.setters.handleAuctionImportantReturnDeposit(e);
    formInputs.setters.setAuctionImportantReturnDeposit(e.target.value);
  }

  // その他
  function handleAuctionImportantOther(e) {
    validate.setters.handleAuctionImportantOther(e);
    formInputs.setters.setAuctionImportantOther(e.target.value);
  }

  // ご注意
  function handleAuctionImportantAttention(e) {
    validate.setters.handleAuctionImportantAttention(e);
    formInputs.setters.setAuctionImportantAttention(e.target.value);
  }

  // 3. お問い合わせ先
  // お問い合わせ先の表示
  function handleAuctionInquiryDisplay(e) {
    formInputs.setters.setAuctionInquiryDisplay(e.target.value);
  }

  // お問い合わせ先
  function handleAuctionInquiryName(e, required) {
    validate.setters.handleAuctionInquiryNameValid(e, required);
    formInputs.setters.setAuctionInquiryName(e.target.value);
  }

  // メールアドレス
  function handleAuctionInquiryEmail(e) {
    validate.setters.handleAuctionInquiryEmailValid(e);
    formInputs.setters.setAuctionInquiryEmail(e.target.value);
  }

  // 電話番号
  function handleAuctionInquiryTel(e, telData) {
    const val = {
      telNum1: e.telNum1,
      telNum2: e.telNum2,
      telNum3: e.telNum3,
    };
    validate.setters.handleAuctionInquiryTelValid(e, { ...telData, ...val });
    formInputs.setters.setAuctionInquiryTel({ ...telData, ...val });
  }
  function handleAuctionInquiryTelNum1(tel) {
    validate.setters.handleAuctionInquiryTelNum1Valid(tel);
    formInputs.setters.setAuctionInquiryTelNum1(tel);
  }

  function handleAuctionInquiryTelNum2(tel) {
    validate.setters.handleAuctionInquiryTelNum2Valid(tel);
    formInputs.setters.setAuctionInquiryTelNum2(tel);
  }

  function handleAuctionInquiryTelNum3(tel) {
    validate.setters.handleAuctionInquiryTelNum3Valid(tel);
    formInputs.setters.setAuctionInquiryTelNum3(tel);
  }

  // 電話番号(内線)
  function handleAuctionInquiryExtension(e) {
    validate.setters.handleAuctionInquiryExtensionValid(e);
    formInputs.setters.setAuctionInquiryExtension(e.target.value);
  }

  // 電話受付時間
  function handleAuctionInquiryAccept(e) {
    validate.setters.handleAuctionInquiryAcceptValid(e);
    formInputs.setters.setAuctionInquiryAccept(e.target.value);
  }

  const validateInputs = {
    data: formInputs.data,
    setters: {
      handleAuctionTransRightDescription,
      handleAuctionCostPropertyDisplay,
      handleAuctionCostPropertyDescription,
      handleAuctionCostCarDisplay,
      handleAuctionCostCarDescription,
      handleAuctionCostEstateDisplay,
      handleAuctionCostEstateDescription,
      handleAuctionCostAttention,
      handleAuctionDocumentPageDisplay,
      handleAuctionDocumentPageName,
      handleAuctionDocumentPageUrl,
      handleAuctionDocumentPropertyDisplay,
      handleAuctionDocumentPropertyDescription,
      handleAuctionDocumentCarDisplay,
      handleAuctionDocumentCarDescription,
      handleAuctionDocumentEstateDisplay,
      handleAuctionDocumentEstateDescription,
      handleAuctionDocumentAttention,
      handleAuctionTransRightPropertyDisplay,
      handleAuctionTransRightPropertyDescription,
      handleAuctionTransRightCarDisplay,
      handleAuctionTransRightCarDescription,
      handleAuctionTransRightEstateDisplay,
      handleAuctionTransRightEstateDescription,
      handleAuctionTransRightAttention,
      handleAuctionOtherTransRightDescription,
      handleAuctionOtherTransRightAttention,
      handleAuctionTransRightDateDescription,
      handleAuctionImportantRiskBearing,
      handleAuctionImportantNonConformingContractsLiability,
      handleAuctionImportantTermsDelivery,
      handleAuctionImportantObligationDelivery,
      handleAuctionImportantReturnItems,
      handleAuctionImportantStorageCost,
      handleAuctionImportantReturnDeposit,
      handleAuctionImportantOther,
      handleAuctionImportantAttention,
      handleAuctionInquiryDisplay,
      handleAuctionInquiryName,
      handleAuctionInquiryEmail,
      handleAuctionInquiryTel,
      handleAuctionInquiryTelNum1,
      handleAuctionInquiryTelNum2,
      handleAuctionInquiryTelNum3,
      handleAuctionInquiryExtension,
      handleAuctionInquiryAccept,
    },
    init: formInputs.setters,
    validationMessage: validate.validationMessage,
  };

  return validateInputs;
};
