import ImportantLabel from 'interfaces/components/common/ImportantLabel';
import { Link } from 'react-router-dom';
import { convertTimestampToJp } from "shared/utils/helper/convert_to_jp.js";
import 'interfaces/css/common/simple-notice.css';

const SimpleNotice = ({ date, title, noticeId, status }) => {
  return (
    <div className="simple-notice" data-testid="simple-notice">
      <div className="notice-date" data-testid="notice-date">{convertTimestampToJp(date)}</div>
      <Link to={`/navi/notice#notice-${noticeId}`} data-testid="notice-title-link">{title}</Link>
      {status === 'TRUE' ? <ImportantLabel/> : null}
    </div>
  );
};

export default SimpleNotice;
