import "interfaces/css/FaqOther/faq-other-list-item.css"

const FaqOtherListItem = ({ question, answer }) => {
  return (
    <div className="faq-other-list-item d-flex flex-wrap" data-testid="faq-other-list-item">
      <div className="faq-other-list-item-text-box d-table-row" data-testid="faq-other-list-item-question">
        <span className="faq-other-list-item-text-box-question-icon d-table-cell font-weight-bold">Q</span>
        <span className="faq-other-list-item-text-box-separator d-table-cell">:</span>
        <span className="d-table-cell font-weight-bold">{ question }</span>
      </div>
      <div className="faq-other-list-item-text-box d-table-row pt-2" data-testid="faq-other-list-item-answer">
        <span className="d-table-cell font-weight-bold">A</span>
        <span className="faq-other-list-item-text-box-separator d-table-cell">:</span>
        <span className="d-table-cell">{ answer }</span>
      </div>
    </div>
  );
}
export default FaqOtherListItem;