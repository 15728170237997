export const NaviUserConfirmConst = (toRegister, register, toIndex) => {
  const Put = async (e) => {
    register(e);
  };
  const Edit = async () => {
    toRegister();
  };
  const Index = () => {
    toIndex();
  };
  const EDIT_BUTTON_DATA = {
    text: '編集',
    clickHandler: Edit,
    className: 'property-info-general-form-button new-style_navi_normal-button',
  };
  const CREATE_BUTTON_DATA = {
    text: '登録',
    clickHandler: Put,
    className: 'property-info-general-form-button',
  };
  const UPDATE_BUTTON_DATA = {
    text: '更新',
    clickHandler: Put,
    className: 'property-info-general-form-button',
  };
  const INDEX_BUTTON_DATA = {
    text: '戻る',
    clickHandler: Index,
    className: 'property-info-general-form-button new-style_navi_normal-button',
  };
  return {
    edit: EDIT_BUTTON_DATA,
    create: CREATE_BUTTON_DATA,
    update: UPDATE_BUTTON_DATA,
    index: INDEX_BUTTON_DATA,
  };
};
