
export class StatusCarMileage {
  #statusCarMileage

  constructor(statusCarMileage) {
    this.#statusCarMileage = statusCarMileage;
  }

  get() {
    return this.#statusCarMileage
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
