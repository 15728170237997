// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import { ActiveStatus } from './PromotionFrames/activeStatus';
import { DisplayDeadline } from './PromotionFrames/displayDeadline';
import { DisplayOrder } from './PromotionFrames/displayOrder';
import { IsMigratory } from './PromotionFrames/isMigratory';
import { SourceType } from './PromotionFrames/sourceType';
import { Theme } from './PromotionFrames/theme';
import { PromotionFrameId } from './PromotionProperties/promotionFrameId';
import { PropertyIdList } from './PromotionProperties/propertyIdList';
import { Url } from './PromotionProperties/url';
import CustomError from '../../../shared/utils/helper/customError.js';
import { NotUndefined } from '../../../shared/utils/helper/NotUndefined.js';

export class PromotionFrameGet {
  // 必要なデータ
  constructor(data) {
    this.id = data.id;
    this.activeStatus = data.activeStatus;
    this.displayDeadline = data.displayDeadline;
    this.displayOrder = data.displayOrder;
    this.isMigratory = data.isMigratory;
    this.sourceType = data.sourceType;
    this.theme = data.theme;
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class PromotionPropertyGet {
  // 必要なデータ
  constructor(data) {
    this.promotionFrameId = data.promotionFrameId;
    this.number = data.number;
    this.url = data.url;
  }

  get isValid() {
    return NotUndefined(this);
  }
}

// Json(promotion_properties.json)を元に作成
// PromotionFrameに紐づくPromotionPropertiesの一覧？
export class PromotionPropertiesGet {
  constructor(data) {
    this.promotion = new PromotionDataGet(data.promotion);
  }
}

export class PromotionDataGet {
  constructor(data) {
    this.theme = data.theme;
    this.property_id_list = data.property_id_list;
    this.display_order = data.display_order;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class PromotionFrameCreate {
  // 必要なデータ

  constructor(data) {
    this.active_status = new ActiveStatus(data.active_status);
    this.display_deadline = new DisplayDeadline(data.display_deadline);
    this.display_order = new DisplayOrder(data.display_order);
    this.is_migratory = new IsMigratory(data.is_migratory);
    this.source_type = new SourceType(data.source_type);
    this.theme = new Theme(data.theme);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.active_status.isValid &&
      this.display_deadline.isValid &&
      this.display_order.isValid &&
      this.is_migratory.isValid &&
      this.source_type.isValid &&
      this.theme.isValid
    );
  }
}

export class PromotionPropertyCreate {
  // 必要なデータ

  constructor(data) {
    this.promotion_frame_id = new PromotionFrameId(data.promotion_frame_id);
    this.number = new Number(data.number);
    this.url = new Url(data.url);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.promotion_frame_id.isValid && this.number.isValid && this.url.isValid
    );
  }
}

// Json(promotion_properties.json)を元に作成
export class PromotionPropertiesCreate {
  constructor(data) {
    this.promotion = new PromotionDataCreate(data.promotion);
  }
}

export class PromotionDataCreate {
  constructor(data) {
    this.theme = new Theme(data.theme);
    this.property_id_list = new PropertyIdList(data.property_id_list);
    this.display_order = new DisplayOrder(data.display_order);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.theme.isValid &&
      this.property_id_list.isValid &&
      this.display_order.isValid
    );
  }
}
