import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class ShadeRegulation {
  #shadeRegulation

  constructor(shadeRegulation) {
    this.#shadeRegulation = shadeRegulation;
  }

  get() {
    return this.#shadeRegulation
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#shadeRegulation, 0, 250)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#shadeRegulation)
  }
}
