
export class SummaryBuildDate {
    #summaryBuildDaet

    constructor(summaryBuildDaet) {
        this.#summaryBuildDaet = summaryBuildDaet;
    }

    get() {
        return this.#summaryBuildDaet
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
