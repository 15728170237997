import { ReactComponent as ArrowRightIcon } from 'interfaces/assets/svg/arrow_back_icon.svg';
import BackButton from 'interfaces/components/common/BackButton';
import PageTitle from 'interfaces/components/common/PageTitle';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useModalControl from 'shared/hooks/useModalControl';

import FixResolutionDateFormModal from './FixResolutionDateFormModal';
import GovernmentPropertyTable from './GovernmentPropertyTable';
import { GovernmentPropertiesContext } from '../../../../pages/GovernmentProperties/entities/GovernmentPropertiesContext';
import Toolbar from 'interfaces/components/common/Toolbar';
import PropertySwitcher from 'interfaces/components/common/Switcher/PropertySwitcher';

//import Button from "../../../components/Button/Button";
//import {
//  validateInputsFixResolutionDateForm
//} from "../../../components/kmanager/validateInputs/validateInputsFixResolutionDateForm";

const GovernmentProperty = () => {
  const navigation = useNavigate();
  const { auctionId } = useParams();
  const { auction, government, properties, setProperties } = useContext(
    GovernmentPropertiesContext,
  );
  const AUCTION_PATH = `/kmanager/auction/${auctionId}/gov`;

  //const [properties, setProperties] = useState([]);
  const [propertyId, setPropertyId] = useState([]);
  //const [error, setError] = useState({})

  const { isOpen, handleOpen, handleClose } = useModalControl();

  //function handleError(err) {
  //  setError(err)
  //  setTimeout(() => {
  //    setError({})
  //  }, 2000)
  //}

  const setId = (id) => {
    setPropertyId(id);
    handleOpen();
  };

  return (
    <div
      className="kmanager-properties kmanager-auction-operation"
      data-testid="kmanager-properties">
      <FixResolutionDateFormModal
        propertyId={propertyId}
        properties={properties}
        setProperties={setProperties}
        isOpen={isOpen}
        handleClose={handleClose}
        setId={setId}
      />
      <div className="kmanager-row">
        <div className="kmanager-col kmanager-col-6">
          <BackButton
            text={auction.name}
            svg={<ArrowRightIcon title="back-to-page" />}
            handleClick={() => navigation(AUCTION_PATH)}
          />
          <PageTitle text={government.name} />
          <Toolbar>
            <PropertySwitcher
              auctionId={auction.id}
              governmentId={government.id}
              activeIndex={0}
            />
          </Toolbar>
        </div>
        <div className="kmanager-col kmanager-col-12">
          <div className="box-shadow">
            <GovernmentPropertyTable setID={setId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GovernmentProperty;
