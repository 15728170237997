
export class FrontWeight {
    #frontWeight

    constructor(frontWeight) {
        this.#frontWeight = frontWeight;
    }

    get() {
        return this.#frontWeight
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
