import { useState } from "react";

export function useCategoryInputs() {

  const [parentId, setParentId] = useState("");
  const [name, setName] = useState("");
  const [depth, setDepth] = useState("");
  const [displayService, setDisplayService] = useState("HIDDEN");
  const [displayProperty, setDisplayProperty] = useState("HIDDEN");
  const [displayEstate, setDisplayEstate] = useState("HIDDEN");
  const [displayCar, setDisplayCar] = useState("HIDDEN");

  const categoryInputs = {
    type: {
      parentId: 'text',
      name: 'text',
      depth: 'text',
      displayService: 'check',
      displayProperty: 'check',
      displayEstate: 'check',
      displayCar: 'check',
    },
    data: {
      parentId,
      name,
      depth,
      displayService,
      displayProperty,
      displayEstate,
      displayCar,
    },
    setters: {
      parentId: setParentId,
      name: setName,
      depth: setDepth,
      displayService: setDisplayService,
      displayProperty: setDisplayProperty,
      displayEstate: setDisplayEstate,
      displayCar: setDisplayCar,
    }
  }

  return categoryInputs;
}