import GuidelineLink from './GuidelineLink.jsx';
import { MylistBtnBranch } from './MylistBtnBranch.jsx';
import { AuctionBtnBranch } from '../ActionArea/AuctionBtnBranch.jsx';
import 'interfaces/css/common/hero-area-modal.css';
const HeroAreaPhoneModal = ({ data, isContainBlockList }) => {
  return (
    <div
      data-testid="hero-area-phone-modal"
      className="items-hero-action-area-modal noshrink nogrow v-sheet theme--light">
      <AuctionBtnBranch data={data} isContainBlockList={isContainBlockList} />
      <MylistBtnBranch className={'hero-area-modal-mylist-button'} />
      <GuidelineLink />
    </div>
  );
};
export default HeroAreaPhoneModal;
