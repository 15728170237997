import { validateSelected } from "../../../../application/validations.js";

export class DisplayServiceType {
  #displayServiceType

  constructor(displayServiceType) {
    this.#displayServiceType = displayServiceType;
  }

  get() {
    return this.#displayServiceType
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#displayServiceType)
  }
}
