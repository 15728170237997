import { ReactComponent as BeginnerIcon } from 'interfaces/assets/svg/detail_beginner_icon.svg';
import URL_LINKS from 'interfaces/pages/Dashboard/entities/externalLinks.js';
import { Link } from 'react-router-dom';

const GuidelineLink = ({ children }) => {
  return (
    <Link
      to={URL_LINKS.BEGINNERS_URL}
      target="_blank"
      className="guideline btn--frame pa-0 mt-2 text-caption btn--frame v-btn v-btn--depressed v-btn--flat v-btn--outlined v-size--large text--text"
      data-testid="guideline">
      <span className="v-btn__content">
        <BeginnerIcon />
        {children}
      </span>
    </Link>
  );
};
export default GuidelineLink;
