import ReceptionCompletedProvider from './ReceptionCompletedProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { receptionCompleted } from "../entities/componentMapping.js";

const ReceptionCompleted = ({ type }) => {
  return (
    <ReceptionCompletedProvider type={type}>
      {getComponent(receptionCompleted, type)}
    </ReceptionCompletedProvider>
  );
};

export default ReceptionCompleted;
