
export class LandSpaceType {
  #landSpaceType

  constructor(landSpaceType) {
    this.#landSpaceType = landSpaceType;
  }

  get() {
    return this.#landSpaceType
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
