import AuctionEditProvider from './AuctionEditProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { auctionEdit } from "../entities/componentMapping.js";

const AuctionEdit = ({ type }) => {
  return (
    <AuctionEditProvider type={type}>
      {getComponent(auctionEdit, type)}
    </AuctionEditProvider>
  );
};

export default AuctionEdit;
