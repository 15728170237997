import { useState } from 'react';
import {
  validateEmailFormatCheck,
  validateSelected,
  hasEmoji,
  validateRangeLength,
  validateNumber,
} from 'application/validations';

export const useAuctionEntryInputsValidation = () => {
  const [invitationNameValid, setInvitationNameValid] = useState(null);
  const [depositDeliveryStatusValid, setDepositDeliveryStatusValid] =
    useState(null);
  const [governmentIdValid, setGovernmentIdValid] = useState(null);
  const [areaValid, setAreaValid] = useState(null);
  const [contactOfficeValid, setContactOfficeValid] = useState(null);
  const [contactEmailValid, setContactEmailValid] = useState(null);
  const [contactTelValid, setContactTelValid] = useState(null);

  function handleInvitationNameValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 30);
    if (invalid !== 'TRUE') {
      setInvitationNameValid(invalid); // 状態更新
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInvitationNameValid(invalid); // 状態更新
      return;
    }
    setInvitationNameValid(invalid); // 状態更新
  }

  function handleDepositDeliveryStatusValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateSelected(e.target.value);
    if (invalid !== 'TRUE') {
      setDepositDeliveryStatusValid(invalid); // 状態更新
      return;
    }
    setDepositDeliveryStatusValid(invalid); // 状態更新
  }

  function handleGovernmentIdValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateSelected(e.target.value);
    if (invalid !== 'TRUE') {
      setGovernmentIdValid(invalid); // 状態更新
      return;
    }
    setGovernmentIdValid(invalid); // 状態更新
  }

  function handleAreaValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 30);
    if (invalid !== 'TRUE') {
      setAreaValid(invalid); // 状態更新
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAreaValid(invalid); // 状態更新
      return;
    }
    setAreaValid(invalid); // 状態更新
  }

  function handleContactOfficeValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setContactOfficeValid(invalid); // 状態更新
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setContactOfficeValid(invalid); // 状態更新
      return;
    }
    setContactOfficeValid(invalid); // 状態更新
  }

  function handleContactEmailValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    invalid = validateEmailFormatCheck(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid); // 状態更新
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 128);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid); // 状態更新
      return;
    }
    setContactEmailValid(invalid); // 状態更新
  }

  function handleContactTelValid(e) {
    let invalid = null;
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 10, 11);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    setContactTelValid(invalid);
  }

  const validations = {
    // validationの処理
    setters: {
      invitationName: handleInvitationNameValid,
      depositDeliveryStatus: handleDepositDeliveryStatusValid,
      governmentId: handleGovernmentIdValid,
      area: handleAreaValid,
      contactOffice: handleContactOfficeValid,
      contactEmail: handleContactEmailValid,
      contactTel: handleContactTelValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      invitationName: invitationNameValid,
      depositDeliveryStatus: depositDeliveryStatusValid,
      governmentId: governmentIdValid,
      area: areaValid,
      contactOffice: contactOfficeValid,
      contactEmail: contactEmailValid,
      contactTel: contactTelValid,
    },
  };

  return validations;
};
