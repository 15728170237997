import PopUpInput from 'interfaces/components/common/Input/PopUpInput';
import RequiredLongLabel from 'interfaces/components/common/RequiredLabel/RequiredLongLabel.jsx';
import { useEffect, useState } from 'react';
import { deliveryTimeLists } from 'shared/utils/constants/deliveryTimeList.js';
import 'interfaces/css/common/pop-up-input.css';
import { JAPAN_POST, SAGAWA, YAMATO } from 'shared/utils/constants/carrier.js';

const DeliveryTimeScheduleForm = ({ carrier, deliveryTime, handleSelect }) => {
  const [deliveryTimeList, setDeliveryTimeList] = useState([]);

  useEffect(() => {
    if (carrier === YAMATO.en) {
      setDeliveryTimeList(deliveryTimeLists.YAMATO);
      return;
    }
    if (carrier === SAGAWA.en) {
      setDeliveryTimeList(deliveryTimeLists.SAGAWA);
      return;
    }
    if (carrier === JAPAN_POST.en) {
      setDeliveryTimeList(deliveryTimeLists.JAPAN_POST);
      return;
    }
    setDeliveryTimeList(deliveryTimeLists.OTHER);
  }, [carrier]);

  useEffect(() => {
    if (!deliveryTimeList.find((item) => item === deliveryTime)) {
      handleSelect('');
    }
  }, [deliveryTimeList]);

  return (
    <tr>
      <th>
        <p>
          希望時間帯 <RequiredLongLabel />
        </p>
      </th>
      <td>
        <div className="pop-up-input-box">
          <PopUpInput
            items={deliveryTimeList}
            value={deliveryTime}
            handleSelect={handleSelect}
          />
        </div>
      </td>
    </tr>
  );
};

export default DeliveryTimeScheduleForm;
