// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-info-form-label{
    display: table-cell;
    padding:12px;
    border-bottom: 1px solid #ccc;
    width: 30%;
    max-width: 300px;
    min-width: 200px;
    background-color: var(--secondary-base);
    font-weight: 700;
    vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/property-info-form-label.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,6BAA6B;IAC7B,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,uCAAuC;IACvC,gBAAgB;IAChB,sBAAsB;AAC1B","sourcesContent":[".property-info-form-label{\n    display: table-cell;\n    padding:12px;\n    border-bottom: 1px solid #ccc;\n    width: 30%;\n    max-width: 300px;\n    min-width: 200px;\n    background-color: var(--secondary-base);\n    font-weight: 700;\n    vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
