// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sample-guideline .usage-caution-list-title {
  font-size: 17px;
  font-weight: bold;
}

.sample-guideline .usage-caution-list {
  list-style-type: disc;
}

.sample-guideline .usage-caution-list-item {
  list-style-type: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/SampleGuideline/usage-caution-list.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".sample-guideline .usage-caution-list-title {\n  font-size: 17px;\n  font-weight: bold;\n}\n\n.sample-guideline .usage-caution-list {\n  list-style-type: disc;\n}\n\n.sample-guideline .usage-caution-list-item {\n  list-style-type: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
