
export class CallUpButtonDownAuctionDate {
  #callUpButtonDownAuctionDate

  constructor(callUpButtonDownAuctionDate) {
    this.#callUpButtonDownAuctionDate = callUpButtonDownAuctionDate;
  }

  get() {
    return this.#callUpButtonDownAuctionDate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
