import PopUpBody from './PopUpBody.jsx';
import PopUpHeader from './PopUpHeader.jsx';

const PopUp = ({ handleClose }) => {
  return (
    <div className="popup" data-testid="popup">
      <PopUpHeader/>
      <PopUpBody handleClose={handleClose}/>
    </div>
  );
};

export default PopUp;
