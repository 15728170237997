import { validateAlphanum, validateRangeLength } from "../../../../application/validations.js";

export class EngineModel {
  #engineModel

  constructor(engineModel) {
    this.#engineModel = engineModel;
  }

  get() {
    return this.#engineModel
  }

  get isValid() {
    return [
      this.validateAlphanum,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateAlphanum() {
    return validateAlphanum(this.#engineModel)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#engineModel, 0, 30)
  }
}
