import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class HomePageName {
  #homePageName

  constructor(homePageName) {
    this.#homePageName = homePageName;
  }

  get() {
    return this.#homePageName
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#homePageName, 1, 50)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#homePageName)
  }
}
