import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import PageCautionDescription from 'interfaces/components/common/PageCautionDescription';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import { breadcrumbs } from 'interfaces/pages/LocalGovernmentManagementGuidelines/entities/breadcrumbs';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import GuidelinesForm from './GuidelinesForm';
import { textExtractionByProcedureType } from 'shared/utils/helper/TextExtraction';      

const Guideline = () => {
  const { localStorageProcedureType, localStorageGovernmentType } =
    useLocalStorage();

  return (
    <div
      className="new-style_navi_local-government-management_guideline_edit"
      data-testid="local-government-management-top">
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <PropertyInfoGeneralPageDescription
        title={'行政機関管理ページ：ガイドライン'}
        content={`貴庁のガイドラインを入力し、「登録ボタン」をクリックしてください。設定1～設定8まで、任意でご利用いただけます。${localStorageGovernmentType !== 'NATIONAL_TAX_AGENCY' ? 'また、「せり売形式」と「入札形式」で個別にガイドラインを設定することも可能です。\nガイドライン作成の注意事項を必ずご確認ください。' : ''}`}
        displayRequired={true}
      />
      <PageCautionDescription
        content={`※${textExtractionByProcedureType(localStorageProcedureType)}の参加申込開始日以降は掲載終了まで修正ができません。`}
      />      <ul className="new-style_navi_no-list-style ma-0 pt-4 pb-0 pl-0 pr-0">
        <li>
          <a
            href="/navi/faq/sample-guideline"
            target="_blank"
            rel="noopener noreferrer">
            ガイドラインサンプル
          </a>
        </li>
        <li>
          <a
            href="/navi/faq/sample-guideline-caution?from=sample-guideline"
            target="_blank"
            rel="noopener noreferrer">
            ガイドラインサンプル作成の注意事項
          </a>
        </li>
      </ul>
      <GuidelinesForm
        procedureType={localStorageProcedureType}
        governmentType={localStorageGovernmentType}
      />
    </div>
  );
};

export default Guideline;
