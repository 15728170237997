import CheckBox from 'interfaces/components/common/CheckBox/CheckBox';
import { useEffect, useState } from 'react';

import CautionDescription from '../../../Form/navi/CautionDescription';
import Label from '../../../Form/navi/Label';
import ValidationMessage from 'interfaces/components/Form/navi/ValidationMessage';

import 'interfaces/css/common/property-info-car-form-vehicle-Identification-number.css';
import { convertEmptyCharacters } from '../../../Form/navi/FormText';

import styled from 'styled-components';

const TextInput = styled.input`
  font-size: 16px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  line-height: 20px;
  #padding: 8px 0;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border: ${(props) =>
    props.$validation
      ? '2px solid #ff5252 !important'
      : '1px solid rgba(0, 0, 0, .42)'};
  border-radius: 4px;
  padding: 8px 12px;
`;

const FormVehicleIdentificationNumber = ({
  caution_description,
  displayProductNumber,
  invalidMessage,
  productNumber,
  handlerDisplayProductNumber,
  handlerProductNumber,
  handlerProductNumberFirst,
  handlerProductNumberLatter,
  label,
}) => {
  const [checkboxStates, setCheckBoxStates] = useState(false);

  useEffect(() => {
    if (displayProductNumber === 'DISPLAY') {
      setCheckBoxStates(true);
    }
  }, [displayProductNumber]);

  const productNumberHandler = (updateParams) => {
    let hashData = productNumber;
    hashData = { ...hashData, ...updateParams };
    handlerProductNumber(hashData);
  };

  const productNumberFirstHandler = (e) => {
    handlerProductNumberFirst(e.target.value);
    if (e.target.value !== '' && productNumber.latter !== '') {
      let updateParams = { first: e.target.value };
      productNumberHandler(updateParams);
    }
  };
  const productNumberLatterHandler = (e) => {
    handlerProductNumberLatter(e.target.value);
    if (productNumber.first !== '' && e.target.value !== '') {
      let updateParams = { latter: e.target.value };
      productNumberHandler(updateParams);
    }
  };

  const handleCheckboxChange = (e) => {
    e.preventDefault();
    let updateValue = 'DISPLAY';
    if (checkboxStates === true) {
      updateValue = 'HIDDEN';
    }
    handlerDisplayProductNumber(updateValue);
    setCheckBoxStates(!checkboxStates);
  };

  return (
    <div className="property-info-general-form-table-row">
      <Label label={label} required={true} />
      <div className="property-info-general-form-table-cell">
        <div className="property-info-car-form-vehicle-Identification-number">
          <TextInput
            type={'text'}
            name={'first'}
            value={convertEmptyCharacters(productNumber?.first)}
            onChange={productNumberFirstHandler}
          />
          <div className="ml-2 mr-2">-</div>
          <TextInput
            className="ml-4"
            type={'text'}
            name={'latter'}
            value={convertEmptyCharacters(productNumber?.latter)}
            onChange={productNumberLatterHandler}
          />
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
        <CautionDescription data={caution_description} />
        <div className="property-info-car-form-vehicle-Identification-number-checkbox">
          <CheckBox
            isChecked={checkboxStates}
            onClick={(e) => handleCheckboxChange(e)}
          />
          <label>ハイフンの後を＊＊＊＊と隠す</label>
        </div>
      </div>
    </div>
  );
};
export default FormVehicleIdentificationNumber;
