import { useEffect, useState } from 'react';

import { usePromotionEditInputs } from './hook/usePromotionEditInputs';
import { UsePromotionEditInputsValidation } from './hook/usePromotionEditInputsValidation';

export const is_display_deadline = [
  { id: 1, value: 'HIDDEN', label: '設定しない' },
  { id: 2, value: 'DISPLAY', label: '設定する' },
];

export const is_active = [
  { id: 1, value: 'ACTIVE', label: 'アクティブ' },
  { id: 2, value: 'NO_ACTIVE', label: 'ノンアクティブ' },
];

export const source_type = [
  { id: 1, value: 'INPUT_URL', label: '手入力' },
  { id: 2, value: 'SEARCH_URL', label: '検索URL' },
];

export const validateInputsPromotionEdit = (frameLength) => {
  const formInputs = usePromotionEditInputs();
  const validate = UsePromotionEditInputsValidation();
  const [displayOrder, setDisplayOrder] = useState([]);
  useEffect(() => {
    const displayOrder = [];
    for (let i = 1; i <= frameLength + 1; i++) {
      displayOrder.push({
        id: i,
        value: i,
        text: `${i}`,
      });
    }
    setDisplayOrder(displayOrder);
  }, [frameLength]);

  // 【KM】PromotionFrame
  function handleTheme(e) {
    validate.setters.theme(e);
    formInputs.setters.theme(e.target.value);
  }

  function handleIsDisplayDeadline(e) {
    formInputs.setters.isDisplayDeadline(e.target.value);
  }

  function handleDisplayDeadline(e) {
    formInputs.setters.displayDeadline(e);
  }

  function handleDisplayOrder(e) {
    validate.setters.displayOrder(e);
    formInputs.setters.displayOrder(e.target.value);
  }

  function handleActiveStatus(e) {
    formInputs.setters.activeStatus(e.target.value);
  }

  function handleSourceType(e) {
    formInputs.setters.sourceType(e.target.value);
  }

  // 【KM】PromotionProperty
  function handleSearchUrl(e) {
    validate.setters.searchUrl(e);
    formInputs.setters.searchUrl(e.target.value);
  }

  function handleSource1(e) {
    validate.setters.source1(e);
    formInputs.setters.source1(e.target.value);
  }

  function handleSource2(e) {
    validate.setters.source2(e);
    formInputs.setters.source2(e.target.value);
  }

  function handleSource3(e) {
    validate.setters.source3(e);
    formInputs.setters.source3(e.target.value);
  }

  function handleSource4(e) {
    validate.setters.source4(e);
    formInputs.setters.source4(e.target.value);
  }

  function handleSource5(e) {
    validate.setters.source5(e);
    formInputs.setters.source5(e.target.value);
  }

  function handleSource6(e) {
    validate.setters.source6(e);
    formInputs.setters.source6(e.target.value);
  }

  function handleSource7(e) {
    validate.setters.source7(e);
    formInputs.setters.source7(e.target.value);
  }

  function handleSource8(e) {
    validate.setters.source8(e);
    formInputs.setters.source8(e.target.value);
  }

  function handleSource9(e) {
    validate.setters.source9(e);
    formInputs.setters.source9(e.target.value);
  }

  function handleSource10(e) {
    validate.setters.source10(e);
    formInputs.setters.source10(e.target.value);
  }

  function handleSource11(e) {
    validate.setters.source11(e);
    formInputs.setters.source11(e.target.value);
  }

  function handleSource12(e) {
    validate.setters.source12(e);
    formInputs.setters.source12(e.target.value);
  }

  function handleSource13(e) {
    validate.setters.source13(e);
    formInputs.setters.source13(e.target.value);
  }

  function handleSource14(e) {
    validate.setters.source14(e);
    formInputs.setters.source14(e.target.value);
  }

  function handleSource15(e) {
    validate.setters.source15(e);
    formInputs.setters.source15(e.target.value);
  }

  function handleSource16(e) {
    validate.setters.source16(e);
    formInputs.setters.source16(e.target.value);
  }

  function handleSource17(e) {
    validate.setters.source17(e);
    formInputs.setters.source17(e.target.value);
  }

  function handleSource18(e) {
    validate.setters.source18(e);
    formInputs.setters.source18(e.target.value);
  }

  const validateInputs = [
    // 【KM】PromotionFrame
    {
      type: formInputs.type.theme,
      column: 'theme',
      text: 'テーマ',
      required: true,
      validationMessage: validate.validationMessage.theme,
      state: formInputs.data.theme,
      init: formInputs.setters.theme,
      setters: handleTheme,
    },
    {
      type: formInputs.type.isDisplayDeadline,
      column: 'isDisplayDeadline',
      text: '表示期限',
      required: true,
      list: is_display_deadline,
      validationMessage: validate.validationMessage.isDisplayDeadline,
      state: formInputs.data.isDisplayDeadline,
      init: (e) => formInputs.setters.isDisplayDeadline(e),
      setters: handleIsDisplayDeadline,
    },
    {
      type: formInputs.type.displayDeadline,
      column: 'displayDeadline',
      text: '',
      validationMessage: validate.validationMessage.displayDeadline,
      state: formInputs.data.displayDeadline,
      init: (e) => formInputs.setters.displayDeadline(e),
      setters: handleDisplayDeadline,
    },
    {
      type: formInputs.type.displayOrder,
      column: 'displayOrder',
      text: '表示枠',
      placeholder: '表示枠',
      required: true,
      list: displayOrder,
      validationMessage: validate.validationMessage.displayOrder,
      state: formInputs.data.displayOrder,
      init: formInputs.setters.displayOrder,
      setters: handleDisplayOrder,
    },
    {
      type: formInputs.type.activeStatus,
      column: 'activeStatus',
      text: 'アクティブ',
      required: true,
      list: is_active,
      validationMessage: validate.validationMessage.activeStatus,
      state: formInputs.data.activeStatus,
      init: formInputs.setters.activeStatus,
      setters: handleActiveStatus,
    },
    {
      type: formInputs.type.sourceType,
      column: 'sourceType',
      text: 'ソース',
      required: true,
      list: source_type,
      validationMessage: validate.validationMessage.sourceType,
      state: formInputs.data.sourceType,
      init: formInputs.setters.sourceType,
      setters: handleSourceType,
    },

    // 【KM】PromotionProperty
    {
      type: formInputs.type.searchUrl,
      column: 'searchUrl',
      text: 'ソース/検索URL',
      validationMessage: validate.validationMessage.searchUrl,
      state: formInputs.data.searchUrl,
      init: formInputs.setters.searchUrl,
      setters: handleSearchUrl,
    },
    {
      type: formInputs.type.source1,
      column: 'source1',
      text: 'ソース/手入力1',
      validationMessage: validate.validationMessage.source1,
      state: formInputs.data.source1,
      init: formInputs.setters.source1,
      setters: handleSource1,
    },
    {
      type: formInputs.type.source2,
      column: 'source2',
      text: 'ソース/手入力2',
      validationMessage: validate.validationMessage.source2,
      state: formInputs.data.source2,
      init: formInputs.setters.source2,
      setters: handleSource2,
    },
    {
      type: formInputs.type.source3,
      column: 'source3',
      text: 'ソース/手入力3',
      validationMessage: validate.validationMessage.source3,
      state: formInputs.data.source3,
      init: formInputs.setters.source3,
      setters: handleSource3,
    },
    {
      type: formInputs.type.source4,
      column: 'source4',
      text: 'ソース/手入力4',
      validationMessage: validate.validationMessage.source4,
      state: formInputs.data.source4,
      init: formInputs.setters.source4,
      setters: handleSource4,
    },
    {
      type: formInputs.type.source5,
      column: 'source5',
      text: 'ソース/手入力5',
      validationMessage: validate.validationMessage.source5,
      state: formInputs.data.source5,
      init: formInputs.setters.source5,
      setters: handleSource5,
    },
    {
      type: formInputs.type.source6,
      column: 'source6',
      text: 'ソース/手入力6',
      validationMessage: validate.validationMessage.source6,
      state: formInputs.data.source6,
      init: formInputs.setters.source6,
      setters: handleSource6,
    },
    {
      type: formInputs.type.source7,
      column: 'source7',
      text: 'ソース/手入力7',
      validationMessage: validate.validationMessage.source7,
      state: formInputs.data.source7,
      init: formInputs.setters.source7,
      setters: handleSource7,
    },
    {
      type: formInputs.type.source8,
      column: 'source8',
      text: 'ソース/手入力8',
      validationMessage: validate.validationMessage.source8,
      state: formInputs.data.source8,
      init: formInputs.setters.source8,
      setters: handleSource8,
    },
    {
      type: formInputs.type.source9,
      column: 'source9',
      text: 'ソース/手入力9',
      validationMessage: validate.validationMessage.source9,
      state: formInputs.data.source9,
      init: formInputs.setters.source9,
      setters: handleSource9,
    },
    {
      type: formInputs.type.source10,
      column: 'source10',
      text: 'ソース/手入力10',
      validationMessage: validate.validationMessage.source10,
      state: formInputs.data.source10,
      init: formInputs.setters.source10,
      setters: handleSource10,
    },
    {
      type: formInputs.type.source11,
      column: 'source11',
      text: 'ソース/手入力11',
      validationMessage: validate.validationMessage.source11,
      state: formInputs.data.source11,
      init: formInputs.setters.source11,
      setters: handleSource11,
    },
    {
      type: formInputs.type.source12,
      column: 'source12',
      text: 'ソース/手入力12',
      validationMessage: validate.validationMessage.source12,
      state: formInputs.data.source12,
      init: formInputs.setters.source12,
      setters: handleSource12,
    },
    {
      type: formInputs.type.source13,
      column: 'source13',
      text: 'ソース/手入力13',
      validationMessage: validate.validationMessage.source13,
      state: formInputs.data.source13,
      init: formInputs.setters.source13,
      setters: handleSource13,
    },
    {
      type: formInputs.type.source14,
      column: 'source14',
      text: 'ソース/手入力14',
      validationMessage: validate.validationMessage.source14,
      state: formInputs.data.source14,
      init: formInputs.setters.source14,
      setters: handleSource14,
    },
    {
      type: formInputs.type.source15,
      column: 'source15',
      text: 'ソース/手入力15',
      validationMessage: validate.validationMessage.source15,
      state: formInputs.data.source15,
      init: formInputs.setters.source15,
      setters: handleSource15,
    },
    {
      type: formInputs.type.source16,
      column: 'source16',
      text: 'ソース/手入力16',
      validationMessage: validate.validationMessage.source16,
      state: formInputs.data.source16,
      init: formInputs.setters.source16,
      setters: handleSource16,
    },
    {
      type: formInputs.type.source17,
      column: 'source17',
      text: 'ソース/手入力17',
      validationMessage: validate.validationMessage.source17,
      state: formInputs.data.source17,
      init: formInputs.setters.source17,
      setters: handleSource17,
    },
    {
      type: formInputs.type.source18,
      column: 'source18',
      text: 'ソース/手入力18',
      validationMessage: validate.validationMessage.source18,
      state: formInputs.data.source18,
      init: formInputs.setters.source18,
      setters: handleSource18,
    },
  ];

  return validateInputs;
};
