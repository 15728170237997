// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.general-detail-property-card {
  width: 100%;
  min-width: 320px;
  background-color: var(--white);
  border: thin solid rgba(0,0,0,.12);
  display: flex;
  align-items: flex-start;
  padding: 8px 16px 8px 20px;
  margin-bottom: 16px;
}

.general-detail-property-thumbnail {
  width: 150px;
  border: thin solid rgba(0,0,0,.12);
  margin-right: 16px;
  flex-shrink: 0;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/general-detail-property-card.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,8BAA8B;EAC9B,kCAAkC;EAClC,aAAa;EACb,uBAAuB;EACvB,0BAA0B;EAC1B,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kCAAkC;EAClC,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".general-detail-property-card {\n  width: 100%;\n  min-width: 320px;\n  background-color: var(--white);\n  border: thin solid rgba(0,0,0,.12);\n  display: flex;\n  align-items: flex-start;\n  padding: 8px 16px 8px 20px;\n  margin-bottom: 16px;\n}\n\n.general-detail-property-thumbnail {\n  width: 150px;\n  border: thin solid rgba(0,0,0,.12);\n  margin-right: 16px;\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
