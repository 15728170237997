import Pagination from 'interfaces/components/common/Pagination';
import KmanagerTableColumn from 'interfaces/components/common/Table/KmanagerTableColumn';
import { useContext, useState } from 'react';

import GovernmentPropertyTableRecord from './GovernmentPropertyTableRecord';
import { GovernmentPropertiesContext } from '../../../../pages/GovernmentProperties/entities/GovernmentPropertiesContext';

import { useSortableData } from 'shared/hooks/useSort.js';
import usePagination from 'shared/hooks/usePagination';
import 'interfaces/css/common/kmanager-table.css';
import { ProcedureTypes } from 'shared/utils/constants/government';

const GovernmentPropertyTable = ({ setID }) => {
  const { auction, properties } = useContext(GovernmentPropertiesContext);
  console.log(auction);

  const {
    items: sortedProperties,
    requestSort,
    sortConfig,
  } = useSortableData(properties);

  const [rowsPerPage, setRowsPerPage] = useState(50);

  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(sortedProperties, rowsPerPage);

  const header = [
    { id: 1, column: 'resolutionDate', text: '議決日' },
    { id: 2, column: 'fixResolutionDate', text: '確定議決日登録' },
    { id: 3, column: 'printOrder', text: '掲載順位' },
    { id: 4, column: 'saleNumber', text: '売却区分番号' },
    { id: 5, column: 'name', text: '物件タイトル' },
    { id: 6, column: 'cancelDate', text: '中止状況' },
    { id: 7, column: 'bidPrice', text: '価格' },
    { id: 8, column: 'deposit', text: '保証金額' },
    { id: 9, column: 'bidEndPrice', text: '落札額' },
    {
      id: 10,
      column: 'proposalBank',
      text: '申込数(銀行振込など)\n 未承認　　　本登録',
    },
    {
      id: 11,
      column: 'proposalCredit',
      text: '申込数(クレジットカード)\n 未承認　　　本登録',
    },
    { id: 12, column: 'bidders', text: '入札者数' },
    ...(auction.procedureType !== ProcedureTypes.ASSET_SALE.string
      ? [
          {
            id: 13,
            column: 'additionalBidders',
            text: '追加入札者数',
          },
        ]
      : []),
    { id: 14, column: 'divisionName', text: '執行機関名' },
  ];

  return (
    <div>
      <div className="kmanager-table-wrap">
        <span className="dashboard">
          <table
            className="kmanager-table kmanager-table-hover"
            data-testid="kmanager-government-deposits-table">
            <KmanagerTableColumn
              headers={header}
              requestSort={requestSort}
              sortConfig={sortConfig}
            />
            <tbody>
              {currentItems?.map((property, count) => (
                <GovernmentPropertyTableRecord
                  key={count}
                  auction={auction}
                  property={property}
                  setId={setID}
                />
              ))}
            </tbody>
          </table>
        </span>
      </div>
      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        DATA_COUNT={sortedProperties.length}
        display_number={[25, 50, 100, 'ALL']}
      />
    </div>
  );
};

export default GovernmentPropertyTable;
