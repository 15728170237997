
export class Id {
    #id

    constructor(id) {
        this.#id = id;
    }

    get() {
        return this.#id
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
