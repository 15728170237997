import Button from 'interfaces/components/common/Button/Button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from 'shared/utils/helper/date_format';

import DeliveryStyleSelectRadio from './DeliveryStyleSelectRadio';

import './css/delivery-style-selector.css';
import { governmentTypes } from 'domain/Property/entities/constants/governmentTypes';
import { deliveryStyles } from 'domain/Property/entities/constants/deliveryStyles';

import useModalControl from 'shared/hooks/useModalControl';

import RegistrationPickUpDeliveryStyleModal from './RegistrationPickUpDeliveryStyleModal';

const DeliveryStyleSelector = ({ data, isShippingRegistration }) => {
  const navigation = useNavigate();
  const [deliveryStyle, setDeliveryStyle] = useState(deliveryStyles.CARRIER);
  const { isOpen, handleOpen, handleClose } = useModalControl();

  if (isShippingRegistration) {
    return;
  }

  // 国税局でない場合は選択肢を非表示
  const isNationalTaxAgency =
    data?.governmentType === governmentTypes.NATIONAL_TAX_AGENCY;
  if (!isNationalTaxAgency) {
    return;
  }

  // 配送設定が非表示の場合は選択肢を非表示
  const isDisplayed = data?.isDeliveryRequestDisplayed === 'DISPLAY';
  if (!isDisplayed) {
    return;
  }

  // 入札期間終了していない場合は非表示
  const isEndBidding = data?.status === 'END_BIDDING';
  if (!isEndBidding) {
    return;
  }

  // 最高額入札者でない場合は非表示
  const isMemberShip = data?.bidderUser === data?.membershipId;
  if (!isMemberShip) {
    return;
  }

  // 配送設定の期限を過ぎていた場合は非表示
  const today = new Date();
  const paymentDeadLine = new Date(data?.paymentDeadline);
  const deliveryStyleSelectDeadline = new Date(
    paymentDeadLine.setHours(17, 0, 0, 0),
  );
  const isBeforeDeadLine = today < deliveryStyleSelectDeadline;
  if (!isBeforeDeadLine) {
    return;
  }

  const deliveryStyleList = [
    {
      id: 1,
      mainLabel: '引渡方法：運送業者に依頼',
      subLabel: '運送業者に運送させることにより引き渡しを受ける方法です。',
      value: deliveryStyles.CARRIER,
    },
    {
      id: 2,
      mainLabel: '引渡方法：直接引き取り',
      subLabel:
        '買受人が税務署長等の指定する場所において、購買担当者から直接引き渡しを受ける方法です。',
      value: deliveryStyles.PICKUP,
    },
  ];

  const handleClick = () => {
    if (deliveryStyle === deliveryStyles.CARRIER) {
      navigation(`/items/${data?.id}/shipping`, { state: { data: data } });
    }
    if (deliveryStyle === deliveryStyles.PICKUP) {
      handleOpen();
    }
  };

  return (
    <div className="delivery-style-selector">
      <div className="text-center">
        <p>
          この物件は希望される引渡方法の登録が必要です。引渡方法は変更できません。
        </p>
        <p>
          以下より受け取りの方法（引渡方法）を選択してください。
          <span className="important">{`【登録期限：${dateFormat(deliveryStyleSelectDeadline, 'YYYY-MM-DD-hh-mm')}まで】`}</span>
        </p>
        <p>
          なお、引き渡しは買受代金納付後となります。入金が確認できない場合は引き渡しできません。
        </p>
      </div>
      <div className="my-6" data-testid="delivery-style-selector-body">
        {deliveryStyleList.map((style) => (
          <DeliveryStyleSelectRadio
            key={style.id}
            style={style}
            deliveryStyle={deliveryStyle}
            setDeliveryStyle={setDeliveryStyle}
          />
        ))}
      </div>
      <div className="text-center">
        <Button
          text="次へ"
          onClick={handleClick}
          className="delivery-style-select-button"
          height="60px"
        />
      </div>
      <RegistrationPickUpDeliveryStyleModal
        isOpen={isOpen}
        handleClose={handleClose}
      />
    </div>
  );
};

export default DeliveryStyleSelector;
