import axios from 'axios';

import {API_BASE_URL} from "../../config/envConstants.js";

export const fetchFavoriteData = async () => {
  return await axios.get(`${API_BASE_URL}/api/v1/favorite`);
};
export const createFavoriteData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/favorite`,
    body, {headers: headers}
  );

};
export const updateFavoriteData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/favorite/${id}`,
    body, {headers: headers}
  );
};
export const deleteFavoriteData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/favorite/${id}`, {
      headers: headers,
    },);
};