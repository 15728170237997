
export class BidEndType {
  #bidEndType

  constructor(bidEndType) {
    this.#bidEndType = bidEndType;
  }

  get() {
    return this.#bidEndType
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
