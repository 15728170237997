import styled from 'styled-components';

export const FlexBox = styled.div`
  display: flex;
`;

export const JustifyContentFlex = styled(FlexBox)`
  justify-content: ${(props) => props.justifycontent || 'flex-start'};
`;

export const AlignItemsFlex = styled(FlexBox)`
  align-items: ${(props) => props.alignitems || 'stretch'};
`;

export const FlexDirectionFlex = styled(FlexBox)`
  flex-direction: ${(props) => props.flexdirection || 'row'};
`;

export const FlexWrapFlex = styled(FlexBox)`
  flex-wrap: ${(props) => props.flexwrap || 'nowrap'};
`;
