const paymentString = (paymentMethod) => {
  console.log(paymentMethod);
  let paymentString = [];
  if (paymentMethod.bankTransfer !== undefined) {
    if (
      paymentMethod.bankTransfer === true ||
      paymentMethod.bankTransfer === "TRUE"
    ) {
      paymentString.push("銀行振込");
    }
  }
  if (paymentMethod.registeredMail !== undefined) {
    if (
      paymentMethod.registeredMail === true ||
      paymentMethod.registeredMail === "TRUE"
    ) {
      paymentString.push("現金書留");
    }
  }
  if (paymentMethod.check !== undefined) {
    if (paymentMethod.check === true || paymentMethod.check === "TRUE") {
      paymentString.push("小切手");
    }
  }
  if (paymentMethod.postalMoneyOrder !== undefined) {
    if (
      paymentMethod.postalMoneyOrder === true ||
      paymentMethod.postalMoneyOrder === "TRUE"
    ) {
      paymentString.push("郵便為替");
    }
  }

  if (paymentMethod.carry !== undefined) {
    if (paymentMethod.carry === true || paymentMethod.carry === "TRUE") {
      paymentString.push("直接持参");
    }
  }

  return paymentString.join("、");
};

export const property_info_asset_sale = (data, division, category) => [
  {
    text: "出品形式",
    state:
      data?.publicSaleType === ""
        ? "未入力"
        : data?.publicSaleType === "BID"
          ? "入札形式"
          : "せり売形式",
  },
  {
    text: "掲載順位",
    state: data?.printOrder === "" ? "未入力" : data?.printOrder,
  },
  {
    text: "カテゴリ",
    state:
      category.grandchild === undefined
        ? "未入力"
        : `${category?.parent.name} ＞ ${category?.child.name} ＞ ${category?.grandchild.name}`,
  },
  {
    text: "イチ押し設定",
    state:
      data?.recommendProperties.reccomend === undefined ? "未設定" : "設定済",
  },
  {
    text: "執行機関名称",
    state: division?.text === "" ? "未入力" : division?.text,
  },
  {
    text: "売却区分番号",
    state: data?.saleNumber === "" ? "未入力" : data?.saleNumber,
  },
  {
    text: "タイトル",
    state: data?.name === "" ? "未入力" : data?.name,
  },
  {
    text: "支払い方法",
    state: paymentString(data?.paymentMethod),
  },
  {
    text: "議決日",
    state: data?.resolutionDate === "" ? "未入力" : data?.resolutionDate,
  },
  {
    text: "売払代金納付期限",
    state: data?.paymentLimit === "" ? "未入力" : data?.paymentLimit,
  },
  {
    text: "契約締結期限",
    state: data?.contractedLimit === "" ? "未入力" : data?.contractedLimit,
  },
  {
    text: "動画URL",
    state: data?.videoUrl === "" ? "未入力" : data?.videoUrl,
  },
  {
    text: "物件の種類",
    state: data?.type === "" ? "未入力" : data?.type,
  },
  {
    text: "物件の所在",
    state:
      data?.governmentSubCategoryId === ""
        ? "未入力"
        : data?.governmentSubCategoryId,
  },
];

// 国税の時にこちらに入る
export const property_info_public_sale = (data, division, category) => [
  {
    text: "掲載順位",
    state: data?.printOrder === "" ? "未入力" : data?.printOrder,
  },
  {
    text: "カテゴリ",
    state:
      category.grandchild === undefined
        ? "未入力"
        : `${category?.parent.name} ＞ ${category?.child.name} ＞ ${category?.grandchild.name}`,
  },
  {
    text: "イチ押し設定",
    state:
      data?.recommendProperties.reccomend === undefined ? "未設定" : "設定済",
  },
  {
    text: "執行機関名称",
    state: division?.text === "" ? "未入力" : division?.text,
  },
  {
    text: "売却区分番号",
    state: data?.saleNumber === "" ? "未入力" : data?.saleNumber,
  },
  {
    text: "タイトル",
    state: data?.name === "" ? "未入力" : data?.name,
  },
  {
    text: "支払い方法",
    state: paymentString(data?.paymentMethod),
  },
  {
    text: "買受代金納付期限",
    state: data?.paymentLimit === "" ? "未入力" : data?.paymentLimit,
  },
  {
    text: "動画URL",
    state: data?.videoUrl === "" ? "未入力" : data?.videoUrl,
  },
  {
    text: "物件の種類",
    state: data?.type === "" ? "未入力" : data?.type,
  },
  {
    text: "物件の所在",
    state:
      data?.governmentSubCategoryId === ""
        ? "未入力"
        : data?.governmentSubCategoryId,
  },
];
