import { DATA_EMPTY } from 'domain/Property/entities/constants/property';

const handleDataEmpty = (item) => {
  return item === DATA_EMPTY ? '' : item;
};

const handleReceiver = (info, data) => {
  if (typeof data === 'number') {
    return info?.receiver === 'OTHER' ? data : null;
  }
  return info?.receiver === 'OTHER' ? data : '';
};

export const deliveryInfoMapper = (info, receiverName) => {
  return {
    carrier: {
      target: info?.carrierNameList,
      carrierName: handleDataEmpty(info?.carrierName),
      carrierTel: handleDataEmpty(info?.carrierTel),
    },
    targetArrangement: info?.arrangement,
    targetPackingMethod: info?.packing,
    address: {
      target: info?.receiver,
      // MEMO: applicantは配送登録側で取得するのでマッピング不要
      receiver: {
        name: {
          name: handleReceiver(info, receiverName),
          label: handleReceiver(
            info,
            `${receiverName}${info?.receiverName === 'CLIENT' ? ' (依頼人)' : ' (委任者)'}`,
          ),
          role: handleReceiver(info, info?.receiverRole),
        },
        postalCode1: handleReceiver(info, info?.zipCode?.split('-')[0]),
        postalCode2: handleReceiver(info, info?.zipCode?.split('-')[1]),
        prefectureId: handleReceiver(
          info,
          Number(info?.receiverGovernmentSubCategoryId),
        ),
        address: handleReceiver(info, info?.receiverAddress),
        tel: handleReceiver(info, info?.receiverTel),
        deliveryReason: handleReceiver(info, info?.deliveryReason),
      },
    },
    paymentData: {
      target: info?.isPaid,
      paymentScheduledDate: handleDataEmpty(info?.paymentScheduledDate)
        ? new Date(info?.paymentScheduledDate)
        : null,
    },
    deliverySchedule: {
      target: handleDataEmpty(info?.deliveryDate) ? 'SPECIFY' : 'NO_SPECIFY',
      deliveryDate: handleDataEmpty(info?.deliveryDate)
        ? new Date(info?.deliveryDate)
        : null,
      deliveryTime: handleDataEmpty(info?.deliveryTime),
    },
    deliveryRequest: info?.deliveryText,
  };
};
