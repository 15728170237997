import naviLogin from 'interfaces/css/Login/navi-login.module.css';

import logoSVG from '../../../assets/svg/logo.svg'
import LoginBox from "../../Auth/navi/LoginBox";

const Login = () => {
    return (
        <span className={naviLogin.login} data-testid="navi-login">
            <span className={naviLogin.logo_wrap}>
                <img src={logoSVG} alt="KSI 官公庁オークション" width="190" height="27" data-testid="logo"/>
            </span>
            <LoginBox />
        </span>
    );
};

export default Login;