
export class DisplayTarget {
  #displayTarget

  constructor(displayTarget) {
    this.#displayTarget = displayTarget;
  }

  get() {
    return this.#displayTarget
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
