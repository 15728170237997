import GovernmentProperty from "interfaces/components/Government/kmanager/Property/GovernmentProperty";

const KmanagerGovernmentProperties = () => {
    return (
        <div data-testid="kmanager-property">
            <GovernmentProperty/>
        </div>
    );
}

export default KmanagerGovernmentProperties;