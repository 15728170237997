// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-schedule {
  background: #fff;
  border: 1px solid var(--line-base);
  border-radius: 4px;
  padding: 8px 30px 20px 38px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/simple-schedule.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kCAAkC;EAClC,kBAAkB;EAClB,2BAA2B;AAC7B","sourcesContent":[".simple-schedule {\n  background: #fff;\n  border: 1px solid var(--line-base);\n  border-radius: 4px;\n  padding: 8px 30px 20px 38px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
