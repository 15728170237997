import AddressForm from 'interfaces/components/common/Form/AddressForm.jsx';
import PostalCodeForm from 'interfaces/components/common/Form/PostalCodeForm.jsx';
import PrefectureForm from 'interfaces/components/common/Form/PrefectureForm.jsx';
import TelForm from 'interfaces/components/common/Form/TelForm.jsx';
import TextForm from 'interfaces/components/common/Form/TextForm.jsx';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel.jsx';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault.jsx';
import handleAddressSearch from 'shared/hooks/handleAddressSearch.js';
import { PREFECTURE } from 'shared/utils/constants/prefecture.js';

import ReceiverNameForm from './ReceiverNameForm.jsx';

const ReceiverAddressForm = ({ address, setAddress }) => {
  const receiver = address.receiver;
  const handler = (obj) => {
    setAddress({ ...address, receiver: { ...receiver, ...obj } });
  };

  return (
    <div>
      <CommonTableDefault>
        <ReceiverNameForm
          receiverName={receiver?.name}
          handleSelect={(item) => handler({ name: item })}
        />
        <PostalCodeForm
          postalCode1={receiver?.postalCode1}
          postalCode2={receiver?.postalCode2}
          handleChangePostalCode1={(e) =>
            handler({ postalCode1: e.target.value })
          }
          handleChangePostalCode2={(e) =>
            handler({ postalCode2: e.target.value })
          }
          handleAddressSearch={() =>
            handleAddressSearch(
              receiver?.postalCode1,
              receiver?.postalCode2,
              handler,
            )
          }
        />
        <PrefectureForm
          prefectureId={receiver.prefectureId}
          handleSelect={(item) =>
            handler({
              prefectureId: PREFECTURE.find(
                (prefecture) => prefecture.name === item,
              ).id,
            })
          }
        />
        <AddressForm
          receiverAddress={receiver?.address}
          handleChange={(e) => handler({ address: e.target.value })}
        />
        <TelForm
          tel={receiver?.tel}
          handleChange={(e) => handler({ tel: e.target.value })}
        />
        <TextForm
          label={
            <span>
              変更理由
              <RequiredLabel />
            </span>
          }
          value={receiver?.deliveryReason}
          handleChange={(e) => handler({ deliveryReason: e.target.value })}
          validates={['no_message_required']}
          charLimit={50}
        />
      </CommonTableDefault>
    </div>
  );
};

export default ReceiverAddressForm;
