//import { isDefinedDateTime, isAfterSet } from "../../../../application/validations.js";
import { isDefinedDateTime } from "../../../../application/validations.js";

export class CloseAt {
  #closeAt

  constructor(closeAt) {
    this.#closeAt = closeAt;
  }

  get() {
    return this.#closeAt
  }

  get isValid() {
    return [
      this.isDefinedDateTime,
      this.isAfterSet,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get isDefinedDateTime() {
    return isDefinedDateTime(this.#closeAt)
  }

  // バリデーションルールの適用
  get isAfterSet() {
    return 'TRUE'
    // TOOD: startDateTimeが引き渡されていないため一時コメントアウト
    //return isAfterSet(this.#closeAt, publishDateTime)
  }
}
