import ChildCategories from './KmanagerChildCategories';
import CategoryAccordion from '../../common/Category/CategoryAccordion';

const ParentCategories = ({ categories, handleAdd, setInputs }) => {
  return (
    categories &&
    Object.entries(categories).map(([id, category]) => (
      <CategoryAccordion
        key={id}
        id="parent-category"
        marginLeft={25}
        category={category}
        handleAdd={handleAdd}
        parentId={category.id}
        grandParentId={-9999}
        setInputs={setInputs}
        depth={1}
        contents={
          <ChildCategories
            categories={category.children}
            setInputs={setInputs}
            handleAdd={handleAdd}
            parentId={category.id}
          />
        }
      />
    ))
  );
};

export default ParentCategories;
