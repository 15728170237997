import { DATA_EMPTY } from 'domain/Property/entities/constants/property';

const ShippingInfoRecord = ({ info }) => {
  if (info.value === DATA_EMPTY) {
    return;
  }

  return (
    <tr key={info.id} data-testid="shipping-info">
      <th>{info.label}</th>
      <td>{info.value}</td>
    </tr>
  );
};

export default ShippingInfoRecord;
