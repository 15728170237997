import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import { RealEstateFormData } from 'interfaces/components/Property/navi/RealEstate/RealEstateFormData';
import PropertyInfoGeneralComplete from 'interfaces/components/Property/PropertyInfo/General/PropertyInfoGeneralComplete';
import PropertyInfoGeneralConfirm from 'interfaces/components/Property/PropertyInfo/General/PropertyInfoGeneralConfirm';
import PropertyInfoGeneralForm from 'interfaces/components/Property/PropertyInfo/General/PropertyInfoGeneralForm';
import { breadcrumbs } from 'interfaces/pages/PropertyInfoRealEstate/entities/breadcrumbs';
import { PropertyInfoRealEstateContext } from 'interfaces/pages/PropertyInfoRealEstate/entities/PropertyInfoRealEstateContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

const RealEstate = () => {
  const { governmentId, invitation, property, paymentMethods } = useContext(
    PropertyInfoRealEstateContext,
  );

  const [procedureType, setProcedureType] = useState('');
  const [onConfirm, setOnConfirm] = useState(false);
  const [onComplete, setOnComplete] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { auction_id, property_id } = useParams();
  const { localStorageProcedureType } = useLocalStorage();

  const search = useLocation().search;
  let queryParams = new URLSearchParams(search);
  let openWay = queryParams.get('open-way');
  let isEdit = false;
  console.log(property_id);
  if (property_id !== undefined) {
    console.log('isEdit');
    isEdit = true;
  }
  const formData = RealEstateFormData({ isEdit: isEdit });

  // 現状ダミー情報
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (property_id === undefined) {
      formData.init.setPublicSaleType(openWay);
      return;
    }
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    // 公開設定
    formData.init.setIsDraft(property?.isDraft);

    // 2.物件情報
    formData.init.setPublicSaleType(
      property?.publicSaleType === undefined
        ? openWay
        : property.publicSaleType,
    );
    formData.init.setPrintOrder(property?.printOrder);
    formData.init.setCategoryId(property?.categoryId);
    //formData.init.setRecommendProperties({ recommend: recommend_property ?? true, })
    formData.init.setDivisionId(property?.divisionId);
    formData.init.setSaleNumber(property?.saleNumber);
    formData.init.setName(property?.name);
    let methods = {};
    paymentMethods?.map((method) => {
      if (method.payment === 'BANK_TRANSFER') {
        methods.bankTransfer = 'TRUE';
      }
      if (method.payment === 'REGISTERED_MAIL') {
        methods.registeredMail = 'TRUE';
      }
      if (method.payment === 'CHECK') {
        methods.check = 'TRUE';
      }
      if (method.payment === 'POSTAL_MONEY_ORDER') {
        methods.postalMoneyOrder = 'TRUE';
      }
      if (method.payment === 'CARRY') {
        methods.carry = 'TRUE';
      }
    });
    formData.init.setPaymentMethod(methods);
    formData.init.setVideoUrl(property?.videoUrl);
    formData.init.setDescription(property?.description);

    // 3. 物件に関するお問い合わせ先
    formData.init.setInquiryName(property?.inquiryName);
    formData.init.setInquiryOffice(property?.inquiryOffice);
    formData.init.setInquiryEmail(property?.inquiryEmail);
    formData.init.setInquiryTel(
      property?.inquiryTel !== undefined
        ? {
            telNum1: property?.inquiryTel.split('-')[0],
            telNum2: property?.inquiryTel.split('-')[1],
            telNum3: property?.inquiryTel.split('-')[2],
          }
        : {
            telNum1: '',
            telNum2: '',
            telNum3: '',
          },
    );
    formData.init.setInquiryExtension(property?.inquiryExtension);
    formData.init.setInquiryAccept(property?.inquiryAccept);
    formData.init.setInquiryNote(property?.inquiryNote);

    // 4. 物件の詳細設定
    formData.init.setDepositPayment(property?.depositPayment);
    formData.init.setIsApprovalRequired(property?.isApprovalRequired);
    formData.init.setEstimatePrice(property?.estimatePrice);
    formData.init.setDeposit(property?.deposit);
    formData.init.setQualifiedInvoice(property?.qualifiedInvoice);

    // 5. 落札後の連絡先
    formData.init.setContactName(property?.contactName);
    formData.init.setContactOffice(property?.contactOffice);
    formData.init.setContactEmail(property?.contactEmail);
    formData.init.setContactTel(
      property?.contactTel !== undefined
        ? {
            telNum1: property?.contactTel.split('-')[0],
            telNum2: property?.contactTel.split('-')[1],
            telNum3: property?.contactTel.split('-')[2],
          }
        : {
            telNum1: '',
            telNum2: '',
            telNum3: '',
          },
    );
    formData.init.setContactExtension(property?.contactExtension);
    formData.init.setContactAccept(property?.contactAccept);

    // その他カラム
    formData.init.setIsCopied(property?.isCopied);
    formData.init.setPaymentDate(property?.paymentDate);
    formData.init.setPaymentLimit(property?.paymentLimit);
    formData.init.setPropertyType(property?.propertyType);
    formData.init.setResolutionDate(property?.resolutionDate);
    formData.init.setStatus(property?.status);
    formData.init.setAuctionId(property?.auctionId);
    formData.init.setContractedLimit(property?.contractedLimit);
    formData.init.setGovernmentId(property?.governmentId);
    formData.init.setGovernmentSubCategoryId(property?.governmentSubCategoryId);
    formData.init.setId(property?.id);
    formData.init.setPaymentDeadline(invitation?.paymentDeadline);
  }, [property, paymentMethods]);

  useEffect(() => {
    // TODO: ユーザー(自治体)のprocedureTypeを取得する -> userContextを作ってユーザー情報にアクセスできるようにする
    setProcedureType(
      localStorageProcedureType === 'PUBLIC_SALE'
        ? 'インターネット公売'
        : '公有財産売却',
    );
  }, [localStorageProcedureType]);

  useEffect(() => {
    // 必須ではないフォーム
    const baseNotRequiredKeys = [
      'printOrderValid',
      'recommendPropertiesValid',
      'videoUrlValid',
      'inquiryNameValid',
      'inquiryDepartmentValid',
      'inquiryEmailValid',
      'inquiryTelValid',
      'inquiryTelNum1Valid',
      'inquiryTelNum2Valid',
      'inquiryTelNum3Valid',
      'inquiryExtensionValid',
      'inquiryAcceptValid',
      'noteValid',
      'contactDepartmentValid',
      'contactEmailValid',
      'contactTelValid',
      'contactTelNum1Valid',
      'contactTelNum2Valid',
      'contactTelNum3Valid',
      'contactExtensionValid',
      'contactAcceptValid',
      'contractedLimitValid',
      'categoryRequiredValid',
    ];

    // 条件付きで必須のフォーム
    const isPublicSale = localStorageProcedureType === 'PUBLIC_SALE';
    const conditionNotRequiredKeys = isPublicSale
      ? ['resolutionDateValid']
      : [];

    const notRequiredKeys = [
      ...baseNotRequiredKeys,
      ...conditionNotRequiredKeys,
      //...(includeAddressKeys ? addressRelatedKeys : []),
    ];
    const validationResults = Object.entries(formData.validationMessage).map(
      ([key, validationMessage]) => {
        const isValid = notRequiredKeys.includes(key)
          ? validationMessage === null || validationMessage === 'TRUE'
          : validationMessage === 'TRUE';
        return { key, validationMessage, isValid };
      },
    );

    const allValid = validationResults.every(({ isValid }) => isValid);
    console.log(validationResults);
    setIsValid(allValid);
  }, [formData]);

  const handleConfirm = (e) => {
    e.preventDefault();
    setOnConfirm(true);
  };

  const handleBackForm = (e) => {
    e.preventDefault();
    setOnConfirm(false);
  };

  const handleComplete = (e) => {
    e.preventDefault();
    setOnComplete(true);
  };

  const getMethod = () => {
    if (property_id !== undefined) {
      return 'update';
    }
    return 'create';
  };

  const getTitle = () => {
    if (getMethod() === 'create') {
      return `物件情報（一般）登録：${invitation?.name}`;
    }
    return `物件登録情報編集：${invitation?.name}`;
  };

  return (
    <div className="new-style_navi_form new-style_navi_object_create_real-estate">
      {onComplete ? (
        <PropertyInfoGeneralComplete method={getMethod()} openWay={openWay} />
      ) : onConfirm ? (
        <PropertyInfoGeneralConfirm
          formData={formData}
          handleComplete={handleComplete}
          handleBackForm={handleBackForm}
          openWay={openWay}
        />
      ) : (
        <>
          <Breadcrumb
            breadcrumbs={breadcrumbs(
              `${getMethod()}`,
              'form',
              invitation?.name,
              auction_id,
              openWay,
            )}
          />
          <PropertyInfoGeneralPageDescription
            title={getTitle()}
            content={
              property_id === undefined
                ? '物件の登録を行います。入力が終わったら「確認する」ボタンをクリックしてください。'
                : '物件の編集を行います。入力が終わったら「確認画面」ボタンを押してください。'
            }
          />
          <PropertyInfoGeneralForm
            formData={formData}
            procedureType={procedureType}
            governmentId={governmentId}
            handleClick={handleConfirm}
            isValid={isValid}
          />
        </>
      )}
    </div>
  );
};
export default RealEstate;
