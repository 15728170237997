import { useEditUserInputs } from '../hook/useEditUserInputs';
import { useEditUserInputsValidation } from '../hook/useEditUserInputsValidation';

export function EditUserFormData({ isEdit = false }) {
  const formInputs = useEditUserInputs();
  const validate = useEditUserInputsValidation({ isEdit: isEdit });

  function handleChangeCorpName(e) {
    formInputs.setters.setCorpName(e.target.value);
  }

  function handleChangeCorpNameKana(e) {
    formInputs.setters.setCorpNameKana(e.target.value);
  }

  function handleChangeFamilyName(e) {
    validate.setters.handleFamilyNameValid(e);
    formInputs.setters.setFamilyName(e.target.value);
  }

  function handleChangeFamilyNameKana(e) {
    validate.setters.handleFamilyNameKanaValid(e);
    formInputs.setters.setFamilyNameKana(e.target.value);
  }

  function handleChangeFirstName(e) {
    validate.setters.handleFirstNameValid(e);
    formInputs.setters.setFirstName(e.target.value);
  }

  function handleChangeFirstNameKana(e) {
    validate.setters.handleFirstNameKanaValid(e);
    formInputs.setters.setFirstNameKana(e.target.value);
  }

  function handleChangeSex(value) {
    formInputs.setters.setSex(value);
  }

  function handleChangeBirthDate(value) {
    validate.setters.handleBirthDateValid(value);
    formInputs.setters.setBirthDate(value);
  }

  function handleChangePostalCode1(e) {
    validate.setters.handlePostalCode1Valid(e);
    formInputs.setters.setPostalCode1(e.target.value);
  }
  function handleChangePostalCode2(e) {
    validate.setters.handlePostalCode2Valid(e);
    formInputs.setters.setPostalCode2(e.target.value);
  }

  function handleChangePostalCode(e) {
    validate.setters.handlePostalCodeValid(e);
    formInputs.setters.setPostalCode(e.target.value);
  }

  function handleChangeLocationGovernmentSubCategoryId(value) {
    validate.setters.handleLocationGovernmentSubCategoryIdValid(value);
    formInputs.setters.setLocationGovernmentSubCategoryId(value);
  }

  function handleChangeAddress(value) {
    validate.setters.handleAddressValid(value);
    formInputs.setters.setAddress(value);
  }

  function handleChangeHouseTel(e) {
    validate.setters.handleHouseTelValid(e);
    formInputs.setters.setHouseTel(e.target.value);
  }

  function handleChangeMobileTel(e) {
    validate.setters.handleMobileTelValid(e);
    formInputs.setters.setMobileTel(e.target.value);
  }

  const validateInputs = {
    data: formInputs.data,
    init: formInputs.setters,
    setters: {
      handleChangeCorpName,
      handleChangeCorpNameKana,
      handleChangeFamilyName,
      handleChangeFamilyNameKana,
      handleChangeFirstName,
      handleChangeFirstNameKana,
      handleChangeSex,
      handleChangeBirthDate,
      handleChangePostalCode1,
      handleChangePostalCode2,
      handleChangePostalCode,
      handleChangeLocationGovernmentSubCategoryId,
      handleChangeAddress,
      handleChangeHouseTel,
      handleChangeMobileTel,
    },
    validationMessage: validate.validationMessage,
  };

  return validateInputs;
}
