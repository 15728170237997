import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class ImportantHandlingBidDeposit {
  #importantHandlingBidDeposit

  constructor(importantHandlingBidDeposit) {
    this.#importantHandlingBidDeposit = importantHandlingBidDeposit;
  }

  get() {
    return this.#importantHandlingBidDeposit
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#importantHandlingBidDeposit, 0, 1000)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#importantHandlingBidDeposit)
  }
}
