import { SortableTh } from 'interfaces/components/common/Table/Th/SortableTh';

const AuctionDepositTableColumn = ({
  auction,
  auctionDeposits,
  requestSort,
  sortConfig,
  checked,
  allCheck,
  clearCheck,
}) => {
  let headerColumns = [
    { id: 1, column: 'location', text: '地域' },
    { id: 2, column: 'region', text: '都道府県' },
    { id: 3, column: 'governmentName', text: '行政機関' },
    { id: 4, column: 'divisionName', text: '執行機関' },
    { id: 5, column: 'depositProxy', text: '納付額' },
    ...(auction.procedureType === 'PUBLIC_SALE'
      ? [
          {
            id: 6,
            column: 'estimatedInvoiceDate',
            text: '保証金返還請求書発行日',
          },
          {
            id: 7,
            column: 'estimatedReturnDate',
            text: '公売(入札)保証金返還予定日',
          },
          {
            id: 8,
            column: 'systemUsageFeeTotal',
            text: '余分な保証金の返還\n返還日　　　　返還額',
          },
        ]
      : []),
  ];

  const checkBoxChange = () => {
    if (
      JSON.stringify(checked) ===
      JSON.stringify(auctionDeposits.map((row) => row.governmentId))
    ) {
      clearCheck();
    } else {
      console.log(allCheck);
      allCheck();
    }
  };

  return (
    <thead data-testid="kmanager-auction-table-column">
      <tr>
        <th
          className="kmanager-auction-th kmanager-table-th kmanager-table-th-checkbox text-center"
          data-testid="kmanager-auction-table-th">
          <div className="d-flex align-center justify-center kmanager-table-th-check-box-icon">
            <input
              role="checkbox"
              type="checkbox"
              value={checked}
              onChange={checkBoxChange}
              checked={
                JSON.stringify(checked) ===
                JSON.stringify(auctionDeposits.map((row) => row.governmentId))
              }
            />
          </div>
        </th>
        {headerColumns.map((header) => (
          <SortableTh
            key={header.id}
            header={header}
            onClick={() => requestSort(header.column)}
            sortConfig={sortConfig}
          />
        ))}
      </tr>
    </thead>
  );
};

export default AuctionDepositTableColumn;
