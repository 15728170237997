export const gov_types = [
  { id: 1, value: "NATIONAL_TAX_AGENCY", label: "国税庁" },
  { id: 2, value: "NATIONAL_ASSET", label: "国_公有財産売却" },
  { id: 3, value: "PREFECTURE", label: "都道府県" },
  { id: 4, value: "MUNICIPALITY", label: "市区町村" },
  { id: 5, value: "OTHER", label: "その他の行政機関" },
];

export const gov_procedure_types = [
  { id: 1, value: "PUBLIC_SALE", label: "公売" },
  { id: 2, value: "ASSET_SALE", label: "公有財産売却" },
  { id: 3, value: "BOTH", label: "両方" },
];

export const gov_procedure_types_for_national_tax_agency = [
  { id: 1, value: "PUBLIC_SALE", label: "公売" },
];
