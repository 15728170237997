// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-up-modal-contents {
  width: 100%;
  padding: 8px 8px 16px 8px;
  border-top: solid 8px var(--primary-base);
  border-radius: 5px;
  pointer-events: auto;
  overflow-y: auto;
}

.sign-up-modal-title {
  font-size: 1.25rem;
  text-align: center;
  padding: 16px;
}

.sign-up-description-image {
  width: 100%;
}

.sign-up-modal-contents-body {
  padding: 16px 24px;
  font-size: .875rem;
  line-height: 1.375rem;
}

.close-sign-up-modal-button-wrap {
  display: flex;
  justify-content: center;
  padding: 8px;
  margin-top: 16px;
}

.close-sign-up-modal-button {
  color: var(--anchor-base);
  background-color: var(--white);
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/sign-up-modal-contents.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,yCAAyC;EACzC,kBAAkB;EAClB,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,8BAA8B;EAC9B,gBAAgB;AAClB","sourcesContent":[".sign-up-modal-contents {\n  width: 100%;\n  padding: 8px 8px 16px 8px;\n  border-top: solid 8px var(--primary-base);\n  border-radius: 5px;\n  pointer-events: auto;\n  overflow-y: auto;\n}\n\n.sign-up-modal-title {\n  font-size: 1.25rem;\n  text-align: center;\n  padding: 16px;\n}\n\n.sign-up-description-image {\n  width: 100%;\n}\n\n.sign-up-modal-contents-body {\n  padding: 16px 24px;\n  font-size: .875rem;\n  line-height: 1.375rem;\n}\n\n.close-sign-up-modal-button-wrap {\n  display: flex;\n  justify-content: center;\n  padding: 8px;\n  margin-top: 16px;\n}\n\n.close-sign-up-modal-button {\n  color: var(--anchor-base);\n  background-color: var(--white);\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
