import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class ImportantDescription3 {
  #importantDescription3

  constructor(importantDescription3) {
    this.#importantDescription3 = importantDescription3;
  }

  get() {
    return this.#importantDescription3
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#importantDescription3, 0, 1000)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#importantDescription3)
  }
}
