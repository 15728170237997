import ReceptionCompletedBidsProvider from './ReceptionCompletedBidsProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { receptionCompletedBids } from "../entities/componentMapping.js";

const ReceptionCompletedBids = ({ type }) => {
  return (
    <ReceptionCompletedBidsProvider type={type}>
      {getComponent(receptionCompletedBids, type)}
    </ReceptionCompletedBidsProvider>
  );
};

export default ReceptionCompletedBids;
