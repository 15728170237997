export const breadcrumbs = (onConfirm, isDetail, fullname) => {
  const baseCrumbs = [
    { title: '官公庁ナビトップ', to: '/navi' },
    { title: 'ナビユーザー管理', to: '/navi/user' },
    { title: isDetail ? fullname : '新規登録' },
  ];

  if (onConfirm) {
    baseCrumbs.push({ title: isDetail ? '更新内容確認' : '登録内容確認' });
  }

  return baseCrumbs;
};
