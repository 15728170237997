// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-bottom {
  height: 60px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 16px;
  border-bottom: 1px solid #e8e6e6;
}

@media screen and (min-width: 720px) {
  .header-bottom {
    justify-content: space-between;
  }

  .header-bottom .header-icon {
    margin-right: 12px;
  }
}

@media screen and (max-width: 719px) {
  .header-bottom {
    justify-content: flex-end;
  }

  .header-bottom .header-icon {
    margin-right: 4px;
  }
}

.header-bottom .header-icon:last-child {
  margin-right: 0;
}

.header-bottom .header-icon:last-child {
  margin-right: 4px;
}

.header-bottom-icon-box {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/header-bottom.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE;IACE,8BAA8B;EAChC;;EAEA;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,8BAA8B;AAChC","sourcesContent":[".header-bottom {\n  height: 60px;\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap;\n  padding: 0 16px;\n  border-bottom: 1px solid #e8e6e6;\n}\n\n@media screen and (min-width: 720px) {\n  .header-bottom {\n    justify-content: space-between;\n  }\n\n  .header-bottom .header-icon {\n    margin-right: 12px;\n  }\n}\n\n@media screen and (max-width: 719px) {\n  .header-bottom {\n    justify-content: flex-end;\n  }\n\n  .header-bottom .header-icon {\n    margin-right: 4px;\n  }\n}\n\n.header-bottom .header-icon:last-child {\n  margin-right: 0;\n}\n\n.header-bottom .header-icon:last-child {\n  margin-right: 4px;\n}\n\n.header-bottom-icon-box {\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
