// import useRipple from "../../../hooks/useRipple.js";
import "../../../../interfaces/css/common/ripple-effect.css";
import "../../../../interfaces/css/common/button.css"

const PrimaryButton = ({ children, disabled, onClick}) => {
  // const [ripples, addRippleEffect] = useRipple();
  return (
    <button
      className={`button button-primary button-shadow ${disabled ? "button-disabled" : ""}`}
      onClick={onClick}
      disabled={disabled}
      data-testid="primary-button"
    >
      <span className="button-content">{ children }</span>
      {/*{ ripples.map((ripple, index) => (*/}
      {/*  <span*/}
      {/*    key={index}*/}
      {/*    className="ripple"*/}
      {/*    style={{*/}
      {/*      top: ripple.top,*/}
      {/*      left: ripple.left,*/}
      {/*      width: ripple.size,*/}
      {/*      height: ripple.size*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)) }*/}
    </button>
  );
}

export default PrimaryButton;