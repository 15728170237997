import { useContext } from 'react';

import Button from 'interfaces/components/common/Button/Button.jsx';
import ApplicationPropertyInformationTable from 'interfaces/components/Guidelines/GuidelineAgreement/ApplicationPropertyInformationTable.jsx';
import GovernmentLabel from 'interfaces/components/Guidelines/GuidelineAgreement/GovernmentLabel.jsx';
import HorizontalPairLinks from 'interfaces/components/Guidelines/GuidelineAgreement/HorizontalPairLinks.jsx';
import Stepper from 'interfaces/components/Guidelines/GuidelineAgreement/Stepper.jsx';
import StepTitle from 'interfaces/components/Guidelines/GuidelineAgreement/StepTitle.jsx';
import ParticipantInformationTable from 'interfaces/components/Property/service/PropertyDetail/VerifyInformation/ParticipantInformationTable.jsx';
import ReceptionCompletionMessage from 'interfaces/components/Reception/ReceptionCompletionMessage.jsx';

import { dateFormat } from 'shared/utils/helper/date_format';
import { EmptyData } from 'shared/utils/constants/emptyData';
import { ReceptionCompletedContext } from 'interfaces/pages/ReceptionCompleted/entities/ReceptionCompletedContext';
import { idToName } from 'shared/utils/constants/prefecture';

const stepData = {
  steps: [
    {
      label: 'ガイドライン同意',
    },
    {
      label: '申し込み情報の入力',
    },
    {
      label: '情報の確認',
    },
    {
      label: '受付完了',
    },
  ],
};

const stepTitleData = {
  num: 4,
  title: '受付完了',
};

const propertyTableData = (auction, property) => {
  const bidStart = dateFormat(auction?.bidStart, 'YYYY-MM-DD-hh-mm');
  const bidEnd = dateFormat(auction?.bidEnd, 'YYYY-MM-DD-hh-mm');

  return {
    tableData: [
      {
        id: 1,
        title: '物件名',
        value: property?.name,
      },
      {
        id: 2,
        title: '入札期間',
        value: `${bidStart} 〜 ${bidEnd}`,
      },
      {
        id: 3,
        title: '公売保証金',
        value: `${property?.deposit} 円`,
      },
    ],
  };
};

const participantInfoData = (applicantInformation, auctionUser, proposal) => {
  const birthday = dateFormat(applicantInformation?.birthDate, 'YYYY-MM-DD');

  return [
    {
      id: 1,
      title: '会員識別番号',
      value: auctionUser?.membershipId,
    },
    {
      id: 2,
      title: '法人、個人',
      value: EmptyData.includes(applicantInformation?.corpName)
        ? '個人で申し込み'
        : '法人で申し込み',
    },
    {
      id: 3,
      title: '法人名',
      value: `${applicantInformation?.corpName}`,
    },
    {
      id: 4,
      title: '法人名 カナ',
      value: `${applicantInformation?.corpNameKana}`,
    },
    {
      id: 5,
      title: '氏名',
      value: `${applicantInformation?.familyName} ${applicantInformation?.firstName}`,
    },
    {
      id: 6,
      title: '氏名 カナ',
      value: `${applicantInformation?.familyNameKana} ${applicantInformation?.firstNameKana}`,
    },
    {
      id: 7,
      title: '性別',
      value: applicantInformation?.sex === 'MAN' ? '男性' : '女性',
    },
    {
      id: 8,
      title: '生年月日',
      value: birthday,
    },
    {
      id: 9,
      title: '郵便番号',
      value: applicantInformation?.postalCode,
    },
    {
      id: 10,
      title: '都道府県',
      value: idToName(applicantInformation?.locationGovernmentSubCategoryId),
    },
    {
      id: 11,
      title: '住所',
      value: applicantInformation?.address,
    },
    {
      id: 12,
      title: '電話番号',
      value: `${EmptyData.includes(applicantInformation?.houseTel) ? '' : `自宅：${applicantInformation?.houseTel}`} ${EmptyData.includes(applicantInformation?.mobileTel) ? '' : `携帯：${applicantInformation?.mobileTel}`}`,
    },
    {
      id: 13,
      title: '公売保証金の納付方法',
      value: proposal?.aplType === 'ONLINE' ? 'クレジットカード' : '銀行振込',
    },
    {
      id: 14,
      title: '代理人による手続き',
      value: proposal?.isRepresentative === 'TRUE' ? 'する' : 'しない',
    },
  ];
};

const representativeInfoData = (applicantInformation) => {
  const birthday = dateFormat(applicantInformation?.birthDate, 'YYYY-MM-DD');

  return [
    {
      id: 1,
      title: '法人、個人',
      value: EmptyData.includes(applicantInformation?.corpName)
        ? '個人で申し込み'
        : '法人で申し込み',
    },
    {
      id: 2,
      title: '法人名',
      value: `${applicantInformation?.corpName}`,
    },
    {
      id: 3,
      title: '法人名 カナ',
      value: `${applicantInformation?.corpNameKana}`,
    },
    {
      id: 4,
      title: '氏名',
      value: `${applicantInformation?.familyName} ${applicantInformation?.firstName}`,
    },
    {
      id: 5,
      title: '氏名 カナ',
      value: `${applicantInformation?.familyNameKana} ${applicantInformation?.firstNameKana}`,
    },
    {
      id: 6,
      title: '性別',
      value: applicantInformation?.sex === 'MAN' ? '男性' : '女性',
    },
    {
      id: 7,
      title: '生年月日',
      value: birthday,
    },
    {
      id: 8,
      title: '郵便番号',
      value: applicantInformation?.postalCode,
    },
    {
      id: 9,
      title: '都道府県',
      value: idToName(applicantInformation?.locationGovernmentSubCategoryId),
    },
    {
      id: 10,
      title: '住所',
      value: applicantInformation?.address,
    },
    {
      id: 11,
      title: '電話番号',
      value: `${EmptyData.includes(applicantInformation?.houseTel) ? '' : `自宅：${applicantInformation?.houseTel}`} ${EmptyData.includes(applicantInformation?.mobileTel) ? '' : `携帯：${applicantInformation?.mobileTel}`}`,
    },
  ];
};

const horizontalPairLinkData = {
  left_link: {
    label: '物件一覧',
    link: '#',
  },
  right_link: {
    label: 'KSI官公庁オークショントップ',
    link: '/',
  },
};

// 委任者情報を追加する必要あり
const ReceptionCompleted = () => {
  const { applicantInformations, auction, auctionUser, property, proposal } =
    useContext(ReceptionCompletedContext);

  return (
    <div
      className="common-side-margin-wrap no-bg mx-auto box grow property-sheet theme-light mx-auto"
      style={{ maxWidth: '1200px', width: '90%' }}>
      <div className="mb-10">
        <GovernmentLabel label="受付完了" />
        <div className="stepper step-stepper no-bg tile stepper-is-booted theme-light">
          <Stepper steps={stepData.steps} active={4} />
          <StepTitle data={stepTitleData} />
          <ReceptionCompletionMessage
            email="test@example.com"
            auction={auction}
            property={property}
            proposal={proposal}
          />
          <ApplicationPropertyInformationTable
            data={propertyTableData(auction, property).tableData}
          />
          <ParticipantInformationTable
            data={participantInfoData(
              applicantInformations[0],
              auctionUser,
              proposal,
            )}
          />

          {proposal?.isRepresentative === 'TRUE' && (
            <ParticipantInformationTable
              data={representativeInfoData(applicantInformations[1])}
              isRepresentative={proposal?.isRepresentative}
            />
          )}

          <div className="flex justify-center mb-10">
            <Button text="物件詳細ページへ戻る" />
          </div>
        </div>
        <HorizontalPairLinks data={horizontalPairLinkData} />
      </div>
    </div>
  );
};

export default ReceptionCompleted;
