const SearchPropertyCount = ({ itemCount, currentPage, itemsPerPage }) => {
  if (itemCount === 0) {
    return;
  }

  const calcViewFirstItemNum = () => {
    return currentPage * itemsPerPage - itemsPerPage + 1;
  };

  const calcViewLastItemNum = () => {
    if (itemCount < currentPage * itemsPerPage) {
      return itemCount;
    }
    return currentPage * itemsPerPage;
  };

  return (
    <div data-testid="search-property-count">
      <div>{itemCount}件中 {calcViewFirstItemNum()} ~ {calcViewLastItemNum()}件</div>
    </div>
  );
};

export default SearchPropertyCount;
