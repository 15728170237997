import { findById as getInvitation } from 'domain/Auction/services/Invitation';
import { getSelectedCategories as getCategories } from 'domain/Category/services/PropertyCategory.js';
import { findByGovernmentId as getDivisions } from 'domain/Division/services/Division';
import { findById as getProperty } from 'domain/Property/services/Property';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { findByPropertyId as getPaymentMethods } from '../../../../domain/Property/services/PaymentMethod';
import { get as getProperties } from '../../../../domain/Property/services/Property.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import { NAVI } from '../../../../shared/utils/helper/constants.js';
import { PropertyInfoRealEstateContext } from '../entities/PropertyInfoRealEstateContext.js';

const PropertyInfoRealEstateProvider = ({ type, children }) => {
  //const { governmentId, governmentType, procedureType } = useAuth();
  const { localStorageGovernmentId } = useLocalStorage();
  const [properties, setProperties] = useState([]);
  const [governmentId, setGovernmentId] = useState('');
  const [invitation, setInvitation] = useState(undefined);
  const [property, setProperty] = useState(undefined);
  const [divisions, setDivisions] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});

  const { auction_id, property_id } = useParams();

  if (type === NAVI) {
    useEffect(() => {
      setGovernmentId(localStorageGovernmentId);
      getProperties(type).then((result) => {
        setProperties(result);
      });
      getInvitation(type, auction_id, localStorageGovernmentId).then(
        (result) => {
          setInvitation(result);
        },
      );
      if (property_id !== undefined) {
        getProperty(type, property_id).then((property) => {
          setProperty(property);
          getCategories(type, property.categoryId).then((categories) => {
            setSelectedCategory(categories);
          });
        });
        getPaymentMethods(type, property_id).then((paymentMethods) => {
          setPaymentMethods(paymentMethods);
        });
      }
      getDivisions(type, localStorageGovernmentId).then((divisions) => {
        let selectDivisions = [];
        divisions.forEach((division) => {
          let tmpDivision = {};
          tmpDivision.id = division.id;
          tmpDivision.text = division.name;
          tmpDivision.value = division.id;
          selectDivisions.push(tmpDivision);
        });
        setDivisions(selectDivisions);
      });
    }, [localStorageGovernmentId, property_id]);
  }
  return (
    <PropertyInfoRealEstateContext.Provider
      value={{
        governmentId,
        properties,
        invitation,
        property,
        divisions,
        paymentMethods,
        selectedCategory,
      }}>
      {children}
    </PropertyInfoRealEstateContext.Provider>
  );
};

export default PropertyInfoRealEstateProvider;
