// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-top {
  height: 60px;
  padding: 0 16px;
  border-bottom: 1px solid #e8e6e6;
}

@media screen and (min-width: 720px) {
  .sign-up-button {
    border: none;
    cursor: pointer;
    margin-right: 8px;
    padding: 0 16px;
  }
}

@media screen and (max-width: 719px) {
  .sign-up-button {
    font-size: 12px;
    padding: 0 8px;
    white-space: nowrap;
    margin-right: 8px;
  }
}

@media screen and (min-width: 720px) {
  .login-button {
    cursor: pointer;
    padding: 0 16px;
  }
}

@media screen and (max-width: 719px) {
  .login-button {
    font-size: 12px;
    padding: 0 8px;
    white-space: nowrap;
  }
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/header-top.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE;IACE,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;IACf,cAAc;IACd,mBAAmB;IACnB,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,eAAe;IACf,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;IACf,cAAc;IACd,mBAAmB;EACrB;AACF","sourcesContent":[".header-top {\n  height: 60px;\n  padding: 0 16px;\n  border-bottom: 1px solid #e8e6e6;\n}\n\n@media screen and (min-width: 720px) {\n  .sign-up-button {\n    border: none;\n    cursor: pointer;\n    margin-right: 8px;\n    padding: 0 16px;\n  }\n}\n\n@media screen and (max-width: 719px) {\n  .sign-up-button {\n    font-size: 12px;\n    padding: 0 8px;\n    white-space: nowrap;\n    margin-right: 8px;\n  }\n}\n\n@media screen and (min-width: 720px) {\n  .login-button {\n    cursor: pointer;\n    padding: 0 16px;\n  }\n}\n\n@media screen and (max-width: 719px) {\n  .login-button {\n    font-size: 12px;\n    padding: 0 8px;\n    white-space: nowrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
