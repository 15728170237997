import 'interfaces/css/common/tab.css';

const Tab = ({ tabIndex, label, isActive, setActiveTab, disabled }) => {
  let toggleClass = isActive ? 'active-tab' : 'inactive-tab';

  return (
    <div
      className={`tab ${toggleClass} flex align-center ${disabled && 'tab-disabled'}`}
      // onClick={() => disabled ? null : setActiveTab(tabIndex)}
      onClick={() => setActiveTab(tabIndex)}
      data-testid="tab">
      {label}
    </div>
  );
};

export default Tab;
