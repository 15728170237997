import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { findById as getAuction } from 'domain/Auction/services/Auction';
import { findById as getAuctionUser } from 'domain/User/services/ServiceUser';
import { findByPropertyIdAndUserId as getApplicantInformations } from 'domain/Applicant/services/ApplicantInformation';
import { findById as getBankTransferPage } from 'domain/Setting/services/BankTransferPages';
import { findById as getCreditCardPage } from 'domain/Setting/services/CreditCardPage';
import { findById as getGovernment } from 'domain/Government/services/Government';
import { findById as getProperty } from 'domain/Property/services/Property';
import { findByIdAndUserId as getProposal } from 'domain/Applicant/services/Proposal';
import { SERVICE, NAVI, KMANAGER } from 'shared/utils/helper/constants';
import { breadcrumbs } from '../entities/breadcrumbs';
import { ReceptionCompletedContext } from '../entities/ReceptionCompletedContext';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

const ReceptionCompletedProvider = ({ type, children }) => {
  const [applicantInformations, setApplicantInformations] = useState([]);
  const [auction, setAuction] = useState(undefined);
  const [auctionUser, setAuctionUser] = useState(undefined);
  const [bankTransferPages, setBankTransferPages] = useState(undefined);
  const [creditCardPages, setCreditCardPages] = useState(undefined);
  const [government, setGovernment] = useState(undefined);
  const [property, setProperty] = useState(undefined);
  const [proposal, setProposal] = useState(undefined);
  const { localStorageUserId } = useLocalStorage();
  const location = useLocation();

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getAuctionUser(type, localStorageUserId).then((user) => {
        getProposal(
          type,
          location?.state?.property_id,
          localStorageUserId,
        ).then((proposal) => {
          setAuctionUser(user);
          setProposal(proposal);
        });
      });

      getApplicantInformations(
        type,
        location?.state?.property_id,
        localStorageUserId,
      ).then((result) => {
        setApplicantInformations(result);
      });

      getProperty(type, location?.state?.property_id).then((property) => {
        getAuction(type, property?.auctionId).then((auction) => {
          setAuction(auction);
          setProperty(property);
        });

        getBankTransferPage(type, property?.auctionId).then(
          (bankTransferPage) => {
            setBankTransferPages(bankTransferPage);
          },
        );

        getCreditCardPage(type, property?.auctionId).then((creditCardPage) => {
          setCreditCardPages(creditCardPage);
        });

        getGovernment(type, property?.governmentId).then((government) => {
          setGovernment(government);
        });
      });
    }, [location, localStorageUserId]);
  }

  if (type === NAVI) {
    useEffect(() => {}, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <ReceptionCompletedContext.Provider
      value={{
        applicantInformations,
        auction,
        auctionUser,
        bankTransferPages,
        breadcrumbs,
        creditCardPages,
        government,
        property,
        proposal,
      }}>
      {children}
    </ReceptionCompletedContext.Provider>
  );
};

export default ReceptionCompletedProvider;
