import OrganizationProvider from './OrganizationProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { organization } from "../entities/componentMapping.js";

const Organization = ({ type }) => {
  return (
    <OrganizationProvider type={type}>
      {getComponent(organization, type)}
    </OrganizationProvider>
  );
};

export default Organization;
