// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sample-guideline .important-caution-title {
  padding-left: 12px;
  border-left: solid 5px var(--primary-base2);
  font-size: 17px;
  font-weight: 700;
  line-height: 1.294;
  color: #3f51b5;
}

.sample-guideline .important-caution-text {
  font-size: 15px;
  color: #3f51b5;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/SampleGuideline/important-caution.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,2CAA2C;EAC3C,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".sample-guideline .important-caution-title {\n  padding-left: 12px;\n  border-left: solid 5px var(--primary-base2);\n  font-size: 17px;\n  font-weight: 700;\n  line-height: 1.294;\n  color: #3f51b5;\n}\n\n.sample-guideline .important-caution-text {\n  font-size: 15px;\n  color: #3f51b5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
