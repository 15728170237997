import { validateSelected } from "../../../../application/validations.js";

export class DepositType {
  #depositType

  constructor(depositType) {
    this.#depositType = depositType;
  }

  get() {
    return this.#depositType
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#depositType)
  }
}
