import { convertEmptyCharacters } from 'shared/utils/helper/convertEmptyCharacters.js';

import EmailTemplate from './EmailTemplate.jsx';
import 'interfaces/css/AutomaticEmailManagement/automatic-email-table.css';

const AutomaticEmailTable = ({ governmentName, tableData, transmissionTimingStyle }) => {
  return (
    <div
      className="automatic-email-table-wrap"
      data-testid="automatic-email-table">
      <div className="automatic-email-table-inner">
        <table className="automatic-email-table table-hover">
          <thead data-testid="automatic-email-table-header">
            <tr
              className="automatic-email-table-headline automatic-email-table-header"
              style={{ border: 'none' }}>
              <th>送信内容</th>
              <th>送信タイミング</th>
              <th>登録状況</th>
              <th></th>
            </tr>
          </thead>
          <tbody data-testid="automatic-email-table-body">
            {tableData &&
              tableData.map((d) => (
                <tr
                  key={d.commonMailTemplateId}
                  data-testid="automatic-email-table-body-row"
                  className="automatic-email-table-row">
                  <td className="automatic-email-table-data">
                    {convertEmptyCharacters(d.linkName)}
                  </td>
                  <td className="automatic-email-table-data">{d.sendTiming}</td>
                  <td
                    className="automatic-email-table-data"
                    style={transmissionTimingStyle}>
                    {d.mailTemplateText == '' ? '未登録' : '登録済'}
                  </td>
                  <td className="automatic-email-table-data text-center">
                    <EmailTemplate
                      text="編集"
                      template={d}
                      governmentName={governmentName}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AutomaticEmailTable;
