
export class BidEndButtonDownAuctionDate {
  #bidEndButtonDownAuctionDate

  constructor(bidEndButtonDownAuctionDate) {
    this.#bidEndButtonDownAuctionDate = bidEndButtonDownAuctionDate;
  }

  get() {
    return this.#bidEndButtonDownAuctionDate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
