
export class ImportantReturnItems {
  #importantReturnItems

  constructor(importantReturnItems) {
    this.#importantReturnItems = importantReturnItems;
  }

  get() {
    return this.#importantReturnItems
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
