import { useEffect, useState } from 'react';
import { usePropertyCategories } from 'shared/hooks/usePropertyCategories.js';

import CautionDescription from './CautionDescription.jsx';
import Label from './Label.jsx';
import PropertyCategoriesSelectBox from '../../common/PropertyCategoriesSelectBox.jsx';
import ValidationMessage from './ValidationMessage';

const FormCategory = ({
  caution_description,
  data = { parent: undefined, child: undefined, grandchild: undefined },
  handler = () => {},
  invalidMessage,
  label,
  propertyType = 'REAL_ESTATE',
}) => {
  const {
    initPropertyParentCategories,
    initPropertyChildCategories,
    initPropertyGrandChildCategories,
    // eslint-disable-next-line no-unused-vars
    loading,
  } = usePropertyCategories(propertyType);

  // 親の情報
  const [selectedParentCategoryId, setSelectedParentPropertyCategoryId] =
    useState(0);

  const [
    selectedParentPropertyCategoryName,
    setSelectedParentPropertyCategoryName,
  ] = useState('');

  // 子の情報
  const [selectedChildPropertyCategories, setSelectedChildPropertyCategories] =
    useState([]);
  const [selectedChildCategoryId, setSelectedChildPropertyCategoryId] =
    useState(0);
  const [
    selectedChildPropertyCategoryName,
    setSelectedChildPropertyCategoryName,
  ] = useState('');

  // 孫の情報
  const [
    selectedGrandChildPropertyCategories,
    setSelectedGrandChildCategories,
  ] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [
    selectedGrandChildCategoryId,
    setSelectedGrandChildPropertyCategoryId,
  ] = useState(0);

  const [
    selectedGrandChildPropertyCategoryName,
    setSelectedGrandChildPropertyCategoryName,
  ] = useState('');

  useEffect(() => {
    setSelectedChildPropertyCategoryId(0);
    setSelectedChildPropertyCategoryName('');
    setSelectedChildPropertyCategories(
      initPropertyChildCategories.filter(
        (item) => item.parentId === selectedParentCategoryId,
      ),
    );
  }, [selectedParentCategoryId]);

  useEffect(() => {
    setSelectedGrandChildPropertyCategoryId(0);
    setSelectedGrandChildPropertyCategoryName('');
    setSelectedGrandChildCategories(
      initPropertyGrandChildCategories.filter(
        (item) => item.parentId === selectedChildCategoryId,
      ),
    );
  }, [selectedChildCategoryId]);

  useEffect(() => {
    if (data.parent !== undefined) {
      setSelectedParentPropertyCategoryId(data.parent.id);
      setSelectedParentPropertyCategoryName(data.parent.name);
      if (data.child !== undefined) {
        setSelectedChildPropertyCategoryId(data.child.id);
        setSelectedChildPropertyCategoryName(data.child.name);
        if (data.grandchild !== undefined) {
          setSelectedGrandChildPropertyCategoryId(data.grandchild.id);
          setSelectedGrandChildPropertyCategoryName(data.grandchild.name);
        }
      }
    }
  }, [data]);

  const handleSelectParent = (id) => {
    console.log(selectedGrandChildCategoryId);
    setSelectedParentPropertyCategoryId(id);
    handler('');
  };

  const handleSelectChild = (id) => {
    console.log(selectedGrandChildCategoryId);
    setSelectedChildPropertyCategoryId(id);
    handler('');
    setSelectedGrandChildPropertyCategoryId(0);
    setSelectedGrandChildPropertyCategoryName('');
  };

  const handleSelectGrandChild = (id) => {
    console.log(selectedGrandChildCategoryId);
    setSelectedGrandChildPropertyCategoryId(id);
    handler(id);
  };

  return (
    <div className="property-info-general-form-table-row">
      <Label label={label} required={true} />
      <div className="property-info-general-form-table-cell">
        <div className="flex">
          <PropertyCategoriesSelectBox
            isCategory={true}
            listItemData={initPropertyParentCategories}
            selectedName={selectedParentPropertyCategoryName}
            setSelectedId={handleSelectParent}
            setSelectedName={setSelectedParentPropertyCategoryName}
          />
          <div className="ml-1 flex-grow-1" />
          <PropertyCategoriesSelectBox
            isCategory={true}
            isDisplay={selectedParentPropertyCategoryName === '' ? false : true}
            listItemData={selectedChildPropertyCategories}
            selectedName={selectedChildPropertyCategoryName}
            setSelectedId={handleSelectChild}
            setSelectedName={setSelectedChildPropertyCategoryName}
          />
          <div className="ml-1 flex-grow-1" />
          <PropertyCategoriesSelectBox
            isCategory={true}
            isDisplay={selectedChildPropertyCategoryName === '' ? false : true}
            listItemData={selectedGrandChildPropertyCategories}
            selectedName={selectedGrandChildPropertyCategoryName}
            //setSelectedId={setSelectedGrandChildPropertyCategoryId}
            setSelectedId={handleSelectGrandChild}
            setSelectedName={setSelectedGrandChildPropertyCategoryName}
          />
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
        <CautionDescription data={caution_description} />
      </div>
    </div>
  );
};
export default FormCategory;
