import PublishedObjectStatusProvider from './PublishedObjectStatusProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { publishedObjectStatus } from "../entities/componentMapping.js";

const PublishedObjectStatus = ({ type }) => {
  return (
    <PublishedObjectStatusProvider type={type}>
      {getComponent(publishedObjectStatus, type)}
    </PublishedObjectStatusProvider>
  );
};

export default PublishedObjectStatus;
