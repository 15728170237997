// ロジックを含まない処理の実装

import { fetchNotices } from "../../../infrastructure/api/storage/notices.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { NoticeGet } from "../entities/News.js";

// getメソッドの実装
export async function getNotices(type) {
  // SAMPLE
  let response = [];
  if (type === SERVICE) {
    response = await fetchNotices()
  }
  if (type === NAVI) {
    response = await fetchNotices()
  }
  if (type === KMANAGER) {
    response = await fetchNotices()
  }

  if (response instanceof Error) {
    throw new CustomError('Error get News', response);
  }

  let results = [];
  response.map((data) => {
    const result = new NoticeGet(data);
    results.push(result)
  })

  return results
}
