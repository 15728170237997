import { validateSelected } from "../../../../application/validations.js";

export class StructureType {
  #structureType

  constructor(structureType) {
    this.#structureType = structureType;
  }

  get() {
    return this.#structureType
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#structureType)
  }
}
