import { SortableTh } from 'interfaces/components/common/Table/Th/SortableTh';

const KmanagerAuctionTableColumn = ({ headers, sortConfig, requestSort }) => {
  return (
    <thead data-testid="kmanager-auction-table-column">
      <tr>
        {headers.map((header) => (
          <SortableTh
            key={header.id}
            header={header}
            onClick={() => requestSort(header.column)}
            sortConfig={sortConfig}
          />
        ))}
      </tr>
    </thead>
  );
};

export default KmanagerAuctionTableColumn;
