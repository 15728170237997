
export class DeliveryReason {
  #deliveryReason

  constructor(deliveryReason) {
    this.#deliveryReason = deliveryReason;
  }

  get() {
    return this.#deliveryReason
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
