import { ReactComponent as TagIcon } from 'interfaces/assets/svg/tag_icon.svg';
import 'interfaces/css/common/index.css';

const PropertyCardImageTags = ({ tags, className }) => {
  return (
    <div className={className} data-testid="property-card-image-tags">
      {tags &&
        tags.map((tag, index) => (
          <div
            className="tag mr-1"
            key={index}
            data-testid={`property-card-image-tag-${index}`}>
            <TagIcon />
            {tag}
          </div>
        ))}
    </div>
  );
};

export default PropertyCardImageTags;
