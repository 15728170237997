import axios from 'axios';
import pino from "pino";

import {API_BASE_URL} from "../../config/envConstants.js";
import { BASE_URL_SERVICE } from "../../config/envConstants.js";
import { extractData } from "../../shared/utils/helper/extractData.js";
import { fetchData } from "../../shared/utils/helper/fetchData.js";

export const fetchPropertiesData = async () => {
  return await axios.get(
    `${API_BASE_URL}/api/v1/management/properties`,
  );
};
export const createPropertiesData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/properties`,
    body, {headers: headers}
  );

};
export const updatePropertiesData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/properties/${id}`,
    body, {headers: headers}
  );
};
export const deletePropertiesData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/management/properties/${id}`, {
      headers: headers,
    },);
};

const logger = pino();

export const fetchAllPropertiesStorage = async () => {
  try {
    const response = await fetchData(`${BASE_URL_SERVICE}/storage/current/properties/properties.json`);
    return extractData(response);
  } catch (error) {
    logger.warn({ error }, "現在の物件一覧（ properties ）を取得できませんでした");

    try {
      const response = await fetchData(`${BASE_URL_SERVICE}/storage/previous/navi/properties/properties.json`);
      return extractData(response);
    } catch (error) {
      logger.error({ error }, "以前の物件一覧（ properties ）の取得にも失敗しました");
      return [];
    }
  }
};
