import { validateSelected } from "../../../../application/validations.js";

export class Type {
  #type

  constructor(type) {
    this.#type = type;
  }

  get() {
    return this.#type
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#type)
  }
}
