
export class AuctionUserId {
  #auctionUserId

  constructor(auctionUserId) {
    this.#auctionUserId = auctionUserId;
  }

  get() {
    return this.#auctionUserId
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
