import { useState } from 'react';
//import { logInput } from "../../utils/log/logInputs.js";
import * as Validation from 'shared/utils/helper/naviValidators';

export const AfterWinningPublicSaleBidCautionForm = () => {
  // せり形式
  // 1. 権利移転手続き
  // 権利移転手続きについての説明
  const [auctionManagementTransValid, setAuctionManagementTransValid] =
    useState(null);

  // 必要な費用
  // 動産の説明
  const [auctionMovableDescriptionValid, setAuctionMovableDescriptionValid] =
    useState(null);

  // 自動車の説明
  const [auctionCarDescriptionValid, setAuctionCarDescriptionValid] =
    useState(null);

  // 不動産の説明
  const [auctionEstateDescriptionValid, setAuctionEstateDescriptionValid] =
    useState(null);

  // ご注意
  const [auctionAttentionValid, setAuctionAttentionValid] = useState(null);

  // 必要な書類
  // 必要な書類の案内ページ - 案内ページ名
  const [auctionDocumentNameValid, setAuctionDocumentNameValid] =
    useState(null);

  // 必要な書類の案内ページ - URL
  const [auctionUrlValid, setAuctionUrlValid] = useState(null);

  // 動産の説明
  const [
    auctionDocumentPropertyDescriptionValid,
    setAuctionDocumentPropertyDescriptionValid,
  ] = useState(null);

  // 自動車の説明
  const [
    auctionDocumentCarDescriptionValid,
    setAuctionDocumentCarDescriptionValid,
  ] = useState(null);

  // 不動産の説明
  const [
    auctionDocumentEstateDescriptionValid,
    setAuctionDocumentEstateDescriptionValid,
  ] = useState(null);

  // ご注意
  const [auctionDocumentAttentionValid, setAuctionDocumentAttentionValid] =
    useState(null);

  // 物件の権利移転について
  // 動産の説明
  const [
    auctionTransRightPropertyDescriptionValid,
    setAuctionTransRightPropertyDescriptionValid,
  ] = useState(null);

  // 自動車の説明
  const [
    auctionTransRightCarDescriptionValid,
    setAuctionTransRightCarDescriptionValid,
  ] = useState(null);

  // 不動産の説明
  const [
    auctionTransRightEstateDescriptionValid,
    setAuctionTransRightEstateDescriptionValid,
  ] = useState(null);

  // ご注意
  const [auctionTransRightAttentionValid, setAuctionTransRightAttentionValid] =
    useState(null);

  // 落札者（落札者が法人の場合は代表者）以外の方が権利移転手続きを行う場合
  // 説明
  const [
    auctionOtherTransRightDescriptionValid,
    setAuctionOtherTransRightDescriptionValid,
  ] = useState(null);

  // ご注意
  const [
    auctionOtherTransRightAttentionValid,
    setAuctionOtherTransRightAttentionValid,
  ] = useState(null);

  // 権利移転の時期
  // 説明
  const [
    auctionOtherTransRightDateDescriptionValid,
    setAuctionOtherTransRightDateDescriptionValid,
  ] = useState(null);

  // 2. 重要事項
  // 危険負担
  const [
    auctionImportantRiskBearingValid,
    setAuctionImportantRiskBearingValid,
  ] = useState(null);

  // 契約不適合責任
  const [
    auctionImportantNonConformingContractsLiabilityValid,
    setAuctionImportantNonConformingContractsLiabilityValid,
  ] = useState(null);

  // 引き渡し条件
  const [
    auctionImportantTermsDeliveryValid,
    setAuctionImportantTermsDeliveryValid,
  ] = useState(null);

  // 執行機関の引き渡し義務
  const [
    auctionImportantObligationDeliveryValid,
    setAuctionImportantObligationDeliveryValid,
  ] = useState(null);

  // 返品、交換
  const [
    auctionImportantReturnItemsValid,
    setAuctionImportantReturnItemsValid,
  ] = useState(null);

  // 保管費用
  const [
    auctionImportantStorageCostValid,
    setAuctionImportantStorageCostValid,
  ] = useState(null);

  // 落札者（最高価申込者）決定後、公売保証金が変換される場合
  const [
    auctionImportantReturnDepositValid,
    setAuctionImportantReturnDepositValid,
  ] = useState(null);

  // その他
  const [auctionImportantOtherValid, setAuctionImportantOtherValid] =
    useState(null);

  // ご注意
  const [auctionImportantAttentionValid, setAuctionImportantAttentionValid] =
    useState(null);

  // 3.お問い合わせ先
  // お問い合わせ先
  const [auctionInquiryNameValid, setAuctionInquiryNameValid] = useState(null);

  // メールアドレス
  const [auctionInquiryEmailValid, setAuctionInquiryEmailValid] =
    useState(null);

  // 電話番号
  const [auctionInquiryTelValid, setAuctionInquiryTelValid] = useState(null);
  // 電話番号1
  const [auctionInquiryTelNum1Valid, setAuctionInquiryTelNum1Valid] =
    useState(null);
  // 電話番号2
  const [auctionInquiryTelNum2Valid, setAuctionInquiryTelNum2Valid] =
    useState(null);
  // 電話番号3
  const [auctionInquiryTelNum3Valid, setAuctionInquiryTelNum3Valid] =
    useState(null);

  // 電話番号(内線)
  const [auctionInquiryExtensionValid, setAuctionInquiryExtensionValid] =
    useState(null);

  // 電話受付時間
  const [auctionInquiryAcceptValid, setAuctionInquiryAcceptValid] =
    useState(null);

  function handleAuctionTransRightDescriptionValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateManagementTransLength(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionManagementTransValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionManagementTransValid(invalid);
      return;
    }
    setAuctionManagementTransValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostPropertyDescriptionValid(e, required) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionMovableDescriptionValid(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateRangeLength(e.target.value, 0, 500);
      if (invalid !== 'TRUE') {
        setAuctionMovableDescriptionValid(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionMovableDescriptionValid(invalid);
        return;
      }
    }
    setAuctionMovableDescriptionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostCarDescriptionValid(e, required) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionCarDescriptionValid(invalid);
        return;
      }
    }
    invalid = Validation.validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionCarDescriptionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCarDescriptionValid(invalid);
      return;
    }
    setAuctionCarDescriptionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostEstateDescriptionValid(e, required) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionEstateDescriptionValid(invalid);
        return;
      }
    }
    invalid = Validation.validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionEstateDescriptionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionEstateDescriptionValid(invalid);
      return;
    }
    setAuctionEstateDescriptionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostAttentionValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionAttentionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionAttentionValid(invalid);
      return;
    }
    setAuctionAttentionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionDocumentPageNameValid(e, required) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;

    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionDocumentNameValid(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateRangeLength(e.target.value, 0, 30);
      if (invalid !== 'TRUE') {
        setAuctionDocumentNameValid(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionDocumentNameValid(invalid);
        return;
      }
    }
    setAuctionDocumentNameValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionDocumentPageUrlValid(e, required) {
    let invalid = null;

    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionUrlValid(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateUrlValidUrl(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionUrlValid(invalid);
        return;
      }
      invalid = Validation.validateUrlLength(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionUrlValid(invalid);
        return;
      }
    }
    setAuctionUrlValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionDocumentPropertyDescriptionValid(e, required) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionDocumentPropertyDescriptionValid(invalid);
        return;
      }
    }
    invalid = Validation.validateImportantRiskBearingLength(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionDocumentPropertyDescriptionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionDocumentPropertyDescriptionValid(invalid);
      return;
    }
    setAuctionDocumentPropertyDescriptionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionDocumentCarDescriptionValid(e, required) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionDocumentCarDescriptionValid(invalid);
        return;
      }
    }
    invalid = Validation.validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setAuctionDocumentCarDescriptionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionDocumentCarDescriptionValid(invalid);
      return;
    }
    setAuctionDocumentCarDescriptionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionDocumentEstateDescriptionValid(e, required) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionDocumentEstateDescriptionValid(invalid);
        return;
      }
    }
    invalid = Validation.validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setAuctionDocumentEstateDescriptionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionDocumentEstateDescriptionValid(invalid);
      return;
    }
    invalid = Validation.validateImportantReturnItemsHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionDocumentEstateDescriptionValid(invalid);
      return;
    }
    setAuctionDocumentEstateDescriptionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionDocumentAttentionValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionDocumentAttentionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionDocumentAttentionValid(invalid);
      return;
    }
    setAuctionDocumentAttentionValid(invalid); // エラーメッセージのリセット
  }

  // 物件の権利移転について
  function handleAuctionTransRightPropertyDescriptionValid(e, required) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionTransRightPropertyDescriptionValid(invalid);
        return;
      }
    }
    invalid = Validation.validateImportantRiskBearingLength(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionTransRightPropertyDescriptionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionTransRightPropertyDescriptionValid(invalid);
      return;
    }
    setAuctionTransRightPropertyDescriptionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionTransRightCarDescriptionValid(e, required) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionTransRightCarDescriptionValid(invalid);
        return;
      }
    }
    invalid = Validation.validateImportantTermsDeliveryLength(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionTransRightCarDescriptionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionTransRightCarDescriptionValid(invalid);
      return;
    }
    setAuctionTransRightCarDescriptionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionTransRightEstateDescriptionValid(e, required) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionTransRightEstateDescriptionValid(invalid);
        return;
      }
    }
    invalid = Validation.validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setAuctionTransRightEstateDescriptionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionTransRightEstateDescriptionValid(invalid);
      return;
    }
    setAuctionTransRightEstateDescriptionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionTransRightAttentionValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionTransRightAttentionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionTransRightAttentionValid(invalid);
      return;
    }
    setAuctionTransRightAttentionValid(invalid); // エラーメッセージのリセット
  }

  // 落札者（落札者が法人の場合は代表者）以外の方が権利移転手続きを行う場合
  // 説明
  function handleAuctionOtherTransRightDescription(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateDescriptionLength1(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionOtherTransRightDescriptionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionOtherTransRightDescriptionValid(invalid);
      return;
    }
    setAuctionOtherTransRightDescriptionValid(invalid); // エラーメッセージのリセット
  }

  // ご注意
  function handleAuctionOtherTransRightAttention(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionOtherTransRightAttentionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionOtherTransRightAttentionValid(invalid);
      return;
    }
    setAuctionOtherTransRightAttentionValid(invalid); // エラーメッセージのリセット
  }

  // 権利移転の時期
  // 説明
  function handleAuctionOtherTransRightDateDescription(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateDescriptionLength1(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionOtherTransRightDateDescriptionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionOtherTransRightDateDescriptionValid(invalid);
      return;
    }
    setAuctionOtherTransRightDateDescriptionValid(invalid); // エラーメッセージのリセット
  }

  // 2. 重要事項
  // 危険負担
  function handleAuctionImportantRiskBearing(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateImportantRiskBearingLength(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantRiskBearingValid(invalid);
      return;
    }
    invalid = Validation.validateImportantRiskBearingHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantRiskBearingValid(invalid);
      return;
    }
    setAuctionImportantRiskBearingValid(invalid); // エラーメッセージのリセット
  }

  // 契約不適合責任
  function handleAuctionImportantNonConformingContractsLiability(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateImportantNonConformingContractsLiabilityLength(
      e.target.value,
    );
    if (invalid !== 'TRUE') {
      setAuctionImportantNonConformingContractsLiabilityValid(invalid);
      return;
    }
    invalid =
      Validation.validateImportantNonConformingContractsLiabilityHasEmoji(
        e.target.value,
      );
    if (invalid !== 'TRUE') {
      setAuctionImportantNonConformingContractsLiabilityValid(invalid);
      return;
    }
    setAuctionImportantNonConformingContractsLiabilityValid(invalid); // エラーメッセージのリセット
  }

  // 引き渡し条件
  function handleAuctionImportantTermsDelivery(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateImportantTermsDeliveryLength(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantTermsDeliveryValid(invalid);
      return;
    }
    invalid = Validation.validateImportantTermsDeliveryHasEmoji1(
      e.target.value,
    );
    if (invalid !== 'TRUE') {
      setAuctionImportantTermsDeliveryValid(invalid);
      return;
    }
    setAuctionImportantTermsDeliveryValid(invalid); // エラーメッセージのリセット
  }

  // 執行機関の引き渡し義務
  function handleAuctionImportantObligationDelivery(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateImportantObligationDeliveryLength(
      e.target.value,
    );
    if (invalid !== 'TRUE') {
      setAuctionImportantObligationDeliveryValid(invalid);
      return;
    }
    invalid = Validation.validateImportantObligationDeliveryHasEmoji(
      e.target.value,
    );
    if (invalid !== 'TRUE') {
      setAuctionImportantObligationDeliveryValid(invalid);
      return;
    }
    setAuctionImportantObligationDeliveryValid(invalid); // エラーメッセージのリセット
  }

  // 返品、交換
  function handleAuctionImportantReturnItems(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateImportantReturnItemsLength(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantReturnItemsValid(invalid);
      return;
    }
    invalid = Validation.validateImportantReturnItemsHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantReturnItemsValid(invalid);
      return;
    }
    setAuctionImportantReturnItemsValid(invalid); // エラーメッセージのリセット
  }

  // 保管費用
  function handleAuctionImportantStorageCost(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateImportantStorageCostLength(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantStorageCostValid(invalid);
      return;
    }
    invalid = Validation.validateImportantStorageCostHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantStorageCostValid(invalid);
      return;
    }
    setAuctionImportantStorageCostValid(invalid); // エラーメッセージのリセット
  }

  // 落札者（最高価申込者）決定後、公売保証金が変換される場合
  function handleAuctionImportantReturnDeposit(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateImportantReturnDepositLength(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantReturnDepositValid(invalid);
      return;
    }
    invalid = Validation.validateImportantReturnDepositHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantReturnDepositValid(invalid);
      return;
    }
    setAuctionImportantReturnDepositValid(invalid); // エラーメッセージのリセット
  }

  // その他
  function handleAuctionImportantOther(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateImportantOtherLength1(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantOtherValid(invalid);
      return;
    }
    invalid = Validation.validateOtherHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantOtherValid(invalid);
      return;
    }
    setAuctionImportantOtherValid(invalid); // エラーメッセージのリセット
  }

  // ご注意
  function handleAuctionImportantAttention(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setAuctionImportantAttentionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantAttentionValid(invalid);
      return;
    }
    setAuctionImportantAttentionValid(invalid); // エラーメッセージのリセット
  }

  // 3. お問い合わせ先
  // お問い合わせ先
  function handleAuctionInquiryNameValid(e, required) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionInquiryNameValid(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateInquiryDisplayRangeLength2(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionInquiryNameValid(invalid);
        return;
      }
      invalid = Validation.validateInquiryDisplayHasEmoji2(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionInquiryNameValid(invalid);
        return;
      }
    }
    setAuctionInquiryNameValid(invalid); // エラーメッセージのリセット
  }

  // メールアドレス
  function handleAuctionInquiryEmailValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value !== '') {
      invalid = Validation.validateEmailFormat(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionInquiryEmailValid(invalid);
        return;
      }
    }
    setAuctionInquiryEmailValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionInquiryTelValid(e, telData) {
    // logInput(e.target.name, e.target.value);;
    let tel = Object.values(telData).join('');
    let invalid = null;
    invalid = Validation.validateTelSpaceMessage(tel);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelValid(invalid);
      return;
    }
    invalid = Validation.validateTelNotInclude(tel);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelValid(invalid);
      return;
    }
    if (tel.length > 0) {
      invalid = Validation.validateTelRangeLength(tel);
      if (invalid !== 'TRUE') {
        setAuctionInquiryTelValid(invalid);
        return;
      }
    }
    setAuctionInquiryTelValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionInquiryTelNum1Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum1Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum1Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum1Valid(invalid);
      return;
    }
    setAuctionInquiryTelNum1Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionInquiryTelNum2Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum2Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum2Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum2Valid(invalid);
      return;
    }
    setAuctionInquiryTelNum2Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionInquiryTelNum3Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum3Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum3Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum3Valid(invalid);
      return;
    }
    setAuctionInquiryTelNum3Valid(invalid); // エラーメッセージのリセット
  }

  // 電話番号(内線)
  function handleAuctionInquiryExtensionValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateExtensionLength(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionInquiryExtensionValid(invalid);
      return;
    }
    invalid = Validation.validateExtensionSpaceMessage(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionInquiryExtensionValid(invalid);
      return;
    }
    invalid = Validation.validateExtensionNotInclude(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionInquiryExtensionValid(invalid);
      return;
    }
    setAuctionInquiryExtensionValid(invalid); // エラーメッセージのリセット
  }

  // 電話受付時間
  function handleAuctionInquiryAcceptValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateReferenceAcceptLength(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionInquiryAcceptValid(invalid);
      return;
    }
    invalid = Validation.validateReferenceAcceptHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionInquiryAcceptValid(invalid);
      return;
    }
    setAuctionInquiryAcceptValid(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      handleAuctionTransRightDescriptionValid,
      handleAuctionCostPropertyDescriptionValid,
      handleAuctionCostCarDescriptionValid,
      handleAuctionCostEstateDescriptionValid,
      handleAuctionCostAttentionValid,
      handleAuctionDocumentPageNameValid,
      handleAuctionDocumentPageUrlValid,
      // 独自で追加
      handleAuctionDocumentPropertyDescriptionValid,
      handleAuctionDocumentCarDescriptionValid,
      handleAuctionDocumentEstateDescriptionValid,
      handleAuctionDocumentAttentionValid,
      handleAuctionTransRightPropertyDescriptionValid,
      handleAuctionTransRightCarDescriptionValid,
      handleAuctionTransRightEstateDescriptionValid,
      handleAuctionTransRightAttentionValid,
      handleAuctionOtherTransRightDescription,
      handleAuctionOtherTransRightAttention,
      handleAuctionOtherTransRightDateDescription,
      handleAuctionImportantRiskBearing,
      handleAuctionImportantNonConformingContractsLiability,
      handleAuctionImportantTermsDelivery,
      handleAuctionImportantObligationDelivery,
      handleAuctionImportantReturnItems,
      handleAuctionImportantStorageCost,
      handleAuctionImportantReturnDeposit,
      handleAuctionImportantOther,
      handleAuctionImportantAttention,
      handleAuctionInquiryNameValid,
      handleAuctionInquiryEmailValid,
      handleAuctionInquiryTelValid,
      handleAuctionInquiryTelNum1Valid,
      handleAuctionInquiryTelNum2Valid,
      handleAuctionInquiryTelNum3Valid,
      handleAuctionInquiryExtensionValid,
      handleAuctionInquiryAcceptValid,
    },

    // invalidの時のメッセージ
    validationMessage: {
      auctionManagementTransValid,
      auctionMovableDescriptionValid,
      auctionCarDescriptionValid,
      auctionEstateDescriptionValid,
      auctionAttentionValid,
      auctionDocumentNameValid,
      auctionUrlValid,
      auctionDocumentPropertyDescriptionValid,
      auctionDocumentCarDescriptionValid,
      auctionDocumentEstateDescriptionValid,
      auctionDocumentAttentionValid,
      auctionTransRightPropertyDescriptionValid,
      auctionTransRightCarDescriptionValid,
      auctionTransRightEstateDescriptionValid,
      auctionTransRightAttentionValid,
      auctionOtherTransRightDescriptionValid,
      auctionOtherTransRightAttentionValid,
      auctionOtherTransRightDateDescriptionValid,
      auctionImportantRiskBearingValid,
      auctionImportantNonConformingContractsLiabilityValid,
      auctionImportantTermsDeliveryValid,
      auctionImportantObligationDeliveryValid,
      auctionImportantReturnItemsValid,
      auctionImportantStorageCostValid,
      auctionImportantReturnDepositValid,
      auctionImportantOtherValid,
      auctionImportantAttentionValid,
      auctionInquiryNameValid,
      auctionInquiryEmailValid,
      auctionInquiryTelValid,
      auctionInquiryTelNum1Valid,
      auctionInquiryTelNum2Valid,
      auctionInquiryTelNum3Valid,
      auctionInquiryExtensionValid,
      auctionInquiryAcceptValid,
    },
  };

  return validations;
};
