// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-notice {
  display: flex;
  padding: 12px 0;
}

.simple-notice a {
  margin-left: 16px;
}

.simple-notice-date {
  margin-right: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/simple-notice.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".simple-notice {\n  display: flex;\n  padding: 12px 0;\n}\n\n.simple-notice a {\n  margin-left: 16px;\n}\n\n.simple-notice-date {\n  margin-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
