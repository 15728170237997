import { validateNumberRange } from "../../../../application/validations.js";

export class PrintOrder {
  #printOrder

  constructor(printOrder) {
    this.#printOrder = printOrder;
  }

  get() {
    return this.#printOrder
  }

  get isValid() {
    return [
      this.validateNumberRange,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateNumberRange() {
    return validateNumberRange(this.#printOrder)
  }
}
