import Heading3 from 'interfaces/components/common/Heading/Heading3';
import RightsTransferTimingAndRequiredPeriodAssetSale from './RightsTransferProcedureAssetSaleSection/RightsTransferTimingAndRequiredPeriodAssetSale.jsx';
import RightsTransferCautionAssetSale from './RightsTransferProcedureAssetSaleSection/RightsTransferCautionAssetSale.jsx';
import RequiredDocumentsAssetSale from './RightsTransferProcedureAssetSaleSection/RequiredDocumentsAssetSale.jsx';
import NecessaryExpensesAssetSale from './RightsTransferProcedureAssetSaleSection/NecessaryExpensesAssetSale.jsx';

const RightsTransferProcedureAssetSaleSection = () => {
  return (
    <div data-testid="rights-transfer-procedure-section" className="pt-6">
      <Heading3 text="権利移転手続き" isLeftBorderStyle={true}/>
      <p className="sample-after-winning-bid-lead-text pt-2" data-testid="sample-after-winning-bid-lead-text" >入札終了後に行政機関が落札者へメールにて、落札物件の区分番号、行政機関の連絡先などをお知らせします。メールを確認後、できるだけ早く行政機関へ連絡し、権利移転手続きについて説明を受けてください。</p>
      <NecessaryExpensesAssetSale/>
      <RequiredDocumentsAssetSale/>
      <RightsTransferCautionAssetSale/>
      <RightsTransferTimingAndRequiredPeriodAssetSale/>
    </div>
  );
};

export default RightsTransferProcedureAssetSaleSection;