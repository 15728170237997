// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading2-wrap {
  align-items: center;
  background-color: #f1f0e8;
  display: flex;
  height: initial;
  justify-content: space-between;
  margin-bottom: 8px;
}

.heading2-wrap  h2 {
  margin-block-start: initial;
  margin-block-end: initial;
  padding-left: 15px;
}

.heading2-wrap .heading2 {
  width: 100%;
  padding-left: 15px;
  position: relative;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.76;
}

.heading2-wrap .heading2::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background: #ff7f00;
}

.heading2-wrap .right {
  white-space: nowrap;
}

.heading2-wrap .right a {
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/heading2.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,OAAO;EACP,UAAU;EACV,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".heading2-wrap {\n  align-items: center;\n  background-color: #f1f0e8;\n  display: flex;\n  height: initial;\n  justify-content: space-between;\n  margin-bottom: 8px;\n}\n\n.heading2-wrap  h2 {\n  margin-block-start: initial;\n  margin-block-end: initial;\n  padding-left: 15px;\n}\n\n.heading2-wrap .heading2 {\n  width: 100%;\n  padding-left: 15px;\n  position: relative;\n  font-size: 18px;\n  font-weight: 700;\n  line-height: 1.76;\n}\n\n.heading2-wrap .heading2::before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  width: 5px;\n  height: 100%;\n  background: #ff7f00;\n}\n\n.heading2-wrap .right {\n  white-space: nowrap;\n}\n\n.heading2-wrap .right a {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
