
export class ImportantTermsDelivery {
  #importantTermsDelivery

  constructor(importantTermsDelivery) {
    this.#importantTermsDelivery = importantTermsDelivery;
  }

  get() {
    return this.#importantTermsDelivery
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
