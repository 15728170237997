
export class AccountHolder {
    #accountHolder

    constructor(accountHolder) {
        this.#accountHolder = accountHolder;
    }

    get() {
        return this.#accountHolder
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
