import GovernmentDeposit from "interfaces/components/Government/kmanager/Deposit/GovernmentDeposit";

const KmanagerGovernmentDeposit = () => {
    return (
        <div data-testid="kmanager-auction-government">
            <GovernmentDeposit/>
        </div>
    );
}

export default KmanagerGovernmentDeposit;