import pino from 'pino';

import { BASE_URL_NAVIGATION } from '../../../config/envConstants.js';
import { fetchData } from '../../../shared/utils/helper/fetchData.js';

const logger = pino();
const CURRENT_AUCTION_USERS = `${BASE_URL_NAVIGATION}/storage/current/auction_users/data.json`;
const PREVIOUS_AUCTION_USERS = `${BASE_URL_NAVIGATION}/storage/previous/auction_users/data.json`;

export const fetchAuctionUsersStorage = async () => {
  try {
    const response = await fetchCurrentAuctionUsers();
    return extractAuctionUsers(response);
  } catch (error) {
    logger.warn(
      { error },
      '現在の通知（ auction_users ）を取得できませんでした',
    );

    try {
      const response = await fetchPreviousAuctionUsers();
      return extractAuctionUsers(response);
    } catch (error) {
      logger.error(
        { error },
        '以前の通知（ auction_users ）の取得にも失敗しました',
      );
      return [];
    }
  }
};

export const fetchNaviAuctionUsersStorage = async () => {
  try {
    const response = await fetchAuctionUsers(
      `${BASE_URL_NAVIGATION}/storage/current/navi/auction_users/auction_users.json`,
    );
    return extractAuctionUsers(response);
  } catch (error) {
    logger.warn(
      { error },
      '現在の通知（ auction_users ）を取得できませんでした',
    );

    try {
      const response = await fetchAuctionUsers(
        `${BASE_URL_NAVIGATION}/storage/previous/navi/auction_users/auction_users.json`,
      );
      return extractAuctionUsers(response);
    } catch (error) {
      logger.error(
        { error },
        '以前の通知（ auction_users ）の取得にも失敗しました',
      );
      return [];
    }
  }
};

export const fetchCurrentAuctionUsers = async () => {
  return fetchAuctionUsers(CURRENT_AUCTION_USERS);
};

export const fetchPreviousAuctionUsers = async () => {
  return fetchAuctionUsers(PREVIOUS_AUCTION_USERS);
};

export const fetchAuctionUsers = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
};

export const extractAuctionUsers = (response) => {
  return response.map((auction_user) => auction_user);
};
