import { ReactComponent as ArrowRightIcon } from 'interfaces/assets/svg/arrow_back_icon.svg';
import BackButton from 'interfaces/components/common/BackButton';
import PageTitle from 'interfaces/components/common/PageTitle';
import GovernmentPaymentTable from 'interfaces/components/Government/kmanager/Payment/GovernmentPaymentTable';
import TaxRuleTable from 'interfaces/components/Government/kmanager/Payment/TaxRuleTable';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import GovernmentPaymentResultTable from './GovernmentPaymentResultTable';
import { GovernmentPaymentContext } from '../../../../pages/GovernmentPayment/entities/GovernmentPaymentContext';
import Toolbar from 'interfaces/components/common/Toolbar';
import PropertySwitcher from 'interfaces/components/common/Switcher/PropertySwitcher';
//import { useGovernmentTax } from "shared/hooks/useGovernmentTax";
//import { useProperty } from "shared/hooks/useProperty";
//import { usePaymentInfo } from "shared/hooks/usePaymentInfo";

const GovernmentPayment = () => {
  const navigation = useNavigate();
  const { auctionId } = useParams();
  const { auction, government } = useContext(GovernmentPaymentContext);

  //const [error, setError] = useState({})

  //const [isLoading, setIsLoading] = useState(true)
  //const [property, setProperty] = useState([])
  //const [notClaimableBtnStatus, setNotClaimableBtnStatus] = useState([])
  //const [systemFee, setSystemFee] = useState({
  //  systemUsageFee: 0,
  //  systemUsageTax: 0,
  //  systemUsageSum: 0,
  //});

  //function handleError(err) {
  //  setError(err)
  //  setTimeout(() => {
  //    setError({})
  //  }, 2000)
  //}

  //const taxes = useGovernmentTax(governmentId, handleError)
  //const properties = useProperty(auctionId, governmentId, handleError)
  //const paymentInfo = usePaymentInfo(auctionId, governmentId, handleError)

  const AUCTION_GOVERNMENT_MANAGEMENT_PATH = `/kmanager/auction/${auctionId}/gov`;

  //useEffect(() => {
  //  if (!taxes.loading && !properties.loading) {
  //    properties.property.map((property) => {
  //      if (property.bidEndPrice === '-9999') {
  //        property.bidEndPrice = '0'
  //      }
  //      if (property.bidPrice === '-9999') {
  //        property.bidPrice = '0'
  //      }
  //      if (property.bidSystemFee === '-9999') {
  //        property.bidSystemFee = '0'
  //      }
  //      if (property.bidSystemFeeTax === '-9999') {
  //        property.bidSystemFeeTax = '0'
  //      }
  //      if (property.deposit === '-9999') {
  //        property.deposit = '0'
  //      }
  //    })

  //    let sumUsage = properties.property.reduce(function(sum, item) {
  //      if (item.isNotClaimable !== 'TRUE') {
  //        return {
  //          systemUsageFee: sum.systemUsageFee + Number(item.bidSystemFee),
  //          systemUsageTax: sum.systemUsageTax + Number(item.bidSystemFeeTax),
  //          systemUsageSum: sum.systemUsageSum + Number(item.bidSystemFeeTax) + Number(item.bidSystemFee)
  //        };
  //      } else {
  //        return {
  //          systemUsageFee: sum.systemUsageFee,
  //          systemUsageTax: sum.systemUsageTax,
  //          systemUsageSum: sum.systemUsageSum
  //        };
  //      }
  //    }, { systemUsageFee: 0, systemUsageTax: 0, systemUsageSum: 0 });

  //    setSystemFee(sumUsage)
  //    setProperty(properties.property)
  //    setIsLoading(false)
  //  }
  //}, [taxes.loading, properties.loading, notClaimableBtnStatus]);

  //useEffect(() => {
  //  setNotClaimableBtnStatus(
  //    [...Array(property.length)].map((_, index) => {
  //      return property[index].isNotClaimable === 'TRUE';
  //    })
  //  )
  //}, [property]);

  return (
    <div className="kmanager-auction-operation" data-testid="kmanager-payment">
      <div className="kmanager-row">
        <div className="kmanager-col kmanager-col-6">
          <BackButton
            text={auction.name}
            svg={<ArrowRightIcon title="back-to-page" />}
            handleClick={() => navigation(AUCTION_GOVERNMENT_MANAGEMENT_PATH)}
          />
          <PageTitle text={government.name} />
          <Toolbar>
            <PropertySwitcher
              auctionId={auction.id}
              governmentId={government.id}
              activeIndex={2}
            />
          </Toolbar>
        </div>
        <div className="kmanager-col kmanager-col-6"></div>
        <div className="kmanager-col kmanager-col-6">
          <TaxRuleTable />
        </div>
        <div className="kmanager-col kmanager-col-12">
          <div className="box-shadow">
            <div style={{ overflowX: 'auto' }}>
              <GovernmentPaymentTable />
            </div>
            <GovernmentPaymentResultTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GovernmentPayment;
