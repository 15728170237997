import CommonInput from 'interfaces/components/common/Input/CommonInput';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel';
import handleValidationMessage from 'shared/utils/helper/handleValidationMessage';

const FullNameInput = ({
  familyName,
  firstName,
  handleFamilyNameChange,
  handleFirstNameChange,
  familyNameValidationMessage,
  firstNameValidationMessage,
}) => {
  return (
    <tr data-testid="full-name-input">
      <th data-testid="full-name-input-label">
        氏名
        <RequiredLabel />
        <br />
        <small>法人の場合は代表者名</small>
      </th>
      <td data-testid="full-name-input-body">
        <div className="w-expand flex">
          <p className="flex align-center mr-4">姓</p>
          <div>
            <CommonInput
              name="family-name"
              value={familyName}
              handleChange={handleFamilyNameChange}
            />
            <small className="validate-message">
              {handleValidationMessage(familyNameValidationMessage)}
            </small>
          </div>
          <div className="mx-3" />
          <p className="flex align-center mr-4">名</p>
          <div>
            <CommonInput
              name="first-name"
              value={firstName}
              handleChange={handleFirstNameChange}
            />
            <small className="validate-message">
              {handleValidationMessage(firstNameValidationMessage)}
            </small>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default FullNameInput;
