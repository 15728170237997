// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sample-guideline .guideline-modal-headline {
  padding: 5px 15px 5px 10px;
  border-left: 5px solid #ff7f00;
  background-color: var(--heading);
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: .0125em;
  color: #101010;
  line-height: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/SampleGuideline/guideline-modal-headline.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,8BAA8B;EAC9B,gCAAgC;EAChC,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;EACvB,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".sample-guideline .guideline-modal-headline {\n  padding: 5px 15px 5px 10px;\n  border-left: 5px solid #ff7f00;\n  background-color: var(--heading);\n  font-size: 1.25rem;\n  font-weight: 500;\n  letter-spacing: .0125em;\n  color: #101010;\n  line-height: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
