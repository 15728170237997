import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class Geographical {
  #geographical

  constructor(geographical) {
    this.#geographical = geographical;
  }

  get() {
    return this.#geographical
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#geographical, 0, 200)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#geographical)
  }
}
