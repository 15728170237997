import { validateSelected } from "../../../../application/validations.js";

export class GovernmentType {
  #governmentType

  constructor(governmentType) {
    this.#governmentType = governmentType;
  }

  get() {
    return this.#governmentType
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#governmentType)
  }
}
