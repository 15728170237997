import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class OtherTransRightDescription {
  #otherTransRightDescription

  constructor(otherTransRightDescription) {
    this.#otherTransRightDescription = otherTransRightDescription;
  }

  get() {
    return this.#otherTransRightDescription
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#otherTransRightDescription, 0, 500)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#otherTransRightDescription)
  }
}
