import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as StarIcon } from 'interfaces/assets/svg/star_icon.svg';
import { ReactComponent as StarFillIcon } from 'interfaces/assets/svg/star_icon_fill.svg';

import fetchDeleteMyList from 'shared/utils/helper/fetchDeleteMyList.js';
import fetchUpdateMyList from 'shared/utils/helper/fetchUpdateMyList.js';

const FavoriteButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: var(--white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

  &.primary-variant {
  }

  &.suspension-variant {
    border: solid 1px rgba(0, 0, 0, 0.26);
    box-shadow: none;
    cursor: default;
  }

  &.disabled-variant {
    border: solid 1px rgba(0, 0, 0, 0.26);
    box-shadow: none;
    cursor: default;
  }

  .kservice & {
    border: thin solid;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.26);

    svg {
      fill: #bdbdbd;
    }
  }
`;

const StyledStarFillIcon = styled(StarFillIcon)`
  width: 18px;
  height: 18px;

  &.primary-variant {
    fill: var(--primary-base);
  }

  &.suspension-variant {
    fill: rgba(0, 0, 0, 0.26);
  }

  &.disabled-variant {
    fill: rgba(0, 0, 0, 0.26);
  }
`;

const StyledStarIcon = styled(StarIcon)`
  width: 18px;
  height: 18px;

  &.primary-variant {
    fill: var(--primary-base);
  }

  &.suspension-variant {
    fill: rgba(0, 0, 0, 0.26);
  }

  &.disabled-variant {
    fill: rgba(0, 0, 0, 0.26);
  }
`;

const AddMyListButton = ({
  isSuspension,
  id,
  userId,
  initialValue = false,
  disabled,
}) => {
  const btnRef = useRef(null);
  const [isActive, setIsActive] = useState(initialValue);

  const variantClassName = useCallback(() => {
    if (isSuspension) {
      return 'suspension-variant';
    } else if (disabled) {
      return 'disabled-variant';
    } else {
      return 'primary-variant';
    }
  }, [isSuspension, disabled]);

  useEffect(() => {
    setIsActive(initialValue);
  }, [initialValue]);

  return (
    <div data-testid="add-my-list-button">
      <FavoriteButton
        type="button"
        className={`${variantClassName()}`}
        ref={btnRef}
        disabled={disabled}
        onClick={
          isActive
            ? () => {
                fetchDeleteMyList(id, userId, btnRef, setIsActive);
              }
            : () => {
                fetchUpdateMyList(id, userId, btnRef, setIsActive);
              }
        }
        data-testid="favorite-button">
        {isActive ? (
          <StyledStarFillIcon
            className={`${variantClassName()}`}
            data-testid="star-fill-icon"
          />
        ) : (
          <StyledStarIcon
            className={`${variantClassName()}`}
            data-testid="star-icon"
          />
        )}
      </FavoriteButton>
    </div>
  );
};

export default AddMyListButton;
