import { useEffect, useState } from 'react';
import { calc_dead_line } from 'shared/utils/helper/calc_dead_line';

import 'interfaces/css/common/hero-area-modal.css';
import { AuctionItem } from './AuctionItem';
import GuidelineLink from './GuidelineLink';
import { MylistBtnBranch } from './MylistBtnBranch';
import { AuctionBtnBranch } from '../ActionArea/AuctionBtnBranch';

import { FINAL_APPLY } from 'domain/Property/entities/constants/property';

import { useAuth } from 'shared/hooks/useAuth';

const HeroAreaModal = ({ data, isContainBlockList }) => {
  const [deadline, setDeadline] = useState('');
  const [isRemainingAreaDisable, setIsRemainingAreaDisable] = useState(false);
  const [isMyListBtn, setIsMyListBtn] = useState(false);
  const { userId } = useAuth();
  useEffect(() => {
    if (
      data.remainingLabel === '入札締切まであと' ||
      data.remainingLabel === '追加入札締切まであと' ||
      data.remainingLabel === '申込締切まであと' ||
      data.remainingLabel === '入札開始まであと'
    ) {
      setIsRemainingAreaDisable(true);
    }
    if (data.aplStatus === FINAL_APPLY) {
      setIsMyListBtn(true);
    } else {
      setIsMyListBtn(false);
    }
    if (data.deadline) {
      setDeadline(calc_dead_line(data.deadline));
      const intervalId = setInterval(() => {
        setDeadline(calc_dead_line(data.deadline));
      }, 60 * 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [data, userId]);

  return (
    <div className="items-hero-action-area-modal noshrink nogrow pa-4 v-sheet theme--light">
      <div className="items-action-area-prices is--pc is--text-right">
        <div>{data.name}</div>
        <AuctionItem data={data} />
      </div>
      {isRemainingAreaDisable && deadline && (
        <div className="items-action-area-remaining mb-6">
          <div className="v-subheader pa-0 label theme--light">
            {data.remainingLabel}
          </div>
          <div className={'hero-area-bid-deadline'}>{deadline}</div>
        </div>
      )}
      <AuctionBtnBranch data={data} isContainBlockList={isContainBlockList} />
      <MylistBtnBranch
        myListBtn={isMyListBtn}
        className={'hero-area-modal-mylist-button'}>
        <span>マイリストに登録</span>
      </MylistBtnBranch>
      <div className="flex">
        <GuidelineLink>
          <span>初めての方へ</span>
        </GuidelineLink>
      </div>
    </div>
  );
};
export default HeroAreaModal;
