// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notice-item {
  border-bottom: 1px solid rgba(0,0,0,.12);
  word-break: normal;
}

.notice-item-date {
  width: 200px;
}

.notice-item-body {
  width: 700px;
  margin-bottom: 10px;
  flex: 1 0 100%;
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Notice/notice-item.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,cAAc;EACd,eAAe;AACjB","sourcesContent":[".notice-item {\n  border-bottom: 1px solid rgba(0,0,0,.12);\n  word-break: normal;\n}\n\n.notice-item-date {\n  width: 200px;\n}\n\n.notice-item-body {\n  width: 700px;\n  margin-bottom: 10px;\n  flex: 1 0 100%;\n  font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
