import GovernmentSettings from "interfaces/components/Auction/kmanager/AuctionGovernmentSettings/GovernmentSettings";

const KmanagerGovernmentSettings = () => {
    return (
        <div data-testid="kmanager-government-setting">
            <GovernmentSettings/>
        </div>
    );
}

export default KmanagerGovernmentSettings;