// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal2-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: rgba(33, 33, 33, 0.64);
  overflow: auto;
}

.modal2-contents {
  max-height: 90vh;
  position: fixed;
  z-index: 105;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  overflow-y: scroll;
}

.modal2-inner-h2 {
  background-color: var(--secondary-base);
  padding: 15px;
  font-size: 1.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/modal2.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,wCAAwC;EACxC,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,QAAQ;EACR,SAAS;EACT,4CAA4C;EAC5C,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,uCAAuC;EACvC,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".modal2-overlay {\n  position: fixed;\n  z-index: 100;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  width: 100%;\n  height: 100%;\n  min-height: 100%;\n  background-color: rgba(33, 33, 33, 0.64);\n  overflow: auto;\n}\n\n.modal2-contents {\n  max-height: 90vh;\n  position: fixed;\n  z-index: 105;\n  top: 50%;\n  left: 50%;\n  transform: translateY(-50%) translateX(-50%);\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  background-color: var(--white);\n  border-radius: 5px;\n  overflow-y: scroll;\n}\n\n.modal2-inner-h2 {\n  background-color: var(--secondary-base);\n  padding: 15px;\n  font-size: 1.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
