import axios from 'axios';
// import { ReactComponent as DeleteIcon } from 'interfaces/assets/svg/delete.svg';
import { ReactComponent as ExpandMoreIcon } from 'interfaces/assets/svg/expand_more_icon.svg';
import {
  PREFECTURE,
  PROCEDURE_TYPE,
  USER_TYPE,
} from 'shared/utils/helper/constants.js';
import { ReactComponent as PencilIcon } from 'interfaces/assets/svg/pencil.svg';
import Button from 'interfaces/components/common/Button/Button.jsx';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { convertEmptyCharacters } from 'shared/utils/helper/convertEmptyCharacters.js';
import { convertManagerCharacters } from 'shared/utils/helper/convertManagerCharacters.js';
import useModalControl from 'shared/hooks/useModalControl.js';
// import { useDeleteSubmit } from 'shared/utils/hooks/useSubmit.jsx';
import { ReturnDateModal } from 'interfaces/components/Auction/kmanager/AuctionUsageFee/ReturnDateModal.jsx';
// import Confirm from '../Confirm.jsx';
import { EditModal } from '../EditModal.jsx';

import 'interfaces/css/common/kmanager-table-record.css';

const KmanagerTableCellEditOnly = (data) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  return (
    <td data-testid="kmanager-table-td">
      <EditModal
        isOpen={isEditOpen}
        closeModal={() => setIsEditOpen(false)}
        data={data}
      />
      <div className="d-flex justify-center">
        <Button
          className="kmanager-common-button small text-no-wrap edit"
          text={<PencilIcon />}
          clickHandler={() => setIsEditOpen(true)}
        />
      </div>
    </td>
  );
};
const KmanagerTableCellEdit = (data, handler) => {
  // const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  // const submitHandler = useDeleteSubmit(data, () => setIsDeleteOpen(false));
  return (
    <td data-testid="kmanager-table-td">
      {/* <Confirm
        type="delete"
        isOpen={isDeleteOpen}
        closeModal={() => setIsDeleteOpen(false)}
        Submit={submitHandler}
      /> */}
      <EditModal
        isOpen={isEditOpen}
        closeModal={() => setIsEditOpen(false)}
        data={data}
        handler={handler}
      />
      <div className="d-flex justify-center">
        <Button
          className="kmanager-common-button small text-no-wrap edit"
          text={<PencilIcon />}
          clickHandler={() => setIsEditOpen(true)}
        />
        {/* <Button
          className="kmanager-common-button small text-no-wrap warning"
          text={<DeleteIcon />}
          clickHandler={() => setIsDeleteOpen(true)}
        /> */}
      </div>
    </td>
  );
};

const KmanagerTableCellExpand = () => {
  return (
    <td
      className="kmanager-auction-table-control"
      data-testid="kmanager-auction-table-td">
      <ExpandMoreIcon title="expand-more" data-testid="kmanager-table-icon" />
    </td>
  );
};

const KmanagerTableCellCheck = (checkedList, data, checked) => {
  return (
    <td className="flex justify-center" data-testid="kmanager-table-td">
      <input
        type="checkbox"
        data-testid="kmanager-th-check"
        onChange={() => checkedList(data.id)}
        checked={checked.includes(data.id)}
      />
    </td>
  );
};

const KmanagerTableCellRegister = () => {
  return (
    <td data-testid="kmanager-table-td">
      <div className="d-flex justify-center">
        <Button
          className="kmanager-common-button small text-no-wrap primary"
          text="登録"
          isOutline={true}
        />
      </div>
    </td>
  );
};

const KmanagerTableCellReturnDate = (data) => {
  const { isOpen, handleOpen, handleClose } = useModalControl();
  const { auctionId } = useParams();
  const submitHandler = async (e) => {
    e.preventDefault();
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      return_date: date.toLocaleDateString('sv-SE'),
    };
    try {
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/management/auctions/${auctionId}/${data.id}/register-return-date`,
        body,
        { headers: headers },
      );
      handleClose();
    } catch (error) {
      return error;
    }
  };
  const handleChange = (selectDate) => {
    setDate(selectDate);
  };
  const [date, setDate] = useState('');
  return (
    <td data-testid="kmanager-table-td">
      <div className="d-flex justify-center">
        {data.returnDate === '' ? (
          <Button
            className="kmanager-common-button small text-no-wrap primary"
            text="返還日登録"
            isOutline={true}
            clickHandler={handleOpen}
          />
        ) : (
          data.returnDate
        )}
        <ReturnDateModal
          isOpen={isOpen}
          handleClose={handleClose}
          handleChange={handleChange}
          label={'返還日登録'}
          date={date}
          submitHandler={submitHandler}
        />
      </div>
    </td>
  );
};

const KmanagerTableCellData = (header, data) => {
  return (
    <td data-testid="kmanager-table-td">
      <div>
        {convertManagerCharacters(
          convertData(data, header.column),
          header.column,
        )}
      </div>
    </td>
  );
};

const convertData = (data, column) => {
  if (
    column === 'invoiceArea' ||
    column === 'certDocumentArea' ||
    column === 'prefectureId'
  ) {
    const prefectureName =
      PREFECTURE.find((prefecture) => prefecture.id.toString() === data[column])
        ?.name || '';
    return prefectureName;
  }
  if (column === 'procedureType') {
    const procedureTypeName = PROCEDURE_TYPE.find(
      (procedureType) => procedureType.value === data[column],
    ).label;
    return procedureTypeName;
  }
  if (column === 'userType') {
    const userTypeName = USER_TYPE.find(
      (userType) => userType.value === data[column],
    ).label;
    return userTypeName;
  }
  return convertEmptyCharacters(data[column]);
};

const KmanagerTableCell = ({ header, data, handler, checkedList, checked }) => {
  if (header.text === '編集') {
    return KmanagerTableCellEditOnly(data);
  }
  if (header.text === '編集/削除') {
    return KmanagerTableCellEdit(data, handler);
  } else if (header.text === '操作など') {
    return KmanagerTableCellExpand();
  } else if (header.text === 'check') {
    return KmanagerTableCellCheck(checkedList, data, checked);
  } else if (data[header.column] === '登録') {
    return KmanagerTableCellRegister();
  } else if (header.text === '余分な保証金の返還 返還日') {
    return KmanagerTableCellReturnDate(data);
  } else {
    return KmanagerTableCellData(header, data);
  }
};

const KmanagerTableRecord = ({
  data,
  headers,
  handler,
  checkedList,
  checked,
}) => {
  return (
    <tr className="kmanager-table-record" data-testid="kmanager-table-record">
      {headers.map((header) => {
        return (
          <KmanagerTableCell
            key={header.id}
            header={header}
            data={data}
            handler={handler}
            checkedList={checkedList}
            checked={checked}
          />
        );
      })}
    </tr>
  );
};

export default KmanagerTableRecord;
