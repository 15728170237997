import ParentCategories from 'interfaces/components/Category/kmanager/KmanagerParentCategories';
import 'interfaces/css/Category/category.css';

const Category = ({ data, handleAdd, setInputs }) => {
  return (
    <div className="category" data-testid="category">
      <ParentCategories
        categories={data}
        setInputs={setInputs}
        handleAdd={handleAdd}
      />
    </div>
  );
};

export default Category;
