// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createPropertiesData,
  updatePropertiesData,
  deletePropertiesData,
} from '../../../infrastructure/api/Properties';
import { compareData } from '../../../shared/utils/helper/compareData';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants';
import CustomError from '../../../shared/utils/helper/customError';
import { EstatePropertyCreate, EstatePropertyGet } from '../entities/Property';
import { getEstateProperty } from '../repositories/EstateProperty';
import { estateTypeToJp } from 'shared/utils/constants/property';

export async function get(type) {
  return await getEstateProperty(type);
}

export async function findById(type, id) {
  const Property = await getEstateProperty(type);
  return Property.find((data) => data.id === id);
}

export async function findByPropertyId(type, propertyId) {
  const Property = await getEstateProperty(type);
  return Property.find((data) => data.propertyId.toString() === propertyId);
}

export async function findByIds(type, ids) {
  const Property = await getEstateProperty(type);
  return Property.filter((data) => ids.includes(data.id));
}

export async function findBySearchParams(type, searchParams, properties) {
  const estateProperties = await getEstateProperty(type);
  let propertyIds = properties?.map((property) => property.id);

  let retEstate = estateProperties.filter((estateProperty) =>
    propertyIds.includes(estateProperty.propertyId),
  );

  let targetPropertyIds = retEstate.map(
    (estateProperty) => estateProperty.propertyId,
  );
  let returnProperties = properties.filter((property) =>
    targetPropertyIds.includes(property.id),
  );

  returnProperties.forEach((property) => {
    property.estateProperty = retEstate.find(
      (estateProperty) => estateProperty.propertyId === property.id,
    );
    property.summaryEstateType = estateTypeToJp[property.estateProperty.type];
  });

  return returnProperties;
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    const estateProperty = new EstatePropertyCreate(data);
    response = await createPropertiesData(estateProperty);
  }
  if (type === KMANAGER) {
    response = await createPropertiesData(data);
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Property', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    const estateProperty = new EstatePropertyCreate(data);
    response = await updatePropertiesData(estateProperty);
  }
  if (type === KMANAGER) {
    response = await updatePropertiesData(data, data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Property', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    response = await deletePropertiesData(data.id);
  }
  if (type === KMANAGER) {
    response = await deletePropertiesData(data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Property', response);
  }
  return response;
}

function createEstatePropertyArray(data) {
  let properties = [];
  data.forEach((property) => {
    properties.push(new EstatePropertyGet(property));
  });
  return properties;
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}

export function sortByPriceASC(data) {
  let properties = createEstatePropertyArray(data);
  return properties.sort((a, b) => a.estimatePrice - b.estimatePrice);
}

export function sortByPriceDESC(data) {
  let properties = createEstatePropertyArray(data);
  return properties.sort((a, b) => b.estimatePrice - a.estimatePrice);
}

export function sortByDepositASC(data) {
  let properties = createEstatePropertyArray(data);
  return properties.sort((a, b) => a.deposit - b.deposit);
}

export function sortByDepositDESC(data) {
  let properties = createEstatePropertyArray(data);
  return properties.sort((a, b) => b.deposit - a.deposit);
}

export function sortByBiddersASC(data) {
  let properties = createEstatePropertyArray(data);
  return properties.sort((a, b) => a.bidders.length - b.bidders.length);
}

export function sortByBiddersDESC(data) {
  let properties = createEstatePropertyArray(data);
  return properties.sort((a, b) => b.bidders.length - a.bidders.length);
}

export function sortByIdASC(data) {
  let properties = createEstatePropertyArray(data);
  return properties.sort((a, b) => a.id - b.id);
}

export function sortByIdDESC(data) {
  let properties = createEstatePropertyArray(data);
  return properties.sort((a, b) => b.id - a.id);
}

export function sortBySaleNumberASC(data) {
  let properties = createEstatePropertyArray(data);
  return properties.sort((a, b) =>
    a.commonData.saleNumber.localeCompare(b.commonData.saleNumber),
  );
}

export function sortBySaleNumberDESC(data) {
  let properties = createEstatePropertyArray(data);
  return properties.sort((a, b) =>
    b.commonData.saleNumber.localeCompare(a.commonData.saleNumber),
  );
}

export function sortByNameASC(data) {
  let properties = createEstatePropertyArray(data);
  return properties.sort((a, b) =>
    a.commonData.name.localeCompare(b.commonData.name),
  );
}

export function sortByNameDESC(data) {
  let properties = createEstatePropertyArray(data);
  return properties.sort((a, b) =>
    b.commonData.name.localeCompare(a.commonData.name),
  );
}

export function sortByUpdatedAtASC(data) {
  let properties = createEstatePropertyArray(data);
  return properties.sort((a, b) =>
    a.commonData.updatedAt.localeCompare(b.commonData.updatedAt),
  );
}

export function sortByUpdatedAtDESC(data) {
  let properties = createEstatePropertyArray(data);
  return properties.sort((a, b) =>
    b.commonData.updatedAt.localeCompare(a.commonData.updatedAt),
  );
}

export function filterByName(data, name) {
  let properties = createEstatePropertyArray(data);
  return properties.filter((property) => property.commonData.name === name);
}

export function filterBySaleNumber(data, saleNumber) {
  let properties = createEstatePropertyArray(data);
  return properties.filter(
    (property) => property.commonData.saleNumber === saleNumber,
  );
}

export function filterByDivisionId(data, divisionId) {
  let properties = createEstatePropertyArray(data);
  return properties.filter((property) => property.divisionId === divisionId);
}
