import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class Address {
  #address

  constructor(address) {
    this.#address = address;
  }

  get() {
    return this.#address
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#address, 1, 50)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#address)
  }
}
