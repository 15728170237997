import Heading3 from 'interfaces/components/common/Heading/Heading3';
import PropertyInfoGeneralFormInputMarkDown from 'interfaces/components/common/PropertyInfoForm/PropertyInfoGeneralFormInputMarkDown';
import { local_government_management_radio1 } from 'interfaces/components/common/RadioLabel';
import {
  guideline_body,
  guideline_title,
} from 'shared/utils/helper/DummyEditFormData';

import FormRadioWithCaution from '../../Form/navi/FormRadioWithCaution';
import FormTextWithCaution from '../../Form/navi/FormTextWithCaution';

const Form = ({ itemNo, data, handler1 = () => {}, invalidMessage }) => {
  return (
    <div className="property-info-general-form-table">
      <Heading3 text={'設定' + itemNo} />
      <FormRadioWithCaution
        label={'設定' + itemNo + 'の表示'}
        radio_label={local_government_management_radio1}
        data={data['isDisplay' + itemNo]}
        handler1={(e) => {
          handler1['handleChangeIsDisplay' + itemNo](e),
            handler1['handleChangeTitle' + itemNo](
              { target: { value: data['title' + itemNo] } },
              e.target.value == 'DISPLAY',
            );
          handler1['handleChangeBody' + itemNo](
            { text: data['body' + itemNo] },
            e.target.value == 'DISPLAY',
          );
        }}
      />
      <FormTextWithCaution
        label={'タイトル'}
        required={data['isDisplay' + itemNo] == 'DISPLAY'}
        caution_description={guideline_title}
        data={data['title' + itemNo]}
        maxCharacters={50}
        handler1={(e) =>
          handler1['handleChangeTitle' + itemNo](
            e,
            data['isDisplay' + itemNo] == 'DISPLAY',
          )
        }
        invalidMessage={invalidMessage['guidelineTitleRequiredValid' + itemNo]}
      />
      <PropertyInfoGeneralFormInputMarkDown
        label={'本文'}
        required={data['isDisplay' + itemNo] == 'DISPLAY'}
        caution_description={guideline_body}
        data={data['body' + itemNo]}
        maxCharacters={12000}
        handler1={(text) =>
          handler1['handleChangeBody' + itemNo](
            text,
            data['isDisplay' + itemNo] == 'DISPLAY',
          )
        }
        invalidMessage={invalidMessage['guidelineBodyValid' + itemNo]}
      />
    </div>
  );
};

export default Form;
