import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_PROPERTY_IMAGES = `${BASE_URL_NAVIGATION}/image_storage/current/property/property_images.json`;
const PREVIOUS_PROPERTY_IMAGES = `${BASE_URL_NAVIGATION}/image_storage/previous/property/property_images.json`;

export const fetchPropertyImagesStorage = async () => {
  try {
    const response = await fetchCurrentPropertyImages();
    return extractPropertyImages(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ property_images ）を取得できませんでした");

    try {
      const response = await fetchPreviousPropertyImages();
      return extractPropertyImages(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ property_images ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchCurrentPropertyImages = async () => {
  return fetchPropertyImages(CURRENT_PROPERTY_IMAGES);
};

export const fetchPreviousPropertyImages = async () => {
  return fetchPropertyImages(PREVIOUS_PROPERTY_IMAGES);
};

export const fetchPropertyImages = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractPropertyImages = (response) => {
  return response.map(propertyImages => propertyImages.data);
}