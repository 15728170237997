
export class IsImportant {
  #isImportant

  constructor(isImportant) {
    this.#isImportant = isImportant;
  }

  get() {
    return this.#isImportant
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
