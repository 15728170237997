import { getPropertyCategories } from 'infrastructure/api/storage/property_categories';
import { useState, useEffect, useCallback } from 'react';
import { useError } from 'shared/hooks/useError';
import camelizeKeys from 'shared/utils/helper/camelize';

export const usePropertyCategories = (propertyType) => {
  const { setError } = useError();
  const [propertyCategories, setPropertyCategories] = useState([]);

  const [initPropertyParentCategories, setInitPropertyParentCategories] =
    useState([]);

  const [initPropertyChildCategories, setInitPropertyChildCategories] =
    useState([]);

  const [
    initPropertyGrandChildCategories,
    setInitPropertyGrandChildCategories,
  ] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPropertyCategories = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getPropertyCategories();
      setPropertyCategories(camelizeKeys(data));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await fetchPropertyCategories();
    };
    fetch().then();
  }, [fetchPropertyCategories]);

  // 物件の種類により物件のカテゴリーを取得する処理
  useEffect(() => {
    let categories = [];

    if (propertyType === 'CAR') {
      categories = propertyCategories.filter(
        (item) =>
          item.displayCar === 'DISPLAY' && item.displayService === 'DISPLAY',
      );
    } else if (propertyType === 'ESTATE') {
      categories = propertyCategories.filter(
        (item) =>
          item.displayEstate === 'DISPLAY' && item.displayService === 'DISPLAY',
      );
    } else {
      categories = propertyCategories.filter(
        (item) =>
          item.displayProperty === 'DISPLAY' &&
          item.displayService === 'DISPLAY',
      );
    }

    setInitPropertyParentCategories(
      categories.filter((item) => item.depth === '1'),
    );
    setInitPropertyChildCategories(
      categories.filter((item) => item.depth === '2'),
    );
    setInitPropertyGrandChildCategories(
      categories.filter((item) => item.depth === '3'),
    );
  }, [propertyCategories]);

  return {
    initPropertyParentCategories,
    initPropertyChildCategories,
    initPropertyGrandChildCategories,
    loading,
  };
};
