
export class DisplayService {
  #displayService

  constructor(displayService) {
    this.#displayService = displayService;
  }

  get() {
    return this.#displayService
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
