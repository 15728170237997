// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sample-guideline .guideline-modal {
  overflow-y: scroll;
  pointer-events: auto;
  width: 840px;
  max-height: 90%;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  margin: 24px;
  background-color: var(--white);
}

.sample-guideline .guideline-modal-content {
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sample-guideline .guideline-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(33, 33, 33);
  opacity: 0.64;
}

.sample-guideline .guideline-modal-text {
  font-size: 14px;
  color: #101010;
  line-height: 1.375rem;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/SampleGuideline/guideline-modal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,kBAAkB;EAClB,4GAA4G;EAC5G,YAAY;EACZ,8BAA8B;AAChC;;AAEA;EACE,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,iCAAiC;EACjC,aAAa;AACf;;AAEA;EACE,eAAe;EACf,cAAc;EACd,qBAAqB;AACvB","sourcesContent":[".sample-guideline .guideline-modal {\n  overflow-y: scroll;\n  pointer-events: auto;\n  width: 840px;\n  max-height: 90%;\n  padding: 20px;\n  border-radius: 4px;\n  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);\n  margin: 24px;\n  background-color: var(--white);\n}\n\n.sample-guideline .guideline-modal-content {\n  pointer-events: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.sample-guideline .guideline-modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgb(33, 33, 33);\n  opacity: 0.64;\n}\n\n.sample-guideline .guideline-modal-text {\n  font-size: 14px;\n  color: #101010;\n  line-height: 1.375rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
