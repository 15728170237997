export const checkbox_label = [
  { id: 1, text: "サービス画面", name: 'is_display_service' },
  { id: 2, text: "官公庁ナビ", name: 'is_display_navi' },
];

export const way_deposits = [
  { id: 1, text: "オン納付", name: 'paymentCreditCard' },
  { id: 2, text: "オフ納付", name: 'paymentBankTransfer' },
  { id: 3, text: "保証金なし", name: 'noneDeposit' },
];

export const category_display = [
  { id: 1, text: "サイトへの表示", name: 'displayService' },
  { id: 2, text: "動産", name: 'displayProperty' },
  { id: 3, text: "不動産", name: 'displayEstate' },
  { id: 4, text: "自動車", name: 'displayCar' }
];