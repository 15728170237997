import React from 'react';
import styled from 'styled-components';

const Note = styled.div`
  font-size: 0.75rem;
  letter-spacing: 0.0333333333em;
  line-height: 1.25rem;
  color: rgba(0, 0, 0, 0.38);
  padding: 0.5rem 0;
`;

export const CautionMessage = ({ cautionMessage }) => {
  return (
    <>
      {cautionMessage && (
        <Note>
          {cautionMessage.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </Note>
      )}
    </>
  );
};
