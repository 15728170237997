
export class AssetSaleBidData {
    #assetSaleBidData

    constructor(assetSaleBidData) {
        this.#assetSaleBidData = assetSaleBidData;
    }

    get() {
        return this.#assetSaleBidData
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
