
export class ProcedureTypeEn {
    #procedureTypeEns

    constructor(procedureTypeEns) {
        this.#procedureTypeEns = procedureTypeEns;
    }

    get() {
        return this.#procedureTypeEns
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
