import { fetchNaviAuctionUsersStorage as fetch } from "infrastructure/api/storage/auction_users";
import { KMANAGER, NAVI, SERVICE } from "shared/utils/helper/constants.js";
import CustomError from "shared/utils/helper/customError.js";

import { AuctionUserGet } from "../entities/Applicant.js";

// getメソッドの実装
export async function getNaviAuctionUsers(type) {
    // SAMPLE
    let response = [];
    if (type === SERVICE) {
        throw new CustomError('Unexpected Request (Proposal)');
    }
    if (type === NAVI) {
        response = await fetch();
    }
    if (type === KMANAGER) {
        throw new CustomError('Unexpected Request (Proposal)');
    }

    if (response instanceof Error) {
        throw new CustomError('Error get Proposal', response);
    }

    let results = [];
    response.map((data) => {
        const result = new AuctionUserGet(data);
        results.push(result)
    })

    return results
}
