
export class SubCategoryName {
    #subCategoryName

    constructor(subCategoryName) {
        this.#subCategoryName = subCategoryName;
    }

    get() {
        return this.#subCategoryName
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
