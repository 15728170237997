import Modal2 from 'interfaces/components/common/Modal/Modal2';

import Button from './Button.jsx';
import ButtonList from './ButtonList.jsx';

const Confirm = ({ type, isOpen, closeModal, Submit }) => {
  const typeMessage = () => {
    switch (type) {
      case 'delete':
        return '削除';
      case 'submit':
        return '送信';
    }
  };

  const CONFIRM_BUTTONS_DATA = [
    {
      text: 'いいえ',
      clickHandler: closeModal,
      className: 'warning',
    },
    {
      text: 'はい',
      clickHandler: Submit,
      className: 'primary',
    },
  ];

  return (
    <Modal2 isOpen={isOpen} handleClose={closeModal} modalSize={'S'}>
      <div className="kmanager-container" data-testid="kmanager-confirm">
        <h2>確認メッセージ</h2>
        <p>{`${typeMessage()}します。よろしいですか?`}</p>
        <ButtonList>
          {CONFIRM_BUTTONS_DATA.map((item, index) => (
            <Button
              key={index}
              text={item.text}
              clickHandler={item.clickHandler}
              isOutline={item.isOutline}
              className={item.className}
            />
          ))}
        </ButtonList>
      </div>
    </Modal2>
  );
};

export default Confirm;
