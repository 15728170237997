import Button from 'interfaces/components/common/Button.jsx';
import Modal2 from 'interfaces/components/common/Modal/Modal2';
import InputDate from 'interfaces/components/common/Form/InputDate.jsx';

// import 'interfaces/css/common/kmanager-common.css';
import 'interfaces/css/common/form.css';

export const ReturnDateModal = ({
  isOpen,
  handleClose,
  label,
  submitHandler,
  date,
  handleChange,
}) => {
  return (
    <div className={'return-date-modal'} data-testid="return-date-modal">
      <Modal2
        isOpen={isOpen}
        handleClose={handleClose}
        modalSize={'S'}
        padding={false}
        closeClickOutside={true}>
        <div className="memo-modal-header-label">{label}</div>
        <form className="kmanager-form">
          <InputDate state={date} setState={handleChange} />
          <div style={{ textAlign: 'right' }}>
            <Button
              text="登録する"
              className="ml-2"
              type={'submit'}
              clickHandler={submitHandler}
            />
          </div>
        </form>
      </Modal2>
    </div>
  );
};
