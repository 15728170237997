
export class CarCondition {
    #carCondition

    constructor(carCondition) {
        this.#carCondition = carCondition;
    }

    get() {
        return this.#carCondition
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
