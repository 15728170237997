import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle.jsx';
import 'interfaces/css/PropertyDetail/bid-history.css';

const BidHistory = ({ data }) => {
  return (
    <div>
      <ArticleDefaultTitle title="入札履歴" />
      <ArticleDefaultBody>
        {!data.bidHistory || data.bidHistory.length === 0 ? (
          <div className="text-center">入札はまだありません</div>
        ) : (
          <table className="bid-history-table">
            <tbody>
              <tr>
                <th>最終手動入札時間</th>
                <th>入札者</th>
                <th>入札価額</th>
              </tr>
              {
                //data.bidHistory.map((item, index) => (
                data.bidHistory.map((item) => (
                  <tr key={item.id}>
                    <td>{item.time}</td>
                    <td>
                      {item.bidStatus === 'CANCEL' ? (
                        <span className="warning-text">取り消されました。</span>
                      ) : (
                        item.membershipId
                      )}
                      {item.bidStatus === 'TOP_PRICE' ||
                      item.bidStatus === 'LOT_TOP_PRICE' ||
                      item.bidStatus === 'ADD_LOT_TOP_PRICE' ? (
                        <span className="warning-text">最高額入札者</span>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="text-no-wrap text-right">
                      {item.price}
                      <small>円</small>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        )}
      </ArticleDefaultBody>
    </div>
  );
};

export default BidHistory;
