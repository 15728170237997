import ResetEmailForm from './ResetEmailForm.jsx';
import './css/reset-email-modal-contents.css';

const ResetEmailModalContents = ({ handleClose, setModalType, setTargetEmail }) => {
  return (
    <div className="reset-email-modal-contents" data-testid="reset-email-modal-contents">
      <p className="reset-email-modal-contents-title" data-testid="reset-email-modal-contents-title">
        メールアドレス変更
      </p>
      <ResetEmailForm handleClose={handleClose} setModalType={setModalType} setTargetEmail={setTargetEmail}/>
    </div>
  );
};

export default ResetEmailModalContents;
