import { validateRangeLength } from "../../../../application/validations.js";

export class ImportantOther {
  #importantOther

  constructor(importantOther) {
    this.#importantOther = importantOther;
  }

  get() {
    return this.#importantOther
  }

  get isValid() {
    return [
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#importantOther, 0, 1000)
  }
}
