import { ValidationMessage } from 'interfaces/components/common/ValidationMessage.jsx';
import React from 'react';

import FormLabel from './FormLabel.jsx';
import { CautionMessage } from '../CautionMessage';

import styled from 'styled-components';

const TextInput = styled.input`
  background-color: rgb(255, 255, 255);
  border-bottom: ${(props) =>
    props.$validation
      ? '2px solid #ff5252!important'
      : '1px solid rgba(0, 0, 0, .42)!important'};
  caret-color: ${(props) => (props.$validation ? '#ff5252' : '#ff7f00')};
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 16px;
  line-height: 20px;
  max-width: 100%;
  min-width: 0;
  outline: none;
  width: 100%;
  padding-bottom: 10px;
`;

const InputText = React.memo(function InputText({
  invalidMessage,
  required,
  label,
  state,
  setState,
  cautionMessage,
  maxCharacters,
}) {
  const calcRemainCharacters = () => {
    if (maxCharacters === undefined) {
      return '';
    }
    if (state === undefined) {
      return maxCharacters;
    } else {
      return maxCharacters - state.length;
    }
  };

  return (
    <div className="kmanager-row">
      <div className="kmanager-col-4">
        <FormLabel label={label} required={required} />
      </div>
      <div className="kmanager-col flex-column">
        <TextInput
          type="text"
          placeholder={label}
          data-testid="input-text"
          value={state}
          onChange={(e) => setState(e)}
          $validation={invalidMessage !== 'TRUE' && invalidMessage !== null}
        />
        <div className="flex justify-space-between">
          <ValidationMessage invalidMessage={invalidMessage} />
          {maxCharacters ? (
            <div className="property-form-input-text-absolute-area">
              <div
                className="number-remaining-characters"
                style={{ color: calcRemainCharacters() < 0 ? '#ff5252' : '' }}>
                {`${state.length}/${maxCharacters}`}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <CautionMessage cautionMessage={cautionMessage} />
      </div>
    </div>
  );
});
export default InputText;
