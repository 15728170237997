import { ReactComponent as PencilIcon } from 'interfaces/assets/svg/pencil.svg';
import { ReactComponent as SaveIcon } from 'interfaces/assets/svg/save.svg';
import { validateInputsCategory } from 'interfaces/components/common/validateInputs/validateInputsCategory';
import { useEffect, useState } from 'react';
import { category_display } from 'shared/utils/helper/checkbox_labels';
import { initInput } from 'shared/utils/helper/input_helper';
import { ReactComponent as AlertIcon } from 'interfaces/assets/svg/alert_yellow.svg';
import { Tooltip } from 'react-tooltip';

import InputCategoryDisplay from './InputCategoryDisplay';
import InputCategoryName from './InputCategoryName';

const depth = 3;

const GrandChildCategory = ({ category, setInputs, parentId }) => {
  const [onEdit, setOnEdit] = useState(false);
  const inputs = validateInputsCategory();

  const startEdit = () => {
    setOnEdit(true);
  };

  const exitEdit = () => {
    setInputs((prevState) => ({ ...prevState, [category.id]: inputs }));
    setOnEdit(false);
  };

  const handleChange = (e, column) => {
    const target = inputs.find((input) => input.column === column);
    const nextValue = target.setters(e);
    const newInputs = [...inputs, { ...target, state: nextValue }];
    setInputs((prevState) => ({ ...prevState, [category.id]: newInputs }));
  };

  useEffect(() => {
    initInput(inputs, category);
    inputs.find((input) => input.column === 'depth').init(depth);
    inputs.find((input) => input.column === 'parentId').init(parentId);
  }, []);

  return (
    <div className="flex align-center my-5">
      <div
        className="kmanager-grand-child-category flex align-center"
        data-testid="grand-child-category">
        {onEdit ? (
          <InputCategoryName
            invalidMessage={
              inputs.find((input) => input.column === 'name').validationMessage
            }
            state={inputs.find((input) => input.column === 'name').state}
            setState={inputs.find((input) => input.column === 'name').setters}
          />
        ) : (
          <p>{inputs.find((input) => input.column === 'name').state}</p>
        )}
        {onEdit ? (
          <SaveIcon onClick={exitEdit} />
        ) : (
          <PencilIcon data-testid="edit-button" onClick={startEdit} />
        )}
        {category.id.includes('new') && (
          <>
            <AlertIcon className="ml-2" data-tooltip-id={`alert-tooltip-${category.id}`} />
            <Tooltip
              id={`alert-tooltip-${category.id}`}
              place="right"
              effect="solid"
              arrowColor={'rgba(0, 0, 0, 0)'}
              style={{
                background: 'rgba(97, 97, 97, .9)',
                color: '#fff',
                fontSize: '14px',
                padding: '5px 16px',
                textTransform: 'none',
                width: 'auto',
                pointerEvents: 'none',
              }}>
              <span>子カテゴリを追加するために画面上部の</span>
              <br />
              <span>保存ボタンをクリックしてください</span>
            </Tooltip>
          </>
        )}
      </div>
      <div className="kmanager-accordion-form flex align-center ml-auto">
        {category_display.map((label) => {
          return (
            <InputCategoryDisplay
              status={inputs.find((input) => input.column === label.name).state}
              handleChange={handleChange}
              label={label}
              key={label.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GrandChildCategory;
