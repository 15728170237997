// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard .upcoming-auctions {
  margin-top: 40px;
}

.dashboard .upcoming-auctions-caption {
  margin-top: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Dashboard/upcoming-auctions.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".dashboard .upcoming-auctions {\n  margin-top: 40px;\n}\n\n.dashboard .upcoming-auctions-caption {\n  margin-top: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
