import LocalGovernmentManagementBankTransferProvider from './LocalGovernmentManagementBankTransferProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { localGovernmentManagementBankTransfer } from "../entities/componentMapping.js";

const LocalGovernmentManagementBankTransfer = ({ type }) => {
  return (
    <LocalGovernmentManagementBankTransferProvider type={type}>
      {getComponent(localGovernmentManagementBankTransfer, type)}
    </LocalGovernmentManagementBankTransferProvider>
  );
};

export default LocalGovernmentManagementBankTransfer;
