import { ReactComponent as FacebookIcon } from 'interfaces/assets/svg/facebook_icon.svg';
import { ReactComponent as TwitterIcon } from 'interfaces/assets/svg/twitter_icon.svg';
import { useLocation } from 'react-router-dom';
import { categoryJP } from 'shared/utils/helper/categoryJP.js';

export const HeroAreaIcon = ({ data }) => {
  const href = process.env.REACT_APP_BASE_URL + useLocation().pathname;
  const category = ['動産', '自動車', '不動産'];
  let uriMessage = `${data?.publicSaleType}や、${categoryJP(data?.propertyType)}、${data?.summaryCarCategories?.split('＞').filter((remove) => !category.includes(remove))}などに入札できる、KSI官公庁オークション（公売・公有財産売却）`;
  return (
    <div className="flex">
      <div className="twitter-button-wrap">
        <button
          data-testid="twitter-button"
          className="mt-3 px-2 v-btn v-btn--flat v-btn--icon v-btn--round theme--light elevation-0 v-size--default text--text twitter-button"
          style={{ minWidth: '100%' }}
          onClick={() =>
            window.open(
              `https://twitter.com/share?text=${encodeURIComponent(uriMessage)}&url=${encodeURIComponent(href)}`,
            )
          }>
          <span className="v-btn__content">
            <TwitterIcon />
          </span>
        </button>
      </div>
      <div>
        <button
          data-testid="facebook-button"
          className="mt-3 px-2 v-btn v-btn--flat v-btn--icon v-btn--round theme--light elevation-0 v-size--default text--text facebook-button"
          style={{ minWidth: '100%' }}
          onClick={() =>
            window.open(
              `https://www.facebook.com/sharer.php?u=${encodeURIComponent(href)}`,
            )
          }>
          <span className="v-btn__content">
            <FacebookIcon />
          </span>
        </button>
      </div>
    </div>
  );
};
