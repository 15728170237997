import FreeSearch from 'interfaces/components/common/LeftSideMenu/FreeSearch';
import PropertyCount from 'interfaces/components/common/LeftSideMenu/PropertyCount';

import TopicCardSwitcher from './TopicCardSwitcher';
import 'interfaces/css/Properties/properties-left-side-menu.css';

const PropertiesLeftSideMenuPC = ({
  category,
  propertiesNum,
  estatePropertiesNum,
  carPropertiesNum,
}) => {
  return (
    <div className="side-menu properties-left-side-menu-pc mr-4">
      <FreeSearch />
      <PropertyCount count={propertiesNum} />
      <TopicCardSwitcher
        category={category}
        estatePropertiesNum={estatePropertiesNum}
        carPropertiesNum={carPropertiesNum}
      />
    </div>
  );
};

export default PropertiesLeftSideMenuPC;
