import Breadcrumb from 'interfaces/components/common/Breadcrumb.jsx';
import Alerts from 'interfaces/components/Dashboard/navi/List/Alerts.jsx';
import GovernmentSettings from 'interfaces/components/Dashboard/navi/List/GovernmentSettings.jsx';
import SimpleNoticeList from 'interfaces/components/Dashboard/navi/List/SimpleNoticeList.jsx';
import UpcomingAuctions from 'interfaces/components/Dashboard/navi/List/UpcomingAuctions.jsx';
import ReminderModal from 'interfaces/components/Dashboard/navi/List/ReminderModal.jsx';
import { breadcrumbs } from 'interfaces/pages/Dashboard/entities/breadcrumbs';

const Dashboard = () => {
  return (
    <span className="dashboard" data-testid="top-page">
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <Alerts />
      <SimpleNoticeList />
      <GovernmentSettings />
      <UpcomingAuctions />
      <ReminderModal />
    </span>
  );
};

export default Dashboard;
