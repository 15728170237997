import { validateInclude, validateRangeLength } from "../../../../application/validations.js";

export class DocumentSubmissionUrl {
  #documentSubmissionUrl

  constructor(documentSubmissionUrl) {
    this.#documentSubmissionUrl = documentSubmissionUrl;
  }

  get() {
    return this.#documentSubmissionUrl
  }

  get isValid() {
    return [
      this.validateIncludeUrl,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateIncludeUrl() {
    return validateInclude(this.#documentSubmissionUrl, ['https://', 'http://'])
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#documentSubmissionUrl, 0, 2048)
  }
}
