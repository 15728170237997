// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import { CommonMessageType } from './MailTemplatesPublic/commonMessageType';
import { Id } from './MailTemplatesPublic/id';
import { IsCompensation } from './MailTemplatesPublic/isCompensation';
import { LeadText } from './MailTemplatesPublic/leadText';
import { LinkName } from './MailTemplatesPublic/linkName';
import { MailTemplateText } from './MailTemplatesPublic/mailTemplateText';
import { MainText } from './MailTemplatesPublic/mainText';
import { ProcedureType } from './MailTemplatesPublic/procedureType';
import { Subject } from './MailTemplatesPublic/subject';
import { Timing } from './MailTemplatesPublic/timing';
import CustomError from '../../../shared/utils/helper/customError.js';
import { NotUndefined } from '../../../shared/utils/helper/NotUndefined.js';

export class EmailGet {
  constructor(data) {
    this.commonMailTemplateId = data.commonMailTemplateId;
    this.procedureType = data.procedureType;
    this.depositExist = data.depositExist;
    this.mailType = data.mailType;
    this.commonMessageType = data.commonMessageType;
    this.linkName = data.linkName;
    this.sendTiming = data.sendTiming;
    this.templateName = data.templateName;
    this.leadText = data.leadText;
    this.subject = data.subject;
    this.printOrder = data.printOrder;
    this.createdUser = data.createdUser;
    this.createdAt = data.createdAt;
    this.updatedUser = data.updatedUser;
    this.updatedAt = data.updatedAt;
  }

  get isValid() {
    return NotUndefined(this);
  }

  setMailTemplateText(emailTemplate) {
    this.mailTemplateText = emailTemplate;
  }
}

export class CommonEmailGet {
  constructor(data) {
    this.id = data.id;
    this.governmentId = data.governmentId;
    this.mainText = data.mainText;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class AddDesctiptionsGet {
  constructor(data) {
    this.addDescription = data.addDescription;
    this.descriptionCreatedAt = data.descriptionCreatedAt;
    this.emailMessage = data.emailMessage;
    this.emailSentAt = data.emailSentAt;
    this.propertyId = data.propertyId;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class EmailCreate {
  constructor(data) {
    this.common_message_type = new CommonMessageType(data.common_message_type);
    this.id = new Id(data.id);
    this.is_compensation = new IsCompensation(data.is_compensation);
    this.lead_text = new LeadText(data.lead_text);
    this.link_name = new LinkName(data.link_name);
    this.mail_template_text = new MailTemplateText(data.mail_template_text);
    this.subject = new Subject(data.subject);
    this.timing = new Timing(data.timing);
    this.procedure_type = new ProcedureType(data.procedure_type);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.common_message_type.isValid &&
      this.id.isValid &&
      this.is_compensation.isValid &&
      this.lead_text.isValid &&
      this.link_name.isValid &&
      this.mail_template_text.isValid &&
      this.subject.isValid &&
      this.timing.isValid &&
      this.procedure_type.isValid
    );
  }
}

export class CommonEmailCreate {
  constructor(data) {
    this.id = data.id;
    this.governmentId = data.governmentId;
    this.mainText = new MainText(data.mainText);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.id.isValid && this.governmentId.isValid && this.mainText.isValid
    );
  }
}

export class SendEmail {
  constructor(data) {
    this.id = data.id;
    this.auctionUserId = data.auctionUserId;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return this.id.isValid && this.auctionUserId.isValid;
  }
}
