import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import FaqOtherListItem from './FaqOtherListItem.jsx';
import KSIAuctionBannerAssetSaleBig from '../../../assets/image/ksi-auction-banner-asset-sale-big.gif';
import KSIAuctionBannerPublicSaleBig from '../../../assets/image/ksi-auction-banner-auction-big.gif';
import KSIAuctionBannerAssetSaleSmall from '../../../assets/image/ksi-auction-banner-asset-sale-small.gif';
import KSIAuctionBannerPublicSaleSmall from '../../../assets/image/ksi-auction-banner-auction-small.gif';
import KSIAuctionBanner from '../../../assets/image/ksi-auction-banner.png';
import 'interfaces/css/FaqOther/faq-other-list.css';

const renderAnswer2 = (procedureType) => {
  return (
    <div>
      <div>
        以下の画像ファイルをダウンロードしてお使いください。
        <br />
        ※ダウンロード方法：ダウンロードしたいファイルの画像（例：「KSI官公庁オークション
        ロゴ」）にマウスを合わせ、右クリックで「名前を付けて画像を保存」で、ファイルを保存してください。
      </div>
      <div className="pt-6">
        「KSI官公庁オークション ロゴ」( 横840pix X 縦120pix PNG形式 )
      </div>
      <img
        src={KSIAuctionBanner}
        alt="KSI官公庁オークション ロゴ"
        className="pt-3"
      />
      <div className="pt-6">
        「官公庁オークション{' '}
        {procedureType === 'ASSET_SALE' ? '公有財産売却' : 'インターネット公売'}
        バナー 大」( 横280pix X 縦50pix GIF形式 )
      </div>
      <img
        src={
          procedureType === 'ASSET_SALE'
            ? KSIAuctionBannerAssetSaleBig
            : KSIAuctionBannerPublicSaleBig
        }
        alt={`官公庁オークション ${procedureType === 'ASSET_SALE' ? '公有財産売却' : 'インターネット公売'}バナー 大`}
        className="pt-3"
      />
      <div className="pt-6">
        「官公庁オークション{' '}
        {procedureType === 'ASSET_SALE' ? '公有財産売却' : 'インターネット公売'}
        バナー 小」( 横120pix X 縦60pix GIF形式 )
      </div>
      <img
        src={
          procedureType === 'ASSET_SALE'
            ? KSIAuctionBannerAssetSaleSmall
            : KSIAuctionBannerPublicSaleSmall
        }
        alt={`官公庁オークション ${procedureType === 'ASSET_SALE' ? '公有財産売却' : 'インターネット公売'}バナー 小`}
        className="pt-3"
      />
      <div className="pt-4">【使用上のご注意点】</div>
      <ul className="disc-list pl-6">
        <li className="list-item">縦横の比率は変更しないでください。</li>
        <li className="list-item">
          ロゴに影をつけたり、枠を付ける、縁取りをするなど、画像の改変はしないでください。
        </li>
        <li className="list-item">
          ロゴと文字が混在しないように配置をお願いします。
        </li>
      </ul>
      <div>
        ※なお、「インターネット
        {procedureType === 'ASSET_SALE' ? '公有財産売却' : '公売'}
        システム利用約款」の第6条2項では『
        {procedureType === 'ASSET_SALE' ? '行政機関' : '地方団体'}は、
        {procedureType === 'ASSET_SALE'
          ? '公有財産売却システム'
          : 'インターネット公売'}
        内での使用を除き、当社の事前の書面による承諾なく「紀尾井町戦略研究所」、「KSI」、「KSI
        官公庁オークション」の表記またはこれらに類似するサービスマーク、ロゴ等を使用してはならない。』としております。上記の弊社からご提供のロゴ、バナーを用いて下記の事例の範疇で広報していただく限りは事前の承諾は必要ありません。もし下記以外でなんらかの活用を検討されている場合や、プレスリリースを出される場合は、事前にメールで実施時期、実施対象、実施方法などを分かる範囲でご連絡いただくようお願いいたします。
      </div>
      <div className="pt-4">＜行政機関様メディア活用事例＞</div>
      <ul className="disc-list pl-6">
        <li className="list-item">行政機関様ウェブサイトへの掲載</li>
        <li className="list-item">
          行政機関様のSNSでの告知（オークション開催告知など）
        </li>
        <li className="list-item">行政機関様でのポスターおよびチラシ作成</li>
        <li className="list-item">行政機関様の広報媒体への掲載</li>
      </ul>
    </div>
  );
};

const renderAnswer4 = (procedureType) => {
  return (
    <div>
      KSI官公庁オークションではHTMLは使用できません。代わりにMarkdown記法が利用可能です。
      <br />
      （「よくある質問{' '}
      <a
        href={`/documents/${procedureType === 'ASSET_SALE' ? 'asset-sale' : 'auction'}-markdown-sample.pdf`}
        target="_blank"
        rel="noopener noreferrer">
        Markdownサンプル
      </a>
      」でもご案内していますので参考にされてください）
    </div>
  );
};

const renderAnswer5 = (procedureType) => {
  return (
    <div>
      KSI官公庁オークションではMarkdownのみ使用可能です。JavaScriptやスタイルシートは利用できません。プレビューモードで正常に表示されているかどうか必ずご確認ください。
      {procedureType === 'ASSET_SALE' ? (
        ''
      ) : (
        <div>
          KSI官公庁オークションではHTMLは使用できません。代わりにMarkdown記法が利用可能です。
        </div>
      )}
      <div>
        （「よくある質問{' '}
        <a
          href={`/documents/${procedureType === 'ASSET_SALE' ? 'asset-sale' : 'auction'}-markdown-sample.pdf`}
          target="_blank"
          rel="noopener noreferrer">
          Markdownサンプル
        </a>
        」でもご案内していますので参考にされてください）
      </div>
    </div>
  );
};

const renderAnswer9 = (procedureType) => {
  return (
    <div>
      <div>
        官公庁オークションでは、実際に手続きをする方が参加申し込み者本人か代理人かを参加申し込み時の個人情報入力ページで選択するようになっています。代理人である場合、入札前に委任状などを執行機関様に提出することが必要です。
      </div>
      <div className="pt-4">◆ご案内方法</div>
      <div>
        官公庁ナビの「自動送信メール管理」機能にて、以下の自動送信メールに文言を追加してご案内できます。
      </div>
      <div className="pt-4">
        ＜{procedureType === 'ASSET_SALE' ? '入札' : '公売'}保証金あり＞
      </div>
      <ul className="disc-list pl-4">
        <li className="list-item">
          クレジットカード{procedureType === 'ASSET_SALE' ? '仮' : ''}
          申し込み完了メール
        </li>
        <li className="list-item">銀行振込など仮申し込み完了メール</li>
      </ul>
      <div className="pt-4">
        ＜{procedureType === 'ASSET_SALE' ? '入札' : '公売'}保証金なし＞
      </div>
      <ul className="disc-list pl-4">
        <li className="list-item">
          {procedureType === 'ASSET_SALE' ? '仮' : ''}申し込み完了メール
        </li>
      </ul>
      <div className="pt-4">◆ご案内文（案）</div>
      <div>
        代理人でお手続きをされる場合は、入札開始の2開庁日前までに、以下の書類を執行機関あてに提出されることが必要です。必要書類のご提出がない場合は、参加申し込みを取り消します。
      </div>
      <ul className="disc-list pl-4">
        <li className="list-item">委任状</li>
        <li className="list-item">住民票などの入札者本人の本人確認書類</li>
      </ul>
    </div>
  );
};

const renderAnswer15 = (procedureType) => {
  return (
    <div>
      以下ファイルをダウンロードのうえ、適宜修正いただいたうえお使いください。
      <br />
      ※ダウンロード方法：テキスト「
      {procedureType === 'ASSET_SALE' ? '公有財産売却' : '公売'}
      参加申込書（サンプル）」（Excel形式）にマウスを合わせ、右クリックで「対象をファイルに保存」で、ファイルを保存してください
      {procedureType === 'ASSET_SALE' ? '。' : ''}
      <br />
      <a
        href={`/documents/sample/entry-${procedureType === 'ASSET_SALE' ? 'asset-sale' : 'auction'}.xls`}>
        「{procedureType === 'ASSET_SALE' ? '公有財産売却' : '公売'}
        参加申込書（サンプル）」（Excel形式）
      </a>
    </div>
  );
};

const renderAnswer16 = (procedureType) => {
  return (
    <div>
      <div>
        ご使用いただけます。ただし、会員識別番号が表示されないようご注意ください。官公庁オークションのトップページにつきましては、以下サンプル画像のファイルをダウンロードのうえ、ご利用ください。
      </div>
      <div>
        ※ダウンロード方法：テキスト「官公庁オークショントップページ（サンプル画像）」（JPEG形式）にマウスを合わせ、右クリックで「対象をファイルに保存」で、ファイルを保存してください。
      </div>
      <div>
        <a
          href="/images/sample/ksi-gov-auction-pc.jpg"
          target="_blank"
          rel="noopener noreferrer">
          「官公庁オークショントップページ（サンプル画像PC版）」（JPEG形式）
        </a>
      </div>
      <div>
        <a
          href="/images/sample/ksi-gov-auction-mb.jpg"
          target="_blank"
          rel="noopener noreferrer">
          「官公庁オークショントップページ（サンプル画像モバイル版）」（JPEG形式）
        </a>
      </div>
      <div>
        また、「<Link to="/navi/faq/other">よくある質問 その他</Link>
        」では、行政機関様の各メディアでご利用になれる官公庁オークション用のロゴやバナー等もご案内しておりますのでご活用ください。
      </div>
      <div className="pt-4">
        なお、「
        {procedureType === 'ASSET_SALE'
          ? 'KSIインターネット公有財産売却'
          : 'インターネット公売'}
        システム利用約款」の第6条2項では『
        {procedureType === 'ASSET_SALE' ? ' 行政機関' : '地方団体'}は、
        {procedureType === 'ASSET_SALE'
          ? '公有財産売却サービス'
          : 'インターネット公売'}
        内での使用を除き、当社の事前の書面による承諾なく「紀尾井町戦略研究所」、「KSI」、「KSI
        官公庁オークション」{procedureType === 'ASSET_SALE' ? '' : 'の表記'}
        またはこれらに類似するサービスマーク、ロゴ等を使用してはならない。』としております。上記の弊社からご提供のサンプル画像、ロゴ、バナーを用いて下記の活用事例の範疇で広報していただく限りは事前の承諾は必要ありません。もし下記以外でなんらかの活用を検討されている場合や、プレスリリースを出される場合は、事前にメールで実施時期、実施対象、実施方法などを分かる範囲でご連絡いただくようお願いいたします。
      </div>
      <div className="pt-4">＜行政機関様メディア活用事例＞</div>
      <ul className="disc-list pl-4">
        <li className="list-item">行政機関様ウェブサイトへの掲載</li>
        <li className="list-item">
          行政機関様のSNSでの告知（オークション開催告知など
        </li>
        <li className="list-item">行政機関様でのポスターおよびチラシ作成</li>
        <li className="list-item">行政機関様の広報媒体への掲載</li>
      </ul>
    </div>
  );
};

const FaqOtherList = ({ procedureType }) => {
  const [faqOtherListItemData, setFaqOtherListItemData] = useState([]);

  useEffect(() => {
    const data = [
      {
        id: 1,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question:
          '複数のパソコンで、同時に同一の物件データを作成、編集しても問題はないですか？',
        answer:
          '不具合が発生する可能性があるので同一の物件データを同時に編集することはお避けください。',
      },
      {
        id: 2,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question: '官公庁オークション用のロゴやバナーを使いたいのですが。',
        answer: renderAnswer2(procedureType),
      },
      {
        id: 3,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question: 'フリーソフトは使用しても問題ないですか？',
        answer:
          'セキュリティーの関係上お勧めはいたしません。行政機関様のご判断でご利用をお願いいたします。',
      },
      {
        id: 4,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question: 'HTMLタグの使用方法が分からないので教えてもらえますか？',
        answer: renderAnswer4(procedureType),
      },
      {
        id: 5,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question:
          'ホームページビルダーなどのホームページ作成ソフトを使用したHTMLタグは、そのまま使用して問題ないですか？',
        answer: renderAnswer5(procedureType),
      },
      {
        id: 6,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question:
          '人事異動などによる担当者の変更が発生した場合、ナビユーザーIDはどうすればいいですか？',
        answer:
          'ナビユーザーIDの管理者、親、子ユーザー様が変更になる場合は、各行政機関様にて、官公庁ナビよりナビユーザーIDの発行・削除等の作業を行っていただく必要があります。変更方法につきましては、最新の「官公庁ナビマニュアル」をダウンロードのうえ、ご確認ください。',
      },
      {
        id: 7,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question:
          '人事異動などによる担当者の変更が発生した場合、連絡先を変更するにはどうすればよいですか？',
        answer: `${procedureType === 'ASSET_SALE' ? '公有財産売却' : 'インターネット公売'}のご担当者を変更する場合は、「ナビユーザー管理」メニューより変更作業をお願いします。`,
      },
      {
        id: 8,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question: `${procedureType === 'ASSET_SALE' ? '公有財産売却' : '官公庁オークション'}の「実施中行政機関一覧」ページに並んでいる行政機関の順番は変更可能ですか？`,
        answer:
          '現在は行政機関様の所在地が北から南の順番で表示しており、さらに都道府県と市町村で分けています。今後も随時変更が予測されますが、弊社で決定をしていますのであらかじめご了承ください。',
      },
      {
        id: 9,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question:
          '代理人が手続きを行う場合に必要な手続きはどこに案内すればよいですか？',
        answer: renderAnswer9(procedureType),
      },
      {
        id: 10,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question:
          'KSI官公庁オークションの中で、自分の行政機関のページビュー数を教えてください。',
        answer: 'KSI官公庁オークションのページビュー数は公開しておりません。',
      },
      {
        id: 11,
        role: ['PUBLIC_SALE'],
        question: '債権者登録は発行してもらえますか？',
        answer:
          '郵送の場合は、メールでご一報の上、弊社運用担当まで登録用紙と書き方見本をお送りください。なお、発行には1週間以上、お時間をいただく場合もありますので、お早めにご依頼ください。デジタル対応されている場合は、メールでご依頼ください。',
      },
      {
        id: 12,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question:
          'ガイドラインサンプルの上下にシステム的に表示される青字の文章はなぜ必要なのですか？',
        answer:
          '前文部分は、各行政機関様が決められたガイドラインが紀尾井町戦略研究所株式会社の各種規約等に比して優先して適用されるという部分になります。後半部分で、「個人情報の取り扱い部分」の重要な内容は、個人情報の収集主体が各行政機関様にあり、紀尾井町戦略研究所株式会社ではないということです。また、公売保証金のオン納付について参加申し込み者に「紀尾井町戦略研究所株式会社を代理人とする」ことをご承諾いただいているのが、ガイドラインのこの部分になります。したがって、いずれも重要な内容になります。なお、青字部分はシステム的に表示しておりますので削除することはできません。',
      },
      {
        id: 13,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question:
          '参加申し込み者から行政機関への提出書類について、ガイドラインの案文では「入札開始2開庁日前まで」となっているが、期限を変更してもよいですか？',
        answer:
          'ガイドラインの案文の日数などは、あくまで例に過ぎません。行政機関様の実際の状況にあわせてガイドラインを作成してください。また、「申し込み締切日の消印まで有効です。」と記載されている行政機関様もいらっしゃいます。',
      },
      {
        id: 14,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question:
          'ガイドラインの案文の中で、「復代理人」「任意代理人」とは、どのような意味でしょうか。代理人との違いは？',
        answer: (
          <div>
            民法上の代理人になります。簡単にご説明いたしますと以下のようになります。
            <br />
            <ul style={{ paddingLeft: '24px', marginTop: '16px' }}>
              <li className="list-item">復代理人→代理人の代理人</li>
              <li className="list-item">
                任意代理人→法定代理人と対になる文言{' '}
              </li>
            </ul>
            それ以外に、文字通り任意で決定できる代理人を言います。
            <br />
            （例）親は子の法定代理人になります。
          </div>
        ),
      },
      {
        id: 15,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question: `${procedureType === 'ASSET_SALE' ? '入札' : '公売'}保証金を銀行振込など（オフ納付）で納付する場合に、参加者に執行機関のホームページよりダウンロードいただく書式のサンプルはありますか？`,
        answer: renderAnswer15(procedureType),
      },
      {
        id: 16,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        question:
          '官公庁オークションの画面を、画像にして使用してもよいですか？',
        answer: renderAnswer16(procedureType),
      },
    ].filter((item) => item.role.includes(procedureType));
    setFaqOtherListItemData(data);
  }, [procedureType]);
  return (
    <div data-testid="faq-other-list" className="faq-other-list pt-16">
      {faqOtherListItemData.map((faq) => (
        <FaqOtherListItem
          key={faq.id}
          question={faq.question}
          answer={faq.answer}
        />
      ))}
    </div>
  );
};

export default FaqOtherList;
