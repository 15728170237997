import "interfaces/css/common/page-title.css";

const PageTitle = ({ text }) => {
  return (
    <h2
      data-testid="kmanager-page-title"
      className="kmanager-page-title"
    >
      { text }
    </h2>
  );
}
export default PageTitle;