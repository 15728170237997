import Button from 'interfaces/components/common/Button/Button.jsx';
import Toggle from 'interfaces/components/common/Toggle/Toggle.jsx';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import BothForm from './BothForm.jsx';
import IndividualForm from './IndividualForm.jsx';
//import configs from "../../api/configs/creditCardPage.js";
//import { allFetch } from "../../api/newEntry.js";

const CreditForm = ({
  validations,
  indivisualSetting,
  setIndivisualSetting,
  setIsOpen,
  procedureType,
  governmentType,
}) => {
  // TODO: ログイン情報から行政機関IDを取得する
  //  const GOVERNMENT_ID = "2";
  //  const PROCEDURE_TYPE = "PUBLIC_SALE";

  const navigate = useNavigate();

  async function update(e) {
    e.preventDefault();
    //    const Configs = configs(GOVERNMENT_ID, PROCEDURE_TYPE, validations)
    //    await allFetch(Configs.auction);
    //    await allFetch(Configs.public);

    setIsOpen(true);
  }

  const backToLocalGovernmentManagement = () => {
    navigate('/navi/local-government-management');
  };

  // const [isValid, setIsValid] = useState(true);
  // useEffect(() => {
  //   setIsValid(true);
  // });

  // TODO: 本番用：実装が終わったら戻す
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const validateMessages = (
      form,
      procedureType,
      indivisualSetting,
      governmentType,
    ) => {
      const { validationMessage } = form;

      if (
        procedureType === 'ASSET_SALE' ||
        governmentType === 'NATIONAL_TAX_AGENCY'
      ) {
        return Object.entries(validationMessage).every(
          ([key, validationMessage]) => {
            if (key === 'bidJointBiddingUrlValid') {
              return validationMessage === null || validationMessage === 'TRUE';
            }
            return validationMessage === 'TRUE';
          },
        );
      }

      if (indivisualSetting) {
        const auctionValid =
          validationMessage.auctionDocumentSubmissionUrlRequiredValid ===
          'TRUE';
        const bidValid =
          validationMessage.bidDocumentSubmissionUrlRequiredValid === 'TRUE';
        const jointBiddingValid =
          validationMessage.bidJointBiddingUrlValid === 'TRUE';

        return auctionValid && (bidValid || jointBiddingValid);
      } else {
        const auctionOrJointBiddingValid =
          validationMessage.auctionDocumentSubmissionUrlRequiredValid ===
            'TRUE' || validationMessage.bidJointBiddingUrlValid === 'TRUE';
        const bidOrJointBiddingValid =
          validationMessage.bidDocumentSubmissionUrlRequiredValid === 'TRUE' ||
          validationMessage.bidJointBiddingUrlValid === 'TRUE';

        return auctionOrJointBiddingValid && bidOrJointBiddingValid;
      }
    };

    setIsValid(
      validateMessages(
        validations,
        procedureType,
        indivisualSetting,
        governmentType,
      ),
    );
  }, [validations, procedureType, indivisualSetting, governmentType]);

  return (
    <div className="new-style_navi_local-government-management-top-form">
      {governmentType !== 'NATIONAL_TAX_AGENCY' && (
        <>
          <Toggle
            message={'「せり売形式」と「入札形式」で個別に情報を登録する'}
            handler={() => setIndivisualSetting(!indivisualSetting)}
          />
          <div className="sub-message">
            ※
            参加者が仮申し込みを完了した際の表示を「せり売形式」と「入札形式」の物件で個別に設定したい場合にご利用ください。
            <br />
          </div>
        </>
      )}
      {!indivisualSetting && (
        <BothForm
          validations={validations}
          procedureType={procedureType}
          governmentType={governmentType}
        />
      )}
      {indivisualSetting && (
        <IndividualForm
          validations={validations}
          procedureType={procedureType}
          governmentType={governmentType}
        />
      )}
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'登録'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_primary-button'
              }
              onClick={update}
              isDisabled={!isValid}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_normal-button'
              }
              onClick={backToLocalGovernmentManagement}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreditForm;
