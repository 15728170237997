import 'interfaces/css/Manual/manual-list.css';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

/* ここから動作確認用サンプル */
const auctionManualData = [
  { text: 'インターネット公売マニュアル', to: '/documents/manual-auction.pdf' },
  {
    text: '2023年度 インターネット公売開催スケジュール',
    to: '/documents/schedule/2023-auction-schedule-auction.pdf',
  },
  {
    text: '2024年度 インターネット公売開催スケジュール',
    to: '/documents/schedule/2024-auction-schedule-auction.pdf',
  },
  {
    text: '2023年7月インターネット公売運用カレンダー',
    to: '/documents/calender/auction/202307.pdf',
  },
  {
    text: '2023年8月インターネット公売運用カレンダー',
    to: '/documents/calender/auction/202308.pdf',
  },
  {
    text: '2023年10月インターネット公売運用カレンダー',
    to: '/documents/calender/auction/202310.pdf',
  },
  {
    text: '2024年1月インターネット公売運用カレンダー',
    to: '/documents/calender/auction/202401.pdf',
  },
  {
    text: '2024年4月インターネット公売運用カレンダー',
    to: '/documents/calender/auction/202404.pdf',
  },
  {
    text: '2024年5月インターネット公売運用カレンダー',
    to: '/documents/calender/auction/202405.pdf',
  },
  {
    text: '2024年7月インターネット公売運用カレンダー',
    to: '/documents/calender/auction/202407.pdf',
  },
  {
    text: '2024年8月インターネット公売運用カレンダー',
    to: '/documents/calender/auction/202408.pdf',
  },
  {
    text: '2024年10月インターネット公売運用カレンダー',
    to: '/documents/calender/auction/202410.pdf',
  },
  {
    text: '2024年1月インターネット公売運用カレンダー',
    to: '/documents/calender/auction/202401.pdf',
  },
  {
    text: '取扱禁止物品ガイドライン',
    to: '/documents/handling-prohibition-property-guideline.pdf',
  },
  {
    text: 'KSIインターネット公売システム利用申込書',
    to: '/documents/auction-system-application-form.pdf',
  },
  {
    text: 'KSIインターネット公売システム利用約款',
    to: '/documents/auction-system-terms-of-use.pdf',
  },
];

const assetSaleManualData = [
  {
    text: '公有財産売却マニュアルASSET',
    to: '/documents/manual-asset-sale.pdf',
  },
  {
    text: '2023年度 公有財産売却開催スケジュール',
    to: '/documents/schedule/2023-auction-schedule-asset-sale.pdf',
  },
  {
    text: '2024年度 公有財産売却開催スケジュール',
    to: '/documents/schedule/2024-auction-schedule-asset-sale.pdf',
  },
  {
    text: '2023年7月公有財産売却運用カレンダー',
    to: '/documents/calender/asset-sale/202307.pdf',
  },
  {
    text: '2023年9月公有財産売却運用カレンダー',
    to: '/documents/calender/asset-sale/202309.pdf',
  },
  {
    text: '2023年10月公有財産売却運用カレンダー',
    to: '/documents/calender/asset-sale/202310.pdf',
  },
  {
    text: '2024年1月公有財産売却運用カレンダー',
    to: '/documents/calender/asset-sale/202401.pdf',
  },
  {
    text: '2024年4月公有財産売却運用カレンダー',
    to: '/documents/calender/asset-sale/202404.pdf',
  },
  {
    text: '2024年5月公有財産売却運用カレンダー',
    to: '/documents/calender/asset-sale/202405.pdf',
  },
  {
    text: '2024年7月公有財産売却運用カレンダー',
    to: '/documents/calender/asset-sale/202407.pdf',
  },
  {
    text: '2024年8月公有財産売却運用カレンダー',
    to: '/documents/calender/asset-sale/202408.pdf',
  },
  {
    text: '2024年10月公有財産売却運用カレンダー',
    to: '/documents/calender/asset-sale/202410.pdf',
  },
  {
    text: '2024年1月公有財産売却運用カレンダー',
    to: '/documents/calender/asset-sale/202401.pdf',
  },
  {
    text: '取扱禁止物品ガイドライン',
    to: '/documents/handling-prohibition-property-guideline.pdf',
  },
  {
    text: 'KSIインターネット公有財産売却システム利用申込書',
    to: '/documents/asset-sale-system-application-form.pdf',
  },
  {
    text: 'KSIインターネット公有財産売却システム利用約款',
    to: '/documents/asset-sale-system-terms-of-use.pdf',
  },
];
/* ここまで動作確認用サンプル */

const NaviManualList = () => {
  const [manualData, setManualData] = useState([]);
  const { localStorageProcedureType } = useLocalStorage();

  useEffect(() => {
    if (localStorageProcedureType === 'ASSET_SALE') {
      setManualData(assetSaleManualData);
      return;
    }
    setManualData(auctionManualData);
  }, [localStorageProcedureType]);

  return (
    <div
      data-testid="manual-list"
      className="flex flex-direction-column align-start pt-6 pl-4 pb-6">
      {manualData.map((item, index) => (
        <a
          key={index}
          href={item.to}
          target="_blank"
          rel="noreferrer"
          data-testid="manual-item"
          className="manual-item mb-6">
          {item.text}
        </a>
      ))}
    </div>
  );
};
export default NaviManualList;
