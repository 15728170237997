// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.properties .search-conditions .single-open-accordion .single-open-accordion-item .single-open-accordion-item-header {
    font-weight: 700;
    padding: 14px 12px;
}

.properties .search-conditions .single-open-accordion .single-open-accordion-item-header-icon {
    top: 38%;
    right: 14px;
}

.properties .search-conditions .single-open-accordion .single-open-accordion-item-header-icon span {
    width: 16px;
}

.properties .search-conditions .single-open-accordion .single-open-accordion-item .single-open-accordion-body {
    padding: 0 16px 12px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Properties/search-conditions.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,QAAQ;IACR,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".properties .search-conditions .single-open-accordion .single-open-accordion-item .single-open-accordion-item-header {\n    font-weight: 700;\n    padding: 14px 12px;\n}\n\n.properties .search-conditions .single-open-accordion .single-open-accordion-item-header-icon {\n    top: 38%;\n    right: 14px;\n}\n\n.properties .search-conditions .single-open-accordion .single-open-accordion-item-header-icon span {\n    width: 16px;\n}\n\n.properties .search-conditions .single-open-accordion .single-open-accordion-item .single-open-accordion-body {\n    padding: 0 16px 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
