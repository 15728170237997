import { useEffect, useState } from 'react';

import { get as getBanner } from "../../../../domain/Banner/services/Banner.js"
import { useAuth } from "../../../../shared/hooks/useAuth.js";
import { FORGET_PASSWORD, LOGIN, SIGN_UP } from "../../../../shared/utils/helper/authState.js";
import { SERVICE, NAVI, KMANAGER } from "../../../../shared/utils/helper/constants.js";
import { BannerContext } from '../entities/BannerContext.js';

const BannerProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = { LOGIN: LOGIN, SIGN_UP: SIGN_UP, FORGET_PASSWORD: FORGET_PASSWORD };
  //const [bannerFiles, setBannerFiles] = useState([]);
  const [bannerFiles] = useState([]);
  const [banners, setBanners] = useState([]);

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
    }, []);
  }

  if (type === KMANAGER) {
    // TODO: domain/File/entitiesにBannerFileにに該当するクラスを追加する
    // useEffect(() => {
    //   getBannerFiles(type).then(result => {
    //     setBannerFiles(result);
    //   });
    // }, []);

    useEffect(() => {
      getBanner(type).then(result => {
        setBanners(result);
      });
    }, []);
  }

  return (
    <BannerContext.Provider value={{
      authState,
      governmentId,
      governmentType,
      procedureType,
      bannerFiles,
      banners,
    }}>
      {children}
    </BannerContext.Provider>
  );
};

export default BannerProvider;