import Button from './Button.jsx';
import ButtonList from './ButtonList.jsx';
import InputType from './InputType.jsx';
import 'interfaces/css/common/form.css';

const Form = ({ inputs, buttonData }) => {
  return (
    <div className="kmanager-col-12">
      <div className="kmanager-form" data-testid="kmanager-form">
        {inputs &&
          inputs.map((input, index) => {
            return InputType(input, index);
          })}
        <ButtonList>
          <div className="d-flex justify-center mt-10">
            {buttonData &&
              buttonData.map((item, index) => (
                <Button
                  key={index}
                  text={item.text}
                  clickHandler={item.clickHandler}
                  isOutline={item.isOutline}
                  isDisabled={item.disabled || false}
                  className={item.className}
                />
              ))}
          </div>
        </ButtonList>
      </div>
    </div>
  );
};

export default Form;
