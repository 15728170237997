import { createContext } from 'react';

import { breadcrumbs } from './breadcrumbs.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { DATA_EMPTY } from '../../../../shared/utils/helper/constants.js';

export const PropertiesContext = createContext({
  // Default Value
  responsive: DATA_EMPTY,
  authState: {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  },
  breadcrumbs: breadcrumbs,
  managementMessages: DATA_EMPTY,
  governmentId: DATA_EMPTY,
  governmentType: DATA_EMPTY,
  procedureType: DATA_EMPTY,
  queryParams: DATA_EMPTY,
  generalCategories: DATA_EMPTY,
  governments: DATA_EMPTY,
  allowPropertyIds: DATA_EMPTY,
  properties: DATA_EMPTY,
  propertiesIdPriceDesc: DATA_EMPTY,
  bidInformation: DATA_EMPTY,
  favoritePropertyIds: DATA_EMPTY,
  propertiesIdPriceAsc: DATA_EMPTY,
  propertiesIdDaysLeftDesc: DATA_EMPTY,
  propertiesIdDaysLeftAsc: DATA_EMPTY,
  propertiesIdBidCountDesc: DATA_EMPTY,
  propertiesIdBidCountAsc: DATA_EMPTY,
  propertiesIdMileageDesc: DATA_EMPTY,
  propertiesIdMileageAsc: DATA_EMPTY,
  propertiesFirstRegisterDateDesc: DATA_EMPTY,
  propertiesIdFirstRegisterDateAsc: DATA_EMPTY,
  myBidPrice: DATA_EMPTY,
  propertyImages: DATA_EMPTY,
  carCategoryData: undefined,
  estateCategoryData: undefined,
  propertyCount: {},
  applicantPropertyIds: DATA_EMPTY,
  searchParams: DATA_EMPTY,
  setSearchParams: () => {},
});
