
export class SystemUsagePaymentDate {
  #systemUsagePaymentDate

  constructor(systemUsagePaymentDate) {
    this.#systemUsagePaymentDate = systemUsagePaymentDate;
  }

  get() {
    return this.#systemUsagePaymentDate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
