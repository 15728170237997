import {
  BidCountBody,
  DepositBody,
  DepositPaymentBody,
  MyBidPriceBody,
  PaymentBody,
} from './PropertyCardCaption.jsx';
import { biddedUser } from '../../helper/biddedUser.js';
import { isFalsy } from '../../helper/isFalsy.js';

const EndBiddingPropertyCardCaptions = ({ data }) => {
  return (
    <>
      <div
        className="detail-item box nogrow flex pa-0 mb-0 gaps"
        data-testid="bidded-user">
        <div className="box text-no-wrap no-bg property-sheet theme-light">
          落札者
        </div>
        <div className="box no-bg property-sheet theme-light">
          <p className="mb-0">
            <b>{biddedUser(data.bidHistory, data.biddedUser)}</b>
          </p>
        </div>
        <div className="box mr-4 property-sheet theme-light"> |</div>
      </div>
      {data.publicSaleType === '入札形式' &&
      data.bidCountDisplay === 'HIDDEN' ? null : (
        <BidCountBody bidCount={data.bidCount} />
      )}
      {!isFalsy(data.myBidPrice) && (
        <MyBidPriceBody myBidPrice={data.myBidPrice} />
      )}
      <DepositBody deposit={data.deposit} />
      <DepositPaymentBody depositPayment={data.depositPayment} />
      <PaymentBody payments={data.payments} />
    </>
  );
};
export default EndBiddingPropertyCardCaptions;
