import { createContext } from 'react';

import { FORGET_PASSWORD, LOGIN, SIGN_UP } from "../../../../shared/utils/helper/authState.js";
import { DATA_EMPTY } from "../../../../shared/utils/helper/constants.js";

export const AuctionDepositContext = createContext({
  // Default Value
  authState: {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD
  },
  auction: DATA_EMPTY,
  auctionDeposits: DATA_EMPTY
});
