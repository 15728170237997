export const GovernmentTypes = {
  NATIONAL_TAX_AGENCY: { string: 'NATIONAL_TAX_AGENCY', jp: '国税庁' },
  PREFECTURE: { string: 'PREFECTURE', jp: '都道府県' },
  MUNICIPALITY: { string: 'MUNICIPALITY', jp: '市区町村' },
  NATIONAL_ASSET: { string: 'NATIONAL_ASSET', jp: '国_公有財産売却' },
  OTHER: { string: 'OTHER', jp: 'その他の行政機関' },
};

export const KmanagerProcedureTypes = {
  ASSET_SALE: { string: 'ASSET_SALE', jp: '公有' },
  PUBLIC_SALE: { string: 'PUBLIC_SALE', jp: '公売' },
};

export const ProcedureTypes = {
  ASSET_SALE: { string: 'ASSET_SALE', jp: '公有財産売却' },
  PUBLIC_SALE: { string: 'PUBLIC_SALE', jp: 'インターネット公売' },
};

export const FeeTypes = {
  RATIO: { string: 'RATIO', jp: '計算式' },
};
