// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-flex {
    display: flex;
    align-items: center;
}

.radio-label {
    margin-block-start: initial;
    margin-block-end: initial;
}

.radio-icon-padding {
    padding-top: 5px;
    padding-right: 5px;
}

.radio-hidden {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/User/user-register-form.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".radio-flex {\n    display: flex;\n    align-items: center;\n}\n\n.radio-label {\n    margin-block-start: initial;\n    margin-block-end: initial;\n}\n\n.radio-icon-padding {\n    padding-top: 5px;\n    padding-right: 5px;\n}\n\n.radio-hidden {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
