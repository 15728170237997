// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-default-container {
  flex: 1 0 auto;
  padding-top: 64px;
  padding-bottom: 36px;
  padding-left: 300px;
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/article-default-container.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;EACjB,oBAAoB;EACpB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".article-default-container {\n  flex: 1 0 auto;\n  padding-top: 64px;\n  padding-bottom: 36px;\n  padding-left: 300px;\n  max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
