import { useState } from 'react';

export function useAuctionInputs(auction) {
  const [name, setName] = useState(auction ? auction.name : '');
  const [governmentTypeGroup, setGovernmentTypeGroup] = useState(
    auction ? auction.governmentTypeGroup : 'NATIONAL_TAX_AGENCY',
  );
  const [procedureType, setProcedureType] = useState(
    auction ? auction.procedureType : 'PUBLIC_SALE',
  );
  const [publicSaleType, setPublicSaleType] = useState(
    auction ? auction.publicSaleType : 'AUCTION',
  );
  const [govAplStart, setGovAplStart] = useState(
    auction ? auction.govAplStart : '',
  );
  const [govAplEnd, setGovAplEnd] = useState(auction ? auction.govAplEnd : '');
  const [aplStart, setAplStart] = useState(auction ? auction.aplStart : '');
  const [aplEnd, setAplEnd] = useState(auction ? auction.aplEnd : '');
  const [bidStart, setBidStart] = useState(auction ? auction.bidStart : '');
  const [auctionEnd, setAuctionEnd] = useState(auction ? auction.auctionEnd : '');
  const [bidEnd, setBidEnd] = useState(auction ? auction.bidEnd : '');
  const [addBidDuration, setAddBidDuration] = useState(
    auction ? auction.addBidDuration : '',
  );
  const [additionalBidEnd, setAdditionalBidEnd] = useState(
    auction ? auction.additionalBidEnd : '',
  );
  const [bidDecision, setBidDecision] = useState(
    auction ? auction.bidDecision : '',
  );
  const [openEnd, setOpenEnd] = useState(auction ? auction.openEnd : '');
  const [nextPaymentLimit, setNextPaymentLimit] = useState(
    auction ? auction.nextPaymentLimit : '',
  );
  const [estateNextPaymentLimit, setEstateNextPaymentLimit] = useState(
    auction ? auction.estateNextPaymentLimit : '',
  );
  const [depositType, setDepositType] = useState(auction ? auction.depositType : 'AGENCY');

  const auctionInputs = {
    type: {
      name: 'text',
      governmentTypeGroup: 'radio',
      procedureType: 'radio',
      publicSaleType: 'radio',
      govAplStart: 'datetime',
      govAplEnd: 'datetime',
      aplStart: 'datetime',
      aplEnd: 'datetime',
      bidStart: 'datetime',
      auctionEnd: 'datetime',
      bidEnd: 'datetime',
      addBidDuration: 'time',
      additionalBidEnd: 'datetime',
      bidDecision: 'datetime',
      openEnd: 'datetime',
      nextPaymentLimit: 'datetime',
      estateNextPaymentLimit: 'datetime',
      depositType: 'select',
    },
    data: {
      name,
      governmentTypeGroup,
      procedureType,
      publicSaleType,
      govAplStart,
      govAplEnd,
      aplStart,
      aplEnd,
      bidStart,
      auctionEnd,
      bidEnd,
      addBidDuration,
      additionalBidEnd,
      bidDecision,
      openEnd,
      nextPaymentLimit,
      estateNextPaymentLimit,
      depositType,
    },
    setters: {
      name: setName,
      governmentTypeGroup: setGovernmentTypeGroup,
      procedureType: setProcedureType,
      publicSaleType: setPublicSaleType,
      govAplStart: setGovAplStart,
      govAplEnd: setGovAplEnd,
      aplStart: setAplStart,
      aplEnd: setAplEnd,
      bidStart: setBidStart,
      auctionEnd: setAuctionEnd,
      bidEnd: setBidEnd,
      addBidDuration: setAddBidDuration,
      additionalBidEnd: setAdditionalBidEnd,
      bidDecision: setBidDecision,
      openEnd: setOpenEnd,
      nextPaymentLimit: setNextPaymentLimit,
      estateNextPaymentLimit: setEstateNextPaymentLimit,
      depositType: setDepositType,
    },
  };
  return auctionInputs;
}
