import CommonInput from './CommonInput.jsx';
import handleValidationMessage from "../../../../shared/utils/helper/handleValidationMessage";

const CommonTextInput = ({ label, name, value, placeholder, handleChange, validationMessage }) => {
  return (
    <tr data-testid="common-text-input">
      <th data-testid="common-text-input-label">{label}</th>
      <td data-testid="common-text-input-body">
        <div className="w-expand">
          <CommonInput
            type="text"
            name={name}
            value={value}
            placeholder={placeholder}
            handleChange={handleChange}
          />
          <small className="validate-message">{handleValidationMessage(validationMessage)}</small>
        </div>
      </td>
    </tr>
  );
};

export default CommonTextInput;
