import RadioInput from 'interfaces/components/common/Input/RadioInput';
import handleValidationMessage from 'shared/utils/helper/handleValidationMessage';

const GenderInput = ({
  userGender,
  handleUserInfoChange,
  validationMessage,
}) => {
  const genderList = [
    { id: 1, label: '男性', value: 'MAN' },
    { id: 2, label: '女性', value: 'WOMAN' },
  ];

  return (
    <tr>
      <th>性別</th>
      <td>
        <div className="flex">
          {genderList.map((gender) => (
            <RadioInput
              key={gender.id}
              item={gender}
              label={gender.label}
              value={userGender}
              handleClick={() => handleUserInfoChange(gender.value)}
            />
          ))}
        </div>
        <small className="validate-message">
          {handleValidationMessage(validationMessage)}
        </small>
        <small>
          反社会的勢力の排除のため、正確な情報を必要としております。性別は戸籍上の性別をご入力ください。
        </small>
      </td>
    </tr>
  );
};

export default GenderInput;
