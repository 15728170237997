import { PUBLIC_SALE } from 'domain/Property/entities/constants/property';

import ReactMarkdown from 'react-markdown';

const createCautionString = (governmentName, procedureType) => {
  return procedureType === PUBLIC_SALE
    ? `${governmentName}インターネット公売をご利用いただくには、以下のガイドラインをよくお読みいただき、同意していただくことが必要です。`
    : `${governmentName}公有財産売却（以下「公有財産売却」といいます）をご利用いただくには、以下の「誓約書」および「${governmentName}インターネット公有財産売却ガイドライン（以下「本ガイドライン」をよくお読みいただき、同意していただくことが必要です。`;
};

const createGuidelineTitle = (governmentName, procedureType) => {
  return procedureType === PUBLIC_SALE
    ? `${governmentName}インターネット公売`
    : `${governmentName}公有財産売却`;
};

const createGuidelineDescription = (governmentName, procedureType) => {
  return procedureType === PUBLIC_SALE
    ? `${governmentName}インターネット公売をご利用いただくには、以下の${governmentName}インターネット公売ガイドライン（以下「本ガイドライン」といいます）」をよくお読みいただき、確認、同意していただくことが必要です。また、インターネット公売の手続きなどに関して、本ガイドラインとKSI官公庁オークションに関連する規約・ガイドラインなどとの間に差異がある場合は、本ガイドラインが優先して適用されます。`
    : `${governmentName}インターネット公有財産売却（以下「公有財産売却」といいます）をご利用いただくには、以下の「誓約書」および「${governmentName}インターネット公有財産売却ガイドライン（以下「本ガイドライン」といいます）」をよくお読みいただき、同意していただくことが必要です。また、公有財産売却の手続きなどに関して、本ガイドラインとKSI官公庁オークションに関連する規約・ガイドラインなどとの間に差異がある場合は、本ガイドラインが優先して適用されます。`;
};

const createPersonalInfoTitle = (procedureType) => {
  return procedureType === PUBLIC_SALE
    ? 'インターネット公売における個人情報について'
    : 'インターネット公有財産売却における個人情報について';
};

const createPersonalInfoBody = (procedureType) => {
  return procedureType === PUBLIC_SALE
    ? 'インターネット公売'
    : 'インターネット公有財産売却';
};

const createDepositByCreditCardTitle = (procedureType) => {
  return procedureType === PUBLIC_SALE
    ? 'クレジットカードで公売保証金を納付する場合'
    : 'クレジットカードで入札保証金を納付する場合';
};

const createDepositByCreditCardBody = (procedureType) => {
  return procedureType === PUBLIC_SALE
    ? 'クレジットカードにより公売保証金を納付する公売参加者およびその代理人（以下、「公売参加者など」という）は、紀尾井町戦略研究所株式会社に対し、クレジットカードによる公売保証金納付および返還事務に関する代理権を付与し、クレジットカードによる請求処理をSBペイメントサービス株式会社に委託することを承諾します。公売参加者などは、インターネット公売が終了し、公売保証金の返還が終了するまでこの承諾を取り消せないことに同意するものとします。 また、公売参加者などは、紀尾井町戦略研究所株式会社が公売保証金取り扱い事務に必要な範囲で、公売参加者などの個人情報をSBペイメントサービス株式会社に開示することに同意するものとします。'
    : 'クレジットカードにより入札保証金を納付する参加者およびその代理人（以下、「参加者など」という）は、紀尾井町戦略研究所株式会社に対し、クレジットカードによる入札保証金納付および返還事務に関する代理権を付与し、クレジットカードによる請求処理をSBペイメントサービス株式会社に委託することを承諾します。参加者などは、公有財産売却手続きが終了し、入札保証金の返還が終了するまでこの承諾を取り消せないことに同意するものとします。 また、参加者などは、紀尾井町戦略研究所株式会社が入札保証金取り扱い事務に必要な範囲で、参加者などの個人情報をSBペイメントサービス株式会社に開示することに同意するものとします。';
};

const GuidelineBody = ({ governmentName, procedureType, data }) => {
  return (
    <div className="card-text">
      <div className="government-dialog-guideline">
        <div className="caution-block">
          {createCautionString(governmentName, procedureType)}
        </div>
        <div className="mb-10">
          <div className="article-default-title">
            <h3>
              {createGuidelineTitle(governmentName, procedureType)} ガイドライン
            </h3>
          </div>
          <div className="article-default-body rounded px-6 py-8 mb-8">
            <div className="tui-editor-contents">
              <p>{createGuidelineDescription(governmentName, procedureType)}</p>
            </div>
          </div>
        </div>
        {data &&
          data.map((data, index) => (
            <section key={index} className="article-default mb-10">
              <div className="article-default-title">
                <h3>{Number(data.itemNo) === 0 ? '誓約書' : data.title}</h3>
              </div>
              <div className="article-default-body rounded px-6 py-8 mb-8">
                <div className="tui-editor-contents">
                  <ReactMarkdown>{data.body}</ReactMarkdown>
                </div>
              </div>
            </section>
          ))}
        <div className="article-default mb-10">
          <div className="article-default-title">
            <h3>{createPersonalInfoTitle(procedureType)}</h3>
          </div>
          <div className="article-default-body rounded px-6 py-8 mb-8">
            <div className="tui-editor-contents">
              <p>
                行政機関が紀尾井町戦略研究所株式会社の提供する官公庁オークションシステムを利用して行う
                {createPersonalInfoBody(procedureType)}
                における個人情報の収集主体は行政機関になります。
              </p>
            </div>
          </div>
        </div>
        <div className="article-default mb-10">
          <div className="article-default-title">
            <h3>{createDepositByCreditCardTitle(procedureType)}</h3>
          </div>
          <div className="article-default-body rounded px-6 py-8 mb-8">
            <div className="tui-editor-contents">
              <p>{createDepositByCreditCardBody(procedureType)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidelineBody;
