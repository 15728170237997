
export class BiddedUser {
    #biddedUser

    constructor(biddedUser) {
        this.#biddedUser = biddedUser;
    }

    get() {
        return this.#biddedUser
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
