import { DATA_EMPTY } from 'domain/Property/entities/constants/property.js';

const handleDataEmpty = (value) => {
  if (!value || value === DATA_EMPTY) {
    return false;
  }
  return value;
};

export default handleDataEmpty;
