import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class CorpName {
  #corpName

  constructor(corpName) {
    this.#corpName = corpName;
  }

  get() {
    return this.#corpName
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#corpName, 0, 128)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#corpName)
  }
}
