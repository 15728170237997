
export class PropertyIdList {
    #propertyIdList

    constructor(propertyIdList) {
        this.#propertyIdList = propertyIdList;
    }

    get() {
        return this.#propertyIdList
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
