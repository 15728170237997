import Button from 'interfaces/components/common/Button/Button.jsx';

import PointToNote from './PointToNote.jsx';

import 'interfaces/css/Shipping/points-to-note-agreement.css';

import styled from 'styled-components';

const CautionBlock = styled.div`
  border: 3px solid #ff0000;
  background-color: #ffffff;
  padding: 10px;
  margin: 5px;
`;

const PointsToNoteAgreement = ({ previousStep, nextStep }) => {
  return (
    <div>
      <CautionBlock>
        運送業者に運送させることにより引き渡しを受ける場合は、以下の留意事項をよくお読みいただき、同意していただくことが必要です。
      </CautionBlock>
      <PointToNote />
      <div className="flex justify-center mb-14">
        <Button
          text="留意事項の内容に同意して次へ"
          className="agreement-points-to-note-button"
          height="52px"
          onClick={nextStep}
        />
        <Button
          text="キャンセル"
          className="cancel-common-button"
          height="52px"
          onClick={previousStep}
        />
      </div>
    </div>
  );
};

export default PointsToNoteAgreement;
