import React from 'react';
import { bidEndToDeadLine } from 'shared/utils/helper/calc_payment_dead_line.js';
import { dateFormat } from 'shared/utils/helper/date_format';

//import "interfaces/css/AuctionSchedule/schedule-table.css";
import styled from 'styled-components';

const TableWrap = styled.div`
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 100%;
  overflow: hidden;
`;

const TableScroll = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  border-spacing: 0;
  border: 1px solid #ccc;
`;

const Th = styled.th`
  background-color: #fbe2ca;
  border: 1px solid #ccc !important;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem!important;
  font-weight: bold;
  height: 32px;
  padding: 0 16px;
  user-select: none;
  white-space: nowrap;
`;

const Td = styled.td`
  border: 1px solid #ccc !important;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.75rem!important;
  font-weight: bold;
  height: 32px;
  padding: 0 16px;
  user-select: none;
  white-space: nowrap;
`;

const NumberWidth = styled.span`
  display: block;
  width: 16px;
`;

const NameWidth = styled.span`
  display: block;
  width: 200px;
`;

const DateWidth = styled.span`
  display: block;
  width: 166px;
`;

const ScheduleTable = ({ tableData, procedureType = 'PUBLIC_SALE' }) => {
  const convertDate = (date) => {
    let timestamp = Date.parse(date);
    let targetDate = new Date(timestamp);
    return dateFormat(targetDate, `YYYY-MM-DD-DAYOFTHEWEEK-hh-mm`);
  };

  return (
    <TableWrap>
      {/*
                <div className={scheduleTable.table_wrap} data-testid="schedule-table">
            */}
      <TableScroll>
        <Table>
          <thead data-testid="schedule-table-header">
            <tr>
              <Th rowSpan="2"></Th>
              <Th rowSpan="2">形式</Th>
              <Th rowSpan="2">名前</Th>
              <Th rowSpan="2">
                ご案内メール
                <br />
                送信予定日
              </Th>
              <Th rowSpan="2">
                行政機関様
                <br />
                申込締切
              </Th>
              <Th colSpan="2">参加申込期間</Th>
              <Th colSpan="2">入札期間</Th>
              {procedureType === 'PUBLIC_SALE' ? (
                <Th rowSpan="2">最高価申込者決定日</Th>
              ) : (
                ''
              )}
              <Th rowSpan="2">入札確定日時</Th>
              <Th rowSpan="2">掲載終了日</Th>
              <Th rowSpan="2">
                代金納付期限
                <br />
                (動産・自動車のみ)
              </Th>
              {procedureType === 'PUBLIC_SALE' ? (
                <Th rowSpan="2">
                  次順位代金納付期限
                  <br />
                  (動産・自動車のみ)
                </Th>
              ) : (
                ''
              )}
            </tr>
            <tr>
              <Th>参加申込開始</Th>
              <Th>参加申込締切</Th>
              <Th>入札開始</Th>
              <Th>入札締切</Th>
            </tr>
          </thead>
          <tbody data-testid="schedule-table-body">
            {tableData &&
              tableData.map((d, index) =>
                d.publicSaleType === 'BOTH' ? (
                  <React.Fragment key={d.id}>
                    <tr data-testid="schedule-table-body-row">
                      <Td rowSpan="2">
                        <NumberWidth>{index + 1}</NumberWidth>
                      </Td>
                      <Td>せり売</Td>
                      <Td rowSpan="2">
                        <NameWidth>{d.name}</NameWidth>
                      </Td>
                      <Td rowSpan="2">
                        <DateWidth>{convertDate(d.govAplStart)}</DateWidth>
                      </Td>
                      <Td rowSpan="2">
                        <DateWidth>{convertDate(d.govAplEnd)}</DateWidth>
                      </Td>
                      <Td rowSpan="2">
                        <DateWidth>{convertDate(d.aplStart)}</DateWidth>
                      </Td>
                      <Td rowSpan="2">
                        <DateWidth>{convertDate(d.aplEnd)}</DateWidth>
                      </Td>
                      <Td rowSpan="2">
                        <DateWidth>{convertDate(d.bidStart)}</DateWidth>
                      </Td>
                      <Td>
                        <DateWidth>{convertDate(d.auctionEnd)}</DateWidth>
                      </Td>
                      {procedureType === 'PUBLIC_SALE' ? (
                        <Td>
                          <DateWidth>{convertDate(d.auctionEnd)}</DateWidth>
                        </Td>
                      ) : (
                        ''
                      )}
                      <Td rowSpan="2">
                        <DateWidth>{convertDate(d.bidDecision)}</DateWidth>
                      </Td>
                      <Td rowSpan="2">
                        <DateWidth>{convertDate(d.openEnd)}</DateWidth>
                      </Td>
                      <Td>
                        <DateWidth>{bidEndToDeadLine(convertDate(d.auctionEnd))}</DateWidth>
                      </Td>
                      {procedureType === 'PUBLIC_SALE' ? (
                        <Td>
                          <DateWidth>
                            {d.nextPaymentLimit.includes('1000')
                              ? 'なし'
                              : convertDate(d.nextPaymentLimit)}
                          </DateWidth>
                        </Td>
                      ) : (
                        ''
                      )}
                    </tr>
                    <tr data-testid="schedule-table-body-row">
                      <Td>
                        <DateWidth>入札</DateWidth>
                      </Td>
                      <Td>
                        <DateWidth>
                          {d.bidEnd.includes('1000')
                            ? 'なし'
                            : convertDate(d.bidEnd)}
                        </DateWidth>
                      </Td>
                      {procedureType === 'PUBLIC_SALE' ? (
                        <Td>
                          <DateWidth>
                            {d.bidEnd.includes('1000')
                              ? 'なし'
                              : convertDate(d.bidEnd)}
                          </DateWidth>
                        </Td>
                      ) : (
                        ''
                      )}
                      <Td>
                        <DateWidth>{bidEndToDeadLine(d.bidEnd)}</DateWidth>
                      </Td>
                      {procedureType === 'PUBLIC_SALE' ? (
                        <Td>
                          <DateWidth>
                            {d.nextPaymentLimit.includes('1000')
                              ? 'なし'
                              : convertDate(d.nextPaymentLimit)}
                          </DateWidth>
                        </Td>
                      ) : (
                        ''
                      )}
                    </tr>
                  </React.Fragment>
                ) : (
                  <tr key={d.id} data-testid="schedule-table-body-row">
                    <Td>
                      <NumberWidth>{index + 1}</NumberWidth>
                    </Td>
                    <Td>
                      {d.publicSaleType === 'AUCTION' ? 'せり売' : '入札'}
                    </Td>
                    <Td>
                      <NameWidth>{d.name}</NameWidth>
                    </Td>
                    <Td>
                      <DateWidth>{convertDate(d.govAplStart)}</DateWidth>
                    </Td>
                    <Td>
                      <DateWidth>{convertDate(d.govAplEnd)}</DateWidth>
                    </Td>
                    <Td>
                      <DateWidth>{convertDate(d.aplStart)}</DateWidth>
                    </Td>
                    <Td>
                      <DateWidth>{convertDate(d.aplEnd)}</DateWidth>
                    </Td>
                    <Td>
                      <DateWidth>{convertDate(d.bidStart)}</DateWidth>
                    </Td>
                    <Td>
                      <DateWidth>{convertDate(d.auctionEnd || d.bidEnd)}</DateWidth>
                    </Td>
                    {procedureType === 'PUBLIC_SALE' ? (
                      <Td>
                        <DateWidth>{convertDate(d.auctionEnd || d.bidEnd)}</DateWidth>
                      </Td>
                    ) : (
                      ''
                    )}
                    <Td>
                      <DateWidth>{convertDate(d.bidDecision)}</DateWidth>
                    </Td>
                    <Td>
                      <DateWidth>{convertDate(d.openEnd)}</DateWidth>
                    </Td>
                    <Td>
                      <DateWidth>{bidEndToDeadLine(convertDate(d.auctionEnd))}</DateWidth>
                    </Td>
                    {procedureType === 'PUBLIC_SALE' ? (
                      <Td>
                        <DateWidth>
                          {d.nextPaymentLimit.includes('1000')
                            ? 'なし'
                            : convertDate(d.nextPaymentLimit)}
                        </DateWidth>
                      </Td>
                    ) : (
                      ''
                    )}
                  </tr>
                ),
              )}
          </tbody>
        </Table>
      </TableScroll>
    </TableWrap>
  );
};

export default ScheduleTable;
