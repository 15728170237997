
export class ItemNo {
  #itemNo

  constructor(itemNo) {
    this.#itemNo = itemNo;
  }

  get() {
    return this.#itemNo
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
