import "interfaces/css/common/management-data-list.css"

const ManagementDataList = ({ data }) => {
  return (
    <div data-testid="kmanager-management-data-list" className='management-data-list mt-1' style={{ marginTop: '4px' }}>
      {
        data?.map((column, index) =>
          <div key={index} className='kmanager-row management-data-list-column' data-testid='kmanager-row'>
            {
              column.map((cell, index) =>
                // TODO: cell.valueが数字かどうか判定する必要あり
                index % 2 !== 0 ?
                  <div
                    key={index}
                    className={
                      `management-data-list-item ${cell.colSize ? `kmanager-col kmanager-col-${cell.colSize}` : 'kmanager-col'} ${cell.colMdSize ? `kmanager-col-md-${cell.colMdSize}` : ''} text-right`
                    }
                    data-testid='kamanger-col'
                  >
                    {cell.value?.toLocaleString()}{cell.isYen && '円'}
                  </div> :
                  <div
                    key={index}
                    className={
                      `management-data-list-item ${cell.colSize ? `kmanager-col kmanager-col-${cell.colSize}` : 'kmanager-col'} ${cell.colMdSize ? `kmanager-col-md-${cell.colMdSize}` : ''}`
                    }
                    data-testid='kamanger-col'
                  >
                    {cell.value}
                  </div>
              )
            }
          </div>
        )
      }
      
      {/* ここからモバイルのテーブル
      <div className="kmanager-mobile">
        <div className="kmanager-row management-data-list-column">
          <div className="management-data-list-item-wrap">
            <div className="management-data-list-item kmanager-col kmanager-col-3">
              システム利用料合計(せり)
            </div>
            <div className="management-data-list-item kmanager-col kmanager-col-3 text-right">
              181,600
            </div>
          </div>
          <div className="management-data-list-item-wrap">
            <div className="management-data-list-item kmanager-col kmanager-col-3">
              消費税合計
            </div>
            <div className="management-data-list-item kmanager-col kmanager-col-3 text-right">
              18,160
            </div>
          </div>
          <div className="management-data-list-item-wrap">
            <div className="management-data-list-item kmanager-col kmanager-col-3">
              システム利用料合計(入札)
            </div>
            <div className="management-data-list-item kmanager-col kmanager-col-3 text-right">
              0
            </div>
          </div>
          <div className="management-data-list-item-wrap">
            <div className="management-data-list-item kmanager-col kmanager-col-3">
              請求額合計
            </div>
            <div className="management-data-list-item kmanager-col kmanager-col-3 text-right">
              199,760
            </div>
          </div>
        </div>
      </div>
      */}
      
    </div>
  );
}
export default ManagementDataList;