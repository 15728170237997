// ロジックを含む処理
// フロントから呼ぶ関数

import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { getBidderList } from '../repositories/BidderList.js';

export async function get(type) {
  return await getBidderList(type);
}

export async function findByPropertyId(type, propertyId) {
  const list = await getBidderList(type);
  return list.filter(
    (data) => data.propertyId.toString() === propertyId.toString(),
  );
}

export async function findByPropertyIds(type, propertyIds) {
  const list = await getBidderList(type);
  return list.filter((data) =>
    propertyIds.includes(data.propertyId.toString()),
  );
}

//export async function create(type, data) {
export async function create(type) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create List', response);
  }
  return response;
}

//export async function update(type, data) {
export async function update(type) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update List', response);
  }
  return response;
}

//export async function destroy(type, data) {
export async function destroy(type) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete List', response);
  }
  return response;
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
