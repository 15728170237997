import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel.jsx';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault.jsx';

import CreditCardLimitInputs from './CreditCardLimitInputs.jsx';
import CreditCardNumInputs from './CreditCardNumInputs.jsx';
import CreditCardSecurityCodeInput from './CreditCardSecurityCodeInput.jsx';
//import { useApplicationInfo } from '../../hooks/useApplicationInfo.js';

const CreditCardInfoFormBody = ({ creditCardInfo, handleChange }) => {
  //const { applicationInfo } = useApplicationInfo();
  //const applicantInfo = applicationInfo.applicantInfo;
  const applicantInfo = {};

  return (
    <ArticleDefaultBody>
      <CommonTableDefault>
        <tr data-testid="credit-card-info-fullname">
          <th data-testid="credit-card-info-fullname-label">
            氏名
            <RequiredLabel />
          </th>
          <td data-testid="credit-card-info-fullname-value">
            {`${applicantInfo.familyName} ${applicantInfo.firstName}`}
          </td>
        </tr>
        <CreditCardNumInputs
          creditCardInfo={creditCardInfo}
          handleChange={handleChange}
        />
        <CreditCardLimitInputs
          creditCardInfo={creditCardInfo}
          handleChange={handleChange}
        />
        <CreditCardSecurityCodeInput
          creditCardInfo={creditCardInfo}
          handleChange={handleChange}
        />
      </CommonTableDefault>
    </ArticleDefaultBody>
  );
};

export default CreditCardInfoFormBody;
