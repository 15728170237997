import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from './Logo.jsx';
import MenuLinkList from './MenuLinkList.jsx';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import { ReactComponent as MenuDownIcon } from '../../../assets/svg/menu-down.svg';
import { useGovernment } from '../../Government/navi/hook/useGovernment';
import LightButton from '../Button/LightButton.jsx';
import PrimaryButton from '../Button/PrimaryButton.jsx';
import TransparentButton from '../Button/TransparentButton.jsx';
import Heading1 from '../Heading/Heading1.jsx';

import 'interfaces/css/common/header.css';

const NaviHeader = () => {
  const [showMenuLinkList, setShowMenuLinkList] = useState(false);
  const navigate = useNavigate();
  const { localStorageProcedureType, localStorageGovernmentType } =
    useLocalStorage();
  const { loading } = useGovernment();

  const handleClickNewEntry = () => {
    navigate('/navi/new-entry');
  };

  const handleClickAuctionSchedule = () => {
    navigate('/navi/auction-schedule');
  };

  const handleClickMenuButton = () => {
    setShowMenuLinkList(!showMenuLinkList);
  };

  const handleGlobalClick = () => {
    setShowMenuLinkList(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleGlobalClick);
    return () => {
      document.removeEventListener('click', handleGlobalClick);
    };
  }, []);

  return (
    <span className={'navi-wrap'}>
      <span className={'navi'}>
        <header className="header" data-testid="header">
          <div className="header-container">
            <Logo type={'NAVI'} />
            <nav>
              <ul className="header-menu">
                <li className="header-menu-item">
                  {!loading &&
                  localStorageGovernmentType !== 'NATIONAL_TAX_AGENCY' ? (
                    <PrimaryButton onClick={handleClickNewEntry}>
                      {localStorageProcedureType === 'PUBLIC_SALE'
                        ? 'インターネット公売'
                        : '公有財産売却'}
                      参加申込
                    </PrimaryButton>
                  ) : (
                    <></>
                  )}
                </li>
                <li className="header-menu-item">
                  {!loading &&
                  localStorageGovernmentType !== 'NATIONAL_TAX_AGENCY' ? (
                    <LightButton onClick={handleClickAuctionSchedule}>
                      開催スケジュール
                    </LightButton>
                  ) : (
                    <></>
                  )}
                </li>
                <li className="header-menu-item w-150">
                  <div style={{ position: 'relative' }}>
                    <TransparentButton
                      icon={<MenuDownIcon />}
                      text="メニュー"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickMenuButton();
                      }}
                    />
                    {showMenuLinkList && <MenuLinkList />}
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <Heading1>官公庁ナビ</Heading1>
        </header>
      </span>
    </span>
  );
};

export default NaviHeader;
