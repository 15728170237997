import Heading3 from 'interfaces/components/common/Heading/Heading3';
import AnchorButton from 'interfaces/components/common/AnchorButton';
import ImportantCaution from 'interfaces/components/common/ImportantCaution';
const TemplateGuidelineAuction = ({ openModal }) => {
  return (
    <div>
      <div data-testid="template-guideline-auction1" className="pt-6">
        <Heading3
          text="●●●インターネット公有財産売却 ガイドライン"
          isBgGray={true}
        />
        <ImportantCaution
          text={
            '●●●インターネット公有財産売却（以下「公有財産売却」といいます）をご利用いただくには、以下の「誓約書」および「●●●インターネット公有財産売却ガイドライン（以下「本ガイドライン」といいます）」をよくお読みいただき、同意していただくことが必要です。また、公有財産売却の手続きなどに関して、本ガイドラインとKSI官公庁オークションに関連する規約・ガイドラインなどとの間に差異がある場合は、本ガイドラインが優先して適用されます。'
          }
        />
        <Heading3
          text="第1 インターネット公売の参加条件など"
          isLeftBorderStyle={true}
          hasCopyButton={true}
          clickHandler={() => openModal('templateGuidelineAuction1')}
        />
      </div>
      <div data-testid="template-guideline-auction1_1" className="pt-4">
        <div className="template-guideline-section-title">
          1. インターネット公売の参加条件
        </div>
        <p className="template-guideline-body pt-2">
          （以下のいずれかに該当する方は、公売へ参加することおよび財産を買い受けることができません。また、（1）から（4）に該当する方は、代理人を通じて参加することもできません）
          <br />
          （1）国税徴収法第92条（買受人の制限）または同法第108条第1項（公売実施の適正化のための措置）に該当する方。
          <br />
          （2）●●●が定める本ガイドラインおよびKSI官公庁オークションに関連する規約・ガイドラインの内容を承諾せず、順守できない方。
          <br />
          （3）公売財産の買受について一定の資格、その他の条件を必要とする場合でこれらの資格などを有していない方
          <br />
          （4）暴力団員による不当な行為の防止等に関する法律に規定する暴力団員等に該当する方
          <br />
          ＊暴力団員等とは、「暴力団員による不当な行為の防止等に関する法律（平成３年法律第77号）第２条第６号に規定する暴力団員又は暴力団員でなくなった日から５年を経過しない者」を指します。
          <br />
          （5）18歳未満の方。ただし、その親権者などが代理人として参加する場合を除きます。
          <br />
          （6）日本語を完全に理解できない方。ただし、その代理人が日本語を理解できる場合は除きます。
          <br />
          （7）日本国内に住所、連絡先がいずれもない方。ただし、その代理人が日本国内に住所または連絡先がある場合を除きます。
        </p>
      </div>
      <div data-testid="template-guideline-auction1_2" className="pt-6">
        <div className="template-guideline-section-title">
          2. インターネット公売参加にあたっての注意事項
        </div>
        <p className="template-guideline-body pt-2">
          （1）インターネット公売は、国税徴収法などの規定にのっとって●●●が執行する公売手続きの一部です。KSI官公庁オークションに関連する規約・ガイドラインについては、本ガイドラインおよび国税徴収法の規定に反しない限り、インターネット公売の手続きにおいて公売参加者またはその代理人（以下、「公売参加者など」といいます）を拘束するものとします。
          <br />
          （2）公売参加者などが国税徴収法第108条第1項に掲げる行為をしたとき、執行機関は同条に基づき、入札をなかったものとするなどの処分を行うことがあります。当該処分を受けた公売参加者などは、以後2年間、当該執行機関の実施する公売に参加することまたは代理人となることができません。また、処分を受けた公売参加者などの納付した公売保証金があるときは、その公売保証金は没収し、返還しません。
          <br />
          なお、以下は第108条第1項に掲げる行為に該当します。
          <br />
          ア．売却決定を受けても買受代金の納付期限までにその代金を故意に納付しない行為。
          <br />
          イ．偽りの名義によりまたは第三者をかたって公売に参加する行為。
          <br />
          ウ．公売を妨害する意思をもって行う、第1の7「代理人などによる自己のための公売参加手続きの禁止」において禁止する行為。
          <br />
          エ．公売を妨害する意思をもって行う、第6の3「システム利用における禁止事項」に掲げる行為。
          <br />
          （3）入札に先立って公売保証金を納付してください。
          <br />
          （4）公売参加者などは、あらかじめインターネット公売システム（以下「公売システム」といいます）上の公売物件詳細画面や執行機関において閲覧に供されている公売公告などを確認し、登記・登録制度のある財産については、関係公簿などを閲覧するほか、十分な調査を行ったうえで公売に参加してください。また、●●●が下見会を実施する財産については、下見会で財産を確認してください。なお、公売財産が不動産の場合、内覧会などは行いませんので、現地確認などはご自身で行ってください。現地確認などの際には、公売財産の所有者、占有者などの権利を侵害してはならないことに留意してください。
          <br />
          （5）インターネット公売は、紀尾井町戦略研究所株式会社の提供する公売システムを採用しています。公売参加者などは、公売システムの画面上で公売参加申し込みなど一連の手続きを行ってください。
          <br />
          （6）インターネット公売においては、特定の売却区分（公売財産の出品区分）の公売が中止になること、もしくは公売全体が中止になることがあります。
        </p>
      </div>
      <div data-testid="template-guideline-auction1_3" className="pt-6">
        <div className="template-guideline-section-title">
          3. 公売財産の権利移転などについての注意事項
        </div>
        <p className="template-guideline-body pt-2">
          （1）公売財産は●●税滞納者などの財産であり、●●●の所有する財産ではありません。
          <br />
          （2）公売財産に財産の種類又は品質に関する不適合があっても、現所有者および●●●には担保責任は生じません。
          <br />
          （3）売却決定を受けた最高価申込者または次順位買受申込者（以下、「買受人」といいます）ならびにその代理人（以下、「買受人など」といいます）が公売財産にかかる買受代金の全額を納付したとき（農地など一定の要件が満たされなければ権利移転の効力が生じない財産については、当該要件が満たされ、権利が移転したとき）、買受人に危険負担が移転します。その後に発生した財産の破損、盗難および焼失などによる損害の負担は、買受人が負うこととなります。
          <br />
          （4）公売財産が登記・登録を要する財産の場合、執行機関は、買受代金を納付した買受人などの請求により、権利移転の登記・登録を関係機関に嘱託します。
          <br />
          （5）公売財産が動産、自動車などである場合、執行機関はその公売財産の引渡を買受代金納付時の現況有姿で行います。
          <br />
          （6）公売財産が不動産の場合、執行機関は引渡の義務を負いません。公売財産内の動産類やゴミなどの撤去、占有者の立退き、前所有者からの鍵などの引渡などは、すべて買受人自身で行ってください。また、隣地との境界確定は、買受人と隣地所有者との間で行ってください。●●●は関与いたしません。
          <br />
          （7）買受人は、買受人に対抗することができる公売財産上の負担（マンションの未納管理費など）を引き受けなければなりません。
          <br />
          （8）買受人は、買受代金の納付後に公売財産の返品および買受代金の返還を求めることができません。
        </p>
      </div>
      <div data-testid="template-guideline-auction1_4" className="pt-6">
        <div className="template-guideline-section-title">
          4. 個人情報の取り扱いについて
        </div>
        <p className="template-guideline-body pt-2">
          （1）公売参加者などは、以下のすべてに同意するものとします。
          <br />
          ア．公売参加申し込みを行う際に、住民登録などのされている住所、氏名（法人の場合は、商業登記簿などに登記されている所在地、名称、法人代表者氏名）および電話番号を公売参加者情報として登録すること。
          <br />
          イ．公売参加者などの公売参加者情報およびKSI官公庁オークションのログインID（以下、「ログインID」といいます）に登録されているメールアドレスを●●●に開示されること。
          <br />
          ・●●●は、公売参加者などに対し、ログインIDで認証済みのメールアドレスに、公売財産に関するお知らせなどを電子メールにて送信することがあります。
          <br />
          ウ．最高価申込者または次順位買受申込者に決定された公売参加者のログインIDに紐づく会員識別番号（代理人による参加の場合は代理人のログインIDに紐づく会員識別番号、共同入札の場合は代表者のログインIDに紐づく会員識別番号）を公売システム上において一定期間公開されること。
          <br />
          （2）●●●は、公売参加者などから直接または●●●が公売システムで収集した個人情報を、●●●文書管理規則に基づき、5年間保管します。●●●は、収集した個人情報を国税徴収法第106条の2に定める調査の嘱託、第108条に定める公売実施の適正化のための措置などを行うことを目的として利用します。
          <br />
          （3）公売財産が登記・登録を要する財産の場合、公売参加者情報の登録内容が住民登録や商業登記簿の内容などと異なる場合（転居などにより異なる場合で、住所証明書によりその経緯などが確認できる場合を除きます）は、買受人となっても所有権移転などの権利移転登記・登録を行うことができません。
        </p>
      </div>
      <div data-testid="template-guideline-auction1_5" className="pt-6">
        <div className="template-guideline-section-title">
          5. 代理人による参加について
        </div>
        <p className="template-guideline-body pt-2">
          インターネット公売では、代理人に公売参加の手続きをさせることができます。代理人には、少なくとも公売参加申し込み、公売保証金の納付および返還にかかる受領、入札並びにこれらに附帯する事務を委任することとします。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （1）代理人の資格
        </p>
        <p className="template-guideline-body">
          代理人は、「第1
          1.インターネット公売の参加条件」を満たさなければなりません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）代理人による参加の手続き
        </p>
        <p className="template-guideline-body">
          ア．代理人に公売参加の手続きをさせる場合、代理人のログインIDにより、代理人が公売参加申し込みおよび入札などを行ってください。
          <br />
          イ．代理人に公売参加の手続きをさせる場合、公売参加者は、委任状および公売参加者の住所証明書（公売参加者が法人の場合は商業登記簿謄本など）を入札開始2開庁日前までに●●●に提出することが必要です。委任状は、●●●ホームページより印刷することができます。
          原則として、入札開始2開庁日前までに●●●が委任状などの提出を確認できない場合、入札をすることができません。公売参加者以外の方から委任状などが提出された場合も、入札をすることができません。
          <br />
          ウ．代理人による公売参加申し込み手続きおよび入札手続きの詳細については、「第2
          公売参加申し込みについて」、「第3
          せり売形式で行うインターネット公売手続き」および「第4
          入札形式で行うインターネット公売手続き」をご覧ください。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）復代理人の選任の権限
        </p>
        <p className="template-guideline-body">
          任意代理人を選任した場合、公売参加者はその代理人に復代理人を選任する権限を付与したものとみなします。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （4）代理人による参加における注意事項
        </p>
        <p className="template-guideline-body">
          ア．代理人に国税徴収法第108条第1項に該当すると認められる事実がある場合、公売参加者およびその代理人は同法第108条第1項に該当し、以後2年間当該執行機関の実施する公売に参加できません。
          <br />
          イ．国税徴収法第108条第1項に該当すると認められる事実があった後2年を経過しない者を代理人とした方は、同法第108条第1項に該当し、以後2年間当該執行機関の実施する公売に参加できません。
          <br />
          ウ．アおよびイの場合、納付された公売保証金は没収し、返還しません。
        </p>
      </div>
      <div data-testid="template-guideline-auction1_6" className="pt-6">
        <div className="template-guideline-section-title">
          6. 共同入札について
        </div>
        <p className="template-guideline-body pt-2">
          公売財産が不動産の場合、共同入札することができます。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （1）共同入札とは
        </p>
        <p className="template-guideline-body">
          一つの財産を複数の方で共有する目的で入札することを共同入札といいます。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）共同入札における注意事項
        </p>
        <p className="template-guideline-body">
          ア．共同入札する場合は、共同入札者のなかから1名の代表者を決める必要があります。実際の公売参加申し込み手続きおよび入札手続きをすることができるのは、当該代表者のみです。したがって、公売参加申し込みおよび入札などは、代表者のログインIDで行うこととなります。手続きの詳細については、「第2
          公売参加申し込みについて」および「第4
          入札形式で行うインターネット公売手続き」をご覧ください。
          <br />
          イ．共同入札する場合は、代表者以外の方全員から代表者に対する委任状、共同入札者全員の住所証明書（共同入札者が法人の場合は商業登記簿謄本など）および共同入札者全員の住所（所在地）と氏名（名称）を記入し、各共同入札者の持分を記載した「共同入札者持分内訳書」を入札開始2開庁日前までに●●●に提出することが必要です。原則として、入札開始2開庁日前までに●●●が提出を確認できない場合、入札をすることができません。なお、委任状および「共同入札者持分内訳書」は●●●ホームページより印刷することができます。
          <br />
          ウ．委任状および「共同入札者持分内訳書」に記載された内容が共同入札者の住民登録や商業登記簿の内容などと異なる場合（転居などにより異なる場合で、住所証明書によりその経緯などが確認できる場合を除きます）は、共同入札者が買受人となっても所有権移転などの権利移転登記を行うことができません。
        </p>
      </div>
      <div data-testid="template-guideline-auction1_7" className="pt-4 pb-6">
        <div className="template-guideline-section-title">
          7. 代理人などによる自己のための公売参加手続きの禁止
        </div>
        <p className="template-guideline-body pt-2">
          （1）代理人および共同入札における代表者（以下、「代理人など」といいます）は、公売参加者、共同入札における代表者を除く共同入札者（以下「本人など」といいます）のために公売参加の手続きをする公売財産について、本人などのために行う公売参加の手続きとは別に、自己のために公売参加の手続きをすることはできません。
          <br />
          （2）代理人などが、一つの公売財産に対し複数の本人などから公売参加の手続きなどについて委任を受けた場合は、その委任を受けたすべての公売参加の手続きをすることができません。
          <br />
          （3）本人などは、代理人などに公売参加の手続きを委任した公売財産について、代理人などが行う買受申込みとは別に、自己のために公売参加の手続きまたはほかの代理人などに委任して公売参加の手続きを行うことはできません。
          なお、ほかの方と共同して、別に公売参加の手続きを行うこともできません。
          <br />
          （4）法人が公売に参加する場合、当該法人の代表権限のある方（以下、「法人代表者」といいます）は、法人のために行う公売参加の手続きとは別に、自己のためまたはほかの本人などの委任を受けて公売参加の手続きをすることはできません。
        </p>
      </div>
      <AnchorButton text="▲ 第1の先頭に戻る" />
      <div data-testid="template-guideline-auction2" className="pt-9">
        <Heading3
          text="第2 公売参加申し込みについて"
          isLeftBorderStyle={true}
          hasCopyButton={true}
          clickHandler={() => openModal('templateGuidelineAuction2')}
        />
        <p
          data-testid="template-guideline-lead-text"
          className="template-guideline-lead-text pt-4">
          入札に先立って、公売参加申し込みを行ってください。公売参加申し込みには、公売参加者など情報の入力、公売保証金の納付および必要に応じて委任状などの書類提出が必要です。公売参加申し込みが完了したログインIDでのみ入札できます。
        </p>
      </div>
      <div data-testid="template-guideline-auction2_1" className="pt-6">
        <div className="template-guideline-section-title">
          1. 公売参加申し込みについて
        </div>
        <p className="template-guideline-body pt-2">
          公売参加者などは、公売公告により定められた公売参加申し込み期間内に、入札しようとする売却区分を指定のうえ、公売システムの画面上で、住民登録などのされている住所、氏名（法人の場合は、商業登記簿などに登記されている所在地、名称、代表者氏名）および電話番号を公売参加者など情報として登録してください。
          <br />
          ・法人が公売に参加する場合は、法人代表者名でログインIDを取得したうえで、法人代表者が公売参加の手続きを行ってください。なお、法人代表者以外の方に公売参加の手続きをさせる場合は、その方を代理人とする必要があります。
          <br />
          ・代理人に公売参加の手続きをさせる場合は、代理人のログインIDにより、代理人が公売参加の手続きを行ってください。代理人は、公売システムの画面上で、代理人による手続きの欄の「する」を選択してください。また、公売参加者は、委任状および公売参加者の住所証明書（公売参加者が法人の場合は商業登記簿謄本など）を入札開始2開庁日前までに●●●に提出することが必要です。原則として、入札開始2開庁日前までに●●●が委任状などの提出を確認できない場合、入札をすることができません。公売参加者以外の方から委任状などが提出された場合も、入札をすることができません。
          <br />
          ・共同入札する場合は、代表者のログインIDにより、代表者が公売参加の手続きを行ってください。代表者は、公売システムの画面上で、共同入札の欄の「する」を選択してください。また、代表者以外の方全員から代表者に対する委任状、共同入札者全員の住所証明書（共同入札者が法人の場合は商業登記簿謄本など）および「共同入札者持分内訳書」を入札開始2開庁日前までに●●●に提出することが必要です。原則として、入札開始2開庁日前までに●●●が提出を確認できない場合、入札をすることができません。
          <br />
          ・公売財産が農地である場合は、農業委員会などの発行する買受適格証明書を入札開始2開庁日前までに●●●に提出することが必要です。原則として、入札開始2開庁日前までに●●●が提出を確認できない場合、入札をすることができません。
        </p>
      </div>
      <div data-testid="template-guideline-auction2_2" className="pt-6">
        <div className="template-guideline-section-title">
          2. 陳述書について（不動産の参加申し込みの場合）
        </div>
        <p className="template-guideline-body pt-2">
          不動産の買受申込みをする場合、買受申込者は、次のいずれにも該当しない旨の陳述書を提出する必要があります（ただし、自己の計算において買受申込みをさせようとする者がいる場合には、陳述書別紙を併せて提出する必要があります。）。
          <br />
          （1）
          買受申込者（その者が法人である場合には、その役員）が、暴力団員（暴力団員による不当な行為の防止等に関する法律（平成３年法律第77号）第２条第６号（定義）に規定する暴力団員をいう。）又は暴力団員でなくなった日から５年を経過していない者（以下「暴力団員等」という。）であること
          <br />
          （2）
          自己の計算において買受申込みをさせようとする者（その者が法人である場合には、その役員）が暴力団員等であること
          <br />
          なお、買受申込者又は自己の計算において買受申込みをさせようとする者が法人である場合には、法人の役員を証する書面（商業登記簿に係る登記事項証明書等）を提出する必要があります。
        </p>
        <p className="template-guideline-body pt-4">
          また、買受申込者又は自己の計算において買受申込みをさせようとする者が宅地建物取引業又は債権回収管理業の事業者である場合には、その許認可等を受けていることを証する書面（宅地建物取引業の免許証等）の写しを併せて提出する必要があります。
        </p>
      </div>
      <div data-testid="template-guideline-auction2_3" className="pt-4 pb-6">
        <div className="template-guideline-section-title">
          3. 公売保証金の納付について
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）公売保証金とは
        </p>
        <p className="template-guideline-body">
          国税徴収法により定められている、入札する前に納付しなければならない金員です。公売保証金は、執行機関が、売却区分ごとに、見積価額（最低入札価格）の100分の10以上の金額を定めます。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）公売保証金の納付方法
        </p>
        <p className="template-guideline-body">
          公売保証金の納付は、売却区分ごとに必要です。公売保証金は、執行機関が売却区分ごとに指定する方法で納付してください。指定する方法は、下記のアのみ、イのみ、アまたはイの3通りです。売却区分ごとに、公売システムの公売物件詳細画面でどの方法が指定されているかを確認してください。
          <br />
          ア．クレジットカードによる納付
          <br />
          クレジットカードで公売保証金を納付する場合は、公売システムの公売物件詳細画面より公売参加申し込みを行い、公売保証金を所定の手続きに従って、クレジットカードにて納付してください。クレジットカードにより公売保証金を納付する公売参加者などは、紀尾井町戦略研究所株式会社に対し、クレジットカードによる公売保証金納付および返還事務に関する代理権を付与し、クレジットカードによる請求処理をSBペイメントサービス株式会社に委託することを承諾します。公売参加者などは、インターネット公売が終了し、公売保証金の返還が終了するまでこの承諾を取り消せないことに同意するものとします。
          <br />
          また、公売参加者などは、紀尾井町戦略研究所株式会社が公売保証金取り扱い事務に必要な範囲で、公売参加者などの個人情報をSBペイメントサービス株式会社に開示することに同意するものとします。
          <br />
          ・VISA、マスターカード、JCB、ダイナース、アメリカン・エキスプレスのマークがついていないクレジットカードなど、ごく一部ご利用いただけないカードがございます。
          <br />
          ・法人で公売に参加する場合、法人代表者名義のクレジットカードをご使用ください。
          <br />
          ・代理人に公売参加の手続きをさせる場合、代理人名義のクレジットカードをご使用ください。
          <br />
          イ．銀行振込などによる納付
          <br />
          銀行振込などで公売保証金を納付する場合は、公売システムの公売物件詳細画面より公売参加仮申し込みを行ってください。その後、●●●ホームページから「公売保証金納付書兼支払請求書兼口座振替依頼書」を印刷し、必要事項を記入・なつ印のうえ、●●●に書留郵便にて送付してください。次に●●●から公売参加仮申し込みを行った公売参加者などに対し、公売参加者などが「公売保証金納付書兼支払請求書兼口座振替依頼書」に記入したメールアドレスに送信する電子メールにて公売保証金納付方法をご案内します。当該電子メールに従って、銀行口座への振込、現金書留（50万円以下の場合のみ）による送付、郵便為替（ゆうちょ銀行が取り扱う普通為替および定額小為替をいう。以下同じ）による納付、または直接持参にて公売保証金を納付してください。
          <br />
          ・銀行口座への振込により公売保証金を納付する場合は、●●●が納付を確認できるまで3開庁日程度要することがあります。
          <br />
          ・原則として、入札開始2開庁日前までに●●●が公売保証金の納付を確認できない場合、入札することができません。
          <br />
          ・現金書留による送付または直接持参により公売保証金を納付する場合、現金もしくは銀行振出の小切手（電子交換所に加入する銀行が振り出したもので、かつ振出日から起算して8日を経過していないものに限る）で●●●に納付してください。
          <br />
          ・郵便為替により公売保証金を納付する場合、郵便為替証書は、発行日から起算して175日を経過していないものに限ります。
          <br />
          ・銀行振込の際の振込手数料や現金書留の郵送料などは公売参加者などの負担となります。
          <br />
          ・代理人に公売参加の手続きをさせる場合、代理人は「公売保証金納付書兼支払請求書兼口座振替依頼書」に公売参加者の住所および氏名など並びに代理人であることを明記した上で、代理人名で公売保証金を納付してください。
          <br />
          ・共同入札する場合は、仮申し込みを行った代表者名で公売保証金を納付する必要があります。
          <br />
          ・「公売保証金納付書兼支払請求書兼口座振替依頼書」に記入する振込先金融機関は、●●●公金収納取扱金融機関に限ります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）公売保証金の買受代金への充当
        </p>
        <p className="template-guideline-body">
          公売参加者などは、買受人などとなり買受代金から公売保証金を差し引いた金額を納付した場合、公売保証金を買受代金に充当することに同意するものとします。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （4）公売保証金の没収
        </p>
        <p className="template-guideline-body">
          公売参加者などが納付した公売保証金は、以下の場合に没収し、返還しません。
          <br />
          ア．最高価申込者または次順位買受申込者となり売却決定されたが、納付期限までに買受代金を納付しない場合
          <br />
          イ．公売参加者などが、国税徴収法第108条第1項の規定に該当する場合
        </p>
      </div>
      <AnchorButton text="▲ 第2の先頭に戻る" />
      <div data-testid="template-guideline-auction3" className="pt-9">
        <Heading3
          text="第3 せり売形式で行うインターネット公売手続き"
          isLeftBorderStyle={true}
          hasCopyButton={true}
          clickHandler={() => openModal('templateGuidelineAuction3')}
        />
        <p
          data-testid="template-guideline-lead-text"
          className="template-guideline-lead-text pt-4">
          せり売形式の公売システムは、紀尾井町戦略研究所株式会社の提供する自動入札システムおよび入札単位を使用しています。本章における入札とは、公売システム上の「入札価額」欄へ希望落札金額の上限を入力することおよび入力した上限以下の範囲で行われる自動入札をいいます。また、本章においては、「入札」はせり売にかかる買受の申し込み、「入札者」は買受申込者、「入札期間」はせり売期間を指します。
        </p>
      </div>
      <div data-testid="template-guideline-auction3_1" className="pt-6">
        <div className="template-guideline-section-title">
          1. インターネット公売への入札
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）入札
        </p>
        <p className="template-guideline-body">
          公売参加申し込み、公売保証金の納付および必要に応じて委任状などの書類提出が完了したログインIDでのみ、入札が可能です。入札は、入札期間中であれば何回でも可能です。ただし、公売システム上の「現在価額」または一度「入札価額」欄に入力した金額を下回る金額を「入札価額」欄に入力することはできません。一度行った入札は、公売参加者などの都合による取り消しや変更はできませんので、ご注意ください。なお、入札期間の自動延長は行いません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）入札をなかったものとする取り扱い
        </p>
        <p className="template-guideline-body">
          執行機関は、国税徴収法第108条第1項の規定に該当する者またはその代理人などが行った入札について、当該入札を取り消し、なかったものとして取り扱うことがあります。入札期間中にその時点における最高価額の入札をなかったものとした場合、当該入札に次ぐ価額の入札を最高価額の入札とし、せり売を続行します。
        </p>
      </div>
      <div data-testid="template-guideline-auction3_2" className="pt-6">
        <div className="template-guideline-section-title">
          2. 最高価申込者の決定など
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）最高価申込者の決定
        </p>
        <p className="template-guideline-body">
          ●●●は入札期間終了後、公売公告により定められた最高価申込者決定の日において、売却区分ごとに、インターネット公売上の入札における入札価額が見積価額以上でかつ最高価額である入札者を、最高価申込者として決定します。
          <br />
          また、インターネット公売では、2人以上が同額の入札価額（上限）を設定した場合、先に設定した人を最高価申込者として決定します。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）せり売終了の告知など
        </p>
        <p className="template-guideline-body">
          ●●●は、最高価申込者を決定したときは、最高価申込者のログインIDに紐づく会員識別番号と落札価額（最高価申込価額）を公売システム上に一定期間公開することによって告げ、せり売終了を告知します。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）●●●から最高価申込者などへの連絡
        </p>
        <p className="template-guideline-body">
          最高価申込者またはその代理人など（以下、「最高価申込者など」といいます）には、●●●から入札終了後、あらかじめログインIDで認証されたメールアドレスに、最高価申込者として決定された旨の電子メールを送信します。
          <br />
          ・●●●が最高価申込者などに送信した電子メールが、最高価申込者などのメールアドレスの変更やプロバイダの不調などの理由により到着しないために、●●●が買受代金納付期限までに最高価申込者などによる買受代金の納付を確認できない場合、その原因が最高価申込者などの責に帰すべきものであるか否かを問わず、公売保証金を没収し、返還しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （4）最高価申込者決定の取り消し
        </p>
        <p className="template-guideline-body">
          以下の場合に、最高価申込者の決定が取り消されます。この場合、公売財産の所有権は最高価申込者に移転しません。アまたはウの場合は、納付された公売保証金を返還します。
          <br />
          ア．売却決定前、公売財産にかかる差押徴収金（●税など）について完納の事実が証明されたとき。
          <br />
          イ．最高価申込者などが国税徴収法第108条第1項の規定に該当するとき。
          <br />
          ウ．最高価申込者などが暴力団員等であることが認められるとき。
        </p>
      </div>
      <div data-testid="template-guideline-auction3_3" className="pt-6">
        <div className="template-guideline-section-title">3. 売却決定</div>
        <p className="template-guideline-body pt-2">
          執行機関は、公売公告に記載した日時に、最高価申込者に対して売却決定を行います。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （1）売却決定金額
        </p>
        <p className="template-guideline-body">
          売却決定金額は、落札価額を売却決定金額とします。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）買受人などが買受代金を納付しなかった場合
        </p>
        <p className="template-guideline-body">
          買受人などが買受代金を納付しなかった場合、納付された公売保証金は返還しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）売却決定の取り消し
        </p>
        <p className="template-guideline-body">
          以下の場合に、売却決定が取り消されます。この場合、公売財産の所有権は買受人に移転しません。ただし、公売財産が動産の場合で、善意の買受人などが買受代金を納付した場合は、公売財産の所有権は当該買受人に移転します。
          <br />
          なお、アの場合にのみ、納付された公売保証金を返還します。
          <br />
          ア．売却決定後、買受人などが買受代金を納付する前に、公売財産にかかる差押徴収金（●税など）について完納の事実が証明されたとき。
          <br />
          イ．買受人などが買受代金を納付期限までに納付しなかったとき。
          <br />
          ウ．買受人などが、国税徴収法第108条第1項の規定に該当するとき。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （4）公売不動産にかかる売却決定の日時および買受代金納付期限の変更
        </p>
        <p className="template-guideline-body">
          不動産の最高価申込者等については、国税徴収法第106条の2に基づく調査の嘱託を行います。売却決定の日時までに、最高価申込者等が暴力団員等に該当しないことの調査の結果が明らかにならない場合は、売却決定の日時及び買受代金の納付期限が変更されます。
        </p>
      </div>
      <div data-testid="template-guideline-auction3_4" className="pt-6">
        <div className="template-guideline-section-title">
          4. 買受代金の納付
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）買受代金納付期限について
        </p>
        <p className="template-guideline-body">
          買受人などは、買受代金納付期限までに●●●が納付を確認できるよう買受代金（買受代金に充当される公売保証金額を除く）を一括で納付してください。買受代金納付期限までに買受代金全額の納付が確認できない場合、納付された公売保証金を没収し、返還しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）買受代金の納付方法
        </p>
        <p className="template-guideline-body">
          買受代金は次の方法で納付してください。なお、買受代金の納付にかかる費用は、買受人などが負担します。なお、買受代金納付期限までに●●●が納付を確認できることが必要です。
          <br />
          ア．●●●の指定する口座へ銀行振込。
          <br />
          イ．現金書留による送付（金額が50万円以下の場合のみ）。
          <br />
          ウ．郵便為替による納付。
          <br />
          ＊発行日から起算して175日を経過していないものに限る。
          <br />
          エ．現金もしくは銀行振出の小切手を●●●へ直接持参。
          <br />
          ＊銀行振出の小切手は、電子交換所に加入する銀行が振り出したもので振出日から起算して8日を経過していないものに限る。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）買受代金の納付の効果
        </p>
        <p className="template-guideline-body">
          ア．買受人などが公売財産にかかる買受代金の全額を納付したとき、買受人に当該公売財産の権利が移転します。ただし、公売財産を買い受けるために関係機関の承認や許可または登録が必要な場合は、それらの要件が満たされたときに買受人への権利移転の効力が生じます。
          <br />
          イ．公売財産の権利が買受人に移転したとき、危険負担が買受人に移転します。危険負担が移転した後に発生した財産の破損、盗難および焼失などによる損害の負担は、その財産の現実の引渡の有無などにかかわらず、買受人が負うことになります。
        </p>
      </div>
      <div data-testid="template-guideline-auction3_5" className="pt-4 pb-6">
        <div className="template-guideline-section-title">
          5. 公売保証金の返還
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）最高価申込者など以外の方への公売保証金の返還
        </p>
        <p className="template-guideline-body">
          最高価申込者または国税徴収法第108条第1項の規定に該当し、同条第2項の処分を受けた者（その代理人などを含む）以外の納付した公売保証金は、入札終了後全額返還します。
          <br />
          なお、公売参加申し込みを行ったものの入札を行わない場合にも、公売保証金の返還は入札終了後となります。
          <br />
          公売保証金返還の方法および返還に要する期間は次のとおりです。
          <br />
          ア．クレジットカードによる納付の場合
          <br />
          紀尾井町戦略研究所株式会社は、クレジットカードにより納付された公売保証金を返還する場合は、クレジットカードからの公売保証金の引き落としを行いません。
          <br />
          ただし、公売参加者などのクレジットカードの引き落としの時期などの関係上、いったん実際に公売保証金の引き落としを行い、翌月以降に返還を行う場合がありますので、ご了承ください。
          <br />
          イ．銀行振込などによる納付の場合
          <br />
          公売保証金の返還方法は、公売参加者などが指定する金融機関の預金口座（●●●公金収納取扱金融機関の口座に限ります）への振込のみとなります。公売参加者など（公売保証金返還請求者）名義の口座のみ指定可能です。
          <br />
          なお、公売保証金の返還には、入札終了後4週間程度要することがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）国税徴収法第114条に該当する場合
        </p>
        <p className="template-guideline-body">
          買受代金納付期限以前に滞納者などから不服申立てなどがあり、滞納処分の続行が停止された場合、その停止期間は、最高価申込者などまたは買受人などは国税徴収法第114条の規定によりその入札または買受を取り消すことができます。この場合、納付された公売保証金は全額返還します。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）国税徴収法第117条に該当する場合
        </p>
        <p className="template-guideline-body">
          売却決定後、買受人などが買受代金を納付する前に、公売財産にかかる差押徴収金（●税など）について完納の事実が証明され、国税徴収法第117条の規定により売却決定が取り消された場合は、納付された公売保証金は全額返還します。
        </p>
      </div>
      <AnchorButton text="▲ 第3の先頭に戻る" />
      <div data-testid="template-guideline-auction4" className="pt-9">
        <Heading3
          text="第4 入札形式で行うインターネット公売手続き"
          isLeftBorderStyle={true}
          hasCopyButton={true}
          clickHandler={() => openModal('templateGuidelineAuction4')}
        />
        <p
          data-testid="template-guideline-lead-text"
          className="template-guideline-lead-text pt-4">
          本章における入札とは、公売システム上で入札価額を登録することをいいます。この登録は、一度しか行うことができません。
        </p>
      </div>
      <div data-testid="template-guideline-auction4_1" className="pt-6">
        <div className="template-guideline-section-title">
          1. インターネット公売への入札
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）入札
        </p>
        <p className="template-guideline-body">
          公売参加申し込み、公売保証金の納付および必要に応じて委任状などの書類提出が完了したログインIDでのみ、入札が可能です。入札は一度のみ可能です。一度行った入札は、公売参加者などの都合による取り消しや変更はできませんので、ご注意ください。なお、入札期間の自動延長は行いません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）入札をなかったものとする取り扱い
        </p>
        <p className="template-guideline-body">
          執行機関は、国税徴収法第108条第1項の規定に該当する者またはその代理人などが行った入札について、当該入札を取り消し、なかったものとして取り扱うことがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）追加入札
        </p>
        <p className="template-guideline-body">
          ア．追加入札とは
          <br />
          最高価額での入札者が複数存在する場合は、その方々（追加入札該当者またはその代理人など。以下、「追加入札該当者など」といいます）のみによる追加の入札を行い、最高価申込者を決定します。これを追加入札といいます。追加入札においても、入札は一度のみ可能です。なお、追加入札は期日入札により行います。
          <br />
          イ．追加入札の周知方法
          <br />
          追加入札該当者などへは、入札期間終了後、電子メールにて追加入札該当者であることおよび追加入札期間をお知らせします。
          <br />
          ウ．その他
          <br />
          （ア）追加入札該当者などが追加入札期間中に追加入札を行わなかった場合は、当初の入札と同額で追加入札したものとみなします。
          <br />
          （イ）共同入札者が追加入札該当者となった場合、代表者のログインIDでのみ追加入札が可能です。
        </p>
      </div>
      <div data-testid="template-guideline-auction4_2" className="pt-6">
        <div className="template-guideline-section-title">
          2. 最高価申込者の決定など
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）最高価申込者の決定
        </p>
        <p className="template-guideline-body">
          入札期間終了後、●●●は開札を行い、売却区分ごとに、インターネット公売上の入札において、入札価額が見積価額以上でかつ最高価額である入札者を最高価申込者として決定します。
          <br />
          追加入札が行われた場合は、追加入札において追加入札価額が当初の入札価額以上でかつ最高価額である入札者を最高価申込者として決定します。
          <br />
          ただし、追加入札終了後も最高価額での入札者が複数存在する場合は、くじ（自動抽選）で最高価申込者を決定します。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）入札終了の告知など
        </p>
        <p className="template-guideline-body">
          ●●●は、最高価申込者を決定したときは、最高価申込者のログインIDに紐づく会員識別番号と落札価額（最高価申込価額）を公売システム上に一定期間公開することによって告げ、入札終了を告知します。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）●●●から最高価申込者などへの連絡
        </p>
        <p className="template-guideline-body">
          最高価申込者などには、●●●から入札終了後、あらかじめログインIDで認証されたメールアドレスに、最高価申込者として決定された旨の電子メールを送信します。
          <br />
          ・●●●が最高価申込者などに送信した電子メールが、最高価申込者などによるメールアドレスの変更やプロバイダの不調などの理由により到着しないために、●●●が最高価申込者などによる買受代金の納付を買受代金納付期限までに確認できない場合、その原因が最高価申込者などの責に帰すべきものであるか否かを問わず、公売保証金を没収し、返還しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （4）最高価申込者決定の取り消し
        </p>
        <p className="template-guideline-body">
          以下の場合に、最高価申込者の決定が取り消されます。この場合、公売財産の所有権は最高価申込者に移転しません。なお、アまたはウの場合は、納付された公売保証金を返還します。
          <br />
          ア．売却決定前に、公売財産にかかる差押徴収金（●税など）について完納の事実が証明されたとき。
          <br />
          イ．最高価申込者などが国税徴収法第108条第1項の規定に該当するとき。
          <br />
          ウ．最高価申込者などが暴力団員等であることが認められるとき。
        </p>
      </div>
      <div data-testid="template-guideline-auction4_3" className="pt-6">
        <div className="template-guideline-section-title">
          3. 次順位買受申込者の決定
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）次順位買受申込者の決定
        </p>
        <p className="template-guideline-body">
          最高価申込者などが買受代金を納付しなかった場合などにおいて、次順位買受申込者がいる場合に、次順位買受申込者に売却決定します。●●●は最高価申込者決定後、以下の条件をすべて満たす入札者を次順位買受申込者として決定します。
          <br />
          ・最高価申込者の入札価額に次ぐ高い価額で入札していること。
          <br />
          ・入札価額が最高価申込者の入札価額から公売保証金額を差し引いた金額以上であること。
          <br />
          ・入札時に次順位買受申し込みを行っていること。
          <br />
          上記の条件をすべて満たす入札者が複数存在する場合は、くじ（自動抽選）により次順位買受申込者を決定します。なお、入札時に次順位買受申し込みを行った場合、この申し込みは取り消すことができませんのでご注意ください。また、●●●は、次順位買受申込者を決定したときは、次順位買受申込者の会員識別番号と次順位買受申込価額を、公売システム上に一定期間公開することによって告げます。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）●●●から次順位買受申込者などへの連絡
        </p>
        <p className="template-guideline-body">
          次順位買受申込者またはその代理人など（以下、「次順位買受申込者など」といいます）には、●●●から入札終了後、あらかじめ会員識別番号で認証された次順位買受申込者などのメールアドレスに、次順位買受申込者として決定された旨の電子メールを送信します。
          <br />
          ・●●●が次順位買受申込者などに送信した電子メールが、次順位買受申込者などのメールアドレスの変更やプロバイダの不調などの理由により到着しないために、●●●が売却決定を受けて買受人となった次順位買受申込者などによる買受代金の納付を買受代金納付期限までに確認できない場合、その原因が次順位買受申込者などの責に帰すべきものであるか否かを問わず、公売保証金を没収し、返還しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）次順位買受申込者決定の取り消し
        </p>
        <p className="template-guideline-body">
          以下の場合に、次順位買受申込者の決定が取り消されます。この場合、公売財産の所有権は次順位買受申込者に移転しません。なお、アまたはウの場合は、納付された公売保証金を返還します。
          <br />
          ア．売却決定前に、公売財産にかかる差押徴収金（●税など）について完納の事実が証明されたとき。
          <br />
          イ．次順位買受申込者などが国税徴収法第108条第1項の規定に該当するとき。
          <br />
          ウ．次順位買受申込者などが暴力団員等であることが認められるとき。
        </p>
      </div>
      <div data-testid="template-guideline-auction4_4" className="pt-6">
        <div className="template-guideline-section-title">4. 売却決定</div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）最高価申込者に対する売却決定
        </p>
        <p className="template-guideline-body">
          執行機関は、公売公告に記載した日時に、最高価申込者に対して売却決定を行います。
          <br />
          ア．売却決定金額
          <br />
          売却決定金額は、落札価額を売却決定金額とします。
          <br />
          イ．売却決定を受けた最高価申込者などが買受代金を納付しなかった場合
          <br />
          売却決定を受けた最高価申込者などが買受代金を納付しなかった場合、納付された公売保証金は返還しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）次順位買受申込者に対する売却決定
        </p>
        <p className="template-guideline-body">
          執行機関は、最高価申込者などが買受代金を納付しなかった場合などにおいて、次順位買受申込者がいる場合に、次順位買受申込者に対して売却決定を行います。
          <br />
          最高価申込者の決定を取り消し、次順位買受申込者がいない場合は、当該公売は成立しません。
          <br />
          ア．次順位買受申込者の売却決定金額
          <br />
          次順位買受申込者などの売却決定金額は、次順位買受申込者などの入札価額を売却決定金額とします。
          <br />
          イ．売却決定を受けた次順位買受申込者などが買受代金を納付しなかった場合
          <br />
          売却決定を受けた次順位買受申込者などが買受代金を納付しない場合、納付された公売保証金は返還しません。
          <br />
          この場合、当該公売は成立しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）売却決定の取り消し
        </p>
        <p className="template-guideline-body">
          以下の場合に、売却決定が取り消されます。この場合、公売財産の所有権は買受人に移転しません。なお、アの場合にのみ、納付された公売保証金を返還します。
          ア．売却決定後、買受人などが買受代金を納付する前に、公売財産にかかる差押徴収金（●税など）について完納の事実が証明されたとき。
          イ．買受人などが買受代金を納付期限までに納付しなかったとき。
          ウ．買受人などが、国税徴収法第108条第1項の規定に該当するとき。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （4）公売不動産にかかる売却決定の日時および買受代金納付期限の変更
        </p>
        <p className="template-guideline-body">
          不動産の最高価申込者等については、国税徴収法第106条の2に基づく調査の嘱託を行います。売却決定の日時までに、最高価申込者等が暴力団員等に該当しないことの調査の結果が明らかにならない場合は、売却決定の日時及び買受代金の納付期限が変更されます。
        </p>
      </div>
      <div data-testid="template-guideline-auction4_5" className="pt-6">
        <div className="template-guideline-section-title">
          5. 買受代金の納付
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）買受代金の金額
        </p>
        <p className="template-guideline-body">
          買受代金の金額は、売却決定金額です。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）買受代金納付期限について
        </p>
        <p className="template-guideline-body">
          買受人などは、買受代金納付期限までに●●●が納付を確認できるよう買受代金（買受代金に充当される公売保証金額を除く）を一括で納付してください。（次順位買受申込者が売却決定を受けた場合の買受代金納付期限は、通常は売却決定の7日後です）。
          <br />
          買受代金納付期限までに買受代金全額の納付が確認できない場合、納付された公売保証金を没収し、返還しません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）買受代金の納付方法
        </p>
        <p className="template-guideline-body">
          買受代金は次の方法で納付してください。なお、買受代金の納付にかかる費用は、買受人などが負担します。なお、買受代金納付期限までに●●●が納付を確認できることが必要です。
          <br />
          ア．●●●の指定する口座へ銀行振込。
          <br />
          イ．現金書留による送付（金額が50万円以下の場合のみ）。
          <br />
          ウ．郵便為替による納付。
          <br />
          ＊発行日から起算して175日を経過していないものに限る。
          <br />
          エ．現金もしくは銀行振出の小切手を●●●へ直接持参。
          <br />
          ＊銀行振出の小切手は、電子交換所に加入する銀行が振り出したもので振出日から起算して8日を経過していないものに限る。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （4）買受代金の納付の効果
        </p>
        <p className="template-guideline-body">
          ア．買受人などが公売財産にかかる買受代金の全額を納付したとき、買受人に当該公売財産の権利が移転します。ただし、公売財産を買い受けるために関係機関の承認や許可または登録が必要な場合は、それらの要件が満たされたときに買受人への権利移転の効力が生じます。
          <br />
          イ．公売財産の権利が買受人に移転したとき、危険負担が買受人に移転します。危険負担が移転した後に発生した財産の破損、盗難および焼失などによる損害の負担は、その財産の現実の引渡の有無などにかかわらず、買受人が負うことになります。
        </p>
      </div>
      <div data-testid="template-guideline-auction4_6" className="pt-6">
        <div className="template-guideline-section-title">
          6. 公売保証金の返還
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）最高価申込者および次順位買受申込者など以外の方への公売保証金の返還
        </p>
        <p className="template-guideline-body">
          最高価申込者、次順位買受申込者または国税徴収法第108条第1項の規定に該当し同条第2項の処分を受けた者並びにその代理人など以外の納付した公売保証金は、入札終了後全額返還します。
          <br />
          なお、公売参加申し込みを行ったものの入札を行わない場合にも、公売保証金の返還は入札終了後となります。
          <br />
          公売保証金返還の方法および返還に要する期間は次のとおりです
          <br />
          ア．クレジットカードによる納付の場合
          <br />
          紀尾井町戦略研究所株式会社は、クレジットカードにより納付された公売保証金を返還する場合は、クレジットカードからの公売保証金の引き落としを行いません。
          <br />
          ただし、公売参加者などのクレジットカードの引き落としの時期などの関係上、いったん実際に公売保証金の引き落としを行い、翌月以降に返還を行う場合がありますので、ご了承ください。
          <br />
          イ．銀行振込などによる納付の場合
          <br />
          公売保証金の返還方法は、公売参加者などが指定する金融機関の預金口座（●●●公金収納取扱金融機関の口座に限ります）への振込のみとなります。公売参加者など（公売保証金返還請求者）名義の口座のみ指定可能です。なお、公売保証金の返還には、入札終了後4週間程度要することがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）次順位買受申込者などへの公売保証金の返還
        </p>
        <p className="template-guideline-body">
          次順位買受申込者などの納付した公売保証金は、最高価申込者などが買受代金納付期限までに買受代金全額を納付した場合に、全額返還します。
          <br />
          公売保証金返還の方法および返還に要する期間は次のとおりです。
          <br />
          ア．クレジットカードによる納付の場合
          <br />
          紀尾井町戦略研究所株式会社は、クレジットカードにより納付された公売保証金を返還する場合は、クレジットカードからの公売保証金の引き落としを行いません。
          <br />
          ただし、次順位買受申込者などのクレジットカードの引き落としの時期などの関係上、いったん実際に公売保証金の引き落としを行い、翌月以降に返還を行う場合がありますので、ご了承ください。
          <br />
          イ．銀行振込などによる納付の場合
          <br />
          なお、公売保証金の返還には、入札終了後4週間程度要することがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）国税徴収法第114条に該当する場合
        </p>
        <p className="template-guideline-body">
          買受代金の納付期限以前に滞納者などから不服申立てなどがあり、滞納処分の続行が停止された場合、その停止期間は、最高価申込者など、次順位買受申込者などおよび買受人などは国税徴収法第114条の規定によりその入札または買受を取り消すことができます。この場合、納付された公売保証金は全額返還します。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （4）国税徴収法第117条に該当する場合
        </p>
        <p className="template-guideline-body">
          売却決定後、買受人などが買受代金を納付する前に、公売財産にかかる差押徴収金（●税など）について完納の事実が証明され、国税徴収法第117条の規定により売却決定が取り消された場合は、納付された公売保証金は全額返還します。
        </p>
      </div>
      <AnchorButton text="▲ 第4の先頭に戻る" />
      <div data-testid="template-guideline-auction5" className="pt-9">
        <Heading3
          text="第5 公売財産の権利移転および引渡について"
          isLeftBorderStyle={true}
          hasCopyButton={true}
          clickHandler={() => openModal('templateGuidelineAuction5')}
        />
      </div>
      <div data-testid="template-guideline-auction5_1" className="pt-4">
        <div className="template-guideline-section-title">
          1. 公売財産の権利移転手続きについて（通則）
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）権利移転手続きについて
        </p>
        <p className="template-guideline-body">
          公売財産の権利移転手続きについては、財産の種類に応じ、第5の2から4までに定めるところによります。ガイドラインに定めのない財産の権利移転手続きについては、これらの定めるところに準じることとします。ただし、執行機関がその財産の特殊な事情などを考慮して必要と認める場合は、第5の2から4までの規定を必要と認める範囲において変更することができるものとします。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）権利移転手続きにおける注意事項
        </p>
        <p className="template-guideline-body">
          ア．公売財産に財産の種類又は品質に関する不適合があっても、現所有者および●●●には担保責任は生じません。
          <br />
          イ．買受人などが公売財産にかかる買受代金の全額を納付したとき、買受人に当該公売財産の権利が移転します。ただし、公売財産を買い受けるために関係機関の承認や許可または登録が必要な場合は、それらの要件が満たされたときに買受人への権利移転の効力が生じます。
          <br />
          ウ．公売財産の権利が買受人に移転したとき、危険負担が買受人に移転します。危険負担が移転した後に発生した財産の破損、盗難および焼失などによる損害の負担は、その財産の現実の引渡の有無などにかかわらず、買受人が負うことになります。
          <br />
          エ．権利移転に伴う費用は、買受人などの負担となります。
        </p>
      </div>
      <div data-testid="template-guideline-auction5_2" className="pt-6">
        <div className="template-guideline-section-title">
          2. 公売財産が動産の場合の権利移転および引渡について
        </div>
        <p className="template-guideline-body pt-2">
          執行機関は、買受代金の納付を確認した後、公売財産の引渡を行います。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （1）公売財産の引渡
        </p>
        <p className="template-guideline-body">
          ア．公売財産の引渡は、買受代金納付時の現況有姿で行います。
          <br />
          イ．公売財産の引渡は、原則として●●●の事務室内で行います。
          <br />
          ウ．執行機関が公売財産を第三者に保管させている場合は、買受人は執行機関から交付される「売却決定通知書」を提示し、保管人から財産の引渡を受けてください。この場合、「売却決定通知書」の交付により、執行機関から買受人に対して公売財産の引渡は完了したことになります。保管人が財産の現実の引渡を拒否しても、執行機関はその現実の引渡を行う義務を負いません。
          <br />
          エ．公売財産または「売却決定通知書」を直接受け取る場合は、買受人の本人確認のため、下記（ア）から（ウ）をお持ちください。なお、買受人が法人である場合には、商業登記簿謄本などと法人代表者の方の下記（ア）から（ウ）をお持ちください。
          <br />
          （ア）身分証明書。
          <br />
          運転免許証、住民基本台帳カードなど、住所および氏名が明記されご本人の写真が添付されている本人確認書類を提示してください。なお、運転免許証などをお持ちでない方は、住民票などの住所および氏名を証する書面およびパスポートなどの写真付き本人確認書類を提示してください。
          <br />
          （イ）●●●より買受人などへ送信した電子メールを印刷したもの。
          <br />
          （ウ）印鑑。
          <br />
          オ．買受人は、送付による公売財産の引渡を希望する場合、「送付依頼書」や住民票などの提出が必要です。「送付依頼書」は、インターネット公売終了後、●●●ホームページより印刷して必要事項を記入・なつ印のうえ、●●●に提出してください。送付による引渡を希望する場合、輸送途中での事故などによって公売財産が破損、紛失などの被害を受けても、●●●は一切責任を負いません。また、極端に重い財産、大きな財産、壊れやすい財産は送付による引渡はできない場合があります。なお、送付先住所が買受人の住所（所在地）と異なる場合は、その旨を「送付依頼書」に記載してください。送付先の受取人となりうるのは、買受人のみです。
          <br />
          カ．買受人は、買受代金納付時に公売財産の引渡を受けない場合、「保管依頼書」や住民票などの提出が必要です。「保管依頼書」は、インターネット公売終了後、●●●ホームページより印刷して必要事項を記入・なつ印のうえ、●●●に提出してください。
          <br />
          キ．一度引き渡された財産は、いかなる理由があっても返品、交換はできません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）注意事項
        </p>
        <p className="template-guideline-body">
          ア．買受人が自ら登録や名義変更などを行う必要がある財産については、引渡後、速やかに登録や名義変更の手続きを行ってください。
          <br />
          イ．買受代金の持参、公売財産の受取または「売却決定通知書」の受取などを代理人が行う場合は、下記（ア）から（エ）をお持ちください。
          <br />
          （ア）代理権限を証する委任状。
          <br />
          （イ）買受人本人の住所証明書（買受人が法人の場合は商業登記簿謄本など）。
          <br />
          （ウ）代理人の身分証明書。
          <br />
          （エ）代理人の印鑑。
          <br />
          ＊委任状は●●●ホームページより印刷することができます。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）引渡および権利移転に伴う費用について
        </p>
        <p className="template-guideline-body">
          ア．落札された公売財産の保管費用が必要な場合、買受代金納付後の保管費用は買受人の負担となります。
          <br />
          イ．買受人が送付による公売財産の引渡を希望する場合、送付費用は買受人の負担となります。
          <br />
          ウ．その他、公売財産の権利移転に伴い費用を要する場合には、その費用は買受人の負担となります。
        </p>
      </div>
      <div data-testid="template-guideline-auction5_3" className="pt-6">
        <div className="template-guideline-section-title">
          3. 公売財産が自動車の場合の権利移転および引渡について
        </div>
        <p className="template-guideline-body pt-2">
          本項の「自動車」は、道路運送車両法の規定により登録を受けた自動車をいいます。したがって、軽自動車および登録のない自動車などの権利移転手続きは、原則として第5の2に定めるところによります。
          <br />
          執行機関は、買受代金の納付を確認後、買受人に対して売却決定通知書を交付し、公売財産の引渡を行います。また、買受人からの請求に基づいて権利移転の手続きを行います。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （1）公売財産の引渡
        </p>
        <p className="template-guideline-body">
          ア．公売財産の引渡は、買受代金納付時の現況有姿で行います。
          <br />
          イ．執行機関が公売財産を第三者に保管させている場合は、買受人は執行機関から交付される「売却決定通知書」を提示し、保管人から財産の引渡を受けてください。この場合、「売却決定通知書」の交付により、執行機関から買受人に対して公売財産の引渡は完了したことになります。保管人が財産の現実の引渡を拒否しても、執行機関はその現実の引渡を行う義務を負いません。
          <br />
          ウ．買受人は、買受代金納付時に公売財産の引渡を受けない場合、「保管依頼書」の提出が必要です。「保管依頼書」は、インターネット公売終了後、●●●ホームページより印刷して必要事項を記入・なつ印のうえ、●●●に提出してください。
          <br />
          エ．一度引き渡された財産は、いかなる理由があっても返品、交換はできません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）権利移転の手続きについて
        </p>
        <p className="template-guideline-body">
          ア．●●●ホームページより「所有権移転登録請求書」を印刷した後、必要事項を記入・署名・なつ印のうえ、自動車保管場所証明書、印鑑証明書などの必要書類を添えて、買受代金納付期限までに●●●へ提出してください。
          <br />
          イ．買受人の「使用の本拠の位置」を管轄する運輸支局、自動車検査登録事務所が、対象財産を管轄する運輸支局などと異なる場合などには、買受人の「使用の本拠の位置」を管轄する運輸支局などに当該自動車を持ち込んでいただくことが必要です。また、買受人の「使用の本拠の位置」を管轄する運輸支局などが、●●運輸局●●運輸支局および●●自動車検査登録事務所以外の場合、所有権の移転登録および差押登録の抹消登録は、郵送で行います。
          <br />
          ウ．自動車検査証有効期限切れの自動車は、所有権移転登録と同時に一時抹消登録をすることとなります。使用される場合は、買受人が自ら新規検査および新規登録の手続きを行う必要があります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）売却決定通知書の交付
        </p>
        <p className="template-guideline-body">
          執行機関は、買受代金の納付を確認後、買受人に対して「売却決定通知書」を交付します。「売却決定通知書」を直接受け取る際は、買受人の本人確認のため、下記アからウをお持ちください。なお、買受人が法人である場合には、商業登記簿謄本などと法人代表者の方の下記アからウをお持ちください。
          <br />
          ア．身分証明書。
          <br />
          運転免許証、住民基本台帳カードなど、住所および氏名が明記されご本人の写真が添付されている本人確認書類を提示してください。なお、運転免許証などをお持ちでない方は、住民票などの住所および氏名を証する書面およびパスポートなどの写真付き本人確認書類を提示してください。
          <br />
          イ．●●●より買受人などへ送信した電子メールを印刷したもの。
          <br />
          ウ．印鑑。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （4）注意事項
        </p>
        <p className="template-guideline-body">
          買受代金の持参、公売財産の受取または「売却決定通知書」の受取などを代理人が行う場合は、下記アからエをお持ちください。
          <br />
          ア．代理権限を証する委任状。
          <br />
          イ．買受人本人の住所証明書（買受人が法人の場合は商業登記簿謄本など）。
          <br />
          ウ．代理人の身分証明書。
          <br />
          エ．代理人の印鑑。
          <br />
          ＊委任状は●●●ホームページより印刷することができます。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （5）引渡および権利移転に伴う費用について
        </p>
        <p className="template-guideline-body">
          ア．権利移転に伴う費用（登録手数料など）は買受人の負担となります。
          <br />
          イ．自動車税環境性能割は、買受人が自ら申告、納税してください。
          <br />
          ウ．買受人の「使用の本拠の位置」を管轄する運輸支局などが、●●運輸局●●運輸支局および●●自動車検査登録事務所以外の場合、所有権の移転登録および差押登録の抹消登録は郵送で行いますので、郵送料（切手1500円程度）が必要です。
          <br />
          エ．落札された公売財産の保管費用が必要な場合、買受代金納付期限の翌日以降の保管費用は、買受人の負担となります。
        </p>
      </div>
      <div data-testid="template-guideline-auction5_4" className="pt-6">
        <div className="template-guideline-section-title">
          4. 公売財産が不動産の場合の権利移転について
        </div>
        <p className="template-guideline-body pt-2">
          執行機関は、買受人の請求に基づいて不動産登記簿上の権利移転のみを行います。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （1）権利移転の時期
        </p>
        <p className="template-guideline-body">
          公売財産は、買受代金の全額を納付したとき、買受人に権利移転します。ただし、買受代金を納付しても、農地の場合は農業委員会などの許可などを受けるまで、その他法令の規定による登録を要する場合は関係機関の登録が完了するまで権利移転の効力は生じません。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）権利移転の手続きについて
        </p>
        <p className="template-guideline-body">
          ア．●●●ホームページより「所有権移転登記請求書」を印刷した後、必要事項を記入・署名・なつ印して、住所証明書などの必要書類を添えて、買受代金納付期限までに●●●へ提出してください。
          <br />
          イ．共同入札の場合は、共同入札者全員の住所証明書（共同入札者が法人の場合は商業登記簿謄本など）および共同入札者全員が署名・なつ印した「共有合意書」の提出が必要です。「共有合意書」の持分割合は、入札前に提出した「共同入札者持分内訳書」と同じものを記載してください。なお、共有合意書は、●●●ホームページより印刷することができます。
          <br />
          ウ．公売財産が農地である場合などは、農業委員会などの発行する権利移転の許可書または届出受理書のいずれかが必要です。
          <br />
          エ．所有権移転の登記が完了するまで、入札終了後●か月程度の期間を要することがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）売却決定通知書の交付
        </p>
        <p className="template-guideline-body">
          執行機関は、買受代金の納付を確認後、買受人に対して「売却決定通知書」を交付します。共同入札者が買受人になった場合は、買受人全員に対しそれぞれの持分に応じた「売却決定通知書」を交付します。「売却決定通知書」を直接受け取る際は、買受人の本人確認のため、下記アからウをお持ちください。なお、買受人が法人である場合には、商業登記簿謄本などと法人代表者の方の下記アからウをお持ちください。
          <br />
          ア．身分証明書。
          <br />
          運転免許証、住民基本台帳カードなど、住所および氏名が明記されご本人の写真が添付されている本人確認書類を提示してください。なお、運転免許証などをお持ちでない方は、住民票などの住所および氏名を証する書面およびパスポートなどの写真付き本人確認書類を提示してください。
          <br />
          イ．●●●より買受人などへ送信した電子メールを印刷したもの。
          <br />
          ウ．印鑑。
          <br />
          なお、所有権移転登記の際に「売却決定通知書」正本が必要な場合がありますので、●●●でいったん「売却決定通知書」をお預かりすることがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）権利移転の手続きについて
        </p>
        <p className="template-guideline-body">
          ア．●●●ホームページより「所有権移転登記請求書」を印刷した後、必要事項を記入・署名・なつ印して、住所証明書などの必要書類を添えて、買受代金納付期限までに●●●へ提出してください。
          <br />
          イ．共同入札の場合は、共同入札者全員の住所証明書（共同入札者が法人の場合は商業登記簿謄本など）および共同入札者全員が署名・なつ印した「共有合意書」の提出が必要です。「共有合意書」の持分割合は、入札前に提出した「共同入札者持分内訳書」と同じものを記載してください。なお、共有合意書は、●●●ホームページより印刷することができます。
          <br />
          ウ．公売財産が農地である場合などは、農業委員会などの発行する権利移転の許可書または届出受理書のいずれかが必要です。
          <br />
          エ．所有権移転の登記が完了するまで、入札終了後1か月半程度の期間を要することがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）売却決定通知書の交付
        </p>
        <p className="template-guideline-body">
          執行機関は、買受代金の納付を確認後、買受人に対して「売却決定通知書」を交付します。共同入札者が買受人になった場合は、買受人全員に対しそれぞれの持分に応じた「売却決定通知書」を交付します。「売却決定通知書」を直接受け取る際は、買受人の本人確認のため、下記アからウをお持ちください。なお、買受人が法人である場合には、商業登記簿謄本などと法人代表者の方の下記アからウをお持ちください。
          <br />
          ア．身分証明書。
          <br />
          運転免許証、住民基本台帳カードなど、住所および氏名が明記されご本人の写真が添付されている本人確認書類を提示してください。なお、運転免許証などをお持ちでない方は、住民票などの住所および氏名を証する書面およびパスポートなどの写真付き本人確認書類を提示してください。
          <br />
          イ．●●●より買受人などへ送信した電子メールを印刷したもの。
          <br />
          ウ．印鑑。
          <br />
          なお、所有権移転登記の際に「売却決定通知書」正本が必要な場合がありますので、●●●でいったん「売却決定通知書」をお預かりすることがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （4）注意事項
        </p>
        <p className="template-guideline-body">
          ア．執行機関は公売財産の引渡の義務を負いません。公売財産内の動産類やゴミなどの撤去、占有者の立退き、前所有者からの鍵などの引渡などは、すべて買受人自身で行ってください。
          <br />
          また、隣地との境界確定は、買受人と隣地所有者との間で行ってください。●●●は関与しません。
          <br />
          イ．買受代金の持参または「売却決定通知書」の受取などを代理人が行う場合は、下記（ア）から（エ）をお持ちください。
          <br />
          （ア）代理権限を証する委任状。
          <br />
          （イ）買受人本人の住所証明書（買受人が法人の場合は商業登記簿謄本など）。
          <br />
          （ウ）代理人の身分証明書。
          <br />
          （エ）代理人の印鑑。
          <br />
          ＊委任状は●●●ホームページより印刷することができます。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （5）引渡および権利移転に伴う費用について
        </p>
        <p className="template-guideline-body">
          ア．権利移転に伴う費用（移転登記の登録免許税、登記嘱託書の郵送料など）は買受人の負担となります。
          <br />
          イ．所有権移転などの登記を行う際は、登録免許税法に定める登録免許税を納付したことを証する領収証書が必要となります。登録免許税額については、入札終了後に●●●よりお知らせします。買受代金を直接持参する場合は、登録免許税相当額をあわせて持参し、納付してください。買受代金を銀行振込などで納付する場合は、登録免許税相当額もあわせて振込もしくは送付してください。共同入札者が買受人となった場合、登録免許税の領収証書は、共同入札者の人数分だけ必要となります。共同入札者は、各々の持分に応じた登録免許税相当額を納付してください。
          <br />
          ・所有権移転登記を行う際に、執行機関と所管の法務局との間で登記嘱託書などの書類を送付するために郵送料（切手1500円程度）が必要です。
        </p>
      </div>
      <AnchorButton text="▲ 第5の先頭に戻る" />
      <div data-testid="template-guideline-auction6" className="pt-9">
        <Heading3
          text="第6 注意事項"
          isLeftBorderStyle={true}
          hasCopyButton={true}
          clickHandler={() => openModal('templateGuidelineAuction6')}
        />
      </div>
      <div data-testid="template-guideline-auction6_1" className="pt-4">
        <div className="template-guideline-section-title">
          1. 公売システムに不具合などが生じた場合の対応
        </div>
        <p className="template-guideline-body pt-2">
          公売システムなどに不具合が生じたために次に掲げる事態が発生した場合、執行機関は公売手続きを中止することがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （1）入札期間前
        </p>
        <p className="template-guideline-body">
          公売参加申し込み期間の始期に公売参加申し込み受付が開始されない場合、公売参加申し込み受付ができない状態が相当期間継続した場合、公売参加申し込み受付が入札開始までに終了しない場合または公売参加申し込み期間の終期後になされた公売参加申し込みを取り消すことができない場合。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）入札期間中
        </p>
        <p className="template-guideline-body">
          入札期間の始期に入札の受付が開始されない場合、入札できない状態が相当期間継続した場合または入札の受付が入札期間の終期に終了しない場合
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）入札期間後
        </p>
        <p className="template-guideline-body">
          せり売形式において執行機関が入札終了後相当期間経過後も最高価申込者などを決定できない場合並びに入札形式において入札終了後相当期間経過後も開札ができない場合、追加入札が必要な場合で追加入札の開始または終了ができない場合またはくじ（自動抽選）が必要な場合でくじ（自動抽選）が適正に行えない場合。
        </p>
      </div>
      <div data-testid="template-guideline-auction6_2" className="pt-6">
        <div className="template-guideline-section-title">
          2. 公売の中止および中止時の公売保証金の返還
        </div>
        <p className="template-guideline-body pt-2">
          公売参加申し込み開始後に公売を中止することがあります。公売財産の公開中であっても、公売にかかる差押徴収金が納付された場合などにインターネット公売を中止します。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （1）特定の公売財産の中止時の公売保証金の返還
        </p>
        <p className="template-guideline-body">
          特定の公売財産の公売が中止となった場合、当該公売財産について納付された公売保証金は中止後返還します。なお、銀行振込などにより公売保証金を納付した場合、返還まで中止後4週間程度要することがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）インターネット公売中止時の公売保証金の返還
        </p>
        <p className="template-guideline-body">
          インターネット公売全体が中止となった場合、公売保証金は中止後返還します。なお、銀行振込などにより公売保証金を納付した場合、返還まで中止後4週間程度要することがあります。
        </p>
      </div>
      <div data-testid="template-guideline-auction6_3" className="pt-6">
        <div className="template-guideline-section-title">
          3. システム利用における禁止事項
        </div>
        <p className="template-guideline-body pt-2">
          公売システムの利用にあたり、次に掲げる行為を禁止します。
          <br />
          （1）公売システムをインターネット公売の手続き以外の目的で不正に利用すること。
          <br />
          （2）公売システムに不正にアクセスをすること。
          <br />
          （3）公売システムの管理および運営を故意に妨害すること。
          <br />
          （4）公売システムにウイルスに感染したファイルを故意に送信すること。
          <br />
          （5）法令もしくは公序良俗に違反する行為またはそのおそれのある行為をすること。
          <br />
          （6）その他公売システムの運用に支障を及ぼす行為またはそのおそれのある行為をすること。
        </p>
      </div>
      <div data-testid="template-guideline-auction6_4" className="pt-6">
        <div className="template-guideline-section-title">
          4. 公売参加者などに損害などが発生した場合
        </div>
        <p className="template-guideline-body pt-2">
          次に掲げる事由などにより公売参加者など（公売システムにアクセスした方、公売参加者などまたは第三者）に損害が発生した場合、●●●はその損害の種類・程度にかかわらず責任を負いません。
          <br />
          （1）公売が中止になったこと。
          <br />
          （2）公売システムに不具合などが生じたこと。
          <br />
          （3）公売参加者など（公売システムにアクセスした方、公売参加者などまたは第三者）の使用する機器およびネットワークなどの不備、不調その他の理由により、公売参加申し込みまたは入札が行えなかったこと。
          <br />
          （4）公売に参加したことに起因して、公売参加者などが使用する機器およびネットワークなどに不備、不調などが生じたこと。
          <br />
          （5）公売参加者などが公売保証金を自己名義（代理人の場合は代理人名義、法人の場合は法人代表者名義）のクレジットカードで納付する場合で、クレジットカード決済システムの不備により、公売保証金の納付ができず公売参加申し込みができなかったこと。
          <br />
          （6）公売参加者などのメールアドレスの変更や公売参加者などの使用する機器およびネットワークなどの不備、不調その他の理由により、●●●から送信される電子メールが到着しなかったこと。
          <br />
          （7）公売参加者など（公売システムにアクセスした方、公売参加者などまたは第三者）の発信もしくは受信するデータが不正アクセスおよび改変などを受けたこと。
          <br />
          （8）公売参加者など（公売システムにアクセスした方、公売参加者などまたは第三者）が、自身のログインIDおよびパスワードなどを紛失もしくは、ログインIDおよびパスワードなどが第三者に漏えいしたこと。
          <br />
          （9）公売参加者など（公売システムにアクセスした方、公売参加者などまたは第三者）が、公売参加の手続きに関する権限の一部を代理人などに委任した場合において、その委任を受けた代理人などがした行為により被害を受けたこと。
          <br />
          （10）買受人などとなった公売参加者などが送付による公売財産の引渡を希望した場合、輸送途中での事故などによって公売財産に破損、紛失などの事態が発生したこと。
        </p>
      </div>
      <div data-testid="template-guideline-auction6_5" className="pt-6">
        <div className="template-guideline-section-title">5. 準拠法</div>
        <p className="template-guideline-body pt-2">
          このガイドラインには、日本法が適用されるものとします。
        </p>
      </div>
      <div data-testid="template-guideline-auction6_6" className="pt-6">
        <div className="template-guideline-section-title">
          6. インターネット公売において使用する通貨、言語、時刻など
        </div>
        <p className="template-guideline-body font-weight-bold pt-2">
          （1）インターネット公売の手続きにおいて使用する通貨
        </p>
        <p className="template-guideline-body">
          インターネット公売の手続きにおいて使用する通貨は、日本国通貨に限り、入札価額などの金額は、日本国通貨により表記しなければならないものとします。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （2）インターネット公売の手続きにおいて使用する言語
        </p>
        <p className="template-guideline-body">
          インターネット公売の手続きにおいて使用する言語は、日本語に限ります。公売システムにおいて使用する文字は、JIS第1第2水準漢字（JIS（産業標準化法（昭和24年法律第185号）第20条第1項の日本産業規格）X0208をいいます）であるため、不動産登記簿上の表示などと異なることがあります。
        </p>
        <p className="template-guideline-body font-weight-bold pt-4">
          （3）インターネット公売の手続きにおいて使用する時刻
        </p>
        <p className="template-guideline-body">
          インターネット公売の手続きにおいて使用する時刻は、日本国の標準時によります。
        </p>
      </div>
      <div data-testid="template-guideline-auction6_7" className="pt-6">
        <div className="template-guideline-section-title">
          7. 公売参加申し込み期間および入札期間
        </div>
        <p className="template-guideline-body pt-2">
          公売参加申し込み期間および入札期間は、公売システム上の公売物件詳細画面上に示された期間となります。ただし、システムメンテナンスなどの期間を除きます。
        </p>
      </div>
      <div data-testid="template-guideline-auction6_8" className="pt-6">
        <div className="template-guideline-section-title">
          8. ●●●インターネット公売ガイドラインの改正
        </div>
        <p className="template-guideline-body pt-2">
          ●●●は、必要があると認めるときは、このガイドラインを改正することができるものとします。
          <br />
          なお、改正を行った場合には、●●●は公売システム上に掲載することにより公表します。改正後のガイドラインは、公表した日以降に公売参加申し込みの受付を開始するインターネット公売から適用します。
        </p>
      </div>
      <div data-testid="template-guideline-auction6_9" className="pt-6">
        <div className="template-guideline-section-title">
          9. リンクの制限など
        </div>
        <p className="template-guideline-body pt-2">
          ●●●が公売システム上に情報を掲載しているウェブページへのリンクについては、●●●物件一覧のページ以外のページへの直接のリンクはできません。
          <br />
          また、公売システム上において、●●●が公開している情報（文章、写真、図面など）について、●●●に無断で転載・転用することは一切できません。
        </p>
      </div>
      <div data-testid="template-guideline-auction6_10" className="pt-6">
        <div className="template-guideline-section-title">10. その他</div>
        <p className="template-guideline-body pt-2">
          官公庁オークションサイトに掲載されている情報で、●●●が掲載したものでない情報については、●●●インターネット公売に関係する情報ではありません。
        </p>
      </div>
      <AnchorButton text="▲ 第6の先頭に戻る" />
      <div data-testid="template-guideline-auction" className="pt-9">
        <ImportantCaution
          title="インターネット公売における個人情報について"
          text="行政機関が紀尾井町戦略研究所株式会社の提供する官公庁オークションシステムを利用して行うインターネット公売における個人情報の収集主体は行政機関になります。"
        />
        <ImportantCaution
          title="クレジットカードで公売保証金を納付する場合"
          text="クレジットカードにより公売保証金を納付する公売参加者およびその代理人（以下、「公売参加者など」という）は、紀尾井町戦略研究所株式会社に対し、クレジットカードによる公売保証金納付および返還事務に関する代理権を付与し、クレジットカードによる請求処理をSBペイメントサービス株式会社に委託することを承諾します。公売参加者などは、インターネット公売が終了し、公売保証金の返還が終了するまでこの承諾を取り消せないことに同意するものとします。 また、公売参加者などは、紀尾井町戦略研究所株式会社が公売保証金取り扱い事務に必要な範囲で、公売参加者などの個人情報をSBペイメントサービス株式会社に開示することに同意するものとします。"
        />
      </div>
    </div>
  );
};

export default TemplateGuidelineAuction;
