import { validateSelected } from "../../../../application/validations.js";

export class LocationGovernmentSubCategoryId {
  #locationGovernmentSubCategoryId

  constructor(locationGovernmentSubCategoryId) {
    this.#locationGovernmentSubCategoryId = locationGovernmentSubCategoryId;
  }

  get() {
    return this.#locationGovernmentSubCategoryId
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#locationGovernmentSubCategoryId)
  }
}
