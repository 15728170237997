// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.after-winning-bid-caution
  .after-winning-bid-caution-form-require-document-label {
  display: table-cell;
  width: 13.5%;
  background-color: #e2e1da;
  max-width: 150px;
  min-width: 100px;
  font-weight: 700;
  vertical-align: middle;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.after-winning-bid-caution
  .after-winning-bid-caution-form-require-document-label
  .property-info-form-label {
  border-bottom: none;
}

.after-winning-bid-caution
  .after-winning-bid-caution-form-require-document-table-row {
  display: table-cell;
  vertical-align: middle;
}

.after-winning-bid-caution
  .after-winning-bid-caution-form-require-document-table {
  display: table;
  width: 100%;
}

.after-winning-bid-caution
  .after-winning-bid-caution-form-require-document-table
  .property-info-form-label {
  width: 11%;
}

.after-winning-bid-caution .auction-cost-table {
  position: relative;
  padding-left: 20px;
  padding-bottom: 15px;
}

.after-winning-bid-caution .auction-cost-table::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 100%;
  background-color: #e4e4e4;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/AfterWinningBidCaution/after-winning-bid-caution-form-require-document.css"],"names":[],"mappings":"AAAA;;EAEE,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,sBAAsB;EACtB,4BAA4B;EAC5B,6BAA6B;AAC/B;;AAEA;;;EAGE,mBAAmB;AACrB;;AAEA;;EAEE,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;;EAEE,cAAc;EACd,WAAW;AACb;;AAEA;;;EAGE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,UAAU;EACV,YAAY;EACZ,yBAAyB;AAC3B","sourcesContent":[".after-winning-bid-caution\n  .after-winning-bid-caution-form-require-document-label {\n  display: table-cell;\n  width: 13.5%;\n  background-color: #e2e1da;\n  max-width: 150px;\n  min-width: 100px;\n  font-weight: 700;\n  vertical-align: middle;\n  border-right: 1px solid #ccc;\n  border-bottom: 1px solid #ccc;\n}\n\n.after-winning-bid-caution\n  .after-winning-bid-caution-form-require-document-label\n  .property-info-form-label {\n  border-bottom: none;\n}\n\n.after-winning-bid-caution\n  .after-winning-bid-caution-form-require-document-table-row {\n  display: table-cell;\n  vertical-align: middle;\n}\n\n.after-winning-bid-caution\n  .after-winning-bid-caution-form-require-document-table {\n  display: table;\n  width: 100%;\n}\n\n.after-winning-bid-caution\n  .after-winning-bid-caution-form-require-document-table\n  .property-info-form-label {\n  width: 11%;\n}\n\n.after-winning-bid-caution .auction-cost-table {\n  position: relative;\n  padding-left: 20px;\n  padding-bottom: 15px;\n}\n\n.after-winning-bid-caution .auction-cost-table::before {\n  content: '';\n  display: block;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 8px;\n  height: 100%;\n  background-color: #e4e4e4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
