import { DocumentSendForm } from 'interfaces/components/common/DocumentSendForm';
import { useDocumentSendInputs } from 'shared/utils/helper/useDocumentSendInputs';

export const validateInputsDocumentSendForm = () => {
  const formInputs = useDocumentSendInputs();
  const validate = DocumentSendForm();

  function handleInvoicePostalCode(postCode) {
    validate.setters.handleInvoicePostalCodeValid(postCode);
    formInputs.setters.setInvoicePostalCode({
      ...formInputs.data.invoicePostalCode,
    });
  }

  function handleInvoicePostalCodeFirst(postCode) {
    validate.setters.handleInvoicePostalCodeFirstValid(postCode);
    formInputs.setters.setInvoicePostalCodeFirst(postCode);
  }

  function handleInvoicePostalCodeLast(postCode) {
    validate.setters.handleInvoicePostalCodeLastValid(postCode);
    formInputs.setters.setInvoicePostalCodeLast(postCode);
  }

  function handleInvoiceArea(e) {
    validate.setters.handleInvoiceAreaValid(e);
    formInputs.setters.setInvoiceArea(e.target.value);
  }

  function handleInvoiceCity(e) {
    validate.setters.handleInvoiceCityValid(e);
    formInputs.setters.setInvoiceCity(e.target.value);
  }

  function handleInvoiceAddress(e) {
    validate.setters.handleInvoiceAddressValid(e);
    formInputs.setters.setInvoiceAddress(e.target.value);
  }

  function handleInvoiceBuildName(e) {
    validate.setters.handleInvoiceBuildNameValid(e);
    formInputs.setters.setInvoiceBuildName(e.target.value);
  }

  function handleInvoiceWrittenDestination(e) {
    validate.setters.handleInvoiceWrittenDestinationValid(e);
    formInputs.setters.setInvoiceWrittenDestination(e.target.value);
  }

  function handleInvoiceDepartment(e) {
    validate.setters.handleInvoiceDepartmentValid(e);
    formInputs.setters.setInvoiceDepartment(e.target.value);
  }

  function handleInvoicePayerName(e) {
    validate.setters.handleInvoicePayerNameValid(e);
    formInputs.setters.setInvoicePayerName(e.target.value);
  }

  function handleInvoiceTel(tel) {
    validate.setters.handleInvoiceTelValid(tel);
    formInputs.setters.setInvoiceTel(tel);
  }

  function handleChangeTelNum1(tel) {
    validate.setters.handleTel1Valid(tel);
    formInputs.setters.setTelNum1(tel);
  }

  function handleChangeTelNum2(tel) {
    validate.setters.handleTel2Valid(tel);
    formInputs.setters.setTelNum2(tel);
  }

  function handleChangeTelNum3(tel) {
    validate.setters.handleTel3Valid(tel);
    formInputs.setters.setTelNum3(tel);
  }

  function handleInvoiceExtension(e) {
    validate.setters.handleInvoiceExtensionValid(e);
    formInputs.setters.setInvoiceExtension(e.target.value);
  }

  function handleInvoiceUrl(e) {
    validate.setters.handleInvoiceUrlValid(e);
    formInputs.setters.setInvoiceUrl(e.target.value);
  }

  function handleCertPostalCode(postCode) {
    validate.setters.handleCertDocumentPostalCodeValid(postCode);
    formInputs.setters.setCertPostalCode({ ...formInputs.data.certPostalCode });
  }

  function handleCertPostalCodeFirst(postCode) {
    validate.setters.handleCertDocumentPostalCodeFirstValid(postCode);
    formInputs.setters.setCertPostalCodeFirst(postCode);
  }

  function handleCertPostalCodeLast(postCode) {
    validate.setters.handleCertDocumentPostalCodeLastValid(postCode);
    formInputs.setters.setCertPostalCodeLast(postCode);
  }

  function handleCertArea(e) {
    validate.setters.handleCertDocumentAreaValid(e);
    formInputs.setters.setCertArea(e.target.value);
  }

  function handleCertCity(e) {
    validate.setters.handleCertDocumentCityValid(e);
    formInputs.setters.setCertCity(e.target.value);
  }

  function handleCertAddress(e) {
    validate.setters.handleCertDocumentAddressValid(e);
    formInputs.setters.setCertAddress(e.target.value);
  }

  function handleCertBuildName(e) {
    validate.setters.handleCertDocumentBuildNameValid(e);
    formInputs.setters.setCertBuildName(e.target.value);
  }

  function handleCertWrittenDestination(e) {
    validate.setters.handleCertDocumentWrittenDestinationValid(e);
    formInputs.setters.setCertWrittenDestination(e.target.value);
  }

  function handleCertDestination(e) {
    validate.setters.handleCertDocumentDestinationValid(e);
    formInputs.setters.setCertDestination(e.target.value);
  }

  const validateInputs = {
    data: formInputs.data,
    setters: {
      handleInvoicePostalCode,
      handleInvoicePostalCodeFirst,
      handleInvoicePostalCodeLast,
      handleInvoiceArea,
      handleInvoiceCity,
      handleInvoiceAddress,
      handleInvoiceBuildName,
      handleInvoiceWrittenDestination,
      handleInvoiceDepartment,
      handleInvoicePayerName,
      handleInvoiceTel,
      handleChangeTelNum1,
      handleChangeTelNum2,
      handleChangeTelNum3,
      handleInvoiceExtension,
      handleInvoiceUrl,
      handleCertPostalCode,
      handleCertPostalCodeFirst,
      handleCertPostalCodeLast,
      handleCertArea,
      handleCertCity,
      handleCertAddress,
      handleCertBuildName,
      handleCertWrittenDestination,
      handleCertDestination,
    },
    init: {
      setInvoicePostalCode: formInputs.setters.setInvoicePostalCode,
      setInvoicePostalCodeFirst: formInputs.setters.setInvoicePostalCodeFirst,
      setInvoicePostalCodeLast: formInputs.setters.setInvoicePostalCodeLast,
      setInvoiceArea: formInputs.setters.setInvoiceArea,
      setInvoiceCity: formInputs.setters.setInvoiceCity,
      setInvoiceAddress: formInputs.setters.setInvoiceAddress,
      setInvoiceBuildName: formInputs.setters.setInvoiceBuildName,
      setInvoiceWrittenDestination:
        formInputs.setters.setInvoiceWrittenDestination,
      setInvoiceDepartment: formInputs.setters.setInvoiceDepartment,
      setInvoicePayerName: formInputs.setters.setInvoicePayerName,
      setInvoiceTel: formInputs.setters.setInvoiceTel,
      setInvoiceExtension: formInputs.setters.setInvoiceExtension,
      setInvoiceUrl: formInputs.setters.setInvoiceUrl,
      setCertPostalCode: formInputs.setters.setCertPostalCode,
      setCertPostalCodeFirst: formInputs.setters.setCertPostalCodeFirst,
      setCertPostalCodeLast: formInputs.setters.setCertPostalCodeLast,
      setCertArea: formInputs.setters.setCertArea,
      setCertCity: formInputs.setters.setCertCity,
      setCertAddress: formInputs.setters.setCertAddress,
      setCertBuildName: formInputs.setters.setCertBuildName,
      setCertWrittenDestination: formInputs.setters.setCertWrittenDestination,
      setCertDestination: formInputs.setters.setCertDestination,
    },
    validationMessage: validate.validationMessage,
  };

  return validateInputs;
};
