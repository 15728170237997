//import useGuideline from '../../../hooks/useGuideline.js';
import GuidelineModal from './GuidelineModal';

// todo デフォルト引数はmock
const GuidelineLink = ({
  government,
  governmentId = '1',
  governmentName = 'mock government',
  procedureType = 'PUBLIC_SALE',
  guidelines = [],
}) => {
  if (!guidelines || guidelines.length === 0) {
    return;
  }

  if (government?.governmentType === 'NATIONAL_TAX_AGENCY') {
    return (
      <GuidelineModal
        guideline={guidelines[0]}
        governmentId={government?.id}
        governmentName={government?.name}
        procedureType={procedureType}
        label="ガイドライン"
      />
    );
  }

  if (guidelines.length === 1) {
    return (
      <GuidelineModal
        guideline={guidelines[0]}
        governmentId={government?.id}
        governmentName={government?.name}
        procedureType={procedureType}
        label="ガイドライン"
      />
    );
  }

  return (
    <div className="flex">
      <span>ガイドライン&emsp;[&emsp;</span>
      <span>
        <GuidelineModal
          guideline={guidelines[0]}
          governmentId={governmentId}
          governmentName={governmentName}
          procedureType={procedureType}
          label="せり売形式"
        />
      </span>
      <span>&emsp;/&emsp;</span>
      <span>
        <GuidelineModal
          guideline={guidelines[1]}
          governmentId={governmentId}
          governmentName={governmentName}
          procedureType={procedureType}
          label="入札形式"
        />
      </span>
      <span>&emsp;]</span>
    </div>
  );
};

export default GuidelineLink;
