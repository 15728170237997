
export class SummaryExclusiveSpace {
    #summaryExclusiveSpace

    constructor(summaryExclusiveSpace) {
        this.#summaryExclusiveSpace = summaryExclusiveSpace;
    }

    get() {
        return this.#summaryExclusiveSpace
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
