import AllSchedule from './AllSchedule.jsx';
import NearlySchedule from './NearlySchedule.jsx';
import ScheduleCaption from './ScheduleCaption.jsx';
import 'interfaces/css/common/layout.css';

const YearlySchedules = () => {
  return (
    <div className="inner yearly-schedule" data-testid="yearly-schedule">
      <div className="yearly-schedule-wrap">
        <ScheduleCaption/>
        <NearlySchedule/>
        <AllSchedule/>
      </div>
    </div>
  );
};

export default YearlySchedules;
