import { useRef, useState } from 'react';
import usePagination from 'shared/hooks/usePagination';
import { BIDDER_LIST_SIZE } from 'config/naviConstants';
import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import SmallLightButton from 'interfaces/components/common/Button/SmallLightButton';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import Pagination from 'interfaces/components/common/Pagination/Pagination';
import SortToolbar from 'interfaces/components/common/SortToolbar';
import PropertyListSearchInput from 'interfaces/components/Property/List/PropertyListSearchInput';
import BidderListTable from 'interfaces/components/Property/navi/BidderList/BidderListTable';

import 'interfaces/css/BidderList/bidder-list.css';
import { BidderListContext } from 'interfaces/pages/BidderList/entities/BidderListContext';
import { breadcrumbs } from 'interfaces/pages/BidderList/entities/breadcrumbs';
import { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const sortButtons = [
  {
    id: 1,
    label: '入札金額',
  },
  {
    id: 2,
    label: '入札時刻',
  },
  {
    id: 3,
    label: '受付方法',
  },
  {
    id: 4,
    label: '代理人の有無',
  },
];

const BidderList = () => {
  const { governmentUser, tableData, property, setSearchParam, setSortParam } =
    useContext(BidderListContext);
  const refMembershipId = useRef();
  const { auction_id } = useParams();
  const location = useLocation();
  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(tableData, BIDDER_LIST_SIZE);

  const [searchMembershipId, setSearchMembershipId] = useState('');
  const [type, setType] = useState('APPLICANT');

  const handleSearch = () => {
    setSearchParam({ membershipId: searchMembershipId });
  };

  const handleMembershipIdParam = (e) => {
    setSearchMembershipId(e);
  };

  const handleDeleteParam = () => {
    refMembershipId.current.value = '';
    setSearchParam({ membershipId: '' });
  };

  return (
    <div className="bidder-list" data-testid="bidder-list">
      <Breadcrumb
        breadcrumbs={breadcrumbs(auction_id, location.state.openWay)}
      />
      <Heading2 title={`入札者一覧:${property.name}`} />
      <div className="mt-5">
        <div className="bidder-num-table-wrap">
          <div
            className="bidder-list-table-count"
            data-testid="bidder-num-table">
            <dl className="bidder-list-num-table">
              <dt>入札者数</dt>
              <dd>{tableData.length}件</dd>
            </dl>
          </div>
        </div>
      </div>

      <div className="bidder-list-filter-buttons mt-5">
        <PropertyListSearchInput
          inputRef={refMembershipId}
          label="会員識別番号"
          style={{ width: '200px' }}
          handler={handleMembershipIdParam}
        />
        <SmallLightButton text="絞り込む" onClick={handleSearch} />
        <SmallLightButton text="解除" onClick={handleDeleteParam} />
      </div>

      <div className="sort-toolbar-wrap pt-5">
        <SortToolbar
        governmentUser={governmentUser}
          sortButtonData={sortButtons}
          showDelegationInfo={true}
          setSortParam={setSortParam}
          setType={setType}>
          <p>並び順</p>
        </SortToolbar>
      </div>
      <BidderListTable tableData={currentItems} type={type} />
      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
        marginTop="40px"
      />
    </div>
  );
};

export default BidderList;
