import { BASE_URL_SERVICE } from "../../../config/envConstants.js";
import { fetchData } from "../../../shared/utils/helper/fetchData.js";

const getProgress = async () => {
  const CURRENT_PROGRESS = `${BASE_URL_SERVICE}/storage/current/schedule/progress.json`;
  const PREVIOUS_PROGRESS = `${BASE_URL_SERVICE}/storage/previous/schedule/progress.json`;

  try {
    return await fetchData(CURRENT_PROGRESS);
  } catch (error) {
    console.warn(`Failed to fetch current progress: ${error.message}`);
    try {
      return await fetchData(PREVIOUS_PROGRESS);
    } catch (error) {
      console.warn(`Failed to fetch previous progress: ${error.message}`);
      return [];
    }
  }
};

export default getProgress;
