import { dateFormat } from 'shared/utils/helper/date_format';

export const FormDateTimeProps = (date, setOpen) => {
  const inputValue = () => {
    const formatDate = dateFormat(date, 'Y-M-D hh:mm');
    if (formatDate === 'DATA_EMPTY') {
      return '';
    }
    return formatDate;
  };

  return {
    inputAdornment: {
      position: 'start'
    },
    textField: {
      readOnly: true,
      onClick: () => setOpen(true),
      InputProps: {
        placeholder: '',
        value: inputValue()
      },
      sx: {
        'maxWidth': '250px',
        '& .MuiOutlinedInput-root': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.38)'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f78c23'
          }
        },
        '& .MuiInputAdornment-root .MuiSvgIcon-root': {
          color: '#f78c23'
        }
      }
    }
  };
};
