import Heading3 from 'interfaces/components/common/Heading/Heading3';
import { useEffect, useState } from 'react';
import 'interfaces/css/Faq/contact-us.css';

const ContactUs = ({ procedureType }) => {
  const [procedureTypeJp, setProcedureTypeJp] = useState('');
  const [email, setEmail] = useState('');
  const [manualPath, setManualPath] = useState('');

  useEffect(() => {
    if (procedureType === 'ASSET_SALE') {
      setProcedureTypeJp('公有財産売却');
      setEmail('contact-gov-ky@kankocho.jp');
      setManualPath('/documents/manual-asset-sale.pdf');
      return;
    }
    setProcedureTypeJp('インターネット公売');
    setEmail('contact-gov-kb@kankocho.jp');
    setManualPath('/documents/manual-auction.pdf');
  }, [procedureType]);

  return (
    <div className="contact-us mt-18" id="contact">
      <Heading3 text="お問い合わせ先" />
      <div className="px-3 py-3">
        <p className="m-0">
          {' '}
          {`${procedureTypeJp}`}
          に関するご質問は、以下までお気軽にお問い合わせください。「よくある質問」や
          <a href={`${manualPath}`} target="_blank" rel="noreferrer">
            マニュアル
          </a>
          も、ぜひご利用ください。
        </p>
        <h4 className="font-weight-regular pt-6">＜お問い合わせの一例＞</h4>
        <ul className="contact-us-example pt-2 pl-8">
          <li className="contact-us-example-item">
            官公庁ナビの使い方について
          </li>
          <li className="contact-us-example-item">
            {`${procedureTypeJp}`}の開催スケジュールについて
          </li>
          <li className="contact-us-example-item">
            一般のお客様との対応について など
          </li>
        </ul>
        <h4 className="mt-6">
          {`【${procedureTypeJp}`}ご契約行政機関様専用 お問い合わせ窓口】
        </h4>
        <div className="pt-2 pl-4">
          <div className="d-table-row">
            <div className="d-table-cell" style={{ width: '100px' }}>
              ◆ メール
            </div>
            <div className="d-table-cell">{`${email}`}</div>
          </div>
          <div className="d-table-row">
            <div className="d-table-cell" style={{ width: '100px' }}>
              ◆ 郵 便
            </div>
            <div className="d-table-cell">
              〒167-0051 東京都杉並区荻窪4-2-16
              <br />
              紀尾井町戦略研究所株式会社 KSI官公庁オークション担当
            </div>
          </div>
        </div>
        <p className="m-0 pl-4 pt-1">
          ※上記は{`${procedureTypeJp}`}
          にご契約の行政機関専用の専用窓口です。一般のお客様への回答はお受けできませんので、開示しないようにお願い申し上げます。
        </p>
        <h4 className="pt-6">【一般のお客様からのお問い合わせについて】</h4>
        <ul className="pt-2 pl-8">
          <li className="general-customer-contact-us-item">
            入札の方法など、官公庁オークションのご利用に関してのお問い合わせは、以下URLのお問い合わせフォームをご紹介ください。
            <br />
            官公庁オークションお問い合わせフォーム：
            <a
              href="https://sites.kankocho.jp/contact-user"
              target="_blank"
              rel="noreferrer">
              https://sites.kankocho.jp/contact-user
            </a>
          </li>
          <li className="general-customer-contact-us-item">
            物件に関するお問い合わせについては、出品された行政機関様でご対応ください。
          </li>
        </ul>
      </div>
    </div>
  );
};
export default ContactUs;
