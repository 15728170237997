//import { useEffect, useState } from 'react';
import { useEffect } from 'react';

import { FORGET_PASSWORD, LOGIN, SIGN_UP } from "../../../../shared/utils/helper/authState.js";
import { SERVICE, NAVI, KMANAGER } from "../../../../shared/utils/helper/constants.js";
import { GovernmentNewContext } from '../entities/GovernmentNewContext.js';

const GovernmentNewProvider = ({ type, children }) => {
  const authState = { LOGIN: LOGIN, SIGN_UP: SIGN_UP, FORGET_PASSWORD: FORGET_PASSWORD };
  //const [governmentSubCategories, setGovernmentSubCategories] = useState([])

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
    }, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      //   GovernmentCreateのインスタンス作成？
    }, []);
  }

  return (
    <GovernmentNewContext.Provider value={{
      authState,
    }}>
      {children}
    </GovernmentNewContext.Provider>
  );
};

export default GovernmentNewProvider;
