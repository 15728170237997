import Button from 'interfaces/components/common/Button';
import ConfirmSection from 'interfaces/components/common/ConfirmSection';
import {
  OrganizationInfo,
  CreditInfo,
  RefundInfo,
} from 'interfaces/components/Division/OrganizationRegisterFormSet';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import { breadcrumbs } from 'interfaces/pages/OrganizationRegister/entities/confirm/breadcrumbs';

import { useLocalStorage } from '../../../../../shared/hooks/useLocalStorage';
import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Heading3 from 'interfaces/components/common/Heading/Heading3';

const OrganizationNewConfirm = ({
  formData,
  governmentType,
  handleComplete,
  handleBackForm,
}) => {
  const organizationInfo = OrganizationInfo(formData.data);
  const creditInfo = CreditInfo(formData.data);
  const refundInfo = RefundInfo(formData.data);
  const { localStorageProcedureType } = useLocalStorage();

  return (
    <div
      className="new-style_navi_organization-confirm"
      data-testid="organization-confirm">
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <PropertyInfoGeneralPageDescription
        title={'執行機関管理：新規登録：登録内容確認'}
        content={
          '登録内容を確認してください。このまま登録する場合は「登録」ボタン、再度編集する場合は「編集」ボタンをクリックしてください。'
        }
        displayRequired={false}
      />
      <ConfirmSection inputsData={organizationInfo} />
      {formData.data.useCreditCard.toString() === 'false' ? (
        ''
      ) : (
        <>
          <Heading3 text={'1. 保証金納付口座'} />
          <ConfirmSection inputsData={creditInfo} />
          {localStorageProcedureType === 'PUBLIC_SALE' &&
          governmentType !== 'NATIONAL_TAX_AGENCY' ? (
            <>
              <Heading3 text={'2. 返還請求書送付先'} />
              <div>
                {' '}
                ※
                KSIより貴庁に不要となった保証金の返還請求書を郵送させていただくための送付先を設定してください。{' '}
              </div>
              <ConfirmSection inputsData={refundInfo} />
            </>
          ) : (
            ''
          )}
        </>
      )}
      <div className="pt-9 property-info-general-form-button-box">
        <div className="pr-1 pl-1">
          <Button
            text="登録"
            clickHandler={handleComplete}
            className="property-info-general-form-button new-style_navi_primary-button"
          />
        </div>
        <div className="pr-1 pl-1">
          <Button
            text="編集"
            clickHandler={handleBackForm}
            className="property-info-general-form-button new-style_navi_normal-button"
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationNewConfirm;
