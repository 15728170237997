export const breadcrumbs = (method, target, procedureType, id) => {
  if (method === 'create') {
    if (target === 'form') {
      return [
        { title: "官公庁ナビトップ", to: "/navi" },
        { title: `${procedureType === 'PUBLIC_SALE' ? 'インターネット公売' : '公有財産売却'}参加申込フォーム` },
      ]
    } else if (target === 'confirm') {
      return [
        { title: "官公庁ナビトップ", to: "/navi" },
        { title: `${procedureType === 'PUBLIC_SALE' ? 'インターネット公売' : '公有財産売却'}参加申込フォーム` },
        { title: '登録内容確認' }
      ]
    } else {
      return [
        { title: "官公庁ナビトップ", to: "/navi" },
        { title: `${procedureType === 'PUBLIC_SALE' ? 'インターネット公売' : '公有財産売却'}参加申込フォーム` },
        { title: '登録完了' }
      ]
    }
  } else {
    if (target === 'form') {
      return [
        { title: "官公庁ナビトップ", to: "/navi" },
        { title: '物件管理', to: `/navi/object/${id}` },
        { title: `${procedureType === 'PUBLIC_SALE' ? 'インターネット公売' : '公有財産売却'}参加申込情報修正` }
      ]
    } else if (target === 'confirm') {
      return [
        { title: "官公庁ナビトップ", to: "/navi" },
        { title: `${procedureType === 'PUBLIC_SALE' ? 'インターネット公売' : '公有財産売却'}参加申込情報修正` },
        { title: '修正内容確認' }
      ]
    } else {
      return [
        { title: "官公庁ナビトップ", to: "/navi" },
        { title: `${procedureType === 'PUBLIC_SALE' ? 'インターネット公売' : '公有財産売却'}参加申込情報修正` },
        { title: '更新完了' }
      ]
    }
  }
}
