import axios from 'axios';
import apiHeaders from 'shared/utils/helper/apiHeaders.js';
import {API_BASE_URL} from "../../config/envConstants.js";

export const fetchGovernmentsData = async () => {
  return await axios.get(
    `${API_BASE_URL}/api/v1/management/governments`,
  );
};
export const createGovernmentsData = async (body) => {
  try {
    return await axios.post(`${API_BASE_URL}/api/v1/kmanager/governments`, body, {headers: apiHeaders});
  } catch (error) {
    console.log(error)
    throw new Error(`Failed to create government data: ${error.message}`);
  }

};
export const updateGovernmentsData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/kmanager/governments/${id}`,
    body, {headers: headers}
  );
};
export const deleteGovernmentsData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/kmanager/governments/${id}`, {
      headers: headers,
    },);
};