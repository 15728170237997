import SmallTopicTitle from '../../../common/Heading/SmallTopicTitle.jsx';
import CautionManualMarkedList from '../../CautionManualMarkedList.jsx';

const RightsTransferCautionAuction = () => {
  return (
    <div className="pt-9" data-testid="rights-transfer-caution">
      <SmallTopicTitle text='落札者（落札者が法人の場合は代表者）以外の方が権利移転手続きを行う場合' isFontBold={true}/>
      <p className="sample-after-winning-bid-lead-text pt-2" data-testid="sample-after-winning-bid-lead-text">落札者本人（落札者が法人の場合はその代表者）が買受代金の支払いまたは公売物件の引き取りを行えない場合、代理人が買受代金の支払いまたは公売物件の引き取りを行えます。その場合、<span className="font-weight-bold">委任状、落札者本人と代理人双方の印鑑証明および代理人の本人確認書面が必要</span>となります。</p>
      <CautionManualMarkedList title="ご注意" className="pt-3" data={[
        {
          text: '落札者が法人で、法人の従業員の方が支払いまたは引き取りを行う場合もその従業員が代理人となり、委任状などが必要となります。',
        },
      ]}/>
    </div>
  );
};
export default RightsTransferCautionAuction;