// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import { Address } from './ApplicantInformationsCreate/address.js';
import { AplType } from './ApplicantInformationsCreate/aplType.js';
import { AuctionUserId } from './ApplicantInformationsCreate/auctionUserId.js';
import { BirthDate } from './ApplicantInformationsCreate/birthDate.js';
import { CorpName } from './ApplicantInformationsCreate/corpName.js';
import { CorpNameKana } from './ApplicantInformationsCreate/corpNameKana.js';
import { DeliveryReason } from './ApplicantInformationsCreate/deliveryReason.js';
import { FamilyName } from './ApplicantInformationsCreate/familyName.js';
import { FamilyNameKana } from './ApplicantInformationsCreate/familyNameKana.js';
import { FirstName } from './ApplicantInformationsCreate/firstName.js';
import { FirstNameKana } from './ApplicantInformationsCreate/firstNameKana.js';
import { HouseTel } from './ApplicantInformationsCreate/houseTel.js';
import { LocationGovernmentSubCategoryId } from './ApplicantInformationsCreate/locationGovernmentSubCategoryId.js';
import { MobileTel } from './ApplicantInformationsCreate/mobileTel.js';
import { PostalCode } from './ApplicantInformationsCreate/postalCode.js';
import { PropertyId } from './ApplicantInformationsCreate/propertyId.js';
import { ReceiverAddress } from './ApplicantInformationsCreate/receiverAddress.js';
import { ReceiverGovernmentSubCategoryId } from './ApplicantInformationsCreate/receiverGovernmentSubCategoryId.js';
import { ReceiverPostalCode } from './ApplicantInformationsCreate/receiverPostalCode.js';
import { ReceiverTel } from './ApplicantInformationsCreate/receiverTel.js';
import { Sex } from './ApplicantInformationsCreate/sex.js';
import { TmpAplDate } from './ApplicantInformationsCreate/tmpAplDate.js';
import { Type } from './ApplicantInformationsCreate/type.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { NotUndefined } from '../../../shared/utils/helper/NotUndefined.js';

class ApplicantCommonGet {
  // 必要なデータ
  // governmentUsers
  constructor(data) {
    this.corpName = data.corpName;
    this.corpNameKana = data.corpNameKana;
    this.familyName = data.familyName;
    this.familyNameKana = data.familyNameKana;
    this.firstName = data.firstName;
    this.firstNameKana = data.firstNameKana;
    this.sex = data.sex;
    this.birthDate = data.birthDate;
    this.postalCode = data.postalCode;
    this.address = data.address;
    this.houseTel = data.houseTel;
    this.mobileTel = data.mobileTel;
  }
}

export class ApplicantInformationsGet extends ApplicantCommonGet {
  constructor(data) {
    super(data);
    this.propertyId = data.propertyId;
    this.auctionUserId = data.auctionUserId;
    this.applicantType = data.applicantType;
    this.locationGovernmentSubCategoryId = data.locationGovernmentSubCategoryId;
    this.tmpAplDate = data.tmpAplDate;
    this.receiverPostalCode = data.receiverPostalCode;
    this.receiverGovernmentSubCategoryId = data.receiverGovernmentSubCategoryId;
    this.receiverAddress = data.receiverAddress;
    this.receiverTel = data.receiverTel;
    this.deliveryReason = data.deliveryReason;
    this.type = data.type;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class PreregistrationApplicantInformationsGet extends ApplicantCommonGet {
  constructor(data) {
    super(data);
    this.auctionUserId = data.auctionUserId;
    this.type = data.type;
    this.locationGovernmentSubCategoryId = data.locationGovernmentSubCategoryId;
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class ApplicantInformationsCreate extends ApplicantCommonGet {
  constructor(data) {
    super(data);
    this.propertyId = new PropertyId(data.propertyId);
    this.auctionUserId = new AuctionUserId(data.auctionUserId);
    this.applicantType = new AplType(data.applicantType);
    this.locationGovernmentSubCategoryId = new LocationGovernmentSubCategoryId(
      data.locationGovernmentSubCategoryId,
    );
    this.tmpAplDate = new TmpAplDate(data.tmpAplDate);
    this.receiverPostalCode = new ReceiverPostalCode(data.receiverPostalCode);
    this.receiverGovernmentSubCategoryId = new ReceiverGovernmentSubCategoryId(
      data.receiverGovernmentSubCategoryId,
    );
    this.receiverAddress = new ReceiverAddress(data.receiverAddress);
    this.receiverTel = new ReceiverTel(data.receiverTel);
    this.deliveryReason = new DeliveryReason(data.deliveryReason);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.propertyId.isValid &&
      this.auctionUserId.isValid &&
      this.applicantType.isValid &&
      this.locationGovernmentSubCategoryId.isValid &&
      this.tmpAplDate.isValid &&
      this.receiverPostalCode.isValid &&
      this.receiverGovernmentSubCategoryId.isValid &&
      this.receiverAddress.isValid &&
      this.receiverTel.isValid &&
      this.deliveryReason.isValid
    );
  }
}

class ApplicantCommonCreate {
  // 必要なデータ
  // governmentUsers
  constructor(data) {
    this.corpName = new CorpName(data.corpName);
    this.corpNameKana = new CorpNameKana(data.corpNameKana);
    this.familyName = new FamilyName(data.familyName);
    this.familyNameKana = new FamilyNameKana(data.familyNameKana);
    this.firstName = new FirstName(data.firstName);
    this.firstNameKana = new FirstNameKana(data.firstNameKana);
    this.sex = new Sex(data.sex);
    this.birthDate = new BirthDate(data.birthDate);
    this.postalCode = new PostalCode(data.postalCode);
    this.address = new Address(data.address);
    this.houseTel = new HouseTel(data.houseTel);
    this.mobileTel = new MobileTel(data.mobileTel);
  }

  get isValid() {
    return (
      this.corpName.isValid &&
      this.corpNameKana.isValid &&
      this.familyName.isValid &&
      this.familyNameKana.isValid &&
      this.firstName.isValid &&
      this.firstNameKana.isValid &&
      this.sex.isValid &&
      this.birthDate.isValid &&
      this.postalCode.isValid &&
      this.address.isValid &&
      this.houseTel.isValid &&
      this.mobileTel.isValid
    );
  }
}

export class PreregistrationApplicantInformationsCreate extends ApplicantCommonCreate {
  constructor(data) {
    super(data);
    this.userId = new AuctionUserId(data.userId);
    this.type = new Type(data.type);
    this.prefecture = new LocationGovernmentSubCategoryId(data.prefecture);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      super.isValid() &&
      this.userId.isValid &&
      this.type.isValid &&
      this.prefecture.isValid
    );
  }
}

// applicantInformation
// auctionMatching/react/src/navi/pages/ApplicantList.jsx
// proposals, auctionUsers, paymentMethods

// navi:申込者一覧
export class ApplicantListGet {
  constructor(data) {
    this.propertyId = data.propertyId;
    this.auctionUserId = data.auctionUserId;
    this.status = data.status;
    this.applicationInfo = new ApplicationInfoGet(data.applicationInfo);
    this.applicantInfo = new ApplicantInfoGet(data.applicantInfo);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class ApplicationInfoGet {
  constructor(data) {
    this.trackingId = data.trackingId;
    this.applicationDate = data.applicationDate;
    this.paymentMethod = data.paymentMethod;
    this.memo = data.memo;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

// auctionUsers membershipId, email
// applicantInformation
// 不明 jointBidding powerOfAttorney

export class ApplicantInfoGet {
  constructor(data) {
    this.membershipId = data.membershipId;
    this.email = data.email;
    this.address = data.address;
    this.familyName = data.familyName;
    this.familyNameKana = data.familyNameKana;
    this.firstName = data.firstName;
    this.firstNameKana = data.firstNameKana;
    this.corpName = data.corpName;
    this.houseTel = data.houseTel;
    this.mobileTel = data.mobileTel;
    this.sex = data.sex;
    this.birthDate = data.birthDate;
    this.jointBidding = data.jointBidding;
    this.powerOfAttorney = data.powerOfAttorney;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class ProposalGet {
  constructor(data) {
    this.aplStatus = data.aplStatus;
    this.aplType = data.aplType;
    this.auctionUserId = data.auctionUserId;
    this.cancelDate = data.cancelDate;
    this.formalAplDate = data.formalAplDate;
    this.isJointBid = data.isJointBid;
    this.isReCharge = data.isReCharge;
    this.isRepresentative = data.isRepresentative;
    this.memo = data.memo;
    this.orderId = data.orderId;
    this.processingDatetime = data.processingDatetime;
    this.propertyId = data.propertyId;
    this.settleStatus = data.settleStatus;
    this.spsTransactionId = data.spsTransactionId;
    this.tempAplDate = data.tempAplDate;
    this.trackingId = data.trackingId;
    //if (this.isValid) throw new CustomError("Proposal の値が不正です")
  }

  get isValid() {
    return NotUndefined(this);
  }
}

// proposals trackingId,memo, applicationDate(formalAplDate)
// paymentMethods paymentMethod
/*
export class ApplicationInfoCreate {
  constructor(data) {
    this.trackingId = new TrackingId(data.trackingId);
    this.applicationDate = new ApplicationDate(data.applicationDate);
    this.paymentMethod = new PaymentMethod(data.paymentMethod);
    this.memo = new (data.memo);
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return this.trackingId.isValid &&
      this.applicationDate.isValid &&
      this.paymentMethod.isValid &&
      this.memo.isValid
  }
}
*/

// auctionUsers membershipId, email
// applicantInformation
// 不明 jointBidding powerOfAttorney
/*
export class ApplicantInfoCreate {
  constructor(data) {
    this.membershipId = new MembershipId(data.membershipId);
    this.email = new Email(data.email);
    this.address = new Address(data.address);
    this.familyName = new FamilyName(data.familyName);
    this.familyNameKana = new FamilyNameKana(data.familyNameKana);
    this.firstName = new FirstName(data.firstName);
    this.firstNameKana = new FirstNameKana(data.firstNameKana);
    this.corpName = new CorpName(data.corpName);
    this.houseTel = new HouseTel(data.houseTel);
    this.mobileTel = new MobileTel(data.mobileTel);
    this.sex = new Sex(data.sex);
    this.birthDate = new BirthDate(data.birthDate);
    this.jointBidding = new JointBidding(data.jointBidding);
    this.powerOfAttorney = new PowerOfAttorney(data.powerOfAttorney);
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return this.membershipId.isValid &&
      this.email.isValid &&
      this.address.isValid &&
      this.familyName.isValid &&
      this.familyNameKana.isValid &&
      this.firstName.isValid &&
      this.firstNameKana.isValid &&
      this.corpName.isValid &&
      this.houseTel.isValid &&
      this.mobileTel.isValid &&
      this.sex.isValid &&
      this.birthDate.isValid &&
      this.jointBidding.isValid &&
      this.powerOfAttorney.isValid
  }
}

*/

/*
export class ProposalsMemoCreate {
  constructor(data) {
    this.propertyId = new PropertyId(data.propertyId)
    this.auctionUserId = new AuctionUserId(data.auctionUserId)
    this.memo = new Memo(data.memo);
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return this.propertyId.isValid &&
      this.auctionUserId.isValid &&
      this.memo.isValid.isValid
  }
}
 */

export class SbPaymentGet {
  constructor(data) {
    this.expiration1 = data.expiration1;
    this.num1 = data.num1;
    this.num2 = data.num2;
    this.num3 = data.num3;
    this.num4 = data.num4;
  }

  get isValid() {
    return NotUndefined(this);
  }
}

/*
export class SbPaymentCreate {
  constructor(data) {
    this.expiration1 = new Expiration1(data.expiration1)
    this.num1 = new Num1(data.num1)
    this.num2 = new Num2(data.num2)
    this.num3 = new Num3(data.num3)
    this.num4 = new Num4(data.num4)
  }

  get isValid() {
    return this.expiration1.isValid &&
      this.num1.isValid &&
      this.num2.isValid &&
      this.num3.isValid &&
      this.num4.isValid
  }
}

 */

export class CancelProposal {
  constructor(data) {
    this.propertyId = data.propertyId;
    this.auctionUserId = data.auctionUserId;
  }
}

export class AuctionUserGet {
  constructor(data) {
    this.id = data.id;
    this.email = data.email;
    this.identificationNumber = data.identificationNumber;
    this.membershipId = data.membershipId;
    this.status = data.status;
  }
}
