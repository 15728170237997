// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createPromotionFramesData,
  deletePromotionFramesData,
  updatePromotionFramesData,
} from '../../../infrastructure/api/PromotionFrames.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { PromotionFrameCreate } from '../entities/Promotion.js';
import { getPromotionFrame } from '../repositories/PromotionFrame.js';

export async function get(type) {
  return await getPromotionFrame(type);
}

export async function findById(type, id) {
  const Promotion = await getPromotionFrame(type);
  return Promotion.find((data) => data.id === id);
}

export async function findByIsActive(type, isActive) {
  const frames = await getPromotionFrame(type);
  return frames.filter((data) => data.isActive === isActive);
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    // todo: serviceのcreate用のapi呼び出し
    // response = await CREATE(data);
  }
  if (type === NAVI) {
    // todo: naviのcreate用のapi呼び出し
    // response = await CREATE(data);
  }
  if (type === KMANAGER) {
    const promotion = new PromotionFrameCreate(data);
    response = await createPromotionFramesData(promotion);
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Promotion', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    // todo: serviceのupdate用のapi呼び出し
    // response = await UPDATE(data);
  }
  if (type === NAVI) {
    // todo: naviのupdate用のapi呼び出し
    // response = await UPDATE(data);
  }
  if (type === KMANAGER) {
    const promotion = new PromotionFrameCreate(data);
    response = await updatePromotionFramesData(promotion, promotion.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Promotion', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    // todo: serviceのdelete用のapi呼び出し
    // response = DELETE(data.id);
  }
  if (type === NAVI) {
    // todo: naviのdelete用のapi呼び出し
    // response = DELETE(data.id);
  }
  if (type === KMANAGER) {
    response = await deletePromotionFramesData(data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Promotion', response);
  }
  return response;
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
