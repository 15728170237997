import SampleAfterWinningBidCautionProvider from './SampleAfterWinningBidCautionProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { sampleAfterWinningBidCaution } from "../entities/componentMapping.js";

const SampleAfterWinningBidCaution = ({ type }) => {
  return (
    <SampleAfterWinningBidCautionProvider type={type}>
      {getComponent(sampleAfterWinningBidCaution, type)}
    </SampleAfterWinningBidCautionProvider>
  );
};

export default SampleAfterWinningBidCaution;
