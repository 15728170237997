import styled from 'styled-components';

import FormAttentionSectionBody from './FormAttentionSectionBody.jsx';
import FormAttentionSectionTitle from './FormAttentionSectionTitle.jsx';

const Section = styled.div`
  position: relative;
  background: #fff;
  border: 1px solid var(--line-base);
  border-radius: 4px;
`;

const Wrapper = styled.div`
  font-size: 0.75rem;
  border: 2px solid var(--warning-base);
`;

const FormAttentionSection = ({ procedureType, propertyType }) => {
  return (
    <Section className="px-6 py-8 mb-8">
      <Wrapper className="pa-3">
        <FormAttentionSectionTitle title="必ずご確認ください" />
        <FormAttentionSectionBody
          procedureType={procedureType}
          propertyType={propertyType}
        />
      </Wrapper>
    </Section>
  );
};
export default FormAttentionSection;
