import { useEffect, useState } from 'react';
import { filterByKey } from 'shared/utils/helper/filter';

import { findByGovernmentId as getInvitations } from '../../../../domain/Auction/services/Invitation.js';
import { findByGovernmentId as getDivisions } from '../../../../domain/Division/services/Division.js';
// import { findById as getGovernment } from '../../../../domain/Government/services/Government.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { NAVI } from '../../../../shared/utils/helper/constants.js';
//import { get as getDivisions } from "../../../../domain/Division/services/Division.js";
//import { get as getAuctions } from "../../../../domain/Auction/services/Auction.js";
import { OrganizationContext } from '../entities/OrganizationContext.js';

const OrganizationProvider = ({ type, children }) => {
  //const { governmentId, governmentType, procedureType } = useAuth();
  const { governmentId } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [divisions, setDivisions] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [auctions] = useState([]);
  const [governmentCategory] = useState([]);
  const [governmentSubCategory] = useState([]);
  const {
    localStorageGovernmentId,
    localStorageProcedureType,
    localStorageGovernmentType,
  } = useLocalStorage();
  const [isNotEditable, setIsNotEditable] = useState([]);
  const [governmentType, setGovernmentType] = useState([]);
  const [procedureType, setProcedureType] = useState('');

  if (type === NAVI) {
    useEffect(() => {
      // 執行機関情報取得
      getDivisions(type, localStorageGovernmentId).then((result) => {
        setDivisions(result);
      });

      // オークションに参加しているかチェック
      getInvitations(type, localStorageGovernmentId).then((result) => {
        setInvitations(result);
        // todo: v1からの移植 db上は存在していないが、auctionStatus は存在するのか？
        const filteredByAuctionStatus = filterByKey(
          result,
          'auctionStatus',
          'DURING',
        );
        // 現在開催中のオークションに参加中の場合は編集ボタンを非活性にする
        setIsNotEditable(filteredByAuctionStatus.length > 0);
      });

      setGovernmentType(localStorageGovernmentType);

      setProcedureType(localStorageProcedureType);
    }, [localStorageGovernmentId, localStorageGovernmentType]);
  }

  return (
    <OrganizationContext.Provider
      value={{
        authState,
        governmentId,
        governmentType,
        procedureType,
        divisions,
        invitations,
        auctions,
        governmentCategory,
        governmentSubCategory,
        isNotEditable,
      }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationProvider;
