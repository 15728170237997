import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { queryParams } from "../../../../../../pages/Dashboard/entities/queryParam.js";
import AccordionWithCheckBox from "../AccordionWithCheckBox.jsx";

const GrandChildCategories = ({ categories, isSeeAll, showCheckBox, showCount, checkBoxState }) => {
  const initialArray = new Array(categories.length).fill(false);
  const [grandChildCheckBoxStates, setGrandChildCheckBoxStates] = useState(initialArray);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const updateArray = [...grandChildCheckBoxStates];
    if (searchParams.has(queryParams.SEARCH_PROPERTY_CATEGORY_ID)) {
      const searchCategoryIds = searchParams.get(queryParams.SEARCH_PROPERTY_CATEGORY_ID);
      if (searchCategoryIds !== "") {
        const searchPropertyCategoryId = searchCategoryIds.split(',');
        categories.forEach((category, index) => {
          searchPropertyCategoryId.includes(category.id.toString()) ? updateArray[index] = true : null;
        })
        setGrandChildCheckBoxStates(updateArray);
      }
    }
  }, [categories]);

  return categories.map((category, index) => {
    if (category.displayService !== 'DISPLAY') {
      return null;
    }
    if (index > 4) {
      return <AccordionWithCheckBox
        key={category.id}
        id="grand-child-category"
        categoryData={category}
        marginLeft={40}
        style={isSeeAll ? {} : { display: "none" }}
        checkBoxState={checkBoxState}
        showCheckBox={showCheckBox}
        showCount={showCount}
        checkboxActive={grandChildCheckBoxStates[index]}
      />
    } else {
      return <AccordionWithCheckBox
        key={category.id}
        id="grand-child-category"
        categoryData={category}
        marginLeft={40}
        style={{}}
        checkBoxState={checkBoxState}
        showCheckBox={showCheckBox}
        showCount={showCount}
        checkboxActive={grandChildCheckBoxStates[index]}
      />
    }
  });
};

export default GrandChildCategories;
