// ロジックを含まない処理の実装

import { fetchAssetSaleCautionPageStorage } from '../../../infrastructure/api/storage/asset_sale_caution_page.js';
import { fetchPublicSaleCautionPagesStorage } from '../../../infrastructure/api/storage/public_sale_caution_pages.js';
import {
  ASSET_SALE,
  KMANAGER,
  NAVI,
  PUBLIC_SALE,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import {
  SettingPublicCautionPageGet,
  SettingAssetCautionPageGet,
} from '../entities/Setting.js';

// getメソッドの実装
export async function getCaution(type, procedureType) {
  // SAMPLE
  let response = [];
  if (type === SERVICE) {
    if (procedureType === PUBLIC_SALE) {
      response = await fetchPublicSaleCautionPagesStorage();
    }
    if (procedureType === ASSET_SALE) {
      response = await fetchAssetSaleCautionPageStorage();
    }
  }
  if (type === NAVI) {
    if (procedureType === PUBLIC_SALE) {
      response = await fetchPublicSaleCautionPagesStorage();
    }
    if (procedureType === ASSET_SALE) {
      response = await fetchAssetSaleCautionPageStorage();
    }
  }
  if (type === KMANAGER) {
    if (procedureType === PUBLIC_SALE) {
      response = await fetchPublicSaleCautionPagesStorage();
    }
    if (procedureType === ASSET_SALE) {
      response = await fetchAssetSaleCautionPageStorage();
    }
  }

  if (response instanceof Error) {
    throw new CustomError('Error get CautionPage', response);
  }

  let results = [];
  if (procedureType === PUBLIC_SALE) {
    response.map((data) => {
      const result = new SettingPublicCautionPageGet(data);
      results.push(result);
    });
  }
  if (procedureType === ASSET_SALE) {
    response.map((data) => {
      const result = new SettingAssetCautionPageGet(data);
      results.push(result);
    });
  }

  return results;
}
