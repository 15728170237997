// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kmanager-main-area {
  top: 64px;
  width: calc(100% - 300px);
  left: 300px;
  transform: translateX(0px);
  position: fixed;
  overflow: auto;
  z-index: 2;
  transition-property: transform;
  transition-duration: .3s;
  height: calc(100% - 64px);
}

.kmanager-main-area-padding {
  display: block;
}

.kmanager-main-area.not-active {
  width: 100%;
  transform: translateX(-300px);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/kmanager-main.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,yBAAyB;EACzB,WAAW;EACX,0BAA0B;EAC1B,eAAe;EACf,cAAc;EACd,UAAU;EACV,8BAA8B;EAC9B,wBAAwB;EACxB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,6BAA6B;AAC/B","sourcesContent":[".kmanager-main-area {\n  top: 64px;\n  width: calc(100% - 300px);\n  left: 300px;\n  transform: translateX(0px);\n  position: fixed;\n  overflow: auto;\n  z-index: 2;\n  transition-property: transform;\n  transition-duration: .3s;\n  height: calc(100% - 64px);\n}\n\n.kmanager-main-area-padding {\n  display: block;\n}\n\n.kmanager-main-area.not-active {\n  width: 100%;\n  transform: translateX(-300px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
