import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody';
import 'interfaces/css/YearlySchedules/schedule-caption.css'

const ScheduleCaption = () => {
  return (
    <div className="schedule-caption" data-testid="schedule-caption">
      <ArticleDefaultBody>
        <p>KSI官公庁オークションでは、インターネット公売や公有財産売却を開催しています。</p>
        <p>インターネット公売や公有財産売却は、開催スケジュールが決まっています。</p>
        <p>掘り出し物やお宝を手に入れるチャンスを見逃さないように、年間開催スケジュールをぜひチェックしてください。</p>
        <p>※KSI官公庁オークションに参加するには、参加申し込み期間中に入札したい物件ごとに参加申し込みの手続きが必要です。当サービスに会員登録（無料）をすると、開催前にメールで開催をお知らせします。</p>
      </ArticleDefaultBody>
    </div>
  );

};

export default ScheduleCaption;
