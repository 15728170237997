// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-user-info-title {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: .1em;
  border-bottom: solid 1px rgba(0,0,0,.12);
  padding-bottom: 8px;
  margin-bottom: 20px;
}

.edit-user-info-body-caption {
  font-size: .8rem;
  color: var(--caption-text);
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/EditUserInfo/edit-user-info.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;EACpB,wCAAwC;EACxC,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,mBAAmB;AACrB","sourcesContent":[".edit-user-info-title {\n  font-size: 1.5rem;\n  font-weight: 700;\n  letter-spacing: .1em;\n  border-bottom: solid 1px rgba(0,0,0,.12);\n  padding-bottom: 8px;\n  margin-bottom: 20px;\n}\n\n.edit-user-info-body-caption {\n  font-size: .8rem;\n  color: var(--caption-text);\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
