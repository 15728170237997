
export class IsNextBuy {
  #isNextBuy

  constructor(isNextBuy) {
    this.#isNextBuy = isNextBuy;
  }

  get() {
    return this.#isNextBuy
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
