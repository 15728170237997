import Button from 'interfaces/components/common/Button';
import ButtonList from 'interfaces/components/common/ButtonList';
import ManagementDataList from 'interfaces/components/common/ManagementDataList';
import Pagination from 'interfaces/components/common/Pagination.jsx';
import { useState } from 'react';
import { useMultipleChecked } from 'shared/hooks/useCheckBox';
import useModalControl from 'shared/hooks/useModalControl';

//import {useParams} from "react-router-dom";

//import {
//  fetchBillingDates,
//  fetchCountApplyReductions,
//  fetchEstimatedSystemUsagePaymentDates,
//  fetchInvoice,
//  fetchSystemUsageFeeAuction,
//  fetchSystemUsageFeeAuctions,
//  fetchSystemUsageFeeBid,
//  fetchSystemUsageFeeBids,
//  fetchSystemUsagePaymentDates,
//  fetchSystemUsageTax,
//  fetchSystemUsageTaxAuction,
//  fetchSystemUsageTaxAuctions,
//  fetchSystemUsageTaxBid,
//  fetchSystemUsageTaxBids,
//  fetchSystemUsageTaxes
//} from "../../api/usageFee";
//import {fetchLocations, fetchNames, fetchPrefectures} from "../../api/auctionGovernments";
//import axios from "axios";
//import {useDownloadFiles} from "shared/hooks/useDownloadFeeFiles";
import UsageFeeGovernmentsTable from './AuctionUsageFeeGovernmentsTable';
import { ReturnDateModal } from './ReturnDateModal';
import usePagination from 'shared/hooks/usePagination';

// TODO: 請求書ダウンロード, 請求書明細ダウンロード, 請求書送付先(CSV)ダウンロード, 入金登録のAPIをfetchするように実装する

const UsageFeeTable = ({ auction, usageFees = [] }) => {
  //const {
  //  downloadSystemUsageFees,
  //  downloadSystemUsageFeesDetail,
  //  downloadBusinessInspectionReport,
  //  downloadSystemUsageFeesAddress,
  ////  loading
  //} = useDownloadFiles();

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(usageFees, rowsPerPage);

  const editDate = (e) => {
    console.log('editDate');
    console.log(e);
    handleOpen();
  };

  //const {auctionId} = useParams();
  const { isOpen, handleOpen, handleClose } = useModalControl();
  //const [invoice, setInvoice] = useState(true);
  //const [managementData, setManagementData] = useState([]);
  //const [governmentTableData, setGovernmentTableData] = useState({});
  //const { checked } = useMultipleChecked(governmentTableData.governments?.map((row) => row.id));
  const { checked, checkedList, allCheck, clearCheck } = useMultipleChecked(
    usageFees.map((row) => row.governmentId),
  );

  const FETCH_BUTTONS_DATA = [
    {
      text: '請求書ダウンロード',
      //clickHandler: () => downloadSystemUsageFees('請求書ダウンロード.json'),
      clickHandler: () => {},
      className: 'small primary',
      isDisabled: Array.isArray(checked) && !checked.length,
    },
    {
      text: '請求書明細ダウンロード',
      //clickHandler: () => downloadSystemUsageFeesDetail('請求書明細ダウンロード.json'),
      clickHandler: () => {},
      className: 'small primary',
      isDisabled: Array.isArray(checked) && !checked.length,
    },
    {
      text: '請求書送付先(CSV)ダウンロード',
      //clickHandler: () => downloadSystemUsageFeesAddress('請求書送付先(CSV)ダウンロード.json'),
      clickHandler: () => {},
      className: 'small primary',
      isDisabled: Array.isArray(checked) && !checked.length,
    },
    auction.governmentTypeGroup === 'NATIONAL_TAX_AGENCY'
      ? {
          text: '業務検査結果連絡書作成用(CSV)ダウンロード',
          //clickHandler: () => downloadBusinessInspectionReport('業務検査結果連絡書作成用(CSV)ダウンロード.json'),
          clickHandler: () => {},
          className: 'small primary',
          isDisabled: false,
        }
      : null,
    {
      text: '入金日登録',
      clickHandler: editDate,
      isOutline: true,
      className: 'small success',
      isDisabled: Array.isArray(checked) && !checked.length,
    },
  ];

  const managementData = () => {
    let totalAuction = 0;
    let totalBid = 0;
    let totalTax = 0;
    let total = 0;

    usageFees.forEach((usageFee) => {
      totalAuction += usageFee.systemUsageFeeAuction;
      totalBid += usageFee.systemUsageFeeBid;
      totalTax +=
        usageFee.systemUsageFeeAuctionTax + usageFee.systemUsageFeeBidTax;
      total += usageFee.systemUsageFeeTotal;
    });

    return [
      [
        {
          value: 'システム利用料合計(せり)',
          colSize: 3,
          colMdSize: null,
        },
        {
          value: totalAuction,
          colSize: 3,
          colMdSize: null,
          isYen: true,
        },
        {
          value: '消費税合計',
          colSize: 3,
          colMdSize: null,
        },
        {
          value: totalTax,
          colSize: 3,
          colMdSize: null,
          isYen: true,
        },
      ],
      [
        {
          value: 'システム利用料合計(入札)',
          colSize: 3,
          colMdSize: null,
        },
        {
          value: totalBid,
          colSize: 3,
          colMdSize: null,
          isYen: true,
        },
        {
          value: '請求額合計',
          colSize: 3,
          colMdSize: null,
        },
        {
          value: total,
          colSize: 3,
          colMdSize: null,
          isYen: true,
        },
      ],
    ];
  };

  //const setAfterInvoiceData = async () => {
  //  const systemUsageFeeAuction = await fetchSystemUsageFeeAuction(auctionId)
  //  const systemUsageFeeAuctionAmount = systemUsageFeeAuction.data.data.systemUsageFeeAuctionAmount;
  //  const systemUsageFeeBid = await fetchSystemUsageFeeBid(auctionId)
  //  const systemUsageFeeBidAmount = systemUsageFeeBid.data.data.systemUsageFeeBidAmount;
  //  const systemUsageTax = await fetchSystemUsageTax(auctionId)
  //  const systemUsageTaxAmount = systemUsageTax.data.data.systemUsageTaxAmount;
  //  const systemUsageFeeAmount =
  //    systemUsageFeeAuctionAmount +
  //    systemUsageFeeBidAmount +
  //    systemUsageTaxAmount

  //  setManagementData([
  //    [
  //      {
  //        value: 'システム利用料合計(せり)',
  //        colSize: 3,
  //        colMdSize: null,
  //      },
  //      {
  //        value: systemUsageFeeAuctionAmount,
  //        colSize: 3,
  //        colMdSize: null,
  //        isYen: true,
  //      },
  //      {
  //        value: '消費税合計',
  //        colSize: 3,
  //        colMdSize: null,
  //      },
  //      {
  //        value: systemUsageTaxAmount,
  //        colSize: 3,
  //        colMdSize: null,
  //        isYen: true,
  //      },
  //    ],
  //    [
  //      {
  //        value: 'システム利用料合計(入札)',
  //        colSize: 3,
  //        colMdSize: null,
  //      },
  //      {
  //        value: systemUsageFeeBidAmount,
  //        colSize: 3,
  //        colMdSize: null,
  //        isYen: true,
  //      },
  //      {
  //        value: '請求額合計',
  //        colSize: 3,
  //        colMdSize: null,
  //      },
  //      {
  //        value: systemUsageFeeAmount,
  //        colSize: 3,
  //        colMdSize: null,
  //        isYen: true,
  //      },
  //    ],
  //  ])

  //  //const locations = await fetchLocations(auctionId, "ASC");
  //  //const prefectures = await fetchPrefectures(auctionId, "ASC");
  //  //const name = await fetchNames(auctionId, "ASC");
  //  //const countApplyReductions = await fetchCountApplyReductions(auctionId, "ASC");
  //  //const systemUsageFeeAuctions = await fetchSystemUsageFeeAuctions(auctionId, "ASC");
  //  //const systemUsageFeeBids = await fetchSystemUsageFeeBids(auctionId, "ASC");
  //  //const systemUsageTaxes = await fetchSystemUsageTaxes(auctionId, "ASC");
  //  //const billingDates = await fetchBillingDates(auctionId, "ASC");
  //  //const estimatedSystemUsagePaymentDates = await fetchEstimatedSystemUsagePaymentDates(auctionId, "ASC");
  //  //const systemUsagePaymentDates = await fetchSystemUsagePaymentDates(auctionId, "ASC");

  //  let governments = [];
  //  for (let i = 0; i < locations.data.data.length; i++) {
  //    governments.push(
  //      {
  //        id: locations.data.data[i].id,
  //        location: locations.data.data[i].location,
  //        subCategoryName: prefectures.data.data[i].prefecture,
  //        name: name.data.data[i].name,
  //        propertyCountApplyReduction: Number(countApplyReductions.data.data[i].isApplyReduction),
  //        propertyAucSysUsageFees: Number(systemUsageFeeAuctions.data.data[i].systemUsageFeeAuction),
  //        propertyBidSysUsageFees: Number(systemUsageFeeBids.data.data[i].systemUsageFeeBid),
  //        propertyConsumptionTax: Number(systemUsageTaxes.data.data[i].systemUsageTax),
  //        systemUsageFeeTotal:
  //          Number(systemUsageFeeAuctions.data.data[i].systemUsageFeeAuction) +
  //          Number(systemUsageFeeBids.data.data[i].systemUsageFeeBid) +
  //          Number(systemUsageTaxes.data.data[i].systemUsageTax),
  //        billingDate: billingDates.data.data[i].billingDate,
  //        estimatedPaymentDate: estimatedSystemUsagePaymentDates.data.data[i].estimatedSystemUsagePaymentDate,
  //        paymentDate: systemUsagePaymentDates.data.data[i].systemUsagePaymentDate
  //      }
  //    )
  //  }
  //  setGovernmentTableData({
  //    headlines: ['地域', '都道府県', '行政機関', '減額申請件数', 'システム利用料(せり)', 'システム利用料(入札)', '消費税', '請求額', '請求日', '入金予定日', '入金日'],
  //    governments: governments
  //  })
  //}

  //const setBeforeInvoiceData = async () => {
  //  const systemUsageFeeAuction = await fetchSystemUsageFeeAuction(auctionId)
  //  const systemUsageFeeAuctionAmount = systemUsageFeeAuction.data.data.systemUsageFeeAuctionAmount;
  //  const systemUsageFeeBid = await fetchSystemUsageFeeBid(auctionId)
  //  const systemUsageFeeBidAmount = systemUsageFeeBid.data.data.systemUsageFeeBidAmount;
  //  const systemUsageTaxAuction = await fetchSystemUsageTaxAuction(auctionId)
  //  const systemUsageTaxAuctionAmount = systemUsageTaxAuction.data.data.systemUsageTaxAuctionAmount;
  //  const systemUsageTaxBid = await fetchSystemUsageTaxBid(auctionId)
  //  const systemUsageTaxBidAmount = systemUsageTaxBid.data.data.systemUsageTaxBidAmount;
  //  const systemUsageFeeAmount =
  //    systemUsageFeeAuctionAmount +
  //    systemUsageFeeBidAmount +
  //    systemUsageTaxAuctionAmount +
  //    systemUsageTaxBidAmount;

  //  setManagementData([
  //    [
  //      {
  //        value: 'システム利用料合計(せり)',
  //        colSize: 6,
  //        colMdSize: 3,
  //      },
  //      {
  //        value: systemUsageFeeAuctionAmount,
  //        colSize: 6,
  //        colMdSize: 2,
  //        isYen: true,
  //      },
  //      {
  //        value: '消費税合計(せり)',
  //        colSize: 6,
  //        colMdSize: 2,
  //      },
  //      {
  //        value: systemUsageTaxAuctionAmount,
  //        colSize: 6,
  //        colMdSize: 2,
  //        isYen: true,
  //      },
  //    ],
  //    [
  //      {
  //        value: 'システム利用料合計(入札)',
  //        colSize: 6,
  //        colMdSize: 3,
  //      },
  //      {
  //        value: systemUsageFeeBidAmount,
  //        colSize: 6,
  //        colMdSize: 2,
  //        isYen: true,
  //      },
  //      {
  //        value: '消費税合計(入札)',
  //        colSize: 6,
  //        colMdSize: 2,
  //      },
  //      {
  //        value: systemUsageTaxBidAmount,
  //        colSize: 6,
  //        colMdSize: 2,
  //        isYen: true,
  //      },
  //      {
  //        value: '請求額合計',
  //        colSize: null,
  //        colMdSize: null,
  //      },
  //      {
  //        value: systemUsageFeeAmount,
  //        colSize: null,
  //        colMdSize: null,
  //        isYen: true,
  //      },
  //    ],
  //  ])

  //  const locations = await fetchLocations(auctionId, "ASC");
  //  const prefectures = await fetchPrefectures(auctionId, "ASC");
  //  const name = await fetchNames(auctionId, "ASC");
  //  const countApplyReductions = await fetchCountApplyReductions(auctionId, "ASC");
  //  const systemUsageFeeAuctions = await fetchSystemUsageFeeAuctions(auctionId, "ASC");
  //  const systemUsageFeeBids = await fetchSystemUsageFeeBids(auctionId, "ASC");
  //  const systemUsageTaxAuctions = await fetchSystemUsageTaxAuctions(auctionId, "ASC");
  //  const systemUsageTaxBids = await fetchSystemUsageTaxBids(auctionId, "ASC");
  //  const billingDates = await fetchBillingDates(auctionId, "ASC");
  //  const estimatedSystemUsagePaymentDates = await fetchEstimatedSystemUsagePaymentDates(auctionId, "ASC");
  //  const systemUsagePaymentDates = await fetchSystemUsagePaymentDates(auctionId, "ASC");

  //  let governments = [];
  //  for (let i = 0; i < locations.data.data.length; i++) {
  //    governments.push(
  //      {
  //        id: locations.data.data[i].id,
  //        location: locations.data.data[i].location,
  //        subCategoryName: prefectures.data.data[i].prefecture,
  //        name: name.data.data[i].name,
  //        propertyCountApplyReduction: Number(countApplyReductions.data.data[i].isApplyReduction),
  //        propertyAucSysUsageFees: Number(systemUsageFeeAuctions.data.data[i].systemUsageFeeAuction),
  //        propertyAucConsumptionTax: Number(systemUsageTaxAuctions.data.data[i].systemUsageTaxAuction),
  //        propertyBidSysUsageFees: Number(systemUsageFeeBids.data.data[i].systemUsageFeeBid),
  //        propertyBidConsumptionTax: Number(systemUsageTaxBids.data.data[i].systemUsageTaxBid),
  //        systemUsageFeeTotal:
  //          Number(systemUsageFeeAuctions.data.data[i].systemUsageFeeAuction) +
  //          Number(systemUsageTaxAuctions.data.data[i].systemUsageTaxAuction) +
  //          Number(systemUsageFeeBids.data.data[i].systemUsageFeeBid) +
  //          Number(systemUsageTaxBids.data.data[i].systemUsageTaxBid),
  //        billingDate: billingDates.data.data[i].billingDate,
  //        estimatedPaymentDate: estimatedSystemUsagePaymentDates.data.data[i].estimatedSystemUsagePaymentDate,
  //        paymentDate: systemUsagePaymentDates.data.data[i].systemUsagePaymentDate
  //      }
  //    )
  //  }

  //  setGovernmentTableData({
  //    headlines: ['地域', '都道府県', '行政機関', '減額申請件数', 'システム利用料(せり)', '消費税(せり)', 'システム利用料(入札)', '消費税(入札)', '請求額', '請求日', '入金予定日', '入金日'],
  //    governments: governments
  //  })
  //}

  //useEffect(() => {
  //  (async () => {
  //    try {
  //      const isInvoice = await fetchInvoice(auctionId)
  //      setInvoice(isInvoice.data.data.invoice);
  //      if (isInvoice.data.data.invoice) {
  //        await setAfterInvoiceData()
  //      } else {
  //        await setBeforeInvoiceData()
  //      }
  //    } catch (err) {
  //      console.error(err)
  //    }

  //  })()
  //}, []);

  // 入金日登録ボタンクリック時の動作
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log('todo: fix submit handler');
    //  const headers = {
    //    'Content-Type': 'application/json',
    //  };
    //  const body = {
    //    "system_usage_payment_date": date.toLocaleDateString('sv-SE')
    //  }
    //  try {
    //    for (const id of checked) {
    //      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/v1/management/auctions/${auctionId}/${id}/system-usage-payment-dates`, body, {headers: headers})
    //    }
    //  } catch (error) {
    //    return error
    //  }
    //  handleClose();
  };

  const handleChange = (selectDate) => {
    setDate(selectDate);
  };

  const [date, setDate] = useState('');

  return (
    <div data-testid="kmanager-usage-fee-table">
      <ButtonList>
        {FETCH_BUTTONS_DATA.map((item, index) =>
          item ? (
            <Button
              key={index}
              text={item.text}
              clickHandler={item.clickHandler}
              isOutline={item.isOutline}
              isDisabled={item.isDisabled}
              className={item.className}
            />
          ) : (
            ''
          ),
        )}
      </ButtonList>
      <ManagementDataList data={managementData()} />
      <ReturnDateModal
        isOpen={isOpen}
        handleClose={handleClose}
        label={'入金日登録'}
        submitHandler={submitHandler}
        date={date}
        handleChange={handleChange}
      />
      <div className="box-shadow">
        <div style={{ overflowX: 'auto', marginTop: '12px' }}>
          <UsageFeeGovernmentsTable
            auction={auction}
            usageFees={currentItems}
            handleOpenModal={handleOpen}
            checked={checked}
            checkedList={checkedList}
            allCheck={allCheck}
            clearCheck={clearCheck}
          />
        </div>
        <Pagination
          currentPage={currentPage}
          pageCount={pageNumbers.length}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          DATA_COUNT={usageFees.length}
        />
      </div>
    </div>
  );
};
export default UsageFeeTable;
