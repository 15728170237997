
export class RelationshipCategoryIds {
    #relationshipCategoryIds

    constructor(relationshipCategoryIds) {
        this.#relationshipCategoryIds = relationshipCategoryIds;
    }

    get() {
        return this.#relationshipCategoryIds
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
