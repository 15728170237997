import 'interfaces/css/common/caution-topic-record.css'

const CautionTopicRecord = ({ headline, content, isBgGray = false }) => {
  return (
    <tr data-testid="caution-topic-record">
      <th
        className={`record-headline ${isBgGray ? 'record-headline-bg-gray' : ''}`}
        data-testid="record-headline"
      >
        { headline }
      </th>
      <td className="record-content" data-testid="record-content">{ content }</td>
    </tr>
  );
};
export default CautionTopicRecord;