// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-button {
    & input {
        display: none;
    }

    --switchHeight: 1rem;
    --switchWidth: 2.5rem;
    --switchPadding: -0.2rem;

    --circleSize: calc(var(--switchHeight) - var(--switchPadding) * 2);

    & label {
        display: inline-block;
        cursor: pointer;
        position: relative;
        width: var(--switchWidth);
        height: var(--switchHeight);
        border-radius: var(--switchHeight);

        background: #ccc;
        transition: 0.4s;

        &:after {
            cursor: pointer;
            content: '';
            position: absolute;
            left: var(--switchPadding);
            top: 0;
            bottom: 0;
            margin: auto;

            width: var(--circleSize);
            height: var(--circleSize);
            border-radius: 50%;

        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

            background: #fff;
            transition: 0.4s;
        }
    }

    & input:checked {
        + label {
            background-color: #ff7f00;

            &:after {
                left: calc(
                        var(--switchWidth) - var(--circleSize) - var(--switchPadding)
                );
            }
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/toggle.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;;IAEA,oBAAoB;IACpB,qBAAqB;IACrB,wBAAwB;;IAExB,kEAAkE;;IAElE;QACI,qBAAqB;QACrB,eAAe;QACf,kBAAkB;QAClB,yBAAyB;QACzB,2BAA2B;QAC3B,kCAAkC;;QAElC,gBAAgB;QAChB,gBAAgB;;QAEhB;YACI,eAAe;YACf,WAAW;YACX,kBAAkB;YAClB,0BAA0B;YAC1B,MAAM;YACN,SAAS;YACT,YAAY;;YAEZ,wBAAwB;YACxB,yBAAyB;YACzB,kBAAkB;;QAEtB,sCAAsC;;YAElC,gBAAgB;YAChB,gBAAgB;QACpB;IACJ;;IAEA;QACI;YACI,yBAAyB;;YAEzB;gBACI;;iBAEC;YACL;QACJ;IACJ;AACJ","sourcesContent":[".toggle-button {\n    & input {\n        display: none;\n    }\n\n    --switchHeight: 1rem;\n    --switchWidth: 2.5rem;\n    --switchPadding: -0.2rem;\n\n    --circleSize: calc(var(--switchHeight) - var(--switchPadding) * 2);\n\n    & label {\n        display: inline-block;\n        cursor: pointer;\n        position: relative;\n        width: var(--switchWidth);\n        height: var(--switchHeight);\n        border-radius: var(--switchHeight);\n\n        background: #ccc;\n        transition: 0.4s;\n\n        &:after {\n            cursor: pointer;\n            content: '';\n            position: absolute;\n            left: var(--switchPadding);\n            top: 0;\n            bottom: 0;\n            margin: auto;\n\n            width: var(--circleSize);\n            height: var(--circleSize);\n            border-radius: 50%;\n\n        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);\n\n            background: #fff;\n            transition: 0.4s;\n        }\n    }\n\n    & input:checked {\n        + label {\n            background-color: #ff7f00;\n\n            &:after {\n                left: calc(\n                        var(--switchWidth) - var(--circleSize) - var(--switchPadding)\n                );\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
