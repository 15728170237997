import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody';
import AddressForm from 'interfaces/components/common/Form/AddressForm';
import PostalCodeForm from 'interfaces/components/common/Form/PostalCodeForm';
import PrefectureForm from 'interfaces/components/common/Form/PrefectureForm';
import CommonTextInput from 'interfaces/components/common/Input/CommonTextInput';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault';
import { PREFECTURE } from 'shared/utils/constants/prefecture';

import BirthDateInput from './BirthDateInput';
import BothTelInput from './BothTelInput';
import FullNameInput from './FullNameInput';
import FullNameKanaInput from './FullNameKanaInput';
import GenderInput from './GenderInput';

import './css/edit-user-info-body.css';

const MembershipRow = ({ membershipId }) => {
  return (
    <tr>
      <th>
        会員識別番号
        <RequiredLabel />
      </th>
      <td>{membershipId}</td>
    </tr>
  );
};

const EditUserInfoBody = ({
  children,
  //handleUserInfoChange,
  membershipId,
  prefix,
  suffix,
  type,
  userInfo,
  validationMessages,
}) => {
  const handleAddressSearch = async (postalCode1, postalCode2) => {
    const postalCode = postalCode1 + postalCode2;
    console.log(postalCode);

    await fetch(
      `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`,
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.results !== null && data.results.length > 0) {
          console.log(data.results[0]);
          console.log(data.results[0].address1);
          let prefectureId = PREFECTURE.find(
            (prefecture) => prefecture.name === data.results[0].address1,
          ).id;
          userInfo.setters.handleChangeLocationGovernmentSubCategoryId(
            prefectureId,
          );
          userInfo.setters.handleChangeAddress(
            data.results[0].address2 + data.results[0].address3,
          );
        }
      });
  };

  return (
    <div className="edit-user-info-body">
      <ArticleDefaultBody>
        {prefix}
        <CommonTableDefault>
          {type === 'APPLICANT' ? (
            <MembershipRow membershipId={membershipId} />
          ) : null}
          <CommonTextInput
            label={
              <p>
                法人名
                <br />
                <small>法人の場合のみ</small>
              </p>
            }
            name="corp-name"
            value={userInfo.data.corpName}
            placeholder="（例）サンプル株式会社"
            handleChange={(e) => userInfo.setters.handleChangeCorpName(e)}
            validationMessage={validationMessages?.corpName}
          />
          <CommonTextInput
            label={
              <p>
                法人名 カナ
                <br />
                <small>法人の場合のみ</small>
              </p>
            }
            name="corp-name-kana"
            value={userInfo.data.corpNameKana}
            placeholder="（例）サンプルカブシキガイシャ"
            handleChange={(e) => userInfo.setters.handleChangeCorpNameKana(e)}
            validationMessage={validationMessages?.corpNameKana}
          />
          <FullNameInput
            familyName={userInfo.data.familyName}
            firstName={userInfo.data.firstName}
            handleFamilyNameChange={(e) =>
              userInfo.setters.handleChangeFamilyName(e)
            }
            handleFirstNameChange={(e) =>
              userInfo.setters.handleChangeFirstName(e)
            }
            familyNameValidationMessage={validationMessages?.familyName}
            firstNameValidationMessage={validationMessages?.firstName}
          />
          <FullNameKanaInput
            familyNameKana={userInfo.data.familyNameKana}
            firstNameKana={userInfo.data.firstNameKana}
            handleFamilyNameKanaChange={(e) =>
              userInfo.setters.handleChangeFamilyNameKana(e)
            }
            handleFirstNameKanaChange={(e) =>
              userInfo.setters.handleChangeFirstNameKana(e)
            }
            familyNameKanaValidationMessage={validationMessages?.familyNameKana}
            firstNameKanaValidationMessage={validationMessages?.firstNameKana}
          />
          <GenderInput
            userGender={userInfo.data.sex}
            handleUserInfoChange={userInfo.setters.handleChangeSex}
            validationMessage={validationMessages?.sex}
          />
          <BirthDateInput
            birthDate={userInfo.data.birthDate}
            handleChange={(selectDate) =>
              userInfo.setters.handleChangeBirthDate(selectDate)
            }
            validationMessage={validationMessages?.birthDate}
          />
          <PostalCodeForm
            postalCode1={userInfo.data.postalCode1}
            postalCode2={userInfo.data.postalCode2}
            postalCode1ValidationMessage={validationMessages?.postalCode1}
            postalCode2ValidationMessage={validationMessages?.postalCode2}
            handleChangePostalCode1={(e) =>
              userInfo.setters.handleChangePostalCode1(e)
            }
            handleChangePostalCode2={(e) =>
              userInfo.setters.handleChangePostalCode2(e)
            }
            handleAddressSearch={() =>
              handleAddressSearch(
                userInfo.data.postalCode1,
                userInfo.data.postalCode2,
                //handlerSearchAddress,
              )
            }
          />
          <PrefectureForm
            prefectureId={
              userInfo.data.locationGovernmentSubCategoryId
                ? Number(userInfo.data.locationGovernmentSubCategoryId)
                : ''
            }
            handleSelect={(item) =>
              userInfo.setters.handleChangeLocationGovernmentSubCategoryId(
                PREFECTURE.find((prefecture) => prefecture.name === item).id,
              )
            }
            validationMessage={validationMessages?.prefectureId}
          />
          <AddressForm
            receiverAddress={userInfo.data.address}
            handleChange={(e) =>
              userInfo.setters.handleChangeAddress(e.target.value)
            }
            validationMessage={validationMessages?.address}
          />
          <BothTelInput
            houseTel={userInfo.data.houseTel}
            mobileTel={userInfo.data.mobileTel}
            houseTelValidationMessage={validationMessages?.houseTel}
            mobileTelValidationMessage={validationMessages?.mobileTel}
            handleHoseTelChange={(e) =>
              userInfo.setters.handleChangeHouseTel(e)
            }
            handleMobileTelChange={(e) =>
              userInfo.setters.handleChangeMobileTel(e)
            }
          />
          {children}
        </CommonTableDefault>
        {suffix}
      </ArticleDefaultBody>
    </div>
  );
};

export default EditUserInfoBody;
