import AuctionUsageFeeProvider from './AuctionUsageFeeProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { auctionUsageFee } from "../entities/componentMapping.js";

const AuctionUsageFee = ({ type }) => {
  return (
    <AuctionUsageFeeProvider type={type}>
      {getComponent(auctionUsageFee, type)}
    </AuctionUsageFeeProvider>
  );
};

export default AuctionUsageFee;
