import Breadcrumb from 'interfaces/components/common/Breadcrumb.jsx';
import LargeSecondaryButton from 'interfaces/components/common/Button/LargeSecondaryButton.jsx';
import Heading2 from 'interfaces/components/common/Heading/Heading2.jsx';
import Heading4 from 'interfaces/components/common/Heading/Heading4.jsx';
import { breadcrumbs } from 'interfaces/pages/PropertiesManagement/entities/breadcrumbs';
import { Fragment, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { textExtractionByProcedureType } from 'shared/utils/helper/TextExtraction.js';

import 'interfaces/css/PropertiesManagement/properties-management.css';
import { PropertiesManagementContext } from 'interfaces/pages/PropertiesManagement/entities/PropertiesManagementContext';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

const NaviPropertiesManagement = () => {
  const navigation = useNavigate();
  const { auction_id } = useParams();
  const { auction, invitation } = useContext(PropertiesManagementContext);
  const { localStorageGovernmentType } = useLocalStorage();

  const today = new Date();
  let todayYear = today.getFullYear();
  let todayMonth = today.getMonth() + 1;
  let todayDate = today.getDate();
  let todayHour = today.getHours();
  let todayMinute = today.getMinutes();
  let todayString = `${todayYear}-${('00' + todayMonth).slice(-2)}-${('00' + todayDate).slice(-2)} ${('00' + todayHour).slice(-2)}:${('00' + todayMinute).slice(-2)}`;

  const openViewFlag =
    invitation.participationStatus === 'PARTICIPATION' &&
    auction.aplStart < todayString;
  const openDownloadViewFlag =
    invitation.participationStatus === 'PARTICIPATION' &&
    auction.aplEnd < todayString;
  const publicSaleType = auction.publicSaleType ?? 'BOTH';

  return (
    <div className="properties-management new-style_navi_properties-management">
      <Breadcrumb breadcrumbs={breadcrumbs(invitation.name ?? '')} />
      <Heading2 title={auction?.name ?? ''} />
      {localStorageGovernmentType !== 'NATIONAL_TAX_AGENCY' ? (
        <div className="header-buttons mt-10">
          {openDownloadViewFlag && (
            <Fragment>
              <LargeSecondaryButton text="認証書面(表示)のダウンロード" />
            </Fragment>
          )}
          {openViewFlag && (
            <Fragment>
              <LargeSecondaryButton
                text="システム利用料額確認"
                onClick={() => navigation(`/navi/object/${auction_id}/billing`)}
              />
            </Fragment>
          )}
          <LargeSecondaryButton
            text={`${textExtractionByProcedureType(auction?.procedureType)}参加申込の編集`}
            onClick={() =>
              navigation(`/navi/new-entry/${auction_id}/edit`, {
                state: { auctionId: auction.id },
              })
            }
            disabled={auction?.aplStart < todayString ? 'disabled' : ''}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="heading4-and-description-wrap mt-16">
        <Heading4 title="物件一覧" />
        <div className="description" data-testid="description">
          <span>公開前の物件の情報の作成、編集を行います。</span>
        </div>
      </div>
      <ul className="links-wrap mt-2" data-testid="property-listing-links">
        {publicSaleType !== 'BID' && (
          <li>
            <Link to={`/navi/object/${auction_id}/list?open-way=AUCTION`}>
              せり売の物件一覧
            </Link>
          </li>
        )}
        {publicSaleType !== 'AUCTION' && (
          <li>
            <Link to={`/navi/object/${auction_id}/list?open-way=BID`}>
              入札の物件一覧
            </Link>
          </li>
        )}
      </ul>
      <div className="heading4-and-description-wrap mt-12">
        <Heading4 title="公開物件管理" />
        <div className="description" data-testinvitation_id="description">
          <span>
            公開後の物件の管理を行います。
            物件ごとの申込者、入札者の一覧を表示し、入札削除やブロックリストへの追加を行います。（公開物件管理のリンクは一般参加申込開始日以降に表示されます。ただし一般参加申込開始日に公開物件数がゼロの場合は不参加扱いとなり、その場合は表示されません。）
          </span>
        </div>
      </div>
      {openViewFlag && (
        <ul className="links-wrap mt-2" data-testid="public-property-links">
          {publicSaleType !== 'BID' && (
            <li>
              <Link
                to={`/navi/object/${auction_id}/published-object?open-way=AUCTION`}>
                せり売の公開物件
              </Link>
            </li>
          )}
          {publicSaleType !== 'AUCTION' && (
            <li>
              <Link
                to={`/navi/object/${auction_id}/published-object?open-way=BID"`}>
                入札の公開物件
              </Link>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default NaviPropertiesManagement;
