import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import PageCautionDescription from 'interfaces/components/common/PageCautionDescription';
import { textExtractionByProcedureType } from 'shared/utils/helper/TextExtraction';
import LocalGovernmentManagementEmblemForm from 'interfaces/components/LocalGovernmentManagement/emblem/LocalGovernmentManagementEmblemForm';
import { breadcrumbs } from 'interfaces/pages/LocalGovernmentManagementEmblem/entities/breadcrumbs';

import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';

const Emblem = () => {
  const { localStorageProcedureType } = useLocalStorage();

  return (
    <div
      className="new-style_navi_local-government-management-top-form new-style_navi_local-government-management_emblem"
      data-testid="local-government-management-emblem">
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <PropertyInfoGeneralPageDescription
        title={'行政機関ページ管理：公章・キャラクター画像'}
        content={
          '公章・キャラクター画像のアップロードをしてください。\n' +
          '登録可能な画像ファイル形式はJPEG、PNG、GIFで、1ファイルあたり最大5MBです。画像の縦横アスペクト比は（１：１）の正方形となるようにし、一辺あたり500ピクセル以上のものをアップロードしてください。'
        }
        displayRequired={true}
      />
      <p className="pb-3">
        {' '}
        ※アニメーション形式、透過形式の画像はアップロードしないでください。KSI社では公章画像、キャラクター画像の加工を承っておりませんので、ご了承ください。
      </p>
      <PageCautionDescription
        content={`※${textExtractionByProcedureType(localStorageProcedureType)}の参加申込開始日以降は掲載終了まで修正ができません。`}
      />
      <LocalGovernmentManagementEmblemForm />
    </div>
  );
};

export default Emblem;
