import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault.jsx';
import { useEffect, useState, useContext } from 'react';
import { ShippingContext } from 'interfaces/pages/Shipping/entities/ShippingContext';
//import { useAuth } from 'shared/hooks/useAuth.js';
//import { getPreRegistrationInfo } from '../../../../read_storage/application-info-input.js';
import { PREFECTURE } from 'shared/utils/constants/prefecture.js';

const ClientAddressTable = ({ setAddress }) => {
  //const { userId } = useAuth();
  const [applicantInfos, setApplicationInfos] = useState({});
  const { applicantInformations } = useContext(ShippingContext);

  // todo: providerに移行
  //const fetch = async () => {
  //  const preRegistrationInfo = (await getPreRegistrationInfo(userId))[0];
  //  setApplicationInfos(
  //    preRegistrationInfo.find((info) => info.type === 'APPLICANT')?.infos,
  //  );
  //};

  useEffect(() => {
    let client = applicantInformations.find(
      (info) => info.type === 'APPLICANT',
    );
    setApplicationInfos(client);
  }, [applicantInformations]);

  useEffect(() => {
    setAddress((address) => ({
      ...address,
      applicant: {
        name: `${applicantInfos?.familyName ?? ''} ${applicantInfos?.firstName ?? ''}`,
        postalCode: applicantInfos?.postalCode,
        prefectureId: PREFECTURE.find(
          (prefecture) => prefecture.name === applicantInfos?.prefecture,
        )?.id,
        address: applicantInfos?.address,
        houseTel: applicantInfos?.houseTel,
        mobileTel: applicantInfos?.mobileTel,
      },
    }));
  }, [applicantInfos]);

  return (
    <div>
      <CommonTableDefault>
        <tr>
          <th>氏名</th>
          <td>{`${applicantInfos?.familyName ?? ''} ${applicantInfos?.firstName ?? ''}`}</td>
        </tr>
        <tr>
          <th>住所</th>
          <td>{`${applicantInfos?.prefecture ?? ''}${applicantInfos?.address ?? ''}`}</td>
        </tr>
        <tr>
          <th>電話番号</th>
          <td>
            自宅：{applicantInfos?.houseTel ?? ''}
            <br />
            携帯：{applicantInfos?.mobileTel ?? ''}
          </td>
        </tr>
      </CommonTableDefault>
    </div>
  );
};

export default ClientAddressTable;
