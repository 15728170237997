import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParamController } from 'shared/hooks/useQueryParamController.js';
import {
  auctionType,
  auctionTypeToQueryParam,
  filterAuctionProgressStatus,
  filterAuctionResultStatus,
  filterDeposit,
  procedureNo,
  procedureNoToQueryParam,
  sortType,
  sortTypeToQueryParam,
} from 'shared/utils/constants/mylist_search_conditions.js';
import {
  FILTER_AUCTION_TYPE,
  FILTER_IMPLEMENTAL_METHOD,
  ORDER,
  PAGE,
  STATUS,
} from 'shared/utils/constants/queryParam.js';

import MylistSortCheckBox from './MylistSortCheckBox.jsx';
import MylistSortSelector from './MylistSortSelector.jsx';
import 'interfaces/css/Mylist/mylist-box.css';

const OptionSearchContents = ({ sortBoxType }) => {
  if (sortBoxType === 'LARGE') {
    return (
      <div className="large-sort-box" data-testid="large-sort-box">
        <div className="mylist-sort-checkbox-list">
          {filterDeposit.map((item) => (
            <MylistSortCheckBox
              key={item.id}
              keyName={item.keyName}
              label={item.label}
              value={item.value}
            />
          ))}
          {filterAuctionResultStatus.map((item) => (
            <MylistSortCheckBox
              key={item.id}
              keyName={item.keyName}
              label={item.label}
              value={item.value}
            />
          ))}
        </div>
        <div className="mylist-sort-checkbox-list">
          {filterAuctionProgressStatus.map((item) => (
            <MylistSortCheckBox
              key={item.id}
              keyName={item.keyName}
              label={item.label}
              value={item.value}
            />
          ))}
        </div>
      </div>
    );
  }
  if (sortBoxType === 'MEDIUM') {
    return (
      <div className="mylist-sort-checkbox-list" data-testid="medium-sort-box">
        {filterDeposit.map((item) => (
          <MylistSortCheckBox
            key={item.id}
            keyName={item.keyName}
            label={item.label}
            value={item.value}
          />
        ))}
      </div>
    );
  }
};

const MylistSortBox = ({ sortBoxType }) => {
  const [sortTypeInitialVal, setSortTypeInitialVal] = useState(sortType[0]);
  const [auctionTypeInitialVal, setAuctionTypeInitialVal] = useState(
    auctionType[0],
  );
  const [procedureNoInitialVal, setProcedureNoInitialVal] =
    useState('実施方法');
  const [searchParams] = useSearchParams();
  const { applyQueryParam, appendQueryParam } = useQueryParamController();

  const handleSelectSortType = (item) => {
    setSortTypeInitialVal(item);
    appendQueryParam(STATUS, 'all');
    applyQueryParam(PAGE, 1);
    applyQueryParam(ORDER, sortTypeToQueryParam[item]);
  };
  const handleSelectAuctionType = (item) => {
    setAuctionTypeInitialVal(item);
    appendQueryParam(STATUS, 'all');
    applyQueryParam(PAGE, 1);
    applyQueryParam(FILTER_AUCTION_TYPE, auctionTypeToQueryParam[item]);
  };
  const handleSelectProcedureNo = (item) => {
    setProcedureNoInitialVal(item);
    appendQueryParam(STATUS, 'all');
    applyQueryParam(PAGE, 1);
    appendQueryParam(ORDER, sortTypeToQueryParam['マイリスト登録の新しい順']);
    applyQueryParam(FILTER_IMPLEMENTAL_METHOD, procedureNoToQueryParam[item]);
  };

  useEffect(() => {
    if (searchParams.has(ORDER)) {
      const key = Object.keys(sortTypeToQueryParam).find(
        (key) => sortTypeToQueryParam[key] === searchParams.get(ORDER),
      );
      setSortTypeInitialVal(key);
    }
    if (searchParams.has(FILTER_AUCTION_TYPE)) {
      const key = Object.keys(auctionTypeToQueryParam).find(
        (key) =>
          auctionTypeToQueryParam[key] ===
          searchParams.get(FILTER_AUCTION_TYPE),
      );
      setAuctionTypeInitialVal(key);
    }
    if (searchParams.has(FILTER_IMPLEMENTAL_METHOD)) {
      const key = Object.keys(procedureNoToQueryParam).find(
        (key) =>
          procedureNoToQueryParam[key] ===
          searchParams.get(FILTER_IMPLEMENTAL_METHOD),
      );
      setProcedureNoInitialVal(key);
    }
  }, []);

  return (
    <div data-testid="mylist-narrow-down" className="mylist-narrow-down">
      <MylistSortSelector
        label={'並べ替え'}
        selected_value={sortTypeInitialVal}
        items={sortType}
        handleSelect={handleSelectSortType}
      />
      <div className="mylist-sort-checkbox-list">
        <MylistSortSelector
          label={'オークション種別'}
          selected_value={auctionTypeInitialVal}
          className={' small'}
          items={auctionType}
          handleSelect={handleSelectAuctionType}
        />
        <MylistSortSelector
          selected_value={procedureNoInitialVal}
          className={'-padding-top small'}
          items={procedureNo}
          handleSelect={handleSelectProcedureNo}
        />
      </div>
      <OptionSearchContents sortBoxType={sortBoxType} />
    </div>
  );
};

export default MylistSortBox;
