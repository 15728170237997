// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shipping .shipping-info-form tr {
  min-height: 88px;
}

.shipping .shipping-info-form td, .shipping-info-form th {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.shipping .shipping-info-form td {
  width: 80%;
  padding-left: 12px;
  padding-right: 12px;
}

.shipping .shipping-info-form .w-expand {
  width: 100%;
}

.shipping .topic-input-caption {
  color: var(--text-lighten1);
  font-size: .75rem;
  margin-top: 28px;
}

@media screen and (min-width: 720px) {
  .shipping .shipping-info-form tr {
    display: flex;
  }

  .shipping .shipping-info-form th {
    width: 20%;
  }
}

@media screen and (max-width: 719px) {
  .shipping .shipping-info-form th {
    width: 100%;
  }

  .shipping .shipping-info-form td {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Shipping/shipping-info-form.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,WAAW;EACb;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".shipping .shipping-info-form tr {\n  min-height: 88px;\n}\n\n.shipping .shipping-info-form td, .shipping-info-form th {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n}\n\n.shipping .shipping-info-form td {\n  width: 80%;\n  padding-left: 12px;\n  padding-right: 12px;\n}\n\n.shipping .shipping-info-form .w-expand {\n  width: 100%;\n}\n\n.shipping .topic-input-caption {\n  color: var(--text-lighten1);\n  font-size: .75rem;\n  margin-top: 28px;\n}\n\n@media screen and (min-width: 720px) {\n  .shipping .shipping-info-form tr {\n    display: flex;\n  }\n\n  .shipping .shipping-info-form th {\n    width: 20%;\n  }\n}\n\n@media screen and (max-width: 719px) {\n  .shipping .shipping-info-form th {\n    width: 100%;\n  }\n\n  .shipping .shipping-info-form td {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
