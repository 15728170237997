//import modal from 'interfaces/css/common/modal2.module.css'
import { useEffect } from 'react';
import styled from 'styled-components';
import { useCloseClickOutside } from 'shared/hooks/useCloseClickOutside';

const ModalOverlay = styled.div`
  background-color: rgba(33, 33, 33, 0.46);
  bottom: 0;
  height: 100%;
  left: 0;
  min-height: 100%;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const ModalContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 50%;
  max-height: ${(props) => props.maxheight};
  padding: ${(props) => (props.$padding ? '24px' : '0px')};
  position: fixed;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  z-index: 105;
`;

const ModalContentsInner = styled.div`
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  padding: ${(props) => props.$padding};
  text-align: left;
`;

const Modal2 = ({
  isOpen = true,
  handleClose,
  children,
  modalSize,
  padding = true,
  className,
  closeClickOutside = false,
  maxHeight = '90vh',
}) => {
  const { ref } = useCloseClickOutside(() => handleClose());

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }
  
  const modalStyle = () => {
    if (modalSize === 'S') {
      return { maxWidth: '338px' };
    } else if (modalSize === 'M') {
      return { maxWidth: '1048px' };
    } else if (modalSize) {
      return { maxWidth: modalSize };
    } else {
      return { width: '95%' };
    }
  };

  return (
    <ModalOverlay data-testid="modal-overlay">
      <ModalContents
        ref={closeClickOutside ? ref : null}
        $padding={padding}
        data-testid="modal"
        className="new-style_modal"
        style={modalStyle()}
        onClick={handleClose}
        maxheight={maxHeight}>
        <ModalContentsInner
          className={className}
          $padding={padding}
          onClick={(e) => e.stopPropagation()}>
          {children}
        </ModalContentsInner>
      </ModalContents>
    </ModalOverlay>
  );
};

export default Modal2;
