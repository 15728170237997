import AuctionGovernmentProvider from './AuctionGovernmentProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { auctionGovernment } from "../entities/componentMapping.js";

const AuctionGovernment = ({ type }) => {
  return (
    <AuctionGovernmentProvider type={type}>
      {getComponent(auctionGovernment, type)}
    </AuctionGovernmentProvider>
  );
};

export default AuctionGovernment;
