import ManualMarkedListRecord from './ManualMarkedListRecord.jsx';
import 'interfaces/css/SampleGuideline/manual-marked-list.css';

const ManualMarkedList = ({ data, className }) => {
  return (
    <div
      className={`manual-marked-list ${className ? className : ''}`}
      data-testid="manual-marked-list">
      {data.map((item, index) => (
        <ManualMarkedListRecord
          mark={item.mark}
          text={item.text}
          isImportant={item.isImportant}
          key={index}
        />
      ))}
    </div>
  );
};
export default ManualMarkedList;
