import { ReactComponent as ArrowRightIcon } from 'interfaces/assets/svg/arrow_back_icon.svg';
import BackButton from 'interfaces/components/common/BackButton';
import Button from 'interfaces/components/common/Button';

import AuctionSwitcher from 'interfaces/components/common/Switcher/AuctionSwitcher';
import ButtonList from 'interfaces/components/common/ButtonList';
import GovernmentFilter from 'interfaces/components/common/GovernmentFilter';
import ManagementDataList from 'interfaces/components/common/ManagementDataList';
import PageTitle from 'interfaces/components/common/PageTitle';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMultipleChecked } from 'shared/hooks/useCheckBox';

import AuctionDepositTable from './AuctionDepositTable';
import { AuctionDepositContext } from '../../../../pages/AuctionDeposit/entities/AuctionDepositContext';
import Toolbar from 'interfaces/components/common/Toolbar';
import { dateFormat } from 'shared/utils/helper/date_format';

const TOP_PATH = '/kmanager';

const AuctionDeposits = () => {
  const { auction, auctionDeposits } = useContext(AuctionDepositContext);

  const [filteredAuctionDeposits, setFilteredAuctionDeposits] =
    useState(auctionDeposits);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setFilteredAuctionDeposits(
      auctionDeposits.filter((auctionDeposit) =>
        auctionDeposit.governmentName.includes(searchInput),
      ),
    );
  }, [searchInput, auctionDeposits]);

  const navigation = useNavigate();

  const { checked, checkedList, allCheck, clearCheck } = useMultipleChecked(
    auctionDeposits.map((row) => row.governmentId),
  );

  const managementData = [
    [
      {
        value: '納付額',
        colSize: 2,
        colMdSize: null,
      },
      {
        value: auctionDeposits.reduce(
          (total, deposit) => total + deposit.depositProxy,
          0,
        ),
        colSize: 2,
        colMdSize: null,
        isYen: true,
      },
      ...(auction.procedureType === 'PUBLIC_SALE'
        ? [
            {
              value: '返還額',
              colSize: 2,
              colMdSize: null,
            },
            {
              value: auctionDeposits.reduce(
                (total, deposit) => total + deposit.depositRefundClaim,
                0,
              ),
              colSize: 2,
              colMdSize: null,
              isYen: true,
            },
            {
              value: '差引保証金',
              colSize: 2,
              colMdSize: null,
            },
            {
              value: 0,
              colSize: 2,
              colMdSize: null,
              isYen: true,
            },
          ]
        : []),
    ],
    [
      {
        value: '保証金納付日',
        colSize: 2,
        colMdSize: null,
      },
      {
        value: dateFormat(auction.depositPaymentDate, 'YYYY-MM-DD'),
        colSize: 2,
        colMdSize: null,
      },
    ],
  ];

  // TODO: 請求書ダウンロード, 請求書明細ダウンロード, 請求書送付先(CSV)ダウンロード, 入金登録のAPIをfetchするように実装する
  const downloadInvoiceDocument = () => {
    console.log('返還請求書ダウンロード');
  };

  const downloadInvoiceDocumentCSV = () => {
    console.log('返還請求書送付先CSVダウンロード');
  };

  const downloadPaymentAccounts = () => {
    console.log('公売(入札)保証金振込先一覧ダウンロード');
  };

  const registerReturnDate = () => {
    console.log('返還日登録');
    //handleOpen()
  };

  const FETCH_BUTTONS_DATA = [
    ...(auction.procedureType === 'PUBLIC_SALE'
      ? [
          {
            text: '返還請求書ダウンロード',
            clickHandler: downloadInvoiceDocument,
            className: 'small primary',
            isDisabled: Array.isArray(checked) && !checked.length,
          },
          {
            text: '返還請求書送付先CSVダウンロード',
            clickHandler: downloadInvoiceDocumentCSV,
            className: 'small primary',
            isDisabled: Array.isArray(checked) && !checked.length,
          },
        ]
      : []),
    {
      text: '公売(入札)保証金振込先一覧ダウンロード',
      clickHandler: downloadPaymentAccounts,
      className: 'small primary',
      isDisabled: Array.isArray(checked) && !checked.length,
    },
    ...(auction.procedureType === 'PUBLIC_SALE'
      ? [
          {
            text: '返還日登録',
            clickHandler: registerReturnDate,
            className: 'small kmanager-button-success',
            isDisabled: Array.isArray(checked) && !checked.length,
          },
        ]
      : []),
  ];

  //  useEffect(() => {
  //    let governments = [];
  //    let auctionData = {};
  //    (async () => {
  //      const auctionRequests = [fetchAuctionData(auctionId, 'name'), fetchAuctionData(auctionId, 'procedure-type'), fetchAuctionData(auctionId, 'public-sale-type')]
  //      const governmentRequests = [fetchLocations(auctionId, "ASC"), fetchPrefectures(auctionId, "ASC"), fetchNames(auctionId, "ASC")];
  //
  //      // オークション情報の取得
  //      await Promise.all(auctionRequests)
  //        .then((auctionResults) => {
  //          auctionResults.map((result) => {
  //            Object.assign(auctionData, result.data.data)
  //          })
  //        })
  //        .catch(err => {
  //          handleError(err)
  //        });
  //
  //      // 行政機関情報の取得
  //      await Promise.all(governmentRequests)
  //        .then((governmentResults) => {
  //          governmentResults.map((result) => {
  //            governments = mergeObjects({"data": governments}, result.data)
  //          })
  //        })
  //        .catch(err => {
  //          handleError(err)
  //        });
  //
  //      // その他の情報を取得
  //      let promises = governments.map(async (government) => {
  //        // TODO: 共通化？
  //        const additionalRequests = [
  //          fetchReturnDate(government.id, auctionId),
  //          fetchEstimatedInvoiceDate(government.id, auctionId),
  //          fetchEstimatedReturnDate(government.id, auctionId),
  //          fetchSumOfDeposits(government.id, auctionId),
  //          fetchSumOfDepositRefundsScheduled(government.id, auctionId),
  //        ];
  //
  //        await Promise.all(additionalRequests)
  //          .then((additionalResults) => {
  //            additionalResults.map((result) => {
  //              Object.assign(government, result.data.data)
  //            })
  //          })
  //          .catch(err => {
  //            handleError(err)
  //          });
  //
  //        // カラム名に変更が必要なデータ
  //        await fetchDivisionName(government.id).then((result) => {
  //          Object.assign(government, {divisionName: result.data.data.name})
  //        })
  //          .catch(err => {
  //            handleError(err)
  //          });
  //      })
  //
  //      await Promise.all(promises)
  //        .then(() => {
  //          setGovernments(governments)
  //          console.log(governments)
  //        })
  //        .catch((err) => {
  //          console.log(err)
  //          handleError(err)
  //        })
  //      setAuction(auctionData)
  //
  //    })()
  //    //   TODO: auction -> properties(sum(deposit), sum(deposit_refund_scheduled))
  //  }, []);

  //  const submitHandler = async (e) => {
  //    e.preventDefault();
  //    const headers = {
  //      'Content-Type': 'application/json',
  //    };
  //    const body = {
  //      "return_date": date.toLocaleDateString('sv-SE')
  //    }
  //    try {
  //      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/v1/management/auctions/${auctionId}/${divisionId}/register-return-date`, body, {headers: headers})
  //    } catch (error) {
  //      return error
  //    }
  //  };
  //  const handleChange = (selectDate) => {
  //    setDate(selectDate)
  //  }
  //  const [date, setDate] = useState("");

  return (
    <div
      className="kmanager-deposits kmanager-auction-operation"
      data-testid="kmanager-deposits">
      <div className="kmanager-row">
        <div className="kmanager-col kmanager-col-6">
          <BackButton
            text="オークション実施一覧"
            svg={<ArrowRightIcon title="back-to-page" />}
            handleClick={() => navigation(TOP_PATH)}
          />
          <PageTitle text={auction.name} />
          <Toolbar>
            <AuctionSwitcher
              id={auction.id}
              activeIndex={2}
              auctionGovernmentTypeGroup={auction?.governmentTypeGroup}
            />
          </Toolbar>
        </div>
        <div className="kmanager-col kmanager-col-6">
          <GovernmentFilter
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
        </div>
        <div className="kmanager-col kmanager-col-12">
          <div data-testid="kmanager-fetch-button-list">
            <ButtonList>
              {FETCH_BUTTONS_DATA.map((item, index) => (
                <Button
                  key={index}
                  text={item.text}
                  clickHandler={item.clickHandler}
                  isOutline={item.isOutline}
                  isDisabled={item.isDisabled}
                  className={item.className}
                />
              ))}
            </ButtonList>
          </div>
          {/*
          <ReturnDateModal isOpen={isOpen} handleClose={handleClose} label={"返還日登録"} submitHandler={submitHandler} date={date} handleChange={handleChange}/>
          */}
          <div className="mb-5">
            <ManagementDataList data={managementData} />
          </div>
          <AuctionDepositTable
            auction={auction}
            auctionDeposits={filteredAuctionDeposits}
            checked={checked}
            checkedList={checkedList}
            allCheck={allCheck}
            clearCheck={clearCheck}
          />
        </div>
      </div>
    </div>
  );
};

export default AuctionDeposits;
