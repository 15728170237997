import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class Theme {
  #theme

  constructor(theme) {
    this.#theme = theme;
  }

  get() {
    return this.#theme
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#theme, 0, 30)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#theme)
  }
}
