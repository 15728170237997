import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class CarStatus {
  #carStatus

  constructor(carStatus) {
    this.#carStatus = carStatus;
  }

  get() {
    return this.#carStatus
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#carStatus, 1, 100)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#carStatus)
  }
}
