
export class DisplayPreview {
  #displayPreview

  constructor(displayPreview) {
    this.#displayPreview = displayPreview;
  }

  get() {
    return this.#displayPreview
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
