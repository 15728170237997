import { validateNumber, validateRangeLength, validateDecimal } from "../../../../application/validations.js";

export class BuildSpace {
  #buildSpace

  constructor(buildSpace) {
    this.#buildSpace = buildSpace;
  }

  get() {
    return this.#buildSpace
  }

  get isValid() {
    return [
      this.validateNumber,
      this.validateRangeLength,
      this.validateDecimal,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateNumber() {
    return validateNumber(this.#buildSpace)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#buildSpace, 0, 10)
  }

  // バリデーションルールの適用
  get validateDecimal() {
    return validateDecimal(this.#buildSpace, 2)
  }
}
