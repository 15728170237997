
export class ChildCategory {
    #childCategory

    constructor(childCategory) {
        this.#childCategory = childCategory;
    }

    get() {
        return this.#childCategory
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
