import Heading3 from 'interfaces/components/common/Heading/Heading3.jsx';
import {
  after_winning_bid_caution_description_13,
  after_winning_bid_caution_description_14,
} from 'shared/utils/helper/DummyAfterWinningBid';
import {
  caution_description11,
  caution_description12,
  local_government_management_top_description1,
  local_government_management_top_description2,
  local_government_management_top_description3,
} from 'shared/utils/helper/DummyEditFormData';
import { local_government_management_radio1 } from 'shared/utils/helper/radio_labels';

import 'interfaces/css/common/property-info-general-form.css';
import FormRadioWithCaution from '../../Form/navi/FormRadioWithCaution';
import FormTelWithCaution from '../../Form/navi/FormTelWithCaution';
import FormTextWithCaution from '../../Form/navi/FormTextWithCaution';

const BothForm = ({ auctionData }) => {
  return (
    <form data-testid="both-form">
      <div className="pt-6">
        <Heading3 text="1. 下見会のご案内" />
        <div className="property-info-general-form-table">
          <FormRadioWithCaution
            label={'下見会の案内の表示'}
            radio_label={local_government_management_radio1}
            data={auctionData.data?.displayPreview}
            handler1={(e) => {
              auctionData.setters?.handleChangeDisplayPreview(e);
              auctionData.setters?.handleChangePreviewUrl(
                { target: { value: auctionData.data?.previewUrl } },
                e.target.value === 'DISPLAY',
              );
            }}
          />
          <FormTextWithCaution
            label={'URL'}
            required={auctionData.data?.displayPreview === 'DISPLAY'}
            caution_description={local_government_management_top_description1}
            data={auctionData.data?.previewUrl}
            handler1={(e) =>
              auctionData.setters?.handleChangePreviewUrl(
                e,
                auctionData.data?.displayPreview === 'DISPLAY',
              )
            }
            invalidMessage={auctionData.validationMessage.previewUrlValid}
          />
        </div>
      </div>
      <div className="pt-9">
        <Heading3 text="2. お問い合わせ先" />
        <div className="property-info-general-form-table">
          <FormRadioWithCaution
            label={'お問い合わせ先の表示'}
            radio_label={local_government_management_radio1}
            data={auctionData.data?.displayInquiry}
            handler1={(e) => {
              auctionData.setters?.handleChangeDisplayInquiry(e);
              auctionData.setters?.handleChangeInquiryName(
                { target: { value: auctionData.data?.inquiryName } },
                e.target.value === 'DISPLAY',
              );
            }}
          />
          <FormTextWithCaution
            label={'お問い合わせ先'}
            caution_description={after_winning_bid_caution_description_13}
            required={auctionData.data?.displayInquiry === 'DISPLAY'}
            data={auctionData.data?.inquiryName}
            number_remaining_characters={
              auctionData.data?.inquiryName
                ? 50 - auctionData.data?.inquiryName.length
                : 50
            }
            handler1={(e) =>
              auctionData.setters?.handleChangeInquiryName(
                e,
                auctionData.data?.displayInquiry === 'DISPLAY',
              )
            }
            invalidMessage={auctionData.validationMessage.inquiryNameValid}
          />
          <FormTextWithCaution
            label={'メールアドレス'}
            caution_description={caution_description11}
            data={auctionData.data?.inquiryEmail}
            handler1={auctionData.setters?.handleChangeInquiryEmail}
            invalidMessage={auctionData.validationMessage.contactEmailValid}
          />
          <FormTelWithCaution
            label={'電話番号'}
            telData={auctionData.data?.inquiryTel}
            setTelData={auctionData.setters?.handleChangeInquiryTel}
            hasExtension={true}
            extensionData={auctionData.data?.inquiryExtension}
            setExtensionData={auctionData.setters?.handleChangeInquiryExtension}
            caution_description={caution_description12}
            invalidMessage={auctionData.validationMessage.telValid}
            extensionMessage={auctionData.validationMessage.extensionValid}
          />
          <FormTextWithCaution
            label={'電話受付時間'}
            caution_description={after_winning_bid_caution_description_14}
            number_remaining_characters={
              auctionData.data?.inquiryAccept
                ? 50 - auctionData.data?.inquiryAccept.length
                : 50
            }
            data={auctionData.data?.inquiryAccept}
            handler1={auctionData.setters?.handleChangeInquiryAccept}
            invalidMessage={auctionData.validationMessage.referenceAcceptValid}
          />
        </div>
      </div>
      <div className="pt-9">
        <Heading3 text="3. 行政機関ホームページ" />
        <div className="property-info-general-form-table">
          <FormRadioWithCaution
            label={'行政機関ホームページの表示'}
            radio_label={local_government_management_radio1}
            data={auctionData.data?.displayHomePage}
            handler1={(e) => {
              auctionData.setters?.handleChangeDisplayHomePage(e);
              auctionData.setters?.handleChangeHomePageName(
                { target: { value: auctionData.data?.homePageName } },
                e.target.value === 'DISPLAY',
              );
              auctionData.setters?.handleChangeHomePageUrl(
                { target: { value: auctionData.data?.homePageUrl } },
                e.target.value === 'DISPLAY',
              );
            }}
          />
          <FormTextWithCaution
            label={'ホームページ名'}
            required={auctionData.data?.displayHomePage === 'DISPLAY'}
            caution_description={local_government_management_top_description2}
            number_remaining_characters={
              auctionData.data?.homePageName
                ? 30 - auctionData.data?.homePageName.length
                : 30
            }
            data={auctionData.data?.homePageName}
            handler1={(e) =>
              auctionData.setters?.handleChangeHomePageName(
                e,
                auctionData.data?.displayHomePage === 'DISPLAY',
              )
            }
            invalidMessage={auctionData.validationMessage.hpNameValid}
          />
          <FormTextWithCaution
            label={'URL'}
            required={auctionData.data?.displayHomePage === 'DISPLAY'}
            caution_description={local_government_management_top_description3}
            data={auctionData.data?.homePageUrl}
            handler1={(e) =>
              auctionData.setters?.handleChangeHomePageUrl(
                e,
                auctionData.data?.displayHomePage === 'DISPLAY',
              )
            }
            invalidMessage={auctionData.validationMessage.homePageUrlValid}
          />
        </div>
      </div>
    </form>
  );
};

export default BothForm;
