import 'interfaces/css/ConfirmSystemUsage/system-usages-table.css';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

const APPLY_STATUS_JP = {
  APPLY: '申請済み',
  UNAPPLY: '',
};

const SystemUsagesTable = ({ data }) => {
  const { localStorageProcedureType } = useLocalStorage();
  const public_sale_columns = [
    'NO',
    '区分番号',
    'タイトル',
    '見積価額',
    '公売保証金額',
    '落札価額',
    '落札システム利用料',
    '減額申請',
  ];

  const asset_sale_columns = [
    'NO',
    '区分番号',
    'タイトル',
    '予定価格',
    '入札保証金額',
    '落札価額',
    '落札システム利用料',
    '減額申請',
  ];

  const intoLocaleYen = (value) => {
    if (value === '') return '0円';
    if (value === 'DATA_EMPTY') return '0円';
    if (value === undefined) return '0円';
    if (value === '-9999') return '0円';
    return `${Number(value).toLocaleString()}円`;
  };

  const sumOfObjectValue = (data, key) => {
    let sum = 0;
    data.map((obj) => {
      if (
        obj[key] !== '' &&
        obj[key] !== undefined &&
        obj[key] !== 'DATA_EMPTY' &&
        obj[key] !== '-9999'
      ) {
        sum += parseInt(obj[key]);
      }
    });
    return sum;
  };

  return (
    <table className="system-usages-table">
      <thead>
        {localStorageProcedureType === 'PUBLIC_SALE' ? (
          <tr>
            {public_sale_columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        ) : (
          <tr>
            {asset_sale_columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        )}
      </thead>
      <tbody>
        {data.map((detail, number) => (
          <tr key={detail.id}>
            <td>{number + 1}</td>
            <td>{detail?.saleNumber}</td>
            <td>{detail?.name}</td>
            <td className="text-right">
              {intoLocaleYen(detail?.estimatePrice)}
            </td>
            <td className="text-right">{intoLocaleYen(detail?.deposit)}</td>
            <td className="text-right">
              {intoLocaleYen(detail?.bidEndPrice ? detail.bidEndPrice : 0)}
            </td>
            {/*TODO: 減額申請時の金額を反映 */}
            <td className="text-right">
              {intoLocaleYen(detail?.bidSystemFee)}
            </td>
            <td className="text-center">
              {APPLY_STATUS_JP[detail?.isApplyReduction]}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr className="system-usages-total-record">
          <td colSpan="3" className="text-center">
            合計
          </td>
          <td className="text-right">
            {intoLocaleYen(sumOfObjectValue(data, 'estimatePrice'))}
          </td>
          <td className="text-right">
            {intoLocaleYen(sumOfObjectValue(data, 'deposit'))}
          </td>
          <td className="text-right">
            {intoLocaleYen(sumOfObjectValue(data, 'bidInfo'))}
          </td>
          <td className="text-right">
            {intoLocaleYen(sumOfObjectValue(data, 'bidSystemFee'))}
          </td>
          <td className="text-right, blank-field">{/* 空のセル */}</td>
        </tr>
      </tfoot>
    </table>
  );
};

export default SystemUsagesTable;

/*
  TODO: 減額申請のフィールドの条件分岐(既存コード: original/gl-auc/navi/components/SystemFeeTable.vue)
    template(v-if="item.isDisplayApplyReductionButton")                      //- ボタンを表示する期間である
      template(v-if="item.isApplyReduction")                                 //- 減額申請有なので、申請キャンセルボタンを表示
      v-btn(x-small, @click="$emit('onClickCancelDiscount', item)") 申請済み
    p.caption クリックして申請キャンセル
    template(v-else)                                                         //- 申請有ではないので、申請ボタンを表示
    v-btn(x-small, @click="$emit('onClickApplyDiscount', item)") 減額申請
    template(v-else)                                                         //- ボタンを表示しない期間である
    template(v-if="item.isReductionApproved")                                //- 申請が許可されているので、承認済みと表記
      p.text 減額申請承認済み
    template(v-else-if="item.isApplyReduction")                              //- 減額申請有なので、テキストで表記
      p.text 減額申請済み
                                                                             //- それ以外は特に表記無し
 */
