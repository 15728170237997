// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-list .description {
  font-size: 14px;
}

.block-list-filter {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.block-list-filter > * + * {
  margin-left: 10px;
}

.block-list-checkbox {
  margin-top: 20px;
}

.block-list-actions {
  display: flex;
  padding: 10px;
  background: #e2e1da;
}

.block-list .action-buttons button {
  margin-left: 10px;
}
.block-list .action-buttons button:first-child {
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/BlockList/block-list.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,cAAc;AAChB","sourcesContent":[".block-list .description {\n  font-size: 14px;\n}\n\n.block-list-filter {\n  display: flex;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.block-list-filter > * + * {\n  margin-left: 10px;\n}\n\n.block-list-checkbox {\n  margin-top: 20px;\n}\n\n.block-list-actions {\n  display: flex;\n  padding: 10px;\n  background: #e2e1da;\n}\n\n.block-list .action-buttons button {\n  margin-left: 10px;\n}\n.block-list .action-buttons button:first-child {\n  margin-left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
