import { propertyStatus } from 'domain/Property/entities/constants/propertyStatus.js';

import AdditionalDuringBiddingPropertyCardCaptions from './GeneralDetailPropertyCardCaptions/AdditionalDuringBiddingPropertyCardCaptions.jsx';
import CommonPropertyCardCaptions from './GeneralDetailPropertyCardCaptions/CommonPropertyCardCaptions.jsx';
import DuringBiddingPropertyCardCaptions from './GeneralDetailPropertyCardCaptions/DuringBiddingPropertyCardCaptions.jsx';
import EndBiddingPropertyCardCaptions from './GeneralDetailPropertyCardCaptions/EndBiddingPropertyCardCaptions.jsx';
import WaitOpenBidPropertyCardCaptions from './GeneralDetailPropertyCardCaptions/WaitOpenBidPropertyCardCaptions.jsx';

const GeneralDetailPropertyCardCaptions = ({ data }) => {
  const status = data?.status;

  if (
    status === propertyStatus.APPLYING ||
    status === propertyStatus.WAIT_START_BID
  ) {
    return <CommonPropertyCardCaptions data={data} />;
  }
  if (status === propertyStatus.DURING_BIDDING) {
    return <DuringBiddingPropertyCardCaptions data={data} />;
  }
  if (status === propertyStatus.WAIT_OPEN_BID) {
    return <WaitOpenBidPropertyCardCaptions data={data} />;
  }
  if (status === propertyStatus.ADDITIONAL_DURING_BIDDING) {
    return <AdditionalDuringBiddingPropertyCardCaptions data={data} />;
  }
  if (status === propertyStatus.END_BIDDING) {
    return <EndBiddingPropertyCardCaptions data={data} />;
  }
};

export default GeneralDetailPropertyCardCaptions;
