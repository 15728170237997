import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class LandRights {
  #landRights

  constructor(landRights) {
    this.#landRights = landRights;
  }

  get() {
    return this.#landRights
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#landRights, 0, 30)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#landRights)
  }
}
