const SelectMagnification = ({ selectMonth, handleClick }) => {
  return (
    <div className="flex" data-testid="select-magnification">
      {[2, 4].map(month =>
        <button
          key={month}
          className={`switch-month ${selectMonth === month ? 'select-switch-month' : ''}`}
          onClick={() => handleClick(month)}
          data-testid="select-month-button"
        >
          {month}ヶ月
        </button>
      )}
    </div>
  );
};

export default SelectMagnification;
