export const QUERY_PARAM_WITHOUT_SEARCH_PROPERTY_CATEGORY_ID =
  'category=GENERAL&searchGovernmentSubCategoryId&direction=ASC&field=PRICE&page=1&pageSize=50';
export const QUERY_PARAM_FREE_SEARCH =
  'searchPropertyCategoryId&searchGovernmentSubCategoryId&direction=ASC&field=PRICE&page=1&pageSize=50';

export const SEARCH_PROPERTY_CATEGORY_ID = 'searchPropertyCategoryId';
export const SEARCH_QUERY = 'searchQuery';
export const SEARCH_STATUS = 'searchStatus';
export const DEPOSIT_FLAG = 'depositFlag';
export const IS_ONLINE = 'ISONLINE';
export const BOTH = 'BOTH';
export const OPEN_WAY = 'openWay';
export const PROCEDURE_NO = 'procedureNo';
export const SEARCH_PRICE_LOW = 'searchPriceLow';
export const SEARCH_PRICE_HIGH = 'searchPriceHigh';
export const SEARCH_GOVERNMENT_SUB_CATEGORY_ID =
  'searchGovernmentSubCategoryId';
export const TYPE = 'type';
export const CAR_MANUFACTURE = 'carManufacture';
export const PURPOSE = 'purpose';
export const FIRST_REGIST_DATE_FROM = 'firstRegistDateFrom';
export const FIRST_REGIST_DATE_TO = 'firstRegistDateTo';
export const CAR_MILEAGE_FROM = 'carMileageFrom';
export const CAR_MILEAGE_TO = 'carMileageTo';
export const DIRECTION = 'direction';
export const FIELD = 'field';
export const PAGE_SIZE = 'pageSize';
export const PAGE = 'page';

export const STATUS = 'status';
export const ORDER = 'order';
export const FILTER_AUCTION_TYPE = 'filterAuctionType';
export const FILTER_IMPLEMENTAL_METHOD = 'filterImplementalMethod';
export const FILTER_DEPOSIT = 'filterDeposit';
export const FILTER_AUCTION_RESULT_STATUS = 'filterAuctionResultStatus';
export const FILTER_AUCTION_PROGRESS_STATUS = 'filterAuctionProgressStatus';

export const DATA_EMPTY = 'DATA_EMPTY';