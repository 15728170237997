import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class ProvideEquipment {
  #provideEquipment

  constructor(provideEquipment) {
    this.#provideEquipment = provideEquipment;
  }

  get() {
    return this.#provideEquipment
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#provideEquipment, 0, 1000)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#provideEquipment)
  }
}
