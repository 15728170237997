// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kmanager-left-side-menu {
  min-height: calc(100vh - 100px);
  top: 64px;
  max-height: calc(100% - 100px);
  transform: translateX(-300px);
  width: 300px;
  border-right: 1px solid rgba(0,0,0,.12);
  position: fixed;
  z-index: 2;
  transition-property: transform;
  transition-duration: .3s;
}

.kmanager-left-side-menu.active {
  transform: translateX(0px);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/kmanager-left-side-menu.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,SAAS;EACT,8BAA8B;EAC9B,6BAA6B;EAC7B,YAAY;EACZ,uCAAuC;EACvC,eAAe;EACf,UAAU;EACV,8BAA8B;EAC9B,wBAAwB;AAC1B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".kmanager-left-side-menu {\n  min-height: calc(100vh - 100px);\n  top: 64px;\n  max-height: calc(100% - 100px);\n  transform: translateX(-300px);\n  width: 300px;\n  border-right: 1px solid rgba(0,0,0,.12);\n  position: fixed;\n  z-index: 2;\n  transition-property: transform;\n  transition-duration: .3s;\n}\n\n.kmanager-left-side-menu.active {\n  transform: translateX(0px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
