import { fetchPublicSaleCautionPagesStorage } from 'infrastructure/api/storage/public_sale_caution_pages.js';
import { useState, useEffect, useCallback } from 'react';
import { useError } from 'shared/hooks/useError.js';
import camelizeKeys from 'shared/utils/helper/camelize.js';

export const usePublicSaleCautionPages = (governmentId) => {
  const { setError } = useError();
  const [publicSaleCautionPages, setPublicSaleCautionPages] = useState([]);
  const [auctionPublicSaleCautionPages, setAuctionPublicSaleCautionPages] =
    useState([{}]);
  const [bidPublicSaleCautionPages, setBidPublicSaleCautionPages] = useState([
    {},
  ]);
  const [loading, setLoading] = useState(true);

  const fetchPublicSaleCautionPages = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchPublicSaleCautionPagesStorage();
      setPublicSaleCautionPages(camelizeKeys(data));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await fetchPublicSaleCautionPages();
    };
    fetch().then();
  }, [fetchPublicSaleCautionPages]);

  useEffect(() => {
    // governmentIdによるデータの出しわけ
    const dataFilterByGovernmentId = publicSaleCautionPages.filter(
      (item) => item.governmentId === governmentId,
    );

    // publicSaleTypeによるデータの出しわけ
    setAuctionPublicSaleCautionPages(
      dataFilterByGovernmentId.filter(
        (item) => item.publicSaleType === 'AUCTION',
      ),
    );
    setBidPublicSaleCautionPages(
      dataFilterByGovernmentId.filter((item) => item.publicSaleType === 'BID'),
    );
  }, [publicSaleCautionPages]);

  return { auctionPublicSaleCautionPages, bidPublicSaleCautionPages, loading };
};
