import { useEffect, useState } from 'react';
//import {getBidInfo} from "../read_storage/bid_info";
import { useError } from 'shared/hooks/useError';

export const useBidInfo = () => {
  const { setError } = useError();
  const [bidInfo, setBidInfo] = useState([]);

  useEffect(() => {
    const bidInfo = async () => {
      try {
        //const bidInfo = await getBidInfo();
        //setBidInfo(bidInfo);
        setBidInfo([]);
      } catch (error) {
        setError(error);
      }
    };
    bidInfo().then();
  }, []);

  return bidInfo;
};
