import { useState } from 'react';
import * as Validation from 'shared/utils/helper/managerValidators.js';

export const useEmblemInputsValidation = () => {
  const [useEmblemValid, setUseEmblemValid] = useState('TRUE');
  const [emblemFileValid, setEmblemFileValid] = useState(null);
  const [useCharacterValid, setUseCharacterValid] = useState('TRUE');
  const [characterNameValid, setCharacterNameValid] = useState(null);
  const [characterInsteadEmblemValid, setCharacterInsteadEmblemValid] =
    useState('TRUE');
  const [characterFileValid, setCharacterFileValid] = useState(null);

  function handleUseEmblemValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setUseEmblemValid(invalid);
      return;
    }
    setUseEmblemValid(invalid);
  }

  function handleEmblemFileValid(e, required) {
    let invalid = 'TRUE';
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setEmblemFileValid('選択必須です');
        return;
      }
    }
    setEmblemFileValid(invalid);
  }

  function handleUseCharacterValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setUseCharacterValid(invalid);
      return;
    }
    setUseCharacterValid(invalid);
  }

  function handleCharacterNameValid(e, required) {
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setCharacterNameValid(invalid); // 状態更新
        return;
      }
    }
    invalid = Validation.validateRangeLength(e.target.value, 1, 20);
    if (invalid !== 'TRUE') {
      setCharacterNameValid(invalid); // 状態更新
      return;
    }
    setCharacterNameValid(invalid); // 状態更新
  }

  function handleCharacterInsteadEmblemValid() {
    let invalid = 'TRUE';
    setCharacterInsteadEmblemValid(invalid);
  }

  function handleCharacterFileValid(e, required) {
    let invalid = 'TRUE';
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setCharacterFileValid('選択必須です');
        return;
      }
    }
    setCharacterFileValid(invalid);
  }

  return {
    setters: {
      useEmblem: handleUseEmblemValid,
      emblemFile: handleEmblemFileValid,
      useCharacter: handleUseCharacterValid,
      characterName: handleCharacterNameValid,
      characterInsteadEmblem: handleCharacterInsteadEmblemValid,
      characterFile: handleCharacterFileValid,
    },
    validationMessage: {
      useEmblem: useEmblemValid,
      emblemFile: emblemFileValid,
      useCharacter: useCharacterValid,
      characterName: characterNameValid,
      characterInsteadEmblem: characterInsteadEmblemValid,
      characterFile: characterFileValid,
    },
  };
};
