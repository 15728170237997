import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import Heading4 from 'interfaces/components/common/Heading/Heading4';
import AutomaticEmailTable from 'interfaces/components/Email/AutomaticEmailTable';
import EmailSwitcher from 'interfaces/components/Email/EmailSwitcher';
import { breadcrumbs } from 'interfaces/pages/MailNotificationConfigure/entities/breadcrumbs';
import { useContext, useState } from 'react';

import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import { MailNotificationConfigureContext } from '../entities/MailNotificationConfigureContext';

import 'interfaces/css/AutomaticEmailManagement/automatic-email-management.css';

const NaviAutomaticEmailManagement = () => {
  const {
    depositCommonMails,
    noDepositCommonMails,
    depositAuctionMails,
    depositBidMails,
    noDepositAuctionMails,
    noDepositBidMails,
    governmentType,
    governmentName,
  } = useContext(MailNotificationConfigureContext);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { localStorageProcedureType } = useLocalStorage();

  const labels = [
    {
      label:
        localStorageProcedureType == 'ASSET_SALE'
          ? '入札保証金あり'
          : '公売保証金あり',
      handleClick: () => {
        setActiveTabIndex(0);
      },
    },
    {
      label:
        localStorageProcedureType == 'ASSET_SALE'
          ? '入札保証金なし'
          : '公売保証金なし',
      handleClick: () => {
        setActiveTabIndex(1);
      },
    },
  ];

  return (
    <div
      className="automatic-email-management new-style_navi_automatic-email-management"
      data-testid="automatic-email-management">
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="rhythm">
        <div>
          <Heading2 title="自動送信メール管理" />
          <p className="description" data-testid="description">
            自動送信メールの一覧を表示しています。自動送信メール文に個別に行政機関からのお知らせ部分を設定できます。お知らせ部分の編集をする場合は、「編集」ボタンをクリックしてください。
          </p>
        </div>

        <EmailSwitcher labels={labels} activeTabIndex={activeTabIndex} />

        <div className="heading4-wrap">
          <Heading4 title="共通部分" />
        </div>
        <AutomaticEmailTable
          governmentName={governmentName}
          tableData={
            activeTabIndex === 0 ? depositCommonMails : noDepositCommonMails
          }
          transmissionTimingStyle={{ textAlign: 'center' }}
        />

        <div className="heading4-wrap">
          <Heading4 title="せり売形式の自動送信メール" />
        </div>
        <AutomaticEmailTable
          governmentName={governmentName}
          tableData={
            activeTabIndex === 0 ? depositAuctionMails : noDepositAuctionMails
          }
          transmissionTimingStyle={{ textAlign: 'center' }}
        />

        {governmentType !== 'NATIONAL_TAX_AGENCY' && (
          <>
            <div className="heading4-wrap">
              <Heading4 title="入札形式の自動送信メール" />
            </div>
            <AutomaticEmailTable
              governmentName={governmentName}
              tableData={
                activeTabIndex === 0 ? depositBidMails : noDepositBidMails
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default NaviAutomaticEmailManagement;
