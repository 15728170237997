import { dateFormat } from 'shared/utils/helper/date_format';

export const applicantInfoToJson = (applicantInfo, preregistrationType) => {
  return {
    corp_name: applicantInfo.corpName,
    corp_name_kana: applicantInfo.corpNameKana,
    family_name: applicantInfo.familyName,
    first_name: applicantInfo.firstName,
    family_name_kana: applicantInfo.familyNameKana,
    first_name_kana: applicantInfo.firstNameKana,
    sex: applicantInfo.sex,
    birth_date: dateFormat(applicantInfo.birthDate, 'Y-M-D'),
    zip_code: `${applicantInfo.postalCode1}-${applicantInfo.postalCode2}`,
    location_government_sub_category_id: applicantInfo.prefectureId,
    address: applicantInfo.address,
    house_tel: applicantInfo.houseTel,
    mobile_tel: applicantInfo.mobileTel,
    type: preregistrationType,
  };
};
