import { validateRangeLength, isFullwidthKatakana, hasEmoji } from "../../../../application/validations.js";

export class CorpNameKana {
  #corpNameKana

  constructor(corpNameKana) {
    this.#corpNameKana = corpNameKana;
  }

  get() {
    return this.#corpNameKana
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.isFullwidthKatakana,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#corpNameKana, 0, 128)
  }

  // バリデーションルールの適用
  get isFullwidthKatakana() {
    return isFullwidthKatakana(this.#corpNameKana)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#corpNameKana)
  }
}
