import { isDefinedDateTime, isAfterNow } from "../../../../application/validations.js";

export class GovAplStart {
  #govAplStart

  constructor(govAplStart) {
    this.#govAplStart = govAplStart;
  }

  get() {
    return this.#govAplStart
  }

  get isValid() {
    return [
      this.isDefinedDateTime,
      this.isAfterNow,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get isDefinedDateTime() {
    return isDefinedDateTime(this.#govAplStart)
  }

  // バリデーションルールの適用
  get isAfterNow() {
    return isAfterNow(this.#govAplStart)
  }
}
