// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bgcolor-base)
}

.page-loading-logo {
  width: 150px;
}

.page-loading-dot-wrap {
  margin: 24px 0 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-loading-dot {
  background-color: var(--primary-base);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  animation: pulse 1.5s infinite ease-in-out;
}

.page-loading-dot:nth-child(1) {
  animation-delay: 0s;
}

.page-loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.page-loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.8);
  }
}

`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/page-loading.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB;AACF;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,qCAAqC;EACrC,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,0CAA0C;AAC5C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".page-loading {\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: var(--bgcolor-base)\n}\n\n.page-loading-logo {\n  width: 150px;\n}\n\n.page-loading-dot-wrap {\n  margin: 24px 0 12px 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.page-loading-dot {\n  background-color: var(--primary-base);\n  border-radius: 50%;\n  width: 10px;\n  height: 10px;\n  margin: 0 8px;\n  animation: pulse 1.5s infinite ease-in-out;\n}\n\n.page-loading-dot:nth-child(1) {\n  animation-delay: 0s;\n}\n\n.page-loading-dot:nth-child(2) {\n  animation-delay: 0.2s;\n}\n\n.page-loading-dot:nth-child(3) {\n  animation-delay: 0.4s;\n}\n\n@keyframes pulse {\n  0%, 80%, 100% {\n    transform: scale(0);\n  }\n  40% {\n    transform: scale(1.8);\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
