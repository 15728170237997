// ロジックを含まない処理の実装

import {getMyLists} from "../../../infrastructure/api/storage/my_lists.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { FavoriteGet } from "../entities/Favorite.js";

// getメソッドの実装
export async function getFavorite(type) {
  // SAMPLE
  let response = [];
  if (type === SERVICE) {
    response = await getMyLists();
  }
  if (type === NAVI) {
    response = await getMyLists();
  }
  if (type === KMANAGER) {
    response = await getMyLists();
  }

  if (response instanceof Error) {
    throw new CustomError('Error get Favorite', response);
  }

  let results = [];
  response.map((data) => {
    const result = new FavoriteGet(data);
    results.push(result)
  })

  return results
}
