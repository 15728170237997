import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class CostEstateDescription {
  #costEstateDescription

  constructor(costEstateDescription) {
    this.#costEstateDescription = costEstateDescription;
  }

  get() {
    return this.#costEstateDescription
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#costEstateDescription, 0, 1000)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#costEstateDescription)
  }
}
