import {
  ASSET_SALE,
  PUBLIC_SALE,
} from 'domain/Property/entities/constants/property';

import GuidelineBody from 'interfaces/components//common/Guideline/GuidelineBody.jsx';
import Button from 'interfaces/components/common/Button/Button.jsx';
// api呼び出しprivateへ移行
//import useGuideline from '../../hooks/useGuideline.js';
import { useEffect, useState } from 'react';

const GuidelineAgreement = ({ data, previousStep, nextStep }) => {
  //const { guidelines } = useGuideline(data?.governmentId);
  const guidelines = [];
  const [guidelineData, setGuidelineData] = useState([]);

  useEffect(() => {
    if (guidelines.length === 1) {
      setGuidelineData(guidelines[0]);
      return;
    }
    if (data?.procedureType === PUBLIC_SALE) {
      setGuidelineData(guidelines[0]);
    }
    if (data?.procedureType === ASSET_SALE) {
      setGuidelineData(guidelines[1]);
    }
  }, [guidelines]);

  return (
    <div data-testid="guideline-agreement">
      <GuidelineBody
        governmentName={data?.governmentName}
        procedureType={data?.procedureType}
        data={guidelineData}
      />
      <div className="flex justify-center mb-14">
        <Button
          text={
            <p>
              ガイドラインに同意して、
              <br />
              次のステップへ
            </p>
          }
          width="300px"
          height="52px"
          onClick={nextStep}
          className="agreement-guideline-button mr-4"
        />
        <Button
          text="キャンセル"
          width="120px"
          height="52px"
          onClick={previousStep}
          className="cancel-common-button"
        />
      </div>
    </div>
  );
};

export default GuidelineAgreement;
