// ロジックを含む処理
// フロントから呼ぶ関数

import { compareData } from "../../../shared/utils/helper/compareData.js";
import { getBidInfo } from "../repositories/BidInfo.js";

export async function get(type) {
  return await getBidInfo(type)
}

export async function findById(type, id) {
  const BidInfo = await getBidInfo(type);
  return BidInfo.find(data => data.id === id)
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]))
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]))
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value)
}
