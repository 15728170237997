import { DATA_EMPTY } from 'domain/Property/entities/constants/property?.js';

import { extension } from 'interfaces/components/Shipping/ShippingInfoRegistrationComplete/ExhibitingGovernmentInfos.jsx';
import { dateFormat } from 'shared/utils/helper/date_format.js';

import DeliveryRegistrationInfoBody from './DeliveryRegistrationInfoBody.jsx';
import DeliveryRegistrationInfoHead from './DeliveryRegistrationInfoHead.jsx';

const DeliveryRegistrationInfo = ({ property, isShippingRegistration }) => {
  const isEndBidding = property?.status === 'END_BIDDING';
  if (!isEndBidding) {
    return;
  }

  const isBidder = property?.bidderUser === property?.membershipId;
  const isNextWinner = property?.nextWinner === property?.membershipId;
  if (!isBidder && !isNextWinner) {
    return;
  }

  const deliveryType = () => {
    if (!isShippingRegistration) {
      return '';
    }
    return property.deliveryType === 'PICKUP'
      ? '直接引き取り'
      : '運送業者に依頼';
  };

  const deliveryDateTime = () => {
    if (!isShippingRegistration) {
      return '';
    }
    if (
      !property.deliveryDate ||
      property.deliveryDate === DATA_EMPTY ||
      property.deliveryDate === ''
    ) {
      return '';
    }
    return `${dateFormat(property?.deliveryDate, 'MM-DD')} ${property?.deliveryTime}`;
  };

  const inquiryTel = () => {
    if (!property?.inquiryTel || property?.inquiryTel === DATA_EMPTY) {
      return '';
    }
    return (
      <p>
        {property?.inquiryTel}
        <br />
        {extension(property)}
      </p>
    );
  };

  const infos = [
    { id: 1, label: '引渡方法', value: deliveryType() },
    { id: 2, label: '配達希望日時', value: deliveryDateTime() },
    { id: 3, label: '連絡先機関名', value: property?.inquiryName },
    { id: 4, label: '担当部署名', value: property?.inquiryOffice },
    { id: 5, label: 'メールアドレス', value: property?.inquiryEmail },
    { id: 6, label: '電話番号', value: inquiryTel() },
    { id: 7, label: '電話受付時間', value: property?.inquiryAccept },
  ];

  return (
    <div>
      <DeliveryRegistrationInfoHead
        isShippingRegistration={isShippingRegistration}
        property={property}
      />
      <DeliveryRegistrationInfoBody infos={infos} />
    </div>
  );
};

export default DeliveryRegistrationInfo;
