import GovernmentListProvider from './GovernmentListProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { governmentList } from "../entities/componentMapping.js";

const GovernmentList = ({ type }) => {
  return (
    <GovernmentListProvider type={type}>
      {getComponent(governmentList, type)}
    </GovernmentListProvider>
  );
};

export default GovernmentList;
