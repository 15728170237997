import { ReactComponent as CheckBoxOutline } from "interfaces/assets/svg/checkbox-blank-outline.svg";
import { ReactComponent as CheckBoxMarked } from "interfaces/assets/svg/checkbox-marked.svg";
import "interfaces/css/common/checkbox.css";

const CheckBox = ({ id, name, handleChange, data = false, }) => {
  return (
    <div className="input-checkbox" data-testid="check-box">
      <div className="input-checkbox-control flex">
        <div className="input-checkbox-slot">
          <div className="selection-controls-input">
            <span className={`icon ${data ? "checked" : ""}`}>
              {
                data ? <CheckBoxMarked/> : <CheckBoxOutline/>
              }
            </span>
            <input
              role="checkbox"
              type="checkbox"
              id={id}
              name={name}
              onChange={handleChange}
              checked={data}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckBox;