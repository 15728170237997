
export class UpdatedUser {
    #updatedUser

    constructor(updatedUser) {
        this.#updatedUser = updatedUser;
    }

    get() {
        return this.#updatedUser
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
