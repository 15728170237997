import URL_LINKS from 'interfaces/pages/Dashboard/entities/externalLinks';
import { Link } from 'react-router-dom';

import { Message, MessageLink } from './WaitOpenBid';

export const BiddedBid = ({ data, setIsNoMessage }) => {
  if (data.status === 'DURING_BIDDING') {
    return (
      <p className="ma-0" data-testid="announce-message">
        この物件への入札は完了しました。
        <br />
        開札までしばらくお待ちください。
      </p>
    );
  }

  if (data.status === 'WAIT_OPEN_BID') {
    return (
      <Message
        content={
          '現在執行機関による入札結果確認中です。落札者は入札結果の確認完了後に確定されます。しばらくお待ちください。'
        }
      />
    );
  }

  if (data.status === 'END_BIDDING') {
    if (data.procedureType === 'PUBLIC_SALE') {
      if (data.biddedUser === data.membershipId) {
        return (
          <p className="ma-0" data-testid="announce-message">
            <span>おめでとうございます！ あなたが落札者です。</span>
            <br />
            今後のお手続きについては、お客様から電話で下記連絡先に、問い合わせください。
            <br />
            ※買受代金納付期限までに買受代金の納付を執行機関が確認できない場合、公売保証金は没収となります。
          </p>
        );
      }
      if (data.nextWinner === data.membershipId) {
        return (
          <p className="ma-0" data-testid="announce-message">
            あなたは次順位買受申し込み者となりました。
            <br />
            最高価申し込み者が買受代金を納付しなかった場合などに、あなたは落札者となります。
          </p>
        );
      }
      return (
        <p className="ma-0" data-testid="announce-message">
          残念ながら落札できませんでした。またのご利用をお待ちしております。
          <br />
          今後の開催予定は<a>こちら</a>からご確認ください。
        </p>
      );
    }
    if (data.bidderUser === data.membershipId) {
      return (
        <p className="ma-0" data-testid="announce-message">
          <span>おめでとうございます！ あなたが落札者です。</span>
          <br />
          今後のお手続きについては、お客様から電話で下記連絡先に、問い合わせください。
          <br />
          ※売払代金納付期限までに売払代金の納付を執行機関が確認できない場合、入札保証金は没収となります。
        </p>
      );
    }
    return (
      <p className="ma-0" data-testid="announce-message">
        残念ながら落札できませんでした。またのご利用をお待ちしております。
        <br />
        今後の開催予定は<Link to={'/schedules'}>こちら</Link>
        からご確認ください。
      </p>
    );
  }

  // ここは絶対に入らない。
  if (data.status === 'ADDITIONAL_DURING_BIDDING') {
    return (
      <MessageLink
        prevText={'この物件は現在'}
        href={URL_LINKS.ABOUT_ADD_BID_URL}
        content={'追加入札'}
        laterText={'中です。'}
      />
    );
  }

  setIsNoMessage(true);
};
