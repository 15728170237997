const TransparentButton = ({ text, icon, onClick }) => {
  return (
    <button className="button button-transparent" data-testid="transparent-button" onClick={onClick}>
      <span className="button-content">
        {text}
        <span className="icon icon-right">{icon}</span>
      </span>
    </button>
  );
}

export default TransparentButton;