import { useEffect, useState } from 'react';

import { get as getBid } from '../../../../domain/Bid/services/Bid.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { ConfirmationBidsContext } from '../entities/ConfirmationBidsContext.js';

const ConfirmationBidsProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [bid, setBid] = useState([]);

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getBid(type).then((result) => {
        setBid(result);
      });
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {}, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <ConfirmationBidsContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        bid,
      }}>
      {children}
    </ConfirmationBidsContext.Provider>
  );
};

export default ConfirmationBidsProvider;
