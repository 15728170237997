import GovernmentList from "interfaces/components/Government/List/GovernmentList";

const KmanagerGovernmentList = () => {
    return (
        <div data-testid="kmanager-government-list">
            <GovernmentList/>
        </div>
    );
}

export default KmanagerGovernmentList;
