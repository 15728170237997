
export class EngineType {
    #engineType

    constructor(engineType) {
        this.#engineType = engineType;
    }

    get() {
        return this.#engineType
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
