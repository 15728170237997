export const public_sale_important = (data) => {
  return [
    {
      id: 1,
      title: "危険負担",
      value: data?.importantRiskBearing,
    },
    {
      id: 2,
      title: "契約不適合責任",
      value: data?.importantNonConformingContractsLiability,
    },
    {
      id: 3,
      title: "引き渡し条件",
      value: data?.importantTermsDelivery,
    },
    {
      id: 4,
      title: "執行機関の引き渡し義務",
      value: data?.importantObligationDelivery,
    },
    {
      id: 5,
      title: "返品、交換",
      value: data?.importantReturnItems,
    },
    {
      id: 6,
      title: "保管費用",
      value: data?.importantStorageCost,
    },
    {
      id: 7,
      title: "落札者（最高価申込者）決定後、公売保証金が返還される場合",
      value: data?.importantReturnDeposit,
    },
    {
      id: 8,
      title: "その他",
      value: data?.importantOther,
    },
  ]
}