import { validateSelected } from "../../../../application/validations.js";

export class IsDisplayAuction {
  #isDisplayAuction

  constructor(isDisplayAuction) {
    this.#isDisplayAuction = isDisplayAuction;
  }

  get() {
    return this.#isDisplayAuction
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#isDisplayAuction)
  }
}
