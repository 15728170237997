
export class AuctionId {
  #auctionId

  constructor(auctionId) {
    this.#auctionId = auctionId;
  }

  get() {
    return this.#auctionId
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
