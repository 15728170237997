import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_AUCTIONS = `${BASE_URL_NAVIGATION}/storage/current/auctions/data.json`;
const PREVIOUS_AUCTIONS = `${BASE_URL_NAVIGATION}/storage/previous/auctions/data.json`;

export const getAuctions = async () => {
  try {
    const response = await fetchAuctions(CURRENT_AUCTIONS);
    return extractAuctions(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ auctions ）を取得できませんでした");

    try {
      const response = await fetchAuctions(PREVIOUS_AUCTIONS);
      return extractAuctions(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ auctions ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchAuctions = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractAuctions = (response) => {
  return response.map(auction => auction);
}