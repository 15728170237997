
export class CategoryName {
    #categoryName

    constructor(categoryName) {
        this.#categoryName = categoryName;
    }

    get() {
        return this.#categoryName
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
