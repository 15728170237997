import axios from 'axios';

import {API_BASE_URL} from "../../config/envConstants.js";

export const fetchDivisionData = async () => {
  return await axios.get(
    `${API_BASE_URL}/api/v1/management/division`,
  );
};
export const createDivisionData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/division`,
    body, {headers: headers}
  );

};
export const updateDivisionData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/division/${id}`,
    body, {headers: headers}
  );
};
export const deleteDivisionData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/management/division/${id}`, {
      headers: headers,
    },);
};