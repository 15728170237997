import Button from 'interfaces/components/common/Button/Button.jsx';
import ConfirmSection from 'interfaces/components/common/Form/ConfirmSection.jsx';

import { NaviUserConfirmConst } from './NaviUserConfirmConst.js';
import { NaviUserConfirmList } from './NaviUserConfirmList.js';

const NaviUserConfirm = ({ type, data, toRegister, register, toIndex }) => {
  const BUTTONS_DATA = NaviUserConfirmConst(toRegister, register, toIndex);
  const list = NaviUserConfirmList(data);
  return (
    <div data-testid="navi-user-confirm">
      <ConfirmSection inputsData={list} />
      <div className="pt-9 property-info-general-form-button-box">
        <div className="pr-1 pl-1">
          <Button
            text={BUTTONS_DATA[type].text}
            onClick={BUTTONS_DATA[type].clickHandler}
            className={BUTTONS_DATA[type].className}
          />
        </div>
        {type !== 'index' && (
          <div className="pr-1 pl-1">
            <Button
              text={BUTTONS_DATA['edit'].text}
              onClick={BUTTONS_DATA['edit'].clickHandler}
              className={BUTTONS_DATA['edit'].className}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default NaviUserConfirm;
