import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { get as getApplicationInformation } from 'domain/Applicant/services/ApplicantInformation.js';
import { get as getMessages } from 'domain/News/services/Message';
import { get as getMyUserInformation } from 'domain/User/services/ServiceUser.js';
import { findByPropertyIdAndUserId as getApplicantInformation } from 'domain/Applicant/services/ApplicantInformation.js';
import { findById as getProperty } from 'domain/Property/services/Property.js';
import { findById as getAuctionUser } from 'domain/User/services/ServiceUser.js';
//import { useAuth } from '../../../../shared/hooks/useAuth.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { ShippingContext } from '../entities/ShippingContext.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage.js';

const ShippingProvider = ({ type, children }) => {
  //const { governmentId, governmentType, procedureType } = useAuth();
  const [managementMessages, setManagementMessages] = useState([]);
  const [applicationInformation, setApplicationInformation] = useState([]);
  const [myUserInformation, setMyUserInformation] = useState([]);
  const [property, setProperty] = useState(undefined);
  const [applicantInformations, setApplicantInformations] = useState([]);
  const [auctionUser, setAuctionUser] = useState([]);

  const { id } = useParams();
  const { localStorageUserId } = useLocalStorage();

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getMyUserInformation(type).then((result) => {
        setMyUserInformation(result);
      });

      getMessages(type).then((result) => {
        setManagementMessages(result);
      });

      getProperty(type, id).then((property) => {
        console.log(property);
        setProperty(property);
      });
    }, []);

    useEffect(() => {
      getApplicantInformation(type, id, localStorageUserId).then((result) => {
        setApplicantInformations(result);
      });

      getAuctionUser(type, localStorageUserId).then((result) => {
        setAuctionUser(result);
      });
    }, [localStorageUserId]);
  }

  if (type === NAVI) {
    useEffect(() => {
      getApplicationInformation(type).then((result) => {
        setApplicationInformation(result);
      });
    }, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <ShippingContext.Provider
      value={{
        breadcrumbs,
        managementMessages,
        applicationInformation,
        myUserInformation,
        property,
        applicantInformations,
        auctionUser,
      }}>
      {children}
    </ShippingContext.Provider>
  );
};

export default ShippingProvider;
