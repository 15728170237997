// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import { Body } from './GuidelinesCreate/body';
import { ItemNo } from './GuidelinesCreate/itemNo';
import { Title } from './GuidelinesCreate/title';
import CustomError from '../../../shared/utils/helper/customError.js';
import { NotUndefined } from '../../../shared/utils/helper/NotUndefined.js';

export class GuidelinesGet {
  constructor(data) {
    this.governmentId = data.governmentId;
    this.procedureType = data.procedureType;
    this.publicSaleType = data.publicSaleType;
    this.itemNo = data.itemNo;
    this.isDisplay = data.isDisplay;
    this.title = data.title;
    this.body = data.body;
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class GuidelineDataGet {
  constructor(data) {
    this.itemNo = data.itemNo;
    this.title = data.title;
    this.body = data.body;
    if (this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class GuidelinesCreate {
  constructor(data) {
    this.governmentId = data.governmentId;
    this.procedureType = data.procedureType;
    this.publicSaleType = data.publicSaleType;
    this.isDisplay = data.isDisplay;
    this.data = new GuidelineDataCreate(data);
  }

  get isValid() {
    return (
      this.governmentId.isValid &&
      this.procedureType.isValid &&
      this.publicSaleType.isValid &&
      this.isDisplay.isValid &&
      this.data.isValid
    );
  }
}

export class GuidelineDataCreate {
  constructor(data) {
    this.itemNo = new ItemNo(data.itemNo);
    this.title = new Title(data.title);
    this.body = new Body(data.body);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return this.itemNo.isValid && this.title.isValid && this.body.isValid;
  }
}
