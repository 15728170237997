const GovernmentLabel = ({label}) => {
  return (
    <section>
      <div data-testid="government-label" className="government-organization-label flex align-center flex-grow-0 my-2 mr-10">
        <div data-testid="rendering-label" className="box text-h6">{label}</div>
      </div>
    </section>
  );
}

export default GovernmentLabel;