import { Link } from 'react-router-dom';
import 'interfaces/css/Government/government-table.css';

const TableRow = ({ data, isPublicSale, isKokuzei = false }) => {
  const queryParam = isPublicSale ? '?p=au' : '?p=as';
  return data.map((governments, index) => (
    <tr key={index} data-testid="governments-table-row">
      {isKokuzei === false ? (
        governments[0].region && (
          <th className="in-progress-government-table-th text-body-2 font-weight-regular pt-2 pr-4">
            {governments[0].region}
          </th>
        )
      ) : (
        <></>
      )}
      <td className="in-progress-government-table-td text-body-2 py-2">
        {governments.map((government, index) => (
          <span key={government.id}>
            <span className="text-body-2 text-no-wrap">
              <span className="mr-1">
                <Link to={`/gov/${government.id}/${queryParam}`}>
                  {government.name}
                </Link>
              </span>
              ({government.numOfProperties}件)
              {governments.length !== index + 1 && (
                <span className="text-body-2 px-2">|</span>
              )}
            </span>
          </span>
        ))}
      </td>
    </tr>
  ));
};

export default TableRow;
