
export class GovernmentName {
    #governmentName

    constructor(governmentName) {
        this.#governmentName = governmentName;
    }

    get() {
        return this.#governmentName
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
