import { useEffect } from 'react';

import { useAuth } from '../../../../shared/hooks/useAuth.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { LoginContext } from '../entities/LoginContext.js';

const LoginProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {}, []);
  }

  if (type === NAVI) {
    useEffect(() => {}, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <LoginContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
      }}>
      {children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;
//  const [government, setGovernment] = useState([]);
//    useEffect(() => {
//      getGovernment(type).then(result => {
//        setGovernment(result);
//      });
//    }, []);
