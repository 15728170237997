import Button from 'interfaces/components/common/Button/Button';
import { SortableTh } from 'interfaces/components/common/Table/Th/SortableTh';
import { useSortableData } from 'shared/hooks/useSort.js';
//import KmanagerTableEmptyRecord from "interfaces/components/common/Table/KmanagerTableEmptyRecord";
//import { putApiData } from "../../api/request.js";
import { useContext } from 'react';

//import { useParams } from "react-router-dom";
//import { fetchAuctionData } from "../../api/auction.js";
import { GovernmentPaymentContext } from '../../../../pages/GovernmentPayment/entities/GovernmentPaymentContext';

const headerColumns = [
  { id: 1, column: 'id', text: 'No' },
  { id: 2, column: 'saleNumber', text: '区分番号' },
  { id: 3, column: 'name', text: 'タイトル' },
  { id: 4, column: 'bidPrice', text: '価格' },
  { id: 5, column: 'deposit', text: '保証金額' },
  { id: 6, column: 'bidEndPrice', text: '落札額' },
  { id: 7, column: 'bidSystemFee', text: '落札システム利用料' },
  { id: 8, column: 'isApplyReduction', text: '減額申請' },
  { id: 9, column: 'isNotClaimable', text: '請求対象外' },
  { id: 10, column: 'isExtensionSurveyTerm', text: '警察期間延長' },
];

const GovernmentPaymentTable = () => {
  //const { auctionId } = useParams();
  //const [governmentTypeGroup, setGovernmentTypeGroup] = useState("");
  const { auction, properties } = useContext(GovernmentPaymentContext);
  const {
    items: sortedProperties,
    requestSort,
    sortConfig,
  } = useSortableData(properties);
  console.log(properties);

  //const createBody = (notClaimable) => {
  //  const value = notClaimable ? 'FALSE' : 'TRUE';
  //  return {
  //    is_not_claimable: value
  //  };
  //}

  const putRegisterReduction = () => {
    console.log('todo: fix register reduction');
    //await putApiData(`auctions/${propertyId}/register-reduction`, [])
    //  .then(() => {
    //    let copyData = data.slice(0, data.length);
    //    copyData.map((item) => {
    //      if (item.id !== propertyId) {
    //        return;
    //      }
    //      item.isApplyReduction = 'TRUE'
    //    })
    //    setData(copyData)
    //  })
    //  .catch((err) => {
    //    handleError(err)
    //    console.log(err)
    //  })
  };

  const putRegisterToggleClaimable = () => {
    console.log('todo: fix register toggle claimbable');
    //await putApiData(`auctions/${propertyId}/register-not-claimable`, createBody(isNotClaimable))
    //  .then(() => {
    //    let copyData = data.slice(0, data.length);
    //    copyData.map((item) => {
    //      if (item.id !== propertyId) {
    //        return;
    //      }
    //      item.isNotClaimable = isNotClaimable ? 'FALSE' : 'TRUE';
    //    })
    //    setData(copyData)
    //  })
    //  .catch((err) => {
    //    handleError(err)
    //    console.log(err)
    //  })
  };

  //useEffect(() => {
  //  (async () => {
  //    try {
  //      const auctionGovernmentTypeGroup = await fetchAuctionData(auctionId, "government-type-group")
  //      setGovernmentTypeGroup(auctionGovernmentTypeGroup.data.data.governmentTypeGroup)
  //    } catch (err) {
  //      handleError(err);
  //    }
  //  })()
  //}, []);

  return (
    <table
      data-testid="kmanager-properties-table"
      className="kmanager-table kmanager-table-hover">
      <thead>
        <tr
          data-testid="kmanager-properties-table-tr"
          className="kmanager-table-tr">
          {headerColumns.map((header) => (
            <SortableTh
              key={header.id}
              header={header}
              onClick={() => requestSort(header.column)}
              sortConfig={sortConfig}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedProperties.map((property, index) => (
          <tr
            data-testid="kmanager-properties-table-tr"
            className="kmanager-table-tr"
            key={index}>
            <td
              data-testid="kmanager-properties-table-td"
              className="kmanager-table-td text-no-wrap">
              {property?.id}
            </td>
            <td
              data-testid="kmanager-properties-table-td"
              className="kmanager-table-td text-no-wrap">
              {property?.saleNumber}
            </td>
            <td
              data-testid="kmanager-properties-table-td"
              className="kmanager-table-td text-no-wrap">
              {property?.name}
            </td>
            <td
              data-testid="kmanager-properties-table-td"
              className="kmanager-table-td text-right text-no-wrap">
              {property?.bidPrice.toLocaleString()}円
            </td>
            <td
              data-testid="kmanager-properties-table-td"
              className="kmanager-table-td text-right text-no-wrap">
              {property?.deposit.toLocaleString()}円
            </td>
            <td
              data-testid="kmanager-properties-table-td"
              className="kmanager-table-td text-right text-no-wrap">
              {property?.bidEndPrice.toLocaleString()}円
            </td>
            <td
              data-testid="kmanager-properties-table-td"
              className="kmanager-table-td text-right text-no-wrap">
              {property?.bidSystemFee.toLocaleString()}円
            </td>
            <td
              data-testid="kmanager-properties-table-td"
              className="kmanager-table-td text-center text-no-wrap">
              {property?.isApplyReduction === 'DATA_EMPTY' &&
                auction.governmentTypeGroup !== 'NATIONAL_TAX_AGENCY' && (
                  <Button
                    className="kmanager-common-button text-no-wrap kmanager-common-button-warning"
                    onClick={() => putRegisterReduction(property?.id)}
                    text="承認する"
                  />
                )}
            </td>
            <td
              data-testid="kmanager-properties-table-td"
              className="kmanager-table-td text-center">
              {property?.isNotClaimable !== 'DATA_EMPTY' &&
              property?.isNotClaimable === 'TRUE' ? (
                <Button
                  className="kmanager-common-button text-no-wrap kmanager-common-button-warning"
                  onClick={() =>
                    putRegisterToggleClaimable(
                      property?.id,
                      property?.isNotClaimable,
                    )
                  }
                  text="対象外済み"
                />
              ) : (
                <Button
                  className="kmanager-common-button text-no-wrap kmanager-common-button-warning"
                  onClick={() =>
                    putRegisterToggleClaimable(
                      property?.id,
                      property?.isNotClaimable,
                    )
                  }
                  text="対象外にする"
                />
              )}
            </td>
            <td
              data-testid="kmanager-properties-table-td"
              className="kmanager-table-td text-center">
              {property?.isExtensionSurveyTerm === 'DATA_EMPTY' &&
                property?.propertyType === 'ESTATE' && (
                  <Button
                    className="kmanager-common-button text-no-wrap kmanager-common-button-warning"
                    text="延長する"
                  />
                )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default GovernmentPaymentTable;
