// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.normal-alert {
  color: var(--white);
  background-color: var(--alert);
}

.important-alert {
  color: var(--white);
  background-color: var(--important-alert);
}

.unimportant-alert {
  color: var(--white);
  background-color: var(--unimportant-alert);
}

.alert {
  padding: 16px 8px;
  margin-bottom: 16px;
  border-radius: 4px;
  position: relative;
  border-left: solid 8px rgba(0, 0, 0, .26);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert-title {
  font-weight: bold;
  margin-bottom: 16px;
  overflow-wrap: break-word;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.alert-body {
  margin-bottom: 16px;
  overflow-wrap: break-word;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hidden-alert-button {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  padding: 0;
  width: 28px;
}

.hidden-alert-button-icon {
  cursor: pointer;
  fill: rgba(0, 0, 0, .54);
  height: 24px;
  width: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/alert.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE,mBAAmB;EACnB,0CAA0C;AAC5C;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,yCAAyC;EACzC,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;EACzB,4CAA4C;EAC5C,kCAAkC;AACpC;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,4CAA4C;EAC5C,kCAAkC;AACpC;;AAEA;EACE,mBAAmB;EACnB,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,eAAe;EACf,wBAAwB;EACxB,YAAY;EACZ,WAAW;AACb","sourcesContent":[".normal-alert {\n  color: var(--white);\n  background-color: var(--alert);\n}\n\n.important-alert {\n  color: var(--white);\n  background-color: var(--important-alert);\n}\n\n.unimportant-alert {\n  color: var(--white);\n  background-color: var(--unimportant-alert);\n}\n\n.alert {\n  padding: 16px 8px;\n  margin-bottom: 16px;\n  border-radius: 4px;\n  position: relative;\n  border-left: solid 8px rgba(0, 0, 0, .26);\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.alert-title {\n  font-weight: bold;\n  margin-bottom: 16px;\n  overflow-wrap: break-word;\n  -webkit-font-smoothing: subpixel-antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.alert-body {\n  margin-bottom: 16px;\n  overflow-wrap: break-word;\n  -webkit-font-smoothing: subpixel-antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.hidden-alert-button {\n  align-items: center;\n  background-color: transparent;\n  border: none;\n  display: flex;\n  justify-content: center;\n  padding: 0;\n  width: 28px;\n}\n\n.hidden-alert-button-icon {\n  cursor: pointer;\n  fill: rgba(0, 0, 0, .54);\n  height: 24px;\n  width: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
