import AuctionEntryProvider from './AuctionEntryProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { auctionEntry } from "../entities/componentMapping.js";

const AuctionEntry = ({ type }) => {
  return (
    <AuctionEntryProvider type={type}>
      {getComponent(auctionEntry, type)}
    </AuctionEntryProvider>
  );
};

export default AuctionEntry;
