
export class Password {
  #password

  constructor(password) {
    this.#password = password;
  }

  get() {
    return this.#password
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
