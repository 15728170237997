import { ValidationMessage } from 'interfaces/components/common/ValidationMessage';
import React from 'react';

import FormLabel from './FormLabel';
import RadioButton from '../RadioButton';
import { CautionMessage } from '../CautionMessage';

const InputRadio = React.memo(function InputRadio({
  label,
  invalidMessage,
  required,
  radio_labels,
  state,
  setState,
  cautionMessage,
}) {
  return (
    <div className="kmanager-row">
      <div className="kmanager-col-4">
        <FormLabel label={label} required={required} />
      </div>
      <div className="kmanager-col flex-column">
        <div className="flex form-input" data-testid="input-radio">
          <RadioButton data={radio_labels} state={state} setState={setState} />
        </div>
        <CautionMessage cautionMessage={cautionMessage} />
        <ValidationMessage invalidMessage={invalidMessage} />
      </div>
    </div>
  );
});
export default InputRadio;
