export const radio_label = [
  {id: 1, text: "下書き", value: "TRUE"},
  {id: 2, text: "公開", value: "FALSE"},
]
export const radio_label2 = [
  {id: 1, text: "せり売り形式", value: "AUCTION"},
  {id: 2, text: "入札形式", value: "BID"},
]
export const radio_label3 = [
  {id: 1, text: "必要", value: "TRUE"},
  {id: 2, text: "不要", value: "FALSE"},
]
export const radio_label4 = [
  {id: 1, text: "発行可能"},
  {id: 2, text: "発行不可"},
]
export const car_radio_label1 = [
  {id: 1, text: "自家用"},
  {id: 2, text: "事業用"},
  {id: 3, text: "不明"},
]
export const car_radio_label2 = [
  {id: 1, text: "あり"},
  {id: 2, text: "期限切れ"},
]
export const car_radio_label3 = [
  {id: 1, text: "マニュアル"},
  {id: 2, text: "オートマチック"},
]
export const car_radio_label4 = [
  {id: 1, text: "右ハンドル"},
  {id: 2, text: "左ハンドル"},
]
export const car_radio_label5 = [
  {id: 1, text: "記録簿なし"},
  {id: 2, text: "記録簿あり"},
]
export const estate_radio_label1 = [
  {id: 1, text: "防火"},
  {id: 2, text: "準防火"},
  {id: 3, text: "不明"},
]
export const estate_radio_label2 = [
  {id: 1, text: "登録簿"},
  {id: 2, text: "実測"},
  {id: 3, text: "不明"},
]
export const local_government_management_radio1 = [
  {id: 1, text: "表示する", value: "DISPLAY"},
  {id: 2, text: "表示しない", value: "HIDDEN"},
]
export const local_government_management_radio2 = [
  {id: 1, text: "使用する"},
  {id: 2, text: "使用しない"},
]
export const new_entry_radio1 = [
  {id: 1, text: "行う", value: "AUTO"},
  {id: 2, text: "行わない", value: "MANUAL"},
]
export const new_entry_radio2 = [
  {id: 1, text: "希望する", value: "MAIL"},
  {id: 2, text: "希望しない", value: "NO_MAIL"},
]
export const new_entry_radio3 = [
  {id: 1, text: "押印して郵送する", value: "MAIL"},
  {id: 2, text: "オンライン申請のみ", value: "NO_MAIL"},
]
export const new_entry_radio4 = [
  {id: 1, text: "表示する", value: "DISPLAY"},
  {id: 2, text: "表示しない", value: "HIDDEN"},
]
export const new_entry_radio5 = [
  {id: 1, text: "表示する", value: "DISPLAY"},
  {id: 2, text: "表示しない", value: "HIDDEN"},
]
export const new_entry_radio6 = [
  {id: 1, text: "表示する", value: "DISPLAY"},
  {id: 2, text: "表示しない", value: "HIDDEN"},
]
export const organization_register_radio = [
  {id: 1, text: "利用する"},
  {id: 2, text: "利用しない"},
]
export const navi_user_register_radio1 = [
  {id: 1, text: "親", value: 'PARENT'},
  {id: 2, text: "子", value: 'CHILD'},
]
export const navi_user_register_radio2 = [
  {id: 1, text: "あり", value: 'AUTHORIZED'},
  {id: 2, text: "なし", value: 'NO_AUTHORIZED'},
]
export const navi_user_register_radio3 = [
  {id: 1, text: "受信する", value: 'RECEIVE'},
  {id: 2, text: "受信しない", value: 'NO_RECEIVE'},
]
export const exhibit_radio = [
  {id: 1, text: "対象にする"},
  {id: 2, text: "対象にしない"},
]
export const procedure_pub = [
  {id: 1, text: "クレジットカード、銀行振込など両方", value: "BOTH"},
  {id: 2, text: "クレジットカード", value: "CREDIT_CARD"},
  {id: 3, text: "銀行振込など", value: "BANK_TRANSFER"},
  {id: 4, text: "公売保証金なし", value: "NONE"}
]
export const procedure_asset = [
  {id: 1, text: "クレジットカード、銀行振込など両方", value: "BOTH"},
  {id: 2, text: "クレジットカード", value: "CREDIT_CARD"},
  {id: 3, text: "銀行振込など", value: "BANK_TRANSFER"},
  {id: 4, text: "入札保証金なし", value: "NONE"}
]