// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* このcss使用箇所なし削除したい */
.property-info-general-form-input-text-absolute-area {
  z-index: 1;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/property-info-general-form-input-email.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,UAAU;EACV,kBAAkB;AACpB","sourcesContent":["/* このcss使用箇所なし削除したい */\n.property-info-general-form-input-text-absolute-area {\n  z-index: 1;\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
