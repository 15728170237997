// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

//import { NoticeCreate } from "../../News/entities/News.js";
import { AddBidDuration } from './Auctions/addBidDuration';
import { AdditionalBidEnd } from './Auctions/additionalBidEnd';
import { AplEnd } from './Auctions/aplEnd';
import { AplStart } from './Auctions/aplStart';
import { AuctionEnd } from './Auctions/auctionEnd';
import { BidDecision } from './Auctions/bidDecision';
import { BidEnd } from './Auctions/bidEnd';
import { BidStart } from './Auctions/bidStart';
import { DepositPaymentDate } from './Auctions/depositPaymentDate';
import { DepositType } from './Auctions/depositType';
import { GovAplEnd } from './Auctions/govAplEnd';
import { GovAplStart } from './Auctions/govAplStart';
import { GovernmentTypeGroup } from './Auctions/governmentTypeGroup';
import { Id } from './Auctions/id.js';
import { Name } from './Auctions/name.js';
import { NextPaymentLimit } from './Auctions/nextPaymentLimit';
import { OpenEnd } from './Auctions/openEnd';
import { ProcedureType } from './Auctions/procedureType';
import { PublicSaleType } from './Auctions/publicSaleType';
import CustomError from '../../../shared/utils/helper/customError.js';
import { NotUndefined } from '../../../shared/utils/helper/NotUndefined.js';

export class AuctionGet {
  constructor(data) {
    this.addBidDuration = data.addBidDuration;
    this.additionalBidEnd = data.additionalBidEnd;
    this.aplEnd = data.aplEnd;
    this.aplStart = data.aplStart;
    this.auctionEnd = data.auctionEnd;
    this.bidDecision = data.bidDecision;
    this.bidEnd = data.bidEnd;
    this.bidStart = data.bidStart;
    this.depositPaymentDate = data.depositPaymentDate;
    this.depositType = data.depositType;
    this.govAplEnd = data.govAplEnd;
    this.govAplStart = data.govAplStart;
    this.governmentTypeGroup = data.governmentTypeGroup;
    this.id = data.id;
    this.name = data.name;
    this.nextPaymentLimit = data.nextPaymentLimit;
    this.openEnd = data.openEnd;
    this.procedureType = data.procedureType;
    this.publicSaleType = data.publicSaleType;
    //if (this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return NotUndefined(this);
  }
}

// GetAuction、GetGovernmentからuse_case経由で作成するクラス？
export class AuctionStatisticsGet {
  constructor(data) {
    this.area = data.area;
    this.governmentName = data.governmentName;
    this.category = data.category;
    this.title = data.title;
    this.openWay = data.openWay;
    this.estimateFee = data.estimateFee;
    this.price = data.price;
    this.bidNumber = data.bidNumber;
    this.carBidFee = data.carBidFee;
    this.carBidFeeType = data.carBidFeeType;
    this.estateBidFee = data.estateBidFee;
    this.estateBidFeeType = data.estateBidFeeType;
    this.movableBidFee = data.movableBidFee;
    this.movableBidFeeType = data.movableBidFeeType;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class AuctionCreate {
  constructor(data) {
    this.addBidDuration = new AddBidDuration(data.addBidDuration);
    this.additionalBidEnd = new AdditionalBidEnd(data.additionalBidEnd);
    this.aplEnd = new AplEnd(data.aplEnd);
    this.aplStart = new AplStart(data.aplStart);
    this.auctionEnd = new AuctionEnd(data.auctionEnd);
    this.bidDecision = new BidDecision(data.bidDecision);
    this.bidEnd = new BidEnd(data.bidEnd);
    this.bidStart = new BidStart(data.bidStart);
    this.depositPaymentDate = new DepositPaymentDate(data.depositPaymentDate);
    this.depositType = new DepositType(data.depositType);
    this.govAplEnd = new GovAplEnd(data.govAplEnd);
    this.govAplStart = new GovAplStart(data.govAplStart);
    this.governmentTypeGroup = new GovernmentTypeGroup(
      data.governmentTypeGroup,
    );
    this.id = new Id(data.id);
    this.name = new Name(data.name);
    this.nextPaymentLimit = new NextPaymentLimit(data.nextPaymentLimit);
    this.openEnd = new OpenEnd(data.openEnd);
    this.procedureType = new ProcedureType(data.procedureType);
    this.publicSaleType = new PublicSaleType(data.publicSaleType);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.addBidDuration.isValid &&
      this.additionalBidEnd.isValid &&
      this.aplEnd.isValid &&
      this.aplStart.isValid &&
      this.auctionEnd.isValid &&
      this.bidDecision.isValid &&
      this.bidEnd.isValid &&
      this.bidStart.isValid &&
      this.depositPaymentDate.isValid &&
      this.depositType.isValid &&
      this.govAplEnd.isValid &&
      this.govAplStart.isValid &&
      this.governmentTypeGroup.isValid &&
      this.name.isValid &&
      this.nextPaymentLimit.isValid &&
      this.openEnd.isValid &&
      this.procedureType.isValid &&
      this.publicSaleType.isValid
    );
  }
}
