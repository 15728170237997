import {useSearchParams} from "react-router-dom";

export const useQueryParamController = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const applyQueryParam = (queryParamName, queryParamValue) => {
    if (searchParams.has(queryParamName)) {
      searchParams.set(queryParamName, queryParamValue);
    } else {
      searchParams.append(queryParamName, queryParamValue);
    }
    setSearchParams(searchParams);
  }

  const applyCommaSeparatedQueryParam = (queryParamName, queryParamValue) => {
    if (searchParams.has(queryParamName)) {
      const currentQueryParam = searchParams.get(queryParamName).split(',');
      if (currentQueryParam[0] === '') {
        currentQueryParam.shift();
      }
      const newQueryParam = [...currentQueryParam, queryParamValue].join(',');
      searchParams.set(queryParamName, newQueryParam);
    } else {
      searchParams.append(queryParamName, queryParamValue);
    }
    setSearchParams(searchParams);
  }

  const removeCommaSeparatedQueryParam = (queryParamName, queryParamValue) => {
    if (searchParams.has(queryParamName)) {
      const currentQueryParam = searchParams.get(queryParamName).split(',');
      if (currentQueryParam[0] === '') {
        currentQueryParam.shift();
      }
      const newQueryParam = currentQueryParam.filter(item => item !== queryParamValue).join(',');
      searchParams.set(queryParamName, newQueryParam);
      setSearchParams(searchParams)
    }
  }

  const appendQueryParam = (queryParamName, queryParamValue) => {
    if (!searchParams.has(queryParamName)) {
      searchParams.append(queryParamName, queryParamValue);
      setSearchParams(searchParams)
    }
  }

  return { applyQueryParam, appendQueryParam, applyCommaSeparatedQueryParam, removeCommaSeparatedQueryParam }
}