
export class Payments {
    #payments

    constructor(payments) {
        this.#payments = payments;
    }

    get() {
        return this.#payments
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
