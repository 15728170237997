import SmallTopicTitle from '../../../common/Heading/SmallTopicTitle.jsx';
import CautionTopicTable from '../../CautionTopicTable.jsx';
import ManualMarkedList from '../../ManualMarkedList.jsx';
import CautionManualMarkedList from '../../CautionManualMarkedList.jsx';

const rightsTransferData = [
  {
    headline: '動産',
    content: <ManualMarkedList data={[
      {
        mark: '・',
        text: <>直接引き渡し<br/>執行機関の案内にしたがい、公売物件を引き取ってください。引渡場所が執行機関の事務所以外である場合は、執行機関が「売却決定通知書」を交付しますので、引渡場所で保管人に提示し、公売物件を引き取ってください。引渡場所は、物件詳細ページで確認してください。なお、引渡場所に執行機関職員は同行しません。</>,
      },
      {
        mark: '・',
        text: <>宅配便などで引き取る<br/>執行機関が買受代金の納付および必要書類の到着を確認した後に、公売物件を発送いたします。なお、送付費用は落札者の負担となります。また、公売物件が美術品などで特別な送付方法を希望する場合は、あらかじめ執行機関に相談してください。</>,
      },
    ]}/>,
  },
  {
    headline: '自動車',
    content: <ManualMarkedList data={[
      {
        mark: '・',
        text: <>権利移転手続き<br/>執行機関は、買受代金納付期限までに代金の納付を確認できた場合、必要書類の提出をもって権利移転の手続き（登録）を行います。</>,
      },
      {
        mark: '・',
        text: <>直接引き渡し<br/>執行機関の案内にしたがい、公売物件を引き取ってください。売却決定後（入札終了日の7日後）、執行機関が代金納付確認をした後に引き取りが可能となります。買受代金納付期限の翌日以降に引き取る場合は、別途保管料を負担していただくことがあります。
          （詳細は落札後にいただく電話などで説明します）</>,
      },
    ]}/>,
  },
  {
    headline: '不動産',
    content: <ManualMarkedList data={[
      {
        mark: '・',
        text: <>権利移転手続き<br/>執行機関は、買受代金納付期限までに代金の納付を確認できた場合、必要書類の提出をもって権利移転の手続き（不動産登記の嘱託）を行います。開札日から所有権移転の登記手続き完了までは、●か月半程度の期間を要します。 なお、執行機関は落札者への不動産登記簿上の所有権移転などの登記は行いますが、実際の引き渡しは行いません。</>,
      },
    ]}/>,
  }
]

const RightsTransferDescriptionAuction = () => {
  return (
    <div className="pt-9" data-testid="rights-transfer-description">
      <SmallTopicTitle text='物件の権利移転について' isFontBold={true}/>
      <CautionTopicTable recordData={rightsTransferData} className='mt-3'/>
      <CautionManualMarkedList title="ご注意" className="pt-3" data={[
        {
          text: '自動車を落札した方の「使用の本拠の位置」を管轄する運輸支局および自動車検査登録事務所が、前所有者（現在の登録を受けている所有者）と異なる場合、落札者自身の「使用の本拠の位置」を管轄する運輸支局及び自動車検査登録事務所に当該自動車を持ち込む必要があります。'
        },
      ]}/>
    </div>
  );
}

export default RightsTransferDescriptionAuction;