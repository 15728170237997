import Breadcrumb from "interfaces/components/common/Breadcrumb";
import Heading2 from "interfaces/components/common/Heading/Heading2.jsx";
import NaviManualList from "interfaces/components/Manual/NaviManualList";
import {breadcrumbs} from "interfaces/pages/Manual/entities/breadcrumbs";

const NaviManual = () => {
    return (
        <div data-testid="manual">
            <Breadcrumb breadcrumbs={breadcrumbs}/>
            <Heading2 title="各種資料ダウンロード"/>
            <NaviManualList/>
        </div>
    );
}
export default NaviManual;