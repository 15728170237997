import { validateSelected } from "../../../../application/validations.js";

export class AccountType {
  #accountType

  constructor(accountType) {
    this.#accountType = accountType;
  }

  get() {
    return this.#accountType
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#accountType)
  }
}
