import PromotionProvider from './PromotionProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { promotion } from "../entities/componentMapping.js";

const Promotion = ({ type }) => {
  return (
    <PromotionProvider type={type}>
      {getComponent(promotion, type)}
    </PromotionProvider>
  );
};

export default Promotion;
