import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_ESTATE_PROPERTIES = `${BASE_URL_NAVIGATION}/storage/current/estate_properties/data.json`;
const PREVIOUS_ESTATE_PROPERTIES = `${BASE_URL_NAVIGATION}/storage/previous/estate_properties/data.json`;

export const fetchEstatePropertiesStorage = async () => {
  try {
    const response = await fetchCurrentEstateProperties();
    return extractEstateProperties(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ estate_properties ）を取得できませんでした");

    try {
      const response = await fetchPreviousEstateProperties();
      return extractEstateProperties(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ estate_properties ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchCurrentEstateProperties = async () => {
  return fetchEstateProperties(CURRENT_ESTATE_PROPERTIES);
};

export const fetchPreviousEstateProperties = async () => {
  return fetchEstateProperties(PREVIOUS_ESTATE_PROPERTIES);
};

export const fetchEstateProperties = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractEstateProperties = (response) => {
  return response.map(estate_property => estate_property);
}