import Switcher from "interfaces/components/common/Switcher";
import { useState } from 'react';

import BothForm from "./BothForm";

const IndividualForm = ({auctionData, bidData}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const labels = [
    'せり売形式',
    '入札形式'
  ];

  return (
    <div data-testid="individual-form">
      <Switcher labels={labels} activeTabIndex={activeTabIndex} setActiveTab={setActiveTabIndex}/>
      { activeTabIndex === 0 && <BothForm validations={auctionData}/> }
      { activeTabIndex === 1 && <BothForm validations={bidData}/> }
    </div>
  );
}

export default IndividualForm