import pino from 'pino';

import { BASE_URL_NAVIGATION } from '../../../config/envConstants.js';
import { fetchData } from '../../../shared/utils/helper/fetchData.js';

const logger = pino();
// 動作確認用
const CURRENT_GUIDELINES = () =>
  `${BASE_URL_NAVIGATION}/storage/current/guidelines/data.json`;
const PREVIOUS_GUIDELINES = () =>
  `${BASE_URL_NAVIGATION}/storage/previous/guidelines/data.json`;

export const fetchGuidelinesStorage = async (governmentId) => {
  try {
    const response = await fetchCurrentGuidelines(governmentId);
    return extractGuidelines(response);
  } catch (error) {
    logger.warn({ error }, '現在の通知（ guidelines ）を取得できませんでした');

    try {
      const response = await fetchPreviousGuidelines(governmentId);
      return extractGuidelines(response);
    } catch (error) {
      logger.error(
        { error },
        '以前の通知（ guidelines ）の取得にも失敗しました',
      );
      return [];
    }
  }
};

export const fetchCurrentGuidelines = async (governmentId) => {
  return fetchGuidelines(CURRENT_GUIDELINES(governmentId));
};

export const fetchPreviousGuidelines = async (governmentId) => {
  return fetchGuidelines(PREVIOUS_GUIDELINES(governmentId));
};

export const fetchGuidelines = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
};

export const extractGuidelines = (response) => {
  return response.map((guideline) => guideline);
};
