/**
 *  TODO:
 *   検索機能
 *
 *   テストファイル: react/src/service/__tests__/components/common/LeftSideMenu/FreeSearch.test.jsx
 *   現行のコード: /work/members_student/original/gl-auc/front/components/sidemenu/SideMenuSearchBox.m.vue
 */

import { ReactComponent as SearchIcon } from 'interfaces/assets/svg/search_icon.svg';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  QUERY_PARAM_FREE_SEARCH,
  SEARCH_QUERY,
} from 'shared/utils/constants/queryParam';

const FreeSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [inputValue, setInputValue] = useState('');
  const [searchParams] = useSearchParams();
  const clickHandle = () => {
    navigate(`/search?searchQuery=${inputValue}&${QUERY_PARAM_FREE_SEARCH}`);
  };
  const changeHandle = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (searchParams.has(SEARCH_QUERY) && location.pathname === '/search') {
      setInputValue(searchParams.get(SEARCH_QUERY));
    }
  }, []);

  return (
    <div
      className="free-search flex justify-space-between"
      data-testid="free-search">
      <input
        className="search-bar"
        placeholder="掘り出しモノを探す"
        data-testid="search-bar"
        onChange={changeHandle}
        value={inputValue}
      />
      <div className="search-button-wrap">
        <button
          className="search-button"
          data-testid="search-button"
          onClick={clickHandle}>
          <SearchIcon className="search-icon" title="search" />
        </button>
      </div>
    </div>
  );
};

export default FreeSearch;
