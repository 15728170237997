import Heading2 from "interfaces/components/common/Heading/Heading2.jsx";
import "interfaces/css/PropertyList/property-list-page-description.css";

const PropertyListPageDescription = ({title, content}) => {
    return (
        <div className="property-list-page-description" data-testid="property-list-page-description">
            <Heading2 title={title}/>
            <div className="description">
                <p data-testid="property-list-page-description-content">{content}</p>
            </div>
        </div>
    );
}
export default PropertyListPageDescription
