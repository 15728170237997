import { notInclude, validateRangeLength } from "../../../../application/validations.js";

export class MobileTel {
  #mobileTel

  constructor(mobileTel) {
    this.#mobileTel = mobileTel;
  }

  get() {
    return this.#mobileTel
  }

  get isValid() {
    return [
      this.notIncludeHyphen,
      this.validateRangeLength,
      this.notIncludeSpace,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get notIncludeHyphen() {
    return notInclude(this.#mobileTel, ['-'])
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#mobileTel, 10, 11)
  }

  // バリデーションルールの適用
  get notIncludeSpace() {
    return notInclude(this.#mobileTel, [' '])
  }
}
