import ConfirmNumberForm from './ConfirmNumberForm.jsx';
import './css/confirm-number-modal-contents.css'

const ConfirmNumberModalContents = ({ handleClose, setModalType, targetEmail }) => {
  return (
    <div className="confirm-number-modal-contents" data-testid="confirm-number-modal-contents">
      <p className="confirm-number-modal-contents-title" data-testid="confirm-number-modal-contents-title">
        確認コード入力
      </p>
      <p data-testid="confirm-number-modal-contents-caption">
        入力されたメールアドレスにメールを送信しました。<br/>
        メールに記載された確認コードを入力してください。
      </p>
      <ConfirmNumberForm handleClose={handleClose} setModalType={setModalType} targetEmail={targetEmail}/>
    </div>
  );
};

export default ConfirmNumberModalContents;
