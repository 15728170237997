// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-list .search-list .property-card {
  width: 24%;
  margin-top: 8px;
  margin-bottom: 24px;
}

.property-list .search-list .thumb-item.property-card {
  width: 100%;
}

.property-list .search-list .font-weight-bold {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertyList/search-list.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".property-list .search-list .property-card {\n  width: 24%;\n  margin-top: 8px;\n  margin-bottom: 24px;\n}\n\n.property-list .search-list .thumb-item.property-card {\n  width: 100%;\n}\n\n.property-list .search-list .font-weight-bold {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
