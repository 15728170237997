const LightButton = ({children, onClick}) => {
  return (
    <button
      className="button button-light button-shadow"
      data-testid="light-button"
      onClick={onClick}
    >
      <span className="button-content">{children}</span>
    </button>
  );
}

export default LightButton;