import Button from '../../../common/Button/Button';

import 'interfaces/css/Dashboard/kmanager-auction-table-record.css';
import { JustifyContentFlex } from 'interfaces/components/common/FlexBox';
import { ProcedureTypes } from 'shared/utils/constants/government';

const GovernmentPropertyTableRecord = ({ auction, property, setId }) => {
  return (
    <tr
      className="kmanager-auction-table-record"
      data-testid="kmanager-auction-table-record">
      <td data-testid="kmanager-auction-table-td">
        {auction.procedureType === ProcedureTypes.ASSET_SALE.string && (
          <div>{property?.resolutionDate}</div>
        )}
      </td>
      <td data-testid="kmanager-auction-table-td">
        {property?.resolutionDate &&
          auction.procedureType === ProcedureTypes.ASSET_SALE.string && (
            <Button
              className="delete-button"
              text={property.fixResolutionDate || '議決日登録'}
            onClick={() => setId(property.id)}
          />
        )}
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{property?.printOrder}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{property?.saleNumber}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{property.name}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{property.cancelDate}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{property.bidPrice.toLocaleString()}円</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{property?.deposit.toLocaleString()}円</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{(property.bidEndPrice || 0).toLocaleString()}円</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <JustifyContentFlex justifycontent="space-around">
          <div>{property.offlineTrialCount}</div>
          <div>{property.offlineApplicantCount}</div>
        </JustifyContentFlex>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <JustifyContentFlex justifycontent="space-around">
          <div>{property.onlineTrialCount}</div>
          <div>{property.onlineApplicantCount}</div>
        </JustifyContentFlex>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{property.bidderCount}</div>
      </td>
      {auction.procedureType !== ProcedureTypes.ASSET_SALE.string && (
        <td data-testid="kmanager-auction-table-td">
          <div>{property.additionCount}</div>
        </td>
      )}
      <td data-testid="kmanager-auction-table-td">
        <div>{property.divisionName}</div>
      </td>
    </tr>
  );
};

export default GovernmentPropertyTableRecord;
