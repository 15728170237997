
export class ReceiverGovernmentSubCategoryId {
  #receiverGovernmentSubCategoryId

  constructor(receiverGovernmentSubCategoryId) {
    this.#receiverGovernmentSubCategoryId = receiverGovernmentSubCategoryId;
  }

  get() {
    return this.#receiverGovernmentSubCategoryId
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
