import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel';
import { DateTime } from 'luxon';
import { useState } from 'react';
import handleValidationMessage from 'shared/utils/helper/handleValidationMessage.js';
import { datePickerSlotProps } from 'shared/utils/helper/muiSlotProps.js';
import { DATA_EMPTY } from '../../../../../shared/utils/helper/constants';

const BirthDateInput = ({ birthDate, handleChange, validationMessage }) => {
  const [open, setOpen] = useState(false);

  return (
    <tr data-testid="birth-date-input">
      <th data-testid="birth-date-input-label">
        生年月日
        <RequiredLabel />
      </th>
      <td data-testid="birth-date-input-body">
        <div>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              value={DateTime.fromJSDate(new Date(birthDate))}
              views={['year', 'month', 'day']}
              openTo="year"
              onChange={(selectDate) => handleChange(selectDate.toJSDate())}
              format="yyyy-MM-dd"
              slotProps={
                birthDate !== undefined
                  ? datePickerSlotProps(birthDate, setOpen)
                  : datePickerSlotProps(DATA_EMPTY, setOpen)
              }
              open={open}
              onClose={() => setOpen(false)}
            />
          </LocalizationProvider>
        </div>
        <small className="validate-message">
          {handleValidationMessage(validationMessage)}
        </small>
      </td>
    </tr>
  );
};

export default BirthDateInput;
