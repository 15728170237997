import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class InvoiceAddress {
  #invoiceAddress

  constructor(invoiceAddress) {
    this.#invoiceAddress = invoiceAddress;
  }

  get() {
    return this.#invoiceAddress
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#invoiceAddress, 1, 50)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#invoiceAddress)
  }
}
