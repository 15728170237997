// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import CustomError from "../../../shared/utils/helper/customError.js";
import { NotUndefined } from "../../../shared/utils/helper/NotUndefined.js";

export class FavoriteGet {

  constructor(data) {
    this.propertyId = data.propertyId;
    this.auctionUserId = data.auctionUserId;
    //if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return NotUndefined(this)
  }
}

export class FavoriteCreate {

  constructor(data) {
    this.property_id = data.property_id;
    this.auction_user_id = data.auction_user_id;
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return this.property_id.isValid &&
      this.auction_user_id.isValid
  }
}
