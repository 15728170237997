// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq .contact-us {
  border: 1px solid #ccc;
}

.faq .contact-us .heading3 {
  background-color: #fbe2ca;
  border-bottom: 1px solid #ccc;
  padding: 12px;
  margin-block-start: initial;
  margin-block-end: initial;
  margin-inline-start: initial;
  margin-inline-end: initial;
}

.faq .contact-us-example-list {
  list-style-type: disc;
}

.faq .contact-us-example-item {
  list-style-type: initial;
}

.faq .contact-us-general-customer-list {
  list-style-type: disc;
}

.faq .contact-us-general-customer-item {
  list-style-type: initial;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Faq/contact-us.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,6BAA6B;EAC7B,aAAa;EACb,2BAA2B;EAC3B,yBAAyB;EACzB,4BAA4B;EAC5B,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".faq .contact-us {\n  border: 1px solid #ccc;\n}\n\n.faq .contact-us .heading3 {\n  background-color: #fbe2ca;\n  border-bottom: 1px solid #ccc;\n  padding: 12px;\n  margin-block-start: initial;\n  margin-block-end: initial;\n  margin-inline-start: initial;\n  margin-inline-end: initial;\n}\n\n.faq .contact-us-example-list {\n  list-style-type: disc;\n}\n\n.faq .contact-us-example-item {\n  list-style-type: initial;\n}\n\n.faq .contact-us-general-customer-list {\n  list-style-type: disc;\n}\n\n.faq .contact-us-general-customer-item {\n  list-style-type: initial;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
