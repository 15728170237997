
import {useState} from "react";
import * as Validation from "shared/utils/helper/managerValidators.js";

export const UseServiceProviderInputsValidation = () =>{
  const [postalCodeValid, setPostalCodeValid] = useState('TRUE');
  const [addressValid, setAddressValid] = useState('TRUE');
  const [serviceProviderNameValid, setServiceProviderNameValid] = useState('TRUE');
  const [serviceProviderPositionValid, setServiceProviderPositionValid] = useState('TRUE');
  const [ceoNameValid, setCeoNameValid] = useState('TRUE');
  const [registrationNumberValid, setRegistrationNumberValid] = useState('TRUE');
  const [bankNameValid, setBankNameValid] = useState('TRUE');
  const [branchNameValid, setBranchNameValid] = useState('TRUE');
  const [serviceProviderDepositTypeValid, setServiceProviderDepositTypeValid] = useState('TRUE');
  const [accountNumberValid, setAccountNumberValid] = useState('TRUE');
  const [accountHolderValid, setAccountHolderValid] = useState('TRUE');
  const [contactPersonValid, setContactPersonValid] = useState('TRUE');
  const [contactDepartmentValid, setContactDepartmentValid] = useState('TRUE');
  const [contactPaEmailValid, setContactPaEmailValid] = useState('TRUE');
  const [contactPpsEmailValid, setContactPpsEmailValid] = useState('TRUE');
  const [confirmerValid, setConfirmerValid] = useState('TRUE');
  const [issuerValid, setIssuerValid] = useState('TRUE');
  const [remarksValid, setRemarksValid] = useState('TRUE');

  function handlePostalCodeValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setPostalCodeValid(invalid);
      return
    }
    setPostalCodeValid(invalid); // エラーメッセージのリセット
  }

  function handleAddressValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setAddressValid(invalid);
      return
    }

    setAddressValid(invalid); // エラーメッセージのリセット
  }

  function handleServiceProviderNameValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setServiceProviderNameValid(invalid);
      return
    }
    setServiceProviderNameValid(invalid); // エラーメッセージのリセット
  }

  function handleServiceProviderPositionValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setServiceProviderPositionValid(invalid);
      return
    }
    setServiceProviderPositionValid(invalid); // エラーメッセージのリセット
  }

  function handleCeoNameValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setCeoNameValid(invalid);
      return
    }
    setCeoNameValid(invalid); // エラーメッセージのリセット
  }

  function handleRegistrationNumberValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setRegistrationNumberValid(invalid);
      return
    }
    setRegistrationNumberValid(invalid); // エラーメッセージのリセット
  }

  function handleBankNameValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setBankNameValid(invalid);
      return
    }
    setBankNameValid(invalid); // エラーメッセージのリセット
  }

  function handleBranchNameValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setBranchNameValid(invalid);
      return
    }
    setBranchNameValid(invalid); // エラーメッセージのリセット
  }

  function handleServiceProviderDepositTypeValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setServiceProviderDepositTypeValid(invalid);
      return
    }
    setServiceProviderDepositTypeValid(invalid); // エラーメッセージのリセット
  }

  function handleAccountNumberValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setAccountNumberValid(invalid);
      return
    }
    setAccountNumberValid(invalid); // エラーメッセージのリセット
  }

  function handleAccountHolderValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setAccountHolderValid(invalid);
      return
    }
    setAccountHolderValid(invalid); // エラーメッセージのリセット
  }

  function handleContactPersonValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setContactPersonValid(invalid);
      return
    }
    setContactPersonValid(invalid); // エラーメッセージのリセット
  }

  function handleContactDepartmentValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setContactDepartmentValid(invalid);
      return
    }
    setContactDepartmentValid(invalid); // エラーメッセージのリセット
  }

  function handleContactPaEmailValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setContactPaEmailValid(invalid);
      return
    }
    setContactPaEmailValid(invalid); // エラーメッセージのリセット
  }

  function handleContactPpsEmailValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setContactPpsEmailValid(invalid);
      return
    }
    setContactPpsEmailValid(invalid); // エラーメッセージのリセット
  }

  function handleConfirmerValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setConfirmerValid(invalid);
      return
    }
    setConfirmerValid(invalid); // エラーメッセージのリセット
  }

  function handleIssuerValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setIssuerValid(invalid);
      return
    }
    setIssuerValid(invalid); // エラーメッセージのリセット
  }

  function handleRemarksValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRequired(e.target.value);
    if (invalid) {
      setRemarksValid(invalid);
      return
    }
    setRemarksValid(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      postalCode:handlePostalCodeValid,
      address:handleAddressValid,
      name:handleServiceProviderNameValid,
      position:handleServiceProviderPositionValid,
      ceoName:handleCeoNameValid,
      registrationNumber:handleRegistrationNumberValid,
      bankName:handleBankNameValid,
      branchName:handleBranchNameValid,
      depositType:handleServiceProviderDepositTypeValid,
      accountNumber:handleAccountNumberValid,
      accountHolder:handleAccountHolderValid,
      contactPerson:handleContactPersonValid,
      contactDepartment:handleContactDepartmentValid,
      contactPaEmail:handleContactPaEmailValid,
      contactPpsEmail:handleContactPpsEmailValid,
      confirmer:handleConfirmerValid,
      issuer:handleIssuerValid,
      remarks:handleRemarksValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      postalCode: postalCodeValid,
      address: addressValid,
      name: serviceProviderNameValid,
      position: serviceProviderPositionValid,
      ceoName: ceoNameValid,
      registrationNumber: registrationNumberValid,
      bankName: bankNameValid,
      branchName: branchNameValid,
      depositType: serviceProviderDepositTypeValid,
      accountNumber: accountNumberValid,
      accountHolder: accountHolderValid,
      contactPerson: contactPersonValid,
      contactDepartment: contactDepartmentValid,
      contactPaEmail: contactPaEmailValid,
      contactPpsEmail: contactPpsEmailValid,
      confirmer: confirmerValid,
      issuer: issuerValid,
      remarks: remarksValid,
    }
  }
  return validations;
}