import styled from 'styled-components';

//import { useAuth } from '../../../../../hooks/useAuth.js';
import ImageDetailPropertyCardBody from './ImageDetailPropertyCardBody';
import ImageDetailPropertyCardHead from './ImageDetailPropertyCardHead';

const Wrapper = styled.div`
  background-color: var(--white);
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  -webkit-box-shadow:
    0 0 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow:
    0 0 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin: 8px 0 24px 0;

  @media (max-width: 719px) {
    .kservice & {
      width: 49% !important;
    }
  }
`;

const ImageDetailPropertyCard = ({
  data,
  width = '24%',
  isShowGovernmentName = false,
}) => {
  //const { userId } = useAuth();
  const isSuspension = data.status === 'CANCEL';

  return (
    <Wrapper style={{ width: width }} data-testid="image-detail-property-card">
      <ImageDetailPropertyCardHead
        isShowGovernmentName={isShowGovernmentName}
        isSuspension={isSuspension}
        data={data}
        //userId={userId}
      />
      <ImageDetailPropertyCardBody data={data} />
    </Wrapper>
  );
};

export default ImageDetailPropertyCard;
