import Button from 'interfaces/components/common/Button/Button';
import Heading3 from 'interfaces/components/common/Heading/Heading3';
import FormPullDownWithCaution from 'interfaces/components/Form/navi/FormPullDownWithCaution';
import { useNavigate } from 'react-router-dom';
import {
  document_send_description1,
  document_send_description2,
  document_send_description3,
  document_send_description4,
  document_send_description_tel_without_extention,
} from 'shared/utils/helper/DocumentSend';
import {
  caution_description12,
  caution_description_char_50,
} from 'shared/utils/helper/DummyEditFormData';
import { pulldown_prefecture } from 'shared/utils/helper/Pulldown';

import FormPostalWithCaution from '../../Form/navi/FormPostalWithCaution';
import FormTelWithCaution from '../../Form/navi/FormTelWithCaution';
import FormTextWithCaution from '../../Form/navi/FormTextWithCaution';
//import configs from "../../api/configs/documentSendPage.js";
//import { allFetch } from "../../api/newEntry.js";

const DocumentSendForm = ({ validations, setIsOpen, isValid }) => {
  console.log(validations);
  const navigate = useNavigate();

  async function update() {
    //    const Configs = configs(GOVERNMENT_ID, PROCEDURE_TYPE, validations)
    //    await allFetch(Configs);
    setIsOpen(true);
  }

  const backToLocalGovernmentManagement = () => {
    navigate('/navi/local-government-management');
  };

  return (
    <div
      className="new-style_navi_local-government-management-top-form"
      data-testid="document-send-form">
      <div className="pt-9">
        <Heading3 text="1. システム利用料請求書送付先" />
        <div className="property-info-general-form-table">
          <FormPostalWithCaution
            required={true}
            postData={validations.data.invoicePostalCode}
            postData1={validations.data.invoicePostalCodeFirst}
            postData2={validations.data.invoicePostalCodeLast}
            setPostData={validations.setters.handleInvoicePostalCode}
            handlerPost1={validations.setters.handleInvoicePostalCodeFirst}
            handlerPost2={validations.setters.handleInvoicePostalCodeLast}
            caution_description={caution_description12}
            invalidMessage={
              validations.validationMessage.invoiceDocumentPostalCodeValid
            }
            invalid1Message={
              validations.validationMessage.invoiceDocumentPostalCodeFirstValid
            }
            invalid2Message={
              validations.validationMessage.invoiceDocumentPostalCodeLastValid
            }
          />
          <FormPullDownWithCaution
            label={'都道府県'}
            listItemData={pulldown_prefecture}
            selectedData={pulldown_prefecture.find(
              (item) => item.value.toString() === validations.data.invoiceArea,
            )}
            formStateHandler={validations.setters.handleInvoiceArea}
            width={'50%'}
          />
          <FormTextWithCaution
            required={true}
            label={'市区町村'}
            maxCharacters={50}
            data={validations.data.invoiceCity}
            handler1={validations.setters.handleInvoiceCity}
            invalidMessage={validations.validationMessage.cityRequiredValid}
            caution_description={caution_description_char_50}
          />
          <FormTextWithCaution
            required={true}
            label={'住所（続き）'}
            maxCharacters={50}
            data={validations.data.invoiceAddress}
            handler1={validations.setters.handleInvoiceAddress}
            invalidMessage={
              validations.validationMessage.invoiceAddressRequiredValid
            }
            caution_description={caution_description_char_50}
          />
          <FormTextWithCaution
            label={'建物'}
            maxCharacters={50}
            data={validations.data.invoiceBuildName}
            handler1={validations.setters.handleInvoiceBuildName}
            invalidMessage={
              validations.validationMessage.invoiceDocumentBuildValid
            }
            caution_description={caution_description_char_50}
          />
          <FormTextWithCaution
            label={'あて名ご名義人'}
            required={true}
            maxCharacters={50}
            data={validations.data.invoiceWrittenDestination}
            handler1={validations.setters.handleInvoiceWrittenDestination}
            invalidMessage={
              validations.validationMessage
                .invoiceWrittenDestinationRequiredValid
            }
            caution_description={document_send_description1}
          />
          <FormTextWithCaution
            label={'ご担当部署'}
            maxCharacters={50}
            required={true}
            data={validations.data.invoiceDepartment}
            handler1={validations.setters.handleInvoiceDepartment}
            invalidMessage={
              validations.validationMessage.invoiceDepartmentRequiredValid
            }
            caution_description={document_send_description2}
          />
          <FormTextWithCaution
            label={'システム利用料振込人名義'}
            maxCharacters={50}
            data={validations.data.invoicePayerName}
            handler1={validations.setters.handleInvoicePayerName}
            invalidMessage={validations.validationMessage.invoicePayerNameValid}
            caution_description={caution_description_char_50}
          />
          <FormTelWithCaution
            label={'電話番号'}
            required={true}
            caution_description={
              document_send_description_tel_without_extention
            }
            telData={validations.data?.invoiceTel}
            setTelData={(tel) => {
              validations.setters.handleInvoiceTel(tel);
            }}
            setTel1Data={(tel) => {
              validations.setters?.handleChangeTelNum1(tel);
            }}
            setTel2Data={(tel) => validations.setters?.handleChangeTelNum2(tel)}
            setTel3Data={(tel) => validations.setters?.handleChangeTelNum3(tel)}
            extensionData={validations.data?.invoiceExtension}
            setExtensionData={validations.setters.handleInvoiceExtension}
            hasExtension={true}
            invalidMessage={validations.validationMessage.telValid}
            invalid1Message={validations.validationMessage.tel1Valid}
            invalid2Message={validations.validationMessage.tel2Valid}
            invalid3Message={validations.validationMessage.tel3Valid}
            extensionMessage={validations.validationMessage.extensionValid}
          />
          <FormTextWithCaution
            label={'URL'}
            required={true}
            data={validations.data.invoiceUrl}
            handler1={validations.setters.handleInvoiceUrl}
            invalidMessage={validations.validationMessage.urlValid}
            caution_description={document_send_description3}
          />
        </div>
      </div>
      <p data-testid="document-send-form-bill-description">
        請求書のあて先は「例）○○市長（ご担当
        ○○部○○課）様」にて発行させていただいております。なお請求書に記載する文字数制限のため部署名を短縮させて頂く場合がございます。
      </p>
      <div className="pt-9">
        <Heading3 text="2. 認証書面送付先" />
        <p data-testid="document-send-form-heading">
          認証書面（表紙）の郵送を希望の場合はご入力ください。
          <br />
          （「建物」以外はすべて必須項目です。）
        </p>
        <p className="pt-3">
          <span className="property-info-form-caution_description">
            ※KSI官公庁オークションでは、管理画面より認証書面（表紙）と認証書面（対象者の識別番号リスト）が開催ごとにダウンロードいただけますので、認証書面（表紙）への押印が不要の行政機関様は郵送を省略いただくことが可能です。
          </span>
        </p>
        <div className="property-info-general-form-table">
          <FormPostalWithCaution
            postData={validations.data.certPostalCode}
            postData1={validations.data.certPostalCodeFirst}
            postData2={validations.data.certPostalCodeLast}
            setPostData={validations.setters.handleCertPostalCode}
            handlerPost1={validations.setters.handleCertPostalCodeFirst}
            handlerPost2={validations.setters.handleCertPostalCodeLast}
            caution_description={caution_description12}
            invalidMessage={
              validations.validationMessage.certDocumentPostalCodeValid
            }
            invalid1Message={
              validations.validationMessage.certDocumentPostalCodeFirstValid
            }
            invalid2Message={
              validations.validationMessage.certDocumentPostalCodeLastValid
            }
          />
          <FormPullDownWithCaution
            label={'都道府県'}
            required={false}
            listItemData={pulldown_prefecture}
            selectedData={validations.data.certArea}
            formStateHandler={validations.setters.handleCertArea}
            width={'50%'}
          />
          <FormTextWithCaution
            label={'市区町村'}
            maxCharacters={50}
            data={validations.data.certCity}
            handler1={validations.setters.handleCertCity}
            invalidMessage={validations.validationMessage.certDocumentCityValid}
            caution_description={caution_description_char_50}
          />
          <FormTextWithCaution
            label={'住所（続き）'}
            maxCharacters={50}
            data={validations.data.certAddress}
            handler1={validations.setters.handleCertAddress}
            invalidMessage={
              validations.validationMessage.certDocumentAddressValid
            }
            caution_description={caution_description_char_50}
          />
          <FormTextWithCaution
            label={'建物'}
            maxCharacters={50}
            data={validations.data.certBuildName}
            handler1={validations.setters.handleCertBuildName}
            invalidMessage={
              validations.validationMessage.certDocumentBuildValid
            }
            caution_description={caution_description_char_50}
          />
          <FormTextWithCaution
            label={'あて先'}
            maxCharacters={50}
            data={validations.data.certDestination}
            handler1={validations.setters.handleCertDestination}
            invalidMessage={
              validations.validationMessage.certDocumentDestinationValid
            }
            caution_description={document_send_description4}
          />
          <FormTextWithCaution
            label={'あて名ご名義人'}
            maxCharacters={50}
            data={validations.data.certWrittenDestination}
            handler1={validations.setters.handleCertWrittenDestination}
            invalidMessage={
              validations.validationMessage.certDocumentWrittenDestinationValid
            }
            caution_description={document_send_description1}
          />
        </div>
      </div>
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'登録'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_primary-button'
              }
              onClick={update}
              isDisabled={!isValid}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_normal-button'
              }
              onClick={backToLocalGovernmentManagement}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DocumentSendForm;
// import configs from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/configs/CAMEL_TABLE_NAME";
// import { allFetch } from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/newEntry";
//
// const create = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.create);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const update = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.update);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const destroy = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.destroy);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
