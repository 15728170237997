
export class PublicSaleType {
    #publicSaleType

    constructor(publicSaleType) {
        this.#publicSaleType = publicSaleType;
    }

    get() {
        return this.#publicSaleType
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
