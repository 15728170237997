import { Link } from 'react-router-dom';

import logoMark from "../../../../assets/image/logo_mark.png"
import logoText from "../../../../assets/image/logo_text.png"

const AuctionLogo = ({ className, handleClick }) => {
  return (
    <Link to="/" className={`${className} logo flex`} onClick={handleClick} data-testid="logo">
      <img src={logoMark} alt="官公庁オークション" className="logo-mark" data-testid="logo-mark"/>
      <img src={logoText} alt="官公庁オークション" className="logo-text" data-testid="logo-text"/>
    </Link>
  )
}

export default AuctionLogo;
