
export class ImportantRiskBearing {
  #importantRiskBearing

  constructor(importantRiskBearing) {
    this.#importantRiskBearing = importantRiskBearing;
  }

  get() {
    return this.#importantRiskBearing
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
