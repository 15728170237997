import GovernmentUserCreate from "../../../components/Government/UserEntry/GovernmentUserCreate";

const KmanagerGovernmentUserEntry = () => {
    return (
        <div data-testid="kmanager-government-list">
            <GovernmentUserCreate />
        </div>
    );
}

export default KmanagerGovernmentUserEntry;
