import { useState } from "react";

export function useFixResolutionDateInputs() {
  const [fixResolutionDate, setFixResolutionDate] = useState("");

  const fixResolutionDateInputs = {
    type: {
      fixResolutionDate: 'date',
    },
    data: {
      fixResolutionDate,
    },
    setters: {
      fixResolutionDate: setFixResolutionDate,
    },
  }

  return fixResolutionDateInputs;
}

