import MylistProvider from './MylistProvider.jsx';
import { getComponent } from "../../../../shared/utils/helper/get_component.js";
import { mylist } from "../entities/componentMapping.js";

const Mylist = ({ type }) => {
  return (
    <MylistProvider type={type}>
      {getComponent(mylist, type)}
    </MylistProvider>
  );
};

export default Mylist;
