import { ValidationMessage } from 'interfaces/components/common/ValidationMessage';

import FormLabel from './FormLabel.jsx';

const InputFile = ({ invalidMessage, required, label, state, setState }) => {
  return (
    <div className="kmanager-row">
      <div className="kmanager-col-4">
        <FormLabel label={label} required={required} />
      </div>
      <div className="kmanager-col form-area flex-column">
        <div style={{ position: 'relative' }}>
          <input
            type="file"
            data-testid="input-file"
            value={state}
            onChange={setState}
          />
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
      </div>
    </div>
  );
};
export default InputFile;
