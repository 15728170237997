import { ReactComponent as PaperClip } from '../../../assets/svg/paper-clip.svg';
import 'interfaces/css/common/property-form-input-file.css';
import { useState } from 'react';
import ValidationMessage from 'interfaces/components/Form/navi/ValidationMessage';

const PropertyFormInputFile = ({
  onlyImage = false,
  handler,
  invalidMessage,
}) => {
  const [preview, setPreview] = useState(null);
  const [fileName, setFileName] = useState('画像を選択してください');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
    if (onlyImage && file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    handler(event);
  };

  const clearFile = () => {
    setFileName('画像を選択してください');
    setPreview(null);
    document.querySelector('input[type="file"]').value = null;
  };

  return (
    <>
      <div className="property-form-input-file">
        <PaperClip
          style={{
            color:
              invalidMessage !== 'TRUE' && invalidMessage !== null
                ? '#ff5252'
                : '',
          }}
        />
        <div
          className="property-form-input-text ml-2"
          style={{
            position: 'relative',
            border:
              invalidMessage !== 'TRUE' && invalidMessage !== null
                ? '2px solid #ff5252'
                : '1px solid rgba(0, 0, 0, .42)',
          }}>
          <span
            style={{
              position: 'absolute',
              padding: '8px 0',
              color: 'rgba(0, 0, 0, .6)',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}>
            {fileName.length > 20
              ? `${fileName.slice(0, 10)}...${fileName.slice(-10)}`
              : fileName}
          </span>
          <input
            type="file"
            data-testid="input-type-file"
            accept={onlyImage ? 'image/*' : '*.*'}
            onChange={handleFileChange}
          />
          {preview && (
            <span
              onClick={clearFile}
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                color: 'rgba(0, 0, 0, .6)',
                fontSize: '2rem',
                cursor: 'pointer',
              }}>
              ×
            </span>
          )}
        </div>
      </div>
      <ValidationMessage invalidMessage={invalidMessage} />
      {onlyImage && preview && (
        <img
          width="50%"
          src={preview}
          alt="Preview"
          data-testid="image-preview"
          className="image-preview mt-2"
        />
      )}
      {preview && (
        <div>
          <button
            className="delete-button property-info-general-form-button new-style_navi_important-button"
            onClick={clearFile}
            style={{ cursor: 'pointer' }}>
            削除
          </button>
        </div>
      )}
    </>
  );
};
export default PropertyFormInputFile;
