
export class SystemUsageTax {
  #systemUsageTax

  constructor(systemUsageTax) {
    this.#systemUsageTax = systemUsageTax;
  }

  get() {
    return this.#systemUsageTax
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
