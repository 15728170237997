import ArticleDefaultBody from '../../common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import ArticleDefaultTitle from '../../common/ArticleDefaultTitle/ArticleDefaultTitle.jsx';
import './css/shipping-info-form-input.css'

const ShippingInfoFormInput = ({ topicNum, title, topicCaption, children }) => {
  return (
    <div data-testid="shipping-info-form-input">
      <ArticleDefaultTitle title={`${topicNum}. ${title}`}/>
      {topicCaption && <div className="topic-caption" data-testid="topic-caption">{topicCaption}</div>}
      <ArticleDefaultBody>
        {children}
      </ArticleDefaultBody>
    </div>
  );
};

export default ShippingInfoFormInput;
