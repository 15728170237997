
export class ManagementRegistrationStatus {
  #managementRegistrationStatus

  constructor(managementRegistrationStatus) {
    this.#managementRegistrationStatus = managementRegistrationStatus;
  }

  get() {
    return this.#managementRegistrationStatus
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
