import { validateNumber, validateRangeLength, validateDecimal } from "../../../../application/validations.js";

export class Displacement {
  #displacement

  constructor(displacement) {
    this.#displacement = displacement;
  }

  get() {
    return this.#displacement
  }

  get isValid() {
    return [
      this.validateNumber,
      this.validateRangeLength,
      this.validateDecimal,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateNumber() {
    return validateNumber(this.#displacement)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#displacement, 0, 2)
  }

  // バリデーションルールの適用
  get validateDecimal() {
    return validateDecimal(this.#displacement, 2)
  }
}
