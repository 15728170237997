import { useState } from 'react';

import { ReactComponent as ChevronDown } from '../../../assets/svg/chevron_down.svg';
import '../../../css/common/single-open-accordion.css';

const ChevronDownIcon = ({ isInvisible }) => {
  if (isInvisible !== true) {
    return (
      <div className="single-open-accordion-item-header-icon">
        <span>
          <ChevronDown className="icon" />
        </span>
      </div>
    );
  }
};

const SingleOpenAccordion = ({ items, className, initOpenId }) => {
  const [selected, setSelected] = useState(initOpenId ?? null);

  return (
    <div className={`single-open-accordion ${className}`}>
      {items.map((item) => (
        <div
          key={item.id}
          className={`single-open-accordion-item ${selected === item.id ? 'active' : ''}`}>
          <div
            onClick={() => setSelected(item.id)}
            className="single-open-accordion-item-header text-caption">
            <div>
              {item.title}
              {item.selectedValue}
            </div>
            <ChevronDownIcon isInvisible={item.isInvisibleIcon} />
          </div>
          {!!item.body && selected === item.id && (
            <div className="single-open-accordion-body">{item.body}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SingleOpenAccordion;
