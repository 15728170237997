import 'interfaces/css/common/kmanager-menu-link.css'

// TODO: アイコン表示？
const MenuLink = ({ text, onClick }) => {
  return (
    <div data-testid="kmanager-menu-link" className="kmanager-menu-link" onClick={onClick}>
      <p>{text}</p>
    </div>
  )
}

export default MenuLink