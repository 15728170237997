
export class DisplayProperty {
  #displayProperty

  constructor(displayProperty) {
    this.#displayProperty = displayProperty;
  }

  get() {
    return this.#displayProperty
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
