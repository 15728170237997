
export class Attribute {
    #attribute

    constructor(attribute) {
        this.#attribute = attribute;
    }

    get() {
        return this.#attribute
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
