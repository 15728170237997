import { findById as getAuction } from 'domain/Auction/services/Auction';
import { findByAuctionId as getInvitations } from 'domain/Auction/services/Invitation';
import { findByIds as getGovernments } from 'domain/Government/services/Government';
import { findByAuctionId as getProperties } from 'domain/Property/services/Property';
import { findByGovernmentId as getAssetSaleCautionPage } from 'domain/Setting/services/AssetSaleCaution.js';
import { findById as getBankTransferPage } from 'domain/Setting/services/BankTransferPages.js';
import { findById as getCreditCardPage } from 'domain/Setting/services/CreditCardPage.js';
import { findById as getDocumentSendPage } from 'domain/Setting/services/DocumentSendPages.js';
import { findById as getGovernmentTopPage } from 'domain/Setting/services/GovernmentTopPage.js';
import { findById as getGuideline } from 'domain/Setting/services/Guidelines.js';
import { findByGovernmentId as getDivisions } from 'domain/Division/services/Division.js';
import { findByGovernmentId as getPublicSaleCautionPage } from 'domain/Setting/services/PublicSaleCaution.js';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FORGET_PASSWORD, LOGIN, SIGN_UP } from 'shared/utils/helper/authState';
import { KMANAGER } from 'shared/utils/helper/constants';

import { AuctionGovernmentSettingsContext } from '../entities/AuctionGovernmentSettingsContext';
import { ProcedureTypes } from 'shared/utils/constants/government';

const AuctionGovernmentSettingsProvider = ({ type, children }) => {
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const { auctionId } = useParams();
  const [auction, setAuction] = useState([]);
  const [governmentSettings, setGovernmentSettings] = useState([]);

  if (type === KMANAGER) {
    useEffect(() => {
      const fetchData = async () => {
        const auctionResult = await getAuction(type, auctionId);
        setAuction(auctionResult);

        let settings = [];
        const invitations = await getInvitations(type, auctionId);
        const governmentIds = invitations.map(
          (invitation) => invitation.governmentId,
        );

        const governments = await getGovernments(type, governmentIds);
        const properties = await getProperties(type, auctionId);

        for (const government of governments) {
          let invitation = invitations.find(
            (invitation) =>
              invitation.governmentId === government.id &&
              invitation.auctionId === auctionId,
          );
          let setting = {};
          let governmentProperties = properties.filter(
            (property) =>
              property.governmentId.toString() === government.id.toString(),
          );
          setting.location = government.location;
          setting.region = government.region;
          setting.name = government.name;
          setting.propertyCount = governmentProperties.length;

          const governmentTopPageResult = await getGovernmentTopPage(
            type,
            government.id,
          );
          setting.topPage = governmentTopPageResult.length > 0 ? 1 : 0;

          const creditCardPageResult = await getCreditCardPage(
            type,
            government.id,
          );
          setting.creditCard = creditCardPageResult.length > 0 ? 1 : 0;

          const bankTransferPageResult = await getBankTransferPage(
            type,
            government.id,
          );
          setting.bank = bankTransferPageResult.length > 0 ? 1 : 0;

          const guidelineResult = await getGuideline(type, government.id);
          setting.guideline = guidelineResult.length > 0 ? 1 : 0;

          if (government.procedureType === ProcedureTypes.ASSET_SALE.string) {
            const assetSaleCautionPageResult = await getAssetSaleCautionPage(
              type,
              government.id,
            );
            setting.caution = assetSaleCautionPageResult.length > 0 ? 1 : 0;
          } else if (
            government.procedureType === ProcedureTypes.PUBLIC_SALE.string
          ) {
            const publicSaleCautionPageResult = await getPublicSaleCautionPage(
              type,
              government.id,
            );
            setting.caution = publicSaleCautionPageResult.length > 0 ? 1 : 0;
          }

          const documentSendPageResult = await getDocumentSendPage(
            type,
            government.id,
          );
          if (documentSendPageResult.length === 0) {
            setting.certDocument = 0;
            setting.systemFeeInvoice = 0;
          } else {
            setting.certDocument = documentSendPageResult.certDocumentAddress
              ? 1
              : 0;
            setting.systemFeeInvoice = documentSendPageResult.invoiceAddress
              ? 1
              : 0;
          }

          setting.emblem = government.useEmblem === 'NONE' ? 0 : 1;

          if (governmentProperties.length === 0) {
            setting.isMatchDeposit = 0;
          } else {
            switch (governmentProperties.depositPayment) {
              case 'NONE':
                setting.isMatchDeposit =
                  invitation.noneDeposit === 'TRUE' ? 1 : 0;
                break;
              case 'BANK_TRANSFER':
                setting.isMismatchDeposit =
                  invitation.paymentBankTransfer === 'TRUE' ? 1 : 0;
                break;
              case 'CREDIT_CARD':
                setting.isMatchDeposit =
                  invitation.paymentCreditCard === 'TRUE' ? 1 : 0;
                break;
              case 'BOTH':
                setting.isMatchDeposit =
                  invitation.paymentCreditCard === 'TRUE' &&
                  invitation.paymentBankTransfer === 'TRUE'
                    ? 1
                    : 0;
                break;
              default:
                setting.isMatchDeposit = 0;
                break;
            }
          }
          const divisions = await getDivisions(type, government.id);
          setting.division = divisions.length > 0 ? 1 : 0;
          setting.depositAccount = divisions.length > 0 ? 1 : 0;
          settings.push(setting);
        }
        setGovernmentSettings(settings);
      };

      fetchData();
    }, [type, auctionId]);
  }

  return (
    <AuctionGovernmentSettingsContext.Provider
      value={{
        authState,
        auction,
        governmentSettings,
      }}>
      {children}
    </AuctionGovernmentSettingsContext.Provider>
  );
};

export default AuctionGovernmentSettingsProvider;
