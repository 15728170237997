import { useState, useEffect } from 'react';

export function useNoticesInputs(notice) {
  const [displayServiceType, setDisplayServiceType] = useState({
    service: notice?.isDisplayService === 'TRUE' ? 'TRUE' : '',
    navi: notice?.isDisplayNavi === 'TRUE' ? 'TRUE' : '',
  });
  useEffect(() => {
    setDisplayServiceType({
      service: notice?.isDisplayService === 'TRUE' ? 'TRUE' : '',
      navi: notice?.isDisplayNavi === 'TRUE' ? 'TRUE' : '',
    });
  }, [notice]);
  const [displayProcedureType, setDisplayProcedureType] = useState({
    publicSale: notice?.isDisplayAuction === 'TRUE' ? 'TRUE' : '',
    assetSale: notice?.isDisplayBid === 'TRUE' ? 'TRUE' : '',
  });
  const [displayGovernmentType, setDisplayGovernmentType] = useState({
    nationalTaxAgency: notice?.isDisplayTaxAgency === 'TRUE' ? 'TRUE' : '',
    localGovernment: notice?.isDisplayLocalGov === 'TRUE' ? 'TRUE' : '',
  });
  const [openAt, setOpenAt] = useState(notice?.openAt);
  const [closeAt, setCloseAt] = useState(notice?.closeAt);
  const [title, setTitle] = useState(notice?.title);
  const [body, setBody] = useState(notice?.body);
  const [image, setImage] = useState(notice?.image);
  const [severityType, setSeverityType] = useState({
    severityType: notice?.severityType,
  });

  const sampleInputs = {
    type: {
      displayServiceType: 'check',
      displayProcedureType: 'check',
      displayGovernmentType: 'check',
      openAt: 'datetime',
      closeAt: 'datetime',
      title: 'text',
      image: 'file',
      body: 'markdown',
      severityType: 'check',
    },
    data: {
      displayServiceType,
      displayProcedureType,
      displayGovernmentType,
      openAt,
      closeAt,
      title,
      image,
      body,
      severityType,
    },
    setters: {
      displayServiceType: setDisplayServiceType,
      displayProcedureType: setDisplayProcedureType,
      displayGovernmentType: setDisplayGovernmentType,
      openAt: setOpenAt,
      closeAt: setCloseAt,
      title: setTitle,
      image: setImage,
      body: setBody,
      severityType: setSeverityType,
    },
  };

  return sampleInputs;
}
