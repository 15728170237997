
export class ContactDepartment {
    #contactDepartment

    constructor(contactDepartment) {
        this.#contactDepartment = contactDepartment;
    }

    get() {
        return this.#contactDepartment
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
