import { useEffect, useState } from 'react';

import {
  switchInputClassName,
  switchInputMessage,
} from '../../../../shared/utils/helper/inputHelper';

import 'interfaces/css/common/common-text-area.css';

const CommonTextArea = ({
  text,
  handleChange,
  rows,
  resizeOption,
  maxChar,
  validateMessage,
}) => {
  const [borderClassName, setBorderClassName] = useState('normal-border');
  const [isFocus, setIsFocus] = useState(false);
  const [message, setMessage] = useState('');
  const isVisibleMessage = validateMessage !== 'NO_MESSAGE';

  useEffect(() => {
    switchInputClassName(isFocus, validateMessage, setBorderClassName);
  }, [isFocus, validateMessage]);

  useEffect(() => {
    switchInputMessage(validateMessage, setMessage, '', isVisibleMessage);
  }, [validateMessage]);

  return (
    <div className="w-expand" data-testid="common-text-area">
      <textarea
        value={text}
        onChange={handleChange}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        rows={rows}
        style={{ resize: resizeOption ?? 'none' }}
        className={`common-text-area ${borderClassName}`}
        data-testid="text-area"
      />
      {!!maxChar && (
        <div
          className="char-count"
          data-testid="char-count">{`${text?.length} / ${maxChar}`}</div>
      )}
      <div
        className={`common-input-message ${validateMessage ? 'application-info-form-validate-message' : 'application-info-form-caption-message'}`}>
        {message}
      </div>
    </div>
  );
};

export default CommonTextArea;
