const TopicCard = (props) => {
  return (
    <div className="topic-card pb-2" data-testid="topic-card">
      <div className="topic-card-title">{props.title}</div>
      <div className="topic-card-body">{props.body}</div>
    </div>
  );
};

export default TopicCard;
