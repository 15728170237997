/**
 * TODO: 物件詳細画面の画像のスライダーの実装(https://kankocho.jp/items/30940)
 * メインの画像(1枚表示)と、画像一覧の表示
 * 画像一覧はメイン画像の下に小さく表示
 * メイン画像と画像一覧の要素の両端にそれぞれ左右の矢印ボタンを表示
 * いずれかの矢印ボタンをクリックすると、メイン画像、画像一覧の両方が連動して次or前の画像に切り替わる
 * テストファイル: react/src/service/__tests__/components/PropertyDetail/DetailSlider/DetailSlider.test.jsx
 * 参考:
 * 別の似たようなコンポーネント(物件カードのスライダー)は共通化されている(react/src/service/components/common/Slider/Slider.jsx)
 * 現状(↓)は固定表示
 *
 * @format
 */

import { ReactComponent as LeftArrowBtn } from 'interfaces/assets/svg/left_arrow.svg';
import { ReactComponent as RightArrowBtn } from 'interfaces/assets/svg/right_arrow.svg';
import { useState } from 'react';

import MainImage from './MainImage.jsx';
import ThumbnailSlider from '../ThumbnailSlider/ThumbnailSlider.jsx';
import '../css/DetailSlider/detail-slider.css';

const DetailSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleArrowClick = (direction) => {
    const newIndex =
      direction === 'left'
        ? currentIndex > 0
          ? currentIndex - 1
          : images.length - 1
        : currentIndex < images.length - 1
          ? currentIndex + 1
          : 0;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="detail-slider flex align-center justify-center">
      {images.length === 0 ? (
        <div className="no-image" data-testid="no-image">
          画像はありません
        </div>
      ) : (
        <>
          <div className="main-image-viewer-wrap">
            <div className="main-image-viewer">
              <button
                aria-label="Previous image"
                className="main-previous-button"
                onClick={() => handleArrowClick('left')}>
                <LeftArrowBtn data-testid="main-left-arrow-btn" />
              </button>
              <MainImage
                url={images[currentIndex]?.image_url}
                title={images[currentIndex]?.image_caption_title}
                loading="lazy"
              />
              <button
                aria-label="Next image"
                className="main-next-button"
                onClick={() => handleArrowClick('right')}>
                <RightArrowBtn data-testid="main-right-arrow-btn" />
              </button>
            </div>
          </div>
          <ThumbnailSlider
            images={images}
            currentIndex={currentIndex}
            handleArrowClick={handleArrowClick}
            setCurrentIndex={setCurrentIndex}
          />
        </>
      )}
    </div>
  );
};

export default DetailSlider;
