import { validateEmailFormatCheck } from "../../../../application/validations.js";

export class InquiryEmail {
  #inquiryEmail

  constructor(inquiryEmail) {
    this.#inquiryEmail = inquiryEmail;
  }

  get() {
    return this.#inquiryEmail
  }

  get isValid() {
    return [
      this.validateEmailFormatCheck,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateEmailFormatCheck() {
    return validateEmailFormatCheck(this.#inquiryEmail)
  }
}
