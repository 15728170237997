import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class InquiryName {
  #inquiryName

  constructor(inquiryName) {
    this.#inquiryName = inquiryName;
  }

  get() {
    return this.#inquiryName
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#inquiryName, 0, 30)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#inquiryName)
  }
}
