import ApplicantListProvider from './ApplicantListProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { applicantList } from "../entities/componentMapping.js";

const ApplicantList = ({ type }) => {
  return (
    <ApplicantListProvider type={type}>
      {getComponent(applicantList, type)}
    </ApplicantListProvider>
  );
};

export default ApplicantList;
