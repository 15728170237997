import RightSideMenuList from "./RightSideMenuList.jsx";
import { useCloseClickOutside } from 'shared/hooks/useCloseClickOutside';
import 'interfaces/css/common/kmanager-right-side-menu.css'

const RightSideDrawerMenu = ({ isOpen, handleClose }) => {
  if (!isOpen) {
    return null;
  }
  const { ref } = useCloseClickOutside(() => handleClose());

  return (
    <div ref={ref}>
      <div className="right-menu-overlay" onClick={handleClose} data-testid="modal-overlay"/>
      <div data-testid="kmanager-right-side-drawer-menu" className={`kmanager-right-side-menu ${isOpen && 'active'}`}>
        <RightSideMenuList handleClose={handleClose}/>
      </div>
    </div>
  );
};

export default RightSideDrawerMenu;