
export class InquiryName {
  #inquiryName

  constructor(inquiryName) {
    this.#inquiryName = inquiryName;
  }

  get() {
    return this.#inquiryName
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
