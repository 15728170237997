import { validateSelected } from "../../../../application/validations.js";

export class EffectiveDate {
  #effectiveDate

  constructor(effectiveDate) {
    this.#effectiveDate = effectiveDate;
  }

  get() {
    return this.#effectiveDate
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#effectiveDate)
  }
}
