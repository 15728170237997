import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class DocumentPageName {
  #documentPageName

  constructor(documentPageName) {
    this.#documentPageName = documentPageName;
  }

  get() {
    return this.#documentPageName
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#documentPageName, 0, 30)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#documentPageName)
  }
}
