
export class SummaryFloorSpace {
    #summaryFloorSpace

    constructor(summaryFloorSpace) {
        this.#summaryFloorSpace = summaryFloorSpace;
    }

    get() {
        return this.#summaryFloorSpace
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
