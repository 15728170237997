export const DATA_EMPTY = 'DATA_EMPTY';

export const EmptyData = [
  DATA_EMPTY,
  '',
  undefined,
  null,
  '-9999',
  '1000-01-01 00:00:00',
  '1000-01-01',
];

export const isEmpty = (value) => {
  return EmptyData.includes(value);
};
