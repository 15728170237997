// ロジックを含む処理
// フロントから呼ぶ関数
import { getRecommendProperties } from '../repositories/RecommendProperties';

export async function get(type) {
  return await getRecommendProperties(type);
}

export async function whereByAuctionIds(type, ids) {
  const properties = await getRecommendProperties(type);
  return properties.filter((data) => ids.includes(data.auctionId));
}
