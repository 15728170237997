import LocalGovernmentManagementGuidelinesProvider from './LocalGovernmentManagementGuidelinesProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { localGovernmentManagementGuidelines } from "../entities/componentMapping.js";

const LocalGovernmentManagementGuidelines = ({ type }) => {
  return (
    <LocalGovernmentManagementGuidelinesProvider type={type}>
      {getComponent(localGovernmentManagementGuidelines, type)}
    </LocalGovernmentManagementGuidelinesProvider>
  );
};

export default LocalGovernmentManagementGuidelines;
