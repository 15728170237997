// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tui-editor-contents p {
    word-wrap: break-word;
}
.tui-editor-contents p {
    margin: 10px 0;
    color: #222;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/markdown.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,WAAW;AACf","sourcesContent":[".tui-editor-contents p {\n    word-wrap: break-word;\n}\n.tui-editor-contents p {\n    margin: 10px 0;\n    color: #222;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
