import pino from "pino";

import {BASE_URL_NAVIGATION,BASE_URL_SERVICE} from "../../../config/envConstants.js";
import { extractData } from "../../../shared/utils/helper/extractData.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_PROPERTIES = `${BASE_URL_NAVIGATION}/storage/current/properties/data.json`;
const PREVIOUS_PROPERTIES = `${BASE_URL_NAVIGATION}/storage/previous/properties/data.json`;

export const fetchPropertiesStorage = async () => {
  try {
    const response = await fetchCurrentProperties();
    return extractProperties(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ properties ）を取得できませんでした");

    try {
      const response = await fetchPreviousProperties();
      return extractProperties(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ properties ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchCurrentProperties = async () => {
  return fetchProperties(CURRENT_PROPERTIES);
};

export const fetchPreviousProperties = async () => {
  return fetchProperties(PREVIOUS_PROPERTIES);
};

export const fetchProperties = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractProperties = (response) => {
  return response.map(property => property);
}

export const fetchAllPropertiesStorage = async () => {
  try {
    const response = await fetchData(`${BASE_URL_SERVICE}/storage/current/properties/properties.json`);
    return extractData(response);
  } catch (error) {
    logger.warn({ error }, "現在の物件一覧（ properties ）を取得できませんでした");

    try {
      const response = await fetchData(`${BASE_URL_SERVICE}/storage/previous/properties/properties.json`);
      return extractData(response);
    } catch (error) {
      logger.error({ error }, "以前の物件一覧（ properties ）の取得にも失敗しました");
      return [];
    }
  }
};



const CAR = 'CAR';
const ESTATE = 'ESTATE';
const REAL_ESTATE = 'REAL_ESTATE';

const HAS_NO_FAVORITES = 'HAS_NO_FAVORITES';
const HAS_NO_MYBIDPRICE = 'HAS_NO_MYBIDPRICE';
const FAVORITE = 'FAVORITES';
const favoriteObject = { favorite: FAVORITE };

// オブジェクトの結合処理
// propertiesに存在しないidのデータは結合しない
const mergeObjects = (properties, ...args) => {
  if (args.length === 0) {
    return [];
  }
  return args.reduce((mergedObjects, currentObjects) => {
    mergedObjects.forEach(mergedObject => {
      const duplicatedObject = currentObjects.find(item => item.id === mergedObject.id);
      if (duplicatedObject) {
        Object.assign(mergedObject.data, duplicatedObject.data);
      }
    });
    return mergedObjects;
  }, properties);
};

const getObjectsFilteredByIds = (objects, ids) => objects.filter(object => ids.includes(object.id.toString()));
const getPropertiesFilteredByType = (properties, type) => properties.filter(property => property.type === type);

const isLoginUser = (userId) => {
  return Boolean(userId);
};

export const getJSONPaths = (userId) => {
  // ログイン済みユーザに表示するJSONファイル
  if (isLoginUser(userId)) {
    return {
      current: {
        allowPropertyIds: `${BASE_URL_SERVICE}/storage/current/users/user${userId}/allow_property_ids.json`,
        properties: `${BASE_URL_SERVICE}/storage/current/properties/properties.json`,
        bidInfos: `${BASE_URL_SERVICE}/storage/current/properties/bid_info.json`,
        favoriteIds: `${BASE_URL_SERVICE}/storage/current/users/user${userId}/favorite_property_ids.json`,
        myBidPrice: `${BASE_URL_SERVICE}/storage/current/users/user${userId}/my_bid_price.json`
      },
      previous: {
        allowPropertyIds: `${BASE_URL_SERVICE}/storage/previous/users/user${userId}/allow_property_ids.json`,
        properties: `${BASE_URL_SERVICE}/storage/previous/properties/properties.json`,
        bidInfos: `${BASE_URL_SERVICE}/storage/previous/properties/bid_info.json`,
        favoriteIds: `${BASE_URL_SERVICE}/storage/previous/users/user${userId}/favorite_property_ids.json`,
        myBidPrice: `${BASE_URL_SERVICE}/storage/previous/users/user${userId}/my_bid_price.json`
      }
    };
  }

  // 非ログインユーザに表示するJSONファイル
  return {
    current: {
      allowPropertyIds: `${BASE_URL_SERVICE}/storage/current/users/unauthorized_user/allow_property_ids.json`,
      properties: `${BASE_URL_SERVICE}/storage/current/properties/properties.json`,
      bidInfos: `${BASE_URL_SERVICE}/storage/current/properties/bid_info.json`
    },
    previous: {
      allowPropertyIds: `${BASE_URL_SERVICE}/storage/previous/users/unauthorized_user/allow_property_ids.json`,
      properties: `${BASE_URL_SERVICE}/storage/previous/properties/properties.json`,
      bidInfos: `${BASE_URL_SERVICE}/storage/previous/properties/bid_info.json`
    }
  };
};

export const allFetch = async (paths) => {
  const promises = Object.values(paths).map(fetchData);
  return await Promise.all(promises);
}

export const modifyObjects = (properties, bidInfos, allowPropertyIds, favoriteIds, myBidPrice, category) => {
  if (allowPropertyIds.length === 0) {
    return [];
  }
  // categoryの中身によってtypeで絞り込み
  if (category === CAR) {
    properties = getPropertiesFilteredByType(properties, CAR);
    if (properties.length === 0) {
      return [];
    }
  } else if (category === ESTATE) {
    properties = getPropertiesFilteredByType(properties, ESTATE);
    if (properties.length === 0) {
      return [];
    }
  }

  // 閲覧可能な物件IDで絞り込み
  properties = getObjectsFilteredByIds(properties, allowPropertyIds.ids);
  if (properties.length === 0) {
    return [];
  }
  bidInfos = getObjectsFilteredByIds(bidInfos, allowPropertyIds.ids);

  /**
   * favoriteIdsがHAS_NO_FAVORITESか、配列のlengthが0なら処理しない
   * 配列の長さが0以上ならpropertiesにfavorite: "FAVORITE"を追加する
   */
  const hasFavorite = !(favoriteIds === HAS_NO_FAVORITES || favoriteIds.length === 0);
  if (hasFavorite) {
    favoriteIds.map(favoriteId => {
      const property = properties.find(property => property.id === favoriteId);
      if (property) {
        Object.assign(property.data, favoriteObject);
      }
    });
  }

  /**
   * myBidPriceがHAS_NO_MYBIDPRICEなら処理しない
   * 配列の長さが0以上ならpropertiesにmyBidPrice: XXXを追加する
   */
  const hasMyBidPrice = myBidPrice !== HAS_NO_MYBIDPRICE;
  if (hasMyBidPrice) {
    myBidPrice.map(item => {
      const property = properties.find(property => property.id === item.id);
      if (property) {
        Object.assign(
          property.data,
          item
        );
      }
    });
  }

  // dataのみ抽出して配列にマッピング
  let data = [];
  mergeObjects(properties, bidInfos).map(item => {
    data.push(item.data);
  });
  return data;
};

export const getProperties = async (userId, category = REAL_ESTATE) => {
  const paths = getJSONPaths(userId);
  try {
    const fetchData = await allFetch(paths.current);
    if (!isLoginUser(userId)) {
      const [allowPropertyIds, properties, bidInfos] = fetchData;
      return modifyObjects(properties, bidInfos, allowPropertyIds, HAS_NO_FAVORITES, HAS_NO_MYBIDPRICE, category);
    }
    const [allowPropertyIds, properties, bidInfos, favoriteIds, myBidPrice] = fetchData;
    return modifyObjects(properties, bidInfos, allowPropertyIds, favoriteIds, myBidPrice, category);
  } catch (error) {
    console.warn(error.message);
    // currentのJSONファイルへのアクセスが失敗、JSON形式でない場合の処理
    try {
      const fetchData = await allFetch(paths.previous);
      if (!isLoginUser(userId)) {
        const [allowPropertyIds, properties, bidInfos] = fetchData;
        return modifyObjects(properties, bidInfos, allowPropertyIds, HAS_NO_FAVORITES, HAS_NO_MYBIDPRICE, category);
      }
      const [allowPropertyIds, properties, bidInfos, favoriteIds, myBidPrice] = fetchData;
      return modifyObjects(properties, bidInfos, allowPropertyIds, favoriteIds, myBidPrice, category);
    } catch (error) {
      console.warn(error.message);
      // previousのJSONファイルへのアクセスも失敗、JSON形式でない場合は空の配列を返す
      return [];
    }
  }
};

export const getGovernmentProperties = async (governmentId) => {
  const CURRENT_PROPERTIES = `${BASE_URL_SERVICE}/storage/current/properties/properties.json`;
  const PREVIOUS_PROPERTIES = `${BASE_URL_SERVICE}/storage/previous/properties/properties.json`;

  try {
    const properties = await fetchData(CURRENT_PROPERTIES);
    return properties.filter(property => property.data.governmentId === governmentId);
  } catch (error) {
    console.warn(`Failed to fetch current properties: ${error.message}`);
    try {
      const properties = await fetchData(PREVIOUS_PROPERTIES);
      return properties.filter(property => property.data.governmentId === governmentId);
    } catch (error) {
      console.warn(`Failed to fetch old properties: ${error.message}`);
      return [];
    }
  }
};

export const getPreRegistrationInfo = async (userId) => {
  const CURRENT_PREREGISTRATION_APPLICANT_INFORMATION_PATH = `${BASE_URL_SERVICE}/storage/current/users/user${userId}/preregistration_applicant_information.json`;
  const PREVIOUS_PREREGISTRATION_APPLICANT_INFORMATION_PATH = `${BASE_URL_SERVICE}/storage/previous/users/user${userId}/preregistration_applicant_information.json`;

  try {
    return await fetchData(CURRENT_PREREGISTRATION_APPLICANT_INFORMATION_PATH);
  } catch (error) {
    console.warn(`Failed to fetch current preRegistrationInfo: ${error.message}`);
    try {
      return await fetchData(PREVIOUS_PREREGISTRATION_APPLICANT_INFORMATION_PATH);
    } catch (error) {
      console.warn(`Failed to fetch old preRegistrationInfo: ${error.message}`);
      return [];
    }
  }
};

export const fetchPublishObject = async () => {
  const CURRENT_PUBLISH_OBJECT_PATH = `${BASE_URL_SERVICE}/storage/current/properties/data.json`;
  const PREVIOUS_PUBLISH_OBJECT_PATH = `${BASE_URL_SERVICE}/storage/current/properties/data.json`;

  try {
    return await fetchData(CURRENT_PUBLISH_OBJECT_PATH);
  } catch (error) {
    console.warn(`Failed to fetch current preRegistrationInfo: ${error.message}`);
    try {
      return await fetchData(PREVIOUS_PUBLISH_OBJECT_PATH);
    } catch (error) {
      console.warn(`Failed to fetch old preRegistrationInfo: ${error.message}`);
      return [];
    }
  }
};
