import styled from 'styled-components';
import "interfaces/css/common/property-info-form-label.css"

const Label = styled.div`
        display: table-cell;
        padding:12px;
        border-bottom: 1px solid #ccc;
        width: 30%;
        max-width: 300px;
        min-width: 200px;
        background-color: var(--secondary-base);
        font-weight: 700;
        vertical-align: middle;
`

const FormLabel = ({label, required, className=''}) => {

    return (
        <Label className={className}>
            <span dangerouslySetInnerHTML={{ __html: label.replace(/\n/g, '<br />') }}></span>
            {required ? <span style={{color: "var(--required)"}}>*</span> : ""}
        </Label>
    );
}
export default FormLabel
