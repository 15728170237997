import RadioInput from 'interfaces/components/common/Input/RadioInput.jsx';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel.jsx';
//import { useApplicationInfo } from '../../hooks/useApplicationInfo.js';

const isDelegatorStatusList = [
  { id: 1, label: 'する', value: true },
  { id: 2, label: 'しない', value: false },
];

const IsDelegatorProcedureInput = ({
  applicantInfo,
  setApplicantInformation,
}) => {
  //const { handleApplicantInfoChange } = useApplicationInfo();
  // mock data
  const handleApplicantInfoChange = (params) => {
    setApplicantInformation({
      ...applicantInfo,
      ...params,
    });
  };

  return (
    <tr>
      <th>
        代理人による手続き
        <RequiredLabel />
      </th>
      <td>
        <div className="flex">
          {isDelegatorStatusList.map((status) => (
            <RadioInput
              key={status.id}
              item={status}
              label={status.label}
              value={applicantInfo?.isDelegatorProcedure}
              handleClick={() =>
                handleApplicantInfoChange({
                  isDelegatorProcedure: status.value,
                })
              }
            />
          ))}
        </div>
        <small>
          「する」を選択した場合は、以下の「委任者情報」を入力してください。
        </small>
      </td>
    </tr>
  );
};

export default IsDelegatorProcedureInput;
