import { ReactComponent as PencilIcon } from 'interfaces/assets/svg/pencil.svg';
import { useNavigate } from 'react-router-dom';

import { NaviUserTypes } from 'shared/utils/constants/user';
import FlatButton from '../../common/FlatButton';

import 'interfaces/css/NaviUserManagement/navi-user-management-table.css';

const NaviUserManagementTable = ({ tableData }) => {
  const navigation = useNavigate();

  const receiveMailLabel = (wantReceiveEmail, userType) => {
    if (userType === 'CHILD') {
      if (wantReceiveEmail === 'RECEIVE') {
        return '受信する';
      }
    } else {
      if (wantReceiveEmail === 'RECEIVE') {
        return '受信する（必須）';
      }
    }
    return '受信しない';
  };

  return (
    <div className="navi-user-management-table-wrap">
      <span className="navi-user-management">
        <div
          className="navi-user-management-table"
          data-testid="navi-user-management-table">
          <div className="navi-user-management-table-inner">
            <table>
              <thead data-testid="navi-user-management-table-header">
                <tr>
                  <th>ナビユーザーID</th>
                  <th>担当者名</th>
                  <th>担当部署</th>
                  <th>電話番号</th>
                  <th>メールアドレス</th>
                  <th>権限</th>
                  <th>運営からのメールの受信</th>
                  <th></th>
                </tr>
              </thead>
              <tbody data-testid="navi-user-management-table-body">
                {tableData &&
                  tableData.map((d) => (
                    <tr
                      key={d.id}
                      data-testid="navi-user-management-table-body-row">
                      <td>{d.loginId}</td>
                      <td>
                        {d.familyName} {d.firstName}
                      </td>
                      <td>{d.department}</td>
                      <td>{d.tel}</td>
                      <td>{d.email}</td>
                      <td>{NaviUserTypes[d.userType].jp}</td>
                      <td>
                        {receiveMailLabel(d.wantReceiveEmail, d.userType)}
                      </td>
                      <td className="action-button">
                        <FlatButton
                          onClick={() =>
                            navigation(`/navi/user/detail/${d.id}`, {
                              state: {
                                userId: d.loginId,
                              },
                            })
                          }
                          text="編集"
                          icon={<PencilIcon />}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </span>
    </div>
  );
};

export default NaviUserManagementTable;
