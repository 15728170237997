import '../css/DetailSlider/mainImage.css';

const MainImage = ({ url, title }) => {
  console.log(url);
  return (
    <div className="main-image-wrap" data-testid="image-detail">
      <img src={url} alt="" style={{ display: 'none' }} />
      <div
        className="main-image"
        style={{ backgroundImage: `url(${url})`, backgroundPosition: 'center' }}
        data-testid="main-image"
      />
      <p className="image-caption" data-testid="image-caption">
        {title}
      </p>
    </div>
  );
};
export default MainImage;
