
export class Issuer {
    #issuer

    constructor(issuer) {
        this.#issuer = issuer;
    }

    get() {
        return this.#issuer
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
