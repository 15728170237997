import { findByPropertyId as findApplicantInformations } from 'domain/Applicant/services/ApplicantInformation';
import { all as findAuctionUsers } from 'domain/Applicant/services/AuctionUser';
import { findByPropertyId as findProposals } from 'domain/Applicant/services/Proposal';
import { findById as findAuction } from 'domain/Auction/services/Auction';
import { findById as findPropertyById } from 'domain/Property/services/Property';
import { findById as findGovernmentUser } from 'domain/User/services/GovernmentUser.js';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { NAVI } from 'shared/utils/helper/constants.js';
import { PrefectureToJP } from 'shared/utils/helper/convert/prefectureToJp.js';

import { ApplicantListContext } from '../entities/ApplicantListContext.js';

const ApplicantListProvider = ({ type, children }) => {
  const { auction_id, property_id } = useParams();

  const [auction, setAuction] = useState(undefined);
  const [applicantList] = useState([]);
  const [governmentUser, setGovernmentUser] = useState(undefined);
  const [property, setProperty] = useState([]);
  const [searchParam, setSearchParam] = useState({ membershipId: '' });
  const [tableData, setTableData] = useState([]);

  const { localStorageUserId } = useLocalStorage();

  const aplStatusToJp = (item) => {
    if (item === 'TRIAL') {
      return '仮申込';
    }
    if (item === 'APPLICATION') {
      return '申込';
    }
    if (item === 'CANCEL') {
      return '取消';
    }
    if (item === 'CONFISCATION') {
      return '取消/没収';
    }
    return '';
  };

  if (type === NAVI) {
    // TODO: ブロックリストに登録済み、未登録かを表すフィールドが追加されたApplicantListの一覧を返す処理をユースケースに追加する
    // useEffect(() => { //   getApplicantInformation(type).then(result => {
    //     setApplicantList(result);
    //   });
    // }, []);

    // TODO: domain/Applicant/services/Proposals.jsに取得する処理を追加する
    useEffect(() => {
      let data = [];
      findProposals(NAVI, property_id).then((resultProposals) => {
        findAuctionUsers(NAVI).then((resultUser) => {
          findApplicantInformations(NAVI, property_id).then(
            (resultApplicantInfomations) => {
              resultProposals.forEach((proposal) => {
                let item = {
                  applicant: {},
                  client: {},
                };
                let user = resultUser.find(
                  (user) =>
                    user.id.toString() === proposal.auctionUserId.toString(),
                );
                if (searchParam.membershipId !== '') {
                  if (!user.membershipId?.includes(searchParam.membershipId)) {
                    return;
                  }
                }
                let applicant = resultApplicantInfomations.find(
                  (info) =>
                    info.auctionUserId.toString() === user.id.toString() &&
                    info.type === 'APPLICANT',
                );
                let client = resultApplicantInfomations.find(
                  (info) =>
                    info.auctionUserId.toString() === user.id.toString() &&
                    info.type === 'CLIENT',
                );

                item.trackingId = proposal.trackingId;
                item.tmpAplDate = applicant.tmpAplDate;
                item.aplStatus = proposal.aplStatus;
                item.aplStatusJp = aplStatusToJp(proposal.aplStatus);
                item.memo = proposal.memo;
                item.aplStatus = proposal.aplStatus;
                item.membershipId = user.membershipId;
                item.applicant.address = `${applicant.postalCode} ${PrefectureToJP(applicant.locationGovernmentSubCategoryId)} ${applicant.address}`;
                item.client.address =
                  client !== undefined
                    ? `${client.postalCode} ${PrefectureToJP(client.locationGovernmentSubCategoryId)} ${client.address}`
                    : item.applicant.address;

                item.applicant.name = `${applicant.familyName} ${applicant.firstName}`;
                item.client.name =
                  client !== undefined
                    ? `${client.familyName} ${client.firstName}`
                    : `${applicant.familyName} ${applicant.firstName}`;
                item.applicant.corpName = applicant.corpName;
                item.client.corpName =
                  client !== undefined ? client.corpName : applicant.corpName;
                item.email = user.email;
                item.applicant.tel = applicant.tel;
                item.client.tel =
                  client !== undefined ? client.tel : applicant.tel;
                item.applicant.mobileTel = applicant.mobileTel;
                item.client.mobileTel =
                  client !== undefined ? client.mobileTel : applicant.mobileTel;
                item.applicant.sex =
                  applicant.sex === 'MAN'
                    ? '男性'
                    : applicant.sex === 'WOMAN'
                      ? '女性'
                      : '未入力';
                item.client.sex =
                  client !== undefined
                    ? client.sex === 'MAN'
                      ? '男性'
                      : client.sex === 'WOMAN'
                        ? '女性'
                        : '未入力'
                    : item.applicant.sex;
                item.applicant.birthDate = applicant.birthDate;
                item.client.birthDate =
                  client !== undefined ? client.birthDate : applicant.birthDate;
                item.isJointBid = proposal.isJointBid;
                item.isRepresentative = proposal.isRepresentative;

                data.push(item);
              });
              setTableData(data);
            },
          );
        });
      });
    }, [property_id, searchParam]);

    useEffect(() => {
      findGovernmentUser(type, localStorageUserId).then((user) => {
        setGovernmentUser(user);
      });
    }, [localStorageUserId]);

    useEffect(() => {
      findAuction(type, auction_id).then((result) => {
        setAuction(result);
      });
    }, [auction_id]);

    useEffect(() => {
      findPropertyById(type, property_id).then((result) => {
        setProperty(result);
      });
    }, []);
  }

  return (
    <ApplicantListContext.Provider
      value={{
        applicantList,
        auction,
        governmentUser,
        property,
        tableData,
        setSearchParam,
      }}>
      {children}
    </ApplicantListContext.Provider>
  );
};

export default ApplicantListProvider;
