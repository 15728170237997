import "interfaces/css/common/listbox.css";

const ListBox = ({
    data,
    setShowListBox,
    setSelectedValue,
    handler,
    name = 'name',
    width,
}) => {
  return (
      <div className="list-box-wrap" style={{ width: width }}>
          <div className="list-box">
              {
                  (Array.isArray(data) && data.length === 0) &&
                  <div
                      className="list-item"
                      onClick={() => {
                          setShowListBox(false);
                      }}
                      data-testid="list-item"
                  >
                      <div className="list-item-content">
                          <div className="list-item-title">
                              No data available
                          </div>
                      </div>
                  </div>
              }
              {
                  data && data.map((d) => (
                      <div
                          className="list-item"
                          key={d.id}
                      >
                          <input
                              type="button"
                              name={name}
                              id={d.id}
                              value={d.value ?? d.text}
                              style={{ opacity: 0, flex: 0 }}
                              onClick={(e) => {
                                  setSelectedValue(d.text);
                                  setShowListBox(false);
                                  if (handler) {
                                      handler(e)
                                  }
                              }}
                          />
                          <label htmlFor={d.id} style={{ width: "100%" }}>
                              <div className="list-item-content">
                                  <div className="list-item-title">
                                      {d?.text ?? ""}
                                  </div>
                              </div>
                          </label>
                      </div>
                  ))
              }
          </div>
      </div>
  );
}

export default ListBox;