import {
  ASSET_SALE,
  PUBLIC_SALE,
  REAL_ESTATE,
} from 'domain/Property/entities/constants/property';

import Attention from './Attention';

const PublicSaleAttention = ({ procedureType }) => {
  if (procedureType !== PUBLIC_SALE) {
    return;
  }

  return (
    <div data-testid="public-sale-attention">
      <Attention
        title="参加者情報の入力内容について"
        texts={[
          '・入力した情報は、会員識別番号とメールアドレスを含め、すべて行政機関に開示します。行政機関は当該情報を、適正な公売の実施（公売保証金の納付期限、国税徴収法第１０８条の措置、売却決定、権利移転手続きなど）に利用します。',
          '・参加者情報は、必ず住民登録または商業登記簿などの内容通りに入力してください。相違する情報を入力した場合は、権利移転の登記、登録手続きができないだけでなく、最高価申込者などの決定が取り消される場合もありますのでご注意ください。',
          '・代理人として参加する場合は、必ず代理人による申し込みであることを選択してください。また、委任者から行政機関へ委任状が提出されなければ、入札に参加できませんのでご注意ください。',
          '・入力した内容は、送信完了後は変更できません。',
        ]}
        link="https://help.kankocho.jp/knowledge/help015"
      />
      <Attention
        title="公売保証金の納付について"
        texts={[
          '・この公売物件に入札するには、公売保証金の納付が必要です。公売保証金の納付を執行機関が確認できた会員識別番号でのみ入札できます。',
          '・落札できなかった場合、原則として納付された公売保証金は、全額返還いたします。',
          '・落札者（最高価申込者）となった場合、買受代金を期限までに納付しなければ、公売保証金は没収されますのでご注意ください。',
          '・公売保証金の納付手続きに関する詳細については、各行政機関にお問い合わせください。',
        ]}
        link="https://help.kankocho.jp/knowledge/help016"
      />
    </div>
  );
};

const AssetSaleAttention = ({ procedureType }) => {
  if (procedureType !== ASSET_SALE) {
    return;
  }

  return (
    <div data-testid="asset-sale-attention">
      <Attention
        title="参加者情報の入力内容について"
        texts={[
          '・必ず住民登録または商業登記簿などに登録、登記されている内容通りに入力してください。',
          '・物件が不動産や自動車の場合、入力された情報によって権利移転の登記、登録手続きを行います。入力した情報が住民登録または商業登記簿と相違する場合、権利移転の手続きができませんのでご注意ください。',
          '・紀尾井町戦略研究所株式会社（KSI）は、本ページを経由して行政機関が執行される公有財産売却手続きに参加される個々のお客様を識別するために、会員識別番号とメールアドレスを行政機関に開示します。行政機関は、地方自治法施行令第１６７条の４の規定に基づく手続きの適正な実施を目的として当該情報を利用します。',
        ]}
        link="https://help.kankocho.jp/knowledge/help015"
      />
      <Attention
        title="入札保証金の納付について"
        texts={[
          '・公有財産売却に参加するには、入札したい物件ごとに入札保証金の納付が必要です。入札保証金の納付を確認できた会員識別番号でのみ入札できます。',
          '・お支払い回数は１回とさせていただきます。',
          '・落札できなかった場合、納付された入札保証金は、原則として入札終了後に全額返還いたします。',
          '・落札者は落札後契約締結などの所定の手続きを実行しない場合、入札保証金は没収されますのでご注意ください。',
        ]}
        link="https://help.kankocho.jp/knowledge/help016"
      />
    </div>
  );
};

const RealEstateAttention = ({ propertyType }) => {
  if (propertyType !== REAL_ESTATE) {
    return;
  }
  return (
    <div data-testid="real-estate-attention">
      <Attention
        title="共同入札について"
        texts={[
          '・共同入札とは、1つの物件を複数人で共有するために、共同で入札することをいいます。',
          '・共同入札をするには、共同入札者の間で代表者を決め、代表者以外の方から代表者に対する「委任状」と、共同入札者全員の住所と氏名を連署し、各人の持分を付記した「共同入札者持分内訳書」の提出が必要となります。',
          '・共同入札の場合は、代表者のみがインターネット公売で実際の入札手続きを行えます。',
          '・共同入札の場合は、参加者情報欄には代表者の情報を入力してください。',
        ]}
        link="https://help.kankocho.jp/knowledge/help019"
      />
    </div>
  );
};

const FormAttentionSectionBody = ({ procedureType, propertyType }) => {
  return (
    <div data-testid="form-attention-section-body">
      <PublicSaleAttention procedureType={procedureType} />
      <AssetSaleAttention procedureType={procedureType} />
      <br />
      <RealEstateAttention propertyType={propertyType} />
      <Attention title="【重要】メールアドレス登録に関して" isImportant={true}>
        <p>
          KSI官公庁オークションからの各種通知メールは、登録のメールアドレスに送信いたします。利用していないメールアドレスが登録されている場合、各種通知メールをお受け取りできません。メールアドレスの登録状況は
          <a href="/mypage/" target="_blank" rel="noopener noreferrer">
            こちら
          </a>
          からご確認ください。また、携帯電話会社が発行するメールアドレスで迷惑メール対策をご利用になられている方は、KSI官公庁オークションのドメイン（kankocho.jp）から送信されるメールが受信できることを、あらかじめご契約なさっている各携帯電話会社の設定画面からご確認ください。
        </p>
      </Attention>
    </div>
  );
};

export default FormAttentionSectionBody;
