import { useAuctionEntryInputs } from './hook/useAuctionEntryInputs';
import { useAuctionEntryInputsValidation } from './hook/useAuctionEntryInputsValidation';

export const auto_bid_end = [
  { id: 1, value: 'MANUAL', label: '行う' },
  { id: 2, value: 'AUTO', label: '行わない' },
];

export const display_apl_count = [
  { id: 1, value: 'DISPLAY', label: '表示する' },
  { id: 2, value: 'HIDDEN', label: '表示しない' },
];

export const mail_cert_document = [
  { id: 1, value: 'MAIL', label: '希望する' },
  { id: 2, value: 'NO_MAIL', label: '希望しない' },
];

export const way_deposits = [
  { id: 1, text: 'オン納付', name: 'paymentCreditCard' },
  { id: 2, text: 'オフ納付', name: 'paymentBankTransfer' },
  { id: 3, text: '保証金なし', name: 'noneDeposit' },
];

export function validateInputsAuctionEntry(governments) {
  const formInputs = useAuctionEntryInputs();
  const validate = useAuctionEntryInputsValidation();

  const government_list = governments.map((government) => ({
    id: government.id,
    value: government.id,
    text: government.name,
  }));

  function handleInvitationName(e) {
    validate.setters.invitationName(e);
    formInputs.setters.invitationName(e.target.value);
  }

  function handleBidEndType(e) {
    formInputs.setters.bidEndType(e.target.value);
  }

  function handleMailCertDocument(e) {
    formInputs.setters.mailCertDocument(e.target.value);
  }

  function handleDepositDeliveryStatus(e) {
    validate.setters.depositDeliveryStatus(e);
    formInputs.setters.depositDeliveryStatus({
      ...formInputs.data.depositDeliveryStatus,
      ...{ [e.target.name]: e.target.checked ? 'TRUE' : '' },
    });
  }

  function handleAplCountAuctionDisplay(e) {
    formInputs.setters.aplCountAuctionDisplay(e.target.value);
  }

  function handleGovernmentId(e) {
    console.log(e);
    validate.setters.governmentId(e);
    formInputs.setters.governmentId(e.target.value);
  }

  function handleArea(e) {
    validate.setters.area(e);
    formInputs.setters.area(e.target.value);
  }

  function handleContactOffice(e) {
    validate.setters.contactOffice(e);
    formInputs.setters.contactOffice(e.target.value);
  }

  function handleContactEmail(e) {
    validate.setters.contactEmail(e);
    formInputs.setters.contactEmail(e.target.value);
  }

  function handleContactTel(e) {
    validate.setters.contactTel(e);
    formInputs.setters.contactTel(e.target.value);
  }

  const validateInputs = [
    {
      type: formInputs.type.invitationName,
      column: 'invitationName',
      text: '公告名',
      validationMessage: validate.validationMessage.invitationName,
      required: true,
      state: formInputs.data.invitationName,
      init: formInputs.setters.invitationName,
      setters: handleInvitationName,
      maxCharacters: 30,
      cautionMessage:
        '例）第○回、令和○年度公売など\n※全角30文字以内で入力してください。\n公告名は官公庁ナビ上のみに表示され、参加者には見えません。行政機関が管理しやすいような文言にしてください。',
    },
    {
      type: formInputs.type.bidEndType,
      column: 'bidEndType',
      text: '入札自動終了',
      list: auto_bid_end,
      required: true,
      state: formInputs.data.bidEndType,
      init: formInputs.setters.bidEndType,
      setters: handleBidEndType,
      cautionMessage:
        '※「行う」を選択した場合、参加申し込み者が0人の時に自動で入札終了処理まで行います',
    },
    {
      type: formInputs.type.mailCertDocument,
      column: 'mailCertDocument',
      text: '保証契約証明書の押印希望',
      list: mail_cert_document,
      required: true,
      state: formInputs.data.mailCertDocument,
      init: formInputs.setters.mailCertDocument,
      setters: handleMailCertDocument,
      cautionMessage:
        '※「希望する」を選択した場合、認証書面（表紙）に押印を行い郵送します。',
    },
    {
      type: formInputs.type.depositDeliveryStatus,
      column: 'depositDeliveryStatus',
      text: '公売保証金の運用',
      list: way_deposits,
      validationMessage: validate.validationMessage.depositDeliveryStatus,
      required: true,
      state: formInputs.data.depositDeliveryStatus,
      init: formInputs.setters.depositDeliveryStatus,
      setters: handleDepositDeliveryStatus,
      cautionMessage:
        '※複数選択可能です。\n出品物件のうち、公売保証金の納付方法にオン納付（クレジットカード納付）が1件でも含まれる場合は、官公庁ナビより保証金納付口座を必ずご登録ください。\nまた、「オン納付」の選択のみでは公売の入札は利用できません。',
    },
    {
      type: formInputs.type.aplCountAuctionDisplay,
      column: 'aplCountAuctionDisplay',
      text: '申し込み総数の表示（せり売）',
      list: display_apl_count,
      required: true,
      state: formInputs.data.aplCountAuctionDisplay,
      init: formInputs.setters.aplCountAuctionDisplay,
      setters: handleAplCountAuctionDisplay,
    },
    {
      type: 'h2',
      text: '【基本情報】',
    },
    {
      type: formInputs.type.governmentId,
      column: 'governmentId',
      text: '行政機関名',
      validationMessage: validate.validationMessage.governmentId,
      required: true,
      list: government_list,
      state: formInputs.data.governmentId,
      init: formInputs.setters.governmentId,
      setters: handleGovernmentId,
    },
    {
      type: formInputs.type.area,
      column: 'area',
      text: '地域',
      validationMessage: validate.validationMessage.area,
      required: true,
      state: formInputs.data.area,
      init: formInputs.setters.area,
      setters: handleArea,
      maxCharacters: 30,
      cautionMessage: '全角30文字以内で入力してください。',
    },
    {
      type: 'h2',
      text: '【落札後画面表示情報】',
      description:
        '落札者の物件詳細ページに掲載される内容です（落札者のみが見られます）。\n入力した内容は、官公庁ナビの物件登録画面の落札後の連絡先項目にデフォルト表示されます。',
    },
    {
      type: formInputs.type.contactOffice,
      column: 'contactOffice',
      text: '担当部署',
      validationMessage: validate.validationMessage.contactOffice,
      state: formInputs.data.contactOffice,
      init: formInputs.setters.contactOffice,
      setters: handleContactOffice,
    },
    {
      type: formInputs.type.contactEmail,
      column: 'contactEmail',
      text: 'メールアドレス',
      validationMessage: validate.validationMessage.contactEmail,
      state: formInputs.data.contactEmail,
      init: formInputs.setters.contactEmail,
      setters: handleContactEmail,
    },
    {
      type: formInputs.type.contactTel,
      column: 'contactTel',
      text: '電話番号',
      validationMessage: validate.validationMessage.contactTel,
      state: formInputs.data.contactTel,
      init: formInputs.setters.contactTel,
      setters: handleContactTel,
    },
  ];

  return validateInputs;
}
