import { useContext, useEffect, useState } from 'react';

import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody';
import 'interfaces/css/Government/information.css';
import { isFalsy } from 'interfaces/components/Property/service/PropertyCard/helper/isFalsy';

import { GovernmentContext } from 'interfaces/pages/Government/entities/GovernmentContext';
import { PublicSaleType } from 'shared/utils/constants/governmentTopPage';

const Information = ({ publicSaleType, procedureType }) => {
  const { government, governmentTopPages } = useContext(GovernmentContext);
  const [topPage, setTopPage] = useState(null);

  useEffect(() => {
    let topPage = governmentTopPages.find(
      (top) => top.publicSaleType === publicSaleType,
    );
    setTopPage(topPage);
  }, [governmentTopPages]);

  return (
    topPage && (
      <div className="information" data-testid="information">
        <ArticleDefaultBody>
          <div className="mb-4">
            <span className="information-label d-inline primary-box-color">
              {PublicSaleType[publicSaleType].jp}
            </span>
          </div>
          {topPage.displayPreview === 'DISPLAY' ? (
            <div
              className="preview-info text-center"
              data-testid="preview-info">
              <div className="information-title mb-4">下見会のご案内</div>
              <div className="information-read">
                {government?.name}では下見会を実施しております。
              </div>
              <div className="information-read" data-testid="preview-hp">
                詳しくは{topPage?.homePageName}{' '}
                <a href={topPage?.previewUrl}>下見会のご案内</a>{' '}
                にてご確認ください。
              </div>
            </div>
          ) : (
            <></>
          )}
          {topPage.displayInquiry === 'DISPLAY' ? (
            <div className="contact-info mt-10" data-testid="contact-info">
              <div className="contact-title information-title mb-4">
                {procedureType === 'PUBLIC_SALE'
                  ? 'インターネット公売'
                  : '公有財産売却'}
                に関するお問い合わせ先
              </div>
              <div className="information-read">{topPage?.inquiryName}</div>
              <div className="information-read" data-testid="contact-email">
                メール：
                <a href={`mailto:${topPage?.inquiryEmail}`}>
                  {topPage?.inquiryEmail}
                </a>
              </div>
              <div className="information-read">
                電話：{topPage?.inquiryTel}
                {isFalsy(topPage?.inquiryExtension)
                  ? ''
                  : `（内線：${topPage?.inquiryExtension}）`}
              </div>
            </div>
          ) : (
            <></>
          )}
          {topPage.displayHomePage === 'DISPLAY' ? (
            <div className="contact-info" data-testid="contact-info">
              <div className="information-read" data-testid="home-page">
                ホームページ：
                <a href={topPage?.homePageUrl}>{topPage?.homePageName}</a>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="default-info mt-10 mb-4">
            <div>
              利用方法に関しては、
              <a href="https://help.kankocho.jp/knowledge/help004">こちら</a>
              からお問い合わせください。
            </div>
            <div>
              注意事項：KSI官公庁オークションに参加するには、事前に参加申し込みが必要です。詳細は
              <a href="https://help.kankocho.jp/knowledge/help015">こちら</a>
              をご覧ください。
            </div>
          </div>
        </ArticleDefaultBody>
      </div>
    )
  );
};

export default Information;
