
export class ParentId {
  #parentId

  constructor(parentId) {
    this.#parentId = parentId;
  }

  get() {
    return this.#parentId
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
