import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Button from 'interfaces/components/common/Button';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import Modal2 from 'interfaces/components/common/Modal/Modal2';
import OrganizationForm from 'interfaces/components/Division/navi/OrganizationForm/OrganizationForm';
import 'interfaces/css/Organization/organization.css';
import { breadcrumbs } from 'interfaces/pages/OrganizationEdit/entities/edit/breadcrumbs';
import { OrganizationEditContext } from 'interfaces/pages/OrganizationEdit/entities/OrganizationEditContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useModalControl from 'shared/hooks/useModalControl';

import OrganizationEditConfirm from './OrganizationEditConfirm';
import { useLocalStorage } from '../../../../../shared/hooks/useLocalStorage';
import { organizationFormData } from '../OrganizationForm/OrganizationFormData';

import PageCautionDescription from 'interfaces/components/common/PageCautionDescription';
import { textExtractionByProcedureType } from 'shared/utils/helper/TextExtraction';
import { ModalButtonArea } from 'interfaces/components/common/ModalButtonArea';

const OrganizationEdit = () => {
  const { division } = useContext(OrganizationEditContext);
  const { isOpen, handleOpen, handleClose } = useModalControl();
  const { localStorageProcedureType, localStorageGovernmentType } =
    useLocalStorage();
  const [onConfirm, setOnConfirm] = useState(false);
  const navigate = useNavigate();

  const formData = organizationFormData(true);

  const [isValid, setIsValid] = useState(true);
  useEffect(() => {
    const baseNotRequiredKeys = [
      'contactExtension',
      'contactEmail',
      'useCreditCard',
      'buildName',
      'area',
      'accountType',
    ];

    const additionalNotRequiredKeys =
      formData?.data.useCreditCard === 'NO'
        ? [
            'financialInstitutionName',
            'branchName',
            'accountNumber',
            'accountName',
            'accountNameKana',
          ]
        : [];

    const addressRelatedKeys = [
      'postalCode',
      'postalCode1',
      'postalCode2',
      'city',
      'address',
      'destination',
      'writtenDestination',
    ];

    const isPublicSaleAndNotNationalTax =
      localStorageProcedureType === 'PUBLIC_SALE' &&
      localStorageGovernmentType !== 'NATIONAL_TAX_AGENCY';
    const includeAddressKeys =
      formData?.data.useCreditCard === 'YES'
        ? !isPublicSaleAndNotNationalTax
        : true;

    const notRequiredKeys = [
      ...baseNotRequiredKeys,
      ...additionalNotRequiredKeys,
      ...(includeAddressKeys ? addressRelatedKeys : []),
    ];
    const validationResults = Object.entries(formData.validationMessage).map(
      ([key, validationMessage]) => {
        const isValid = notRequiredKeys.includes(key)
          ? validationMessage === null || validationMessage === 'TRUE'
          : validationMessage === 'TRUE';

        return { key, validationMessage, isValid };
      },
    );

    const allValid = validationResults.every(({ isValid }) => isValid);
    setIsValid(allValid);
  }, [formData, localStorageProcedureType, localStorageGovernmentType]);

  useEffect(() => {
    if (division) {
      formData.init.setDivisionName(division.name);
      formData.init.setDivisionNameKana(division.nameKana);
      formData.init.setContactName(division.contactName);
      formData.setters.stringToHashContactTel(division?.contactTel);
      formData.init.setContactExtension(division.contactExtension);
      formData.init.setContactEmail(division.contactEmail);
      formData.init.setUseCreditCard(division.useCreditCard);
      formData.init.setFinancialInstitutionName(
        division.financialInstitutionName,
      );
      formData.init.setBranchName(division.branchName);
      formData.init.setAccountType(division.accountType);
      formData.init.setAccountNumber(division.accountNumber);
      formData.init.setAccountName(division.accountName);
      formData.init.setAccountNameKana(division.accountNameKana);
      formData.setters.stringToHashPostalCode(division?.postalCode);
      formData.init.setPrefectureId(division.prefectureId);
      formData.init.setCity(division.city);
      formData.init.setAddress(division.address);
      formData.init.setBuildName(division.buildName);
      formData.init.setDestination(division.destination);
      formData.init.setWrittenDestination(division.writtenDestination);
    }
  }, [division]);

  const handleConfirm = (e) => {
    e.preventDefault();
    setOnConfirm(true);
  };

  const handleBackForm = (e) => {
    e.preventDefault();
    setOnConfirm(false);
  };

  const handleComplete = (e) => {
    e.preventDefault();
    console.log(division.id);
    navigate(
      '/navi/organization/' + division.id.toString() + '/edit/complete',
      { state: { divisionName: division.name } },
    );
  };

  const handleDelete = (e) => {
    e.preventDefault();
    handleClose();
    navigate('/navi/organization');
  };

  return (
    <>
      {onConfirm ? (
        <OrganizationEditConfirm
          formData={formData}
          governmentType={localStorageGovernmentType}
          handleComplete={handleComplete}
          handleBackForm={handleBackForm}
        />
      ) : (
        <>
          <div className="organization" data-testid="organization">
            <Breadcrumb breadcrumbs={breadcrumbs(division.name)} />
            <PropertyInfoGeneralPageDescription
              title={`執行機関管理：${division.name}`}
              content={
                '登録済み執行機関の編集を行います。入力が終わったら「確認画面」ボタンを押してください。\nこの執行機関を削除する場合は「削除」ボタンを押すことで削除することができます。'
              }
              displayRequired={true}
            />
            <PageCautionDescription
              content={`※${textExtractionByProcedureType(localStorageProcedureType)}の参加申込開始日以降は掲載終了まで修正ができません。`}
            />
            <OrganizationForm
              formData={formData}
              governmentType={localStorageGovernmentType}
              procedureType={localStorageProcedureType}
            />
            <div className="pt-9">
              <div className="property-info-general-form-button-box">
                <div className="pr-1 pl-1">
                  <Button
                    text={'確認する'}
                    isOutline={false}
                    height={'44px'}
                    className={`property-info-general-form-button ${isValid ? 'new-style_navi_primary-button' : 'new-style_navi_disabled-button'}`}
                    // clickHandler={() => navigate('/navi/organization/' + id.toString() + '/confirm')}
                    clickHandler={handleConfirm}
                    isDisabled={!isValid}
                  />
                </div>
                <div className="pr-1 pl-1">
                  <Button
                    text={'キャンセル'}
                    isOutline={false}
                    height={'44px'}
                    className={
                      'property-info-general-form-button new-style_navi_normal-button'
                    }
                  />
                </div>
                <div className="pr-1 pl-1">
                  <Button
                    text={'削除'}
                    isOutline={false}
                    height={'44px'}
                    clickHandler={handleOpen}
                    className={
                      'property-info-general-form-button new-style_navi_important-button'
                    }
                  />
                </div>
              </div>
            </div>
            <Modal2
              isOpen={isOpen}
              handleClose={handleClose}
              modalSize="550px"
              modalHeight="90%"
              padding={false}>
              <div className="modal-title">{`削除確認`}</div>
              <div className="modal-description-area">
                <p className="modal-description">{`${formData.data.divisionName}を削除しますか？`}</p>
              </div>
              <hr className="modal-border" />
              <ModalButtonArea>
                <Button
                  text={'キャンセル'}
                  isOutline={false}
                  height={'44px'}
                  className={'cancel-button new-style_navi_normal-button'}
                  clickHandler={handleClose}
                />

                <Button
                  text={'削除する'}
                  isOutline={false}
                  height={'44px'}
                  className={'delete-button new-style_navi_important-button'}
                  clickHandler={handleDelete}
                />
              </ModalButtonArea>
            </Modal2>
          </div>
        </>
      )}
    </>
  );
};

export default OrganizationEdit;
