
export class DocumentPageDisplay {
  #documentPageDisplay

  constructor(documentPageDisplay) {
    this.#documentPageDisplay = documentPageDisplay;
  }

  get() {
    return this.#documentPageDisplay
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
