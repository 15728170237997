import HeaderBottom from './HeaderBottom/HeaderBottom.jsx';
import HeaderTop from './HeaderTop/HeaderTop.jsx';

const AuctionHeader = () => {
  return (
    <header className="header" data-testid="header">
      <HeaderTop />
      <HeaderBottom />
    </header>
  );
};

export default AuctionHeader;
