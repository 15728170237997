
export class SummaryCarMileage {
    #summaryCarMileage

    constructor(summaryCarMileage) {
        this.#summaryCarMileage = summaryCarMileage;
    }

    get() {
        return this.#summaryCarMileage
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
