import CopyRight from "interfaces/components/common/Footer/CopyRight";
import FooterNav from "interfaces/components/common/Footer/FooterNav";
import "interfaces/css/common/footer.css"

const NaviFooter = () => {
    return (
        <footer className="footer" data-testid="footer">
            <FooterNav/>
            <CopyRight/>
        </footer>
    );
}

export default NaviFooter;