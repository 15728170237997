import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class ImportantDescription2 {
  #importantDescription2

  constructor(importantDescription2) {
    this.#importantDescription2 = importantDescription2;
  }

  get() {
    return this.#importantDescription2
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#importantDescription2, 0, 1000)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#importantDescription2)
  }
}
