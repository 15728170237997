import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class FirstName {
  #firstName

  constructor(firstName) {
    this.#firstName = firstName;
  }

  get() {
    return this.#firstName
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#firstName, 1, 30)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#firstName)
  }
}
