
export class ContactPpsEmail {
    #contactPpsEmail

    constructor(contactPpsEmail) {
        this.#contactPpsEmail = contactPpsEmail;
    }

    get() {
        return this.#contactPpsEmail
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
