import Button from 'interfaces/components/common/Button/Button';

const CallUpUserInfoButton = ({ handleClick }) => {
  return (
    <Button
      text="マイページに保存した情報を利用する"
      onClick={handleClick}
      className="mb-4"
    />
  );
};

export default CallUpUserInfoButton;
