import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class ContactAccept {
  #contactAccept

  constructor(contactAccept) {
    this.#contactAccept = contactAccept;
  }

  get() {
    return this.#contactAccept
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#contactAccept, 0, 50)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#contactAccept)
  }
}
