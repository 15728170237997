// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.government .margin-h {
  margin: 0 12px;
}

.government .government-head-top {
  align-items: center;
  font-family: "Roboto", sans-serif;
  margin-bottom: 16px;
}

.government .government-emblem {
  height: 32px;
}

.government .government-name {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  margin: 0 8px;
}

.government .government-info {
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.government .government-head-bottom {
  align-items: center;
}

.government .government-status-tag {
  font-size: .75rem;
  background-color: var(--primary-base);
  color: var(--white);
  padding: 8px;
  font-weight: 700;
  line-height: 1em;
}

.government .government-caption {
  font-size: .75rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: .0333333333em;
  margin-left: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Government/government-head.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,iCAAiC;EACjC,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,qCAAqC;EACrC,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,iCAAiC;EACjC,gBAAgB;EAChB,oBAAoB;EACpB,6BAA6B;EAC7B,iBAAiB;AACnB","sourcesContent":[".government .margin-h {\n  margin: 0 12px;\n}\n\n.government .government-head-top {\n  align-items: center;\n  font-family: \"Roboto\", sans-serif;\n  margin-bottom: 16px;\n}\n\n.government .government-emblem {\n  height: 32px;\n}\n\n.government .government-name {\n  font-size: 1.25rem;\n  font-weight: 500;\n  line-height: 2rem;\n  margin: 0 8px;\n}\n\n.government .government-info {\n  font-size: .75rem;\n  font-weight: 400;\n  line-height: 1.25rem;\n}\n\n.government .government-head-bottom {\n  align-items: center;\n}\n\n.government .government-status-tag {\n  font-size: .75rem;\n  background-color: var(--primary-base);\n  color: var(--white);\n  padding: 8px;\n  font-weight: 700;\n  line-height: 1em;\n}\n\n.government .government-caption {\n  font-size: .75rem;\n  font-family: \"Roboto\", sans-serif;\n  font-weight: 400;\n  line-height: 1.25rem;\n  letter-spacing: .0333333333em;\n  margin-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
