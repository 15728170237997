import PopUpInput from 'interfaces/components/common/Input/PopUpInput';
import { PREFECTURE } from 'shared/utils/helper/constants';
import handleValidationMessage from 'shared/utils/helper/handleValidationMessage';

import RequiredLabel from '../RequiredLabel/RequiredLabel.jsx';

const PrefectureForm = ({ prefectureId, handleSelect, validationMessage }) => {
  return (
    <tr data-testid="prefecture-form">
      <th data-testid="prefecture-form-label">
        都道府県
        <RequiredLabel />
      </th>
      <td>
        <div className="pop-up-input-box">
          <PopUpInput
            items={PREFECTURE.map((prefecture) => prefecture.name)}
            value={
              PREFECTURE.find((prefecture) => prefecture.id === prefectureId)
                ?.name ?? ''
            }
            handleSelect={handleSelect}
            name="prefecture"
          />
        </div>
        <small className="validate-message flex-wrap">
          {handleValidationMessage(validationMessage)}
        </small>
      </td>
    </tr>
  );
};

export default PrefectureForm;
