// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kmanager-toolbar {
  display: flex;
  align-items: center;
  max-width: 100%;
  height: 64px;
  padding: 4px 16px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/kmanager-toolbar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".kmanager-toolbar {\n  display: flex;\n  align-items: center;\n  max-width: 100%;\n  height: 64px;\n  padding: 4px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
