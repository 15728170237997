import { findByPropertyId as getProposals } from 'domain/Applicant/services/Proposal';
import { findById as findAuction } from 'domain/Auction/services/Auction.js';
import { get as getBids } from 'domain/Bid/services/Bid';
import { get as getBidderHistories } from 'domain/Bid/services/BidderHistory';
import { findByGovernmentId as getDivisions } from 'domain/Division/services/Division';
import { findById as findGovernment } from 'domain/Government/services/Government.js';
import { findByAuctionIdAndGovernmentId as getProperties } from 'domain/Property/services/Property.js';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from 'shared/utils/helper/authState.js';
import { KMANAGER } from 'shared/utils/helper/constants.js';

import { breadcrumbs } from '../entities/breadcrumbs.js';
import { GovernmentPropertiesContext } from '../entities/GovernmentPropertiesContext.js';
import { isEmpty } from 'shared/utils/constants/emptyData.js';

const GovernmentPropertiesProvider = ({ type, children }) => {
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [auction, setAuction] = useState({});
  const [government, setGovernment] = useState({});
  const [properties, setProperties] = useState([]);

  const { auctionId, governmentId } = useParams();

  if (type === KMANAGER) {
    useEffect(() => {
      findGovernment(type, governmentId).then((result) => {
        setGovernment(result);
      });
    }, [governmentId]);

    useEffect(() => {
      findAuction(type, auctionId).then((result) => {
        setAuction(result);
      });
    }, [auctionId]);

    useEffect(() => {
      async function fetchAndSetProperties() {
        const resultProperties = await getProperties(
          type,
          auctionId,
          governmentId,
        );
        const resultDivisions = await getDivisions(type, governmentId);
        const resultProposals = await getProposals(type, governmentId);
        const resultBids = await getBids(type);
        const resultBidderHistories = await getBidderHistories(type);

        let properties = [];
        resultProperties.forEach((tmpProperty) => {
          let property = tmpProperty;
          property.resolutionDate = isEmpty(property.resolutionDate)
            ? ''
            : property.resolutionDate;
          property.fixedResolutionDate = isEmpty(property.fixedResolutionDate)
            ? ''
            : property.fixedResolutionDate;
          
          let division = resultDivisions.find(
            (division) =>
              division.id.toString() === property.divisionId.toString(),
          );
          property.divisionName = division?.name || '';

          let offlineTrialCount = 0;
          let offlineApplicantCount = 0;
          let onlineTrialCount = 0;
          let onlineApplicantCount = 0;
          resultProposals.forEach((proposal) => {
            if (
              proposal.aplStatus === 'TRIAL' &&
              proposal.aplType === 'OFFLINE'
            ) {
              offlineTrialCount += 1;
            } else if (
              proposal.aplStatus === 'APPLICATION' &&
              proposal.aplType === 'OFFLINE'
            ) {
              offlineApplicantCount += 1;
            } else if (
              proposal.aplStatus === 'TRIAL' &&
              proposal.aplType === 'ONLINE'
            ) {
              onlineTrialCount += 1;
            } else if (
              proposal.aplStatus === 'APPLICATION' &&
              proposal.aplType === 'ONLINE'
            ) {
              onlineApplicantCount += 1;
            }
          });
          property.offlineTrialCount = offlineTrialCount;
          property.offlineApplicantCount = offlineApplicantCount;
          property.onlineTrialCount = onlineTrialCount;
          property.onlineApplicantCount = onlineApplicantCount;

          let bidders = resultBids.filter(
            (bidder) => bidder.propertyId.toString() === property.id.toString(),
          );
          property.bidderCount = bidders.length;

          let histories = resultBidderHistories.filter(
            (history) =>
              history.propertyId.toString() === property.id.toString() &&
              history.bidInfo === 'ADDITION',
          );
          property.additionCount = histories.length;
          property.bidPrice = Number(property.bidPrice);
          property.deposit = Number(
            property.deposit === '-9999' ? 0 : property.deposit,
          );
          property.bidEndPrice = Number(property.bidEndPrice);
          properties.push(property);
        });
        setProperties(properties);
      }

      fetchAndSetProperties();
    }, [auctionId, governmentId]);
  }

  return (
    <GovernmentPropertiesContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        properties,
        auction,
        government,
      }}>
      {children}
    </GovernmentPropertiesContext.Provider>
  );
};

export default GovernmentPropertiesProvider;
