import 'interfaces/css/SampleGuideline/guideline-modal-headline.css';

const GuidelineModalHeadline = ({ text }) => {
  return (
    <div
      data-testid="guideline-modal-headline"
      className="guideline-modal-headline">
      {text}
    </div>
  );
};
export default GuidelineModalHeadline;
