import {
  after_winning_bid_caution_description_4,
  after_winning_bid_caution_description_5,
} from 'shared/utils/helper/DummyAfterWinningBid';
import { local_government_management_radio1 } from 'shared/utils/helper/radio_labels';

import 'interfaces/css/AfterWinningBidCaution/after-winning-bid-caution-form-require-document.css';
import FormRadioWithCaution from '../../../Form/navi/FormRadioWithCaution';
import FormTextWithCaution from '../../../Form/navi/FormTextWithCaution';
import Label from '../../../Form/navi/Label';

const AuctionAfterWinningAssetSaleBidCautionFormRequireDocument = ({
  label,
  validations,
}) => {
  return (
    <div className="after-winning-bid-caution-form-require-document-table">
      <Label
        label={label}
        className="property-info-general-form-table-cell after-winning-bid-caution-form-require-document-label"
      />
      <div className="pa-0_i property-info-general-form-table-cell">
        <div className="after-winning-bid-caution-form-require-document-table">
          <FormRadioWithCaution
            label={'ページの表示'}
            label_class_name="after-winning-bid-caution-form-require-document-label"
            radio_label={local_government_management_radio1}
            data={validations.data.auctionDocumentPageDisplay}
            handler1={(e) => {
              validations.setters.handleAuctionDocumentPageDisplay(e);
              validations.setters.handleAuctionDocumentPageName(
                { target: { value: validations.data.auctionDocumentPageName } },
                e.target.value === 'DISPLAY',
              );
              validations.setters.handleAuctionDocumentPageUrl(
                { target: { value: validations.data.auctionDocumentPageUrl } },
                e.target.value === 'DISPLAY',
              );
            }}
          />
          <FormTextWithCaution
            label={'案内ページ名'}
            label_class_name="after-winning-bid-caution-form-require-document-label"
            maxCharacters={30}
            required={validations.data.auctionDocumentPageDisplay === 'DISPLAY'}
            caution_description={after_winning_bid_caution_description_4}
            data={validations.data.auctionDocumentPageName}
            handler1={(e) =>
              validations.setters.handleAuctionDocumentPageName(
                e,
                validations.data.auctionDocumentPageDisplay === 'DISPLAY',
              )
            }
            invalidMessage={
              validations.validationMessage.auctionDocumentPageNameValid
            }
          />
          <FormTextWithCaution
            label={'URL'}
            label_class_name="after-winning-bid-caution-form-require-document-label"
            caution_description={after_winning_bid_caution_description_5}
            required={validations.data.auctionDocumentPageDisplay === 'DISPLAY'}
            data={validations.data.auctionDocumentPageUrl}
            handler1={(e) =>
              validations.setters.handleAuctionDocumentPageUrl(
                e,
                validations.data.auctionDocumentPageDisplay === 'DISPLAY',
              )
            }
            invalidMessage={
              validations.validationMessage.auctionDocumentPageUrlValid
            }
          />
        </div>
      </div>
    </div>
  );
};
export default AuctionAfterWinningAssetSaleBidCautionFormRequireDocument;
