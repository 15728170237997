import styled from 'styled-components';

import { useContext } from 'react';
import { Link } from 'react-router-dom';

import NotificationListItem from './NotificationListItem.jsx';
import { DashboardContext } from '../../../../../../pages/Dashboard/entities/DashboardContext.js';

import { TextCaption } from 'interfaces/components/common/TextCaption/index';

const Wrapper = styled.div``;

const NotificationList = () => {
  const { notices } = useContext(DashboardContext);

  return (
    <TextCaption data-testid="notification-list-wrapper">
      <Wrapper>
        {notices?.slice(0, 10).map((notice) => (
          <NotificationListItem
            key={notice.id}
            id={notice.id}
            title={notice.title}
          />
        ))}
        <p className="text-right mb-0">
          <Link to="/news" data-testid="news-link">
            お知らせ一覧へ ＞
          </Link>
        </p>
      </Wrapper>
    </TextCaption>
  );
};

export default NotificationList;
