// ロジックを含む処理
// フロントから呼ぶ関数

import { createEmailData, updateEmailData } from "../../../infrastructure/api/Email.js";
import { compareData } from "../../../shared/utils/helper/compareData.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { EmailCreate } from "../entities/Email.js";
import { getEmail } from "../repositories/Email.js";
//import { createEmailData, updateEmailData, deleteEmailData } from "../../../infrastructure/api/Email.js";

export async function get(type) {
  return await getEmail(type)
}

export async function findBygovernmentId(type, id) {
  const Email = await getEmail(type);
  return Email.find(data => data.governmentId === id)
}

export async function create(type, data) {
  let response;
  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Email)');
  }
  if (type === NAVI) {
    const email = new EmailCreate(data)
    response = await createEmailData(email);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Email)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Email', response);
  }
  return response
}

export async function update(type, data) {
  let response;
  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Email)');
  }
  if (type === NAVI) {
    const email = new EmailCreate(data)
    response = await updateEmailData(email);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Email)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Email', response);
  }
  return response
}

export async function destroy(type) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Email)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Email)');
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Email)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Email', response);
  }
  return response
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]))
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]))
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value)
}
