
export class AgencyPrefecture {
    #agencyPrefecture

    constructor(agencyPrefecture) {
        this.#agencyPrefecture = agencyPrefecture;
    }

    get() {
        return this.#agencyPrefecture
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
