import {useEffect, useState} from "react";

import SelectBox from "./SelectBox";
import CautionDescription from "../../../Form/navi/CautionDescription.jsx";
import FormText from "../../../Form/navi/FormText";
import Label from "../../../Form/navi/Label.jsx";

const FormPullDownAndOtherTextWithCaution = ({
    label,
    listItemData = [],
    caution_description,
    handler1,
    required = true,
    selectedData = listItemData[0].value,  // DataBaseに保存されている値が取得される
    otherData = '',
    otherHandler,
    otherInvalidMessage,
    otherWidth = 70,
    width = '100%'
}) => {
    const [selectText, setSelectText] = useState('')

    useEffect(() => {
        let item = listItemData?.find(item => item.value === selectedData)
        setSelectText(item?.text);
    }, [listItemData, selectedData])

    return (
        <div className="property-info-general-form-table-row">
            <Label label={label} required={required}/>
            <div className="property-info-general-form-table-cell">
                <SelectBox
                    width={width}
                    handler={handler1}
                    listItemData={listItemData}
                    selectedTextData={selectText}
                />
                {
                    selectedData === 'OTHER' ?
                        <FormText
                            className={`property-input-text-size-${otherWidth}`}
                            invalidMessage={otherInvalidMessage}
                            data={otherData}
                            handler1={otherHandler}
                            maxCharacters={50}
                        /> : <></>
                }

                <CautionDescription data={caution_description}/>
            </div>
        </div>
    );
}
export default FormPullDownAndOtherTextWithCaution
