// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import { CarBodyColor } from './CarProperties/carBodyColor';
import { CarMileage } from './CarProperties/carMileage';
import { CarStatus } from './CarProperties/carStatus';
import { ClassificationNumber } from './CarProperties/classificationNumber';
import { DeliveryTerms } from './CarProperties/deliveryTerms';
import { Displacement } from './CarProperties/displacement';
import { DisplayProductNumber } from './CarProperties/displayProductNumber';
import { DisplayRegisterNumber } from './CarProperties/displayRegisterNumber';
import { EffectiveDate } from './CarProperties/effectiveDate';
import { EngineModel } from './CarProperties/engineModel';
import { ExistsCarMaintenanceSheet } from './CarProperties/existsCarMaintenanceSheet';
import { FirstRegisterDate } from './CarProperties/firstRegisterDate';
import { FormalNumber } from './CarProperties/formalNumber';
import { FrontAxelWeight } from './CarProperties/frontAxelWeight';
import { FuelType } from './CarProperties/fuelType';
import { HandleType } from './CarProperties/handleType';
import { Height } from './CarProperties/height';
import { InventoryLocation } from './CarProperties/inventoryLocation';
import { Length } from './CarProperties/length';
import { Manufacture } from './CarProperties/manufacture';
import { ManufactureOther } from './CarProperties/manufactureOther';
import { MapUrl } from './CarProperties/mapUrl';
import { Model } from './CarProperties/model';
import { PrivateOrBusiness } from './CarProperties/privateOrBusiness';
import { ProductNumber } from './CarProperties/productNumber';
import { Purpose } from './CarProperties/purpose';
import { RearAxelWeight } from './CarProperties/rearAxelWeight';
import { RegisterDate } from './CarProperties/registerDate';
import { RegisterNumber } from './CarProperties/registerNumber';
import { SafetyTerm } from './CarProperties/safetyTerm';
import { SeatingCapacity } from './CarProperties/seatingCapacity';
import { Shape } from './CarProperties/shape';
import { StatusCarMileage } from './CarProperties/statusCarMileage';
import { StatusSafetyTerm } from './CarProperties/statusSafetyTerm';
import { TotalWeight } from './CarProperties/totalWeight';
import { TransmissionType } from './CarProperties/transmissionType';
import { Weight } from './CarProperties/weight';
import { Width } from './CarProperties/width';
import { BuildDate } from './EstateProperties/buildDate';
import { BuildDateOther } from './EstateProperties/buildDateOther';
import { BuildName } from './EstateProperties/buildName';
import { BuildOther } from './EstateProperties/buildOther';
import { BuildRatio } from './EstateProperties/buildRatio';
import { BuildSpace } from './EstateProperties/buildSpace';
import { CityPlanningArea } from './EstateProperties/cityPlanningArea';
import { Description } from './EstateProperties/description';
import { EstateStatus } from './EstateProperties/estateStatus';
import { ExclusiveSpace } from './EstateProperties/exclusiveSpace';
import { FirebreakArea } from './EstateProperties/firebreakArea';
import { Floor } from './EstateProperties/floor';
import { FloorAreaRatio } from './EstateProperties/floorAreaRatio';
import { FloorSpace } from './EstateProperties/floorSpace';
import { Geographical } from './EstateProperties/geographical';
import { HighDistrict } from './EstateProperties/highDistrict';
import { LandCategory } from './EstateProperties/landCategory';
import { LandOther } from './EstateProperties/landOther';
import { LandRights } from './EstateProperties/landRights';
import { LandShare } from './EstateProperties/landShare';
import { LandSpace } from './EstateProperties/landSpace';
import { LandSpaceType } from './EstateProperties/landSpaceType';
import { LawRegulation } from './EstateProperties/lawRegulation';
import { Layout } from './EstateProperties/layout';
import { LocationGovernmentSubCategoryId } from './EstateProperties/locationGovernmentSubCategoryId';
import { LocationText } from './EstateProperties/locationText';
import { LocationUrl } from './EstateProperties/locationUrl';
import { NearestStation } from './EstateProperties/nearestStation';
import { NeighboringRoad } from './EstateProperties/neighboringRoad';
import { Other } from './EstateProperties/other';
import { PrivateRoad } from './EstateProperties/privateRoad';
import { PropertyPrint } from './EstateProperties/propertyPrint';
import { ProvideEquipment } from './EstateProperties/provideEquipment';
import { ResidenceGovernmentSubCategoryId } from './EstateProperties/residenceGovernmentSubCategoryId';
import { ResidenceLocation } from './EstateProperties/residenceLocation';
import { RestrictedArea } from './EstateProperties/restrictedArea';
import { ShadeRegulation } from './EstateProperties/shadeRegulation';
import { SketchUrl } from './EstateProperties/sketchUrl';
import { StructureType } from './EstateProperties/structureType';
import { TotalHouses } from './EstateProperties/totalHouses';
import { Type } from './EstateProperties/type';
import { ContractedLimit } from './GeneralProperties/contractedLimit';
import { Deposit } from './GeneralProperties/deposit';
import { DepositPayment } from './GeneralProperties/depositPayment';
import { DivisionId } from './GeneralProperties/divisionId';
import { EstimatePrice } from './GeneralProperties/estimatePrice';
import { Id } from './GeneralProperties/id';
import { IsApprovalRequired } from './GeneralProperties/isApprovalRequired';
import { IsCopied } from './GeneralProperties/isCopied';
import { IsDraft } from './GeneralProperties/isDraft';
import { PaymentDate } from './GeneralProperties/paymentDate';
import { PaymentLimit } from './GeneralProperties/paymentLimit';
import { PrintOrder } from './GeneralProperties/printOrder';
import { ResolutionDate } from './GeneralProperties/resolutionDate';
import { VideoUrl } from './GeneralProperties/videoUrl';
import { AddDescription } from './Property/addDescription';
import { AdditionalBidEnd } from './Property/additionalBidEnd';
import { AplEnd } from './Property/aplEnd';
import { AplStart } from './Property/aplStart';
import { AuctionEnd } from './Property/auctionEnd';
import { BidCount } from './Property/bidCount';
import { BidCountDisplay } from './Property/bidCountDisplay';
import { BiddedPrice } from './Property/biddedPrice';
import { BiddedUser } from './Property/biddedUser';
import { Bidders } from './Property/bidders';
import { BidEnd } from './Property/bidEnd';
import { BidHistory } from './Property/bidHistory';
import { BidStart } from './Property/bidStart';
import { BodyColor } from './Property/bodyColor';
import { CarCondition } from './Property/carCondition';
import { CarDescription } from './Property/carDescription';
import { CarType } from './Property/carType';
import { Circumstance } from './Property/circumstance';
import { CommonDescription } from './Property/commonDescription';
import { ContactAccept } from './Property/contactAccept';
import { ContactEmail } from './Property/contactEmail';
import { ContactExtension } from './Property/contactExtension';
import { ContactName } from './Property/contactName';
import { ContactOffice } from './Property/contactOffice';
import { ContactTel } from './Property/contactTel';
import { ContractDeadline } from './Property/contractDeadline';
import { CurrentTopPrice } from './Property/currentTopPrice';
import { CurrentTopUser } from './Property/currentTopUser';
import { Deadline } from './Property/deadline';
import { DescriptionCreatedAt } from './Property/descriptionCreatedAt';
import { DivisionName } from './Property/divisionName';
import { EmailMessage } from './Property/emailMessage';
import { EmailSentAt } from './Property/emailSentAt';
import { EngineType } from './Property/engineType';
import { Form } from './Property/form';
import { FrontWeight } from './Property/frontWeight';
import { GovernmentName } from './Property/governmentName';
import { GovernmentType } from './Property/governmentType';
import { Handle } from './Property/handle';
import { IndividualEnterprise } from './Property/individualEnterprise';
import { InquiryAccept } from './Property/inquiryAccept';
import { InquiryEmail } from './Property/inquiryEmail';
import { InquiryExtension } from './Property/inquiryExtension';
import { InquiryName } from './Property/inquiryName';
import { InquiryNote } from './Property/inquiryNote';
import { InquiryOffice } from './Property/inquiryOffice';
import { InquiryTel } from './Property/inquiryTel';
import { IsDeliveryLock } from './Property/isDeliveryLock';
import { Location } from './Property/location';
import { LocationMap } from './Property/locationMap';
import { NextWinner } from './Property/nextWinner';
import { NextWinnerPrice } from './Property/nextWinnerPrice';
import { Note } from './Property/note';
import { PaymentDeadline } from './Property/paymentDeadline';
import { Payments } from './Property/payments';
import { ProcedureTypeEn } from './Property/procedureTypeEn';
import { PropertyId } from './Property/propertyId';
import { QualifiedInvoice } from './Property/qualifiedInvoice';
import { RearWeight } from './Property/rearWeight';
import { RegistrationDate } from './Property/registrationDate';
import { RegistrationNumber } from './Property/registrationNumber';
import { RemainingLabel } from './Property/remainingLabel';
import { StatusEn } from './Property/statusEn';
import { Structure } from './Property/structure';
import { SummaryBuildDate } from './Property/summaryBuildDate';
import { SummaryBuildSpace } from './Property/summaryBuildSpace';
import { SummaryCarCategories } from './Property/summaryCarCategories';
import { SummaryCarMileage } from './Property/summaryCarMileage';
import { SummaryDisplacement } from './Property/summaryDisplacement';
import { SummaryEstateType } from './Property/summaryEstateType';
import { SummaryExclusiveSpace } from './Property/summaryExclusiveSpace';
import { SummaryFirstRegisterDate } from './Property/summaryFirstRegisterDate';
import { SummaryFloor } from './Property/summaryFloor';
import { SummaryFloorSpace } from './Property/summaryFloorSpace';
import { SummaryLayout } from './Property/summaryLayout';
import { SummaryLocationText } from './Property/summaryLocationText';
import { SummaryManufacture } from './Property/summaryManufacture';
import { SummaryNearestStation } from './Property/summaryNearestStation';
import { SummaryPurpose } from './Property/summaryPurpose';
import { SummarySafetyTerm } from './Property/summarySafetyTerm';
import { SummarySeatingCapacity } from './Property/summarySeatingCapacity';
import { SummaryTotalHouses } from './Property/summaryTotalHouses';
import { SummaryTransmissionType } from './Property/summaryTransmissionType';
import { Tags } from './Property/tags';
import { CommonData } from './PropertyCompositions.js';
import { NotUndefined } from '../../../shared/utils/helper/NotUndefined.js';

export class PropertyGet {
  constructor(property) {
    this.id = property.id;
    this.auctionId = property.auctionId;
    this.governmentId = property.governmentId;
    this.categoryId = property.categoryId;
    this.divisionId = property.divisionId;
    this.governmentSubCategoryId = property.governmentSubCategoryId;
    this.printOrder = property.printOrder;
    this.propertyType = property.propertyType;
    this.saleNumber = property.saleNumber;
    this.name = property.name;
    this.publicSaleType = property.publicSaleType;
    this.status = property.status;
    this.deposit = property.deposit;
    this.depositPayment = property.depositPayment;
    this.depositProxy = property.depositProxy;
    this.depositRefundScheduled = property.depositRefundScheduled;
    this.depositRefundClaim = property.depositRefundClaim;
    this.bidPrice = property.bidPrice;
    this.estimatePrice = property.estimatePrice;
    this.paymentScheduledPrice = property.paymentScheduledPrice;
    this.restitutionScheduledPrice = property.restitutionScheduledPrice;
    this.auctionUserId = property.auctionUserId;
    this.nextAuctionUserId = property.nextAuctionUserId;
    this.bidEndPrice = property.bidEndPrice;
    this.nextPrice = property.nextPrice;
    this.isPaid = property.isPaid;
    this.paymentLimit = property.paymentLimit;
    this.paymentDate = property.paymentDate;
    this.paymentScheduledDate = property.paymentScheduledDate;
    this.contractedLimit = property.contractedLimit;
    this.cancelDate = property.cancelDate;
    this.isDraft = property.isDraft;
    this.isCopied = property.isCopied;
    this.isLock = property.isLock;
    this.isNextBuyApl = property.isNextBuyApl;
    this.videoUrl = property.videoUrl;
    this.description = property.description;
    this.inquiryName = property.inquiryName;
    this.inquiryOffice = property.inquiryOffice;
    this.inquiryEmail = property.inquiryEmail;
    this.inquiryTel = property.inquiryTel;
    this.inquiryExtension = property.inquiryExtension;
    this.inquiryAccept = property.inquiryAccept;
    this.inquiryNote = property.inquiryNote;
    this.contactName = property.contactName;
    this.contactOffice = property.contactOffice;
    this.contactEmail = property.contactEmail;
    this.contactTel = property.contactTel;
    this.contactAccept = property.contactAccept;
    this.contactExtension = property.contactExtension;
    this.isDeliveryRequestDisplayed = property.isDeliveryRequestDisplayed;
    this.deliveryType = property.deliveryType;
    this.carrierNameList = property.carrierNameList;
    this.carrierName = property.carrierName;
    this.carrierTel = property.carrierTel;
    this.arrangement = property.arrangement;
    this.packing = property.packing;
    this.receiver = property.receiver;
    this.receiverName = property.receiverName;
    this.deliveryDate = property.deliveryDate;
    this.deliveryTime = property.deliveryTime;
    this.deliveryText = property.deliveryText;
    this.registrationDate = property.registrationDate;
    this.isApplyReduction = property.isApplyReduction;
    this.isNotClaimable = property.isNotClaimable;
    this.isApprovalRequired = property.isApprovalRequired;
    this.reductionApproval = property.reductionApproval;
    this.reductionApplicant = property.reductionApplicant;
    this.reductionReason = property.reductionReason;
    this.reductionReasonOther = property.reductionReasonOther;
    this.isExtensionSurveyTerm = property.isExtensionSurveyTerm;
    this.resolutionDate = property.resolutionDate;
    this.fixResolutionDate = property.fixResolutionDate;
    this.bidSystemFee = property.bidSystemFee;
    this.bidSystemFeeTax = property.bidSystemFeeTax;
    this.createdUser = property.createdUser;
    this.createdAt = property.createdAt;
    this.updatedUser = property.updatedUser;
    this.updatedAt = property.updatedAt;
  }
}

export class PropertyGetBk {
  constructor(data) {
    this.id = data.id;

    this.auctionId = data.auctionId;
    this.governmentId = data.governmentId;
    this.isInvoiceTarget = data.isInvoiceTarget;
    this.commonData = new CommonData(data);
    this.name = data.name;
    this.saleNumber = data.saleNumber;
    this.publicSaleType = data.publicSaleType;
    this.propertyType = data.propertyType;
    // 分割予定のクラス
    // this.inquiryData = new InquiryData(data);
    // this.contactData = new ContactData(data);
    // this.carData = new CarData(data);
    // this.estateData = new EstateData(data);
    // this.estateDataSummary = new EstateDataSummary(data);

    this.title = data.title;
    this.manageId = data.manageId;
    this.divisionName = data.divisionName;
    this.deposit = data.deposit;
    this.bidPrice = data.bidPrice;
    this.bidEndPrice = data.bidEndPrice;
    this.bidSystemFee = data.bidSystemFee;
    this.bidSystemFeeTax = data.bidSystemFeeTax;
    this.depositPayment = data.depositPayment;
    this.paymentScheduledPrice = data.paymentScheduledPrice;
    this.restitutionScheduledPrice = data.restitutionScheduledPrice;
    this.description = data.description;
    this.estimatePrice = data.estimatePrice;
    this.videoUrl = data.videoUrl;
    this.contractedLimit = data.contractedLimit;
    this.divisionId = data.divisionId;
    this.isApprovalRequired = data.isApprovalRequired;
    this.isCopied = data.isCopied;
    this.isDraft = data.isDraft;
    this.paymentDate = data.paymentDate;
    this.paymentLimit = data.paymentLimit;
    this.printOrder = data.printOrder;
    this.qualifiedInvoice = data.qualifiedInvoice;
    this.resolutionDate = data.resolutionDate;

    this.addDescription = data.addDescription;
    this.additionalBidEnd = data.additionalBidEnd;
    this.aplEnd = data.aplEnd;
    this.aplStart = data.aplStart;
    this.auctionEnd = data.auctionEnd;
    this.bidEnd = data.bidEnd;
    this.bidStart = data.bidStart;
    this.bidders = data.bidders;
    this.bodyColor = data.bodyColor;
    this.circumstance = data.circumstance;
    this.classificationNumber = data.classificationNumber;
    this.commonDescription = data.commonDescription;
    this.contractDeadline = data.contractDeadline;
    this.deadline = data.deadline;
    this.displacement = data.displacement;
    this.divisionName = data.divisionName;
    this.description = data.description;
    this.form = data.form;
    this.governmentName = data.governmentName;
    this.governmentType = data.governmentType;
    this.individualEnterprise = data.individualEnterprise;
    this.isDeliveryLock = data.isDeliveryLock;
    this.location = data.location;
    this.locationMap = data.locationMap;
    this.note = data.note;
    this.other = data.other;
    this.paymentDeadline = data.paymentDeadline;
    this.payments = data.payments;
    this.procedureTypeEn = data.procedureTypeEn;
    this.productNumber = data.productNumber;
    this.propertyPrint = data.propertyPrint;
    this.purpose = data.purpose;
    this.rearWeight = data.rearWeight;
    this.registrationDate = data.registrationDate;
    this.registrationNumber = data.registrationNumber;
    this.remainingLabel = data.remainingLabel;
    this.residenceLocation = data.residenceLocation;
    this.restrictedArea = data.restrictedArea;
    this.safetyTerm = data.safetyTerm;
    this.seatingCapacity = data.seatingCapacity;
    this.shape = data.shape;
    this.status = data.status;
    this.statusEn = data.statusEn;
    this.structure = data.structure;
    this.tags = data.tags;
    this.totalWeight = data.totalWeight;
    this.transmissionType = data.transmissionType;
    this.weight = data.weight;
    this.width = data.width;

    // InquiryData
    this.inquiryAccept = data.inquiryAccept;
    this.inquiryEmail = data.inquiryEmail;
    this.inquiryExtension = data.inquiryExtension;
    this.inquiryName = data.inquiryName;
    this.inquiryNote = data.inquiryNote;
    this.inquiryOffice = data.inquiryOffice;
    this.inquiryTel = data.inquiryTel;

    // ContactData
    this.contactAccept = data.contactAccept;
    this.contactEmail = data.contactEmail;
    this.contactExtension = data.contactExtension;
    this.contactName = data.contactName;
    this.contactOffice = data.contactOffice;
    this.contactTel = data.contactTel;
    this.contractedLimit = data.contractedLimit;

    // CarData
    this.carBodyColor = data.carBodyColor;
    this.carCondition = data.carCondition;
    this.carMileage = data.carMileage;
    this.carDescription = data.carDescription;
    this.carType = data.carType;
    this.classificationNumber = data.classificationNumber;
    this.deliveryTerms = data.deliveryTerms;
    this.description = data.description;
    this.displacement = data.displacement;
    this.displayProductNumber = data.displayProductNumber;
    this.displayRegisterNumber = data.displayRegisterNumber;
    this.effectiveDate = data.effectiveDate;
    this.engineType = data.engineType;
    this.existsCarMaintenanceSheet = data.existsCarMaintenanceSheet;
    this.firstRegisterDate = data.firstRegisterDate;
    this.formalNumber = data.formalNumber;
    this.frontWeight = data.frontWeight;
    this.fuelType = data.fuelType;
    this.handle = data.handle;
    this.height = data.height;
    this.inventoryLocation = data.inventoryLocation;
    this.length = data.length;
    this.manufacture = data.manufacture;
    this.manufactureOther = data.manufactureOther;
    this.mapUrl = data.mapUrl;
    this.model = data.model;
    this.other = data.other;
    this.privateOrBusiness = data.privateOrBusiness;
    this.productNumber = data.productNumber;
    this.purpose = data.purpose;
    this.rearAxelWeight = data.rearAxelWeight;
    this.registerDate = data.registerDate;
    this.registerNumber = data.registerNumber;
    this.safetyTerm = data.safetyTerm;
    this.seatingCapacity = data.seatingCapacity;
    this.shape = data.shape;
    this.statusCarMileage = data.statusCarMileage;
    this.statusSafetyTerm = data.statusSafetyTerm;
    this.totalWeight = data.totalWeight;
    this.transmissionType = data.transmissionType;
    this.weight = data.weight;
    this.width = data.width;

    // EstateData
    this.buildDate = data.buildDate;
    this.buildDateOther = data.buildDateOther;
    this.buildName = data.buildName;
    this.buildOther = data.buildOther;
    this.buildRatio = data.buildRatio;
    this.buildSpace = data.buildSpace;
    this.cityPlanningArea = data.cityPlanningArea;
    this.description = data.description;
    this.estateStatus = data.estateStatus;
    this.exclusiveSpace = data.exclusiveSpace;
    this.firebreakArea = data.firebreakArea;
    this.floor = data.floor;
    this.floorAreaRatio = data.floorAreaRatio;
    this.floorSpace = data.floorSpace;
    this.geographical = data.geographical;
    this.highDistrict = data.highDistrict;
    this.landCategory = data.landCategory;
    this.landOther = data.landOther;
    this.landRights = data.landRights;
    this.landShare = data.landShare;
    this.landSpace = data.landSpace;
    this.landSpaceType = data.landSpaceType;
    this.lawRegulation = data.lawRegulation;
    this.layout = data.layout;
    this.locationGovernmentSubCategoryId = data.locationGovernmentSubCategoryId;
    this.locationText = data.locationText;
    this.locationUrl = data.locationUrl;
    this.nearestStation = data.nearestStation;
    this.neighboringRoad = data.neighboringRoad;
    this.other = data.other;
    this.privateRoad = data.privateRoad;
    this.propertyPrint = data.propertyPrint;
    this.provideEquipment = data.provideEquipment;
    this.residenceGovernmentSubCategoryId =
      data.residenceGovernmentSubCategoryId;
    this.residenceLocation = data.residenceLocation;
    this.restrictedArea = data.restrictedArea;
    this.shadeRegulation = data.shadeRegulation;
    this.sketchUrl = data.sketchUrl;
    this.structureType = data.structureType;
    this.totalHouses = data.totalHouses;
    this.type = data.estateType;

    // estateDataSummary
    this.summaryBuildDate = data.summaryBuildDate;
    this.summaryBuildSpace = data.summaryBuildSpace;
    this.summaryCarCategories = data.summaryCarCategories;
    this.summaryCarMileage = data.summaryCarMileage;
    this.summaryDisplacement = data.summaryDisplacement;
    this.summaryEstateType = data.summaryEstateType;
    this.summaryExclusiveSpace = data.summaryExclusiveSpace;
    this.summaryFirstRegisterDate = data.summaryFirstRegisterDate;
    this.summaryFloor = data.summaryFloor;
    this.summaryFloorSpace = data.summaryFloorSpace;
    this.summaryLayout = data.summaryLayout;
    this.summaryLocationText = data.summaryLocationText;
    this.summaryManufacture = data.summaryManufacture;
    this.summaryNearestStation = data.summaryNearestStation;
    this.summaryPurpose = data.summaryPurpose;
    this.summarySafetyTerm = data.summarySafetyTerm;
    this.summarySeatingCapacity = data.summarySeatingCapacity;
    this.summaryTotalHouses = data.summaryTotalHouses;
    this.summaryTransmissionType = data.summaryTransmissionType;
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class CarPropertyGet {
  constructor(data) {
    this.propertyId = data.propertyId;

    // 3. 登録事項など証明書の記載内容
    this.effectiveDate = data.effectiveDate;
    this.registerNumber = data.registerNumber;
    this.displayRegisterNumber = data.displayRegisterNumber;
    this.registerDate = data.registerDate;
    this.firstRegisterDate = data.firstRegisterDate;
    this.type = data.type;
    this.purpose = data.purpose;
    this.privateOrBusiness = data.privateOrBusiness;
    this.shape = data.shape;
    this.manufacture = data.manufacture;
    this.manufactureOther = data.manufactureOther;
    this.model = data.model;
    this.seatingCapacity = data.seatingCapacity;
    this.weight = data.weight;
    this.totalWeight = data.totalWeight;
    this.productNumber = data.productNumber;
    this.displayProductNumber = data.displayProductNumber;
    this.engineModel = data.engineModel;
    this.length = data.length;
    this.width = data.width;
    this.height = data.height;
    this.displacement = data.displacement;
    this.fuelType = data.fuelType;
    this.frontAxelWeight = data.frontAxelWeight;
    this.rearAxelWeight = data.rearAxelWeight;
    this.safetyTerm = data.safetyTerm;
    this.statusSafetyTerm = data.statusSafetyTerm;
    this.formalNumber = data.formalNumber;
    this.classificationNumber = data.classificationNumber;

    // 4. 基本情報
    this.transmissionType = data.transmissionType;
    this.handleType = data.handleType;
    this.existsCarMaintenanceSheet = data.existsCarMaintenanceSheet;
    this.carBodyColor = data.carBodyColor;
    this.carMileage = data.carMileage;
    this.statusCarMileage = data.statusCarMileage;
    this.inventoryLocation = data.inventoryLocation;
    this.mapUrl = data.mapUrl;
    this.carStatus = data.carStatus;
    this.deliveryTerms = data.deliveryTerms;
    this.other = data.other;
    this.description = data.description;
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class EstatePropertyGet {
  constructor(data) {
    this.propertyId = data.propertyId;
    this.type = data.type;

    // 3. 基本情報
    this.propertyPrint = data.propertyPrint;
    this.locationGovernmentSubCategoryId = data.locationGovernmentSubCategoryId;
    this.locationText = data.locationText;
    this.residenceGovernmentSubCategoryId =
      data.residenceGovernmentSubCategoryId;
    this.residenceLocation = data.residenceLocation;
    this.nearestStation = data.nearestStation;
    this.locationUrl = data.locationUrl;
    this.estateStatus = data.estateStatus;
    this.other = data.other;
    this.sketchUrl = data.sketchUrl;
    this.description = data.description;

    // 4. 土地の情報
    this.landSpace = data.landSpace;
    this.landSpaceType = data.landSpaceType;
    this.landRights = data.landRights;
    this.landShare = data.landShare;
    this.landCategory = data.landCategory;
    this.cityPlanningArea = data.cityPlanningArea;
    this.restrictedArea = data.restrictedArea;
    this.buildRatio = data.buildRatio;
    this.floorAreaRatio = data.floorAreaRatio;
    this.highDistrict = data.highDistrict;
    this.firebreakArea = data.firebreakArea;
    this.shadeRegulation = data.shadeRegulation;
    this.lawRegulation = data.lawRegulation;
    this.geographical = data.geographical;
    this.neighboringRoad = data.neighboringRoad;
    this.privateRoad = data.privateRoad;
    this.provideEquipment = data.provideEquipment;
    this.landOther = data.landOther;

    // 建物の情報
    this.buildName = data.buildName;
    this.buildSpace = data.buildSpace;
    this.exclusiveSpace = data.exclusiveSpace;
    this.floorSpace = data.floorSpace;
    this.structureType = data.structureType;
    this.buildDate = data.buildDate;
    this.buildDateOther = data.buildDateOther;
    this.floor = data.floor;
    this.totalHouses = data.totalHouses;
    this.layout = data.layout;
    this.buildOther = data.buildOther;
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class AddDescriptionsGet {
  constructor(data) {
    this.data = new AddDescriptionsDataGet(data.data);
    this.id = data.id;
  }

  get isValid() {
    return NotUndefined(this);
  }
}

class AddDescriptionsDataGet {
  constructor(data) {
    this.add_description = data.add_description;
    this.description_created_at = data.description_created_at;
    this.email_message = data.email_message;
    this.email_sent_at = data.email_sent_at;
    this.property_id = data.property_id;
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class BidInfoGet {
  constructor(data) {
    this.id = data.id;
    this.data = new BidInfoDataGet(data.data);
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class BidInfoDataGet {
  constructor(data) {
    this.bid_count = data.bid_count;
    this.bid_count_display = data.bid_count_display;
    this.bid_history = data.bid_history;
    this.bidded_price = data.bidded_price;
    this.bidded_user = data.bidded_user;
    this.current_top_price = data.current_top_price;
    this.current_top_user = data.current_top_user;
    this.next_winner = data.next_winner;
    this.next_winner_price = data.next_winner_price;
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class SortedPropertyIdsGet {
  constructor(data) {
    this.ids = data.ids;
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class RecommendPropertiesGet {
  constructor(data) {
    this.propertyId = data.propertyId;
    this.auctionId = data.auctionId;
    this.governmentId = data.governmentId;
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class PropertyCreate {
  constructor(data) {
    this.id = new Id(data.id);
    this.commonData = new CommonData(new CommonData(data));
    this.deposit = new Deposit(data.deposit);
    this.depositPayment = new DepositPayment(data.depositPayment);
    this.description = new Description(data.description);
    this.estimatePrice = new EstimatePrice(data.estimatePrice);
    this.videoUrl = new VideoUrl(data.videoUrl);
    this.contractedLimit = new ContractedLimit(data.contractedLimit);
    this.divisionId = new DivisionId(data.divisionId);
    this.isApprovalRequired = new IsApprovalRequired(data.isApprovalRequired);
    this.isCopied = new IsCopied(data.isCopied);
    this.isDraft = new IsDraft(data.isDraft);
    this.paymentDate = new PaymentDate(data.paymentDate);
    this.paymentLimit = new PaymentLimit(data.paymentLimit);
    this.printOrder = new PrintOrder(data.printOrder);
    this.qualifiedInvoice = new QualifiedInvoice(data.qualifiedInvoice);
    this.resolutionDate = new ResolutionDate(data.resolutionDate);
    this.addDescription = new AddDescription(data.addDescription);
    this.additionalBidEnd = new AdditionalBidEnd(data.additionalBidEnd);
    this.aplEnd = new AplEnd(data.aplEnd);
    this.aplStart = new AplStart(data.aplStart);
    this.auctionEnd = new AuctionEnd(data.auctionEnd);
    this.bidEnd = new BidEnd(data.bidEnd);
    this.bidStart = new BidStart(data.bidStart);
    this.bidders = new Bidders(data.bidders);
    this.bodyColor = new BodyColor(data.bodyColor);
    this.circumstance = new Circumstance(data.circumstance);
    this.classificationNumber = new ClassificationNumber(
      data.classificationNumber,
    );
    this.commonDescription = new CommonDescription(data.commonDescription);
    this.contractDeadline = new ContractDeadline(data.contractDeadline);
    this.deadline = new Deadline(data.deadline);
    this.displacement = new Displacement(data.displacement);
    this.divisionName = new DivisionName(data.divisionName);
    this.description = new Description(data.description);
    this.form = new Form(data.form);
    this.governmentName = new GovernmentName(data.governmentName);
    this.governmentType = new GovernmentType(data.governmentType);
    this.individualEnterprise = new IndividualEnterprise(
      data.individualEnterprise,
    );
    this.isDeliveryLock = new IsDeliveryLock(data.isDeliveryLock);
    this.location = new Location(data.location);
    this.locationMap = new LocationMap(data.locationMap);
    this.note = new Note(data.note);
    this.other = new Other(data.other);
    this.paymentDeadline = new PaymentDeadline(data.paymentDeadline);
    this.payments = new Payments(data.payments);
    this.procedureTypeEn = new ProcedureTypeEn(data.procedureTypeEn);
    this.productNumber = new ProductNumber(data.productNumber);
    this.propertyPrint = new PropertyPrint(data.propertyPrint);
    this.purpose = new Purpose(data.purpose);
    this.rearWeight = new RearWeight(data.rearWeight);
    this.registrationDate = new RegistrationDate(data.registrationDate);
    this.registrationNumber = new RegistrationNumber(data.registrationNumber);
    this.remainingLabel = new RemainingLabel(data.remainingLabel);
    this.residenceLocation = new ResidenceLocation(data.residenceLocation);
    this.restrictedArea = new RestrictedArea(data.restrictedArea);
    this.safetyTerm = new SafetyTerm(data.safetyTerm);
    this.seatingCapacity = new SeatingCapacity(data.seatingCapacity);
    this.shape = new Shape(data.shape);
    this.statusEn = new StatusEn(data.statusEn);
    this.structure = new Structure(data.structure);
    this.tags = new Tags(data.tags);
    this.totalWeight = new TotalWeight(data.totalWeight);
    this.transmissionType = new TransmissionType(data.transmissionType);
    this.weight = new Weight(data.weight);
    this.width = new Width(data.width);
    this.inquiryAccept = new InquiryAccept(data.inquiryAccept);
    this.inquiryEmail = new InquiryEmail(data.inquiryEmail);
    this.inquiryExtension = new InquiryExtension(data.inquiryExtension);
    this.inquiryName = new InquiryName(data.inquiryName);
    this.inquiryNote = new InquiryNote(data.inquiryNote);
    this.inquiryOffice = new InquiryOffice(data.inquiryOffice);
    this.inquiryTel = new InquiryTel(data.inquiryTel);
    this.contactAccept = new ContactAccept(data.contactAccept);
    this.contactEmail = new ContactEmail(data.contactEmail);
    this.contactExtension = new ContactExtension(data.contactExtension);
    this.contactName = new ContactName(data.contactName);
    this.contactOffice = new ContactOffice(data.contactOffice);
    this.contactTel = new ContactTel(data.contactTel);
    this.contractedLimit = new ContractedLimit(data.contractedLimit);
    this.carBodyColor = new CarBodyColor(data.carBodyColor);
    this.carCondition = new CarCondition(data.carCondition);
    this.carMileage = new CarMileage(data.carMileage);
    this.carDescription = new CarDescription(data.carDescription);
    this.carType = new CarType(data.carType);
    this.classificationNumber = new ClassificationNumber(
      data.classificationNumber,
    );
    this.deliveryTerms = new DeliveryTerms(data.deliveryTerms);
    this.description = new Description(data.description);
    this.displacement = new Displacement(data.displacement);
    this.displayProductNumber = new DisplayProductNumber(
      data.displayProductNumber,
    );
    this.displayRegisterNumber = new DisplayRegisterNumber(
      data.displayRegisterNumber,
    );
    this.effectiveDate = new EffectiveDate(data.effectiveDate);
    this.engineType = new EngineType(data.engineType);
    this.existsCarMaintenanceSheet = new ExistsCarMaintenanceSheet(
      data.existsCarMaintenanceSheet,
    );
    this.firstRegisterDate = new FirstRegisterDate(data.firstRegisterDate);
    this.formalNumber = new FormalNumber(data.formalNumber);
    this.frontWeight = new FrontWeight(data.frontWeight);
    this.fuelType = new FuelType(data.fuelType);
    this.handle = new Handle(data.handle);
    this.height = new Height(data.height);
    this.inventoryLocation = new InventoryLocation(data.inventoryLocation);
    this.length = new Length(data.length);
    this.manufacture = new Manufacture(data.manufacture);
    this.manufactureOther = new ManufactureOther(data.manufactureOther);
    this.mapUrl = new MapUrl(data.mapUrl);
    this.model = new Model(data.model);
    this.other = new Other(data.other);
    this.privateOrBusiness = new PrivateOrBusiness(data.privateOrBusiness);
    this.productNumber = new ProductNumber(data.productNumber);
    this.purpose = new Purpose(data.purpose);
    this.rearAxelWeight = new RearAxelWeight(data.rearAxelWeight);
    this.registerDate = new RegisterDate(data.registerDate);
    this.registerNumber = new RegisterNumber(data.registerNumber);
    this.safetyTerm = new SafetyTerm(data.safetyTerm);
    this.seatingCapacity = new SeatingCapacity(data.seatingCapacity);
    this.shape = new Shape(data.shape);
    this.statusCarMileage = new StatusCarMileage(data.statusCarMileage);
    this.statusSafetyTerm = new StatusSafetyTerm(data.statusSafetyTerm);
    this.totalWeight = new TotalWeight(data.totalWeight);
    this.transmissionType = new TransmissionType(data.transmissionType);
    this.weight = new Weight(data.weight);
    this.width = new Width(data.width);
    this.buildDate = new BuildDate(data.buildDate);
    this.buildDateOther = new BuildDateOther(data.buildDateOther);
    this.buildName = new BuildName(data.buildName);
    this.buildOther = new BuildOther(data.buildOther);
    this.buildRatio = new BuildRatio(data.buildRatio);
    this.buildSpace = new BuildSpace(data.buildSpace);
    this.cityPlanningArea = new CityPlanningArea(data.cityPlanningArea);
    this.description = new Description(data.description);
    this.estateStatus = new EstateStatus(data.estateStatus);
    this.exclusiveSpace = new ExclusiveSpace(data.exclusiveSpace);
    this.firebreakArea = new FirebreakArea(data.firebreakArea);
    this.floor = new Floor(data.floor);
    this.floorAreaRatio = new FloorAreaRatio(data.floorAreaRatio);
    this.floorSpace = new FloorSpace(data.floorSpace);
    this.geographical = new Geographical(data.geographical);
    this.highDistrict = new HighDistrict(data.highDistrict);
    this.landCategory = new LandCategory(data.landCategory);
    this.landOther = new LandOther(data.landOther);
    this.landRights = new LandRights(data.landRights);
    this.landShare = new LandShare(data.landShare);
    this.landSpace = new LandSpace(data.landSpace);
    this.landSpaceType = new LandSpaceType(data.landSpaceType);
    this.lawRegulation = new LawRegulation(data.lawRegulation);
    this.layout = new Layout(data.layout);
    this.locationGovernmentSubCategoryId = new LocationGovernmentSubCategoryId(
      data.locationGovernmentSubCategoryId,
    );
    this.locationText = new LocationText(data.locationText);
    this.locationUrl = new LocationUrl(data.locationUrl);
    this.nearestStation = new NearestStation(data.nearestStation);
    this.neighboringRoad = new NeighboringRoad(data.neighboringRoad);
    this.other = new Other(data.other);
    this.privateRoad = new PrivateRoad(data.privateRoad);
    this.propertyPrint = new PropertyPrint(data.propertyPrint);
    this.provideEquipment = new ProvideEquipment(data.provideEquipment);
    this.residenceGovernmentSubCategoryId =
      new ResidenceGovernmentSubCategoryId(
        data.residenceGovernmentSubCategoryId,
      );
    this.residenceLocation = new ResidenceLocation(data.residenceLocation);
    this.restrictedArea = new RestrictedArea(data.restrictedArea);
    this.shadeRegulation = new ShadeRegulation(data.shadeRegulation);
    this.sketchUrl = new SketchUrl(data.sketchUrl);
    this.structureType = new StructureType(data.structureType);
    this.totalHouses = new TotalHouses(data.totalHouses);
    this.type = new Type(data.estateType);
    this.summaryBuildDate = new SummaryBuildDate(data.summaryBuildDate);
    this.summaryBuildSpace = new SummaryBuildSpace(data.summaryBuildSpace);
    this.summaryCarCategories = new SummaryCarCategories(
      data.summaryCarCategories,
    );
    this.summaryCarMileage = new SummaryCarMileage(data.summaryCarMileage);
    this.summaryDisplacement = new SummaryDisplacement(
      data.summaryDisplacement,
    );
    this.summaryEstateType = new SummaryEstateType(data.summaryEstateType);
    this.summaryExclusiveSpace = new SummaryExclusiveSpace(
      data.summaryExclusiveSpace,
    );
    this.summaryFirstRegisterDate = new SummaryFirstRegisterDate(
      data.summaryFirstRegisterDate,
    );
    this.summaryFloor = new SummaryFloor(data.summaryFloor);
    this.summaryFloorSpace = new SummaryFloorSpace(data.summaryFloorSpace);
    this.summaryLayout = new SummaryLayout(data.summaryLayout);
    this.summaryLocationText = new SummaryLocationText(
      data.summaryLocationText,
    );
    this.summaryManufacture = new SummaryManufacture(data.summaryManufacture);
    this.summaryNearestStation = new SummaryNearestStation(
      data.summaryNearestStation,
    );
    this.summaryPurpose = new SummaryPurpose(data.summaryPurpose);
    this.summarySafetyTerm = new SummarySafetyTerm(data.summarySafetyTerm);
    this.summarySeatingCapacity = new SummarySeatingCapacity(
      data.summarySeatingCapacity,
    );
    this.summaryTotalHouses = new SummaryTotalHouses(data.summaryTotalHouses);
    this.summaryTransmissionType = new SummaryTransmissionType(
      data.summaryTransmissionType,
    );
  }

  get isValid() {
    return (
      this.id.isValid &&
      this.commonData.isValid &&
      this.deposit.isValid &&
      this.depositPayment.isValid &&
      this.description.isValid &&
      this.estimatePrice.isValid &&
      this.videoUrl.isValid &&
      this.contractedLimit.isValid &&
      this.divisionId.isValid &&
      this.isApprovalRequired.isValid &&
      this.isCopied.isValid &&
      this.isDraft.isValid &&
      this.paymentDate.isValid &&
      this.paymentLimit.isValid &&
      this.printOrder.isValid &&
      this.qualifiedInvoice.isValid &&
      this.resolutionDate.isValid &&
      this.addDescription.isValid &&
      this.additionalBidEnd.isValid &&
      this.aplEnd.isValid &&
      this.aplStart.isValid &&
      this.auctionEnd.isValid &&
      this.bidEnd.isValid &&
      this.bidStart.isValid &&
      this.bidders.isValid &&
      this.bodyColor.isValid &&
      this.circumstance.isValid &&
      this.classificationNumber.isValid &&
      this.commonDescription.isValid &&
      this.contractDeadline.isValid &&
      this.deadline.isValid &&
      this.displacement.isValid &&
      this.divisionName.isValid &&
      this.description.isValid &&
      this.form.isValid &&
      this.governmentName.isValid &&
      this.governmentType.isValid &&
      this.individualEnterprise.isValid &&
      this.isDeliveryLock.isValid &&
      this.location.isValid &&
      this.locationMap.isValid &&
      this.note.isValid &&
      this.other.isValid &&
      this.paymentDeadline.isValid &&
      this.payments.isValid &&
      this.procedureTypeEn.isValid &&
      this.productNumber.isValid &&
      this.propertyPrint.isValid &&
      this.purpose.isValid &&
      this.rearWeight.isValid &&
      this.registrationDate.isValid &&
      this.registrationNumber.isValid &&
      this.remainingLabel.isValid &&
      this.residenceLocation.isValid &&
      this.restrictedArea.isValid &&
      this.safetyTerm.isValid &&
      this.seatingCapacity.isValid &&
      this.shape.isValid &&
      this.statusEn.isValid &&
      this.structure.isValid &&
      this.tags.isValid &&
      this.totalWeight.isValid &&
      this.transmissionType.isValid &&
      this.weight.isValid &&
      this.width.isValid &&
      this.inquiryAccept.isValid &&
      this.inquiryEmail.isValid &&
      this.inquiryExtension.isValid &&
      this.inquiryName.isValid &&
      this.inquiryNote.isValid &&
      this.inquiryOffice.isValid &&
      this.inquiryTel.isValid &&
      this.contactAccept.isValid &&
      this.contactEmail.isValid &&
      this.contactExtension.isValid &&
      this.contactName.isValid &&
      this.contactOffice.isValid &&
      this.contactTel.isValid &&
      this.contractedLimit.isValid &&
      this.carBodyColor.isValid &&
      this.carCondition.isValid &&
      this.carMileage.isValid &&
      this.carDescription.isValid &&
      this.carType.isValid &&
      this.classificationNumber.isValid &&
      this.deliveryTerms.isValid &&
      this.description.isValid &&
      this.displacement.isValid &&
      this.displayProductNumber.isValid &&
      this.displayRegisterNumber.isValid &&
      this.effectiveDate.isValid &&
      this.engineType.isValid &&
      this.existsCarMaintenanceSheet.isValid &&
      this.firstRegisterDate.isValid &&
      this.formalNumber.isValid &&
      this.frontWeight.isValid &&
      this.fuelType.isValid &&
      this.handle.isValid &&
      this.height.isValid &&
      this.inventoryLocation.isValid &&
      this.length.isValid &&
      this.manufacture.isValid &&
      this.manufactureOther.isValid &&
      this.mapUrl.isValid &&
      this.model.isValid &&
      this.other.isValid &&
      this.privateOrBusiness.isValid &&
      this.productNumber.isValid &&
      this.purpose.isValid &&
      this.rearAxelWeight.isValid &&
      this.registerDate.isValid &&
      this.registerNumber.isValid &&
      this.safetyTerm.isValid &&
      this.seatingCapacity.isValid &&
      this.shape.isValid &&
      this.statusCarMileage.isValid &&
      this.statusSafetyTerm.isValid &&
      this.totalWeight.isValid &&
      this.transmissionType.isValid &&
      this.weight.isValid &&
      this.width.isValid &&
      this.buildDate.isValid &&
      this.buildDateOther.isValid &&
      this.buildName.isValid &&
      this.buildOther.isValid &&
      this.buildRatio.isValid &&
      this.buildSpace.isValid &&
      this.cityPlanningArea.isValid &&
      this.description.isValid &&
      this.estateStatus.isValid &&
      this.exclusiveSpace.isValid &&
      this.firebreakArea.isValid &&
      this.floor.isValid &&
      this.floorAreaRatio.isValid &&
      this.floorSpace.isValid &&
      this.geographical.isValid &&
      this.highDistrict.isValid &&
      this.landCategory.isValid &&
      this.landOther.isValid &&
      this.landRights.isValid &&
      this.landShare.isValid &&
      this.landSpace.isValid &&
      this.landSpaceType.isValid &&
      this.lawRegulation.isValid &&
      this.layout.isValid &&
      this.locationGovernmentSubCategoryId.isValid &&
      this.locationText.isValid &&
      this.locationUrl.isValid &&
      this.nearestStation.isValid &&
      this.neighboringRoad.isValid &&
      this.other.isValid &&
      this.privateRoad.isValid &&
      this.propertyPrint.isValid &&
      this.provideEquipment.isValid &&
      this.residenceGovernmentSubCategoryId.isValid &&
      this.residenceLocation.isValid &&
      this.restrictedArea.isValid &&
      this.shadeRegulation.isValid &&
      this.sketchUrl.isValid &&
      this.structureType.isValid &&
      this.totalHouses.isValid &&
      this.type.isValid &&
      this.summaryBuildDate.isValid &&
      this.summaryBuildSpace.isValid &&
      this.summaryCarCategories.isValid &&
      this.summaryCarMileage.isValid &&
      this.summaryDisplacement.isValid &&
      this.summaryEstateType.isValid &&
      this.summaryExclusiveSpace.isValid &&
      this.summaryFirstRegisterDate.isValid &&
      this.summaryFloor.isValid &&
      this.summaryFloorSpace.isValid &&
      this.summaryLayout.isValid &&
      this.summaryLocationText.isValid &&
      this.summaryManufacture.isValid &&
      this.summaryNearestStation.isValid &&
      this.summaryPurpose.isValid &&
      this.summarySafetyTerm.isValid &&
      this.summarySeatingCapacity.isValid &&
      this.summaryTotalHouses.isValid &&
      this.summaryTransmissionType.isValid
    );
  }
}

export class CarPropertyCreate {
  constructor(data) {
    this.car_body_color = new CarBodyColor(data.car_body_color);
    this.car_mileage = new CarMileage(data.car_mileage);
    this.car_status = new CarStatus(data.car_status);
    this.classification_number = new ClassificationNumber(
      data.classification_number,
    );
    this.delivery_terms = new DeliveryTerms(data.delivery_terms);
    this.description = new Description(data.description);
    this.displacement = new Displacement(data.displacement);
    this.display_product_number = new DisplayProductNumber(
      data.display_product_number,
    );
    this.display_register_number = new DisplayRegisterNumber(
      data.display_register_number,
    );
    this.effective_date = new EffectiveDate(data.effective_date);
    this.engine_model = new EngineModel(data.engine_model);
    this.exists_car_maintenance_sheet = new ExistsCarMaintenanceSheet(
      data.exists_car_maintenance_sheet,
    );
    this.first_register_date = new FirstRegisterDate(data.first_register_date);
    this.formal_number = new FormalNumber(data.formal_number);
    this.front_axel_weight = new FrontAxelWeight(data.front_axel_weight);
    this.fuel_type = new FuelType(data.fuel_type);
    this.handle_type = new HandleType(data.handle_type);
    this.height = new Height(data.height);
    this.inventory_location = new InventoryLocation(data.inventory_location);
    this.length = new Length(data.length);
    this.manufacture = new Manufacture(data.manufacture);
    this.manufacture_other = new ManufactureOther(data.manufacture_other);
    this.map_url = new MapUrl(data.map_url);
    this.model = new Model(data.model);
    this.other = new Other(data.other);
    this.private_or_business = new PrivateOrBusiness(data.private_or_business);
    this.product_number = new ProductNumber(data.product_number);
    this.purpose = new Purpose(data.purpose);
    this.rear_axel_weight = new RearAxelWeight(data.rear_axel_weight);
    this.register_date = new RegisterDate(data.register_date);
    this.register_number = new RegisterNumber(data.register_number);
    this.safety_term = new SafetyTerm(data.safety_term);
    this.seating_capacity = new SeatingCapacity(data.seating_capacity);
    this.shape = new Shape(data.shape);
    this.status_car_mileage = new StatusCarMileage(data.status_car_mileage);
    this.status_safety_term = new StatusSafetyTerm(data.status_safety_term);
    this.total_weight = new TotalWeight(data.total_weight);
    this.transmission_type = new TransmissionType(data.transmission_type);
    this.type = new Type(data.type);
    this.weight = new Weight(data.weight);
    this.width = new Width(data.width);
  }

  get isValid() {
    return (
      this.car_body_color.isValid &&
      this.car_mileage.isValid &&
      this.car_status.isValid &&
      this.classification_number.isValid &&
      this.delivery_terms.isValid &&
      this.description.isValid &&
      this.displacement.isValid &&
      this.display_product_number.isValid &&
      this.display_register_number.isValid &&
      this.effective_date.isValid &&
      this.engine_model.isValid &&
      this.exists_car_maintenance_sheet.isValid &&
      this.first_register_date.isValid &&
      this.formal_number.isValid &&
      this.front_axel_weight.isValid &&
      this.fuel_type.isValid &&
      this.handle_type.isValid &&
      this.height.isValid &&
      this.inventory_location.isValid &&
      this.length.isValid &&
      this.manufacture.isValid &&
      this.manufacture_other.isValid &&
      this.map_url.isValid &&
      this.model.isValid &&
      this.other.isValid &&
      this.private_or_business.isValid &&
      this.product_number.isValid &&
      this.purpose.isValid &&
      this.rear_axel_weight.isValid &&
      this.register_date.isValid &&
      this.register_number.isValid &&
      this.safety_term.isValid &&
      this.seating_capacity.isValid &&
      this.shape.isValid &&
      this.status_car_mileage.isValid &&
      this.status_safety_term.isValid &&
      this.total_weight.isValid &&
      this.transmission_type.isValid &&
      this.type.isValid &&
      this.weight.isValid &&
      this.width.isValid
    );
  }
}

export class EstatePropertyCreate {
  constructor(data) {
    this.build_date = new BuildDate(data.build_date);
    this.build_date_other = new BuildDateOther(data.build_date_other);
    this.build_name = new BuildName(data.build_name);
    this.build_other = new BuildOther(data.build_other);
    this.build_ratio = new BuildRatio(data.build_ratio);
    this.build_space = new BuildSpace(data.build_space);
    this.city_planning_area = new CityPlanningArea(data.city_planning_area);
    this.description = new Description(data.description);
    this.estate_status = new EstateStatus(data.estate_status);
    this.exclusive_space = new ExclusiveSpace(data.exclusive_space);
    this.firebreak_area = new FirebreakArea(data.firebreak_area);
    this.floor = new Floor(data.floor);
    this.floor_area_ratio = new FloorAreaRatio(data.floor_area_ratio);
    this.floor_space = new FloorSpace(data.floor_space);
    this.geographical = new Geographical(data.geographical);
    this.high_district = new HighDistrict(data.high_district);
    this.land_category = new LandCategory(data.land_category);
    this.land_other = new LandOther(data.land_other);
    this.land_rights = new LandRights(data.land_rights);
    this.land_share = new LandShare(data.land_share);
    this.land_space = new LandSpace(data.land_space);
    this.land_space_type = new LandSpaceType(data.land_space_type);
    this.law_regulation = new LawRegulation(data.law_regulation);
    this.layout = new Layout(data.layout);
    this.location_government_sub_category_id =
      new LocationGovernmentSubCategoryId(
        data.location_government_sub_category_id,
      );
    this.location_text = new LocationText(data.location_text);
    this.location_url = new LocationUrl(data.location_url);
    this.nearest_station = new NearestStation(data.nearest_station);
    this.neighboring_road = new NeighboringRoad(data.neighboring_road);
    this.other = new Other(data.other);
    this.private_road = new PrivateRoad(data.private_road);
    this.property_print = new PropertyPrint(data.property_print);
    this.provide_equipment = new ProvideEquipment(data.provide_equipment);
    this.residence_government_sub_category_id =
      new ResidenceGovernmentSubCategoryId(
        data.residence_government_sub_category_id,
      );
    this.residence_location = new ResidenceLocation(data.residence_location);
    this.restricted_area = new RestrictedArea(data.restricted_area);
    this.shade_regulation = new ShadeRegulation(data.shade_regulation);
    this.sketch_url = new SketchUrl(data.sketch_url);
    this.structure_type = new StructureType(data.structure_type);
    this.total_houses = new TotalHouses(data.total_houses);
    this.type = new Type(data.type);
  }

  get isValid() {
    return (
      this.build_date.isValid &&
      this.build_date_other.isValid &&
      this.build_name.isValid &&
      this.build_other.isValid &&
      this.build_ratio.isValid &&
      this.build_space.isValid &&
      this.city_planning_area.isValid &&
      this.description.isValid &&
      this.estate_status.isValid &&
      this.exclusive_space.isValid &&
      this.firebreak_area.isValid &&
      this.floor.isValid &&
      this.floor_area_ratio.isValid &&
      this.floor_space.isValid &&
      this.geographical.isValid &&
      this.high_district.isValid &&
      this.land_category.isValid &&
      this.land_other.isValid &&
      this.land_rights.isValid &&
      this.land_share.isValid &&
      this.land_space.isValid &&
      this.land_space_type.isValid &&
      this.law_regulation.isValid &&
      this.layout.isValid &&
      this.location_government_sub_category_id.isValid &&
      this.location_text.isValid &&
      this.location_url.isValid &&
      this.nearest_station.isValid &&
      this.neighboring_road.isValid &&
      this.other.isValid &&
      this.private_road.isValid &&
      this.property_print.isValid &&
      this.provide_equipment.isValid &&
      this.residence_government_sub_category_id.isValid &&
      this.residence_location.isValid &&
      this.restricted_area.isValid &&
      this.shade_regulation.isValid &&
      this.sketch_url.isValid &&
      this.structure_type.isValid &&
      this.total_houses.isValid &&
      this.type.isValid
    );
  }
}

export class AddDescriptionsCreate {
  constructor(data) {
    this.data = new AddDescriptionsDataCreate(data.data);
    this.id = data.id;
  }
}

class AddDescriptionsDataCreate {
  constructor(data) {
    this.add_description = new AddDescription(data.add_description);
    this.description_created_at = new DescriptionCreatedAt(
      data.description_created_at,
    );
    this.email_message = new EmailMessage(data.email_message);
    this.email_sent_at = new EmailSentAt(data.email_sent_at);
    this.property_id = new PropertyId(data.property_id);
  }

  get isValid() {
    return (
      this.add_description.isValid &&
      this.description_created_at.isValid &&
      this.email_message.isValid &&
      this.email_sent_at.isValid &&
      this.property_id.isValid
    );
  }
}

export class BidInfoCreate {
  constructor(data) {
    this.id = data.id;
    this.data = new BidInfoDataCreate(data.data);
  }
}

export class BidInfoDataCreate {
  constructor(data) {
    this.bid_count = new BidCount(data.bid_count);
    this.bid_count_display = new BidCountDisplay(data.bid_count_display);
    this.bid_history = new BidHistory(data.bid_history);
    this.bidded_price = new BiddedPrice(data.bidded_price);
    this.bidded_user = new BiddedUser(data.bidded_user);
    this.current_top_price = new CurrentTopPrice(data.current_top_price);
    this.current_top_user = new CurrentTopUser(data.current_top_user);
    this.next_winner = new NextWinner(data.next_winner);
    this.next_winner_price = new NextWinnerPrice(data.next_winner_price);
  }

  get isValid() {
    return (
      this.bid_count.isValid &&
      this.bid_count_display.isValid &&
      this.bid_history.isValid &&
      this.bidded_price.isValid &&
      this.bidded_user.isValid &&
      this.current_top_price.isValid &&
      this.current_top_user.isValid &&
      this.next_winner.isValid &&
      this.next_winner_price.isValid
    );
  }
}

export class SortedPropertyIdsCreate {
  constructor(data) {
    this.ids = data.ids;
  }
}

export class RecommendPropertiesCreate {
  constructor(data) {
    this.property_id = data.property_id;
    this.auction_id = data.auction_id;
  }
}

export class PropertyLockRemove {
  constructor(data) {
    this.id = data.id;
    this.isLock = data.isLock;
  }
}

export class UpdateFixResolutionDate {
  constructor(data) {
    this.fix_resolution_date = data.fix_resolution_date;
  }
}
