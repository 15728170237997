import Tab from 'interfaces/components/common/Tab';
import { useNavigate } from 'react-router-dom';

const PropertySwitcher = ({ auctionId, governmentId, activeIndex }) => {
  const navigation = useNavigate();

  const labels = [
    {
      index: 0,
      label: '物件一覧',
      handleClick: () =>
        navigation(`/kmanager/auction/${auctionId}/${governmentId}/property`),
    },
    {
      index: 1,
      label: '保証金確認',
      handleClick: () =>
        navigation(`/kmanager/auction/${auctionId}/${governmentId}/deposit`),
    },
    {
      index: 2,
      label: '請求管理確認',
      handleClick: () =>
        navigation(`/kmanager/auction/${auctionId}/${governmentId}/payment`),
    },
  ];

  return (
    <div className="kmanager-tab">
      <div className="kmanager-tab_wrap">
        <div className="kmanager-tab_content">
          {labels.map((label, index) => (
            <Tab
              key={index}
              label={label.label}
              isActive={label.index === activeIndex}
              setActiveTab={label.handleClick}
              className={`kmanager-tab_menu ${index === activeIndex ? 'kmanager-tab_menu_active' : ''}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default PropertySwitcher;
