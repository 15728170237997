import { ReactComponent as MenuIcon } from "interfaces/assets/svg/menu-icon.svg";

import IconToggleButton from "./IconToggleButton.jsx";
import TextLink from "./TextLink.jsx";
import 'interfaces/css/common/header.css'

const Header = ({ leftMenuHandler, rightMenuHandler }) => {
  return (
        <header data-testid="kmanager-header" className="kmanager-header">
            <div className="kmanager-header-area align-center">
                <div className='kmanager-header-area-item'>
                    <IconToggleButton svg={<MenuIcon title='left-menu-icon'/>} onClick={leftMenuHandler}/>
                </div>
                <div className='kmanager-header-area-item kmanager-header-text-link-padding'>
                    <TextLink text='官公庁オークション&emsp;運営管理ツール' link='/kmanager'/>
                </div>
                <div className='kmanager-header-area-item'>
                    <IconToggleButton svg={<MenuIcon title='right-menu-icon'/>} onClick={rightMenuHandler}/>
                </div>
            </div>
        </header>
  );
};
export default Header;