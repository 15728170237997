import { convertEmptyCharacters } from 'shared/utils/helper/convert';
import styled from 'styled-components';

import CautionDescription from './CautionDescription.jsx';
import Label from './Label.jsx';
import ValidationMessage from './ValidationMessage';

const Input = styled.input`
  border: ${(props) =>
    props.$validation
      ? '2px solid #ff5252!important'
      : '1px solid rgba(0, 0, 0, .42)'};
`;

const FormTelWithCaution = ({
  telData,
  setTelData,
  setTel1Data,
  setTel2Data,
  setTel3Data,
  hasExtension = false,
  extensionData,
  setExtensionData,
  label,
  caution_description,
  disabled = false,
  invalidMessage = 'TRUE',
  invalid1Message = 'TRUE',
  invalid2Message = 'TRUE',
  invalid3Message = 'TRUE',
  extensionMessage = 'TRUE',
  required = false,
}) => {
  const tel1ChangeHandler = (e) => {
    telData[e.target.name] = e.target.value;
    setTel1Data(e.target.value);
    if (telData.telNum1 && telData.telNum2 && telData.telNum3) {
      setTelData(telData);
    }
  };

  const tel2ChangeHandler = (e) => {
    telData[e.target.name] = e.target.value;
    setTel2Data(e.target.value);
    if (telData.telNum1 && telData.telNum2 && telData.telNum3) {
      setTelData(telData);
    }
  };

  const tel3ChangeHandler = (e) => {
    telData[e.target.name] = e.target.value;
    setTel3Data(e.target.value);
    if (telData.telNum1 && telData.telNum2 && telData.telNum3) {
      setTelData(telData);
    }
  };

  const extensionChangeHandler = (e) => {
    setExtensionData(e);
  };

  return (
    <div
      data-testid="procedure-new-entry-form-tell"
      className="property-info-general-form-input-tell property-info-general-form-table-row">
      <Label label={label} required={required} />
      <div className="property-info-general-form-table-cell">
        <div className="property-info-general-form-input-tell-inline">
          <Input
            $validation={
              (invalid1Message !== 'TRUE' && invalid1Message !== null) ||
              (invalidMessage !== 'TRUE' && invalidMessage !== null)
            }
            type="text"
            name="telNum1"
            className={
              'property-info-general-form-table-cell-1 new-style_navi_form-text'
            }
            value={convertEmptyCharacters(telData?.telNum1)}
            onChange={(e) => tel1ChangeHandler(e)}
            disabled={disabled}
          />
          <div className="ml-2">-</div>
          <Input
            $validation={
              (invalid2Message !== 'TRUE' && invalid2Message !== null) ||
              (invalidMessage !== 'TRUE' && invalidMessage !== null)
            }
            type="text"
            name="telNum2"
            className={
              'property-info-general-form-table-cell-1 new-style_navi_form-text'
            }
            value={convertEmptyCharacters(telData?.telNum2)}
            onChange={(e) => tel2ChangeHandler(e)}
            disabled={disabled}
          />
          <div className="ml-2">-</div>
          <Input
            $validation={
              (invalid3Message !== 'TRUE' && invalid3Message !== null) ||
              (invalidMessage !== 'TRUE' && invalidMessage !== null)
            }
            type="text"
            name="telNum3"
            className={
              'property-info-general-form-table-cell-1 new-style_navi_form-text'
            }
            value={convertEmptyCharacters(telData?.telNum3)}
            onChange={(e) => tel3ChangeHandler(e)}
            disabled={disabled}
          />
          {hasExtension && (
            <>
              <div className="ml-6">内線</div>
              <Input
                $validation={
                  extensionMessage !== 'TRUE' && extensionMessage !== null
                }
                value={convertEmptyCharacters(extensionData)}
                type="text"
                className={
                  'property-info-general-form-table-cell-1 new-style_navi_form-text'
                }
                onChange={extensionChangeHandler}
                disabled={disabled}
              />
            </>
          )}
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
        <ValidationMessage invalidMessage={invalid1Message} />
        <ValidationMessage invalidMessage={invalid2Message} />
        <ValidationMessage invalidMessage={invalid3Message} />
        <ValidationMessage invalidMessage={extensionMessage} />
        <CautionDescription data={caution_description} />
      </div>
    </div>
  );
};
export default FormTelWithCaution;
