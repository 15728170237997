import Button from 'interfaces/components/common/Button/Button';
import Modal2 from 'interfaces/components/common/Modal/Modal2';
import ShippingInfos from 'interfaces/components/Shipping/ConfirmShippingInfo/ShippingInfos';
import { initFormattedInfo } from 'interfaces/components/Shipping/ShippingStepComponent';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'shared/hooks/useAuth';
import useModalControl from 'shared/hooks/useModalControl';
//import { getPreRegistrationInfo } from '../../../read_storage/application-info-input';
//import { useError } from 'shared/hooks/useError';
import './css/read-delivery-registration-info.css';

const ReadDeliveryRegistrationInfo = ({ property }) => {
  const { userId } = useAuth();
  //const { setError } = useError();
  const { isOpen, handleOpen, handleClose } = useModalControl();
  const [shippingInfo, setShippingInfo] = useState(initFormattedInfo);
  const [receiverName, setReceiverName] = useState('');

  //const fetch = async () => {
  //  try {
  //    const preRegistrationInfos = (await getPreRegistrationInfo(userId))[0];
  //    const preRegistrationInfo = preRegistrationInfos.find(
  //      (info) =>
  //        info.type ===
  //        (property?.receiverName === 'DELEGATOR' ? 'CLIENT' : 'APPLICANT'),
  //    )?.infos;
  //    setReceiverName(
  //      `${preRegistrationInfo.familyName} ${preRegistrationInfo.firstName}`,
  //    );
  //  } catch (e) {
  //    setError(e);
  //  }
  //};

  useEffect(() => {
    //  fetch().then();
    setReceiverName('-- mock data --');
  }, [property, userId]);

  useEffect(() => {
    setShippingInfo({
      carrierNameList: property?.carrierNameList,
      carrierName: property?.carrierName,
      carrierTel: property?.carrierTel,
      arrangement: property?.arrangement,
      packing: property?.packing,
      receiver: property?.receiver,
      receiverName: receiverName,
      receiverRole: property?.receiverName, // Nameで正しい
      zipCode: property?.zipCode,
      receiverGovernmentSubCategoryId:
        property?.receiverGovernmentSubCategoryId,
      receiverAddress: property?.receiverAddress,
      receiverTel: property?.receiverTel,
      deliveryReason: property?.deliveryReason,
      isPaid: property?.isPaid,
      paymentScheduledDate: property?.paymentScheduledDate,
      deliverySchedule: property?.deliveryDate ? 'SPECIFY' : 'NO_SPECIFY',
      deliveryDate: property?.deliveryDate,
      deliveryTime: property?.deliveryTime,
      deliveryText: property?.deliveryText,
    });
  }, [property?.receiverName]);

  return (
    <div data-testid="read-delivery-registration-info">
      <p data-testid="delivery-registration-info-caption">
        <span>この物件は引渡方法を登録済みです。 </span>
        <Link onClick={handleOpen} data-testid="read-registration-info-link">
          登録内容の確認はこちら
        </Link>
      </p>
      <Modal2 isOpen={isOpen} handleClose={handleClose}>
        <div className="shipping-info-modal">
          <div className="shipping-info-modal-contents-body">
            <ShippingInfos shippingInfo={shippingInfo} />
          </div>
          <div className="close-button-wrap">
            <Button
              text="閉じる"
              height="44px"
              width="100px"
              onClick={handleClose}
            />
          </div>
        </div>
      </Modal2>
    </div>
  );
};

export default ReadDeliveryRegistrationInfo;
