import { findById as getDivision } from 'domain/Division/services/Division.js';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from 'shared/utils/helper/authState.js';
import { NAVI } from 'shared/utils/helper/constants.js';

import { organizationFormData } from '../../../components/Division/navi/OrganizationForm/OrganizationFormData';
import { OrganizationEditContext } from '../entities/OrganizationEditContext.js';

const OrganizationEditProvider = ({ type, children }) => {
  const { localStorageProcedureType, localStorageGovernmentType } =
    useLocalStorage();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [procedureType, setProcedureType] = useState('');
  const [division, setDivision] = useState('');
  const formValue = organizationFormData();
  const [governmentType, setGovernmentType] = useState(undefined);

  let { id } = useParams();

  if (type === NAVI) {
    useEffect(() => {
      getDivision(type, id).then((result) => {
        setDivision(result);
      });
      setProcedureType(localStorageProcedureType);
    }, [id, localStorageProcedureType]);
    useEffect(() => {
      setGovernmentType(localStorageGovernmentType);
    }, [localStorageGovernmentType]);
  }

  return (
    <OrganizationEditContext.Provider
      value={{
        authState,
        procedureType,
        formValue,
        id,
        division,
        governmentType,
      }}>
      {children}
    </OrganizationEditContext.Provider>
  );
};

export default OrganizationEditProvider;
