import LeftSideMenuPanelList from "./LeftSideMenuList.jsx";
import "interfaces/css/common/kmanager-left-side-menu.css"

const LeftSideMenu = ({ isOpen }) => {
  return (
    <div data-testid="kmanager-left-side-menu" className={`kmanager-left-side-menu ${isOpen && 'active'}`}>
      <LeftSideMenuPanelList/>
    </div>
  );
};
export default LeftSideMenu;