import { useNaviUserRegisterInputs } from 'interfaces/components/User/Create/useNaviUserRegisterInputs';

// validation
import { NaviUserRegisterForm } from '../NaviUserRegisterForm';

export function validateInputsNaviUerRegisterForm(isEdit) {
  const formInputs = useNaviUserRegisterInputs();
  const validate = NaviUserRegisterForm(isEdit);

  function handleChangeFamilyName(e) {
    validate.setters.handleFamilyNameValid(e);
    formInputs.setters.setFamilyName(e.target.value);
  }

  function handleChangeFamilyNameKana(e) {
    validate.setters.handleFamilyNameKanaValid(e);
    formInputs.setters.setFamilyNameKana(e.target.value);
  }

  function handleChangeFirstName(e) {
    validate.setters.handleFirstNameValid(e);
    formInputs.setters.setFirstName(e.target.value);
  }

  function handleChangeFirstNameKana(e) {
    validate.setters.handleFirstNameKanaValid(e);
    formInputs.setters.setFirstNameKana(e.target.value);
  }

  function handleChangeDepartment(e) {
    validate.setters.handleDepartmentValid(e);
    formInputs.setters.setDepartment(e.target.value);
  }

  function handleChangeOfficePosition(e) {
    validate.setters.handleOfficePositionValid(e);
    formInputs.setters.setOfficePosition(e.target.value);
  }

  function handleChangeTel(tel) {
    validate.setters.handleTelValid(tel);
    formInputs.setters.setTel({
      ...formInputs.data.tel,
    });
  }

  function handleChangeTelNum1(tel) {
    validate.setters.handleTel1Valid(tel);
    formInputs.setters.setTelNum1(tel);
  }

  function handleChangeTelNum2(tel) {
    validate.setters.handleTel2Valid(tel);
    formInputs.setters.setTelNum2(tel);
  }

  function handleChangeTelNum3(tel) {
    validate.setters.handleTel3Valid(tel);
    formInputs.setters.setTelNum3(tel);
  }

  function handleChangeExtension(e) {
    validate.setters.handleExtensionValid(e);
    formInputs.setters.setExtension(e.target.value);
  }

  function handleChangeEmail(e, required = false) {
    validate.setters.handleEmailValid(e, required);
    formInputs.setters.setEmail(e.target.value);
  }

  function handleChangeAccessPersonalInfo(e) {
    formInputs.setters.setAccessPersonalInfo(e.target.value);
  }

  function handleChangeWantReceiveEmail(e) {
    formInputs.setters.setWantReceiveEmail(e.target.value);
  }

  function handleChangeUserType(e) {
    formInputs.setters.setUserType(e.target.value);
  }

  const validateInputs = {
    data: formInputs.data,
    setters: {
      handleChangeFamilyName,
      handleChangeFamilyNameKana,
      handleChangeFirstName,
      handleChangeFirstNameKana,
      handleChangeDepartment,
      handleChangeOfficePosition,
      handleChangeTel,
      handleChangeTelNum1,
      handleChangeTelNum2,
      handleChangeTelNum3,
      handleChangeExtension,
      handleChangeEmail,
      handleChangeAccessPersonalInfo,
      handleChangeWantReceiveEmail,
      handleChangeUserType,
    },
    init: {
      setFamilyName: formInputs.setters.setFamilyName,
      setFirstName: formInputs.setters.setFirstName,
      setFamilyNameKana: formInputs.setters.setFamilyNameKana,
      setFirstNameKana: formInputs.setters.setFirstNameKana,
      setDepartment: formInputs.setters.setDepartment,
      setOfficePosition: formInputs.setters.setOfficePosition,
      setTel: formInputs.setters.setTel,
      setExtension: formInputs.setters.setExtension,
      setUserType: formInputs.setters.setUserType,
      setEmail: formInputs.setters.setEmail,
      setAccessPersonalInfo: formInputs.setters.setAccessPersonalInfo,
      setWantReceiveEmail: formInputs.setters.setWantReceiveEmail,
    },
    validationMessage: validate.validationMessage,
  };

  return validateInputs;
}
