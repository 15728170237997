export const document_send_description1 = [
  { id: 1, text: '例）〇〇市長', marked: false },
  { id: 2, text: '50文字以内で入力してください。' },
];
export const document_send_description2 = [
  { id: 1, text: '例）〇〇部〇〇課', marked: false },
  { id: 2, text: '50文字以内で入力してください。' },
];
export const document_send_description3 = [
  { id: 1, text: '例）https://example.com', marked: false },
  { id: 2, text: '行政機関ホームページのトップページURLを入力してください。' },
];
export const document_send_description4 = [
  {
    id: 1,
    text: '例）〇〇市〇〇部〇〇課官公庁オークション担当者',
    marked: false,
  },
  { id: 2, text: '50文字以内で入力してください。' },
];
export const document_send_description_tel_without_extention = [
  {
    id: 1,
    text: '数字は半角で入力してください。内線は入力必須ではありません。',
  },
];
