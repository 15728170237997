
export class Circumstance {
    #circumstance

    constructor(circumstance) {
        this.#circumstance = circumstance;
    }

    get() {
        return this.#circumstance
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
