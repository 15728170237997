// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sample-guideline .guideline-modal-template-text-area {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #ccc;
  color: #101010;
  font-family: -apple-system, "system-ui", "Helvetica Neue", YuGothic, "Yu Gothic", "Hiragino Kaku Gothic ProN", Meiryo, verdana, "Droid Sans", Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  resize: vertical;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/SampleGuideline/guideline-modal-template-text-area.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;EACtB,cAAc;EACd,gKAAgK;EAChK,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".sample-guideline .guideline-modal-template-text-area {\n  width: 100%;\n  box-sizing: border-box;\n  padding: 5px;\n  border: 1px solid #ccc;\n  color: #101010;\n  font-family: -apple-system, \"system-ui\", \"Helvetica Neue\", YuGothic, \"Yu Gothic\", \"Hiragino Kaku Gothic ProN\", Meiryo, verdana, \"Droid Sans\", Roboto, sans-serif;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 22px;\n  resize: vertical;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
