import AnchorButton from 'interfaces/components/common/AnchorButton';
import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import FaqOtherList from 'interfaces/components/Faq/Other/FaqOtherList';
import FaqOtherNavigator from 'interfaces/components/Faq/Other/FaqOtherNavigator';
import { breadcrumbs } from 'interfaces/pages/FaqOther/entities/breadcrumbs';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

const FaqOther = () => {
  const { localStorageProcedureType } = useLocalStorage();
  console.log(localStorageProcedureType)
  return (
    <div className="faq-other" data-testid="faq-other">
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <Heading2 title="その他" />
      <FaqOtherNavigator procedureType={localStorageProcedureType} />
      <FaqOtherList procedureType={localStorageProcedureType} />
      <AnchorButton text="▲ ページトップへ" />
    </div>
  );
};

export default FaqOther;
