import { useEffect, useState } from 'react';

import { get as getIndividualMailTemplate } from '../../../../domain/Email/services/Email.js';
import { findById as getGovernment } from '../../../../domain/Government/services/Government';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { NAVI } from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { MailNotificationConfigureContext } from '../entities/MailNotificationConfigureContext.js';

const MailNotificationConfigureProvider = ({ type, children }) => {
  const { governmentId } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [depositCommonMails, setDepositCommonMails] = useState([]);
  const [noDepositCommonMails, setNoDepositCommonMails] = useState([]);
  const [depositAuctionMails, setDepositAuctionMails] = useState([]);
  const [depositBidMails, setDepositBidMails] = useState([]);
  const [noDepositAuctionMails, setNoDepositAuctionMails] = useState([]);
  const [noDepositBidMails, setNoDepositBidMails] = useState([]);
  const { localStorageGovernmentId, localStorageProcedureType } =
    useLocalStorage();
  const [governmentType, setGovernmentType] = useState('');
  const [governmentName, setGovernmentName] = useState('');
  const [procedureType, setProcedureType] = useState('');

  if (type === NAVI) {
    useEffect(() => {
      getIndividualMailTemplate(type).then((templates) => {
        let dcm = templates.filter(
          (template) =>
            template.commonMessageType === 'COMMON' &&
            template.depositExist === 'EXIST' &&
            template.procedureType ===
              (localStorageProcedureType === 'ASSET_SALE'
                ? 'ASSET_SALE'
                : 'AUCTION'),
        );
        dcm = dcm.sort((a, b) => a.printOrder - b.printOrder);
        setDepositCommonMails(dcm);
        let ncm = templates.filter(
          (template) =>
            template.commonMessageType === 'COMMON' &&
            template.depositExist === 'NO_EXIST' &&
            template.procedureType ===
              (localStorageProcedureType === 'ASSET_SALE'
                ? 'ASSET_SALE'
                : 'AUCTION'),
        );
        ncm = ncm.sort((a, b) => a.printOrder - b.printOrder);
        setNoDepositCommonMails(ncm);
        let dam = templates.filter(
          (template) =>
            template.commonMessageType === 'AUCTION' &&
            template.depositExist === 'EXIST' &&
            template.procedureType ===
              (localStorageProcedureType === 'ASSET_SALE'
                ? 'ASSET_SALE'
                : 'AUCTION'),
        );
        dam = dam.sort((a, b) => a.printOrder - b.printOrder);
        setDepositAuctionMails(dam);
        let nam = templates.filter(
          (template) =>
            template.commonMessageType === 'AUCTION' &&
            template.depositExist === 'NO_EXIST' &&
            template.procedureType ===
              (localStorageProcedureType === 'ASSET_SALE'
                ? 'ASSET_SALE'
                : 'AUCTION'),
        );
        nam = nam.sort((a, b) => a.printOrder - b.printOrder);
        setNoDepositAuctionMails(nam);
        let dbm = templates.filter(
          (template) =>
            template.commonMessageType === 'BID' &&
            template.depositExist === 'EXIST' &&
            template.procedureType ===
              (localStorageProcedureType === 'ASSET_SALE'
                ? 'ASSET_SALE'
                : 'AUCTION'),
        );
        dbm = dbm.sort((a, b) => a.printOrder - b.printOrder);
        setDepositBidMails(dbm);
        let nbm = templates.filter(
          (template) =>
            template.commonMessageType === 'BID' &&
            template.depositExist === 'NO_EXIST' &&
            template.procedureType ===
              (localStorageProcedureType === 'ASSET_SALE'
                ? 'ASSET_SALE'
                : 'AUCTION'),
        );
        nbm = nbm.sort((a, b) => a.printOrder - b.printOrder);
        setNoDepositBidMails(nbm);
      });
    }, [localStorageProcedureType]);

    useEffect(() => {
      if (localStorageGovernmentId != '') {
        getGovernment(type, localStorageGovernmentId).then((result) => {
          setGovernmentType(result.governmentType);
          setGovernmentName(result.name);
        });
        setProcedureType(localStorageProcedureType);
      }
    }, [localStorageGovernmentId]);
  }

  return (
    <MailNotificationConfigureContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        governmentName,
        procedureType,
        depositCommonMails,
        noDepositCommonMails,
        depositAuctionMails,
        noDepositAuctionMails,
        depositBidMails,
        noDepositBidMails,
      }}>
      {children}
    </MailNotificationConfigureContext.Provider>
  );
};

export default MailNotificationConfigureProvider;
