import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class InquiryAccept {
  #inquiryAccept

  constructor(inquiryAccept) {
    this.#inquiryAccept = inquiryAccept;
  }

  get() {
    return this.#inquiryAccept
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#inquiryAccept, 0, 50)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#inquiryAccept)
  }
}
