
export class PublicSaleAuctionData {
    #publicSaleAuctionData

    constructor(publicSaleAuctionData) {
        this.#publicSaleAuctionData = publicSaleAuctionData;
    }

    get() {
        return this.#publicSaleAuctionData
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
