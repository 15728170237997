import Button from 'interfaces/components/common/Button';
import GovernmentFilter from 'interfaces/components/common/GovernmentFilter';
import PageTitle from 'interfaces/components/common/PageTitle';
import Pagination from 'interfaces/components/common/Pagination';
import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSortableData } from 'shared/hooks/useSort.js';
import usePagination from 'shared/hooks/usePagination.js';

import GovernmentListTable from './GovernmentListTable';
import { GovernmentTypeToJP } from '../../../../shared/utils/helper/convert/governmentTypeToJp';
import { GovernmentListContext } from '../../../pages/GovernmentList/entities/GovernmentListContext';

const CREATE_URL = '/kmanager/gov_list/new';

export const dummyHeader = [
  { id: 1, column: 'governmentType', text: '行政機関種別' },
  { id: 2, column: 'region', text: '地域' },
  { id: 3, column: 'location', text: '都道府県' },
  { id: 4, column: 'name', text: '行政機関名' },
  { id: 5, column: 'nameKana', text: '行政機関名カナ' },
  { id: 6, column: 'isPublic', text: '公売管理者ユーザ名' },
  { id: 7, column: 'isAsset', text: '公有管理者ユーザ名' },
  { id: 8, text: '編集/削除' },
];

const GovernmentList = () => {
  const navigation = useNavigate();
  const { governments, error } = useContext(GovernmentListContext);
  const [searchInput, setSearchInput] = useState('');

  governments.forEach((government) => {
    government.governmentType = GovernmentTypeToJP(government.governmentType);
  });

  const [filteredGovernments, setFilteredGovernments] = useState(governments);
  const {
    items: sortedGovernments,
    requestSort,
    sortConfig,
  } = useSortableData(filteredGovernments);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(sortedGovernments, rowsPerPage);

  useEffect(() => {
    setFilteredGovernments(
      governments.filter((government) => government.name.includes(searchInput)),
    );
  }, [searchInput, governments]);

  return (
    <div
      className="kmanager-government-lists kmanager-auction-operation"
      data-testid="kmanager-government-lists">
      <div className="kmanager-row">
        <div
          className="kmanager-col kmanager-col-6"
          style={{ display: 'flex', alignItems: 'center' }}>
          <PageTitle text="行政機関一覧" />
          <span data-testid="create-button">
            <Button
              className="primary"
              text="行政機関新規登録"
              isOutline={true}
              clickHandler={() => navigation(CREATE_URL)}
            />
          </span>
        </div>
        <div className="kmanager-col">
          <GovernmentFilter
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
        </div>
        <div className="kmanager-col kmanager-col-12">
          <div className="box-shadow">
            {/*TODO: 行政機関名にリンクを追加（Tableコンポーネントを作成？）*/}
            {error?.code && <PageTitle text={error.message} />}
            <GovernmentListTable
              data={currentItems}
              headers={dummyHeader}
              requestSort={requestSort}
              sortConfig={sortConfig}
            />
            <Pagination
              currentPage={currentPage}
              pageCount={pageNumbers.length}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              DATA_COUNT={governments.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GovernmentList;
