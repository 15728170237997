
export class CreatedUser {
    #createdUser

    constructor(createdUser) {
        this.#createdUser = createdUser;
    }

    get() {
        return this.#createdUser
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
