import { REAL_ESTATE } from 'domain/Property/entities/constants/property.js';

import RadioInput from 'interfaces/components/common/Input/RadioInput.jsx';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel.jsx';
//import { useApplicationInfo } from 'interfaces/hooks/useApplicationInfo.js';

const jointBiddingStatusList = [
  { id: 1, label: 'する', value: 'TRUE' },
  { id: 2, label: 'しない', value: 'FALSE' },
];

const JointBiddingInput = ({
  data,
  applicantInfo,
  setApplicantInformation,
}) => {
  //const { handleApplicantInfoChange } = useApplicationInfo();
  // mock data
  const handleApplicantInfoChange = (params) => {
    setApplicantInformation({
      ...applicantInfo,
      ...params,
    });
  };

  if (data.propertyType !== REAL_ESTATE) {
    return;
  }

  return (
    <tr data-testid="joint-bidding-input">
      <th>
        共同入札
        <RequiredLabel />
      </th>
      <td>
        <div className="flex">
          {jointBiddingStatusList.map((status) => (
            <RadioInput
              key={status.id}
              item={status}
              label={status.label}
              value={applicantInfo.jointBidding}
              handleClick={() =>
                handleApplicantInfoChange({ jointBidding: status.value })
              }
            />
          ))}
        </div>
        <small>
          不動産など共有できる物件の場合のみ選択できます。それ以外は選択しないでください。
        </small>
      </td>
    </tr>
  );
};

export default JointBiddingInput;
