import Breadcrumb from "interfaces/components/common/Breadcrumb";
import Heading2 from "interfaces/components/common/Heading/Heading2";
import { breadcrumbs } from 'interfaces/pages/NewEntry/entities/breadcrumbs'
import {Link, useParams} from "react-router-dom";

import "interfaces/css/PropertyInfoCar/property-info-car.css";
import "interfaces/css/common/property-info-general-form.css"
import {useLocalStorage} from "../../../../shared/hooks/useLocalStorage";

const NaviNewEntryComplete = ({method}) => {
    const { auction_id } = useParams();

    const { localStorageProcedureType } = useLocalStorage();

    const getProcedureName = () => {
        if (localStorageProcedureType === "PUBLIC_SALE") {
            return "インターネット公売";
        }
        return "公有財産売却"
    }

    const getTitle = () => {
        if (method === 'create') {
            return `登録完了：${getProcedureName()}参加申込フォーム`;
        }
        return `更新完了：${getProcedureName()}参加申込情報修正`;
    }

    const getMethod = () => {
        if (auction_id !== undefined) {
            return 'update';
        }
        return 'create';
    }

    return (
        <div className="property-info-car">
            <Breadcrumb breadcrumbs={breadcrumbs(getMethod(), 'complete', localStorageProcedureType, auction_id)}/>
            <Heading2 title={getTitle()}/>
            <div className="complete-message">
                <div>{`${getProcedureName()}${getMethod() === 'create' ? '参加申込フォームの登録' : '参加申込情報修正の更新'}が完了しました。`}</div>
            </div>
            <div className="description">
                <Link to={'/navi'}>トップページへ</Link>
            </div>
        </div>
    );
}

export default NaviNewEntryComplete;
