import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class NeighboringRoad {
  #neighboringRoad

  constructor(neighboringRoad) {
    this.#neighboringRoad = neighboringRoad;
  }

  get() {
    return this.#neighboringRoad
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#neighboringRoad, 0, 400)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#neighboringRoad)
  }
}
