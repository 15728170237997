import pino from "pino";
import { fetchData } from "shared/utils/helper/fetchData";

const logger = pino();
const CURRENT_MY_LISTS = `${process.env.REACT_APP_BASE_URL_NAVIGATION}/storage/current/my_lists/data.json`;
const PREVIOUS_MY_LISTS = `${process.env.REACT_APP_BASE_URL_NAVIGATION}/storage/previous/my_lists/data.json`;

export const getMyLists = async () => {
  try {
    const response = await fetchCurrentMyLists();
    return extractMyLists(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ my_lists ）を取得できませんでした");

    try {
      const response = await fetchPreviousMyLists();
      return extractMyLists(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ my_lists ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchCurrentMyLists = async () => {
  return fetchMyLists(CURRENT_MY_LISTS);
};

export const fetchPreviousMyLists = async () => {
  return fetchMyLists(PREVIOUS_MY_LISTS);
};

export const fetchMyLists = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractMyLists = (response) => {
  return response.map(my_list => my_list);
}