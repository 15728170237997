import CalendarIconLink from 'interfaces/components/common/Schedule/ScheduleRow/CalendarIconLink.jsx';
import convertToDateTime from 'shared/utils/helper/convertToDateTime.js';
import 'interfaces/css/AuctionSchedule/auction-schedule.css';

const AuctionSchedule = ({ auctionName, baseColor, schedule, year }) => {
  return (
    <div className="auction-schedule" data-testid="auction-schedule">
      <div className="auction-name" style={{ color: baseColor }} data-testid="auction-name">{auctionName}</div>
      <div className="auction-apl-term auction-term-row" data-testid="auction-apl-term">
        <div className="auction-term-label">参加申し込み受付期間</div>
        <div className={`auction-term ${baseColor || ''}`}>
          <div className="auction-start">開始：{schedule.aplStart}</div>
          <div className="flex align-center">
            締切：{schedule.aplEnd}
            <div className="calendar-icon-link-wrap" data-testid="calendar-icon-link-wrap">
              <CalendarIconLink
                title={auctionName}
                subtitle="参加申し込み受付期間"
                startDate={convertToDateTime(year, schedule.aplStart)}
                endDate={convertToDateTime(year, schedule.aplEnd)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="auction-bid-term auction-term-row" data-testid="auction-bid-term">
        <div className="auction-term-label">入札期間</div>
        <div className={`auction-term ${baseColor || ''}`}>
          <div className="auction-start">開始：{schedule.bidStart}</div>
          <div className="auction-end-wrap">
            <div className="auction-end flex align-center">
              せり売締切：{schedule.auctionEnd}
              <div className="calendar-icon-link-wrap" data-testid="calendar-icon-link-wrap">
                <CalendarIconLink
                  title={auctionName}
                  subtitle="入札期間"
                  startDate={convertToDateTime(year, schedule.bidStart)}
                  endDate={convertToDateTime(year, schedule.auctionEnd)}
                />
              </div>
            </div>
            <div className="auction-end flex align-center">
              入札締切：{schedule.bidEnd}
              <div className="calendar-icon-link-wrap" data-testid="calendar-icon-link-wrap">
                <CalendarIconLink
                  title={auctionName}
                  subtitle="入札期間"
                  startDate={convertToDateTime(year, schedule.bidStart)}
                  endDate={convertToDateTime(year, schedule.bidEnd)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionSchedule;
