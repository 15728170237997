import GovernmentPaymentProvider from './GovernmentPaymentProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { governmentPayment } from "../entities/componentMapping.js";

const GovernmentPayment = ({ type }) => {
  return (
    <GovernmentPaymentProvider type={type}>
      {getComponent(governmentPayment, type)}
    </GovernmentPaymentProvider>
  );
};

export default GovernmentPayment;
