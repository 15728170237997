import { validateSelected } from "../../../../application/validations.js";

export class ResidenceGovernmentSubCategoryId {
  #residenceGovernmentSubCategoryId

  constructor(residenceGovernmentSubCategoryId) {
    this.#residenceGovernmentSubCategoryId = residenceGovernmentSubCategoryId;
  }

  get() {
    return this.#residenceGovernmentSubCategoryId
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#residenceGovernmentSubCategoryId)
  }
}
