
export class Tags {
    #tags

    constructor(tags) {
        this.#tags = tags;
    }

    get() {
        return this.#tags
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
