const KmanagerTableRowHeader = ({ header, data }) => {
  const imageColumns = ['emblem', 'character'];

  return (
    <tr
      className="kmanager-table-record"
      data-testid="kmanager-table-record-header">
      <td data-testid="kmanager-table-td">{header.text}</td>
      <td data-testid="kmanager-table-td">
        {imageColumns.includes(header.column)
          ? data
            ? '設定済'
            : '未設定'
          : data}
      </td>
    </tr>
  );
};

export default KmanagerTableRowHeader;
