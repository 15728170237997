
export class AplCountBidDisplay {
  #aplCountBidDisplay

  constructor(aplCountBidDisplay) {
    this.#aplCountBidDisplay = aplCountBidDisplay;
  }

  get() {
    return this.#aplCountBidDisplay
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
