// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-term-row {
  height: 70px;
  display: flex;
  align-items: center;
  border-right: 1px solid var(--line-base);
  border-bottom: 1px solid var(--line-base);
  border-left: 1px solid var(--line-base);
}

.property-schedule-term {
  white-space: nowrap;
  overflow: visible;
  padding: 6px 10px;
  border-radius: 8px;
  position: absolute;
}

.property-apl-term {
  background-color: var(--schedule-1);
}

.property-bid-term {
  background-color: var(--schedule-2);
}

.payment-deadline {
  width: 4px;
  height: 60px;
  background-color: var(--accent-base1);
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/property-schedule-row.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,wCAAwC;EACxC,yCAAyC;EACzC,uCAAuC;AACzC;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,qCAAqC;EACrC,kBAAkB;AACpB","sourcesContent":[".property-term-row {\n  height: 70px;\n  display: flex;\n  align-items: center;\n  border-right: 1px solid var(--line-base);\n  border-bottom: 1px solid var(--line-base);\n  border-left: 1px solid var(--line-base);\n}\n\n.property-schedule-term {\n  white-space: nowrap;\n  overflow: visible;\n  padding: 6px 10px;\n  border-radius: 8px;\n  position: absolute;\n}\n\n.property-apl-term {\n  background-color: var(--schedule-1);\n}\n\n.property-bid-term {\n  background-color: var(--schedule-2);\n}\n\n.payment-deadline {\n  width: 4px;\n  height: 60px;\n  background-color: var(--accent-base1);\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
