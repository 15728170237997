import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class ContactOffice {
  #contactOffice

  constructor(contactOffice) {
    this.#contactOffice = contactOffice;
  }

  get() {
    return this.#contactOffice
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#contactOffice, 0, 30)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#contactOffice)
  }
}
