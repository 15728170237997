import PropertyListSearchInput from "interfaces/components/Property/List/PropertyListSearchInput.jsx";
import PropertyListSelector from "interfaces/components/Property/List/PropertyListSelector.jsx";
import "interfaces/css/PropertyList/input-infomation-table.css";

const InputInformationTable = () => {
    return (
        <div className="input-information-table" data-testid="copy-dialog-info-table">
            <div className="table-row" data-testid="table-row">
                <div className="thead">
                    <span>登録先</span>
                </div>
                <div className="tbody">
                    <p>以下の物件データを登録する公告を選択してください。<br/>
                        選択した公告にコピーしたデータを登録します。</p>
                    <PropertyListSelector content=""/>
                </div>
            </div>
            <div className="table-row" data-testid="table-row">
                <div className="thead">
                    <span>買受代金納付期限</span>
                </div>
                <div className="tbody">
                    <p>新しく作成する物件データの買受代金納付期限を選択してください</p>
                    <PropertyListSearchInput label=""/>
                </div>
            </div>
        </div>
    );
}

export default InputInformationTable;