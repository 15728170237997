import { findById as getGovernment } from "domain/Government/services/Government";

import { useState, useEffect } from 'react';
import { useLocalStorage } from "shared/hooks/useLocalStorage";
import { NAVI } from "shared/utils/helper/constants";

export const useGovernment = () => {
    const [government, setGovernment] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const { localStorageGovernmentId } = useLocalStorage();

    useEffect(() => {
        setLoading(true);
        try {
            getGovernment(NAVI, localStorageGovernmentId).then(government => {
                setGovernment(government);
            });
        } finally {
            setLoading(false);
        }
    }, [localStorageGovernmentId]);

    return { government, loading };
};
