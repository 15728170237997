// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-list .search-display-toggle {
  color: rgb(0, 128, 255);
}

.property-list .search-display-toggle.item-group {
  flex: 0 1 auto;
  position: relative;
  max-width: 100%;
}

.property-list .search-display-toggle.btn-toggle {
  border-radius: 4px;
  max-width: 100%;
}

.property-list .search-display-toggle-button {
  min-height: 0;
  min-width: 48px;
  height: 36px;
  border-color: rgba(0,0,0,.12);
  border-radius: 0;
  border-style: solid;
  border-width: thin;
  box-shadow: none;
  opacity: .8;
  padding: 0 12px;
}

.property-list .search-display-toggle-button:first-child {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.property-list .search-display-toggle-button:not(:first-child) {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border-left-width: 0;
}

.property-list .search-display-toggle-button-content {
  display: flex;
  flex: 1 0 auto;
  line-height: normal;
  position: relative;
}

.property-list .search-display-toggle-button--active {
  color: rgba(0,0,0,.87);
  background-color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertyList/display-toggle.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,YAAY;EACZ,6BAA6B;EAC7B,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,+BAA+B;EAC/B,kCAAkC;AACpC;;AAEA;EACE,gCAAgC;EAChC,mCAAmC;EACnC,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,8BAA8B;AAChC","sourcesContent":[".property-list .search-display-toggle {\n  color: rgb(0, 128, 255);\n}\n\n.property-list .search-display-toggle.item-group {\n  flex: 0 1 auto;\n  position: relative;\n  max-width: 100%;\n}\n\n.property-list .search-display-toggle.btn-toggle {\n  border-radius: 4px;\n  max-width: 100%;\n}\n\n.property-list .search-display-toggle-button {\n  min-height: 0;\n  min-width: 48px;\n  height: 36px;\n  border-color: rgba(0,0,0,.12);\n  border-radius: 0;\n  border-style: solid;\n  border-width: thin;\n  box-shadow: none;\n  opacity: .8;\n  padding: 0 12px;\n}\n\n.property-list .search-display-toggle-button:first-child {\n  border-top-left-radius: inherit;\n  border-bottom-left-radius: inherit;\n}\n\n.property-list .search-display-toggle-button:not(:first-child) {\n  border-top-right-radius: inherit;\n  border-bottom-right-radius: inherit;\n  border-left-width: 0;\n}\n\n.property-list .search-display-toggle-button-content {\n  display: flex;\n  flex: 1 0 auto;\n  line-height: normal;\n  position: relative;\n}\n\n.property-list .search-display-toggle-button--active {\n  color: rgba(0,0,0,.87);\n  background-color: var(--white);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
