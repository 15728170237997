import Schedule from '../Schedule/Schedule.jsx';
import 'interfaces/css/common/simple-schedule.css';

const SimpleSchedule = ({ data, scheduleType }) => {
  return (
    <div className="simple-schedule mb-10">
      <Schedule termData={data} scheduleType={scheduleType} />
      {(data?.auctionInfo?.status ?? data?.bidInfo?.status) === 'APPLYING' && (
        <div>参加申し込み受付中です！</div>
      )}
    </div>
  );
};

export default SimpleSchedule;
