import { useEffect, useState } from 'react';

import { findByGovernmentId } from '../../../../domain/List/services/BlockList.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { BlockListContext } from '../entities/BlockListContext.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';

const BlockListProvider = ({ type, children }) => {
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [blockList, setBlockList] = useState([]);
  const {
    localStorageGovernmentId,
    localStorageGovernmentType,
    localStorageProcedureType,
  } = useLocalStorage();
  const [searchParam, setSearchParam] = useState({ auctionUserId: '' });

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {}, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      findByGovernmentId(type, localStorageGovernmentId).then((governments) => {
        if (searchParam.auctionUserId !== '') {
          governments = governments.filter((data) =>
            data.auctionUserId.includes(searchParam.auctionUserId),
          );
        }
        setBlockList(governments);
      });
    }, [localStorageGovernmentId, searchParam]);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <BlockListContext.Provider
      value={{
        authState,
        breadcrumbs,
        localStorageGovernmentType,
        localStorageProcedureType,
        blockList,
        setSearchParam,
      }}>
      {children}
    </BlockListContext.Provider>
  );
};

export default BlockListProvider;
