import { useState } from 'react';

export function useDocumentSendInputs() {
  const [invoicePostalCode, setInvoicePostalCode] = useState({
    invoicePostalCodeFirst: '',
    invoicePostalCodeLast: '',
  });
  const [invoicePostalCodeFirst, setInvoicePostalCodeFirst] = useState('');
  const [invoicePostalCodeLast, setInvoicePostalCodeLast] = useState('');
  const [invoiceArea, setInvoiceArea] = useState('');
  const [invoiceCity, setInvoiceCity] = useState('');
  const [invoiceAddress, setInvoiceAddress] = useState('');
  const [invoiceBuildName, setInvoiceBuildName] = useState('');
  const [invoiceWrittenDestination, setInvoiceWrittenDestination] =
    useState('');
  const [invoiceDepartment, setInvoiceDepartment] = useState('');
  const [invoicePayerName, setInvoicePayerName] = useState('');
  const [invoiceTel, setInvoiceTel] = useState({
    telNum1: '',
    telNum2: '',
    telNum3: '',
  });
  const [telNum1, setTelNum1] = useState('');
  const [telNum2, setTelNum2] = useState('');
  const [telNum3, setTelNum3] = useState('');
  const [invoiceExtension, setInvoiceExtension] = useState('');
  const [invoiceUrl, setInvoiceUrl] = useState('');

  const [certPostalCode, setCertPostalCode] = useState({
    certPostalCodeFirst: '',
    certPostalCodeLast: '',
  });
  const [certPostalCodeFirst, setCertPostalCodeFirst] = useState('');
  const [certPostalCodeLast, setCertPostalCodeLast] = useState('');
  const [certArea, setCertArea] = useState('');
  const [certCity, setCertCity] = useState('');
  const [certAddress, setCertAddress] = useState('');
  const [certBuildName, setCertBuildName] = useState('');
  const [certWrittenDestination, setCertWrittenDestination] = useState('');
  const [certDestination, setCertDestination] = useState('');

  return {
    data: {
      invoicePostalCode,
      invoicePostalCodeFirst,
      invoicePostalCodeLast,
      invoiceArea,
      invoiceCity,
      invoiceAddress,
      invoiceBuildName,
      invoiceWrittenDestination,
      invoiceDepartment,
      invoicePayerName,
      invoiceTel,
      telNum1,
      telNum2,
      telNum3,
      invoiceExtension,
      invoiceUrl,
      certPostalCode,
      certPostalCodeFirst,
      certPostalCodeLast,
      certArea,
      certCity,
      certAddress,
      certBuildName,
      certWrittenDestination,
      certDestination,
    },
    setters: {
      setInvoicePostalCode,
      setInvoicePostalCodeFirst,
      setInvoicePostalCodeLast,
      setInvoiceArea,
      setInvoiceCity,
      setInvoiceAddress,
      setInvoiceBuildName,
      setInvoiceWrittenDestination,
      setInvoiceDepartment,
      setInvoicePayerName,
      setInvoiceTel,
      setTelNum1,
      setTelNum2,
      setTelNum3,
      setInvoiceExtension,
      setInvoiceUrl,
      setCertPostalCode,
      setCertPostalCodeFirst,
      setCertPostalCodeLast,
      setCertArea,
      setCertCity,
      setCertAddress,
      setCertBuildName,
      setCertWrittenDestination,
      setCertDestination,
    },
  };
}
