import { useState } from 'react';

const usePagination = (items, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);

  const allItemCount = items?.length;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items?.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(items?.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return {
    allItemCount,
    currentPage,
    currentItems,
    pageNumbers,
    setCurrentPage,
  };
};

export default usePagination;
