const searchProperty = (query, properties) => {
  let tmpProperties = properties;
  if (query.get('category') === 'CAR') {
    tmpProperties = tmpProperties.filter(
      (property) => property.propertyType === 'CAR',
    );
  } else if (query.get('category') === 'ESTATE') {
    tmpProperties = tmpProperties.filter(
      (property) => property.propertyType === 'ESTATE',
    );
  } else if (query.get('category') === 'REAL_ESTATE') {
    tmpProperties = tmpProperties.filter(
      (property) => property.propertyType === 'REAL_ESTATE',
    );
  }

  if (query.get('searchPropertyCategoryId')) {
    tmpProperties = tmpProperties.filter((property) => {
      return query
        .get('searchPropertyCategoryId')
        .split(',')
        .includes(property.categoryId);
    });
  }

  return tmpProperties;
};

export default searchProperty;
