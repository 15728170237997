
export class CurrentTopUser {
    #currentTopUser

    constructor(currentTopUser) {
        this.#currentTopUser = currentTopUser;
    }

    get() {
        return this.#currentTopUser
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
