import { useState } from 'react';

const useStep = (initStep) => {
  const [step, setStep] = useState(initStep);

  const previousStep = () => setStep(step - 1);
  const nextStep = () => setStep(step + 1);

  return { step, previousStep, nextStep };
};

export default useStep;
