import styled from 'styled-components';

const Wrapper = styled.div``;

const Header = styled.h3`
  position: relative;
  display: inline-block;
  padding-left: 12px;
  margin-bottom: 8px;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  white-space: nowrap;

  &:before {
    position: absolute;
    display: block;
    content: '';
    background-color: var(--primary-base);
    width: 4px;
    height: 100%;
    left: 0;
  }

  .kservice & {
    margin: 0 0 8px;
  }
`;

const ArticleDefaultTitle = ({ title }) => {
  return (
    <Wrapper>
      <Header>{title}</Header>
    </Wrapper>
  );
};

export default ArticleDefaultTitle;
