// react/src/constants/httpStatusCodes.js

// Success status codes
export const OK = 200;  // Request succeeded and that the requested information is in the response
export const CREATED = 201;  // Request succeeded and a new resource was created as a result

//Redirection status code
export const MULTIPLE_CHOICES = 300;

// Client error status codes
export const BAD_REQUEST = 400;  // Bad request; server didn't understand the request
export const UNAUTHORIZED = 401;  // Unauthorized; client needs to authenticate itself to get the requested response
export const FORBIDDEN = 403;  // Client does not have access rights to the content
export const NOT_FOUND = 404;  // Server can not find requested resource

// Server error status codes
export const INTERNAL_SERVER_ERROR = 500;  // Server has encountered a situation it doesn't know how to handle
export const SERVICE_UNAVAILABLE = 503;
