// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category {
  font-size: 0.8rem;
}

.category-see-all-toggle {
  text-align: center;
  margin-top: 10px;
}

.category-see-all-toggle a {
  color: var(--black);
}

.grand-child-category {
  min-height: 30px;
  text-decoration: underline;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/category.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,eAAe;AACjB","sourcesContent":[".category {\n  font-size: 0.8rem;\n}\n\n.category-see-all-toggle {\n  text-align: center;\n  margin-top: 10px;\n}\n\n.category-see-all-toggle a {\n  color: var(--black);\n}\n\n.grand-child-category {\n  min-height: 30px;\n  text-decoration: underline;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
