export const procedure_asset = [
  {id: 1, text: "クレジットカード、銀行振込など両方", value: "BOTH"},
  {id: 2, text: "クレジットカード", value: "CREDIT_CARD"},
  {id: 3, text: "銀行振込など", value: "BANK_TRANSFER"},
  {id: 4, text: "入札保証金なし", value: "NONE"}
]
export const procedure_pub = [
  {id: 1, text: "クレジットカード、銀行振込など両方", value: "BOTH"},
  {id: 2, text: "クレジットカード", value: "CREDIT_CARD"},
  {id: 3, text: "銀行振込など", value: "BANK_TRANSFER"},
  {id: 4, text: "公売保証金なし", value: "NONE"}
]
export const is_draft = [
  {id: 1, text: "下書き", value: "TRUE"},
  {id: 2, text: "公開", value: "FALSE"},
]
export const public_sale_type = [
  {id: 1, text: "せり売り形式", value: "AUCTION"},
  {id: 2, text: "入札形式", value: "BID"},
]
export const qualified_invoice = [
  {id: 1, text: "発行可能"},
  {id: 2, text: "発行不可"},
]
export const radio_label3 = [
  {id: 1, text: "必要", value: "TRUE"},
  {id: 2, text: "不要", value: "FALSE"},
]
