import SmallLightButton from 'interfaces/components/common/Button/SmallLightButton';
import Memo from 'interfaces/components/Memo/Memo';
import { dateFormat } from 'shared/utils/helper/date_format';

import BlockListRegister from './BlockListRegister';

import 'interfaces/css/ApplicantList/applicant-list-table.css';

const ApplicantListTable = ({
  tableData,
  auction,
  type,
  handlerCheck = () => {},
}) => {
  const today = new Date();
  let todayYear = today.getFullYear();
  let todayMonth = today.getMonth() + 1;
  let todayDate = today.getDate();
  let todayHour = today.getHours();
  let todayMinute = today.getMinutes();
  let todayString = `${todayYear}-${('00' + todayMonth).slice(-2)}-${('00' + todayDate).slice(-2)} ${('00' + todayHour).slice(-2)}:${('00' + todayMinute).slice(-2)}`;
  let displayLimitDateString = `${todayYear}-${('00' + (todayMonth + 1)).slice(-2)}-${('00' + todayDate).slice(-2)} ${('00' + todayHour).slice(-2)}:${('00' + todayMinute).slice(-2)}`;

  return (
    <table className="applicant-list-table">
      <thead>
        <tr>
          <th>選択</th>
          <th>ステータス</th>
          <th>申し込み情報</th>
          <th>申し込み者情報</th>
        </tr>
      </thead>
      <tbody className="applicant-list-table-body">
        {tableData &&
          tableData.map((d) => (
            <tr key={d.membershipId}>
              <td>
                {d.aplStatus === 'TRIAL' &&
                auction?.bidEnd > todayString &&
                auction?.auctionEnd > todayString ? (
                  <input type="checkbox" onChange={handlerCheck} />
                ) : (
                  <></>
                )}
              </td>
              <td className="status">{d.aplStatusJp}</td>
              <td className="col-application-info">
                <dl>
                  <dt>トラッキングID</dt>
                  <dd>{d.trackingId}</dd>
                </dl>
                <dl>
                  <dt>申し込み日時</dt>
                  <dd>{d.tempAplDate}</dd>
                </dl>
                <dl>
                  <dt>納付方法</dt>
                  <dd>
                    {d.aplType === 'ONLINE' ? 'クレジットカード' : '銀行振込'}
                  </dd>
                </dl>
                <dl>
                  <dt>メモ</dt>
                  <dd>
                    {d.memo}
                    <Memo isSmallLightButton={true} text="登録" />
                  </dd>
                </dl>
                <dl>
                  <dt>取り消し</dt>
                  <dd>
                    {d.aplStatus === 'APPLICATION' &&
                    auction?.bidEnd > todayString &&
                    auction?.auctionEnd > todayString ? (
                      <>
                        <div data-testid="memo" className="d-inline-block">
                          <SmallLightButton text={'申し込み取り消し'} />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </dd>
                </dl>
              </td>
              <td className="col-applicant-info">
                {auction.openEnd < displayLimitDateString ? (
                  <>
                    <dl>
                      <dt>会員識別番号</dt>
                      <dd>{d.membershipId}</dd>
                    </dl>
                    <dl>
                      <dt>住所</dt>
                      <dd>
                        {type !== 'CLIENT'
                          ? d.applicant.address
                          : d.client.address}
                      </dd>
                    </dl>
                    <dl>
                      <dt>氏名</dt>
                      <dd>
                        {type !== 'CLIENT' ? d.applicant.name : d.client.name}
                      </dd>
                    </dl>
                    <dl>
                      <dt>法人名</dt>
                      <dd>
                        {type !== 'CLIENT'
                          ? d.applicant.corpName
                          : d.client.corpName}
                      </dd>
                    </dl>
                    <dl>
                      <dt>メール</dt>
                      <dd>{d.email}</dd>
                    </dl>
                    <dl className="tel">
                      <dt>電話番号</dt>
                      <dd>
                        <dl>
                          <dt>自宅</dt>
                          <dd>
                            {type !== 'CLIENT' ? d.applicant.tel : d.client.tel}
                          </dd>
                        </dl>
                        <dl>
                          <dt>携帯</dt>
                          <dd>
                            {type !== 'CLIENT'
                              ? d.applicant.mobileTel
                              : d.client.mobileTel}
                          </dd>
                        </dl>
                      </dd>
                    </dl>
                    <dl>
                      <dt>性別</dt>
                      <dd>
                        {type !== 'CLIENT' ? d.applicant.sex : d.client.sex}
                      </dd>
                    </dl>
                    <dl>
                      <dt>生年月日</dt>
                      <dd>
                        {type !== 'CLIENT'
                          ? d.applicant.birthDate !== undefined
                            ? dateFormat(
                                new Date(d.applicant.birthDate),
                                'YYYY-MM-DD',
                              )
                            : ''
                          : d.client.birthDate !== undefined
                            ? dateFormat(
                                new Date(d.client.birthDate),
                                'YYYY-MM-DD',
                              )
                            : ''}
                      </dd>
                    </dl>
                    <dl>
                      <dt>共同入札</dt>
                      <dd>{d.isJointBid === 'TRUE' ? 'はい' : 'いいえ'}</dd>
                    </dl>
                    <dl>
                      <dt>代理人への委任</dt>
                      <dd>{d.isRepresentative === 'TRUE' ? 'はい' : ''}</dd>
                    </dl>
                    <dl>
                      <dt>制限</dt>
                      <dd>
                        {/*
                      TODO: 一つ目のページ(〇〇〇〇形式の公開物件管理ページ)の申込者一覧をクリックした時に物件名を渡す必要がある
                      TODO: ユーザ情報を取得するAPIと結合した時に返り値をuserDataにセットする必要がある
                    */}
                        {auction.openEnd > todayString ? (
                          <BlockListRegister
                            propertyName="テスト　移動販売カー"
                            userData={{
                              status: '申込',
                              paymentMethod: 'test',
                              identificationNum: 'XXX',
                              applicationDate: '2023年11月2日',
                              memo: 'memo',
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </dd>
                    </dl>
                  </>
                ) : (
                  <>
                    <table className="applicant-user-table">
                      <tbody>
                        <tr>
                          <td className="applicant-user-table-header">
                            会員識別番号
                          </td>
                          <td rowSpan="8">表示期間が終了しました</td>
                        </tr>
                        <tr>
                          <td>住所</td>
                        </tr>
                        <tr>
                          <td>氏名</td>
                        </tr>
                        <tr>
                          <td>法人名</td>
                        </tr>
                        <tr>
                          <td>メール</td>
                        </tr>
                        <tr>
                          <td>電話番号</td>
                        </tr>
                        <tr>
                          <td>性別</td>
                        </tr>
                        <tr>
                          <td>生年月日</td>
                        </tr>
                        <tr>
                          <td>共同入札</td>
                          <td>{d.isJointBid === 'TRUE' ? 'はい' : 'いいえ'}</td>
                        </tr>
                        <tr>
                          <td>代理人への委任</td>
                          <td>{d.representation}</td>
                        </tr>
                        <tr>
                          <td>制限</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default ApplicantListTable;
