// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-info-general-form-input-tell input {
  font-size: 16px;
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0;
  max-width: 100%;
  min-width: 0;
  width: 100%;
}

.property-info-general-form-input-tell .property-info-general-form-table-cell-1:first-child {
  margin: 0
}

.property-info-general-form-input-tell .property-info-general-form-table-cell-1 {
  width: 5em;
  margin-left: 8px;
}

.property-info-general-form-input-tell-flex {
  display: flex;
  align-items: center;
}

.property-info-general-form-input-tell-inline {
  display: inline-flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/property-info-general-form-input-tell.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EAGf,cAAc;EACd,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,YAAY;EACZ,WAAW;AACb;;AAEA;EACE;AACF;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;AACrB","sourcesContent":[".property-info-general-form-input-tell input {\n  font-size: 16px;\n  -webkit-box-flex: 1;\n  -ms-flex: 1 1 auto;\n  flex: 1 1 auto;\n  line-height: 20px;\n  padding: 8px 0;\n  max-width: 100%;\n  min-width: 0;\n  width: 100%;\n}\n\n.property-info-general-form-input-tell .property-info-general-form-table-cell-1:first-child {\n  margin: 0\n}\n\n.property-info-general-form-input-tell .property-info-general-form-table-cell-1 {\n  width: 5em;\n  margin-left: 8px;\n}\n\n.property-info-general-form-input-tell-flex {\n  display: flex;\n  align-items: center;\n}\n\n.property-info-general-form-input-tell-inline {\n  display: inline-flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
