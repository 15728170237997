import { getNaviAuctionUsers } from '../repositories/AuctionUser';

export async function all(type) {
  return await getNaviAuctionUsers(type);
}

export async function findByAuctionUserId(type, auctionUserId) {
  const auctionUsers = await getNaviAuctionUsers(type);
  return await auctionUsers.find((user) => user.id === auctionUserId);
}
