
import { APPLICATION_LIST_SIZE } from 'config/naviConstants';
import ApplicantListTable from 'interfaces/components/Applicant/navi/ApplicantListTable';
import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import SmallLightButton from 'interfaces/components/common/Button/SmallLightButton';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import Pagination from 'interfaces/components/common/Pagination/Pagination';
import SortToolbar from 'interfaces/components/common/SortToolbar';
import PropertyListSearchInput from 'interfaces/components/Property/List/PropertyListSearchInput';
import { ApplicantListContext } from 'interfaces/pages/ApplicantList/entities/ApplicantListContext';
import { breadcrumbs } from 'interfaces/pages/ApplicantList/entities/breadcrumbs';
import { useContext, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import usePagination from 'shared/hooks/usePagination';

import 'interfaces/css/ApplicantList/applicant-list.css';

const sortButtonData = [
  {
    id: 1,
    label: 'ステータス',
  },
  {
    id: 2,
    label: '申込日時',
  },
  {
    id: 3,
    label: '納付方法',
  },
  {
    id: 4,
    label: '代理人',
  },
];

const ApplicantList = () => {
  const { auction, governmentUser, tableData, setSearchParam } =
    useContext(ApplicantListContext);
  const refMembershipId = useRef();
  const { auction_id } = useParams();
  const location = useLocation();

  const [searchMembershipId, setSearchMembershipId] = useState('');
  const [type, setType] = useState('APPLICANT');

  const today = new Date();
  let todayYear = today.getFullYear();
  let todayMonth = today.getMonth() + 1;
  let todayDate = today.getDate();
  let todayHour = today.getHours();
  let todayMinute = today.getMinutes();
  let todayString = `${todayYear}-${('00' + todayMonth).slice(-2)}-${('00' + todayDate).slice(-2)} ${('00' + todayHour).slice(-2)}:${('00' + todayMinute).slice(-2)}`;

  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(tableData, APPLICATION_LIST_SIZE);

  const handleSearch = () => {
    setSearchParam({ membershipId: searchMembershipId });
  };

  const handleMembershipIdParam = (e) => {
    setSearchMembershipId(e);
  };

  const handleDeleteParam = () => {
    refMembershipId.current.value = '';
    setSearchParam({ membershipId: '' });
  };

  const handleTypeChange = (e) => {
    if (e.target.checked) {
      setType('CLIENT');
    } else setType('APPLICANT');
  };

  return (
    <span className="applicant-list" data-testid="applicant-list">
      <Breadcrumb
        breadcrumbs={breadcrumbs(auction_id, location.state.openWay)}
      />
      <Heading2 title="申し込み者一覧:" />
      <div
        className="applicant-list-table-count mt-10"
        data-testid="applicants-table">
        <dl data-testid="applicants-num">
          <dt>申し込み合計</dt>
          <dd>{tableData?.length}件</dd>
        </dl>
        <dl data-testid="applied-credit-card-num">
          <dt>クレジットカード</dt>
          <dd>
            {tableData.filter((data) => data.aplType === 'ONLINE').length}件
          </dd>
        </dl>
        <dl data-testid="applied-bank-transfer-num">
          <dt>銀行振込</dt>
          <dd>
            {tableData.filter((data) => data.aplType === 'OFFLINE').length}件
          </dd>
        </dl>
      </div>
      <div className="applicant-list-filter mt-10">
        <PropertyListSearchInput
          inputRef={refMembershipId}
          label="会員識別番号"
          style={{ width: '200px' }}
          handler={handleMembershipIdParam}
        />
        <div className="filter-buttons">
          <SmallLightButton text="絞り込む" onClick={handleSearch} />
          <SmallLightButton text="解除" onClick={handleDeleteParam} />
        </div>
      </div>
      <div className="sort-toolbar-wrap mt-10">
        <SortToolbar sortButtonData={sortButtonData}>
          {governmentUser?.userType === 'CHILD' ? (
            <></>
          ) : (
            <div>
              {auction?.bidEnd > todayString ? (
                <span>
                  <span>選択した申し込み者を </span>
                  <SmallLightButton text="本申し込み登録" />
                </span>
              ) : (
                <></>
              )}
              <input
                className="ml-5"
                type="checkbox"
                onChange={handleTypeChange}
              />
              このページ内の委任者情報を全て表示する
            </div>
          )}
        </SortToolbar>
      </div>
      <ApplicantListTable
        auction={auction}
        checked={'CLIENT'}
        tableData={currentItems}
        type={type}
      />
      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
        marginTop="40px"
      />
    </span>
  );
};

export default ApplicantList;
