import { useState } from 'react';
//import { logInput } from "../../utils/log/logInputs.js";

import {
  validateUrlFormatCheck,
  validateEmptyString,
  validateUrlLength,
  validateEmailFormatCheck,
  validateRangeLength,
  notInclude,
  validateNumber,
  hasEmoji,
} from '../../../application/validations';

export const AfterWinningAssetSaleBidCautionForm = () => {
  // せり形式
  // 1. 権利移転手続き
  // 権利移転手続きについての説明
  const [auctionManagementTransValid, setAuctionManagementTransValid] =
    useState(null);

  // 必要な費用
  // ■ 契約締結期限までに
  // タイトル1
  const [auctionCostContractTitle1Valid, setAuctionCostContractTitle1Valid] =
    useState(null);
  // 説明1
  const [
    auctionCostContractDescription1Valid,
    setAuctionCostContractDescription1Valid,
  ] = useState(null);
  // タイトル2
  const [auctionCostContractTitle2Valid, setAuctionCostContractTitle2Valid] =
    useState(null);
  // 説明2
  const [
    auctionCostContractDescription2Valid,
    setAuctionCostContractDescription2Valid,
  ] = useState(null);
  // タイトル3
  const [auctionCostContractTitle3Valid, setAuctionCostContractTitle3Valid] =
    useState(null);
  // 説明3
  const [
    auctionCostContractDescription3Valid,
    setAuctionCostContractDescription3Valid,
  ] = useState(null);

  // ■ 代金納付期限までに
  // タイトル1
  const [auctionCostPaymentTitle1Valid, setAuctionCostPaymentTitle1Valid] =
    useState(null);
  // 説明1
  const [
    auctionCostPaymentDescription1Valid,
    setAuctionCostPaymentDescription1Valid,
  ] = useState(null);
  // タイトル2
  const [auctionCostPaymentTitle2Valid, setAuctionCostPaymentTitle2Valid] =
    useState(null);
  // 説明2
  const [
    auctionCostPaymentDescription2Valid,
    setAuctionCostPaymentDescription2Valid,
  ] = useState(null);
  // タイトル3
  const [auctionCostPaymentTitle3Valid, setAuctionCostPaymentTitle3Valid] =
    useState(null);
  // 説明3
  const [
    auctionCostPaymentDescription3Valid,
    setAuctionCostPaymentDescription3Valid,
  ] = useState(null);
  // タイトル4
  const [auctionCostPaymentTitle4Valid, setAuctionCostPaymentTitle4Valid] =
    useState(null);
  // 説明4
  const [
    auctionCostPaymentDescription4Valid,
    setAuctionCostPaymentDescription4Valid,
  ] = useState(null);
  // タイトル5
  const [auctionCostPaymentTitle5Valid, setAuctionCostPaymentTitle5Valid] =
    useState(null);
  // 説明5
  const [
    auctionCostPaymentDescription5Valid,
    setAuctionCostPaymentDescription5Valid,
  ] = useState(null);
  // ご注意
  const [auctionCostAttentionValid, setAuctionCostAttentionValid] =
    useState(null);

  // 必要な書類
  // 必要な書類の案内ページ
  // 案内ページ名
  const [auctionDocumentPageNameValid, setAuctionDocumentPageNameValid] =
    useState(null);
  // URL
  const [auctionDocumentPageUrlValid, setAuctionDocumentPageUrlValid] =
    useState(null);
  // 説明
  const [auctionDocumentDescriptionValid, setAuctionDocumentDescriptionValid] =
    useState(null);

  // 落札者（落札者が法人の場合は代表者）以外の方が権利移転手続きを行う場合
  // 説明
  const [
    auctionOtherTransRightDescriptionValid,
    setAuctionOtherTransRightDescriptionValid,
  ] = useState(null);
  // ご注意
  const [
    auctionOtherTransRightAttentionValid,
    setAuctionOtherTransRightAttentionValid,
  ] = useState(null);

  // 権利移転の時期と要する期間
  // 権利移転の時期
  const [auctionTransRightDateValid, setAuctionTransRightDateValid] =
    useState(null);
  // 登記手続き完了までの期間
  const [
    auctionTransRightCompletedTimeValid,
    setAuctionTransRightCompletedTimeValid,
  ] = useState(null);

  // 2. 重要事項
  // 危険負担
  const [
    auctionImportantRiskBearingValid,
    setAuctionImportantRiskBearingValid,
  ] = useState(null);
  // 引き渡し条件
  const [
    auctionImportantTermsDeliveryValid,
    setAuctionImportantTermsDeliveryValid,
  ] = useState(null);
  // 入札保証金の取り扱い
  const [
    auctionImportantHandlingBidDepositValid,
    setAuctionImportantHandlingBidDepositValid,
  ] = useState(null);
  // 契約保証金の取り扱い
  const [
    auctionImportantHandlingContractDepositValid,
    setAuctionImportantHandlingContractDepositValid,
  ] = useState(null);
  // 使用用途の制限
  const [
    auctionImportantRestrictionUseValid,
    setAuctionImportantRestrictionUseValid,
  ] = useState(null);
  // 移転登記前の使用制限
  const [
    auctionImportantRestrictionUseBeforeValid,
    setAuctionImportantRestrictionUseBeforeValid,
  ] = useState(null);
  // タイトル1
  const [auctionImportantOther1Valid, setAuctionImportantOther1Valid] =
    useState(null);
  // 説明1
  const [
    auctionImportantDescription1Valid,
    setAuctionImportantDescription1Valid,
  ] = useState(null);
  // タイトル2
  const [auctionImportantOther2Valid, setAuctionImportantOther2Valid] =
    useState(null);
  // 説明2
  const [
    auctionImportantDescription2Valid,
    setAuctionImportantDescription2Valid,
  ] = useState(null);
  // タイトル3
  const [auctionImportantOther3Valid, setAuctionImportantOther3Valid] =
    useState(null);
  // 説明3
  const [
    auctionImportantDescription3Valid,
    setAuctionImportantDescription3Valid,
  ] = useState(null);
  // ご注意
  const [auctionImportantAttentionValid, setAuctionImportantAttentionValid] =
    useState(null);

  // 3. お問い合わせ先
  // お問い合わせ先
  const [auctionInquiryNameValid, setAuctionInquiryNameValid] = useState(null);
  // メールアドレス
  const [auctionInquiryEmailValid, setAuctionInquiryEmailValid] =
    useState(null);
  // 電話番号
  const [auctionInquiryTelValid, setAuctionInquiryTelValid] = useState(null);
  // 電話番号1
  const [auctionInquiryTelNum1Valid, setAuctionInquiryTelNum1Valid] =
    useState(null);
  // 電話番号2
  const [auctionInquiryTelNum2Valid, setAuctionInquiryTelNum2Valid] =
    useState(null);
  // 電話番号3
  const [auctionInquiryTelNum3Valid, setAuctionInquiryTelNum3Valid] =
    useState(null);
  // 内線
  const [auctionInquiryExtensionValid, setAuctionInquiryExtensionValid] =
    useState(null);
  // 電話受付時間
  const [auctionInquiryAcceptValid, setAuctionInquiryAcceptValid] =
    useState(null);

  function handleAuctionTransRightDescriptionValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 300);
    if (invalid !== 'TRUE') {
      setAuctionManagementTransValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionManagementTransValid(invalid);
      return;
    }
    setAuctionManagementTransValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostContractTitle1Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setAuctionCostContractTitle1Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostContractTitle1Valid(invalid);
      return;
    }
    setAuctionCostContractTitle1Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostContractDescription1Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionCostContractDescription1Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostContractDescription1Valid(invalid);
      return;
    }
    setAuctionCostContractDescription1Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostContractTitle2Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setAuctionCostContractTitle2Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostContractTitle2Valid(invalid);
      return;
    }
    setAuctionCostContractTitle2Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostContractDescription2Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionCostContractDescription2Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostContractDescription2Valid(invalid);
      return;
    }
    setAuctionCostContractDescription2Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostContractTitle3Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setAuctionCostContractTitle3Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostContractTitle3Valid(invalid);
      return;
    }
    setAuctionCostContractTitle3Valid(invalid); // エラーメッセージのリセット
  }
  function handleAuctionCostContractDescription3Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionCostContractDescription3Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostContractDescription3Valid(invalid);
      return;
    }
    setAuctionCostContractDescription3Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostPaymentTitle1Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentTitle1Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentTitle1Valid(invalid);
      return;
    }
    setAuctionCostPaymentTitle1Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostPaymentDescription1Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentDescription1Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentDescription1Valid(invalid);
      return;
    }
    setAuctionCostPaymentDescription1Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostPaymentTitle2Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentTitle2Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentTitle2Valid(invalid);
      return;
    }
    setAuctionCostPaymentTitle2Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostPaymentDescription2Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentDescription2Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentDescription2Valid(invalid);
      return;
    }
    setAuctionCostPaymentDescription2Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostPaymentTitle3Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentTitle3Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentTitle3Valid(invalid);
      return;
    }
    setAuctionCostPaymentTitle3Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostPaymentDescription3Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentDescription3Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentDescription3Valid(invalid);
      return;
    }
    setAuctionCostPaymentDescription3Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostPaymentTitle4Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentTitle4Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentTitle4Valid(invalid);
      return;
    }
    setAuctionCostPaymentTitle4Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostPaymentDescription4Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentDescription4Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentDescription4Valid(invalid);
      return;
    }
    setAuctionCostPaymentDescription4Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostPaymentTitle5Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentTitle5Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentTitle5Valid(invalid);
      return;
    }
    setAuctionCostPaymentTitle5Valid(invalid); // エラーメッセージのリセット
  }
  function handleAuctionCostPaymentDescription5Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentDescription5Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostPaymentDescription5Valid(invalid);
      return;
    }
    setAuctionCostPaymentDescription5Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionCostAttentionValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionCostAttentionValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionCostAttentionValid(invalid);
      return;
    }
    setAuctionCostAttentionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionDocumentPageNameValid(e, required) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (required) {
      invalid = validateEmptyString(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionDocumentPageNameValid(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = validateRangeLength(e.target.value, 1, 30);
      if (invalid !== 'TRUE') {
        setAuctionDocumentPageNameValid(invalid);
        return;
      }
      invalid = hasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionDocumentPageNameValid(invalid);
        return;
      }
    }
    setAuctionDocumentPageNameValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionDocumentPageUrlValid(e, required) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (required) {
      invalid = validateEmptyString(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionDocumentPageUrlValid(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = validateUrlFormatCheck(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionDocumentPageUrlValid(invalid);
        return;
      }
      invalid = validateUrlLength(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionDocumentPageUrlValid(invalid);
        return;
      }
    }
    setAuctionDocumentPageUrlValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionDocumentDescriptionValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value !== '') {
      invalid = validateRangeLength(e.target.value, 0, 1000);
      if (invalid !== 'TRUE') {
        setAuctionDocumentDescriptionValid(invalid);
        return;
      }
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionDocumentDescriptionValid(invalid);
      return;
    }
    setAuctionDocumentDescriptionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionOtherTransRightDescriptionValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionOtherTransRightDescriptionValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionOtherTransRightDescriptionValid(invalid);
      return;
    }
    setAuctionOtherTransRightDescriptionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionOtherTransRightAttentionValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setAuctionOtherTransRightAttentionValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionOtherTransRightAttentionValid(invalid);
      return;
    }
    setAuctionOtherTransRightAttentionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionTransRightDateValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setAuctionTransRightDateValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionTransRightDateValid(invalid);
      return;
    }
    setAuctionTransRightDateValid(invalid); // エラーメッセージのリセット
  }
  function handleAuctionTransRightCompletedTimeValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setAuctionTransRightCompletedTimeValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionTransRightCompletedTimeValid(invalid);
      return;
    }
    setAuctionTransRightCompletedTimeValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionImportantRiskBearingValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setAuctionImportantRiskBearingValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantRiskBearingValid(invalid);
      return;
    }
    setAuctionImportantRiskBearingValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionImportantTermsDeliveryValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setAuctionImportantTermsDeliveryValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantTermsDeliveryValid(invalid);
      return;
    }
    setAuctionImportantTermsDeliveryValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionImportantHandlingBidDepositValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setAuctionImportantHandlingBidDepositValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantHandlingBidDepositValid(invalid);
      return;
    }
    setAuctionImportantHandlingBidDepositValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionImportantHandlingContractDepositValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setAuctionImportantHandlingContractDepositValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantHandlingContractDepositValid(invalid);
      return;
    }
    setAuctionImportantHandlingContractDepositValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionImportantRestrictionUseValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setAuctionImportantRestrictionUseValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantRestrictionUseValid(invalid);
      return;
    }
    setAuctionImportantRestrictionUseValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionImportantRestrictionUseBeforeValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setAuctionImportantRestrictionUseBeforeValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantRestrictionUseBeforeValid(invalid);
      return;
    }
    setAuctionImportantRestrictionUseBeforeValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionImportantOther1Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setAuctionImportantOther1Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantOther1Valid(invalid);
      return;
    }
    setAuctionImportantOther1Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionImportantDescription1Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setAuctionImportantDescription1Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantDescription1Valid(invalid);
      return;
    }
    setAuctionImportantDescription1Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionImportantOther2Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setAuctionImportantOther2Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantOther2Valid(invalid);
      return;
    }
    setAuctionImportantOther2Valid(invalid); // エラーメッセージのリセット
  }
  function handleAuctionImportantDescription2Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setAuctionImportantDescription2Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantDescription2Valid(invalid);
      return;
    }
    setAuctionImportantDescription2Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionImportantOther3Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setAuctionImportantOther3Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantOther3Valid(invalid);
      return;
    }
    setAuctionImportantOther3Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionImportantDescription3Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setAuctionImportantDescription3Valid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantDescription3Valid(invalid);
      return;
    }
    setAuctionImportantDescription3Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionImportantAttentionValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setAuctionImportantAttentionValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionImportantAttentionValid(invalid);
      return;
    }
    setAuctionImportantAttentionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionInquiryNameValid(e, required) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (required) {
      invalid = validateEmptyString(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionInquiryNameValid(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = validateRangeLength(e.target.value, 1, 50);
      if (invalid !== 'TRUE') {
        setAuctionInquiryNameValid(invalid);
        return;
      }
      invalid = hasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionInquiryNameValid(invalid);
        return;
      }
    }
    setAuctionInquiryNameValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionInquiryEmailValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value !== '') {
      invalid = validateEmailFormatCheck(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionInquiryEmailValid(invalid);
        return;
      }
    }
    setAuctionInquiryEmailValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionInquiryTelValid(e, telData) {
    // logInput(e.target.name, e.target.value);;
    let tel = Object.values(telData).join('');
    let invalid = null;
    invalid = validateRangeLength(tel, 1, 50);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelValid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelValid(invalid);
      return;
    }
    if (tel.length > 0) {
      invalid = validateRangeLength(tel, 10, 11);
      if (invalid !== 'TRUE') {
        setAuctionInquiryTelValid(invalid);
        return;
      }
    }
    setAuctionInquiryTelValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionInquiryTelNum1Valid(tel) {
    let invalid = null;
    invalid = validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum1Valid(invalid);
      return;
    }
    invalid = notInclude(tel, [' ']);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum1Valid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum1Valid(invalid);
      return;
    }
    setAuctionInquiryTelNum1Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionInquiryTelNum2Valid(tel) {
    let invalid = null;
    invalid = validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum2Valid(invalid);
      return;
    }
    invalid = notInclude(tel, [' ']);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum2Valid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum2Valid(invalid);
      return;
    }
    setAuctionInquiryTelNum2Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionInquiryTelNum3Valid(tel) {
    let invalid = null;
    invalid = validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum3Valid(invalid);
      return;
    }
    invalid = notInclude(tel, [' ']);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum3Valid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setAuctionInquiryTelNum3Valid(invalid);
      return;
    }
    setAuctionInquiryTelNum3Valid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionInquiryExtensionValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 10);
    if (invalid !== 'TRUE') {
      setAuctionInquiryExtensionValid(invalid);
      return;
    }
    invalid = notInclude(e.target.value, [' ']);
    if (invalid !== 'TRUE') {
      setAuctionInquiryExtensionValid(invalid);
      return;
    }
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionInquiryExtensionValid(invalid);
      return;
    }
    setAuctionInquiryExtensionValid(invalid); // エラーメッセージのリセット
  }

  function handleAuctionInquiryAcceptValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setAuctionInquiryAcceptValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setAuctionInquiryAcceptValid(invalid);
      return;
    }
    setAuctionInquiryAcceptValid(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      handleAuctionTransRightDescriptionValid,
      handleAuctionCostContractTitle1Valid,
      handleAuctionCostContractDescription1Valid,
      handleAuctionCostContractTitle2Valid,
      handleAuctionCostContractDescription2Valid,
      handleAuctionCostContractTitle3Valid,
      handleAuctionCostContractDescription3Valid,
      handleAuctionCostPaymentTitle1Valid,
      handleAuctionCostPaymentDescription1Valid,
      handleAuctionCostPaymentTitle2Valid,
      handleAuctionCostPaymentDescription2Valid,
      handleAuctionCostPaymentTitle3Valid,
      handleAuctionCostPaymentDescription3Valid,
      handleAuctionCostPaymentTitle4Valid,
      handleAuctionCostPaymentDescription4Valid,
      handleAuctionCostPaymentTitle5Valid,
      handleAuctionCostPaymentDescription5Valid,
      handleAuctionCostAttentionValid,
      handleAuctionDocumentPageNameValid,
      handleAuctionDocumentPageUrlValid,
      handleAuctionDocumentDescriptionValid,
      handleAuctionOtherTransRightDescriptionValid,
      handleAuctionOtherTransRightAttentionValid,
      handleAuctionTransRightDateValid,
      handleAuctionTransRightCompletedTimeValid,
      handleAuctionImportantRiskBearingValid,
      handleAuctionImportantTermsDeliveryValid,
      handleAuctionImportantHandlingBidDepositValid,
      handleAuctionImportantHandlingContractDepositValid,
      handleAuctionImportantRestrictionUseValid,
      handleAuctionImportantRestrictionUseBeforeValid,
      handleAuctionImportantOther1Valid,
      handleAuctionImportantDescription1Valid,
      handleAuctionImportantOther2Valid,
      handleAuctionImportantDescription2Valid,
      handleAuctionImportantOther3Valid,
      handleAuctionImportantDescription3Valid,
      handleAuctionImportantAttentionValid,
      handleAuctionInquiryNameValid,
      handleAuctionInquiryEmailValid,
      handleAuctionInquiryTelValid,
      handleAuctionInquiryTelNum1Valid,
      handleAuctionInquiryTelNum2Valid,
      handleAuctionInquiryTelNum3Valid,
      handleAuctionInquiryExtensionValid,
      handleAuctionInquiryAcceptValid,
    },

    // invalidの時のメッセージ
    validationMessage: {
      auctionManagementTransValid,
      auctionCostContractTitle1Valid,
      auctionCostContractDescription1Valid,
      auctionCostContractTitle2Valid,
      auctionCostContractDescription2Valid,
      auctionCostContractTitle3Valid,
      auctionCostContractDescription3Valid,
      auctionCostPaymentTitle1Valid,
      auctionCostPaymentDescription1Valid,
      auctionCostPaymentTitle2Valid,
      auctionCostPaymentDescription2Valid,
      auctionCostPaymentTitle3Valid,
      auctionCostPaymentDescription3Valid,
      auctionCostPaymentTitle4Valid,
      auctionCostPaymentDescription4Valid,
      auctionCostPaymentTitle5Valid,
      auctionCostPaymentDescription5Valid,
      auctionCostAttentionValid,
      auctionDocumentPageNameValid,
      auctionDocumentPageUrlValid,
      auctionDocumentDescriptionValid,
      auctionOtherTransRightDescriptionValid,
      auctionOtherTransRightAttentionValid,
      auctionTransRightDateValid,
      auctionTransRightCompletedTimeValid,
      auctionImportantRiskBearingValid,
      auctionImportantTermsDeliveryValid,
      auctionImportantHandlingBidDepositValid,
      auctionImportantHandlingContractDepositValid,
      auctionImportantRestrictionUseValid,
      auctionImportantRestrictionUseBeforeValid,
      auctionImportantOther1Valid,
      auctionImportantDescription1Valid,
      auctionImportantOther2Valid,
      auctionImportantDescription2Valid,
      auctionImportantOther3Valid,
      auctionImportantDescription3Valid,
      auctionImportantAttentionValid,
      auctionInquiryNameValid,
      auctionInquiryEmailValid,
      auctionInquiryTelValid,
      auctionInquiryTelNum1Valid,
      auctionInquiryTelNum2Valid,
      auctionInquiryTelNum3Valid,
      auctionInquiryExtensionValid,
      auctionInquiryAcceptValid,
    },
  };

  return validations;
};
