export const convertToJp = (mappingData, key) => {
  return mappingData[key]
}

export function convertRadioToJP(radioLabel,display) {
  return radioLabel[display] ?? "NO_DATA"
}

export const convertCheckBox = (value, convertRule) => {
  return value ? convertRule.on : convertRule.off
}

export const convertTimestampToJp = (timestamp) => {
  const date = new Date(timestamp)
  const yyyy = date.getFullYear()
  const mm = `0${date.getMonth() + 1}`.slice(-2)
  const dd = `0${date.getDate()}`.slice(-2)

  return `${yyyy}年${mm}月${dd}日`
}