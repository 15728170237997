// ロジックを含む処理
// フロントから呼ぶ関数

//import { createDivisionData, updateDivisionData, deleteDivisionData } from "../../../infrastructure/api/Division.js";
import { updateDivisionData, deleteDivisionData } from "../../../infrastructure/api/Division.js";
import {
  createNaviDivisionsData,
  deleteNaviDivisionsData,
  updateNaviDivisionsData
} from "../../../infrastructure/api/NaviDivisions.js";
import { compareData } from "../../../shared/utils/helper/compareData.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { DivisionCreate } from "../entities/Division.js";
import { getDivision } from "../repositories/Division.js";

export async function get(type) {
  return await getDivision(type)
}

export async function findById(type, id) {
  const Division = await getDivision(type);
  return Division.find(data => data.id.toString() === id.toString())
}

export async function findByGovernmentId(type, governmentId) {
  const divisions = await getDivision(type);
  return divisions.filter(data => data.governmentId.toString() === governmentId.toString())
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Division)');
  }
  if (type === NAVI) {
    const division = new DivisionCreate(data)
    response = await createNaviDivisionsData(division);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Division)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Division', response);
  }
  return response
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Division)');
  }
  if (type === NAVI) {
    const division = new DivisionCreate(data)
    response = await updateNaviDivisionsData(division);
  }
  if (type === KMANAGER) {
    response = await updateDivisionData(data, data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Division', response);
  }
  return response
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Division)');
  }
  if (type === NAVI) {
    response = deleteNaviDivisionsData(data.id);
  }
  if (type === KMANAGER) {
    response = await deleteDivisionData(data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Division', response);
  }
  return response
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]))
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]))
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value)
}
