import "interfaces/css/PublishedObject/property-list-search-input.css"

const PropertyListSearchInput = ({label, style}) => {
  return (
    <div style={style} className='property-list'>
      <span className='property-list-search-input'>
        <input className='text-field' data-testid="property-list-search-input" placeholder={label}/>
      </span>
    </div>
  );
}
export default PropertyListSearchInput