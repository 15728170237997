import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { findById as findAuctionById } from '../../../../domain/Auction/services/Auction.js';
import { findById as findInvitationById } from '../../../../domain/Auction/services/Invitation.js';
import { findByPropertyId as findBidderByPropertyId } from '../../../../domain/Bid/services/Bid';
import { findById as findGovernmentById } from '../../../../domain/Government/services/Government.js';
import { findByAuctionIdAndGovernmentId as getProperty } from '../../../../domain/Property/services/Property.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { NAVI } from '../../../../shared/utils/helper/constants.js';
import { BillingContext } from '../entities/BillingContext.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';

const BillingProvider = ({ type, children }) => {
  //const { governmentId, governmentType, procedureType } = useAuth();
  const { governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [invitation, setInvitation] = useState([]);
  const [auction, setAuction] = useState([]);
  const [property, setProperty] = useState([]);
  const [government, setGovernment] = useState([]);
  const { localStorageGovernmentId } = useLocalStorage();
  const [governmentId, setGovernmentId] = useState('');
  const [bidders, setBidder] = useState([]);

  const { auction_id } = useParams();

  if (type === NAVI) {
    useEffect(() => {
      findInvitationById(type, auction_id, localStorageGovernmentId).then(
        (invitationResult) => {
          if (invitationResult !== undefined) {
            setInvitation(invitationResult);
            const auctionId = invitationResult.auctionId;
            findAuctionById(type, auctionId).then((result) => {
              setAuction(result);
            });
          }
        },
      );
    }, [localStorageGovernmentId]);

    useEffect(() => {
      setGovernmentId(localStorageGovernmentId);
    }, [localStorageGovernmentId]);

    useEffect(() => {
      getProperty(type, auction_id, localStorageGovernmentId).then(
        (resultProperties) => {
          setProperty(resultProperties);

          let bidders = [];
          resultProperties.map((property) => {
            findBidderByPropertyId(type, property.id).then((bidder) => {
              bidder.forEach((item) => bidders.push(item));
            });
          });
          setBidder(bidders);
        },
      );
    }, [localStorageGovernmentId]);

    useEffect(() => {
      findGovernmentById(type, localStorageGovernmentId).then((result) => {
        setGovernment(result);
      });
    }, [localStorageGovernmentId]);
  }

  return (
    <BillingContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        invitation,
        auction,
        property,
        government,
        bidders,
      }}>
      {children}
    </BillingContext.Provider>
  );
};

export default BillingProvider;
