import LightButton from "interfaces/components/common/Button/LightButton.jsx";
import PrimaryButton from "interfaces/components/common/Button/PrimaryButton.jsx";
import InputInformationTable from "interfaces/components/Property/List/InputInformationTable.jsx";
import PropertyTable from "interfaces/components/Property/List/PropertyTable.jsx";

import "interfaces/css/PropertyList/copy-dialog.css";

const CopyDialog = ({ cancelButtonOnClick }) => {
    return (
        <div className="copy-dialog-wrap">
            <div className="copy-dialog" data-testid="copy-dialog">
                <div className="copy-dialog-inner">
                    <div className="dialog-title" data-testid="copy-dialog-title">物件データのコピー | </div>
                    <div className="dialog-body">
                        <div data-testid="copy-dialog-description">物件データをコピーした場合は、以下の項目の選択内容はそれぞれ同じ内容が設定されます。<br/>
                            以下の項目以外の内容は、元の物件データがそのままコピーされます。<br/>
                            *は必須項目です。必ず選択してください。</div>
                        <InputInformationTable/>
                        <PropertyTable/>
                    </div>
                    <hr className="divider"/>
                    <div className="actions">
                        <div className="spacer"></div>
                        <LightButton onClick={cancelButtonOnClick}>キャンセル</LightButton>
                        <PrimaryButton disabled={true}>登録</PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CopyDialog;
