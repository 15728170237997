import GovernmentNewProvider from './GovernmentNewProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { governmentNew } from "../entities/componentMapping.js";

const GovernmentNew = ({ type }) => {
  return (
    <GovernmentNewProvider type={type}>
      {getComponent(governmentNew, type)}
    </GovernmentNewProvider>
  );
};

export default GovernmentNew;
