import { DATA_EMPTY } from 'shared/utils/helper/constants';

const isEmpty = [DATA_EMPTY, undefined, ''];

export const estate_basic_info = (data) => [
  {
    text: '登記簿の表示',
    state: isEmpty.includes(data?.propertyPrint)
      ? '未入力'
      : data?.propertyPrint,
  },
  {
    text: '所在地',
    state: isEmpty.includes(data?.locationText) ? '未入力' : data?.locationText,
  },
  {
    text: '住居表示',
    state: isEmpty.includes(data?.residenceLocation)
      ? '未入力'
      : data?.residenceLocation,
  },
  {
    text: '交通、最寄りの駅など',
    state: isEmpty.includes(data?.nearestStation)
      ? '未入力'
      : data?.nearestStation,
  },
  {
    text: '所在図',
    state: isEmpty.includes(data?.locationUrl) ? '未入力' : data?.locationUrl,
  },
  {
    text: '現況',
    state: isEmpty.includes(data?.estateStatus) ? '未入力' : data?.estateStatus,
  },
  {
    text: 'その他事項',
    state: isEmpty.includes(data?.other) ? '未入力' : data?.other,
  },
  {
    text: '見取り図など',
    state: isEmpty.includes(data?.sketchUrl) ? '未入力' : data?.sketchUrl,
  },
  {
    text: '説明',
    state: isEmpty.includes(data?.description) ? '未入力' : data?.description,
  },
];
