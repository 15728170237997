import { getProposals } from 'infrastructure/api/storage/proposals';
import { useState, useEffect, useCallback } from 'react';
import { useError } from 'shared/hooks/useError';
import camelizeKeys from 'shared/utils/helper/camelize';

export const useProposals = () => {
  const { setError } = useError();
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchProposals = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getProposals();
      setProposals(camelizeKeys(data));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchProposals();
  }, [fetchProposals]);
  return { proposals, loading };
};
