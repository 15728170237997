import { ReactComponent as MapIcon } from 'interfaces/assets/svg/map.svg';
import { ReactComponent as YouTubeIcon } from 'interfaces/assets/svg/youtube.svg';
import { Link } from 'react-router-dom';

const CustomPrice = ({ data }) => {
  if (!data) {
    return;
  }
  if (data.type === 'link') {
    return (
      <td>
        <Link
          to={data.value}
          className="d-flex"
          target="_blank"
          data-testid="video-url">
          <YouTubeIcon title="youtube-icon" className="youtube-icon" />
          動画をみる
        </Link>
      </td>
    );
  }
  if (data.type === 'map') {
    return (
      <td>
        <Link
          to={data.value}
          className="d-flex"
          target="_blank"
          data-testid="map-url">
          <MapIcon title="map-url" className="youtube-icon" />
          地図を見る
        </Link>
      </td>
    );
  }

  if (typeof data.value !== 'string') {
    return <td>{data.value}</td>;
  }

  const match = data.value.match(/(.*?)(円（.*?万円）)/);
  if (!match) {
    return <td>{data.value}</td>;
  }

  const [part1, part2] = match;
  return (
    <td style={{ color: 'red' }}>
      <span>{part1}</span>
      <span>
        <small>{part2}</small>
      </span>
    </td>
  );
};

export default CustomPrice;
