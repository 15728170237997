import {
  MobileDateTimePicker,
  DateTimePickerToolbar,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { ValidationMessage } from 'interfaces/components/common/ValidationMessage.jsx';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { dateFormat } from 'shared/utils/helper/date_format.js';

import FormLabel from './FormLabel.jsx';

export const slotProps = (date, setOpen, { placeholder = '' }) => {
  const inputValue = () => {
    const formatDate = dateFormat(date, 'Y-M-D hh:mm');
    if (formatDate === 'DATA_EMPTY') {
      return '';
    }
    return formatDate;
  };

  return {
    inputAdornment: {
      position: 'start',
    },
    textField: {
      readOnly: true,
      onClick: () => setOpen(true),
      InputProps: {
        placeholder: placeholder,
        value: inputValue(),
      },
      sx: {
        maxWidth: '500px',
        '& .MuiOutlinedInput-root': {
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
        '& .MuiInputAdornment-root .MuiSvgIcon-root': {
          color: '#f78c23',
        },
        '& .MuiTypography-overline': {
          display: 'none',
        },
        '& [role="tab"][aria-selected="true"]': {
          backgroundColor: '#f78c23',
        },
      },
    },
  };
};

const InputDateTime = React.memo(function InputDateTime({
  invalidMessage,
  required,
  label,
  state,
  setState,
  placeholder,
}) {
  const [open, setOpen] = useState(false);
  return (
    <div className="kmanager-row">
      <div className="kmanager-col-4">
        <FormLabel label={label} required={required} />
      </div>
      <div className="kmanager-col form-area flex-column">
        <div style={{ position: 'relative' }} className={'form-input'}>
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            dateFormats={{
              dayOfMonth: 'd日',
              dayOfMonthFull: 'dd日',
              fullDate: 'yyyy年MM月dd日',
              fullTime: 'HH:mm:ss',
              fullTime12h: 'hh:mm:ss a',
              fullTime24h: 'HH:mm:ss',
              hours12h: 'hh',
              hours24h: 'HH',
              keyboardDate: 'yyyy/MM/dd',
              keyboardDateTime: 'yyyy/MM/dd HH:mm',
              keyboardDateTime12h: 'yyyy/MM/dd hh:mm a',
              keyboardDateTime24h: 'yyyy/MM/dd HH:mm',
              meridiem: 'a',
              minutes: 'mm',
              month: 'MM月',
              monthShort: 'MMM',
              normalDate: 'yyyy年MM月dd日',
              normalDateWithWeekday: 'yyyy年MM月dd日 (EEE)',
              seconds: 'ss',
              shortDate: 'MM/dd',
              weekday: 'EEEE',
              weekdayShort: 'EEE',
              year: 'yyyy年',
            }}>
            <MobileDateTimePicker
              value={DateTime.fromJSDate(new Date(state))}
              onChange={(e) => setState(e.toJSDate())}
              // slotPropsの使い方:
              // slotPropsは、カスタムプロパティを渡すために使用されます。
              // ここでは、state, setOpen, placeholderを渡しています。
              slotProps={slotProps(state, setOpen, {
                placeholder: placeholder,
              })}
              // slotsの使い方:
              // slotsは、特定の条件に基づいてコンポーネントを動的に変更するために使用されます。
              // ここでは、stateの長さが0より大きい場合にDateTimePickerToolbarを表示します。
              slots={{
                toolbar:
                  typeof state === 'string' ? null : DateTimePickerToolbar,
              }}
              open={open}
              onClose={() => setOpen(false)}
              views={['year', 'month', 'day', 'hours', 'minutes']}
              dayOfWeekFormatter={(date) =>
                dateFormat(new Date(date), 'weekdayJS')
              }
              ampm={false}
              dateFormats={{ monthAndYear: 'yyyy年 MM月' }}
            />
          </LocalizationProvider>
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
      </div>
    </div>
  );
});
export default InputDateTime;
