import Breadcrumb from "interfaces/components/common/Breadcrumb";
import Heading2 from "interfaces/components/common/Heading/Heading2";
import ContactUs from "interfaces/components/Faq/List/ContactUs";
import FaqList from "interfaces/components/Faq/List/FaqList";
import {breadcrumbs} from "interfaces/pages/Faq/entities/breadcrumbs";

import {useLocalStorage} from "../../../../shared/hooks/useLocalStorage";

const Faq = () => {
    const { localStorageProcedureType } = useLocalStorage();
    return (
        <div className="faq new-style_navi_faq" data-testid="faq">
            <Breadcrumb breadcrumbs={breadcrumbs}/>
            <Heading2 title="よくある質問、お問い合わせ先"/>
            <FaqList procedureType={localStorageProcedureType}/>
            <ContactUs procedureType={localStorageProcedureType}/>
        </div>
    );
};

export default Faq;

