import { dateFormat } from './date_format';

export const datePickerSlotProps = ({ date, setOpen, placeholder = '' }) => {
  const inputValue = () => {
    const formatDate = dateFormat(date, 'Y-M-D');
    if (formatDate === 'DATA_EMPTY') {
      return '';
    }
    return formatDate;
  };

  return {
    calendarHeader: { format: 'yyyy-MM-dd' },
    inputAdornment: {
      position: 'start',
    },
    textField: {
      readOnly: true,
      onClick: () => setOpen(true),
      InputProps: {
        placeholder: placeholder,
        value: inputValue(),
      },
      sx: {
        maxWidth: '200px',
        '& .MuiOutlinedInput-root': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.38)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f78c23',
          },
        },
        '& .MuiInputAdornment-root .MuiSvgIcon-root': {
          color: '#f78c23',
        },
      },
    },
  };
};
