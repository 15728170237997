
export class SeverityType {
  #severityType

  constructor(severityType) {
    this.#severityType = severityType;
  }

  get() {
    return this.#severityType
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
