import AuctionDeposit from "interfaces/components/Auction/kmanager/AuctionDeposit/AuctionDeposits";

const KmanagerAuctionDeposit = () => {
    return (
        <div data-testid="kmanager-auction-government">
            <AuctionDeposit/>
        </div>
    );
}

export default KmanagerAuctionDeposit;