
export function filterByKey(dataList, key, value) {
  return dataList.filter(data => {
    return data[key] === value;
  });
}

export function filterByKeys(dataList, conditions) {
  if (conditions === null) {
    return [];
  }

  if (typeof conditions !== 'object') {
    return [];
  }

  return dataList.filter((data) => {
    for (const key in conditions) {
      if (data[key] !== conditions[key]) return false;
    }
    return true;
  });
}
