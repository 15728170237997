import ApplicationProvider from './ApplicationProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { application } from "../entities/componentMapping.js";

const Application = ({ type }) => {
  return (
    <ApplicationProvider type={type}>
      {getComponent(application, type)}
    </ApplicationProvider>
  );
};

export default Application;
