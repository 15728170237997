// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-accordion {
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.common-accordion-panel {
  padding: 16px 24px;
  cursor: pointer;
}

.common-accordion-label {
  display: flex;
  width: 100%;
  margin-left: 5px;
}

.common-accordion-container {
  width: 100%;
  max-width: 100%;
  padding: 0 24px 16px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/common-accordion.css"],"names":[],"mappings":"AAAA;EACE,wBAAmB;EAAnB,mBAAmB;EACnB,uBAAuB;EACvB,4GAA4G;AAC9G;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,oBAAoB;AACtB","sourcesContent":[".common-accordion {\n  height: fit-content;\n  background-color: white;\n  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);\n}\n\n.common-accordion-panel {\n  padding: 16px 24px;\n  cursor: pointer;\n}\n\n.common-accordion-label {\n  display: flex;\n  width: 100%;\n  margin-left: 5px;\n}\n\n.common-accordion-container {\n  width: 100%;\n  max-width: 100%;\n  padding: 0 24px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
