import {Link, useParams} from "react-router-dom";
import Breadcrumb from "interfaces/components/common/Breadcrumb";
import Heading2 from "interfaces/components/common/Heading/Heading2";
import { breadcrumbs } from 'interfaces/pages/PropertyInfoEstate/entities/breadcrumbs'
import "interfaces/css/PropertyInfoEstate/property-info-estate.css";
import "interfaces/css/common/property-info-general-form.css"
import {PropertyInfoEstateContext} from "interfaces/pages/PropertyInfoEstate/entities/PropertyInfoEstateContext";
import {useContext} from "react";

const PropertyInfoEstateComplete = ({method, openWay}) => {
    const { auction_id } = useParams();
    const { invitation, property } = useContext(PropertyInfoEstateContext);

    const getTitle = () => {
        if (method === 'create') {
            return `登録完了：物件情報（不動産）登録：${invitation?.name}`;
        }
        return `編集完了：${invitation?.name}：${property?.name}`
    }

    return (
        <div className="property-info-estate">
            <Breadcrumb breadcrumbs={breadcrumbs(method, 'complete', invitation?.name, auction_id, openWay, property?.name)}/>
            <div className="pt-5">
                <Heading2 title={getTitle()}/>
            </div>
            <div className="complete-message pt-9 mb-6">
                {
                    method === 'create' ?
                        <div>物件作成完了しました。</div> :
                        <div>{`${property?.name} を更新しました。`}</div>

                }
            </div>
            <div className="description pt-3">
                <Link to={`/navi/object/${auction_id}`}>{invitation.name}へ</Link>
            </div>
            <div className="description pt-3">
                <Link to={'/navi'}>トップページへ</Link>
            </div>
        </div>
    );
}

export default PropertyInfoEstateComplete;
