
export class AplType {
  #aplType

  constructor(aplType) {
    this.#aplType = aplType;
  }

  get() {
    return this.#aplType
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
