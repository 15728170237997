import { useContext, useEffect, useState } from 'react';
import { DashboardContext } from '../../../../../../pages/Dashboard/entities/DashboardContext.js';

import TopicCard from '../../../../../common/TopicCard.jsx';
import HeldInfo from './HeldInfo.jsx';
import DeadlineInfo from './DeadlineInfo.jsx';

import { TextCaption } from 'interfaces/components/common/TextCaption/index';

const ScheduleTopic = () => {
  const { progressSchedules } = useContext(DashboardContext);

  let now = new Date();
  let year = now.getFullYear();
  let month = (now.getMonth() + 1).toString().padStart(2, '0');
  let day = now.getDate().toString().padStart(2, '0');
  let hours = now.getHours().toString().padStart(2, '0');
  let minutes = now.getMinutes().toString().padStart(2, '0');
  let nowString = `${year}-${month}-${day} ${hours}:${minutes}`;

  const [isApplying, setIsApplying] = useState(false);

  useEffect(() => {
    progressSchedules?.some((schedule) => {
      if (schedule.aplStart <= nowString && nowString <= schedule.aplEnd) {
        setIsApplying(true);
        return true;
      }
    });
  }, [progressSchedules]);

  return (
    <div data-testid="schedule-topic">
      <TopicCard
        title="スケジュール"
        body={
          <TextCaption>
            <HeldInfo auctionStatus={isApplying} />
            {progressSchedules?.length && <DeadlineInfo />}
          </TextCaption>
        }
      />
    </div>
  );
};

export default ScheduleTopic;
