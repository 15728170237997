import { createContext } from 'react';

import { breadcrumbs } from './breadcrumbs.js';
import { DATA_EMPTY } from '../../../../shared/utils/helper/constants.js';

export const ApplicationContext = createContext({
  // Default Value
  auctionUser: DATA_EMPTY,
  breadcrumbs: breadcrumbs,
  governmentId: DATA_EMPTY,
  guidelineInformation: DATA_EMPTY,
  isLoggedIn: DATA_EMPTY,
  preregistrationApplicantInformation: DATA_EMPTY,
  procedureTypeEn: DATA_EMPTY,
  property: DATA_EMPTY,
  publicSaleType: DATA_EMPTY,
  responsive: DATA_EMPTY,
});
