
export class CommonMessageType {
  #commonMessageType

  constructor(commonMessageType) {
    this.#commonMessageType = commonMessageType;
  }

  get() {
    return this.#commonMessageType
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
