import { findById as getAuction } from 'domain/Auction/services/Auction.js';
import { findByAuctionId as getInvitations } from 'domain/Auction/services/Invitation';
import { findByAuctionId as getDeposits } from 'domain/Deposit/services/Deposit';
import { get as getDivisions } from 'domain/Division/services/Division';
import { get as getGovernments } from 'domain/Government/services/Government.js';
import { findByAuctionId as getProperties } from 'domain/Property/services/Property';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from 'shared/utils/helper/authState.js';
import { KMANAGER } from 'shared/utils/helper/constants.js';

import { AuctionDepositContext } from '../entities/AuctionDepositContext.js';

const AuctionDepositProvider = ({ type, children }) => {
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const { auctionId } = useParams();
  const [auction, setAuction] = useState([]);
  const [auctionDeposits, setAuctionDeposits] = useState([]);

  if (type === KMANAGER) {
    useEffect(() => {
      getInvitations(type, auctionId).then((resultInvitations) => {
        getAuction(type, auctionId).then((resultAuction) => {
          setAuction(resultAuction);
          getGovernments(type).then((resultGovernments) => {
            getDivisions(type).then((resultDivisions) => {
              getDeposits(type, auctionId).then((resultDeposits) => {
                getProperties(type, auctionId).then((resultProperties) => {
                  let auctionDeposits = [];

                  resultGovernments.forEach((government) => {
                    let invitation = resultInvitations.find(
                      (invitation) =>
                        invitation.auctionId.toString() ===
                          auctionId.toString() &&
                        invitation.governmentId.toString() === government.id,
                    );
                    if (invitation === undefined) {
                      return;
                    }
                    let auctionDeposit = {};
                    auctionDeposit.location = government.location;
                    auctionDeposit.region = government.region;
                    auctionDeposit.governmentSubCategoryId =
                      government.governmentSubCategoryId;
                    auctionDeposit.governmentName = government.name;

                    let division = resultDivisions.find(
                      (division) =>
                        division.governmentId.toString() ===
                        government.id.toString(),
                    );
                    if (division !== undefined) {
                      auctionDeposit.divisionName = division.name;
                    } else {
                      auctionDeposit.divisionName = '';
                    }

                    if (resultDeposits !== undefined) {
                      let deposit = resultDeposits.find(
                        (deposit) =>
                          deposit.divisionId.toString() ===
                          division.id.toString(),
                      );
                      if (deposit !== undefined) {
                        auctionDeposit.estimatedInvoiceDate =
                          deposit.estimatedInvoiceDate;
                        auctionDeposit.estimatedReturnDate =
                          deposit.estimatedReturnDate;
                        auctionDeposit.returnDate = deposit.returnDate;
                      } else {
                        auctionDeposit.estimatedInvoiceDate = '';
                        auctionDeposit.estimatedReturnDate = '';
                        auctionDeposit.returnDate = '';
                      }
                    } else {
                      auctionDeposit.estimatedInvoiceDate = '';
                      auctionDeposit.estimatedReturnDate = '';
                      auctionDeposit.returnDate = '';
                    }

                    let properties = resultProperties.filter(
                      (property) =>
                        property.governmentId.toString() ===
                        government.id.toString(),
                    );
                    let depositProxy = 0; // 保証金納付額
                    let depositRefundClaim = 0; // 保証金返還額
                    properties.forEach((property) => {
                      depositProxy += isNaN(Number(property.depositProxy))
                        ? 0
                        : Number(property.depositProxy);
                      depositRefundClaim += isNaN(Number(property.depositRefundClaim))
                        ? 0
                        : Number(property.depositRefundClaim);
                    });
                    auctionDeposit.depositProxy = depositProxy;
                    auctionDeposit.depositRefundClaim = depositRefundClaim;

                    auctionDeposit.depositPaymentDate =
                      resultAuction.depositPaymentDate;
                    auctionDeposit.governmentId = government.id;
                    auctionDeposits.push(auctionDeposit);
                  });
                  setAuctionDeposits(auctionDeposits);
                });
              });
            });
          });
        });
      });
    }, [auctionId]);
  }

  return (
    <AuctionDepositContext.Provider
      value={{
        authState,
        auction,
        auctionDeposits,
      }}>
      {children}
    </AuctionDepositContext.Provider>
  );
};

export default AuctionDepositProvider;
