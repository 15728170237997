import { getAuctionSchedule } from 'domain/Auction/services/Auction';

import { AuctionScheduleContext } from 'interfaces/pages/AuctionSchedule/entities/AuctionScheduleContext.js';
import { useEffect, useState } from 'react';

//import { SERVICE, NAVI, KMANAGER } from "../../../../shared/utils/helper/constants.js";
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { NAVI } from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';

// storageのjson形式が決まったらロジックを修正する

const AuctionScheduleProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [schedules, setSchedules] = useState({
    thisYear: {
      year: '',
      auctions: [],
    },
    nextYear: {
      year: '',
      auctions: [],
    },
  });
  const { localStorageProcedureType } = useLocalStorage();

  if (type === NAVI) {
    useEffect(() => {
      getAuctionSchedule(type, localStorageProcedureType).then((result) => {
        const thisFiscalYear = calcFiscalYear();
        const nextFiscalYear = thisFiscalYear + 1;
        const thisFiscalYearAuctionData = filterByFiscalDate(
          result,
          thisFiscalYear,
        );
        const nextFiscalYearAuctionData = filterByFiscalDate(
          result,
          nextFiscalYear,
        );
        setSchedules({
          thisYear: {
            year: thisFiscalYear,
            auctions:
              thisFiscalYearAuctionData.length > 0
                ? thisFiscalYearAuctionData
                : [],
          },
          nextYear: {
            year: nextFiscalYear,
            auctions:
              nextFiscalYearAuctionData.length > 0
                ? nextFiscalYearAuctionData
                : [],
          },
        });
      });
    }, [localStorageProcedureType]);
  }

  return (
    <AuctionScheduleContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        schedules,
      }}>
      {children}
    </AuctionScheduleContext.Provider>
  );
};

const calcFiscalYear = () => {
  // 現在の日付を取得
  const today = new Date();
  // 年を取得
  const year = today.getFullYear();
  // 月を取得
  const month = today.getMonth() + 1;

  // 年度を取得
  const fiscalYear = month >= 4 ? year : year - 1;
  return fiscalYear;
};

const filterByFiscalDate = (dataList, fiscalYear) => {
  return dataList.filter((data) => {
    return isIncludeDate(fiscalYear, data['aplStart']);
  });
};

const isIncludeDate = (year, date) => {
  const firstDateFormat = `${year + 1}-04-01 00:00:00`;
  const pastLimitFormat = `${year}-04-01 00:00:00`;

  const firstDate = new Date(firstDateFormat);
  const pastLimitDate = new Date(pastLimitFormat);
  const targetDate = new Date(date);

  if (targetDate.getTime() === pastLimitDate.getTime()) {
    return true;
  }

  return (
    firstDate.getTime() > targetDate.getTime() &&
    targetDate.getTime() > pastLimitDate.getTime()
  );
};
export default AuctionScheduleProvider;
