// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-list .property-table-wrap {
    margin-top: 20px;
    background-color: #fff;
    color: rgba(0,0,0,.87);
    line-height: 1.5;
    max-width: 100%;
}

.property-list .property-table {
    overflow-x: auto;
    overflow-y: hidden;
}

.property-list .property-table-wrap > .property-table > table {
    width: 100%;
    border-spacing: 0;
}

.property-list .property-table-wrap > .property-table > table > thead > tr > th {
    border-bottom: thin solid rgba(0,0,0,.12);
    color: rgba(0,0,0,.6);
    height: 32px;
    -webkit-user-select: none;
            user-select: none;
    font-size: .75rem;
    padding: 0 16px;
    transition: height .2s cubic-bezier(.4,0,.6,1);
    white-space: nowrap;
    text-align: left;
}

.property-list .property-table-wrap > .property-table > table > tbody > tr > td {
    height: 32px;
    font-size: .875rem;
    padding: 0 16px;
    transition: height .2s cubic-bezier(.4,0,.6,1);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertyList/property-table.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,yCAAyC;IACzC,qBAAqB;IACrB,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;IACf,8CAA8C;IAC9C,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,8CAA8C;AAClD","sourcesContent":[".property-list .property-table-wrap {\n    margin-top: 20px;\n    background-color: #fff;\n    color: rgba(0,0,0,.87);\n    line-height: 1.5;\n    max-width: 100%;\n}\n\n.property-list .property-table {\n    overflow-x: auto;\n    overflow-y: hidden;\n}\n\n.property-list .property-table-wrap > .property-table > table {\n    width: 100%;\n    border-spacing: 0;\n}\n\n.property-list .property-table-wrap > .property-table > table > thead > tr > th {\n    border-bottom: thin solid rgba(0,0,0,.12);\n    color: rgba(0,0,0,.6);\n    height: 32px;\n    user-select: none;\n    font-size: .75rem;\n    padding: 0 16px;\n    transition: height .2s cubic-bezier(.4,0,.6,1);\n    white-space: nowrap;\n    text-align: left;\n}\n\n.property-list .property-table-wrap > .property-table > table > tbody > tr > td {\n    height: 32px;\n    font-size: .875rem;\n    padding: 0 16px;\n    transition: height .2s cubic-bezier(.4,0,.6,1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
