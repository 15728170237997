import GrandChildCategory from '../../common/Category/GrandChildCategory.jsx';

const GrandChildCategories = ({ categories, setInputs, parentId }) => {
  return Object.entries(categories).map(([id, category]) => (
    <GrandChildCategory
      key={id}
      category={category}
      setInputs={setInputs}
      parentId={parentId}
    />
  ));
};

export default GrandChildCategories;
