import { useEffect, useState } from 'react';

import { get as getApplicantPropertyIds } from '../../../../domain/Applicant/services/ApplicantInformation.js';
import { get as getProposals } from '../../../../domain/Applicant/services/Proposal.js';
import { get as getBidInformation } from '../../../../domain/Bid/services/BidInfo.js';
import { get as getGeneralCategories } from '../../../../domain/Category/services/PropertyCategory.js';
import { get as getFavoritePropertyIds } from '../../../../domain/Favorite/services/Favorite.js';
import { get as getAllowPropertyIds } from '../../../../domain/Property/services/Property.js';
import { get as getProperties } from '../../../../domain/Property/services/Property.js';
import { get as getMyUserInformation } from '../../../../domain/User/services/ServiceUser.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { MylistContext } from '../entities/MylistContext.js';
const MylistProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [favoritePropertyIds, setFavoritePropertyIds] = useState([]);
  const [generalCategories, setGeneralCategories] = useState([]);
  const [bidInformation, setBidInformation] = useState([]);
  const [applicantPropertyIds, setApplicantPropertyIds] = useState([]);
  const [myUserInformation, setMyUserInformation] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [allowPropertyIds, setAllowPropertyIds] = useState([]);
  const [properties, setProperties] = useState([]);
  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getFavoritePropertyIds(type).then((result) => {
        setFavoritePropertyIds(result);
      });
    }, []);

    useEffect(() => {
      getGeneralCategories(type).then((result) => {
        setGeneralCategories(result);
      });
    }, []);

    useEffect(() => {
      getBidInformation(type).then((result) => {
        setBidInformation(result);
      });
    }, []);

    useEffect(() => {
      getApplicantPropertyIds(type).then((result) => {
        setApplicantPropertyIds(result);
      });
    }, []);

    useEffect(() => {
      getMyUserInformation(type).then((result) => {
        setMyUserInformation(result);
      });
    }, []);

    useEffect(() => {
      getProposals(type).then((result) => {
        setProposals(result);
      });
    }, []);

    useEffect(() => {
      getAllowPropertyIds(type).then((result) => {
        setAllowPropertyIds(result);
      });
    }, []);

    useEffect(() => {
      getProperties(type).then((result) => {
        setProperties(result);
      });
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      getFavoritePropertyIds(type).then((result) => {
        setFavoritePropertyIds(result);
      });
    }, []);

    useEffect(() => {
      getGeneralCategories(type).then((result) => {
        setGeneralCategories(result);
      });
    }, []);

    useEffect(() => {
      getBidInformation(type).then((result) => {
        setBidInformation(result);
      });
    }, []);

    useEffect(() => {
      getApplicantPropertyIds(type).then((result) => {
        setApplicantPropertyIds(result);
      });
    }, []);

    useEffect(() => {
      getMyUserInformation(type).then((result) => {
        setMyUserInformation(result);
      });
    }, []);

    useEffect(() => {
      getProposals(type).then((result) => {
        setProposals(result);
      });
    }, []);

    useEffect(() => {
      getAllowPropertyIds(type).then((result) => {
        setAllowPropertyIds(result);
      });
    }, []);

    useEffect(() => {
      getProperties(type).then((result) => {
        setProperties(result);
      });
    }, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <MylistContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        favoritePropertyIds,
        generalCategories,
        bidInformation,
        applicantPropertyIds,
        myUserInformation,
        proposals,
        allowPropertyIds,
        properties,
      }}>
      {children}
    </MylistContext.Provider>
  );
};

export default MylistProvider;
