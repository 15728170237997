import InputCheckBox from 'interfaces/components/common/Form/kmanager/InputCheckBox.jsx';
import InputDate from 'interfaces/components/common/Form/kmanager/InputDate.jsx';
import InputDateTime from 'interfaces/components/common/Form/kmanager/InputDateTime.jsx';
import InputTime from 'interfaces/components/common/Form/kmanager/InputTime.jsx';
import InputFile from 'interfaces/components/common/Form/kmanager/InputFile.jsx';
import InputMarkDown from 'interfaces/components/common/Form/kmanager/InputMarkDown.jsx';
import InputPullDown from 'interfaces/components/common/Form/kmanager/InputPullDown.jsx';
import InputRadio from 'interfaces/components/common/Form/kmanager/InputRadio.jsx';
import InputText from 'interfaces/components/common/Form/kmanager/InputText.jsx';

const KmanagerInputType = (input, index) => {
  switch (input.type) {
    case 'text':
      return (
        <InputText
          key={index}
          label={input.text}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
        />
      );
    case 'radio':
      return (
        <InputRadio
          key={index}
          label={input.text}
          radio_labels={input.list}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
        />
      );
    case 'select':
      return (
        <InputPullDown
          key={index}
          label={input.text}
          itemList={input.list}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
        />
      );
    case 'check':
      return (
        <InputCheckBox
          key={index}
          label={input.text}
          check_labels={input.list}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
          describe={input.describe}
        />
      );
    case 'file':
      return (
        <InputFile
          key={index}
          label={input.text}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
        />
      );
    case 'date':
      return (
        <InputDate
          key={index}
          label={input.text}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
        />
      );
    case 'time':
      return (
        <InputTime
          key={index}
          label={input.text}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
        />
      );
    case 'datetime':
      return (
        <InputDateTime
          key={index}
          label={input.text}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
        />
      );
    case 'markdown':
      return (
        <InputMarkDown
          key={index}
          label={input.text}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
          width={input.width}
        />
      );
    default:
      return;
  }
};

export default KmanagerInputType;
