import 'interfaces/css/common/kmanager-table.css';
import KmanagerTableInTable from 'interfaces/components/common/Table/KmanagerTableInTable.jsx';
import KmanagerTableRowHeader from 'interfaces/components/common/Table/KmanagerTableRowHeader.jsx';

export const invoice_dummy_header = {
  id: 1,
  column: 'systemFeeInfo',
  text: 'システム利用料請求書送付先情報',
};
export const invoiceDoc_header = [
  { id: 1, column: 'invoicePostalCode', text: '郵便番号' },
  { id: 2, column: 'invoiceArea', text: '都道府県' },
  { id: 3, column: 'invoiceCity', text: '市区町村' },
  { id: 4, column: 'invoiceAddress', text: '住所' },
  { id: 5, column: 'invoiceBuildName', text: '建物名' },
  { id: 6, column: 'invoiceWrittenDestination', text: '宛名' },
  { id: 7, column: 'invoiceDepartment', text: '担当部署' },
  { id: 8, column: 'invoicePayerName', text: '振込人名義' },
  { id: 9, column: 'invoiceTel', text: '電話番号' },
  { id: 10, column: 'invoiceExtension', text: '内線' },
  { id: 11, column: 'invoiceUrl', text: 'URL' },
];

export const cert_dummy_header = {
  id: 2,
  column: 'certDocumentInfo',
  text: '認証書面送付先情報',
};
export const certDoc_header = [
  { id: 1, column: 'certDocumentPostalCode', text: '郵便番号' },
  { id: 2, column: 'certDocumentArea', text: '都道府県' },
  { id: 3, column: 'certDocumentCity', text: '市区町村' },
  { id: 4, column: 'certDocumentAddress', text: '住所' },
  { id: 5, column: 'certDocumentBuildName', text: '建物名' },
  { id: 6, column: 'certDocumentDestination', text: '宛名' },
  { id: 7, column: 'certDocumentWrittenDestination', text: '宛先' },
];

export const division_dummy_header = {
  id: 3,
  column: 'divisions',
  text: '執行機関',
};
export const division_header = [
  { id: 1, column: 'procedureType', text: '手続き種別' },
  { id: 2, column: 'name', text: '執行機関名' },
  { id: 3, column: 'nameKana', text: '執行機関名(カナ)' },
  { id: 4, column: 'contactName', text: '担当者名' },
  { id: 5, column: 'contactTel', text: '担当者電話番号' },
  { id: 6, column: 'contactEmail', text: '担当者メールアドレス' },
  { id: 7, column: 'useCreditCard', text: 'クレジットカードを利用する' },
  { id: 8, column: 'financialInstitutionName', text: '金融機関名' },
  { id: 9, column: 'branchName', text: '支店名' },
  { id: 10, column: 'accountType', text: '口座種別' },
  { id: 11, column: 'accountNumber', text: '口座番号' },
  { id: 12, column: 'accountName', text: '口座名義' },
  { id: 13, column: 'accountNameKana', text: '口座名義（フリガナ）' },
  { id: 14, column: 'postalCode', text: '郵便番号' },
  { id: 15, column: 'prefectureId', text: '都道府県' },
  { id: 16, column: 'city', text: '市区町村' },
  { id: 17, column: 'address', text: '住所' },
  { id: 18, column: 'buildName', text: '建物名' },
  { id: 19, column: 'destination', text: '宛先' },
  { id: 20, column: 'writtenDestination', text: '書面宛名' },
];

export const user_header = [
  { id: 1, column: 'userType', text: '管理者/親/子' },
  { id: 2, column: 'name', text: '担当者名' },
  { id: 3, column: 'department', text: '担当部署名' },
  { id: 4, column: 'officePosition', text: '役職名' },
  { id: 5, column: 'tel', text: '電話番号' },
  { id: 6, column: 'extension', text: '内線' },
  { id: 7, column: 'email', text: 'メール' },
];

export const dummyHeaderData = [
  { id: 1, column: 'governmentType', text: '行政機関種別' },
  { id: 2, column: 'region', text: '地域' },
  { id: 3, column: 'location', text: '都道府県' },
  { id: 4, column: 'name', text: '行政機関名' },
  { id: 5, column: 'nameKana', text: '行政機関名カナ' },
  { id: 6, column: 'emblem', text: '公章画像' },
  { id: 7, column: 'character', text: 'キャラクタ画像' },
];

export const public_dummy_header = {
  id: 4,
  column: 'publicSaleUsers',
  text: '公売管理者ユーザ名',
};
export const asset_dummy_header = {
  id: 5,
  column: 'assetSaleUsers',
  text: '公有管理者ユーザ名',
};

const KmanagerGovernmentDetailTable = ({
  government,
  divisions,
  documentSendPages,
  publicSaleUsers,
  assetSaleUsers,
}) => {
  return (
    <div className="kmanager-table-wrap">
      <table
        className="kmanager-table kmanager-table-hover"
        data-testid="kmanager-government-detail-table">
        <tbody>
          {dummyHeaderData.map((header) => {
            return (
              <KmanagerTableRowHeader
                key={header.id}
                data={government[header.column]}
                header={header}
              />
            );
          })}
          <KmanagerTableInTable
            data={documentSendPages}
            header={invoice_dummy_header}
            data_header={invoiceDoc_header}
          />
          <KmanagerTableInTable
            data={documentSendPages}
            header={cert_dummy_header}
            data_header={certDoc_header}
          />
          <KmanagerTableInTable
            data={divisions}
            header={division_dummy_header}
            data_header={division_header}
          />
          <KmanagerTableInTable
            data={publicSaleUsers}
            header={public_dummy_header}
            data_header={user_header}
          />
          <KmanagerTableInTable
            data={assetSaleUsers}
            header={asset_dummy_header}
            data_header={user_header}
          />
        </tbody>
      </table>
    </div>
  );
};

export default KmanagerGovernmentDetailTable;
