
export class Time {
  #time

  constructor(time) {
    this.#time = time;
  }

  get() {
    return this.#time
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
