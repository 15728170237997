
export class LinkName {
  #linkName

  constructor(linkName) {
    this.#linkName = linkName;
  }

  get() {
    return this.#linkName
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
