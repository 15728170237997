import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_INDIVIDUAL_MAIL_TEMPLATE = `${BASE_URL_NAVIGATION}/storage/current/individual_mail_templates/data.json`;
const PREVIOUS_INDIVIDUAL_MAIL_TEMPLATE = `${BASE_URL_NAVIGATION}/storage/previous/individual_mail_templates/data.json`;

export const fetchIndividualMailTemplateStorage = async () => {
  try {
    const response = await fetchIndividualMailTemplate(CURRENT_INDIVIDUAL_MAIL_TEMPLATE);
    return extractIndividualMailTemplate(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ individual_mail_template ）を取得できませんでした");

    try {
      const response = await fetchIndividualMailTemplate(PREVIOUS_INDIVIDUAL_MAIL_TEMPLATE);
      return extractIndividualMailTemplate(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ individual_mail_template ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchIndividualMailTemplate = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractIndividualMailTemplate = (response) => {
  return response.map(individual_mail_template => individual_mail_template);
}