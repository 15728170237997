export const calc_dead_line = (deadline) => {
  if (deadline === '') {
    return '';
  }
  const currentTime = new Date().getTime();
  const deadlineTime = new Date(deadline).getTime();
  const diff = deadlineTime - currentTime;
  const day = Math.floor(diff / (24 * 60 * 60 * 1000));

  if (diff <= 0) {
    return '';
  }

  // 24時間以上の差分がある時
  if (day > 0) {
    return `${day}日`;
  }

  let hour =  Math.floor(diff / (60 * 60 * 1000));
  let minutes = Math.ceil((diff - hour * 60 * 60 * 1000) / (60 * 1000));

  // 1時間未満の差分の時
  if (hour === 0) {
    // 59分01秒 ~ 59分59秒 までは60分判定
    if (minutes === 60) {
      return '1時間';
    }
    return `${minutes}分`;
  }

  // 差分が23時間59分01秒 ~ 23時間59分59秒 までは1日判定
  if (hour === 23 && minutes === 60) {
    return '1日';
  }

  if (minutes === 0) {
    return `${hour}時間`;
  }

  // 差分がXX時間59分01秒 ~ XX時間59分59秒 まではXX + 1時間判定
  if (minutes === 60) {
    return `${hour + 1}時間`;
  }
  return `${hour}時間${minutes}分`;
}
