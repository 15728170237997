// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createPropertyCategoriesData,
  deletePropertyCategoriesData,
  updatePropertyCategoriesData,
} from '../../../infrastructure/api/PropertyCategories.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import {
  getPropertyCategory,
  getV1PropertyCategory,
} from '../repositories/PropertyCategory.js';

export const get = async (type) => await getV1PropertyCategory(type);

export const findById = async (type, id) => {
  const Category = await getV1PropertyCategory(type);
  return Category.find((data) => data.id === id);
};

// 物件種類ごとのカテゴリー情報を取得
export const findByCategory = async (type, category) => {
  let categories = await getPropertyCategory(type);

  if (category === 'CAR') {
    categories = categories.filter((data) => data.displayCar === 'DISPLAY');
  }

  if (category === 'ESTATE') {
    categories = categories.filter((data) => data.displayEstate === 'DISPLAY');
  }

  return categories;
};

export const getSelectedCategories = async (type, id) => {
  let returnCategories = {
    parent: undefined,
    child: undefined,
    grandchild: undefined,
  };
  const categories = await getV1PropertyCategory(type);
  let category = categories.find(
    (data) => data.id.toString() === id.toString(),
  );

  for (let i = 0; i < 3; i++) {
    if (category.depth === '1') {
      returnCategories.parent = category;
      break;
    }
    if (category.depth === '2') {
      returnCategories.child = category;
    }
    if (category.depth === '3') {
      returnCategories.grandchild = category;
    }
    category = categories.find(
      (data) => data.id.toString() === category.parentId.toString(),
    );
  }

  return returnCategories;
};

export const create = async (type, data) => {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Category)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Category)');
  }
  if (type === KMANAGER) {
    response = await createPropertyCategoriesData(data);
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Category', response);
  }
  return response;
};

export const update = async (type, data) => {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Category)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Category)');
  }
  if (type === KMANAGER) {
    response = await updatePropertyCategoriesData(data, data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Category', response);
  }
  return response;
};

export const destroy = async (type, data) => {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Category)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Category)');
  }
  if (type === KMANAGER) {
    response = await deletePropertyCategoriesData(data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Category', response);
  }
  return response;
};

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
