// こちら使用していなさそう。確認。

import Button from 'interfaces/components/common/Button/Button.jsx';
import { useEffect, useState } from 'react';
import { useError } from 'shared/hooks/useError.js';
import { useInput } from 'shared/hooks/useInput.js';
import { useValidate } from 'shared/hooks/useValidate.js';

import AuthFormInput from '../AuthFormInput.jsx';
import 'interfaces/css/common/forget-password-form.css';

const emailValidates = ['no_message_required', 'email'];

const ForgetPasswordForm = () => {
  const { setError } = useError();
  const [email, handleEmailChange, validateEmailMessage] =
    useInput(emailValidates);
  const [isDisabled, setIsDisabled] = useState(true);
  const { handleValidates } = useValidate();

  const checkAllValidation = () => {
    const emailValidateMessage = handleValidates(email, emailValidates);
    setIsDisabled(emailValidateMessage);
  };

  useEffect(() => {
    checkAllValidation();
  }, [email]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isDisabled) {
      return;
    }

    try {
      // TODO: CreateのAPIを叩く
      console.log(email);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      className="forget-password-form"
      data-testid="forget-password-form">
      <AuthFormInput
        label="パスワードリセットのための確認コードを受信する登録メールアドレスを入力してください。"
        type="email"
        handleChange={handleEmailChange}
        validateMessage={validateEmailMessage}
        caption="ドメイン受信制限をしている場合は @kankocho.jp からのメールの受信を許可してください。"
      />
      <div className="forget-password-form-submit-button-wrap">
        <Button
          text="送信"
          height="44px"
          width="100px"
          type="submit"
          isDisabled={isDisabled}
        />
      </div>
    </form>
  );
};

export default ForgetPasswordForm;
