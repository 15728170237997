import handleValidationMessage from '../../../../shared/utils/helper/handleValidationMessage';
import Button from '../Button/Button.jsx';
import CommonInput from '../Input/CommonInput.jsx';
import RequiredLabel from '../RequiredLabel/RequiredLabel.jsx';

const PostalCodeForm = ({
  postalCode1,
  postalCode2,
  postalCode1ValidationMessage,
  postalCode2ValidationMessage,
  handleChangePostalCode1,
  handleChangePostalCode2,
  handleAddressSearch,
}) => {
  return (
    <tr data-testid="postal-code-form">
      <th data-testid="postal-code-form-label">
        <div>
          <p>
            郵便番号
            <RequiredLabel />
          </p>
          <small>
            郵便番号検索は
            <a
              href="https://www.post.japanpost.jp/zipcode/index.html"
              target="_blank"
              rel="noopener noreferrer">
              こちら
            </a>
          </small>
        </div>
      </th>
      <td>
        <div>
          <div className="application-info-form-postalcode-input-group">
            <div className="common-input-wrap">
              <CommonInput
                type="text"
                value={postalCode1}
                name="postalCode1"
                maxLength={3}
                handleChange={handleChangePostalCode1}
              />
              <small className="validate-message flex-wrap">
                {handleValidationMessage(postalCode1ValidationMessage)}
              </small>
            </div>
            <div
              className="input-group-divide-hyphen"
              data-testid="input-group-divide-hyphen">
              -
            </div>
            <div className="common-input-wrap">
              <CommonInput
                type="text"
                value={postalCode2}
                name="postalCode2"
                maxLength={4}
                handleChange={handleChangePostalCode2}
              />
              <small className="validate-message">
                {handleValidationMessage(postalCode2ValidationMessage)}
              </small>
            </div>
            <div className="ml-5 mt-2">
              <Button
                text="住所検索"
                type="button"
                onClick={handleAddressSearch}
                className="search-address-button"
              />
            </div>
          </div>
          <p data-testid="search-address-caption">
            <small>
              郵便番号入力後、「住所検索」ボタンを押すと住所が自動的に入力されます。
            </small>
          </p>
        </div>
      </td>
    </tr>
  );
};

export default PostalCodeForm;
