// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-system-usage .total-amount-breakdown-table {
  width: 100%;
  margin-bottom: 40px;
}

.confirm-system-usage .total-amount-record-label {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/ConfirmSystemUsage/total-amount-breakdown-table.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".confirm-system-usage .total-amount-breakdown-table {\n  width: 100%;\n  margin-bottom: 40px;\n}\n\n.confirm-system-usage .total-amount-record-label {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
