import { findByAuctionIdAndGovernmentId as getProperties } from 'domain/Property/services/Property.js';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { findById as findAuction } from 'domain/Auction/services/Auction.js';
import { findById as findGovernment } from 'domain/Government/services/Government.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from 'shared/utils/helper/authState.js';
import { KMANAGER } from 'shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { GovernmentPaymentContext } from '../entities/GovernmentPaymentContext.js';
import { isEmpty } from 'shared/utils/constants/emptyData.js';

const GovernmentPaymentProvider = ({ type, children }) => {
  const { auctionId, governmentId } = useParams();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [auction, setAuction] = useState({});
  const [government, setGovernment] = useState({});
  const [properties, setProperties] = useState([]);

  if (type === KMANAGER) {
    useEffect(() => {
      findAuction(type, auctionId).then((result) => {
        setAuction(result);
      });
    }, [auctionId]);

    useEffect(() => {
      findGovernment(type, governmentId).then((result) => {
        setGovernment(result);
      });
    }, [governmentId]);

    useEffect(() => {
      getProperties(type, auctionId, governmentId).then((resultProperties) => {
        const properties = [];
        resultProperties.forEach((resultProperty) => {
          let property = resultProperty;
          property.bidPrice = isEmpty(resultProperty.bidPrice)
            ? 0
            : Number(resultProperty.bidPrice);
          property.deposit = isEmpty(resultProperty.deposit)
            ? 0
            : Number(resultProperty.deposit);
          property.bidEndPrice = isEmpty(resultProperty.bidEndPrice)
            ? 0
            : Number(resultProperty.bidEndPrice);
          property.bidSystemFee = isEmpty(resultProperty.bidSystemFee)
            ? 0
            : Number(resultProperty.bidSystemFee) ;
          property.bidSystemFeeTax = isEmpty(resultProperty.bidSystemFeeTax)
            ? 0
            : Number(resultProperty.bidSystemFeeTax);
          properties.push(property);
        });
        setProperties(properties);
      });
    }, [governmentId, auctionId]);

    //   properties services bid関連の和を算出する処理 bidEndPrice, bidPrice, bidSystemFee, bidSystemFeeTax, deposit
  }

  return (
    <GovernmentPaymentContext.Provider
      value={{
        authState,
        breadcrumbs,
        government,
        auction,
        properties,
      }}>
      {children}
    </GovernmentPaymentContext.Provider>
  );
};

export default GovernmentPaymentProvider;
