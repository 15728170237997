
export class IsApprovalRequired {
  #isApprovalRequired

  constructor(isApprovalRequired) {
    this.#isApprovalRequired = isApprovalRequired;
  }

  get() {
    return this.#isApprovalRequired
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
