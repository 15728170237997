import '../../../interfaces/css/common/button.css';

const Button = ({
  className,
  text,
  clickHandler,
  isOutline = false,
  isDisabled = false,
}) => {
  return (
    <button
      data-testid="kmanager-button"
      className={`${className ? className : ''} kmanager-button ${isOutline ? 'kmanager-button-outline' : ''} d-inline-flex align-center justify-center`}
      onClick={(e) => clickHandler(e)}
      disabled={isDisabled}>
      {text}
    </button>
  );
};
export default Button;
