import { ValidationMessage } from 'interfaces/components/common/ValidationMessage';
import React from 'react';

const InputCategoryName = React.memo(function InputCategoryName({
  invalidMessage,
  state,
  setState,
}) {
  return (
    <div className="form-area">
      <div style={{ position: 'relative' }} className={'form-input'}>
        <input
          type="text"
          placeholder={'カテゴリー名'}
          data-testid="input-text"
          value={state}
          onChange={(e) => setState(e)}
        />
      </div>
      <ValidationMessage invalidMessage={invalidMessage} />
    </div>
  );
});
export default InputCategoryName;
