// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-block-list-modal .modal-title {
  background-color: #e0e0e0;
  font-size: 1.5rem;
  padding: 16px 24px 10px;
  width: 100%;
}

.delete-block-list-modal .modal-description-area {
  padding: 0 24px 20px;
  width: 100%;
}
.delete-block-list-modal .modal-description-area p {
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
}

.delete-block-list-modal .modal-border {
  background-color: #cccccc;
  border: none;
  height: 1px;
  margin: 0;
  width: 100%;
}

.delete-block-list-modal .cancel-button {
  border: 1px solid #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/BlockList/delete-block-list-modal.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,oBAAoB;EACpB,WAAW;AACb;AACA;EACE,SAAS;EACT,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,SAAS;EACT,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".delete-block-list-modal .modal-title {\n  background-color: #e0e0e0;\n  font-size: 1.5rem;\n  padding: 16px 24px 10px;\n  width: 100%;\n}\n\n.delete-block-list-modal .modal-description-area {\n  padding: 0 24px 20px;\n  width: 100%;\n}\n.delete-block-list-modal .modal-description-area p {\n  margin: 0;\n  color: rgba(0, 0, 0, 0.6);\n}\n\n.delete-block-list-modal .modal-border {\n  background-color: #cccccc;\n  border: none;\n  height: 1px;\n  margin: 0;\n  width: 100%;\n}\n\n.delete-block-list-modal .cancel-button {\n  border: 1px solid #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
