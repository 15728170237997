import styled from 'styled-components';

import ValidationMessage from './ValidationMessage';
import CautionDescription from '../../Form/navi/CautionDescription';
import Label from '../../Form/navi/Label';


const Input = styled.input`
  border: ${(props) =>
    props.$validation
      ? '2px solid #ff5252!important'
      : '1px solid rgba(0, 0, 0, .42)'};
`;

const FormPostalWithCaution = ({
  postData,
  postData1,
  postData2,
  setPostData,
  caution_description,
  handlerPost1,
  handlerPost2,
  required = false,
  invalidMessage,
  invalid1Message,
  invalid2Message,
}) => {
  const handleChangePostData1 = (e) => {
    postData[e.target.name] = e.target.value;
    handlerPost1(e.target.value);
    if (postData1 && postData2) {
      setPostData(postData);
    }
  };
  const handleChangePostData2 = (e) => {
    postData[e.target.name] = e.target.value;
    handlerPost2(e.target.value);
    if (postData1 && postData2) {
      setPostData(postData);
    }
  };
  return (
    <div
      data-testid="property-info-general-form-input-tell"
      className={'property-info-general-form-table-row'}>
      <Label label={'郵便番号'} required={required} />
      <div className="property-info-general-form-table-cell">
        <div className="property-info-general-form-input-tell-flex">
          <Input
            className={'property-info-general-form-table-cell-1'}
            onChange={handleChangePostData1}
            type="text"
            name="postalCode1"
            value={postData1 ?? ''}
            $validation={
              (invalid1Message !== 'TRUE' && invalid1Message !== null) ||
              (invalidMessage !== 'TRUE' && invalidMessage !== null)
            }
          />
          <div className="ml-2 mr-2">-</div>
          <Input
            className={'property-info-general-form-table-cell-1'}
            onChange={handleChangePostData2}
            type="text"
            name="postalCode2"
            value={postData2 ?? ''}
            $validation={
              (invalid2Message !== 'TRUE' && invalid2Message !== null) ||
              (invalidMessage !== 'TRUE' && invalidMessage !== null)
            }
          />
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
        <ValidationMessage invalidMessage={invalid1Message} />
        <ValidationMessage invalidMessage={invalid2Message} />
        <CautionDescription data={caution_description} />
      </div>
    </div>
  );
};
export default FormPostalWithCaution;
