import {
  CAR_MANUFACTURE,
  CAR_MILEAGE_FROM,
  CAR_MILEAGE_TO,
  DEPOSIT_FLAG,
  FIRST_REGIST_DATE_FROM,
  FIRST_REGIST_DATE_TO,
  OPEN_WAY,
  PROCEDURE_NO,
  PURPOSE,
  SEARCH_PRICE_HIGH,
  SEARCH_PRICE_LOW,
  SEARCH_STATUS,
} from 'shared/utils/constants/queryParam';
import { validateLessMinimumAmount } from 'shared/utils/helper/serviceValidators';

import {
  SearchStatusData,
  DepositFlag,
  OpenWayData,
  ProcedureNo,
  searchPrice,
  //priceToQueryParamValue,
  procedureNoNameToQueryParam,
  openWayNameToQueryParam,
  depositFlagNameToQueryParam,
  searchStatusToQueryParam,
  priceHighToQueryParamValue,
} from './generalSearchConditions';
import BetweenValueGroup from '../PropertyList/BetweenValueGroup';
import CheckBoxGroup from '../PropertyList/CheckBoxGroup';
import RadioButtonGroup from '../PropertyList/RadioButtonGroup';
import SelectedValue from '../PropertyList/SelectedValue';

export const carManufactureToQueryParamValue = {
  トヨタ: 'TOYOTA',
  レクサス: 'LEXUS',
  ホンダ: 'HONDA',
  日産: 'NISSAN',
  スズキ: 'SUZUKI',
  ダイハツ: 'DAIHATSU',
  マツダ: 'MATSUDA',
  スバル: 'SUBARU',
  三菱: 'MITSUBISHI',
  ヤマハ: 'YAMAHA',
  日野: 'HINO',
  メルセデス: 'MERCEDES_BENZ',
  BMW: 'BMW',
  フォルクスワーゲン: 'VOLKSWAGEN',
  アウディ: 'AUDI',
  ミニ: 'MINI',
  ポルシェ: 'PORSCHE',
  ボルボ: 'VOLVO',
  プジョー: 'PEUGEOT',
  ランドローバ: 'LAND_ROVER',
  その他: 'OTHER',
};
export const purposeNameToQueryParamValue = {
  乗用: 'PASSENGER',
  特殊: 'SPECIAL',
  貨物: 'FREIGHT',
  乗合: 'BUS',
  その他: 'OTHER',
};
export const firstRegistDataToQueryParamValue = {
  '': '',
  '昭和30年(1955年)': '1954-12-31T15:00:00.000Z',
  '昭和31年(1956年)': '1955-12-31T15:00:00.000Z',
  '昭和32年(1957年)': '1956-12-31T15:00:00.000Z',
  '昭和33年(1958年)': '1957-12-31T15:00:00.000Z',
  '昭和34年(1959年)': '1958-12-31T15:00:00.000Z',
  '昭和35年(1960年)': '1959-12-31T15:00:00.000Z',
  '昭和36年(1961年)': '1960-12-31T15:00:00.000Z',
  '昭和37年(1962年)': '1961-12-31T15:00:00.000Z',
  '昭和38年(1963年)': '1962-12-31T15:00:00.000Z',
  '昭和39年(1964年)': '1963-12-31T15:00:00.000Z',
  '昭和40年(1965年)': '1964-12-31T15:00:00.000Z',
  '昭和41年(1966年)': '1965-12-31T15:00:00.000Z',
  '昭和42年(1967年)': '1966-12-31T15:00:00.000Z',
  '昭和43年(1968年)': '1967-12-31T15:00:00.000Z',
  '昭和44年(1969年)': '1968-12-31T15:00:00.000Z',
  '昭和45年(1970年)': '1969-12-31T15:00:00.000Z',
  '昭和46年(1971年)': '1970-12-31T15:00:00.000Z',
  '昭和47年(1972年)': '1971-12-31T15:00:00.000Z',
  '昭和48年(1973年)': '1972-12-31T15:00:00.000Z',
  '昭和49年(1974年)': '1973-12-31T15:00:00.000Z',
  '昭和50年(1975年)': '1974-12-31T15:00:00.000Z',
  '昭和51年(1976年)': '1975-12-31T15:00:00.000Z',
  '昭和52年(1977年)': '1976-12-31T15:00:00.000Z',
  '昭和53年(1978年)': '1977-12-31T15:00:00.000Z',
  '昭和54年(1979年)': '1978-12-31T15:00:00.000Z',
  '昭和55年(1980年)': '1979-12-31T15:00:00.000Z',
  '昭和56年(1981年)': '1980-12-31T15:00:00.000Z',
  '昭和57年(1982年)': '1981-12-31T15:00:00.000Z',
  '昭和58年(1983年)': '1982-12-31T15:00:00.000Z',
  '昭和59年(1984年)': '1983-12-31T15:00:00.000Z',
  '昭和60年(1985年)': '1984-12-31T15:00:00.000Z',
  '昭和61年(1986年)': '1985-12-31T15:00:00.000Z',
  '昭和62年(1987年)': '1986-12-31T15:00:00.000Z',
  '昭和63年(1988年)': '1987-12-31T15:00:00.000Z',
  '昭和64年/平成元年(1989年)': '1988-12-31T15:00:00.000Z',
  '平成2年(1990年)': '1989-12-31T15:00:00.000Z',
  '平成3年(1991年)': '1990-12-31T15:00:00.000Z',
  '平成4年(1992年)': '1991-12-31T15:00:00.000Z',
  '平成5年(1993年)': '1992-12-31T15:00:00.000Z',
  '平成6年(1994年)': '1993-12-31T15:00:00.000Z',
  '平成7年(1995年)': '1994-12-31T15:00:00.000Z',
  '平成8年(1996年)': '1995-12-31T15:00:00.000Z',
  '平成9年(1997年)': '1996-12-31T15:00:00.000Z',
  '平成10年(1998年)': '1997-12-31T15:00:00.000Z',
  '平成11年(1999年)': '1998-12-31T15:00:00.000Z',
  '平成12年(2000年)': '1999-12-31T15:00:00.000Z',
  '平成13年(2001年)': '2000-12-31T15:00:00.000Z',
  '平成14年(2002年)': '2001-12-31T15:00:00.000Z',
  '平成15年(2003年)': '2002-12-31T15:00:00.000Z',
  '平成16年(2004年)': '2003-12-31T15:00:00.000Z',
  '平成17年(2005年)': '2004-12-31T15:00:00.000Z',
  '平成18年(2006年)': '2005-12-31T15:00:00.000Z',
  '平成19年(2007年)': '2006-12-31T15:00:00.000Z',
  '平成20年(2008年)': '2007-12-31T15:00:00.000Z',
  '平成21年(2009年)': '2008-12-31T15:00:00.000Z',
  '平成22年(2010年)': '2009-12-31T15:00:00.000Z',
  '平成23年(2011年)': '2010-12-31T15:00:00.000Z',
  '平成24年(2012年)': '2011-12-31T15:00:00.000Z',
  '平成25年(2013年)': '2012-12-31T15:00:00.000Z',
  '平成26年(2014年)': '2013-12-31T15:00:00.000Z',
  '平成27年(2015年)': '2014-12-31T15:00:00.000Z',
  '平成28年(2016年)': '2015-12-31T15:00:00.000Z',
  '平成29年(2017年)': '2016-12-31T15:00:00.000Z',
  '平成30年(2018年)': '2017-12-31T15:00:00.000Z',
  '平成31年/令和元年(2019年)': '2018-12-31T15:00:00.000Z',
  '令和2年(2020年)': '2019-12-31T15:00:00.000Z',
  '令和3年(2021年)': '2020-12-31T15:00:00.000Z',
  '令和4年(2022年)': '2021-12-31T15:00:00.000Z',
  '令和5年(2023年)': '2022-12-31T15:00:00.000Z',
};
export const carMileageToQueryParamValue = {
  指定なし: '',
  '0.5万km': 5000,
  '1万km': 10000,
  '2万km': 20000,
  '3万km': 30000,
  '4万km': 40000,
  '5万km': 50000,
  '6万km': 60000,
  '7万km': 70000,
  '8万km': 80000,
  '9万km': 90000,
  '10万km': 100000,
  '11万km': 110000,
  '12万km': 120000,
  '13万km': 130000,
  '14万km': 140000,
  '15万km': 150000,
};
export const carManufacture = [
  {
    id: 1,
    key: 'carManufacture',
    label: '',
    items: [
      'トヨタ',
      'レクサス',
      'ホンダ',
      '日産',
      'スズキ',
      'ダイハツ',
      'マツダ',
      'スバル',
      '三菱',
      'ヤマハ',
      '日野',
      'メルセデス',
      'BMW',
      'フォルクスワーゲン',
      'アウディ',
      'ミニ',
      'ポルシェ',
      'ボルボ',
      'プジョー',
      'ランドローバ',
      'その他',
    ],
    queryParam: carManufactureToQueryParamValue,
    value: '',
  },
];
export const purpose = [
  {
    id: 1,
    key: 'purpose',
    label: '',
    items: ['乗用', '特殊', '貨物', '乗合', 'その他'],
    queryParam: purposeNameToQueryParamValue,
    value: '',
  },
];
export const firstRegistDate = [
  {
    id: 1,
    key: 'firstRegistDateFrom',
    label: '',
    items: [
      '昭和30年(1955年)',
      '昭和31年(1956年)',
      '昭和32年(1957年)',
      '昭和33年(1958年)',
      '昭和34年(1959年)',
      '昭和35年(1960年)',
      '昭和36年(1961年)',
      '昭和37年(1962年)',
      '昭和38年(1963年)',
      '昭和39年(1964年)',
      '昭和40年(1965年)',
      '昭和41年(1966年)',
      '昭和42年(1967年)',
      '昭和43年(1968年)',
      '昭和44年(1969年)',
      '昭和45年(1970年)',
      '昭和46年(1971年)',
      '昭和47年(1972年)',
      '昭和48年(1973年)',
      '昭和49年(1974年)',
      '昭和50年(1975年)',
      '昭和51年(1976年)',
      '昭和52年(1977年)',
      '昭和53年(1978年)',
      '昭和54年(1979年)',
      '昭和55年(1980年)',
      '昭和56年(1981年)',
      '昭和57年(1982年)',
      '昭和58年(1983年)',
      '昭和59年(1984年)',
      '昭和60年(1985年)',
      '昭和61年(1986年)',
      '昭和62年(1987年)',
      '昭和63年(1988年)',
      '昭和64年/平成元年(1989年)',
      '平成2年(1990年)',
      '平成3年(1991年)',
      '平成4年(1992年)',
      '平成5年(1993年)',
      '平成6年(1994年)',
      '平成7年(1995年)',
      '平成8年(1996年)',
      '平成9年(1997年)',
      '平成10年(1998年)',
      '平成11年(1999年)',
      '平成12年(2000年)',
      '平成13年(2001年)',
      '平成14年(2002年)',
      '平成15年(2003年)',
      '平成16年(2004年)',
      '平成17年(2005年)',
      '平成18年(2006年)',
      '平成19年(2007年)',
      '平成20年(2008年)',
      '平成21年(2009年)',
      '平成22年(2010年)',
      '平成23年(2011年)',
      '平成24年(2012年)',
      '平成25年(2013年)',
      '平成26年(2014年)',
      '平成27年(2015年)',
      '平成28年(2016年)',
      '平成29年(2017年)',
      '平成30年(2018年)',
      '平成31年/令和元年(2019年)',
      '令和2年(2020年)',
      '令和3年(2021年)',
      '令和4年(2022年)',
      '令和5年(2023年)',
    ],
    queryParam: firstRegistDataToQueryParamValue,
    value: '',
  },
  {
    id: 2,
    key: 'firstRegistDateTo',
    label: '',
    items: [
      '昭和30年(1955年)',
      '昭和31年(1956年)',
      '昭和32年(1957年)',
      '昭和33年(1958年)',
      '昭和34年(1959年)',
      '昭和35年(1960年)',
      '昭和36年(1961年)',
      '昭和37年(1962年)',
      '昭和38年(1963年)',
      '昭和39年(1964年)',
      '昭和40年(1965年)',
      '昭和41年(1966年)',
      '昭和42年(1967年)',
      '昭和43年(1968年)',
      '昭和44年(1969年)',
      '昭和45年(1970年)',
      '昭和46年(1971年)',
      '昭和47年(1972年)',
      '昭和48年(1973年)',
      '昭和49年(1974年)',
      '昭和50年(1975年)',
      '昭和51年(1976年)',
      '昭和52年(1977年)',
      '昭和53年(1978年)',
      '昭和54年(1979年)',
      '昭和55年(1980年)',
      '昭和56年(1981年)',
      '昭和57年(1982年)',
      '昭和58年(1983年)',
      '昭和59年(1984年)',
      '昭和60年(1985年)',
      '昭和61年(1986年)',
      '昭和62年(1987年)',
      '昭和63年(1988年)',
      '昭和64年/平成元年(1989年)',
      '平成2年(1990年)',
      '平成3年(1991年)',
      '平成4年(1992年)',
      '平成5年(1993年)',
      '平成6年(1994年)',
      '平成7年(1995年)',
      '平成8年(1996年)',
      '平成9年(1997年)',
      '平成10年(1998年)',
      '平成11年(1999年)',
      '平成12年(2000年)',
      '平成13年(2001年)',
      '平成14年(2002年)',
      '平成15年(2003年)',
      '平成16年(2004年)',
      '平成17年(2005年)',
      '平成18年(2006年)',
      '平成19年(2007年)',
      '平成20年(2008年)',
      '平成21年(2009年)',
      '平成22年(2010年)',
      '平成23年(2011年)',
      '平成24年(2012年)',
      '平成25年(2013年)',
      '平成26年(2014年)',
      '平成27年(2015年)',
      '平成28年(2016年)',
      '平成29年(2017年)',
      '平成30年(2018年)',
      '平成31年/令和元年(2019年)',
      '令和2年(2020年)',
      '令和3年(2021年)',
      '令和4年(2022年)',
      '令和5年(2023年)',
    ],
    queryParam: firstRegistDataToQueryParamValue,
    value: '',
  },
];
export const carMileage = [
  {
    id: 1,
    key: 'carMileageFrom',
    label: '',
    items: [
      '指定なし',
      '0.5万km',
      '1万km',
      '2万km',
      '3万km',
      '4万km',
      '5万km',
      '6万km',
      '7万km',
      '8万km',
      '9万km',
      '10万km',
      '11万km',
      '12万km',
      '13万km',
      '14万km',
      '15万km',
    ],
    queryParam: carMileageToQueryParamValue,
    value: '指定なし',
  },
  {
    id: 2,
    key: 'carMileageTo',
    label: '',
    items: [
      '指定なし',
      '0.5万km',
      '1万km',
      '2万km',
      '3万km',
      '4万km',
      '5万km',
      '6万km',
      '7万km',
      '8万km',
      '9万km',
      '10万km',
      '11万km',
      '12万km',
      '13万km',
      '14万km',
      '15万km',
    ],
    queryParam: carMileageToQueryParamValue,
    value: '指定なし',
  },
];
export const CarSearchConditionsData = [
  {
    id: 1,
    title: 'メーカー',
    selectedValue: (
      <SelectedValue
        displayType="normal"
        getQueryParam={CAR_MANUFACTURE}
        convertTable={carManufactureToQueryParamValue}
      />
    ),
    body: <BetweenValueGroup data={carManufacture} />,
  },
  {
    id: 2,
    title: '用途',
    selectedValue: (
      <SelectedValue
        displayType="normal"
        getQueryParam={PURPOSE}
        convertTable={purposeNameToQueryParamValue}
      />
    ),
    body: <BetweenValueGroup data={purpose} />,
  },
  {
    id: 3,
    title: '初度登録年月',
    selectedValue: (
      <SelectedValue
        displayType="between"
        getQueryParam={[FIRST_REGIST_DATE_FROM, FIRST_REGIST_DATE_TO]}
        convertTable={firstRegistDataToQueryParamValue}
      />
    ),
    body: <BetweenValueGroup data={firstRegistDate} />,
  },
  {
    id: 4,
    title: '走行距離',
    selectedValue: (
      <SelectedValue
        displayType="between"
        getQueryParam={[CAR_MILEAGE_FROM, CAR_MILEAGE_TO]}
        convertTable={carMileageToQueryParamValue}
      />
    ),
    body: <BetweenValueGroup data={carMileage} />,
  },
  {
    id: 5,
    title: '実施状況',
    selectedValue: (
      <SelectedValue
        displayType="commaSplit"
        getQueryParam={SEARCH_STATUS}
        convertTable={searchStatusToQueryParam}
      />
    ),
    body: <CheckBoxGroup data={SearchStatusData} />,
  },
  {
    id: 6,
    title: '保証金',
    selectedValue: (
      <SelectedValue
        displayType="commaSplit"
        getQueryParam={DEPOSIT_FLAG}
        convertTable={depositFlagNameToQueryParam}
      />
    ),
    body: <CheckBoxGroup data={DepositFlag} />,
  },
  {
    id: 7,
    title: '実施方法',
    selectedValue: (
      <SelectedValue
        displayType="normal"
        getQueryParam={OPEN_WAY}
        convertTable={openWayNameToQueryParam}
      />
    ),
    body: <RadioButtonGroup data={OpenWayData} />,
  },
  {
    id: 8,
    title: 'オークション種別',
    selectedValue: (
      <SelectedValue
        displayType="normal"
        getQueryParam={PROCEDURE_NO}
        convertTable={procedureNoNameToQueryParam}
      />
    ),
    body: <RadioButtonGroup data={ProcedureNo} />,
  },
  {
    id: 9,
    title: '金額範囲',
    selectedValue: (
      <SelectedValue
        displayType="between"
        getQueryParam={[SEARCH_PRICE_LOW, SEARCH_PRICE_HIGH]}
        convertTable={priceHighToQueryParamValue}
      />
    ),
    body: (
      <BetweenValueGroup
        data={searchPrice}
        validation={validateLessMinimumAmount}
      />
    ),
  },
];
