import { Link } from 'react-router-dom';

const IconContents = ({ svg, label }) => {
  return <>
    {svg}
    <span className="header-icon-label" data-testid="header-icon-label">{label}</span>
  </>;
}

const Icon = ({ link, svg, label, isTargetBlank }) => {
  if (isTargetBlank) {
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="icon"
        className="header-icon flex flex-direction-column align-items-center justify-center"
      >
        <IconContents svg={svg} label={label}/>
      </a>
    )
  }

  return (
    <Link
      to={link}
      data-testid="icon"
      className="header-icon flex flex-direction-column align-items-center justify-center"
    >
      <IconContents svg={svg} label={label}/>
    </Link>
  );
};

export default Icon;
