import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Button from 'interfaces/components/common/Button';
import ConfirmSection from 'interfaces/components/common/Form/ConfirmSection';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import { breadcrumbs } from 'interfaces/pages/PropertyInfoCar/entities/breadcrumbs';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

import { createConfirmSectionData } from './CreateConfirmSectionData';
import { PropertyInfoCarContext } from '../../../../pages/PropertyInfoCar/entities/PropertyInfoCarContext';

const PropertyInfoCarConfirm = ({
  formData,
  handleComplete,
  handleBackForm,
  openWay,
}) => {
  const { auction_id, property_id } = useParams();
  const { invitation, divisions, property, selectedCategory } = useContext(
    PropertyInfoCarContext,
  );
  const { localStorageProcedureType } = useLocalStorage();

  const confirmSectionData = createConfirmSectionData(
    formData,
    divisions.find(
      (data) => data?.id.toString() === formData?.data?.divisionId?.toString(),
    ),
    selectedCategory,
    localStorageProcedureType,
  );

  const getMethod = () => {
    if (property_id !== undefined) {
      return 'update';
    }
    return 'create';
  };

  const getTitle = () => {
    if (getMethod() === 'create') {
      return `登録内容確認：物件情報（車）登録：${invitation?.name}`;
    }
    return `編集内容確認：${invitation?.name}：${property?.name}`;
  };

  return (
    <div
      className="new-style_navi_object_confirm"
      data-testid="property-info-general-confirm">
      <Breadcrumb
        breadcrumbs={breadcrumbs(
          `${getMethod()}`,
          'confirm',
          invitation?.name,
          auction_id,
          openWay,
          property?.name,
        )}
      />
      <div>
        <Heading2 title={getTitle()} />
        <div>
          {property_id === undefined ? (
            <p>
              登録する物件内容を確認してください。このまま登録する場合は「登録」ボタンを押し、再度編集する場合は「編集」ボタンを押してください。
            </p>
          ) : (
            <p>
              編集した物件内容を確認してください。このまま更新する場合は「更新」ボタンを押し、再度編集する場合は「編集」ボタンを押してください。
            </p>
          )}
        </div>
      </div>
      <div className="new-style_navi_confirm-section_first">
        <ConfirmSection inputsData={confirmSectionData.section1} />
      </div>
      <ConfirmSection
        headings={[
          {
            type: 'h3',
            title: '1. 画像の選択',
          },
        ]}
        inputsData={confirmSectionData.section2}
      />
      <ConfirmSection
        headings={[
          {
            type: 'h3',
            title: '2. 物件情報',
          },
        ]}
        inputsData={confirmSectionData.section3}
      />
      <ConfirmSection
        headings={[
          {
            type: 'h3',
            title: '3. 登録事項など証明書の記載内容',
          },
        ]}
        inputsData={confirmSectionData.section4}
      />
      <ConfirmSection
        headings={[
          {
            type: 'h3',
            title: '4. 基本情報',
          },
        ]}
        inputsData={confirmSectionData.section5}
      />
      <ConfirmSection
        headings={[
          {
            type: 'h3',
            title: '5. 物件に関するお問い合わせ先',
          },
        ]}
        inputsData={confirmSectionData.section6}
      />
      <ConfirmSection
        headings={[
          {
            type: 'h3',
            title: '6. 物件の詳細設定',
          },
        ]}
        inputsData={confirmSectionData.section7}
      />
      <ConfirmSection
        headings={[
          {
            type: 'h3',
            title: '7. 落札後の連絡先',
          },
        ]}
        inputsData={confirmSectionData.section8}
      />
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              type="button"
              text={property_id === undefined ? '登録' : '更新'}
              isOutline={false}
              height={'44px'}
              clickHandler={handleComplete}
              className={
                'property-info-general-form-button new-style_navi_primary-button'
              }
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              type="button"
              text={'編集'}
              isOutline={false}
              height={'44px'}
              clickHandler={handleBackForm}
              className={
                'property-info-general-form-button common-button-bg-whitesmoke new-style_navi_normal-button'
              }
            />
          </div>
          {property_id === undefined ? (
            <></>
          ) : (
            <div className="new-style_navi_right-button-wrap">
              <Button
                type="button"
                text={'新しい物件として登録'}
                isOutline={false}
                height={'44px'}
                clickHandler={handleComplete}
                className={
                  'property-info-general-form-button new-style_navi_primary-button'
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default PropertyInfoCarConfirm;
