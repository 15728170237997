import 'interfaces/css/SampleGuideline/manual-marked-list-record.css';
const ManualMarkedListRecord = ({ mark, text, isImportant = false }) => {
  return (
    <span className="d-flex" data-testid="manual-marked-list-record">
      {mark && <span data-testid="manual-marked-list-record-mark">{mark}</span>}
      <span
        data-testid="manual-marked-list-record-text"
        className={`${isImportant ? 'manual-marked-list-record-text-important' : ''}`}>
        {text}
      </span>
    </span>
  );
};
export default ManualMarkedListRecord;
