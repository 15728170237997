import CautionDescription from './CautionDescription';
import FormTextArea from './FormTextArea';
import Label from './Label';

const FormTextAreaWithCaution = ({
  label,
  caution_description,
  number_remaining_characters,
  maxCharacters,
  required = false,
  data = '',
  handler1 = () => {},
  handler2 = () => {},
  invalidMessage = '',
}) => {
  return (
    <div className="property-info-general-form-table-row">
      <Label label={label} required={required} />
      <div className="property-info-general-form-table-cell">
        <FormTextArea
          number_remaining_characters={number_remaining_characters}
          rows={10}
          data={data}
          required={required}
          handler1={handler1}
          handler2={handler2}
          invalidMessage={invalidMessage}
          maxCharacters={maxCharacters}
        />
        <CautionDescription data={caution_description} />
      </div>
    </div>
  );
};

export default FormTextAreaWithCaution;
