import { validateSelected } from "../../../../application/validations.js";

export class SellRealEstate {
  #sellRealEstate

  constructor(sellRealEstate) {
    this.#sellRealEstate = sellRealEstate;
  }

  get() {
    return this.#sellRealEstate
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#sellRealEstate)
  }
}
