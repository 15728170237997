import PropertyFormInputFile from 'interfaces/components/common/PropertyInfoForm/PropertyFormInputFile';
import PropertyInfoFormLabel from 'interfaces/components/common/PropertyInfoForm/PropertyInfoFormLabel';

import CautionDescription from './CautionDescription';

const FormFileWithCaution = ({
  label,
  caution_description,
  handler,
  required = false,
  onlyImage = false,
  invalidMessage = 'TRUE',
}) => {
  return (
    <div
      data-testid="property-info-general-form-input-file"
      className="property-info-general-form-table-row">
      <PropertyInfoFormLabel label={label} required={required} />
      <div className="property-info-general-form-table-cell">
        <PropertyFormInputFile
          onlyImage={onlyImage}
          handler={handler}
          invalidMessage={invalidMessage}
        />
        <CautionDescription data={caution_description} />
      </div>
    </div>
  );
};
export default FormFileWithCaution;
