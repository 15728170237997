import GovernmentFilterButton from './GovernmentFilterButton.jsx';
import 'interfaces/css/common/government-filter.css';

const GovernmentFilter = ({ searchInput, setSearchInput }) => {
  return (
    <div
      data-testid="kmanager-government-filter"
      className="government-filter d-flex align-center justify-center">
      <GovernmentFilterButton
        text="絞り込み"
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
    </div>
  );
};
export default GovernmentFilter;
