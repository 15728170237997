// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mylist {
  max-width: 1200px;
  min-height: 500px;
  width: 90%;
  margin-top: 24px;
  margin-right: auto;
  margin-left: auto;
  background: none;
  padding-bottom: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Mylist/mylist.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,UAAU;EACV,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":[".mylist {\n  max-width: 1200px;\n  min-height: 500px;\n  width: 90%;\n  margin-top: 24px;\n  margin-right: auto;\n  margin-left: auto;\n  background: none;\n  padding-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
