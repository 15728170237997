export const createGeneralCategoryData = (categories, properties) => {
  let categoryData = [];

  const parentCategories = categories.filter(
    (data) => data.depth === '1' && data.displayService === 'DISPLAY',
  );

  parentCategories.forEach((category) => {
    let parentCategory = {
      childCategory: [],
      propertyIds: [],
      relationShipCategoryIds: [],
    };
    parentCategory.depth = category.depth;
    parentCategory.displayCar = category.displayCar;
    parentCategory.displayEstate = category.displayEstate;
    parentCategory.displayProperty = category.displayProperty;
    parentCategory.displayService = category.displayService;
    parentCategory.id = category.id;
    parentCategory.name = category.name;
    parentCategory.parentId = '0';
    parentCategory.relationShipCategoryIds.push(parentCategory.id);

    let childCategories = categories.filter(
      (data) =>
        data.parentId === parentCategory.id &&
        data.depth === '2' &&
        data.displayService === 'DISPLAY',
    );

    childCategories.forEach((cCategory) => {
      let childCategory = {
        childCategory: [],
        propertyIds: [],
        relationShipCategoryIds: [],
      };
      childCategory.depth = cCategory.depth;
      childCategory.displayService = cCategory.displayService;
      childCategory.id = cCategory.id;
      childCategory.name = cCategory.name;
      childCategory.parentId = cCategory.parentId;
      parentCategory.childCategory.push(childCategory);
      parentCategory.relationShipCategoryIds.push(childCategory.id);

      let grandchildCategories = categories.filter(
        (data) =>
          data.parentId === childCategory.id &&
          data.depth === '3' &&
          data.displayService === 'DISPLAY',
      );

      grandchildCategories.forEach((gCategory) => {
        let grandchildCategory = {
          childCategory: [],
          propertyIds: [],
          relationShipCategoryIds: [],
        };
        grandchildCategory.depth = gCategory.depth;
        grandchildCategory.displayService = gCategory.displayService;
        grandchildCategory.id = gCategory.id;
        grandchildCategory.name = gCategory.name;
        grandchildCategory.parentId = gCategory.parentId;
        properties.forEach((property) => {
          if (property.categoryId === grandchildCategory.id) {
            grandchildCategory.propertyIds.push(property.id);
          }
        });
        grandchildCategory.relationShipCategoryIds.push(grandchildCategory.id);
        childCategory.childCategory.push(grandchildCategory);
        childCategory.relationShipCategoryIds.push(grandchildCategory.id);
        parentCategory.relationShipCategoryIds.push(grandchildCategory.id);
      });
      childCategory.relationShipCategoryIds.map((id) => {
        properties.forEach((property) => {
          if (property.categoryId === id) {
            childCategory.propertyIds.push(property.id);
          }
        });
      });
    });

    parentCategory.relationShipCategoryIds.map((id) => {
      properties.forEach((property) => {
        if (property.categoryId === id) {
          parentCategory.propertyIds.push(property.id);
        }
      });
    });

    categoryData.push(parentCategory);
  });

  return categoryData;
};
