import CalcMethodTable from 'interfaces/components/Billing/CalcMethodTable';
import 'interfaces/css/ConfirmSystemUsage/calc-method-details.css'

const CalcMethodDetails = () => {
  return (
    <div data-testid="calc-method-details">
      <div className="calc-method-caption" data-testid="calc-method-caption">
        計算方法の詳細はこちらをご確認ください。契約書等の特例によるシステム利用料請求額は反映していません。
      </div>
      <CalcMethodTable/>
    </div>
  );
};

export default CalcMethodDetails;
