import { ReactComponent as EyeIcon } from "interfaces/assets/svg/eye.svg";
import FlatButton from "interfaces/components/common/Button/FlatButton.jsx";
import 'interfaces/css/LocalGovernmentManagement/preview-button.css';

const PreviewButton = ({ governmentType }) => {
  return (
    <div className='preview-button' data-testid='preview-button'>
      {
        governmentType === "NATIONAL_TAX_AGENCY"
        ? <FlatButton buttonStyle={{ width: "75%", maxWidth: "130px" }} text="プレビュー" icon={<EyeIcon/>}/>
        : <>
            <FlatButton buttonStyle={{ width: "45%", maxWidth: "130px" }} text="せり売" icon={<EyeIcon/>}/>
            <FlatButton buttonStyle={{ width: "45%", maxWidth: "130px" }} text="入札" icon={<EyeIcon/>}/>
          </>
      }
    </div>
  )
};
export default PreviewButton;