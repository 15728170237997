// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 960px) {
  .properties {
    display: flex;
    justify-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Properties/properties.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;IACb,uBAAuB;EACzB;AACF","sourcesContent":["@media screen and (min-width: 960px) {\n  .properties {\n    display: flex;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
