const calcPaymentScheduledPrice = (data) => {
  return data.reduce((sum, item) => {
    return sum + (item.paymentScheduledPrice === '-9999' || item.paymentScheduledPrice === undefined ? 0 : Number(item.paymentScheduledPrice))
  }, 0)
}

const calcRestitutionScheduledPrice = (data) => {
  return data.reduce((sum, item) => {
    return sum + (item.restitutionScheduledPrice === '-9999' || item.restitutionScheduledPrice === undefined ? 0 : Number(item.restitutionScheduledPrice))
  }, 0)
}

const GovernmentDepositsTableFooter = ({ data }) => {
  console.log(data)
  return (
    <tfoot data-testid="kmanager-table-footer">
      <tr className="kmanager-tr kmanager-table-record text-center">
        <td colSpan="6" data-testid="kmanager-table-td"/>
        <td data-testid="kmanager-table-td">合計</td>
        <td data-testid="kmanager-table-td">{calcPaymentScheduledPrice(data).toLocaleString()}円</td>
        <td data-testid="kmanager-table-td">{calcRestitutionScheduledPrice(data).toLocaleString()}円</td>
      </tr>
    </tfoot>
  );
};

export default GovernmentDepositsTableFooter;
