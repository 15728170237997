
export class ReceiverTel {
  #receiverTel

  constructor(receiverTel) {
    this.#receiverTel = receiverTel;
  }

  get() {
    return this.#receiverTel
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
