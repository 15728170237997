import { validateRangeLength, isOnlyFullwidthKatakana, replaceFullwidthKatakana } from "../../../../application/validations.js";

export class AccountNameKana {
  #accountNameKana

  constructor(accountNameKana) {
    this.#accountNameKana = accountNameKana;
  }

  get() {
    return this.#accountNameKana
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.isOnlyFullwidthKatakana,
      this.replaceFullwidthKatakana,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#accountNameKana, 1, 50)
  }

  // バリデーションルールの適用
  get isOnlyFullwidthKatakana() {
    return isOnlyFullwidthKatakana(this.#accountNameKana)
  }

  // バリデーションルールの適用
  get replaceFullwidthKatakana() {
    return replaceFullwidthKatakana(this.#accountNameKana)
  }
}
