import { createContext, useState, useContext } from 'react';

// Contextの作成
const SnackBarContext = createContext();

export const useSnackBar = () => useContext(SnackBarContext);

export const SnackBarProvider = ({ children }) => {
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [message, setMessage] = useState('');

  const openSnackBar = (msg) => {
    setMessage(msg);
    setIsSnackBarOpen(true);
  };

  return (
    <SnackBarContext.Provider
      value={{ isSnackBarOpen, setIsSnackBarOpen, openSnackBar, message }}>
      {children}
    </SnackBarContext.Provider>
  );
};
