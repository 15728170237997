// ロジックを含まない処理の実装

import { fetchPropertyImagesStorage } from "../../../infrastructure/api/storage/property_images.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { PropertyFilesGet } from "../entities/Files.js";

// getメソッドの実装
export async function getPropertyFiles(type) {
  // SAMPLE
  let response = [];
  if (type === SERVICE) {
    response = await fetchPropertyImagesStorage()
  }
  if (type === NAVI) {
    response = await fetchPropertyImagesStorage()
  }
  if (type === KMANAGER) {
    response = await fetchPropertyImagesStorage()
  }

  if (response instanceof Error) {
    throw new CustomError('Error get Files', response);
  }

  let results = [];
  response.map((data) => {
    const result = new PropertyFilesGet(data);
    results.push(result)
  })

  return results
}
