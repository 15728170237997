import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class DocumentEstateDisplay {
  #documentEstateDisplay

  constructor(documentEstateDisplay) {
    this.#documentEstateDisplay = documentEstateDisplay;
  }

  get() {
    return this.#documentEstateDisplay
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#documentEstateDisplay, 0, 1000)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#documentEstateDisplay)
  }
}
