import { ReactComponent as ArrowDropDownIcon } from 'interfaces/assets/svg/arrow_drop_down_icon.svg';
import { useState, useRef, useEffect } from 'react';
import 'interfaces/css/common/pop-up-input.css';

const PopUpInput = ({
  items,
  value,
  handleSelect,
  placeholder,
  name,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const menuRef = useRef(null);

  const openMenu = () => {
    if (inputRef.current) {
      setIsOpen(true);
    }
  };

  const selectItem = (item) => {
    handleSelect(item);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !inputRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`pop-up-input-wrap ${className}`}>
      <div className="pop-up-input-body">
        <input
          ref={inputRef}
          readOnly
          type="text"
          aria-label={name}
          value={value}
          onClick={openMenu}
          placeholder={placeholder}
          className="pop-up-input"
          data-testid={`${name}-pop-up-input`}
        />
        <ArrowDropDownIcon
          title="arrow-drop-down-icon"
          className="pop-up-input-icon"
        />
      </div>
      {isOpen && (
        <div ref={menuRef} className="pop-up-menu" data-testid="pop-up-menu">
          <ul className="pop-up-menu-item-wrap">
            {items.map((item, index) => (
              <li
                key={index}
                onClick={() => selectItem(item)}
                className={`pop-up-menu-item ${value === item ? 'active' : ''}`}
                data-testid="pop-up-menu-item">
                {item.label ?? item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PopUpInput;
