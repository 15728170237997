import axios from 'axios';

import {API_BASE_URL} from "../../config/envConstants.js";
//import pino from "pino";
//import { fetchData } from "../../shared/utils/helper/fetchData.js";
//import { extractData } from "../../shared/utils/helper/extractData.js";
//import { BASE_URL_SERVICE } from "../../config/envConstants.js";

export const createPropertiesImageData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/properties_images`,
    body, {headers: headers}
  );

};
export const updatePropertiesImageData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/properties_images/${id}`,
    body, {headers: headers}
  );
};
export const deletePropertiesImageData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/properties_images/${id}`, {
      headers: headers,
    },);
};

//const logger = pino();


