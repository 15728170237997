import { hasEmoji, validateRangeLength } from "../../../../application/validations.js";

export class CostAttention {
  #costAttention

  constructor(costAttention) {
    this.#costAttention = costAttention;
  }

  get() {
    return this.#costAttention
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#costAttention)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#costAttention, 0, 1000)
  }
}
