export const checkbox_label_recommend = [
  {id: 1, text: "イチ押しとして設定", name: "recommend"}
]
export const checkbox_label_emblem = [
  {id: 1, text: "使用する"}
]
export const checkbox_label = [
  {id: 1, text: "銀行振込", name: "bankTransfer"},
  {id: 2, text: "現金書留", name: "registeredMail"},
  {id: 3, text: "小切手", name: "check"},
  {id: 4, text: "郵便為替", name: "postalMoneyOrder"},
  {id: 5, text: "直接持参", name: "carry"}
]
export const new_entry_checkbox = [
  {id: 1, text: "オン納付", name: 'paymentCreditCard'},
  {id: 2, text: "オフ納付", name: 'paymentBankTransfer'},
  {id: 3, text: "保証金なし", name: 'noneDeposit'},
]