import { validatePrefecture, validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class LocationText {
  #locationText

  constructor(locationText) {
    this.#locationText = locationText;
  }

  get() {
    return this.#locationText
  }

  get isValid() {
    return [
      this.validatePrefecture,
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validatePrefecture() {
    return validatePrefecture(this.#locationText)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#locationText, 0, 100)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#locationText)
  }
}
