import {useState} from "react";

export function useServiceProviderInputs() {
  const [postalCode, setPostalCode] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [ceoName, setCeoName] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [depositType, setDepositType] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactDepartment, setContactDepartment] = useState("");
  const [contactPaEmail, setContactPaEmail] = useState("");
  const [contactPpsEmail, setContactPpsEmail] = useState("");
  const [confirmer, setConfirmer] = useState("");
  const [issuer, setIssuer] = useState("");
  const [remarks, setRemarks] = useState("");

  return {
    type: {
      postalCode: 'text',
      address: 'text',
      name: 'text',
      position: 'text',
      ceoName: 'text',
      registrationNumber: 'text',
      bankName: 'text',
      branchName: 'text',
      depositType: 'text',
      accountNumber: 'text',
      accountHolder: 'text',
      contactPerson: 'text',
      contactDepartment: 'text',
      contactPaEmail: 'text',
      contactPpsEmail: 'text',
      confirmer: 'text',
      issuer: 'text',
      remarks: 'text',
    },
    data: {
      postalCode,
      address,
      name,
      position,
      ceoName,
      registrationNumber,
      bankName,
      branchName,
      depositType,
      accountNumber,
      accountHolder,
      contactPerson,
      contactDepartment,
      contactPaEmail,
      contactPpsEmail,
      confirmer,
      issuer,
      remarks,
    },
    setters: {
      postalCode: setPostalCode,
      address: setAddress,
      name: setName,
      position: setPosition,
      ceoName: setCeoName,
      registrationNumber: setRegistrationNumber,
      bankName: setBankName,
      branchName: setBranchName,
      depositType: setDepositType,
      accountNumber: setAccountNumber,
      accountHolder: setAccountHolder,
      contactPerson: setContactPerson,
      contactDepartment: setContactDepartment,
      contactPaEmail: setContactPaEmail,
      contactPpsEmail: setContactPpsEmail,
      confirmer: setConfirmer,
      issuer: setIssuer,
      remarks: setRemarks,
    }
  }
}