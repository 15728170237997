import BeforeInvoicePublicSaleTypeSystemUsages from "interfaces/components/Billing/BeforeInvoicePublicSaleTypeSystemUsages";

const BeforeInvoiceSystemUsage = ({data}) => {
  return (
    <div data-testid="system-usages">
      {/* TODO: API疎通したらダミーデータを置き換える */}
      <BeforeInvoicePublicSaleTypeSystemUsages publicSaleType="せり売り" data={data.auctionData}/>
      <BeforeInvoicePublicSaleTypeSystemUsages publicSaleType="入札" data={data.bidData}/>
    </div>
  );
}

export default BeforeInvoiceSystemUsage;