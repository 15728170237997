import Schedule from 'interfaces/components/common/Schedule/Schedule';

const ScheduleSection = ({ schedule, index }) => {
  if  (!schedule.termData) {
    return;
  }

  return (
    <div key={index} className={schedule.className} data-testid={schedule.className}>
      <Schedule
        prefix={schedule.termData?.status}
        title={schedule.title}
        titleColor={schedule.titleColor}
        termData={schedule.termData}
        scheduleType="NEARLY"
      />
    </div>
  );
};

export default ScheduleSection;
