import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  background: #fff;
  border: 1px solid var(--line-base);
  border-radius: 4px;
  padding: 32px 24px;
  margin-bottom: 32px;
`;

const ArticleDefaultBody = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default ArticleDefaultBody;
