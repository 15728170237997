import { PREFECTURE } from 'shared/utils/constants/prefecture.js';

import ApplicantInfoForm from './ApplicantInfoForm.jsx';
import DelegatorInfoForm from './DelegatorInfoForm.jsx';
import { useContext } from 'react';
import { ApplicationContext } from 'interfaces/pages/Application/entities/ApplicationContext.js';
import { useEffect, useState } from 'react';

const generateCallUpInfo = (registeredInfo) => {
  return {
    corpName: registeredInfo.corpName,
    corpNameKana: registeredInfo.corpNameKana,
    familyName: registeredInfo.familyName,
    firstName: registeredInfo.firstName,
    familyNameKana: registeredInfo.familyNameKana,
    firstNameKana: registeredInfo.firstNameKana,
    sex: registeredInfo.sex,
    birthDate: new Date(registeredInfo.birthDate),
    postalCode1: registeredInfo.postalCode.split('-')[0],
    postalCode2: registeredInfo.postalCode.split('-')[1],
    prefectureId: PREFECTURE.find(
      (prefecture) =>
        prefecture.id === registeredInfo.locationGovernmentSubCategoryId,
    )?.id,
    address: registeredInfo.address,
    houseTel: registeredInfo.houseTel,
    mobileTel: registeredInfo.mobileTel,
  };
};

const ApplicationInfoForm = ({
  data,
  applicantInformation,
  delegatorInformation,
  setApplicantInformation,
  setDelegatorInformation,
}) => {
  const { preregistrationApplicantInformation } =
    useContext(ApplicationContext);
  // DBに保存されているマイリストで登録した情報
  const [registeredApplicantInfo, setRegisteredApplicantInfo] = useState({});
  const [registeredDelegatorInfo, setRegisteredDelegatorInfo] = useState({});

  useEffect(() => {
    const applicantInfo = preregistrationApplicantInformation.find(
      (info) => info.type === 'APPLICANT',
    );
    setRegisteredApplicantInfo(applicantInfo);
    const delegatorInfo = preregistrationApplicantInformation.find(
      (info) => info.type === 'CLIENT',
    );
    setRegisteredDelegatorInfo(delegatorInfo);
  }, [preregistrationApplicantInformation]);

  return (
    <div>
      <ApplicantInfoForm
        data={data}
        applicantInfo={applicantInformation}
        registeredInfo={registeredApplicantInfo}
        setApplicantInformation={setApplicantInformation}
        callUpInfo={generateCallUpInfo}
      />
      {applicantInformation?.isDelegatorProcedure && (
        <DelegatorInfoForm
          delegatorInfo={delegatorInformation}
          registeredInfo={registeredDelegatorInfo}
          setDelegatorInformation={setDelegatorInformation}
          callUpInfo={generateCallUpInfo}
        />
      )}
    </div>
  );
};

export default ApplicationInfoForm;
