// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kmanager-table-record > td {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "Yu Gothic", "Hiragino Kaku Gothic ProN", Meiryo, verdana, "Droid Sans", Roboto, sans-serif;
  font-size: .875rem;
  height: 48px;
  padding: 0 16px;
  color: rgba(0, 0, 0, .87);
  border-top: thin solid rgba(0, 0, 0, .12);
  border-right: thin solid hsla(0, 0%, 50.2%, .12) !important;
  border-bottom: thin solid hsla(0, 0%, 50.2%, .12) !important;
}

.kmanager-table-record > td > div {
  white-space: nowrap;
}

.kmanager-table-empty-record > td > div {
  text-align: center;
}

.kmanager-table-control {
  text-align: center;
}

.kmanager-table-control svg {
  fill: rgba(0,0,0,.54);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/kmanager-table-record.css"],"names":[],"mappings":"AAAA;EACE,uKAAuK;EACvK,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,yBAAyB;EACzB,yCAAyC;EACzC,2DAA2D;EAC3D,4DAA4D;AAC9D;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".kmanager-table-record > td {\n  font-family: -apple-system, BlinkMacSystemFont, \"Helvetica Neue\", YuGothic, \"Yu Gothic\", \"Hiragino Kaku Gothic ProN\", Meiryo, verdana, \"Droid Sans\", Roboto, sans-serif;\n  font-size: .875rem;\n  height: 48px;\n  padding: 0 16px;\n  color: rgba(0, 0, 0, .87);\n  border-top: thin solid rgba(0, 0, 0, .12);\n  border-right: thin solid hsla(0, 0%, 50.2%, .12) !important;\n  border-bottom: thin solid hsla(0, 0%, 50.2%, .12) !important;\n}\n\n.kmanager-table-record > td > div {\n  white-space: nowrap;\n}\n\n.kmanager-table-empty-record > td > div {\n  text-align: center;\n}\n\n.kmanager-table-control {\n  text-align: center;\n}\n\n.kmanager-table-control svg {\n  fill: rgba(0,0,0,.54);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
