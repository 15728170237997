
export class RegistrationNumber {
    #registrationNumber

    constructor(registrationNumber) {
        this.#registrationNumber = registrationNumber;
    }

    get() {
        return this.#registrationNumber
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
