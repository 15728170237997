import { useState } from 'react';
import * as Validation from 'shared/utils/helper/managerValidators.js';

export function useBannerInputsValidation() {
  const [bannerTitleRequiredValid, setBannerTitleRequiredValid] =
    useState(null);
  const [destinationUrlValid, setDestinationUrlValid] = useState(null);

  function handleDestinationUrlChange(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateDestinationHttps(e.target.value);
    if (invalid !== 'TRUE') {
      setDestinationUrlValid(invalid);
      return;
    }
    invalid = Validation.validateDestinationLength(e.target.value);
    if (invalid !== 'TRUE') {
      setDestinationUrlValid(invalid);
      return;
    }
    setDestinationUrlValid(invalid); // 状態更新
  }

  function handleTitleRequiredChange(e) {
    let invalid = null;
    invalid = Validation.validateRangeTitleLength2(e.target.value);
    if (invalid !== 'TRUE') {
      setBannerTitleRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateIncludeEmojiTitle2(e.target.value);
    if (invalid !== 'TRUE') {
      setBannerTitleRequiredValid(invalid);
      return;
    }
    setBannerTitleRequiredValid(invalid); // 状態更新
  }

  const validations = {
    // validationの処理
    setters: {
      title: handleTitleRequiredChange,
      destinationUrl: handleDestinationUrlChange,
    },
    // invalidの時のメッセージ
    validationMessage: {
      title: bannerTitleRequiredValid,
      destinationUrl: destinationUrlValid,
    },
  };

  return validations;
}
