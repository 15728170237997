import { useState, useContext } from 'react';

import ManagementUserFormModal from './ManagementUserFormModal';
import ManagementUserTable from './ManagementUserTable';
import Button from '../../common/Button.jsx';
import PageTitle from '../../common/PageTitle.jsx';
import Toolbar from '../../common/Toolbar.jsx';
import { UserContext } from 'interfaces/pages/User/entities/UserContext';
import { isEmpty } from 'shared/utils/constants/emptyData.js';
import { KmanagerUserTypes } from 'shared/utils/constants/user.js';
const ManagementUsers = () => {
  const { loginUser } = useContext(UserContext);
  if (isEmpty(loginUser)) {
    return <div>読み込み中</div>;
  }

  const isAdmin = loginUser.userType === KmanagerUserTypes.ADMIN.string;
  // 新規作成時のモーダル制御
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  return (
    <div
      className="kmanager-users kmanager-auction-operation"
      data-testid="kmanager-users">
      {isCreateModalOpen ? (
        <ManagementUserFormModal
          closeModal={() => setIsCreateModalOpen(false)}
        />
      ) : (
        ''
      )}
      {/*
      <KmanagerUserFormModal newId={newId} isOpen={isOpen} closeModal={() => setIsOpen(false)}/>
      */}
      <div className="kmanager-row">
        <div className="kmanager-col kmanager-col-6">
          <PageTitle text="ユーザー管理" />
        </div>
        <div className="kmanager-col kmanager-col-12">
          <div className="box-shadow">
            {isAdmin && (
              <Toolbar>
                <Button
                  className="primary"
                  text="新規ユーザー作成"
                  clickHandler={() =>
                    setIsCreateModalOpen((prevState) => !prevState)
                  }
                />
              </Toolbar>
            )}
            <ManagementUserTable isAdmin={isAdmin} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementUsers;
