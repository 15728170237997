import {Link, useParams} from "react-router-dom";
import "interfaces/css/common/property-info-general-form-image.css"

const PropertyInfoGeneralFormImage = ({label}) => {
  const { auction_id } = useParams()

  return (
    <div data-testid="property-info-general-form-image" className={"property-info-general-form-image"}>
      <div className="property-info-general-form-image-table-row">
        <div className="property-info-general-form-image-table-label">
          <span data-testid="property-info-general-form-image-label">{label}</span>
        </div>
        <div className="property-info-general-form-image-link">
          <p>画像が登録されていません。</p>
          <div>
            <Link to={`/navi/object/${auction_id}/create/upload-images/general`}>画像登録</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PropertyInfoGeneralFormImage
// import ImageUploader from '/components/Image/ImageUploader.jsx';
// <ImageUploader setState={setState}/>
