// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard .upcoming-auction-table-wrap {
  margin-top: 40px;
  overflow-y: scroll;
}

.dashboard .upcoming-auction-table {
  width: 100%;
  border: 1px solid #ccc !important;
  border-spacing: 0;
}

.dashboard .upcoming-auction-table thead {
  background-color: #fbe2ca;
}

.dashboard .upcoming-auction-table thead th {
  color: rgba(0,0,0,.6);
}

.dashboard .upcoming-auction-table th {
  border: 1px solid #ccc !important;
  font-size: .75rem;
  height: 32px;
  padding: 0 16px;
}

.dashboard .upcoming-auction-table tbody th {
  text-align: left;
}

.dashboard .upcoming-auction-table td {
  border: 1px solid #ccc !important;
  font-size: .875rem;
  height: 32px;
  padding: 0 16px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Dashboard/upcoming-auction-table.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,iCAAiC;EACjC,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iCAAiC;EACjC,iBAAiB;EACjB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;EACjC,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".dashboard .upcoming-auction-table-wrap {\n  margin-top: 40px;\n  overflow-y: scroll;\n}\n\n.dashboard .upcoming-auction-table {\n  width: 100%;\n  border: 1px solid #ccc !important;\n  border-spacing: 0;\n}\n\n.dashboard .upcoming-auction-table thead {\n  background-color: #fbe2ca;\n}\n\n.dashboard .upcoming-auction-table thead th {\n  color: rgba(0,0,0,.6);\n}\n\n.dashboard .upcoming-auction-table th {\n  border: 1px solid #ccc !important;\n  font-size: .75rem;\n  height: 32px;\n  padding: 0 16px;\n}\n\n.dashboard .upcoming-auction-table tbody th {\n  text-align: left;\n}\n\n.dashboard .upcoming-auction-table td {\n  border: 1px solid #ccc !important;\n  font-size: .875rem;\n  height: 32px;\n  padding: 0 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
