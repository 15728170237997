import { ReactComponent as PencilIcon } from 'interfaces/assets/svg/pencil.svg';
import { ReactComponent as SaveIcon } from 'interfaces/assets/svg/save.svg';
import { validateInputsCategory } from 'interfaces/components/common/validateInputs/validateInputsCategory';
import { useEffect, useRef, useState } from 'react';
import { category_display } from 'shared/utils/helper/checkbox_labels';
import { initInput } from 'shared/utils/helper/input_helper';
import { ReactComponent as AlertIcon } from 'interfaces/assets/svg/alert_yellow.svg';
import { Tooltip } from 'react-tooltip';

import InputCategoryDisplay from './InputCategoryDisplay';
import InputCategoryName from './InputCategoryName';
import Button from '../Button';
import 'interfaces/css/Category/category_accordion.css';

const CategoryAccordion = ({
  id,
  marginLeft,
  contents,
  category,
  handleAdd,
  parentId,
  grandParentId,
  setInputs,
  depth,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const [onEdit, setOnEdit] = useState(false);
  const inputs = validateInputsCategory();

  const toggleCategoryAccordion = () => {
    setIsOpen(!isOpen);
    contentRef.current.style.display = isOpen ? 'none' : 'block';
  };

  const startEdit = () => {
    setOnEdit(true);
  };

  const exitEdit = () => {
    setInputs((prevState) => {
      console.log(prevState);
      const newState = { ...prevState, [category.id]: [...inputs] };
      console.log(newState);
      return newState;
    });
    setOnEdit(false);
  };

  const handleChange = (e, column) => {
    const target = inputs.find((input) => input.column === column);
    const nextValue = target.setters(e);
    const newInputs = [...inputs, { ...target, state: nextValue }];
    setInputs((prevState) => ({ ...prevState, [category.id]: [newInputs] }));
  };

  useEffect(() => {
    initInput(inputs, category);
    inputs.find((input) => input.column === 'depth').init(depth);
    inputs.find((input) => input.column === 'parentId').init(grandParentId);
  }, []);

  return (
    <div id={id} data-testid={`${id}-accordion`}>
      <div className="accordion-panel flex align-center">
        <button
          onClick={toggleCategoryAccordion}
          className="accordion-toggle-button"
          data-testid={`${id}-toggle`}
        />
        <div className="kmanager-accordion-label">
          {onEdit ? (
            <InputCategoryName
              invalidMessage={
                inputs.find((input) => input.column === 'name')
                  .validationMessage
              }
              state={inputs.find((input) => input.column === 'name').state}
              setState={inputs.find((input) => input.column === 'name').setters}
            />
          ) : (
            <p>{inputs.find((input) => input.column === 'name').state}</p>
          )}
          {onEdit ? (
            <SaveIcon onClick={exitEdit} />
          ) : (
            <PencilIcon
              className="edit-button"
              data-testid="edit-button"
              onClick={startEdit}
            />
          )}
          {!isNaN(parseInt(category.id)) && (
            <Button
              className="secondary small"
              text="子カテゴリ追加"
              clickHandler={() => handleAdd(parentId, grandParentId)}
            />
          )}
          {category.id.includes('new') && (
            <>
              <AlertIcon
                className="ml-2"
                data-tooltip-id={`alert-tooltip-${category.id}`}
              />
              <Tooltip
                id={`alert-tooltip-${category.id}`}
                place="right"
                effect="solid"
                arrowColor={'rgba(0, 0, 0, 0)'}
                style={{
                  background: 'rgba(97, 97, 97, .9)',
                  color: '#fff',
                  fontSize: '14px',
                  padding: '5px 16px',
                  textTransform: 'none',
                  width: 'auto',
                  pointerEvents: 'none',
                }}>
                <span>子カテゴリを追加するために画面上部の</span>
                <br />
                <span>保存ボタンをクリックしてください</span>
              </Tooltip>
            </>
          )}
          <div className="kmanager-accordion-form flex align-center ml-auto">
            {category_display.map((label) => {
              return (
                <InputCategoryDisplay
                  status={
                    inputs.find((input) => input.column === label.name).state
                  }
                  handleChange={handleChange}
                  label={label}
                  key={label.id}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div
        ref={contentRef}
        style={{ display: 'none', marginLeft: `${marginLeft ?? 0}px` }}>
        {contents}
      </div>
    </div>
  );
};

export default CategoryAccordion;
