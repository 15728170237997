export const sortItems = ['物件価格の高い順', '物件価格の低い順', '保証金の高い順', '保証金の低い順', '入札数の多い順', '入札数の少ない順'];
export const sortCarItems = ['物件価格の高い順', '物件価格の低い順', '保証金の高い順', '保証金の低い順', '入札数の多い順', '入札数の少ない順', '走行距離の長い順', '走行距離の短い順', '初度登録年月が新しい順', '初度登録年月が古い順'];
export const displayNumItems = ['20件', '50件', '100件'];
export const itemNameToQueryParamTable = {
  '物件価格の高い順': {
    direction: 'DESC',
    field: 'PRICE'
  },
  '物件価格の低い順': {
    direction: 'ASC',
    field: 'PRICE'
  },
  '保証金の高い順': {
    direction: 'DESC',
    field: 'DEPOSIT'
  },
  '保証金の低い順': {
    direction: 'ASC',
    field: 'DEPOSIT'
  },
  '入札数の多い順': {
    direction: 'DESC',
    field: 'BID_TOTAL_COUNT'
  },
  '入札数の少ない順': {
    direction: 'ASC',
    field: 'BID_TOTAL_COUNT'
  },
  '走行距離の長い順': {
    direction: 'DESC',
    field: 'CAR_MILEAGE'
  },
  '走行距離の短い順': {
    direction: 'ASC',
    field: 'CAR_MILEAGE'
  },
  '初度登録年月が新しい順': {
    direction: 'DESC',
    field: 'FIRST_REGIST_DATE'
  },
  '初度登録年月が古い順': {
    direction: 'ASC',
    field: 'FIRST_REGIST_DATE'
  },
}
export const itemNameToDisplayNumTable = {
  '20件': 20,
  '50件': 50,
  '100件': 100,
};