
export class BidEndButtonDownBidDate {
  #bidEndButtonDownBidDate

  constructor(bidEndButtonDownBidDate) {
    this.#bidEndButtonDownBidDate = bidEndButtonDownBidDate;
  }

  get() {
    return this.#bidEndButtonDownBidDate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
