import { useState, useContext, useEffect } from 'react';

import PropertiesLeftSideMenuPC from './PropertiesLeftSideMenuPC';
//import PropertiesLeftSideMenuSP from './PropertiesLeftSideMenuSP.jsx';
import PropertyList from './PropertyList/PropertyList';
import 'interfaces/css/Properties/properties.css';
import 'interfaces/css/Properties/properties-left-side-menu.css';
import 'interfaces/css/Properties/category-button.css';
import { PropertiesContext } from '../../../pages/Properties/entities/PropertiesContext';

const Properties = () => {
  const { searchParams, properties, propertyCount } =
    useContext(PropertiesContext);
  const category = searchParams?.get('category');
  const [isImageList, setIsImageList] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [searchParams]);

  return (
    <div className="properties inner">
      <PropertiesLeftSideMenuPC
        category={category}
        propertiesNum={propertyCount.general}
        estatePropertiesNum={propertyCount.estate}
        carPropertiesNum={propertyCount.car}
      />
      {/*
      <PropertiesLeftSideMenuSP
        category={category}
        estatePropertiesNum={propertyCount.estate}
        carPropertiesNum={propertyCount.car}
      />
       */}
      <PropertyList
        category={category}
        dataList={properties}
        isImageList={isImageList}
        setIsImageList={setIsImageList}
        searchParams={searchParams}
      />
    </div>
  );
};
export default Properties;
