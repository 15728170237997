const MediumLightButton = ({ text, disabled, textStyle, onClick }) => {
  return (
    <button
      className={`button button-medium-light button-shadow ${disabled ? 'button-disabled' : ''}`}
      data-testid="medium-light-button"
      disabled={disabled}
      onClick={onClick}>
      <span className={`button-content`} style={textStyle}>
        {text}
      </span>
    </button>
  );
};

export default MediumLightButton;
