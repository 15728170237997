
export class CeoName {
    #ceoName

    constructor(ceoName) {
        this.#ceoName = ceoName;
    }

    get() {
        return this.#ceoName
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
