// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-text-area {
  flex: 1 1 auto;
  width: 100%;
  min-height: 56px;
  font-size: 1rem;
  padding: 12px;
  border-radius: 4px;
}

.common-text-area.normal-border {
  border: solid 1px rgba(0, 0, 0, 0.38);
}

.common-text-area.focus-border {
  border: solid 2px var(--primary-base);
}

.common-text-area.focus-error-border {
  border: solid 2px var(--error-base);
}

.common-text-area.blur-error-border {
  border: solid 1px var(--error-base);
}

.char-count {
  text-align: end;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/common-text-area.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".common-text-area {\n  flex: 1 1 auto;\n  width: 100%;\n  min-height: 56px;\n  font-size: 1rem;\n  padding: 12px;\n  border-radius: 4px;\n}\n\n.common-text-area.normal-border {\n  border: solid 1px rgba(0, 0, 0, 0.38);\n}\n\n.common-text-area.focus-border {\n  border: solid 2px var(--primary-base);\n}\n\n.common-text-area.focus-error-border {\n  border: solid 2px var(--error-base);\n}\n\n.common-text-area.blur-error-border {\n  border: solid 1px var(--error-base);\n}\n\n.char-count {\n  text-align: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
