// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createInvitationsData,
  updateInvitationsData,
  deleteInvitationsData,
} from '../../../infrastructure/api/Invitations.js';
import { updateNaviCommonDescriptionsData } from '../../../infrastructure/api/NaviCommonDescriptions.js';
import {
  createNaviInvitationsData,
  updateNaviInvitationsData,
} from '../../../infrastructure/api/NaviInvitations.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
//import { updateNaviAddDescriptionsData } from "../../../infrastructure/api/NaviAddDescriptions.js";
import {
  InvitationCreate,
  CommonDescriptionCreate,
} from '../entities/Invitation.js';
import { getInvitation } from '../repositories/Invitation.js';

export async function get(type) {
  return await getInvitation(type);
}

export async function findByGovernmentId(type, governmentId) {
  const invitations = await getInvitation(type);
  return invitations.filter(
    (data) => data.governmentId.toString() === governmentId.toString(),
  );
}

export async function findByAuctionId(type, auctionId) {
  const invitations = await getInvitation(type);
  return invitations.filter(
    (invitation) => invitation.auctionId.toString() === auctionId.toString(),
  );
}

export async function findByAuctionIds(type, auctionIds) {
  const invitations = await getInvitation(type);
  return invitations.filter((invitation) =>
    auctionIds.includes(invitation.auctionId.toString()),
  );
}

export async function findById(type, auctionId, governmentId) {
  let invitations = await getInvitation(type);
  return invitations.find(
    (invitation) =>
      invitation.auctionId.toString() === auctionId.toString() &&
      invitation.governmentId.toString() === governmentId.toString(),
  );
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Invitation)');
  }
  if (type === NAVI) {
    let invitation = new InvitationCreate(data);
    response = await createNaviInvitationsData(invitation);
  }
  if (type === KMANAGER) {
    let invitation = new InvitationCreate(data);
    response = await createInvitationsData(invitation);
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Invitation', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Invitation)');
  }
  if (type === NAVI) {
    let invitation = new InvitationCreate(data);
    response = await updateNaviInvitationsData(invitation, invitation.id);
  }
  if (type === KMANAGER) {
    let invitation = new InvitationCreate(data);
    response = await updateInvitationsData(invitation, invitation.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Invitation', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Invitation)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Invitation)');
  }
  if (type === KMANAGER) {
    response = await deleteInvitationsData(data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Invitation', response);
  }
  return response;
}

export async function createCommonDescription(type, data) {
  let response;

  if (type === NAVI) {
    let commonDescription = new CommonDescriptionCreate(data);
    response = await updateNaviCommonDescriptionsData(
      commonDescription,
      commonDescription.invitationId,
    );
  }

  if (response instanceof Error) {
    throw new CustomError('Error create Invitation', response);
  }
  return response;
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
