import { Email as ResetEmailEmail } from './MyUserResetEmail/email.js';
import { Id as ResetEmailId } from './MyUserResetEmail/id.js';
import { MembershipId as ResetEmailMembershipId } from './MyUserResetEmail/membershipId.js';
import { Password as ResetEmailPassword } from './MyUserResetEmail/password.js';
import { Status as ResetEmailStatus } from './MyUserResetEmail/status.js';
import { TwoStepVerificationStatus as ResetEmailTwoStepVerificationStatus } from './MyUserResetEmail/twoStepVerificationStatus.js';
import {
  Email,
  Email as ResetPasswordEmail,
} from './MyUserResetPassword/email.js';
import { Id } from './MyUserResetPassword/id';
import { Id as ResetPasswordId } from './MyUserResetPassword/id.js';
import {
  MembershipId,
  MembershipId as ResetPasswordMembershipId,
} from './MyUserResetPassword/membershipId.js';
import {
  Password,
  Password as ResetPasswordPassword,
} from './MyUserResetPassword/password.js';
import {
  Status,
  Status as ResetPasswordStatus,
} from './MyUserResetPassword/status.js';
import { TwoStepVerificationStatus as ResetPasswordTwoStepVerificationStatus } from './MyUserResetPassword/twoStepVerificationStatus.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { NotUndefined } from '../../../shared/utils/helper/NotUndefined.js';

export class MyUserInfoGet {
  constructor(props) {
    this.info = new MyUserInfoDataGet(props.info);
  }
}

export class MyUserInfoDataGet {
  constructor(data) {
    this.id = data.id;
    this.email = data.email;
    this.membershipId = data.membershipId;
    this.status = data.status;
    //this.password = data.password;
    //if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class MyBidPrice {
  constructor(props) {
    this.id = props.id;
    this.my_additional_price = props.my_additional_price;
    this.my_bid_price = props.my_bid_price;
  }
}

export class AllowPropertyIds {
  constructor(props) {
    this.ids = props.ids;
  }
}

export class ApplicantPropertyIds {
  constructor(props) {
    this.ids = props.ids;
  }
}

export class FavoritePropertyIds {
  constructor(props) {
    this.ids = props.ids;
  }
}

export class PropertiesIdFavoriteRegisterAsc {
  constructor(props) {
    this.ids = props.ids;
  }
}

export class PropertiesIdFavoriteRegisterDesc {
  constructor(props) {
    this.ids = props.ids;
  }
}

export class MyUserInfoDataCreate {
  constructor(props) {
    this.id = new Id(props.id);
    this.email = new Email(props.email);
    this.membership_id = new MembershipId(props.membership_id);
    this.status = new Status(props.status);
    this.password = new Password(props.password);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.id.isValid &&
      this.email.isValid &&
      this.membership_id.isValid &&
      this.status.isValid &&
      this.password.isValid
    );
  }
}

export class MyUserResetEmail {
  constructor(data) {
    this.email = new ResetEmailEmail(data.email);
    this.id = new ResetEmailId(data.id);
    this.membershipId = new ResetEmailMembershipId(data.membershipId);
    this.password = new ResetEmailPassword(data.password);
    this.status = new ResetEmailStatus(data.status);
    this.twoStepVerificationStatus = new ResetEmailTwoStepVerificationStatus(
      data.twoStepVerificationStatus,
    );
  }
}

export class MyUserResetPassword {
  constructor(data) {
    this.email = new ResetPasswordEmail(data.email);
    this.id = new ResetPasswordId(data.id);
    this.membershipId = new ResetPasswordMembershipId(data.membershipId);
    this.password = new ResetPasswordPassword(data.password);
    this.status = new ResetPasswordStatus(data.status);
    this.twoStepVerificationStatus = new ResetPasswordTwoStepVerificationStatus(
      data.twoStepVerificationStatus,
    );
  }
}
