import { Link } from 'react-router-dom';

const HeldInfo = ({ auctionStatus }) => {
  return (
    <div data-testid="held-info">
      {auctionStatus ? (
        <>
          <div className="mb-2" data-testid="held-info-message">
            <b>参加申し込み受付期間中</b>
          </div>
          <p className="text-right mb-0">
            <Link
              to="/search?searchQuery=&searchStatus=APPLYING"
              data-testid="held-info-link">
              今すぐ参加できます！＞
            </Link>
          </p>
          <hr
            role="separator"
            aria-orientation="horizontal"
            className="my-2 divider"
          />
        </>
      ) : (
        <div className="mb-2" data-testid="held-info-message">
          現在参加申込可能なオークションはありません
        </div>
      )}
    </div>
  );
};

export default HeldInfo;
