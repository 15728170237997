import { isFalsy } from "./isFalsy.js";

const bidStatus = {
  LOT_TOP_PRICE: 'LOT_TOP_PRICE',
  LOT_NEXT_PRICE: 'LOT_NEXT_PRICE',
  ADD_LOT_TOP_PRICE: 'ADD_LOT_TOP_PRICE',
  ADD_LOT_NEXT_PRICE: 'ADD_LOT_NEXT_PRICE',
}
const IS_LOT_MESSAGE = '(同額複数のため、くじで選定)';

/**
 * 仮実装
 * くじによって落札者が決定されたかを判定する
 *
 */
const isLot = (bidHistory) => {
  const history = bidHistory.filter(item => item.bidStatus === bidStatus.LOT_TOP_PRICE || item.bidStatus === bidStatus.LOT_NEXT_PRICE || item.bidStatus === bidStatus.ADD_LOT_TOP_PRICE || item.bidStatus === bidStatus.ADD_LOT_NEXT_PRICE);
  return history.length > 0;
}

export const biddedUser = (bidHistory, biddedUser) => {
  if (isFalsy(biddedUser)) {
    return '';
  }
  if (isFalsy(bidHistory)) {
    return biddedUser;
  }
  return isLot(bidHistory) ? `${biddedUser}${IS_LOT_MESSAGE}` : biddedUser;
}