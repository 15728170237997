import { validateSelected } from "../../../../application/validations.js";

export class IsDisplayNavi {
  #isDisplayNavi

  constructor(isDisplayNavi) {
    this.#isDisplayNavi = isDisplayNavi;
  }

  get() {
    return this.#isDisplayNavi
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#isDisplayNavi)
  }
}
