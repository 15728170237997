
export class SummaryDisplacement {
    #summaryDisplacement

    constructor(summaryDisplacement) {
        this.#summaryDisplacement = summaryDisplacement;
    }

    get() {
        return this.#summaryDisplacement
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
