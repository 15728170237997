
export class DisplayInquiry {
  #displayInquiry

  constructor(displayInquiry) {
    this.#displayInquiry = displayInquiry;
  }

  get() {
    return this.#displayInquiry
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
