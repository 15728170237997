// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedule-table {
    background-color: #fff;
    color: rgba(0,0,0,.87);
    line-height: 1.5;
    max-width: 100%;
}

.schedule-table table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #ccc;
}

.schedule-table th {
    background-color: #fbe2ca;
    border: 1px solid #ccc !important;
    -webkit-user-select: none;
            user-select: none;
    font-size: .75rem;
    font-weight: bold;
    color: rgba(0,0,0,.6);
}

.schedule-table td {
    border: 1px solid #ccc !important;
    -webkit-user-select: none;
            user-select: none;
    font-size: .75rem;
    color: rgba(0,0,0,.6);
    font-weight: bold;
    padding: 0 16px;
}


`, "",{"version":3,"sources":["webpack://./src/interfaces/css/AuctionSchedule/schedule-table.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,iCAAiC;IACjC,yBAAiB;YAAjB,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,iCAAiC;IACjC,yBAAiB;YAAjB,iBAAiB;IACjB,iBAAiB;IACjB,qBAAqB;IACrB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".schedule-table {\n    background-color: #fff;\n    color: rgba(0,0,0,.87);\n    line-height: 1.5;\n    max-width: 100%;\n}\n\n.schedule-table table {\n    width: 100%;\n    border-spacing: 0;\n    border: 1px solid #ccc;\n}\n\n.schedule-table th {\n    background-color: #fbe2ca;\n    border: 1px solid #ccc !important;\n    user-select: none;\n    font-size: .75rem;\n    font-weight: bold;\n    color: rgba(0,0,0,.6);\n}\n\n.schedule-table td {\n    border: 1px solid #ccc !important;\n    user-select: none;\n    font-size: .75rem;\n    color: rgba(0,0,0,.6);\n    font-weight: bold;\n    padding: 0 16px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
