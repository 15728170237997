
export class DisplayRegisterNumber {
  #displayRegisterNumber

  constructor(displayRegisterNumber) {
    this.#displayRegisterNumber = displayRegisterNumber;
  }

  get() {
    return this.#displayRegisterNumber
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
