import { useCarInputs } from './hook/useCarInputs';
import { UseCarInputsValidation } from './hook/useCarInputsValidation.jsx';

export function CarFormData({ isEdit = false }) {
  const formInputs = useCarInputs();
  const validate = UseCarInputsValidation({ isEdit: isEdit });

  // 公開設定
  function handleChangeIsDraft(e) {
    formInputs.setters.setIsDraft(e.target.value);
  }

  // 1. 画像の選択

  // 2. 物件情報
  function handleChangePublicSaleType(e) {
    formInputs.setters.setPublicSaleType(e.target.value);
  }

  function handleChangeIsNextBuyApl(e) {
    formInputs.setters.setIsNextBuyApl(e.target.value);
  }

  function handleChangePrintOrder(e) {
    validate.setters.handlePrintOrderValid(e);
    formInputs.setters.setPrintOrder(e.target.value);
  }

  function handleChangeCategoryId(e) {
    validate.setters.handleCategoryIdValid(e);
    formInputs.setters.setCategoryId(e.target.value);
  }

  function handleChangeRecommendProperties(e) {
    formInputs.setters.setRecommendProperties(e);
  }

  function handleChangeDivisionId(e) {
    validate.setters.handleDivisionIdValid(e);
    formInputs.setters.setDivisionId(e.target.value);
  }

  function handleChangeSaleNumber(e) {
    validate.setters.handleSaleNumberValid(e);
    formInputs.setters.setSaleNumber(e.target.value);
  }

  function handleChangeName(e) {
    validate.setters.handleTitleValid(e);
    formInputs.setters.setName(e.target.value);
  }

  function handleChangePaymentMethod(e) {
    validate.setters.handlePaymentMethodValid(e);
    formInputs.setters.setPaymentMethod(e);
  }

  function handleChangePaymentLimit(e) {
    validate.setters.handlePaymentLimitValid(e);
    formInputs.setters.setPaymentLimit(e);
  }

  function handleChangeResolutionDate(e) {
    validate.setters.handleResolutionDateValid(e);
    formInputs.setters.setResolutionDate(e.target.value);
  } // 議決日

  function handleChangeContractedLimit(e) {
    formInputs.setters.setContractedLimit(e);
  } // 契約締結期限

  function handleChangeVideoUrl(e) {
    validate.setters.handleVideoUrlValid(e);
    formInputs.setters.setVideoUrl(e.target.value);
  }

  // 3. 登録事項など証明書の記載内容
  // 証明書発行日
  function handleChangeEffectiveDate(hashDate) {
    validate.setters.handleEffectiveDateValid(hashDate);
    formInputs.setters.setEffectiveDate(hashDate);
  }

  // 自動車登録番号
  function handleChangeRegisterNumber(hashRegisterNumber) {
    validate.setters.handleRegisterNumberValid(hashRegisterNumber);
    formInputs.setters.setRegisterNumber(hashRegisterNumber);
  }

  function handleChangeRegisterNumberFirst(value) {
    validate.setters.handleRegisterNumberFirstValid(value);
    formInputs.setters.setRegisterNumberFirst(value);
  }
  function handleChangeRegisterNumberLatter(value) {
    validate.setters.handleRegisterNumberLatterValid(value);
    formInputs.setters.setRegisterNumberLatter(value);
  }

  // 自動車登録番号（下４桁の表示）
  function handleChangeDisplayRegisterNumber(value) {
    validate.setters.handleDisplayRegisterNumberValid(value);
    formInputs.setters.setDisplayRegisterNumber(value);
  }

  // 登録年月日
  function handleChangeRegisterDate(hashDate) {
    validate.setters.handleRegisterDateValid(hashDate);
    formInputs.setters.setRegisterDate(hashDate);
  }

  // 初度登録年月日
  function handleChangeFirstRegisterDate(e, hashDate) {
    const val = {
      year: e.year,
      month: e.month,
      day: '1',
    };
    validate.setters.handleFirstRegisterDateValid(e, { ...hashDate, ...val });
    formInputs.setters.setFirstRegisterDate({ ...hashDate, ...val });
  }
  function handleChangeType(e) {
    validate.setters.handleCarTypeValid(e);
    formInputs.setters.setType(e.target.value);
  }

  // 自動車の種別
  function handleChangeTypeReset(e) {
    validate.setters.handleCarTypeValid(e);
    formInputs.setters.setType('');
  }

  // 用途
  function handleChangePurpose(e) {
    validate.setters.handlePurposeValid(e);
    formInputs.setters.setPurpose(e.target.value);
  }
  function handleChangePurposeOther(e) {
    validate.setters.handlePurposeOtherValid(e);
    formInputs.setters.setPurposeOther(e.target.value);
  }
  function handleChangePrivateOrBusiness(e) {
    validate.setters.handlePrivateOrBusinessValid(e);
    formInputs.setters.setPrivateOrBusiness(e.target.value);
  }

  // 車体の形状
  function handleChangeShape(e) {
    validate.setters.handleShapeValid(e);
    formInputs.setters.setShape(e.target.value);
  }
  function handleChangeShapeReset(e) {
    validate.setters.handleShapeValid(e);
    formInputs.setters.setShape('');
  }

  // 車名（メーカー）
  function handleChangeManufacture(e) {
    validate.setters.handleManufactureValid(e);
    formInputs.setters.setManufacture(e.target.value);
  }
  function handleChangeManufactureOther(e) {
    validate.setters.handleManufactureOtherValid(e);
    formInputs.setters.setManufactureOther(e.target.value);
  }

  function handleChangeModel(e) {
    validate.setters.handleModelValid(e);
    formInputs.setters.setModel(e.target.value);
  }
  function handleChangeModelReset(e) {
    validate.setters.handleModelValid(e);
    formInputs.setters.setModel('');
  }
  function handleChangeSeatingCapacity(e) {
    validate.setters.handleSeatingCapacityValid(e);
    formInputs.setters.setSeatingCapacity(e.target.value);
  }
  function handleChangeSeatingCapacityReset(e, value) {
    validate.setters.handleSeatingCapacityValid(e, value);
    formInputs.setters.setSeatingCapacity('');
  }
  function handleChangeWeight(e) {
    validate.setters.handleWeightValid(e);
    formInputs.setters.setWeight(e.target.value);
  }
  function handleChangeWeightReset(e, value) {
    validate.setters.handleWeightValid(e, value);
    formInputs.setters.setWeight('');
  }
  function handleChangeTotalWeight(e) {
    validate.setters.handleTotalWeightValid(e);
    formInputs.setters.setTotalWeight(e.target.value);
  }
  function handleChangeTotalWeightReset(e, value) {
    validate.setters.handleTotalWeightValid(e, value);
    formInputs.setters.setTotalWeight('');
  }

  // 車台番号
  function handleChangeProductNumber(hashProductNumber) {
    validate.setters.handleProductNumberValid(hashProductNumber);
    formInputs.setters.setProductNumber(hashProductNumber);
  }
  function handleChangeProductNumberFirst(value) {
    validate.setters.handleProductNumberFirstValid(value);
    formInputs.setters.setProductNumberFirst(value);
  }
  function handleChangeProductNumberLatter(value) {
    validate.setters.handleProductNumberLatterValid(value);
    formInputs.setters.setProductNumberLatter(value);
  }

  function handleChangeDisplayProductNumber(e) {
    validate.setters.handleDisplayProductNumberValid(e);
    formInputs.setters.setDisplayProductNumber(e);
  }

  function handleChangeEngineModel(e) {
    validate.setters.handleEngineModelValid(e);
    formInputs.setters.setEngineModel(e.target.value);
  }
  function handleChangeEngineModelReset(e) {
    validate.setters.handleEngineModelValid(e);
    formInputs.setters.setEngineModel('');
  }
  function handleChangeLength(e) {
    validate.setters.handleLengthValid(e);
    formInputs.setters.setLength(e.target.value);
  }
  function handleChangeLengthReset(e, value) {
    validate.setters.handleLengthValid(e, value);
    formInputs.setters.setLength('');
  }
  function handleChangeWidth(e) {
    validate.setters.handleWidthValid(e);
    formInputs.setters.setWidth(e.target.value);
  }
  function handleChangeWidthReset(e, value) {
    validate.setters.handleWidthValid(e, value);
    formInputs.setters.setWidth('');
  }
  function handleChangeHeight(e) {
    validate.setters.handleHeightValid(e);
    formInputs.setters.setHeight(e.target.value);
  }
  function handleChangeHeightReset(e, value) {
    validate.setters.handleHeightValid(e, value);
    formInputs.setters.setHeight('');
  }
  function handleChangeDisplacement(e) {
    validate.setters.handleDisplacementValid(e);
    formInputs.setters.setDisplacement(e.target.value);
  }
  function handleChangeDisplacementReset(e, value) {
    validate.setters.handleDisplacementValid(e, value);
    formInputs.setters.setDisplacement('');
  }
  function handleChangeFuelType(e) {
    validate.setters.handleFuelTypeValid(e);
    formInputs.setters.setFuelType(e.target.value);
  }
  function handleChangeFuelTypeReset(e) {
    validate.setters.handleFuelTypeValid(e);
    formInputs.setters.setFuelType('');
  }

  // 前前軸重
  function handleChangeFrontAxelWeight(e) {
    validate.setters.handleFrontAxelWeightValid(e);
    formInputs.setters.setFrontAxelWeight(e.target.value);
  }
  function handleChangeFrontAxelWeightReset(e, value) {
    validate.setters.handleFrontAxelWeightValid(e, value);
    formInputs.setters.setFrontAxelWeight('');
  }
  function handleChangeRearAxelWeight(e) {
    validate.setters.handleRearAxelWeightValid(e);
    formInputs.setters.setRearAxelWeight(e.target.value);
  }
  function handleChangeRearAxelWeightReset(e, value) {
    validate.setters.handleRearAxelWeightValid(e, value);
    formInputs.setters.setRearAxelWeight('');
  }

  // 自動車検査証有効期限・日付
  function handleChangeSafetyTerm(e) {
    validate.setters.handleSafetyTermValid(e);
    formInputs.setters.setSafetyTerm(e.target.value);
  }
  // 自動車検査証有効期限・ラジオボタン
  function handleChangeStatusSafetyTerm(value) {
    validate.setters.handleStatusSafetyTermValid(value);
    formInputs.setters.setStatusSafetyTerm(value);
  }

  function handleChangeFormalNumber(e) {
    validate.setters.handleFormalNumberValid(e);
    formInputs.setters.setFormalNumber(e.target.value);
  }
  function handleChangeFormalNumberReset(e) {
    validate.setters.handleFormalNumberValid(e);
    formInputs.setters.setFormalNumber('');
  }
  function handleChangeClassificationNumber(e) {
    validate.setters.handleClassificationNumberValid(e);
    formInputs.setters.setClassificationNumber(e.target.value);
  }
  function handleChangeClassificationNumberReset(e) {
    validate.setters.handleClassificationNumberValid(e);
    formInputs.setters.setClassificationNumber('');
  }

  // 4. 基本情報(e.target.value);
  function handleChangeTransmissionType(e) {
    validate.setters.handleTransmissionTypeValid(e);
    formInputs.setters.setTransmissionType(e.target.value);
  }
  function handleChangeHandleType(e) {
    validate.setters.handleHandleTypeValid(e);
    formInputs.setters.setHandleType(e.target.value);
  }
  function handleChangeExistsCarMaintenanceSheet(e) {
    validate.setters.handleExistsCarMaintenanceSheetValid(e);
    formInputs.setters.setExistsCarMaintenanceSheet(e.target.value);
  }
  function handleChangeCarBodyColor(e) {
    validate.setters.handleCarBodyColorValid(e);
    formInputs.setters.setCarBodyColor(e.target.value);
  }

  // 走行距離
  function handleChangeCarMileage(e) {
    validate.setters.handleCarMileageValid(e);
    formInputs.setters.setCarMileage(e.target.value);
  }
  function handleChangeStatusCarMileage(e, value) {
    validate.setters.handleCarMileageValid(e, value);
    formInputs.setters.setStatusCarMileage('');
  }

  function handleChangeInventoryLocation(e) {
    validate.setters.handleInventoryLocationValid(e);
    formInputs.setters.setInventoryLocation(e.target.value);
  }
  function handleChangeMapUrl(e) {
    validate.setters.handleMapUrlValid(e);
    formInputs.setters.setMapUrl(e.target.value);
  }
  function handleChangeCarStatus(e) {
    validate.setters.handleCarStatusValid(e);
    formInputs.setters.setCarStatus(e.target.value);
  }
  function handleChangeDeliveryTerms(e) {
    validate.setters.handleDeliveryTermsValid(e);
    formInputs.setters.setDeliveryTerms(e.target.value);
  }
  function handleChangeOther(e) {
    validate.setters.handleOtherValid(e);
    formInputs.setters.setOther(e.target.value);
  }
  function handleChangeDescription({ text }) {
    validate.setters.handleDescriptionValid(text);
    formInputs.setters.setDescription(text);
  }

  // 5. 物件に関する音尾合わせ先
  function handleChangeInquiryName(e) {
    validate.setters.handleInquiryNameValid(e);
    formInputs.setters.setInquiryName(e.target.value);
  }

  function handleChangeInquiryOffice(e) {
    validate.setters.handleInquiryOfficeValid(e);
    formInputs.setters.setInquiryOffice(e.target.value);
  }

  function handleChangeInquiryEmail(e) {
    validate.setters.handleInquiryEmailValid(e);
    formInputs.setters.setInquiryEmail(e.target.value);
  }

  function handleChangeInquiryTel(telData) {
    validate.setters.handleInquiryTelValid(telData);
    formInputs.setters.setInquiryTel(telData);
  }
  function handleChangeInquiryTelNum1(e) {
    validate.setters.handleInquiryTelNum1Valid(e);
    formInputs.setters.setInquiryTelNum1(e);
  }
  function handleChangeInquiryTelNum2(e) {
    validate.setters.handleInquiryTelNum2Valid(e);
    formInputs.setters.setInquiryTelNum2(e);
  }
  function handleChangeInquiryTelNum3(e) {
    validate.setters.handleInquiryTelNum3Valid(e);
    formInputs.setters.setInquiryTelNum3(e);
  }

  function handleChangeInquiryExtension(e) {
    validate.setters.handleInquiryExtensionValid(e);
    formInputs.setters.setInquiryExtension(e.target.value);
  }

  function handleChangeInquiryAccept(e) {
    validate.setters.handleInquiryAcceptValid(e);
    formInputs.setters.setInquiryAccept(e.target.value);
  }

  function handleChangeInquiryNote(e) {
    validate.setters.handleInquiryNoteValid(e);
    formInputs.setters.setInquiryNote(e.target.value);
  }

  // 6. 物件の詳細設定
  function handleChangeDepositPayment(e) {
    formInputs.setters.setDepositPayment(e.target.value);
  }

  function handleChangeIsApprovalRequired(e) {
    formInputs.setters.setIsApprovalRequired(e.target.value);
  }

  function handleChangeEstimatePrice(e) {
    validate.setters.handleEstimatePriceValid(e);
    formInputs.setters.setEstimatePrice(e.target.value);
  }

  function handleChangeDeposit(value, depositPayment) {
    validate.setters.handleDepositValid(value, depositPayment);
    formInputs.setters.setDeposit(value);
  }

  function handleChangeQualifiedInvoice(e) {
    formInputs.setters.setQualifiedInvoice(e.target.value);
  }

  // 7. 落札後の連絡先
  function handleChangeContactName(e) {
    validate.setters.handleContactNameValid(e);
    formInputs.setters.setContactName(e.target.value);
  }

  function handleChangeContactOffice(e) {
    validate.setters.handleContactOfficeValid(e);
    formInputs.setters.setContactOffice(e.target.value);
  }

  function handleChangeContactEmail(e) {
    validate.setters.handleContactEmailValid(e);
    formInputs.setters.setContactEmail(e.target.value);
  }

  function handleChangeContactTel(telData) {
    validate.setters.handleContactTelValid(telData);
    formInputs.setters.setContactTel(telData);
  }
  function handleChangeContactTelNum1(e) {
    validate.setters.handleContactTelNum1Valid(e);
    formInputs.setters.setContactTelNum1(e);
  }
  function handleChangeContactTelNum2(e) {
    validate.setters.handleContactTelNum2Valid(e);
    formInputs.setters.setContactTelNum2(e);
  }
  function handleChangeContactTelNum3(e) {
    validate.setters.handleContactTelNum3Valid(e);
    formInputs.setters.setContactTelNum3(e);
  }

  function handleChangeContactExtension(e) {
    validate.setters.handleContactExtensionValid(e);
    formInputs.setters.setContactExtension(e.target.value);
  }

  function handleChangeContactAccept(e) {
    validate.setters.handleContactAcceptValid(e);
    formInputs.setters.setContactAccept(e.target.value);
  }

  //function handleChangeAuctionUserId(e) {
  //    validate.setters.handleAuctionUserIdValid(e)
  //    formInputs.setters.setAuctionUserId(e.target.value)
  //}

  //function handleChangePaymentDate(e) {
  //    formInputs.setters.setPaymentDate(e.target.value)
  //}

  //function handleChangePaymentDeadline(e) {
  //    validate.setters.handlePaymentDeadlineValid(e)
  //    formInputs.setters.setPaymentDeadline(e.target.value)
  //}

  const validateInputs = {
    data: formInputs.data,
    init: formInputs.setters,
    setters: {
      handleChangeIsDraft,

      handleChangePublicSaleType,
      handleChangeIsNextBuyApl,
      handleChangePrintOrder,
      handleChangeCategoryId,
      handleChangeRecommendProperties,
      handleChangeDivisionId,
      handleChangeSaleNumber,
      handleChangeName,
      handleChangePaymentMethod,
      handleChangePaymentLimit,
      handleChangeResolutionDate,
      handleChangeContractedLimit,
      handleChangeVideoUrl,

      handleChangeEffectiveDate,
      handleChangeRegisterNumber,
      handleChangeRegisterNumberFirst,
      handleChangeRegisterNumberLatter,
      handleChangeDisplayRegisterNumber,
      handleChangeRegisterDate,
      handleChangeFirstRegisterDate,
      handleChangeType,
      handleChangeTypeReset,
      handleChangePurpose,
      handleChangePurposeOther,
      handleChangePrivateOrBusiness,
      handleChangeShape,
      handleChangeShapeReset,
      handleChangeManufacture,
      handleChangeManufactureOther,
      handleChangeModel,
      handleChangeModelReset,
      handleChangeSeatingCapacity,
      handleChangeSeatingCapacityReset,
      handleChangeWeight,
      handleChangeWeightReset,
      handleChangeTotalWeight,
      handleChangeTotalWeightReset,
      handleChangeProductNumber,
      handleChangeProductNumberFirst,
      handleChangeProductNumberLatter,
      handleChangeDisplayProductNumber,
      handleChangeEngineModel,
      handleChangeEngineModelReset,
      handleChangeLength,
      handleChangeLengthReset,
      handleChangeWidth,
      handleChangeWidthReset,
      handleChangeHeight,
      handleChangeHeightReset,
      handleChangeDisplacement,
      handleChangeDisplacementReset,
      handleChangeFuelType,
      handleChangeFuelTypeReset,
      handleChangeFrontAxelWeight,
      handleChangeFrontAxelWeightReset,
      handleChangeRearAxelWeight,
      handleChangeRearAxelWeightReset,
      handleChangeSafetyTerm,
      handleChangeStatusSafetyTerm,
      handleChangeFormalNumber,
      handleChangeFormalNumberReset,
      handleChangeClassificationNumber,
      handleChangeClassificationNumberReset,

      handleChangeTransmissionType,
      handleChangeHandleType,
      handleChangeExistsCarMaintenanceSheet,
      handleChangeCarBodyColor,
      handleChangeCarMileage,
      handleChangeStatusCarMileage,
      handleChangeInventoryLocation,
      handleChangeMapUrl,
      handleChangeCarStatus,
      handleChangeDeliveryTerms,
      handleChangeOther,
      handleChangeDescription,

      handleChangeInquiryName,
      handleChangeInquiryOffice,
      handleChangeInquiryEmail,
      handleChangeInquiryTel,
      handleChangeInquiryTelNum1,
      handleChangeInquiryTelNum2,
      handleChangeInquiryTelNum3,
      handleChangeInquiryExtension,
      handleChangeInquiryAccept,
      handleChangeInquiryNote,

      handleChangeDepositPayment,
      handleChangeIsApprovalRequired,
      handleChangeEstimatePrice,
      handleChangeDeposit,
      handleChangeQualifiedInvoice,

      handleChangeContactName,
      handleChangeContactOffice,
      handleChangeContactEmail,
      handleChangeContactTel,
      handleChangeContactTelNum1,
      handleChangeContactTelNum2,
      handleChangeContactTelNum3,
      handleChangeContactExtension,
      handleChangeContactAccept,

      //handleChangeAuctionUserId,
      //handleChangePaymentDeadline,
      //handleChangePaymentDate,
      //handleChangePaymentScheduledDate,
    },
    validationMessage: validate.validationMessage,
  };

  return validateInputs;
}
