// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kmanager-accordion-topic {
  margin-left: 6px;
}

.active-menu-item {
  background-color: #f0f0f0; /* Change to your desired color */
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/accordion-topic.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB,EAAE,iCAAiC;AAC9D","sourcesContent":[".kmanager-accordion-topic {\n  margin-left: 6px;\n}\n\n.active-menu-item {\n  background-color: #f0f0f0; /* Change to your desired color */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
