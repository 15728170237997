
export class SystemUsageTaxBid {
  #systemUsageTaxBid

  constructor(systemUsageTaxBid) {
    this.#systemUsageTaxBid = systemUsageTaxBid;
  }

  get() {
    return this.#systemUsageTaxBid
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
