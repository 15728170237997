import PublishedObjectProvider from './PublishedObjectProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { publishedObject } from "../entities/componentMapping.js";

const PublishedObject = ({ type }) => {
  return (
    <PublishedObjectProvider type={type}>
      {getComponent(publishedObject, type)}
    </PublishedObjectProvider>
  );
};

export default PublishedObject;
