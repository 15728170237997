import Modal2 from '../../../interfaces/components/common/Modal/Modal2.jsx';
import useModalControl from '../../../shared/hooks/useModalControl';
import { useCloseClickOutside } from 'shared/hooks/useCloseClickOutside';

const DisplaySample = ({ triggerText, imagePath }) => {
  const { isOpen, handleOpen, handleClose } = useModalControl();
  const { ref } = useCloseClickOutside(handleClose);

  return (
    <>
      <span
        style={{
          color: '#0080FF',
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
        onClick={handleOpen}
        data-testid="display-sample-modal-trigger">
        {triggerText}
      </span>

      <Modal2 isOpen={isOpen} handleClose={handleClose} modalSize="M">
        <div
          ref={ref}
          style={{
            padding: '12px',
            textAlign: 'right',
            pointerEvents: 'auto',
          }}>
          <img
            style={{
              width: '100%',
              verticalAlign: 'bottom',
              paddingBottom: '20px',
            }}
            src={imagePath}
            alt=""
            data-testid="sample-image"
          />
          <span
            onClick={handleClose}
            className="common-button-bg-whitesmoke"
            style={{
              padding: '15px',
              cursor: 'pointer',
              textDecoration: 'none',
              borderRadius: '12px',
              boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
            }}>
            閉じる
          </span>
        </div>
      </Modal2>
    </>
  );
};
export default DisplaySample;
