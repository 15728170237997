// ロジックを含まない処理の実装

import { fetchPaymentMethodsStorage } from "../../../infrastructure/api/storage/payment_methods.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import {PaymentMethodGet} from "../entities/PaymentMethod";

// getメソッドの実装
export async function getPaymentMethods(type) {
  // SAMPLE
  let response = [];
  if (type === SERVICE) {
    response = await fetchPaymentMethodsStorage()
  }
  if (type === NAVI) {
    response = await fetchPaymentMethodsStorage()
  }
  if (type === KMANAGER) {
    response = await fetchPaymentMethodsStorage()
  }

  if (response instanceof Error) {
    throw new CustomError('Error get CarProperty', response);
  }

  let results = [];
  response.map((data) => {
    const result = new PaymentMethodGet(data);
    results.push(result)
  })

  return results
}
