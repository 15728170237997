import { useContext } from 'react';

import { GovernmentPaymentContext } from '../../../../pages/GovernmentPayment/entities/GovernmentPaymentContext';
import 'interfaces/css/common/table-default.css';
import { FeeTypes } from 'shared/utils/constants/government';

export const taxHeader = ['消費税計算', '種別', '落札システム利用料'];

const TAX_TYPE_TO_JP = {
  TOTAL_TAXATION: '合算一括課税',
  INDIVIDUAL_TAXATION: '個別課税',
  INDIVIDUAL_CONTRACT_TAXATION: '個別契約課税',
};

const TaxRuleTable = () => {
  const { government } = useContext(GovernmentPaymentContext);

  return (
    <table data-testid="kmanager-tax-rule-table" className="kmanager-table">
      <thead>
        <tr
          data-testid="kmanager-tax-rule-table-tr"
          className="kmanager-table-tr">
          {taxHeader.map((headline, index) => (
            <th
              key={index}
              data-testid="kmanager-tax-rule-table-th"
              className="kmanager-table-th text-left text-no-wrap">
              {headline}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr
          data-testid="kmanager-tax-rule-table-tr"
          className="kmanager-table-tr">
          <td
            data-testid="kmanager-tax-rule-table-td"
            className="kmanager-table-td text-no-wrap"
            rowSpan={3}>
            {TAX_TYPE_TO_JP[government.taxType]}
          </td>
          <td
            data-testid="kmanager-tax-rule-table-td"
            className="kmanager-table-td text-no-wrap">
            {FeeTypes[government?.movableBidFeeType]?.jp}
          </td>
          <td
            data-testid="kmanager-tax-rule-table-td"
            className="kmanager-table-td text-no-wrap text-right">
            落札合計の{(parseFloat(government.movableBidFee) * 100).toFixed(0) + '%'}
          </td>
        </tr>
        <tr
          data-testid="kmanager-tax-rule-table-tr"
          className="kmanager-table-tr">
          <td
            data-testid="kmanager-tax-rule-table-td"
            className="kmanager-table-td text-no-wrap">
            {FeeTypes[government?.carBidFeeType]?.jp}
          </td>
          <td
            data-testid="kmanager-tax-rule-table-td"
            className="kmanager-table-td text-no-wrap text-right">
            落札合計の{(parseFloat(government.carBidFee) * 100).toFixed(0) + '%'}
          </td>
        </tr>
        <tr
          data-testid="kmanager-tax-rule-table-tr"
          className="kmanager-table-tr">
          <td
            data-testid="kmanager-tax-rule-table-td"
            className="kmanager-table-td text-no-wrap">
            {FeeTypes[government?.estateBidFeeType]?.jp}
          </td>
          <td
            data-testid="kmanager-tax-rule-table-td"
            className="kmanager-table-td text-no-wrap text-right">
            落札合計の{(parseFloat(government.estateBidFee) * 100).toFixed(0) + '%'}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default TaxRuleTable;
