export const procedure_new_entry_labels_pub = [
    "参加公売\n" + "（参加申込開始日)", "公売保証金の運用"
]
export const procedure_new_entry_labels_asset = [
    "参加公有財産売却\n" + "（参加申込開始日)", "入札保証金の運用"
]
export const property_info_form_pub = [
    "見積価額","公売保証金"
]
export const property_info_form_asset = [
    "予定価格","入札保証金"
]
