import { findById as getGovernmentUser } from "domain/User/services/GovernmentUser";

import { useState, useEffect } from 'react';
import { useLocalStorage } from "shared/hooks/useLocalStorage";
import { NAVI } from "shared/utils/helper/constants";

export const useGovernmentUser = () => {
    const [governmentUser, setGovernmentUser] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const { localStorageUserId } = useLocalStorage();

    useEffect(() => {
        setLoading(true);
        try {
            getGovernmentUser(NAVI, localStorageUserId).then(user => {
                setGovernmentUser(user);
            });
        } finally {
            setLoading(false);
        }
    }, [localStorageUserId]);

    return { governmentUser, loading };
};
