// データ取得後の整形処理
// フロントから呼ぶ関数
import {
  createGovernmentUsersData,
  updateGovernmentUsersData,
  deleteGovernmentUsersData,
} from '../../../infrastructure/api/GovernmentUsers.js';
import {
  createNaviGovernmentUsersData,
  deleteNaviGovernmentUsersData,
  updateNaviGovernmentUsersData,
} from '../../../infrastructure/api/NaviGovernmentUsers.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import { bodyToSnake, getValues } from 'shared/utils/helper/convertData.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { GovernmentUserCreate } from '../entities/GovernmentUser.js';
import { getGovernmentUsers } from '../repositories/GovernmentUser.js';

export async function get(type, governmentId) {
  return await getGovernmentUsers(type, governmentId);
}

export async function findById(type, id) {
  const governmentUsers = await getGovernmentUsers(type);
  return governmentUsers.find((user) => user.id === id);
}

export async function findByGovernmentId(type, id) {
  const governmentUsers = await getGovernmentUsers(type);
  return governmentUsers.filter(
    (user) => user.governmentId.toString() === id.toString(),
  );
}

export async function create(type, data) {
  let response;
  if (type === SERVICE) {
    return [];
  }
  if (type === NAVI) {
    const user = new GovernmentUserCreate(data);
    response = await createNaviGovernmentUsersData(bodyToSnake(getValues(user)));
  }
  if (type === KMANAGER) {
    const user = new GovernmentUserCreate(data);
    response = await createGovernmentUsersData(bodyToSnake(user));
  }
  if (response instanceof Error) {
    throw new CustomError('Error create GovernmentUser', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    return [];
  }
  if (type === NAVI) {
    const user = new GovernmentUserCreate(data);
    response = await updateNaviGovernmentUsersData(bodyToSnake(user), user.id);
  }
  if (type === KMANAGER) {
    const user = new GovernmentUserCreate(data);
    response = await updateGovernmentUsersData(bodyToSnake(user), user.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error create GovernmentUser', response);
  }
  return response;
}

export function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    return [];
  }
  if (type === NAVI) {
    response = deleteNaviGovernmentUsersData(data.id);
  }
  if (type === KMANAGER) {
    response = deleteGovernmentUsersData(data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error create GovernmentUser', response);
  }
  return response;
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
