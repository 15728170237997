import axios from 'axios';

import {API_BASE_URL} from "../../config/envConstants.js";

export const fetchManagementUsersData = async () => {
  return await axios.get(
    `${API_BASE_URL}/api/v1/management/management-users`,
  );
};


export const createManagementUsersData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/management-users`,
    body, {headers: headers}
  );

};
export const updateManagementUsersData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/management-users/${id}`,
    body, {headers: headers}
  );
};
export const deleteManagementUsersData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/management/management-users/${id}`, {
      headers: headers,
    },);
};