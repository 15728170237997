import Breadcrumb from 'interfaces/components/common/Breadcrumb.jsx';
import LocalGovernmentManagementTable from 'interfaces/components/LocalGovernmentManagement/LocalGovernmentManagementTable';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import { breadcrumbs } from 'interfaces/pages/LocalGovernmentManagement/entities/breadcrumbs';
import 'interfaces/css/LocalGovernmentManagement/local-government-management.css';
import { useContext } from 'react';

import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import { LocalGovernmentManagementContext } from '../entities/LocalGovernmentManagementContext';

let tableData = [
  {
    id: 1,
    pageName: '行政機関トップページ',
    listingDetails: '下見会、問い合わせ先、貴庁HP情報などの設定',
    isRegistered: false,
    link: 'top',
  },
  {
    id: 2,
    pageName: 'クレジットカード仮申し込み完了画面',
    listingDetails: '保証金をクレジットカード納付した参加者への手続案内を設定',
    isRegistered: false,
    link: 'credit',
  },
  {
    id: 3,
    pageName: '銀行振込など仮申し込み完了画面',
    listingDetails: '保証金を銀行振込で納付した参加者への手続案内を設定',
    isRegistered: false,
    link: 'bank-transfer',
  },
  {
    id: 4,
    pageName: 'ガイドライン',
    listingDetails: '参加申込時に提示するガイドラインの設定',
    isRegistered: false,
    link: 'guideline',
  },
  {
    id: 5,
    pageName: '落札後の注意事項',
    listingDetails: '落札後に必要な費用、書類、注意事項など、表示項目の設定',
    isRegistered: false,
    link: 'after-winning-public',
  },
  {
    id: 6,
    pageName: '書類送付先',
    listingDetails: 'システム利用料請求書や認証書面送付先の設定',
    isRegistered: false,
    link: 'send-documents-to',
  },
  {
    id: 7,
    pageName: '公章・キャラクター画像',
    listingDetails: '公章・キャラクター画像のアップロード設定',
    isRegistered: false,
    link: 'emblem',
  },
];

const NaviLocalGovernmentManagement = () => {
  const {
    government,
    governmentTopPages,
    creditCardPages,
    bankTransferPages,
    guidelines,
    documentSendPages,
    assetSaleCautionPage,
    publicSaleCautionPages,
  } = useContext(LocalGovernmentManagementContext);
  const { localStorageProcedureType, localStorageGovernmentType } =
    useLocalStorage();

  if (governmentTopPages?.length !== 0) {
    tableData[0].isRegistered = true;
  }
  if (creditCardPages?.length !== 0) {
    tableData[1].isRegistered = true;
  }
  if (bankTransferPages?.length !== 0) {
    tableData[2].isRegistered = true;
  }
  if (guidelines?.length !== 0) {
    tableData[3].isRegistered = true;
  }

  if (localStorageProcedureType === 'ASSET_SALE') {
    if (assetSaleCautionPage?.length !== 0) {
      tableData[4].isRegistered = true;
    }
  } else {
    if (publicSaleCautionPages?.length !== 0) {
      tableData[4].isRegistered = true;
    }
  }
  if (documentSendPages?.length !== 0) {
    tableData[5].isRegistered = true;
  }
  if (
    government?.isEmblemExist !== undefined &&
    government?.isCharaExist !== undefined
  ) {
    tableData[6].isRegistered = true;
  }

  // governmentTypeがNATIONAL_TAX_AGENCYの場合、書類送付先のデータを除外
  if (localStorageGovernmentType == 'NATIONAL_TAX_AGENCY') {
    tableData = tableData.filter((item) => item.id !== 6);
  }

  return (
    <div className="local-government-management new-style_navi_local-government-management">
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <PropertyInfoGeneralPageDescription
        title={'行政機関ページ管理'}
        content={
          localStorageGovernmentType !== 'NATIONAL_TAX_AGENCY'
            ? '行政機関で設定するページの一覧を表示しています。\n\
            KSI官公庁オークションでは書類送付先と公章・キャラクター画像を除く各種登録画面ではせり売形式と入札形式とでそれぞれ情報を登録することが可能です。プレビューボタンも必要に応じてご利用ください。\n\
            ページの編集をする場合は各ページの「編集」ボタンをクリックしてください。また編集作業は参加申込受付〜掲載終了日まで行えないため注意してください。'
            : '国税局等で設定するページの一覧を表示しています。\n\
            ページの登録・編集をする場合は各ページの「編集」ボタンをクリックしてください。プレビューボタンも必要に応じてご利用ください。なお、参加申込受付から掲載終了日までは、編集ページの入力内容の確認および修正ができないのでご注意ください。\n\
            ※プレビューページはご確認いただけます'
        }
      />
      <LocalGovernmentManagementTable
        tableData={tableData}
        governmentType={localStorageGovernmentType}
        procedureType={localStorageProcedureType}
      />
    </div>
  );
};

export default NaviLocalGovernmentManagement;
