import styled from 'styled-components';

const Card = styled.div`
  background-color: var(--white);
  border: 1px solid var(--secondary-base);
  border-radius: 5px;
  margin-bottom: 15px;
`;

const CardTitle = styled.div`
  padding: 0 12px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 2.25rem;
  letter-spacing: 0.09em;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  background-color: var(--secondary-base);
`;

const CardBody = styled.div`
  padding: 8px;
`;

const TopicCard = (props) => {
  return (
    <Card data-testid="topic-card">
      <CardTitle data-testid="topic-card-title">{props.title}</CardTitle>
      <CardBody>{props.body}</CardBody>
    </Card>
  );
};

export default TopicCard;
