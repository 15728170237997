import { useEffect, useState } from 'react';

import { get as getServiceProvider } from '../../../../domain/List/services/ServiceProvider';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { KMANAGER } from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { ServiceProviderContext } from '../entities/ServiceProviderContext.js';

const ServiceProviderProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [serviceProviders, setServiceProviders] = useState([]);

  if (type === KMANAGER) {
    useEffect(() => {
      getServiceProvider(type).then((result) => {
        setServiceProviders(result);
      });
    }, []);
  }

  return (
    <ServiceProviderContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        serviceProviders,
      }}>
      {children}
    </ServiceProviderContext.Provider>
  );
};

export default ServiceProviderProvider;
