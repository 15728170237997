import { ReactComponent as CheckCircleIcon } from 'interfaces/assets/svg/check_circle_icon.svg';

const Step = ({ currentStepNum, stepData, activeStep, stepLength }) => {
  const isInactive = currentStepNum > activeStep;
  const isPreviousStep = currentStepNum < activeStep;

  return (
    <>
      <div
        data-testid="step"
        className={`stepper-step ${isInactive ? 'stepper-step-inactive' : 'stepper-step-active'}`}>
        {isPreviousStep ? (
          <CheckCircleIcon title="check-circle" />
        ) : (
          <span
            className="stepper-step-step primary"
            data-testid="stepper-step-step">
            {currentStepNum}
          </span>
        )}
        <div className="stepper-label" data-testid="stepper-label">
          {stepData.label}
        </div>
      </div>
      {currentStepNum !== stepLength && (
        <hr
          role="separator"
          aria-orientation="horizontal"
          className="divider theme-light"
        />
      )}
    </>
  );
};

export default Step;
