
export class TmpAplDate {
  #tmpAplDate

  constructor(tmpAplDate) {
    this.#tmpAplDate = tmpAplDate;
  }

  get() {
    return this.#tmpAplDate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
