import {DATA_EMPTY} from "shared/utils/helper/constants";

const displayConvert = {
    DISPLAY: "表示する",
    HIDDEN: "表示しない"
}

export function DisplayToJP(display) {
    return displayConvert[display] ?? "NO_DATA"
}

const mailReductionConvert = {
    NO_MAIL: "オンライン申請のみ",
    MAIL: "押印して郵送する"
}
const mailConvert = {
    NO_MAIL: "希望しない",
    MAIL: "希望する"
}

export function MailToReductionJP(mail) {
    return mailReductionConvert[mail] ?? "NO_DATA"
}
export function MailToJP(mail) {
    return mailConvert[mail] ?? "NO_DATA"
}
const bidEndTypeConvert = {
    AUTO: "行う",
    MANUAL: "行わない"
}

export function BidEndTypeToJp(bidEndType) {
    return bidEndTypeConvert[bidEndType] ?? "NO_DATA"
}

export function DataEmptyConvert(value){
    return value === DATA_EMPTY ? "" : value
}