export const progressScheduleSections = [
  {
    title: 'インターネット公売（国税）',
    titleColor: 'var(--accent-base2)',
    className: 'national-tax-auction-schedule'
  },
  {
    title: '公有財産売却',
    titleColor: 'var(--accent-base3)',
    className: 'asset-sale-schedule'
  },
  {
    title: 'インターネット公売',
    titleColor: 'var(--accent-base4)',
    className: 'auction-schedule'
  }
];