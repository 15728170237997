export const publicSaleSteps = [
  { label: 'ガイドライン同意' },
  { label: '申し込み情報の入力' },
  { label: '情報の確認' },
  { label: '受付完了' },
];

export const publicSaleStepsWithCard = [
  { label: 'ガイドライン同意' },
  { label: '申し込み情報の入力' },
  {
    label: (
      <p>
        情報の確認
        <br />
        <small>カード情報の入力</small>
      </p>
    ),
  },
  { label: '受付完了' },
];

// 国税でasset_saleは存在しないため不要の認識
export const assetSaleSteps = [
  {
    label: (
      <p>
        誓約書および
        <br />
        ガイドライン同意
      </p>
    ),
  },
  { label: '申し込み情報の入力' },
  { label: '情報の確認' },
  { label: '受付完了' },
];
