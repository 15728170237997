import 'interfaces/css/common/kmanager-table-record.css'

const KmanagerTableEmptyRecord = () => {

  return (
    <tr className="kmanager-table-empty-record" data-testid="kmanager-table-empty-record">
      <td colSpan={100} data-testid="kmanager-table-td">
        <div>No data available</div>
      </td>
    </tr>
  )
}

export default KmanagerTableEmptyRecord;
