
export class IndividualEnterprise {
    #individualEnterprise

    constructor(individualEnterprise) {
        this.#individualEnterprise = individualEnterprise;
    }

    get() {
        return this.#individualEnterprise
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
