
export class CarDescription {
    #carDescription

    constructor(carDescription) {
        this.#carDescription = carDescription;
    }

    get() {
        return this.#carDescription
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
