import GovernmentDepositProvider from './GovernmentDepositProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { governmentDeposit } from "../entities/componentMapping.js";

const GovernmentDeposit = ({ type }) => {
  return (
    <GovernmentDepositProvider type={type}>
      {getComponent(governmentDeposit, type)}
    </GovernmentDepositProvider>
  );
};

export default GovernmentDeposit;
