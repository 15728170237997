import SmallTopicTitle from '../../../common/Heading/SmallTopicTitle.jsx';
import ManualMarkedList from '../../ManualMarkedList.jsx';

const data = [
  {
    className: 'pt-2',
    cautions : [
      {
        mark: '・',
        text: '必要な書類の一部は●●●ホームページからダウンロードできます。',
        isImportant: true,
      },
    ],
  },
  {
    className: 'pt-3',
    cautions : [
      {
        mark: '・',
        text: '所有権移転登記請求書',
      },
      {
        text: '執行機関の代金納付確認後、執行機関が所有権移転登記をするために必要な書類です。',
      },
    ],
  },
  {
    className: 'pt-3',
    cautions : [
      {
        mark: '・',
        text: '登録免許税領収証書',
      },
      {
        text: '税務署で登録免許税の納付後に交付される領収証書※書留による収入印紙の送付に代えることができます。',
      },
    ],
  },
  {
    className: 'pt-3',
    cautions : [
      {
        mark: '・',
        text: '身分証明書（落札者が個人の場合のみ）',
      },
      {
        text: '以下の事項を証明する市町村が発行した書面',
      },
      {
        mark: '・',
        text: '禁治産または準禁治産の宣告の通知を受けていないこと',
      },
      {
        mark: '・',
        text: '後見の登記の通知を受けていないこと',
      },
      {
        mark: '・',
        text: '破産宣告の通知を受けていないこと'
      },
    ],
  },
  {
    className: 'pt-3',
    cautions : [
      {
        mark: '・',
        text: '執行機関から落札者へ送信したメールをプリントアウトしたもの',
      },
    ],
  },
]

const RequiredDocumentsAssetSale = () => {
  return (
    <div data-testid="required-documents" className="pt-9">
      <SmallTopicTitle text='必要な書類' isFontBold={true}/>
      {
        data.map((item, index) =>
          <ManualMarkedList data={item.cautions} className={item.className} key={index}/>
        )
      }
    </div>
  );
};
export default RequiredDocumentsAssetSale;