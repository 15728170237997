import styled from 'styled-components';

import CommonInput from 'interfaces/components/common/Input/CommonInput';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel';

const Wrapper = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 1.5%;
  }
`;

const CreditCardNumInputs = ({ creditCardInfo, handleChange }) => {
  return (
    <tr>
      <th>
        カード番号
        <RequiredLabel />
      </th>
      <td className="w-expand">
        <Wrapper>
          <CommonInput
            placeholder="（例）1234"
            value={creditCardInfo.cardNum1}
            handleChange={(e) => handleChange({ cardNum1: e.target.value })}
          />
          <CommonInput
            placeholder="5678"
            value={creditCardInfo.cardNum2}
            handleChange={(e) => handleChange({ cardNum2: e.target.value })}
          />
          <CommonInput
            placeholder="9012"
            value={creditCardInfo.cardNum3}
            handleChange={(e) => handleChange({ cardNum3: e.target.value })}
          />
          <CommonInput
            placeholder="3456"
            value={creditCardInfo.cardNum4}
            handleChange={(e) => handleChange({ cardNum4: e.target.value })}
          />
        </Wrapper>
      </td>
    </tr>
  );
};

export default CreditCardNumInputs;
