import LocalGovernmentManagementProvider from './LocalGovernmentManagementProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { localGovernmentManagement } from "../entities/componentMapping.js";

const LocalGovernmentManagement = ({ type }) => {
  return (
    <LocalGovernmentManagementProvider type={type}>
      {getComponent(localGovernmentManagement, type)}
    </LocalGovernmentManagementProvider>
  );
};

export default LocalGovernmentManagement;
