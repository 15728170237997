import { useEffect, useState } from 'react';

import { get as getCategory } from '../../../../domain/Category/services/PropertyCategory.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { CategoryContext } from '../entities/CategoryContext.js';

const CategoryProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [propertyCategories, setPropertyCategories] = useState({});
  const [loading, setLoading] = useState(true);

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getCategory(type).then((result) => {
        setPropertyCategories(result);
      });
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      getCategory(type).then((result) => {
        setPropertyCategories(result);
      });
    }, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      setLoading(true);
      getCategory(type)
        .then((resultCategories) => {
          let parentCategories = resultCategories.filter(
            (category) => category.depth.toString() === '1',
          );
          let categories = {};
          parentCategories.forEach((parent) => {
            let parentCategory = {};
            parentCategory.id = parent.id;
            parentCategory.name = parent.name;
            parentCategory.displayService = parent.displayService;
            parentCategory.displayProperty = parent.displayProperty;
            parentCategory.displayEstate = parent.displayEstate;
            parentCategory.displayCar = parent.displayCar;

            let childCategories = resultCategories.filter(
              (category) =>
                category.depth.toString() === '2' &&
                category.parentId.toString() === parent.id.toString(),
            );
            let tmpChildCategories = {};
            childCategories.forEach((child) => {
              let childCategory = {};
              childCategory.id = child.id;
              childCategory.name = child.name;
              childCategory.displayService = child.displayService;
              childCategory.displayProperty = child.displayProperty;
              childCategory.displayEstate = child.displayEstate;
              childCategory.displayCar = child.displayCar;

              let grandchildCategories = resultCategories.filter(
                (category) =>
                  category.depth.toString() === '3' &&
                  category.parentId.toString() === child.id.toString(),
              );
              let tmpGrandchildCategories = {};
              grandchildCategories.forEach((grandchild) => {
                let grandchildCategory = {};
                grandchildCategory.id = grandchild.id;
                grandchildCategory.name = grandchild.name;
                grandchildCategory.displayService = grandchild.displayService;
                grandchildCategory.displayProperty = grandchild.displayProperty;
                grandchildCategory.displayEstate = grandchild.displayEstate;
                grandchildCategory.displayCar = grandchild.displayCar;
                tmpGrandchildCategories[grandchild.id] = grandchildCategory;
              });
              childCategory.children = tmpGrandchildCategories;
              tmpChildCategories[child.id] = childCategory;
            });
            parentCategory.children = tmpChildCategories;
            categories[parent.id] = parentCategory;
          });
          setPropertyCategories(categories);
        })
        .then(setLoading(false));
    }, []);
  }

  return (
    <CategoryContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        propertyCategories,
        loading,
      }}>
      {children}
    </CategoryContext.Provider>
  );
};

export default CategoryProvider;
