import 'interfaces/css/common/property-info-form-label.css';

const PropertyInfoFormLabel = ({ label, required }) => {
  return (
    <div className="property-info-form-label">
      <span data-testid="property-info-form-label">{label}</span>
      {required ? (
        <span data-testid="form-asterisk" style={{ color: 'var(--required)' }}>
          *
        </span>
      ) : (
        ''
      )}
    </div>
  );
};
export default PropertyInfoFormLabel;
