import { useEffect, useState } from 'react';

import { calc_dead_line } from '../../../../../../../shared/utils/helper/calc_dead_line.js';

const DeadlineInfoPanel = ({ text, auctionName, aplEnd }) => {
  const [deadline, setDeadline] = useState('');

  useEffect(() => {
    let deadline = calc_dead_line(aplEnd);

    setDeadline(deadline);

    const intervalId = setInterval(() => {
      setDeadline(calc_dead_line(aplEnd));
    }, 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div data-testid="deadline-info-panel">
      {text}：<br />
      {auctionName}
      <br />
      <b>
        {deadline === '' ? '申し込み終了' : `あと${deadline}で申し込み終了`}
      </b>
      <hr
        role="separator"
        aria-orientation="horizontal"
        className="my-2 divider"
      />
    </div>
  );
};

export default DeadlineInfoPanel;
