import MailNotificationConfigureProvider from './MailNotificationConfigureProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { mailNotificationConfigure } from "../entities/componentMapping.js";

const MailNotificationConfigure = ({ type }) => {
  return (
    <MailNotificationConfigureProvider type={type}>
      {getComponent(mailNotificationConfigure, type)}
    </MailNotificationConfigureProvider>
  );
};

export default MailNotificationConfigure;
