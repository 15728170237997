// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-info-general-form-radio-table-label {
  display: table-cell;
  padding: 12px;
  border-bottom-color: transparent;
  width: 30%;
  max-width: 300px;
  min-width: 200px;
  background-color: var(--secondary-base);
  font-weight: 700;
  vertical-align: middle;

}

.property-info-general-form-radio-table-button {
  padding: 12px;
  display: table-cell;
  width: 70%;
  background-color: var(--heading);
  vertical-align: middle;
  border-bottom: 1px solid #ccc;
}

.property-info-form-caution_description {
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 1.571;
}

.property-info-general-form-radio-procedure .radio-button:not(:last-child) {
  margin-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/property-info-general-form-radio.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,gCAAgC;EAChC,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,uCAAuC;EACvC,gBAAgB;EAChB,sBAAsB;;AAExB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,UAAU;EACV,gCAAgC;EAChC,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".property-info-general-form-radio-table-label {\n  display: table-cell;\n  padding: 12px;\n  border-bottom-color: transparent;\n  width: 30%;\n  max-width: 300px;\n  min-width: 200px;\n  background-color: var(--secondary-base);\n  font-weight: 700;\n  vertical-align: middle;\n\n}\n\n.property-info-general-form-radio-table-button {\n  padding: 12px;\n  display: table-cell;\n  width: 70%;\n  background-color: var(--heading);\n  vertical-align: middle;\n  border-bottom: 1px solid #ccc;\n}\n\n.property-info-form-caution_description {\n  white-space: pre-wrap;\n  font-size: 14px;\n  line-height: 1.571;\n}\n\n.property-info-general-form-radio-procedure .radio-button:not(:last-child) {\n  margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
