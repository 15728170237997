import AuctionNewProvider from './AuctionNewProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { auctionNew } from "../entities/componentMapping.js";

const AuctionNew = ({ type }) => {
  return (
    <AuctionNewProvider type={type}>
      {getComponent(auctionNew, type)}
    </AuctionNewProvider>
  );
};

export default AuctionNew;
