import SmallLightButton from "interfaces/components/common/Button/SmallLightButton";
import useModalControl from "shared/hooks/useModalControl";

import BlockListRegisterModal from "./BlockListRegisterModal";

const BlockListRegister = ({propertyName, userData}) => {
  const { isOpen, handleOpen, handleClose } = useModalControl();
  return (
    <div data-testid="blocklist-register">
      <SmallLightButton
        text="ブロックリスト登録"
        onClick={handleOpen}
      />
      <BlockListRegisterModal
        label="ブロックリスト登録"
        isOpen={isOpen}
        closeModal={handleClose}
        maxTextLimit={50}
        propertyName={propertyName}
        userData={userData}
      />
    </div>
  )
}
export default BlockListRegister;