// ロジックを含まない処理の実装

import { fetchGovernmentsStorage } from 'infrastructure/api/storage/governments.js';
import { getGovernmentSubCategory } from 'infrastructure/api/storage/government_sub_category.js';
import { getGovernmentCategory } from 'infrastructure/api/storage/government_category.js';
import { KMANAGER, NAVI, SERVICE } from 'shared/utils/helper/constants.js';
import CustomError from 'shared/utils/helper/customError.js';

import { GovernmentGet } from '../entities/Government.js';

// getメソッドの実装
export async function getGovernment(type) {
  // SAMPLE
  let response = [];
  let governmentSubCategoryResponse = [];
  let governmentCategoryResponse = [];
  if (type === SERVICE) {
    response = await fetchGovernmentsStorage();
    governmentSubCategoryResponse = await getGovernmentSubCategory();
    governmentCategoryResponse = await getGovernmentCategory();
  }
  if (type === NAVI) {
    response = await fetchGovernmentsStorage();
    governmentSubCategoryResponse = await getGovernmentSubCategory();
    governmentCategoryResponse = await getGovernmentCategory();
  }
  if (type === KMANAGER) {
    response = await fetchGovernmentsStorage();
    governmentSubCategoryResponse = await getGovernmentSubCategory();
    governmentCategoryResponse = await getGovernmentCategory();
  }

  if (response instanceof Error) {
    throw new CustomError('Error get Government', response);
  }

  let results = [];
  response.map((data) => {
    const result = new GovernmentGet(
      data,
      governmentSubCategoryResponse,
      governmentCategoryResponse,
    );
    results.push(result);
  });

  return results;
}
