import Button from 'interfaces/components/common/Button/Button.jsx';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useError } from 'shared/hooks/useError.js';
//import { useApplicationInfo } from '../../hooks/useApplicationInfo.js';

import { ProcedureType } from 'shared/utils/constants/auction.js';

import ApplicationInfoTable from './ApplicationInfoTable.jsx';
import {
  AssetSaleStepCaptionText,
  PublicSaleStepCaptionText,
} from './ConfirmApplicationInfoStepCaption.jsx';
import CreditCardInfoForm from './CreditCardInfoForm.jsx';

export const initCreditCardInfo = {
  cardNum1: '',
  cardNum2: '',
  cardNum3: '',
  cardNum4: '',
  expirationDateMM: '',
  expirationDateYY: '',
  securityCode: '',
};

const ConfirmApplicationInfo = ({
  data,
  applicantInformation,
  delegatorInformation,
  previousStep,
  nextStep,
}) => {
  const { setError } = useError();
  const [creditCardInfo, setCreditCardInfo] = useState(initCreditCardInfo);
  const navigate = useNavigate();

  const handleCreditCardInfoChange = (obj) => {
    setCreditCardInfo((currentInfo) => ({ ...currentInfo, ...obj }));
  };

  const handleClick = () => {
    try {
      /*
        TODO: 1. SBPSのトークンを取得する外部APIを叩く(クレジットカード決済の場合のみ)
              2. トークンが取得できたらCreate処理(内部API)を叩く
                  * applicationInfoとトークンの情報をパラメータとして渡す
      */
      navigate('/reception-completed', {
        state: { property_id: data.id },
      });
      nextStep();
    } catch (e) {
      setError(e);
    }
  };

  return (
    <div data-testid="confirm-application-info">
      <div className="primary-caution-block">
        {data.procedureType === ProcedureType.PUBLIC_SALE.string && (
          <PublicSaleStepCaptionText aplType={applicantInformation.aplType} />
        )}
        {data.procedureType === ProcedureType.ASSET_SALE.string && (
          <AssetSaleStepCaptionText aplType={applicantInformation.aplType} />
        )}
      </div>
      <ApplicationInfoTable
        title="参加者情報"
        info={applicantInformation}
        data={data}
      />
      {applicantInformation.isDelegatorProcedure && (
        <ApplicationInfoTable title="委任者情報" info={delegatorInformation} />
      )}
      {applicantInformation.aplType === 'ONLINE' && (
        <CreditCardInfoForm
          creditCardInfo={creditCardInfo}
          handleChange={handleCreditCardInfoChange}
        />
      )}
      <div className="flex justify-center mb-14">
        <Button
          text={
            <p>
              上記内容に同意のうえ、
              <br />
              申し込みを完了する
            </p>
          }
          height="52px"
          width="300px"
          onClick={handleClick}
          className="important-button mr-4"
        />
        <Button
          text="前に戻る"
          height="52px"
          width="120px"
          onClick={previousStep}
          className="cancel-common-button"
        />
      </div>
    </div>
  );
};

export default ConfirmApplicationInfo;
