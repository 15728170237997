import { useContext } from 'react';
import { MyPageContext } from 'interfaces/pages/MyPage/entities/MyPageContext';

import Button from 'interfaces/components/common/Button/Button';
import { useNavigate } from 'react-router-dom';

import ResetEmailButton from './ResetEmail/ResetEmailButton';
import ResetPasswordButton from './ResetPassword/ResetPasswordButton';

import styled from 'styled-components';

const PageTable = styled.table`
  border-spacing: 0;
  width: 100%;
`;

const MyPageTable = () => {
  const navigation = useNavigate();
  const { myUserInformation } = useContext(MyPageContext);

  return (
    <section className="section">
      <div className="mypage-table px-6 py-8 mb-8">
        <PageTable>
          <tbody>
            <tr>
              <th>メールアドレス</th>
              <td>{myUserInformation?.email}</td>
              <td>
                <ResetEmailButton />
              </td>
            </tr>
            <tr>
              <th>パスワード</th>
              <td>
                <span className="grey-text">セキュリティのため非表示</span>
              </td>
              <td>
                <ResetPasswordButton />
              </td>
            </tr>
            <tr>
              <th>会員識別番号</th>
              <td>
                <span>{myUserInformation?.membershipId}</span>
                <br />
                <small className="grey-text">
                  この番号は参加申し込みや入札、落札時の画面で利用されます。
                </small>
              </td>
              <td>変更できません</td>
            </tr>
            {/*// TODO: 2段階認証はfirst releaseに含めないので一時的に非表示*/}
            {/*<tr>*/}
            {/*  <th data-testid="table-title">二段階認証</th>*/}
            {/*  <td data-testid="table-cell">*/}
            {/*    /!*  TODO: 現行サイトの場合２段階認証済みだと電話番号を表示しているがQRコード以降後はどのような表示にするかお客様に確認する必要あり *!/*/}
            {/*    <span>{userInfo.twoStepVerificationStatus === 'UNSET' ? '未設定' : '設定済み'}</span>*/}
            {/*    <br/>*/}
            {/*    <small className="grey-text">*/}
            {/*      パスワード以外の認証方法を設定することで第三者による不正アクセス等に対してアカウント保護を強化することができます。設定にはSMSコードを受け取れるスマートフォンの電話番号が必要です。*/}
            {/*    </small>*/}
            {/*  </td>*/}
            {/*  <td data-testid="table-cell">*/}
            {/*    {userInfo.twoStepVerificationStatus === 'UNSET'*/}
            {/*      ? <Button*/}
            {/*        text='設定する'*/}
            {/*        height='44px'*/}
            {/*      />*/}
            {/*      : <Button*/}
            {/*        text='解除する'*/}
            {/*        height='44px'*/}
            {/*      />*/}
            {/*    }*/}
            {/*  </td>*/}
            {/*</tr>*/}
            <tr>
              <th rowSpan={2}>入札参加申し込み情報</th>
              <td>
                <span>参加者情報</span>
                <br />
                <small className="grey-text">
                  入札参加申し込みページのSTEP3で入力する参加者情報の管理を行えます。
                </small>
              </td>
              <td>
                {
                  <Button
                    text={`${myUserInformation?.hasApplicantData ? '変更する' : '設定する'}`}
                    height="44px"
                    onClick={() =>
                      navigation('/mypage/edit/user/', {
                        state: {
                          isEdit: myUserInformation?.hasApplicantData
                            ? true
                            : false,
                        },
                      })
                    }
                  />
                }
              </td>
            </tr>
            <tr>
              <td>
                <span>委任者情報</span>
                <br />
                <small className="grey-text">
                  入札参加申し込みページのSTEP3で入力する参加者情報の管理、及びクリア（消去）を行えます。
                </small>
              </td>
              <td>
                {
                  <Button
                    text={`${myUserInformation?.hasClientData ? '変更する' : '設定する'}`}
                    height="44px"
                    onClick={() =>
                      navigation('/mypage/edit/delegator/', {
                        state: {
                          isEdit: myUserInformation?.hasClientData
                            ? true
                            : false,
                        },
                      })
                    }
                  />
                }
              </td>
            </tr>
          </tbody>
        </PageTable>
      </div>
    </section>
  );
};

export default MyPageTable;
