import {useState} from "react";
//import * as Validation from "shared/utils/helper/managerValidators";

export const useFixResolutionDateInputsValidation = () => {
  const [fixResolutionDateValid, setFixResolutionDateValid] = useState('TRUE');

  // eslint-disable-next-line no-unused-vars
  function handleAuctionEntryValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    // TODO: 現状はどんな日付でも登録できる
    // invalid = Validation.validateAnnouncementLength(e.target.value);
    // if (invalid !== "TRUE") {
    //   setFixResolutionDateValid(invalid); // 状態更新
    //   return
    // }
    setFixResolutionDateValid(invalid); // 状態更新
  }

  const validations = {
    // validationの処理
    setters: {
      fixResolutionDate: handleAuctionEntryValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      fixResolutionDate: fixResolutionDateValid,
    }
  }

  return validations;
}
