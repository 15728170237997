import { DATA_EMPTY } from 'shared/utils/helper/constants';

export const createConfirmSectionData = (
  formData,
  division,
  category,
  procedureType,
) => {
  let depositPaymentValue = '保証金なし';
  if (formData.data.depositPayment === 'BOTH') {
    depositPaymentValue = 'クレジットカードまたは銀行振込';
  } else if (formData.data.depositPayment === 'CREDIT') {
    depositPaymentValue = 'クレジットカード';
  } else if (formData.data.depositPayment === 'BANK_TRANSFER') {
    depositPaymentValue = '銀行振込';
  }

  const isEmpty = [DATA_EMPTY, undefined, ''];

  const paymentString = (paymentMethod) => {
    console.log(paymentMethod);
    let paymentString = [];
    if (paymentMethod.bankTransfer !== undefined) {
      if (
        paymentMethod.bankTransfer === true ||
        paymentMethod.bankTransfer === 'TRUE'
      ) {
        paymentString.push('銀行振込');
      }
    }
    if (paymentMethod.registeredMail !== undefined) {
      if (
        paymentMethod.registeredMail === true ||
        paymentMethod.registeredMail === 'TRUE'
      ) {
        paymentString.push('現金書留');
      }
    }
    if (paymentMethod.check !== undefined) {
      if (paymentMethod.check === true || paymentMethod.check === 'TRUE') {
        paymentString.push('小切手');
      }
    }
    if (paymentMethod.postalMoneyOrder !== undefined) {
      if (
        paymentMethod.postalMoneyOrder === true ||
        paymentMethod.postalMoneyOrder === 'TRUE'
      ) {
        paymentString.push('郵便為替');
      }
    }

    if (paymentMethod.carry !== undefined) {
      if (paymentMethod.carry === true || paymentMethod.carry === 'TRUE') {
        paymentString.push('直接持参');
      }
    }

    return paymentString.join('、');
  };

  const section3PublicContents = [
    {
      text: '出品形式',
      state: isEmpty.includes(formData.data.publicSaleType)
        ? '未入力'
        : formData.data.publicSaleType === 'BID'
          ? '入札形式'
          : 'せり売形式',
    },
    {
      text: '掲載順位',
      state: isEmpty.includes(formData.data.printOrder)
        ? '未入力'
        : formData.data.printOrder !== ''
          ? formData.data.printOrder
          : '指定なし',
    },
    {
      text: 'カテゴリ',
      state: isEmpty.includes(category.grandchild)
        ? '未入力'
        : `${category?.parent.name} ＞ ${category?.child.name} ＞ ${category?.grandchild.name}`,
    },
    {
      text: 'イチ押し設定',
      state: '設定する',
    },
    {
      text: '執行機関名称',
      state: isEmpty.includes(formData.data.divisionId)
        ? '未入力'
        : formData.data.divisionId,
    },

    {
      text: '売却区分番号',
      state: isEmpty.includes(formData.data.saleNumber)
        ? '未入力'
        : formData.data.saleNumber,
    },

    {
      text: 'タイトル',
      state: isEmpty.includes(formData.data.name)
        ? '未入力'
        : formData.data.name,
    },
    {
      text: '支払い方法',
      state: paymentString(formData?.data.paymentMethod),
    },
    {
      text: '買受代金納付期限',
      state: isEmpty.includes(formData.data.paymentLimit)
        ? '未入力'
        : formData.data.paymentLimit,
    },

    {
      text: '動画URL',
      state: isEmpty.includes(formData.data.videoUrl)
        ? '未入力'
        : formData.data.videoUrl,
    },
    {
      text: '説明',
      state: isEmpty.includes(formData.data.description)
        ? '未入力'
        : formData.data.description,
    },
  ];

  const section3AssetContents = [
    {
      text: '出品形式',
      state: isEmpty.includes(formData.data.publicSaleType)
        ? '未入力'
        : formData.data.publicSaleType === 'BID'
          ? '入札形式'
          : 'せり売形式',
    },
    {
      text: '掲載順位',
      state: isEmpty.includes(formData.data.printOrder)
        ? '未入力'
        : formData.data.printOrder,
    },
    {
      text: 'カテゴリ',
      state: isEmpty.includes(category.grandchild)
        ? '未入力'
        : `${category?.parent.name} ＞ ${category?.child.name} ＞ ${category?.grandchild.name}`,
    },
    {
      text: 'イチ押し設定',
      state: '設定する',
    },
    {
      text: '執行機関名称',
      state: isEmpty.includes(formData.data.divisionId)
        ? '未入力'
        : formData.data.divisionId,
    },

    {
      text: '売却区分番号',
      state: isEmpty.includes(formData.data.saleNumber)
        ? '未入力'
        : formData.data.saleNumber,
    },

    {
      text: 'タイトル',
      state: isEmpty.includes(formData.data.name)
        ? '未入力'
        : formData.data.name,
    },
    {
      text: '支払い方法',
      state: paymentString(formData?.data.paymentMethod),
    },
    {
      text: '議決日',
      state: isEmpty.includes(formData.data.resolutionDate)
        ? '未入力'
        : formData.data.resolutionDate,
    },

    {
      text: '売払代金納付期限',
      state: isEmpty.includes(formData.data.paymentLimit)
        ? '未入力'
        : formData.data.paymentLimit,
    },
    {
      text: '契約締結期限',
      state: isEmpty.includes(formData.data.contractedLimit)
        ? '未入力'
        : formData.data.contractedLimit,
    },

    {
      text: '動画URL',
      state: isEmpty.includes(formData.data.videoUrl)
        ? '未入力'
        : formData.data.videoUrl,
    },
    {
      text: '説明',
      state: isEmpty.includes(formData.data.description)
        ? '未入力'
        : formData.data.description,
    },
  ];

  const section5PublicContents = [
    {
      text: '保証金納付手続き',
      state: isEmpty.includes(depositPaymentValue)
        ? '未入力'
        : depositPaymentValue,
    },
    {
      text: '参加申し込み承認',
      state: isEmpty.includes(formData.data.isApprovalRequired)
        ? '未入力'
        : formData.data.isApprovalRequired !== 'TRUE'
          ? '不要'
          : '必要',
    },
    {
      text: '見積価額',
      state: isEmpty.includes(formData.data.estimatePrice)
        ? '未入力'
        : `${formData.data.estimatePrice} 円`,
    },
    {
      text: '公売保証金',
      state: isEmpty.includes(formData.data.deposit)
        ? '未入力'
        : `${formData.data.deposit} 円`,
    },
    {
      text: '適格請求書',
      state: isEmpty.includes(formData.data.qualifiedInvoice)
        ? '未入力'
        : formData.data.qualifiedInvoice !== 'FALSE'
          ? '発行可能'
          : '発行不可',
    },
  ];

  const section5AssetContents = [
    {
      text: '保証金納付手続き',
      state: isEmpty.includes(depositPaymentValue)
        ? '未入力'
        : depositPaymentValue,
    },
    {
      text: '見積価額',
      state: isEmpty.includes(formData.data.estimatePrice)
        ? '未入力'
        : `${formData.data.estimatePrice} 円`,
    },
    {
      text: '公売保証金',
      state: isEmpty.includes(formData.data.deposit)
        ? '未入力'
        : `${formData.data.deposit} 円`,
    },
    {
      text: '適格請求書',
      state: isEmpty.includes(formData.data.qualifiedInvoice)
        ? '未入力'
        : formData.data.qualifiedInvoice !== 'FALSE'
          ? '発行可能'
          : '発行不可',
    },
  ];
  return {
    section1: [
      {
        text: '公開設定',
        state: isEmpty.includes(formData.data.isDraft)
          ? '未入力'
          : formData.data.isDraft === 'DRAFT'
            ? '下書き'
            : '公開',
      },
    ],
    section2: [
      {
        text: '画像データ',
        state: '画像が登録されていません',
      },
    ],
    section3:
      procedureType === 'PUBLIC_SALE'
        ? section3PublicContents
        : section3AssetContents,
    section4: [
      {
        text: '出品行政機関名',
        state: isEmpty.includes(formData.data.inquiryName)
          ? '未入力'
          : formData.data.inquiryName,
      },
      {
        text: '担当部署名',
        state: isEmpty.includes(formData.data.inquiryOffice)
          ? '未入力'
          : formData.data.inquiryOffice,
      },
      {
        text: 'メールアドレス',
        state: isEmpty.includes(formData.data.inquiryEmail)
          ? '未入力'
          : formData.data.inquiryEmail,
      },
      {
        text: '電話番号',
        state:
          formData.data?.inquiryTel.telNum1 &&
          formData.data?.inquiryTel.telNum2 &&
          formData.data?.inquiryTel.telNum3
            ? `${formData.data?.inquiryTel.telNum1}-${formData.data?.inquiryTel.telNum2}-${formData.data?.inquiryTel.telNum3}${formData.data?.extension == 'DATA_EMPTY' ? '' : formData.data?.inquiryExtension ? `  内線: ${formData.data?.inquiryExtension}` : ''}`
            : '未入力',
        //state: `${formData.data.inquiryTel !== '--' ? formData.data.inquiryTel : '未入力'} 内線：${formData.data.inquiryExtension !== '' ? formData.data.inquiryExtension : '未入力'}`,
      },
      {
        text: '電話受付時間',
        state: isEmpty.includes(formData.data.inquiryAccept)
          ? '未入力'
          : formData.data.inquiryAccept,
      },
      {
        text: '備考',
        state: isEmpty.includes(formData.data.inquiryNote)
          ? '未入力'
          : formData.data.inquiryNote,
      },
    ],
    section5:
      procedureType === 'PUBLIC_SALE'
        ? section5PublicContents
        : section5AssetContents,
    section6: [
      {
        text: '連絡先機関名',
        state: isEmpty.includes(formData.data.contactName)
          ? '未入力'
          : formData.data.contactName,
      },
      {
        text: '担当部署名',
        state: isEmpty.includes(formData.data.contactOffice)
          ? '未入力'
          : formData.data.contactOffice,
      },
      {
        text: 'メールアドレス',
        state: isEmpty.includes(formData.data.contactEmail)
          ? '未入力'
          : formData.data.contactEmail,
      },
      {
        text: '電話番号',
        state:
          formData.data?.contactTel.telNum1 &&
          formData.data?.contactTel.telNum2 &&
          formData.data?.contactTel.telNum3
            ? `${formData.data?.contactTel.telNum1}-${formData.data?.contactTel.telNum2}-${formData.data?.contactTel.telNum3}${formData.data?.extension == 'DATA_EMPTY' ? '' : formData.data?.contactExtension ? `  内線: ${formData.data?.contactExtension}` : ''}`
            : '未入力',
        //state: `${formData.data.contactTel !== '--' ? formData.data.contactTel : '未入力'} 内線：${formData.data.contactExtension !== '' ? formData.data.contactExtension : '未入力'}`,
      },
      {
        text: '電話受付時間',
        state: isEmpty.includes(formData.data.contactAccept)
          ? '未入力'
          : formData.data.contactAccept,
      },
    ],
  };
};
