// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-info-car-form-input-number {
  display: flex;
  align-items: center;
}

.property-info-car-form-input-number-unit {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, .42);
  border-radius: 4px;
  padding: 0 12px;
  width: 10em;
}

.property-info-car-form-unit-margin {
  margin-top: 8px;
  margin-bottom: auto;
}

.property-info-car-form-input-checkbox {
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-top: 8px;
  margin-bottom: auto;
}

.property-info-car-form-input-checkbox .input-checkbox {
  margin: 0;
  padding: 0;
}

.property-info-car-form-input-checkbox .input-checkbox-slot {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertyInfoCar/property-info-car-form-input-number.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;EACpC,oCAAoC;EACpC,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,SAAS;AACX","sourcesContent":[".property-info-car-form-input-number {\n  display: flex;\n  align-items: center;\n}\n\n.property-info-car-form-input-number-unit {\n  background-color: rgb(255, 255, 255);\n  border: 1px solid rgba(0, 0, 0, .42);\n  border-radius: 4px;\n  padding: 0 12px;\n  width: 10em;\n}\n\n.property-info-car-form-unit-margin {\n  margin-top: 8px;\n  margin-bottom: auto;\n}\n\n.property-info-car-form-input-checkbox {\n  display: flex;\n  align-items: center;\n  margin-left: 24px;\n  margin-top: 8px;\n  margin-bottom: auto;\n}\n\n.property-info-car-form-input-checkbox .input-checkbox {\n  margin: 0;\n  padding: 0;\n}\n\n.property-info-car-form-input-checkbox .input-checkbox-slot {\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
