import styled from 'styled-components';

const Wrapper = styled.span`
  color: var(--required);
`;

const RequiredLongLabel = () => {
  return <Wrapper data-testid="required-long-label">必須*</Wrapper>;
};

export default RequiredLongLabel;
