import axios from 'axios';

import {API_BASE_URL} from "../../config/envConstants.js";

export const fetchDepositData = async () => {
  return await axios.get(
    `${API_BASE_URL}/api/v1/management/deposit`,
  );
};
export const createDepositData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/deposit`,
    body, {headers: headers}
  );

};
export const updateDepositData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/deposit/${id}`,
    body, {headers: headers}
  );
};
export const deleteDepositData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/management/deposit/${id}`, {
      headers: headers,
    },);
};