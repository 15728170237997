import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class OfficePosition {
  #officePosition

  constructor(officePosition) {
    this.#officePosition = officePosition;
  }

  get() {
    return this.#officePosition
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#officePosition, 0, 30)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#officePosition)
  }
}
