import { Link } from 'react-router-dom';

import { Message } from './WaitOpenBid.jsx';

export const BiddedAuction = ({ data, setIsNoMessage }) => {
  if (data.status === 'DURING_BIDDING') {
    if (data.currentTopUser === data.membershipId) {
      return <Message content={'現在あなたが最高額入札者です。'} />;
    }
    return (
      <p className="ma-0">
        他の方が最高額入札者となりました。この物件への再入札を行ってください。
        <br />
        ※同額の場合は先に入札した方が「最高額入札者」となります。
      </p>
    );
  }

  if (data.status === 'WAIT_OPEN_BID') {
    return (
      <Message
        content={
          '現在執行機関による入札結果確認中です。落札者は入札結果の確認完了後に確定されます。しばらくお待ちください。'
        }
      />
    );
  }

  if (data.status === 'END_BIDDING') {
    if (data.membershipId === data.bidderUser) {
      return (
        <p className="ma-0">
          おめでとうございます！ あなたが落札者です。
          <br />
          今後のお手続きについては、お客様から電話で下記連絡先に、問い合わせください。
          <br />
          {data.procedureType === 'PUBLIC_SALE' ? (
            <span>
              ※買受代金納付期限までに買受代金の納付を執行機関が確認できない場合、公売保証金は没収となります。
            </span>
          ) : (
            <span>
              ※売払代金納付期限までに売払代金の納付を執行機関が確認できない場合、入札保証金は没収となります。
            </span>
          )}
        </p>
      );
    }
    return (
      <p className="ma-0">
        残念ながら落札できませんでした。またのご利用をお待ちしております。
        <br />
        今後の開催予定は<Link to={'/schedules'}>こちら</Link>
        からご確認ください。
      </p>
    );
  }

  setIsNoMessage(true);
};
