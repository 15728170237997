// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login .service-login-form {
  margin: 0 16px 16px 16px;
}

.login .login-form-link {
  height: 36px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}

.login .login-form-link:hover {
  text-decoration: none;
}

.login .cancel-button {
  color: var(--anchor-base);
  background-color: var(--white);
  box-shadow: none;
  margin: 0 8px;
  padding: 0 4px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Login/service-login-form.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,8BAA8B;EAC9B,gBAAgB;EAChB,aAAa;EACb,cAAc;AAChB","sourcesContent":[".login .service-login-form {\n  margin: 0 16px 16px 16px;\n}\n\n.login .login-form-link {\n  height: 36px;\n  font-size: 0.75rem;\n  display: flex;\n  align-items: center;\n}\n\n.login .login-form-link:hover {\n  text-decoration: none;\n}\n\n.login .cancel-button {\n  color: var(--anchor-base);\n  background-color: var(--white);\n  box-shadow: none;\n  margin: 0 8px;\n  padding: 0 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
