import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class Department {
  #department

  constructor(department) {
    this.#department = department;
  }

  get() {
    return this.#department
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#department, 1, 50)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#department)
  }
}
