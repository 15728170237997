
export class CallUpButtonDownBidDate {
  #callUpButtonDownBidDate

  constructor(callUpButtonDownBidDate) {
    this.#callUpButtonDownBidDate = callUpButtonDownBidDate;
  }

  get() {
    return this.#callUpButtonDownBidDate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
