import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import { breadcrumbs } from 'interfaces/pages/OrganizationRegister/entities/complete/breadcrumbs';
import { Link } from 'react-router-dom';
import 'interfaces/css/Organization/organization.css';
import 'interfaces/css/common/property-info-general-form.css';

const OrganizationComplete = () => {
  return (
    <div className="organization" data-testid="organization">
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <Heading2 title="執行機関管理：新規登録：登録完了" />
      <div className="complete-message">
        <div>登録完了しました。</div>
      </div>
      <div className="description" data-testid="description">
        <Link to={'/navi/organization'}>執行機関管理へ</Link>
      </div>
      <div className="description" data-testid="description">
        <Link to={'/navi'}>トップページへ</Link>
      </div>
    </div>
  );
};

export default OrganizationComplete;
