import { useState } from 'react';

import SmallLightIconButton from './Button/SmallLightIconButton.jsx';
import 'interfaces/css/common/sort-toolbar.css';

/*
  sortButtonData
*/
export const SortToolbar = ({
  sortButtonData,
  children,
  showDelegationInfo,
  governmentUser = undefined,
  setSortParam = () => {},
  setType = () => {},
}) => {
  const [activeSortButtonId, setActiveSortButtonId] = useState(1);
  const [isSortDesc, setIsSortDesc] = useState(false);
  const handleSortButtonClick = (id) => {
    let sortParam = { sortId: id, order: 'asc' };
    if (id === activeSortButtonId) {
      sortParam.order = !isSortDesc === false ? 'asc' : 'desc';
      setIsSortDesc(!isSortDesc);
    } else {
      sortParam.order = 'asc';
      setIsSortDesc(false);
    }
    setActiveSortButtonId(id);
    setSortParam(sortParam);
  };

  const handleTypeChange = (e) => {
    if (e.target.checked) {
      setType('CLIENT');
    } else setType('APPLICANT');
  };

  return (
    <div className="sort-toolbar" data-testid="sort-toolbar">
      <div className="sort-toolbar-inner">
        {children}
        <div className="group" data-testid="group">
          {sortButtonData &&
            sortButtonData.map((sortButton) => (
              <SmallLightIconButton
                key={sortButton.id}
                active={sortButton.id === activeSortButtonId}
                isSortDesc={isSortDesc}
                onClick={() => {
                  handleSortButtonClick(sortButton.id);
                }}>
                {sortButton.label}
              </SmallLightIconButton>
            ))}
        </div>
      </div>
      {showDelegationInfo &&
        (governmentUser?.userType === 'CHILD' ? (
          <></>
        ) : (
          <div className="delegation-info" data-testid="delegation-info">
            <input type="checkbox" onChange={handleTypeChange} />
            このページ内の委任者情報を全て表示する
          </div>
        ))}
    </div>
  );
};

export default SortToolbar;
