// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-number-button-wrap {
  text-indent: -1em;
  margin-left: 1em;
}

.page-number-button {
  min-width: 34px;
  height: 34px;
  background-color: var(--white);
  border-radius: 4px;
  font-size: 1rem;
  margin: 0.3rem;
  padding: 0 5px;
  -webkit-transition: .3s cubic-bezier(0,0,.2,1);
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  border: 0;
}

.current-page {
  background-color: var(--primary-base);
  color: var(--white);
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/page-number-button.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,8BAA8B;EAC9B,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,cAAc;EACd,8CAA8C;EAE9C,mGAAmG;EACnG,SAAS;AACX;;AAEA;EACE,qCAAqC;EACrC,mBAAmB;AACrB","sourcesContent":[".page-number-button-wrap {\n  text-indent: -1em;\n  margin-left: 1em;\n}\n\n.page-number-button {\n  min-width: 34px;\n  height: 34px;\n  background-color: var(--white);\n  border-radius: 4px;\n  font-size: 1rem;\n  margin: 0.3rem;\n  padding: 0 5px;\n  -webkit-transition: .3s cubic-bezier(0,0,.2,1);\n  -webkit-box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);\n  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);\n  border: 0;\n}\n\n.current-page {\n  background-color: var(--primary-base);\n  color: var(--white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
