/** @format */

// App コンポーネントが多くの責任を負って Single Responsibility Principle (SRP) に反していた
// 認証やAPIの設定（Axiosの設定など）をこのコンポーネントで行う

import axios from "axios";

import './shared/utils/helper/axiosInterseptore.js';
import ErrorProvider from "./ErrorProvider.jsx";
import { getBaseUrl } from "./shared/utils/helper/config";

const AppProviders = ({ children, ...props }) => {

  // ユーザーのタイプによって、React の REACT_APP_BASE_URL を変更する
  const baseUrl = getBaseUrl();
  axios.defaults.baseURL = baseUrl;

  return (
    <ErrorProvider {...props}>
      {children}
    </ErrorProvider>
  );
};

export default AppProviders;
