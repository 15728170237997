
export class CreatedAt {
    #createdAt

    constructor(createdAt) {
        this.#createdAt = createdAt;
    }

    get() {
        return this.#createdAt
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
