export const bidEndToDeadLine = (bidEnd) => {
  const isDateType = bidEnd instanceof Date;
  const isTimestamp = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.?\d{0,6}$/.test(bidEnd);

  if (!isDateType && !isTimestamp) {
    return 'なし';
  }

  const date = new Date(bidEnd);
  const differentMinute = 210;
  return new Date(date.getTime() + (60000 * differentMinute))
}