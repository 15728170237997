import { createContext } from 'react';

import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { DATA_EMPTY } from '../../../../shared/utils/helper/constants.js';

export const GovernmentDetailContext = createContext({
  authState: {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  },
  government: DATA_EMPTY,
  governmentId: DATA_EMPTY,
  documentSendPages: DATA_EMPTY,
  divisions: DATA_EMPTY,
  publicSaleUsers: DATA_EMPTY,
  assetSaleUsers: DATA_EMPTY,
});
