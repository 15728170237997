import SortToolbar from 'interfaces/components/common/SortToolbar';

import 'interfaces/css/PublishedObjectStatus/property-list-table.css';
import 'interfaces/css/PublishedObjectStatus/sort-toolbar.css';
import { useContext } from 'react';

import { PublishedObjectStatusContext } from '../../../pages/PublishedObjectStatus/entities/PublishedObjectStatusContext';

const sortButtonData = [
  {
    id: 1,
    label: '掲載順',
  },
  {
    id: 2,
    label: '区分番号',
  },
  {
    id: 3,
    label: 'タイトル',
  },
  {
    id: 4,
    label: '公売保証金',
  },
  {
    id: 5,
    label: '見積価額',
  },
  {
    id: 6,
    label: '落札価額',
  },
  {
    id: 7,
    label: '銀行振込など(仮申込)',
  },
  {
    id: 8,
    label: '銀行振込など(本登録)',
  },
  {
    id: 9,
    label: 'クレジットカード(仮申込)',
  },
  {
    id: 10,
    label: 'クレジットカード(本登録)',
  },
  {
    id: 11,
    label: 'マイリスト登録数',
  },
  {
    id: 12,
    label: '入札者数',
  },
  {
    id: 13,
    label: '執行機関',
  },
];
const sortButtonDataAssetSale = [
  {
    id: 1,
    label: '掲載順',
  },
  {
    id: 2,
    label: '区分番号',
  },
  {
    id: 3,
    label: 'タイトル',
  },
  {
    id: 4,
    label: '入札保証金',
  },
  {
    id: 5,
    label: '予定価格',
  },
  {
    id: 6,
    label: '落札価格',
  },
  {
    id: 7,
    label: '銀行振込など(仮申込)',
  },
  {
    id: 8,
    label: '銀行振込など(本登録)',
  },
  {
    id: 9,
    label: 'クレジットカード(仮申込)',
  },
  {
    id: 10,
    label: 'クレジットカード(本登録)',
  },
  {
    id: 11,
    label: 'マイリスト登録数',
  },
  {
    id: 12,
    label: '入札者数',
  },
  {
    id: 13,
    label: '執行機関',
  },
];

const intoLocaleYen = (value) => {
  if (value === '') return '0円';
  if (value === 'DATA_EMPTY') return '0円';
  if (value === undefined) return '0円';
  if (value === '-9999') return '0円';
  return `${Number(value).toLocaleString()}円`;
};

const PropertyListTable = ({ tableData, procedureType }) => {
  const { government, setSortParam } = useContext(PublishedObjectStatusContext);

  return (
    <div className="property-list">
      <div className="sort-toolbar-wrap">
        <SortToolbar
          sortButtonData={
            procedureType === 'PUBLIC_SALE'
              ? sortButtonData
              : sortButtonDataAssetSale
          }
          setSortParam={setSortParam}>
          <p className="sort-toolbar-label">並び順</p>
        </SortToolbar>
      </div>
      <div className="property-list-table">
        <table>
          <thead data-testid="property-list-table-header">
            <tr key="header1">
              <th className="th-order" rowSpan="2">
                掲載順
              </th>
              <th className="th-segment-num" rowSpan="2">
                区分番号
              </th>
              <th className="th-title" rowSpan="2">
                タイトル
              </th>
              <th className="th-estimated-price" rowSpan="2">
                {procedureType === 'PUBLIC_SALE' ? '見積価額' : '予定価格'}
              </th>
              <th className="th-deposit" rowSpan="2">
                {procedureType === 'PUBLIC_SALE'
                  ? '公売保証金額'
                  : '入札保証金額'}
              </th>
              <th className="th-contract-price" rowSpan="2">
                落札価額
              </th>
              {government?.governmentType === 'NATIONAL_TAX_AGENCY' ? (
                <th className="th-title" rowSpan="2">
                  配送情報
                </th>
              ) : (
                <></>
              )}
              <th className="th-bank-transfers" colSpan="2">
                申し込み数
                <br />
                (銀行振込など)
              </th>
              <th className="th-credit_card" colSpan="2">
                申し込み数
                <br />
                (クレジットカード)
              </th>
              <th className="th-my-list-entries" rowSpan="2">
                マイリスト
                <br />
                登録数
              </th>
              <th className="th-bidders" rowSpan="2">
                入札者数
              </th>
              <th className="th-executive-committee" rowSpan="2">
                執行機関
              </th>
            </tr>
            <tr key="header2">
              <th>仮申込</th>
              <th>本登録</th>
              <th>仮申込</th>
              <th>本登録</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((d) => (
              <tr key={d.saleNumber}>
                <td className="object-padding">{d.order}</td>
                <td className="object-padding">{d.saleNumber}</td>
                <td className="object-padding">{d.name}</td>
                <td className="text-right object-padding">
                  {intoLocaleYen(d.estimatePrice)}
                </td>
                <td className="text-right object-padding">
                  {intoLocaleYen(d.deposit)}
                </td>
                <td className="text-right object-padding">
                  {intoLocaleYen(d.bidEndPrice)}
                </td>
                {government.governmentType === 'NATIONAL_TAX_AGENCY' ? (
                  <td className="object-padding">
                    {d.isDeliveryRequestDisplayed === 'DISPLAY'
                      ? d.deliveryStyle !== undefined
                        ? '登録完了'
                        : '未登録'
                      : 'ー'}
                  </td>
                ) : (
                  <></>
                )}
                <td className="text-right object-padding">{d.offlineTrial}</td>
                <td className="text-right object-padding">
                  {d.offlineApplication}
                </td>
                <td className="text-right object-padding">{d.onlineTrial}</td>
                <td className="text-right object-padding">
                  {d.onlineApplication}
                </td>
                <td className="text-right object-padding">{d.mylistCount}</td>
                <td className="text-right object-padding">{d.bidderCount}</td>
                <td className="object-padding">{d.divisionName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PropertyListTable;
