
export class RemainingLabel {
    #remainingLabel

    constructor(remainingLabel) {
        this.#remainingLabel = remainingLabel;
    }

    get() {
        return this.#remainingLabel
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
