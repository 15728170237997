
export class Region {
    #region

    constructor(region) {
        this.#region = region;
    }

    get() {
        return this.#region
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
