
export class SystemUsageFeeBid {
  #systemUsageFeeBid

  constructor(systemUsageFeeBid) {
    this.#systemUsageFeeBid = systemUsageFeeBid;
  }

  get() {
    return this.#systemUsageFeeBid
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
