import { useState, useCallback } from 'react';
import styled from 'styled-components';

import ImageDetailPropertyCard from 'interfaces/components/Property/service/PropertyCard/ImageDetailPropertyCard/ImageDetailPropertyCard.jsx';
import { ReactComponent as LeftArrowButton } from '../../../assets/svg/left_arrow.svg';
import { ReactComponent as RightArrowButton } from '../../../assets/svg/right_arrow.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SlideContainerWrapper = styled.div`
  padding-right: 50px;
  overflow: hidden;
  color: #38342d;
  margin: 0 auto;
  box-shadow:
    0 0 0 0 rgba(0, 0, 0, 0.2),
    0 0 0 0 rgba(0, 0, 0, 0.14),
    0 0 0 0 rgba(0, 0, 0, 0.12);
  border-color: #fff;
  background: none;
  border-radius: 0;
`;

const SlideContainer = styled.div`
  overflow: visible;
  margin: 0 auto;
  position: relative;
  list-style: none;
  padding: 0;
  z-index: 1;
`;

const SlideContainerInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property:
    transform,
    -webkit-transform;
  box-sizing: content-box;
`;

const Slide = styled.div`
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 200px;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property:
    transform,
    -webkit-transform;
  margin: 0 5px;
  margin-bottom: -1px;
  padding: 8px 0;
`;

const Button = styled.button`
  color: #f78c23;
  height: 28px;
  width: 17.5px;
  will-change: box-shadow;
  font-size: 0.875rem;
  border-radius: 50%;
  background-color: transparent;
  min-height: 0;
  min-width: 0;
  padding: 0;
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: uppercase;
  transition-duration: 0.28s;
  transition-property:
    box-shadow,
    transform,
    opacity,
    -webkit-box-shadow,
    -webkit-transform;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  border-style: none;
  overflow: visible;
`;

const StyledLeftArrowButton = styled(LeftArrowButton)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;

  svg {
    overflow: visible;
    color: #f78c23;
    caret-color: #f78c23;
    width: 0.625em;
    font-size: 2em;
    display: inline-block;
    height: 1em;
    vertical-align: -0.125em;
  }
`;

const StyledRightArrowButton = styled(RightArrowButton)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;

  svg {
    overflow: visible;
    color: #f78c23;
    caret-color: #f78c23;
    width: 0.625em;
    font-size: 2em;
    display: inline-block;
    height: 1em;
    vertical-align: -0.125em;
  }
`;

const Slider = ({ properties, isShowGovernmentName }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex < properties.length - 3 ? prevIndex + 1 : prevIndex,
    );
  }, [properties.length]);

  const handlePrev = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  }, []);

  const sliderStyle = {
    transform: `translateX(-${currentIndex * 212}px)`,
    transition: 'transform 0.3s ease-in-out',
  };

  return (
    <Wrapper data-testid="slider">
      <Button onClick={handlePrev} data-testid="left-arrow-btn">
        <StyledLeftArrowButton data-testid="left-arrow-icon" />
      </Button>
      <SlideContainerWrapper data-testid="slide-container-wrap">
        <SlideContainer>
          <SlideContainerInner style={sliderStyle}>
            {properties.map((property, index) => (
              <Slide
                className={`${index === currentIndex ? 'active' : ''}`}
                key={property.id}
                data-testid={`slider-slide-${index}`}>
                <ImageDetailPropertyCard
                  data={property}
                  width="100%"
                  isShowGovernmentName={isShowGovernmentName}
                />
              </Slide>
            ))}
          </SlideContainerInner>
        </SlideContainer>
      </SlideContainerWrapper>
      <Button onClick={handleNext} data-testid="right-arrow-btn">
        <StyledRightArrowButton data-testid="right-arrow-icon" />
      </Button>
    </Wrapper>
  );
};

export default Slider;
