import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class DeliveryTerms {
  #deliveryTerms

  constructor(deliveryTerms) {
    this.#deliveryTerms = deliveryTerms;
  }

  get() {
    return this.#deliveryTerms
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#deliveryTerms, 1, 100)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#deliveryTerms)
  }
}
