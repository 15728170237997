import { fetchAssetSaleCautionPageStorage } from 'infrastructure/api/storage/asset_sale_caution_page.js';
import { useState, useEffect, useCallback } from 'react';
import { useError } from 'shared/hooks/useError.js';
import camelizeKeys from 'shared/utils/helper/camelize.js';

export const useAssetSaleCautionPage = (governmentId) => {
  const { setError } = useError();
  const [assetSaleCautionPages, setAssetSaleCautionPage] = useState([]);
  const [auctionAssetSaleCautionPages, setAuctionAssetSaleCautionPages] =
    useState([{}]);
  const [bidAssetSaleCautionPages, setBidAssetSaleCautionPages] = useState([
    {},
  ]);
  const [loading, setLoading] = useState(true);

  const fetchAssetSaleCautionPage = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchAssetSaleCautionPageStorage();
      setAssetSaleCautionPage(camelizeKeys(data));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    const fetch = async () => {
      await fetchAssetSaleCautionPage();
    };
    fetch().then();
  }, [fetchAssetSaleCautionPage]);

  useEffect(() => {
    // governmentIdによるデータの出しわけ
    const dataFilterByGovernmentId = assetSaleCautionPages.filter(
      (item) => item.governmentId === governmentId,
    );

    // publicSaleTypeによるデータの出しわけ
    setAuctionAssetSaleCautionPages(
      dataFilterByGovernmentId.filter(
        (item) => item.publicSaleType === 'AUCTION',
      ),
    );
    setBidAssetSaleCautionPages(
      dataFilterByGovernmentId.filter((item) => item.publicSaleType === 'BID'),
    );
  }, [assetSaleCautionPages]);

  return { auctionAssetSaleCautionPages, bidAssetSaleCautionPages, loading };
};
