import { useState } from 'react';

export function useManagementUserInputs() {
  const [userType, setUserType] = useState('ADMIN');
  const [familyName, setFamilyName] = useState('');
  const [familyNameKana, setFamilyNameKana] = useState('');
  const [firstName, setFirstName] = useState('');
  const [firstNameKana, setFirstNameKana] = useState('');
  const [department, setDepartment] = useState('');
  const [officePosition, setOfficePosition] = useState('');
  const [tel, setTel] = useState('');
  const [extension, setExtension] = useState('');
  const [email, setEmail] = useState('');

  const userInputs = {
    type: {
      userType: 'radio',
      familyName: 'text',
      familyNameKana: 'text',
      firstName: 'text',
      firstNameKana: 'text',
      department: 'text',
      officePosition: 'text',
      tel: 'text',
      extension: 'text',
      email: 'text',
    },
    data: {
      userType,
      familyName,
      familyNameKana,
      firstName,
      firstNameKana,
      department,
      officePosition,
      tel,
      extension,
      email,
    },
    setters: {
      userType: setUserType,
      familyName: setFamilyName,
      familyNameKana: setFamilyNameKana,
      firstName: setFirstName,
      firstNameKana: setFirstNameKana,
      department: setDepartment,
      officePosition: setOfficePosition,
      tel: setTel,
      extension: setExtension,
      email: setEmail,
    },
  };

  return userInputs;
}
