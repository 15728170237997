import { DATA_EMPTY } from 'domain/Property/entities/constants/property.js';

import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle.jsx';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault.jsx';

export const extension = (data) => {
  if (!data || !data.inquiryExtension || data.inquiryExtension === DATA_EMPTY) {
    return '';
  }
  return `（内線 ${data.inquiryExtension}）`;
};

const ExhibitingGovernmentInfos = ({ info }) => {
  const infos = [
    { id: 1, label: '連絡先機関名', value: info.data?.inquiryName },
    { id: 2, label: '担当部署名', value: info.data?.inquiryOffice },
    { id: 3, label: 'メールアドレス', value: info.data?.inquiryEmail },
    {
      id: 4,
      label: '電話番号',
      value: (
        <p>
          {info.data?.inquiryTel}
          <br />
          {extension(info.data)}
        </p>
      ),
    },
    { id: 5, label: '電話受付時間', value: info.data?.inquiryAccept },
  ];

  return (
    <div data-testid="exhibiting-government-infos">
      <ArticleDefaultTitle title="出品者情報" />
      <ArticleDefaultBody>
        <CommonTableDefault>
          {infos.map((info) => (
            <tr
              key={info.id}
              className="flex"
              data-testid="exhibiting-government-info">
              <th>{info.label}</th>
              <td>{info.value}</td>
            </tr>
          ))}
        </CommonTableDefault>
      </ArticleDefaultBody>
    </div>
  );
};

export default ExhibitingGovernmentInfos;
