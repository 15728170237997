import {
  BidCountBody,
  CurrentTopUserBody,
  DepositBody,
  DepositPaymentBody,
  MyBidPriceBody,
  PaymentBody,
  RemainingLabel,
} from './PropertyCardCaption.jsx';
import { isFalsy } from '../../helper/isFalsy.js';
import { useCalcDeadline } from '../../hooks/useCalcDeadline.js';

const DuringBiddingPropertyCardCaptions = ({ data }) => {
  const { deadline } = useCalcDeadline(data);

  return (
    <>
      {data.publicSaleType === 'せり売形式' && (
        <>
          <CurrentTopUserBody currentTopUser={data.currentTopUser} />
          <BidCountBody bidCount={data.bidCount} />
        </>
      )}
      {!isFalsy(data.myBidPrice) && (
        <MyBidPriceBody myBidPrice={data.myBidPrice} />
      )}
      <DepositBody deposit={data.deposit} />
      <RemainingLabel
        remainingLabel={data.remainingLabel}
        deadline={deadline}
      />
      <DepositPaymentBody depositPayment={data.depositPayment} />
      <PaymentBody payments={data.payments} />
    </>
  );
};

export default DuringBiddingPropertyCardCaptions;
