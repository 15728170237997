import SampleAfterWinningBidProvider from './SampleAfterWinningBidProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { sampleAfterWinningBid } from "../entities/componentMapping.js";

const SampleAfterWinningBid = ({ type }) => {
  return (
    <SampleAfterWinningBidProvider type={type}>
      {getComponent(sampleAfterWinningBid, type)}
    </SampleAfterWinningBidProvider>
  );
};

export default SampleAfterWinningBid;
