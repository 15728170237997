import { useContext } from 'react';

import ForgetPasswordModalContents from './ForgetPassword/ForgetPasswordModalContents.jsx';
import LoginModalContents from './Login/LoginModalContents.jsx';
import SignUpModalContents from './SignUp/SignUpModalContents.jsx';
import { DashboardContext } from '../../../../../pages/Dashboard/entities/DashboardContext';
import Modal2 from '../../../../common/Modal/Modal2.jsx';

const AuthModal = ({
  isOpen,
  handleClose,
  currentAuthStatus,
  switchAuthStatus,
}) => {
  const { authState } = useContext(DashboardContext);
  const targetChild = () => {
    if (currentAuthStatus === authState.LOGIN) {
      return (
        <LoginModalContents
          switchAuthStatus={switchAuthStatus}
          handleClose={handleClose}
        />
      );
    }
    if (currentAuthStatus === authState.SIGN_UP) {
      return (
        <SignUpModalContents
          switchAuthStatus={switchAuthStatus}
          handleClose={handleClose}
        />
      );
    }
    if (currentAuthStatus === authState.FORGET_PASSWORD) {
      return <ForgetPasswordModalContents />;
    }
  };

  return (
    <Modal2
      isOpen={isOpen}
      handleClose={handleClose}
      modalSize="420px"
      className="auth-modal"
      padding={false}
      closeClickOutside={true}>
      {targetChild()}
    </Modal2>
  );
};

export default AuthModal;
