import Form from 'interfaces/components/common/Form/Form';
import Modal2 from 'interfaces/components/common/Modal/Modal2';
import { useEffect, useState } from 'react';

import { validateInputsManagementUserForm } from './validateInputsManagementUserForm';
import { useSnackBar } from 'interfaces/components/Auth/kmanager/SnackBarProvider';

const ManagementUserFormModal = ({ closeModal, data = undefined }) => {
  const inputs = validateInputsManagementUserForm(data !== undefined);

  useEffect(() => {
    if (data) {
      inputs
        .find((object) => object.column === 'familyName')
        .init(data?.familyName);
      inputs
        .find((object) => object.column === 'firstName')
        .init(data?.firstName);
      inputs
        .find((object) => object.column === 'familyNameKana')
        .init(data?.familyNameKana);
      inputs
        .find((object) => object.column === 'firstNameKana')
        .init(data?.firstNameKana);
      inputs
        .find((object) => object.column === 'department')
        .init(data?.department);
      inputs
        .find((object) => object.column === 'officePosition')
        .init(data?.officePosition);
      inputs.find((object) => object.column === 'tel').init(data?.tel);
      inputs
        .find((object) => object.column === 'extension')
        .init(data?.extension);
      inputs.find((object) => object.column === 'email').init(data?.email);
      inputs
        .find((object) => object.column === 'userType')
        .init(data?.userType);
    }
  }, [data]);

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const notRequiredKeys = ['officePosition', 'extension'];
    const validationResults = inputs
      .filter((input) => input.validationMessage !== undefined)
      .map((input) => {
        const validationMessage = input.validationMessage;
        const column = input.column;
        const isValid = notRequiredKeys.includes(column)
          ? validationMessage === null || validationMessage === 'TRUE'
          : validationMessage === 'TRUE';
        return { column, validationMessage, isValid };
      });
    const allValid = validationResults.every(({ isValid }) => isValid);
    setIsValid(allValid);
  }, [inputs]);

  const { openSnackBar } = useSnackBar();

  const register = () => {
    console.log('todo: fix management user register process');
    closeModal();
    openSnackBar('作成しました');
  };

  const update = () => {
    console.log('todo: fix management user update process');
    closeModal();
    openSnackBar('更新しました');
  };

  const FORM_BUTTONS_DATA = [
    {
      text: '確定',
      clickHandler: data === undefined ? register : update,
      className: 'primary',
      disabled: !isValid,
    },
    {
      text: 'キャンセル',
      clickHandler: () => closeModal(),
      className: 'warning',
    },
  ];

  return (
    <Modal2 handleClose={closeModal} modalSize={'M'}>
      <div
        className="kmanager-container kmanager-notice-create-modal"
        data-testid="kmanager-notice-create-modal">
        <Form inputs={inputs} buttonData={FORM_BUTTONS_DATA} />
      </div>
    </Modal2>
  );
};

export default ManagementUserFormModal;
