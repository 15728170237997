const PopUpIconButton = ({ svg, label, handleClick }) => {
  return (
    <button onClick={handleClick} className="popup-icon popup-icon-button flex align-items-center" data-testid="popup-icon-button">
      <div className="popup-icon-svg">
        {svg}
      </div>
      <div className="popup-icon-label-container flex align-items-center flex-wrap-wrap">
        <span className="popup-icon-label">{label}</span>
      </div>
    </button>
  );
};

export default PopUpIconButton;
