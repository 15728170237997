// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.caution-topic-table {
  width: 100%;
  text-align: left;
}

.caution-topic-caption {
  padding: 12px 12px 12px 0;
  line-height: 1.375;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}

.caution-topic-caption-has-bg-color {
  padding-left: 12px;
  border: 1px solid var(--tborder);
  border-bottom: none;
  background-color: #fbe2ca;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/caution-topic-table.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gCAAgC;EAChC,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".caution-topic-table {\n  width: 100%;\n  text-align: left;\n}\n\n.caution-topic-caption {\n  padding: 12px 12px 12px 0;\n  line-height: 1.375;\n  text-align: left;\n  font-size: 16px;\n  font-weight: 700;\n}\n\n.caution-topic-caption-has-bg-color {\n  padding-left: 12px;\n  border: 1px solid var(--tborder);\n  border-bottom: none;\n  background-color: #fbe2ca;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
