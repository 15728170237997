import { useState } from 'react';

export function usePromotionEditInputs() {
  // 【KM】PromotionFrame
  const [theme, setTheme] = useState('');
  const [isDisplayDeadline, setIsDisplayDeadline] = useState('HIDDEN');
  const [displayDeadline, setDisplayDeadline] = useState('');
  const [displayOrder, setDisplayOrder] = useState('');
  const [activeStatus, setActiveStatus] = useState('ACTIVE');
  const [sourceType, setSourceType] = useState('INPUT_URL');

  // 【KM】PromotionProperty
  const [searchUrl, setSearchUrl] = useState('');
  const [source1, setSource1] = useState('');
  const [source2, setSource2] = useState('');
  const [source3, setSource3] = useState('');
  const [source4, setSource4] = useState('');
  const [source5, setSource5] = useState('');
  const [source6, setSource6] = useState('');
  const [source7, setSource7] = useState('');
  const [source8, setSource8] = useState('');
  const [source9, setSource9] = useState('');
  const [source10, setSource10] = useState('');
  const [source11, setSource11] = useState('');
  const [source12, setSource12] = useState('');
  const [source13, setSource13] = useState('');
  const [source14, setSource14] = useState('');
  const [source15, setSource15] = useState('');
  const [source16, setSource16] = useState('');
  const [source17, setSource17] = useState('');
  const [source18, setSource18] = useState('');

  const promotionEditInputs = {
    type: {
      // 【KM】PromotionFrame
      theme: 'text',
      isDisplayDeadline: 'radio',
      displayDeadline: 'datetime',
      displayOrder: 'select',
      activeStatus: 'radio',
      sourceType: 'radio',

      // 【KM】PromotionProperty
      searchUrl: 'text',
      source1: 'text',
      source2: 'text',
      source3: 'text',
      source4: 'text',
      source5: 'text',
      source6: 'text',
      source7: 'text',
      source8: 'text',
      source9: 'text',
      source10: 'text',
      source11: 'text',
      source12: 'text',
      source13: 'text',
      source14: 'text',
      source15: 'text',
      source16: 'text',
      source17: 'text',
      source18: 'text',
    },
    data: {
      // 【KM】PromotionFrame
      theme,
      isDisplayDeadline,
      displayDeadline,
      displayOrder,
      activeStatus,
      sourceType,

      // 【KM】PromotionProperty
      searchUrl,
      source1,
      source2,
      source3,
      source4,
      source5,
      source6,
      source7,
      source8,
      source9,
      source10,
      source11,
      source12,
      source13,
      source14,
      source15,
      source16,
      source17,
      source18,
    },
    setters: {
      // 【KM】PromotionFrame
      theme: setTheme,
      isDisplayDeadline: setIsDisplayDeadline,
      displayDeadline: setDisplayDeadline,
      displayOrder: setDisplayOrder,
      activeStatus: setActiveStatus,
      sourceType: setSourceType,

      // 【KM】PromotionProperty
      searchUrl: setSearchUrl,
      source1: setSource1,
      source2: setSource2,
      source3: setSource3,
      source4: setSource4,
      source5: setSource5,
      source6: setSource6,
      source7: setSource7,
      source8: setSource8,
      source9: setSource9,
      source10: setSource10,
      source11: setSource11,
      source12: setSource12,
      source13: setSource13,
      source14: setSource14,
      source15: setSource15,
      source16: setSource16,
      source17: setSource17,
      source18: setSource18,
    },
  };

  return promotionEditInputs;
}
