
export class MailTemplateText {
    #mailTemplateText

    constructor(mailTemplateText) {
        this.#mailTemplateText = mailTemplateText;
    }

    get() {
        return this.#mailTemplateText
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
