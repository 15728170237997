import Banner from 'interfaces/components/Banner/Banner';

const KmanagerBanner = () => {
  return (
    <div className="kmanager-banner" data-testid="kmanager-banner">
      <h2 className="my-5 pl-5">バナー設定ツール</h2>
      <Banner />
    </div>
  );
};
export default KmanagerBanner;
