import axios from 'axios';
import pino from 'pino';

import camelizeKeys from './camelize.js';

const logger = pino();

axios.interceptors.request.use(
  (config) => {
    // MEMO: リクエスト時の共通処理を書くことができる
    logger.info('Place order start');

    // デフォルトのヘッダー
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    // MEMO: 共通のエラーハンドリングを定義できる(＊個別のエラーハンドリングは必要)
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    logger.info(`API response`, response);
    // MEMO: responseに対する共通処理を書くことができる
    return camelizeKeys(response);
  },
  (error) => {
    // MEMO: 共通のエラーハンドリングを定義できる(＊個別のエラーハンドリングは必要)
    return Promise.reject(error);
  },
);
