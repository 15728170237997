import { useState, useMemo } from 'react';

export const useSortableData = (
  items,
  config = { key: null, direction: 'ascending' },
) => {
  const [sortConfig, setSortConfig] = useState(config);
  const sortedItems = useMemo(() => {
    if (sortConfig.key) {
      return [...items].sort((a, b) => {
        const aValue = isNaN(a[sortConfig.key])
          ? a[sortConfig.key]
          : parseFloat(a[sortConfig.key]);
        const bValue = isNaN(b[sortConfig.key])
          ? b[sortConfig.key]
          : parseFloat(b[sortConfig.key]);

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return items;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
