import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { findById as getCreditCardPages } from '../../../../domain/Setting/services/CreditCardPage.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { LocalGovernmentManagementCreditContext } from '../entities/LocalGovernmentManagementCreditContext.js';

const LocalGovernmentManagementCreditProvider = ({ type, children }) => {
  const { governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [creditCardPages, setCreditCardPages] = useState([]);
  const { localStorageGovernmentId } = useLocalStorage();
  const { governmentId } = useParams();

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getCreditCardPages(type, governmentId).then((result) => {
        setCreditCardPages(result);
      });
    }, [governmentId]);
  }

  if (type === NAVI) {
    useEffect(() => {
      getCreditCardPages(type, localStorageGovernmentId).then((result) => {
        setCreditCardPages(result);
      });
    }, [localStorageGovernmentId]);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getCreditCardPages(type).then((result) => {
        setCreditCardPages(result);
      });
    }, []);
  }

  return (
    <LocalGovernmentManagementCreditContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        creditCardPages,
      }}>
      {children}
    </LocalGovernmentManagementCreditContext.Provider>
  );
};

export default LocalGovernmentManagementCreditProvider;
