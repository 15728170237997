import { DATA_EMPTY } from 'domain/Property/entities/constants/property';
import './css/after-winning-table-default.css';

const AfterWinningBidTable = ({ title, data }) => {
  return (
    <table
      data-testid="after-winning-bid-table"
      className="common-table-default after-winning-bid-text">
      <caption data-testid="after-winning-bid-table-title">{title}</caption>
      <tbody>
        {data?.map((data) => {
          return (
            data.value !== undefined &&
            data.value !== '' &&
            data.value !== DATA_EMPTY && (
              <tr key={data.id} data-testid="sub-title">
                <th>{data.title}</th>
                <td className="word-wrap">{data.value}</td>
              </tr>
            )
          );
        })}
      </tbody>
    </table>
  );
};
export default AfterWinningBidTable;
