import { DATA_EMPTY } from 'domain/Property/entities/constants/property.js';

import { useApplicantPropertyIds } from 'interfaces/components/Property/service/PropertyCard/hooks/useApplicantPropertyIds.js';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useBidInfo } from 'shared/hooks/useBidInfo.js';
import { useProposals } from 'shared/hooks/useProposals.js';
import {
  queryParamToAuctionType,
  queryParamToProcedureNo,
} from 'shared/utils/constants/mylist_search_conditions.js';
import {
  FILTER_AUCTION_PROGRESS_STATUS,
  FILTER_AUCTION_RESULT_STATUS,
  FILTER_AUCTION_TYPE,
  FILTER_DEPOSIT,
  FILTER_IMPLEMENTAL_METHOD,
} from 'shared/utils/constants/queryParam.js';

//import useMembershipId from './useMembershipId.js';
import { useMyUserInfo } from './useMyUserInfo.js';
import {
  filteringAuctionProgressStatus,
  filteringAuctionResultStatus,
  filteringMyListDeposit,
} from '../helper/filtering_properties.js';


export const useMyListQueryParamChangeProcessing = (
  properties,
  setDisplayProperties,
) => {
  const [searchParam] = useSearchParams();
  const bidInfo = useBidInfo();
  const applicantPropertyIds = useApplicantPropertyIds(3);
  const proposals = useProposals(3);
  const { myUserInfo } = useMyUserInfo(3);
  //const { membershipId } = useMembershipId(3);
  const membershipId = 3;

  const filterAuctionType = searchParam.get(FILTER_AUCTION_TYPE) ?? DATA_EMPTY;
  const filterImplementalMethod =
    searchParam.get(FILTER_IMPLEMENTAL_METHOD) ?? DATA_EMPTY;
  const filterDeposit = searchParam.get(FILTER_DEPOSIT) ?? DATA_EMPTY;
  const filterAuctionResultStatus =
    searchParam.get(FILTER_AUCTION_RESULT_STATUS) ?? DATA_EMPTY;
  const filterAuctionProgressStatus =
    searchParam.get(FILTER_AUCTION_PROGRESS_STATUS) ?? DATA_EMPTY;

  useEffect(() => {
    if (filterAuctionType !== DATA_EMPTY && filterAuctionType !== '') {
      properties = properties.filter(
        (item) =>
          item.data.publicSaleType ===
          queryParamToAuctionType[filterAuctionType],
      );
    }

    if (
      filterImplementalMethod !== DATA_EMPTY &&
      filterImplementalMethod !== ''
    ) {
      properties = properties.filter(
        (item) =>
          item.data.procedureType ===
          queryParamToProcedureNo[filterImplementalMethod],
      );
    }

    if (filterDeposit !== DATA_EMPTY && filterDeposit !== '') {
      properties = filteringMyListDeposit(properties, filterDeposit);
    }

    if (
      filterAuctionResultStatus !== DATA_EMPTY &&
      filterAuctionResultStatus !== ''
    ) {
      properties = filteringAuctionResultStatus(
        properties,
        filterAuctionResultStatus,
        bidInfo,
        membershipId,
      );
    }

    if (
      filterAuctionProgressStatus !== DATA_EMPTY &&
      filterAuctionProgressStatus !== ''
    ) {
      properties = filteringAuctionProgressStatus(
        properties,
        filterAuctionProgressStatus,
        applicantPropertyIds,
        proposals,
        bidInfo,
        myUserInfo,
      );
    }

    setDisplayProperties(properties);
  }, [properties, searchParam]);
};
