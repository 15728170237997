
export class IsMigratory {
  #isMigratory

  constructor(isMigratory) {
    this.#isMigratory = isMigratory;
  }

  get() {
    return this.#isMigratory
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
