import styled from 'styled-components';

import NewsList from './NewsList';
import { mediaQuery, useMediaQuery } from 'shared/hooks/useResponsive.jsx';

import 'interfaces/css/News/news.css';

const NewsBox = styled.div`
  max-width: 1200px;
  width: 90%;
`;

const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  padding-top: 24px;
`;

const TitleHr = styled.hr`
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0px;
  max-width: 100%;
  height: 0;
  max-height: 0;
  border: solid 1px #e8e6e6;
  border-width: thin 0 0;
  -webkit-transition: inherit;
  transition: inherit;
`;

const News = () => {
  const isPc = useMediaQuery(mediaQuery.pc);

  return (
    <NewsBox className={`mx-auto ${!isPc ? 'px-4' : ''} kservice_news-body`}>
      <div className="news-body pb-10">
        <div
          className="page-title mb-4"
          style={{ display: 'block', paddingBottom: '0' }}>
          <PageTitle>お知らせ</PageTitle>
          <TitleHr />
        </div>
        <NewsList />
      </div>
    </NewsBox>
  );
};

export default News;
