import axios from 'axios';
import pino from "pino";

import {API_BASE_URL} from "../../config/envConstants.js";
import { extractData } from "../../shared/utils/helper/extractData.js";
import { fetchData } from "../../shared/utils/helper/fetchData.js";

export const fetchNoticesData = async () => {
  return await axios.get(
    `${API_BASE_URL}/api/v1/management/notices`,
  );
};
export const createNoticesData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/notices`,
    body, { headers: headers }
  );

};
export const updateNoticesData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/notices/${id}`,
    body, { headers: headers }
  );
};
export const deleteNoticesData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/management/notices/${id}`, {
      headers: headers,
    },);
};

const logger = pino();

export const fetchNoticesStorage = async () => {
  try {
    const response = await fetchData(`${process.env.REACT_APP_BASE_URL_NAVIGATION}/storage/current/navi/notices/notices.json`);
    return extractData(response);
  } catch (error) {
    logger.warn({ error }, "現在のメッセージ一覧（ notices ）を取得できませんでした");

    try {
      const response = await fetchData(`${process.env.REACT_APP_BASE_URL_NAVIGATION}/storage/previous/navi/notices/notices.json`);
      return extractData(response);
    } catch (error) {
      logger.error({ error }, "以前のメッセージ一覧（ notices ）の取得にも失敗しました");
      return [];
    }
  }
};
