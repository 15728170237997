import MyPageTable from 'interfaces/components/MyPage/MyPageTable';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//import { useAuth } from '../../hooks/useAuth.js';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

import 'interfaces/css/MyPage/mypage.css';

const MyPage = () => {
  //const { userId } = useAuth();
  const { localStorageUserId, localStorageLoading } = useLocalStorage();
  const navigation = useNavigate();

  useEffect(() => {
    if (!localStorageLoading) {
      if (!localStorageUserId) {
        navigation('/');
      }
    }
  }, [localStorageUserId, localStorageLoading]);

  return (
    <div className="mypage-body kservice_mypage">
      <div className="pt-6 pb-10">
        <div className="default-title mb-4">
          <h1 className="pb-2">マイページ</h1>
          <hr className="divider" />
        </div>
        <MyPageTable />
      </div>
    </div>
  );
};

export default MyPage;
