import { useState, useEffect, useCallback } from 'react';

import { useError } from './useError.js';

export const useLocalStorage = () => {
  const { setError } = useError();
  const [localStorageUserId, setUserId] = useState('');
  const [localStorageProcedureType, setProcedureType] = useState('');
  const [localStorageGovernmentId, setGovernmentId] = useState('');
  const [localStorageGovernmentType, setGovernmentType] = useState('');
  const [localStorageRequestAccessToken, setLocalStorageRequestAccessToken] =
    useState(true);
  const [localStorageRequestRefreshToken, setLocalStorageRequestRefreshToken] =
    useState(true);
  const [localStorageLoading, setLocalStorageLoading] = useState(true);

  const getLocalStorage = useCallback(async () => {
    setLocalStorageLoading(true);
    try {
      let userId = null;
      if (localStorage.getItem('auctionUserId') != null) {
        userId = localStorage.getItem('auctionUserId');
      } else if (
        localStorage.getItem('naviUserId') != null &&
        localStorage.getItem('governmentId') != null
      ) {
        userId = localStorage.getItem('naviUserId');
      } else if (localStorage.getItem('kmanagerUserId') != null) {
        userId = localStorage.getItem('kmanagerUserId');
      }
      setUserId(userId);
      const procedureType = localStorage.getItem('procedureType');
      setProcedureType(procedureType);
      const governmentId = localStorage.getItem('governmentId');
      setGovernmentId(governmentId);
      const governmentType = localStorage.getItem('governmentType');
      setGovernmentType(governmentType);
      const requestAccessToken = localStorage.getItem('requestAccessToken');
      setLocalStorageRequestAccessToken(requestAccessToken);
      const requestRefreshToken = localStorage.getItem('requestRefreshToken');
      setLocalStorageRequestRefreshToken(requestRefreshToken);
    } catch (error) {
      setError(error);
    } finally {
      setLocalStorageLoading(false);
    }
  });

  useEffect(() => {
    getLocalStorage();
  }, [getLocalStorage]);

  return {
    localStorageUserId,
    localStorageProcedureType,
    localStorageGovernmentId,
    localStorageGovernmentType,
    localStorageRequestAccessToken,
    localStorageRequestRefreshToken,
    localStorageLoading,
    getLocalStorage,
  };
};
