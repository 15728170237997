// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createNaviAssetSaleCautionPagesData,
  deleteNaviAssetSaleCautionPagesData,
  updateNaviAssetSaleCautionPagesData,
} from '../../../infrastructure/api/NaviAssetSaleCautionPages.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  ASSET_SALE,
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { SettingAssetCautionPageCreate } from '../entities/Setting.js';
import { getCaution } from '../repositories/Caution.js';

export async function get(type) {
  return await getCaution(type);
}

export async function findByGovernmentId(type, id) {
  const AssetCaution = await getCaution(type, ASSET_SALE);
  return AssetCaution.filter(
    (data) => data.governmentId.toString() === id.toString(),
  );
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (AssetCaution)');
  }
  if (type === NAVI) {
    const assetSaleCaution = new SettingAssetCautionPageCreate(data);
    response = await createNaviAssetSaleCautionPagesData(assetSaleCaution);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (AssetCaution)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create AssetCaution', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (AssetCaution)');
  }
  if (type === NAVI) {
    const assetSaleCaution = new SettingAssetCautionPageCreate(data);
    response = await updateNaviAssetSaleCautionPagesData(assetSaleCaution);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (AssetCaution)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update AssetCaution', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (AssetCaution)');
  }
  if (type === NAVI) {
    response = await deleteNaviAssetSaleCautionPagesData(data.id);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (AssetCaution)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete AssetCaution', response);
  }
  return response;
}

// function createAssetCautionArray(data) {
//   let settings = []
//   data.forEach((setting) => {
//     settings.push(new AssetCaution(setting))
//   })
//   return settings
// }

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
