import { useState } from 'react';

import ParentCategories from './ParentCategories.jsx';
import 'interfaces/css/common/category.css';

const SearchCategoryMenu = ({
  isOpen,
  showCheckBox,
  showCount,
  propertyCategories = [],
}) => {
  const [isSeeAll, setIsSeeAll] = useState(isOpen);

  const handleClick = () => {
    setIsSeeAll(!isSeeAll);
  };

  return (
    <div className="category">
      <ParentCategories
        isSeeAll={isSeeAll}
        showCheckBox={showCheckBox}
        showCount={showCount}
        propertyCategories={propertyCategories}
      />
      <div className="see-all-toggle" onClick={handleClick}>
        {isSeeAll ? <a>{'> 閉じる'}</a> : <a>{'> すべて見る'}</a>}
      </div>
    </div>
  );
};

export default SearchCategoryMenu;
