
export class BodyColor {
    #bodyColor

    constructor(bodyColor) {
        this.#bodyColor = bodyColor;
    }

    get() {
        return this.#bodyColor
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
