import { ReactComponent as ArrowRightIcon } from 'interfaces/assets/svg/arrow_back_icon.svg';
import BackButton from 'interfaces/components/common/BackButton';
import GovernmentFilter from 'interfaces/components/common/GovernmentFilter';
import PageTitle from 'interfaces/components/common/PageTitle';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//import { AreaToJP } from "shared/utils/helper/convert/areaToJp";
import GovernmentSettingTable from './GovernmentSettingTable';
import { AuctionGovernmentSettingsContext } from '../../../../pages/AuctionGovernmentSettings/entities/AuctionGovernmentSettingsContext';
import AuctionSwitcher from 'interfaces/components/common/Switcher/AuctionSwitcher';

import Toolbar from 'interfaces/components/common/Toolbar';

const TOP_PATH = '/kmanager';

const KmanagerGovernmentSettings = () => {
  const navigation = useNavigate();

  const { auction, governmentSettings } = useContext(
    AuctionGovernmentSettingsContext,
  );
  const [filteredGovernmentSettings, setFilteredGovernmentSettings] =
    useState(governmentSettings);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setFilteredGovernmentSettings(
      governmentSettings.filter((governmentSetting) =>
        governmentSetting.name.includes(searchInput),
      ),
    );
  }, [searchInput, governmentSettings]);

  return (
    <div
      className="kmanager-government-settings"
      data-testid="kmanager-government-settings">
      <div className="kmanager-row">
        <div className="kmanager-col kmanager-col-6">
          <BackButton
            text="オークション実施一覧"
            svg={<ArrowRightIcon title="back-to-page" />}
            handleClick={() => navigation(TOP_PATH)}
          />
          <PageTitle text={auction?.name} />
          <Toolbar>
            <AuctionSwitcher
              id={auction.id}
              activeIndex={0}
              auctionGovernmentTypeGroup={auction?.governmentTypeGroup}
            />
          </Toolbar>
        </div>
        <div className="kmanager-col kmanager-col-6">
          <GovernmentFilter
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
        </div>
        <div className="kmanager-col kmanager-col-12">
          <div className="box-shadow">
            {/* error?.code && <PageTitle text={error.message}/> */}
            <GovernmentSettingTable
              auction={auction}
              governmentSettings={filteredGovernmentSettings}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KmanagerGovernmentSettings;
