// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vertical-link-group {
  margin-right: 40px;
  margin-left: 40px;
}

.vertical-link-group-items {
  display: block;
  padding: 8px 0;
  position: static;
}

.vertical-link-group-item {
  margin-bottom: 4px;
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  outline: none;
  position: relative;
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/vertical-link-group.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,cAAc;EACd,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,qBAAqB;AACvB","sourcesContent":[".vertical-link-group {\n  margin-right: 40px;\n  margin-left: 40px;\n}\n\n.vertical-link-group-items {\n  display: block;\n  padding: 8px 0;\n  position: static;\n}\n\n.vertical-link-group-item {\n  margin-bottom: 4px;\n  align-items: center;\n  display: flex;\n  flex: 1 1 100%;\n  letter-spacing: normal;\n  outline: none;\n  position: relative;\n  text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
