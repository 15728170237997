import {
  validateRangeLength,
  hasEmoji,
} from '../../../../application/validations.js';

export class InvoiceWrittenDestination {
  #invoiceWrittenDestination;

  constructor(invoiceWrittenDestination) {
    this.#invoiceWrittenDestination = invoiceWrittenDestination;
  }

  get() {
    return this.#invoiceWrittenDestination;
  }

  get isValid() {
    return [this.validateRangeLength, this.hasEmoji].every(
      (result) => result === 'TRUE',
    );
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#invoiceWrittenDestination, 1, 50);
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#invoiceWrittenDestination);
  }
}
