import Heading3 from 'interfaces/components/common/Heading/Heading3';
import AfterWinningBidCautionContactFrom from 'interfaces/components/LocalGovernmentManagement/AfterWinningBidCautionContactFrom';
import {
  after_winning_bid_caution_description_1,
  after_winning_bid_caution_description_3,
  after_winning_bid_caution_description_9,
  after_winning_bid_title1,
  after_winning_bid_caution_description_10,
  after_winning_bid_caution_description_11,
} from 'shared/utils/helper/DummyAfterWinningBid';

import AuctionAfterWinningAssetSaleBidCautionFormRequireDocument from './AuctionAfterWinningAssetSaleBidCautionFormRequireDocument';
import FormTextAreaWithCaution from '../../../Form/navi/FormTextAreaWithCaution';
import FormTextWithCaution from '../../../Form/navi/FormTextWithCaution';
import {
  after_winning_bid_caution_description_12,
  after_winning_bid_caution_description_15,
  after_winning_bid_caution_description_16,
} from '../../../../../shared/utils/helper/DummyAfterWinningBid';

const BothForm = ({ validations }) => {
  return (
    <form data-testid="both-form">
      <div className="pt-9">
        <Heading3 text="1. 権利移転手続き" />
        <div className="property-info-general-form-table">
          <FormTextAreaWithCaution
            label={'権利移転手続きについての説明'}
            maxCharacters={300}
            caution_description={after_winning_bid_caution_description_1}
            data={validations.data.auctionTransRightDescription}
            handler1={validations.setters.handleAuctionTransRightDescription}
            invalidMessage={
              validations.validationMessage.auctionManagementTransValid
            }
          />
        </div>

        <div className="pt-9">
          <Heading3 text="必要な費用" />
        </div>
        <div className="auction-cost-table">
          <div>
            <Heading3 text="■ 契約締結期限までに" />
            <div className="property-info-general-form-table">
              <FormTextWithCaution
                label={'タイトル1'}
                maxCharacters={50}
                caution_description={after_winning_bid_title1}
                data={validations.data.auctionCostContractTitle1}
                handler1={validations.setters.handleAuctionCostContractTitle1}
                invalidMessage={
                  validations.validationMessage.auctionCostContractTitle1Valid
                }
              />
              <FormTextAreaWithCaution
                label={'説明1'}
                maxCharacters={500}
                caution_description={after_winning_bid_caution_description_9}
                data={validations.data.auctionCostContractDescription1}
                handler1={
                  validations.setters.handleAuctionCostContractDescription1
                }
                invalidMessage={
                  validations.validationMessage
                    .auctionCostContractDescription1Valid
                }
              />
              <FormTextWithCaution
                label={'タイトル2'}
                maxCharacters={50}
                caution_description={after_winning_bid_title1}
                data={validations.data.auctionCostContractTitle2}
                handler1={validations.setters.handleAuctionCostContractTitle2}
                invalidMessage={
                  validations.validationMessage.auctionCostContractTitle2Valid
                }
              />
              <FormTextAreaWithCaution
                label={'説明2'}
                maxCharacters={500}
                caution_description={after_winning_bid_caution_description_9}
                data={validations.data.auctionCostContractDescription2}
                handler1={
                  validations.setters.handleAuctionCostContractDescription2
                }
                invalidMessage={
                  validations.validationMessage
                    .auctionCostContractDescription2Valid
                }
              />
              <FormTextWithCaution
                label={'タイトル3'}
                maxCharacters={50}
                caution_description={after_winning_bid_title1}
                data={validations.data.auctionCostContractTitle3}
                handler1={validations.setters.handleAuctionCostContractTitle3}
                invalidMessage={
                  validations.validationMessage.auctionCostContractTitle3Valid
                }
              />
              <FormTextAreaWithCaution
                label={'説明3'}
                maxCharacters={500}
                caution_description={after_winning_bid_caution_description_9}
                data={validations.data.auctionCostContractDescription3}
                handler1={
                  validations.setters.handleAuctionCostContractDescription3
                }
                invalidMessage={
                  validations.validationMessage
                    .auctionCostContractDescription3Valid
                }
              />
            </div>
            <div className="pt-9"></div>
            <Heading3 text="■ 代金納付期限までに" />
            <div className="property-info-general-form-table">
              <FormTextWithCaution
                label={'タイトル1'}
                maxCharacters={50}
                caution_description={after_winning_bid_title1}
                data={validations.data.auctionCostPaymentTitle1}
                handler1={validations.setters.handleAuctionCostPaymentTitle1}
                invalidMessage={
                  validations.validationMessage.auctionCostPaymentTitle1Valid
                }
              />
              <FormTextAreaWithCaution
                label={'説明1'}
                maxCharacters={500}
                caution_description={after_winning_bid_caution_description_9}
                data={validations.data.auctionCostPaymentDescription1}
                handler1={
                  validations.setters.handleAuctionCostPaymentDescription1
                }
                invalidMessage={
                  validations.validationMessage
                    .auctionCostPaymentDescription1Valid
                }
              />
              <FormTextWithCaution
                label={'タイトル2'}
                maxCharacters={50}
                caution_description={after_winning_bid_title1}
                data={validations.data.auctionCostPaymentTitle2}
                handler1={validations.setters.handleAuctionCostPaymentTitle2}
                invalidMessage={
                  validations.validationMessage.auctionCostPaymentTitle2Valid
                }
              />
              <FormTextAreaWithCaution
                label={'説明2'}
                maxCharacters={500}
                caution_description={after_winning_bid_caution_description_9}
                data={validations.data.auctionCostPaymentDescription2}
                handler1={
                  validations.setters.handleAuctionCostPaymentDescription2
                }
                invalidMessage={
                  validations.validationMessage
                    .auctionCostPaymentDescription2Valid
                }
              />
              <FormTextWithCaution
                label={'タイトル3'}
                maxCharacters={50}
                caution_description={after_winning_bid_title1}
                data={validations.data.auctionCostPaymentTitle3}
                handler1={validations.setters.handleAuctionCostPaymentTitle3}
                invalidMessage={
                  validations.validationMessage.auctionCostPaymentTitle3Valid
                }
              />
              <FormTextAreaWithCaution
                label={'説明3'}
                maxCharacters={500}
                caution_description={after_winning_bid_caution_description_9}
                data={validations.data.auctionCostPaymentDescription3}
                handler1={
                  validations.setters.handleAuctionCostPaymentDescription3
                }
                invalidMessage={
                  validations.validationMessage
                    .auctionCostPaymentDescription3Valid
                }
              />
              <FormTextWithCaution
                label={'タイトル4'}
                maxCharacters={50}
                caution_description={after_winning_bid_title1}
                data={validations.data.auctionCostPaymentTitle4}
                handler1={validations.setters.handleAuctionCostPaymentTitle4}
                invalidMessage={
                  validations.validationMessage.auctionCostPaymentTitle4Valid
                }
              />
              <FormTextAreaWithCaution
                label={'説明4'}
                maxCharacters={500}
                caution_description={after_winning_bid_caution_description_9}
                data={validations.data.auctionCostPaymentDescription4}
                handler1={
                  validations.setters.handleAuctionCostPaymentDescription4
                }
                invalidMessage={
                  validations.validationMessage
                    .auctionCostPaymentDescription4Valid
                }
              />
              <FormTextWithCaution
                label={'タイトル5'}
                maxCharacters={50}
                caution_description={after_winning_bid_title1}
                data={validations.data.auctionCostPaymentTitle5}
                handler1={validations.setters.handleAuctionCostPaymentTitle5}
                invalidMessage={
                  validations.validationMessage.auctionCostPaymentTitle5Valid
                }
              />
              <FormTextAreaWithCaution
                label={'説明5'}
                maxCharacters={500}
                caution_description={after_winning_bid_caution_description_9}
                data={validations.data.auctionCostPaymentDescription5}
                handler1={
                  validations.setters.handleAuctionCostPaymentDescription5
                }
                invalidMessage={
                  validations.validationMessage
                    .auctionCostPaymentDescription5Valid
                }
              />
            </div>
          </div>
        </div>
        <div className="property-info-general-form-table pt-0_i">
          <FormTextAreaWithCaution
            label={'ご注意'}
            maxCharacters={500}
            caution_description={after_winning_bid_caution_description_3}
            data={validations.data.auctionCostAttention}
            handler1={validations.setters.handleAuctionCostAttention}
            invalidMessage={
              validations.validationMessage.auctionCostAttentionValid
            }
          />
        </div>

        <div className="pt-9">
          <Heading3 text="必要な書類" />
          <AuctionAfterWinningAssetSaleBidCautionFormRequireDocument
            label={'必要な書類の案内ページ'}
            validations={validations}
          />
          <div className="after-winning-bid-caution-form-require-document-table">
            <FormTextAreaWithCaution
              label={'説明'}
              maxCharacters={1000}
              caution_description={after_winning_bid_caution_description_11}
              data={validations.data.auctionDocumentDescription}
              handler1={validations.setters.handleAuctionDocumentDescription}
              invalidMessage={
                validations.validationMessage.auctionDocumentDescriptionValid
              }
            />
          </div>
        </div>

        <div className="pt-9">
          <Heading3 text="落札者（落札者が法人の場合は代表者）以外の方が権利移転手続きを行う場合" />
          <div className="property-info-general-form-table">
            <FormTextAreaWithCaution
              label={'説明'}
              maxCharacters={500}
              caution_description={after_winning_bid_caution_description_9}
              data={validations.data.auctionOtherTransRightDescription}
              handler1={
                validations.setters.handleAuctionOtherTransRightDescription
              }
              invalidMessage={
                validations.validationMessage
                  .auctionOtherTransRightDescriptionValid
              }
            />
            <FormTextAreaWithCaution
              label={'ご注意'}
              maxCharacters={500}
              caution_description={after_winning_bid_caution_description_10}
              data={validations.data.auctionOtherTransRightAttention}
              handler1={
                validations.setters.handleAuctionOtherTransRightAttention
              }
              invalidMessage={
                validations.validationMessage
                  .auctionOtherTransRightAttentionValid
              }
            />
          </div>
        </div>

        <div className="pt-9">
          <Heading3 text="権利移転の時期と要する期間" />
          <div className="property-info-general-form-table">
            <FormTextAreaWithCaution
              label={'権利移転の時期'}
              maxCharacters={500}
              caution_description={after_winning_bid_caution_description_15}
              data={validations.data.auctionTransRightDate}
              handler1={validations.setters.handleAuctionTransRightDate}
              invalidMessage={
                validations.validationMessage.auctionTransRightDateValid
              }
            />
            <FormTextAreaWithCaution
              label={'登記手続き完了までの期間'}
              maxCharacters={500}
              caution_description={after_winning_bid_caution_description_16}
              data={validations.data.auctionTransRightCompletedTime}
              handler1={
                validations.setters.handleAuctionTransRightCompletedTime
              }
              invalidMessage={
                validations.validationMessage
                  .auctionTransRightCompletedTimeValid
              }
            />
          </div>
        </div>

        <div className="pt-9">
          <Heading3 text="2. 重要事項" />
          <div className="property-info-general-form-table">
            <FormTextAreaWithCaution
              label={'危険負担'}
              maxCharacters={1000}
              caution_description={after_winning_bid_caution_description_11}
              data={validations.data.auctionImportantRiskBearing}
              handler1={validations.setters.handleAuctionImportantRiskBearing}
              invalidMessage={
                validations.validationMessage.auctionImportantRiskBearingValid
              }
            />
            <FormTextAreaWithCaution
              label={'引き渡し条件'}
              maxCharacters={1000}
              caution_description={after_winning_bid_caution_description_11}
              data={validations.data.auctionImportantTermsDelivery}
              handler1={validations.setters.handleAuctionImportantTermsDelivery}
              invalidMessage={
                validations.validationMessage.auctionImportantTermsDeliveryValid
              }
            />
            <FormTextAreaWithCaution
              label={'入札保証金の取り扱い'}
              maxCharacters={1000}
              caution_description={after_winning_bid_caution_description_11}
              data={validations.data.auctionImportantHandlingBidDeposit}
              handler1={
                validations.setters.handleAuctionImportantHandlingBidDeposit
              }
              invalidMessage={
                validations.validationMessage
                  .auctionImportantHandlingBidDepositValid
              }
            />
            <FormTextAreaWithCaution
              label={'契約保証金の取り扱い'}
              maxCharacters={1000}
              caution_description={after_winning_bid_caution_description_11}
              data={validations.data.auctionImportantHandlingContractDeposit}
              handler1={
                validations.setters
                  .handleAuctionImportantHandlingContractDeposit
              }
              invalidMessage={
                validations.validationMessage
                  .auctionImportantHandlingContractDepositValid
              }
            />
            <FormTextAreaWithCaution
              label={'使用用途の制限'}
              maxCharacters={1000}
              caution_description={after_winning_bid_caution_description_11}
              data={validations.data.auctionImportantRestrictionUse}
              handler1={
                validations.setters.handleAuctionImportantRestrictionUse
              }
              invalidMessage={
                validations.validationMessage
                  .auctionImportantRestrictionUseValid
              }
            />
            <FormTextAreaWithCaution
              label={'移転登記前の使用制限'}
              maxCharacters={1000}
              caution_description={after_winning_bid_caution_description_11}
              data={validations.data.auctionImportantRestrictionUseBefore}
              handler1={
                validations.setters.handleAuctionImportantRestrictionUseBefore
              }
              invalidMessage={
                validations.validationMessage
                  .auctionImportantRestrictionUseBeforeValid
              }
            />
            <FormTextWithCaution
              label={'その他1'}
              maxCharacters={50}
              caution_description={after_winning_bid_title1}
              data={validations.data.auctionImportantOther1}
              handler1={validations.setters.handleAuctionImportantOther1}
              invalidMessage={
                validations.validationMessage.auctionImportantOther1Valid
              }
            />
            <FormTextAreaWithCaution
              label={'説明1'}
              maxCharacters={1000}
              caution_description={after_winning_bid_caution_description_11}
              data={validations.data.auctionImportantDescription1}
              handler1={validations.setters.handleAuctionImportantDescription1}
              invalidMessage={
                validations.validationMessage.auctionImportantDescription1Valid
              }
            />
            <FormTextWithCaution
              label={'その他2'}
              maxCharacters={50}
              caution_description={after_winning_bid_title1}
              data={validations.data.auctionImportantOther2}
              handler1={validations.setters.handleAuctionImportantOther2}
              invalidMessage={
                validations.validationMessage.auctionImportantOther2Valid
              }
            />
            <FormTextAreaWithCaution
              label={'説明2'}
              maxCharacters={1000}
              caution_description={after_winning_bid_caution_description_11}
              data={validations.data.auctionImportantDescription2}
              handler1={validations.setters.handleAuctionImportantDescription2}
              invalidMessage={
                validations.validationMessage.auctionImportantDescription2Valid
              }
            />
            <FormTextWithCaution
              label={'その他3'}
              maxCharacters={50}
              caution_description={after_winning_bid_title1}
              data={validations.data.auctionImportantOther3}
              handler1={validations.setters.handleAuctionImportantOther3}
              invalidMessage={
                validations.validationMessage.auctionImportantOther3Valid
              }
            />
            <FormTextAreaWithCaution
              label={'説明3'}
              maxCharacters={1000}
              caution_description={after_winning_bid_caution_description_11}
              data={validations.data.auctionImportantDescription3}
              handler1={validations.setters.handleAuctionImportantDescription3}
              invalidMessage={
                validations.validationMessage.auctionImportantDescription3Valid
              }
            />
            <FormTextAreaWithCaution
              label={'ご注意'}
              maxCharacters={1000}
              caution_description={after_winning_bid_caution_description_12}
              data={validations.data.auctionImportantAttention}
              handler1={validations.setters.handleAuctionImportantAttention}
              invalidMessage={
                validations.validationMessage.auctionImportantAttentionValid
              }
            />
          </div>
        </div>
        <AfterWinningBidCautionContactFrom validations={validations} />
      </div>
    </form>
  );
};

export default BothForm;
