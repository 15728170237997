
export class SummaryFirstRegisterDate {
    #summaryFirstRegisterDate

    constructor(summaryFirstRegisterDate) {
        this.#summaryFirstRegisterDate = summaryFirstRegisterDate;
    }

    get() {
        return this.#summaryFirstRegisterDate
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
