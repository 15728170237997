import 'interfaces/css/Dashboard/kmanager-auction-table-record.css';
import { ProcedureTypes } from 'shared/utils/constants/government';
import { dateFormat } from 'shared/utils/helper/date_format';
const GovernmentDepositTableRecord = ({ auction, property }) => {
  return (
    <tr
      className="kmanager-auction-table-record"
      data-testid="kmanager-auction-table-record">
      <td data-testid="kmanager-auction-table-td">
        <div>{property?.id}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{property?.saleNumber}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{property?.name}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{dateFormat(property?.cancelDate, 'YYYY-MM-DD-hh-mm')}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{property.divisionName}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{property.deposit.toLocaleString()}円</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        {property.paymentOnlineCount}
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{property.paymentScheduledPrice.toLocaleString()}円</div>
      </td>
      {auction.procedureType !== ProcedureTypes.ASSET_SALE.string && (
        <td data-testid="kmanager-auction-table-td">
          <div>{property.restitutionScheduledPrice.toLocaleString()}円</div>
        </td>
      )}
    </tr>
  );
};

export default GovernmentDepositTableRecord;
