import { ReactComponent as CheckBoxOutline } from "../../assets/svg/checkbox-blank-outline.svg";
import { ReactComponent as CheckBoxMarked } from "../../assets/svg/checkbox-marked.svg";
import "../../../interfaces/css/common/checkbox.css";

const ManagedCheckBox = ({
  id,
  name = "",
  handleCheckboxChange,
  data = false,
  showMessage
}) => {

  const clickHandler = (e) => {
    handleCheckboxChange(e)
  }

  return (
    <div className="input-checkbox" data-testid="check-box-managed">
      <div className="input-checkbox-control">
        <div className="input-checkbox-slot">
          <div className="selection-controls-input">
            <span className={`icon ${data ? "checked" : ""}`} data-testid='check-box-icon-wrap'>
              {
                data ? <CheckBoxMarked title='check-box-marked'/> :
                  <CheckBoxOutline title='check-box-outline'/>
              }
            </span>
            <input
              role="checkbox"
              type="checkbox"
              id={id}
              name={name}
              onChange={(e) => clickHandler(e)}
              checked={data}
            />
          </div>
        </div>
        {
          showMessage &&
          <div className="messages" data-testid='messages'>
            <div className="messages-wrap"></div>
          </div>
        }
      </div>
    </div>
  );
}

export default ManagedCheckBox;