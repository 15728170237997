import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class TransRightCompletedTime {
  #transRightCompletedTime

  constructor(transRightCompletedTime) {
    this.#transRightCompletedTime = transRightCompletedTime;
  }

  get() {
    return this.#transRightCompletedTime
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#transRightCompletedTime, 0, 500)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#transRightCompletedTime)
  }
}
