import { fetchGovernmentUsersStorage } from "../../../infrastructure/api/storage/government_users.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { GovernmentUserGet } from "../entities/GovernmentUser.js";

export async function getGovernmentUsers(type) {
  let response = [];
  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (User)');
  }
  if (type === NAVI) {
    response = await fetchGovernmentUsersStorage();
  }
  if (type === KMANAGER) {
    response = await fetchGovernmentUsersStorage();
  }

  if (response instanceof Error) {
    throw new CustomError('Error get governmentUser', response);
  }

  let results = [];

  response.map((user) => {
    results.push(new GovernmentUserGet(user))
  })

  return results
}
