
export class SummaryLocationText {
    #summaryLocationText

    constructor(summaryLocationText) {
        this.#summaryLocationText = summaryLocationText;
    }

    get() {
        return this.#summaryLocationText
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
