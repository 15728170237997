import HorizontalLinkGroupGrid from './HorizontalLinkGroupGrid.jsx';
import AuctionFooterProvider from 'interfaces/pages/Settings/services/AuctionFooterProvider.jsx';

const FooterTop = () => {
  return (
    <AuctionFooterProvider>
      <div data-testid="footer-top" className="footerTop mx-auto">
        <HorizontalLinkGroupGrid />
      </div>
    </AuctionFooterProvider>
  );
};

export default FooterTop;
