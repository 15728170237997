import { ReactComponent as IconDelete } from 'interfaces/assets/svg/delete.svg';
import { ReactComponent as IconEye } from 'interfaces/assets/svg/eye.svg';
import { ReactComponent as IconPencil } from 'interfaces/assets/svg/pencil.svg';
import FlatButton from 'interfaces/components/common/Button/FlatButton.jsx';
import SmallLightButton from 'interfaces/components/common/Button/SmallLightButton.jsx';
import CheckBox from 'interfaces/components/common/CheckBox/CheckBox.jsx';
import SortToolbar from 'interfaces/components/common/SortToolbar.jsx';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//import {underBarRemove} from "shared/utils/helper/camelize.js";

import 'interfaces/css/PropertyList/property-list-table.css';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { dateFormat } from 'shared/utils/helper/date_format';
import toYenFrom from 'shared/utils/helper/toYenString.js';

import CopyDialog from './CopyDialog.jsx';
import { PropertyListContext } from '../../../pages/PropertyList/entities/PropertyListContext';

const sortButtonPublicSale = [
  {
    id: 1,
    label: '掲載順',
  },
  {
    id: 2,
    label: '区分番号',
  },
  {
    id: 3,
    label: 'タイトル',
  },
  {
    id: 4,
    label: '見積価額',
  },
  {
    id: 5,
    label: '更新日',
  },
];

const sortButtonAssetSale = [
  {
    id: 1,
    label: '掲載順',
  },
  {
    id: 2,
    label: '区分番号',
  },
  {
    id: 3,
    label: 'タイトル',
  },
  {
    id: 4,
    label: '予定価格',
  },
  {
    id: 5,
    label: '更新日',
  },
];

const PropertyListTable = ({ data }) => {
  const navigation = useNavigate();
  const { auction_id } = useParams();
  const checkBoxInitialState = data
    ? data.reduce((acc, item) => {
        acc[item.id] = false;
        return acc;
      }, {})
    : {};
  const [checkboxStates, setCheckBoxStates] = useState(checkBoxInitialState);
  const [isCopyButtonDisabled, setIsCopyButtonDisabled] = useState(true);
  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const { localStorageProcedureType } = useLocalStorage();

  const { setSortParam, auction } = useContext(PropertyListContext);

  useEffect(() => {
    setIsCopyButtonDisabled(!Object.values(checkboxStates).some(Boolean));
  }, [checkboxStates]);

  const handleCheckboxChange = (event) => {
    const id = event.target.id;
    const isChecked = event.target.checked;
    setCheckBoxStates({
      ...checkboxStates,
      [id]: isChecked,
    });
  };

  const handleShowCopyDialog = () => {
    setShowCopyDialog(!showCopyDialog);
  };

  const getpropertyTypeUrl = (propertyType) => {
    if (propertyType === 'REAL_ESTATE') {
      return 'real-estate';
    }
    if (propertyType === 'CAR') {
      return 'car';
    }
    return 'estate';
  };

  const isDisable = () => {
    let aplStart = Date.parse(auction.aplStart);
    let today = new Date();
    if (aplStart > today) {
      return false;
    }
    //return true
    return false;
  };

  const displayDepositPayment = (depositPayment) => {
    if (depositPayment === 'BOTH') {
      return 'クレジットカード/銀行振込み';
    }
    if (depositPayment === 'BANK_TRANSFER') {
      return '銀行振込み';
    }
    if (depositPayment === 'CREDIT_CARD') {
      return 'クレジットカード';
    }
    return '保証金なし';
  };

  return (
    <>
      <div data-testid="property-list-table">
        <SortToolbar
          sortButtonData={
            localStorageProcedureType === 'PUBLIC_SALE'
              ? sortButtonPublicSale
              : sortButtonAssetSale
          }
          setSortParam={setSortParam}>
          <SmallLightButton
            text="選択したデータをコピー"
            disabled={isCopyButtonDisabled}
            onClick={handleShowCopyDialog}
          />
        </SortToolbar>
        <div className="property-list">
          <div className="property-list-table">
            <table>
              <thead data-testid="property-list-table-header">
                <tr>
                  <th className="table-check">選択</th>
                  <th className="table-order">掲載順</th>
                  <th>区分番号</th>
                  <th>タイトル</th>
                  <th className="table-published">公開状況</th>
                  <th className="table-recommend">イチ押し</th>
                  <th>
                    {localStorageProcedureType === 'PUBLIC_SALE'
                      ? '見積価格'
                      : '予定価格'}
                  </th>
                  <th>
                    {localStorageProcedureType === 'PUBLIC_SALE'
                      ? '公売保証金額'
                      : '入札保証金額'}
                  </th>
                  <th>保証金納付方法</th>
                  <th>更新日</th>
                  <th className="table-buttons"></th>
                </tr>
              </thead>
              <tbody data-testid="property-list-table-body">
                {data &&
                  data.map((d, index) => (
                    <tr key={index} data-testid="property-list-table-row">
                      <td>
                        <CheckBox
                          id={d.id}
                          handleCheckboxChange={handleCheckboxChange}
                          showMessage={true}
                        />
                      </td>
                      <td>{d.printOrder}</td>
                      <td>{d.saleNumber}</td>
                      <td>{d.name}</td>
                      <td>{d.isDraft === 'TRUE' ? '下書き' : '公開'}</td>
                      <td>{d.recommend}</td>
                      <td>{`${toYenFrom(Number(d.estimatePrice))}円`}</td>
                      <td>{`${toYenFrom(Number(d.deposit))}円`}</td>
                      <td>{displayDepositPayment(d.depositPayment)}</td>
                      <td>{dateFormat(d.updatedAt, 'YYYY-MM-DD-hh-mm')}</td>
                      <td className="action-buttons">
                        <FlatButton
                          text="編集"
                          icon={<IconPencil />}
                          onClick={() =>
                            navigation(
                              `/navi/object/${auction_id}/detail/${d.id}/${getpropertyTypeUrl(d.propertyType)}/edit`,
                            )
                          }
                          isDisabled={isDisable()}
                        />
                        <FlatButton text="プレビュー" icon={<IconEye />} />
                        <FlatButton
                          text="削除"
                          icon={<IconDelete />}
                          buttonStyle={{
                            backgroundColor: '#ff5252',
                            borderColor: '#ff5252',
                            color: '#fff',
                            fill: '#fff',
                          }}
                          isDisabled={isDisable()}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showCopyDialog && (
        <CopyDialog cancelButtonOnClick={handleShowCopyDialog} />
      )}
    </>
  );
};
export default PropertyListTable;
