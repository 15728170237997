import UpcomingAuctionTableTH from './UpcomingAuctionTableTH.jsx';

const UpcomingAuctionTableColumn = () => {
  return (
    <thead data-testid="upcoming-auction-table-column">
    <tr>
      <UpcomingAuctionTableTH rowSpan={2} text="公告"/>
      <UpcomingAuctionTableTH rowSpan={2} text="方法"/>
      <UpcomingAuctionTableTH rowSpan={2} text="参加実績"/>
      <UpcomingAuctionTableTH colSpan={2} text="申込期間"/>
      <UpcomingAuctionTableTH colSpan={2} text="オークション期間"/>
      <UpcomingAuctionTableTH rowSpan={2} text="掲載終了日"/>
    </tr>
    <tr>
      <UpcomingAuctionTableTH text="参加申込開始"/>
      <UpcomingAuctionTableTH text="参加申込締切"/>
      <UpcomingAuctionTableTH text="入札開始"/>
      <UpcomingAuctionTableTH text="入札締切"/>
    </tr>
    </thead>
  );
};

export default UpcomingAuctionTableColumn;
