import Step from './Step.jsx';

const Stepper = ({ steps, active }) => {
  return (
    <div className="sticky" data-testid="stepper">
      <div className="stepper-header step-stepper-header flat mb-10">
        {steps && steps.map((step, index) => (
          <Step key={index} currentStepNum={index + 1} stepData={step} activeStep={active} stepLength={steps.length}/>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
