import { PUBLISHED_OBJECT_PAGE_SIZE } from 'config/naviConstants';
import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import PrimaryButton from 'interfaces/components/common/Button/PrimaryButton';
import SmallLightButton from 'interfaces/components/common/Button/SmallLightButton';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import Pagination from 'interfaces/components/common/Pagination/Pagination';
import SelectBox from 'interfaces/components/common/SelectBox';
import SortToolbar from 'interfaces/components/common/SortToolbar';
import PropertyListSearchInput from 'interfaces/components/Property/PublishedObject/PropertyListSearchInput';
import PublishedObjectTable from 'interfaces/components/Property/PublishedObject/PublishedObjectTable';
import { breadcrumbs } from 'interfaces/pages/PublishedObject/entities/breadcrumbs';
import { useContext } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import usePagination from 'shared/hooks/usePagination';

import { PublishedObjectContext } from '../entities/PublishedObjectContext';

import 'interfaces/css/PublishedObject/published-object.css';

const sortButtonData = [
  {
    id: 1,
    label: '区分番号',
  },
  {
    id: 2,
    label: 'タイトル',
  },
  {
    id: 3,
    label: '見積価額',
  },
  {
    id: 4,
    label: '落札価額',
  },
];
const sortButtonAssetSale = [
  {
    id: 1,
    label: '区分番号',
  },
  {
    id: 2,
    label: 'タイトル',
  },
  {
    id: 3,
    label: '予定価格',
  },
  {
    id: 4,
    label: '落札価格',
  },
];
const listItemData = [
  {
    id: 1,
    text: '未選択',
  },
  {
    id: 2,
    text: 'サンプル',
  },
];

const NaviPublishedObject = () => {
  const { publishObjects, procedureType, auction, setSortParam } = useContext(
    PublishedObjectContext,
  );
  const search = useLocation().search;
  let queryParams = new URLSearchParams(search);
  let openWay = queryParams.get('open-way');
  const { auction_id } = useParams();
  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(publishObjects, PUBLISHED_OBJECT_PAGE_SIZE);

  return (
    <div className="published-object" data-testid="published-object">
      {/*
            <Breadcrumb breadcrumbs={breadcrumbs(id, invitation?.name, publicSaleType)}/>
            */}
      <Breadcrumb breadcrumbs={breadcrumbs(auction, openWay)} />
      <Heading2 title={`${auction?.name ?? ''}/公開物件管理`} />
      <div className="description" data-testid="description">
        <span>
          公開後の物件の状況、申込者、入札者の一覧を表示します。
          <br />
          編集ボタンより公開されている物件に説明文の追加ができます。「申込者一覧」より、申込者の確認、仮申込みの許可、ブロックリストへの登録を行います。物件に関する手続きを中止したい場合は、「取引情報」の「物件の中止」より行います。
        </span>
      </div>
      <div className="column">
        <ul>
          <li data-testid="certification-documents">
            <p>
              <Link to="/">認証書面(IDリスト)</Link>
            </p>
          </li>
          <li data-testid="published-object-status">
            <Link to={`/navi/object/${auction_id}/published-object/status`}>
              物件状況一覧
            </Link>
          </li>
        </ul>
      </div>

      {/* 入札終了後のボタン表示 */}
      <div className="column">
        <PrimaryButton>呼び上げ</PrimaryButton>
      </div>

      {/* 検索フォーム表示 */}
      <div className="column">
        <div className="search-form-area">
          <PropertyListSearchInput
            label="区分番号"
            style={{ width: '200px' }}
          />
          <PropertyListSearchInput
            label="タイトル"
            style={{ width: '200px' }}
          />
          <SelectBox listItemData={listItemData} width="200px" />
          <SmallLightButton text="絞り込む" />
          <SmallLightButton text="解除" />
        </div>
      </div>

      {/* 並び順 */}
      <div className="sort-toolbar-wrap">
        <SortToolbar
          sortButtonData={
            procedureType === 'PUBLIC_SALE'
              ? sortButtonData
              : sortButtonAssetSale
          }
          setSortParam={setSortParam}>
          <div className="sort-toolbar-label">並び順</div>
        </SortToolbar>
      </div>

      {currentItems.map((data) => (
        <PublishedObjectTable
          key={data.id}
          tableData={data}
          procedureType={procedureType}
        />
      ))}

      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
        marginTop="40px"
      />
    </div>
  );
};

export default NaviPublishedObject;
