import { MobileTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { ValidationMessage } from 'interfaces/components/common/ValidationMessage.jsx';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { dateFormat } from 'shared/utils/helper/date_format.js';
import styled from 'styled-components';

import FormLabel from './FormLabel.jsx';

export const slotProps = (date, setOpen, { placeholder = '' }) => {
  const inputValue = () => {
    const formatDate = dateFormat(date, 'HH:mm');
    if (formatDate === 'DATA_EMPTY') {
      return '';
    }
    return formatDate;
  };

  return {
    inputAdornment: {
      position: 'start',
    },
    textField: {
      readOnly: true,
      onClick: () => setOpen(true),
      InputProps: {
        placeholder: placeholder,
        value: inputValue(),
      },
      sx: {
        maxWidth: '500px',
        '& .MuiOutlinedInput-root': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.38)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f78c23',
          },
        },
        '& .MuiInputAdornment-root .MuiSvgIcon-root': {
          color: '#f78c23',
        },
        '& .MuiTypography-overline': {
          display: 'none',
        },
        '& [role="tab"][aria-selected="true"]': {
          backgroundColor: '#f78c23',
        },
      },
    },
  };
};

const InputTime = React.memo(function InputTime({
  invalidMessage,
  required,
  label,
  state,
  setState,
  placeholder,
}) {
  const [open, setOpen] = useState(false);
  return (
    <div className="kmanager-row">
      <div className="kmanager-col-4">
        <FormLabel label={label} required={required} />
      </div>
      <div
        className="kmanager-col form-area flex-column"
        data-testid="date-time-picker">
        <FormInput className={'form-input'} invalidMessage={invalidMessage}>
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            dateFormats={{
              fullTime: 'HH:mm:ss',
              fullTime12h: 'hh:mm:ss a',
              fullTime24h: 'HH:mm:ss',
              hours12h: 'hh',
              hours24h: 'HH',
              minutes: 'mm',
              seconds: 'ss',
            }}>
            <MobileTimePicker
              style={{ width: '100%', backgroundColor: 'red' }}
              value={DateTime.fromJSDate(new Date(state))}
              onChange={(e) => {
                setState(e.toJSDate());
              }}
              slotProps={slotProps(state, setOpen, {
                placeholder: placeholder,
              })}
              open={open}
              onClose={() => setOpen(false)}
              views={['hours', 'minutes']}
              ampm={false}
            />
          </LocalizationProvider>
        </FormInput>
        <ValidationMessage invalidMessage={invalidMessage} />
      </div>
    </div>
  );
});

const FormInput = styled.div`
  width: 100%;
  position: relative;
  div {
    width: 100%;
    fieldset {
      border: none;
    }
  }
`;
export default InputTime;
