import { PUBLISHED_OBJECT_STATUS_PAGE_SIZE } from 'config/naviConstants';
import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import Pagination from 'interfaces/components/common/Pagination/Pagination';
import PropertyListTable from 'interfaces/components/Property/PublishedObjectStatus/PropertyListTable';
import { breadcrumbs } from 'interfaces/pages/PublishedObjectStatus/entities/breadcrumbs';

import 'interfaces/css/PublishedObjectStatus/published-object-status.css';
import { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import usePagination from 'shared/hooks/usePagination';

import { PublishedObjectStatusContext } from '../entities/PublishedObjectStatusContext';

const PublishedObjectStatus = () => {
  const { auction_id } = useParams();

  const search = useLocation().search;
  let queryParams = new URLSearchParams(search);
  let openWay = queryParams.get('open-way');
  const { localStorageProcedureType } = useLocalStorage();
  const { invitation, tableData } = useContext(PublishedObjectStatusContext);

  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(tableData, PUBLISHED_OBJECT_STATUS_PAGE_SIZE);

  return (
    <div className="published-object-status">
      <Breadcrumb breadcrumbs={breadcrumbs(auction_id, openWay)} />
      <Heading2 title={`${invitation?.name}/物件状況一覧`} />
      <div className="description" data-testid="description">
        <p>物件の状況一覧です。各物件の現在の状況を表示しています。</p>
      </div>
      <PropertyListTable
        tableData={currentItems}
        procedureType={localStorageProcedureType}
      />
      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
        marginTop="40px"
      />
    </div>
  );
};

export default PublishedObjectStatus;
//const { auctions, isAuctionsLoading } = useAuctions()
//const { properties, isPropertiesLoading } = useProperties()
