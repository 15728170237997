import Button from 'interfaces/components/common/Button';
import ButtonList from 'interfaces/components/common/ButtonList';
import KmanagerInputType from 'interfaces/components/common/Form/KmanagerInputType';
import { useNavigate } from 'react-router-dom';
import 'interfaces/css/common/form.css';

const TOP_PATH = '/kmanager/gov_list';

const GovernmentForm = ({ inputs, toConfirm, isValid }) => {
  const navigation = useNavigate();
  const ValidationCheck = () => {
    // const validationResult = validation;
    // if (validationResult.length !== 0) {
    //   setValidationResult(validationResult)
    //   return;
    // }
    //
    toConfirm();
  };

  const Cancel = () => {
    navigation(TOP_PATH);
  };

  const Clear = () => {
    inputs?.forEach((input) => {
      switch (input.column) {
        case 'name':
          input.init('');
          break;
        case 'nameKana':
          input.init('');
          break;
        case 'governmentType':
          input.init('NATIONAL_TAX_AGENCY');
          break;
        case 'procedureType':
          input.init('PUBLIC_SALE');
          break;
        case 'location':
          input.init('9999');
          break;
        case 'region':
          input.init('9999');
          break;
        default:
          input.init('');
      }
    });
  };

  const FORM_BUTTONS_DATA = [
    {
      text: 'キャンセル',
      clickHandler: Cancel,
      className: 'edit',
      isDisabled: false,
    },
    {
      text: 'クリア',
      clickHandler: Clear,
      className: 'warning',
      isDisabled: false,
    },
    {
      text: '確認',
      clickHandler: ValidationCheck,
      className: 'primary',
      isDisabled: !isValid,
    },
  ];

  return (
    <div className="kmanager-col-12">
      <div className="kmanager-form" data-testid="kmanager-government-form">
        {inputs &&
          inputs.map((input, index) => {
            return KmanagerInputType(input, index);
          })}
        <ButtonList>
          <div className="d-flex justify-center mt-10">
            {FORM_BUTTONS_DATA.map((item, index) => (
              <Button
                key={index}
                text={item.text}
                clickHandler={item.clickHandler}
                isOutline={item.isOutline}
                isDisabled={item.isDisabled}
                className={item.className}
              />
            ))}
          </div>
        </ButtonList>
      </div>
    </div>
  );
};

export default GovernmentForm;
