import passwordForm from 'interfaces/css/Login/password-form.module.css';

const PasswordForm = ({ state, setState }) => {
  return (
    <div className={passwordForm.password_form} data-testid="password-form">
      <div data-testid="password-label">パスワード</div>
      <input
          type="password"
          className={passwordForm.password_input}
          data-testid="password-input"
          value={state} onChange={(e => setState(e.target.value))}
      />
    </div>
  );
};

export default PasswordForm;
