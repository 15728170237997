import { useGovernmentUser } from 'interfaces/components/GovernmentUser/navi/hook/useGovernmentUser'
import { Link } from "react-router-dom";

import LogoutLink from "./LogoutLink.jsx";

import "interfaces/css/common/menu-link-list.css";


const MenuLinkList = () => {
  const { governmentUser } = useGovernmentUser();

  return (
    <div className="menu-link-list" data-testid="menu-link-list">
      <div className="menu-link-list-inner">
        {
          governmentUser?.userType !== 'CHILD' ?
              <div className="menu-link-list-item">
                <div className="menu-link-list-item-title">
                  <Link to="/navi/user" data-testid="navi-user-management-link">ナビユーザー管理</Link>
                </div>
              </div> :
              <></>
        }
        <div className="menu-link-list-item">
          <div className="menu-link-list-item-title">
            <Link to="/navi/manual" data-testid="manual-link">マニュアル・資料(スケジュールなど)</Link>
          </div>
        </div>
        <div className="menu-link-list-item">
          <div className="menu-link-list-item-title">
            <Link to="/navi/faq" data-testid="Faq-link">よくある質問</Link>
          </div>
        </div>
        <div className="menu-link-list-item">
          <div className="menu-link-list-item-title">
            <a href="/navi/faq#contact" data-testid="contact-link">お問い合わせ先</a>
          </div>
        </div>
        <div className="menu-link-list-item">
          <div className="menu-link-list-item-title">
            <Link to="/navi/block-list" data-testid="blocklist-link">ブロックリスト</Link>
          </div>
        </div>
        <div className="menu-link-list-item">
          <div className="menu-link-list-item-title">
            <LogoutLink/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuLinkList;