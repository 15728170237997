// react/src/shared/utils/helper/managerValidators.js
import {
  validateEmptyString,
  validateEmailFormatCheck,
  validateSelected,
  isDefinedDateTime,
  isAfterNow,
  validateNumber,
  hasEmoji,
  validateRequiredFields,
  validateRangeLength,
  isAfterSet,
  isOnlyFullwidthKatakana,
  removeBlankSymbols,
  notInclude,
  isUploadedImageFile,
  isFullwidthKatakana,
  validatePrefecture,
  validateInclude,
  isAfterEnd,
  validateArea,
  replaceFullwidthKatakana,
} from '../../../application/validations';

// 必須チェック
// eslint-disable-next-line
export function validateRequired(value) {
  return validateEmptyString(value);
}

export {validateRangeLength}

// 空白が含まれていないか
export function validateHasNoSpace(input) {
  return notInclude(input, [' ']);
}

// 半角数字以外が含まれていないか
export function validateOnlyNumber(tel_number) {
  return validateNumber(tel_number);
}

// メールフォーマットチェック
export function validateEmailFormat(email) {
  return validateEmailFormatCheck(email);
}

// メールアドレス 文字数が128文字以内であるか
export function validateEmailLength(email) {
  return validateRangeLength(email, 0, 128);
}

// 文字列が全角のカタカナであるか
export function validateFullKatakana(text) {
  return isFullwidthKatakana(text);
}

// タイトル*	半角全角のカタカナは全角のカタカナに置換する
export function validateFullwidthKatakana(text) {
  return replaceFullwidthKatakana(text);
}

// 「確認」ボタン	必須項目がすべて入力されているか
export function validateAllClear(confirm, required_list) {
  return validateRequiredFields(confirm, required_list);
}

// お知らせ表示先システム*	表示先が選択されている
export function validateSelectDisplayPlace(notice) {
  return validateSelected(notice);
}

// (表示先が官公庁の場合に表示)お知らせの表示先（契約種別）* 	表示先が選択されている
export function validateSelectContractType(contract_type) {
  return validateSelected(contract_type);
}

// (表示先が官公庁の場合に表示)お知らせ表示先（行政機関種別）* 	表示先が選択されている
export function validateSelectGovType(gov_type) {
  return validateSelected(gov_type);
}

// 公開日時*	日時が選択されている
export function validateSelectOpenDate(open_date) {
  return isDefinedDateTime(open_date);
}

// 公開日時*	現在日時以降に設定されている
export function validateSetOpenDateAfterCurrentTime(open_date) {
  return isAfterNow(open_date);
}

// タイトル*	絵文字・機種依存文字が含まれていない
export function validateTitleLength(title) {
  return validateRangeLength(title, 1, 255);
}

// タイトル*	絵文字・機種依存文字が含まれていない
export function validateIncludeEmojiTitle1(title) {
  return hasEmoji(title);
}

// 本文*	文字数が1文字以上20000文字であるか
export function validateRangeBodyLength(body) {
  return validateRangeLength(body, 1, 20000);
}

// 本文*	絵文字・機種依存文字が含まれていない
export function validateIncludeEmojiBody(body) {
  return hasEmoji(body);
}

// 終了日時*	日時が選択されている
export function validateSelectEndDate(end_date) {
  return isDefinedDateTime(end_date);
}

// 終了日時*	公開日時以降に設定されている
export function validateSetAfterOpenDate(end_date, publishDateTime) {
  return isAfterSet(end_date, publishDateTime);
}

// 担当者名（姓）*	文字数が1文字以上30文字以内か
export function validateRangLastNameLength(last_name) {
  return validateRangeLength(last_name, 1, 30);
}

// 担当者名（姓）*	絵文字・機種依存文字が含まれていないか
export function validateIncludeEmojiLastName(last_name) {
  return hasEmoji(last_name);
}

// 担当者名（名）*	文字数が1文字以上30文字以内か
export function validateRangFirstNameLength(first_name) {
  return validateRangeLength(first_name, 1, 30);
}

// 担当者名（名）*	絵文字・機種依存文字が含まれていないか
export function validateIncludeEmojiFirstName(first_name) {
  return hasEmoji(first_name);
}

// 担当者名（姓）カナ*	文字数が1文字以上30文字以内か
export function validateRangLastNameKanaLength(last_name_kana) {
  return validateRangeLength(last_name_kana, 1, 30);
}

// 担当者名（姓）カナ*	文字列が全角カタカナであるか
export function validateLastNameKatakana(last_name_kana) {
  return isOnlyFullwidthKatakana(last_name_kana);
}

// 担当者名（名）カナ*	文字数が1文字以上30文字以内か
export function validateRangFirstNameKanaLength(first_name_kana) {
  return validateRangeLength(first_name_kana, 1, 30);
}

// 担当者名（名）カナ*	文字列が全角カタカナであるか
export function validateFirstNameKatakana(first_name_kana) {
  return isOnlyFullwidthKatakana(first_name_kana);
}

// 担当部署名*	文字数が1文字以上50文字以内か
export function validateRangeDeptNameLength(dept_name) {
  return validateRangeLength(dept_name, 1, 50);
}

// 担当部署名*	絵文字・機種依存文字が含まれていないか
export function validateIncludeEmojiDeptName(dept_name) {
  return hasEmoji(dept_name);
}

// 役職名	文字数が30文字以内か
export function validateRangeJobTitleLength(job_title) {
  return validateRangeLength(job_title, 0, 30);
}

// 電話番号	文字数が合計で10文字以上11文字以下であるか
export function validateRangeTelNumberLength(tel_number) {
  return validateRangeLength(tel_number, 10, 11);
}

// 電話番号	「-」と空白を除外する
export function validateExclusionTelSpace(tel_number) {
  return removeBlankSymbols(tel_number);
}

// 内線	文字数が10文字以内であるか
export function validateRangeExtensionLength(extension) {
  return validateRangeLength(extension, 0, 10);
}

// 内線	空白が含まれていないか
export function validateExclusionExtensionSpace(extension) {
  return notInclude(extension, [' ']);
}

// 内線	「,」「-」「.」「/」と半角数字以外が含まれていないか
export function validateInputExtLimitExtension(extension) {
  return notInclude(extension, [',', '-', '.', '/']);
}

// メール	「@」が含まれているか
export function validateIncludeAtMark(email) {
  return validateEmailFormatCheck(email);
}

// メール	文字数が128文字以内であるか
export function validateRangeMailLength(email) {
  return validateRangeLength(email, 0, 128);
}

// 開催名*	文字数が1文字以上30文字以内であるか
export function validateRangeEventLength(event_name) {
  return validateRangeLength(event_name, 1, 30);
}

// 開催名*	絵文字・機種依存文字が含まれていないか
export function validateIncludeEmojiEventName(event_name) {
  return hasEmoji(event_name);
}

// 行政機関申込開始日時*	日時が選択されている
export function validateSelectGovAplDate(gov_apl) {
  return isDefinedDateTime(gov_apl);
}

// 行政機関申込開始日時*	現在日時以降に設定されている
export function validateSetGovAplAfterCurrentTime(gov_apl) {
  return isAfterNow(gov_apl);
}

// 行政機関申込締切日時*	日時が選択されている
export function validateSelectGovAplEnd(gov_apl_end) {
  return isDefinedDateTime(gov_apl_end);
}

// 	行政機関申込開始日時以降に設定されている
export function validateSetAfterGovApl(gov_apl_end, startDateTime) {
  return isAfterSet(gov_apl_end, startDateTime);
}

// 参加申込開始日時*	日時が選択されている
export function validateSelectAplStartDate(apl_start) {
  return isDefinedDateTime(apl_start);
}

// 参加申込開始日時*	行政機関申込締切日時から24時間以降に設定されている
export function validateSetAplStartAfter24Hours(apl_start, endDateTime) {
  return isAfterEnd(apl_start, endDateTime, 24);
}

// 参加申込締切日時*	日時が選択されている
export function validateSelectAplEnd(apl_end) {
  return isDefinedDateTime(apl_end);
}

// 参加申込締切日時*	参加申込開始日時以降に設定されている
export function validateSetAplEndAfterAplStart(apl_end, startDateTime) {
  return isAfterSet(apl_end, startDateTime);
}

// 入札開始日時*	日時が選択されている
export function validateSelectAuctionStart(auction_start) {
  return isDefinedDateTime(auction_start);
}

// 入札開始日時*	参加申込締切日時以降に設定されている
export function validateSetAuctionStartAfterAplEnd(auction_start, endDateTime) {
  return isAfterSet(auction_start, endDateTime);
}

// 入札終了日時（せり）*	日時が選択されている
export function validateSelectAuctionEnd(auction_end) {
  return isDefinedDateTime(auction_end);
}

// 入札終了日時（せり）*	入札開始日時以降に設定されている
export function validateSetAuctionEndAfterAuctionStart(
  auction_end,
  startDateTime,
) {
  return isAfterSet(auction_end, startDateTime);
}

// 入札終了日時（入札）*	日時が選択されている
export function validateSelectBidEnd(bid_end) {
  return isDefinedDateTime(bid_end);
}

// 入札終了日時（入札）*	入札開始日時以降に設定されている
export function validateSetBidEndAfterAuctionStart(bid_end, startDateTime) {
  return isAfterSet(bid_end, startDateTime);
}

// 追加入札時間*	時間が選択されている
export function validateSetAdditionalTime(additional_time) {
  return validateSelected(additional_time);
}

// 入札確定処理日時*	日時が選択されている
export function validateSelectDeciDate(decision_date) {
  return isDefinedDateTime(decision_date);
}

// 入札確定処理日時*	（入札・せりどちらか遅い方の）入札終了日時以降に設定されている
export function validateSetDeciDateAfterBidEnd(decision_date, endDateTime) {
  return isAfterSet(decision_date, endDateTime);
}

// 入札確定処理日時*  自治体の公売形式の場合	日時が選択されている
export function validateSelectDeciDate2(decision_date) {
  return isDefinedDateTime(decision_date);
}

// 入札確定処理日時*  入札終了日時から追加入札時間が経過した時間以降に設定されている
export function validateSetDeciDateAfterBidEnd2(
  decision_date,
  endDateTime,
  addingTime,
) {
  return isAfterEnd(decision_date, endDateTime, addingTime);
}

// 次順位買受代金納付期限（動産・自動車）*	日時が設定されている
export function validateSetDeadline(next_deadline) {
  return isDefinedDateTime(next_deadline);
}

// 次順位買受代金納付期限（動産・自動車）*	入札確定処理日時以降に設定されている
export function validateSetDeadlineAfterDeciDate(
  next_deadline,
  confirmationDateTime,
) {
  return isAfterSet(next_deadline, confirmationDateTime);
}

// 次順位買受代金納付期限（不動產）*	日時が設定されている
export function validateSetDeadline2(next_deadline) {
  return isDefinedDateTime(next_deadline);
}

// 次順位買受代金納付期限（不動產）*	入札確定処理日時以降に設定されている
export function validateSetDeadlineAfterDeciDate2(
  next_deadline,
  confirmationDateTime,
) {
  return isAfterSet(next_deadline, confirmationDateTime);
}

// 公開終了日*	日時が選択されている
export function validateSelectOpenEnd(open_end) {
  return isDefinedDateTime(open_end);
}

// 公開終了日*	（入札・せりどちらか遅い方の）入札終了日時以降に設定されている
export function validateSetOpenEndAfterBidEnd(open_end, endDateTime) {
  return isAfterSet(open_end, endDateTime);
}

// 保証金の取り扱い*	保証金の取り扱いが選択されている
export function validateSelectHandlingDeposit(handling_deposit) {
  return validateSelected(handling_deposit);
}

// タイトル*	文字数が200文字以内であるか
export function validateRangeTitleLength(title) {
  return validateRangeLength(title, 0, 200);
}

// カテゴリ名	文字数が1文字以上、255文字以内であるか
export function validateRangeCategoryNameLength(category_name) {
  return validateRangeLength(category_name, 1, 255);
}

// カテゴリ名	絵文字や機種依存文字が含まれていないか
export function validateIncludeEmojiCategoryName(category_name) {
  return hasEmoji(category_name);
}

// 子カテゴリ名	文字数が1文字以上、255文字以内であるか
export function validateRangeChildCategoryLength(child_category) {
  return validateRangeLength(child_category, 1, 255);
}

// 子カテゴリ名	絵文字や機種依存文字が含まれていないか
export function validateIncludeEmojiChild(child_category) {
  return hasEmoji(child_category);
}

// 孫カテゴリ名	文字数が1文字以上、255文字以内であるか
export function validateRangeGrandchildCategoryLength(grandchild_category) {
  return validateRangeLength(grandchild_category, 1, 255);
}

// 孫カテゴリ名	絵文字や機種依存文字が含まれていないか
export function validateIncludeEmojiGrandchild(grandchild_category) {
  return hasEmoji(grandchild_category);
}

// 入金日登録	日付が選択されている
export function validateSelectDepositDate(deposit_date) {
  return validateSelected(deposit_date);
}

// タイトル*	文字数が1文字以上50文字以内であるか
export function validateRangeTitleLength2(title) {
  return validateRangeLength(title, 1, 50);
}

// タイトル*	絵文字・機種依存文字が含まれていないか
export function validateIncludeEmojiTitle2(title) {
  return hasEmoji(title);
}

/// ------------------------------------ これ以降、最後まで ------------------------------------
// 遷移先URL*	URLに「https://」が含まれている
export function validateDestinationHttps(transition_url) {
  return validateInclude(transition_url, ['https://', 'http://']);
}

// 遷移先URL*	文字数が2048文字以内であるか
export function validateDestinationLength(transition_url) {
  return validateRangeLength(transition_url, 0, 2048);
}

// 画像ファイルをアップロード	画像がアップロードされているか
export function validateImageFileUpload(image_file) {
  return isUploadedImageFile(image_file);
}

// テーマ	文字数が30文字以内か
export function validateThemeLength(theme) {
  return validateRangeLength(theme, 0, 30);
}

// テーマ	絵文字・機種依存文字が含まれていないか
export function validateThemeCharacter(theme) {
  return hasEmoji(theme);
}

// 表示枠*	表示枠が選択されている
export function validateDisplaySelect(display) {
  return validateSelected(display);
}

// 検索URL	URLに「https://」が含まれている
export function validateSearchUrlHttps(url) {
  return validateInclude(url, ['https://', 'http://']);
}

// 検索URL	文字数が2048文字以内であるか
export function validateSearchUrlLength(url) {
  return validateRangeLength(url, 0, 2048);
}

// ソース/手入力1~18	URLに「https://」が含まれている
export function validateSourceHttps(url) {
  return validateInclude(url, ['https://', 'http://']);
}

// ソース/手入力1~18	文字数が2048文字以内であるか
export function validateSourceLength(source) {
  return validateRangeLength(source, 0, 2048);
}

// 部署名*	文字数が1文字以上30文字以内か
export function validateDepartmentLength(department_name) {
  return validateRangeLength(department_name, 1, 30);
}

// 部署名*	絵文字・機種依存文字が含まれていないか
export function validateDepartmentCharacter(department_name) {
  return hasEmoji(department_name);
}

// 役職名	絵文字・機種依存文字が含まれていないか
export function validateRoleCharacter(role_name) {
  return hasEmoji(role_name);
}

// 公告名*	文字数が1文字以上30文字以内か
export function validateAnnouncementLength(name) {
  return validateRangeLength(name, 1, 30);
}

// 公告名*	絵文字・機種依存文字が含まれていないか
export function validateAnnouncementCharacter(name) {
  return hasEmoji(name);
}

// 公売保証金の運用*	運用方法が一つ以上選択されている
export function validateInvestmentSelected(investment) {
  return validateSelected(investment);
}

// 行政機関名*	行政機関名が選択されている
export function validateGovernmentNameSelected(name) {
  return validateSelected(name);
}

// 地域*	文字数が1文字以上30文字以内か
export function validateAreaLength(area) {
  return validateRangeLength(area, 1, 30);
}

// 地域*	絵文字・機種依存文字が含まれていないか
export function validateAreaCharacter(area) {
  return hasEmoji(area);
}

// 担当部署	文字数が30文字以内か
export function validatePicLength(pic) {
  return validateRangeLength(pic, 0, 30);
}

// 担当部署	絵文字・機種依存文字が含まれていないか
export function validatePicCharacter(pic) {
  return hasEmoji(pic);
}

// // @@@行政機関名*	重複して登録していないか
// export function validateOverlappingName(government_name) {
// }

// 行政機関名*	文字数が1文字以上50文字以内か
export function validateRangeGovernmentNameLength(government_name) {
  return validateRangeLength(government_name, 1, 50);
}

// 行政機関名*	絵文字・機種依存文字が含まれていないか
export function validateGovernmentNameCharacter(government_name) {
  return hasEmoji(government_name);
}

// // @@@行政機関名（カナ）*	重複して登録していないか
// export function validateOverlappingNameKatakana(government_name_kana) {
// }

// 行政機関名（カナ）*	文字数が1文字以上50文字以内か
export function validateGovernmentNameKanaLength(government_name_kana) {
  return validateRangeLength(government_name_kana, 1, 50);
}

// 行政機関名（カナ）*	文字列が全角カタカナであるか
export function validateGovernmentNameKatakana(government_name_kana) {
  return isOnlyFullwidthKatakana(government_name_kana);
}

// 行政機関名（カナ）*	絵文字・機種依存文字が含まれていないか
export function validateGovernmentNameKanaCharacter(government_name_kana) {
  return hasEmoji(government_name_kana);
}

// 地域*	地域が選択されているか
export function validateAreaRequired(area) {
  return validateArea(area);
}

// 都道府県*	都道府県が選択されているか
export function validateGovernmentPrefectureRequired(prefecture) {
  return validatePrefecture(prefecture);
}

// 「確認」ボタンクリック	必須項目がすべて入力されているか
export function validateGovernmentAllClear(confirm, required_list) {
  return validateRequiredFields(confirm, required_list);
}

// タイトル*	文字数が20文字以内であるか
export function validateRangeCharacterNameLength(title) {
  return validateRangeLength(title, 1, 20);
}
