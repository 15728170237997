import { ReactComponent as RadioMarked } from '../../../assets/svg/radio_button_checked_icon.svg';
import { ReactComponent as RadioBlank } from '../../../assets/svg/radio_button_unchecked_icon.svg';

const RadioInput = ({ item, label, value, handleClick, className }) => {
  return (
    <label
      key={item.id}
      className={`radio-input-wrap ${className}`}
      data-testid={`radio-input-${label}`}>
      <div className="flex">
        {item.value === value ? <RadioMarked /> : <RadioBlank />}
      </div>
      <input
        type="radio"
        name="radio"
        value={value}
        onClick={handleClick}
        className="radio-input"
        data-testid="radio-input"
      />
      <p className="radio-input-label" data-testid="radio-input-label">
        {label ?? item.value}
      </p>
    </label>
  );
};

export default RadioInput;
