import CommonInput from 'interfaces/components/common/Input/CommonInput';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel';
import handleValidationMessage from 'shared/utils/helper/handleValidationMessage';

const BothTelInput = ({
  houseTel,
  mobileTel,
  houseTelValidationMessage,
  mobileTelValidationMessage,
  handleHouseTelChange,
  handleMobileTelChange,
}) => {
  return (
    <tr data-testid="both-tel-input">
      <th data-testid="both-tel-input-label">
        電話番号
        <RequiredLabel />
      </th>
      <td data-testid="both-tel-input-body">
        <div className="flex">
          <p className="flex align-center mr-4">自宅：</p>
          <CommonInput
            type="txt"
            name="house-tel"
            placeholder="（例）0312345678"
            value={houseTel}
            handleChange={handleHouseTelChange}
          />
        </div>
        <small className="validate-message ml-15">
          {handleValidationMessage(houseTelValidationMessage)}
        </small>
        <div className="flex mt-2">
          <p className="flex align-center mr-4">携帯：</p>
          <CommonInput
            type="text"
            name="mobile-tel"
            placeholder="（例）09012345678"
            value={mobileTel}
            handleChange={handleMobileTelChange}
          />
        </div>
        <small className="validate-message ml-15">
          {handleValidationMessage(mobileTelValidationMessage)}
        </small>
        <br />
        <small data-testid="both-tel-input-caption">
          どちらか一方は必ず入力してください。
        </small>
      </td>
    </tr>
  );
};

export default BothTelInput;
