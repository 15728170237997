
export class SummaryTotalHouses {
    #summaryTotalHouses

    constructor(summaryTotalHouses) {
        this.#summaryTotalHouses = summaryTotalHouses;
    }

    get() {
        return this.#summaryTotalHouses
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
