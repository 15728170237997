import axios from 'axios';

import {API_BASE_URL} from "../../config/envConstants.js";

export const createNaviPublicSaleCautionPagesData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/navi/public-sale-caution-pages`,
    body, {headers: headers}
  );

};
export const updateNaviPublicSaleCautionPagesData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/navi/public-sale-caution-pages/${id}`,
    body, {headers: headers}
  );
};
export const deleteNaviPublicSaleCautionPagesData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/navi/public-sale-caution-pages/${id}`, {
      headers: headers,
    },);
};