import Button from "interfaces/components/common/Button/Button";
import Modal2 from "interfaces/components/common/Modal/Modal2";
import { useNavigate } from "react-router-dom";
import { ProcedureTypeToJP } from "shared/utils/helper/ProcedureTypeToJp";
import 'interfaces/css/LocalGovernmentManagement/local-government-modal.css';

const LocalGovernmentManagementModal = ({ handleClose, procedureType }) => {
  const navigation = useNavigate();
  return (
    <div>
      <Modal2 handleClose={handleClose} modalSize={'550px'}>
        <div className="modal-content">
          <div className="local-government-management-modal-container">
            <div className="local-government-management-modal-message">
              登録が完了しました。<br/>
              {`${ProcedureTypeToJP(procedureType)}`}の参加申込開始日以降は修正できません。<br/>
              入力した内容に誤りがないかプレビューをご確認ください。
            </div>
            <Button
              text={"行政機関管理ページへ戻る"}
              isOutline={false}
              height={"44px"}
              className={"local-government-management-modal-button"}
              onClick={() => navigation('/navi/local-government-management')}
            />
          </div>
        </div>
      </Modal2>
    </div>
  )

}
export default LocalGovernmentManagementModal