
export class DocumentName {
  #documentName

  constructor(documentName) {
    this.#documentName = documentName;
  }

  get() {
    return this.#documentName
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
