import { createContext } from 'react';

import { breadcrumbs } from "./breadcrumbs.js";
import { checkbox_label, checkbox_label_recommend } from "./checkbox_label.js";
import {
  procedure_asset,
  procedure_pub,
  is_draft,
  public_sale_type,
  qualified_invoice,
  radio_label3
} from "./radio_label.js";
import { FORGET_PASSWORD, LOGIN, SIGN_UP } from "../../../../shared/utils/helper/authState.js";
import { DATA_EMPTY } from "../../../../shared/utils/helper/constants.js";

export const GeneralEditContext = createContext({
  // Default Value
  responsive: DATA_EMPTY,
  authState: {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD
  },
  breadcrumbs: breadcrumbs,
  managementMessages: DATA_EMPTY,
  radioLabelData: {
    procedureAsset: procedure_asset,
    procedurePub: procedure_pub,
    isDraft: is_draft,
    publicSaleType: public_sale_type,
    qualifiedInvoice: qualified_invoice,
    radioLabel3: radio_label3
  },
  selectBoxData: {
    checkboxLabel: checkbox_label,
    checkboxLabelRecommend: checkbox_label_recommend,
  },
  governmentId: DATA_EMPTY,
  governmentType: DATA_EMPTY,
  procedureType: DATA_EMPTY,
  queryParams: DATA_EMPTY,
  propertyDetail: DATA_EMPTY,
});
