// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.record-headline {
  width: 30%;
  max-width: 300px;
  min-width: 200px;
  background-color: var(--thead);
  font-weight: 700;
}

.record-headline-bg-gray {
  background-color: var(--heading);
  font-weight: 400;
}

.record-content {
  padding: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/caution-topic-record.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".record-headline {\n  width: 30%;\n  max-width: 300px;\n  min-width: 200px;\n  background-color: var(--thead);\n  font-weight: 700;\n}\n\n.record-headline-bg-gray {\n  background-color: var(--heading);\n  font-weight: 400;\n}\n\n.record-content {\n  padding: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
