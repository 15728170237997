
export class NumOfProperties {
    #numOfProperties

    constructor(numOfProperties) {
        this.#numOfProperties = numOfProperties;
    }

    get() {
        return this.#numOfProperties
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
