import { useState } from "react";

export function useBannerInputs() {
  const [title, setTitle] = useState("");
  const [destinationUrl, setDestinationUrl] = useState("");
  const [displayDeadline, setDisplayDeadline] = useState("HIDDEN");
  const [deadline, setDeadline] = useState("");
  const [displayTarget, setDisplayTarget] = useState("SAME_WINDOW");
  const [displayOrder, setDisplayOrder] = useState("");
  const [activeStatus, setActiveStatus] = useState("NO_ACTIVE");

  const bannerInputs = {
    type: {
      title: 'text',
      destinationUrl: 'text',
      displayDeadline: 'radio',
      deadline: 'date',
      displayTarget: 'radio',
      displayOrder: 'text',
      activeStatus: 'radio',
    },
    data: {
      title,
      destinationUrl,
      displayDeadline,
      deadline,
      displayTarget,
      displayOrder,
      activeStatus,
    },
    setters: {
      title: setTitle,
      destinationUrl: setDestinationUrl,
      displayDeadline: setDisplayDeadline,
      deadline: setDeadline,
      displayTarget: setDisplayTarget,
      displayOrder: setDisplayOrder,
      activeStatus: setActiveStatus,
    }
  }

  return bannerInputs;
}