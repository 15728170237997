
export class MembershipId {
  #membershipId

  constructor(membershipId) {
    this.#membershipId = membershipId;
  }

  get() {
    return this.#membershipId
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
