import { validateSelected } from "../../../../application/validations.js";

export class ProcedureType {
  #procedureType

  constructor(procedureType) {
    this.#procedureType = procedureType;
  }

  get() {
    return this.#procedureType
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#procedureType)
  }
}
