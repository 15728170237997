export const ActionAreaPrice = ({children, label}) => {
  return (
    <div className="items-action-area-price">
      <div className="v-subheader pa-0 label flex center gaps theme--light">
        <div className="box grow"
             data-testid="current-price-label">{label}
        </div>
      </div>
      <div data-testid="current-price" className="price">
        {children}
      </div>
    </div>
  )
}