import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import PropertyInfoCarComplete from 'interfaces/components/Property/PropertyInfo/Car/PropertyInfoCarComplete';
import PropertyInfoCarConfirm from 'interfaces/components/Property/PropertyInfo/Car/PropertyInfoCarConfirm';
import PropertyInfoCarForm from 'interfaces/components/Property/PropertyInfo/Car/PropertyInfoCarForm';
import { breadcrumbs } from 'interfaces/pages/PropertyInfoCar/entities/breadcrumbs';
import { PropertyInfoCarContext } from 'interfaces/pages/PropertyInfoCar/entities/PropertyInfoCarContext';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

import { CarFormData } from './CarFormData';

const Car = () => {
  const [procedureType, setProcedureType] = useState('');
  const [onConfirm, setOnConfirm] = useState(false);
  const [onComplete, setOnComplete] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { carProperty, invitation, paymentMethods, property } = useContext(
    PropertyInfoCarContext,
  );
  const { auction_id, property_id } = useParams();
  const search = useLocation().search;
  let queryParams = new URLSearchParams(search);
  let openWay = queryParams.get('open-way');
  let isEdit = false;

  if (property_id !== undefined) {
    isEdit = true;
  }
  const formData = CarFormData({ isEdit: isEdit });
  const { localStorageProcedureType } = useLocalStorage();

  useEffect(() => {
    if (property_id === undefined) {
      formData.init.setPublicSaleType(openWay);
      formData.init.setIsNextBuyApl('FALSE');
      return;
    }

    // 公開設定
    formData.init.setIsDraft(property?.isDraft);

    // 2. 物件情報
    formData.init.setPublicSaleType(
      property?.publicSaleType === undefined
        ? openWay
        : property.publicSaleType,
    );
    formData.init.setPrintOrder(property?.printOrder);
    formData.init.setCategoryId(property?.categoryId);
    //formData.init.setRecommendProperties({ recommend: recommend_property ?? true, })
    formData.init.setDivisionId(property?.divisionId);
    formData.init.setSaleNumber(property?.saleNumber);
    formData.init.setName(property?.name);
    let methods = {};
    paymentMethods?.map((method) => {
      if (method.payment === 'BANK_TRANSFER') {
        methods.bankTransfer = 'TRUE';
      }
      if (method.payment === 'REGISTERED_MAIL') {
        methods.registeredMail = 'TRUE';
      }
      if (method.payment === 'CHECK') {
        methods.check = 'TRUE';
      }
      if (method.payment === 'POSTAL_MONEY_ORDER') {
        methods.postalMoneyOrder = 'TRUE';
      }
      if (method.payment === 'CARRY') {
        methods.carry = 'TRUE';
      }
    });
    formData.init.setPaymentMethod(methods);
    formData.init.setPaymentLimit(property?.paymentLimit);
    formData.init.setVideoUrl(property?.videoUrl);

    // 3. 登録事項など証明書の記載内容
    let tmpEffectiveDate = {};
    if (carProperty?.effectiveDate !== undefined) {
      let targetDate = new Date(carProperty.effectiveDate);
      tmpEffectiveDate.year = targetDate.getFullYear().toString();
      tmpEffectiveDate.month = (targetDate.getMonth() + 1).toString();
      tmpEffectiveDate.day = targetDate.getDate().toString();
    }
    formData.init.setEffectiveDate(tmpEffectiveDate);

    let tmpRegisterNumber = {};
    if (carProperty?.registerNumber !== undefined) {
      if (carProperty.registerNumber.includes(':')) {
        let regNumber = carProperty.registerNumber.split(':');
        tmpRegisterNumber.first = regNumber[0];
        tmpRegisterNumber.latter = regNumber[1];

        // 現行環境の不具合対応用ロジック
      } else if (carProperty.registerNumber.includes('****')) {
        let index = carProperty.registerNumber.indexOf('****');
        let firstString = carProperty.registerNumber.substring(0, index);
        tmpRegisterNumber.first = firstString;
        tmpRegisterNumber.latter = '****'; // 不具合（データを復元できない）
      }
    }
    formData.init.setRegisterNumber(tmpRegisterNumber);
    formData.init.setDisplayRegisterNumber(carProperty?.displayRegisterNumber);
    let tmpRegisterDate = {};
    if (carProperty?.registerDate !== undefined) {
      let targetDate = new Date(carProperty.registerDate);
      tmpRegisterDate.year = targetDate.getFullYear().toString();
      tmpRegisterDate.month = (targetDate.getMonth() + 1).toString();
      tmpRegisterDate.day = targetDate.getDate().toString();
    }
    formData.init.setRegisterDate(tmpRegisterDate);

    let tmpFirstRegisterDate = {};
    if (carProperty?.registerDate !== undefined) {
      let targetDate = new Date(carProperty.registerDate);
      tmpFirstRegisterDate.year = targetDate.getFullYear().toString();
      tmpFirstRegisterDate.month = (targetDate.getMonth() + 1).toString();
      tmpFirstRegisterDate.day = '1';
    }
    formData.init.setFirstRegisterDate(tmpFirstRegisterDate);
    formData.init.setType(carProperty?.type);
    formData.init.setPurpose(carProperty?.purpose);
    formData.init.setPrivateOrBusiness(carProperty?.privateOrBusiness);
    formData.init.setShape(carProperty?.shape);
    formData.init.setManufacture(carProperty?.manufacture);
    formData.init.setManufactureOther(carProperty?.manufactureOther);
    formData.init.setModel(carProperty?.model);
    formData.init.setSeatingCapacity(carProperty?.seatingCapacity);
    formData.init.setWeight(carProperty?.weight);
    formData.init.setTotalWeight(carProperty?.totalWeight);
    formData.init.setProductNumber(carProperty?.productNumber);
    let tmpProductNumber = {};
    if (carProperty?.productNumber !== undefined) {
      if (carProperty.productNumber.includes('-')) {
        let productNumber = carProperty.productNumber.split('-');
        tmpProductNumber.first = productNumber[0];
        tmpProductNumber.latter = productNumber[1];
      }
    }
    formData.init.setProductNumber(tmpProductNumber);
    formData.init.setDisplayProductNumber(carProperty?.displayProductNumber);
    formData.init.setEngineModel(carProperty?.engineModel);
    formData.init.setLength(carProperty?.length.toString());
    formData.init.setWidth(carProperty?.width);
    formData.init.setHeight(carProperty?.height);
    formData.init.setDisplacement(carProperty?.displacement);
    formData.init.setFuelType(carProperty?.fuelType);
    formData.init.setFrontAxelWeight(carProperty?.frontAxelWeight);
    formData.init.setRearAxelWeight(carProperty?.rearAxelWeight);

    let tmpSafetyTerm = {};
    if (carProperty?.safetyTerm !== undefined) {
      let targetDate = new Date(carProperty.safetyTerm);
      tmpSafetyTerm.year = targetDate.getFullYear().toString();
      tmpSafetyTerm.month = (targetDate.getMonth() + 1).toString();
      tmpSafetyTerm.day = targetDate.getDate().toString();
    }
    formData.init.setSafetyTerm(tmpSafetyTerm);

    formData.init.setStatusSafetyTerm(carProperty?.statusSafetyTerm);
    formData.init.setFormalNumber(carProperty?.formalNumber);
    formData.init.setClassificationNumber(carProperty?.classificationNumber);

    formData.init.setTransmissionType(carProperty?.transmissionType);
    formData.init.setHandleType(carProperty?.handleType);
    //formData.init.setExistsCarMaintenanceSheet(
    //  carProperty?.existsCarMaintenanceSheet,
    //);
    formData.init.setCarBodyColor(carProperty?.carBodyColor);
    formData.init.setCarMileage(carProperty?.carMileage);
    formData.init.setStatusCarMileage(carProperty?.statusCarMileage);
    formData.init.setInventoryLocation(carProperty?.inventoryLocation);
    formData.init.setMapUrl(carProperty?.mapUrl);
    formData.init.setCarStatus(carProperty?.carStatus);
    formData.init.setDeliveryTerms(carProperty?.deliveryTerms);
    formData.init.setOther(carProperty?.other);
    formData.init.setDescription(carProperty?.description);

    // 3. 物件に関するお問い合わせ先
    formData.init.setInquiryName(property?.inquiryName);
    formData.init.setInquiryOffice(property?.inquiryOffice);
    formData.init.setInquiryEmail(property?.inquiryEmail);
    formData.init.setInquiryTel(
      property?.inquiryTel !== undefined
        ? {
            telNum1: property?.inquiryTel.split('-')[0],
            telNum2: property?.inquiryTel.split('-')[1],
            telNum3: property?.inquiryTel.split('-')[2],
          }
        : {
            telNum1: '',
            telNum2: '',
            telNum3: '',
          },
    );
    formData.init.setInquiryExtension(property?.inquiryExtension);
    formData.init.setInquiryAccept(property?.inquiryAccept);
    formData.init.setInquiryNote(property?.inquiryNote);

    // 4. 物件の詳細設定
    formData.init.setDepositPayment(property?.depositPayment);
    formData.init.setIsApprovalRequired(property?.isApprovalRequired);
    formData.init.setEstimatePrice(property?.estimatePrice);
    formData.init.setDeposit(property?.deposit);
    formData.init.setQualifiedInvoice(property?.qualifiedInvoice);

    // 5. 落札後の連絡先
    formData.init.setContactName(property?.contactName);
    formData.init.setContactOffice(property?.contactOffice);
    formData.init.setContactEmail(property?.contactEmail);
    formData.init.setContactTel(
      property?.contactTel !== undefined
        ? {
            telNum1: property?.contactTel.split('-')[0],
            telNum2: property?.contactTel.split('-')[1],
            telNum3: property?.contactTel.split('-')[2],
          }
        : {
            telNum1: '',
            telNum2: '',
            telNum3: '',
          },
    );
    formData.init.setContactExtension(property?.contactExtension);
    formData.init.setContactAccept(property?.contactAccept);
  }, [carProperty, paymentMethods, property]);

  useEffect(() => {
    setProcedureType(
      localStorageProcedureType === 'PUBLIC_SALE'
        ? 'インターネット公売'
        : '公有財産売却',
    );
  }, [localStorageProcedureType]);

  useEffect(() => {
    const baseNotRequiredKeys = [
      // 2. 物件情報
      'printOrderValid',
      'isNextBuyAplValid',
      'categoryRequiredValid',
      'recommendPropertiesValid',
      'videoUrlValid',

      // 3.登録事項など証明書の記載内容
      'effectiveDateValid',
      'displayRegisterNumberValid',
      'registerDateValid',
      'firstRegisterDateValid',
      'purposeOtherValid',
      'privateOrBusinessValid',
      'statusSafetyTermValid',
      'manufactureOtherValid',
      'displayProductNumberValid',
      'safetyTermValid',

      // 4.基本情報
      'transmissionTypeValid',
      'handleTypeValid',
      'existsCarMaintenanceSheetValid',
      'otherValid',
      'descriptionValid',
      'statusCarMileageValid',
      'mapUrlValid',

      // 5. 物件に関するお問い合わせ先
      'inquiryNameValid',
      'inquiryOfficeValid',
      'inquiryEmailValid',
      'inquiryTelValid',
      'inquiryTelNum1Valid',
      'inquiryTelNum2Valid',
      'inquiryTelNum3Valid',
      'inquiryExtensionValid',
      'inquiryAcceptValid',
      'inquiryNoteValid',

      // 8. 落札後の連絡先
      'contactOfficeValid',
      'contactEmailValid',
      'contactTelValid',
      'contactTelNum1Valid',
      'contactTelNum2Valid',
      'contactTelNum3Valid',
      'contactExtensionValid',
      'contactAcceptValid',
    ];

    const isPublicSale = localStorageProcedureType === 'PUBLIC_SALE';
    const conditionNotRequiredKeys = isPublicSale
      ? ['resolutionDateValid', 'contractedLimitValid']
      : [];

    const notRequiredKeys = [
      ...baseNotRequiredKeys,
      ...conditionNotRequiredKeys,
      //...(includeAddressKeys ? addressRelatedKeys : []),
    ];

    const validationResults = Object.entries(formData.validationMessage).map(
      ([key, validationMessage]) => {
        const isValid = notRequiredKeys.includes(key)
          ? validationMessage === null || validationMessage === 'TRUE'
          : validationMessage === 'TRUE';
        return { key, validationMessage, isValid };
      },
    );

    const allValid = validationResults.every(({ isValid }) => isValid);
    setIsValid(allValid);
  }, [formData]);

  const handleConfirm = (e) => {
    e.preventDefault();
    setOnConfirm(true);
  };

  const handleBackForm = (e) => {
    e.preventDefault();
    setOnConfirm(false);
  };

  const handleComplete = (e) => {
    e.preventDefault();
    setOnComplete(true);
  };

  const getMethod = () => {
    if (property_id !== undefined) {
      return 'update';
    }
    return 'create';
  };

  const getTitle = () => {
    if (getMethod() === 'create') {
      return `物件情報（車）登録：${invitation?.name}`;
    }
    return `物件登録情報編集：${invitation?.name}`;
  };

  return (
    <div
      className="new-style_navi_form new-style_navi_object_create_real-estate"
      data-testid="property-info-car">
      {onComplete ? (
        <PropertyInfoCarComplete method={getMethod()} openWay={openWay} />
      ) : onConfirm ? (
        <PropertyInfoCarConfirm
          formData={formData}
          handleComplete={handleComplete}
          handleBackForm={handleBackForm}
          openWay={openWay}
        />
      ) : (
        <>
          <Breadcrumb
            breadcrumbs={breadcrumbs(
              `${getMethod()}`,
              'form',
              invitation?.name,
              auction_id,
              openWay,
            )}
          />
          <PropertyInfoGeneralPageDescription
            title={getTitle()}
            content={
              property_id === undefined
                ? '物件の登録を行います。入力が終わったら「確認する」ボタンをクリックしてください。'
                : '物件の編集を行います。入力が終わったら「確認画面」ボタンを押してください。'
            }
          />
          <PropertyInfoCarForm
            formData={formData}
            procedureType={procedureType}
            handleClick={handleConfirm}
            isValid={isValid}
          />
        </>
      )}
    </div>
  );
};

export default Car;
