import Button from 'interfaces/components/common/Button/Button.jsx';
import Modal2 from 'interfaces/components/common/Modal/Modal2.jsx';
import { useEffect, useState } from 'react';

import AfterWinningBid from './AfterWinningBid.jsx';
import ArticleDefaultTitle from '../ArticleDefaultTitle/ArticleDefaultTitle.jsx';

const AfterWinningBidModal = ({
  label,
  governmentName,
  procedureType,
  afterWinningBid = undefined,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    setModalData(afterWinningBid);
  }, [afterWinningBid]);

  const handleOpen = () => {
    setIsOpen(true);
    document.documentElement.style.overflowY = 'hidden';
  };
  const handleClose = () => {
    setIsOpen(false);
    document.documentElement.style.overflowY = 'scroll';
  };

  return (
    <div>
      <a onClick={handleOpen}>{label}</a>
      <Modal2 isOpen={isOpen} handleClose={handleClose}>
        <div className="modal-content">
          <div className="pt-4 pl-4 pr-4">
            <ArticleDefaultTitle
              title={`${governmentName} (${modalData?.publicSaleType === 'AUCTION' ? 'せり売形式' : '入札形式'}) ${procedureType} - 落札後の注意事項`}
              className="gray"
            />
            {modalData ? (
              <AfterWinningBid data={modalData} procedureType={procedureType} />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="close-button-wrap">
          <Button
            text="閉じる"
            height="44px"
            width="100px"
            onClick={handleClose}
          />
        </div>
      </Modal2>
    </div>
  );
};
export default AfterWinningBidModal;
