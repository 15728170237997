import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ListItem = styled.div`
  text-indent: -0.5em;
  margin-left: 0.5em;
  line-height: 1.4em;
  margin-bottom: 4px;
`;

const StyledLink = styled(Link)`
  &:before {
    position: relative;
    display: inline;
    content: '-';
    margin-right: 4px;
  }
`;

const NotificationListItem = ({ id, title }) => {
  return (
    <ListItem>
      <StyledLink
        to={`/news#news-item--${id}`}
        data-testid={`notification-list-item-${id}`}>
        {title}
      </StyledLink>
    </ListItem>
  );
};
export default NotificationListItem;
