
export class ReceiverAddress {
  #receiverAddress

  constructor(receiverAddress) {
    this.#receiverAddress = receiverAddress;
  }

  get() {
    return this.#receiverAddress
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
