import Button from "../Button.jsx";

const CancelButton = ({ text = 'キャンセル', className = 'edit' , clickHandler }) => {

  return (
    <Button
      text={text}
      clickHandler={clickHandler}
      className={className}
    />
  )
}

export default CancelButton