
export class EmailSentAt {
    #emailSentAt

    constructor(emailSentAt) {
        this.#emailSentAt = emailSentAt;
    }

    get() {
        return this.#emailSentAt
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
