
export class PropertyBidSystemFee {
  #propertyBidSystemFee

  constructor(propertyBidSystemFee) {
    this.#propertyBidSystemFee = propertyBidSystemFee;
  }

  get() {
    return this.#propertyBidSystemFee
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
