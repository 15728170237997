import Heading2 from 'interfaces/components/common/Heading/Heading2.jsx';
import { useContext } from 'react';

import SimpleNotice from './SimpleNotice.jsx';
import { DashboardContext } from '../../../../pages/Dashboard/entities/DashboardContext';
import '../../../../css/common/simple-notice-list.css';

const SimpleNoticeList = () => {
  const { notices } = useContext(DashboardContext);

  return (
    <div className="simple-notice-list" data-testid="simple-notice-list">
      <Heading2 title="お知らせ" linkLabel="お知らせ一覧" link="/navi/notice" />
      <div className="simple-notice-wrap">
        {notices ? (
          notices.map((notice) => (
            <SimpleNotice
              key={notice.id}
              date={notice.openAt}
              title={notice.title}
              noticeId={notice.id}
              status={notice.isImportant}
            />
          ))
        ) : (
          <p>loading...</p>
        )}
      </div>
    </div>
  );
};

export default SimpleNoticeList;
