import { useEffect, useState } from 'react';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

import { findById as getGovernment } from '../../../../domain/Government/services/Government';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { NAVI } from '../../../../shared/utils/helper/constants.js';
import { OrganizationRegisterContext } from '../entities/OrganizationRegisterContext.js';

const OrganizationEditProvider = ({ type, children }) => {
  const { localStorageGovernmentId } = useLocalStorage();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [government, setGovernment] = useState(undefined);

  if (type === NAVI) {
    useEffect(() => {
      getGovernment(type, localStorageGovernmentId).then((government) => {
        setGovernment(government);
      });
    }, [localStorageGovernmentId]);
  }

  return (
    <OrganizationRegisterContext.Provider
      value={{
        authState,
        government,
      }}>
      {children}
    </OrganizationRegisterContext.Provider>
  );
};

export default OrganizationEditProvider;
