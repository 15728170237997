// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard .kmanager-auction-table-record > td {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', YuGothic,
    'Yu Gothic', 'Hiragino Kaku Gothic ProN', Meiryo, verdana, 'Droid Sans',
    Roboto, sans-serif;
  font-size: 0.875rem;
  height: 48px;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.87);
  border-top: thin solid rgba(0, 0, 0, 0.12);
  border-right: thin solid hsla(0, 0%, 50.2%, 0.12) !important;
  border-bottom: thin solid hsla(0, 0%, 50.2%, 0.12) !important;
}

.dashboard .kmanager-auction-table-record > td > div {
  white-space: nowrap;
}

.dashboard .kmanager-auction-table-control {
  text-align: center;
}

.dashboard .kmanager-auction-table-control svg {
  fill: rgba(0, 0, 0, 0.54);
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Dashboard/kmanager-auction-table-record.css"],"names":[],"mappings":"AAAA;EACE;;sBAEoB;EACpB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,0BAA0B;EAC1B,0CAA0C;EAC1C,4DAA4D;EAC5D,6DAA6D;AAC/D;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".dashboard .kmanager-auction-table-record > td {\n  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', YuGothic,\n    'Yu Gothic', 'Hiragino Kaku Gothic ProN', Meiryo, verdana, 'Droid Sans',\n    Roboto, sans-serif;\n  font-size: 0.875rem;\n  height: 48px;\n  padding: 0 16px;\n  color: rgba(0, 0, 0, 0.87);\n  border-top: thin solid rgba(0, 0, 0, 0.12);\n  border-right: thin solid hsla(0, 0%, 50.2%, 0.12) !important;\n  border-bottom: thin solid hsla(0, 0%, 50.2%, 0.12) !important;\n}\n\n.dashboard .kmanager-auction-table-record > td > div {\n  white-space: nowrap;\n}\n\n.dashboard .kmanager-auction-table-control {\n  text-align: center;\n}\n\n.dashboard .kmanager-auction-table-control svg {\n  fill: rgba(0, 0, 0, 0.54);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
