
export class EstimatedSystemUsagePaymentDate {
  #estimatedSystemUsagePaymentDate

  constructor(estimatedSystemUsagePaymentDate) {
    this.#estimatedSystemUsagePaymentDate = estimatedSystemUsagePaymentDate;
  }

  get() {
    return this.#estimatedSystemUsagePaymentDate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
