import { useEffect, useState } from 'react';

import { get as getManagementMessages } from '../../../../domain/News/services/Message.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { MessageContext } from '../entities/MessageContext.js';
const MessageProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [managementMessages, setManagementMessages] = useState([]);
  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getManagementMessages(type).then((result) => {
        setManagementMessages(result);
      });
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      getManagementMessages(type).then((result) => {
        setManagementMessages(result);
      });
    }, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getManagementMessages(type).then((result) => {
        setManagementMessages(result);
      });
    }, []);
  }

  return (
    <MessageContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        managementMessages,
      }}>
      {children}
    </MessageContext.Provider>
  );
};

export default MessageProvider;
