import { ProcedureType } from 'shared/utils/constants/auction.js';
import { DepositPayment } from 'shared/utils/constants/property.js';

import imgCardAE from 'interfaces/assets/image/credit_logo/img_cardAE.png';
import imgCardDiners from 'interfaces/assets/image/credit_logo/img_cardDiners.png';
import imgCardJCB from 'interfaces/assets/image/credit_logo/img_cardJCB.png';
import imgCardMaster from 'interfaces/assets/image/credit_logo/img_cardMaster.png';
import imgCardVISA from 'interfaces/assets/image/credit_logo/img_cardVISA.png';
import RadioInput from 'interfaces/components/common/Input/RadioInput';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel';
import { useEffect, useState } from 'react';

const AplTypeInput = ({ data, applicantInfo, setApplicantInformation }) => {
  const [aplTypeList, setAplTypeList] = useState([]);
  const isBankTransfer =
    data.depositPayment === DepositPayment.BANK_TRANSFER.string ||
    data.depositPayment === DepositPayment.BOTH.string;
  const isCreditCard =
    data.depositPayment === DepositPayment.CREDIT_CARD.string ||
    data.depositPayment === DepositPayment.BOTH.string;

  useEffect(() => {
    if (!isBankTransfer && !isCreditCard) {
      return;
    }
    if (data.depositPayment === DepositPayment.BANK_TRANSFER.string) {
      setAplTypeList([{ id: 1, label: '銀行振込など', value: 'OFFLINE' }]);
      return;
    }
    if (data.depositPayment === DepositPayment.CREDIT_CARD.string) {
      setAplTypeList([{ id: 1, label: 'クレジットカード', value: 'ONLINE' }]);
      return;
    }
    setAplTypeList([
      { id: 1, label: '銀行振込など', value: 'OFFLINE' },
      { id: 2, label: 'クレジットカード', value: 'ONLINE' },
    ]);
  }, []);

  if (!isBankTransfer && !isCreditCard) {
    return;
  }

  const handleApplicantInfoChange = (params) => {
    setApplicantInformation({
      ...applicantInfo,
      ...params,
    });
  };

  return (
    <tr data-testid="apl-type-input">
      <th>
        {data.procedureType === ProcedureType.PUBLIC_SALE.string
          ? '公売保証金の納付方法'
          : '入札保証金の納付方法'}
        <RequiredLabel />
      </th>
      <td>
        {aplTypeList.map((method) => (
          <RadioInput
            key={method.id}
            item={method}
            label={method.label}
            value={applicantInfo.aplType}
            handleClick={() => {
              handleApplicantInfoChange({ aplType: method.value });
            }}
          />
        ))}
        {isCreditCard && (
          <div className="flex gaps ml-8 wrap" data-testid="credit-card-list">
            <div className="box">
              <img src={imgCardVISA} alt="VISA" />
            </div>
            <div className="box">
              <img src={imgCardMaster} alt="mastercard" />
            </div>
            <div className="box">
              <img src={imgCardAE} alt="アメリカン・エキスプレス" />
            </div>
            <div className="box">
              <img src={imgCardJCB} alt="JCB" />
            </div>
            <div className="box">
              <img src={imgCardDiners} alt="ダイナースクラブカード" />
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export default AplTypeInput;
