import axios from 'axios';
import apiHeaders from 'shared/utils/helper/apiHeaders.js';
import {API_BASE_URL} from "../../config/envConstants.js";

export const createNaviGovernmentUsersData = async (body) => {
  return await axios.post(`${API_BASE_URL}/api/v1/navi/government_users`,
    body, {headers: apiHeaders}
  );

};
export const updateNaviGovernmentUsersData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/navi/government-users/${id}`,
    body, {headers: headers}
  );
};
export const deleteNaviGovernmentUsersData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/navi/government-users/${id}`, {
      headers: headers,
    },);
};