import { get as getGovernments } from "domain/Government/services/Government.js"

import { useEffect, useState } from 'react';

import { FORGET_PASSWORD, LOGIN, SIGN_UP } from "../../../../shared/utils/helper/authState.js";
import { SERVICE, NAVI, KMANAGER } from "../../../../shared/utils/helper/constants.js";
import { GovernmentListContext } from '../entities/GovernmentListContext.js';


const GovernmentListProvider = ({ type, children }) => {
  const authState = { LOGIN: LOGIN, SIGN_UP: SIGN_UP, FORGET_PASSWORD: FORGET_PASSWORD };
  const [governments, setGovernments] = useState([]);
  const [error, setError] = useState({})

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
    }, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getGovernments(type).then(result => {
        result.map((govData) => {
          if (govData.procedureType === "ASSET_SALE") {
            govData.isAsset = "登録"
          } else if (govData.procedureType === "PUBLIC_SALE") {
            govData.isPublic = "登録"
          } else if (govData.procedureType === "BOTH") {
            govData.isAsset = "登録"
            govData.isPublic = "登録"
          }
        })
        setGovernments(result);
      }).catch((err) => {
        console.log(err)
        setError(err)
      });
    }, []);
  }

  return (
    <GovernmentListContext.Provider value={{
      authState,
      governments,
      error,
    }}>
      {children}
    </GovernmentListContext.Provider>
  );
};

export default GovernmentListProvider;
