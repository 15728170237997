import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ImageDetailPropertyCardBodyBottom from './ImageDetailPropertyCardBodyBottom';
import PropertyCardImageTags from '../PropertyCardImageTags';
import { isFalsy } from '../helper/isFalsy';
import { price } from '../helper/price';
import { propertyStatus } from 'domain/Property/entities/constants/propertyStatus';

import {
  PropertyInfo,
  PropertyInfoLabel,
  PropertyInfoValue,
} from 'interfaces/components/common/PropertyInfo/index';

const PropertyStatus = styled.div`
  position: relative;
  font-size: 0.6rem;
  white-space: nowrap;
  background-color: #fff;
  color: var(--text-base);
  border: 1px solid var(--line-base);
  display: inline-block;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px;
  font-weight: 700;
  line-height: 1.2em;

  .kservice & {
    position: relative;
    font-size: 0.6rem;
    white-space: nowrap;
    background-color: #fff;
    color: #38342d;
    border: 1px solid #e8e6e6;
    display: inline-block;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 4px;
    font-weight: 700;
    line-height: 1.2em;
  }
`;

const PropertyName = styled.div`
  height: 44px;
  max-height: 44px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  .kservice & {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
  }
`;

const ImageDetailPropertyCardBody = ({ data }) => {
  const isSuspension = data.status === 'CANCEL';

  return (
    <div
      className="px-2 py-0 mx-0 my-1"
      data-testid="image-detail-property-card-body">
      <div className="ml-n1 tags">
        <PropertyCardImageTags tags={data.tags} className="property-tags" />
      </div>
      <PropertyStatus className="my-1" data-testid="property-status">
        {isFalsy(data.status) ? '' : propertyStatus[data.status]}
      </PropertyStatus>
      <PropertyName className="subtitle pa-0 pb-1">
        <Link
          to={`/items/${data.id}`}
          className="link font-weight-bold"
          data-testid="property-link">
          {isFalsy(data.name) ? '' : data.name}
        </Link>
      </PropertyName>
      <div>
        <PropertyInfo className="flex justify-space-between align-center py-1">
          <PropertyInfoLabel className="ma-0">物件価格</PropertyInfoLabel>
          <PropertyInfoValue
            className="ma-0 text-right font-weight-bold text-body- tex-body-1"
            data-testid="property-price">
            {data.status === 'CANCEL' ? '0' : price(data)}
            <small className="ml-1">円</small>
          </PropertyInfoValue>
        </PropertyInfo>
        {!isSuspension && <ImageDetailPropertyCardBodyBottom data={data} />}
      </div>
    </div>
  );
};

export default ImageDetailPropertyCardBody;
