import numberButton from 'interfaces/css/common/Pagination/page-number-button.module.css';


const PageNumberButton = ({ pageNumber, currentPage, setCurrentPage }) => {
  if (!pageNumber) {
    return <span className={numberButton.page_number_button_wrap}>...</span>;
  }

  const handleClick = () => {
    setCurrentPage(pageNumber)
    window.scrollTo(0, 0);
  };

  return (
    <span className={numberButton.page_number_button_wrap}>
      <button
        className={`${numberButton.page_number_button} ${currentPage === pageNumber && numberButton.current_page}`}
        onClick={handleClick}
        data-testid="page-number-button"
      >
        {pageNumber}
      </button>
    </span>
  );
};

export default PageNumberButton;
