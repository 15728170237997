
export class ContactPaEmail {
    #contactPaEmail

    constructor(contactPaEmail) {
        this.#contactPaEmail = contactPaEmail;
    }

    get() {
        return this.#contactPaEmail
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
