
export class BillingDate {
  #billingDate

  constructor(billingDate) {
    this.#billingDate = billingDate;
  }

  get() {
    return this.#billingDate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
