import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle.jsx';

import DeliveryRegistrationInfoCaption from './DeliveryRegistrationInfoCaption.jsx';

const DeliveryRegistrationInfoHead = ({ isShippingRegistration, property }) => {
  return (
    <div>
      {!isShippingRegistration ? (
        <ArticleDefaultTitle title="落札後の連絡先" />
      ) : (
        <>
          <ArticleDefaultTitle title="引渡登録情報の確認" />
          <DeliveryRegistrationInfoCaption property={property} />
        </>
      )}
    </div>
  );
};

export default DeliveryRegistrationInfoHead;
