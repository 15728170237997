import AuctionFooter from "./AuctionFooter/AuctionFooter.jsx";
import GovernmentFooter from "./GovernmentFooter/GovernmentFooter.jsx";
import ManagementFooter from "./ManagementFooter/ManagementFooter.jsx";
import '../../../css/common/footer.css'

const FooterType = (props) => {
  return {
    SERVICE: <AuctionFooter {...props}/>,
    NAVI: <GovernmentFooter {...props}/>,
    KMANAGER: <ManagementFooter {...props}/>
  }
}

export const Footer = ({ type, props }) => {
  const footer = FooterType(props)
  return footer[type]
};

export default Footer