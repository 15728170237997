import { validateEmailFormatCheck, validateRangeLength } from "../../../../application/validations.js";

export class ContactEmail {
  #contactEmail

  constructor(contactEmail) {
    this.#contactEmail = contactEmail;
  }

  get() {
    return this.#contactEmail
  }

  get isValid() {
    return [
      this.validateEmailFormatCheck,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateEmailFormatCheck() {
    return validateEmailFormatCheck(this.#contactEmail)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#contactEmail, 0, 128)
  }
}
