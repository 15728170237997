import Heading2 from 'interfaces/components/common/Heading/Heading2.jsx';

import UpcomingAuctionTable from './UpcomingAuctionTable.jsx';

import 'interfaces/css/Dashboard/upcoming-auctions.css'

const UpcomingAuctions = () => {
  return (
    <div className="upcoming-auctions" data-testid="upcoming-auctions">
      <Heading2 title="実施一覧"/>
      <p className="upcoming-auctions-caption" data-testid="upcoming-auctions-caption">
        実施されている官公庁オークションの一覧を表示しています。<br/>
        物件情報の編集をする場合は、公告名をクリックしてください。
      </p>
      <UpcomingAuctionTable/>
    </div>
  );
};

export default UpcomingAuctions;
