import React, { useState } from 'react';

import ServiceTab from './ServiceTab.jsx';
import 'interfaces/css/common/switcher.css';

const Switcher = ({ labels, counts, alwaysVisibleComponent, children }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div>
      <div className="switcher" data-testid="switcher">
        <div className="switcher-tabs flex">
          {labels?.map((label, index) => (
            <ServiceTab
              key={index}
              tabIndex={index}
              label={label}
              isActive={index === activeTabIndex}
              setActiveTab={setActiveTabIndex}
              {...(counts && { disabled: counts[index] === 0 })}
            />
          ))}
        </div>
        <hr className="switcher-divider divider" />
      </div>
      {alwaysVisibleComponent}
      <div>{React.Children.toArray(children)[activeTabIndex]}</div>
    </div>
  );
};

export default Switcher;
