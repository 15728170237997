import useWindowResize from 'interfaces/components/Schedule/service/hook/useWindowResize.js';
import { useEffect, useState } from 'react';
import isLeapYear from 'shared/utils/helper/isLeapYear.js';

export const COMMON_YEAR_DATE = 365;
export const LEAP_YEAR_DATE = 366;

export const useCalcScheduleMagnification = (ref, initViewMonthLength) => {
  const { windowWidth } = useWindowResize();
  const [viewMonthLength, setViewMonthLength] = useState(initViewMonthLength);
  const [magnification, setMagnification] = useState(0);

  const today = new Date();
  const isLeap = isLeapYear(today.getFullYear());

  useEffect(() => {
    if (ref.current) {
      // MEMO: 画面サイズから表示領域をの倍率を計算するロジック
      const scheduleViewWidth = ref.current.offsetWidth;
      const yearDateLength = isLeap ? LEAP_YEAR_DATE : COMMON_YEAR_DATE;
      setMagnification(scheduleViewWidth * (12 / viewMonthLength) / yearDateLength);
    }
  }, [windowWidth, viewMonthLength]);

  return { magnification, setViewMonthLength };
};
