import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel.jsx';

import ShippingInfoForm from './ShippingInfoForm.jsx';

import styled from 'styled-components';

const CautionBlock = styled.div`
  border: 3px solid #ff0000;
  background-color: #ffffff;
  padding: 10px;
  margin: 5px;
`;

const ShippingInfoRegistration = ({
  data,
  previousStep,
  nextStep,
  shippingInfo,
  setShippingInfo,
}) => {
  return (
    <div>
      <CautionBlock>
        以下の項目を入力または選択して「確認」ボタンを押してください。
        <br />
        （<RequiredLabel />
        は必須項目）
      </CautionBlock>
      <ShippingInfoForm
        propertyData={data}
        previousStep={previousStep}
        nextStep={nextStep}
        shippingInfo={shippingInfo}
        setShippingInfo={setShippingInfo}
      />
    </div>
  );
};

export default ShippingInfoRegistration;
