// react/src/shared/utils/helper/serviceValidators.js
import axios from 'axios';

import {
  validateNumber,
  validateLength,
  validatePrefecture,
  validateRangeLength,
  hasEmoji,
  notInclude,
  validateEmptyString,
  isLessThanCriteria,
  isExceedCriteria,
  validateSelected,
  validateAgeCheck,
  isFullwidthKatakana,
  validateCurrentBidCheck,
  validateAlphanum,
  validateNotIncludes,
  removeDashesAndSpaces,
  validateParamsMatch,
  validatePasswordFormat,
  validateRequiredFields,
  isOverAmount,
} from '../../../application/validations';

// ラジオボタンの選択肢が未選択
export function validateSelectRequired(value) {
  return validateSelected(value);
}

// 氏名* 	文字数が1文字以上32文字以内
export function validateNameLength(name) {
  return validateRangeLength(name, 1, 32);
}

// 氏名* 姓	文字数が1文字以上32文字以内
export function validateFirstNameLength(first_name) {
  return validateRangeLength(first_name, 1, 32);
}

// 氏名* 姓	絵文字・機種依存文字が含まれていない
export function validateFirstNameHasEmoji(first_name) {
  return hasEmoji(first_name);
}

// 氏名* 名	文字数が1文字以上32文字以内
export function validateLastNameLength(last_name) {
  return validateRangeLength(last_name, 1, 32);
}

// 氏名* 名	絵文字・機種依存文字が含まれていない
export function validateLastNameHasEmoji(last_name) {
  return hasEmoji(last_name);
}

// 氏名* カナ	文字数が1文字以上64文字以内
export function validateFirstNameKanaLength(first_name_kana) {
  return validateRangeLength(first_name_kana, 1, 64);
}

// 氏名* カナ  文字列がカタカナである
export function validateFirstNameKanaCheck(corp_name_kana) {
  return isFullwidthKatakana(corp_name_kana);
}

// 氏名* カナ	絵文字・機種依存文字が含まれていない
export function validateFirstNameKanaHasEmoji(first_name_kana) {
  return hasEmoji(first_name_kana);
}

// 氏名* カナ	文字数が1文字以上64文字以内
export function validateLastNameKanaLength(last_name_kana) {
  return validateRangeLength(last_name_kana, 1, 64);
}

// 氏名* カナ  文字列がカタカナである
export function validateLastNameKanaCheck(corp_name_kana) {
  return isFullwidthKatakana(corp_name_kana);
}

// 氏名* カナ	絵文字・機種依存文字が含まれていない
export function validateLastNameKanaHasEmoji(last_name_kana) {
  return hasEmoji(last_name_kana);
}

// 生年月日*	生年月日が選択されている
export function validateBirthdaySelected(birthday) {
  return validateSelected(birthday);
}

// 生年月日*	年齢が18歳以上である
export function validateBirthdayCheck(birthday) {
  return validateAgeCheck(birthday, 18);
}

// 法人名	文字数が128文字以内
export function validateCorpNameLength(corp_name) {
  return validateRangeLength(corp_name, 0, 128);
}

// 法人名	絵文字・機種依存文字が含まれていない
export function validateCorpNameHasEmoji(corp_name) {
  return hasEmoji(corp_name);
}

// 法人名 カナ	文字数が128文字以内
export function validateCorpNameKanaLength(corp_name_kana) {
  return validateRangeLength(corp_name_kana, 0, 128);
}

// 法人名 カナ	文字列がカタカナである
export function validateCorpNameKanaCheck(corp_name_kana) {
  return isFullwidthKatakana(corp_name_kana);
}

// 法人名 カナ	絵文字・機種依存文字が含まれていない
export function validateCorpNameKanaHasEmoji(corp_name_kana) {
  return hasEmoji(corp_name_kana);
}

// 郵便番号*	文字列が半角数字のみである
export function validateZipCodeNumber(zip_code) {
  return validateNumber(zip_code);
}

// 郵便番号-前*	文字数が3文字である
export function validateZipcodeFirstJustLength(zip_code_first) {
  return validateLength(zip_code_first, 3);
}

// 郵便番号-後*	文字数が4文字である
export function validateZipcodeLastJustLength(zip_code_last) {
  return validateLength(zip_code_last, 4);
}

// 都道府県*	都道府県が選択されている
export function validatePrefectureSelected(prefecture) {
  return validatePrefecture(prefecture);
}

// 市区町村番地*	文字数が1文字以上255文字以内
export function validateCityLength(city) {
  return validateRangeLength(city, 1, 255);
}

// 市区町村番地*	絵文字・機種依存文字が含まれていない
export function validateCityHasEmoji(city) {
  return hasEmoji(city);
}

// 電話番号*	ハイフンが含まれていない
export function validateTelNumberHyphen(tel_number) {
  return validateNotIncludes(tel_number, '-');
}

// 電話番号	半角数字以外が含まれていないか
export function validateTelNumberNumber(tel_number) {
  return validateNumber(tel_number);
}

// 電話番号*	10桁または11桁入力されている
export function validateTelNumberRangeLength(tel_number) {
  return validateRangeLength(tel_number, 10, 11);
}

// 電話番号*	空白が含まれていない
export function validateTelNumberBlankCheck(tel_number) {
  return validateNotIncludes(tel_number, ' ');
}

// 新しいメールアドレスを入力	「@」が含まれている
export function validateEmailIncludesCheck(email) {
  return validateNotIncludes(email, '@');
}

// 新しいメールアドレスを入力	半角英数字以外含まれていない
export function validateEmailAlphanumCheck(email) {
  return validateAlphanum(email);
}

// 新しいメールアドレスを入力	ピリオド、ハイフン、アンダーバー以外が含まれていない
export function validateEmailCharacterCheck(email) {
  return validateNotIncludes(email, ['.', '-', '_']);
}

// 新しいメールアドレスを入力	文字数が128文字以内（varchar128型）
export function validateEmailLength(email) {
  return validateRangeLength(email, 0, 128);
}

// パスワード	半角のアルファベットの小文字と大文字と半角数字を８文字以上
export function validatePasswordCheck(password) {
  return validatePasswordFormat(password);
}

// パスワード	文字数が128文字以内
export function validatePassLength(password) {
  return validateRangeLength(password, 0, 128);
}

// パスワード	新しいパスワードと確認用パスワードが一致している
export function validatePasswordBothCheck(new_password, check_password) {
  return validateParamsMatch(new_password, check_password);
}

// パスワード	変更前のパスワードが正しい
export function validateCurrentPasswordCheck(user_id, input_password) {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/v1/auction-users/confirm_password`,
    { headers: { user_id: user_id, password: input_password } },
  );
}

// スマートフォンの電話番号	090・080・070が含まれている
export function validateMobilePhoneFormat(mobile_phone_number) {
  return validateNotIncludes(mobile_phone_number, ['090', '080', '070']);
}

// スマートフォンの電話番号	半角数字以外が含まれていない
export function validateMobilePhoneNumber(mobile_phone_number) {
  return validateNumber(mobile_phone_number);
}

// スマートフォンの電話番号	11桁入力されている
export function validateMobilePhoneLength(mobile_phone_number) {
  return validateLength(mobile_phone_number, 11);
}

// スマートフォンの電話番号	空白が含まれていない
export function validateMobilePhoneBlankCheck(mobile_phone_number) {
  return validateNotIncludes(mobile_phone_number, ' ');
}

// スマートフォンの電話番号	ハイフンが含まれていない
export function validateMobilePhoneHyphenCheck(mobile_phone_number) {
  return validateNotIncludes(mobile_phone_number, '-');
}

// 「更新する」ボタンクリック	必須項目がすべて入力されている
export function validateUpdateClickedRequired(update, required_list) {
  return validateRequiredFields(update, required_list);
}

// 「上記内容に同意のうえ、次のステップへ」ボタンクリック	必須項目がすべて入力されている
export function validateAgreeClickedRequired(agree, required_list) {
  return validateRequiredFields(agree, required_list);
}

// 運送業者	配送業者が選択されている
export function validateCarrierSelected(carrier) {
  return validateSelected(carrier);
}

// 運送業者名(その他を選択した場合に表示)	文字数が1文字以上255文字以内
export function validateCarrierNameLength(carrier_name) {
  return validateRangeLength(carrier_name, 1, 255);
}

// 運送業者名(その他を選択した場合に表示)	絵文字・機種依存文字が含まれていない
export function validateCarrierNameHasEmoji(carrier_name) {
  return hasEmoji(carrier_name);
}

// 運送業者の電話番号(その他を選択した場合に表示)	半角数字以外が含まれていない
export function validateCarrierTelNumber(carrier_tel) {
  return validateNumber(carrier_tel);
}

// 運送業者の電話番号(その他を選択した場合に表示)	文字数が合計で10文字以上11文字以下である
export function validateTelLength(carrier_tel) {
  return validateRangeLength(carrier_tel, 10, 11);
}

// 運送業者の電話番号(その他を選択した場合に表示)	「-」と空白を除外する
export function validateCarrierTelCheck(carrier_tel) {
  return removeDashesAndSpaces(carrier_tel);
}

// 手配について	手配の方法が選択されている
export function validateArrangementsSelected(arrangements) {
  return validateSelected(arrangements);
}

// 梱包について	梱包の依頼が選択されている
export function validatePackingSelected(packing) {
  return validateSelected(packing);
}

// 氏名	氏名が選択されている
export function validateNameSelected(name) {
  return validateSelected(name);
}

// 変更理由	文字数が50文字以内
export function validateReasonLength(reason) {
  return validateRangeLength(reason, 0, 50);
}

// 変更理由	絵文字・機種依存文字が含まれていない
export function validateReasonHasEmoji(reason) {
  return hasEmoji(reason);
}

// 納付予定日 *	日付が選択されている
export function validateFixedDateSelected(fixed_date) {
  return validateSelected(fixed_date);
}

// 希望日 *	日付が選択されている
export function validateDayHopeSelected(day_hope) {
  return validateSelected(day_hope);
}

// 希望時間帯 * 	時間帯が選択されている
export function validateTimeHopeSelected(time_hope) {
  return validateSelected(time_hope);
}

// ご要望欄	文字数が255文字以内
export function validateRequestColumnLength(request_column) {
  return validateRangeLength(request_column, 1, 255);
}

// ご要望欄	絵文字・機種依存文字が含まれていない
export function validateLeaveMassageHasEmoji(leave_message) {
  return hasEmoji(leave_message);
}

// 入札額	せり売形式の現在価格が1円～1,000円未満の場合,入札額が現在価格より10円以上である
// 入札額	せり売形式の現在価格が1,000円～5,000円未満の場合,入札額が現在価格より100円以上である
// 入札額	せり売形式の現在価格が5,000円～1万円未満の場合,入札額が現在価格より250円以上である
// 入札額	せり売形式の現在価格が1万円～5万円未満の場合,入札額が現在価格より500円以上である
// 入札額	せり売形式の現在価格が5万円～の場合,入札額が現在価格より1,000円以上である
export function validateEntryAmountCheck(current_price, entry_amount) {
  return validateCurrentBidCheck(current_price, entry_amount);
}

// 入札額	入札額半角数字以外が含まれていないか
export function validateBidPriceHalfWidthNumber(amount_tendered) {
  return validateNumber(amount_tendered);
}

// 入札額	入札額最低入札額より小さい金額ではないか
export function validateBidPriceLowerThanMinBid(
  amount_tendered,
  minimum_charge,
) {
  return isLessThanCriteria(amount_tendered, minimum_charge);
}

// 入札額	入札額入力値が300億円よりも大きくないか
export function validateBidPriceLength(amount_tendered) {
  return isLessThanCriteria(amount_tendered, 30000000000);
}

// 金額範囲	金額範囲最高金額が最低金額よりも大きいか
export function validateDateFirstRegistrationRangeLength(
  range_maximum_amount,
  minimum_amount,
) {
  return isExceedCriteria(range_maximum_amount, minimum_amount);
}

// 郵便番号	郵便番号文字数が4文字であるか
export function validatePostalCodeLength(postalCode) {
  return validateLength(postalCode, 4);
}

// 都道府県	都道府県が選択されているか
export function validatePrefectureRequired(prefecture) {
  return validatePrefecture(prefecture);
}

// 市区町村番地	市区町村番地文字数が1文字以上255文字以内か
export function validateAddressRangeLength(address) {
  return validateRangeLength(address, 1, 255);
}

// 市区町村番地	市区町村番地絵文字・機種依存文字が含まれていないか
export function validateAddressHasEmoji(address) {
  return hasEmoji(address);
}

// 電話番号	電話番号ハイフンが含まれていない
export function validateTelNotInclude(tel) {
  return notInclude(tel, ['-']);
}

// 電話番号	電話番号10桁または11桁入力されている
export function validateTelRangeLength(tel) {
  return validateRangeLength(tel, 10, 11);
}

// 電話番号	電話番号空白が含まれていない
export function validateTelNotIncludeSpace(tel) {
  return notInclude(tel, [' ']);
}

// クレジットカード番号	クレジットカード番号半角数字以外が含まれていないか
export function validateCreditCardNumHalfWidthNumber(cardNum) {
  return validateNumber(cardNum);
}

// クレジットカード番号	クレジットカード番号4桁入力されている
export function validateCreditCardNumLength(cardNum) {
  return validateLength(cardNum, 4);
}

// クレジットカード有効期限	クレジットカード有効期限クレジットカード有効期限が入力されている
export function validateCreditCardExpirationRequired(expiration) {
  return validateEmptyString(expiration);
}

// クレジットカードセキュリティコード	クレジットカードセキュリティコードセキュリティコードが入力されている
export function validateCreditCardSecurityCodeRequired(securityCode) {
  return validateEmptyString(securityCode);
}

// クレジットカードセキュリティコード	クレジットカードセキュリティコード半角数字以外が含まれていないか
export function validateCreditCardSecurityCodeHalfWidthNumber(securityCode) {
  return validateNumber(securityCode);
}

// 最低金額より指定した最高金額が高く設定されているか
export function validateLessMinimumAmount(value, min) {
  return isOverAmount(value, min);
}

// 確認番号 6桁で入力されている
export function validateConfirmNumLength(confirmNumber) {
  return validateLength(confirmNumber, 6);
}

// 確認番号 半角数字以外が含まれていない
export function validateConfirmNumber(confirmNumber) {
  return validateNumber(confirmNumber);
}
