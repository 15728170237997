// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-panel {
    min-height: 30px;
}

.accordion-label {
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer;
}

.accordion-label a {
    color: var(--text-base);
}

.accordion-label .disabled {
    opacity: .5;
}

.accordion-toggle-button {
    height: 24px;
    width: 24px;
    outline: none;
    position: relative;
    display: inline-block;
    /*padding-left: 12px;*/
    color: var(--line-darken4);
    text-decoration: none;
}

.accordion-toggle-button.toggle-open:before {
    transform: rotate(90deg);
}

.accordion-toggle-button:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 5px;
    border-color: transparent transparent transparent var(--line-darken4);
    position: absolute;
    left: 10px;
    margin-top: -3px;
    transition: transform 0.25s ease;
}


.accordion-toggle-button {
    display: inline-flex;
    flex-shrink: 0;
}

.grand-child-category {
    margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/accordion-with-checkbox.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,qBAAqB;IACrB,sBAAsB;IACtB,0BAA0B;IAC1B,qBAAqB;AACzB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,WAAW;IACX,QAAQ;IACR,SAAS;IACT,mBAAmB;IACnB,2BAA2B;IAC3B,qEAAqE;IACrE,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,gCAAgC;AACpC;;;AAGA;IACI,oBAAoB;IACpB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".accordion-panel {\n    min-height: 30px;\n}\n\n.accordion-label {\n    margin-left: 5px;\n    text-decoration: underline;\n    cursor: pointer;\n}\n\n.accordion-label a {\n    color: var(--text-base);\n}\n\n.accordion-label .disabled {\n    opacity: .5;\n}\n\n.accordion-toggle-button {\n    height: 24px;\n    width: 24px;\n    outline: none;\n    position: relative;\n    display: inline-block;\n    /*padding-left: 12px;*/\n    color: var(--line-darken4);\n    text-decoration: none;\n}\n\n.accordion-toggle-button.toggle-open:before {\n    transform: rotate(90deg);\n}\n\n.accordion-toggle-button:before {\n    content: '';\n    width: 0;\n    height: 0;\n    border-style: solid;\n    border-width: 4px 0 4px 5px;\n    border-color: transparent transparent transparent var(--line-darken4);\n    position: absolute;\n    left: 10px;\n    margin-top: -3px;\n    transition: transform 0.25s ease;\n}\n\n\n.accordion-toggle-button {\n    display: inline-flex;\n    flex-shrink: 0;\n}\n\n.grand-child-category {\n    margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
