
export class PropertyImagePath {
    #propertyImagePath

    constructor(propertyImagePath) {
        this.#propertyImagePath = propertyImagePath;
    }

    get() {
        return this.#propertyImagePath
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
