import CommonInput from 'interfaces/components/common/Input/CommonInput.jsx';
import RadioInput from 'interfaces/components/common/Input/RadioInput.jsx';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel.jsx';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault.jsx';
import { memo } from 'react';
import { useValidate } from 'shared/hooks/useValidate.js';
import {
  JAPAN_POST,
  OTHER,
  SAGAWA,
  YAMATO,
} from 'shared/utils/constants/carrier.js';

export const carriers = [
  { id: 1, label: YAMATO.ja, value: YAMATO.en },
  { id: 2, label: SAGAWA.ja, value: SAGAWA.en },
  { id: 3, label: JAPAN_POST.ja, value: JAPAN_POST.en },
  { id: 4, label: OTHER.ja, value: OTHER.en },
];

const SelectCarrierInput = memo(function SelectCarrierInput({
  carrier,
  setCarrier,
  carrierTelCaption,
}) {
  const { handleValidates } = useValidate();
  const carrierNameValidateMessage = handleValidates(carrier.carrierName, [
    'no_message_required',
  ]);
  const carrierTelValidateMessage = handleValidates(carrier.carrierTel, [
    'no_message_required',
    'tel',
  ]);

  return (
    <>
      <CommonTableDefault className="shipping-info">
        <tr>
          <th>
            運送業者
            <RequiredLabel />
          </th>
          <td>
            {carriers.map((item) => (
              <RadioInput
                key={item.id}
                item={item}
                label={item.label}
                value={carrier.target}
                handleClick={() => {
                  let updateParams = { ...carrier, target: item.value };
                  setCarrier(updateParams);
                }}
              />
            ))}
          </td>
        </tr>
        {carrier.target === 'OTHER' && (
          <>
            <tr>
              <th>
                運送業者名
                <RequiredLabel />
              </th>
              <td>
                <CommonInput
                  type="text"
                  value={carrier.carrierName}
                  name="carrier-name"
                  placeholder="（例）サンプル運送"
                  handleChange={(e) =>
                    setCarrier({ ...carrier, carrierName: e.target.value })
                  }
                  validateMessage={carrierNameValidateMessage}
                />
              </td>
            </tr>
            <tr>
              <th>
                <p>
                  運送業者の
                  <br />
                  電話番号
                  <RequiredLabel />
                </p>
              </th>
              <td>
                <div className="w-expand">
                  <CommonInput
                    type="text"
                    value={carrier.carrierTel}
                    name="carrier-tel"
                    inputMode="numeric"
                    placeholder="（例）0312345678"
                    handleChange={(e) =>
                      setCarrier({ ...carrier, carrierTel: e.target.value })
                    }
                    validateMessage={carrierTelValidateMessage}
                  />
                  <p>
                    <small>{carrierTelCaption}</small>
                  </p>
                </div>
              </td>
            </tr>
          </>
        )}
      </CommonTableDefault>
      <div className="topic-input-caption">
        <p>【留意事項】</p>
        <p>1. 運送費用等は、買受人の負担となります。</p>
        <p>2. 運送業者によっては、公売財産を引き渡せない場合があります。</p>
      </div>
    </>
  );
});

export default SelectCarrierInput;
