import { useState } from 'react';

export function useGuidelineInputs() {
  const [isDisplayPledge, setIsDisplayPledge] = useState('DISPLAY');
  const [isDisplay1, setIsDisplay1] = useState('DISPLAY');
  const [isDisplay2, setIsDisplay2] = useState('HIDDEN');
  const [isDisplay3, setIsDisplay3] = useState('HIDDEN');
  const [isDisplay4, setIsDisplay4] = useState('HIDDEN');
  const [isDisplay5, setIsDisplay5] = useState('HIDDEN');
  const [isDisplay6, setIsDisplay6] = useState('HIDDEN');
  const [isDisplay7, setIsDisplay7] = useState('HIDDEN');
  const [isDisplay8, setIsDisplay8] = useState('HIDDEN');
  const [title1, setTitle1] = useState('');
  const [title2, setTitle2] = useState('');
  const [title3, setTitle3] = useState('');
  const [title4, setTitle4] = useState('');
  const [title5, setTitle5] = useState('');
  const [title6, setTitle6] = useState('');
  const [title7, setTitle7] = useState('');
  const [title8, setTitle8] = useState('');
  const [pledgeBody, setPledgeBody] = useState('');
  const [body1, setBody1] = useState('');
  const [body2, setBody2] = useState('');
  const [body3, setBody3] = useState('');
  const [body4, setBody4] = useState('');
  const [body5, setBody5] = useState('');
  const [body6, setBody6] = useState('');
  const [body7, setBody7] = useState('');
  const [body8, setBody8] = useState('');
  const inputs = {
    data: {
      isDisplayPledge,
      isDisplay1,
      isDisplay2,
      isDisplay3,
      isDisplay4,
      isDisplay5,
      isDisplay6,
      isDisplay7,
      isDisplay8,
      title1,
      title2,
      title3,
      title4,
      title5,
      title6,
      title7,
      title8,
      pledgeBody,
      body1,
      body2,
      body3,
      body4,
      body5,
      body6,
      body7,
      body8,
    },
    setters: {
      setIsDisplayPledge,
      setIsDisplay1,
      setIsDisplay2,
      setIsDisplay3,
      setIsDisplay4,
      setIsDisplay5,
      setIsDisplay6,
      setIsDisplay7,
      setIsDisplay8,
      setTitle1,
      setTitle2,
      setTitle3,
      setTitle4,
      setTitle5,
      setTitle6,
      setTitle7,
      setTitle8,
      setPledgeBody,
      setBody1,
      setBody2,
      setBody3,
      setBody4,
      setBody5,
      setBody6,
      setBody7,
      setBody8,
    },
  };
  return inputs;
}
