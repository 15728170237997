import styled from 'styled-components';

export const PropertyInfo = styled.div``;

export const PropertyInfoLabel = styled.p`
  font-size: 10px;
`;

export const PropertyInfoValue = styled.p`
  word-break: break-all;
`;
