import {
  DATA_EMPTY,
  DISPLAY,
} from 'domain/Property/entities/constants/property.js';

import { useEffect, useState } from 'react';

import AfterWinningBidTable from './AfterWinningBidTable.jsx';
import { asset_sale_important } from './SetAfterWinningBid/asset_sale_important.js';
import { deadline_conclusion_contract } from './SetAfterWinningBid/deadline_conclusion_contract.js';
import { payment_date } from './SetAfterWinningBid/payment_date.js';
import { public_sale_important } from './SetAfterWinningBid/public_sale_important.js';
import { required_costs } from './SetAfterWinningBid/required_costs.js';
import { required_documents } from './SetAfterWinningBid/required_documents.js';
import { trans_right } from './SetAfterWinningBid/trans_right.js';
import { trans_right_date } from './SetAfterWinningBid/trans_right_date.js';
import './css/AfterWinningBid.css';

const Attention = ({ description }) => {
  const condition = [DATA_EMPTY, undefined, ''];
  if (condition.includes(description)) {
    return '';
  }
  return (
    <div
      data-testid="after-winning-bid-attention"
      className="after-winning-bid-text mb-4">
      <p className="red--text mb-0">ご注意</p>
      <p>
        <span className="word-wrap">{description}</span>
      </p>
    </div>
  );
};

const DocumentHead = ({ data }) => {
  if (data?.documentPageDisplay === DISPLAY) {
    return (
      <div
        className="after-winning-bid-text"
        data-testid="after-winning-bid-document">
        <h4>
          必要な書類
          <span className="text-body-1 ml-2">
            必要な書類の一部は
            <a href={data?.documentPageUrl} target="new">
              {data?.documentPageName}
            </a>
            からダウンロードできます。
          </span>
        </h4>
      </div>
    );
  }
  return '';
};

const Contact = ({ data }) => {
  if (data?.inquiryDisplay === DISPLAY) {
    return (
      <div className="after-winning-bid-text mt-10">
        <p className="text-body-1 text-center mb-4">
          落札後の注意事項に関するお問い合わせ先
        </p>
        <p className="text-body-2 text-center">
          {data?.inquiryName}
          <br />
          メール:{' '}
          <a href={`mailto:${data?.inquiryEmail}`}>{data?.inquiryEmail}</a>
          <br />
          電話：{data?.inquiryTel} {data?.inquiryExtension} <br />
          <span className="text-caption">受付時間：{data?.inquiryAccept}</span>
        </p>
      </div>
    );
  }
  return '';
};

const AfterWinningBid = ({ data, procedureType }) => {
  const [requiredCosts, setRequiredCosts] = useState([]);
  const [deadlineConclusionContract, setDeadlineConclusionContract] = useState(
    [],
  );
  const [paymentDate, setPaymentDate] = useState([]);
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [transRight, setTransRight] = useState([]);
  const [transRightDate, setTransRightDate] = useState([]);
  const [publicSaleImportant, setPublicSaleImportant] = useState([]);
  const [assetSaleImportant, setAssetSaleImportant] = useState([]);
  useEffect(() => {
    setRequiredCosts(required_costs(data));
    setDeadlineConclusionContract(deadline_conclusion_contract(data));
    setPaymentDate(payment_date(data));
    setRequiredDocuments(required_documents(data));
    setTransRight(trans_right(data));
    setTransRightDate(trans_right_date(data));
    setPublicSaleImportant(public_sale_important(data));
    setAssetSaleImportant(asset_sale_important(data));
  }, [data]);
  if (procedureType === 'PUBLIC_SALE') {
    return (
      <div className="px-6 py-8 mb-8">
        <div className="after-winning-bid-text">
          <h3>権利移転手続き</h3>
          <p className="mb-4">
            <span className="word-wrap">{data?.transRightDescription}</span>
          </p>
        </div>
        <div className="after-winning-bid-text">
          <h4>必要な費用</h4>
        </div>
        <AfterWinningBidTable data={requiredCosts} />
        <Attention description={data?.costAttention} />
        <DocumentHead data={data} />
        <AfterWinningBidTable data={requiredDocuments} />
        <Attention description={data?.documentAttention} />
        <AfterWinningBidTable
          data={transRight}
          title={'物件の権利移転について'}
        />
        <Attention description={data?.transRightAttention} />
        <div className="after-winning-bid-text">
          <h4>
            落札者（落札者が法人の場合は代表者）以外の方が権利移転手続きを行う場合
          </h4>
          <p className="mb-4">
            <span className="word-wrap">
              {data?.otherTransRightDescription}
            </span>
          </p>
        </div>
        <Attention description={data?.otherTransRightAttention} />
        <div
          data-testid="after-winning-bid-time-transfer-rights"
          className="after-winning-bid-text mb-4">
          <h4>権利移転の時期</h4>
          <p>
            <span className="word-wrap">{data?.transRightDateDescription}</span>
          </p>
        </div>
        <div className="after-winning-bid-text mb-2">
          <h3>重要事項</h3>
          <p>
            落札後の権利移転手続きにおける重要な事項です。必ずご確認ください。
          </p>
        </div>
        <AfterWinningBidTable data={publicSaleImportant} />
        <Attention description={data?.importantAttention} />
        <Contact data={data} />
      </div>
    );
  } else {
    return (
      <div data-testid="after-winning-bid" className="px-6 py-8 mb-8">
        <div
          data-testid="after-winning-bid-transfer-rights"
          className="after-winning-bid-text">
          <h3>権利移転手続き</h3>
          <p className="mb-4">
            <span className="word-wrap">{data?.transRightDescription}</span>
          </p>
        </div>
        <div className="after-winning-bid-text">
          <h4>必要な費用</h4>
        </div>
        <AfterWinningBidTable
          data={deadlineConclusionContract}
          title={'契約締結期限までに'}
        />
        <AfterWinningBidTable data={paymentDate} title={'代金納付期限までに'} />
        <Attention description={data?.costAttention} />
        <DocumentHead data={data} />
        {data?.documentDescription === DATA_EMPTY ? (
          ''
        ) : (
          <p
            data-testid="after-winning-bid-document-asset-sale"
            className="after-winning-bid-text mb-4">
            <span className="word-wrap">{data?.documentDescription}</span>
          </p>
        )}
        <div
          className="after-winning-bid-text"
          data-testid="after-winning-bid-other">
          <h4>
            落札者（落札者が法人の場合は代表者）以外の方が権利移転手続きを行う場合
          </h4>
          <p className="mb-4">
            <span className="word-wrap">
              {data?.otherTransRightDescription}
            </span>
          </p>
        </div>
        <Attention description={data?.otherTransRightAttention} />
        <AfterWinningBidTable
          data={transRightDate}
          title={'権利移転の時期と要する期間'}
        />
        <div className="after-winning-bid-text mb-2">
          <h3>重要事項</h3>
          <p>
            落札後の権利移転手続きにおける重要な事項です。必ずご確認ください。
          </p>
        </div>
        <AfterWinningBidTable data={assetSaleImportant} />
        <Attention description={data?.importantAttention} />
        <Contact data={data} />
      </div>
    );
  }
};
export default AfterWinningBid;
