import handleValidationMessage from '../../../../shared/utils/helper/handleValidationMessage';
import CommonInput from '../Input/CommonInput.jsx';
import RequiredLabel from '../RequiredLabel/RequiredLabel.jsx';

const AddressForm = ({ receiverAddress, handleChange, validationMessage }) => {
  return (
    <tr data-testid="address-form">
      <th data-testid="address-form-label">
        市区町村番地
        <RequiredLabel />
      </th>
      <td>
        <div className="w-expand">
          <CommonInput
            type="text"
            name="address"
            value={receiverAddress}
            placeholder="（例）千代田区"
            handleChange={handleChange}
          />
          <small className="validate-message flex-wrap">
            {handleValidationMessage(validationMessage)}
          </small>
          <p data-testid="address-form-caption">
            <small>
              法人の場合は、商業登記簿に登記されている住所をご入力ください。
            </small>
          </p>
        </div>
      </td>
    </tr>
  );
};

export default AddressForm;
