import {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";

const MarkDown = ({data}) => {
  const [markdown, setMarkdown] = useState("");
  useEffect(() => {
    setMarkdown(data)
  }, []);
  return (
    <ReactMarkdown>{markdown}</ReactMarkdown>
  );
}
export default MarkDown