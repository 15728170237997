const toYenFrom = (price) => {
  if (price === 0) {
    return '0'
  }

  if (!price || price < 0) {
    return '0';
  }

  if (price < 1000) {
    return price.toString();
  }

  return price.toLocaleString('ja-JP');
};

export default toYenFrom;
