import { BASE_URL_SERVICE } from "../../../config/envConstants.js";
import { fetchData } from "../../../shared/utils/helper/fetchData.js";

const fetchBannersStorage = async () => {
  const CURRENT_BANNERS = `${BASE_URL_SERVICE}/storage/current/banners/data.json`;
  const PREVIOUS_BANNERS = `${BASE_URL_SERVICE}/storage/current/banners/data.json`;
  try {
    return await fetchData(CURRENT_BANNERS);
  } catch (error) {
    console.warn(`Failed to fetch current banners: ${error.message}`);
    try {
      return await fetchData(PREVIOUS_BANNERS);
    } catch (error) {
      console.warn(`Failed to fetch previous banners: ${error.message}`);
      return [];
    }
  }
};

export default fetchBannersStorage;
