import { validateInclude, validateRangeLength } from "../../../../application/validations.js";

export class Url {
  #url

  constructor(url) {
    this.#url = url;
  }

  get() {
    return this.#url
  }

  get isValid() {
    return [
      this.validateInclude,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateInclude() {
    return validateInclude(this.#url, ['https://', 'http://'])
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#url, 0, 2048)
  }
}
