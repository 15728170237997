
export class Structure {
    #structure

    constructor(structure) {
        this.#structure = structure;
    }

    get() {
        return this.#structure
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
