import { useState } from "react";

import ServiceProvidersConfirm from "./ServiceProvidersConfirm.jsx";
import ServiceProvidersCreate from "./ServiceProvidersCreate.jsx";
import "interfaces/css/common/form.css"

const ServiceProvidersForm = ({ newId, inputs, closeModal }) => {
  const [onConfirm, setOnConfirm] = useState(false)

  return (
    <div className="kmanager-col-12">
      {onConfirm ?
        <ServiceProvidersConfirm id={newId} data={inputs} closeModal={closeModal} endConfirm={() => setOnConfirm(false)}/>
        :
        <ServiceProvidersCreate inputs={inputs} closeModal={closeModal} toConfirm={() => setOnConfirm(true)}/>
      }
    </div>
  );
};

export default ServiceProvidersForm;
