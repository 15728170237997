import Button from 'interfaces/components/common/Button/Button';
import Heading3 from 'interfaces/components/common/Heading/Heading3';
import PropertyInfoGeneralFormInputFile from 'interfaces/components/common/PropertyInfoForm/PropertyInfoGeneralFormInputFile';
import PropertyInfoGeneralFormInputText from 'interfaces/components/common/PropertyInfoForm/PropertyInfoGeneralFormInputText';
import { property_info_images_description1 } from 'shared/utils/helper/DummyEditFormData';

const PropertyInfoImage = ({ element }) => {
  const GetImageElements = (element) => {
    const elements = [];
    for (let i = 1; i <= element; i++) {
      elements.push(
        <div key={i} className="pt-9">
          <Heading3 text={'ファイル' + i} />
          <div className="property-info-general-form-table">
            <PropertyInfoGeneralFormInputFile label={'画像ファイル'} />
            <PropertyInfoGeneralFormInputText
              label={'コメント'}
              number_remaining_characters={30}
              caution_description={property_info_images_description1}
            />
          </div>
        </div>,
      );
    }
    return <div>{elements}</div>;
  };

  // eslint-disable-next-line no-unused-vars
  const CURRENT_GOVERNMENTS = `${process.env.REACT_APP_BASE_URL_SERVICE}/storage/current/governments/governments.json`;
  // eslint-disable-next-line no-unused-vars
  const PREVIOUS_GOVERNMENTS = `${process.env.REACT_APP_BASE_URL_SERVICE}/storage/previous/governments/governments.json`;

  // eslint-disable-next-line no-unused-vars
  const saveImageToStorage = () => {
    // todo
    /*
     ・一意な識別子の生成
       - identifier_1 = xxx (本登録で行政機関IDと置き換える)
       - identifier_2 = yyy (本登録で物件IDと置き換える)

     ・画像を保存するディレクトリのパス
       - target_dir = 'react/public/image_storage/${identifier_1}/${identifier_2}'

     ・target_dir配下に画像を保存する (Max20ファイル)
        001_main.jpg
        002_main.jpg
        003_main.jpg
        004_main.jpg
            ...
        020_main.jpg

      ・保存した画像のファイルパスを保持しておくこと. 物件を保存する際に、ファイルパスもリクエストボディに含めること
     */
  };

  return (
    <form data-testid="property-info-image">
      {GetImageElements(element)}
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'登録'}
              isOutline={false}
              height={'44px'}
              className={'property-info-general-form-button'}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={'property-info-general-form-button'}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
export default PropertyInfoImage;
