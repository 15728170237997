import { ReactComponent as MenuDown } from "interfaces/assets/svg/menu-down.svg";
import "interfaces/css/common/property-list-selector.css";

const ListSelector = ({ content, onClick, width }) => {
    return (
        <div className="property-list-selector text-field-outlined" style={{ width: width }}>
            <div className="input-control" onClick={onClick}>
                <div className="select-slot">
                    <div className="select-selections">
                        <div className="select-selection comma">{content}</div>
                    </div>
                    <div className="input-append-inner">
                        <div className="input-icon">
                            <MenuDown/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ListSelector
