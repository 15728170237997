/**
 * TODO:
 *   直接このページにアクセスした場合に物件詳細ページにリダイレクトされること
 *
 *   テストファイル: react/src/service/__tests__/pages/ConfirmationBids.test
 *   現行のコード: /work/members_student/original/gl-auc/front/pages/items/_id/bid.vue
 *               /work/members_student/original/gl-auc/front/components/bid/BidBodyStepConfirm.o.vue
 * @format
 */

import CautionBlock from 'interfaces/components/Guidelines/GuidelineAgreement/CautionBlock';
import GovernmentLabel from 'interfaces/components/Guidelines/GuidelineAgreement/GovernmentLabel';
import HorizontalPairLinks from 'interfaces/components/Guidelines/GuidelineAgreement/HorizontalPairLinks';
import StepControls from 'interfaces/components/Guidelines/GuidelineAgreement/StepControls';
import Stepper from 'interfaces/components/Guidelines/GuidelineAgreement/Stepper';
import StepTitle from 'interfaces/components/Guidelines/GuidelineAgreement/StepTitle';

import BidsDetailTable from './BidsDetailTable';
import CautionMessage from './CautionMessage';

const stepData = {
  steps: [
    {
      label: '入札内容の確認',
    },
    {
      label: '受付完了',
    },
  ],
};
const stepTitleData = {
  num: 1,
  title: '入札内容の確認',
};
const cautionBlockData = {
  text: (
    <>
      <b>入札形式での入札は1度しかできません</b>
      <br />
      入札した内容はキャンセルできませんので、内容に間違いがないか必ずご確認ください。
      <br />
      問題なければ「ガイドラインに同意して、入札する」ボタンを押してください。
      <br />
      ※入札締切日時以降にボタンを押した場合は無効になります。
    </>
  ),
};
const stepControlsData = {
  next_step_button: {
    color: 'primary',
    text: 'ガイドラインに同意して、入札する',
  },
  cancel_or_back_button: {
    color: 'grey lighten-2',
    text: 'キャンセル',
  },
};
const horizontalPairLinksData = {
  left_link: {
    label: '物件一覧',
    link: '#',
  },
  right_link: {
    label: 'KSI官公庁オークショントップ',
    link: '/',
  },
};
const bidsDetailTableData = {
  tableData: [
    {
      id: 1,
      title: '物件名',
      value: 'レンダリングテスト',
    },
    {
      id: 2,
      title: '見積価格',
      value: '1,000,000円',
    },
    {
      id: 3,
      title: '入札価格',
      value: '1,200,000円（120万円）',
    },
  ],
};
const cautionMessageData = {
  text: '行政機関名 インターネット公売ガイドライン',
  link: '/',
};

const ConfirmationBids = () => {
  return (
    <div
      className="common-side-margin-wrap no-bg mx-auto box grow property-sheet theme-light mx-auto"
      style={{ maxWidth: '1200px', width: '90%' }}>
      <div data-testid="confirmation-bids" className="mb-10">
        <GovernmentLabel label="ラベルレンダリングテスト" />
        <Stepper steps={stepData.steps} active={1} />
        <StepTitle data={stepTitleData} />
        <div className="mx-2 mb-10">
          <CautionBlock data={cautionBlockData} />
        </div>
        <BidsDetailTable data={bidsDetailTableData.tableData} />
        <div className="flex justify-center mb-4">
          <CautionMessage data={cautionMessageData} />
        </div>
        <StepControls data={stepControlsData} />
        <HorizontalPairLinks data={horizontalPairLinksData} />
      </div>
    </div>
  );
};

export default ConfirmationBids;
