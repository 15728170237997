import Heading2 from './Heading/Heading2.jsx';

const PropertyInfoGeneralPageDescription = ({
  title,
  content,
  displayRequired,
}) => {
  return (
    <>
      <Heading2 title={title} />
      <p className="pt-3 pb-3">
        <div>
          {content.split('\n').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </div>
        {displayRequired && (
          <div>
            <span className="property-info-general-page-description-content-required">
              *
            </span>
            は必須項目です。
          </div>
        )}
      </p>
    </>
  );
};
export default PropertyInfoGeneralPageDescription;
