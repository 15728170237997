import { ReactComponent as CheckboxMarked } from 'interfaces/assets/svg/checkbox-marked.svg';
import { ReactComponent as Checkbox } from 'interfaces/assets/svg/checkbox_outline_blank.svg';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  DIRECTION,
  FIELD,
  PAGE,
  PAGE_SIZE,
  SEARCH_GOVERNMENT_SUB_CATEGORY_ID,
  SEARCH_PROPERTY_CATEGORY_ID,
} from 'shared/utils/constants/queryParam';

import 'interfaces/css/Properties/checkbox-group.css';

const CheckBoxGroup = ({ data }) => {
  return (
    <div data-testid="checkbox-group" className="checkbox-group">
      {data &&
        data.map((d) => (
          <div
            key={d.id}
            className="checkbox-group-item"
            data-testid="checkbox-group-item">
            <CheckBox label={d.label} keyName={d.keyName} value={d.value} />
          </div>
        ))}
    </div>
  );
};

const CheckBox = ({ label, keyName, value }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const activeCheckBox = (queryParamName) => {
      const allParams = searchParams.getAll(queryParamName);
      if (allParams.includes(value)) {
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }
    };
    activeCheckBox(keyName);
  }, [searchParams]);
  const clickHandle = () => {
    setIsChecked(!isChecked);
  };

  const toggleQueryParam = (queryParamName, queryParamValue) => {
    if (isChecked) {
      removeSpecificQueryParam(queryParamName, queryParamValue);
    } else {
      searchParams.append(queryParamName, queryParamValue);
      setSearchParams(searchParams);
    }
  };
  const removeSpecificQueryParam = (keyToRemove, valueToRemove) => {
    const newSearchParams = new URLSearchParams();
    searchParams.forEach((value, key) => {
      if (!(key === keyToRemove && value === valueToRemove)) {
        newSearchParams.append(key, value);
      }
    });
    setSearchParams(newSearchParams);
  };

  const appendQueryParam = (queryParamName, queryParamValue) => {
    if (!searchParams.has(queryParamName)) {
      searchParams.append(queryParamName, queryParamValue);
      setSearchParams(searchParams);
    }
  };
  return (
    <>
      <div className="svg-and-input-wrap">
        {isChecked ? (
          <CheckboxMarked
            className="checkbox-marked-svg"
            data-testid="checkbox-marked-svg"
          />
        ) : (
          <Checkbox className="checkbox-svg" data-testid="checkbox-svg" />
        )}
        <input
          data-testid="checkbox-input"
          className="checkbox-input"
          ref={inputRef}
          type="checkbox"
          value={value}
          onClick={() => {
            clickHandle();
            toggleQueryParam(keyName, value);
            appendQueryParam(SEARCH_PROPERTY_CATEGORY_ID, '');
            appendQueryParam(SEARCH_GOVERNMENT_SUB_CATEGORY_ID, '');
            appendQueryParam(DIRECTION, 'ASC');
            appendQueryParam(FIELD, 'PRICE');
            appendQueryParam(PAGE, 1);
            appendQueryParam(PAGE_SIZE, 50);
          }}
        />
      </div>
      <label
        data-testid="checkbox-label"
        className={`checkbox-label ${isChecked ? 'active' : ''}`}
        onClick={() => {
          inputRef.current.click();
        }}>
        {label}
      </label>
    </>
  );
};

export default CheckBoxGroup;
