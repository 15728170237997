import { useEffect, useState } from "react";
import 'interfaces/css/ConfirmSystemUsage/total-amount-breakdown-table.css'

const TotalAmountBreakdownTable = ({ invitation }) => {
  const [usageFee, setUsageFee] = useState(0)
  const [usageTax, setUsageTax] = useState(0)

  const intoLocaleYen = (value) => {
    return `${value.toLocaleString()}円`
  }

  function handleSetAuction() {
    let fees = {fee: 0, tax: 0};
    if (invitation.callUpAuctionStatus === "BEFORE") {
      fees.fee = 0;
      fees.tax = 0;
    }
    if (invitation.callUpAuctionStatus === "DONE") {
      fees.fee = invitation.systemUsageFeeAuction
      fees.tax = invitation.systemUsageTaxAuction
    }
    return fees
  }

  function handleSetBid() {
    let fees = {fee: 0, tax: 0};
    if (invitation.callUpBidStatus === "BEFORE") {
      fees.fee = 0;
      fees.tax = 0;
    }
    if (invitation.callUpBidStatus === "DONE") {
      fees.fee = invitation.systemUsageFeeAuction;
      fees.tax = invitation.systemUsageTaxAuction;
    }
    // ステータスは仮
    if (invitation.callUpBidStatus === "DONE_ADDITIONAL") {
      fees.fee = invitation.systemUsageFeeAuction + invitation.systemUsageFeeBid
      fees.tax = invitation.systemUsageTaxAuction + invitation.systemUsageTaxBid
    }
    return fees
  }

  useEffect(() => {
    if (!invitation) {
      return
    }
    let auctionFees = handleSetAuction()
    let bidFees = handleSetBid()

    setUsageFee(auctionFees.fee + bidFees.fee);
    setUsageTax(auctionFees.tax + bidFees.tax);
  }, [invitation])

  return (
    <table className="total-amount-breakdown-table" data-testid="total-amount-breakdown-table">
      <tbody>
        <tr data-testid="total-amount-record">
          <td className="total-amount-record-label">請求金額(税別)</td>
          <td className="text-right">{intoLocaleYen(usageFee)}</td>
        </tr>
        <tr data-testid="total-amount-record">
          <td className="total-amount-record-label">消費税</td>
          <td className="text-right">{intoLocaleYen(usageTax)}</td>
        </tr>
        <tr data-testid="total-amount-record">
          <td className="total-amount-record-label">請求金額合計</td>
          <td
            className="text-right">{intoLocaleYen(usageFee + usageTax)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default TotalAmountBreakdownTable;
