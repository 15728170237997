import getProgress from 'infrastructure/api/storage/progress.js';
import { useEffect, useState } from 'react';

import { useError } from './useError.js';

export const useProgress = () => {
  const { setError } = useError();
  const [progressSchedules, setProgressSchedules] = useState({});

  useEffect(() => {
    const progress = async () => {
      try {
        const progressSchedules = await getProgress();
        setProgressSchedules(progressSchedules);
      } catch (error) {
        setError(error);
      }
    };
    progress().then();
  }, []);

  return { progressSchedules };
};
