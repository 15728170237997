import { DATA_EMPTY } from 'domain/Property/entities/constants/property';

export const payment_date = (data) => {
  const condition = [DATA_EMPTY, undefined, ''];
  return [
    {
      id: 1,
      title: condition.includes(data?.costPaymentTitle1)
        ? DATA_EMPTY
        : data?.costPaymentTitle1,
      value: condition.includes(data?.costPaymentDescription1)
        ? DATA_EMPTY
        : data?.costPaymentDescription1,
    },
    {
      id: 2,
      title: condition.includes(data?.costPaymentTitle2)
        ? DATA_EMPTY
        : data?.costPaymentTitle2,
      value: condition.includes(data?.costPaymentDescription2)
        ? DATA_EMPTY
        : data?.costPaymentDescription2,
    },
    {
      id: 3,
      title: condition.includes(data?.costPaymentTitle3)
        ? DATA_EMPTY
        : data?.costPaymentTitle3,
      value: condition.includes(data?.costPaymentDescription3)
        ? DATA_EMPTY
        : data?.costPaymentDescription3,
    },
    {
      id: 4,
      title: condition.includes(data?.costPaymentTitle4)
        ? DATA_EMPTY
        : data?.costPaymentTitle4,
      value: condition.includes(data?.costPaymentDescription4)
        ? DATA_EMPTY
        : data?.costPaymentDescription4,
    },
    {
      id: 5,
      title: condition.includes(data?.costPaymentTitle5)
        ? DATA_EMPTY
        : data?.costPaymentTitle5,
      value: condition.includes(data?.costPaymentDescription5)
        ? DATA_EMPTY
        : data?.costPaymentDescription5,
    },
  ];
};
