import { useEffect, useState } from 'react';

import 'interfaces/css/FaqOther/faq-other-navigator.css';

const FaqOtherNavigator = ({ procedureType }) => {
  console.log(procedureType);
  const [faqOtherAnchorData, setFaqOtherAnchorData] = useState([]);
  useEffect(() => {
    const data = [
      {
        id: 1,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: '複数のパソコンで、同時に同一の物件データを作成、編集しても問題はないですか？',
      },
      {
        id: 2,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: '官公庁オークション用のロゴやバナーを使いたいのですが。',
      },
      {
        id: 3,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: 'フリーソフトは使用しても問題ないですか？',
      },
      {
        id: 4,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: 'HTMLタグの使用方法が分からないので教えてもらえますか？',
      },
      {
        id: 5,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: 'ホームページビルダーなどのホームページ作成ソフトを使用したHTMLタグは、そのまま使用して問題ないですか？',
      },
      {
        id: 6,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: '人事異動などによる担当者の変更が発生した場合、ナビユーザーIDはどうすればいいですか？',
      },
      {
        id: 7,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: '人事異動などによる担当者の変更が発生した場合、連絡先を変更するにはどうすればよいですか？',
      },
      {
        id: 8,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: `${procedureType === 'ASSET_SALE' ? '公有財産売却' : '官公庁オークション'}の「実施中行政機関一覧」ページに並んでいる行政機関の順番は変更可能ですか？`,
      },
      {
        id: 9,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: '代理人が手続きを行う場合に必要な手続きはどこに案内すればよいですか？',
      },
      {
        id: 10,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: 'KSI官公庁オークションの中で、自分の行政機関のページビュー数を教えてください。',
      },
      {
        id: 11,
        role: ['PUBLIC_SALE'],
        text: '債権者登録は発行してもらえますか？',
      },
      {
        id: 12,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: 'ガイドラインサンプルの上下にシステム的に表示される青字の文章はなぜ必要なのですか？',
      },
      {
        id: 13,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: '参加申し込み者から行政機関への提出書類について、ガイドラインの案文では「入札開始2開庁日前まで」となっているが、期限を変更してもよいですか？',
      },
      {
        id: 14,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: 'ガイドラインの案文の中で、「復代理人」「任意代理人」とは、どのような意味でしょうか。代理人との違いは？',
      },
      {
        id: 15,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: `${procedureType === 'ASSET_SALE' ? '入札' : '公売'}保証金を銀行振込など（オフ納付）で納付する場合に、参加者に執行機関のホームページよりダウンロードいただく書式のサンプルはありますか？`,
      },
      {
        id: 16,
        role: ['PUBLIC_SALE', 'ASSET_SALE'],
        text: '官公庁オークションの画面を、画像にして使用してもよいですか？',
      },
    ].filter((item) => item.role.includes(procedureType));
    setFaqOtherAnchorData(data);
  }, [procedureType]);
  return (
    <ul data-testid="faq-other-navigator" className="faq-other-navigator">
      {faqOtherAnchorData.map((anchor) => (
        <li
          key={anchor.id}
          className="faq-other-navigator-item"
          data-testid="faq-other-navigator-item">
          <div
            className="faq-other-navigator-anchor-link"
            data-testid="faq-other-navigator-anchor-link">
            {anchor.text}
          </div>
        </li>
      ))}
    </ul>
  );
};
export default FaqOtherNavigator;
