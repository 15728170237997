import Button from 'interfaces/components/common/Button/Button';
import 'interfaces/css/MyPage/complete-modal-contents.css';

const CompleteModalContents = ({ title, body, buttonText, handleClose }) => {
  return (
    <div className="complete-modal-contents">
      <div className="complete-modal-contents-title">{title}</div>
      <div className="mb-8">{body}</div>
      <Button
        text={buttonText}
        onClick={handleClose}
        width="80px"
        height="44px"
      />
    </div>
  );
};

export default CompleteModalContents;
