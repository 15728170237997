import {
  procedure_new_entry_labels_asset,
  procedure_new_entry_labels_pub,
} from 'interfaces/components/common/TableLabels';
import {
  BidEndTypeToJp,
  DisplayToJP,
  MailToJP,
  MailToReductionJP,
} from 'shared/utils/helper/CreateConfirmData';

const createDepositDeliveryStatusMessage = (depositDeliveryStatus) => {
  const convert = (value, convertRule) => {
    return value ? convertRule.on : convertRule.off;
  };

  return Object.entries(depositDeliveryStatus)
    .map(([key, value]) => {
      if (key === 'paymentCreditCard') {
        return convert(value, { on: 'オン納付', off: '' });
      }

      if (key === 'paymentBankTransfer') {
        return convert(value, { on: 'オフ納付', off: '' });
      }

      if (key === 'noneDeposit') {
        return convert(value, { on: '保証金なし', off: '' });
      }
    })
    .filter(Boolean)
    .join('、');
};

export const createConfirmSectionData = (inputs, procedureType, auction) => {
  const labels =
    procedureType === 'インターネット公売'
      ? procedure_new_entry_labels_pub
      : procedure_new_entry_labels_asset;
  return {
    section1: [
      {
        text: labels[0],
        state: auction ? auction.aplStart : inputs.data.auctionName,
      },
      {
        text: '公告名',
        state: inputs.data.name,
      },
      {
        text: '入札自動終了',
        state: BidEndTypeToJp(inputs.data.bidEndType),
      },
      {
        text: '認証書面（表紙）の押印と郵送を希望する',
        state: MailToJP(inputs.data.mailCertDocument),
      },
      {
        text: '減額申請書に押印し、KSIへ郵送する',
        state: MailToReductionJP(inputs.data.mailReductionDocument),
      },
      {
        text: labels[1],
        state: createDepositDeliveryStatusMessage(
          inputs.data.depositDeliveryStatus,
        ),
      },
    ],

    section2: [
      {
        text: '申し込み総数の表示（せり売）',
        state: DisplayToJP(inputs.data.aplCountAuctionDisplay),
      },
    ],

    section3: [
      {
        text: '申し込み総数の表示（入札）',
        state: DisplayToJP(inputs.data.aplCountBidDisplay),
      },
      {
        text: '入札件数の表示（入札）',
        state: DisplayToJP(inputs.data.bidCountDisplay),
      },
    ],

    section4: [
      {
        text: '地域',
        state:
          inputs.data.area === '' || inputs.data.area === undefined
            ? '未入力'
            : inputs.data.area,
      },
    ],

    section5: [
      {
        text: '担当部署',
        state:
          inputs.data.contactOffice === '' ||
          inputs.data.contactOffice === undefined
            ? '未入力'
            : inputs.data.contactOffice,
      },
      {
        text: 'メールアドレス',
        state:
          inputs.data.contactEmail === '' ||
          inputs.data.contactEmail === undefined
            ? '未入力'
            : inputs.data.contactEmail,
      },
      {
        text: '電話番号',
        state:
          (inputs.data.contactTel.telNum1 === '' ||
            inputs.data.contactTel.telNum1 === undefined) &&
          (inputs.data.contactTel.telNum2 === '' ||
            inputs.data.contactTel.telNum2 === undefined) &&
          (inputs.data.contactTel.telNum3 === '' ||
            inputs.data.contactTel.telNum3 === undefined)
            ? '未入力'
            : `${inputs.data.contactTel.telNum1}-${inputs.data.contactTel.telNum2}-${inputs.data.contactTel.telNum3}`,
      },
    ],
  };
};
