import { ReactComponent as BeginnerIcon } from 'interfaces/assets/svg/detail_beginner_icon.svg';
import URL_LINKS from 'interfaces/pages/Dashboard/entities/externalLinks.js';
import { Link } from 'react-router-dom';

import ActionArea from './ActionArea.jsx';
import DetailSlider from '../DetailSlider/DetailSlider.jsx';
import { AuctionHelpBtnBranch } from '../HeroArea/AuctionHelpBtnBranch.jsx';
import { HeroAreaIcon } from '../HeroArea/HeroAreaIcon.jsx';

export const ActionAreaResponsive = ({ data, isContainBlockList }) => {
  const mainImages = [
    {
      id: '1',
      image_url:
        `react/public/image_storage/${data?.governmentId}/${data?.id}/001_main.jpg`.replace(
          'react/public',
          '',
        ),
      image_caption_title: 'カメラ',
    },
    {
      id: '2',
      image_url:
        `react/public/image_storage/${data?.governmentId}/${data?.id}/002_main.jpg`.replace(
          'react/public',
          '',
        ),
      image_caption_title: '車',
    },
    {
      id: '3',
      image_url:
        `react/public/image_storage/${data?.governmentId}/${data?.id}/003_main.jpg`.replace(
          'react/public',
          '',
        ),
      image_caption_title: '車',
    },
    {
      id: '4',
      image_url:
        `react/public/image_storage/${data?.governmentId}/${data?.id}/004_main.jpg`.replace(
          'react/public',
          '',
        ),
      image_caption_title: 'PC',
    },
  ];

  return (
    <div data-testid="action-area-responsive">
      <DetailSlider images={mainImages} />
      <ActionArea data={data} isContainBlockList={isContainBlockList}>
        <div className="flex gaps">
          <Link
            to={URL_LINKS.BEGINNERS_URL}
            target="_blank"
            className="btn--frame pa-0 mt-3 grow text-caption btn--frame v-btn v-btn--depressed v-btn--flat v-btn--outlined v-size--large text--text"
            style={{ minWidth: '0px', width: 'auto' }}
            data-testid="guideline">
            <span className="v-btn__content">
              <BeginnerIcon />
              <span>初めての方へ</span>
            </span>
          </Link>
          <AuctionHelpBtnBranch data={data} />
        </div>
        <HeroAreaIcon data={data} />
      </ActionArea>
    </div>
  );
};
