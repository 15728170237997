//import { useDeleteSubmit } from "shared/hooks/useSubmit";
import Button from 'interfaces/components/common/Button';
import Confirm from 'interfaces/components/common/Confirm';
import InputType from 'interfaces/components/common/Form/InputType';
import { useEffect, useState } from 'react';

import { validateInputsPromotionEdit } from './validateInputsPromotionEdit';
//import { bodyToSnake, createBody } from "../../api/convertData";
//import { putApiData } from "../../api/request";
//import { dateFormat } from "../../../service/helper/date_format";

//const PromotionForm = ({ data, frameLength, fetchPromotionFrames, closeAllAccordion }) => {
const PromotionForm = ({ promotionFrame, frameLength }) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  //const submitHandler = useDeleteSubmit(data);

  const createInputs = validateInputsPromotionEdit(frameLength);

  // 表示期限が設定しないの場合は表示期限の入力欄が表示されないこと
  const isDisplayDeadline = createInputs.find(
    (object) => object.column === 'isDisplayDeadline',
  ).state;
  let inputs = createInputs;

  if (isDisplayDeadline === 'HIDDEN') {
    inputs = createInputs.filter(
      (object) => object.column !== 'displayDeadline',
    );
  }

  // ソースが手入力の場合は、ソース/検索URLの入力欄が表示されないこと
  const sourceType = inputs.find(
    (object) => object.column === 'sourceType',
  ).state;

  if (sourceType === 'INPUT_URL') {
    inputs = inputs.filter((object) => object.column !== 'searchUrl');
  }

  // ソースが検索URLの場合は、ソース/手入力の入力欄が表示されないこと
  if (sourceType === 'SEARCH_URL') {
    inputs = inputs.filter(
      (object) =>
        object.column !== 'source1' &&
        object.column !== 'source2' &&
        object.column !== 'source3' &&
        object.column !== 'source4' &&
        object.column !== 'source5' &&
        object.column !== 'source6' &&
        object.column !== 'source7' &&
        object.column !== 'source8' &&
        object.column !== 'source9' &&
        object.column !== 'source10' &&
        object.column !== 'source11' &&
        object.column !== 'source12' &&
        object.column !== 'source13' &&
        object.column !== 'source14' &&
        object.column !== 'source15' &&
        object.column !== 'source16' &&
        object.column !== 'source17' &&
        object.column !== 'source18',
    );
  }

  // 入力欄の初期化処理
  useEffect(() => {
    let inputs = createInputs;

    // テーマの初期化
    inputs
      .find((object) => object.column === 'theme')
      .init(promotionFrame?.theme);

    // 表示期限の取得
    const displayDeadline = promotionFrame?.displayDeadline;

    // 表示期限フラグの初期化
    const isDisplayDeadline =
      displayDeadline === '' ||
      displayDeadline === 'NULL' ||
      displayDeadline === '1000-01-01'
        ? 'HIDDEN'
        : 'DISPLAY';
    inputs
      .find((object) => object.column === 'isDisplayDeadline')
      .init(isDisplayDeadline);

    // 表示期限フラグがDISPLAYの場合は、表示期限を初期化
    if (isDisplayDeadline === 'DISPLAY') {
      inputs
        .find((object) => object.column === 'displayDeadline')
        .init(new Date(promotionFrame?.displayDeadline));
    }

    // 表示枠の初期化
    inputs
      .find((object) => object.column === 'displayOrder')
      .init(promotionFrame?.displayOrder);

    // アクティブフラグの初期化
    inputs
      .find((object) => object.column === 'activeStatus')
      .init(promotionFrame?.activeStatus);

    // ソースの取得
    const sourceType = promotionFrame?.sourceType;

    // ソースが検索URLの場合は、ソースと検索URLを初期化
    if (
      sourceType === 'SEARCH_URL' &&
      promotionFrame.promotionProperties?.length > 0
    ) {
      inputs
        .find((object) => object.column === 'sourceType')
        .init(promotionFrame?.sourceType);
      inputs
        .find((object) => object.column === 'searchUrl')
        .init(promotionFrame?.promotionProperties[0].url);
    }

    // ソースが手入力の場合は、ソース1~18を初期化
    if (
      sourceType === 'INPUT_URL' &&
      promotionFrame.promotionProperties?.length > 0
    ) {
      promotionFrame.promotionProperties.map((item, index) =>
        inputs
          .find((object) => object.column === `source${index + 1}`)
          .init(item.url),
      );
    }
  }, [promotionFrame]);

  //const formDataAdd = (inputBody) => {
  //  let snakeBody = bodyToSnake(createBody(inputBody));
  //  snakeBody['display_deadline'] = dateFormat(snakeBody['display_deadline'], 'Y-M-D hh:mm');

  //  // ソースが検索URLの場合に、search_urlを削除して、source1でAPIに値を送信するように変更した
  //  if (snakeBody['source_type'] === 'SEARCH_URL') {
  //    snakeBody['source1'] = snakeBody['search_url']
  //    delete snakeBody['search_url'];
  //  }

  //  delete snakeBody['is_display_deadline'];
  //  return snakeBody
  //}

  const Submit = async (promotionFrame) => {
    console.log(promotionFrame);
    console.log('todo: submit promotion form');
    //  // プロモーションパーツを追加で追加されたかどうかで新規登録、更新を判別する
    //  if (data.isNewPromotion === 'TRUE') {
    //    await putApiData(``, formDataAdd(inputs))
    //      .then(() => {
    //        // TODO: APIに渡す値のハンドリング
    //        console.log('更新処理')
    //        closeAllAccordion();
    //        //fetchPromotionFrames();
    //      })
    //      .catch((err) => {
    //        // TODO: エラーハンドリング
    //        console.log(err)
    //      })
    //    return;
    //  }

    //  // TODO: ソースが検索URLの場合は登録済みのソース/手入力1~18を削除する
    //  // TODO: ソースが手入力の場合は登録済みのソース/手入力1を削除する
    //  await putApiData(``, formDataAdd(inputs))
    //    .then(() => {
    //      // TODO: APIに渡す値のハンドリング
    //      closeAllAccordion();
    //      //fetchPromotionFrames();
    //    })
    //    .catch((err) => {
    //      // TODO: エラーハンドリング
    //      console.log(err)
    //    })
  };

  return (
    <div
      className="box-shadow pa-3"
      data-testid="kmanager-promotion-form"
      style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
      {/*
      <Confirm type="delete" isOpen={isDeleteOpen} closeModal={() => setIsDeleteOpen(false)} Submit={submitHandler}/>
      */}
      <Confirm
        type="delete"
        isOpen={isDeleteOpen}
        closeModal={() => setIsDeleteOpen(false)}
        Submit={() => {}}
      />
      <div className="flex flex-wrap">
        <div className="kmanager-form flex-grow-1">
          {inputs &&
            inputs.map((input, index) => {
              return InputType(input, index);
            })}
        </div>
        <div className="flex justify-center mt-3" style={{ width: '100%' }}>
          <Button
            className="primary"
            text="保存"
            clickHandler={() => Submit(promotionFrame)}
          />
          <Button
            className="warning"
            text="削除"
            clickHandler={() => setIsDeleteOpen(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default PromotionForm;
