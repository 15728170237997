import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  get as getDivisions,
  filter as filterDivision,
} from '../../../../domain/Division/services/Division.js';
import { findById as findGovernment } from '../../../../domain/Government/services/Government.js';
import { findByGovernmentId as findGovernmentUser } from '../../../../domain/User/services/GovernmentUser.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { KMANAGER } from '../../../../shared/utils/helper/constants.js';
import {
  get as getDocumentSendPage,
  filter as filterDocument,
} from '../../../../domain/Setting/services/DocumentSendPages.js';

import { GovernmentTypeToJP } from '../../../../shared/utils/helper/convert/governmentTypeToJp';
import { GovernmentDetailContext } from '../entities/GovernmentDetailContext.js';

const GovernmentDetailProvider = ({ type, children }) => {
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [government, setGovernment] = useState({});
  const [publicSaleUsers, setPublicSaleUsers] = useState([]);
  const [assetSaleUsers, setAssetSaleUsers] = useState([]);
  const [documentSendPages, setDocumentSendPages] = useState([]);
  const [divisions, setDivisions] = useState([]);

  const { governmentId } = useParams();

  if (type === KMANAGER) {
    useEffect(() => {
      findGovernment(type, governmentId).then((resultGovernment) => {
        resultGovernment.governmentType = GovernmentTypeToJP(
          resultGovernment.governmentType,
        );
        setGovernment(resultGovernment);
        findGovernmentUser(type, governmentId).then((result) => {
          const publicSale = result.filter(
            (user) =>
              user.procedureType === 'PUBLIC_SALE' &&
              user.userType === 'MANAGER',
          );
          const assetSale = result.filter(
            (user) =>
              user.procedureType === 'ASSET_SALE' &&
              user.userType === 'MANAGER',
          );
          setPublicSaleUsers(publicSale);
          setAssetSaleUsers(assetSale);
        });
      });
      getDocumentSendPage(type).then((result) => {
        const filteredDocuments = filterDocument(
          result,
          'governmentId',
          governmentId,
        );
        console.log(filteredDocuments);
        setDocumentSendPages(filteredDocuments);
      });
      getDivisions(type).then((result) => {
        const filteredDivisions = filterDivision(
          result,
          'governmentId',
          governmentId,
        );
        setDivisions(filteredDivisions);
      });
    }, [governmentId]);
  }

  return (
    <GovernmentDetailContext.Provider
      value={{
        authState,
        government,
        governmentId,
        publicSaleUsers,
        assetSaleUsers,
        documentSendPages,
        divisions,
      }}>
      {children}
    </GovernmentDetailContext.Provider>
  );
};

export default GovernmentDetailProvider;
