const camelize = (str) => {
  return str.replace(/_([a-z0-9])/g, (match, letter) => letter.toUpperCase());
}

export const underBarRemove = (str) => {
  return str.replace("_","-").toLowerCase();
}

const camelizeKeys = (input) => {
  if (Array.isArray(input)) {
    return input.map(v => camelizeKeys(v));
  } else if (input !== null && input !== undefined && input.constructor === Object) {
    return Object.keys(input).reduce((result, key) => {
      result[camelize(key)] = camelizeKeys(input[key]);
      return result;
    }, {});
  }
  return input;
};

export default camelizeKeys;
