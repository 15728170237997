import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { findById as getGovernment } from '../../../../domain/Government/services/Government';
import { get as getGovernmentUsers } from '../../../../domain/User/services/GovernmentUser';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { NAVI } from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { RegistrationUserContext } from '../entities/RegistrationUserContext.js';

const RegistrationUserProvider = ({ type, children }) => {
  const { governmentId } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [governmentUser, setGovernmentUser] = useState(null);
  const {
    localStorageGovernmentId,
    localStorageProcedureType,
    localStorageUserId,
  } = useLocalStorage();
  const [governmentType, setGovernmentType] = useState('');
  const [procedureType, setProcedureType] = useState('');
  const [loginUser, setLoginUser] = useState('');
  const location = useLocation();

  if (type === NAVI) {
    useEffect(() => {
      if (localStorageGovernmentId != '') {
        getGovernment(type, localStorageGovernmentId).then((result) => {
          setGovernmentType(result.governmentType);
        });
        getGovernmentUsers(type, localStorageGovernmentId).then((result) => {
          if (location.state !== null) {
            let user = result.find(
              (data) => data.loginId.toString() === location.state.userId.toString()
            );
            setGovernmentUser(user);
          }
          let loginUser = result.find(
            (data) => data.id.toString() === localStorageUserId.toString()
          );
          setLoginUser(loginUser);
        });
        setProcedureType(localStorageProcedureType);
      }
    }, [localStorageGovernmentId, location.state?.loginId]);
  }

  return (
    <RegistrationUserContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        governmentUser,
        loginUser,
      }}>
      {children}
    </RegistrationUserContext.Provider>
  );
};

export default RegistrationUserProvider;
