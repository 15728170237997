// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login .login-box {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding: 36px;
  background-color: var(--heading);
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Login/login-box.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,cAAc;EACd,aAAa;EACb,gCAAgC;AAClC","sourcesContent":[".login .login-box {\n  max-width: 700px;\n  width: 100%;\n  margin: 0 auto;\n  padding: 36px;\n  background-color: var(--heading);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
