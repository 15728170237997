import { BASE_URL_SERVICE } from "../../../config/envConstants.js";
import { fetchData } from "../../../shared/utils/helper/fetchData.js";

export const fetchBidInfoStorage = async () => {
  const CURRENT_BID_INFO = `${BASE_URL_SERVICE}/storage/current/properties/bid_info.json`;
  const PREVIOUS_BID_INFO = `${BASE_URL_SERVICE}/storage/previous/properties/bid_info.json`;

  try {
    return await fetchData(CURRENT_BID_INFO);
  } catch (error) {
    console.warn(`Failed to fetch current bidInfo: ${error.message}`);
    try {
      return await fetchData(PREVIOUS_BID_INFO);
    } catch (error) {
      console.warn(`Failed to fetch old bidInfo: ${error.message}`);
      return [];
    }
  }
};
