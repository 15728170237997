import Heading3 from 'interfaces/components/common/Heading/Heading3';
import RightsTransferProcedureAssetSaleSection from './TemplateAfterWinningBidAssetSale/RightsTransferProcedureAssetSaleSection.jsx';
import ImportantMatterAssetSaleSection from './TemplateAfterWinningBidAssetSale/ImportantMatterAssetSaleSection.jsx';

const TemplateAfterWinningBidAssetSale = () => {
  return (
    <div data-testid="template-after-winning-bid-asset-sale" className="pt-9">
      <Heading3 text="落札後の注意事項" isBgGray={true} />
      <RightsTransferProcedureAssetSaleSection />
      <ImportantMatterAssetSaleSection />
    </div>
  );
};

export default TemplateAfterWinningBidAssetSale;
