import { validateRangeLength, isOnlyFullwidthKatakana } from "../../../../application/validations.js";

export class FamilyNameKana {
  #familyNameKana

  constructor(familyNameKana) {
    this.#familyNameKana = familyNameKana;
  }

  get() {
    return this.#familyNameKana
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.isOnlyFullwidthKatakana,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#familyNameKana, 1, 30)
  }

  // バリデーションルールの適用
  get isOnlyFullwidthKatakana() {
    return isOnlyFullwidthKatakana(this.#familyNameKana)
  }
}
