import { ReactComponent as ArrowRightIcon } from 'interfaces/assets/svg/arrow_back_icon.svg';
import BackButton from 'interfaces/components/common/BackButton.jsx';
import PageTitle from 'interfaces/components/common/PageTitle.jsx';
import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import {useState, useEffect} from "react";

//import GovernmentFilter from "interfaces/components/UsageFee/GovernmentFilter.jsx";
import AuctionUsageFeeTable from './AuctionUsageFeeTable';

//import tabLabels from 'shared/utils/helper/switcher/switcherTabLabels.js';
import AuctionSwitcher from 'interfaces/components/common/Switcher/AuctionSwitcher';
import Toolbar from 'interfaces/components/common/Toolbar';

import { AuctionUsageFeeContext } from '../../../../pages/AuctionUsageFee/entities/AuctionUsageFeeContext';
import GovernmentFilter from '../../../common/GovernmentFilter';
import { GovernmentTypes } from 'shared/utils/constants/government';

const AuctionUsageFee = () => {
  const navigation = useNavigate();
  //const { auctionId } = useParams();
  const { auction, usageFees } = useContext(AuctionUsageFeeContext);

  const [filteredUsageFees, setFilteredUsageFees] = useState(usageFees);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setFilteredUsageFees(
      usageFees.filter((usageFee) => usageFee.name.includes(searchInput)),
    );
  }, [searchInput, usageFees]);

  //const [auctionName, setAuctionName] = useState('');
  //const [governmentTypeGroup, setGovernmentTypeGroup] = useState('');
  //const [error, setError] = useState({})

  //function handleError(err) {
  //  setError(err)
  //  setTimeout(() => {
  //    setError({})
  //  }, 2000)
  //}

  const TOP_PAGE_PATH = '/kmanager';

  //useEffect(() => {
  //  (async () => {
  //    try {
  //      const auction = await fetchAuctionData(auctionId, "name");
  //      const governmentTypeGroup = await fetchAuctionData(auctionId, "government-type-group");
  //      setAuctionName(auction.data.data.name)
  //      setGovernmentTypeGroup(governmentTypeGroup.data.data.governmentTypeGroup)
  //    } catch (err) {
  //      handleError(err)
  //    }
  //  })()
  //}, []);

  return (
    <div data-testid="kmanager-usage-fee" style={{ margin: '-6px' }}>
      <div className="kmanager-row align-center">
        <div className="kmanager-col kmanager-col-6">
          <BackButton
            text="オークション実施一覧"
            svg={<ArrowRightIcon title="back-to-page" />}
            handleClick={() => navigation(TOP_PAGE_PATH)}
          />
          <PageTitle text={auction.name} />
          <Toolbar>
            <AuctionSwitcher
              id={auction.id}
              activeIndex={
                auction?.governmentTypeGroup ===
                GovernmentTypes.NATIONAL_TAX_AGENCY.string
                  ? 2
                  : 3
              }
              auctionGovernmentTypeGroup={auction?.governmentTypeGroup}
            />
          </Toolbar>
        </div>
        <div className="kmanager-col kmanager-col-6">
          <GovernmentFilter
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
        </div>
        <div className="kmanager-col kmanager-col-12">
          <AuctionUsageFeeTable
            auction={auction}
            usageFees={filteredUsageFees}
          />
        </div>
      </div>
    </div>
  );
};

export default AuctionUsageFee;
