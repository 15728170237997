import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CautionBlock = styled.div`
  border: 2px solid var(--primary-base);
  background-color: #ffffff;
  padding: 10px;
  margin: 5px;
  margin-bottom: 40px;
`;

export const PublicSaleStepCaptionText = ({ aplType }) => {
  if (aplType === 'ONLINE') {
    return (
      <CautionBlock data-testid="public-sale-and-credit-caption">
        <b>参加申し込みには公売保証金の納付が必要です。</b>
        <br />
        クレジットカード会社に登録した情報を入力してください。以下の項目すべて入力または選択して「上記内容に同意の上、次のステップへ」ボタンを押してください。（*は必須項目）
        <br />
        参加申し込み手続きが完了しましたら、KSI官公庁オークションに登録されているメールアドレス宛にメールにてご連絡いたします。
        <br />
        メールアドレスの確認は
        <Link to="/mypage/" target="_blank" rel="noopener noreferrer">
          こちら
        </Link>
        から行ってください。
      </CautionBlock>
    );
  }

  if (aplType === 'OFFLINE') {
    return (
      <CautionBlock data-testid="public-sale-and-bank-caption">
        <b>参加申し込みには公売保証金の納付が必要です。</b>
        <br />
        仮申し込み後、執行機関へ必要書類の提出および公売保証金の納付を行ってください。執行機関が確認できた時点で参加申し込みが完了となります。登録されているメールアドレス宛にメールにてご連絡いたします。
        <br />
        メールアドレスの確認は
        <Link to="/mypage/" target="_blank" rel="noopener noreferrer">
          こちら
        </Link>
        から行ってください。
      </CautionBlock>
    );
  }
  return (
    <CautionBlock data-testid="public-sale-and-bank-caption">
      以下の内容で仮申し込みをします。「上記内容に同意の上、申し込みを完了する」ボタンを押してください。
    </CautionBlock>
  );
};

export const AssetSaleStepCaptionText = ({ aplType }) => {
  if (aplType === 'ONLINE') {
    return (
      <CautionBlock data-testid="asset-sale-and-credit-caption">
        <b>参加申し込みには入札保証金の納付が必要です。</b>
        <br />
        クレジットカード会社に登録した情報を入力してください。以下の項目すべて入力または選択して「上記内容に同意の上、次のステップへ」ボタンを押してください。（*は必須項目）
        <br />
        参加申し込み手続きが完了しましたら、KSI官公庁オークションに登録されているメールアドレス宛にメールにてご連絡いたします。
        <br />
        メールアドレスの確認は
        <Link to="/mypage/" target="_blank" rel="noopener noreferrer">
          こちら
        </Link>
        から行ってください。
      </CautionBlock>
    );
  }

  if (aplType === 'OFFLINE') {
    <CautionBlock data-testid="asset-sale-and-bank-caption">
      <b>参加申し込みには入札保証金の納付が必要です。</b>
      <br />
      仮申し込み後、執行機関へ必要書類の提示および入札保証金の納付を行ってください。執行機関が確認できた時点で参加申し込みが完了となります。登録されているメールアドレス宛にメールにてご連絡いたします。
      <br />
      メールアドレスの確認は
      <Link to="/mypage/" target="_blank" rel="noopener noreferrer">
        こちら
      </Link>
      から行ってください。
    </CautionBlock>;
  }

  return (
    <CautionBlock data-testid="asset-sale-and-bank-caption">
      以下の内容で仮申し込みをします。「上記内容に同意の上、申し込みを完了する」ボタンを押してください。
    </CautionBlock>
  );
};
