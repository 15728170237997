// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createNaviGuidelinesData,
  updateNaviGuidelinesData,
  deleteNaviGuidelinesData,
} from '../../../infrastructure/api/NaviGuidelines.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { SettingGuidelineCreate } from '../entities/Setting.js';
import { getGuidelines } from '../repositories/Guidelines.js';

export async function get(type) {
  return await getGuidelines(type);
}

export async function findById(type, id) {
  const Guidelines = await getGuidelines(type);
  return Guidelines.filter(
    (data) => data.governmentId.toString() === id.toString(),
  );
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Guidelines)');
  }
  if (type === NAVI) {
    const guidelines = new SettingGuidelineCreate(data);
    response = await createNaviGuidelinesData(guidelines);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Guidelines)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Guidelines', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Guidelines)');
  }
  if (type === NAVI) {
    const guidelines = new SettingGuidelineCreate(data);
    response = await updateNaviGuidelinesData(guidelines);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Guidelines)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Guidelines', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Guidelines)');
  }
  if (type === NAVI) {
    response = await deleteNaviGuidelinesData(data.id);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Guidelines)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Guidelines', response);
  }
  return response;
}

// function createGuidelinesArray(data) {
//   let settings = []
//   data.forEach((setting) => {
//     settings.push(new Guidelines(setting))
//   })
//   return settings
// }

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
