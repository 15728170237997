import { useEffect, useState } from 'react';

import { get as getMyUserInformation } from 'domain/User/services/ServiceUser.js';
import { findById as findAuctionUser } from 'domain/User/services/ServiceUser.js';
import { whereByUserId as findPreregistrationApplicantInformation } from 'domain/Applicant/services/PreregistrationApplicantInformation';

import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { MyPageContext } from '../entities/MyPageContext.js';
import { useLocalStorage } from 'shared/hooks/useLocalStorage.js';

const MyPageProvider = ({ type, children }) => {
  const [myUserInformation, setMyUserInformation] = useState([]);
  const { localStorageUserId } = useLocalStorage();

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      findAuctionUser(type, localStorageUserId).then((auctionUser) => {
        console.log(auctionUser);
        findPreregistrationApplicantInformation(type, localStorageUserId).then(
          (preregistrationApplicantInformation) => {
            let applicantUser = preregistrationApplicantInformation.find(
              (applicant) => applicant.type === 'APPLICANT',
            );
            let hasApplicantData = false;
            if (applicantUser) {
              hasApplicantData = true;
            }
            let clientUser = preregistrationApplicantInformation.find(
              (applicant) => applicant.type === 'CLIENT',
            );
            let hasClientData = false;
            if (clientUser) {
              hasClientData = true;
            }

            let userInfo = {
              ...auctionUser,
              ...{
                hasApplicantData: hasApplicantData,
                hasClientData: hasClientData,
              },
            };

            setMyUserInformation(userInfo);
          },
        );
      });
    }, [localStorageUserId]);
  }

  if (type === NAVI) {
    useEffect(() => {
      getMyUserInformation(type).then((result) => {
        setMyUserInformation(result);
      });
    }, []);

    //useEffect(() => {
    //  getPreregistrationApplicantInformation(type).then(result => {
    //    setPreregistrationApplicantInformation(result);
    //  });
    //}, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getMyUserInformation(type).then((result) => {
        setMyUserInformation(result);
      });
    }, []);

    //useEffect(() => {
    //  getPreregistrationApplicantInformation(type).then(result => {
    //    setPreregistrationApplicantInformation(result);
    //  });
    //}, []);
  }

  return (
    <MyPageContext.Provider
      value={{
        breadcrumbs,
        myUserInformation,
      }}>
      {children}
    </MyPageContext.Provider>
  );
};

export default MyPageProvider;
