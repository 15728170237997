//import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { Link } from 'react-router-dom';
//import { useEffect, useState } from 'react';
// import { breadcrumbs } from '../../../constants/breadcrumbs.js';
// import {usePropertyDetail} from "../../../../navi/hooks/usePropertyDetail.js";
// import usePropertyDetailBreadCrumb from "../../../../shared/hooks/usePropertyDetailBreadCrumb.js";

const Breadcrumb = () => {
  //const [crumbs, setCrumbs] = useState([]);
  const [crumbs] = useState([]);
  //const location = useLocation();
  //const currentPathNames = location.pathname;
  // const propertyDetailBreadCrumb = usePropertyDetailBreadCrumb(currentPathNames);

  // useEffect(() => {
    // if (currentPathNames === '/schedules') {
    //   setCrumbs(breadcrumbs.schedule)
    //   return;
    // }
    // if (currentPathNames === '/gov') {
    //   setCrumbs(breadcrumbs.gov)
    //   return;
    // }
    // if (/^\/gov\/\d+\/$/.test(currentPathNames)) {
    //   setCrumbs(breadcrumbs.govId)
    //   return;
    // }
    // if (/^\/items\/([1-9][0-9]*)$/.test(currentPathNames)) {
    //   if (Object.keys(propertyDetailBreadCrumb).length > 0) {
    //     setCrumbs(propertyDetailBreadCrumb);
    //   }
    //   return;
    // }
    // if (currentPathNames === '/mylist') {
    //   setCrumbs(breadcrumbs.myList)
    //   return;
    // }
    // setCrumbs([]);
  // }, [location, propertyDetailBreadCrumb]);

  const crumbsSize = crumbs.length;

  return (
    <div className="breadcrumb" data-testid="breadcrumb">
      {crumbs?.map((crumb, index) => {
        return (
          <div key={index} className="flex">
            {
              crumb.to
                ? <Link to={crumb.to} className="crumb-link">{crumb.title}</Link>
                : <div>{crumb.title}</div>
            }
            {
              index === crumbsSize - 1
                ? null
                : <span className="crumb-separator" data-testid="crumb-separator">&gt;</span>
            }
          </div>
        )
      })}
    </div>
  );
};

export default Breadcrumb;
