import Button from "../../../../common/Button/Button.jsx";
import '../../../../../css/common/footer-bottom-modal-contents.css'

const FooterBottomModalContents = ({ iframeLink, handleClose }) => {
  return (
    <div className="footer-bottom-modal-contents" data-testid="footer-bottom-modal-contents">
      <div className="footer-bottom-modal-contents-body">
        <iframe
          src={iframeLink}
          width="100%"
          height="100%"
          title="External Site"
          style={{ border: 'none' }}
          data-testid="footer-bottom-modal-iframe"
        />
      </div>
      <div className="close-button-wrap">
        <Button
          text="閉じる"
          height="44px"
          width="100px"
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

export default FooterBottomModalContents;
