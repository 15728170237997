export const government_categories = [
  { id: 9999, value: "9999", label: "地域を選択してください", hidden: true },
  { id: 1, value: "1", label: "北海道" },
  { id: 2, value: "2", label: "東北" },
  { id: 3, value: "3", label: "関東" },
  { id: 4, value: "4", label: "信越・北陸" },
  { id: 5, value: "5", label: "東海" },
  { id: 6, value: "6", label: "近畿" },
  { id: 7, value: "7", label: "中国" },
  { id: 8, value: "8", label: "四国" },
  { id: 9, value: "9", label: "九州・沖縄" },
];

export const government_sub_categories = [
  { id: 9999, value: "9999", label: "都道府県を選択してください", category_id: "9999", hidden: true },
  { id: 1, value: "1", label: "北海道", category_id: "1" },
  { id: 2, value: "2", label: "青森県", category_id: "2" },
  { id: 3, value: "3", label: "岩手県", category_id: "2" },
  { id: 4, value: "4", label: "宮城県", category_id: "2" },
  { id: 5, value: "5", label: "秋田県", category_id: "2" },
  { id: 6, value: "6", label: "山形県", category_id: "2" },
  { id: 7, value: "7", label: "福島県", category_id: "2" },
  { id: 8, value: "8", label: "茨城県", category_id: "3" },
  { id: 9, value: "9", label: "栃木県", category_id: "3" },
  { id: 10, value: "10", label: "群馬県", category_id: "3" },
  { id: 11, value: "11", label: "埼玉県", category_id: "3" },
  { id: 12, value: "12", label: "千葉県", category_id: "3" },
  { id: 13, value: "13", label: "東京都", category_id: "3" },
  { id: 14, value: "14", label: "神奈県川", category_id: "3" },
  { id: 15, value: "15", label: "新潟県", category_id: "4" },
  { id: 16, value: "16", label: "富山県", category_id: "4" },
  { id: 17, value: "17", label: "石川県", category_id: "4" },
  { id: 18, value: "18", label: "福井県", category_id: "4" },
  { id: 19, value: "19", label: "山梨県", category_id: "4" },
  { id: 20, value: "20", label: "長野県", category_id: "4" },
  { id: 21, value: "21", label: "岐阜県", category_id: "5" },
  { id: 22, value: "22", label: "静岡県", category_id: "5" },
  { id: 23, value: "23", label: "愛知県", category_id: "5" },
  { id: 24, value: "24", label: "三重県", category_id: "5" },
  { id: 25, value: "25", label: "滋賀県", category_id: "6" },
  { id: 26, value: "26", label: "京都府", category_id: "6" },
  { id: 27, value: "27", label: "大阪府", category_id: "6" },
  { id: 28, value: "28", label: "兵庫県", category_id: "6" },
  { id: 29, value: "29", label: "奈良県", category_id: "6" },
  { id: 30, value: "30", label: "和歌県山", category_id: "6" },
  { id: 31, value: "31", label: "鳥取県", category_id: "7" },
  { id: 32, value: "32", label: "島根県", category_id: "7" },
  { id: 33, value: "33", label: "岡山県", category_id: "7" },
  { id: 34, value: "34", label: "広島県", category_id: "7" },
  { id: 35, value: "35", label: "山口県", category_id: "7" },
  { id: 36, value: "36", label: "徳島県", category_id: "8" },
  { id: 37, value: "37", label: "香川県", category_id: "8" },
  { id: 38, value: "38", label: "愛媛県", category_id: "8" },
  { id: 39, value: "39", label: "高知県", category_id: "8" },
  { id: 40, value: "40", label: "福岡県", category_id: "9" },
  { id: 41, value: "41", label: "佐賀県", category_id: "9" },
  { id: 42, value: "42", label: "長崎県", category_id: "9" },
  { id: 43, value: "43", label: "熊本県", category_id: "9" },
  { id: 44, value: "44", label: "大分県", category_id: "9" },
  { id: 45, value: "45", label: "宮崎県", category_id: "9" },
  { id: 46, value: "46", label: "鹿児県島", category_id: "9" },
  { id: 47, value: "47", label: "沖縄県", category_id: "9" },
];
