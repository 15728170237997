import { findByAuctionIds as getPropertiesByAuctionIds } from 'domain/Property/services/Property';

import { useEffect, useState } from 'react';

import { findByOpenDate as getAuctionsByOpenDate } from '../../../../domain/Auction/services/Auction.js';
import { findByIds as getGovernmentsByGovernmentIds } from '../../../../domain/Government/services/Government.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { GovernmentsContext } from '../entities/GovernmentsContext.js';

const GovernmentsProvider = ({ type, children }) => {
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [governments, setGovernments] = useState({
    PUBLIC_SALE: {
      NATIONAL_TAX_AGENCY: [],
      PREFECTURE: [],
      MANICIPALITY: [],
      OTHER: [],
    },
    ASSET_SALE: {
      PREFECTURE: [],
      MANICIPALITY: [],
      OTHER: [],
      NATIONAL_ASSET: [],
    },
  });

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      let governmentData = {
        PUBLIC_SALE: {
          NATIONAL_TAX_AGENCY: [],
          PREFECTURE: [],
          MANICIPALITY: [],
          OTHER: [],
        },
        ASSET_SALE: {
          PREFECTURE: [],
          MANICIPALITY: [],
          OTHER: [],
          NATIONAL_ASSET: [],
        },
      };
      getAuctionsByOpenDate(type).then((resultAuctions) => {
        let auctionIds = resultAuctions.map((auction) => auction.id);
        getPropertiesByAuctionIds(type, auctionIds).then((resultProperties) => {
          let tmpGovernmentIds = new Set(
            resultProperties.map((property) => property.governmentId),
          );
          let governmentIds = Array.from(tmpGovernmentIds);

          getGovernmentsByGovernmentIds(type, governmentIds).then(
            (resultGovernments) => {
              resultGovernments.forEach((government) => {
                if (government.governmentType === 'NATIONAL_TAX_AGENCY') {
                  let properties = resultProperties.filter(
                    (property) => property.governmentId === government.id,
                  );
                  governmentData.PUBLIC_SALE.NATIONAL_TAX_AGENCY.push({
                    id: government.id,
                    name: government.name,
                    numOfProperties: properties.length,
                    region: government.location,
                  });
                } else {
                  let tmpProperties = resultProperties.filter(
                    (property) => property.governmentId === government.id,
                  );
                  if (tmpProperties.length > 0) {
                    let tmpAuction = resultAuctions.find(
                      (auction) => auction.id === tmpProperties[0].auctionId,
                    );

                    let governmentItem = {};
                    governmentItem.id = government.id;
                    governmentItem.name = government.name;
                    governmentItem.numOfProperties = tmpProperties.length;
                    governmentItem.region = government.location;

                    if (tmpAuction.procedureType === 'PUBLIC_SALE') {
                      if (government.governmentType === 'PREFECTURE') {
                        governmentData.PUBLIC_SALE.PREFECTURE.push(
                          governmentItem,
                        );
                      } else if (government.governmentType === 'MANICIPALITY') {
                        governmentData.PUBLIC_SALE.MANICIPALITY.push(
                          governmentItem,
                        );
                      } else {
                        governmentData.PUBLIC_SALE.OTHER.push(governmentItem);
                      }
                    } else {
                      if (government.governmentType === 'PREFECTURE') {
                        governmentData.ASSET_SALE.PREFECTURE.push(
                          governmentItem,
                        );
                      } else if (government.governmentType === 'MANICIPALITY') {
                        governmentData.ASSET_SALE.MANICIPALITY.push(
                          governmentItem,
                        );
                      } else {
                        governmentData.ASSET_SALE.OTHER.push(governmentItem);
                      }
                    }
                  }
                }
              });
              setGovernments(governmentData);
            },
          );
        });
      });
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {}, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <GovernmentsContext.Provider
      value={{
        authState,
        breadcrumbs,
        governments,
      }}>
      {children}
    </GovernmentsContext.Provider>
  );
};

export default GovernmentsProvider;
