import { useState } from "react";

export function LocalGovernmentManagementTopInputs() {
  const [displayPreview, setDisplayPreview] = useState("HIDDEN");
  const [previewUrl, setPreviewUrl] = useState("");
  const [displayInquiry, setDisplayInquiry] = useState("HIDDEN");
  const [inquiryName, setInquiryName] = useState("");
  const [inquiryEmail, setInquiryEmail] = useState("");
  const [inquiryTel, setInquiryTel] = useState({
    telNum1:"",
    telNum2:"",
    telNum3:""
  });
  const [inquiryExtension, setInquiryExtension] = useState("");
  const [inquiryAccept, setInquiryAccept] = useState("");
  const [displayHomePage, setDisplayHomePage] = useState("HIDDEN");
  const [homePageName, setHomePageName] = useState("");
  const [homePageUrl, setHomePageUrl] = useState("");


  const inputs = {
    data: {
      displayPreview,
      previewUrl,
      displayInquiry,
      inquiryName,
      inquiryEmail,
      inquiryTel,
      inquiryExtension,
      inquiryAccept,
      displayHomePage,
      homePageName,
      homePageUrl,
    },
    setters: {
      setDisplayPreview,
      setPreviewUrl,
      setDisplayInquiry,
      setInquiryName,
      setInquiryEmail,
      setInquiryTel,
      setInquiryExtension,
      setInquiryAccept,
      setDisplayHomePage,
      setHomePageName,
      setHomePageUrl,
    }
  }
  return inputs;
}