
export class BidCountDisplay {
  #bidCountDisplay

  constructor(bidCountDisplay) {
    this.#bidCountDisplay = bidCountDisplay;
  }

  get() {
    return this.#bidCountDisplay
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
