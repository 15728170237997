import GovernmentCreate from "interfaces/components/Government/Create/GovernmentCreate";

const KmanagerGovernmentNew = () => {
    return (
        <div data-testid="kmanager-government-list">
            <GovernmentCreate />
        </div>
    );
}

export default KmanagerGovernmentNew;
