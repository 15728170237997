
export class GovernmentTypeGroup {
  #governmentTypeGroup

  constructor(governmentTypeGroup) {
    this.#governmentTypeGroup = governmentTypeGroup;
  }

  get() {
    return this.#governmentTypeGroup
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
