//import {getAddressList} from "../../infrastructure/api/address_list.js";

export const searchAddress = async (noHyphenZipcode) => {
  console.log(noHyphenZipcode);
  //try {
  //const addressList = await getAddressList();
  //const result = addressList.find(
  //  (address) => address.zipCode === noHyphenZipcode,
  //);
  //if (!result) {
  //  return { prefecture: '', address: '', street: '' };
  //}
  return {
    prefecture: '-- mock prefecture --',
    address: '-- mock address --',
    street: '-- mock street --',
  };
  //return result;
  //}// catch (error) {
  //  console.error(error);
  //  throw error;
  //}
};
