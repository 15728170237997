import 'interfaces/css/SampleGuideline/guideline-modal-template-text-area.css';

const GuidelineModalTemplateTextArea = ({ value }) => {
  return (
    <textarea
      className="guideline-modal-template-text-area"
      rows="10"
      defaultValue={value}
      data-testid="guideline-modal-template-text-area"></textarea>
  );
};
export default GuidelineModalTemplateTextArea;