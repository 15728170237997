import {
  bank_provisional_apply_completion_description1,
  card_provisional_apply_completion_description2
} from "shared/utils/helper/DummyEditFormData";

import FormTextWithCaution from "../../Form/navi/FormTextWithCaution";

const BothForm = ({validations, governmentType}) => {
  return (
    <form data-testid="card-provisional-apply-completion-form">
      <div className="pt-3">
        <div className="property-info-general-form-table">
          <FormTextWithCaution
            label={"銀行振込など案内ページURL"}
            required={true}
            caution_description={bank_provisional_apply_completion_description1}
            data={validations.data.auctionBankTransferUrl}
            handler1={validations.setters.handleAuctionBankTransferUrl}
            handler2={validations.setters.handleBidBankTransferUrl}
            invalidMessage={validations.validationMessage.auctionBankTransferUrlRequiredValid}
          />
          <FormTextWithCaution
            label={"共同入札案内ページURL"}
            caution_description={card_provisional_apply_completion_description2(governmentType)}
            data={validations.data.auctionJointBiddingUrl}
            handler1={validations.setters.handleAuctionJointBiddingUrl}
            invalidMessage={validations.validationMessage.auctionJointBiddingUrlValid}
          />
        </div>
      </div>
    </form>
  );
}

export default BothForm