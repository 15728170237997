import TableRow from './TableRow.jsx';

const Table = ({ label, data, isPublicSale, isKokuzei = false }) => {
  if (!data || data.length === 0) {
    return;
  }

  const groupingData = Object.values(
    data.reduce((acc, item) => {
      if (!acc[item.region]) {
        acc[item.region] = [];
      }
      acc[item.region].push(item);
      return acc;
    }, {}),
  );

  return (
    <div data-testid="in-progress-government-table">
      <table className="in-progress-government-table pb-2 text-left">
        <caption
          className="government-group text-body-2 font-weight-bold pb-3"
          data-testid="government-group">
          {label}
        </caption>
        <tbody>
          <TableRow
            data={groupingData}
            isPublicSale={isPublicSale}
            isKokuzei={isKokuzei}
          />
        </tbody>
      </table>
    </div>
  );
};
export default Table;
