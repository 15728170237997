
export class HandleType {
  #handleType

  constructor(handleType) {
    this.#handleType = handleType;
  }

  get() {
    return this.#handleType
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
