import RadioInput from 'interfaces/components/common/Input/RadioInput';
import handleValidationMessage from 'shared/utils/helper/handleValidationMessage';

const CorporateIndividualInput = ({
  userCorporateIndividual,
  handleUserInfoChange,
  validationMessage,
}) => {
  const corporateIndividualList = [
    { id: 1, label: '法人で申し込み', value: 'CORPORATE' },
    { id: 2, label: '個人で申し込み', value: 'INDIVIDUAL' },
  ];

  return (
    <tr>
      <th>法人・個人</th>
      <td>
        <div className="flex">
          {corporateIndividualList.map((corporateIndividual) => (
            <RadioInput
              key={corporateIndividual.id}
              item={corporateIndividual}
              label={corporateIndividual.label}
              value={userCorporateIndividual}
              handleClick={() =>
                handleUserInfoChange({
                  corporateIndividual: corporateIndividual.value,
                })
              }
            />
          ))}
        </div>
        <small className="validate-message">
          {handleValidationMessage(validationMessage)}
        </small>
      </td>
    </tr>
  );
};

export default CorporateIndividualInput;
