import pino from "pino";

import {BASE_URL_NAVIGATION, BASE_URL_SERVICE} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_GOVERNMENTS = `${BASE_URL_NAVIGATION}/storage/current/governments/data.json`;
const PREVIOUS_GOVERNMENTS = `${BASE_URL_NAVIGATION}/storage/previous/governments/data.json`;

export const fetchGovernmentsStorage = async () => {
  try {
    const response = await fetchGovernments(CURRENT_GOVERNMENTS);
    return extractGovernments(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ governments ）を取得できませんでした");

    try {
      const response = await fetchGovernments(PREVIOUS_GOVERNMENTS);
      return extractGovernments(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ governments ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchGovernments = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractGovernments = (response) => {
  return response.map(government => government);
}

export const getGovernments = async () => {
  //const CURRENT_GOVERNMENTS = `${BASE_URL_SERVICE}/storage/current/governments/governments.json`
  //const PREVIOUS_GOVERNMENTS = `${BASE_URL_SERVICE}/storage/previous/governments/governments.json`
  const CURRENT_GOVERNMENTS = `${BASE_URL_SERVICE}/storage/current/navi/governments/governments.json`
  const PREVIOUS_GOVERNMENTS = `${BASE_URL_SERVICE}/storage/previous/navi/governments/governments.json`

  try {
    return await fetchData(CURRENT_GOVERNMENTS);
  } catch (error) {
    console.warn(`Failed to fetch current governments: ${error.message}`);
    try {
      return await fetchData(PREVIOUS_GOVERNMENTS);
    } catch (error) {
      console.warn(`Failed to fetch old governments: ${error.message}`);
      return [];
    }
  }
};
