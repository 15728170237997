export const KmanagerUserTypes = {
  ADMIN: { string: 'ADMIN', jp: '管理者' },
  GENERAL: { string: 'GENERAL', jp: '一般' },
};

export const NaviUserTypes = {
  MANAGER: { string: 'MANAGER', jp: '管理者' },
  PARENT: { string: 'PARENT', jp: '親' },
  CHILD: { string: 'CHILD', jp: '子' },
};
