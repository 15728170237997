import {
  bank_provisional_apply_completion_description1,
  card_provisional_apply_completion_description2
} from "shared/utils/helper/DummyEditFormData";

import FormTextWithCaution from "../../Form/navi/FormTextWithCaution";

const BidForm = ({validations, governmentType}) => {
  return(
    <div className="pt-9">
      <div className="property-info-general-form-table">
        <FormTextWithCaution
          label={"銀行振込など案内ページURL"}
          required={true}
          caution_description={bank_provisional_apply_completion_description1}
          data={validations.data.bidBankTransferUrl}
          handler1={validations.setters.handleBidBankTransferUrl}
          invalidMessage={validations.validationMessage.bidBankTransferUrlRequiredValid}
        />
        <FormTextWithCaution
          label={"共同入札案内ページURL"}
          caution_description={card_provisional_apply_completion_description2(governmentType)}
          data={validations.data.bidJointBiddingUrl}
          handler1={validations.setters.handleBidJointBiddingUrl}
          invalidMessage={validations.validationMessage.bidJointBiddingUrlValid}
        />
      </div>
    </div>
  )
}

export default BidForm