import { useState } from 'react';

export function useMultipleChecked(canCheckItems, init) {

  const [checked, setChecked] = useState(init || []);
  const checkedList = (target) => {
    if (checked.includes(target)) {
      setChecked([...checked.filter((item) => item !== target)]);
    } else {
      setChecked([...checked.concat([target])]);
    }
  };
  const allCheck = () => setChecked(canCheckItems);
  const clearCheck = () => setChecked([]);

  return {
    checked,
    checkedList,
    allCheck,
    clearCheck,
  };
}
