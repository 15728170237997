import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();

export const fetchManagementUsersStorage = async () => {
  try {
    const response = await fetchManagementUsers(`${BASE_URL_NAVIGATION}/storage/current/management_users/data.json`);
    return extractManagementUsers(response);
  } catch (error) {
    logger.warn({ error }, "現在の運営管理ユーザ一覧（ management_users ）を取得できませんでした");

    try {
      const response = await fetchManagementUsers(`${BASE_URL_NAVIGATION}/storage/previous/management_users/data.json`);
      return extractManagementUsers(response);
    } catch (error) {
      logger.error({ error }, "以前の運営管理ユーザ一覧（ management_users ）の取得にも失敗しました");
      return [];
    }
  }
}

export const fetchManagementUsers = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractManagementUsers = (response) => {
  return response.map(managementUser => managementUser);
}