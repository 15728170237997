import { DATA_EMPTY } from 'shared/utils/helper/constants';

const isEmpty = [DATA_EMPTY, undefined, ''];

export const contact_info = (data) => [
  {
    text: '出品行政機関名',
    state: isEmpty.includes(data?.inquiryName) ? '未入力' : data?.inquiryName,
  },
  {
    text: '担当部署名',
    state: isEmpty.includes(data?.inquiryOffice)
      ? '未入力'
      : data?.inquiryOffice,
  },
  {
    text: 'メールアドレス',
    state: isEmpty.includes(data?.inquiryEmail) ? '未入力' : data?.inquiryEmail,
  },
  {
    text: '電話番号',
    state:
      data?.inquiryTel.telNum1 &&
      data?.inquiryTel.telNum2 &&
      data?.inquiryTel.telNum3
        ? `${data?.inquiryTel.telNum1}-${data?.inquiryTel.telNum2}-${data?.inquiryTel.telNum3}${data?.extension == 'DATA_EMPTY' ? '' : data?.inquiryExtension ? `  内線: ${data?.inquiryExtension}` : ''}`
        : '未入力',
  },
  {
    text: '電話受付時間',
    state: isEmpty.includes(data?.inquiryAccept)
      ? '未入力'
      : data?.inquiryAccept,
  },
  {
    text: '備考',
    state: isEmpty.includes(data?.inquiryNote) ? '未入力' : data?.inquiryNote,
  },
];
