import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class CostContractTitle3 {
  #costContractTitle3

  constructor(costContractTitle3) {
    this.#costContractTitle3 = costContractTitle3;
  }

  get() {
    return this.#costContractTitle3
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#costContractTitle3, 1, 50)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#costContractTitle3)
  }
}
