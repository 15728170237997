import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

//import { useAuth } from 'shared/hooks/useAuth';
//import { useError } from 'shared/hooks/useError';
//import { getPreRegistrationInfo } from '../../../read_storage/application-info-input.js';
import { deliveryInfoMapper } from './helper/deliveryInfo';

const EditDeliveryRegistrationInfo = ({ property }) => {
  const { id } = useParams();
  //const { userId } = useAuth();
  //const { setError } = useError();
  const [receiverName, setReceiverName] = useState('');

  //const fetch = async () => {
  //  try {
  //    const preRegistrationInfos = (await getPreRegistrationInfo(userId))[0];
  //    if (deliveryInfo.receiver === "CLIENT") {
  //      return;
  //    }
  //    const receiverType = deliveryInfo.receiverName === 'DELEGATOR' ? 'CLIENT' : 'APPLICANT'; // DBの値とAPIの
  //    const preRegistrationInfo = preRegistrationInfos.find(info => info.type === receiverType)?.infos;
  //    setReceiverName(`${preRegistrationInfo?.familyName} ${preRegistrationInfo?.firstName}`)
  //  } catch (e) {
  //    setError(e);
  //  }
  //};

  useEffect(() => {
    setReceiverName('-- mock data --');
  }, [property]);

  //useEffect(() => {
  //  fetch().then();
  //}, [deliveryInfo, userId]);

  const locationState = {
    data: property,
    step: 2,
    deliveryInfo: deliveryInfoMapper(property, receiverName),
  };

  return (
    <div>
      <p>
        <span>この物件は引渡方法を登録済みです。登録内容は </span>
        <Link to={`/items/${id}/shipping`} state={locationState}>
          こちらから確認・変更
        </Link>
        <span> できます</span>
      </p>
    </div>
  );
};

export default EditDeliveryRegistrationInfo;
