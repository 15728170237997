import { ReactComponent as ArrowRightIcon } from 'interfaces/assets/svg/arrow_back_icon.svg';
import BackButton from 'interfaces/components/common/BackButton';
import Button from 'interfaces/components/common/Button';
import ButtonList from 'interfaces/components/common/ButtonList';
import InputType from 'interfaces/components/common/Form/InputType';
import PageTitle from 'interfaces/components/common/PageTitle';
//import { putApiData } from "../api/request.js";
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

//import { bodyToSnake, createBody } from "../api/convertData.js";
//import {useEffect} from "react";
//import {useInvitation} from "../hooks/useInvitation";
import { validateInputsAuctionEntry } from './validateInputsAuctionEntry';
import { AuctionEntryContext } from 'interfaces/pages/AuctionEntry/entities/AuctionEntryContext';

import 'interfaces/css/common/common-form.css';

const TOP_PATH = '/kmanager';

const AuctionEntry = () => {
  const navigation = useNavigate();
  const { governments } = useContext(AuctionEntryContext);
  const { auctionId } = useParams();
  //  const location = useLocation();
  //  const invitationId = location.state?.invitationId;
  const createInputs = validateInputsAuctionEntry(governments);
  //  const {result} = useInvitation(invitationId)
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    console.log(
      createInputs
        .filter((input) => input.validationMessage !== undefined)
        .map((input) => {
          return {
            column: input.column,
            state: input.state,
            validationMessage: input.validationMessage,
          };
        }),
    );
    setIsValid(
      createInputs
        .filter((input) => input.validationMessage !== undefined)
        .every((input) => {
          if (
            ['contactOffice', 'contactEmail', 'contactTel'].includes(
              input.column,
            )
          ) {
            return (
              input.validationMessage === 'TRUE' ||
              input.validationMessage === null
            );
          }
          return input.validationMessage === 'TRUE';
        }),
    );
  }, [createInputs]);

  //useEffect(() => {
  //  if (Object.keys(result).length > 0) {
  //    // 編集時、データの挿入
  //    Object.keys(result).map((key) => {
  //      const target = createInputs.find((input) => input.column === key)
  //      if (target?.init) {
  //        target.init(result[key])
  //      }
  //    })
  //  }
  //}, [invitationId]);

  const Clear = () => {
    createInputs.forEach((input) => {
      switch (input.column) {
        case 'bidEndType':
          input.init('MANUAL');
          break;
        case 'mailCertDocument':
          input.init('MAIL');
          break;
        case 'depositDeliveryStatus':
          input.init('PUBLIC_SALE');
          break;
        case 'aplCountAuctionDisplay':
          input.init('DISPLAY');
          break;
        default:
          input.init('');
      }
    });
  };

  //const inputBody = createBody(createInputs);

  //const formDataAdd = (inputBody) => {
  //  let snakeBody = bodyToSnake(inputBody)

  //  const governmentId = Number(snakeBody['government_id']);
  //  const noneDeposit = snakeBody['deposit_delivery_status'].noneDeposit;
  //  const paymentCreditCard = snakeBody['deposit_delivery_status'].paymentCreditCard;
  //  const paymentBankTransfer = snakeBody['deposit_delivery_status'].paymentBankTransfer;

  //  snakeBody['government_id'] = governmentId;
  //  snakeBody['none_deposit'] = noneDeposit === '' ? 'FALSE' : noneDeposit;
  //  snakeBody['payment_credit_card'] = paymentCreditCard === '' ? 'FALSE' : paymentCreditCard;
  //  snakeBody['payment_bank_transfer'] = paymentBankTransfer === '' ? "FALSE" : paymentBankTransfer;

  //  delete snakeBody['deposit_delivery_status'];
  //  return snakeBody
  //}

  const AUCTION_PATH = `/kmanager/auction/${auctionId}/gov`;
  const Put = async () => {
    console.log('todo: auction entry api');
    navigation(AUCTION_PATH);
    //    await putApiData(`auctions/${auctionId}/entry`, formDataAdd(inputBody))
    //      .then(() => {
    //        navigation(AUCTION_PATH);
    //      })
    //      .catch((err) => {
    //        console.log(err)
    //      })
  };

  const FORM_BUTTONS_DATA = [
    {
      text: '確定',
      clickHandler: Put,
      className: 'primary',
      disabled: !isValid,
    },
    {
      text: 'クリア',
      clickHandler: Clear,
      className: 'warning',
      disabled: false,
    },
  ];

  // TODO: 入力欄下部の説明欄を追加
  // TODO: 行政機関名一覧の取得
  // TODO: 国税からのみ飛べるように修正（修正箇所：行政機関管理）
  // TODO: 入力欄のスタイル修正
  // TODO: 入力値の管理をリファクタ

  return (
    <div
      className="kmanager-container"
      data-testid="kmanager-auction-entry"
      style={{ padding: '20px 100px' }}>
      <div className="kmanager-row">
        <div className="kmanager-col-12">
          <BackButton
            text="オークション実施一覧"
            svg={<ArrowRightIcon title="back-to-page" />}
            handleClick={() => navigation(TOP_PATH)}
          />
          <PageTitle text="参加申込フォーム(国税)" />
        </div>
        <div className="kmanager-col-12">
          <div className="kmanager-form" data-testid="kmanager-auction-form">
            {createInputs &&
              createInputs.map((input, index) => {
                return InputType(input, index);
              })}

            <ButtonList>
              <div className="d-flex justify-center mt-10">
                {FORM_BUTTONS_DATA.map((item, index) => (
                  <Button
                    key={index}
                    text={item.text}
                    clickHandler={item.clickHandler}
                    className={item.className}
                    isDisabled={item.disabled}
                  />
                ))}
              </div>
            </ButtonList>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionEntry;
