
export class ContactTel {
  #contactTel

  constructor(contactTel) {
    this.#contactTel = contactTel;
  }

  get() {
    return this.#contactTel
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
