import {DashboardContext} from "interfaces/pages/Dashboard/entities/DashboardContext";
import {useContext} from "react";
import { Link } from 'react-router-dom';

import { participationStatusConvert, publicSaleTypeConvert } from "../../../../../shared/utils/helper/convert";

import 'interfaces/css/Dashboard/upcoming-auction-tabl-record.css'
import {dateFormat} from "../../../../../shared/utils/helper/date_format";

const UpcomingAuctionTableRecord = ({ invitation }) => {
  const { auctions } = useContext(DashboardContext)

  // isBothをProviderで渡す？
  const auction = auctions.find(auction => Number(invitation.auctionId) === Number(auction.id))
  const isBoth = auction.publicSaleType === 'BOTH'
  const rowSpan = isBoth ? 2 : 1;

  const convertDate = (date) => {
    let timestamp = Date.parse(date);
    let targetDate = new Date(timestamp);
    return dateFormat(targetDate, `YYYY-MM-DD-DAYOFTHEWEEK-hh`);
  }

  return (
    <>
      <tr className="upcoming-auction-table-record" data-testid="upcoming-auction-table-record">
        <th rowSpan={rowSpan} data-testid="upcoming-auction-table-th">
          <Link to={`/navi/object/${invitation.auctionId}`}>{invitation.name}</Link>
        </th>
        <th data-testid="upcoming-auction-table-th">{publicSaleTypeConvert(auction.publicSaleType)}</th>
        <th rowSpan={rowSpan} data-testid="upcoming-auction-table-th">{participationStatusConvert(auction.participationStatusConvert)}</th>
        <th rowSpan={rowSpan} data-testid="upcoming-auction-table-th">{convertDate(auction.aplStart)}</th>
        <th rowSpan={rowSpan} data-testid="upcoming-auction-table-th">{convertDate(auction.aplEnd)}</th>
        <th rowSpan={rowSpan} data-testid="upcoming-auction-table-th">{convertDate(auction.bidStart)}</th>
        <th data-testid="upcoming-auction-table-th">{auction.publicSaleType === 'BID' ? convertDate(auction.bidEnd) : convertDate(auction.auctionEnd)}</th>
        <th rowSpan={rowSpan} data-testid="upcoming-auction-table-th">{convertDate(auction.openEnd)}</th>
      </tr>
      {
        isBoth && <tr data-testid="upcoming-auction-table-record">
          <th data-testid="upcoming-auction-table-th">入札</th>
          <th data-testid="upcoming-auction-table-th">{convertDate(auction.bidEnd)}</th>
        </tr>
      }
    </>
  );
};

export default UpcomingAuctionTableRecord;
