import OrganizationRegisterProvider from './OrganizationRegisterProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { organizationNewComplete } from "../entities/complete/componentMapping"
import { organizationNewConfirm } from "../entities/confirm/componentMapping";
import { organizationRegister } from "../entities/new/componentMapping.js";

const OrganizationRegister = ({ type, target }) => {
  return (
    <OrganizationRegisterProvider type={type}>
      {
        target === 'new' ?
            getComponent(organizationRegister, type) :
            target === 'confirm' ?
                getComponent(organizationNewConfirm, type) :
                getComponent(organizationNewComplete, type)
      }
    </OrganizationRegisterProvider>
  );
};

export default OrganizationRegister;
