
export class BidCount {
    #bidCount

    constructor(bidCount) {
        this.#bidCount = bidCount;
    }

    get() {
        return this.#bidCount
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
