import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_APPLICANT_INFORMATIONS = `${BASE_URL_NAVIGATION}/storage/current/applicant_informations/data.json`;
const PREVIOUS_APPLICANT_INFORMATIONS = `${BASE_URL_NAVIGATION}/storage/previous/applicant_informations/data.json`;

export const fetchApplicantInformationsStorage = async () => {
  try {
    const response = await fetchCurrentApplicantInformations();
    return extractApplicantInformations(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ applicant_informations ）を取得できませんでした");

    try {
      const response = await fetchPreviousApplicantInformations();
      return extractApplicantInformations(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ applicant_informations ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchCurrentApplicantInformations = async () => {
  return fetchApplicantInformations(CURRENT_APPLICANT_INFORMATIONS);
};

export const fetchPreviousApplicantInformations = async () => {
  return fetchApplicantInformations(PREVIOUS_APPLICANT_INFORMATIONS);
};

export const fetchApplicantInformations = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractApplicantInformations = (response) => {
  return response.map(applicant_information => applicant_information);
}