import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import PageCautionDescription from 'interfaces/components/common/PageCautionDescription';
import { validateInputsDocumentSendForm } from 'interfaces/components/common/validateInputsDocumentSendForm';
import DocumentSendForm from 'interfaces/components/LocalGovernmentManagement/document_send/DocumentSendForm';
import LocalGovernmentManagementModal from 'interfaces/components/LocalGovernmentManagement/LocalGovernmentManagementModal';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import { breadcrumbs } from 'interfaces/pages/LocalGovernmentManagementSendDocumentsTo/entities/breadcrumbs';
import { useContext, useEffect, useState } from 'react';

import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import { LocalGovernmentManagementSendDocumentsToContext } from '../../../pages/LocalGovernmentManagementSendDocumentsTo/entities/LocalGovernmentManagementSendDocumentsToContext';

import { textExtractionByProcedureType } from 'shared/utils/helper/TextExtraction';

const SendDocumentsTo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const validations = validateInputsDocumentSendForm();
  const { localStorageProcedureType } = useLocalStorage();
  const { documentSendPages } = useContext(
    LocalGovernmentManagementSendDocumentsToContext,
  );

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const notRequiredKeys = [
      'invoiceDocumentBuildValid',
      'invoiceWrittenDestinationRequiredValid',
      'invoicePayerNameValid',
      'certDocumentBuildValid',
      'extensionValid',
    ];

    const certFields = [
      'certDocumentAddressValid',
      'certDocumentBuildValid',
      'certDocumentCityValid',
      'certDocumentDestinationValid',
      'certDocumentPostalCodeFirstValid',
      'certDocumentPostalCodeLastValid',
      'certDocumentPostalCodeValid',
      'certDocumentPrefectureValid',
      'certDocumentWrittenDestinationValid',
      'cityRequiredValid',
    ];

    certFields.forEach((field) => {
      if (
        !(
          validations.data.certAddress ||
          validations.data.certArea ||
          validations.data.certBuildName ||
          validations.data.certCity ||
          validations.data.certDestination ||
          validations.data.certPostalCodeFirst ||
          validations.data.certPostalCodeLast ||
          validations.data.certWrittenDestination
        )
      ) {
        notRequiredKeys.push(field);
      }
    });
    const validationResults = Object.entries(validations.validationMessage).map(
      ([key, validationMessage]) => {
        const isValid = notRequiredKeys.includes(key)
          ? validationMessage === null || validationMessage === 'TRUE'
          : validationMessage === 'TRUE';

        return { key, validationMessage, isValid };
      },
    );
    console.log(validationResults);
    const allValid = validationResults.every(({ isValid }) => isValid);
    setIsValid(allValid);
  }, [validations]);

  useEffect(() => {
    // procedureTypeによるデータの出しわけ
    const filteredDocumentData = documentSendPages.find(
      (item) => item.procedureType === localStorageProcedureType,
    );

    // 入力欄の初期値を設定する
    validations.init.setInvoicePostalCodeFirst(
      filteredDocumentData?.invoicePostalCode?.slice(0, 3),
    );
    validations.init.setInvoicePostalCodeLast(
      filteredDocumentData?.invoicePostalCode?.slice(3, 7),
    );
    validations.init.setInvoiceArea(filteredDocumentData?.invoiceArea);
    validations.init.setInvoiceCity(filteredDocumentData?.invoiceCity);
    validations.init.setInvoiceAddress(filteredDocumentData?.invoiceAddress);
    validations.init.setInvoiceBuildName(
      filteredDocumentData?.invoiceBuildName,
    );
    validations.init.setInvoiceWrittenDestination(
      filteredDocumentData?.invoiceWrittenDestination,
    );
    validations.init.setInvoiceDepartment(
      filteredDocumentData?.invoiceDepartment,
    );
    validations.init.setInvoicePayerName(
      filteredDocumentData?.invoicePayerName,
    );
    validations.init.setInvoiceTel({
      telNum1: filteredDocumentData?.invoiceTel?.slice(0, 3) ?? '',
      telNum2: filteredDocumentData?.invoiceTel?.slice(3, 6) ?? '',
      telNum3: filteredDocumentData?.invoiceTel?.slice(6, 9) ?? '',
    });
    validations.init.setInvoiceExtension(
      filteredDocumentData?.invoiceExtension,
    );
    validations.init.setInvoiceUrl(filteredDocumentData?.invoiceUrl);
    validations.init.setCertPostalCodeFirst(
      filteredDocumentData?.certDocumentPostalCode?.slice(0, 3),
    );
    validations.init.setCertPostalCodeLast(
      filteredDocumentData?.certDocumentPostalCode?.slice(3, 7),
    );
    validations.init.setCertArea(filteredDocumentData?.certDocumentArea);
    validations.init.setCertCity(filteredDocumentData?.certDocumentCity);
    validations.init.setCertAddress(filteredDocumentData?.certDocumentAddress);
    validations.init.setCertBuildName(
      filteredDocumentData?.certDocumentBuildName,
    );
    validations.init.setCertWrittenDestination(
      filteredDocumentData?.certDocumentWrittenDestination,
    );
    validations.init.setCertDestination(
      filteredDocumentData?.certDocumentDestination,
    );
  }, [documentSendPages]);

  return (
    <div className="new-style_navi_document-send" data-testid="document-send">
      {isOpen ? (
        <LocalGovernmentManagementModal
          isOpen={isOpen}
          handleClose={() => setIsOpen(false)}
          procedureType={localStorageProcedureType}
        />
      ) : (
        <></>
      )}
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <PropertyInfoGeneralPageDescription
        title={'行政機関ページ管理：書類送付先'}
        content={
          'KSIより貴庁への各種書類を郵送する際の郵送先を設定してください。'
        }
        displayRequired={true}
      />
      <PageCautionDescription
        content={`※${textExtractionByProcedureType(localStorageProcedureType)}の参加申込開始日以降は掲載終了まで修正ができません。`}
      />{' '}
      <DocumentSendForm
        validations={validations}
        setIsOpen={setIsOpen}
        isValid={isValid}
      />
    </div>
  );
};
export default SendDocumentsTo;
