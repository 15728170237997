import { useState } from 'react';

import { useValidate } from './useValidate.js';

export const useInput = (validates, password) => {
  const [value, setValue] = useState('');
  const [validateMessage, setValidateMessage] = useState('');
  const { handleValidates } = useValidate();

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    const validateMessage = handleValidates(inputValue, validates, password);
    setValidateMessage(validateMessage);
  };

  // Memo: useStateと同じように、使う場所でリネームする
  return [value, handleChange, validateMessage];
};
