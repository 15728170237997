import CategoryProvider from './CategoryProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { category } from "../entities/componentMapping.js";

const Category = ({ type }) => {
  return (
    <CategoryProvider type={type}>
      {getComponent(category, type)}
    </CategoryProvider>
  );
};

export default Category;
