const FooterNav = () => {
  return (
    <div data-testid="footer-nav">
      <ul className="footer-nav">
        <li className="footer-nav-item"><a href="#" data-testid="privacy-policy">プライバシーポリシー</a></li>
        <li className="footer-nav-item"><a href="#" data-testid="use-term">利用規約</a></li>
        <li className="footer-nav-item"><a href="#" data-testid="specified-commercial-transactions">特定商取引法の表示</a></li>
        <li className="footer-nav-item"><a href="#" data-testid="auction-help">官公庁オークションヘルプ</a></li>
        <li className="footer-nav-item"><a href="#" data-testid="contact">お問い合わせ</a></li>
      </ul>

    </div>
  );
}

export default FooterNav;