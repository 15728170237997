import { ReactComponent as CalendarIcon } from 'interfaces/assets/svg/calendar_logo.svg';
import getGoogleCalendarDatesParam from 'shared/utils/helper/getGoogleCalendarDatesParam.js';
import 'interfaces/css/common/calendar-icon-link.css';

const CalendarIconLink = ({ title, subtitle, startDate, endDate }) => {
  if (!startDate || !endDate) {
    return;
  }

  if (startDate > endDate) {
    endDate.setYear(endDate.getFullYear() + 1);
  }

  const formatStartDate = getGoogleCalendarDatesParam(startDate);
  const formatEndDate = getGoogleCalendarDatesParam(endDate);

  const url = `https://calendar.google.com/calendar/u/0/r/eventedit?text=${title}（${subtitle}）&dates=${formatStartDate}/${formatEndDate}`;

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="calendar-icon-link"
      data-testid="calendar-icon-link"
    >
      <CalendarIcon title="calendar"/>
    </a>
  );
};

export default CalendarIconLink;
