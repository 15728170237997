// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-other .faq-other-navigator {
  padding: 16px 16px 16px 48px;
  margin-top: 20px;
  border: 1px solid var(--tborder);
  border-radius: 10px;
  list-style-type: disc;
}

.faq-other .faq-other-navigator-item {
  margin-bottom: 8px;
  list-style-type: inherit;
}

.faq-other .faq-other-navigator-item:last-child {
  margin-bottom: 0;
}

.faq-other .faq-other-navigator-anchor-link {
  display: inline-block;
  line-height: 1.5;
  color: var(--anchor-base);
  cursor: pointer;
}

.faq-other .faq-other-navigator-anchor-link:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/FaqOther/faq-other-navigator.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,gBAAgB;EAChB,gCAAgC;EAChC,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".faq-other .faq-other-navigator {\n  padding: 16px 16px 16px 48px;\n  margin-top: 20px;\n  border: 1px solid var(--tborder);\n  border-radius: 10px;\n  list-style-type: disc;\n}\n\n.faq-other .faq-other-navigator-item {\n  margin-bottom: 8px;\n  list-style-type: inherit;\n}\n\n.faq-other .faq-other-navigator-item:last-child {\n  margin-bottom: 0;\n}\n\n.faq-other .faq-other-navigator-anchor-link {\n  display: inline-block;\n  line-height: 1.5;\n  color: var(--anchor-base);\n  cursor: pointer;\n}\n\n.faq-other .faq-other-navigator-anchor-link:hover {\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
