import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_DIVISION = `${BASE_URL_NAVIGATION}/storage/current/divisions/data.json`;
const PREVIOUS_DIVISION = `${BASE_URL_NAVIGATION}/storage/previous/divisions/data.json`;

export const fetchDivisionStorage = async () => {
  try {
    const response = await fetchDivision(CURRENT_DIVISION);
    return extractDivision(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ division ）を取得できませんでした");

    try {
      const response = await fetchDivision(PREVIOUS_DIVISION);
      return extractDivision(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ division ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchDivision = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractDivision = (response) => {
  return response.map(division => division);
}