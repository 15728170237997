import Modal2 from 'interfaces/components/common/Modal/Modal2';
import Button from 'interfaces/components/common/Button/Button';
import 'interfaces/css/common/kmanager-table.css';
import 'interfaces/css/common/kmanager-table-th.css';
import 'interfaces/css/common/kmanager-table-record.css';
import { ModalButtonArea } from 'interfaces/components/common/ModalButtonArea';

const AuctionGovernmentInvitationModal = ({
  invitation,
  government,
  handleClose,
}) => {
  return (
    <Modal2 handleClose={handleClose} padding={false} closeClickOutside={true}>
      <div className="kmanager-auction-operation pa-4">
        <div className="kmanager-table-wrap">
          <table className="kmanager-table kmanager-table-hover">
            <tbody>
              <tr>
                <td>公告名</td>
                <td>{invitation.name}</td>
              </tr>
              <tr>
                <td>入札自動終了</td>
                <td>
                  {invitation.bidEndType === 'MANUAL' ? 'いいえ' : 'はい'}
                </td>
              </tr>
              <tr>
                <td>保証契約証明書の押印希望</td>
                <td>
                  {invitation.mailCertDocument === 'NO_MAIL'
                    ? 'いいえ'
                    : 'はい'}
                </td>
              </tr>
              <tr>
                <td>公売保証金の運用</td>
                <td>
                  {invitation.noneDeposit === 'TRUE' ? '1' : '0'}:
                  {invitation.paymentCreditCard === 'TRUE' ? '1' : '0'}:
                  {invitation.paymentBankTransfer === 'TRUE' ? '1' : '0'}
                </td>
              </tr>
              <tr>
                <td>申し込み総数の表示（せり売）</td>
                <td>
                  {invitation.aplCountAuctionDisplay === 'DISPLAY'
                    ? 'はい'
                    : 'いいえ'}
                </td>
              </tr>
              <tr>
                <td>申し込み総数の表示（入札）</td>
                <td>
                  {invitation.aplCountAuctionDisplay === 'DISPLAY'
                    ? 'はい'
                    : 'いいえ'}
                </td>
              </tr>
              <tr>
                <td>入札件数の表示（入札）</td>
                <td>
                  {invitation.aplCountAuctionDisplay === 'DISPLAY'
                    ? 'はい'
                    : 'いいえ'}
                </td>
              </tr>
              <tr>
                <td>行政機関名</td>
                <td>{government.name}</td>
              </tr>
              <tr>
                <td>地域</td>
                <td>{invitation.area}</td>
              </tr>
              <tr>
                <td>担当部署</td>
                <td>{invitation.contactOffice}</td>
              </tr>
              <tr>
                <td>メールアドレス</td>
                <td>{invitation.contactEmail}</td>
              </tr>
              <tr>
                <td>電話番号</td>
                <td>{invitation.contactTel}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr className="modal-border" />
      <ModalButtonArea>
        <Button
          text={'閉じる'}
          isOutline={false}
          height={'44px'}
          className={'cancel-button new-style_navi_normal-button'}
          onClick={handleClose}
        />
      </ModalButtonArea>
    </Modal2>
  );
};

export default AuctionGovernmentInvitationModal;
