import axios from 'axios';

import {API_BASE_URL} from "../../config/envConstants.js";

export const createNaviBankTransferPagesData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/navi/bank-transfer-pages`,
    body, {headers: headers}
  );

};
export const updateNaviBankTransferPagesData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/navi/bank-transfer-pages/${id}`,
    body, {headers: headers}
  );
};
export const deleteNaviBankTransferPagesData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/navi/bank-transfer-pages/${id}`, {
      headers: headers,
    },);
};