//import {
//  FORGET_PASSWORD,
//  LOGIN,
//  SIGN_UP,
//} from '../../../../shared/utils/helper/authState';
import { findBySearchParams as getAuctionsBySearchParams } from 'domain/Auction/services/Auction';
import { get as getBidInformation } from 'domain/Bid/services/BidInfo';
import {
  get as getGeneralCategories,
  findByCategory as getCategories,
} from 'domain/Category/services/PropertyCategory';
import { get as getFavoritePropertyIds } from 'domain/Favorite/services/Favorite';
import { get as getPropertyImages } from 'domain/Files/services/PropertyFiles';
import { get as getGovernments } from 'domain/Government/services/Government';
import { findBySearchParams as getCarPropertiesBySearchParams } from 'domain/Property/services/CarProperty';
import { findBySearchParams as getEstatePropertiesBySearchParams } from 'domain/Property/services/EstateProperty';
import {
  get as getProperties,
  findByAuctionIds as getPropertiesAuctionIds,
  findBySearchParams as getPropertiesBySearchParams,
} from 'domain/Property/services/Property';
import { findByAuctionIds as getServiceInvitations } from 'domain/Auction/services/Invitation';
import { findByPropertyIds as getBidders } from 'domain/List/services/BidderList';

import { sortDESC as getPropertiesIdPriceDesc } from 'domain/Property/services/Property';
import { sortASC as getPropertiesIdPriceAsc } from 'domain/Property/services/Property';
import { sortDESC as getPropertiesIdDaysLeftDesc } from 'domain/Property/services/Property';
import { sortASC as getPropertiesIdDaysLeftAsc } from 'domain/Property/services/Property';
import { sortDESC as getPropertiesIdBidCountDesc } from 'domain/Property/services/Property';
import { sortASC as getPropertiesIdBidCountAsc } from 'domain/Property/services/Property';
import { sortDESC as getPropertiesIdMileageDesc } from 'domain/Property/services/Property';
import { sortASC as getPropertiesIdMileageAsc } from 'domain/Property/services/Property';
import { sortDESC as getPropertiesFirstRegisterDateDesc } from 'domain/Property/services/Property';
import { sortASC as getPropertiesIdFirstRegisterDateAsc } from 'domain/Property/services/Property';
import { getAllowPropertyIds } from 'domain/User/services/ServiceUser';
import { createGeneralCategoryData } from 'shared/utils/helper/createGeneralCategoryData';

import { ReactComponent as AmbulanceIcon } from 'interfaces/assets/svg/category/category_ambulance_icon.svg';
import { ReactComponent as ApartmentIcon } from 'interfaces/assets/svg/category/category_apartment_icon.svg';
import { ReactComponent as BusIcon } from 'interfaces/assets/svg/category/category_bus_icon.svg';
import { ReactComponent as ConstructionMachineryIcon } from 'interfaces/assets/svg/category/category_construction_machinery_icon.svg';
import { ReactComponent as HouseIcon } from 'interfaces/assets/svg/category/category_detached_house_icon.svg';
import { ReactComponent as DumpIcon } from 'interfaces/assets/svg/category/category_dump_icon.svg';
import { ReactComponent as FireEngineIcon } from 'interfaces/assets/svg/category/category_fire_engine_icon.svg';
import { ReactComponent as GeneralIcon } from 'interfaces/assets/svg/category/category_general_icon.svg';
import { ReactComponent as OthersIcon } from 'interfaces/assets/svg/category/category_others_icon.svg';
import { ReactComponent as TruckIcon } from 'interfaces/assets/svg/category/category_truck_icon.svg';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SERVICE, NAVI, KMANAGER } from 'shared/utils/helper/constants';

import { breadcrumbs } from '../entities/breadcrumbs';
import { PropertiesContext } from '../entities/PropertiesContext';

const PropertiesProvider = ({ type, children }) => {
  const [searchParams, setSearchParams] = useSearchParams({
    searchQuery: '',
    searchPropertyCategoryId: '',
    searchGovernmentSubCategoryId: '',
    direction: 'DESC',
    field: 'DEPOSIT',
    page: 1,
    pageSize: 50,
  });

  let category = searchParams.get('category');

  //const authState = {
  //  LOGIN: LOGIN,
  //  SIGN_UP: SIGN_UP,
  //  FORGET_PASSWORD: FORGET_PASSWORD,
  //};
  const [generalCategories, setGeneralCategories] = useState([]);
  const [governments, setGovernments] = useState([]);
  const [allowPropertyIds, setAllowPropertyIds] = useState([]);
  const [properties, setProperties] = useState([]);
  const [propertiesIdPriceDesc, setPropertiesIdPriceDesc] = useState([]);
  const [bidInformation, setBidInformation] = useState([]);
  const [favoritePropertyIds, setFavoritePropertyIds] = useState([]);
  const [propertiesIdPriceAsc, setPropertiesIdPriceAsc] = useState([]);
  const [propertiesIdDaysLeftDesc, setPropertiesIdDaysLeftDesc] = useState([]);
  const [propertiesIdDaysLeftAsc, setPropertiesIdDaysLeftAsc] = useState([]);
  const [propertiesIdBidCountDesc, setPropertiesIdBidCountDesc] = useState([]);
  const [propertiesIdBidCountAsc, setPropertiesIdBidCountAsc] = useState([]);
  const [propertiesIdMileageDesc, setPropertiesIdMileageDesc] = useState([]);
  const [propertiesIdMileageAsc, setPropertiesIdMileageAsc] = useState([]);
  const [propertiesFirstRegisterDateDesc, setPropertiesFirstRegisterDateDesc] =
    useState([]);
  const [
    propertiesIdFirstRegisterDateAsc,
    setPropertiesIdFirstRegisterDateAsc,
  ] = useState([]);
  //const [myBidPrice, setMyBidPrice] = useState([]);
  const [myBidPrice] = useState([]);
  const [propertyImages, setPropertyImages] = useState([]);
  //const [applicantPropertyIds, setApplicantPropertyIds] = useState([]);
  const [applicantPropertyIds] = useState([]);
  const [carCategoryData, setCarCategoryData] = useState([]);
  const [estateCategoryData, setEstateCategoryData] = useState([]);
  const [propertyCount, setPropertyCount] = useState({
    general: 0,
    car: 0,
    estate: 0,
  });

  const carIconData = (name) => {
    if (name === '一般車') return <GeneralIcon />;
    if (name === 'バス') return <BusIcon />;
    if (name === '救急車') return <AmbulanceIcon />;
    if (name === '消防車') return <FireEngineIcon />;
    if (name === 'トラック') return <TruckIcon />;
    if (name === '建設機械') return <ConstructionMachineryIcon />;
    if (name === 'ダンプ') return <DumpIcon />;
    if (name === 'その他') return <OthersIcon />;
    return <OthersIcon />;
  };

  const estateIconData = (name) => {
    if (name === 'マンション') return <ApartmentIcon />;
    if (name === '一戸建て') return <HouseIcon />;
    if (name === '土地') return <HouseIcon />;
    return <OthersIcon />;
  };

  const createCarCategoryData = (categories) => {
    let categoryData = [];

    [
      '一般車',
      'バス',
      '救急車',
      '消防車',
      'トラック',
      '建設機械',
      'ダンプ',
      'その他',
    ].forEach((name) => {
      let tmpCategoryData = {
        categoryIds: [],
        categoryName: '',
        icon: undefined,
      };
      let childCategories = [];
      if (name !== 'その他') {
        childCategories = categories.filter(
          (data) => data.name === name && data.depth === '2',
        );
      } else {
        childCategories = categories.filter(
          (data) =>
            [
              '一般車',
              'バス',
              '救急車',
              '消防車',
              'トラック',
              '建設機械',
              'ダンプ',
            ].includes(data.name) === false && data.depth === '2',
        );
      }

      if (name === '一般車') {
        tmpCategoryData.categoryName = '一般';
      } else {
        tmpCategoryData.categoryName = name;
      }

      let grandchildCategories = [];
      childCategories.forEach((childCategory) => {
        tmpCategoryData.categoryIds.push(childCategory.id);
        tmpCategoryData.icon = carIconData(name);

        grandchildCategories = categories.filter(
          (data) => data.parentId.toString() === childCategory.id.toString(),
        );
      });

      grandchildCategories.forEach((data) =>
        tmpCategoryData.categoryIds.push(data.id),
      );
      categoryData.push(tmpCategoryData);
    });
    return categoryData;
  };

  const createEstateCategoryData = (categories) => {
    let categoryData = [];

    ['マンション', '一戸建て', '土地', 'その他'].forEach((name) => {
      let tmpCategoryData = {
        categoryIds: [],
        categoryName: '',
        icon: undefined,
      };
      let childCategories = [];
      if (name !== 'その他') {
        childCategories = categories.filter(
          (data) => data.name === name && data.depth === '2',
        );
      } else {
        childCategories = categories.filter(
          (data) =>
            ['マンション', '一戸建て', '土地'].includes(data.name) === false &&
            data.depth === '2',
        );
      }

      tmpCategoryData.categoryName = name;

      let grandchildCategories = [];
      childCategories.forEach((childCategory) => {
        tmpCategoryData.categoryIds.push(childCategory.id);
        tmpCategoryData.icon = estateIconData(name);

        grandchildCategories = categories.filter(
          (data) => data.parentId.toString() === childCategory.id.toString(),
        );
      });

      grandchildCategories.forEach((data) =>
        tmpCategoryData.categoryIds.push(data.id),
      );
      categoryData.push(tmpCategoryData);
    });

    return categoryData;
  };

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      // 物件件数の取得
      getPropertiesAuctionIds(type, undefined).then((resultProperties) => {
        let generalNum = resultProperties.length;
        let carNum = resultProperties.filter(
          (property) => property.propertyType === 'CAR',
        ).length;
        let estateNum = resultProperties.filter(
          (property) => property.propertyType === 'ESTATE',
        ).length;
        setPropertyCount({
          ...propertyCount,
          general: generalNum,
          car: carNum,
          estate: estateNum,
        });

        if (category === 'ESTATE') {
          getCategories(type, category).then((estateCategories) => {
            let estateCategoryData = createEstateCategoryData(estateCategories);
            setEstateCategoryData(estateCategoryData);
          });
        } else if (category === 'CAR') {
          getCategories(type, category).then((carCategories) => {
            let carCategoryData = createCarCategoryData(carCategories);
            setCarCategoryData(carCategoryData);
          });
        } else {
          getCategories(type, category).then((result) => {
            let generalCategoryData = createGeneralCategoryData(
              result,
              resultProperties,
            );
            setGeneralCategories(generalCategoryData);
          });
        }
      });
    }, [category]);

    // 行政機関取得
    //useEffect(() => {
    //  getGovernments(type).then(result => {
    //    setGovernments(result);
    //  });
    //}, []);

    // ??
    //useEffect(() => {
    //  getAllowPropertyIds(userId).then(result => {
    //    setAllowPropertyIds(result);
    //  });
    //}, [userId]);

    // 物件情報取得
    useEffect(() => {
      getAuctionsBySearchParams(type, searchParams).then((resultAuctions) => {
        let auctionIds = resultAuctions.map((auction) => auction.id);
        getGovernments(type).then((governments) => {
          getPropertiesBySearchParams(type, searchParams, auctionIds).then(
            (properties) => {
              getServiceInvitations(type, auctionIds).then((invitations) => {
                getBidders(
                  type,
                  properties.map((property) => property.id),
                ).then((bidders) => {
                  properties.forEach((property) => {
                    // 全ての情報を取得する必要はないかもしれない。不要なら削除
                    property.auction = resultAuctions.find(
                      (auction) => auction.id === property.auctionId,
                    );

                    let targetDeadline = '';
                    if (property.status === 'APPLYING') {
                      targetDeadline = property.auction.aplEnd;
                    }
                    if (property.status === 'WAIT_START_BID') {
                      targetDeadline = property.auction.bidStart;
                    }
                    if (property.status === 'DURING_BIDDING') {
                      targetDeadline = property.auction.bidEnd;
                    }
                    if (property.status === 'ADDITIONAL_DURING_BIDDING') {
                      targetDeadline = property.auction.addBidDuration;
                    }
                    property.deadline = targetDeadline;

                    property.bidCount = bidders.filter(
                      (bidder) => bidder.propertyId === property.id,
                    ).length;

                    let tags = [];
                    tags.push(
                      property.auction.procedureType === 'ASSET_TYPE'
                        ? '公有財産売却'
                        : 'インターネット公売',
                    );
                    tags.push(
                      property.publicSaleType === 'AUCTION'
                        ? 'せり売形式'
                        : '入札形式',
                    );

                    let invitation = invitations.find(
                      (invitation) =>
                        invitation.auctionId === property.auction.id &&
                        invitation.governmentId === property.governmentId,
                    );
                    if (invitation) {
                      let tag = '';
                      if (
                        invitation.paymentCreditCard === 'TRUE' ||
                        invitation.paymentBankTransfer === 'TRUE'
                      ) {
                        tag = '保証金あり';
                        if (invitation.paymentCreditCard === 'TRUE') {
                          tag += '(クレカ納付OK)';
                        }
                      } else {
                        tag = '保証金なし';
                      }
                      tags.push(tag);
                    }

                    property.tags = tags;

                    let government = governments.find(
                      (government) => government.id === property.governmentId,
                    );

                    property.governmentName = government?.name;
                  });

                  // 自動車物件の独自の条件検索を行う
                  if (category === 'CAR') {
                    getCarPropertiesBySearchParams(
                      type,
                      searchParams,
                      properties,
                    ).then((carResult) => {
                      setProperties(carResult);
                    });

                    // 不動産物件の独自の条件検索を行う
                  } else if (category === 'ESTATE') {
                    getEstatePropertiesBySearchParams(
                      type,
                      searchParams,
                      properties,
                    ).then((estateResult) => {
                      setProperties(estateResult);
                    });
                  } else {
                    setProperties(properties);
                  }
                });
              });
            },
          );
        });
      });
    }, [searchParams]);

    //useEffect(() => {
    //  getBidInformation(type).then(result => {
    //    setBidInformation(result);
    //  });
    //}, []);

    // お気に入りの物件IDを取得
    //useEffect(() => {
    //  getFavoritePropertyIds(type).then(result => {
    //    setFavoritePropertyIds(result);
    //  });
    //}, []);

    // 各ソートの結果
    //useEffect(() => {
    //  getPropertiesIdPriceDesc(type).then(result => {
    //    setPropertiesIdPriceDesc(result);
    //  });
    //}, []);
    //useEffect(() => {
    //  getPropertiesIdPriceAsc(type).then(result => {
    //    setPropertiesIdPriceAsc(result);
    //  });
    //}, []);
    //useEffect(() => {
    //  getPropertiesIdDaysLeftDesc(type).then(result => {
    //    setPropertiesIdDaysLeftDesc(result);
    //  });
    //}, []);
    //useEffect(() => {
    //  getPropertiesIdDaysLeftAsc(type).then(result => {
    //    setPropertiesIdDaysLeftAsc(result);
    //  });
    //}, []);
    //useEffect(() => {
    //  getPropertiesIdBidCountDesc(type).then(result => {
    //    setPropertiesIdBidCountDesc(result);
    //  });
    //}, []);
    //useEffect(() => {
    //  getPropertiesIdBidCountAsc(type).then(result => {
    //    setPropertiesIdBidCountAsc(result);
    //  });
    //}, []);
    //useEffect(() => {
    //  getPropertiesIdMileageDesc(type).then(result => {
    //    setPropertiesIdMileageDesc(result);
    //  });
    //}, []);
    //useEffect(() => {
    //  getPropertiesIdMileageAsc(type).then(result => {
    //    setPropertiesIdMileageAsc(result);
    //  });
    //}, []);
    //useEffect(() => {
    //  getPropertiesFirstRegisterDateDesc(type).then(result => {
    //    setPropertiesFirstRegisterDateDesc(result);
    //  });
    //}, []);
    //useEffect(() => {
    //  getPropertiesIdFirstRegisterDateAsc(type).then(result => {
    //    setPropertiesIdFirstRegisterDateAsc(result);
    //  });
    //}, []);

    ////useEffect(() => {
    ////  getMyBidPrice(type).then(result => {
    ////    setMyBidPrice(result);
    ////  });
    ////}, []);
    //useEffect(() => {
    //  getPropertyImages(type).then(result => {
    //    setPropertyImages(result);
    //  });
    //}, []);
    //useEffect(() => {
    //  getApplicantPropertyIds(type).then(result => {
    //    setApplicantPropertyIds(result);
    //  });
    //}, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      getGeneralCategories(type).then((result) => {
        setGeneralCategories(result);
      });
    }, []);
    useEffect(() => {
      getGovernments(type).then((result) => {
        setGovernments(result);
      });
    }, []);
    useEffect(() => {
      getAllowPropertyIds(type).then((result) => {
        setAllowPropertyIds(result);
      });
    }, []);
    useEffect(() => {
      getProperties(type).then((result) => {
        setProperties(result);
      });
    }, []);
    useEffect(() => {
      getPropertiesIdPriceDesc(type).then((result) => {
        setPropertiesIdPriceDesc(result);
      });
    }, []);
    useEffect(() => {
      getBidInformation(type).then((result) => {
        setBidInformation(result);
      });
    }, []);
    useEffect(() => {
      getFavoritePropertyIds(type).then((result) => {
        setFavoritePropertyIds(result);
      });
    }, []);
    useEffect(() => {
      getPropertiesIdPriceAsc(type).then((result) => {
        setPropertiesIdPriceAsc(result);
      });
    }, []);
    useEffect(() => {
      getPropertiesIdDaysLeftDesc(type).then((result) => {
        setPropertiesIdDaysLeftDesc(result);
      });
    }, []);
    useEffect(() => {
      getPropertiesIdDaysLeftAsc(type).then((result) => {
        setPropertiesIdDaysLeftAsc(result);
      });
    }, []);
    useEffect(() => {
      getPropertiesIdBidCountDesc(type).then((result) => {
        setPropertiesIdBidCountDesc(result);
      });
    }, []);
    useEffect(() => {
      getPropertiesIdBidCountAsc(type).then((result) => {
        setPropertiesIdBidCountAsc(result);
      });
    }, []);
    useEffect(() => {
      getPropertiesIdMileageDesc(type).then((result) => {
        setPropertiesIdMileageDesc(result);
      });
    }, []);
    useEffect(() => {
      getPropertiesIdMileageAsc(type).then((result) => {
        setPropertiesIdMileageAsc(result);
      });
    }, []);
    useEffect(() => {
      getPropertiesFirstRegisterDateDesc(type).then((result) => {
        setPropertiesFirstRegisterDateDesc(result);
      });
    }, []);
    useEffect(() => {
      getPropertiesIdFirstRegisterDateAsc(type).then((result) => {
        setPropertiesIdFirstRegisterDateAsc(result);
      });
    }, []);
    //useEffect(() => {
    //  getMyBidPrice(type).then(result => {
    //    setMyBidPrice(result);
    //  });
    //}, []);
    useEffect(() => {
      getPropertyImages(type).then((result) => {
        setPropertyImages(result);
      });
    }, []);
    //useEffect(() => {
    //  getApplicantPropertyIds(type).then(result => {
    //    setApplicantPropertyIds(result);
    //  });
    //}, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <PropertiesContext.Provider
      value={{
        //authState,
        breadcrumbs,
        generalCategories,
        governments,
        allowPropertyIds,
        properties,
        propertiesIdPriceDesc,
        bidInformation,
        favoritePropertyIds,
        propertiesIdPriceAsc,
        propertiesIdDaysLeftDesc,
        propertiesIdDaysLeftAsc,
        propertiesIdBidCountDesc,
        propertiesIdBidCountAsc,
        propertiesIdMileageDesc,
        propertiesIdMileageAsc,
        propertiesFirstRegisterDateDesc,
        propertiesIdFirstRegisterDateAsc,
        myBidPrice,
        propertyImages,
        applicantPropertyIds,
        carCategoryData,
        estateCategoryData,
        propertyCount,
        searchParams,
        setSearchParams,
      }}>
      {children}
    </PropertiesContext.Provider>
  );
};

export default PropertiesProvider;
