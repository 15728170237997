// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-sheet {
    border-radius: 0;
}
.theme--light{
    border-color: #fff;
    background-color: #fff;
}
.no-bg {
    background: none!important;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/components/Property/service/PropertyDetail/css/property-detail.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".v-sheet {\n    border-radius: 0;\n}\n.theme--light{\n    border-color: #fff;\n    background-color: #fff;\n}\n.no-bg {\n    background: none!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
