export const trans_right_date = (data) => {
  return [
    {
      id: 1,
      title: "権利移転の時期",
      value: data?.transRightDate,
    },
    {
      id: 2,
      title: "登記手続き完了までの期間",
      value: data?.transRightCompletedTime,
    },

  ]
}