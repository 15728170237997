import 'interfaces/css/Category/category_accordion.css'

const InputCategoryDisplay = ({ status, handleChange, label }) => {

  return (
    <div className="form-input flex align-center">
      <label className="flex">
        <input className="mr-1" type="checkbox" data-testid="register-form"
               name={label.name ?? ''}
               checked={status === 'DISPLAY'}
               onChange={(e) => handleChange(e, label.name)}
        />
        <p>{label.text ?? ''}</p>
      </label>
    </div>
  );
};

export default InputCategoryDisplay;
