import { useEffect } from 'react';

const OneTimeToken = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    // ワンタイムトークン用URL
    script.src = '';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const doSubmit = () => {
    console.log('doSubmit');
    window.com_sbps_system.generateToken(
      {
        merchantId: document.getElementById('merchant_id').value,
        serviceId: document.getElementById('service_id').value,
        ccNumber: document.getElementById('cc_number').value,
        ccExpiration: document.getElementById('cc_expiration').value,
        securityCode: document.getElementById('security_code').value,
      },
      afterGenerateToken,
    );
  };

  const afterGenerateToken = (response) => {
    if (response.result === 'OK') {
      console.log(response.tokenResponse.token);
      console.log(response.tokenResponse.tokenKey);
    } else {
      alert('エラーが発生しました。トークンの取得に失敗しました。');
    }
  };

  return (
    <>
      <h1>Test One Time Token</h1>
      <button type="button" onClick={doSubmit}>
        ワンタイムトークン発行
      </button>
    </>
  );
};

export default OneTimeToken;
