import GovernmentProvider from './GovernmentProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { government } from "../entities/componentMapping.js";

const Government = ({ type }) => {
  return (
    <GovernmentProvider type={type}>
      {getComponent(government, type)}
    </GovernmentProvider>
  );
};

export default Government;
