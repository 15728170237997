
export class Depth {
  #depth

  constructor(depth) {
    this.#depth = depth;
  }

  get() {
    return this.#depth
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
