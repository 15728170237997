import 'interfaces/css/SampleGuideline/guideline-modal.css';
import GuidelineModalHeader from './GuidelineModalHeader.jsx';
import GuidelineModalBody from './GuidelineModalBody.jsx';
import GuidelineModalFooter from './GuidelineModalFooter.jsx';

const GuidelineModal = ({ isOpen, closeModal, text, value }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div>
      <div className="guideline-modal-overlay" onClick={closeModal} data-testid="guideline-modal-overlay"></div>
      <div className="guideline-modal-content">
        <div data-testid="guideline-modal" className="guideline-modal">
          <GuidelineModalHeader text={text}/>
          <GuidelineModalBody value={value}/>
          <GuidelineModalFooter closeModal={closeModal} value={value}/>
        </div>
      </div>
    </div>
  );
};


export default GuidelineModal;
