import FaqProvider from './FaqProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { faq } from "../entities/componentMapping.js";

const Faq = ({ type }) => {
  return (
    <FaqProvider type={type}>
      {getComponent(faq, type)}
    </FaqProvider>
  );
};

export default Faq;
