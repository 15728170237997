import { DATA_EMPTY } from 'shared/utils/helper/constants';

const isEmpty = [DATA_EMPTY, undefined, '', '-9999'];

export const property_detail_asset_sale = (data) => [
  {
    text: '保証金納付手続き',
    state: isEmpty.includes(data?.depositPayment)
      ? '未入力'
      : data?.depositPayment,
  },
  {
    text: '予定価格',
    state: isEmpty.includes(data?.estimatePrice)
      ? '未入力'
      : data?.estimatePrice,
  },
  {
    text: '入札保証金',
    state: isEmpty.includes(data?.deposit) ? '未入力' : data?.deposit,
  },
  {
    text: '適格請求書',
    state: isEmpty.includes(data?.qualifiedBill)
      ? '未入力'
      : data?.qualifiedBill,
  },
];

export const property_detail_public_sale = (data) => [
  {
    text: '保証金納付手続き',
    state: isEmpty.includes(data?.depositPayment)
      ? '未入力'
      : data?.depositPayment,
  },
  {
    text: '参加申し込み承認',
    state: isEmpty.includes(data?.approval) ? '未入力' : data?.approval,
  },
  {
    text: '予定価格',
    state: isEmpty.includes(data?.estimatePrice)
      ? '未入力'
      : data?.estimatePrice,
  },
  {
    text: '入札保証金',
    state: isEmpty.includes(data?.deposit) ? '未入力' : data?.deposit,
  },
  {
    text: '適格請求書',
    state: isEmpty.includes(data?.qualifiedBill)
      ? '未入力'
      : data?.qualifiedBill,
  },
];
