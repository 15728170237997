
export class OpenButtonDownDate {
  #openButtonDownDate

  constructor(openButtonDownDate) {
    this.#openButtonDownDate = openButtonDownDate;
  }

  get() {
    return this.#openButtonDownDate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
