import { validateSelected } from "../../../../application/validations.js";

export class GovernmentId {
  #governmentId

  constructor(governmentId) {
    this.#governmentId = governmentId;
  }

  get() {
    return this.#governmentId
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#governmentId)
  }
}
