import Heading2 from './Heading2.jsx';
import InputCheckBox from './InputCheckBox.jsx';
import InputDate from './InputDate.jsx';
import InputDateTime from './InputDateTime.jsx';
import InputFile from './InputFile.jsx';
import InputMarkDown from './InputMarkDown.jsx';
import InputPullDown from './InputPullDown.jsx';
import InputRadio from './InputRadio.jsx';
import InputText from './InputText.jsx';
import InputTime from './InputTime.jsx';

const InputType = (input, index) => {
  switch (input.type) {
    case 'text':
      return (
        <InputText
          key={index}
          label={input.text}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
          cautionMessage={input.cautionMessage}
          maxCharacters={input.maxCharacters}
        />
      );
    case 'radio':
      return (
        <InputRadio
          key={index}
          label={input.text}
          radio_labels={input.list}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
          cautionMessage={input.cautionMessage}
        />
      );
    case 'select':
      return (
        <InputPullDown
          key={index}
          label={input.text}
          itemList={input.list}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
          cautionMessage={input.cautionMessage}
          placeholder={input.placeholder}
        />
      );
    case 'check':
      return (
        <InputCheckBox
          key={index}
          label={input.text}
          check_labels={input.list}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
          describe={input.describe}
          cautionMessage={input.cautionMessage}
        />
      );
    case 'file':
      return (
        <InputFile
          key={index}
          label={input.text}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
          cautionMessage={input.cautionMessage}
        />
      );
    case 'date':
      return (
        <InputDate
          key={index}
          label={input.text}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
          cautionMessage={input.cautionMessage}
        />
      );
    case 'time':
      return (
        <InputTime
          key={index}
          label={input.text}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
          cautionMessage={input.cautionMessage}
        />
      );
    case 'datetime':
      return (
        <InputDateTime
          key={index}
          label={input.text}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
          placeholder={input.placeholder}
          cautionMessage={input.cautionMessage}
        />
      );
    case 'markdown':
      return (
        <InputMarkDown
          key={index}
          label={input.text}
          state={input.state}
          setState={input.setters}
          invalidMessage={input.validationMessage}
          required={input.required}
          width={input.width}
          cautionMessage={input.cautionMessage}
        />
      );
    case 'h2':
      return <Heading2 text={input.text} description={input.description} />;
    default:
      return;
  }
};

export default InputType;
