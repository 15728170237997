const convertToDateTime = (year, dateString) => {
  const match = dateString.match(/(\d+)月(\d+)日 \(.\) (\d+)時/);

  if (!match) {
    return;
  }

  const month = parseInt(match[1], 10) - 1; // 0~11
  const day = parseInt(match[2], 10);
  const hour = parseInt(match[3], 10);

  return new Date(year, month, day, hour);
}

export default convertToDateTime;
