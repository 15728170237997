// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-system-usage .calc-method-table {
  width: 100%;
  margin: 40px 0;
}

.confirm-system-usage table {
  width: 100%;
  border-spacing: 0;
}

.confirm-system-usage table {
  border: 1px solid #ccc !important;
}

.confirm-system-usage thead {
  background-color: #fbe2ca;
}

.confirm-system-usage th {
  border: 1px solid #ccc !important;
  color: rgba(0,0,0,.6);
  font-size: .75rem;
  height: 32px;
  padding: 0 16px;
  text-align: center!important;
}

.confirm-system-usage tbody {
}

.confirm-system-usage td {
  border: 1px solid #ccc !important;
  font-size: .875rem;
  height: 32px;
  padding: 0 16px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/ConfirmSystemUsage/calc-method-table.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iCAAiC;EACjC,qBAAqB;EACrB,iBAAiB;EACjB,YAAY;EACZ,eAAe;EACf,4BAA4B;AAC9B;;AAEA;AACA;;AAEA;EACE,iCAAiC;EACjC,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".confirm-system-usage .calc-method-table {\n  width: 100%;\n  margin: 40px 0;\n}\n\n.confirm-system-usage table {\n  width: 100%;\n  border-spacing: 0;\n}\n\n.confirm-system-usage table {\n  border: 1px solid #ccc !important;\n}\n\n.confirm-system-usage thead {\n  background-color: #fbe2ca;\n}\n\n.confirm-system-usage th {\n  border: 1px solid #ccc !important;\n  color: rgba(0,0,0,.6);\n  font-size: .75rem;\n  height: 32px;\n  padding: 0 16px;\n  text-align: center!important;\n}\n\n.confirm-system-usage tbody {\n}\n\n.confirm-system-usage td {\n  border: 1px solid #ccc !important;\n  font-size: .875rem;\n  height: 32px;\n  padding: 0 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
