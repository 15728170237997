import Button from "interfaces/components/common/Button";
import ErrorMessage from "interfaces/components/common/ErrorMessage";
import PageTitle from "interfaces/components/common/PageTitle";
import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

import InputPassword from "./InputPassword";
import InputUserName from "./InputUserName";
import "interfaces/css/Login/login-form.css"
import { ServiceTypes } from "shared/utils/constants/service";
import { login } from "domain/Auth/services/Login";

const Login = () => {
  const navigation = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const TEXT_FAIL_LOGIN = "ユーザー名かパスワードが異なります。";

  useEffect(() => {
    setIsDisabled(!(userName && password));
  }, [userName, password]);


  const handlePopstate = () => {
    navigation('/kmanager/login')
  }

  useEffect(() => {
    if (localStorage.getItem("kmanagerUserId") !== null) { navigation("/kmanager") }
    window.addEventListener('popstate', handlePopstate, false);
  }, []);

  async function handleClick() {
    setMessage("")

    if (isDisabled) {
      return;
    }
    try {
      const requiredNewPassword = await login(userName, password, ServiceTypes.KMANAGER.string)
      console.log(requiredNewPassword)

      if (requiredNewPassword) {
        // TODO: パスワード変更画面に遷移するようにする
        navigation("/kmanager")
      }
      navigation("/kmanager")
    } catch (error) {
      setMessage(TEXT_FAIL_LOGIN)
    }
    navigation('/kmanager');
  }

  return (
    <div className="kmanager-login-div" data-testid="kmanager-login-div">
      <div className="kmanager-login-form" data-testid="kmanager-login-form">
        <PageTitle text="ログイン"/>
        {message && <ErrorMessage text={message}/>}
        <InputUserName state={userName} setState={setUserName}/>
        <InputPassword state={password} setState={setPassword}/>
        <Button className="secondary full-width" text="ログイン" clickHandler={handleClick}/>
      </div>
    </div>
  )

}
export default Login