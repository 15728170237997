import KmanagerTableEmptyRecord from 'interfaces/components/common/Table/KmanagerTableEmptyRecord';
import { useContext } from 'react';

import { ServiceProviderContext } from '../../../../pages/ServiceProvider/entities/ServiceProviderContext';

import 'interfaces/css/common/kmanager-table.css';
import 'interfaces/css/common/kmanager-table-th.css';

export const companyHeaders = [
  { id: 1, column: 'registrationNumber', text: '登録番号' },
  { id: 2, column: 'postalCode', text: '郵便番号' },
  { id: 3, column: 'address', text: '住所' },
  { id: 4, column: 'name', text: '会社名' },
  { id: 5, column: 'position', text: '役職' },
  { id: 6, column: 'ceoName', text: '社長名' },
];

export const accountHeaders = [
  { id: 7, column: 'bankName', text: '銀行名' },
  { id: 8, column: 'branchName', text: '支店名' },
  { id: 9, column: 'depositType', text: '預金種目' },
  { id: 11, column: 'accountNumber', text: '口座番号' },
  { id: 12, column: 'accountHolder', text: '名義人' },
];

export const contactHeaders = [
  { id: 13, column: 'contactDepartment', text: '担当部署' },
  { id: 14, column: 'contactPerson', text: '担当者名' },
  {
    id: 15,
    column: 'contactPaEmail',
    text: '公売のお問い合わせ先のメールアドレス',
  },
  {
    id: 16,
    column: 'contactPpsEmail',
    text: '公有財産売却のお問い合わせ先のメールアドレス',
  },
  { id: 17, column: 'confirmer', text: '確認者' },
  { id: 18, column: 'issuer', text: '発行者' },
];

const ServiceProvidersTable = () => {
  const { serviceProviders } = useContext(ServiceProviderContext);

  return (
    <div>
      <div className="kmanager-col kmanager-col-12">
        <h2>会社情報</h2>
      </div>
      <div className="kmanager-table-wrap">
        <table
          className="kmanager-table kmanager-table-hover"
          data-testid="kmanager-table">
          <thead data-testid="kmanager-table-column">
            <tr data-testid="kmanager-tr">
              {companyHeaders.map((header) => {
                return (
                  <th
                    key={header.id}
                    className="kmanager-th kmanager-table-th text-center"
                    data-testid="kmanager-table-th">
                    <div className="align-center justify-center d-flex">
                      {header.text}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {serviceProviders.length > 0 ? (
              serviceProviders.map((serviceProvider) => {
                return (
                  <tr
                    key={serviceProvider.id}
                    className="kmanager-table-record"
                    data-testid="kmanager-table-record">
                    <td>
                      <div>{serviceProvider.registrationNumber}</div>
                    </td>
                    <td>
                      <div>{serviceProvider.postalCode}</div>
                    </td>
                    <td>
                      <div>{serviceProvider.address}</div>
                    </td>
                    <td>
                      <div>{serviceProvider.name}</div>
                    </td>
                    <td>
                      <div>{serviceProvider.position}</div>
                    </td>
                    <td>
                      <div>{serviceProvider.ceoName}</div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <KmanagerTableEmptyRecord />
            )}
          </tbody>
        </table>
      </div>

      <div className="kmanager-col kmanager-col-12">
        <h2>お振込先</h2>
      </div>
      <div className="kmanager-table-wrap">
        <table
          className="kmanager-table kmanager-table-hover"
          data-testid="kmanager-table">
          <thead data-testid="kmanager-table-column">
            <tr data-testid="kmanager-tr">
              {accountHeaders.map((header) => {
                return (
                  <th
                    key={header.id}
                    className="kmanager-th kmanager-table-th text-center"
                    data-testid="kmanager-table-th">
                    <div className="align-center justify-center d-flex">
                      {header.text}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {serviceProviders.length > 0 ? (
              serviceProviders.map((serviceProvider) => {
                return (
                  <tr
                    key={serviceProvider.id}
                    className="kmanager-table-record"
                    data-testid="kmanager-table-record">
                    <td>
                      <div>{serviceProvider.bankName}</div>
                    </td>
                    <td>
                      <div>{serviceProvider.branchName}</div>
                    </td>
                    <td>
                      <div>{serviceProvider.depositType}</div>
                    </td>
                    <td>
                      <div>{serviceProvider.accountNumber}</div>
                    </td>
                    <td>
                      <div>{serviceProvider.accountHolder}</div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <KmanagerTableEmptyRecord />
            )}
          </tbody>
        </table>
      </div>

      <div className="kmanager-col kmanager-col-12">
        <h2>お問い合わせ先</h2>
      </div>
      <div className="kmanager-table-wrap">
        <table
          className="kmanager-table kmanager-table-hover"
          data-testid="kmanager-table">
          <thead data-testid="kmanager-table-column">
            <tr data-testid="kmanager-tr">
              {contactHeaders.map((header) => {
                return (
                  <th
                    key={header.id}
                    className="kmanager-th kmanager-table-th text-center"
                    data-testid="kmanager-table-th">
                    <div className="align-center justify-center d-flex">
                      {header.text}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {serviceProviders.length > 0 ? (
              serviceProviders.map((serviceProvider) => {
                return (
                  <tr
                    key={serviceProvider.id}
                    className="kmanager-table-record"
                    data-testid="kmanager-table-record">
                    <td>
                      <div>{serviceProvider.contactDepartment}</div>
                    </td>
                    <td>
                      <div>{serviceProvider.contactPerson}</div>
                    </td>
                    <td>
                      <div>{serviceProvider.contactPaEmail}</div>
                    </td>
                    <td>
                      <div>{serviceProvider.contactPpsEmail}</div>
                    </td>
                    <td>
                      <div>{serviceProvider.confirmer}</div>
                    </td>
                    <td>
                      <div>{serviceProvider.issuer}</div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <KmanagerTableEmptyRecord />
            )}
          </tbody>
        </table>
      </div>

      <div className="kmanager-col kmanager-col-12">
        <h2>備考</h2>
      </div>
      <div className="kmanager-table-wrap">
        <table
          className="kmanager-table kmanager-table-hover"
          data-testid="kmanager-table">
          <tbody>
            {serviceProviders.length > 0 ? (
              serviceProviders.map((serviceProvider) => {
                return (
                  <tr
                    key={serviceProvider.id}
                    className="kmanager-table-record"
                    data-testid="kmanager-table-record">
                    <td>
                      <div>{serviceProvider.remarks}</div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <KmanagerTableEmptyRecord />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServiceProvidersTable;
