import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { findById as auctionFindById } from '../../../../domain/Auction/services/Auction.js';
import { findById as invitationFindById } from '../../../../domain/Auction/services/Invitation.js';
import { findById as getGovernment } from '../../../../domain/Government/services/Government';
import { findByAuctionIdAndGovernmentId as getProperty } from '../../../../domain/Property/services/Property.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { NAVI } from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { PropertyListContext } from '../entities/PropertyListContext.js';

const PropertyListProvider = ({ type, children }) => {
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [invitation, setInvitation] = useState([]);
  const [property, setProperty] = useState([]);
  const [auction, setAuction] = useState([]);
  const [governmentType, setGovernmentType] = useState(undefined);
  // auctionIdの間違い
  const { auction_id } = useParams();
  const [searchParam, setSearchParam] = useState({ manageId: '', title: '' });
  const [sortParam, setSortParam] = useState({ sortId: 1, order: 'asc' });
  const { localStorageGovernmentId } = useLocalStorage();

  // データの取得、加工等を行う
  if (type === NAVI) {
    useEffect(() => {
      invitationFindById(type, auction_id, localStorageGovernmentId).then(
        (invitation) => {
          if (invitation !== undefined) {
            setInvitation(invitation);
            auctionFindById(type, invitation.auctionId).then((a) => {
              setAuction(a);
            });
          }
        },
      );
      getGovernment(type, localStorageGovernmentId).then((government) => {
        setGovernmentType(government?.governmentType);
      });
    }, [localStorageGovernmentId]);

    useEffect(() => {
      getProperty(type, auction_id, localStorageGovernmentId).then(
        (properties) => {
          // filter
          if (searchParam.manageId !== '') {
            properties = properties.filter((data) =>
              data.saleNumber?.includes(searchParam.manageId),
            );
          }
          if (searchParam.title !== '') {
            properties = properties.filter((data) =>
              data.name?.includes(searchParam.title),
            );
          }

          // sort
          properties = sortProperties(properties);
          setProperty(properties);
        },
      );
    }, [localStorageGovernmentId, searchParam, sortParam]);
  }

  const sortProperties = (properties) => {
    if (sortParam.sortId === 1) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          Number(a.printOrder) > Number(b.printOrder) ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          Number(b.printOrder) > Number(a.printOrder) ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 2) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          a.saleNumber > b.saleNumber ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          b.saleNumber > a.saleNumber ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 3) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) => (a.name > b.name ? 1 : -1));
      } else {
        return properties.sort((a, b) => (b.name > a.name ? 1 : -1));
      }
    }
    if (sortParam.sortId === 4) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          Number(a.estimatePrice) > Number(b.estimatePrice) ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          Number(b.estimatePrice) > Number(a.estimatePrice) ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 5) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) => (a.updatedAt > b.updatedAt ? 1 : -1));
      } else {
        return properties.sort((a, b) => (b.updatedAt > a.updatedAt ? 1 : -1));
      }
    }
    return properties;
  };

  return (
    <PropertyListContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentType,
        auction,
        invitation,
        property,
        setSearchParam,
        setSortParam,
      }}>
      {children}
    </PropertyListContext.Provider>
  );
};

export default PropertyListProvider;
