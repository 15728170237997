// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createNaviGovernmentTopPagesData,
  updateNaviGovernmentTopPagesData,
  deleteNaviGovernmentTopPagesData,
} from '../../../infrastructure/api/NaviGovernmentTopPages.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { SettingGovernmentTopPageCreate } from '../entities/Setting.js';
import { getGovernmentTopPage } from '../repositories/GovernmentTopPage.js';

export async function get(type) {
  return await getGovernmentTopPage(type);
}

export async function findById(type, id) {
  const GovernmentTopPage = await getGovernmentTopPage(type);
  return GovernmentTopPage.filter(
    (data) => data.governmentId.toString() === id.toString(),
  );
}

export async function getByGovernmentIdAndProcedureType(
  type,
  governmentId,
  procedureType,
) {
  const governmentTopPages = await getGovernmentTopPage(type);
  return governmentTopPages.filter(
    (data) =>
      data.governmentId.toString() === governmentId.toString() &&
      data.procedureType === procedureType,
  );
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (GovernmentTopPage)');
  }
  if (type === NAVI) {
    const governmentTopPage = new SettingGovernmentTopPageCreate(data);
    response = await createNaviGovernmentTopPagesData(governmentTopPage);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (GovernmentTopPage)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create GovernmentTopPage', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (GovernmentTopPage)');
  }
  if (type === NAVI) {
    const governmentTopPage = new SettingGovernmentTopPageCreate(data);
    response = await updateNaviGovernmentTopPagesData(governmentTopPage);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (GovernmentTopPage)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update GovernmentTopPage', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (GovernmentTopPage)');
  }
  if (type === NAVI) {
    response = await deleteNaviGovernmentTopPagesData(data.id);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (GovernmentTopPage)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete GovernmentTopPage', response);
  }
  return response;
}

// function createGovernmentTopPageArray(data) {
//   let settings = []
//   data.forEach((setting) => {
//     settings.push(new GovernmentTopPage(setting))
//   })
//   return settings
// }

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
