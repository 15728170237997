// ロジックを含む処理
// フロントから呼ぶ関数

import { compareData } from '../../../shared/utils/helper/compareData.js';
import { getPreregistrationApplicantInformations } from '../repositories/PreregistrationApplicantInformation.js';

export async function get(type) {
  return await getPreregistrationApplicantInformations(type);
}

export async function findById(type, id) {
  const Applicant = await getPreregistrationApplicantInformations(type);
  return Applicant.find((data) => data.userId === id);
}

export async function whereByUserId(type, userId) {
  const applicants = await getPreregistrationApplicantInformations(type);
  return applicants.filter((data) => data.auctionUserId === userId);
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
