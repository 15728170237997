import { useState } from 'react';
import * as Validation from 'shared/utils/helper/managerValidators';

export const GovernmentUserForm = () => {
  const [emailValid, setEmailValid] = useState(null);
  const [extensionValid, setExtensionValid] = useState(null);
  const [telValid, setTelValid] = useState(null);
  const [positionValid, setPositionValid] = useState(null);
  const [departmentRequiredValid, setDepartmentRequiredValid] = useState(null);
  const [firstNameKanaRequiredValid, setFirstNameKanaRequiredValid] =
    useState(null);
  const [familyNameKanaRequiredValid, setFamilyNameKanaRequiredValid] =
    useState(null);
  const [firstNameRequiredValid, setFirstNameRequiredValid] = useState(null);
  const [familyNameRequiredValid, setFamilyNameRequiredValid] = useState(null);
  function handleFamilyNameValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setFamilyNameRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateRangLastNameLength(e.target.value);
    if (invalid !== 'TRUE') {
      setFamilyNameRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateIncludeEmojiLastName(e.target.value);
    if (invalid !== 'TRUE') {
      setFamilyNameRequiredValid(invalid);
      return;
    }
    setFamilyNameRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleFamilyNameKanaValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setFamilyNameKanaRequiredValid(invalid);
      return;
    }

    invalid = Validation.validateRangLastNameKanaLength(e.target.value);
    if (invalid !== 'TRUE') {
      setFamilyNameKanaRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateLastNameKatakana(e.target.value);
    if (invalid !== 'TRUE') {
      setFamilyNameKanaRequiredValid(invalid);
      return;
    }
    setFamilyNameKanaRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleFirstNameValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setFirstNameRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateRangFirstNameLength(e.target.value);
    if (invalid !== 'TRUE') {
      setFirstNameRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateIncludeEmojiFirstName(e.target.value);
    if (invalid !== 'TRUE') {
      setFirstNameRequiredValid(invalid);
      return;
    }
    setFirstNameRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleFirstNameKanaValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setFirstNameKanaRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateRangFirstNameKanaLength(e.target.value);
    if (invalid !== 'TRUE') {
      setFirstNameKanaRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateFirstNameKatakana(e.target.value);
    if (invalid !== 'TRUE') {
      setFirstNameKanaRequiredValid(invalid);
      return;
    }
    setFirstNameKanaRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleDepartmentValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setDepartmentRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateRangeDeptNameLength(e.target.value);
    if (invalid !== 'TRUE') {
      setDepartmentRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateIncludeEmojiDeptName(e.target.value);
    if (invalid !== 'TRUE') {
      setDepartmentRequiredValid(invalid);
      return;
    }
    setDepartmentRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleOfficePositionValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateRangeJobTitleLength(e.target.value);
    if (invalid !== 'TRUE') {
      setPositionValid(invalid);
      return;
    }
    invalid = Validation.validateRoleCharacter(e.target.value);
    if (invalid !== 'TRUE') {
      setPositionValid(invalid);
      return;
    }
    setPositionValid(invalid); // エラーメッセージのリセット
  }

  function handleTelValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = null;
    let removedValue = Validation.validateExclusionTelSpace(e.target.value);
    invalid = Validation.validateRequired(removedValue);
    if (invalid !== 'TRUE') {
      setTelValid(invalid);
      return;
    }
    invalid = Validation.validateRangeTelNumberLength(removedValue);
    if (invalid !== 'TRUE') {
      setTelValid(invalid);
      return;
    }
    setTelValid(invalid); // エラーメッセージのリセット
  }

  function handleExtensionValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateRangeExtensionLength(e.target.value);
    if (invalid !== 'TRUE') {
      setExtensionValid(invalid);
      return;
    }
    invalid = Validation.validateExclusionExtensionSpace(e.target.value);
    if (invalid !== 'TRUE') {
      setExtensionValid(invalid);
      return;
    }
    invalid = Validation.validateInputExtLimitExtension(e.target.value);
    if (invalid !== 'TRUE') {
      setExtensionValid(invalid);
      return;
    }
    setExtensionValid(invalid); // エラーメッセージのリセット
  }

  function handleEmailValid(e) {
    //  logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setEmailValid(invalid);
      return;
    }
    invalid = Validation.validateIncludeAtMark(e.target.value);
    if (invalid !== 'TRUE') {
      setEmailValid(invalid);
      return;
    }
    invalid = Validation.validateRangeMailLength(e.target.value);
    if (invalid !== 'TRUE') {
      setEmailValid(invalid);
      return;
    }
    setEmailValid(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      familyName: handleFamilyNameValid,
      familyNameKana: handleFamilyNameKanaValid,
      firstName: handleFirstNameValid,
      firstNameKana: handleFirstNameKanaValid,
      department: handleDepartmentValid,
      officePosition: handleOfficePositionValid,
      tel: handleTelValid,
      extension: handleExtensionValid,
      email: handleEmailValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      familyName: familyNameRequiredValid,
      familyNameKana: familyNameKanaRequiredValid,
      firstName: firstNameRequiredValid,
      firstNameKana: firstNameKanaRequiredValid,
      department: departmentRequiredValid,
      officePosition: positionValid,
      tel: telValid,
      extension: extensionValid,
      email: emailValid,
    },
  };

  return validations;
};
