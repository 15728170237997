import { useEstateInputs } from './hook/useEstateInputs';
import { UseEstateInputsValidation } from './hook/useEstateInputsValidation.jsx';

export function EstateFormData({ isEdit = false }) {
  const formInputs = useEstateInputs();
  const validate = UseEstateInputsValidation({ isEdit: isEdit });

  // 公開設定
  function handleChangeIsDraft(e) {
    formInputs.setters.setIsDraft(e.target.value);
  }

  // 1. 画像の選択

  // 2. 物件情報
  function handleChangePublicSaleType(e) {
    formInputs.setters.setPublicSaleType(e.target.value);
  }

  function handleChangePrintOrder(e) {
    validate.setters.handlePrintOrderValid(e);
    formInputs.setters.setPrintOrder(e.target.value);
  } // 掲載順位

  function handleChangeCategoryNames(e) {
    validate.setters.handleCategoryNamesValid(e);
    formInputs.setters.setCategoryNames(e.target.value);
  } // カテゴリ

  function handleChangeCategoryId(id) {
    validate.setters.handleCategoryIdValid(id);
    formInputs.setters.setCategoryId(id);
  } // カテゴリ

  function handleChangeRecommendProperties(e) {
    //validate.setters.handleRecommendPropertiesValid(e);
    formInputs.setters.setRecommendProperties(e.target.value);
  } // イチ押し設定

  function handleChangeDivisionId(e) {
    validate.setters.handleDivisionIdValid(e);
    formInputs.setters.setDivisionId(e.target.value);
  } // 執行機関名称（id）

  function handleChangeSaleNumber(e) {
    validate.setters.handleSaleNumberValid(e);
    formInputs.setters.setSaleNumber(e.target.value);
  } // 売却区分番号

  function handleChangeName(e) {
    validate.setters.handleNameValid(e);
    formInputs.setters.setName(e.target.value);
  } // タイトル

  function handleChangePaymentMethod(e) {
    validate.setters.handlePaymentMethodValid(e);
    formInputs.setters.setPaymentMethod(e);
  } // 支払い方法

  function handleChangePaymentLimit(date) {
    validate.setters.handlePaymentLimitValid(date);
    formInputs.setters.setPaymentLimit(date);
  } // 納付期限

  function handleChangeVideoUrl(e) {
    validate.setters.handleVideoUrlValid(e);
    formInputs.setters.setVideoUrl(e.target.value);
  } // 動画URL

  function handleChangeType(e) {
    validate.setters.handleTypeValid(e);
    formInputs.setters.setType(e.target.value);
  } // 物件の種類

  function handleChangeGovernmentSubCategoryId(e) {
    validate.setters.handleGovernmentSubCategoryIdValid(e);
    formInputs.setters.setGovernmentSubCategoryId(e.target.value);
  } // 物件の所在(properties)

  function handleChangeResolutionDate(e) {
    validate.setters.handleResolutionDateValid(e);
    formInputs.setters.setResolutionDate(e.target.value);
  } // 議決日

  function handleChangeContractedLimit(e) {
    validate.setters.handleContractedLimitValid(e);
    formInputs.setters.setContractedLimit(e.target.value);
  } // 契約締結期限

  // 3. 基本情報
  function handleChangePropertyPrint(e) {
    validate.setters.handlePropertyPrintValid(e);
    formInputs.setters.setPropertyPrint(e.target.value);
  } // 登記簿の表示

  function handleChangeLocationGovernmentSubCategoryId(e) {
    validate.setters.handleLocationGovernmentSubCategoryIdValid(e);
    formInputs.setters.setLocationGovernmentSubCategoryId(e.target.value);
  } // 所在地

  function handleChangeLocationText(e) {
    validate.setters.handleLocationTextValid(e);
    formInputs.setters.setLocationText(e.target.value);
  } // 所在地(テキスト)

  function handleChangeResidenceGovernmentSubCategoryId(e) {
    validate.setters.handleResidenceGovernmentSubCategoryIdValid(e);
    formInputs.setters.setResidenceGovernmentSubCategoryId(e.target.value);
  } // 住居表示

  function handleChangeResidenceLocation(e) {
    validate.setters.handleResidenceLocationValid(e);
    formInputs.setters.setResidenceLocation(e.target.value);
  } // 住居表示(テキスト)

  function handleChangeNearestStation(e) {
    validate.setters.handleNearestStationValid(e);
    formInputs.setters.setNearestStation(e.target.value);
  } // 交通、最寄りの駅など

  function handleChangeLocationUrl(e) {
    validate.setters.handleLocationUrlValid(e);
    formInputs.setters.setLocationUrl(e.target.value);
  } // 所在図

  function handleChangeEstateStatus(e) {
    validate.setters.handleEstateStatusValid(e);
    formInputs.setters.setEstateStatus(e.target.value);
  } // 現況

  function handleChangeOther(e) {
    validate.setters.handleOtherValid(e);
    formInputs.setters.setOther(e.target.value);
  } // その他事項

  function handleChangeSketchUrl(e) {
    validate.setters.handleSketchUrlValid(e);
    formInputs.setters.setSketchUrl(e.target.value);
  } // 見取り図など

  function handleChangeDescription({ text }) {
    validate.setters.handleDescriptionValid(text);
    formInputs.setters.setDescription(text);
  } // 説明

  // 4. 土地の情報
  function handleChangeLandSpace(e) {
    validate.setters.handleLandSpaceValid(e);
    formInputs.setters.setLandSpace(e.target.value);
  } // 土地面積

  function handleChangeLandSpaceType(value) {
    validate.setters.handleLandSpaceTypeValid(value);
    formInputs.setters.setLandSpaceType(value);
  } // 土地面積種別

  function handleChangeLandRights(e) {
    validate.setters.handleLandRightsValid(e);
    formInputs.setters.setLandRights(e.target.value);
  } // 土地権利

  function handleChangeLandShare(hashLandShare) {
    console.log(hashLandShare);
    validate.setters.handleLandShareValid(hashLandShare);
    formInputs.setters.setLandShare(hashLandShare);
  } // 土地持分

  function handleChangeLandCategory(e) {
    validate.setters.handleLandCategoryValid(e);
    formInputs.setters.setLandCategory(e.target.value);
  } // 地目

  function handleChangeCityPlanningArea(e) {
    validate.setters.handleCityPlanningAreaValid(e);
    formInputs.setters.setCityPlanningArea(e.target.value);
  } // 都市計画地域

  function handleChangeRestrictedArea(e) {
    validate.setters.handleRestrictedAreaValid(e);
    formInputs.setters.setRestrictedArea(e.target.value);
  } // 用途地域

  function handleChangeBuildRatio(e) {
    validate.setters.handleBuildRatioValid(e);
    formInputs.setters.setBuildRatio(e.target.value);
  } // 建ぺい率

  function handleChangeFloorAreaRatio(e) {
    validate.setters.handleFloorAreaRatioValid(e);
    formInputs.setters.setFloorAreaRatio(e.target.value);
  } // 容積率

  function handleChangeHighDistrict(e) {
    validate.setters.handleHighDistrictValid(e);
    formInputs.setters.setHighDistrict(e.target.value);
  } // 高度地区

  function handleChangeFirebreakArea(e) {
    validate.setters.handleFirebreakAreaValid(e);
    formInputs.setters.setFirebreakArea(e.target.value);
  } // 防火地域

  function handleChangeShadeRegulation(e) {
    validate.setters.handleShadeRegulationValid(e);
    formInputs.setters.setShadeRegulation(e.target.value);
  } // 日影規制

  function handleChangeLawRegulation(e) {
    validate.setters.handleLawRegulationValid(e);
    formInputs.setters.setLawRegulation(e.target.value);
  } // その他法令などの規制

  function handleChangeGeographical(e) {
    validate.setters.handleGeographicalValid(e);
    formInputs.setters.setGeographical(e.target.value);
  } // 地勢など

  function handleChangeNeighboringRoad(e) {
    validate.setters.handleNeighboringRoadValid(e);
    formInputs.setters.setNeighboringRoad(e.target.value);
  } // 幅員、接道状況

  function handleChangePrivateRoad(e) {
    validate.setters.handlePrivateRoadValid(e);
    formInputs.setters.setPrivateRoad(e.target.value);
  } // 私道の負担など

  function handleChangeProvideEquipment(e) {
    validate.setters.handleProvideEquipmentValid(e);
    formInputs.setters.setProvideEquipment(e.target.value);
  } // 供給処理施設

  function handleChangeLandOther(e) {
    validate.setters.handleLandOtherValid(e);
    formInputs.setters.setLandOther(e.target.value);
  } // その他土地に関する物件情報

  // 5. 建物の情報
  function handleChangeBuildName(e) {
    validate.setters.handleBuildNameValid(e);
    formInputs.setters.setBuildName(e.target.value);
  } // 建物の名称

  function handleChangeBuildSpace(e) {
    validate.setters.handleBuildSpaceValid(e);
    formInputs.setters.setBuildSpace(e.target.value);
  } // 建物面積

  function handleChangeExclusiveSpace(e) {
    validate.setters.handleExclusiveSpaceValid(e);
    formInputs.setters.setExclusiveSpace(e.target.value);
  } // 専有面積

  function handleChangeFloorSpace(e) {
    validate.setters.handleFloorSpaceValid(e);
    formInputs.setters.setFloorSpace(e.target.value);
  } // 延床面積

  function handleChangeStructureType(e) {
    validate.setters.handleStructureTypeValid(e);
    formInputs.setters.setStructureType(e.target.value);
  } // 構造

  function handleChangeBuildDate(e, hashDate) {
    const val = {
      year: e.year,
      month: e.month,
      day: e.day,
    };
    validate.setters.handleBuildDateValid(e, { ...hashDate, ...val });
    formInputs.setters.setBuildDate({ ...hashDate, ...val });
  } // 建築年月

  function handleChangeBuildDateOther(e) {
    validate.setters.handleBuildDateOtherValid(e);
    formInputs.setters.setBuildDateOther(e.target.value);
  } // 建築年月（その他を選択した時出るフォーム）

  function handleChangeFloor(e) {
    validate.setters.handleFloorValid(e);
    formInputs.setters.setFloor(e.target.value);
  } // 階建/階

  function handleChangeTotalHouses(e) {
    validate.setters.handleTotalHousesValid(e);
    formInputs.setters.setTotalHouses(e.target.value);
  } // 総戸数

  function handleChangeLayout(e) {
    validate.setters.handleLayoutValid(e);
    formInputs.setters.setLayout(e.target.value);
  } // 間取り

  function handleChangeBuildOther(e) {
    validate.setters.handleBuildOtherValid(e);
    formInputs.setters.setBuildOther(e.target.value);
  } // その他建物に関する物件情報

  // 6. 物件に関する音尾合わせ先
  function handleChangeInquiryName(e) {
    validate.setters.handleInquiryNameValid(e);
    formInputs.setters.setInquiryName(e.target.value);
  }

  function handleChangeInquiryOffice(e) {
    validate.setters.handleInquiryOfficeValid(e);
    formInputs.setters.setInquiryOffice(e.target.value);
  }

  function handleChangeInquiryEmail(e) {
    validate.setters.handleInquiryEmailValid(e);
    formInputs.setters.setInquiryEmail(e.target.value);
  }

  function handleChangeInquiryTel(telData) {
    validate.setters.handleInquiryTelValid(telData);
    formInputs.setters.setInquiryTel(telData);
  }

  function handleChangeInquiryTelNum1(e) {
    validate.setters.handleInquiryTelNum1Valid(e);
    formInputs.setters.setInquiryTelNum1(e);
  }
  function handleChangeInquiryTelNum2(e) {
    validate.setters.handleInquiryTelNum2Valid(e);
    formInputs.setters.setInquiryTelNum2(e);
  }
  function handleChangeInquiryTelNum3(e) {
    validate.setters.handleInquiryTelNum3Valid(e);
    formInputs.setters.setInquiryTelNum3(e);
  }

  function handleChangeInquiryExtension(e) {
    validate.setters.handleInquiryExtensionValid(e);
    formInputs.setters.setInquiryExtension(e.target.value);
  }

  function handleChangeInquiryAccept(e) {
    validate.setters.handleInquiryAcceptValid(e);
    formInputs.setters.setInquiryAccept(e.target.value);
  }

  function handleChangeInquiryNote(e) {
    validate.setters.handleInquiryNoteValid(e);
    formInputs.setters.setInquiryNote(e.target.value);
  }

  // 6. 物件の詳細設定
  function handleChangeDepositPayment(e) {
    formInputs.setters.setDepositPayment(e.target.value);
  }

  function handleChangeIsApprovalRequired(e) {
    formInputs.setters.setIsApprovalRequired(e.target.value);
  }

  function handleChangeEstimatePrice(e) {
    validate.setters.handleEstimatePriceValid(e);
    formInputs.setters.setEstimatePrice(e.target.value);
  }

  function handleChangeDeposit(value, payment) {
    validate.setters.handleDepositValid(value, payment);
    formInputs.setters.setDeposit(value);
  }

  function handleChangeQualifiedInvoice(e) {
    formInputs.setters.setQualifiedInvoice(e.target.value);
  }

  // 7. 落札後の連絡先
  function handleChangeContactName(e) {
    validate.setters.handleContactNameValid(e);
    formInputs.setters.setContactName(e.target.value);
  }

  function handleChangeContactOffice(e) {
    validate.setters.handleContactOfficeValid(e);
    formInputs.setters.setContactOffice(e.target.value);
  }

  function handleChangeContactEmail(e) {
    validate.setters.handleContactEmailValid(e);
    formInputs.setters.setContactEmail(e.target.value);
  }

  function handleChangeContactTel(telData) {
    validate.setters.handleContactTelValid(telData);
    formInputs.setters.setContactTel(telData);
  }

  function handleChangeContactExtension(e) {
    validate.setters.handleContactExtensionValid(e);
    formInputs.setters.setContactExtension(e.target.value);
  }

  function handleChangeContactAccept(e) {
    validate.setters.handleContactAcceptValid(e);
    formInputs.setters.setContactAccept(e.target.value);
  }

  //function handleChangeAuctionUserId(e) {
  //    validate.setters.handleAuctionUserIdValid(e)
  //    formInputs.setters.setAuctionUserId(e.target.value)
  //}

  //function handleChangePaymentDate(e) {
  //    formInputs.setters.setPaymentDate(e.target.value)
  //}

  //function handleChangePaymentDeadline(e) {
  //    validate.setters.handlePaymentDeadlineValid(e)
  //    formInputs.setters.setPaymentDeadline(e.target.value)
  //}

  const validateInputs = {
    data: formInputs.data,
    init: formInputs.setters,
    setters: {
      handleChangeIsDraft,

      // 設定

      // 2. 物件情報
      handleChangePublicSaleType,
      handleChangePrintOrder, // 掲載順位
      handleChangeCategoryNames, // カテゴリ
      handleChangeCategoryId, // カテゴリ
      handleChangeRecommendProperties, // イチ押し設定
      handleChangeDivisionId, // 執行機関名称（id）
      handleChangeSaleNumber, // 売却区分番号
      handleChangeName, // タイトル
      handleChangePaymentMethod, // 支払い方法
      handleChangePaymentLimit, // 納付期限
      handleChangeVideoUrl, // 動画URL
      handleChangeType, // 物件の種類
      handleChangeGovernmentSubCategoryId, // 物件の所在(properties)
      handleChangeResolutionDate,
      handleChangeContractedLimit,

      // 3. 基本情報
      handleChangePropertyPrint, // 登記簿の表示
      handleChangeLocationGovernmentSubCategoryId, // 所在地
      handleChangeLocationText, // 所在地(テキスト)
      handleChangeResidenceGovernmentSubCategoryId, // 住居表示
      handleChangeResidenceLocation, // 住居表示(テキスト)
      handleChangeNearestStation, // 交通、最寄りの駅など
      handleChangeLocationUrl, // 所在図
      handleChangeEstateStatus, // 現況
      handleChangeOther, // その他事項
      handleChangeSketchUrl, // 見取り図など
      handleChangeDescription, // 説明

      // 4. 土地の情報
      handleChangeLandSpace, // 土地面積
      handleChangeLandSpaceType, // 土地面積種別
      handleChangeLandRights, // 土地権利
      handleChangeLandShare, // 土地持分
      handleChangeLandCategory, // 地目
      handleChangeCityPlanningArea, // 都市計画地域
      handleChangeRestrictedArea, // 用途地域
      handleChangeBuildRatio, // 建ぺい率
      handleChangeFloorAreaRatio, // 容積率
      handleChangeHighDistrict, // 高度地区
      handleChangeFirebreakArea, // 防火地域
      handleChangeShadeRegulation, // 日影規制
      handleChangeLawRegulation, // その他法令などの規制
      handleChangeGeographical, // 地勢など
      handleChangeNeighboringRoad, // 幅員、接道状況
      handleChangePrivateRoad, // 私道の負担など
      handleChangeProvideEquipment, // 供給処理施設
      handleChangeLandOther, // その他土地に関する物件情報

      // 5. 建物の情報
      handleChangeBuildName, // 建物の名称
      handleChangeBuildSpace, // 建物面積
      handleChangeExclusiveSpace, // 専有面積
      handleChangeFloorSpace, // 延床面積
      handleChangeStructureType, // 構造
      handleChangeBuildDate, // 建築年月
      handleChangeBuildDateOther, // 建築年月（その他を選択した時出るフォーム）
      handleChangeFloor, // 階建/階
      handleChangeTotalHouses, // 総戸数
      handleChangeLayout, // 間取り
      handleChangeBuildOther, // その他建物に関する物件情報

      // 6. 物件に関するお問い合わせ先
      handleChangeInquiryName,
      handleChangeInquiryOffice,
      handleChangeInquiryEmail,
      handleChangeInquiryTel,
      handleChangeInquiryTelNum1,
      handleChangeInquiryTelNum2,
      handleChangeInquiryTelNum3,
      handleChangeInquiryExtension,
      handleChangeInquiryAccept,
      handleChangeInquiryNote,

      // 7. 物件の詳細設定
      handleChangeDepositPayment,
      handleChangeIsApprovalRequired,
      handleChangeEstimatePrice,
      handleChangeDeposit,
      handleChangeQualifiedInvoice,

      // 8. 落札後の連絡先
      handleChangeContactName,
      handleChangeContactOffice,
      handleChangeContactEmail,
      handleChangeContactTel,
      handleChangeContactExtension,
      handleChangeContactAccept,

      //handleChangeAuctionUserId,
      //handleChangePaymentDeadline,
      //handleChangePaymentDate,
      //handleChangePaymentScheduledDate,
    },
    validationMessage: validate.validationMessage,
  };

  return validateInputs;
}
