const StepTitle = ({ data }) => {
  return (
    <div data-testid="step-title">
      <div className="page-title mb-4 no-bg property-card card-flat property-sheet theme-light rounded-0">
        <h1 className="pb-2 text-no-wrap">
          <span className="lead-number">{data.num}</span>
          <span className="lead-title">{data.title}</span>
        </h1>
        <hr role="separator" aria-orientation="horizontal" className="divider theme-light"/>
      </div>
    </div>
  );
};

export default StepTitle;
