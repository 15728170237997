import BillingProvider from './BillingProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { billing } from "../entities/componentMapping.js";

const Billing = ({ type }) => {
  return (
    <BillingProvider type={type}>
      {getComponent(billing, type)}
    </BillingProvider>
  );
};

export default Billing;
