import './css/step-controls.css';
import { Link } from 'react-router-dom';

//const StepControls = ({ data, propertyId }) => {
const StepControls = ({ data }) => {
  return (
    <div data-testid="step-controls" className="step-controls mb-10">
      <div className="flex justify-center">
        <Link
          //to={`/items/${propertyId}/application-info-input`}
          to={`/reception-completed-bids`}
          type="button"
          className={`next-step-button box ma-2 btn-shadow-deep btn btn-contained theme-light size-x-large ${data && data.next_step_button.color} `}
          data-testid="next-step-button">
          <span className="btn-content">
            <div className="btn-inner">
              {data && data.next_step_button.text}
            </div>
          </span>
        </Link>
        <button
          data-testid="cancel-or-back-button"
          type="button"
          className={`cancel-or-back-button box ma-2 btn-shadow btn btn-contained theme-light size-x-large ${data && data.cancel_or_back_button.color}`}>
          <span className="btn_content">
            <div className="btn-inner">
              {data && data.cancel_or_back_button.text}
            </div>
          </span>
        </button>
      </div>
    </div>
  );
};

export default StepControls;
