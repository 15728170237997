import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle.jsx';

import Table from './Table.jsx';

const List = ({ title, data }) => {
  const isPublicSale = title === 'インターネット公売';
  return (
    <div data-testid="government-list">
      <ArticleDefaultTitle title={title} />
      <div className="article-default-body py-8 px-6 mb-8 rounded">
        {data && (
          <>
            {data['NATIONAL_TAX_AGENCY'] && (
              <Table
                label="国税庁"
                data={data['NATIONAL_TAX_AGENCY']}
                isPublicSale={isPublicSale}
                isKokuzei={true}
              />
            )}
            {data['PREFECTURE'] && (
              <Table
                label="都道府県"
                data={data['PREFECTURE']}
                isPublicSale={isPublicSale}
              />
            )}
            {data['MANICIPALITY'] && (
              <Table
                label="市区町村"
                data={data['MANICIPALITY']}
                isPublicSale={isPublicSale}
              />
            )}
            {data['OTHER'] && (
              <Table
                label="その他"
                data={data['OTHER']}
                isPublicSale={isPublicSale}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default List;
