
export class SummaryBuildSpace {
    #summaryBuildSpace

    constructor(summaryBuildSpace) {
        this.#summaryBuildSpace = summaryBuildSpace;
    }

    get() {
        return this.#summaryBuildSpace
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
