import { DATA_EMPTY } from 'domain/Property/entities/constants/property';

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_GOVERNMENT_SUB_CATEGORY_ID } from 'shared/utils/constants/queryParam';

const TreeViewNodeChildren = ({
  data,
  checkBoxRef,
  queryParam,
  setQueryParam,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const Ids =
      searchParams.get(SEARCH_GOVERNMENT_SUB_CATEGORY_ID) ?? DATA_EMPTY;
    if (Ids !== DATA_EMPTY && Ids !== '') {
      const IdsArray = Ids.split(',');
      if (IdsArray.includes(data.id.toString())) {
        setIsChecked(true);
      }
    } else {
      setIsChecked(false);
    }
  }, [searchParams]);

  const applyQueryParam = () => {
    const applied = [...queryParam];
    applied.push(data.id);
    setQueryParam((prevState) => {
      const newArray = [...prevState, ...applied];
      return Array.from(new Set(newArray));
    });
  };
  const removeQueryParam = () => {
    setQueryParam((prevState) => {
      return [...prevState].filter((id) => id !== data.id);
    });
  };
  const toggleCheckBox = () => {
    setIsChecked(!isChecked);
    isChecked ? removeQueryParam() : applyQueryParam();
  };

  return (
    <div
      className="treeview-node-children"
      data-testid="treeview-node-children">
      <div
        aria-expanded="false"
        className="treeview-node treeview-node--selected">
        <div className="treeview-node-root d-flex align-center">
          <div className="treeview-node-level"></div>
          <div className="treeview-node-level"></div>
          <button
            type="button"
            onClick={toggleCheckBox}
            ref={checkBoxRef}
            className={`d-inline-flex align-center justify-center icon theme--light ${isChecked ? 'checkbox-icon' : 'checkbox-outline-blank-icon'}`}
            data-testid="treeview-node-children-checkbox"></button>
          <div className="treeview-node-content">
            <div
              className="treeview-node-label"
              data-testid="treeview-node-children-label">
              {data.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreeViewNodeChildren;
