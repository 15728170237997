
export class DeletedAt {
  #deletedAt

  constructor(deletedAt) {
    this.#deletedAt = deletedAt;
  }

  get() {
    return this.#deletedAt
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
