// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.automatic-email-management .automatic-email-table-wrap {
    background-color: #fff;
    color: rgba(0,0,0,.87);
    line-height: 1.5;
    max-width: 100%;
}

.automatic-email-management .automatic-email-table-inner {
    overflow-x: auto;
    overflow-y: hidden;
}

.automatic-email-management .automatic-email-table {
    width: 100%;
    border-spacing: 0;
    border: none;
}

.automatic-email-management .automatic-email-table-header {
    background-color: #fbe2ca;
}

.automatic-email-management .automatic-email-table-headline {
    border: none;
    border-bottom: thin solid rgba(0,0,0,.12);
    color: rgba(0,0,0,.6);
    -webkit-user-select: none;
            user-select: none;
    font-size: .75rem;
    height: 48px;
    transition: height .2s cubic-bezier(.4,0,.6,1);
}

.automatic-email-management .automatic-email-table-data {
    font-size: .875rem;
    height: 48px;
    transition: height .2s cubic-bezier(.4,0,.6,1);
    border: none;
}

.automatic-email-management .automatic-email-table-row:not(:last-child) > .automatic-email-table-data {
    border-bottom: thin solid rgba(0,0,0,.12);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/AutomaticEmailManagement/automatic-email-table.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,yCAAyC;IACzC,qBAAqB;IACrB,yBAAiB;YAAjB,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;IACZ,8CAA8C;AAClD;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,8CAA8C;IAC9C,YAAY;AAChB;;AAEA;IACI,yCAAyC;AAC7C","sourcesContent":[".automatic-email-management .automatic-email-table-wrap {\n    background-color: #fff;\n    color: rgba(0,0,0,.87);\n    line-height: 1.5;\n    max-width: 100%;\n}\n\n.automatic-email-management .automatic-email-table-inner {\n    overflow-x: auto;\n    overflow-y: hidden;\n}\n\n.automatic-email-management .automatic-email-table {\n    width: 100%;\n    border-spacing: 0;\n    border: none;\n}\n\n.automatic-email-management .automatic-email-table-header {\n    background-color: #fbe2ca;\n}\n\n.automatic-email-management .automatic-email-table-headline {\n    border: none;\n    border-bottom: thin solid rgba(0,0,0,.12);\n    color: rgba(0,0,0,.6);\n    user-select: none;\n    font-size: .75rem;\n    height: 48px;\n    transition: height .2s cubic-bezier(.4,0,.6,1);\n}\n\n.automatic-email-management .automatic-email-table-data {\n    font-size: .875rem;\n    height: 48px;\n    transition: height .2s cubic-bezier(.4,0,.6,1);\n    border: none;\n}\n\n.automatic-email-management .automatic-email-table-row:not(:last-child) > .automatic-email-table-data {\n    border-bottom: thin solid rgba(0,0,0,.12);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
