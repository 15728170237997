import Heading3 from 'interfaces/components/common/Heading/Heading3.jsx';

import ConfirmInputText from './ConfirmInputText.jsx';

const renderHeading = (heading, index) => {
  if (heading.type === 'h3') {
    return <Heading3 key={index} text={heading.title} />;
  }

  if (heading.type === 'h4') {
    return (
      <h4 key={index} data-testid="procedure-new-entry-form-headline">
        {heading.title}
      </h4>
    );
  }

  return null;
};

const ConfirmSection = ({ headings, inputsData, description }) => {
  return (
    <div className="pt-6" data-testid="confirm-section">
      {headings &&
        headings.map((heading, index) => renderHeading(heading, index))}
      {description && (
        <p className="mt-4 text-sm text-gray-500">
          {description.split('\n').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </p>
      )}
      <div className="property-info-general-form-table">
        {inputsData.map((data, index) => (
          <ConfirmInputText
            key={index}
            label={data.text}
            state={data.state?.toString()}
          />
        ))}
      </div>
    </div>
  );
};
export default ConfirmSection;
