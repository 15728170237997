import {Link, useParams} from "react-router-dom";
import Breadcrumb from "interfaces/components/common/Breadcrumb";
import Heading2 from "interfaces/components/common/Heading/Heading2";
import { breadcrumbs } from 'interfaces/pages/PropertyInfoCar/entities/breadcrumbs'
import "interfaces/css/PropertyInfoCar/property-info-car.css";
import "interfaces/css/common/property-info-general-form.css"
import {PropertyInfoCarContext} from "interfaces/pages/PropertyInfoCar/entities/PropertyInfoCarContext";
import {useContext} from "react";

const PropertyInfoCarComplete = ({method, openWay}) => {
    const { auction_id } = useParams();
    const { invitation, property } = useContext(PropertyInfoCarContext);

    const getTitle = () => {
        if (method === 'create') {
            return `登録完了：物件情報（車）登録：${invitation?.name}`;
        }
        return `編集完了：${invitation?.name}：${property?.name}`
    }

    return (
        <div className="property-info-car">
            <Breadcrumb breadcrumbs={breadcrumbs(method, 'complete', invitation?.name, auction_id, openWay, property?.name)}/>
            <Heading2 title={getTitle()}/>
            <div className="complete-message">
                {
                    method === 'create' ?
                        <div>物件作成完了しました。</div> :
                        <div>{`${property?.name} を更新しました。`}</div>
                }
            </div>
            <div className="description">
                <Link to={`/navi/object/${auction_id}`}>{invitation.name}へ</Link>
            </div>
            <div className="description">
                <Link to={'/navi'}>トップページへ</Link>
            </div>
        </div>
    );
}

export default PropertyInfoCarComplete;
