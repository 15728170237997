import { useContext } from 'react';

import { DashboardContext } from 'interfaces/pages/Dashboard/entities/DashboardContext';
import RightSideMenu from './LoadMap/RightSideMenu.jsx';
import TopLeftSideMenu from './LoadMap/TopLeftSideMenu.jsx';
import TopPageBody from './TopPageBody.jsx';
import 'interfaces/css/common/common.css';

const Dashboard = () => {
  // 値はproviderから取得する
  const { properties, promotionProperties } = useContext(DashboardContext);

  return (
    <div
      className="inner flex justify-space-between kservice_dashboard"
      data-testid="dashboard">
      <TopLeftSideMenu />
      <TopPageBody
        properties={properties}
        promotionProperties={promotionProperties}
      />
      <RightSideMenu />
    </div>
  );
};

export default Dashboard;
