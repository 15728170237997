import { validateRangeLength, validateAlphanum } from "../../../../application/validations.js";

export class FormalNumber {
  #formalNumber

  constructor(formalNumber) {
    this.#formalNumber = formalNumber;
  }

  get() {
    return this.#formalNumber
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.validateAlphanum,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#formalNumber, 0, 30)
  }

  // バリデーションルールの適用
  get validateAlphanum() {
    return validateAlphanum(this.#formalNumber)
  }
}
