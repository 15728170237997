
export class InquiryDisplay {
  #inquiryDisplay

  constructor(inquiryDisplay) {
    this.#inquiryDisplay = inquiryDisplay;
  }

  get() {
    return this.#inquiryDisplay
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
