import { Link } from 'react-router-dom';

const HorizontalLink = ({ link, label }) => {
  return (
    <div className="vertical-link-group-item pa-0">
      <div className="font-weight-bold">
        <Link to={link}>{label}</Link>
      </div>
    </div>
  );
};

export default HorizontalLink;
