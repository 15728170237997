import RegistrationInfoPageFooter from 'interfaces/components/common/RegistrationInfo/RegistrationInfoPageFooter';
import RegistrationInfoPageHeading from 'interfaces/components/common/RegistrationInfo/RegistrationInfoPageHeading';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import useStep from 'shared/hooks/useStep';

import ApplicationStepComponent from './ApplicationStepComponent';

//import ApplicationProvider from '../features/application/ApplicationProvider';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';

const Application = () => {
  const { id } = useParams();
  const location = useLocation();
  const locationState = location?.state;
  const { step, previousStep, nextStep } = useStep(locationState?.step ?? 1);
  const { localStorageUserId } = useLocalStorage();

  const isLoggedIn = () => {
    return localStorageUserId !== null;
  };

  if (!isLoggedIn || !locationState) {
    return <Navigate replace to={`/items/${id}`} />;
  }

  return (
    <div className="inner">
      <RegistrationInfoPageHeading locationState={locationState} step={step} />
      <ApplicationStepComponent
        locationState={locationState}
        step={step}
        previousStep={previousStep}
        nextStep={nextStep}
      />
      <RegistrationInfoPageFooter data={locationState.data} />
    </div>
  );
};

export default Application;
