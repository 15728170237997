import { useEffect, useState } from 'react';

import { get as getPromotionFrames } from 'domain/Promotion/services/PromotionFrame.js';
import { get as getPromotionProperties } from 'domain/Promotion/services/PromotionProperty.js';
import { getAuctionProgressSchedule as getAuctions } from 'domain/Auction/services/Auction.js';
import { findByAuctionIds as getProperties } from 'domain/Property/services/Property.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { PromotionContext } from '../entities/PromotionContext.js';

import searchProperty from 'shared/utils/helper/searchProperty';

const PromotionProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [promotionFrames, setPromotionFrames] = useState([]);
  const [promotionProperties, setPromotionProperties] = useState([]);
  const [previewProperties, setPreviewProperties] = useState([]);
  // アコーディオンの開閉管理
  const [promotionAccordionControls, setPromotionAccordionControls] = useState(
    [],
  );

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getPromotionFrames(type).then((result) => {
        setPromotionFrames(result);
      });
    }, []);

    useEffect(() => {
      getPromotionProperties(type).then((result) => {
        setPromotionProperties(result);
      });
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {}, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getAuctions(type).then((auctions) => {
        let auctionIds = auctions.map((auction) => auction.id);
        getProperties(type, auctionIds).then((properties) => {
          getPromotionFrames(type).then((frames) => {
            getPromotionProperties(type).then((promotionProperties) => {
              let retFrames = [];
              let retPreviews = [];

              frames.forEach((frame) => {
                let tmpFrame = frame;
                tmpFrame.promotionProperties = promotionProperties.filter(
                  (promotionProperty) =>
                    promotionProperty.promotionFrameId === frame.id,
                );

                // 物件情報取得
                let tmp = [];
                tmpFrame.promotionProperties.forEach((promotionProperty) => {
                  if (frame.sourceType === 'SEARCH_URL') {
                    const queryString = promotionProperty.url.substr(
                      promotionProperty.url.indexOf('?'),
                    );
                    const query = new URLSearchParams(queryString);
                    tmp = searchProperty(query, properties);
                  } else {
                    console.log('INPUT_URL');
                  }
                });

                retFrames.push(tmpFrame);
                retPreviews.push({
                  promotionFrameId: frame.id,
                  previewProperties: tmp,
                  theme: frame.theme,
                });
              });

              setPromotionFrames(retFrames);
              setPreviewProperties(retPreviews);
            });
          });
        });
      });
    }, []);
  }

  return (
    <PromotionContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        previewProperties,
        procedureType,
        promotionFrames,
        promotionProperties,
        promotionAccordionControls,
        setPromotionAccordionControls,
      }}>
      {children}
    </PromotionContext.Provider>
  );
};

export default PromotionProvider;
