import 'interfaces/css/common/caution-manual-marked-list.css';
import SmallTopicTitle from 'interfaces/components/common/Heading/SmallTopicTitle.jsx';
import CautionTopicTable from 'interfaces/components/SampleAfterWinningBidCaution/CautionTopicTable.jsx';
import ManualMarkedList from '../../ManualMarkedList.jsx';
import CautionManualMarkedList from '../../CautionManualMarkedList.jsx';

const NecessaryExpensesData= [
  {
    headline: '動産',
    content: <ManualMarkedList data={[
      {
        mark: '・',
        text: '落札価額－公売保証金額',
      },
    ]}/>,
  },
  {
    headline: '自動車',
    content: <ManualMarkedList data={[
      {
        mark: '・',
        text: '落札価額－公売保証金額',
      },
      {
        mark: '・',
        text: '自動車検査登録印紙相当額',
      },
    ]}/>,
  },
  {
    headline: '不動産',
    content: <ManualMarkedList data={[
      {
        mark: '・',
        text: '落札価額－公売保証金額',
      },
      {
        mark: '・',
        text: '登録免許税相当額',
      },
    ]}/>,
  },
]

const NecessaryExpensesAuction = () => {
  return (
    <div data-testid="necessary-expenses" className="pt-9">
      <SmallTopicTitle text='必要な費用' isFontBold={true}/>
      <CautionTopicTable recordData={NecessaryExpensesData} className='mt-3'/>
      <CautionManualMarkedList title="ご注意" className="pt-3" data={[
        {
          mark: '・',
          text: '必要な費用は、一括で納付してください。また、買受代金納付期限までに、執行機関が納付を確認できる必要があります。',
        },
        {
          mark: '・',
          text: '上記以外に必要書類の郵送料、物件の配送料、振込み手数料、その他所有権移転などに伴う費用は落札者の負担となります。',
        },
      ]} />
    </div>
  );
};
export default NecessaryExpensesAuction;