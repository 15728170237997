// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.propertiesManagement .properties-management .header-buttons {
    margin-top: 40px;
    display: inline-block;
}

.propertiesManagement .properties-management .header-buttons > * + * {
    margin-left: 16px;
}

.propertiesManagement .properties-management .heading4-and-description-wrap {
    margin-top: 64px;
}

.propertiesManagement .properties-management .heading4-and-description-wrap .description {
    font-size: 14px;
}

.propertiesManagement .properties-management .links-wrap {
    margin-top: 8px;
    padding-left: 24px;
}

.propertiesManagement .properties-management .links-wrap > li {
    list-style-type: disc;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertiesManagement/properties-management.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".propertiesManagement .properties-management .header-buttons {\n    margin-top: 40px;\n    display: inline-block;\n}\n\n.propertiesManagement .properties-management .header-buttons > * + * {\n    margin-left: 16px;\n}\n\n.propertiesManagement .properties-management .heading4-and-description-wrap {\n    margin-top: 64px;\n}\n\n.propertiesManagement .properties-management .heading4-and-description-wrap .description {\n    font-size: 14px;\n}\n\n.propertiesManagement .properties-management .links-wrap {\n    margin-top: 8px;\n    padding-left: 24px;\n}\n\n.propertiesManagement .properties-management .links-wrap > li {\n    list-style-type: disc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
