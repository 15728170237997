import Button from 'interfaces/components/common/Button/Button.jsx';
import copy from 'copy-to-clipboard';
import { useState } from 'react';

const GuidelineModalFooter = ({ closeModal, value }) => {
  const [buttonText, setButtonText] = useState('コピーする');
  const [isCopying, setIsCopying] = useState(false);

  const handleCopy = () => {
    copy(value);
    setButtonText('コピーしました。');
    setIsCopying(true);
    setTimeout(() => {
      setButtonText('コピーする');
      setIsCopying(false);
    }, 1000);
  };

  return (
    <div data-testid="guideline-modal-footer" className="px-2 py-2">
      <div className="d-flex justify-end">
        {isCopying ? (
          <span
            style={{
              lineHeight: '44px',
              padding: '0 16px',
            }}>
            {buttonText}
          </span>
        ) : (
          <Button text={buttonText} height="44px" onClick={handleCopy} />
        )}
        <Button
          text="閉じる"
          height="44px"
          className="common-button-bg-whitesmoke ml-2"
          onClick={closeModal}
        />
      </div>
    </div>
  );
};
export default GuidelineModalFooter;
