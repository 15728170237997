import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import PropertyInfoFormCautionDescription from "interfaces/components/common/PropertyInfoForm/PropertyInfoFormCautionDescription";
import { DateTime } from "luxon";
import {useState} from "react";

import {FormDateTimeProps} from "./FormDateTimeProps";
import Label from "./Label";


const FormDateTimeWithCaution = ({
                               caution_description,
                               date,
                               handleChange,
                               label,
                               required,
}) => {
  const changeHandler = (e) => {
    handleChange(e);
  }

  const [open, setOpen] = useState(false);

  return (
    <div className="property-info-general-form-table-row">
      <Label label={label} required={required}/>
      <div className="property-info-general-form-table-cell">
        <LocalizationProvider
            //adapterLocale={jaJP}
            dateAdapter={AdapterLuxon}
        >
          <DateTimePicker
            ampm={false}
            className={'property-input-text-size-100'}
            onChange={selectDate => changeHandler(selectDate.toJSDate())}
            onClose={() => setOpen(false)}
            open={open}
            slotProps={FormDateTimeProps(date, setOpen)}
            value={DateTime.fromJSDate(new Date(date))}
            views={['year', 'month', 'day', 'hours', 'minutes']}
          />
        </LocalizationProvider>
        <PropertyInfoFormCautionDescription data={caution_description}/>
      </div>
    </div>
  );
}
export default FormDateTimeWithCaution