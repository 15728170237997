import {getPaymentMethods} from "../repositories/PaymentMethods";

export async function get(type) {
  return await getPaymentMethods(type)
}

export async function findByPropertyId(type, id) {
  const paymentMethods = await getPaymentMethods(type);
  return paymentMethods.filter(data => data.propertyId.toString() === id.toString())
}
