import ForgetPasswordForm from './ForgetPasswordForm.jsx';

const ForgetPasswordModalContents = () => {
  return (
    <div data-testid="forget-password-modal-contents">
      <p
        className="forget-password-contents-title"
        data-testid="forget-password-contents-title">
        パスワード再設定
      </p>
      <ForgetPasswordForm />
    </div>
  );
};

export default ForgetPasswordModalContents;
