import { ReactComponent as ExpandMoreIcon } from 'interfaces/assets/svg/expand_more_icon.svg';
import { ReactComponent as ExpandLessIcon } from 'interfaces/assets/svg/expand_less_icon.svg';
import 'interfaces/css/common/government-filter-button.css';
import { useState } from 'react';
import { TextField, Collapse } from '@mui/material';

const GovernmentFilterButton = ({ text, searchInput, setSearchInput }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="government-filter-button">
        <div
          style={{ padding: '15px' }}
          className="d-flex align-center justify-space-between"
          onClick={() => setOpen(!open)}>
          {text}
          {open ? (
            <ExpandLessIcon title="expand-less-icon" />
          ) : (
            <ExpandMoreIcon title="expand-more-icon" />
          )}
        </div>
        <Collapse in={open}>
          <div
            className="d-flex justify-space-between"
            style={{ paddingTop: '50px', paddingBottom: '20px' }}>
            <label
              htmlFor="government-name-input"
              style={{
                fontSize: '14px',
                width: '100px',
                color: '#808080',
                paddingLeft: '30px',
              }}>
              行政機関名
            </label>
            <TextField
              id="government-name-input"
              type="text"
              placeholder="行政機関名"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              variant="standard"
              fullWidth
              style={{ width: '70%' }}
            />
          </div>
        </Collapse>
      </div>
    </>
  );
};
export default GovernmentFilterButton;
