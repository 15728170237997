import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class FamilyName {
  #familyName

  constructor(familyName) {
    this.#familyName = familyName;
  }

  get() {
    return this.#familyName
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#familyName, 1, 32)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#familyName)
  }
}
