
export class SummaryNearestStation {
    #summaryNearestStation

    constructor(summaryNearestStation) {
        this.#summaryNearestStation = summaryNearestStation;
    }

    get() {
        return this.#summaryNearestStation
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
