import ArticleDefaultBody from '../../common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import './css/point-to-note.css';

const PointToNote = () => {
  return (
    <div className="point-to-note" data-testid="point-to-note">
      <div className="article-default-title">
        <h3 data-testid="point-to-note-headline">留意事項</h3>
      </div>
      <ArticleDefaultBody>
        <div className="point-to-note-main-text" data-testid="point-to-note-main-text">
          <dl>
            <dt>1. 運送費用等について</dt>
            <dd>
              運送費用等は、買受人の負担となります。税務署長等は、立て替え払いができません。
            </dd>
            <dt>2. 運送業者</dt>
            <dd>
              税務署長等は、買受人が登録した運送業者に引き渡しを行います。ただし、運送業者によっては、公売財産を引き渡せない場合があります。
            </dd>
            <dt>3. 梱包</dt>
            <dd>
              梱包については、税務署等の公売担当者に依頼することができます。ただし、公売担当者は専門的な梱包を行えませんので、専門的な梱包を希望される場合は、買受人において梱包業者を手配してください。<br/>
              なお、公売財産によっては、税務署等の公売担当者が梱包できない場合があります。
            </dd>
            <dt>4. 配達希望日時</dt>
            <dd>
              配達希望日時は、納付予定日の5日後以降の日時から入力可能となります。ただし、公売財産の運送業者への引き渡しは、入力された配達希望日時にかかわらず、買受代金の納付確認後に行います。<br/>
              また「1.運送業者の選択」で「その他」を選択された場合は、希望する運送業者の配達可能日時をあらかじめご確認のうえ、配達希望日時を入力してください。<br/>
              なお、税務署等にとける公売物件の保管状況により配達準備に時間を要する場合や交通事情のやむを得ない事情により、指定された配達希望日に配達できない場合があります。
            </dd>
            <dt>5. 運送業者に運送させることにより引き渡しを受ける場合の責任</dt>
            <dd>
              買受代金の納付後、税務所長等は公売財産を運送業者へ引き渡したことをもって、買受人への引き渡し義務を完了したものとみなしますので、運送業者への引き渡し後に生じた事故や紛失島による損害について責任を負いません。<br/>
              また、公売担当者が、自己の財産に対するのと同一の注意をもって梱包を行ったにもかかわらず生じた損害について、税務署長等は責任を負いません。
            </dd>
            <dt>6. 登録内容の修正等</dt>
            <dd>
              内容に誤りがある場合等、登録した引き渡し方法等の内容を修正する必要があるときは、買受人は、公売担当者の指示に従い、内容を修正する必要があります。
            </dd>
            <dt>7. 引き渡しを留保する場合</dt>
            <dd>
              次の場合は、税務署長等は公売財産の引き渡しを行いませんので、ご注意ください。<br/>
              <ul>
                <li>
                  （１）KSI官公庁オークションサイトに必要な情報が登録されない場合、登録された内容に不備がある場合、公売担当者の指示に従って登録内容が修正されない場合または税務署長等に必要な書類が提出されない場合
                </li>
                <li>
                  （２）運送業者に依頼した搬送先が、公売参加申込者等の情報で登録した住所（代理人に依頼した場合は、公売参加申し込みで提出した委任状に記載した住所）と相違する場合で、買受人が税務署長等にその理由を示さないとき。
                </li>
                <li>
                  （３）運送業者に依頼した搬送先の受取人が、買受人または代理人と相違する場合
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </ArticleDefaultBody>
    </div>
  );
};

export default PointToNote;
