import Button from 'interfaces/components/common/Button/Button';
import TextCounter from 'interfaces/components/common/TextCounter';
import { useEffect, useState } from 'react';

const EmailTemplateEdit = ({
  emailTemplateText,
  text,
  initText,
  changeHandler,
  count,
  isOverCount,
  closeModal,
  navigateToConfirm,
}) => {
  const [emailTemplateTextArray, setEmailTemplateTextArray] = useState([
    '',
    '',
  ]);
  useEffect(() => {
    if (emailTemplateText) {
      const regex = /■.*?からのお知らせ/;
      if (regex.test(emailTemplateText)) {
        emailTemplateText = emailTemplateText.replace(/\\r\\n/g, `\n`);
        const splitString = emailTemplateText.match(regex)[0];
        const parts = emailTemplateText.split(splitString);
        setEmailTemplateTextArray([parts[0] + splitString, parts[1]]);
      }
    }
  }, [emailTemplateText]);
  return (
    <div className="email-template-edit" data-testid="email-template-edit">
      <div className="email-template-text-wrap">
        <p className="email-template-text" data-testid="email-template-text">
          {emailTemplateTextArray[0]}
        </p>
        <form data-testid="email-template-form">
          <textarea
            rows="12"
            value={text || initText}
            onChange={changeHandler}
            className="email-template-form-textarea"
          />
          <TextCounter count={count} isOverCount={isOverCount} />
        </form>
        <p className="email-template-text" data-testid="email-template-text">
          {emailTemplateTextArray[1]}
        </p>
      </div>

      <div className="email-template-button-wrapper">
        <Button
          text="キャンセル"
          className="common-button-bg-whitesmoke new-style_navi_modal-edit_normal-button"
          onClick={closeModal}
        />
        <Button
          text="確認"
          isOutline={true}
          className="ml-2 new-style_navi_modal-edit_primary-button"
          onClick={navigateToConfirm}
          isDisabled={isOverCount}
        />
      </div>
    </div>
  );
};
export default EmailTemplateEdit;
