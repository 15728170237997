import { ReactComponent as SelectedMylistIcon } from 'interfaces/assets/svg/selected_mylist_icon.svg';
import { ReactComponent as MylistIcon } from 'interfaces/assets/svg/star_icon.svg';
import AuthModal from 'interfaces/components/common/Header/HeaderTop/AuthModal.jsx';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'shared/context/AuthContext.js';
import { useAuth } from 'shared/hooks/useAuth.js';
import useModalControl from 'shared/hooks/useModalControl.js';
import { LOGIN } from 'shared/utils/helper/authState.js';

export const MylistBtnBranch = ({ myListBtn, className, children }) => {
  const { isLoggedIn } = useContext(AuthContext);
  const { isOpen, handleOpen, handleClose } = useModalControl();
  const [isMyListBtn, setIsMyListBtn] = useState(false);
  const [currentAuthStatus, setCurrentAuthStatus] = useState(null);
  const { userId } = useAuth();
  const mylistSelected = () => {
    if (!isLoggedIn) {
      handleOpen();
      setIsMyListBtn(false);
    } else {
      setIsMyListBtn(true);
    }
  };
  const mylistRemove = () => {
    setIsMyListBtn(false);
  };
  const switchAuthStatus = (authStatus) => {
    setCurrentAuthStatus(authStatus);
  };
  useEffect(() => {
    if (!isLoggedIn) {
      setIsMyListBtn(false);
      setCurrentAuthStatus(LOGIN);
    } else if (myListBtn) {
      setIsMyListBtn(true);
    }
  }, [myListBtn, userId]);
  return (
    <>
      {isMyListBtn ? (
        <>
          <button
            data-testid="mylist-button"
            onClick={mylistRemove}
            disabled={myListBtn}
            className={className + ' ' + 'v-btn'}>
            <SelectedMylistIcon className="add-mylist-button" />
            {children}
          </button>
          <AuthModal
            isOpen={isOpen}
            handleClose={handleClose}
            currentAuthStatus={currentAuthStatus}
            switchAuthStatus={switchAuthStatus}
          />
        </>
      ) : (
        <>
          <button
            data-testid="mylist-button"
            onClick={mylistSelected}
            className={`${className}` + ' ' + 'v-btn mylist-button'}>
            <MylistIcon className="add-mylist-button" />
            {children}
          </button>
          <AuthModal
            isOpen={isOpen}
            handleClose={handleClose}
            currentAuthStatus={currentAuthStatus}
            switchAuthStatus={switchAuthStatus}
          />
        </>
      )}
    </>
  );
};
