import SingleOpenAccordion from 'interfaces/components/common/Accordion/SingleOpenAccordion.jsx';
import DatePickerForm from 'interfaces/components/common/Form/DatePickerForm.jsx';
import RadioInput from 'interfaces/components/common/Input/RadioInput.jsx';
import RequiredLongLabel from 'interfaces/components/common/RequiredLabel/RequiredLongLabel.jsx';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault.jsx';
import { memo } from 'react';

export const paymentStatuses = [
  { id: 1, label: '納付済み', value: 'PAID' },
  { id: 2, label: '納付予定日を入力する', value: 'NO_PAID' },
];

const SelectPaymentStatusInput = memo(function SelectPaymentStatusInput({
  paymentData,
  setPaymentData,
  paymentDeadline,
}) {
  const today = new Date().setHours(0, 0, 0, 0);
  const deadline = new Date(paymentDeadline);

  const handleClick = (item) => {
    setPaymentData({ ...paymentData, target: item.value });
  };

  const handleChange = (selectDate) => {
    setPaymentData({ ...paymentData, paymentScheduledDate: selectDate });
  };

  const items = [
    {
      id: 1,
      title: (
        <RadioInput
          item={paymentStatuses[0]}
          label={paymentStatuses[0].label}
          value={paymentData.target}
          handleClick={() => handleClick(paymentStatuses[0])}
          className="accordion-label-radio"
        />
      ),
      isInvisibleIcon: true,
    },
    {
      id: 2,
      title: (
        <RadioInput
          item={paymentStatuses[1]}
          label={paymentStatuses[1].label}
          value={paymentData.target}
          handleClick={() => handleClick(paymentStatuses[1])}
          className="accordion-label-radio"
        />
      ),
      body: (
        <CommonTableDefault>
          <DatePickerForm
            date={paymentData.paymentScheduledDate}
            handleChange={handleChange}
            label={
              <p>
                納付予定日 <RequiredLongLabel />
              </p>
            }
            minDateTime={today}
            maxDateTime={deadline}
          />
        </CommonTableDefault>
      ),
    },
  ];

  return (
    <div data-testid="select-payment-status-input">
      <SingleOpenAccordion
        items={items}
        className="shipping-single-open-accordion"
      />
    </div>
  );
});

export default SelectPaymentStatusInput;
