// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reminder-modal .modal-title {
  font-size: 1.25rem;
  text-align: center;
  width: 100%;
  font-weight: 700;
  color: #c23c3c;
  padding: 16px 24px 10px;
}

.reminder-modal .modal-content {
  padding: 24px;
}
.reminder-modal .modal-border {
  background-color: #cccccc;
  border: none;
  height: 1px;
  margin: 0;
  width: 100%;
}

.reminder-modal .cancel-button {
  border: 1px solid #ccc;
}

.reminder-modal ul {
  margin-left: 12px;
}
.reminder-modal li {
  padding: 0;
  list-style: disc;
  margin-bottom: 24px;
  white-space: pre-wrap;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Dashboard/reminder-modal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,SAAS;EACT,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,UAAU;EACV,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;AACvB","sourcesContent":[".reminder-modal .modal-title {\n  font-size: 1.25rem;\n  text-align: center;\n  width: 100%;\n  font-weight: 700;\n  color: #c23c3c;\n  padding: 16px 24px 10px;\n}\n\n.reminder-modal .modal-content {\n  padding: 24px;\n}\n.reminder-modal .modal-border {\n  background-color: #cccccc;\n  border: none;\n  height: 1px;\n  margin: 0;\n  width: 100%;\n}\n\n.reminder-modal .cancel-button {\n  border: 1px solid #ccc;\n}\n\n.reminder-modal ul {\n  margin-left: 12px;\n}\n.reminder-modal li {\n  padding: 0;\n  list-style: disc;\n  margin-bottom: 24px;\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
