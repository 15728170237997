import {
  CAR,
  ESTATE,
  REAL_ESTATE,
} from 'domain/Property/entities/constants/property';

import { Link } from 'react-router-dom';
import { procedureTypeParam } from 'shared/utils/helper/procedure_type_param';

import GeneralDetailPropertyCardCaptions from './GeneralDetailPropertyCardCaptions';
import { governmentName } from '../helper/governmentName';
import { isFalsy } from '../helper/isFalsy';
import { price } from '../helper/price';
import PropertyCardImageTags from '../PropertyCardImageTags';
import { propertyStatus } from 'domain/Property/entities/constants/propertyStatus';

const GeneralDetailPropertyCardBody = ({
  data,
  isSuspension,
  category = REAL_ESTATE,
}) => {
  return (
    <div className="box flex flex-wrap flex-grow-1 justify-space-between align-flex-start no-bg property-sheet theme-light">
      <div className="box flex justify-space-between align-center pa-0 mb-1 property-sheet theme-light">
        <div className="box flex justify-start pa-0 ma-0 pr-10 property-sheet theme-light">
          <Link
            to={`/items/${data?.id}`}
            className="box mr-4 text-h6 font-weight-bold">
            {isFalsy(data?.name) ? '-' : data?.name}
          </Link>
        </div>
      </div>
      <div className="pa-0 ma-0 col-12 no-bg flex align-center property-sheet theme-light">
        <div className="thumb-status-tag box mr-2 property-sheet">
          {isFalsy(data?.status) ? '-' : propertyStatus[data?.status]}
        </div>
        <PropertyCardImageTags tags={data?.tags} className="items-tags" />
      </div>
      <div className="col-12 pa-0 no-bg property-sheet theme-light">
        <div className="flex pa-0 mt-1 mb-1 col-12 align-center col-6">
          <div className="box mr-2 property-sheet theme-light text-body-1">
            物件価格
          </div>
          <div className="box h1 property-sheet theme-light text-h5">
            {isSuspension ? (
              <b>
                0<small className="ml-1">円</small>
              </b>
            ) : (
              <b>
                {price(data)}
                <small className="ml-1">円</small>
              </b>
            )}
          </div>
        </div>
        <div className="col-12 pa-0 flex flex-wrap-wrap no-bg property-sheet theme-light">
          {isSuspension ? null : (
            <GeneralDetailPropertyCardCaptions data={data} />
          )}
          <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
            <div className="box text-no-wrap no-bg property-sheet theme-light">
              行政機関名
            </div>
            <div className="box no-bg property-sheet theme-light">
              <Link
                to={`/gov/${data?.governmentId}/?p=${procedureTypeParam(data?.procedureType)}`}>
                <b>{governmentName(data)}</b>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {category === CAR && !isSuspension && (
        <div className="col-12 mt-4 pa-0 no-bg v-sheet theme--light">
          <p className="text-subtitle-2 font-weight-bold mb-2">
            カテゴリ{' '}
            {isFalsy(data.summaryCarCategories)
              ? '-'
              : data.summaryCarCategories}
          </p>
          <div className="col-12 mb-2 pa-0 flex flex-wrap-wrap no-bg v-sheet theme--light">
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                車名（メーカー）
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.carProperty.manufactureJp)
                      ? '-'
                      : data.carProperty.manufactureJp}
                  </b>
                </p>
              </div>
              <div className="box mr-4 property-sheet theme-light"> |</div>
            </div>
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                初度登録年月
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.carProperty.summaryFirstRegisterDate)
                      ? '-'
                      : data.carProperty.summaryFirstRegisterDate}
                  </b>
                </p>
              </div>
              <div className="box mr-4 property-sheet theme-light"> |</div>
            </div>
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                車検
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.carProperty.summarySafetyTerm)
                      ? '-'
                      : data.carProperty.summarySafetyTerm}
                  </b>
                </p>
              </div>
              <div className="box mr-4 property-sheet theme-light"> |</div>
            </div>
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                走行距離
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.carProperty.carMileage)
                      ? '-'
                      : data.carProperty.carMileage.toLocaleString()}
                    km
                  </b>
                </p>
              </div>
              <div className="box mr-4 property-sheet theme-light"> |</div>
            </div>
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                総排気量
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.carProperty.displacement)
                      ? '-'
                      : data.carProperty.displacement}
                    リットル
                  </b>
                </p>
              </div>
              <div className="box mr-4 property-sheet theme-light"> |</div>
            </div>
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                用途
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.carProperty.summaryPurpose)
                      ? '-'
                      : data.carProperty.summaryPurpose}
                  </b>
                </p>
              </div>
              <div className="box mr-4 property-sheet theme-light"> |</div>
            </div>
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                ミッション
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.carProperty.summaryTransmissionType)
                      ? '-'
                      : data.carProperty.summaryTransmissionType}
                  </b>
                </p>
              </div>
              <div className="box mr-4 property-sheet theme-light"> |</div>
            </div>
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                乗車定員
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.carProperty.seatingCapacity)
                      ? '-'
                      : data.carProperty.seatingCapacity}
                    人
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {category === ESTATE && !isSuspension && (
        <div className="col-12 mt-4 pa-0 no-bg v-sheet theme--light">
          <div className="col-12 pa-0 no-bg v-sheet theme--light">
            <div className="col-12 mb-2 pa-0 flex no-bg text-caption v-sheet theme--light">
              <p className="text-no-wrap box nogrow pa-0 ma-0 mr-4">
                交通、最寄り駅など
              </p>
              <p className="box pa-0 ma-0 ">
                {isFalsy(data.estateProperty.nearestStation)
                  ? '-'
                  : data.estateProperty.nearestStation}
              </p>
            </div>
            <div className="col-12 mb-2 pa-0 flex no-bg text-caption v-sheet theme--light">
              <p className="text-no-wrap box nogrow pa-0 ma-0 mr-4">所在地</p>
              <p className="box pa-0 ma-0 ">
                {isFalsy(data.estateProperty.locationText)
                  ? '-'
                  : data.estateProperty.locationText}
              </p>
            </div>
          </div>
          <p className="text-subtitle-2 font-weight-bold mb-2">
            カテゴリ{' '}
            {isFalsy(data.summaryEstateType) ? '-' : data.summaryEstateType}
          </p>
          <div className="col-12 mb-2 pa-0 flex flex-wrap-wrap no-bg v-sheet theme--light">
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                建築年月
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.estateProperty.buildDate)
                      ? '-'
                      : data.estateProperty.buildDate}
                  </b>
                </p>
              </div>
              <div className="box mr-4 property-sheet theme-light"> |</div>
            </div>
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                建物面積
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.estateProperty.buildSpace)
                      ? '-'
                      : data.estateProperty.buildSpace}{' '}
                    m<sup>2</sup>
                  </b>
                </p>
              </div>
              <div className="box mr-4 property-sheet theme-light"> |</div>
            </div>
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                間取り
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.estateProperty.layout)
                      ? '-'
                      : data.estateProperty.layout}
                  </b>
                </p>
              </div>
              <div className="box mr-4 property-sheet theme-light"> |</div>
            </div>
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                総戸数
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.estateProperty.totalHouses)
                      ? '-'
                      : data.estateProperty.totalHouses}{' '}
                    戸
                  </b>
                </p>
              </div>
              <div className="box mr-4 property-sheet theme-light"> |</div>
            </div>
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                専有面積
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.estateProperty.exclusiveSpace)
                      ? '-'
                      : data.estateProperty.exclusiveSpace}{' '}
                    m<sup>2</sup>
                  </b>
                </p>
              </div>
              <div className="box mr-4 property-sheet theme-light"> |</div>
            </div>
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                延床面積
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.estateProperty.floorSpace)
                      ? '-'
                      : data.estateProperty.floorSpace}{' '}
                    m<sup>2</sup>
                  </b>
                </p>
              </div>
              <div className="box mr-4 property-sheet theme-light"> |</div>
            </div>
            <div className="detail-item box nogrow flex pa-0 mb-0 gaps">
              <div className="box text-no-wrap no-bg property-sheet theme-light">
                階建/階
              </div>
              <div className="box no-bg property-sheet theme-light">
                <p className="mb-0">
                  <b>
                    {isFalsy(data.estateProperty.floor)
                      ? '-'
                      : data.estateProperty.floor}
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralDetailPropertyCardBody;
