const SmallLightButton = ({ text, disabled, onClick }) => {
  return (
    <button
      className={`button button-small-light button-shadow ${disabled ? "button-disabled" : ""}`}
      data-testid="small-light-button"
      disabled={disabled}
      onClick={onClick}
    >
      <span className="button-content">{text}</span>
    </button>
  );
}

export default SmallLightButton;