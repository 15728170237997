export const convertEmptyCharacters = (value) => {
    const emptyCondition = ["DATA_EMPTY", "-9999", "1000-01-01 00:00:00", "1000-01-01", "00:00:00", null, undefined]
    if (emptyCondition.includes(value)) {
        return ""
    }
    return value;
};

export const convertGenerateString = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};
