import {
  displayNumItems,
  itemNameToDisplayNumTable,
  itemNameToQueryParamTable,
  sortCarItems,
  sortItems,
} from 'domain/Property/entities/constants/popupInput';

import PopUpInput from 'interfaces/components/common/Input/PopUpInput';
import {
  DIRECTION,
  FIELD,
  PAGE,
  PAGE_SIZE,
  SEARCH_GOVERNMENT_SUB_CATEGORY_ID,
} from 'shared/utils/constants/queryParam';

import { useQueryParamController } from '../../../../../shared/hooks/useQueryParamController';

const PopUpInputGroup = ({ category, sortType, itemsPerPage }) => {
  const { applyQueryParam, appendQueryParam } = useQueryParamController();

  return (
    <>
      <div className="text-subtitle-1">
        <div
          className={`underline ${category === 'CAR' ? 'category-car' : ''}`}>
          <PopUpInput
            items={category === 'CAR' ? sortCarItems : sortItems}
            value={sortType}
            handleSelect={(item) => {
              appendQueryParam(SEARCH_GOVERNMENT_SUB_CATEGORY_ID, '');
              applyQueryParam(
                DIRECTION,
                itemNameToQueryParamTable[item]?.direction,
              );
              applyQueryParam(FIELD, itemNameToQueryParamTable[item]?.field);
              appendQueryParam(PAGE, 1);
              appendQueryParam(PAGE_SIZE, 50);
            }}
          />
        </div>
      </div>
      <div className="text-subtitle-2">
        <div
          className={`underline ${category === 'CAR' ? 'category-car' : ''}`}>
          <PopUpInput
            items={displayNumItems}
            value={`${itemsPerPage}件`}
            handleSelect={(item) => {
              appendQueryParam(SEARCH_GOVERNMENT_SUB_CATEGORY_ID, '');
              appendQueryParam(DIRECTION, 'ASC');
              appendQueryParam(FIELD, 'PRICE');
              appendQueryParam(PAGE, 1);
              applyQueryParam(PAGE_SIZE, itemNameToDisplayNumTable[item]);
            }}
            className="large"
          />
        </div>
      </div>
    </>
  );
};

export default PopUpInputGroup;
