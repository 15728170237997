import EmailSwitcher from '../Email/EmailSwitcher';
import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import { breadcrumbs } from 'interfaces/pages/SampleGuideline/entities/breadcrumbs.js';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import TemplateGuidelineAssetSale from './TemplateGuidelineAssetSale';
import TemplateGuidelineAuction from './TemplateGuidelineAuction';
import GuidelineUsageCautionListAssetSale from './GuidelineUsageCautionListAssetSale';
import GuidelineUsageCautionListAuction from './GuidelineUsageCautionListAuction';
import GuidelineModal from './GuidelineModal';
import { TemplateGuidelineData } from 'shared/utils/helper/template-guideline-data.js';

import 'interfaces/css/SampleGuideline/template-guideline.css';
import 'interfaces/css/SampleGuideline/important-caution.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import useModalControl from 'shared/hooks/useModalControl';

const SampleGuideline = () => {
  const { isOpen, handleOpen, handleClose } = useModalControl();
  const [guidelineModalTitle, setGuidelineModalTitle] = useState('');
  const [guidelineModalContent, setGuidelineModalContent] = useState('');

  const closeModal = () => {
    const htmlElem = document.querySelector('html');
    htmlElem.classList.remove('has-guideline-modal-overlay');
    handleClose();
    setGuidelineModalTitle('');
    setGuidelineModalContent('');
  };
  const openModal = (key) => {
    const htmlElem = document.querySelector('html');
    htmlElem.classList.add('has-guideline-modal-overlay');
    handleOpen();
    setGuidelineModalTitle(TemplateGuidelineData[key].title);
    setGuidelineModalContent(TemplateGuidelineData[key].content);
  };

  const { localStorageProcedureType } = useLocalStorage();
  const navigate = useNavigate();
  const labels = [
    {
      label: 'ガイドラインサンプル',
      handleClick: () => {
        navigate('/navi/faq/sample-guideline');
      },
    },
    {
      label: '記入文言の注意事項',
      handleClick: () => {
        navigate('/navi/faq/sample-guideline-caution', { state: { from: 'sample-guideline' } });
      },
    },
  ];
  return (
    <div className="sample-guideline">
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <EmailSwitcher labels={labels} activeTabIndex={0} />

      <Heading2
        title={
          localStorageProcedureType === 'ASSET_SALE'
            ? '【KSI官公庁オークション】インターネット公有財産売却ガイドラインサンプル'
            : '【KSI官公庁オークション】インターネット公売ガイドラインサンプル'
        }
      />
      {localStorageProcedureType === 'ASSET_SALE' ? (
        <GuidelineUsageCautionListAssetSale openModal={openModal} />
      ) : (
        <GuidelineUsageCautionListAuction openModal={openModal} />
      )}
      {localStorageProcedureType === 'ASSET_SALE' ? (
        <TemplateGuidelineAssetSale openModal={openModal} />
      ) : (
        <TemplateGuidelineAuction openModal={openModal} />
      )}
      <GuidelineModal
        text={guidelineModalTitle}
        value={guidelineModalContent}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </div>
  );
};

export default SampleGuideline;
