import pino from 'pino';

import { BASE_URL_NAVIGATION } from '../../../config/envConstants.js';
import { fetchData } from '../../../shared/utils/helper/fetchData.js';

const logger = pino();
const COMMON_MAIL_TEMPLATE = (id) =>
  `${BASE_URL_NAVIGATION}/storage/current/navi/common_mail_templates/${id}/common_mail_templates.json`;

export const fetchCommonMailTemplateStorage = async (id) => {
  try {
    const response = await fetchCommonMailTemplate(id);
    return extractCommonMailTemplates(response);
  } catch (error) {
    logger.warn(
      { error },
      '現在の通知（ common_mail_templates ）を取得できませんでした',
    );
  }
};

export const fetchCommonMailTemplate = async (id) => {
  return fetchCommonMailTemplates(COMMON_MAIL_TEMPLATE(id));
};

export const fetchCommonMailTemplates = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
};

export const extractCommonMailTemplates = (response) => {
  return response.map((common_mail_template) => common_mail_template);
};
