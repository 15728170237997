
export class Status {
  #status

  constructor(status) {
    this.#status = status;
  }

  get() {
    return this.#status
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
