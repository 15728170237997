import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class LandOther {
  #landOther

  constructor(landOther) {
    this.#landOther = landOther;
  }

  get() {
    return this.#landOther
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#landOther, 0, 2000)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#landOther)
  }
}
