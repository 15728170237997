import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { DATA_EMPTY } from 'shared/utils/helper/constants';
import {
  switchInputClassName,
  switchInputMessage,
} from 'shared/utils/helper/inputHelper';

import 'interfaces/css/common/common-input.css';

const Wrapper = styled.div`
  flex: 1 1 auto;

  ${(props) =>
    props.$label &&
    css`
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    `}
`;

const Label = styled.div`
  text-align: left;
  font-size: 0.875rem;
  color: var(--text-base);
  line-height: 1.375rem;
  margin-right: 14px;
`;

const Body = styled.div`
  flex: 1 1 auto;
`;

const Input = styled.input`
  flex: 1 1 auto;
  width: 100%;
  min-height: 56px;
  font-size: 1rem;
  padding: 0 12px;
  border-radius: 4px;

  ${(props) =>
    props.$variant === 'normal-border' &&
    css`
      border: solid 1px rgba(0, 0, 0, 0.38);
    `}

  ${(props) =>
    props.$variant === 'focus-border' &&
    css`
      border: solid 2px var(--primary-base);
    `}

  ${(props) =>
    props.$variant === 'focus-error-border' &&
    css`
      border: solid 2px var(--error-base);
    `}

  ${(props) =>
    props.$variant === 'blur-error-border' &&
    css`
      border: solid 1px var(--error-base);
    `}
`;

const Caption = styled.div`
  text-align: left;
  font-size: 12px;
  line-height: 1.4em;

  ${(props) =>
    props.$validateMessage
      ? css`
          color: var(--error-base);
        `
      : css`
          color: rgba(0, 0, 0, 0.6);
        `}
`;

const CommonInput = ({
  name,
  value,
  label = '',
  placeholder = '',
  type,
  inputMode,
  maxLength,
  handleChange,
  validateMessage,
  caption,
}) => {
  const [borderClassName, setBorderClassName] = useState('normal-border');
  const [isFocus, setIsFocus] = useState(false);
  const [message, setMessage] = useState(caption);
  const isVisibleMessage = validateMessage !== 'NO_MESSAGE';

  useEffect(() => {
    switchInputClassName(isFocus, validateMessage, setBorderClassName);
  }, [isFocus, validateMessage]);

  useEffect(() => {
    switchInputMessage(validateMessage, setMessage, caption, isVisibleMessage);
  }, [validateMessage]);

  return (
    <Wrapper $label={label}>
      {label && <Label data-testid="common-input-label">{label}</Label>}
      <Body>
        <Input
          $variant={borderClassName}
          type={type}
          aria-label={name}
          name={name}
          inputMode={inputMode}
          maxLength={maxLength}
          value={value !== DATA_EMPTY ? value : ''}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          data-testid={`${name}-input`}
        />
        <Caption $validateMessage={validateMessage}>{message}</Caption>
      </Body>
    </Wrapper>
  );
};

export default CommonInput;
