import { createContext } from 'react';

import { breadcrumbs } from './breadcrumbs.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { DATA_EMPTY } from '../../../../shared/utils/helper/constants.js';

export const DashboardContext = createContext({
  responsive: DATA_EMPTY,
  authState: {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  },
  breadcrumbs: breadcrumbs,
  auctions: DATA_EMPTY,
  managementMessages: DATA_EMPTY,
  reminderMessages: DATA_EMPTY,
  notices: [],
  allowPropertyCategories: DATA_EMPTY,
  allowProperties: DATA_EMPTY,
  propertiesNum: {
    propertiesNum: 0,
    carPropertiesNum: 0,
    estatePropertiesNum: 0,
  },
  progressSchedules: null,
  invitations: [],
  government: undefined,
  promotionProperties: [],
});
