import Button from 'interfaces/components/common/Button';
import PageTitle from 'interfaces/components/common/PageTitle.jsx';
import { useContext, useState } from 'react';

import ServiceProvidersFormModal from './ServiceProvidersFormModal.jsx';
//import LoadingMessage from "interfaces/components/common/LoadingMessage.jsx";
import ServiceProvidersTable from './ServiceProvidersTable';
import { ServiceProviderContext } from '../../../../pages/ServiceProvider/entities/ServiceProviderContext';

import 'interfaces/css/ServiceProvider/service-provider.css';

const ServiceProviders = () => {
  const { serviceProviders } = useContext(ServiceProviderContext);
  const [isEditOpen, setIsEditOpen] = useState(false);

  return (
    <div className="kmanager-users" data-testid="kmanager-users">
      {isEditOpen ? (
        <ServiceProvidersFormModal
          closeModal={() => setIsEditOpen(false)}
          data={serviceProviders[0]}
        />
      ) : (
        ''
      )}
      <div className="kmanager-row">
        <div className="kmanager-col kmanager-col-12">
          <PageTitle text="サービス提供会社管理" />
        </div>
        <div className="edit-button-align">
          <Button
            className="primary"
            text={'編集'}
            clickHandler={() => {
              setIsEditOpen(true);
            }}
          />
        </div>
        <div className="kmanager-col kmanager-col-12">
          <div className="box-shadow">
            <ServiceProvidersTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceProviders;
