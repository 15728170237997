
export class DepositPaymentDate {
  #depositPaymentDate

  constructor(depositPaymentDate) {
    this.#depositPaymentDate = depositPaymentDate;
  }

  get() {
    return this.#depositPaymentDate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
