import PageNumberButton from './PageNumberButton.jsx';

const PageNumberButtonsComponent = ({ currentPage, pageNumbers, setCurrentPage }) => {
  return (
    <div data-testid="page-number-buttons">
      {pageNumbers.map((pageNumber, index) =>
        <PageNumberButton
          key={index}
          pageNumber={pageNumber}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default PageNumberButtonsComponent;
