import 'interfaces/css/common/caution-topic-record.css';
import Heading3 from 'interfaces/components/common/Heading/Heading3';
import CautionTopicTable from '../CautionTopicTable.jsx';
import ManualMarkedList from '../ManualMarkedList.jsx';
import SmallTopicTitle from '../../common/Heading/SmallTopicTitle.jsx';
import CautionManualMarkedList from '../CautionManualMarkedList.jsx';

const importantMatterTableData = [
  {
    headline: '危険負担',
    content: '買受代金を納付した時点で、危険負担は落札者に移転します。したがって、その後に発生した財産の毀損、盗難および焼失などによる損害の負担は、落札者が負うことになります。',
  },
  {
    headline: '契約不適合責任',
    content: '●●●は公売物件について契約不適合責任を負いません。'
  },
  {
    headline: '引き渡し条件',
    content: '公売物件は、落札者が買受代金を納付した時点の状況（現況有姿）で引き渡します。',
  },
  {
    headline: '執行機関の引き渡し義務',
    content: <ManualMarkedList data={[
      {
        mark: '・',
        text: <>「売却決定通知書」を保管人に提示して引き渡しを受ける場合<br/>執行機関は「売却決定通知書」を落札者に交付する方法により公売物件の引き渡しを行います。落札者は「売却決定通知書」を保管人に提示して公売物件の引き渡しを受けてください。当該保管人が現実の引き渡しを拒否しても執行機関は現実の引き渡しを行う義務を負いません。</>,
      },
      {
        mark: '・',
        text: <>公売物件が不動産の場合<br/>執行機関は落札者への不動産登記簿上の所有権移転などの登記は行いますが、物件の引き渡しの義務を負いません。物件内の動産類やごみなどの撤去、占有者の立ち退き、前所有者からの鍵の引き渡しなどは、すべて落札者自身で行っていただきます。また、隣地との境界確定は、落札者と隣地所有者との間で行っていただきます。</>,
      },
    ]}/>
  },
  {
    headline: '返品、交換',
    content: '落札された物件はいかなる理由があっても返品、交換できません。',
  },
  {
    headline: '保管費用',
    content: '買受代金納付期限日に公売物件を引き取らない場合、保管費用がかかることがあります。',
  },
  {
    headline: '落札者（最高価申込者）決定後、公売保証金が返還される場合',
    content: <ManualMarkedList data={[
      {
        mark: '・',
        text: '買受代金が納付されるまでに公売物件にかかる差押徴収金の完納の事実が証明された場合、物件を買い受けることができません。この場合、納付された公売保証金は全額返還されます。',
      },
      {
        mark: '・',
        text: '買受代金の納付前に、滞納者などから不服申立てなどがあった場合、公売の手続きは停止します。手続きの停止中は、落札者は買受を辞退できます。辞退した場合、納付された公売保証金は全額返還されます。',
      },
      {
        text: '※公売保証金の返還には、4週間程度かかることがあります。',
      },
    ]}/>
  },
]

const contactTableData = [
  {
    headline: 'お問い合わせ先',
    content: '●●市●●部●●課',
    isBgGray: true,
  },
  {
    headline: 'メールアドレス',
    content: 'xxx@xxx.lg.jp',
    isBgGray: true,
  },
  {
    headline: '電話番号',
    content: '03-3333-5555（内線：1234）',
    isBgGray: true,
  },
  {
    headline: '電話受付時間',
    content: '平日 9時から17時',
    isBgGray: true,
  },
]

const ImportantMatterAuctionSection = () => {
  return (
    <div data-testid="important-matter-section" className="pt-16">
      <Heading3 text="重要事項" isLeftBorderStyle={true}/>
      <p className="sample-after-winning-bid-lead-text pt-2 pb-9" data-testid="sample-after-winning-bid-lead-text">落札後の権利移転手続きにおける重要な事項です。必ずご確認ください。</p>
      <SmallTopicTitle text="必要な費用" isFontBold={true}/>
      <CautionTopicTable recordData={importantMatterTableData} className="mt-3"/>
      <CautionManualMarkedList title="ご注意" className="pt-3" data={[
        {
          text: '入札方法が入札形式による公売で、公売物件が不動産などの場合、売却決定を受けた次順位買受申込者も落札者に含みます。'
        }
      ]}/>
      <CautionTopicTable recordData={contactTableData} className="mt-16" caption="落札後の注意事項に関するお問い合わせ" hasCaptionBgColor={true}/>
    </div>
  );
};

export default ImportantMatterAuctionSection;