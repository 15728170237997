
export class TaxId {
  #taxId

  constructor(taxId) {
    this.#taxId = taxId;
  }

  get() {
    return this.#taxId
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
