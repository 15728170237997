import SmallTopicTitle from '../../../common/Heading/SmallTopicTitle.jsx';
import ManualMarkedList from '../../ManualMarkedList.jsx';

const data = [
  {
    mark: '※',
    text: '落札者が法人で、法人の従業員の方が支払いを行う場合もその従業員が代理人となり、委任状などが必要となります。',
  },
  {
    mark: '※',
    text: '代理人名で所有権の登記をすることはできません。',
  },
]


const RightsTransferCautionAssetSale = () => {
  return (
    <div className="pt-9" data-testid="rights-transfer-caution">
      <SmallTopicTitle text='落札者（落札者が法人の場合は代表者）以外の方が権利移転手続きを行う場合' isFontBold={true}/>
      <p className="sample-after-winning-bid-lead-text pt-2 pb-3" data-testid="sample-after-winning-bid-lead-text">落札者本人（落札者が法人の場合はその代表者）が売払代金の支払い手続きを行えない場合、代理人が売却代金の支払い手続きを代行できます。その場合、委任状、落札者本人と代理人双方の印鑑登録証明書および代理人の本人確認書面が必要となります。</p>
      <ManualMarkedList data={data}/>
    </div>
  );
}

export default RightsTransferCautionAssetSale;