// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-button{
    margin-right: 8px;
    display: flex;
}
.radio-button input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    max-height: 24px;
    max-width: 24px;
}
.radio-button-marked{
    color: #ff7f00;
    caret-color: #ff7f00;
}
.radio-button-blank{
    color: rgba(0,0,0,.54);
}
.radio-button-estate{
    color: rgba(0,0,0,.38);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/radio-button.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,cAAc;IACd,oBAAoB;AACxB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,sBAAsB;AAC1B","sourcesContent":[".radio-button{\n    margin-right: 8px;\n    display: flex;\n}\n.radio-button input {\n    position: absolute;\n    opacity: 0;\n    width: 100%;\n    height: 100%;\n    cursor: pointer;\n    max-height: 24px;\n    max-width: 24px;\n}\n.radio-button-marked{\n    color: #ff7f00;\n    caret-color: #ff7f00;\n}\n.radio-button-blank{\n    color: rgba(0,0,0,.54);\n}\n.radio-button-estate{\n    color: rgba(0,0,0,.38);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
