import {
  validateRangeLength,
  hasEmoji,
} from '../../../../application/validations.js';

export class CertDocumentWrittenDestination {
  #certDocumentWrittenDestination;

  constructor(certDocumentWrittenDestination) {
    this.#certDocumentWrittenDestination = certDocumentWrittenDestination;
  }

  get() {
    return this.#certDocumentWrittenDestination;
  }

  get isValid() {
    return [this.validateRangeLength, this.hasEmoji].every(
      (result) => result === 'TRUE',
    );
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#certDocumentWrittenDestination, 0, 50);
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#certDocumentWrittenDestination);
  }
}
