import { useContext } from 'react';

import { DATA_EMPTY } from 'domain/Property/entities/constants/property.js';

import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle.jsx';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault.jsx';
import { PREFECTURE } from 'shared/utils/constants/prefecture.js';
import { dateFormat } from 'shared/utils/helper/date_format.js';

import { ShippingContext } from 'interfaces/pages/Shipping/entities/ShippingContext';
import ShippingInfoRecord from './ShippingInfoRecord.jsx';
import { OTHER } from '../../../../shared/utils/constants/carrier.js';
import { arrangements } from '../ShippingInfoRegistration/ShippingInfoRegistrationFormInputs/SelectArrangementInput.jsx';
import { carriers } from '../ShippingInfoRegistration/ShippingInfoRegistrationFormInputs/SelectCarrierInput.jsx';
import { packingMethods } from '../ShippingInfoRegistration/ShippingInfoRegistrationFormInputs/SelectPackingMethodInput.jsx';

const carrierName = (shippingInfo) => {
  if (!shippingInfo?.carrierNameList) {
    return '';
  }
  if (shippingInfo.carrierNameList === OTHER.en) {
    return shippingInfo.carrierName;
  }
  return carriers.find(
    (carrier) => carrier.value === shippingInfo.carrierNameList,
  ).label;
};

const arrangement = (shippingInfo) => {
  if (!shippingInfo?.arrangement) {
    return '';
  }
  if (shippingInfo.arrangement === arrangements[0].value) {
    return arrangements[0].label;
  }
  return arrangements[1].label;
};

const packingMethod = (shippingInfo) => {
  if (!shippingInfo?.packing) {
    return '';
  }
  if (shippingInfo.packing === packingMethods[0].value) {
    return packingMethods[0].label;
  }
  return packingMethods[1].label;
};

const receiver = (shippingInfo) => {
  if (!shippingInfo?.receiver) {
    return '';
  }
  if (shippingInfo.receiver === 'CLIENT') {
    return '依頼人と同じ';
  }
  if (shippingInfo.receiverRole === 'CLIENT') {
    return 'その他（依頼人へ送る）';
  }
  return 'その他（委任者へ送る）';
};

const address = (shippingInfo) => {
  if (!shippingInfo?.receiverGovernmentSubCategoryId) {
    return '';
  }
  const prefecture = PREFECTURE.find(
    (prefecture) =>
      prefecture.id === Number(shippingInfo.receiverGovernmentSubCategoryId),
  );
  return `${shippingInfo.zipCode} ${prefecture.name} ${shippingInfo.receiverAddress}`;
};

const deliverReason = (shippingInfo) => {
  if (!shippingInfo?.receiver || shippingInfo.receiver !== 'OTHER') {
    return DATA_EMPTY;
  }
  return shippingInfo.deliveryReason;
};

const paymentScheduledDate = (shippingInfo) => {
  if (!shippingInfo?.paymentScheduledDate) {
    return DATA_EMPTY;
  }
  if (shippingInfo.isPaid === 'PAID') {
    return '納付済';
  }
  return dateFormat(shippingInfo.paymentScheduledDate, 'YYYY-MM-DD');
};

const deliverySchedule = (shippingInfo) => {
  if (shippingInfo?.deliverySchedule === 'SPECIFY') {
    return `${dateFormat(shippingInfo.deliveryDate, 'YYYY-MM-DD')} ${shippingInfo.deliveryTime}`;
  }
  return '希望なし';
};

const ShippingInfos = ({ shippingInfo, step }) => {
  //const membershipId = '--  mock membership id --';

  const { auctionUser } = useContext(ShippingContext);

  const infos = () => {
    const infoList = [
      { id: 1, label: '会員識別番号', value: auctionUser.membershipId },
      { id: 2, label: '運送業者', value: carrierName(shippingInfo) },
      {
        id: 3,
        label: '運送業者電話番号',
        value: shippingInfo?.carrierTel ?? '',
      },
      { id: 4, label: '手配について', value: arrangement(shippingInfo) },
      { id: 5, label: '梱包について', value: packingMethod(shippingInfo) },
      { id: 6, label: 'お届け先住所', value: receiver(shippingInfo) },
      { id: 7, label: '氏名', value: shippingInfo?.receiverName ?? '' },
      { id: 8, label: '住所', value: address(shippingInfo) },
      { id: 9, label: '電話番号', value: shippingInfo?.receiverTel ?? '' },
      { id: 10, label: '変更理由', value: deliverReason(shippingInfo) },
      {
        id: 11,
        label: '買受代金納付予定日',
        value: paymentScheduledDate(shippingInfo),
      },
      { id: 12, label: '配達希望日時', value: deliverySchedule(shippingInfo) },
      { id: 13, label: 'ご要望欄', value: shippingInfo?.deliveryText ?? '' },
    ];

    if (step !== 4) {
      return infoList;
    }

    return infoList.slice(1);
  };

  return (
    <div>
      <ArticleDefaultTitle title="お届先情報" />
      <ArticleDefaultBody>
        <CommonTableDefault>
          {infos().map((info) => (
            <ShippingInfoRecord key={info.id} info={info} />
          ))}
        </CommonTableDefault>
      </ArticleDefaultBody>
    </div>
  );
};

export default ShippingInfos;
