// mock用

export const dummyPropertyCardData = [
  {
    auctionUserId: '1',
    bidders: [],
    deadline: '2023-10-17 9:59:00',
    deposit: 1000,
    depositPayment: '-',
    governmentId: 1,
    governmentName: '行政機関名表示テスト1',
    id: 1,
    payment: [],
    price: 10000,
    propertyName: '商品名テスト1',
    remainingLabel: '申込締切まであと',
    status: '申し込み受付中',
    statusEn: 'APPLYING',
    tags: ['公有財産売却', '入札形式', '保証金あり（クレカ納付OK）'],
  },
  {
    auctionUserId: '1',
    bidders: [],
    deadline: '2023-10-17 9:59:00',
    deposit: 1000,
    depositPayment: '-',
    governmentId: 1,
    governmentName: '行政機関名表示テスト2',
    id: 2,
    payment: [],
    price: 10000,
    propertyName: '商品名テスト2',
    remainingLabel: '申込締切まであと',
    status: '申し込み受付中',
    statusEn: 'APPLYING',
    tags: ['公有財産売却', '入札形式', '保証金あり（クレカ納付OK）'],
  },
  {
    auctionUserId: '1',
    bidders: [],
    deadline: '2023-10-17 9:59:00',
    deposit: 1000,
    depositPayment: '-',
    governmentId: 1,
    governmentName: '行政機関名表示テスト3',
    id: 3,
    payment: [],
    price: 10000,
    propertyName: '商品名テスト3',
    remainingLabel: '申込締切まであと',
    status: '申し込み受付中',
    statusEn: 'APPLYING',
    tags: ['公有財産売却', '入札形式', '保証金あり（クレカ納付OK）'],
  },
  {
    auctionUserId: '1',
    bidders: [],
    deadline: '2023-10-17 9:59:00',
    deposit: 1000,
    depositPayment: '-',
    governmentId: 1,
    governmentName: '行政機関名表示テスト4',
    id: 4,
    payment: [],
    price: 10000,
    propertyName: '商品名テスト4',
    remainingLabel: '申込締切まであと',
    status: '申し込み受付中',
    statusEn: 'APPLYING',
    tags: ['公有財産売却', '入札形式', '保証金あり（クレカ納付OK）'],
  },
  {
    auctionUserId: '1',
    bidders: [],
    deadline: '2023-10-17 9:59:00',
    deposit: 1000,
    depositPayment: '-',
    governmentId: 1,
    governmentName: '行政機関名表示テスト5',
    id: 5,
    payment: [],
    price: 10000,
    propertyName: '商品名テスト5',
    remainingLabel: '申込締切まであと',
    status: '申し込み受付中',
    statusEn: 'APPLYING',
    tags: ['公有財産売却', '入札形式', '保証金あり（クレカ納付OK）'],
  },
  {
    auctionUserId: '1',
    bidders: [],
    deadline: '2023-10-17 9:59:00',
    deposit: 1000,
    depositPayment: '-',
    governmentId: 1,
    governmentName: '行政機関名表示テスト6',
    id: 6,
    payment: [],
    price: 10000,
    propertyName: '商品名テスト6',
    remainingLabel: '申込締切まであと',
    status: '申し込み受付中',
    statusEn: 'APPLYING',
    tags: ['公有財産売却', '入札形式', '保証金あり（クレカ納付OK）'],
  },
];
