import RadioButton from "interfaces/components/common/Button/RadioButton";
import { useEffect, useState } from "react";
import { pulldown_month } from "shared/utils/helper/Pulldown";

import SelectBox from "./SelectBox";
import { car_radio_label2 } from "../../../common/RadioLabel";
import Label from "../../../Form/navi/Label";

const FormSafetyTerm = ({
  className = "",
  label,
  safetyTerm,
  //setSafetyTerm,
  setStatusSafetyTerm,
  statusSafetyData,
}) => {
  const [yearList, setYearList] = useState([]);
  const [dayList, setDayList] = useState([]);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [selectedRadioText, setSelectedRadioText] = useState("あり");

  const yearText = (year) => {
    let tmpYear = Number(year);
    if (isNaN(tmpYear)) {
      return "";
    }

    let tmp = new Date(year, 0, 1);
    let wareki = tmp.toLocaleString("ja-JP-u-ca-japanese", {
      era: "short",
      year: "numeric",
    });
    return `${wareki}(${tmpYear}年)`;
  };

  // 初期化
  useEffect(() => {
    if (statusSafetyData === "TRUE") {
      setSelectedRadioText("あり");
    } else {
      setSelectedRadioText("期限切れ");
    }
  }, [statusSafetyData]);

  useEffect(() => {
    if (safetyTerm && safetyTerm?.year !== "") {
      setYear(safetyTerm.year);
      setMonth(safetyTerm.month);
      setDay(safetyTerm.day);
    }

    let list = [];
    let today = new Date();
    let thisYear = today.getFullYear();

    // 車検有効期限は最大３年
    for (let i = thisYear; i < thisYear + 4; i++) {
      list.push({ id: `${i}`, text: yearText(i), value: `${i}` });
    }
    setYearList(list);
  }, [safetyTerm]);

  // 月が変更された時、日付のリストを変更する
  useEffect(() => {
    let list = [];
    let endDate = new Date(Number(year), Number(month), 0);
    for (let i = 1; i <= endDate.getDate(); i++) {
      list.push({ id: `${i}`, text: `${i}`, value: `${i}` });
    }
    setDayList(list);
  }, [year, month]);

  const handleChangeStatus = (event) => {
    let value = "TRUE";
    if (event.target.value === "期限切れ") {
      value = "FALSE";
    }
    setStatusSafetyTerm(value);
  };

  const Expiration = () => {
    if ("期限切れ" !== selectedRadioText) {
      return (
        <div className="property-info-car-form-date-pulldown new-style_navi_form-date-pulldown">
          <div className="new-style_navi_form-date-pulldown_year">
            <SelectBox
              listItemData={yearList}
              name={"year"}
              selectedTextData={year !== "" ? yearText(year) : ""}
              width={"18em"}
            />
          </div>
          <div className="ml-2">年</div>
          <div className="ml-2 new-style_navi_form-date-pulldown_md">
            <SelectBox
              handler={handlerMonth}
              listItemData={pulldown_month}
              name={"month"}
              selectedTextData={month}
              width={"6em"}
            />
          </div>
          <div className="ml-2">月</div>
          {
            <>
              <div className="ml-2 new-style_navi_form-date-pulldown_md">
                <SelectBox
                  name={"day"}
                  listItemData={dayList}
                  selectedTextData={day}
                  width={"6em"}
                />
              </div>
              <div className="ml-2">日</div>
            </>
          }
          {/*
                <div className="property-info-car-form-date-pulldown pt-2">
                    <SelectBox listItemData={pulldown_year} width={"18em"}/>
                    <div className="ml-2">年</div>
                    <div className="ml-2">
                        <SelectBox listItemData={pulldown_month} width={"6em"}/>
                    </div>
                    <div className="ml-2">月</div>
                    <div className="ml-2">
                        <SelectBox listItemData={pulldown_day} width={"6em"}/>
                    </div>
                    <div className="ml-2">日</div>
                </div>
                */}
        </div>
      );
    }
    return "";
  };

  const handlerMonth = (e) => {
    setMonth(e.target.value);
  };

  return (
    <div className="property-info-general-form-table-row">
      <Label label={label} required={true} />
      <div className="property-info-general-form-table-cell">
        <div
          className={
            className ? "property-info-general-form-radio-" + className : "flex"
          }
        >
          <RadioButton
            data={car_radio_label2}
            isText={selectedRadioText}
            handleChange={(e) => handleChangeStatus(e)}
          />
        </div>
        <Expiration />
      </div>
    </div>
  );
};

export default FormSafetyTerm;
