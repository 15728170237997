
export class SketchUrl {
  #sketchUrl

  constructor(sketchUrl) {
    this.#sketchUrl = sketchUrl;
  }

  get() {
    return this.#sketchUrl
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
