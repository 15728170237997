import { findByGovernmentId as getDivisions } from 'domain/Division/services/Division';
import { findByPropertyId as getEstateProperty } from 'domain/Property/services/EstateProperty';
import { findById as getProperty } from 'domain/Property/services/Property';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { findById as getInvitation } from '../../../../domain/Auction/services/Invitation';
import { getSelectedCategories as getCategories } from '../../../../domain/Category/services/PropertyCategory.js';
import { get as getEstateProperties } from '../../../../domain/Property/services/EstateProperty.js';
import { findByPropertyId as getPaymentMethods } from '../../../../domain/Property/services/PaymentMethod';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { PropertyInfoEstateContext } from '../entities/PropertyInfoEstateContext.js';

const PropertyInfoEstateProvider = ({ type, children }) => {
  const { governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [estateProperties, setEstateProperties] = useState([]);
  const [invitation, setInvitation] = useState([]);
  const [property, setProperty] = useState(undefined);
  const [divisions, setDivisions] = useState([]);
  const [estateProperty, setEstateProperty] = useState(undefined);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});

  const { auction_id, property_id } = useParams();
  const { localStorageGovernmentId } = useLocalStorage();

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getEstateProperties(type).then((result) => {
        setEstateProperties(result);
      });
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      getDivisions(type, localStorageGovernmentId).then((divisions) => {
        let selectDivisions = [];
        divisions.forEach((division) => {
          let tmpDivision = {};
          tmpDivision.id = division.id;
          tmpDivision.text = division.name;
          tmpDivision.value = division.id;
          selectDivisions.push(tmpDivision);
        });
        setDivisions(selectDivisions);
      });
      getEstateProperties(type).then((result) => {
        setEstateProperties(result);
      });
      getInvitation(type, auction_id, localStorageGovernmentId).then(
        (result) => {
          setInvitation(result);
        },
      );
      if (property_id !== undefined) {
        getProperty(type, property_id).then((result) => {
          setProperty(result);
          getCategories(type, result.categoryId).then((categories) => {
            setSelectedCategory(categories);
          });
        });
        getEstateProperty(type, property_id).then((result) => {
          setEstateProperty(result);
        });
        getPaymentMethods(type, property_id).then((paymentMethods) => {
          setPaymentMethods(paymentMethods);
        });
      }
    }, [localStorageGovernmentId]);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getEstateProperties(type).then((result) => {
        setEstateProperties(result);
      });
    }, []);
  }

  return (
    <PropertyInfoEstateContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentType,
        procedureType,
        estateProperties,
        invitation,
        property,
        divisions,
        estateProperty,
        paymentMethods,
        selectedCategory,
      }}>
      {children}
    </PropertyInfoEstateContext.Provider>
  );
};

export default PropertyInfoEstateProvider;
