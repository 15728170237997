import React from 'react';

export default function Heading2({ text, description }) {
  return (
    <>
      <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{text}</h2>
      {description && (
        <p style={{ marginLeft: '1rem', color: 'black' }}>
          {description.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      )}
    </>
  );
}
