import { auctionSchedule } from "interfaces/pages/AuctionSchedule/entities/componentMapping.js";
import AuctionScheduleProvider from 'interfaces/pages/AuctionSchedule/services/AuctionScheduleProvider';
import { getComponent } from "shared/utils/helper/get_component";

const AuctionSchedule = ({ type }) => {
  return (
    <AuctionScheduleProvider type={type}>
      {getComponent(auctionSchedule, type)}
    </AuctionScheduleProvider>
  );
};

export default AuctionSchedule;
