import { ReactComponent as ExpandMoreIcon } from 'interfaces/assets/svg/expand_more_icon.svg';
import { useEffect, useRef, useState } from 'react';
import 'interfaces/css/common/common-accordion.css';

const PromotionAccordion = ({
  label,
  contents,
  promotionAccordionControls,
  id,
  setPromotionAccordionControls,
  activeIndex,
  setActiveIndex
}) => {
  const contentRef = useRef(null);

  // クリックしたアコーディオンの開閉を管理するオブジェクト
  const [target, setTarget] = useState({});

  // 開閉オブジェクトの状態を更新
  useEffect(() => {
    setTarget(promotionAccordionControls.filter((item) => item.id === id)[0]);
  }, [promotionAccordionControls]);

  const toggleAccordion = () => {
    // 一致するプロモーションIDのアコーディオンのisOpenのtrue/falseを反転する
    promotionAccordionControls.map((item) => {
      if (item.id === id) {
        const isOpen = item.isOpen;
        item['isOpen'] = !isOpen;
      }
    });

    // promotionAccordionControlsの更新処理
    setPromotionAccordionControls([...promotionAccordionControls]);

    // アコーディオンが開いた状態になり、他のアコーディオンが開いている状態の場合はactiveIndexを更新しない
    if (target.isOpen === true && activeIndex === 0) {
      setActiveIndex(id);
    }

    // アコーディオンが閉じた状態になった場合
    if (target.isOpen === false) {
      // 他のアコーディオンが開いている状態の場合は、そのアコーディオンのIDをactiveIndexにセットする
      if (promotionAccordionControls.filter((item) => item.isOpen === true).length > 0) {
        return setActiveIndex(promotionAccordionControls.filter((item) => item.isOpen === true)[0].id);
      }
      // activeIndexを全てのアコーディオンが閉じている状態にリセット
      setActiveIndex(0);
    }
  };

  return (
    <div className="common-accordion" data-testid="common-accordion">
      <div
        className="common-accordion-panel flex align-center justify-space-between"
        onClick={toggleAccordion}
        data-testid="common-accordion-toggle"
      >
        <div className="common-accordion-label" data-testid="common-accordion-label">{label}</div>
        <ExpandMoreIcon/>
      </div>
      <div
        className="common-accordion-container"
        ref={contentRef}
        style={{ display: target?.isOpen ? 'inline-block' : 'none' }}
        data-testid="common-accordion-container"
      >
        {contents}
      </div>
    </div>
  );
};

export default PromotionAccordion;
