import 'interfaces/css/common/ItemFloatModal.css';
import { useEffect, useState } from 'react';

const ItemFloatModal = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.scrollY >= 600 && window.scrollY <= 4000) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);
  return (
    <>
      {isVisible ? (
        <section
          data-testid="item-float-modal-overlay"
          className="item-float-modal-overlay">
          <div data-testid="item-float-modal" className="item-float-modal">
            {children}
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  );
};
export default ItemFloatModal;
