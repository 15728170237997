// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navi-wrap .navi .logo .header-logo {
    display: block;
    width: 290px;
    height: 27px;
    overflow: hidden;
    position: relative;
}

.navi-wrap .navi .logo .header-logo img {
    position: absolute;
    top: 0;
    left: 0;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/navi-logo.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;AACX","sourcesContent":[".navi-wrap .navi .logo .header-logo {\n    display: block;\n    width: 290px;\n    height: 27px;\n    overflow: hidden;\n    position: relative;\n}\n\n.navi-wrap .navi .logo .header-logo img {\n    position: absolute;\n    top: 0;\n    left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
