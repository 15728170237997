// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kmanager-header-icon-toggle-button:first-child {
  margin-left: -12px;
}
.kmanager-header-icon-toggle-button:last-child {
  margin-right: -12px;
}

.kmanager-header-icon-toggle-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 48px;
  width: 48px;
}

.kmanager-header-icon-toggle-button svg {
  fill: rgba(0, 0, 0, 0.54);
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/icon-toggle-button.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".kmanager-header-icon-toggle-button:first-child {\n  margin-left: -12px;\n}\n.kmanager-header-icon-toggle-button:last-child {\n  margin-right: -12px;\n}\n\n.kmanager-header-icon-toggle-button {\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  height: 48px;\n  width: 48px;\n}\n\n.kmanager-header-icon-toggle-button svg {\n  fill: rgba(0, 0, 0, 0.54);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
