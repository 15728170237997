
export class CommonDescription {
  #commonDescription

  constructor(commonDescription) {
    this.#commonDescription = commonDescription;
  }

  get() {
    return this.#commonDescription
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
