import GuidelineModalTemplateTextArea from './GuidelineModalTemplateTextArea.jsx';
import 'interfaces/css/SampleGuideline/guideline-modal-body.css';

const GuidelineModalBody = ({ value }) => {
  return (
    <div data-testid="guideline-modal-body" className="guideline-modal-body">
      <GuidelineModalTemplateTextArea value={value}/>
      <div
        className="guideline-modal-text pt-4">上記テキストをコピーし、ガイドラインの「本文」部分に貼り付け、太字やフォントサイズの変更をしてください。
      </div>
    </div>
  );
};
export default GuidelineModalBody;