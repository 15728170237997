const governmentTypeJp = {
  NATIONAL_TAX_AGENCY: '国税庁',
  PREFECTURE: '都道府県',
  MUNICIPALITY: '市区町村',
  NATIONAL_ASSET: '国_公有財産売却',
  OTHER: 'その他の行政機関',
}

export const GovernmentTypeToJP = (governmentType) => {
  return governmentTypeJp[governmentType] ?? governmentType
}