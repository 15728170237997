import { validateRangeLength } from "../../../../application/validations.js";

export class TransRightPropertyDescription {
  #transRightPropertyDescription

  constructor(transRightPropertyDescription) {
    this.#transRightPropertyDescription = transRightPropertyDescription;
  }

  get() {
    return this.#transRightPropertyDescription
  }

  get isValid() {
    return [
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#transRightPropertyDescription, 0, 1000)
  }
}
