// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sample-guideline .template-guideline-lead-text {
  font-size: 15px;
  line-height: 1.467;
}

.sample-guideline .template-guideline-section-title {
  font-size: 17px;
  font-weight: 700;
  line-height: 1.294;
}

.sample-guideline .template-guideline-body {
  font-size: 15px;
  line-height: 1.467;
}

`, "",{"version":3,"sources":["webpack://./src/interfaces/css/SampleGuideline/template-guideline.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".sample-guideline .template-guideline-lead-text {\n  font-size: 15px;\n  line-height: 1.467;\n}\n\n.sample-guideline .template-guideline-section-title {\n  font-size: 17px;\n  font-weight: 700;\n  line-height: 1.294;\n}\n\n.sample-guideline .template-guideline-body {\n  font-size: 15px;\n  line-height: 1.467;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
