import { useContext } from "react";

import Alert from './Alert.jsx';
import { DashboardContext } from "../../../../pages/Dashboard/entities/DashboardContext";

const Alerts = () => {
  const { managementMessages } = useContext(DashboardContext);
  return (
    <div data-testid="alerts">
      {managementMessages?.map(alert =>
        <Alert key={alert.id} data={alert}/>
      )}
    </div>
  );
};

export default Alerts;
