import { validateRangeLength, validateAlphanum } from "../../../../application/validations.js";

export class ClassificationNumber {
  #classificationNumber

  constructor(classificationNumber) {
    this.#classificationNumber = classificationNumber;
  }

  get() {
    return this.#classificationNumber
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.validateAlphanum,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#classificationNumber, 0, 30)
  }

  // バリデーションルールの適用
  get validateAlphanum() {
    return validateAlphanum(this.#classificationNumber)
  }
}
