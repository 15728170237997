// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createNaviGuidelinesData,
  updateNaviGuidelinesData,
  deleteNaviGuidelinesData,
} from '../../../infrastructure/api/NaviGuidelines.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { GuidelinesCreate } from '../entities/Guidelines.js';
import { getGuidelines } from '../repositories/Guidelines.js';

export async function get(type) {
  return await getGuidelines(type);
}

export async function findById(type, id) {
  const Guidelines = await getGuidelines(type);
  return Guidelines.find((data) => data.id === id);
}

export async function findByGovernmentId(type, governmentId, procedureType) {
  const guidelines = await getGuidelines(type);

  let targetGuidelines = guidelines.filter(
    (guideline) =>
      guideline.governmentId === governmentId &&
      guideline.procedureType === procedureType,
  );

  return targetGuidelines;
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Guidelines)');
  }
  if (type === NAVI) {
    const guideline = new GuidelinesCreate(data);
    response = await createNaviGuidelinesData(guideline);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Guidelines)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Guidelines', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Guidelines)');
  }
  if (type === NAVI) {
    const guideline = new GuidelinesCreate(data);
    response = await updateNaviGuidelinesData(guideline);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Guidelines)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Guidelines', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Guidelines)');
  }
  if (type === NAVI) {
    response = await deleteNaviGuidelinesData(data.id);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Guidelines)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Guidelines', response);
  }
  return response;
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
