import axios from "axios";

import { API_BASE_URL } from "../../config/envConstants.js";

export const createApplicantData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/applicant`,
    body, { headers: headers }
  );

};


export const createData = async (body, url) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(url,
    body, { headers: headers }
  );

};

export const fetchApplicantData = async () => {
  return await axios.get(
    `${API_BASE_URL}/api/v1/management/applicant`,
  );
};

export const createUrl = (type, endpoint) => {
  if (type) {
    return `${API_BASE_URL}/api/v1/${type}/${endpoint}`
  }
  return `${API_BASE_URL}/api/v1/${endpoint}`
}

export const updateApplicantData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/applicant/${id}`,
    body, { headers: headers }
  );
};
export const deleteApplicantData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/management/applicant/${id}`, {
      headers: headers,
    },);
};