import { useEffect, useState } from 'react';

import { get as getGeneralCategories } from '../../../../domain/Category/services/PropertyCategory.js';
import {
  get as getNotices,
  findByService as getServiceNotices,
} from '../../../../domain/News/services/Notices.js';
import { get as getAllowPropertyIds } from '../../../../domain/Property/services/Property.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { NewsContext } from '../entities/NewsContext.js';
const NewsProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [generalCategories, setGeneralCategories] = useState([]);
  const [notices, setNotices] = useState([]);
  const [allowPropertyIds, setAllowPropertyIds] = useState([]);

  if (type === SERVICE) {
    useEffect(() => {
      getServiceNotices(type).then((result) => {
        result = result.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
        setNotices(result);
      });
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      getGeneralCategories(type).then((result) => {
        setGeneralCategories(result);
      });
    }, []);
    useEffect(() => {
      getNotices(type).then((result) => {
        setNotices(result);
      });
    }, []);
    useEffect(() => {
      getAllowPropertyIds(type).then((result) => {
        setAllowPropertyIds(result);
      });
    }, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getNotices(type).then((result) => {
        setNotices(result);
      });
    }, []);
  }

  return (
    <NewsContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        generalCategories,
        notices,
        allowPropertyIds,
      }}>
      {children}
    </NewsContext.Provider>
  );
};

export default NewsProvider;
