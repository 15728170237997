import {
  APPLY_BTN,
  APPLY_DEADLINE_BTN,
  APPLY_FIN_BTN,
  BIDDING_BTN,
  CANCEL_APPLY,
  DATA_EMPTY,
  END_BIDDING_BTN,
  FINAL_APPLY,
  TEMPORARY_APPLY,
  TEMPORARY_APPLY_FIN_BTN,
} from 'domain/Property/entities/constants/property';

import AuthModal from 'interfaces/components//common/Header/HeaderTop/AuthModal';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useModalControl from 'shared/hooks/useModalControl';
import { LOGIN } from 'shared/utils/helper/authState';

import { AuctionBtn } from './AuctionBtn';

//import { AuthContext } from 'shared/context/AuthContext';

import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import E_BiddingDialog from '../e_BiddingDialog';

export const AuctionBtnBranch = ({ data, isContainBlockList }) => {
  const { isOpen, handleOpen, handleClose } = useModalControl();
  const [currentAuthStatus, setCurrentAuthStatus] = useState(null);
  //const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const { localStorageUserId } = useLocalStorage;

  const isLoggedIn = () => {
    return localStorageUserId !== null && localStorageUserId !== undefined;
  };

  const handleClick = () => {
    if (
      !isLoggedIn ||
      data?.status === 'DURING_BIDDING' ||
      data?.status === 'ADDITIONAL_DURING_BIDDING'
    ) {
      handleOpen();
    }
    if (isLoggedIn && data?.status === 'APPLYING') {
      navigate(`/items/${data?.id}/application`, { state: { data: data } });
    }
  };

  const switchAuthStatus = (authStatus) => {
    setCurrentAuthStatus(authStatus);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      setCurrentAuthStatus(LOGIN);
    }
  }, [data, localStorageUserId]);

  if (data?.status === 'APPLYING') {
    if (
      data?.aplStatus === TEMPORARY_APPLY ||
      data?.aplStatus === FINAL_APPLY
    ) {
      return <AuctionBtn label={APPLY_FIN_BTN} disabled={true} />;
    }
    if (isContainBlockList) {
      return (
        <AuctionBtn label={APPLY_BTN} disabled={true} onClick={handleClick} />
      );
    }
    return (
      <>
        <AuctionBtn label={APPLY_BTN} disabled={false} onClick={handleClick} />
        <AuthModal
          isOpen={isOpen}
          handleClose={handleClose}
          currentAuthStatus={currentAuthStatus}
          switchAuthStatus={switchAuthStatus}
        />
      </>
    );
  }
  if (data?.status === 'WAIT_START_BID') {
    if (
      (isLoggedIn && data?.aplStatus === TEMPORARY_APPLY) ||
      data?.aplStatus === FINAL_APPLY
    ) {
      return <AuctionBtn label={TEMPORARY_APPLY_FIN_BTN} disabled={true} />;
    }
    return <AuctionBtn label={APPLY_DEADLINE_BTN} disabled={true} />;
  }
  if (data?.status === 'DURING_BIDDING') {
    // ログインしていない場合
    if (!isLoggedIn) {
      return (
        <>
          <AuctionBtn
            label={BIDDING_BTN}
            disabled={false}
            onClick={handleClick}
          />
          <AuthModal
            isOpen={isOpen}
            handleClose={handleClose}
            currentAuthStatus={currentAuthStatus}
            switchAuthStatus={switchAuthStatus}
          />
        </>
      );
    }

    if (data?.publicSaleType === 'AUCTION') {
      if (data?.aplStatus === FINAL_APPLY) {
        return (
          <>
            <AuctionBtn
              label={BIDDING_BTN}
              disabled={false}
              onClick={handleClick}
            />
            <E_BiddingDialog
              isOpen={isOpen}
              handleClose={handleClose}
              bidMin={100}
            />
          </>
        );
      }
    } else {
      if (data?.myBidPrice === undefined || data?.myBidPrice === DATA_EMPTY) {
        if (data?.aplStatus === FINAL_APPLY) {
          return (
            <>
              <AuctionBtn
                label={BIDDING_BTN}
                disabled={false}
                onClick={handleClick}
              />
              <E_BiddingDialog
                isOpen={isOpen}
                handleClose={handleClose}
                bidMin={100}
              />
            </>
          );
        }
        return <AuctionBtn label={BIDDING_BTN} disabled={true} />;
      }
      return <AuctionBtn label={'入札済み'} disabled={true} />;
    }
  }

  if (data?.status === 'ADDITIONAL_DURING_BIDDING') {
    if (!isLoggedIn) {
      return (
        <>
          <AuctionBtn
            label={BIDDING_BTN}
            disabled={false}
            onClick={handleClick}
          />
          <AuthModal
            isOpen={isOpen}
            handleClose={handleClose}
            currentAuthStatus={currentAuthStatus}
            switchAuthStatus={switchAuthStatus}
          />
        </>
      );
    }
    if (data?.aplStatus === CANCEL_APPLY || data?.aplStatus === DATA_EMPTY) {
      return <AuctionBtn label={END_BIDDING_BTN} disabled={true} />;
    }
    if (data?.aplStatus === undefined || data?.aplStatus === TEMPORARY_APPLY) {
      return <AuctionBtn label={BIDDING_BTN} disabled={true} />;
    }
    if (
      data?.myAdditionalPrice === undefined ||
      data?.myAdditionalPrice === DATA_EMPTY
    ) {
      //未追加入札
      if (data?.publicSaleType === 'BID') {
        return (
          <>
            <AuctionBtn
              label={BIDDING_BTN}
              disabled={false}
              onClick={handleClick}
            />
            <E_BiddingDialog
              isOpen={isOpen}
              handleClose={handleClose}
              bidMin={100}
            />
          </>
        );
      } else {
        return '';
      }
    }
    return <AuctionBtn label={'入札済み'} disabled={true} />;
  }
  if (data?.status === 'WAIT_OPEN_BID' || data?.status === 'END_BIDDING') {
    return <AuctionBtn label={END_BIDDING_BTN} disabled={true} />;
  }
};
