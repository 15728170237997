// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.caution-manual-marked-list {
  font-size: 15px
}

.caution-manual-marked-list-title {
  font-weight: 700;
  color: #ff5252;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/caution-manual-marked-list.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".caution-manual-marked-list {\n  font-size: 15px\n}\n\n.caution-manual-marked-list-title {\n  font-weight: 700;\n  color: #ff5252;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
