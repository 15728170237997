import pino from 'pino';

import { BASE_URL_NAVIGATION } from '../../../config/envConstants.js';
import { fetchData } from '../../../shared/utils/helper/fetchData.js';

const logger = pino();
const CURRENT_GOVERNMENT_TOP_PAGES = () =>
  `${BASE_URL_NAVIGATION}/storage/current/government_top_pages/data.json`;
const PREVIOUS_GOVERNMENT_TOP_PAGES = () =>
  `${BASE_URL_NAVIGATION}/storage/previous/government_top_pages/data.json`;

export const fetchGovernmentTopPagesStorage = async () => {
  try {
    const response = await fetchGovernmentTopPages(
      CURRENT_GOVERNMENT_TOP_PAGES(),
    );
    return extractGovernmentTopPages(response);
  } catch (error) {
    logger.warn(
      { error },
      '現在の通知（ government_top_pages ）を取得できませんでした',
    );

    try {
      const response = await fetchGovernmentTopPages(
        PREVIOUS_GOVERNMENT_TOP_PAGES(),
      );
      return extractGovernmentTopPages(response);
    } catch (error) {
      logger.error(
        { error },
        '以前の通知（ government_top_pages ）の取得にも失敗しました',
      );
      return [];
    }
  }
};

export const fetchGovernmentTopPages = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
};

export const extractGovernmentTopPages = (response) => {
  return response.map((object) => object);
};
