import styled from 'styled-components';

import HelpBanner from '../../../../../../assets/image/help-banner.jpeg';
import URL_LINKS from '../../../../../../pages/Dashboard/entities/externalLinks.js';
import TopicCard from '../../../../../common/TopicCard.jsx';

import { TextCaption } from 'interfaces/components/common/TextCaption/index';

const Wrapper = styled.div``;

const BannerImage = styled.img`
  max-width: 100%;
`;

const HelpList = styled.ul``;

const HelpListItem = styled.li`
  text-indent: -0.5em;
  margin-left: 0.5em;
  line-height: 1.4em;
  margin-bottom: 4px;
`;

const HelpLink = styled.a`
  &:before {
    position: relative;
    display: inline;
    content: '-';
    margin-right: 4px;
  }
`;

const HelpTopic = () => {
  return (
    <Wrapper data-testid="help-topic">
      <TopicCard
        title="ヘルプ"
        body={
          <TextCaption>
            <div>
              <a
                href="https://www.pages.kankocho.jp/guide"
                target="_blank"
                rel="noopener noreferrer">
                <BannerImage
                  src={HelpBanner}
                  alt="初めての方へ KSI官公庁オークションとは? 詳しくはこちら"
                  data-testid="help-banner"
                />
              </a>
              <HelpList>
                <HelpListItem>
                  <HelpLink
                    href={URL_LINKS.KNOWLEDGE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="knowledge-link">
                    ヘルプ
                  </HelpLink>
                </HelpListItem>
                <HelpListItem>
                  <HelpLink
                    href={URL_LINKS.HOW_TO_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="how-to-link">
                    KSI官公庁オークションの流れ
                  </HelpLink>
                </HelpListItem>
                <HelpListItem>
                  <HelpLink
                    href={URL_LINKS.HOW_TO_PARTICIPATE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="how-to-participate-link">
                    参加申し込みの方法
                  </HelpLink>
                </HelpListItem>
                <HelpListItem>
                  <HelpLink
                    href={URL_LINKS.GLOSSARY_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="glossary-link">
                    用語集
                  </HelpLink>
                </HelpListItem>
                <HelpListItem>
                  <HelpLink
                    href={URL_LINKS.GOV_AGENCY_GUIDLINE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="gov-agency-guideline-link">
                    ガイドライン
                  </HelpLink>
                </HelpListItem>
                <HelpListItem>
                  <HelpLink
                    href={URL_LINKS.HOW_TO_USE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="how-to-use-link">
                    KSI官公庁オークション利用についてのお問い合わせ
                  </HelpLink>
                </HelpListItem>
              </HelpList>
            </div>
            <div>※物件については直接各行政機関へお問い合わせください</div>
          </TextCaption>
        }
      />
    </Wrapper>
  );
};

export default HelpTopic;
