// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.published-object-status .property-list-table .th-order {
    width: 4%;
}

.published-object-status .property-list-table .th-segment-num {
    width: 8%;
}

.published-object-status .property-list-table .th-title {
    width: 15.5%;
}

.published-object-status .property-list-table .th-estimated-price,
.published-object-status .property-list-table .th-deposit,
.published-object-status .property-list-table .th-contract-price,
.published-object-status .property-list-table .th-bank-transfers,
.published-object-status .property-list-table .th-credit_card {
    width: 10%;
}

.published-object-status .property-list-table .th-my-list-entries {
    width: 6.5%;
}

.published-object-status .property-list-table .th-bidders {
    width: 6%;
}

.published-object-status .property-list-table .th-executive-committee {
    width: 9%;
}

.published-object-status .property-list-table td.text-right {
    text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PublishedObjectStatus/property-list-table.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;;;;;IAKI,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".published-object-status .property-list-table .th-order {\n    width: 4%;\n}\n\n.published-object-status .property-list-table .th-segment-num {\n    width: 8%;\n}\n\n.published-object-status .property-list-table .th-title {\n    width: 15.5%;\n}\n\n.published-object-status .property-list-table .th-estimated-price,\n.published-object-status .property-list-table .th-deposit,\n.published-object-status .property-list-table .th-contract-price,\n.published-object-status .property-list-table .th-bank-transfers,\n.published-object-status .property-list-table .th-credit_card {\n    width: 10%;\n}\n\n.published-object-status .property-list-table .th-my-list-entries {\n    width: 6.5%;\n}\n\n.published-object-status .property-list-table .th-bidders {\n    width: 6%;\n}\n\n.published-object-status .property-list-table .th-executive-committee {\n    width: 9%;\n}\n\n.published-object-status .property-list-table td.text-right {\n    text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
