// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navi-user-management-table-wrap {
    padding-top: 16px;
}

.navi-user-management .navi-user-management-table {
    background-color: #fff;
    color: rgba(0,0,0,.87);
    line-height: 1.5;
    max-width: 100%;
}

.navi-user-management .navi-user-management-table-inner {
    overflow-x: auto;
    overflow-y: hidden;
}

.navi-user-management .navi-user-management-table-inner table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #ccc;
}

.navi-user-management .navi-user-management-table-inner > table > thead > tr > th {
    background-color: #fbe2ca;
    border: 1px solid #ccc !important;
    font-size: .75rem;
    height: 32px;
    text-aligh: center!important;
    -webkit-user-select: none;
            user-select: none;
    color: rgba(0, 0, 0, .6);
}

.navi-user-management .navi-user-management-table-inner > table > tbody > tr > td {
    border: 1px solid #ccc !important;
    -webkit-user-select: none;
            user-select: none;
    font-size: .875rem;
    padding: 0 16px;
}

.navi-user-management .navi-user-management-table-inner .action-button {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/NaviUserManagement/navi-user-management-table.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,iCAAiC;IACjC,iBAAiB;IACjB,YAAY;IACZ,4BAA4B;IAC5B,yBAAiB;YAAjB,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,iCAAiC;IACjC,yBAAiB;YAAjB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".navi-user-management-table-wrap {\n    padding-top: 16px;\n}\n\n.navi-user-management .navi-user-management-table {\n    background-color: #fff;\n    color: rgba(0,0,0,.87);\n    line-height: 1.5;\n    max-width: 100%;\n}\n\n.navi-user-management .navi-user-management-table-inner {\n    overflow-x: auto;\n    overflow-y: hidden;\n}\n\n.navi-user-management .navi-user-management-table-inner table {\n    width: 100%;\n    border-spacing: 0;\n    border: 1px solid #ccc;\n}\n\n.navi-user-management .navi-user-management-table-inner > table > thead > tr > th {\n    background-color: #fbe2ca;\n    border: 1px solid #ccc !important;\n    font-size: .75rem;\n    height: 32px;\n    text-aligh: center!important;\n    user-select: none;\n    color: rgba(0, 0, 0, .6);\n}\n\n.navi-user-management .navi-user-management-table-inner > table > tbody > tr > td {\n    border: 1px solid #ccc !important;\n    user-select: none;\n    font-size: .875rem;\n    padding: 0 16px;\n}\n\n.navi-user-management .navi-user-management-table-inner .action-button {\n    padding-top: 10px;\n    padding-bottom: 10px;\n    font-size: 18px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
