import { findById as getInvitation } from 'domain/Auction/services/Invitation';
import { getSelectedCategories as getCategories } from 'domain/Category/services/PropertyCategory.js';
import { findByGovernmentId as getDivisions } from 'domain/Division/services/Division';
import { findById as getCarProperty } from 'domain/Property/services/CarProperty';
import { get as getCarProperties } from 'domain/Property/services/CarProperty.js';
import { findByPropertyId as getPaymentMethods } from 'domain/Property/services/PaymentMethod';
import { findById as getProperty } from 'domain/Property/services/Property';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { SERVICE, NAVI, KMANAGER } from 'shared/utils/helper/constants.js';

import { breadcrumbs } from '../entities/breadcrumbs.js';
import { PropertyInfoCarContext } from '../entities/PropertyInfoCarContext.js';

const PropertyInfoCarProvider = ({ type, children }) => {
  const [carProperty, setCarProperty] = useState(undefined);
  const [invitation, setInvitation] = useState([]);
  const [property, setProperty] = useState(undefined);
  const { auction_id, property_id } = useParams();
  const { localStorageGovernmentId } = useLocalStorage();
  const [divisions, setDivisions] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getCarProperties(type).then((result) => {
        setCarProperty(result);
      });
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      getInvitation(type, auction_id, localStorageGovernmentId).then(
        (result) => {
          setInvitation(result);
        },
      );
      if (property_id !== undefined) {
        getProperty(type, property_id).then((result) => {
          setProperty(result);
          getCategories(type, result.categoryId).then((categories) => {
            setSelectedCategory(categories);
          });
        });
        getCarProperty(type, property_id).then((result) => {
          setCarProperty(result);
        });
        getPaymentMethods(type, property_id).then((paymentMethods) => {
          setPaymentMethods(paymentMethods);
        });
      }
      getDivisions(type, localStorageGovernmentId).then((divisions) => {
        let selectDivisions = [];
        divisions.forEach((division) => {
          let tmpDivision = {};
          tmpDivision.id = division.id;
          tmpDivision.text = division.name;
          tmpDivision.value = division.id;
          selectDivisions.push(tmpDivision);
        });
        setDivisions(selectDivisions);
      });
    }, [localStorageGovernmentId]);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getCarProperties(type).then((result) => {
        setCarProperty(result);
      });
    }, []);
  }

  return (
    <PropertyInfoCarContext.Provider
      value={{
        breadcrumbs,
        carProperty,
        invitation,
        property,
        divisions,
        paymentMethods,
        selectedCategory,
      }}>
      {children}
    </PropertyInfoCarContext.Provider>
  );
};

export default PropertyInfoCarProvider;
