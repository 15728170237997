const Button = ({
  text,
  height = '36px',
  width,
  isOutline = false,
  onClick,
  className,
  type,
  isDisabled,
  form,
  isDelete = false,
}) => {
  const buttonStatus = () => {
    if (isDisabled) {
      return `disable-button`;
    }
    if (isDelete) {
      return `delete-button`;
    }
    if (isOutline) {
      return `${className} common-button-outline`;
    }
    return `${className} common-button`;
  };

  return (
    <button
      className={buttonStatus()}
      style={{ height: height, width: width }}
      onClick={onClick}
      type={type}
      form={form}
      disabled={isDisabled}
      data-testid={buttonStatus()}>
      {text}
    </button>
  );
};

export default Button;
