export const select_box = [
  { id: 1, value: 'AGENCY', label: '代理契約' },
  { id: 2, value: 'GUARANTEE', label: '保証契約' },
];

export const deposit_handing = [
  { id: 1, value: 'AGENCY', label: '代理契約' },
  { id: 2, value: 'GUARANTEE', label: '保証契約' },
];

export const government_regions = [
  { id: 9999, value: '9999', label: '地域を選択してください', hidden: true },
  { id: 1, value: '1', label: '北海道' },
  { id: 2, value: '2', label: '東北' },
  { id: 3, value: '3', label: '関東' },
  { id: 4, value: '4', label: '信越・北陸' },
  { id: 5, value: '5', label: '東海' },
  { id: 6, value: '6', label: '近畿' },
  { id: 7, value: '7', label: '中国' },
  { id: 8, value: '8', label: '四国' },
  { id: 9, value: '9', label: '九州・沖縄' },
];

export const government_locations = [
  {
    id: 9999,
    value: '9999',
    label: '都道府県を選択してください',
    region_id: '9999',
    hidden: true,
  },
  { id: 1, value: '1', label: '北海道', region_id: '1' },
  { id: 2, value: '2', label: '青森県', region_id: '2' },
  { id: 3, value: '3', label: '岩手県', region_id: '2' },
  { id: 4, value: '4', label: '宮城県', region_id: '2' },
  { id: 5, value: '5', label: '秋田県', region_id: '2' },
  { id: 6, value: '6', label: '山形県', region_id: '2' },
  { id: 7, value: '7', label: '福島県', region_id: '2' },
  { id: 8, value: '8', label: '茨城県', region_id: '3' },
  { id: 9, value: '9', label: '栃木県', region_id: '3' },
  { id: 10, value: '10', label: '群馬県', region_id: '3' },
  { id: 11, value: '11', label: '埼玉県', region_id: '3' },
  { id: 12, value: '12', label: '千葉県', region_id: '3' },
  { id: 13, value: '13', label: '東京都', region_id: '3' },
  { id: 14, value: '14', label: '神奈県川', region_id: '3' },
  { id: 15, value: '15', label: '新潟県', region_id: '4' },
  { id: 16, value: '16', label: '富山県', region_id: '4' },
  { id: 17, value: '17', label: '石川県', region_id: '4' },
  { id: 18, value: '18', label: '福井県', region_id: '4' },
  { id: 19, value: '19', label: '山梨県', region_id: '4' },
  { id: 20, value: '20', label: '長野県', region_id: '4' },
  { id: 21, value: '21', label: '岐阜県', region_id: '5' },
  { id: 22, value: '22', label: '静岡県', region_id: '5' },
  { id: 23, value: '23', label: '愛知県', region_id: '5' },
  { id: 24, value: '24', label: '三重県', region_id: '5' },
  { id: 25, value: '25', label: '滋賀県', region_id: '6' },
  { id: 26, value: '26', label: '京都府', region_id: '6' },
  { id: 27, value: '27', label: '大阪府', region_id: '6' },
  { id: 28, value: '28', label: '兵庫県', region_id: '6' },
  { id: 29, value: '29', label: '奈良県', region_id: '6' },
  { id: 30, value: '30', label: '和歌県山', region_id: '6' },
  { id: 31, value: '31', label: '鳥取県', region_id: '7' },
  { id: 32, value: '32', label: '島根県', region_id: '7' },
  { id: 33, value: '33', label: '岡山県', region_id: '7' },
  { id: 34, value: '34', label: '広島県', region_id: '7' },
  { id: 35, value: '35', label: '山口県', region_id: '7' },
  { id: 36, value: '36', label: '徳島県', region_id: '8' },
  { id: 37, value: '37', label: '香川県', region_id: '8' },
  { id: 38, value: '38', label: '愛媛県', region_id: '8' },
  { id: 39, value: '39', label: '高知県', region_id: '8' },
  { id: 40, value: '40', label: '福岡県', region_id: '9' },
  { id: 41, value: '41', label: '佐賀県', region_id: '9' },
  { id: 42, value: '42', label: '長崎県', region_id: '9' },
  { id: 43, value: '43', label: '熊本県', region_id: '9' },
  { id: 44, value: '44', label: '大分県', region_id: '9' },
  { id: 45, value: '45', label: '宮崎県', region_id: '9' },
  { id: 46, value: '46', label: '鹿児県島', region_id: '9' },
  { id: 47, value: '47', label: '沖縄県', region_id: '9' },
];

// TODO: サブカテゴリIDも更新する
export const get_locations = (region_id) => {
  return government_locations.filter(
    (location) => location.region_id === region_id.toString(),
  );
};

export const get_regions = (category_id) => {
  return government_regions.filter((region) => region.id === category_id);
};

export const get_location_label = (location_id) => {
  return government_locations.filter(
    (location) => location.id.toString() === location_id.toString(),
  )[0];
};

export const get_region_label = (region_id) => {
  return government_regions.filter(
    (region) => region.id.toString() === region_id.toString(),
  )[0];
};

export const get_region_id = (region) => {
  return government_regions.find((data) => data.label === region)?.id;
};
export const get_location_id = (prefecture) => {
  return government_locations.find((data) => data.label === prefecture)?.id;
};
