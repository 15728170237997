import Heading3 from 'interfaces/components/common/Heading/Heading3';
import CautionTopicTable from '../CautionTopicTable.jsx';
import ManualMarkedList from '../ManualMarkedList.jsx';
import 'interfaces/css/common/caution-topic-record.css';

const deliveryConditions = [
  {
    mark: '・',
    text: '売却物件は、落札者が売払代金を納付した時点の状況（現況有姿）で引き渡します。',
  },
  {
    mark: '・',
    text: '落札者が法人の場合、物件情報詳細ページなどの記載内容と実地に符合しない事項が売却物件にあることを発見しても、それを理由として契約の締結を拒んだり、落札の無効を主張したり、売払代金の減額を請求することはできません。',
  },
  {
    mark: '※',
    text: '法人には、事業としてまたは事業のために契約当事者となる個人も含みます。',
  },
  {
    mark: '・',
    text: '自動車の場合、落札者は、「使用の本拠の位置」を管轄する運輸支局または自動車検査登録事務所に当該自動車を持ち込んでいただくことが必要です。また、譲渡証明書に記載する譲受人の名義は、落札者本人となります。落札者本人以外の名義にはできません。',
  },
]

const handlingOfContractDeposit = [
  {
    mark: '・',
    text: '売買契約締結後、入札保証金を契約保証金に充当する（契約保証金の納付に代える）ことができます。',
  },
  {
    mark: '・',
    text: '契約保証金は、物件情報詳細ページ等に記載された売払代金納付期限までに、実施行政機関が売払代金の納付を確認できない場合、没収になります。',
  },
]

const importantMatterTableData = [
  {
    headline: '危険負担',
    content: '契約締結から売却物件引き渡しまでの間に、当該物件が行政機関の責に帰すことのできない事由により滅失または、き損した場合には、行政機関に対して売払代金の減免を請求することはできません。',
  },
  {
    headline: '引き渡し条件',
    content: <ManualMarkedList data={deliveryConditions}/>
  },
  {
    headline: '入札保証金の取り扱い',
    content: '落札者は物件情報詳細ページなどに記載された契約締結期限までに、実施行政機関と売却物件の売買契約を締結しない場合、参加申し込み時に納付した入札保証金は没収になります。',
  },
  {
    headline: '契約保証金の取り扱い',
    content: <ManualMarkedList data={handlingOfContractDeposit}/>
  },
  {
    headline: '使用用途の制限',
    content: '落札者は、契約締結の日から5年を経過するまでの間、当該物件を「風俗営業等の規制及び業務の適正化等に関する法律」第2条第1項に規定する風俗営業、同条第5項に規定する性風俗関連特殊営業その他これらに類する業、または、「暴力団員による不当な行為の防止等に関する法律」第2条第2項に規定する暴力団の事務所の用に使用することはできません。',
  },
  {
    headline: '移転登記前の使用制限',
    content: '落札者は、売却物件の所有権移転登記完了前に、売却物件に地上権や貸借権などの使用・収益を目的とする権利を設定したり、当該物件を売買などによる所有権の移転をすることはできません。',
  },
]

const contactTableData = [
  {
    headline: 'お問い合わせ先',
    content: '●●市●●部●●課',
    isBgGray: true,
  },
  {
    headline: 'メールアドレス',
    content: 'xxx@xxx.lg.jp',
    isBgGray: true,
  },
  {
    headline: '電話番号',
    content: '03-3333-5555（内線：1234）',
    isBgGray: true,
  },
  {
    headline: '電話受付時間',
    content: '平日 9時から17時',
    isBgGray: true,
  },
]

const ImportantMatterAssetSaleSection = () => {
  return (
    <div data-testid="important-matter-section" className="pt-16">
      <Heading3 text="重要事項" isLeftBorderStyle={true}/>
      <p className="sample-after-winning-bid-lead-text pt-2 pb-3" data-testid="sample-after-winning-bid-lead-text">落札後の権利移転手続きにおける重要な事項です。必ずご確認ください。</p>
      <CautionTopicTable recordData={importantMatterTableData}/>
      <CautionTopicTable recordData={contactTableData} className="mt-16" caption="落札後の注意事項に関するお問い合わせ" hasCaptionBgColor={true}/>
    </div>
  );
};

export default ImportantMatterAssetSaleSection;