import { useState } from 'react';

import Header from './Header';
import LeftSideMenu from './LeftSideMenu';
import RightSideDrawerMenu from './RightSideDrawerMenu';
import SnackBarComponent from './SnackBarComponent';

const CommonUI = ({ handlerLeftMenu, isLeftMenuOpen }) => {
  const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
  return (
    <>
      <SnackBarComponent />
      <Header
        leftMenuHandler={handlerLeftMenu}
        rightMenuHandler={() => setIsRightMenuOpen((prevState) => !prevState)}
      />
      <LeftSideMenu isOpen={isLeftMenuOpen} />
      <RightSideDrawerMenu
        isOpen={isRightMenuOpen}
        handleClose={() => setIsRightMenuOpen(false)}
      />
    </>
  );
};

export default CommonUI;
