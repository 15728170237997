
export class PreviewHpName {
    #previewHpName

    constructor(previewHpName) {
        this.#previewHpName = previewHpName;
    }

    get() {
        return this.#previewHpName
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
