import { useState } from 'react';
//import { logInput } from "../../utils/log/logInputs";
import * as Validation from "shared/utils/helper/managerValidators";

export const Category = () => {

  const [categoryNameValid, setCategoryNameValid] = useState(null);

  function handleNameValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateRangeCategoryNameLength(e.target.value);
    if (invalid !== 'TRUE') {
      setCategoryNameValid(invalid);
      return
    }
    invalid = Validation.validateIncludeEmojiCategoryName(e.target.value);
    if (invalid !== 'TRUE') {
      setCategoryNameValid(invalid);
      return
    }
    setCategoryNameValid(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      name: handleNameValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      name: categoryNameValid,
    }
  }

  return validations;
}
