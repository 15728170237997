import { validateRangeLength } from "../../../../application/validations.js";

export class InventoryLocation {
  #inventoryLocation

  constructor(inventoryLocation) {
    this.#inventoryLocation = inventoryLocation;
  }

  get() {
    return this.#inventoryLocation
  }

  get isValid() {
    return [
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#inventoryLocation, 1, 60)
  }
}
