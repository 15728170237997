
export class SummaryManufacture {
    #summaryManufacture

    constructor(summaryManufacture) {
        this.#summaryManufacture = summaryManufacture;
    }

    get() {
        return this.#summaryManufacture
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
