import PopUpIcon from './PopUpIcon.jsx';
import PopUpIconButton from './PopUpIconButton.jsx';
import { ReactComponent as LogoutIcon } from '../../../../../../assets/svg/logout_icon.svg';
import { ReactComponent as MyListIcon } from '../../../../../../assets/svg/my_list_icon.svg';
import { ReactComponent as MyPageIcon } from '../../../../../../assets/svg/my_page_icon.svg';

const PopUpBody = ({ handleClose }) => {
  function logout() {
    console.log('service logout');
    localStorage.removeItem("auctionUserId")
    localStorage.removeItem("procedureType")
    localStorage.removeItem("governmentId")
    localStorage.removeItem("governmentType")
    localStorage.removeItem('requestAccessToken');
    localStorage.removeItem('requestRefreshToken');

    window.location.reload();
  }

  return (
    <div className="popup-body" data-testid="popup-body">
      <PopUpIcon svg={<MyListIcon title="my-list"/>} label="マイリスト" link="/mylist" handleClose={handleClose}/>
      <PopUpIcon svg={<MyPageIcon title="my-page"/>} label="マイページ" link="/mypage" handleClose={handleClose}/>
      <PopUpIconButton svg={<LogoutIcon title="logout-icon-button"/>} label="ログアウト" handleClick={logout}/>
    </div>
  );
};

export default PopUpBody;
