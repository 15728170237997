import LocalGovernmentManagementEmblemProvider from './LocalGovernmentManagementEmblemProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { localGovernmentManagementEmblem } from "../entities/componentMapping.js";

const LocalGovernmentManagementEmblem = ({ type }) => {
  return (
    <LocalGovernmentManagementEmblemProvider type={type}>
      {getComponent(localGovernmentManagementEmblem, type)}
    </LocalGovernmentManagementEmblemProvider>
  );
};

export default LocalGovernmentManagementEmblem;
