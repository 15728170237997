// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navi .login {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 69px 0;
}

.navi .login .logo-wrap {
  display: flex;
  justify-content: center;
  vertical-align: bottom;
  margin-bottom: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Login/navi-login.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".navi .login {\n  max-width: 900px;\n  width: 100%;\n  margin: 0 auto;\n  padding: 69px 0;\n}\n\n.navi .login .logo-wrap {\n  display: flex;\n  justify-content: center;\n  vertical-align: bottom;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
