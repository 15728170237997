import { useState } from "react";

export function useGovernmentUserInputs() {
  const [familyName, setFamilyName] = useState("");
  const [familyNameKana, setFamilyNameKana] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameKana, setFirstNameKana] = useState("");
  const [department, setDepartment] = useState("");
  const [officePosition, setOfficePosition] = useState("");
  const [tel, setTel] = useState("");
  const [extension, setExtension] = useState("");
  const [email, setEmail] = useState("");

  const governmentUserInputs = {
    type: {
      familyName: 'text',
      familyNameKana: 'text',
      firstName: 'text',
      firstNameKana: 'text',
      department: 'text',
      officePosition: 'text',
      tel: 'text',
      extension: 'text',
      email: 'text',
    },
    data: {
      familyName,
      familyNameKana,
      firstName,
      firstNameKana,
      department,
      officePosition,
      tel,
      extension,
      email,
    },
    setters: {
      familyName: setFamilyName,
      familyNameKana: setFamilyNameKana,
      firstName: setFirstName,
      firstNameKana: setFirstNameKana,
      department: setDepartment,
      officePosition: setOfficePosition,
      tel: setTel,
      extension: setExtension,
      email: setEmail,
    }
  }

  return governmentUserInputs;
}