import { useContext, useEffect } from 'react';
import { PromotionContext } from 'interfaces/pages/Promotion/entities/PromotionContext';

import PageTitle from 'interfaces/components/common/PageTitle';
//import PromotionPreview from "./PromotionPreview";
//import { usePromotionFrames } from './hook/usePromotionFrames';
import PromotionEdit from './PromotionEdit';

//import Slider from "interfaces/components/common/Slider/Slider";
import 'interfaces/css/common/common-form.css';
import { useState } from 'react';
import ArticleDefaultBody from '../../common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import Slider from '../../common/Slider/Slider.jsx';

const Promotion = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const [displayPreviewProperties, setDisplayPreviewProperties] =
    useState(undefined);

  const { previewProperties, promotionAccordionControls } =
    useContext(PromotionContext);

  useEffect(() => {
    // プレビューに表示する対象を取得
    let control = promotionAccordionControls.find(
      (promotionAccordionControl) => {
        return promotionAccordionControl.isOpen === true;
      },
    );

    if (control) {
      let preview = previewProperties.find((previewProperty) => {
        return previewProperty.promotionFrameId === control.id;
      });
      console.log(preview);
      setDisplayPreviewProperties({
        theme: preview.theme,
        previewProperties: preview.previewProperties,
        previewPropertyIds: preview.previewProperties.map(
          (property) => property.id,
        ),
      });
    } else {
      setDisplayPreviewProperties(undefined);
    }
  }, [previewProperties, promotionAccordionControls]);


  return (
    <div className="kmanager-promotion" data-testid="kmanager-promotion">
      <PageTitle text="プロモーション設定ツール" />
      {/*
      <PromotionPreview
        contents={
          // アコーディオンが開いている状態で、開いているアコーディオンが追加されたPromotionではない場合
          (activeIndex !== 0 && result.promotionFrames.filter((item) => item.isNewPromotion !== 'TRUE').length >= activeIndex && properties.length > 0) &&
          <Slider
            properties={properties}
          />
        }
      />
      */}
      <div className="kmanager-promotion-edit box-shadow ma-2 pa-2">
        <div className="align-center">
          <h2 className="kmanager-page-title">プレビュー</h2>
          {displayPreviewProperties && (
            <>
              {displayPreviewProperties.theme}
              <ArticleDefaultBody>
                {
                  <Slider
                    properties={displayPreviewProperties.previewProperties}
                  />
                }
              </ArticleDefaultBody>
            </>
          )}
        </div>
      </div>
      <PromotionEdit
        //data={promotionFlames}
        //fetchPromotionFrames={result.fetchPromotionFrames}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </div>
  );
};

export default Promotion;
