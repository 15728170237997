import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class GovernmentId {
  #governmentId

  constructor(governmentId) {
    this.#governmentId = governmentId;
  }

  get() {
    return this.#governmentId
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#governmentId, 0, 50)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#governmentId)
  }
}
