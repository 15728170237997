// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nearly-term-row {
  height: 100px;
  display: flex;
  align-items: center;
  border-right: 1px solid var(--line-base);
  border-bottom: 1px solid var(--line-base);
}

.nearly-schedule-term {
  white-space: nowrap;
  overflow: visible;
  padding: 6px 10px;
  border-radius: 8px;
  position: absolute;
  background-color: var(--schedule-4);
}

.wait-start-bid-term {
  height: 84px;
  white-space: nowrap;
  overflow: visible;
  padding: 6px 10px;
  border-radius: 8px;
  position: absolute;
  background-color: var(--bgcolor-whitesmoke);
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/neary-schedule-row.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,wCAAwC;EACxC,yCAAyC;AAC3C;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,mCAAmC;AACrC;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,2CAA2C;EAC3C,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".nearly-term-row {\n  height: 100px;\n  display: flex;\n  align-items: center;\n  border-right: 1px solid var(--line-base);\n  border-bottom: 1px solid var(--line-base);\n}\n\n.nearly-schedule-term {\n  white-space: nowrap;\n  overflow: visible;\n  padding: 6px 10px;\n  border-radius: 8px;\n  position: absolute;\n  background-color: var(--schedule-4);\n}\n\n.wait-start-bid-term {\n  height: 84px;\n  white-space: nowrap;\n  overflow: visible;\n  padding: 6px 10px;\n  border-radius: 8px;\n  position: absolute;\n  background-color: var(--bgcolor-whitesmoke);\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
