// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 959px) {
  .yearly-schedules-inner {
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
    padding: 0 16px;
  }

  .yearly-schedules-inner .yearly-schedule-wrap {
    margin: 16px;
    padding-bottom: 40px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/YearlySchedules/yearly-schedules.css"],"names":[],"mappings":"AAAA;EACE;IACE,eAAe;IACf,cAAc;IACd,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,oBAAoB;EACtB;AACF","sourcesContent":["@media (max-width: 959px) {\n  .yearly-schedules-inner {\n    max-width: 100%;\n    margin: 0 auto;\n    width: 100%;\n    padding: 0 16px;\n  }\n\n  .yearly-schedules-inner .yearly-schedule-wrap {\n    margin: 16px;\n    padding-bottom: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
