import Button from '../Button.jsx';

const ClearButton = ({ inputs }) => {
  const Clear = () => {
    inputs.map((input) => {
      input.init('');
      input.setValidationMessage({ target: { value: '' } });
    });
  };

  return <Button text={'クリア'} clickHandler={Clear} className={'warning'} />;
};

export default ClearButton;
