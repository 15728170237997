import { ReactComponent as RadioMarked } from 'interfaces/assets/svg/radio_button_checked_icon.svg';
import { ReactComponent as RadioBlank } from 'interfaces/assets/svg/radio_button_unchecked_icon.svg';

const DeliveryStyleSelectRadio = ({
  style,
  deliveryStyle,
  setDeliveryStyle,
}) => {
  return (
    <label key={style.id} className="delivery-style-select-item">
      <div className="delivery-style-radio-icon">
        {deliveryStyle === style.value ? <RadioMarked /> : <RadioBlank />}
      </div>
      <input
        type="radio"
        name="radio"
        value={deliveryStyle}
        onClick={() => setDeliveryStyle(style.value)}
      />
      <div>
        <p className="delivery-style-main-label">{style.mainLabel}</p>
        <p>{style.subLabel}</p>
      </div>
    </label>
  );
};

export default DeliveryStyleSelectRadio;
