import { useEffect, useState } from 'react';

import { switchInputClassName, switchInputMessage } from '../../../../../../shared/utils/helper/inputHelper.js';
import { styled } from 'styled-components';

const BoldLabel = styled.p`
  font-weight: bold;
`;

const AuthFormInput = ({ label, type, handleChange, validateMessage, caption, labelBold = false }) => {
  const [borderClassName, setBorderClassName] = useState('normal-border');
  const [isFocus, setIsFocus] = useState(false);
  const [message, setMessage] = useState(caption);
  const isVisibleMessage = validateMessage !== 'NO_MESSAGE';

  useEffect(() => {
    switchInputClassName(isFocus, validateMessage, setBorderClassName);
  }, [isFocus, validateMessage]);

  useEffect(() => {
    switchInputMessage(validateMessage, setMessage, caption, isVisibleMessage);
  }, [validateMessage]);

  return (
    <div className="auth-form-input-wrap" data-testid="auth-form-input-wrap">
      {labelBold ? <BoldLabel>{label}</BoldLabel> : <p className="auth-form-input-label" data-testid="auth-form-input-label">{label}</p>}
      <input
        className={`auth-form-input ${borderClassName}`}
        type={type}
        onChange={handleChange}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        data-testid="auth-form-input"
      />
      <div className={`auth-form-input-message ${validateMessage ? 'validate-message' : 'caption-message'}`}>
        {message}
      </div>
    </div>
  );
};

export default AuthFormInput;
