import { createContext } from 'react';

import { DATA_EMPTY } from '../../../../shared/utils/helper/constants.js';

export const ApplicantListContext = createContext({
  auction: undefined,
  responsive: DATA_EMPTY,
  managementMessages: DATA_EMPTY,
  governmentId: DATA_EMPTY,
  governmentType: DATA_EMPTY,
  procedureType: DATA_EMPTY,
  applicantList: DATA_EMPTY,
  proposal: DATA_EMPTY,
  property: DATA_EMPTY,
  tableData: DATA_EMPTY,
  governmentUser: undefined,
  setSearchParam: () => {},
});
