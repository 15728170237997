export const SelectBox = ({ listItemData, state, setState }) => {
  return (
    <div data-testid="select-box">
      <select value={state} data-testid="input-control" onChange={setState}>
        {listItemData &&
          listItemData.map((item, index) => {
            return (
              <option
                key={index}
                value={item.value}
                data-testid="list-item"
                hidden={item.hidden}>
                {item.label}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default SelectBox;
