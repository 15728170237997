import { validateSelected } from "../../../../application/validations.js";

export class BuildDate {
  #buildDate

  constructor(buildDate) {
    this.#buildDate = buildDate;
  }

  get() {
    return this.#buildDate
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#buildDate)
  }
}
