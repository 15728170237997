import { validateNumber } from "../../../../application/validations.js";

export class CertDocumentPostalCode {
  #certDocumentPostalCode

  constructor(certDocumentPostalCode) {
    this.#certDocumentPostalCode = certDocumentPostalCode;
  }

  get() {
    return this.#certDocumentPostalCode
  }

  get isValid() {
    return [
      this.validateNumber,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateNumber() {
    return validateNumber(this.#certDocumentPostalCode)
  }
}
