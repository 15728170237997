import Modal2 from 'interfaces/components/common/Modal/Modal2';
import { useEffect } from 'react';

import ResetPasswordModalContents from './ResetPasswordModalContents';
import CompleteModalContents from '../CompleteModalContents';

const ResetPasswordModal = ({
  isOpen,
  handleClose,
  modalType,
  setModalType,
}) => {
  useEffect(() => {
    setModalType('PASSWORD');
  }, [isOpen]);

  const targetChild = () => {
    if (modalType === 'PASSWORD') {
      return (
        <ResetPasswordModalContents
          handleClose={handleClose}
          setModalType={setModalType}
        />
      );
    }
    if (modalType === 'COMPLETE') {
      return (
        <CompleteModalContents
          title="パスワード変更完了"
          body="パスワードを変更しました。"
          buttonText="OK"
          handleClose={handleClose}
        />
      );
    }
  };

  return (
    <Modal2 isOpen={isOpen} handleClose={handleClose} modalSize="420px">
      {targetChild()}
    </Modal2>
  );
};

export default ResetPasswordModal;
