// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.management-data-list {
  box-shadow: 0 3px 3px -2px rgba(0,0,0,.2),0 3px 4px 0 rgba(0,0,0,.14),0 1px 8px 0 rgba(0,0,0,.12);
}

.management-data-list-column .management-data-list-item:nth-child(odd) {
    background-color: #e0e0e0;
  }

.management-data-list-column .management-data-list-item:nth-child(even) {
  background-color: #f5f5f5;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/management-data-list.css"],"names":[],"mappings":"AAAA;EACE,iGAAiG;AACnG;;AAEA;IACI,yBAAyB;EAC3B;;AAEF;EACE,yBAAyB;AAC3B","sourcesContent":[".management-data-list {\n  box-shadow: 0 3px 3px -2px rgba(0,0,0,.2),0 3px 4px 0 rgba(0,0,0,.14),0 1px 8px 0 rgba(0,0,0,.12);\n}\n\n.management-data-list-column .management-data-list-item:nth-child(odd) {\n    background-color: #e0e0e0;\n  }\n\n.management-data-list-column .management-data-list-item:nth-child(even) {\n  background-color: #f5f5f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
