
export class CarBidSystemFeeRate {
  #carBidSystemFeeRate

  constructor(carBidSystemFeeRate) {
    this.#carBidSystemFeeRate = carBidSystemFeeRate;
  }

  get() {
    return this.#carBidSystemFeeRate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
