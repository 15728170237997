import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import PrimaryButton from 'interfaces/components/common/Button/PrimaryButton';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import NaviUserManagementTable from 'interfaces/components/User/index/NaviUserManagementTable';
import { breadcrumbs } from 'interfaces/pages/User/entities/breadcrumbs';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../entities/UserContext';

import 'interfaces/css/NaviUserManagement/navi-user-management.css';

const NaviUser = () => {
  const { governmentUsers } = useContext(UserContext);
  const navigation = useNavigate();

  return (
    <div className="navi-user-management" data-testid="navi-user-management">
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <Heading2 title={'ナビユーザー管理'} />
      <div className="primary-button-wrap">
        <PrimaryButton onClick={() => navigation('/navi/user/new')}>
          新規作成
        </PrimaryButton>
      </div>
      <div className="attention-text-wrap">
        <p className="attention-text" data-testid="attention-text">
          <span className="d-table-row">
            <span className="d-table-cell">※</span>
            <span className="d-table-cell">
              管理者、親権限のナビユーザーIDは運営からの各種ご案内メール（自動送信含む）の受信が必須となります。子権限のナビユーザーIDはメールアドレスの登録と受信が任意となります。
            </span>
          </span>
        </p>
        <p className="attention-text" data-testid="attention-text">
          <span className="d-table-row">
            <span className="d-table-cell">※</span>
            <span className="d-table-cell">
              重要なご案内をメールすることがございますので、主担当に該当するご担当者様は親または管理者権限のナビユーザーIDをご利用ください。
            </span>
          </span>
        </p>
      </div>
      <NaviUserManagementTable tableData={governmentUsers} />
    </div>
  );
};

export default NaviUser;
