import { validateInputsAuctionForm } from 'interfaces/components/Auction/kmanager/common/validateInputsAuctionForm';
import PageTitle from 'interfaces/components/common/PageTitle';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AuctionEditContext } from '../../../../pages/AuctionEdit/entities/AuctionEditContext';
import AuctionConfirm from '../common/AuctionConfirm';
import AuctionForm from '../common/AuctionForm';

//import { createBody } from "../api/convertData";
//import { dummyEditHeader } from "../helper/test_helper/dummy_kmanager_auction_data";
//import { fetchAuctionData } from "../api/auction";
//import { camelToSnakeHyphen } from "../helper/conversion_helper/camelToSnake";

const AuctionEdit = () => {
  const { auctionId } = useParams();
  const [onConfirm, setOnConfirm] = useState(false);
  const { auction } = useContext(AuctionEditContext);
  const auctionInputs = validateInputsAuctionForm(auction);

  useEffect(() => {
    if (auction) {
      auctionInputs
        .find((object) => object.column === 'name')
        ?.init(auction?.name);
      auctionInputs
        .find((object) => object.column === 'aplStart')
        ?.init(auction?.aplStart);
      auctionInputs
        .find((object) => object.column === 'aplEnd')
        ?.init(auction?.aplEnd);
      auctionInputs
        .find((object) => object.column === 'govAplStart')
        ?.init(auction?.govAplStart);
      auctionInputs
        .find((object) => object.column === 'govAplEnd')
        ?.init(auction?.govAplEnd);
      auctionInputs
        .find((object) => object.column === 'auctionEnd')
        ?.init(auction?.auctionEnd);
      auctionInputs
        .find((object) => object.column === 'bidEnd')
        ?.init(auction?.bidEnd);
      auctionInputs
        .find((object) => object.column === 'bidStart')
        ?.init(auction?.bidStart);
      auctionInputs
        .find((object) => object.column === 'bidDecision')
        ?.init(auction?.bidDecision);
      auctionInputs
        .find((object) => object.column === 'openEnd')
        ?.init(auction?.openEnd);
      auctionInputs
        .find((object) => object.column === 'nextPaymentLimit')
        ?.init(auction?.nextPaymentLimit);
      auctionInputs
        .find((object) => object.column === 'depositType')
        ?.init(auction?.depositType);
      auctionInputs
        .find((object) => object.column === 'procedureType')
        ?.init(auction?.procedureType);
      auctionInputs
        .find((object) => object.column === 'governmentTypeGroup')
        ?.init(auction?.governmentTypeGroup);
      auctionInputs
        .find((object) => object.column === 'publicSaleType')
        ?.init(auction?.publicSaleType);
      if (auction?.additionalBidEnd !== undefined) {
        auctionInputs
          .find((object) => object.column === 'additionalBidEnd')
          ?.init(auction?.additionalBidEnd);
      }
    }
  }, [auctionId, auction]);

  return (
    <div className="kmanager-container" data-testid="kmanager-auction-edit">
      <div className="kmanager-row">
        <div className="kmanager-col-12">
          <PageTitle text="オークション編集" />
        </div>
        {onConfirm ? (
          <AuctionConfirm
            cancel={() => setOnConfirm(false)}
            type="edit"
            data={auctionInputs}
            id={auctionId}
          />
        ) : (
          <AuctionForm
            inputs={auctionInputs}
            toConfirm={() => setOnConfirm(true)}
          />
        )}
      </div>
    </div>
  );
};

export default AuctionEdit;
