// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import {ActiveStatus} from "./Banners/activeStatus";
import {CreatedAt} from "./Banners/createdAt";
import {CreatedUser} from "./Banners/createdUser";
import {DestinationUrl} from "./Banners/destinationUrl";
import {DisplayDeadline} from "./Banners/displayDeadline";
import {DisplayOrder} from "./Banners/displayOrder";
import {DisplayTarget} from "./Banners/displayTarget";
import {Id} from "./Banners/id";
import {Title} from "./Banners/title";
import {UpdatedAt} from "./Banners/updatedAt";
import {UpdatedUser} from "./Banners/updatedUser";
import CustomError from "../../../shared/utils/helper/customError.js";
import { NotUndefined } from "../../../shared/utils/helper/NotUndefined.js";

export class BannerGet {
  constructor(data) {
    this.id = data.id;
    this.title = data.title;
    this.destinationUrl = data.destinationUrl;
    this.displayDeadline = data.displayDeadline;
    this.displayTarget = data.displayTarget;
    this.displayOrder = data.displayOrder;
    this.activeStatus = data.activeStatus;
    this.createdUser = data.createdUser;
    this.createdAt = data.createdAt;
    this.updatedUser = data.updatedUser;
    this.updatedAt = data.updatedAt;
    //if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return NotUndefined(this)
  }
}

export class BannerCreate {

  constructor(data) {
    this.id = new Id(data.id);
    this.title = new Title(data.title);
    this.destinationUrl = new DestinationUrl(data.destinationUrl);
    this.displayDeadline = new DisplayDeadline(data.displayDeadline);
    this.displayTarget = new DisplayTarget(data.displayTarget);
    this.displayOrder = new DisplayOrder(data.displayOrder);
    this.activeStatus = new ActiveStatus(data.activeStatus);
    this.createdUser = new CreatedUser(data.createdUser);
    this.createdAt = new CreatedAt(data.createdAt);
    this.updatedUser = new UpdatedUser(data.updatedUser);
    this.updatedAt = new UpdatedAt(data.updatedAt);
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return this.title.isValid &&
      this.destinationUrl.isValid &&
      this.displayDeadline.isValid &&
      this.displayTarget.isValid &&
      this.displayOrder.isValid &&
      this.activeStatus.isValid &&
      this.createdUser.isValid &&
      this.createdAt.isValid &&
      this.updatedUser.isValid &&
      this.updatedAt.isValid
  }
}
