import {
  CAR,
  ESTATE,
  REAL_ESTATE,
} from 'domain/Property/entities/constants/property.js';

export const categoryJP = (input) => {
  if (input === REAL_ESTATE) {
    return '一般';
  }
  if (input === CAR) {
    return '自動車';
  }
  if (input === ESTATE) {
    return '不動産';
  }
};
