import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class FirstNameKana {
  #firstNameKana

  constructor(firstNameKana) {
    this.#firstNameKana = firstNameKana;
  }

  get() {
    return this.#firstNameKana
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#firstNameKana, 1, 64)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#firstNameKana)
  }
}
