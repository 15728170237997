import NoticeFormModal from 'interfaces/components/News/kmanager/NoticeFormModal';
import { useLocation } from 'react-router-dom';

import MessageFormModal from 'interfaces/components/Message/kmanager/MessageFormModal';
import ServiceProvidersFormModal from 'interfaces/components/Setting/kmanager/ServiceProvider/ServiceProvidersFormModal';
import UserFormModal from 'interfaces/components/User/kmanager/ManagementUserFormModal';

export function EditModal({ isOpen, closeModal, data, handler }) {
  let target = useLocation().pathname.replace('/kmanager/', '');
  if (target === 'notice') {
    <NoticeFormModal isOpen={isOpen} closeModal={closeModal} data={data} />;
  }
  if (target === 'settings') {
    return (
      <UserFormModal
        isOpen={isOpen}
        closeModal={closeModal}
        data={data}
        newId={data.id}
        type="edit"
      />
    );
  }
  if (target === 'service_provider') {
    return (
      <ServiceProvidersFormModal
        isOpen={isOpen}
        closeModal={closeModal}
        data={data}
        newId={data.id}
      />
    );
  }
  if (target === 'message') {
    return (
      <MessageFormModal
        isOpen={isOpen}
        closeModal={closeModal}
        registerData={data}
        newId={data.id}
        handler={handler}
      />
    );
  }
  return null;
}
