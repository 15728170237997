import axios from 'axios';

import {API_BASE_URL} from "../../config/envConstants.js";

export const fetchMessagesData = async () => {
  return await axios.get(
    `${API_BASE_URL}/api/v1/management/messages`,
  );
};
export const createMessagesData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/messages`,
    body, {headers: headers}
  );

};
export const updateMessagesData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/messages/${id}`,
    body, {headers: headers}
  );
};
export const deleteMessagesData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/management/messages/${id}`, {
      headers: headers,
    },);
};