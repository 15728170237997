import PropertyInfoFormCautionDescription from 'interfaces/components/common/PropertyInfoForm/PropertyInfoFormCautionDescription';
import 'interfaces/css/PropertyInfoEstate/property-info-estate-form-land-equity.css';

import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Label from '../../../Form/navi/Label';
import ValidationMessage from 'interfaces/components/Form/navi/ValidationMessage';

const FormArea = styled.div`
  display: flex;
  align-items: center;
`;

const TextInput = styled.input`
  font-size: 16px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  line-height: 20px;
  #padding: 8px 0;
  max-width: 100px;
  min-width: 0;
  width: 10em;
  background-color: rgb(255, 255, 255);
  border: ${(props) =>
    props.$validation
      ? '2px solid #ff5252 !important'
      : '1px solid rgba(0, 0, 0, .42)'};
  border-radius: 4px;
  padding: 8px 12px;
`;

const TextCaution = styled.div`
  width: 100%;
`;

const FormLandEquity = ({
  caution_description,
  dataLandShare, // {'first': '', 'latter': ''} の形式のデータ
  handlerLandShare,
  label,
  invalidMessage,
}) => {
  const [first, setFirst] = useState('');
  const [latter, setLatter] = useState('');

  const handleFirstChange = (e) => {
    setFirst(e.target.value);
    dataLandShare[e.target.name] = e.target.value;
    handlerLandShare(dataLandShare);
  };

  const handleLatterChange = (e) => {
    setLatter(e.target.value);
    dataLandShare[e.target.name] = e.target.value;
    handlerLandShare(dataLandShare);
  };

  useEffect(() => {
    setFirst(dataLandShare.first);
    setLatter(dataLandShare.latter);
  }, [dataLandShare]);

  return (
    <div className="property-info-general-form-table-row">
      <Label label={label} />
      <div className="property-info-general-form-table-cell">
        <PropertyInfoFormCautionDescription data={caution_description} />
        <div>
          <FormArea>
            <TextInput
              name={'first'}
              onChange={(e) => handleFirstChange(e)}
              type={'number'}
              value={first ?? ''}
            />
            <div className="ml-2 mr-2">/</div>
            <TextInput
              name={'latter'}
              onChange={(e) => handleLatterChange(e)}
              type={'number'}
              value={latter ?? ''}
            />
            <TextCaution className="ml-4">（例：9999/99999）</TextCaution>
          </FormArea>
          <ValidationMessage invalidMessage={invalidMessage} />
        </div>
      </div>
    </div>
  );
};
export default FormLandEquity;
