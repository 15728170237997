import {
  ASSET_SALE,
  PUBLIC_SALE,
  REAL_ESTATE,
} from 'domain/Property/entities/constants/property';

import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody';
import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle';
//import { useAuth } from '../../../hooks/useAuth';
//import useMembershipId from '../../hooks/useMembershipId';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault';
import { PREFECTURE } from 'shared/utils/constants/prefecture';
import { dateFormat } from 'shared/utils/helper/date_format';

const telText = (info) => {
  let telTextArr = [];

  if (info?.houseTel) {
    telTextArr.push(`自宅：${info.houseTel}`);
  }

  if (info?.mobileTel) {
    telTextArr.push(`携帯：${info.mobileTel}`);
  }

  if (telTextArr.length === 2) {
    return (
      <p>
        {telTextArr[0]}
        <br />
        {telTextArr[1]}
      </p>
    );
  } else if (telTextArr.length == 1) {
    return <p>{telTextArr[0]}</p>;
  } else {
    return null;
  }
};

const applicantTableRows = (applicantInfo, data) => {
  let tableRows = [];

  if (applicantInfo.aplType) {
    tableRows.push({
      id: 1,
      label:
        data.procedureType === PUBLIC_SALE
          ? '公売保証金の納付方法'
          : '入札保証金の納付方法',
      value:
        applicantInfo.aplType === 'ONLINE' ? 'クレジットカード' : '銀行振込',
    });
  }

  if (data.procedureType === ASSET_SALE && data.propertyType === REAL_ESTATE) {
    tableRows.push({
      id: 2,
      label: '共同入札',
      value: applicantInfo.jointBidding === 'TRUE' ? 'する' : 'しない',
    });
  }

  tableRows.push({
    id: 3,
    label: '代理人による手続き',
    value: applicantInfo.isDelegatorProcedure ? 'する' : 'しない',
  });

  return tableRows;
};

const ApplicationInfoTable = ({ title, info, data }) => {
  // mock data
  //const { userId } = useAuth();
  //const { membershipId } = useMembershipId(userId);
  const membershipId = '12345678';
  const prefectureName = PREFECTURE.find(
    (prefecture) => prefecture.id === info?.prefectureId,
  )?.name;

  const commonTableRows = [
    { id: 1, label: '会員識別番号', value: membershipId },
    {
      id: 2,
      label: '法人、個人',
      value: `${info?.corpName ? '法人' : '個人'}で申し込み`,
    },
    {
      id: 3,
      label: (
        <p>
          法人名
          <br />
          <small>法人の場合のみ</small>
        </p>
      ),
      value: info?.corpName,
    },
    {
      id: 4,
      label: (
        <p>
          法人名 カナ
          <br />
          <small>法人の場合のみ</small>
        </p>
      ),
      value: info?.corpNameKana,
    },
    {
      id: 5,
      label: (
        <p>
          氏名
          <br />
          <small>法人の場合は代表者名</small>
        </p>
      ),
      value:
        info?.familyName &&
        info?.firstName &&
        `${info?.familyName} ${info?.firstName}`,
    },
    {
      id: 6,
      label: (
        <p>
          氏名 カナ
          <br />
          <small>法人の場合は代表者名</small>
        </p>
      ),
      value:
        info?.familyNameKana &&
        info?.firstNameKana &&
        `${info?.familyNameKana} ${info?.firstNameKana}`,
    },
    {
      id: 7,
      label: '性別',
      value: info?.sex && (info?.sex === 'MALE' ? '男性' : '女性'),
    },
    {
      id: 8,
      label: '生年月日',
      value: info?.birthDate && dateFormat(info?.birthDate, 'YYYY-MM-DD'),
    },
    {
      id: 9,
      label: '郵便番号',
      value:
        info?.postalCode1 &&
        info?.postalCode2 &&
        `${info?.postalCode1}-${info?.postalCode2}`,
    },
    { id: 10, label: '都道府県', value: prefectureName },
    { id: 11, label: '市区町村番地', value: info?.address },
    {
      id: 12,
      label: '住所',
      value:
        prefectureName && info?.address && `${prefectureName}${info?.address}`,
    },
    { id: 13, label: '電話番号', value: telText(info) },
  ];

  return (
    <div data-testid="application-info-table">
      <ArticleDefaultTitle title={title} />
      <ArticleDefaultBody>
        <CommonTableDefault>
          {commonTableRows.map((row) => {
            return (
              !!row.value && (
                <tr key={row.id} data-testid={`common-table-row-${row.id}`}>
                  <th data-testid={`common-table-row-${row.id}-label`}>
                    {row.label}
                  </th>
                  <td data-testid={`common-table-row-${row.id}-value`}>
                    {row.value}
                  </td>
                </tr>
              )
            );
          })}
          {info.isDelegatorProcedure !== undefined &&
            applicantTableRows(info, data).map((row) => (
              <tr key={row.id} data-testid="applicant-info-table-row">
                <th data-testid={`applicant-info-table-row-${row.id}-label`}>
                  {row.label}
                </th>
                <td data-testid={`applicant-info-table-row-${row.id}-value`}>
                  {row.value}
                </td>
              </tr>
            ))}
        </CommonTableDefault>
      </ArticleDefaultBody>
    </div>
  );
};

export default ApplicationInfoTable;
