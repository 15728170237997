import { useState } from "react";

import { typeOfAlert } from "../../../../../shared/utils/helper/convert.js";
import { ReactComponent as CloseIcon } from '../../../../assets/svg/close_icon.svg';
import '../../../../css/common/alert.css';

const Alert = ({ data }) => {
  // TODO: Providerに持ち上げ？
  const [isOpen, setIsOpen] = useState(true);
  const onClick = () => {
    setIsOpen(false)
  }

  return (
    <>
      {isOpen &&
        <div className={`alert ${typeOfAlert(data?.severityType)}`} data-testid="alert">
          <div>
            <div className="alert-title" data-testid="alert-title">{data?.title}</div>
            <div className="alert-body" data-testid="alert-body">{data?.body}</div>
          </div>
          <button className="hidden-alert-button" data-testid="hidden-alert-button" onClick={onClick}>
            <CloseIcon className="hidden-alert-button-icon" data-testid="hidden-alert-button-icon"/>
          </button>
        </div>
      }
    </>
  );
};

export default Alert;
