import { dateFormat } from 'shared/utils/helper/date_format';
import {
  PROCEDURE_TYPE_JP,
  GOVERNMENT_TYPE_GROUP_JP,
  PUBLIC_SALE_TYPE,
} from 'shared/utils/helper/constants';

const KmanagerTableRowHeaderData = ({ data }) => {
  return (
    <tr
      className="kmanager-table-record"
      data-testid="kmanager-table-record-header">
      <td data-testid="kmanager-table-td">{data.text}</td>
      <td data-testid="kmanager-table-td">
        {data.hasLabel ??
          PROCEDURE_TYPE_JP[data.state] ??
          PUBLIC_SALE_TYPE[data.state] ??
          GOVERNMENT_TYPE_GROUP_JP[data.state] ??
          (data.state instanceof Date
            ? dateFormat(data.state, 'YYYY-MM-DD-hh-mm')
            : data.state)}
      </td>
    </tr>
  );
};

export default KmanagerTableRowHeaderData;
