
export class PaymentDeadline {
  #paymentDeadline

  constructor(paymentDeadline) {
    this.#paymentDeadline = paymentDeadline;
  }

  get() {
    return this.#paymentDeadline
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
