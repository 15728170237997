import isLeapYear from './isLeapYear.js';
import {
  COMMON_MONTH_DAYS,
  COMMON_YEAR_DATE,
  LEAP_MONTH_DAYS,
  LEAP_YEAR_DATE,
} from '../constants/date?.js';

export const calcXCoordinate = (date) => {
  const currentYear = new Date().getFullYear();
  const year = date?.getFullYear();
  const month = date?.getMonth(); // 0から11までの値
  const day = date?.getDate();

  let targetMonthDays = [];

  if (isLeapYear(year)) {
    targetMonthDays = LEAP_MONTH_DAYS;
  } else {
    targetMonthDays = COMMON_MONTH_DAYS;
  }

  // 月ごとの日数を合計
  let dayCount = day;
  for (let i = 0; i < month; i++) {
    dayCount += targetMonthDays[i];
  }

  if (currentYear < year) {
    if (isLeapYear(currentYear)) {
      dayCount += LEAP_YEAR_DATE;
    } else {
      dayCount += COMMON_YEAR_DATE;
    }
  }

  return dayCount;
};
