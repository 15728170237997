// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sample-guideline .manual-marked-list-record-text-important {
  color: #3f51b5;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/SampleGuideline/manual-marked-list-record.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","sourcesContent":[".sample-guideline .manual-marked-list-record-text-important {\n  color: #3f51b5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
