// ロジックを含まない処理の実装

import { fetchManagementMessagesStorage } from "../../../infrastructure/api/ManagementMessages.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { MessageGet } from "../entities/News.js";
//import { fetchMessagesData } from "../../../infrastructure/api/Messages.js";

// getメソッドの実装
export async function getMessage(type) {
  // SAMPLE
  let response = [];
  if (type === SERVICE) {
    response = await fetchManagementMessagesStorage()
  }
  if (type === NAVI) {
    response = await fetchManagementMessagesStorage()
  }
  if (type === KMANAGER) {
    response = await fetchManagementMessagesStorage()
  }

  if (response instanceof Error) {
    throw new CustomError('Error get Message', response);
  }

  let results = [];
  response.map((data) => {
    const result = new MessageGet(data);
    results.push(result)
  })

  return results
}
