import OrganizationEditProvider from './OrganizationEditProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { organizationEditComplete } from '../entities/complete/componentMapping'
import { organizationEditConfirm } from '../entities/confirm/componentMapping'
import { organizationEdit } from "../entities/edit/componentMapping.js";

const OrganizationEdit = ({ type, target }) => {
  return (
    <OrganizationEditProvider type={type}>
      {
        target === 'edit' ?
            getComponent(organizationEdit, type) :
            target === 'confirm' ?
              getComponent(organizationEditConfirm, type) :
                getComponent(organizationEditComplete, type )
      }
    </OrganizationEditProvider>
  );
};

export default OrganizationEdit;
