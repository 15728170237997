import styled from 'styled-components';

import PopUpInput from 'interfaces/components/common/Input/PopUpInput';

const Label = styled.p`
  display: flex;
  align-items: center;
  margin: 0 16px;
`;

const CreditCardLimitInput = ({ items, value, handleSelect, label }) => {
  return (
    <div className="flex">
      <div className="pop-up-input-box">
        <PopUpInput items={items} value={value} handleSelect={handleSelect} />
      </div>
      <Label>{label}</Label>
    </div>
  );
};

export default CreditCardLimitInput;
