import KmanagerTableColumn from 'interfaces/components/common/Table/KmanagerTableColumn';
import { useContext } from 'react';

import GovernmentDepositsTableFooter from './GovernmentDepositTableFooter';
import GovernmentDepositTableRecord from './GovernmentDepositTableRecord';
import { GovernmentDepositContext } from '../../../../pages/GovernmentDeposit/entities/GovernmentDepositContext';
import { useSortableData } from 'shared/hooks/useSort.js';
import { ProcedureTypes } from 'shared/utils/constants/government';
import 'interfaces/css/common/kmanager-table.css';

const GovernmentDepositTable = () => {
  const { auction, properties } = useContext(GovernmentDepositContext);

  const {
    items: sortedProperties,
    requestSort,
    sortConfig,
  } = useSortableData(properties);

  const header = [
    { id: 1, column: 'id', text: 'No' },
    { id: 2, column: 'saleNumber', text: '区分番号' },
    { id: 3, column: 'name', text: 'タイトル' },
    { id: 4, column: 'cancelDate', text: '中止状況' },
    { id: 5, column: 'divisionName', text: '執行機関' },
    { id: 6, column: 'deposit', text: '保証金額' },
    { id: 7, column: 'paymentOnlineCount', text: 'クレジットカード納付人数' },
    { id: 8, column: 'paymentScheduledPrice', text: '納付予定額' },
    ...(auction.procedureType === ProcedureTypes.ASSET_SALE.string ? [] : [
      { id: 9, column: 'restitutionScheduledPrice', text: '返還予定額' },
    ]),
  ];

  return (
    <div>
      <div className="kmanager-table-wrap">
        <span className="dashboard">
          <table
            className="kmanager-table kmanager-table-hover"
            data-testid="kmanager-government-deposits-table">
            <KmanagerTableColumn
              headers={header}
              requestSort={requestSort}
              sortConfig={sortConfig}
            />
            <tbody>
              {sortedProperties.map((deposit, count) => (
                <GovernmentDepositTableRecord
                  key={count}
                  auction={auction}
                  property={deposit}
                />
              ))}
            </tbody>
            <GovernmentDepositsTableFooter data={properties} />
          </table>
        </span>
      </div>
    </div>
  );
};

export default GovernmentDepositTable;
