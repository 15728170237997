// ロジックを含まない処理の実装

import { fetchPropertiesData } from "../../../infrastructure/api/Properties.js";
import { fetchRecommendPropertiesStorage } from "../../../infrastructure/api/storage/recommend_properties.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { RecommendPropertiesGet } from "../entities/Property.js";

// getメソッドの実装
export async function getRecommendProperties(type) {
  // SAMPLE
  let response = [];
  if (type === SERVICE) {
    response = await fetchRecommendPropertiesStorage()
  }
  if (type === NAVI) {
    response = await fetchRecommendPropertiesStorage()
  }
  if (type === KMANAGER) {
    response = await fetchPropertiesData()
  }

  if (response instanceof Error) {
    throw new CustomError('Error get RecommendProperties', response);
  }

  let results = [];
  response.map((data) => {
    const result = new RecommendPropertiesGet(data);
    results.push(result)
  })

  return results
}
