
export class DisplayCar {
  #displayCar

  constructor(displayCar) {
    this.#displayCar = displayCar;
  }

  get() {
    return this.#displayCar
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
