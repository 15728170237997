
export class TransmissionType {
  #transmissionType

  constructor(transmissionType) {
    this.#transmissionType = transmissionType;
  }

  get() {
    return this.#transmissionType
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
