import ForgetPasswordForm from './ForgetPasswordForm.jsx';
import 'interfaces/css/common/forget-password-modal-contents.css';

const ForgetPasswordModalContents = () => {
  return (
    <div
      className="forget-password-modal-contents"
      data-testid="forget-password-modal-contents">
      <p
        className="forget-password-contents-title"
        data-testid="forget-password-contents-title">
        パスワード再設定
      </p>
      <ForgetPasswordForm />
    </div>
  );
};

export default ForgetPasswordModalContents;
