// 構造体の定義

export class CommonData {
  constructor(data) {
    this.id = data.id;
    this.auctionId = data.auctionId;
    this.categoryId = data.categoryId;
    this.governmentId = data.governmentId;
    this.governmentSubCategoryId = data.governmentSubCategoryId;
    this.name = data.name;
    this.procedureType = data.procedureType;
    this.propertyType = data.propertyType;
    this.publicSaleType = data.publicSaleType;
    this.saleNumber = data.saleNumber;
    this.status = data.status;
    this.updatedAt = data.updatedAt;
  }
}

export class InquiryData {

  constructor(data) {
    this.inquiryAccept = data.inquiryAccept;
    this.inquiryEmail = data.inquiryEmail;
    this.inquiryExtension = data.inquiryExtension;
    this.inquiryName = data.inquiryName;
    this.inquiryNote = data.inquiryNote;
    this.inquiryOffice = data.inquiryOffice;
    this.inquiryTel = data.inquiryTel;
  }
}

export class CarData {

  constructor(data) {
    this.carBodyColor = data.carBodyColor;
    this.carCondition = data.carCondition;
    this.carMileage = data.carMileage;
    this.carDescription = data.carDescription;
    this.carType = data.carType;
    this.classificationNumber = data.classificationNumber;
    this.deliveryTerms = data.deliveryTerms;
    this.description = data.description;
    this.displacement = data.displacement;
    this.displayProductNumber = data.displayProductNumber;
    this.displayRegisterNumber = data.displayRegisterNumber;
    this.effectiveDate = data.effectiveDate;
    this.engineType = data.engineType;
    this.existsCarMaintenanceSheet = data.existsCarMaintenanceSheet;
    this.firstRegisterDate = data.firstRegisterDate;
    this.formalNumber = data.formalNumber;
    this.frontWeight = data.frontWeight;
    this.fuelType = data.fuelType;
    this.handle = data.handle;
    this.height = data.height;
    this.inventoryLocation = data.inventoryLocation;
    this.length = data.length;
    this.manufacture = data.manufacture;
    this.manufactureOther = data.manufactureOther;
    this.mapUrl = data.mapUrl;
    this.model = data.model;
    this.other = data.other;
    this.privateOrBusiness = data.privateOrBusiness;
    this.productNumber = data.productNumber;
    this.purpose = data.purpose;
    this.rearAxelWeight = data.rearAxelWeight;
    this.registerDate = data.registerDate;
    this.registerNumber = data.registerNumber;
    this.safetyTerm = data.safetyTerm;
    this.seatingCapacity = data.seatingCapacity;
    this.shape = data.shape;
    this.statusCarMileage = data.statusCarMileage;
    this.statusSafetyTerm = data.statusSafetyTerm;
    this.totalWeight = data.totalWeight;
    this.transmissionType = data.transmissionType;
    this.weight = data.weight;
    this.width = data.width;
  }
}

export class ContactData {
  constructor(data) {
    this.contactAccept = data.contactAccept;
    this.contactEmail = data.contactEmail;
    this.contactExtension = data.contactExtension;
    this.contactName = data.contactName;
    this.contactOffice = data.contactOffice;
    this.contactTel = data.contactTel;
    this.contractedLimit = data.contractedLimit;
  }
}

export class EstateData {
  constructor(data) {
    this.buildDate = data.buildDate;
    this.buildDateOther = data.buildDateOther;
    this.buildName = data.buildName;
    this.buildOther = data.buildOther;
    this.buildRatio = data.buildRatio;
    this.buildSpace = data.buildSpace;
    this.cityPlanningArea = data.cityPlanningArea;
    this.description = data.description;
    this.estateStatus = data.estateStatus;
    this.exclusiveSpace = data.exclusiveSpace;
    this.firebreakArea = data.firebreakArea;
    this.floor = data.floor;
    this.floorAreaRatio = data.floorAreaRatio;
    this.floorSpace = data.floorSpace;
    this.geographical = data.geographical;
    this.highDistrict = data.highDistrict;
    this.landCategory = data.landCategory;
    this.landOther = data.landOther;
    this.landRights = data.landRights;
    this.landShare = data.landShare;
    this.landSpace = data.landSpace;
    this.landSpaceType = data.landSpaceType;
    this.lawRegulation = data.lawRegulation;
    this.layout = data.layout;
    this.locationGovernmentSubCategoryId = data.locationGovernmentSubCategoryId;
    this.locationText = data.locationText;
    this.locationUrl = data.locationUrl;
    this.nearestStation = data.nearestStation;
    this.neighboringRoad = data.neighboringRoad;
    this.other = data.other;
    this.privateRoad = data.privateRoad;
    this.propertyPrint = data.propertyPrint;
    this.provideEquipment = data.provideEquipment;
    this.residenceGovernmentSubCategoryId = data.residenceGovernmentSubCategoryId;
    this.residenceLocation = data.residenceLocation;
    this.restrictedArea = data.restrictedArea;
    this.shadeRegulation = data.shadeRegulation;
    this.sketchUrl = data.sketchUrl;
    this.structureType = data.structureType;
    this.totalHouses = data.totalHouses;
    this.type = data.estateType;
  }
}

export class EstateDataSummary {
  constructor(data) {
    this.summaryBuildDate = data.summaryBuildDate;
    this.summaryBuildSpace = data.summaryBuildSpace;
    this.summaryCarCategories = data.summaryCarCategories;
    this.summaryCarMileage = data.summaryCarMileage;
    this.summaryDisplacement = data.summaryDisplacement;
    this.summaryEstateType = data.summaryEstateType;
    this.summaryExclusiveSpace = data.summaryExclusiveSpace;
    this.summaryFirstRegisterDate = data.summaryFirstRegisterDate;
    this.summaryFloor = data.summaryFloor;
    this.summaryFloorSpace = data.summaryFloorSpace;
    this.summaryLayout = data.summaryLayout;
    this.summaryLocationText = data.summaryLocationText;
    this.summaryManufacture = data.summaryManufacture;
    this.summaryNearestStation = data.summaryNearestStation;
    this.summaryPurpose = data.summaryPurpose;
    this.summarySafetyTerm = data.summarySafetyTerm;
    this.summarySeatingCapacity = data.summarySeatingCapacity;
    this.summaryTotalHouses = data.summaryTotalHouses;
    this.summaryTransmissionType = data.summaryTransmissionType;
  }
}
