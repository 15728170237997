import { createContext } from 'react';

import { DATA_EMPTY } from '../../../../shared/utils/helper/constants.js';

export const BidderListContext = createContext({
  // Default Value
  responsive: DATA_EMPTY,
  managementMessages: DATA_EMPTY,
  government: undefined,
  governmentId: DATA_EMPTY,
  governmentType: DATA_EMPTY,
  governmentUser: DATA_EMPTY,
  procedureType: DATA_EMPTY,
  applicantInformation: DATA_EMPTY,
  proposals: DATA_EMPTY,
  myUserInformation: DATA_EMPTY,
  property: DATA_EMPTY,
  bidderList: DATA_EMPTY,
  tableData: DATA_EMPTY,
  setSearchParam: () => {},
  setSortParam: () => {},
});
