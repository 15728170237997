import { useState } from "react";

export function useCardProvisionalInputs() {
  const [auctionDocumentSubmissionUrl, setAuctionDocumentSubmissionUrl] = useState("");
  const [bidDocumentSubmissionUrl, setBidDocumentSubmissionUrl] = useState("");
  const [bidJointBiddingUrl, setBidJointBiddingUrl] = useState("");

  const cardProvisionalInputs = {
    data: {
      auctionDocumentSubmissionUrl,
      bidDocumentSubmissionUrl,
      bidJointBiddingUrl,
    },
    setters: {
      setAuctionDocumentSubmissionUrl,
      setBidDocumentSubmissionUrl,
      setBidJointBiddingUrl,
    }
  }
  return cardProvisionalInputs;
}