import {
  DATA_EMPTY,
  DISPLAY,
} from 'domain/Property/entities/constants/property';

export const trans_right = (data) => {
  return [
    {
      id: 1,
      title: '動産',
      value:
        data?.transRightPropertyDisplay === DISPLAY
          ? data?.transRightPropertyDescription
          : DATA_EMPTY,
    },
    {
      id: 2,
      title: '自動車',
      value:
        data?.transRightCarDisplay === DISPLAY
          ? data?.transRightCarDescription
          : DATA_EMPTY,
    },
    {
      id: 3,
      title: '不動産',
      value:
        data?.transRightEstateDisplay === DISPLAY
          ? data?.transRightEstateDescription
          : DATA_EMPTY,
    },
  ];
};
