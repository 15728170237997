import { BASE_URL_SERVICE } from '../../../config/envConstants.js';
import { AllowPropertyIds } from '../../../domain/User/entities/ServiceUser.js';
import { fetchData } from '../../../shared/utils/helper/fetchData.js';
import { newObjectClass } from '../../../shared/utils/helper/newClass.js';

export const getCurrentAllowPropertyIdsUnAuth = async () => {
  const response = await fetchData(`${BASE_URL_SERVICE}/storage/current/users/unauthorized_user/allow_property_ids.json`);
  return newObjectClass(response, AllowPropertyIds);
};

export const getPreviousAllowPropertyIdsUnAuth = async () => {
  const response = await fetchData(`${BASE_URL_SERVICE}/storage/previous/users/unauthorized_user/allow_property_ids.json`);
  return newObjectClass(response, AllowPropertyIds);
};

export const getCurrentAllowPropertyIds = async (userId) => {
  const response = await fetchData(`${BASE_URL_SERVICE}/storage/current/users/user${userId}/allow_property_ids.json`);
  return newObjectClass(response, AllowPropertyIds);
};

export const getPreviousAllowPropertyIds = async (userId) => {
  const response = await fetchData(`${BASE_URL_SERVICE}/storage/previous/users/user${userId}/allow_property_ids.json`);
  return newObjectClass(response, AllowPropertyIds);
};
