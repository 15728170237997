import { useState } from 'react';
//import { logInput } from "../../utils/log/logInputs.js";
import * as Validation from 'shared/utils/helper/managerValidators.js';
import { get_location_label } from 'shared/utils/helper/select_box.js';

export const GovernmentForm = () => {
  const [nameKanaValid, setNameKanaValid] = useState(null);
  const [nameValid, setNameValid] = useState(null);
  const [regionValid, setRegionValid] = useState(null);
  const [locationValid, setLocationValid] = useState(null);

  function handleNameValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setNameValid(invalid); // 状態更新
      return;
    }

    invalid = Validation.validateRangeGovernmentNameLength(e.target.value);
    if (invalid !== 'TRUE') {
      setNameValid(invalid); // 状態更新
      return;
    }
    invalid = Validation.validateGovernmentNameCharacter(e.target.value);
    if (invalid !== 'TRUE') {
      setNameValid(invalid); // 状態更新
      return;
    }
    setNameValid(invalid); // 状態更新
  }

  function handleNameKanaValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setNameKanaValid(invalid); // 状態更新
      return;
    }

    invalid = Validation.validateGovernmentNameKanaLength(e.target.value);
    if (invalid !== 'TRUE') {
      setNameKanaValid(invalid); // 状態更新
      return;
    }
    invalid = Validation.validateGovernmentNameKatakana(e.target.value);
    if (invalid !== 'TRUE') {
      setNameKanaValid(invalid); // 状態更新
      return;
    }
    invalid = Validation.validateGovernmentNameKanaCharacter(e.target.value);
    if (invalid !== 'TRUE') {
      setNameKanaValid(invalid); // 状態更新
      return;
    }
    setNameKanaValid(invalid); // 状態更新
  }

  function handleRegionValid(e) {
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setRegionValid(invalid); // 状態更新
      return;
    }
    setRegionValid(invalid); // 状態更新
  }

  function handleLocationValid(e) {
    let invalid = null;
    const label = get_location_label(Number(e.target.value)).label;
    invalid = Validation.validateRequired(label);
    if (invalid !== 'TRUE') {
      setLocationValid(invalid); // 状態更新
      return;
    }
    setLocationValid(invalid); // 状態更新
  }

  const validations = {
    // validationの処理
    setters: {
      name: handleNameValid,
      nameKana: handleNameKanaValid,
      region: handleRegionValid,
      location: handleLocationValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      name: nameValid,
      nameKana: nameKanaValid,
      region: regionValid,
      location: locationValid,
    },
  };

  return validations;
};
