import { useState } from 'react';
import { get_region_id, get_location_id } from 'shared/utils/helper/select_box';
import {
  get_radio_value,
  government_types,
} from 'shared/utils/helper/radio_labels';

export function useGovernmentInputs(government) {
  const [name, setName] = useState(government?.name || '');
  const [nameKana, setNameKana] = useState(government?.nameKana || '');
  const [governmentType, setGovernmentType] = useState(
    get_radio_value(government_types, 'label', government?.governmentType) ||
      'NATIONAL_TAX_AGENCY',
  );
  const [procedureType, setProcedureType] = useState(
    government?.procedureType || 'PUBLIC_SALE',
  );
  const [region, setRegion] = useState(
    get_region_id(government?.region) || '9999',
  );
  const [location, setLocation] = useState(
    get_location_id(government?.location + government?.attribute) || '9999',
  );

  const governmentInputs = {
    type: {
      name: 'text',
      nameKana: 'text',
      governmentType: 'radio',
      procedureType: 'radio',
      region: 'select',
      location: 'select',
    },
    data: {
      name,
      nameKana,
      governmentType,
      procedureType,
      region,
      location,
    },
    setters: {
      name: setName,
      nameKana: setNameKana,
      governmentType: setGovernmentType,
      procedureType: setProcedureType,
      region: setRegion,
      location: setLocation,
    },
  };
  return governmentInputs;
}
