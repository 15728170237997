import { PUBLIC_SALE } from 'domain/Property/entities/constants/property.js';

import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle.jsx';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault.jsx';
import { deliveryStyles } from 'shared/utils/constants/deliveryStyles.js';
import { dateFormat } from 'shared/utils/helper/date_format.js';
import toYenFrom from 'shared/utils/helper/toYenString.js';

import PropertyInfo from './PropertyInfo.jsx';

const PropertyInfos = ({ info, step }) => {
  const bidStart = dateFormat(info.data.bidStart, 'YYYY-MM-DD-hh-mm');
  const bidEnd = dateFormat(info.data.bidEnd, 'YYYY-MM-DD-hh-mm');

  const infos = () => {
    let infoList = [];

    infoList.push(
      { id: 1, label: '物件名', info: info.data.name, isBold: step !== 4 },
      { id: 2, label: '入札期間', info: `${bidStart}〜${bidEnd}` },
    );
    if (info.data.bidPrice) {
      infoList.push(
        {
          id: 3,
          label: '落札価額',
          info: `${toYenFrom(info.data.biddedPrice)}円`,
          isImportant: true,
        },
        {
          id: 4,
          label: '引き渡し方法',
          info: deliveryStyles.CARRIER,
          isImportant: true,
        },
      );
    }
    if (!info.data.bidPrice) {
      infoList.push({
        id: 5,
        label:
          info.data.procedureType === PUBLIC_SALE
            ? '公売保証金'
            : '入札保証金額',
        info: info.data.deposit ? `${toYenFrom(info.data.deposit)}円` : 'なし',
      });
    }

    return infoList;
  };

  return (
    <div>
      <ArticleDefaultTitle
        title={info.data.bidPrice ? '落札物件情報' : '申し込み物件情報'}
      />
      <ArticleDefaultBody>
        <CommonTableDefault>
          {infos().map((info) => (
            <PropertyInfo
              key={info.id}
              label={info.label}
              info={info.info}
              isBold={info.isBold}
              isImportant={info.isImportant}
            />
          ))}
        </CommonTableDefault>
      </ArticleDefaultBody>
    </div>
  );
};

export default PropertyInfos;
