import axios from 'axios';

import {API_BASE_URL} from "../../config/envConstants.js";

export const createNaviGuidelinesData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/navi/guidelines`,
    body, {headers: headers}
  );

};
export const updateNaviGuidelinesData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/navi/guidelines/${id}`,
    body, {headers: headers}
  );
};
export const deleteNaviGuidelinesData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/navi/guidelines/${id}`, {
      headers: headers,
    },);
};