//import { useAuth } from '../../hooks/useAuth.js';
//import { useMyList } from './hooks/useMyList.js';
import Switcher from 'interfaces/components/common/Switcher';
import MyListContents from 'interfaces/components/Mylist/MyListContents';
import MylistHeadline from 'interfaces/components/Mylist/MylistHeadline';
import { useState } from 'react';
//import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import 'interfaces/css/Mylist/mylist.css';

const Mylist = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  //const { userId } = useAuth();
  //const { localStorageUserId } = useLocalStorage();
  //const {
  //  //allMyList,
  //  applyingMyList,
  //  provisionalApplicationMyList,
  //  mainApplicationMyList,
  //  beforeBiddingMylist,
  //  biddingInProgressMyList,
  //  biddingCompletionMyList,
  //  bidOpeningResultMyList,
  //} = useMyList(localStorageUserId);

  const allMyList = [
    {
      addDescription: 'DATA_EMPTY',
      additionalBidEnd: 'DATA_EMPTY',
      aplEnd: '2023-12-10 18:00:00',
      aplStart: '2023-12-03 08:00:00',
      auctionEnd: '2024-01-15 18:00:00',
      bidEnd: 'DATA_EMPTY',
      bidStart: '2023-12-25 08:00:00',
      bidders: [],
      bodyColor: 'DATA_EMPTY',
      buildDate: 'DATA_EMPTY',
      buildDateOther: 'DATA_EMPTY',
      buildName: 'DATA_EMPTY',
      buildOther: 'DATA_EMPTY',
      buildRatio: 'DATA_EMPTY',
      buildSpace: 'DATA_EMPTY',
      carCondition: 'DATA_EMPTY',
      carDescription: 'DATA_EMPTY',
      carMileage: 'DATA_EMPTY',
      carType: 'DATA_EMPTY',
      categoryId: '600',
      circumstance: 'DATA_EMPTY',
      cityPlanningArea: 'DATA_EMPTY',
      classificationNumber: 'DATA_EMPTY',
      commonDescription: 'これはテスト用のオークションです。',
      contractDeadline: 'DATA_EMPTY',
      deadline: '2024-01-15 18:00:00',
      deposit: 1000,
      depositPayment: '銀行振込',
      description: 'テスト用の物件です。',
      displacement: 'DATA_EMPTY',
      divisionName: 'テスト執行機関',
      effectiveDate: 'DATA_EMPTY',
      engineType: 'DATA_EMPTY',
      estateDescription: 'DATA_EMPTY',
      estateStatus: 'DATA_EMPTY',
      estateType: 'DATA_EMPTY',
      estimatePrice: 10000,
      exclusiveSpace: 'DATA_EMPTY',
      existsCarMaintenanceSheet: 'DATA_EMPTY',
      firebreakArea: 'DATA_EMPTY',
      firstRegisterDate: 'DATA_EMPTY',
      floor: 'DATA_EMPTY',
      floorAreaRatio: 'DATA_EMPTY',
      floorSpace: 'DATA_EMPTY',
      form: 'DATA_EMPTY',
      formalNumber: 'DATA_EMPTY',
      frontWeight: 'DATA_EMPTY',
      fuelType: 'DATA_EMPTY',
      geographical: 'DATA_EMPTY',
      governmentId: 1,
      governmentName: '行政機関（国税）',
      governmentSubCategoryId: '27',
      governmentType: 'NATIONAL_TAXAGENCY',
      handle: 'DATA_EMPTY',
      height: 'DATA_EMPTY',
      highDistrict: 'DATA_EMPTY',
      id: 54,
      individualEnterprise: 'DATA_EMPTY',
      inquiryAccept: '平日　8:00 ~ 17:00',
      inquiryEmail: 'auction@sample.com',
      inquiryExtension: 'DATA_EMPTY',
      inquiryName: 'テスト機関',
      inquiryNote: '繋がらない場合がございます。',
      inquiryOffice: 'オークション部',
      inquiryTel: '1234567890',
      inventoryLocation: 'DATA_EMPTY',
      isDeliveryLock: 'UNLOCK',
      landCategory: 'DATA_EMPTY',
      landOther: 'DATA_EMPTY',
      landRight: 'DATA_EMPTY',
      landShare: 'DATA_EMPTY',
      landSpace: 'DATA_EMPTY',
      lawRegulation: 'DATA_EMPTY',
      layout: 'DATA_EMPTY',
      length: 'DATA_EMPTY',
      location: 'DATA_EMPTY',
      locationMap: 'DATA_EMPTY',
      locationText: 'DATA_EMPTY',
      locationUrl: 'DATA_EMPTY',
      manufacture: 'DATA_EMPTY',
      name: '国税-一般-54',
      nearestStation: 'DATA_EMPTY',
      neighboringRoad: 'DATA_EMPTY',
      note: 'DATA_EMPTY',
      other: 'DATA_EMPTY',
      paymentDeadline: '2024-01-27 18:00:00',
      payments: [''],
      privateRoad: 'DATA_EMPTY',
      procedureType: 'インターネット公売',
      procedureTypeEn: 'PUBLIC_SALE',
      productNumber: 'DATA_EMPTY',
      propertyPrint: 'DATA_EMPTY',
      propertyType: 'REAL_ESTATE',
      provideEquipment: 'DATA_EMPTY',
      publicSaleType: 'せり売形式',
      purpose: 'DATA_EMPTY',
      rearWeight: 'DATA_EMPTY',
      registrationDate: 'DATA_EMPTY',
      registrationNumber: 'DATA_EMPTY',
      remainingLabel: '入札締切まであと',
      residenceLocation: 'DATA_EMPTY',
      restrictedArea: 'DATA_EMPTY',
      safetyTerm: 'DATA_EMPTY',
      saleNumber: 'property-054',
      seatingCapacity: 'DATA_EMPTY',
      shadeRegulation: 'DATA_EMPTY',
      shape: 'DATA_EMPTY',
      sketchUrl: 'DATA_EMPTY',
      status: '入札受付中',
      statusEn: 'DURING_BIDDING',
      structure: 'DATA_EMPTY',
      summaryBuildDate: '-',
      summaryBuildSpace: '-',
      summaryCarCategories: '動産＞音楽＞DVD＞ロック、ポップス（洋楽）',
      summaryCarMileage: '-',
      summaryDisplacement: '-',
      summaryEstateType: '',
      summaryExclusiveSpace: '-',
      summaryFirstRegisterDate: '年月',
      summaryFloor: '',
      summaryFloorSpace: '-',
      summaryLayout: '',
      summaryLocationText: '',
      summaryManufacture: '-',
      summaryNearestStation: '',
      summaryPurpose: '-',
      summarySafetyTerm: '年月',
      summarySeatingCapacity: '-',
      summaryTotalHouses: '-',
      summaryTransmissionType: '-',
      tags: ['インターネット公売', 'せり売形式', '保証金あり'],
      totalHouses: 'DATA_EMPTY',
      totalWeight: 'DATA_EMPTY',
      transmissionType: 'DATA_EMPTY',
      videoUrl: 'DATA_EMPTY',
      weight: 'DATA_EMPTY',
      width: 'DATA_EMPTY',
      bidCount: 0,
      bidCountDisplay: 'DISPLAY',
      bidHistory: [],
      biddedPrice: '',
      biddedUser: '',
      currentTopPrice: 0,
      currentTopUser: '-',
      nextWinner: 'DATA_EMPTY',
      nextWinnerPrice: 'DATA_EMPTY',
    },
  ];
  const applyingMyList = [];
  const provisionalApplicationMyList = [];
  const mainApplicationMyList = [];
  const beforeBiddingMylist = [];
  const biddingInProgressMyList = [];
  const biddingCompletionMyList = [];
  const bidOpeningResultMyList = [];

  const labels = [
    `すべて(${allMyList.length}件)`,
    `申し込み可能(${applyingMyList.length}件)`,
    `仮申し込み状態(${provisionalApplicationMyList.length}件)`,
    `本申し込み完了(${mainApplicationMyList.length}件)`,
    `入札前(${beforeBiddingMylist.length}件)`,
    `入札中(${biddingInProgressMyList.length}件)`,
    `入札完了(${biddingCompletionMyList.length}件)`,
    `開札結果(${bidOpeningResultMyList.length}件)`,
  ];

  const counts = [
    allMyList.length,
    applyingMyList.length,
    provisionalApplicationMyList.length,
    mainApplicationMyList.length,
    beforeBiddingMylist.length,
    biddingInProgressMyList.length,
    biddingCompletionMyList.length,
    bidOpeningResultMyList.length,
  ];

  return (
    <div className="mylist kservice-mylist">
      <div className="pt-6">
        <MylistHeadline headline={'マイリスト'} />
        <Switcher
          labels={labels}
          counts={counts}
          activeTabIndex={activeTabIndex}
          setActiveTab={setActiveTabIndex}>
          <MyListContents
            properties={allMyList}
            sortBoxType="LARGE"
            isDeleteFeature={true}
          />
          <MyListContents
            properties={applyingMyList}
            sortBoxType="MEDIUM"
            isDeleteFeature={true}
          />
          <MyListContents
            properties={provisionalApplicationMyList}
            sortBoxType="MEDIUM"
          />
          <MyListContents
            properties={mainApplicationMyList}
            sortBoxType="MEDIUM"
          />
          <MyListContents properties={beforeBiddingMylist} sortBoxType="MEDIUM" />
          <MyListContents
            properties={biddingInProgressMyList}
            sortBoxType="MEDIUM"
          />
          <MyListContents
            properties={biddingCompletionMyList}
            sortBoxType="MEDIUM"
          />
          <MyListContents
            properties={bidOpeningResultMyList}
            sortBoxType="MEDIUM"
          />
        </Switcher>
      </div>
    </div>
  );
};

export default Mylist;
