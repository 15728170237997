
export class Timing {
    #timing

    constructor(timing) {
        this.#timing = timing;
    }

    get() {
        return this.#timing
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
