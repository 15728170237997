// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.important-label_important_label__roXLQ {
  height: 24px;
  width: 48px;
  color: var(--white);
  background-color: var(--important);
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/important-label.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,kCAAkC;EAClC,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".important_label {\n  height: 24px;\n  width: 48px;\n  color: var(--white);\n  background-color: var(--important);\n  border-radius: 4px;\n  font-size: 12px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"important_label": `important-label_important_label__roXLQ`
};
export default ___CSS_LOADER_EXPORT___;
