export const after_winning_bid_caution_description_1 = [
  {id: 1, text: "説明を300文字以内で入力してください。"},
]
export const after_winning_bid_caution_description_2 = [
  {id: 1, text: "説明を500文字以内で入力してください。"},
  {id: 2, text: "「表示する」を選択した場合は入力必須です。"},
]
export const after_winning_bid_caution_description_3 = [
  {id: 1, text: "必要な費用に関する全般的なご注意を500文字以内で入力してください。"},
]
export const after_winning_bid_caution_description_4 = [
  {id: 1, text: "例）○○県必要書類ダウンロードページ", marked: false},
  {id: 2, text: "必要な書類の案内ページの名称を30文字以内で入力してください。"},
  {id: 3, text: "「表示する」を選択した場合は入力必須です。"},
]
export const after_winning_bid_caution_description_5 = [
  {id: 1, text: "例）https://example.com", marked: false},
  {id: 2, text: "必要な書類の案内ページのURLを入力してください。"},
  {id: 3, text: "「表示する」を選択した場合は入力必須です。"},
]
export const after_winning_bid_caution_description_6 = [
  {id: 1, text: "説明を1000文字以内で入力してください。"},
  {id: 2, text: "「表示する」を選択した場合は入力必須です。"},
]
export const after_winning_bid_caution_description_7 = [
  {id: 1, text: "必要な書類に関する全般的なご注意を500文字以内で入力してください。"},
]
export const after_winning_bid_caution_description_8 = [
  {id: 1, text: "物件の権利移転に関する全般的なご注意を500文字以内で入力してください。"},
]
export const after_winning_bid_caution_description_9 = [
  {id: 1, text: "説明を500文字以内で入力してください。"},
]
export const after_winning_bid_caution_description_10 = [
  {id: 1, text: "落札者（落札者が法人の場合は代表者）以外の方が権利移転手続きを行う場合に関する全般的なご注意を500文字以内で入力してください。"},
]
export const after_winning_bid_caution_description_11 = [
  {id: 1, text: "説明を1000文字以内で入力してください。"},
]
export const after_winning_bid_caution_description_12 = [
  {id: 1, text: "重要事項に関する全般的なご注意を1000文字以内で入力してください。"},
]
export const after_winning_bid_caution_description_13 = [
  {id: 1, text: "例）〇〇県〇〇郡〇〇課〇〇係", marked: false},
  {id: 2, text: "お問い合わせ先の名称を50文字以内で入力してください。"},
  {id: 3, text: "「表示する」を選択した場合は入力必須です。"},
]
export const after_winning_bid_caution_description_14 = [
  {id: 1, text: "電話受付時間を50文字以内で入力してください。"},
]
export const after_winning_bid_caution_description_15 = [
  {id: 1, text: "権利移転の時期について500文字以内で入力してください。"},
]
export const after_winning_bid_caution_description_16 = [
  {id: 1, text: "登記手続き完了までの期間について500文字以内で入力してください。"},
]
export const after_winning_bid_title1 = [
  {id: 1, text: "タイトルを50文字以内で入力してください。"},
]