// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-input-wrap {
  margin-right: 14px;
  display: flex;
}

.radio-input {
  width: 6px; /* ラジオボタンとテキストの間の余白になる */
  visibility: hidden;
}

.radio-input-label {
  font-size: 1.05rem;
  display: flex;
  align-items: center;
  padding-bottom: 1px;
}

.accordion-label-radio {
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  z-index: 10; /* onClickの親子関係を調整 => react/src/components/Accordion/css/single-open-accordion.css */
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/radio-input.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,UAAU,EAAE,wBAAwB;EACpC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,WAAW,EAAE,oFAAoF;AACnG","sourcesContent":[".radio-input-wrap {\n  margin-right: 14px;\n  display: flex;\n}\n\n.radio-input {\n  width: 6px; /* ラジオボタンとテキストの間の余白になる */\n  visibility: hidden;\n}\n\n.radio-input-label {\n  font-size: 1.05rem;\n  display: flex;\n  align-items: center;\n  padding-bottom: 1px;\n}\n\n.accordion-label-radio {\n  width: 100%;\n  height: 100%;\n  padding: 16px 24px;\n  z-index: 10; /* onClickの親子関係を調整 => react/src/components/Accordion/css/single-open-accordion.css */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
