import "interfaces/css/common/accordion-topic.css"

const AccordionTopic = ({ text, handleClick, className }) => {
  return (
    <div
      data-testid="kmanager-accordion-topic"
      // TODO: 表示されたページのpathがAccordionTopicに設定しているpathと一致していたらクラス名に"kmanager-accordion-panel-active"を付与する
      className={`kmanager-accordion-panel kmanager-accordion-topic d-flex align-center px-4 ${className}`}
      onClick={ handleClick }
    >
      { text }
    </div>
  );
};
export default AccordionTopic;