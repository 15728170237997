import { useEmblemInputs } from './hook/useEmblemInputs';
import { useEmblemInputsValidation } from './hook/useEmblemInputsValidation';

export function validationEmblemForm() {
  const formInputs = useEmblemInputs();
  const validate = useEmblemInputsValidation();

  function handleUseEmblem(e) {
    formInputs.setters.setUseEmblem(e.target.value);
  }
  function handleEmblemFile(e, required) {
    validate.setters.emblemFile(e, required);
    formInputs.setters.setEmblemFile(e.target.value);
  }
  function handleUseCharacter(e) {
    formInputs.setters.setUseCharacter(e.target.value);
  }
  function handleCharacterName(e, required) {
    validate.setters.characterName(e, required);
    formInputs.setters.setCharacterName(e.target.value);
  }
  function handleCharacterInsteadEmblem(e) {
    formInputs.setters.setCharacterInsteadEmblem(e.target.value);
  }
  function handleCharacterFile(e, required) {
    validate.setters.characterFile(e, required);
    formInputs.setters.setCharacterFile(e.target.value);
  }

  const validateInputs = {
    data: formInputs.data,
    setters: {
      handleUseEmblem,
      handleEmblemFile,
      handleUseCharacter,
      handleCharacterName,
      handleCharacterInsteadEmblem,
      handleCharacterFile,
    },
    init: formInputs.setters,
    validationMessage: validate.validationMessage,
  };

  return validateInputs;
}
