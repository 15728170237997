const FlatButton = ({ text, icon, buttonStyle, onClick, isDisabled = false }) => {
  return (
    <button
      className={`button button-flat-light button-shadow ${isDisabled ? "button-disabled" : ""}`}
      style={buttonStyle}
      data-testid="flat-button"
      onClick={onClick}
      disabled={isDisabled}
    >
      <span className="button-content">
        <span className="icon icon-left">{icon}</span>
        {text}
      </span>
    </button>
  );
}

export default FlatButton;