const CommonTableDefault = ({ className, children }) => {
  return (
    <table className={`table-default ${className}`} data-testid="table-default">
      <tbody>
        {children}
      </tbody>
    </table>
  );
};

export default CommonTableDefault;
