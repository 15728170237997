
export class BidHistory {
    #bidHistory

    constructor(bidHistory) {
        this.#bidHistory = bidHistory;
    }

    get() {
        return this.#bidHistory
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
