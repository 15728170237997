const ImportantCaution = ({ text, title }) => {
  return (
    <>
      {title && <p className="important-caution-title">{title}</p>}
      <p className="important-caution-text">{text}</p>
    </>
  );
};

export default ImportantCaution;
