import Button from 'interfaces/components/common/Button/Button.jsx';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'shared/hooks/useAuth.js';
import { useError } from 'shared/hooks/useError.js';
import { useInput } from 'shared/hooks/useInput.js';
import { useValidate } from 'shared/hooks/useValidate.js';
import { FORGET_PASSWORD, SIGN_UP } from 'shared/utils/helper/authState.js';

import AuthFormInput from '../AuthFormInput.jsx';
import 'interfaces/css/Login/service-login-form.css';

const emailValidates = ['no_message_required', 'email'];
const passwordValidate = ['no_message_required', 'password'];

const ServiceLoginForm = ({ switchAuthStatus, handleClose }) => {
  const { setError } = useError();
  const { login } = useAuth();
  const { handleValidates } = useValidate();
  const [email, handleEmailChange, validateEmailMessage] =
    useInput(emailValidates);
  const [password, handlePasswordChange, validatePasswordMessage] =
    useInput(passwordValidate);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loginFailMessage, setLoginFailMessage] = useState('');

  const checkAllValidation = () => {
    const emailValidateMessage = handleValidates(email, emailValidates);
    const passwordValidateMessage = handleValidates(password, passwordValidate);
    setIsDisabled(emailValidateMessage || passwordValidateMessage);
  };

  useEffect(() => {
    checkAllValidation();
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isDisabled) {
      return;
    }

    try {
      const message = await login(email, password);
      setLoginFailMessage(message);
    } catch (e) {
      setError(e);
    }
  };

  const serviceLoginFormInputs = [
    {
      id: 1,
      label: 'ログインID（メールアドレス）',
      type: 'email',
      handleChange: handleEmailChange,
      validateMessage: validateEmailMessage,
    },
    {
      id: 2,
      label: 'ログインパスワード',
      type: 'password',
      handleChange: handlePasswordChange,
      validateMessage: validatePasswordMessage,
    },
  ];

  return (
    <div className="service-login-form" data-testid="service-login-form">
      <small className="validate-message flex justify-center mb-2">
        {loginFailMessage}
      </small>
      <form noValidate onSubmit={handleSubmit}>
        {serviceLoginFormInputs.map((input) => (
          <AuthFormInput
            key={input.id}
            label={input.label}
            type={input.type}
            handleChange={input.handleChange}
            validateMessage={input.validateMessage}
          />
        ))}
        <div className="flex flex-column align-end">
          <Link
            onClick={() => switchAuthStatus(SIGN_UP)}
            className="login-form-link"
            data-testid="to-create-account-modal">
            新規登録はこちら
          </Link>
          <Link
            onClick={() => switchAuthStatus(FORGET_PASSWORD)}
            className="login-form-link"
            data-testid="to-reset-password-modal">
            パスワードを忘れた場合
          </Link>
        </div>
        <div className="flex justify-center mt-10">
          <Button
            text="キャンセル"
            height="44px"
            width="100px"
            className="cancel-button"
            type="button"
            onClick={handleClose}
          />
          <Button
            text="次へ"
            height="44px"
            width="100px"
            className="mx-2"
            type="submit"
            isDisabled={isDisabled}
          />
        </div>
      </form>
    </div>
  );
};

export default ServiceLoginForm;
