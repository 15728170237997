import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class Body {
  #body

  constructor(body) {
    this.#body = body;
  }

  get() {
    return this.#body
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#body, 1, 20000)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#body)
  }
}
