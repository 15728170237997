//import {useAuth} from "../../../hooks/useAuth.js";
import { useContext } from 'react';
//import { useSearchCategoryIds } from './hooks/useSearchCategoryIds';
import { SEARCH_PROPERTY_CATEGORY_ID } from 'shared/utils/constants/queryParam';

//import { useQueryParamController } from './hooks/useQueryParamController';
import { PropertiesContext } from '../../../pages/Properties/entities/PropertiesContext';

const CategoryButton = ({ categoryData, onClick, disabled }) => {
  const { categoryIds: ids, categoryName: title, icon } = categoryData;
  //const { userId } = useAuth();
  //const searchCategoryIds = useSearchCategoryIds(userId, ids);
  //const searchCategoryIds = useSearchCategoryIds(1, ids);
  //const { applyQueryParam } = useQueryParamController();
  const { setSearchParams } = useContext(PropertiesContext);

  return (
    <button
      type="button"
      className={`font-weight-bold category-btn category-btn--shadow category-btn-size--default d-inline-flex align-center justify-center mb-2 theme--light v-size--default text--text ${disabled ? 'category-btn--disabled' : ''}`}
      style={{ height: '70px', width: '48%' }}
      onClick={() => {
        onClick && onClick();
        //applyQueryParam(SEARCH_PROPERTY_CATEGORY_ID, searchCategoryIds);
        setSearchParams((prev) => {
          prev.set(SEARCH_PROPERTY_CATEGORY_ID, ids.join(','));
          return prev;
        });
      }}
      disabled={disabled}>
      <span className="category-btn-content flex align-center">
        <div className="text-center">
          <div className="svg-wrapper svg-wrapper--primary">{icon}</div>
          <div>
            <p className="text-body-2 font-weight-bold mb-n1">{title}</p>
          </div>
        </div>
      </span>
    </button>
  );
};
export default CategoryButton;
