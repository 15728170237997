import { findById as findGovernment } from 'domain/Government/services/Government.js';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { get as getProposals } from '../../../../domain/Applicant/services/Proposal';
import { findById as getAuction } from '../../../../domain/Auction/services/Auction';
import { findById as getInvitation } from '../../../../domain/Auction/services/Invitation';
import { get as getBidders } from '../../../../domain/Bid/services/Bid';
import { get as getDivisions } from '../../../../domain/Division/services/Division';
import { get as getMylists } from '../../../../domain/Favorite/services/Favorite';
import { findByAuctionIdAndGovernmentId as getProperties } from '../../../../domain/Property/services/Property';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { NAVI } from '../../../../shared/utils/helper/constants.js';
import { PublishedObjectStatusContext } from '../entities/PublishedObjectStatusContext.js';

const PublishedObjectStatusProvider = ({ type, children }) => {
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [auction, setAuction] = useState([]);
  const [invitation, setInvitation] = useState([]);
  const { auction_id } = useParams();
  const { localStorageGovernmentId } = useLocalStorage();
  const [tableData, setTableData] = useState([]);
  const [government, setGovernment] = useState(undefined);
  const [sortParam, setSortParam] = useState({ sortId: 1, order: 'asc' });

  if (type === NAVI) {
    useEffect(() => {
      getAuction(type, auction_id).then((result) => {
        setAuction(result);
        getInvitation(type, auction_id, localStorageGovernmentId).then(
          (invitation) => {
            setInvitation(invitation);
          },
        );
      });
      getProperties(type, auction_id, localStorageGovernmentId).then(
        (properties) => {
          let data = [];
          getMylists(type).then((mylists) => {
            getBidders(type).then((bidders) => {
              getDivisions(type).then((divisions) => {
                getProposals(type).then((proposals) => {
                  properties.forEach((property) => {
                    let onlineTrial = 0;
                    let onlineApplication = 0;
                    let offlineTrial = 0;
                    let offlineApplication = 0;
                    let mylistCount = mylists.filter(
                      (mylist) =>
                        mylist.propertyId.toString() === property.id.toString(),
                    ).length;
                    let bidderCount = bidders.filter(
                      (bidder) =>
                        bidder.propertyId.toString() === property.id.toString(),
                    ).length;
                    let division = divisions.find(
                      (division) =>
                        division.id.toString() ===
                        property.divisionId.toString(),
                    );

                    proposals.forEach((proposal) => {
                      if (
                        proposal.propertyId.toString() ===
                        property.id.toString()
                      ) {
                        if (proposal.aplType === 'ONLINE') {
                          if (proposal.aplStatus === 'APPLICATION') {
                            onlineApplication = onlineApplication + 1;
                          } else {
                            onlineTrial = onlineTrial + 1;
                          }
                        } else {
                          if (proposal.aplStatus === 'APPLICATION') {
                            offlineApplication = offlineApplication + 1;
                          } else {
                            offlineTrial = offlineTrial + 1;
                          }
                        }
                      }
                    });
                    let item = {};
                    item.printOrder = property.printOrder;
                    item.saleNumber = property.saleNumber;
                    item.name = property.name;
                    item.estimatePrice = property.estimatePrice;
                    item.deposit = property.deposit;
                    item.bidEndPrice = property.bidEndPrice;
                    item.isDeliveryRequestDisplayed =
                      property.isDeliveryRequestDisplayed;
                    item.deliveryStyle = property.deliveryStyle;
                    item.offlineTrial = offlineTrial;
                    item.offlineApplication = offlineApplication;
                    item.onlineTrial = onlineTrial;
                    item.onlineApplication = onlineApplication;
                    item.mylistCount = mylistCount;
                    item.bidderCount = bidderCount;
                    item.divisionName = division.name;
                    data.push(item);
                  });
                  let sorteditems = sortProperties(data);
                  setTableData(sorteditems);
                });
              });
            });
          });
        },
      );
    }, [localStorageGovernmentId, auction_id, sortParam]);

    useEffect(() => {
      findGovernment(type, localStorageGovernmentId).then((government) => {
        setGovernment(government);
      });
    }, [localStorageGovernmentId]);
  }

  const sortProperties = (properties) => {
    if (sortParam.sortId === 1) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          a.printOrder > b.printOrder ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          b.printOrder > a.printOrder ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 2) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          a.saleNumber > b.saleNumber ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          b.saleNumber > a.saleNumber ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 3) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) => (a.name > b.name ? 1 : -1));
      } else {
        return properties.sort((a, b) => (b.name > a.name ? 1 : -1));
      }
    }
    if (sortParam.sortId === 4) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          Number(a.estimatePrice) > Number(b.estimatePrice) ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          Number(b.estimatePrice) > Number(a.estimatePrice) ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 5) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) => (a.deposit > b.deposit ? 1 : -1));
      } else {
        return properties.sort((a, b) => (b.deposit > a.deposit ? 1 : -1));
      }
    }
    if (sortParam.sortId === 6) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          a.bidEndPrice > b.bidEndPrice ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          b.bidEndPrice > a.bidENdPrice ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 7) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          a.offlineTrial > b.offlineTrial ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          b.offlineTrial > a.offlineTrial ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 8) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          a.offlineApplication > b.offlineApplication ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          b.offlineApplication > a.offlineApplication ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 9) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          a.onlineTrial > b.onlineTrial ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          b.onlineTrial > a.onlineTrial ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 10) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          a.onlineApplication > b.onlineApplication ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          b.onlineApplication > a.onlineApplication ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 11) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          a.mylistCount > b.mylistCount ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          b.mylistCount > a.mylistCount ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 12) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          a.bidderCount > b.bidderCount ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          b.bidderCount > a.bidderCount ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 13) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          a.divisionName > b.divisionName ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          b.divisionName > a.divisionName ? 1 : -1,
        );
      }
    }
    return properties;
  };

  return (
    <PublishedObjectStatusContext.Provider
      value={{
        authState,
        government,
        auction,
        invitation,
        tableData,
        setSortParam,
      }}>
      {children}
    </PublishedObjectStatusContext.Provider>
  );
};

export default PublishedObjectStatusProvider;
