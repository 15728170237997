import { DATA_EMPTY } from 'domain/Property/entities/constants/property';

export const deadline_conclusion_contract = (data) => {
  const condition = [DATA_EMPTY, undefined, ''];
  return [
    {
      id: 1,
      title: condition.includes(data?.costContractTitle1)
        ? DATA_EMPTY
        : data?.costContractTitle1,
      value: condition.includes(data?.costContractDescription1)
        ? DATA_EMPTY
        : data?.costContractDescription1,
    },
    {
      id: 2,
      title: condition.includes(data?.costContractTitle2)
        ? DATA_EMPTY
        : data?.costContractTitle2,
      value: condition.includes(data?.costContractDescription2)
        ? DATA_EMPTY
        : data?.costContractDescription2,
    },
    {
      id: 3,
      title: condition.includes(data?.costContractTitle2)
        ? DATA_EMPTY
        : data?.costContractTitle3,
      value: condition.includes(data?.costContractDescription3)
        ? DATA_EMPTY
        : data?.costContractDescription3,
    },
  ];
};
