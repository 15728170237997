
export class ImportantStorageCost {
  #importantStorageCost

  constructor(importantStorageCost) {
    this.#importantStorageCost = importantStorageCost;
  }

  get() {
    return this.#importantStorageCost
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
