
export class SummarySafetyTerm {
    #summarySafetyTerm

    constructor(summarySafetyTerm) {
        this.#summarySafetyTerm = summarySafetyTerm;
    }

    get() {
        return this.#summarySafetyTerm
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
