// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.government .total-applications {
  font-size: .875rem;
  font-weight: 400;
  font-family: "Roboto",sans-serif;
  line-height: 1.25rem;
  letter-spacing: .02em;
  margin-bottom: 16px;
}

.government .total-applications-label {
  margin-right: 20px;
}

.government .partition {
  width: 1px;
  background-color: var(--line-base);
  margin: 2px 15px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Government/government-simple-schedule-section.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,oBAAoB;EACpB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,kCAAkC;EAClC,gBAAgB;AAClB","sourcesContent":[".government .total-applications {\n  font-size: .875rem;\n  font-weight: 400;\n  font-family: \"Roboto\",sans-serif;\n  line-height: 1.25rem;\n  letter-spacing: .02em;\n  margin-bottom: 16px;\n}\n\n.government .total-applications-label {\n  margin-right: 20px;\n}\n\n.government .partition {\n  width: 1px;\n  background-color: var(--line-base);\n  margin: 2px 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
