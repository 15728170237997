import { useEffect, useState } from 'react';

import { findById as findGovernmentUser } from 'domain/User/services/GovernmentUser.js';
import { findById as findGovernment } from 'domain/Government/services/Government.js';
import { useLocalStorage } from 'shared/hooks/useLocalStorage.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from 'shared/utils/helper/authState.js';
import { SERVICE, NAVI, KMANAGER } from 'shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { GovernmentUserEntryContext } from '../entities/GovernmentUserEntryContext.js';
import { GovernmentTypeToJP } from 'shared/utils/helper/convert/governmentTypeToJp.js';
import { useParams } from 'react-router-dom';

const GovernmentUserEntryProvider = ({ type, children }) => {
  const { governmentId } = useParams();
  const { localStorageGovernmentType: governmtnType } = useLocalStorage();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const { procedureType } = useParams();
  const [government, setGovernment] = useState([]);
  const [governmentUser, setGovernmentUser] = useState([]);
  const [governmentUserId, setGovernmentUserId] = useState();

  useEffect(() => {
    setGovernmentUserId(location.state?.userId);
  }, []);

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {}, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      findGovernmentUser(type, governmentUserId).then((result) => {
        setGovernmentUser(result);
      });
    }, [governmentUserId]);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      findGovernment(type, governmentId).then((resultGovernment) => {
        resultGovernment.governmentType = GovernmentTypeToJP(
          resultGovernment.governmentType,
        );
        setGovernment(resultGovernment);
      });
    }, [governmentId]);

    useEffect(() => {
      findGovernmentUser(type, governmentUserId).then((result) => {
        setGovernmentUser(result);
      });
    }, [governmentUserId]);
  }

  return (
    <GovernmentUserEntryContext.Provider
      value={{
        authState,
        breadcrumbs,
        government,
        governmentId,
        governmtnType,
        procedureType,
        governmentUser,
      }}>
      {children}
    </GovernmentUserEntryContext.Provider>
  );
};

export default GovernmentUserEntryProvider;
