import { isNaturalNumber } from 'shared/utils/helper/isNaturalNumber';
import toYenFrom from 'shared/utils/helper/toYenString';
const NONE = 'なし';

export const depositToYen = (deposit) => {
  if (!isNaturalNumber(Number(deposit))) {
    return NONE;
  }
  return `${toYenFrom(deposit)}円`;
};
