import { validateSelected } from "../../../../application/validations.js";

export class RegisterDate {
  #registerDate

  constructor(registerDate) {
    this.#registerDate = registerDate;
  }

  get() {
    return this.#registerDate
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#registerDate)
  }
}
