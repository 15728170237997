import Button from 'interfaces/components/common/Button/Button';
import { checkbox_label_emblem } from 'interfaces/components/LocalGovernmentManagement/emblem/checkbox_label';
import {
  local_government_management_emblem_description1,
  local_government_management_emblem_description2,
  local_government_management_emblem_description3,
} from 'interfaces/components/LocalGovernmentManagement/emblem/local-government-management-emblem';
import { local_government_management_radio2 } from 'interfaces/components/LocalGovernmentManagement/emblem/radio_label';
import { LocalGovernmentManagementEmblemContext } from 'interfaces/pages/LocalGovernmentManagementEmblem/entities/LocalGovernmentManagementEmblemContext';
import { useContext, useEffect, useState } from 'react';

import { validationEmblemForm } from './validationEmblemForm';
import FormCheckboxWithCaution from '../../Form/navi/FormCheckboxWithCaution';
import FormFileWithCaution from '../../Form/navi/FormFileWithCaution';
import FormRadioWithCaution from '../../Form/navi/FormRadioWithCaution';
import FormTextWithCaution from '../../Form/navi/FormTextWithCaution';

import { useNavigate } from 'react-router-dom';

const LocalGovernmentManagementEmblemForm = () => {
  const { government } = useContext(LocalGovernmentManagementEmblemContext);
  const formValues = validationEmblemForm();

  const navigate = useNavigate();
  const backToLocalGovernmentManagement = () => {
    navigate('/navi/local-government-management');
  };

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const validateMessages = (form) => {
      const { validationMessage, data } = form;
      return Object.entries(validationMessage).every(
        ([key, validationMessage]) => {
          const notRequiredKeys = [
            data?.useEmblem === '使用する' ? '' : 'emblemFile',
            data?.useCharacter === '使用する' ? '' : 'characterFile',
            data?.useCharacter === '使用する' ? '' : 'characterName',
          ];
          if (notRequiredKeys.includes(key)) {
            return validationMessage === null || validationMessage === 'TRUE';
          }
          return validationMessage === 'TRUE';
        },
      );
    };

    setIsValid(validateMessages(formValues));
  }, [formValues]);

  useEffect(() => {
    if (
      government?.useEmblem === 'EMBLEM' ||
      government?.useEmblem === 'BOTH'
    ) {
      formValues.init.setUseEmblem('使用する');
    } else {
      formValues.init.setUseEmblem('使用する');
    }
    formValues.init.setEmblemFile(government?.emblemFile ?? '');
    if (
      government?.useCharacter === 'CHARACTER' ||
      government?.useEmblem === 'BOTH'
    ) {
      formValues.init.setUseCharacter('使用する');
    } else {
      formValues.init.setUseCharacter('使用しない');
    }
    formValues.init.setCharacterName(government?.characterName ?? '');
    formValues.init.setCharacterInsteadEmblem(
      government?.characterInsteadEmblem ?? '',
    );
    formValues.init.setCharacterFile(government?.characterFile ?? '');
  }, [government]);

  return (
    <div data-testid="local-government-management-emblem-form">
      <div className="pt-9">
        <div className="property-info-general-form-table">
          <FormRadioWithCaution
            data={formValues.data.useEmblem}
            handler1={(e) => {
              formValues.setters.handleUseEmblem(e);
              formValues.setters.handleEmblemFile(
                { target: { value: formValues.data.emblemFile } },
                e.target.value == '使用する',
              );
            }}
            label={'公章の使用'}
            radio_label={local_government_management_radio2}
          />
          <FormFileWithCaution
            required={formValues.data.useEmblem == '使用する'}
            label={'公章画像ファイル'}
            onlyImage={true}
            caution_description={
              local_government_management_emblem_description1
            }
            handler={(e) => {
              formValues.setters.handleEmblemFile(
                e,
                formValues.data.useEmblem == '使用する',
              );
            }}
            invalidMessage={formValues.validationMessage.emblemFile}
          />
        </div>
      </div>
      <div className="pt-9">
        <div className="property-info-general-form-table">
          <FormRadioWithCaution
            data={formValues.data.useCharacter}
            handler1={(e) => {
              formValues.setters.handleUseCharacter(e);
              formValues.setters.handleCharacterName(
                { target: { value: formValues.data.characterName } },
                e.target.value == '使用する',
              );
              formValues.setters.handleCharacterFile(
                { target: { value: formValues.data.characterFile } },
                e.target.value == '使用する',
              );
            }}
            label={'キャラクターの使用'}
            radio_label={local_government_management_radio2}
          />
          <FormTextWithCaution
            label={'キャラクター名'}
            maxCharacters={20}
            required={formValues.data.useCharacter == '使用する'}
            caution_description={
              local_government_management_emblem_description2
            }
            data={formValues.data.characterName}
            handler1={(e) =>
              formValues.setters.handleCharacterName(
                e,
                formValues.data.useCharacter == '使用する',
              )
            }
            invalidMessage={formValues.validationMessage.characterName}
          />
          <FormCheckboxWithCaution
            caution_description={
              local_government_management_emblem_description3
            }
            checkbox_label={checkbox_label_emblem}
            data={formValues.data.characterInsteadEmblem}
            label={'イチオシ物件の表示にキャラクター画像を使用する'}
            handler={formValues.setters.handleCharacterInsteadEmblem}
          />
          <FormFileWithCaution
            label={'キャラクター画像ファイル'}
            required={formValues.data.useCharacter == '使用する'}
            onlyImage={true}
            caution_description={
              local_government_management_emblem_description1
            }
            handler={formValues.setters.handleCharacterFile}
            invalidMessage={formValues.validationMessage.characterFile}
          />
        </div>
      </div>
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'登録'}
              isOutline={false}
              isDisabled={!isValid}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_primary-button'
              }
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_normal-button'
              }
              onClick={backToLocalGovernmentManagement}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalGovernmentManagementEmblemForm;
