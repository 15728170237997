import AuctionDepositProvider from './AuctionDepositProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { auctionDeposit } from "../entities/componentMapping.js";

const AuctionDeposit = ({ type }) => {
  return (
    <AuctionDepositProvider type={type}>
      {getComponent(auctionDeposit, type)}
    </AuctionDepositProvider>
  );
};

export default AuctionDeposit;
