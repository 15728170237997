import CommonTextArea from 'interfaces/components/common/Input/CommonTextArea.jsx';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault.jsx';
import { memo } from 'react';

const RequestInput = memo(function RequestInput({
  deliveryRequest,
  setDeliveryRequest,
}) {
  return (
    <CommonTableDefault>
      <tr>
        <td className="w-expand">
          <CommonTextArea
            text={deliveryRequest}
            handleChange={(e) => setDeliveryRequest(e.target.value)}
            rows={8}
            maxChar={255}
          />
        </td>
      </tr>
    </CommonTableDefault>
  );
});

export default RequestInput;
