
export class Sex {
  #sex

  constructor(sex) {
    this.#sex = sex;
  }

  get() {
    return this.#sex
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
