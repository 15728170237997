
export class SourceType {
  #sourceType

  constructor(sourceType) {
    this.#sourceType = sourceType;
  }

  get() {
    return this.#sourceType
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
