import Button from "interfaces/components/common/Button";
import PageTitle from "interfaces/components/common/PageTitle";
import SearchInput from "interfaces/components/common/SearchInput";
import Toolbar from "interfaces/components/common/Toolbar";
import {useState} from "react";

import AdvanceNoticeFormModal from "./AdvanceNoticeFormModal";
import NoticeFormModal from "./NoticeFormModal";
import NoticeTable from "./NoticeTable";

const Notices = () => {
  // 新規作成時のモーダル制御
  const [isNoticeOpen, setIsNoticeOpen] = useState(false);

  // 農地事前告知新規作成のモーダル制御
  const [isAdvanceNoticeOpen, setIsAdvanceNoticeOpen] = useState(false);

  const [searchInput, setSearchInput] = useState('');
  const handleChangeSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div className="kmanager-notices" data-testid="kmanager-notices">
      {
        isNoticeOpen ? <NoticeFormModal closeModal={() => setIsNoticeOpen(false)}/> : ''
      }
      {
        isAdvanceNoticeOpen ? <AdvanceNoticeFormModal closeModal={() => setIsAdvanceNoticeOpen(false)}/> : ''
      }
      <div className="kmanager-row">
        <div className="kmanager-col kmanager-col-6">
          <PageTitle text="お知らせ管理"/>
        </div>

        <div className="kmanager-col">
          <SearchInput onChange={handleChangeSearchInput}/>
        </div>

        <div className="kmanager-col kmanager-col-12">
          <div className="box-shadow">
            <Toolbar>
              <Button
                  className="primary"
                  text="新規作成"
                  clickHandler={() => setIsNoticeOpen(prevState => !prevState)}
              />
              <Button
                  className="lightBlue"
                  text="農地情報の事前告知"
                  clickHandler={() => setIsAdvanceNoticeOpen(prevState => !prevState)}
              />
            </Toolbar>
            <NoticeTable searchInput={searchInput}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notices;
