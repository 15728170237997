import {useServiceProviderInputs} from "./hook/useServiceProviderInputs.jsx";
import {UseServiceProviderInputsValidation} from "./hook/useServiceProviderInputsValidation.jsx";

export const validateInputsServiceProvider = () => {
  const formInputs = useServiceProviderInputs();
  const validate = UseServiceProviderInputsValidation();

  // 【KM】User
  function handlePostalCode(e) {
    validate.setters.postalCode(e)
    formInputs.setters.postalCode(e.target.value)
    console.log(validate.validationMessage.postalCode)
  }
  function handleAddress(e) {
    validate.setters.address(e)
    formInputs.setters.address(e.target.value)
    console.log(validate.validationMessage.address)
  }
  function handleName(e) {
    validate.setters.name(e)
    formInputs.setters.name(e.target.value)
    console.log(validate.validationMessage.name)
  }
  function handlePosition(e) {
    validate.setters.position(e)
    formInputs.setters.position(e.target.value)
    console.log(validate.validationMessage.position)
  }
  function handleCeoName(e) {
    validate.setters.ceoName(e)
    formInputs.setters.ceoName(e.target.value)
    console.log(validate.validationMessage.ceoName)
  }
  function handleRegistrationNumber(e) {
    validate.setters.registrationNumber(e)
    formInputs.setters.registrationNumber(e.target.value)
    console.log(validate.validationMessage.registrationNumber)
  }
  function handleBankName(e) {
    validate.setters.bankName(e)
    formInputs.setters.bankName(e.target.value)
    console.log(validate.validationMessage.bankName)
  }
  function handleBranchName(e) {
    validate.setters.branchName(e)
    formInputs.setters.branchName(e.target.value)
    console.log(validate.validationMessage.branchName)
  }
  function handleDepositType(e) {
    validate.setters.depositType(e)
    formInputs.setters.depositType(e.target.value)
    console.log(validate.validationMessage.depositType)
  }
  function handleAccountNumber(e) {
    validate.setters.accountNumber(e)
    formInputs.setters.accountNumber(e.target.value)
    console.log(validate.validationMessage.accountNumber)
  }
  function handleAccountHolder(e) {
    validate.setters.accountHolder(e)
    formInputs.setters.accountHolder(e.target.value)
    console.log(validate.validationMessage.accountHolder)
  }
  function handleContactPerson(e) {
    validate.setters.contactPerson(e)
    formInputs.setters.contactPerson(e.target.value)
    console.log(validate.validationMessage.contactPerson)
  }
  function handleContactDepartment(e) {
    validate.setters.contactDepartment(e)
    formInputs.setters.contactDepartment(e.target.value)
    console.log(validate.validationMessage.contactDepartment)
  }
  function handleContactPaEmail(e) {
    validate.setters.contactPaEmail(e)
    formInputs.setters.contactPaEmail(e.target.value)
    console.log(validate.validationMessage.contactPaEmail)
  }
  function handleContactPpsEmail(e) {
    validate.setters.contactPpsEmail(e)
    formInputs.setters.contactPpsEmail(e.target.value)
    console.log(validate.validationMessage.contactPpsEmail)
  }
  function handleConfirmer(e) {
    validate.setters.confirmer(e)
    formInputs.setters.confirmer(e.target.value)
    console.log(validate.validationMessage.confirmer)
  }
  function handleIssuer(e) {
    validate.setters.issuer(e)
    formInputs.setters.issuer(e.target.value)
    console.log(validate.validationMessage.issuer)
  }
  function handleRemarks(e) {
    validate.setters.remarks(e)
    formInputs.setters.remarks(e.target.value)
    console.log(validate.validationMessage.remarks)
  }

  return [
    // 【KM】User
    {
      type: formInputs.type.postalCode,
      column: 'postalCode',
      text: '郵便番号',
      validationMessage: validate.validationMessage.postalCode,
      state: formInputs.data.postalCode,
      init: formInputs.setters.postalCode,
      setters: handlePostalCode
    },
    {
      type: formInputs.type.address,
      column: 'address',
      text: '住所',
      validationMessage: validate.validationMessage.address,
      state: formInputs.data.address,
      init: formInputs.setters.address,
      setters: handleAddress
    },
    {
      type: formInputs.type.name,
      column: 'name',
      text: '名前',
      validationMessage: validate.validationMessage.name,
      state: formInputs.data.name,
      init: formInputs.setters.name,
      setters: handleName
    },
    {
      type: formInputs.type.position,
      column: 'position',
      text: '役職',
      validationMessage: validate.validationMessage.position,
      state: formInputs.data.position,
      init: formInputs.setters.position,
      setters: handlePosition
    },
    {
      type: formInputs.type.ceoName,
      column: 'ceoName',
      text: '社長名',
      validationMessage: validate.validationMessage.ceoName,
      state: formInputs.data.ceoName,
      init: formInputs.setters.ceoName,
      setters: handleCeoName
    },
    {
      type: formInputs.type.registrationNumber,
      column: 'registrationNumber',
      text: '登録番号',
      validationMessage: validate.validationMessage.registrationNumber,
      state: formInputs.data.registrationNumber,
      init: formInputs.setters.registrationNumber,
      setters: handleRegistrationNumber
    },
    {
      type: formInputs.type.bankName,
      column: 'bankName',
      text: '銀行名',
      validationMessage: validate.validationMessage.bankName,
      state: formInputs.data.bankName,
      init: formInputs.setters.bankName,
      setters: handleBankName
    },
    {
      type: formInputs.type.branchName,
      column: 'branchName',
      text: '支店名',
      validationMessage: validate.validationMessage.branchName,
      state: formInputs.data.branchName,
      init: formInputs.setters.branchName,
      setters: handleBranchName
    },
    {
      type: formInputs.type.depositType,
      column: 'depositType',
      text: '預金種目',
      validationMessage: validate.validationMessage.depositType,
      state: formInputs.data.depositType,
      init: formInputs.setters.depositType,
      setters: handleDepositType
    },
    {
      type: formInputs.type.accountNumber,
      column: 'accountNumber',
      text: '口座番号',
      validationMessage: validate.validationMessage.accountNumber,
      state: formInputs.data.accountNumber,
      init: formInputs.setters.accountNumber,
      setters: handleAccountNumber
    },
    {
      type: formInputs.type.accountHolder,
      column: 'accountHolder',
      text: '名義人',
      validationMessage: validate.validationMessage.accountHolder,
      state: formInputs.data.accountHolder,
      init: formInputs.setters.accountHolder,
      setters: handleAccountHolder
    },
    {
      type: formInputs.type.contactPerson,
      column: 'contactPerson',
      text: '担当者名',
      validationMessage: validate.validationMessage.contactPerson,
      state: formInputs.data.contactPerson,
      init: formInputs.setters.contactPerson,
      setters: handleContactPerson
    },
    {
      type: formInputs.type.contactDepartment,
      column: 'contactDepartment',
      text: '担当部署',
      validationMessage: validate.validationMessage.contactDepartment,
      state: formInputs.data.contactDepartment,
      init: formInputs.setters.contactDepartment,
      setters: handleContactDepartment
    },
    {
      type: formInputs.type.contactPaEmail,
      column: 'contactPaEmail',
      text: '公売のお問い合わせ先のメールアドレス',
      validationMessage: validate.validationMessage.contactPaEmail,
      state: formInputs.data.contactPaEmail,
      init: formInputs.setters.contactPaEmail,
      setters: handleContactPaEmail
    },
    {
      type: formInputs.type.contactPpsEmail,
      column: 'contactPpsEmail',
      text: '公有財産売却のお問い合わせ先のメールアドレス',
      validationMessage: validate.validationMessage.contactPpsEmail,
      state: formInputs.data.contactPpsEmail,
      init: formInputs.setters.contactPpsEmail,
      setters: handleContactPpsEmail
    },
    {
      type: formInputs.type.confirmer,
      column: 'confirmer',
      text: '確認者',
      validationMessage: validate.validationMessage.confirmer,
      state: formInputs.data.confirmer,
      init: formInputs.setters.confirmer,
      setters: handleConfirmer
    },
    {
      type: formInputs.type.issuer,
      column: 'issuer',
      text: '発行者',
      validationMessage: validate.validationMessage.issuer,
      state: formInputs.data.issuer,
      init: formInputs.setters.issuer,
      setters: handleIssuer
    },
    {
      type: formInputs.type.remarks,
      column: 'remarks',
      text: '備考欄',
      validationMessage: validate.validationMessage.remarks,
      state: formInputs.data.remarks,
      init: formInputs.setters.remarks,
      setters: handleRemarks
    },


  ];
}

