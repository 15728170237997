// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.government .information {
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.government .information-label {
  padding: 10px;
  border-radius: 4px;
}

.government .preview-info, .contact-info, .default-info {
  text-align: center;
  padding: 16px 0 24px;
}

.government .information-title, .contact-title {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 16px;
}

.government .default-info {
  font-size: .75rem;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Government/information.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,gBAAgB;EAChB,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".government .information {\n  font-size: .875rem;\n  line-height: 1.25rem;\n  font-weight: 500;\n  font-family: \"Roboto\", sans-serif;\n}\n\n.government .information-label {\n  padding: 10px;\n  border-radius: 4px;\n}\n\n.government .preview-info, .contact-info, .default-info {\n  text-align: center;\n  padding: 16px 0 24px;\n}\n\n.government .information-title, .contact-title {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  margin-bottom: 16px;\n}\n\n.government .default-info {\n  font-size: .75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
