import {
  DEPOSIT_FLAG,
  OPEN_WAY,
  PROCEDURE_NO,
  SEARCH_PRICE_HIGH,
  SEARCH_PRICE_LOW,
  SEARCH_STATUS,
  TYPE,
} from 'shared/utils/constants/queryParam';
import { validateLessMinimumAmount } from 'shared/utils/helper/serviceValidators';

import {
  DepositFlag,
  depositFlagNameToQueryParam,
  OpenWayData,
  openWayNameToQueryParam,
  priceHighToQueryParamValue,
  ProcedureNo,
  procedureNoNameToQueryParam,
  searchPrice,
  SearchStatusData,
  searchStatusToQueryParam,
} from './generalSearchConditions';
import BetweenValueGroup from '../PropertyList/BetweenValueGroup';
import CheckBoxGroup from '../PropertyList/CheckBoxGroup';
import RadioButtonGroup from '../PropertyList/RadioButtonGroup';
import SelectedValue from '../PropertyList/SelectedValue';

//import {priceToQueryParamValue} from "./general_search_conditions";

export const itemNameToQueryParamValue = {
  土地付き建物: 'BUILDING_WITH_LAND',
  区分所有建物: 'SECTIONAL_OWNERSHIP_BUILDING',
  宅地: 'RESIDENTIAL_LAND',
  山林: 'MOUNTAIN_FOREST',
  その他土地: 'OTHER_LAND',
  底地: 'BOTTOM_LAND',
  その他: 'OTHER',
};

export const idToPrefectureName = {
  1: '北海道',
  2: '青森県',
  3: '岩手県',
  4: '宮城県',
  5: '秋田県',
  6: '山形県',
  7: '福島県',
  8: '茨城県',
  9: '栃木県',
  10: '群馬県',
  11: '埼玉県',
  12: '千葉県',
  13: '東京都',
  14: '神奈川県',
  15: '山梨県',
  16: '新潟県',
  17: '長野県',
  18: '富山県',
  19: '石川県',
  20: '福井県',
  21: '岐阜県',
  22: '静岡県',
  23: '愛知県',
  24: '三重県',
  25: '滋賀県',
  26: '京都府',
  27: '大阪府',
  28: '兵庫県',
  29: '奈良県',
  30: '和歌山県',
  31: '鳥取県',
  32: '島根県',
  33: '岡山県',
  34: '広島県',
  35: '山口県',
  36: '徳島県',
  37: '香川県',
  38: '愛媛県',
  39: '高知県',
  40: '福岡県',
  41: '佐賀県',
  42: '長崎県',
  43: '熊本県',
  44: '大分県',
  45: '宮崎県',
  46: '鹿児島',
  47: '沖縄県',
};

export const type = [
  {
    id: 1,
    key: TYPE,
    label: '',
    items: [
      '土地付き建物',
      '区分所有建物',
      '宅地',
      '山林',
      'その他土地',
      '底地',
      'その他',
    ],
    queryParam: itemNameToQueryParamValue,
    value: '',
  },
];

export const EstateSearchConditionsData = [
  {
    id: 1,
    title: '物件の種類',
    selectedValue: (
      <SelectedValue
        displayType="normal"
        getQueryParam={TYPE}
        convertTable={itemNameToQueryParamValue}
      />
    ),
    body: <BetweenValueGroup data={type} />,
  },
  {
    id: 2,
    title: '実施状況',
    selectedValue: (
      <SelectedValue
        displayType="commaSplit"
        getQueryParam={SEARCH_STATUS}
        convertTable={searchStatusToQueryParam}
      />
    ),
    body: <CheckBoxGroup data={SearchStatusData} />,
  },
  {
    id: 3,
    title: '保証金',
    selectedValue: (
      <SelectedValue
        displayType="commaSplit"
        getQueryParam={DEPOSIT_FLAG}
        convertTable={depositFlagNameToQueryParam}
      />
    ),
    body: <CheckBoxGroup data={DepositFlag} />,
  },
  {
    id: 4,
    title: '実施方法',
    selectedValue: (
      <SelectedValue
        displayType="normal"
        getQueryParam={OPEN_WAY}
        convertTable={openWayNameToQueryParam}
      />
    ),
    body: <RadioButtonGroup data={OpenWayData} />,
  },
  {
    id: 5,
    title: 'オークション種別',
    selectedValue: (
      <SelectedValue
        displayType="normal"
        getQueryParam={PROCEDURE_NO}
        convertTable={procedureNoNameToQueryParam}
      />
    ),
    body: <RadioButtonGroup data={ProcedureNo} />,
  },
  {
    id: 6,
    title: '金額範囲',
    selectedValue: (
      <SelectedValue
        displayType="between"
        getQueryParam={[SEARCH_PRICE_LOW, SEARCH_PRICE_HIGH]}
        convertTable={priceHighToQueryParamValue}
      />
    ),
    body: (
      <BetweenValueGroup
        data={searchPrice}
        validation={validateLessMinimumAmount}
      />
    ),
  },
];
