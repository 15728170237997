import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { findById } from '../../../../domain/Auction/services/Auction.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { KMANAGER } from '../../../../shared/utils/helper/constants.js';
import { AuctionEditContext } from '../entities/AuctionEditContext.js';

const AuctionEditProvider = ({ type, children }) => {
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const { auctionId } = useParams();
  const [auction, setAuction] = useState(null);

  if (type === KMANAGER) {
    useEffect(() => {
      findById(type, auctionId).then((result) => {
        setAuction(result);
      });
    }, []);
  }

  return (
    <AuctionEditContext.Provider
      value={{
        authState,
        auction,
      }}>
      {children}
    </AuctionEditContext.Provider>
  );
};

export default AuctionEditProvider;
