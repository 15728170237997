
export class CharacterPathName {
    #characterPathName

    constructor(characterPathName) {
        this.#characterPathName = characterPathName;
    }

    get() {
        return this.#characterPathName
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
