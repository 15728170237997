import Breadcrumb from 'interfaces/components/common/Breadcrumb';

import { breadcrumbs } from 'interfaces/pages/SampleAfterWinningBidCaution/entities/breadcrumbs.js';

import 'interfaces/css/SampleGuideline/important-caution.css';
import EmailSwitcher from '../Email/EmailSwitcher';
import Heading2 from 'interfaces/components/common/Heading/Heading2';

import UsageCautionListAssetSale from 'interfaces/components/SampleAfterWinningBidCaution/UsageCautionListAssetSale';
import UsageCautionListAuction from 'interfaces/components/SampleAfterWinningBidCaution/UsageCautionListAuction';
import TemplateAfterWinningBidAssetSale from 'interfaces/components/SampleAfterWinningBidCaution/TemplateAfterWinningBidAssetSale';
import TemplateAfterWinningBidAuction from 'interfaces/components/SampleAfterWinningBidCaution/TemplateAfterWinningBidAuction';

import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

const SampleAfterWinningBidCaution = () => {
  const navigate = useNavigate();
  const { localStorageProcedureType } = useLocalStorage();

  const labels = [
    {
      label: '落札後の注意事項サンプル',
      handleClick: () => navigate('/navi/faq/sample-after-winning-bid'),
    },
    {
      label: '記入文言の注意事項',
      handleClick: () =>
        navigate('/navi/faq/sample-after-winning-bid-caution', {
          state: { from: 'sample-after-winning-bid' },
        }),
    },
  ];
  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <EmailSwitcher labels={labels} activeTabIndex={0} />
      <Heading2 title="落札後の注意事項サンプル" />
      {localStorageProcedureType === 'ASSET_SALE' ? (
        <UsageCautionListAssetSale />
      ) : (
        <UsageCautionListAuction />
      )}
      {localStorageProcedureType === 'ASSET_SALE' ? (
        <TemplateAfterWinningBidAssetSale />
      ) : (
        <TemplateAfterWinningBidAuction />
      )}

      {/* <div>
        <div className="pt-6" data-testid="usage-caution-list-asset-sale-wrap">
          <div className="usage-caution-list-title">ご注意</div>
          <ul className="usage-caution-list pl-6">
            <li className="usage-caution-list-item">
              落札後の注意事項を作成する場合は●●部分に各行政機関様の名称を入れてください。
            </li>
            <li className="usage-caution-list-item">
              あくまでサンプルとなりますので、各行政機関様で追記、編集などをしていただいてご利用ください。
            </li>
            <li className="usage-caution-list-item">
              ただし、青字部分は自動で挿入されます。修正はできません。
            </li>
          </ul>
        </div>
        <div className="pt-6" data-testid="usage-caution-list-auction-wrap">
          <div className="usage-caution-list-title">ご注意</div>
          <ul className="usage-caution-list pl-6">
            <li className="usage-caution-list-item">
              落札後の注意事項を作成する場合は●●部分に各行政機関様の名称を入れてください。
            </li>
            <li className="usage-caution-list-item">
              あくまでサンプルとなりますので、各行政機関様で追記、編集などをしていただいてご利用ください。所有権移転の登記完了の目安など仮で●としているものがございます。想定では「2か月程度」が見込まれますが、各行政機関様で嘱託調査期間が違うことが予想されますので、こちらは、各行政機関様で期間をご記入ください。
            </li>
            <li className="usage-caution-list-item">
              ただし、青字部分は自動で挿入されます。修正はできません。
            </li>
          </ul>
        </div>
        <div data-testid="template-after-winning-bid-asset-sale" className="pt-9">
          <Heading3 text="落札後の注意事項" isBgGray={true} />
          <RightsTransferProcedureAssetSaleSection />
          <ImportantMatterAssetSaleSection />
        </div>
        <div data-testid="rights-transfer-procedure-section" className="pt-6">
          <Heading3 text="権利移転手続き" isLeftBorderStyle={true} />
          <p
            className="sample-after-winning-bid-lead-text pt-2"
            data-testid="sample-after-winning-bid-lead-text">
            入札終了後に行政機関が落札者へメールにて、落札物件の区分番号、行政機関の連絡先などをお知らせします。メールを確認後、できるだけ早く行政機関へ連絡し、権利移転手続きについて説明を受けてください。
          </p>
          <NecessaryExpensesAssetSale />
          <RequiredDocumentsAssetSale />
          <RightsTransferCautionAssetSale />
          <RightsTransferTimingAndRequiredPeriodAssetSale />
        </div>
        <div data-testid="necessary-expenses" className="pt-9">
          <SmallTopicTitle text="必要な費用" isFontBold={true} />
          <CautionTopicTable
            recordData={contractConclusionDeadlineData}
            caption="契約締結期限までに"
          />
          <CautionTopicTable
            recordData={paymentDeadlineData}
            caption="代金納付期限までに"
          />
          <CautionManualMarkedList
            title="ご注意"
            className="pt-3"
            data={[
              {
                text: '上記費用は、それぞれ必要な期限までに一括で納付してください。',
              },
              {
                text: 'なお、自動車の権利移転に伴う費用（自動車検査登録印紙および自動車審査証紙、自動車税環境性能割など）は落札者の負担となります。',
              },
              {
                text: '上記以外に書類の郵送料、振込手数料が必要になることがあります。',
              },
            ]}
          />
        </div>
        <div data-testid="required-documents" className="pt-9">
          <SmallTopicTitle text="必要な書類" isFontBold={true} />
          {data.map((item, index) => (
            <ManualMarkedList
              data={item.cautions}
              className={item.className}
              key={index}
            />
          ))}
        </div>
        <div className="pt-9" data-testid="rights-transfer-caution">
          <SmallTopicTitle
            text="落札者（落札者が法人の場合は代表者）以外の方が権利移転手続きを行う場合"
            isFontBold={true}
          />
          <p
            className="sample-after-winning-bid-lead-text pt-2 pb-3"
            data-testid="sample-after-winning-bid-lead-text">
            落札者本人（落札者が法人の場合はその代表者）が売払代金の支払い手続きを行えない場合、代理人が売却代金の支払い手続きを代行できます。その場合、委任状、落札者本人と代理人双方の印鑑登録証明書および代理人の本人確認書面が必要となります。
          </p>
          <ManualMarkedList data={data} />
        </div>
        <div
          className="pt-9"
          data-testid="rights-transfer-timing-and-required-period">
          <SmallTopicTitle text="権利移転の時期と要する期間" isFontBold={true} />
          <CautionTopicTable
            recordData={timingAndRequiredPeriodData}
            className="mt-3"
          />
        </div>
        <div data-testid="important-matter-section" className="pt-16">
          <Heading3 text="重要事項" isLeftBorderStyle={true} />
          <p
            className="sample-after-winning-bid-lead-text pt-2 pb-3"
            data-testid="sample-after-winning-bid-lead-text">
            落札後の権利移転手続きにおける重要な事項です。必ずご確認ください。
          </p>
          <CautionTopicTable recordData={importantMatterTableData} />
          <CautionTopicTable
            recordData={contactTableData}
            className="mt-16"
            caption="落札後の注意事項に関するお問い合わせ"
            hasCaptionBgColor={true}
          />
        </div>
        <div data-testid="template-after-winning-bid-auction" className="pt-9">
          <Heading3 text="落札後の注意事項" isBgGray={true} />
          <RightsTransferProcedureAuctionSection />
          <ImportantMatterAuctionSection />
        </div>
        <div data-testid="rights-transfer-procedure-section" className="pt-6">
          <Heading3 text="権利移転手続き" isLeftBorderStyle={true} />
          <p
            className="sample-after-winning-bid-lead-text pt-2"
            data-testid="sample-after-winning-bid-lead-text">
            入札終了後に執行機関が落札者などへメールにて、落札した公売物件の売却区分番号、執行機関の所在および連絡先などをお知らせします。メール確認後できるだけ早く、執行機関連絡先へ電話にて連絡をし、権利移転手続きについて説明を受けてください。
          </p>
          <NecessaryExpensesAuction />
          <RequiredDocumentsAuction />
          <RightsTransferDescriptionAuction />
          <RightsTransferCautionAuction />
          <RightsTransferTimingAndRequiredPeriodAuction />
        </div>
        <div data-testid="necessary-expenses" className="pt-9">
          <SmallTopicTitle text="必要な費用" isFontBold={true} />
          <CautionTopicTable
            recordData={NecessaryExpensesData}
            className="mt-3"
          />
          <CautionManualMarkedList
            title="ご注意"
            className="pt-3"
            data={[
              {
                mark: '・',
                text: '必要な費用は、一括で納付してください。また、買受代金納付期限までに、執行機関が納付を確認できる必要があります。',
              },
              {
                mark: '・',
                text: '上記以外に必要書類の郵送料、物件の配送料、振込み手数料、その他所有権移転などに伴う費用は落札者の負担となります。',
              },
            ]}
          />
        </div>
        <div data-testid="required-documents" className="pt-9">
          <SmallTopicTitle text="必要な書類" isFontBold={true} />
          <ManualMarkedList
            className="pt-2"
            data={[
              {
                mark: '・',
                text: '必要な書類の一部は●●●ホームページからダウンロードできます。',
                isImportant: true,
              },
            ]}
          />
          <CautionTopicTable
            recordData={requiredDocumentsData}
            className="mt-3"
          />
          <CautionManualMarkedList
            title="ご注意"
            className="pt-3"
            data={[
              {
                mark: '・',
                text: '上記書類は、買受代金納付期限までに執行機関へ提出してください。',
              },
            ]}
          />
        </div>
        <div className="pt-9" data-testid="rights-transfer-description">
          <SmallTopicTitle text="物件の権利移転について" isFontBold={true} />
          <CautionTopicTable recordData={rightsTransferData} className="mt-3" />
          <CautionManualMarkedList
            title="ご注意"
            className="pt-3"
            data={[
              {
                text: '自動車を落札した方の「使用の本拠の位置」を管轄する運輸支局および自動車検査登録事務所が、前所有者（現在の登録を受けている所有者）と異なる場合、落札者自身の「使用の本拠の位置」を管轄する運輸支局及び自動車検査登録事務所に当該自動車を持ち込む必要があります。',
              },
            ]}
          />
        </div>
        <div className="pt-9" data-testid="rights-transfer-caution">
          <SmallTopicTitle
            text="落札者（落札者が法人の場合は代表者）以外の方が権利移転手続きを行う場合"
            isFontBold={true}
          />
          <p
            className="sample-after-winning-bid-lead-text pt-2"
            data-testid="sample-after-winning-bid-lead-text">
            落札者本人（落札者が法人の場合はその代表者）が買受代金の支払いまたは公売物件の引き取りを行えない場合、代理人が買受代金の支払いまたは公売物件の引き取りを行えます。その場合、
            <span className="font-weight-bold">
              委任状、落札者本人と代理人双方の印鑑証明および代理人の本人確認書面が必要
            </span>
            となります。
          </p>
          <CautionManualMarkedList
            title="ご注意"
            className="pt-3"
            data={[
              {
                text: '落札者が法人で、法人の従業員の方が支払いまたは引き取りを行う場合もその従業員が代理人となり、委任状などが必要となります。',
              },
            ]}
          />
        </div>
        <div
          className="pt-9"
          data-testid="rights-transfer-timing-and-required-period">
          <SmallTopicTitle text="権利移転の時期" isFontBold={true} />
          <p
            className="sample-after-winning-bid-lead-text pt-2"
            data-testid="sample-after-winning-bid-lead-text">
            買受代金を納付した時点で、その物件の所有権などの権利は落札者に移転します。
            <br />
            ※ただし、公売物件が農地の場合は都道府県知事などの許可などを受けた時点となります。
          </p>
        </div>
        <div data-testid="important-matter-section" className="pt-16">
          <Heading3 text="重要事項" isLeftBorderStyle={true} />
          <p
            className="sample-after-winning-bid-lead-text pt-2 pb-9"
            data-testid="sample-after-winning-bid-lead-text">
            落札後の権利移転手続きにおける重要な事項です。必ずご確認ください。
          </p>
          <SmallTopicTitle text="必要な費用" isFontBold={true} />
          <CautionTopicTable
            recordData={importantMatterTableData}
            className="mt-3"
          />
          <CautionManualMarkedList
            title="ご注意"
            className="pt-3"
            data={[
              {
                text: '入札方法が入札形式による公売で、公売物件が不動産などの場合、売却決定を受けた次順位買受申込者も落札者に含みます。',
              },
            ]}
          />
          <CautionTopicTable
            recordData={contactTableData}
            className="mt-16"
            caption="落札後の注意事項に関するお問い合わせ"
            hasCaptionBgColor={true}
          />
        </div>
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <Switcher labels={labels} activeTabIndex={0} />
        <Heading2 title="落札後の注意事項サンプル" />
        {localStorageProcedureType === 'ASSET_SALE' ? (
          <UsageCautionListAssetSale />
        ) : (
          <UsageCautionListAuction />
        )}
        {localStorageProcedureType === 'ASSET_SALE' ? (
          <TemplateAfterWinningBidAssetSale />
        ) : (
          <TemplateAfterWinningBidAuction />
        )}
      </div> */}
    </>
  );
};

export default SampleAfterWinningBidCaution;
