import { validateSelected } from "../../../../application/validations.js";

export class IsDisplayBid {
  #isDisplayBid

  constructor(isDisplayBid) {
    this.#isDisplayBid = isDisplayBid;
  }

  get() {
    return this.#isDisplayBid
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#isDisplayBid)
  }
}
