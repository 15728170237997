
export class AdditionalBidEnd {
  #additionalBidEnd

  constructor(additionalBidEnd) {
    this.#additionalBidEnd = additionalBidEnd;
  }

  get() {
    return this.#additionalBidEnd
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
