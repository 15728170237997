import axios from 'axios';

import {API_BASE_URL} from "../../config/envConstants.js";

export const createNaviDivisionsData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/navi/divisions`,
    body, {headers: headers}
  );

};
export const updateNaviDivisionsData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/navi/divisions/${id}`,
    body, {headers: headers}
  );
};
export const deleteNaviDivisionsData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/navi/divisions/${id}`, {
      headers: headers,
    },);
};