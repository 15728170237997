import { isDefinedDateTime, isAfterNow } from "../../../../application/validations.js";

export class OpenAt {
  #openAt

  constructor(openAt) {
    this.#openAt = openAt;
  }

  get() {
    return this.#openAt
  }

  get isValid() {
    return [
      this.isDefinedDateTime,
      this.isAfterNow,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get isDefinedDateTime() {
    return isDefinedDateTime(this.#openAt)
  }

  // バリデーションルールの適用
  get isAfterNow() {
    return isAfterNow(this.#openAt)
  }
}
