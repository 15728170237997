import 'interfaces/css/common/toggle.css'

const Toggle = ({message="", handler}) => (
  <form data-testid="toggle">
    <div className="toggle-button">
      <input id="toggle" type="checkbox" onChange ={handler}/>
      <label htmlFor="toggle"/>
      {message}
      <br/>
    </div>
  </form>
)

export default Toggle;