import AuctionUsageFee from "interfaces/components/Auction/kmanager/AuctionUsageFee/AuctionUsageFee";

const KmanagerAuctionUsageFee = () => {
    return (
        <div data-testid="kmanager-auction-government">
            <AuctionUsageFee/>
        </div>
    );
}

export default KmanagerAuctionUsageFee;