import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class Other {
  #other

  constructor(other) {
    this.#other = other;
  }

  get() {
    return this.#other
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#other, 0, 200)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#other)
  }
}
