import GovernmentScheduleRow from './ScheduleRow/GovernmentScheduleRow.jsx';
import NearlyScheduleRow from './ScheduleRow/NearlyScheduleRow.jsx';
import PropertyScheduleRow from './ScheduleRow/PropertyScheduleRow.jsx';

const ScheduleRow = ({ scheduleType, termData, magnification, title }) => {
  if (scheduleType === 'GOVERNMENT') {
    return <GovernmentScheduleRow
      termData={termData}
      magnification={magnification}
    />;
  }
  if (scheduleType === 'PROPERTY') {
    return <PropertyScheduleRow
      termData={termData}
      magnification={magnification}
    />;
  }
  if (scheduleType === 'NEARLY') {
    return <NearlyScheduleRow
      termData={termData}
      magnification={magnification}
      title={title}
    />;
  }
};

export default ScheduleRow;
