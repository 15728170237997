// ロジックを含む処理
// フロントから呼ぶ関数
// マイリスト

import {
  createFavoriteData,
  updateFavoriteData,
  deleteFavoriteData,
} from '../../../infrastructure/api/Favorite.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { FavoriteCreate } from '../entities/Favorite.js';
import { getFavorite } from '../repositories/Favorite.js';

export async function get(type) {
  return await getFavorite(type);
}

export async function findById(type, id) {
  const Favorite = await getFavorite(type);
  return Favorite.find((data) => data.auctionUserId === id);
}

export async function whereById(type, id) {
  const Favorite = await getFavorite(type);
  return Favorite.filter((data) => data.auctionUserId === id);
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    const favorite = new FavoriteCreate(data);
    response = await createFavoriteData(favorite);
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Favorite)');
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Favorite)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Favorite', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    const favorite = new FavoriteCreate(data);
    response = await updateFavoriteData(favorite);
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Favorite)');
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Favorite)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Favorite', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    const favorite = new FavoriteCreate(data);
    response = deleteFavoriteData(favorite);
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Favorite)');
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Favorite)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Favorite', response);
  }
  return response;
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
