// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.local-government-management-description {
    font-size: 14px;
    padding-bottom: 12px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/LocalGovernmentManagement/local-government-management.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,oBAAoB;AACxB","sourcesContent":[".local-government-management-description {\n    font-size: 14px;\n    padding-bottom: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
