
export class PromotionFrameId {
  #promotionFrameId

  constructor(promotionFrameId) {
    this.#promotionFrameId = promotionFrameId;
  }

  get() {
    return this.#promotionFrameId
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
