// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-detail .announce-body {
    font-size: .875rem!important;
    line-height: 1.25rem;
    letter-spacing: .0178571429em!important;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertyDetail/property-detail-announce.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,oBAAoB;IACpB,uCAAuC;AAC3C","sourcesContent":[".property-detail .announce-body {\n    font-size: .875rem!important;\n    line-height: 1.25rem;\n    letter-spacing: .0178571429em!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
