// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.government-filter-button:before {
  border-radius: 4px;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  transition: box-shadow .28s cubic-bezier(.4,0,.2,1);
  will-change: box-shadow;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

.government-filter-button {
  font-size: .9375rem;
  line-height: 1;
  min-height: 48px;
  outline: none;
  padding: 8px 24px;
  position: relative;
  transition: min-height .3s cubic-bezier(.25,.8,.5,1);
  width: 100%;
  box-sizing: border-box;
}

.government-filter-button svg {
  fill: rgba(0,0,0,.54);
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/government-filter-button.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,OAAO;EACP,kBAAkB;EAClB,QAAQ;EACR,MAAM;EACN,WAAW;EACX,mDAAmD;EACnD,uBAAuB;EACvB,mGAAmG;AACrG;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,gBAAgB;EAChB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,oDAAoD;EACpD,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".government-filter-button:before {\n  border-radius: 4px;\n  bottom: 0;\n  content: \"\";\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n  z-index: -1;\n  transition: box-shadow .28s cubic-bezier(.4,0,.2,1);\n  will-change: box-shadow;\n  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);\n}\n\n.government-filter-button {\n  font-size: .9375rem;\n  line-height: 1;\n  min-height: 48px;\n  outline: none;\n  padding: 8px 24px;\n  position: relative;\n  transition: min-height .3s cubic-bezier(.25,.8,.5,1);\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.government-filter-button svg {\n  fill: rgba(0,0,0,.54);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
