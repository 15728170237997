import {searchAddress} from "./searchAddress.js";
import {PREFECTURE} from "../utils/helper/constants.js";

const handleAddressSearch = async (postalCode1, postalCode2, handler) => {
  const { prefecture, address, street } = await searchAddress(postalCode1 + postalCode2);
  handler({
    prefectureId: prefecture ? PREFECTURE.find(data => data.name === prefecture).id : null,
    address: (address + street) ?? ''
  });
};

export default handleAddressSearch;
