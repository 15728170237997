import Heading3 from 'interfaces/components/common/Heading/Heading3';
import NecessaryExpensesAuction from './RightsTransferProcedureAuctionSection/NecessaryExpensesAuction.jsx';
import RequiredDocumentsAuction from './RightsTransferProcedureAuctionSection/RequiredDocumentsAuction.jsx';
import RightsTransferDescriptionAuction from './RightsTransferProcedureAuctionSection/RightsTransferDescriptionAuction.jsx';
import RightsTransferTimingAndRequiredPeriodAuction from './RightsTransferProcedureAuctionSection/RightsTransferTimingAndRequiredPeriodAuction.jsx';
import RightsTransferCautionAuction from './RightsTransferProcedureAuctionSection/RightsTransferCautionAuction.jsx';

const RightsTransferProcedureAuctionSection = () => {
  return (
    <div data-testid="rights-transfer-procedure-section" className="pt-6">
      <Heading3 text="権利移転手続き" isLeftBorderStyle={true} />
      <p
        className="sample-after-winning-bid-lead-text pt-2"
        data-testid="sample-after-winning-bid-lead-text">
        入札終了後に執行機関が落札者などへメールにて、落札した公売物件の売却区分番号、執行機関の所在および連絡先などをお知らせします。メール確認後できるだけ早く、執行機関連絡先へ電話にて連絡をし、権利移転手続きについて説明を受けてください。
      </p>
      <NecessaryExpensesAuction />
      <RequiredDocumentsAuction />
      <RightsTransferDescriptionAuction />
      <RightsTransferCautionAuction />
      <RightsTransferTimingAndRequiredPeriodAuction />
    </div>
  );
};

export default RightsTransferProcedureAuctionSection;
