import { validatePrefecture } from "../../../../application/validations.js";

export class InvoiceArea {
  #invoiceArea

  constructor(invoiceArea) {
    this.#invoiceArea = invoiceArea;
  }

  get() {
    return this.#invoiceArea
  }

  get isValid() {
    return [
      this.validatePrefecture,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validatePrefecture() {
    return validatePrefecture(this.#invoiceArea)
  }
}
