import PopUpInput from 'interfaces/components/common/Input/PopUpInput.jsx';
//import { getPreRegistrationInfo } from '../../../../read_storage/application-info-input.js';
//import { useAuth } from 'shared/hooks/useAuth.js';
import { useEffect, useState } from 'react';

const ReceiverNameForm = ({ receiverName, handleSelect }) => {
  //const { userId } = useAuth();
  const [items, setItems] = useState([]);

  //const fetch = async () => {
  //  if (!userId) {
  //    return;
  //  }

  //  const preRegistrationInfo = (await getPreRegistrationInfo(userId))[0];
  //  const applicantInfo = preRegistrationInfo.find(info => info.type === 'APPLICANT'); // 依頼者（落札者）
  //  const clientInfo = preRegistrationInfo.find(info => info.type === 'CLIENT'); // 委任者

  //  let names = [];

  //  if (applicantInfo) {
  //    names.push({
  //      name: `${applicantInfo.infos.familyName} ${applicantInfo.infos.firstName}`,
  //      label: `${applicantInfo.infos.familyName} ${applicantInfo.infos.firstName} (依頼人)`,
  //      role: 'CLIENT' // MEMO: paramとDBに食い違いがある(ここは"CLIENT"で正しい)
  //    });
  //  }
  //  if (clientInfo) {
  //    names.push({
  //      name: `${clientInfo.infos.familyName} ${clientInfo.infos.firstName}`,
  //      label: `${clientInfo.infos.familyName} ${clientInfo.infos.firstName} (委任者)`,
  //      role: 'DELEGATOR' // MEMO: paramとDBに食い違いがある(ここは"DELEGATOR"で正しい)
  //    });
  //  }

  //  setItems(names);
  //};
  useEffect(() => {
    setItems([
      { name: 'mock user1', label: 'mock user1(依頼人)', role: 'CLIENT' },
      { name: 'mock user2', label: 'mock user1(委任者)', role: 'DELEGATOR' },
    ]);
  }, []);

  //useEffect(() => {
  //  fetch().then();
  //}, []);

  return (
    <tr>
      <th>氏名</th>
      <td>
        <PopUpInput
          items={items}
          value={receiverName.label}
          handleSelect={handleSelect}
          className="border-bottom-pop-up-input"
        />
      </td>
    </tr>
  );
};

export default ReceiverNameForm;
