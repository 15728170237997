import AuthModal from 'interfaces/components/common/Header/HeaderTop/AuthModal';
import URL_LINKS from 'interfaces/pages/Dashboard/entities/externalLinks';

import { Message, MessageLink } from './WaitOpenBid';

export const NotLogin = ({
  data,
  handleClick,
  isOpen,
  handleClose,
  currentAuthStatus,
  switchAuthStatus,
  setIsNoMessage,
}) => {
  if (data?.status === 'APPLYING') {
    return (
      <>
        <p className="ma-0" data-testid="announce-message">
          この物件は現在、入札の参加申し込み受付期間中です！
          <br />
          参加申し込みには
          <a onClick={handleClick} data-testid="announce-login">
            ログイン
          </a>
          が必要です。新規ユーザー登録は<a>こちら</a>
          <br />
          <a
            href={URL_LINKS.ABOUT_PARTICIPATE_URL}
            target="_blank"
            rel="noreferrer">
            参加申し込みとは？
          </a>
        </p>
        <AuthModal
          isOpen={isOpen}
          handleClose={handleClose}
          currentAuthStatus={currentAuthStatus}
          switchAuthStatus={switchAuthStatus}
        />
      </>
    );
  }

  if (data?.status === 'WAIT_START_BID') {
    return (
      <p className="ma-0" data-testid="announce-message">
        この物件への参加申し込み受付は終了しました。
        <br />
        <a
          href={URL_LINKS.ABOUT_PARTICIPATE_URL}
          target="_blank"
          rel="noreferrer">
          参加申し込みとは？
        </a>
      </p>
    );
  }

  if (data?.status === 'DURING_BIDDING') {
    return (
      <>
        <p className="ma-0" data-testid="announce-message">
          この物件は現在、入札を受付中です。
          <br />
          入札には参加申し込みが必要です。すでに参加申し込み済の方は
          <a onClick={handleClick} data-testid="announce-login">
            ログイン
          </a>
          してください。
          <a
            href={URL_LINKS.ABOUT_PARTICIPATE_URL}
            target="_blank"
            rel="noreferrer">
            参加申し込みとは？
          </a>
        </p>
        <AuthModal
          isOpen={isOpen}
          handleClose={handleClose}
          currentAuthStatus={currentAuthStatus}
          switchAuthStatus={switchAuthStatus}
        />
      </>
    );
  }

  if (data?.status === 'WAIT_OPEN_BID') {
    return (
      <Message
        content={
          '現在執行機関による入札結果確認中です。落札者は入札結果の確認完了後に確定されます。しばらくお待ちください。'
        }
      />
    );
  }

  if (data?.status === 'END_BIDDING') {
    if (data?.bidCount === 0) {
      return <Message content={'この物件には入札がありませんでした。'} />;
    }
  }

  if (data?.status === 'ADDITIONAL_DURING_BIDDING') {
    return (
      <MessageLink
        prevText={'この物件は現在'}
        href={URL_LINKS.ABOUT_ADD_BID_URL}
        content={'追加入札'}
        laterText={'中です。'}
      />
    );
  }

  setIsNoMessage(true);
};
