import { validateRangeLength, notInclude, validateExtensionFormat } from "../../../../application/validations.js";

export class ContactExtension {
  #contactExtension

  constructor(contactExtension) {
    this.#contactExtension = contactExtension;
  }

  get() {
    return this.#contactExtension
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.notIncludeSpace,
      this.validateExtensionFormat,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#contactExtension, 0, 10)
  }

  // バリデーションルールの適用
  get notIncludeSpace() {
    return notInclude(this.#contactExtension, [' '])
  }

  // バリデーションルールの適用
  get validateExtensionFormat() {
    return validateExtensionFormat(this.#contactExtension)
  }
}
