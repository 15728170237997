import FooterBottom from './FooterBottom/FooterBottom.jsx';
import FooterSp from './FooterSp.jsx';
import FooterTop from './FooterTop/FooterTop.jsx';
import { mediaQuery, useMediaQuery } from 'shared/hooks/useResponsive.jsx';

const AuctionFooter = () => {
  const isWidthOver720 = useMediaQuery(mediaQuery.widthOver720);

  if (isWidthOver720) {
    return (
      <footer className="text-caption">
        <FooterTop />
        <hr className="divider" />
        <FooterBottom />
      </footer>
    );
  }

  return <FooterSp />;
};

export default AuctionFooter;
