import pino from 'pino';

import { BASE_URL_NAVIGATION } from '../../../config/envConstants.js';
import { fetchData } from '../../../shared/utils/helper/fetchData.js';

const logger = pino();
const CURRENT_DEPOSITS = `${BASE_URL_NAVIGATION}/storage/current/deposits/data.json`;
const PREVIOUS_DEPOSITS = `${BASE_URL_NAVIGATION}/storage/previous/deposits/data.json`;

export const fetchDepositsStorage = async () => {
  try {
    const response = await fetchCurrentDeposits();
    return extractDeposits(response);
  } catch (error) {
    logger.warn({ error }, '現在の通知（ deposits ）を取得できませんでした');

    try {
      const response = await fetchPreviousDeposits();
      return extractDeposits(response);
    } catch (error) {
      logger.error({ error }, '以前の通知（ deposits ）の取得にも失敗しました');
      return [];
    }
  }
};

export const fetchCurrentDeposits = async () => {
  return fetchDeposits(CURRENT_DEPOSITS);
};

export const fetchPreviousDeposits = async () => {
  return fetchDeposits(PREVIOUS_DEPOSITS);
};

export const fetchDeposits = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
};

export const extractDeposits = (response) => {
  return response.map((deposit) => deposit);
};
