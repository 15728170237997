import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import BasicInfoTable from 'interfaces/components/Property/service/PropertyDetail/BasicInfo/BasicInfoTable.jsx';

const BidsDetailTable = ({ data }) => {
  return (
    <div data-testid="bids-detail-table">
      <ArticleDefaultBody>
        <BasicInfoTable title="入札内容" data={data} />
      </ArticleDefaultBody>
    </div>
  );
};

export default BidsDetailTable;
