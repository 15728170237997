// ロジックを含む処理
// フロントから呼ぶ関数

//import { DepositCreate } from "../entities/Deposit.js";
import { deleteDepositData } from '../../../infrastructure/api/Deposit.js';
//import { createDepositData, updateDepositData, deleteDepositData } from "../../../infrastructure/api/Deposit.js";
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { getDeposit } from '../repositories/Deposit.js';

export async function get(type) {
  return await getDeposit(type);
}

export async function findByAuctionId(type, auctionId) {
  const Deposit = await getDeposit(type);
  return Deposit.filter(
    (data) => data.auctionId.toString() === auctionId.toString(),
  );
}

//export async function create(type, data) {
//  let response;
//
//  if (type === SERVICE) {
//    throw new CustomError('Unexpected Request (Deposit)');
//  }
//  if (type === NAVI) {
//    throw new CustomError('Unexpected Request (Deposit)');
//  }
//  if (type === KMANAGER) {
//    const deposit = new DepositCreate(data)
//    response = await createDepositData(deposit);
//  }
//  if (response instanceof Error) {
//    throw new CustomError('Error create Deposit', response);
//  }
//  return response
//}

//export async function update(type, data) {
//  let response;
//
//  if (type === SERVICE) {
//    throw new CustomError('Unexpected Request (Deposit)');
//  }
//  if (type === NAVI) {
//    throw new CustomError('Unexpected Request (Deposit)');
//  }
//  if (type === KMANAGER) {
//    const deposit = new DepositCreate(data)
//    response = await updateDepositData(deposit, deposit.auctionId, deposit.divisionId);
//  }
//  if (response instanceof Error) {
//    throw new CustomError('Error update Deposit', response);
//  }
//  return response
//}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Deposit)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Deposit)');
  }
  if (type === KMANAGER) {
    response = await deleteDepositData(data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Deposit', response);
  }
  return response;
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
