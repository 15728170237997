import { useState, useEffect } from "react";

import GovernmentLogo from "./GovernmentLogo.jsx";
import MenuLinkList from "./MenuLinkList.jsx";
import { ReactComponent as MenuDownIcon } from "../../../../assets/svg/menu-down.svg";
import Button from '../../../common/Button/Button.jsx';
import Heading1 from 'interfaces/components/common/Heading/Heading1.jsx';
// import GovrenmentLogo from "./GovernmentLogo.jsx";
// import { useHeader } from "../../../../shared/hooks/pages/useHeader.js";

const GovernmentHeader = ({ navigate }) => {
  const [showMenuLinkList, setShowMenuLinkList] = useState(false);
  // const { procedureType, governmentType, localStorageLoading } = useHeader();

  const handleClickNewEntry = () => {
    navigate('/navi/new-entry');
  };

  const handleClickAuctionSchedule = () => {
    navigate('/navi/auction-schedule');
  }

  const handleClickMenuButton = () => {
    setShowMenuLinkList(!showMenuLinkList);
  }

  const handleGlobalClick = () => {
    setShowMenuLinkList(false);
  }

  useEffect(() => {
    document.addEventListener('click', handleGlobalClick);
    return () => {
      document.removeEventListener('click', handleGlobalClick);
    }
  }, []);

  return (
    <header className="header" data-testid="header">
      <div className="header-container">
        <GovernmentLogo/>
        <nav>
          <ul className="header-menu">
            <li className="header-menu-item">
              {/*{*/}
              {/*  !localStorageLoading*/}
              {/*  && governmentType !== "NATIONAL_TAX_AGENCY" && <PrimaryButton onClick={handleClickNewEntry}>{procedureType === "PUBLIC_SALE" ? "インターネット公売" : "公有財産売却"}参加申込</PrimaryButton>*/}
              {/*}*/}
              {
                <Button onClick={handleClickNewEntry}>{"インターネット公売"}参加申込</Button>
              }
            </li>
            <li className="header-menu-item">
              {/*{ governmentType !== "NATIONAL_TAX_AGENCY" && <LightButton onClick={handleClickAuctionSchedule}>開催スケジュール</LightButton> }*/}
              <Button onClick={handleClickAuctionSchedule}>開催スケジュール</Button>
            </li>
            <li className="header-menu-item">
              <div style={{ position: "relative" }}>
                <div className="w-150">
                  <Button icon={<MenuDownIcon/>} text="メニュー" onClick={(e) => {
                    e.stopPropagation();
                    handleClickMenuButton();
                  }}/>
                </div>
                {showMenuLinkList && <MenuLinkList/>}
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <Heading1>官公庁ナビ</Heading1>
    </header>
  );
};

export default GovernmentHeader