import Button from 'interfaces/components/common/Button/Button';

import GuidelineBody from './GuidelineBody';
import 'interfaces/css/common/guideline.css';

const Guideline = ({
  governmentName,
  procedureType,
  data,
  closeModal = () => {},
}) => {
  return (
    <>
      <div data-testid="guideline" className="guideline">
        <GuidelineBody
          governmentName={governmentName}
          procedureType={procedureType}
          data={data}
          closeModal={closeModal}
        />
      </div>
      <Button text="閉じる" className="common-button" onClick={closeModal} />
    </>
  );
};

export default Guideline;
