// ロジックを含む処理
// フロントから呼ぶ関数

import {
  SEARCH_PROPERTY_CATEGORY_ID,
  SEARCH_GOVERNMENT_SUB_CATEGORY_ID,
  SEARCH_STATUS,
  DEPOSIT_FLAG,
  OPEN_WAY,
} from 'shared/utils/constants/queryParam';

import { createNaviAddDescriptionsData } from '../../../infrastructure/api/NaviAddDescriptions.js';
import { updateNaviGuidelinesData } from '../../../infrastructure/api/NaviGuidelines.js';
import {
  createPropertiesData,
  updatePropertiesData,
  deletePropertiesData,
} from '../../../infrastructure/api/Properties.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { AddDescriptionsCreate, PropertyCreate } from '../entities/Property.js';
import { getProperty } from '../repositories/Property.js';

export async function get(type) {
  return await getProperty(type);
}

export async function findById(type, id) {
  const property = await getProperty(type);
  return property.find((data) => data.id === id);
}

export async function findByAuctionId(type, auctionId) {
  const properties = await getProperty(type);
  return properties.filter(
    (property) => property?.auctionId?.toString() === auctionId.toString(),
  );
}

export async function findByAuctionIdAndGovernmentId(
  type,
  auctionId,
  governmentId,
) {
  const properties = await getProperty(type);
  return properties.filter(
    (property) =>
      property?.auctionId?.toString() === auctionId.toString() &&
      property?.governmentId?.toString() === governmentId.toString(),
  );
}

export async function findByIds(type, ids) {
  const Property = await getProperty(type);
  return Property.filter((data) => ids.includes(data.id));
}

export async function findByAuctionIds(type, auctionIds) {
  const properties = await getProperty(type);
  let ret = properties;

  if ([undefined, null, ''].includes(auctionIds) === false) {
    ret = ret.filter((property) => auctionIds.includes(property.auctionId));
  }

  return ret;
}

export async function findBySearchParams(type, searchParams, auctionIds) {
  const properties = await getProperty(type);
  let ret = properties;

  if ([undefined, null, ''].includes(auctionIds) === false) {
    ret = ret.filter((property) => auctionIds.includes(property.auctionId));
  }

  // カテゴリーによる検索
  let condCategory = searchParams.get('category');
  if ([undefined, null].includes(condCategory)) {
    ret = ret.filter((property) => property.propertyType === 'REAL_ESTATE');
  } else {
    ret = ret.filter((property) => property.propertyType === condCategory);
  }

  // 物件のカテゴリーによる検索
  let condCategoryId = searchParams.get(SEARCH_PROPERTY_CATEGORY_ID);
  if ([undefined, null, ''].includes(condCategoryId) === false) {
    ret = ret.filter((property) =>
      condCategoryId.includes(property.categoryId),
    );
  }

  let condSearchStatus = searchParams.get(SEARCH_STATUS);
  if ([undefined, null, ''].includes(condSearchStatus) === false) {
    console.log('cond search status');
    ret = ret.filter((property) => property.status === condSearchStatus);
  }

  let condDeposit = searchParams.get(DEPOSIT_FLAG);
  if ([undefined, null, ''].includes(condDeposit) === false) {
    ret = ret.filter((property) =>
      condSearchStatus === 'NODEPOSIT'
        ? ['NONE'].includes(property.depositPayment)
        : ['CREDIT_CARD', 'BOTH'].includes(property.depositPayment),
    );
  }

  let condPublicSaleType = searchParams.get(OPEN_WAY);
  if ([undefined, null, ''].includes(condPublicSaleType) === false) {
    ret = ret.filter(
      (property) => property.publicSaleType === condPublicSaleType,
    );
  }

  // 条件による絞り込み
  if (condCategory === 'ESTATE') {
    let condGovernmentSubCategoryId = searchParams.get(
      SEARCH_GOVERNMENT_SUB_CATEGORY_ID,
    );
    if (condGovernmentSubCategoryId !== '') {
      ret = ret.filter((property) =>
        condGovernmentSubCategoryId.includes(property.governmentSubCategoryId),
      );
    }
  }

  return ret;
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    const property = new PropertyCreate(data);
    response = await createPropertiesData(property);
  }
  if (type === KMANAGER) {
    response = await createPropertiesData(data);
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Property', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    response = await updatePropertiesData(data, data.id);
  }
  if (type === KMANAGER) {
    response = await updatePropertiesData(data, data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Property', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    response = await deletePropertiesData(data.id);
  }
  if (type === KMANAGER) {
    response = await deletePropertiesData(data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Property', response);
  }
  return response;
}

export async function createAddDescription(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    const addDescription = new AddDescriptionsCreate(data);
    response = await createNaviAddDescriptionsData(addDescription);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create AddDescription', response);
  }
  return response;
}

export async function updateAddDescription(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    const addDescription = new AddDescriptionsCreate(data);
    response = await updateNaviGuidelinesData(addDescription);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update AddDescription', response);
  }
  return response;
}

// function createPropertyArray(data) {
//   let properties = []
//   data.forEach((property) => {
//     properties.push(new Property(property))
//   })
//   return properties
// }

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((property) => property[column] === value);
}

export function sortByBiddersASC(data) {
  return data.sort((a, b) => compareData(a.bidders.length, b.bidders.length));
}

export function sortByBiddersDESC(data) {
  return data.sort((a, b) => compareData(b.bidders.length, a.bidders.length));
}

export function sortFromCommonDataASC(data, column) {
  return data.sort((a, b) =>
    compareData(a.commonData[column], b.commonData[column]),
  );
}

export function sortFromCommonDataDESC(data, column) {
  return data.sort((a, b) =>
    compareData(b.commonData[column], a.commonData[column]),
  );
}

export function filterFromCommonData(data, column, value) {
  return data.filter((property) => property.commonData[column] === value);
}

export function filterByGovernmentAuction(data, governmentId, auctionId) {
  const filteredByGovernment = filterFromCommonData(
    data,
    'governmentId',
    governmentId,
  );
  return filterFromCommonData(filteredByGovernment, 'auctionId', auctionId);
}
