import { validatePrefecture } from "../../../../application/validations.js";

export class LocationGovernmentSubCategoryId {
  #locationGovernmentSubCategoryId

  constructor(locationGovernmentSubCategoryId) {
    this.#locationGovernmentSubCategoryId = locationGovernmentSubCategoryId;
  }

  get() {
    return this.#locationGovernmentSubCategoryId
  }

  get isValid() {
    return [
      this.validatePrefecture,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validatePrefecture() {
    return validatePrefecture(this.#locationGovernmentSubCategoryId)
  }
}
