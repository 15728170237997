export const COMMON_YEAR_DATE = 365;
export const LEAP_YEAR_DATE = 366;

const JANUARY_DATE = 31;
const COMMON_FEBRUARY_DATE = 28;
const LEAP_FEBRUARY_DATE = 29;
const MARCH_DATE = 31;
const APRIL_DATE = 30;
const MAY_DATE = 31;
const JUNE_DATE = 30;
const JULY_DATE = 31;
const AUGUST_DATE = 31;
const SEPTEMBER_DATE = 30;
const OCTOBER_DATE = 31;
const NOVEMBER_DATE = 30;
const DECEMBER_DATE = 31;

export const COMMON_MONTH_DAYS = [JANUARY_DATE, COMMON_FEBRUARY_DATE, MARCH_DATE, APRIL_DATE, MAY_DATE, JUNE_DATE, JULY_DATE, AUGUST_DATE, SEPTEMBER_DATE, OCTOBER_DATE, NOVEMBER_DATE, DECEMBER_DATE];
export const LEAP_MONTH_DAYS = [JANUARY_DATE, LEAP_FEBRUARY_DATE, MARCH_DATE, APRIL_DATE, MAY_DATE, JUNE_DATE, JULY_DATE, AUGUST_DATE, SEPTEMBER_DATE, OCTOBER_DATE, NOVEMBER_DATE, DECEMBER_DATE];
