export default class CustomError extends Error {
  constructor(message, originalError) {
    if (message === null) {
      throw new TypeError('メッセージをNULLにすることはできない');
    }
    super(message);
    this.name = "CustomError";
    this.originalError = originalError;
  }
}
