import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_BANK_TRANSFER_PAGES = `${BASE_URL_NAVIGATION}/storage/current/bank_transfer_pages/data.json`;
const PREVIOUS_BANK_TRANSFER_PAGES = `${BASE_URL_NAVIGATION}/storage/previous/bank_transfer_pages/data.json`;

export const fetchBankTransferPagesStorage = async () => {
  try {
    const response = await fetchBankTransferPages(CURRENT_BANK_TRANSFER_PAGES);
    return extractBankTransferPages(response);
  } catch (error) {
    logger.warn({ error }, "現在の銀行振込など仮申し込み完了画面の設定情報（ bank_transfer_pages ）を取得できませんでした");

    try {
      const response = await fetchBankTransferPages(PREVIOUS_BANK_TRANSFER_PAGES);
      return extractBankTransferPages(response);
    } catch (error) {
      logger.error({ error }, "以前の銀行振込など仮申し込み完了画面の設定情報（ bank_transfer_pages ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchBankTransferPages = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractBankTransferPages = (response) => {
  return response.map(bank_transfer_page => bank_transfer_page);
}