import { useState } from 'react';
// import { logInput } from "../../utils/log/logInputs.js";
import * as Validation from 'shared/utils/helper/naviValidators';

export const DocumentSendForm = () => {
  const [certDocumentPostalCodeValid, setCertDocumentPostalCodeValid] =
    useState(null);
  const [
    certDocumentPostalCodeFirstValid,
    setCertDocumentPostalCodeFirstValid,
  ] = useState(null);
  const [certDocumentPostalCodeLastValid, setCertDocumentPostalCodeLastValid] =
    useState(null);
  const [certDocumentDestinationValid, setCertDocumentDestinationValid] =
    useState(null);
  const [
    certDocumentWrittenDestinationValid,
    setCertDocumentWrittenDestinationValid,
  ] = useState(null);
  const [certDocumentBuildValid, setCertDocumentBuildValid] = useState(null);
  const [certDocumentAddressValid, setCertDocumentAddressValid] =
    useState(null);
  const [certDocumentCityValid, setCertDocumentCityValid] = useState(null);
  const [certDocumentPrefectureValid, setCertDocumentPrefectureValid] =
    useState(null);
  const [invoiceDocumentPostalCodeValid, setInvoiceDocumentPostalCodeValid] =
    useState(null);
  const [
    invoiceDocumentPostalCodeFirstValid,
    setInvoiceDocumentPostalCodeFirstValid,
  ] = useState(null);
  const [
    invoiceDocumentPostalCodeLastValid,
    setInvoiceDocumentPostalCodeLastValid,
  ] = useState(null);
  const [invoicePayerNameValid, setInvoicePayerNameValid] = useState(null);
  const [invoiceDepartmentRequiredValid, setInvoiceDepartmentRequiredValid] =
    useState(null);
  const [
    invoiceWrittenDestinationRequiredValid,
    setInvoiceWrittenDestinationRequiredValid,
  ] = useState(null);
  const [invoiceDocumentBuildValid, setInvoiceDocumentBuildValid] =
    useState(null);
  const [invoiceAddressRequiredValid, setInvoiceAddressRequiredValid] =
    useState(null);
  const [cityRequiredValid, setCityRequiredValid] = useState(null);
  const [invoiceDocumentPrefectureValid, setInvoiceDocumentPrefectureValid] =
    useState(null);
  const [urlValid, setUrlValid] = useState(null);
  const [extensionValid, setExtensionValid] = useState(null);
  const [telValid, setTelValid] = useState(null);
  const [tel1Valid, setTel1Valid] = useState(null);
  const [tel2Valid, setTel2Valid] = useState(null);
  const [tel3Valid, setTel3Valid] = useState(null);

  function handleInvoicePostalCodeValid(postalCode) {
    let invalid = null;
    const postCodeAll = postalCode.postalCode1 + postalCode.postalCode2;
    invalid = Validation.validateRequired(postCodeAll);
    if (invalid !== 'TRUE') {
      setInvoiceDocumentPostalCodeValid(invalid);
      return;
    }
    setInvoiceDocumentPostalCodeValid(invalid);
  }

  function handleInvoicePostalCodeFirstValid(postalCode) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateOnlyNumber(postalCode);
    if (invalid !== 'TRUE') {
      setInvoiceDocumentPostalCodeFirstValid(invalid);
      return;
    }
    invalid = Validation.validateLength(postalCode, 3);
    if (invalid !== 'TRUE') {
      setInvoiceDocumentPostalCodeFirstValid(invalid);
      return;
    }
    setInvoiceDocumentPostalCodeFirstValid(invalid); // エラーメッセージのリセット
  }

  function handleInvoicePostalCodeLastValid(postalCode) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateOnlyNumber(postalCode);
    if (invalid !== 'TRUE') {
      setInvoiceDocumentPostalCodeLastValid(invalid);
      return;
    }
    invalid = Validation.validateLength(postalCode, 4);
    if (invalid !== 'TRUE') {
      setInvoiceDocumentPostalCodeLastValid(invalid);
      return;
    }
    setInvoiceDocumentPostalCodeLastValid(invalid); // エラーメッセージのリセット
  }

  function handleInvoiceAreaValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validatePrefectureRequiredRequired(e.target.value);
    console.log(invalid);
    if (invalid !== 'TRUE') {
      setInvoiceDocumentPrefectureValid(invalid);
      return;
    }
    setInvoiceDocumentPrefectureValid(invalid); // エラーメッセージのリセット
  }

  function handleInvoiceCityValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateCityRequiredRangeLength(e.target.value);
    if (invalid !== 'TRUE') {
      setCityRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateCityRequiredHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setCityRequiredValid(invalid);
      return;
    }
    setCityRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleInvoiceAddressValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateInvoiceAddressRequiredRangeLength(
      e.target.value,
    );
    if (invalid !== 'TRUE') {
      setInvoiceAddressRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateInvoiceAddressRequiredHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInvoiceAddressRequiredValid(invalid);
      return;
    }
    setInvoiceAddressRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleInvoiceBuildNameValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateBuildLength(e.target.value);
    if (invalid !== 'TRUE') {
      setInvoiceDocumentBuildValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInvoiceDocumentBuildValid(invalid);
      return;
    }
    setInvoiceDocumentBuildValid(invalid); // エラーメッセージのリセット
  }

  function handleInvoiceWrittenDestinationValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateInvoiceWrittenDestinationRequiredRangeLength(
      e.target.value,
    );
    if (invalid !== 'TRUE') {
      setInvoiceWrittenDestinationRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateInvoiceWrittenDestinationRequiredHasEmoji(
      e.target.value,
    );
    if (invalid !== 'TRUE') {
      setInvoiceWrittenDestinationRequiredValid(invalid);
      return;
    }
    setInvoiceWrittenDestinationRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleInvoiceDepartmentValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateInvoiceDepartmentRequiredRangeLength(
      e.target.value,
    );
    if (invalid !== 'TRUE') {
      setInvoiceDepartmentRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateInvoiceDepartmentRequiredHasEmoji(
      e.target.value,
    );
    if (invalid !== 'TRUE') {
      setInvoiceDepartmentRequiredValid(invalid);
      return;
    }
    setInvoiceDepartmentRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleInvoicePayerNameValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateInvoicePayerNameLength(e.target.value);
    if (invalid !== 'TRUE') {
      setInvoicePayerNameValid(invalid);
      return;
    }
    invalid = Validation.validateInvoicePayerNameHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInvoicePayerNameValid(invalid);
      return;
    }
    setInvoicePayerNameValid(invalid); // エラーメッセージのリセット
  }

  function handleInvoiceTelValid(tel) {
    let invalid = null;
    const telAll = tel.telNum1 + tel.telNum2 + tel.telNum3;
    invalid = Validation.validateRangeLength(telAll, 10, 11);
    if (invalid !== 'TRUE') {
      setTelValid(invalid);
      return;
    }
    setTelValid(invalid); // エラーメッセージのリセット
  }

  function handleTel1Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setTel1Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setTel1Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setTel1Valid(invalid);
      return;
    }
    setTel1Valid(invalid); // エラーメッセージのリセット
  }

  function handleTel2Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setTel2Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setTel2Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setTel2Valid(invalid);
      return;
    }
    setTel2Valid(invalid); // エラーメッセージのリセット
  }

  function handleTel3Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setTel3Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setTel3Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setTel3Valid(invalid);
      return;
    }
    setTel3Valid(invalid); // エラーメッセージのリセット
  }

  function handleInvoiceExtensionValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateExtensionLength(e.target.value);
    if (invalid !== 'TRUE') {
      setExtensionValid(invalid);
      return;
    }
    invalid = Validation.validateExtensionSpaceMessage(e.target.value);
    if (invalid !== 'TRUE') {
      setExtensionValid(invalid);
      return;
    }
    invalid = Validation.validateExtensionNotInclude(e.target.value);
    if (invalid !== 'TRUE') {
      setExtensionValid(invalid);
      return;
    }
    setExtensionValid(invalid); // エラーメッセージのリセット
  }

  function handleInvoiceUrlValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateUrlValidUrl(e.target.value);
    if (invalid !== 'TRUE') {
      setUrlValid(invalid);
      return;
    }
    invalid = Validation.validateUrlLength(e.target.value);
    if (invalid !== 'TRUE') {
      setUrlValid(invalid);
      return;
    }
    setUrlValid(invalid); // エラーメッセージのリセット
  }

  function handleCertDocumentPostalCodeValid(postalCode) {
    let invalid = null;
    const postCodeAll = postalCode.postalCode1 + postalCode.postalCode2;
    invalid = Validation.validateRequired(postCodeAll);
    if (invalid !== 'TRUE') {
      setCertDocumentPostalCodeValid(invalid);
      return;
    }
    setCertDocumentPostalCodeValid(invalid);
  }

  function handleCertDocumentPostalCodeFirstValid(postalCode) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateOnlyNumber(postalCode);
    if (invalid !== 'TRUE') {
      setCertDocumentPostalCodeFirstValid(invalid);
      return;
    }
    invalid = Validation.validateLength(postalCode, 3);
    if (invalid !== 'TRUE') {
      setCertDocumentPostalCodeFirstValid(invalid);
      return;
    }
    setCertDocumentPostalCodeFirstValid(invalid); // エラーメッセージのリセット
  }

  function handleCertDocumentPostalCodeLastValid(postalCode) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateOnlyNumber(postalCode);
    if (invalid !== 'TRUE') {
      setCertDocumentPostalCodeLastValid(invalid);
      return;
    }
    invalid = Validation.validateLength(postalCode, 4);
    if (invalid !== 'TRUE') {
      setCertDocumentPostalCodeLastValid(invalid);
      return;
    }
    setCertDocumentPostalCodeLastValid(invalid); // エラーメッセージのリセット
  }

  function handleCertDocumentAreaValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validatePrefectureRequiredRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setCertDocumentPrefectureValid(invalid);
      return;
    }
    setCertDocumentPrefectureValid(invalid); // エラーメッセージのリセット
  }

  function handleCertDocumentCityValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateCertDocumentCityLength(e.target.value);
    if (invalid !== 'TRUE') {
      setCertDocumentCityValid(invalid);
      return;
    }
    invalid = Validation.validateCertDocumentCityHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setCertDocumentCityValid(invalid);
      return;
    }
    setCertDocumentCityValid(invalid); // エラーメッセージのリセット
  }

  function handleCertDocumentAddressValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateCertDocumentAddressLength(e.target.value);
    if (invalid !== 'TRUE') {
      setCertDocumentAddressValid(invalid);
      return;
    }
    invalid = Validation.validateCertDocumentAddressHasEmoji1(e.target.value);
    if (invalid !== 'TRUE') {
      setCertDocumentAddressValid(invalid);
      return;
    }
    setCertDocumentAddressValid(invalid); // エラーメッセージのリセット
  }

  function handleCertDocumentBuildNameValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateBuildLength(e.target.value);
    if (invalid !== 'TRUE') {
      setCertDocumentBuildValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setCertDocumentBuildValid(invalid);
      return;
    }
    setCertDocumentBuildValid(invalid); // エラーメッセージのリセット
  }

  function handleCertDocumentWrittenDestinationValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateCertDocumentWrittenDestinationLength(
      e.target.value,
    );
    if (invalid !== 'TRUE') {
      setCertDocumentWrittenDestinationValid(invalid);
      return;
    }
    invalid = Validation.validateCertDocumentWrittenDestinationHasEmoji(
      e.target.value,
    );
    if (invalid !== 'TRUE') {
      setCertDocumentWrittenDestinationValid(invalid);
      return;
    }
    setCertDocumentWrittenDestinationValid(invalid); // エラーメッセージのリセット
  }

  function handleCertDocumentDestinationValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = 'TRUE';
    invalid = Validation.validateCertDocumentDestinationLength(e.target.value);
    if (invalid !== 'TRUE') {
      setCertDocumentDestinationValid(invalid);
      return;
    }
    invalid = Validation.validateCertDocumentDestinationHasEmoji1(
      e.target.value,
    );
    if (invalid !== 'TRUE') {
      setCertDocumentDestinationValid(invalid);
      return;
    }
    setCertDocumentDestinationValid(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      handleCertDocumentPostalCodeValid,
      handleCertDocumentPostalCodeFirstValid,
      handleCertDocumentPostalCodeLastValid,
      handleCertDocumentDestinationValid,
      handleCertDocumentWrittenDestinationValid,
      handleCertDocumentBuildNameValid,
      handleCertDocumentAddressValid,
      handleCertDocumentCityValid,
      handleCertDocumentAreaValid,
      handleInvoicePostalCodeValid,
      handleInvoicePostalCodeFirstValid,
      handleInvoicePostalCodeLastValid,
      handleInvoiceUrlValid,
      handleInvoiceExtensionValid,
      handleInvoiceTelValid,
      handleTel1Valid,
      handleTel2Valid,
      handleTel3Valid,
      handleInvoicePayerNameValid,
      handleInvoiceDepartmentValid,
      handleInvoiceWrittenDestinationValid,
      handleInvoiceBuildNameValid,
      handleInvoiceAddressValid,
      handleInvoiceCityValid,
      handleInvoiceAreaValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      certDocumentPostalCodeValid,
      certDocumentPostalCodeFirstValid,
      certDocumentPostalCodeLastValid,
      certDocumentDestinationValid,
      certDocumentWrittenDestinationValid,
      certDocumentBuildValid,
      certDocumentAddressValid,
      certDocumentCityValid,
      certDocumentPrefectureValid,
      urlValid,
      extensionValid,
      telValid,
      tel1Valid,
      tel2Valid,
      tel3Valid,
      invoiceDocumentPostalCodeValid,
      invoiceDocumentPostalCodeFirstValid,
      invoiceDocumentPostalCodeLastValid,
      invoicePayerNameValid,
      invoiceDepartmentRequiredValid,
      invoiceWrittenDestinationRequiredValid,
      invoiceDocumentBuildValid,
      invoiceAddressRequiredValid,
      cityRequiredValid,
      invoiceDocumentPrefectureValid,
    },
  };

  return validations;
};
