// import KmanagerTableTH from "./KmanagerTableTH.jsx";
import { SortableTh } from 'interfaces/components/common/Table/Th/SortableTh';
const KmanagerTableColumn = ({ headers, requestSort, sortConfig }) => {
  return (
    <thead data-testid="kmanager-table-column">
      <tr data-testid="kmanager-tr">
        {headers?.map((header) => {
          return (
            <SortableTh
              key={header.id}
              header={header}
              onClick={() => requestSort(header.column)}
              sortConfig={sortConfig}
            />
          );
          // return <KmanagerTableTH key={count} text={header.text} checkBoxChange={checkBoxChange}/>
        })}
      </tr>
    </thead>
  );
};

export default KmanagerTableColumn;
