import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { findById as findInvitation } from '../../../../domain/Auction/services/Invitation.js';
import { get as getPropertyImages } from '../../../../domain/Files/services/PropertyFiles.js';
import { get as getMessages } from '../../../../domain/News/services/Message';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { UploadImagesImagesGeneralContext } from '../entities/UploadImagesImagesGeneralContext.js';

const UploadImagesImagesGeneralProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const { auction_id } = useParams();
  const [managementMessages, setManagementMessages] = useState([]);
  const [invitation, setInvitation] = useState({});
  const [propertyImages, setPropertyImages] = useState([]);
  const { localStorageGovernmentId } = useLocalStorage();

  if (type === SERVICE) {
    useEffect(() => {
      getPropertyImages(type).then((result) => {
        setPropertyImages(result);
      });
      getMessages(type).then((result) => {
        setManagementMessages(result);
      });
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      findInvitation(type, auction_id, localStorageGovernmentId).then(
        (result) => {
          setInvitation(result);
        },
      );
    }, [localStorageGovernmentId]);
    useEffect(() => {
      getPropertyImages(type).then((result) => {
        setPropertyImages(result);
      });
    }, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <UploadImagesImagesGeneralContext.Provider
      value={{
        authState,
        breadcrumbs,
        managementMessages,
        governmentId,
        governmentType,
        procedureType,
        propertyImages,
        invitation,
      }}>
      {children}
    </UploadImagesImagesGeneralContext.Provider>
  );
};

export default UploadImagesImagesGeneralProvider;
