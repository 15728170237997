import PropertyDetailProvider from './PropertyDetailProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { propertyDetail } from "../entities/componentMapping.js";

const PropertyDetail = ({ type }) => {
  return (
    <PropertyDetailProvider type={type}>
      {getComponent(propertyDetail, type)}
    </PropertyDetailProvider>
  );
};

export default PropertyDetail;
