// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-info-car .property-info-car-form-input-text {
  display: flex;
}

.property-info-car .property-info-car-form-input-text .property-info-car-form-input-checkbox {
  display: flex;
  align-items: inherit;
  padding-top: 8px;
}

.property-info-car .property-info-car-form-input-text-width {
  width: 70%;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertyInfoCar/property-info-car-form-input-text.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".property-info-car .property-info-car-form-input-text {\n  display: flex;\n}\n\n.property-info-car .property-info-car-form-input-text .property-info-car-form-input-checkbox {\n  display: flex;\n  align-items: inherit;\n  padding-top: 8px;\n}\n\n.property-info-car .property-info-car-form-input-text-width {\n  width: 70%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
