import { validateRangeLength, isOnlyFullwidthKatakana, hasEmoji } from "../../../../application/validations.js";

export class NameKana {
  #nameKana

  constructor(nameKana) {
    this.#nameKana = nameKana;
  }

  get() {
    return this.#nameKana
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.isOnlyFullwidthKatakana,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#nameKana, 1, 50)
  }

  // バリデーションルールの適用
  get isOnlyFullwidthKatakana() {
    return isOnlyFullwidthKatakana(this.#nameKana)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#nameKana)
  }
}
