// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createGovernmentsData,
  updateGovernmentsData,
  deleteGovernmentsData
} from "../../../infrastructure/api/Governments.js";
import { compareData } from "../../../shared/utils/helper/compareData.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { GovernmentCreate } from "../entities/Government.js";
import { getGovernment } from "../repositories/Government.js";
import { bodyToSnake, getValues } from 'shared/utils/helper/convertData.js';

export async function get(type) {
  return await getGovernment(type)
}

export async function findById(type, id) {
  const governments = await getGovernment(type);
  return await governments.find(government => government.id.toString() === id.toString())
}

export async function findByIds(type, ids) {
  const governments = await getGovernment(type);
  return await governments.filter(government => ids.includes(government.id.toString()))
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Government)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Government)');
  }
  if (type === KMANAGER) {
    const government = new GovernmentCreate(data)
    response = await createGovernmentsData(bodyToSnake(getValues(government)));
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Government', response);
  }
  return response
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Government)');
  }
  if (type === NAVI) {
    const government = new GovernmentCreate(data)
    response = await updateGovernmentsData(government, government.id);
  }
  if (type === KMANAGER) {
    const government = new GovernmentCreate(data)
    response = await updateGovernmentsData(government, government.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Government', response);
  }
  return response
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Government)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Government)');
  }
  if (type === KMANAGER) {
    response = await deleteGovernmentsData(data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Government', response);
  }
  return response
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]))
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]))
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value)
}
