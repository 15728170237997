import Pagination from 'interfaces/components/common/Pagination/Pagination';
import SearchPropertyCount from 'interfaces/components/common/PropertyList/SearchPropertyCount';
import { useContext } from 'react';
import usePagination from 'shared/hooks/usePagination';
import { dateFormat } from 'shared/utils/helper/date_format';

import { mediaQuery, useMediaQuery } from 'shared/hooks/useResponsive';
import { NewsContext } from '../../../pages/News/entities/NewsContext';

const NewsList = () => {
  const { notices } = useContext(NewsContext);
  const isWidthOver720 = useMediaQuery(mediaQuery.widthOver720);
  const itemsPerPage = 50;
  const {
    allItemCount,
    currentPage,
    currentItems,
    pageNumbers,
    setCurrentPage,
  } = usePagination(notices, itemsPerPage);

  const convertDate = (date) => {
    let timestamp = Date.parse(date);
    let targetDate = new Date(timestamp);
    return dateFormat(targetDate, `YYYY-MM-DD-hh-mm`);
  };

  return (
    <section>
      {currentItems?.map((data) => {
        return (
          <section key={data.id}>
            <section className={`${isWidthOver720 ? 'mb-10' : 'mb-12'}`}>
              <div className="news-title mb-2" id={`news-item--${data.id}`}>
                <p className="text-caption pa-0 ma-0">
                  {convertDate(data.updatedAt)}
                </p>
                <h2 className="news-subtitle">{data.title}</h2>
              </div>
              <div className="news-substance px-4 py-2 rounded theme--light">
                <div className="news-context">
                  <p>{data.body}</p>
                </div>
              </div>
            </section>
          </section>
        );
      })}
      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
      />
      <div className="text-center">
        <SearchPropertyCount
          itemCount={allItemCount}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
        />
      </div>
    </section>
  );
};
export default NewsList;
