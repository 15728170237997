import { Link } from 'react-router-dom';
import 'interfaces/css/common/heading2.css'

const Heading2 = ({ title, linkLabel, link }) => {
  return (
    <div className='heading2-wrap' data-testid={"heading2"}>
      <h2 className="heading2 ">{title}</h2>
      <div className="right">
        {link && <Link to={link} data-testid="heading2-link">{linkLabel}</Link>}
      </div>
    </div>
  );
}

export default Heading2;
