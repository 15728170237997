
export class CarType {
    #carType

    constructor(carType) {
        this.#carType = carType;
    }

    get() {
        return this.#carType
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
