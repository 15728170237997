// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-id-form_user_id_form__fnAaY {
  margin-bottom: 16px;
}

.user-id-form_user_id_input__sJVTw {
  width: 100%;
  line-height: 20px;
  padding: 8px 8px;
  font-size: 1rem;
  margin-top: 8px;
  background-color: var(--white);
  border: solid 1px var(--input-border);
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Login/user-id-form.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,8BAA8B;EAC9B,qCAAqC;EACrC,kBAAkB;AACpB","sourcesContent":[".user_id_form {\n  margin-bottom: 16px;\n}\n\n.user_id_input {\n  width: 100%;\n  line-height: 20px;\n  padding: 8px 8px;\n  font-size: 1rem;\n  margin-top: 8px;\n  background-color: var(--white);\n  border: solid 1px var(--input-border);\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user_id_form": `user-id-form_user_id_form__fnAaY`,
	"user_id_input": `user-id-form_user_id_input__sJVTw`
};
export default ___CSS_LOADER_EXPORT___;
