
export class DivisionName {
    #divisionName

    constructor(divisionName) {
        this.#divisionName = divisionName;
    }

    get() {
        return this.#divisionName
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
