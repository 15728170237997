import PropertyCardCaption from './PropertyCardCaption';
import { depositToYen } from '../../helper/deposit';
import { joinArrayWhiteSpace } from '../../helper/joinArrayWhiteSpace';
import { useCalcDeadline } from '../../hooks/useCalcDeadline';

const CommonPropertyCardCaptions = ({ data }) => {
  const { deadline } = useCalcDeadline(data);

  const captions = [
    { id: 1, label: '保証金', value: depositToYen(data.deposit) },
    { id: 2, label: data.remainingLabel, value: deadline },
    { id: 3, label: '保証金納付方法', value: data.depositPayment },
    { id: 4, label: '代金納付方法', value: joinArrayWhiteSpace(data.payments) },
  ];

  return captions.map((caption) => (
    <PropertyCardCaption
      key={caption.id}
      label={caption.label}
      value={caption.value}
    />
  ));
};

export default CommonPropertyCardCaptions;
