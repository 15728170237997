
export class Confirmer {
    #confirmer

    constructor(confirmer) {
        this.#confirmer = confirmer;
    }

    get() {
        return this.#confirmer
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
