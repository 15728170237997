import 'interfaces/css/Government/government-simple-schedule-section.css';

const TotalApplications = ({ propertiesCount, invitations }) => {
  // TODO: governmentIdを使って参加申し込み総数を取得（せり売形式 | 入札形式）
  const { auctionPropertiesCount: auctionCount, bidPropertiesCount: bidCount } =
    propertiesCount;
  const invitation =
    invitations.length > 0
      ? invitations[0][invitations[0].length - 1]
      : undefined;

  let aplCountAuctionDisplay =
    invitation !== undefined ? invitation.aplCountAuctionDisplay : 'HIDDEN';
  let aplCountBidDisplay =
    invitation !== undefined ? invitation.aplCountBidDisplay : 'HIDDEN';

  return aplCountAuctionDisplay !== 'DISPLAY' &&
    aplCountBidDisplay !== 'DISPLAY' ? null : (
    <div className="total-applications flex">
      <span className="total-applications-label">参加申し込み総数：</span>
      {aplCountAuctionDisplay !== 'DISPLAY' ? null : (
        <span>せり売形式{auctionCount}件</span>
      )}
      {aplCountAuctionDisplay === 'DISPLAY' &&
        aplCountBidDisplay === 'DISPLAY' && <span className="partition" />}
      {aplCountBidDisplay !== 'DISPLAY' ? null : (
        <span>入札形式{bidCount}件</span>
      )}
    </div>
  );
};

export default TotalApplications;
