import { useState } from 'react';

import { IdAndNameData } from './helper/searchGovernmentSubCategoryId.js';
import TreeViewNode from './TreeViewNode.jsx';
import 'interfaces/css/Properties/place-tree.css';

const PlacesTree = () => {
  const [queryParam, setQueryParam] = useState([]);
  return (
    <div className="places-tree px-2 py-3" data-testid="places-tree">
      <div className="font-weight-bold" data-testid="places-tree-label">
        物件の所在
      </div>
      <div className="treeview treeview--dense theme--light">
        {IdAndNameData &&
          Object.keys(IdAndNameData).map((key, index) => {
            return (
              <TreeViewNode
                key={index}
                keyName={key}
                queryParam={queryParam}
                setQueryParam={setQueryParam}
              />
            );
          })}
      </div>
    </div>
  );
};

export default PlacesTree;
