
export class DisplayHomePage {
  #displayHomePage

  constructor(displayHomePage) {
    this.#displayHomePage = displayHomePage;
  }

  get() {
    return this.#displayHomePage
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
