import { useState } from 'react';

export function useEmblemInputs() {
    const [useEmblem, setUseEmblem] = useState('');
    const [emblemFile, setEmblemFile] = useState('');
    const [useCharacter, setUseCharacter] = useState('');
    const [characterName, setCharacterName] = useState('');
    const [characterInsteadEmblem, setCharacterInsteadEmblem] = useState('');
    const [characterFile, setCharacterFile] = useState('');

    return {
        type: {
            useEmblem: 'radio',
            emblemFile: 'text',
            useCharacter: 'radio',
            characterName: 'text',
            characterInsteadEmblem: 'check',
            characterFile: 'text',
        },
        data: {
            useEmblem,
            emblemFile,
            useCharacter,
            characterName,
            characterInsteadEmblem,
            characterFile,
        },
        setters: {
            setUseEmblem,
            setEmblemFile,
            setUseCharacter,
            setCharacterName,
            setCharacterInsteadEmblem,
            setCharacterFile,
        }
    };
}