// react/src/shared/utils/helper/naviValidators.js
import {
  validateEmptyString,
  validateEmailFormatCheck,
  validateRangeLength,
  notInclude,
  validateNumber,
  hasEmoji,
  validateLength,
  validatePrefecture,
  validateInvoiceType,
  validateSelected,
  validateNumberRange,
  replaceFullwidthKatakana,
  validateSomeSelected,
  includesPrefecture,
  validateImageSizeCheck,
  isValidImageExtension,
  validateRequiredFields,
  includesHalftwidthKatakana,
  isFullwidthKatakana,
  validateNumberLength,
  validateDeposit,
  isOnlyFullwidthKatakana,
  validateAlphanum,
  validateDecimal,
  isValidAmount,
  isInvalidInput,
  validateInclude,
  validateExtensionFormat,
} from '../../../application/validations';

// 必須チェック
export function validateRequired(value) {
  return validateEmptyString(value);
}

// メールフォーマットチェック
export function validateEmailFormat(email) {
  return validateEmailFormatCheck(email);
}

// メールアドレス 文字数が128文字以内であるか
export function validateEmailLength(email) {
  return validateRangeLength(email, 0, 128);
}

// 空白が含まれていないか
export function validateHasNoSpace(input) {
  return notInclude(input, [' ']);
}

// 半角数字以外が含まれていないか
export function validateOnlyNumber(number) {
  return validateNumber(number);
}

export { validateLength, validateRangeLength };

// 電話番号(3分割）	文字数が4文字以内であるか
export function validateTelLength(tel_number) {
  return validateRangeLength(tel_number, 0, 4);
}

// 電話番号(3分割）	空白が含まれていないか
export function validateTelSpaceMessage(tel_number) {
  return notInclude(tel_number, [' ']);
}

// 電話番号(3分割）	半角数字以外が含まれていないか
export function validateTelNotInclude(tel_number) {
  return validateNumber(tel_number);
}

// 電話番号(3分割）	文字数が合計で10文字以上11文字以下であるか
export function validateTelRangeLength(tel_number) {
  return validateRangeLength(tel_number, 10, 11);
}

// 内線	文字数が10文字以内であるか
export function validateExtensionLength(extension) {
  return validateRangeLength(extension, 0, 10);
}

// 内線	空白が含まれていないか
export function validateExtensionSpaceMessage(extension) {
  return notInclude(extension, [' ']);
}

// 内線	「,」「-」「.」「/」と半角数字以外が含まれていないか
export function validateExtensionNotInclude(extension) {
  return validateExtensionFormat(extension);
}

// 本文	文字数が12000文字以内であるか
export function validateBodyLength1(body) {
  return validateRangeLength(body, 0, 12000);
}

// URL	文字列に「https://」が含まれているか
export function validateUrlValidUrl(url) {
  return validateInclude(url, ['https://', 'http://']);
}

// URL	文字数が2048文字以内であるか
export function validateUrlLength(url) {
  return validateRangeLength(url, 0, 2048);
}
// URL	文字数が1文字以上2048文字以内であるか
export function validateUrlRequiredLength(url) {
  return validateRangeLength(url, 1, 2048);
}

// 権利移転手続きについての説明	文字数が300文字以内であるか
export function validateManagementTransLength(description) {
  return validateRangeLength(description, 0, 300);
}

// 動産の説明「表示する」にした場合	文字数が1文字以上500文字以内であるか
export function validateMovableDescriptionDisplayRangeLength(description) {
  return validateRangeLength(description, 1, 500);
}

// 不動産の説明「表示する」にした場合	文字数が1文字以上1000文字以内であるか
export function validateEstateDescriptionDisplayRangeLength2(description) {
  return validateRangeLength(description, 1, 1000);
}

// 不動産の説明「表示しない」にした場合	文字数が1000文字以内であるか
export function validateEstateDescriptionHiddenLength2(description) {
  return validateRangeLength(description, 0, 1000);
}

// 案内ページ名「表示する」にした場合	文字数が1文字以上30文字以内であるか
export function validateDocumentNameDisplayRangeLength(name) {
  return validateRangeLength(name, 1, 30);
}

// 建物	文字数が50文字以内であるか
export function validateBuildLength(build) {
  return validateRangeLength(build, 0, 50);
}

// 申し込み完了メール	文字数が1000文字以内であること
export function validateApplyCompletionEmailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 申し込み完了メール	絵文字・機種依存文字が含まれていない
export function validateApplyCompletionEmailHasEmoji(email) {
  return hasEmoji(email);
}

// 追加入札非該当者通知メール	文字数が1000文字以内であること
export function validateAdditionalBidNonApplicableEmailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 追加入札未落札者通知メール	文字数が1000文字以内であること
export function validateAddBidLoserEmailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 追加入札次順位通知メール	文字数が1000文字以内であること
export function validateAddBidNextWinnerMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 追加入札次順位通知メール	絵文字・機種依存文字が含まれていない
export function validateAddBidNextWinnerMailHasEmoji(email) {
  return hasEmoji(email);
}

// 追加入札最高価通知メール	文字数が1000文字以内であること
export function validateAddBidWinnerMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 追加入札最高価通知メール	絵文字・機種依存文字が含まれていない
export function validateAddBidWinnerMailHasEmoji(email) {
  return hasEmoji(email);
}

// 追加入札確認メール	文字数が1000文字以内であること
export function validateAddBidEntryMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 追加入札確認メール	絵文字・機種依存文字が含まれていない
export function validateAddBidEntryMailHasEmoji(email) {
  return hasEmoji(email);
}

// 追加入札該当者メール	文字数が1000文字以内であること
export function validateAddBidderBidMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 追加入札該当者メール	絵文字・機種依存文字が含まれていない
export function validateAddBidderBidMailHasEmoji(email) {
  return hasEmoji(email);
}

// 次順位通知メール	文字数が1000文字以内であること
export function validateNormalNextWinnerBidMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 次順位通知メール	絵文字・機種依存文字が含まれていない
export function validateNormalNextWinnerBidMailHasEmoji(email) {
  return hasEmoji(email);
}

// 引渡方法登録（内容変更）完了メール	文字数が1000文字以内であること
export function validateDeliveryRequestRegistrationChangeCompletionMailLength(
  email,
) {
  return validateRangeLength(email, 0, 1000);
}

// 引渡方法登録（内容変更）完了メール	絵文字・機種依存文字が含まれていない
export function validateDeliveryRequestRegistrationChangeCompletionMailHasEmoji(
  email,
) {
  return hasEmoji(email);
}

// 引渡方法登録完了メール	文字数が1000文字以内であること
export function validateDeliveryRequestRegistrationCompletionMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 引渡方法登録完了メール	絵文字・機種依存文字が含まれていない
export function validateDeliveryRequestRegistrationCompletionMailHasEmoji(
  email,
) {
  return hasEmoji(email);
}

// リマインドメール（入札終了前）	文字数が1000文字以内であること
export function validateBeforeBidEndRemindMailLength1(email) {
  return validateRangeLength(email, 0, 1000);
}

// リマインドメール（入札終了前）	絵文字・機種依存文字が含まれていない
export function validateBeforeBidEndRemindMailHasEmoji1(email) {
  return hasEmoji(email);
}

// リマインドメール（入札開始時）	文字数が1000文字以内であること
export function validateOnBidStartRemindMailLength1(email) {
  return validateRangeLength(email, 0, 1000);
}

// リマインドメール（入札開始時）	絵文字・機種依存文字が含まれていない
export function validateOnBidStartRemindMailHasEmoji1(email) {
  return hasEmoji(email);
}

// リマインドメール（入札開始4日前）	文字数が1000文字以内であること
export function validateBeforeBidStartFourDayRemindMailLength1(email) {
  return validateRangeLength(email, 0, 1000);
}

// リマインドメール（入札開始4日前）	絵文字・機種依存文字が含まれていない
export function validateBeforeBidStartFourDayRemindMailHasEmoji1(email) {
  return hasEmoji(email);
}

// 公売中止連絡メール	文字数が1000文字以内であること
export function validateCancelAuctionMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 公売中止連絡メール	絵文字・機種依存文字が含まれていない
export function validateCancelAuctionMailHasEmoji(email) {
  return hasEmoji(email);
}

// クレジットカード申し込み完了メール	文字数が1000文字以内であること
export function validateCreditApplyCompletionEmailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// クレジットカード申し込み完了メール	絵文字・機種依存文字が含まれていない
export function validateCreditApplyCompletionEmailHasEmoji(email) {
  return hasEmoji(email);
}

// 銀行振込など仮申し込み完了メール	文字数が1000文字以内であること
export function validateBankTransferTemporaryApplyCompletionEmailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 銀行振込など仮申し込み完了メール	絵文字・機種依存文字が含まれていない
export function validateBankTransferTemporaryApplyCompletionEmailHasEmoji(
  email,
) {
  return hasEmoji(email);
}

// 銀行振込など本申し込み完了メール	文字数が1000文字以内であること
export function validateBankTransferApprovedApplyCompletionEmailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 銀行振込など本申し込み完了メール	絵文字・機種依存文字が含まれていない
export function validateBankTransferApprovedApplyCompletionEmailHasEmoji(
  email,
) {
  return hasEmoji(email);
}

// 行政機関共通設定	文字数が1000文字以内であること
export function validateGovernmentCommonSettingLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 行政機関共通設定	絵文字・機種依存文字が含まれていない
export function validateGovernmentCommonSettingHasEmoji(email) {
  return hasEmoji(email);
}

// 現在の最高価メール	文字数が1000文字以内であること
export function validateBidderHighestMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 現在の最高価メール	絵文字・機種依存文字が含まれていない
export function validateBidderHighestMailHasEmoji(email) {
  return hasEmoji(email);
}

// 高値更新メール	文字数が1000文字以内であること
export function validateBidderLoserMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 高値更新メール	絵文字・機種依存文字が含まれていない
export function validateBidderLoserMailHasEmoji(email) {
  return hasEmoji(email);
}

// 現在の最高価取消メール	文字数が1000文字以内であること
export function validateBidderHighestCancelMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 現在の最高価取消メール	絵文字・機種依存文字が含まれていない
export function validateBidderHighestCancelMailHasEmoji(email) {
  return hasEmoji(email);
}

// クレジットカード仮申し込み完了メール	文字数が1000文字以内であること
export function validateCreditTemporaryApplyEmailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// クレジットカード仮申し込み完了メール	絵文字・機種依存文字が含まれていない
export function validateCreditTemporaryApplyEmailHasEmoji(email) {
  return hasEmoji(email);
}

// クレジットカード本申し込み完了メール	文字数が1000文字以内であること
export function validateCreditApplyEmailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// クレジットカード本申し込み完了メール	絵文字・機種依存文字が含まれていない
export function validateCreditApplyEmailHasEmoji(email) {
  return hasEmoji(email);
}

// 仮申し込み完了メール	文字数が1000文字以内であること
export function validateTemporaryApplyCompletonEmailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 仮申し込み完了メール	絵文字・機種依存文字が含まれていない
export function validateTemporaryApplyCompletonEmailHasEmoji(email) {
  return hasEmoji(email);
}

// 本申し込み完了メール	文字数が1000文字以内であること
export function validateApprovedApplyCompletionEmailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 本申し込み完了メール	絵文字・機種依存文字が含まれていない
export function validateApprovedApplyCompletionEmailHasEmoji(email) {
  return hasEmoji(email);
}

// 中止連絡メール	文字数が1000文字以内であること
export function validateCancelEmailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 中止連絡メール	絵文字・機種依存文字が含まれていない
export function validateCancelEmailHasEmoji(email) {
  return hasEmoji(email);
}

// 申し込み取消、入札取消連絡メール	文字数が1000文字以内であること
export function validateApplyCancelEmailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 申し込み取消、入札取消連絡メール	絵文字・機種依存文字が含まれていない
export function validateApplyCancelEmailHasEmoji(email) {
  return hasEmoji(email);
}

// リマインドメール(入札開始4日前)	文字数が1000文字以内であること
export function validateBeforeBidStartFourDayRemindMailLength2(email) {
  return validateRangeLength(email, 0, 1000);
}

// リマインドメール(入札開始4日前)	絵文字・機種依存文字が含まれていない
export function validateBeforeBidStartFourDayRemindMailHasEmoji2(email) {
  return hasEmoji(email);
}

// リマインドメール(入札開始時)	文字数が1000文字以内であること
export function validateOnBidStartRemindMailLength2(email) {
  return validateRangeLength(email, 0, 1000);
}

// リマインドメール(入札開始時)	絵文字・機種依存文字が含まれていない
export function validateOnBidStartRemindMailHasEmoji2(email) {
  return hasEmoji(email);
}

// リマインドメール(入札終了前)	文字数が1000文字以内であること
export function validateBeforeBidEndRemindMailLength2(email) {
  return validateRangeLength(email, 0, 1000);
}

// リマインドメール(入札終了前)	絵文字・機種依存文字が含まれていない
export function validateBeforeBidEndRemindMailHasEmoji2(email) {
  return hasEmoji(email);
}

// 入札確認メール	文字数が1000文字以内であること
export function validateBidEntryMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 入札確認メール	絵文字・機種依存文字が含まれていない
export function validateBidEntryMailHasEmoji(email) {
  return hasEmoji(email);
}

// 最高価通知メール	文字数が1000文字以内であること
export function validateNormalWinnerBidMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 最高価通知メール	絵文字・機種依存文字が含まれていない
export function validateNormalWinnerBidMailHasEmoji(email) {
  return hasEmoji(email);
}

// 未落札者通知メール	文字数が1000文字以内であること
export function validateNormalLoserBidMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 未落札者通知メール	絵文字・機種依存文字が含まれていない
export function validateNormalLoserBidMailHasEmoji(email) {
  return hasEmoji(email);
}

// 終了告知メール	文字数が1000文字以内であること
export function validateCompleteNotificationMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 終了告知メール	絵文字・機種依存文字が含まれていない
export function validateCompleteNotificationMailHasEmoji(email) {
  return hasEmoji(email);
}

// 市区町村*	文字数が1文字以上50文字以内であるか
export function validateCityRequiredRangeLength(address) {
  return validateRangeLength(address, 1, 50);
}

// 市区町村*	絵文字・機種依存文字が含まれていない
export function validateCityRequiredHasEmoji(city) {
  return hasEmoji(city);
}

// 都道府県*	都道府県が選択されている
export function validatePrefectureRequiredRequired(prefecture) {
  return validatePrefecture(prefecture);
}

// 「確認する」ボタンをクリックする	必須項目がすべて入力されているか
export function validateIsConfirmButtonClickedRequired1(
  confirm,
  required_list,
) {
  return validateRequiredFields(confirm, required_list);
}

// 連絡先機関名*	文字数が1文字以上50文字以内であるか
export function validateContactNameRequiredRangeLength(name) {
  return validateRangeLength(name, 1, 50);
}

// 連絡先機関名*	絵文字・機種依存文字が含まれていない
export function validateContactNameRequiredHasEmoji(corporate_name) {
  return hasEmoji(corporate_name);
}

// 適格請求書*	「発行可能」「発行負荷」どちらかが選択されている
export function validateEligibleInvoiceRequiredRequired(qualified_bill) {
  return !validateInvoiceType(qualified_bill);
}

// （公有: 予定価格）予定価格*	半角数字以外が含まれていないか
export function validateEstimatePriceRequiredNotInclude1(price) {
  return validateNumber(price);
}

// （公有: 予定価格）予定価格*	11桁以内であるか
export function validateEstimatePriceRequiredLength1(price) {
  return validateRangeLength(price, 0, 11);
}

// 見積価格*	半角数字以外が含まれていないか
export function validateEstimatePriceRequiredNotInclude2(estimate_price) {
  return validateNumber(estimate_price);
}

// 見積価格*	11桁以内であるか
export function validateEstimatePriceRequiredLength2(estimate_price) {
  return validateRangeLength(estimate_price, 0, 11);
}

// 備考	文字数が50文字以内であるか
export function validateNoteLength(note) {
  return validateRangeLength(note, 0, 50);
}

// 備考	絵文字・機種依存文字が含まれていない
export function validateNoteHasEmoji(note) {
  return hasEmoji(note);
}

// 電話受付時間	文字数が50文字以内であるか
export function validateReferenceAcceptLength(tel_time) {
  return validateRangeLength(tel_time, 0, 50);
}

// 電話受付時間	文字数が100文字以内であるか
export function validateInquiryAcceptLength(tel_time) {
  return validateRangeLength(tel_time, 0, 100);
}

// 電話受付時間	絵文字・機種依存文字が含まれていない
export function validateReferenceAcceptHasEmoji(tel_time) {
  return hasEmoji(tel_time);
}

// 担当部署名	文字数が50文字以内であるか
export function validateDepartmentLength(pic_name) {
  return validateRangeLength(pic_name, 0, 50);
}

// 担当部署名	絵文字・機種依存文字が含まれていない
export function validateDepartmentHasEmoji1(pic_name) {
  return hasEmoji(pic_name);
}

// 出品行政機関名	文字数が30文字以内であるか
export function validateInquiryNameLength(name) {
  return validateRangeLength(name, 0, 30);
}

// 出品行政機関名	絵文字・機種依存文字が含まれていない
export function validateInquiryNameHasEmoji(name) {
  return hasEmoji(name);
}

// （公有: 契約締結期限）	日付が選択されている
export function validateCcontractedLimitRequired(data) {
  return validateSelected(data);
}

// （公有: 売払代金納付期限）買受代金納付期限*	日付が選択されている
export function validatePaymentDeadlineRequiredRequired1(data) {
  return validateSelected(data);
}

// （公有: 議決日）	文字数が30文字以内であるか
export function validateRresolutionDateLength(public_data) {
  return validateRangeLength(public_data, 0, 30);
}

// （公有: 議決日）	絵文字・機種依存文字が含まれていない
export function validateRresolutionDateHasEmoji(public_data) {
  return hasEmoji(public_data);
}

// 掲載順位	1~9999のいずれかが入力されている
export function validatePrintOrderRangeNumber(ranking) {
  return validateNumberRange(ranking);
}

// カテゴリ*	カテゴリが選択されている
export function validateCategoryRequiredRequired(category) {
  return validateSelected(category);
}

// &&&&
// 執行機関名称*	執行機関名称が選択されている
export function validateDivisionNameRequiredRequired(name) {
  return validateSelected(name);
}

// 売却区分番号*	文字数が1文字以上30文字以内であるか
export function validateManageIdRequiredRangeLength(number) {
  return validateRangeLength(number, 1, 30);
}

// 売却区分番号*	絵文字・機種依存文字が含まれていない
export function validateManageIdRequiredHasEmoji(number) {
  return hasEmoji(number);
}

// タイトル*	文字数が1文字以上50文字以内であるか
export function validateTitleRequiredRangeLength(title) {
  return validateRangeLength(title, 1, 50);
}

// タイトル*	半角全角のカタカナは全角のカタカナに置換する
export function validateTitleRequiredFullwidthKatakana(title) {
  return replaceFullwidthKatakana(title);
}

// タイトル*	絵文字・機種依存文字が含まれていない
export function validateTitleRequiredHasEmoji(title) {
  return hasEmoji(title);
}

// 支払い方法*	一つ以上選択されている
export function validatePaymentMethodRequiredRequired(payment_method) {
  return validateSomeSelected(payment_method);
}

// 動画URL	"URL	文字列に「https://」が含まれているか"
export function validateVideoUrlValidUrl(url) {
  return validateInclude(url, ['https://', 'http://']);
}

// 動画URL	文字数が2048文字以内であるか
export function validateVideoUrlLength(url) {
  return validateRangeLength(url, 0, 2048);
}

// メモ登録	文字数が50文字以内であるか
export function validateMemoRegisterLength(memo) {
  return validateRangeLength(memo, 0, 50);
}

// メモ登録	絵文字・機種依存文字が含まれていないか
export function validateMemoRegisterHasEmoji(memo) {
  return hasEmoji(memo);
}

// 「登録」ボタンをクリック	必須項目が全て入力されているか
export function validateIsRegisterButtonClickedRangeNumber(
  registration,
  required_list,
) {
  return validateRequiredFields(registration, required_list);
}

// 説明	文字数が500文字以内であるか
export function validateDescriptionLength1(discription) {
  return validateRangeLength(discription, 0, 500);
}

// 参加公売（参加申込開始日）*	公告が選択されている
export function validateOpenStartRequiredRequired(data) {
  return validateSelected(data);
}

// 公告名*	文字数が1文字以上30文字以内であるか
export function validateInvitationNameRequiredRangeLength(name) {
  return validateRangeLength(name, 1, 30);
}

// 公告名*	絵文字・機種依存文字が含まれていない
export function validateInvitationNameRequiredHasEmoji(name) {
  return hasEmoji(name);
}

// 地域*	文字数が1文字以上30文字以内であるか
export function validateAreaRequiredRangeLength(area) {
  return validateRangeLength(area, 1, 30);
}

// 地域*	文字列に都道府県が含まれている
export function validateAreaRequiredInclude(area) {
  return includesPrefecture(area);
}

// 地域*	絵文字・機種依存文字が含まれていない
export function validateAreaRequiredHasEmoji(area) {
  return hasEmoji(area);
}

// 担当部署	文字数が1文字以上30文字以内であるか
export function validateDepartmentRangeLength(department_in_charge) {
  return validateRangeLength(department_in_charge, 1, 30);
}

// 担当部署	絵文字・機種依存文字が含まれていない
export function validateDepartmentHasEmoji2(department_in_charge) {
  return hasEmoji(department_in_charge);
}

//「確認画面」ボタンをクリック	必須項目(*)がすべて入力されているか
export function validateIsConfirmPageButtonClickedRequired(
  confirm,
  required_list,
) {
  return validateRequiredFields(confirm, required_list);
}

// 担当者名（姓）*	絵文字・機種依存文字が含まれていない
export function validateFamilyNameRequiredHasEmoji1(first_name) {
  return hasEmoji(first_name);
}

// 絵文字・機種依存文字が含まれていない
export function validateHasEmoji(text) {
  return hasEmoji(text);
}

// 担当者名（名）*	文字数が1文字以上30文字以内か
export function validateFamilyNameKanaRequiredRangeLength(last_name) {
  return validateRangeLength(last_name, 1, 30);
}

// 担当者名（名）*	絵文字・機種依存文字が含まれていない
export function validateFamilyNameKanaRequiredHasEmoji(last_name) {
  return hasEmoji(last_name);
}

// 担当者名（姓カナ）*	文字数が1文字以上30文字以内か
export function validateFirstNameRequiredRangeLength(first_name_kana) {
  return validateRangeLength(first_name_kana, 1, 30);
}

// 担当者名（姓カナ）*	文字列が全角のカタカナであるか
export function validateFirstNameRequiredFullKatakana(first_name_kana) {
  return isFullwidthKatakana(first_name_kana);
}

// 担当者名（姓カナ）*	文字列が全角のカタカナであるか
export function validateFamilyNameRequiredFullKatakana(first_name_kana) {
  return isFullwidthKatakana(first_name_kana);
}

// 文字列が全角のカタカナであるか
export function validateFullKatakana(text) {
  return isFullwidthKatakana(text);
}

// 担当者名（姓カナ）*	絵文字・機種依存文字が含まれていない
export function validateFirstNameRequiredHasEmoji(first_name_kana) {
  return hasEmoji(first_name_kana);
}

// 担当者名（名カナ）*	文字数が1文字以上30文字以内か
export function validateFirstNameKanaRequiredRangeLength(last_name_kana) {
  return validateRangeLength(last_name_kana, 1, 30);
}

// 担当者名（名カナ）*	文字列が全角のカタカナであるか
export function validateFirstNameKanaRequiredFullKatakana(last_name_kana) {
  return isFullwidthKatakana(last_name_kana);
}

// 担当者名（名カナ）*	絵文字・機種依存文字が含まれていない
export function validateFirstNameKanaRequiredHasEmoji(last_name_kana) {
  return hasEmoji(last_name_kana);
}

// 部署名*	文字数が1文字以上30文字以内か
export function validateDepartmentRequiredRangeLength(department_name) {
  return validateRangeLength(department_name, 1, 30);
}

// 部署名*	絵文字・機種依存文字が含まれていない
export function validateDepartmentRequiredHasEmoji(department_name) {
  return hasEmoji(department_name);
}

// メールアドレス	  絵文字・機種依存文字が含まれていない
export function validateEmailHasEmoji(email) {
  return hasEmoji(email);
}

// 「確認する」ボタンをクリック	  必須項目(*)がすべて入力されているか
export function validateIsConfirmButtonClickedRequired2(
  confirm,
  required_list,
) {
  return validateRequiredFields(confirm, required_list);
}

// 本文	  文字数が5000文字以内であるか
export function validateBodyLength2(body) {
  return validateRangeLength(body, 0, 5000);
}

// 必要書類提出の案内ページURL*	  文字列の先頭が「https://」であるか
export function validateDocumentSubmissionUrlRequiredValidUrl(url) {
  return validateInclude(url, ['https://', 'http://']);
}

// 必要書類提出の案内ページURL*	  文字数が2048文字以内であるか
export function validateDocumentSubmissionUrlRequiredLength(url) {
  return validateRangeLength(url, 0, 2048);
}

// 共同入札案内ページURL	 文字列の先頭が「https://」であるか
export function validateJointBiddingUrlValidUrl1(url) {
  return validateInclude(url, ['https://', 'http://']);
}

// 共同入札案内ページURL	 文字数が2048文字以内であるか
export function validateJointBiddingUrlLength1(url) {
  return validateRangeLength(url, 0, 2048);
}

// 銀行振込など案内ページURL*	 文字列の先頭が「https://」であるか
export function validateBankTransferUrlRequiredValidUrl(url) {
  return validateInclude(url, ['https://', 'http://']);
}

// 銀行振込など案内ページURL*	 文字数が2048文字以内であるか
export function validateBankTransferUrlRequiredLength(url) {
  return validateRangeLength(url, 0, 2048);
}

// 共同入札案内ページURL	 文字列の先頭が「https://」であるか
export function validateJointBiddingUrlValidUrl2(url) {
  return validateInclude(url, ['https://', 'http://']);
}

// 共同入札案内ページURL	 文字数が2048文字以内であるか
export function validateJointBiddingUrlLength2(url) {
  return validateRangeLength(url, 0, 2048);
}

// 公証画像ファイル「使用する」にした場合  	画像が選択されているか
export function validateEmblemFileUseRequired(image_file) {
  return validateSelected(image_file);
}

// 公章画像ファイル	拡張子が.jpg .jpeg .png .gif であるか
export function validateEmblemFileValidExtension(image_file) {
  return isValidImageExtension({ name: image_file });
}

// 公章画像ファイル	画像のサイズが5MB以下であるか
export function validateEmblemFileSize(image_file) {
  return validateImageSizeCheck(image_file);
}

// キャラクター名「使用する」にした場合  	    文字数が1文字以上、20文字以下であるか
export function validateCharacterNameUseRangeLength(name) {
  return validateRangeLength(name, 1, 20);
}

// キャラクター名「使用する」にした場合	    絵文字・機種依存文字が含まれていない
export function validateCharacterNameUseHasEmoji(name) {
  return hasEmoji(name);
}

// キャラクター名「使用しない」にした場合 	文字数が20文字以下であるか
export function validateCharacterNameNoneLength(name) {
  return validateRangeLength(name, 1, 20);
}

// キャラクター名「使用しない」にした場合    	絵文字・機種依存文字が含まれていない
export function validateCharacterNameNoneHasEmoji(name) {
  return hasEmoji(name);
}

// キャラクター画像ファイル	    拡張子が.jpg .jpeg .png .gif であるか
export function validateCharacterImageValidExtension(image_file) {
  return isValidImageExtension({ name: image_file });
}

// キャラクター画像ファイル	    画像のサイズが5MB以下であるか
export function validateCharacterImageSize(image_file) {
  return validateImageSizeCheck(image_file);
}

// お問い合せ先「使用する」にした場合	文字数が1文字以上50文字以内であるか
export function validateInquiryDisplayRangeLength1(contact) {
  return validateRangeLength(contact, 1, 50);
}

// お問い合せ先「使用する」にした場合	絵文字・機種依存文字が含まれていない
export function validateInquiryDisplayHasEmoji1(contact) {
  return hasEmoji(contact);
}

// お問い合せ先「使用しない」にした場合	文字数が50文字以内であるか
export function validateInquiryHiddenLength1(contact) {
  return validateRangeLength(contact, 0, 50);
}

// お問い合せ先「使用しない」にした場合	絵文字・機種依存文字が含まれていない
export function validateInquiryHiddenHasEmoji1(contact) {
  return hasEmoji(contact);
}

// ホームページ名「使用する」にした場合	文字数が1文字以上50文字以内であるか
export function validateHpNameDisplayRangeLength(website) {
  return validateRangeLength(website, 1, 50);
}

// ホームページ名「使用する」にした場合	絵文字・機種依存文字が含まれていない
export function validateHpNameDisplayHasEmoji(website) {
  return hasEmoji(website);
}

// ホームページ名「使用しない」にした場合	文字数が50文字以内であるか
export function validateHpNameHiddenLength(website) {
  return validateRangeLength(website, 0, 50);
}

// ホームページ名「使用しない」にした場合	絵文字・機種依存文字が含まれていない
export function validateHpNameHiddenHasEmoji(website) {
  return hasEmoji(website);
}

// 危険負担	文字数が1000文字以内であるか
export function validateImportantRiskBearingLength(burden_risk) {
  return validateRangeLength(burden_risk, 0, 1000);
}

// 危険負担	絵文字・機種依存文字が含まれていない
export function validateImportantRiskBearingHasEmoji(burden_risk) {
  return hasEmoji(burden_risk);
}

// 契約不適合責任	文字数が1000文字以内であるか
export function validateImportantNonConformingContractsLiabilityLength(
  nonconformity,
) {
  return validateRangeLength(nonconformity, 0, 1000);
}

// 契約不適合責任	絵文字・機種依存文字が含まれていない
export function validateImportantNonConformingContractsLiabilityHasEmoji(
  nonconformity,
) {
  return hasEmoji(nonconformity);
}

// 引き渡し条件	文字数が1000文字以内であるか
export function validateImportantTermsDeliveryLength(handover_conditions) {
  return validateRangeLength(handover_conditions, 0, 1000);
}

// 引き渡し条件	絵文字・機種依存文字が含まれていない
export function validateImportantTermsDeliveryHasEmoji1(handover_conditions) {
  return hasEmoji(handover_conditions);
}

// 執行機関の引き渡し義務	文字数が1000文字以内であるか
export function validateImportantObligationDeliveryLength(obligation) {
  return validateRangeLength(obligation, 0, 1000);
}

// 執行機関の引き渡し義務	絵文字・機種依存文字が含まれていない
export function validateImportantObligationDeliveryHasEmoji(obligation) {
  return hasEmoji(obligation);
}

// 返品、交換	文字数が1000文字以内であるか
export function validateImportantReturnItemsLength(exchanges) {
  return validateRangeLength(exchanges, 0, 1000);
}

// 返品、交換	絵文字・機種依存文字が含まれていない
export function validateImportantReturnItemsHasEmoji(exchanges) {
  return hasEmoji(exchanges);
}

// 保管費用	文字数が1000文字以内であるか
export function validateImportantStorageCostLength(storage_cost) {
  return validateRangeLength(storage_cost, 0, 1000);
}

// 保管費用	絵文字・機種依存文字が含まれていない
export function validateImportantStorageCostHasEmoji(storage_cost) {
  return hasEmoji(storage_cost);
}

// 落札者（最高価申込者）決定後、公売保証金が変換される場合	文字数が1000文字以内であるか
export function validateImportantReturnDepositLength(highest_price_applicant) {
  return validateRangeLength(highest_price_applicant, 0, 1000);
}

// 落札者（最高価申込者）決定後、公売保証金が変換される場合	絵文字・機種依存文字が含まれていない
export function validateImportantReturnDepositHasEmoji(
  highest_price_applicant,
) {
  return hasEmoji(highest_price_applicant);
}

// その他	文字数が1000文字以内であるか
export function validateImportantOtherLength1(other) {
  return validateRangeLength(other, 0, 1000);
}

// お問い合わせ先「表示する」にした場合	文字数が1文字以上50文字以内であるか
export function validateInquiryDisplayRangeLength2(contact) {
  return validateRangeLength(contact, 1, 50);
}

// お問い合わせ先「表示する」にした場合	絵文字・機種依存文字が含まれていない
export function validateInquiryDisplayHasEmoji2(contact) {
  return hasEmoji(contact);
}

// お問い合わせ先「表示しない」にした場合	文字数が50文字以内であるか
export function validateInquiryHiddenLength2(contact) {
  return validateRangeLength(contact, 0, 50);
}

// お問い合わせ先「表示しない」にした場合	絵文字・機種依存文字が含まれていない
export function validateInquiryHiddenHasEmoji2(contact) {
  return hasEmoji(contact);
}

// 住所(続き)*	文字数が1文字50文字以内であるか
export function validateInvoiceAddressRequiredRangeLength(address) {
  return validateRangeLength(address, 1, 50);
}

// 住所(続き)*	絵文字・機種依存文字が含まれていない
export function validateInvoiceAddressRequiredHasEmoji(address) {
  return hasEmoji(address);
}

// あて名ご名義人*	文字数が1文字以上50文字以内であるか
export function validateInvoiceWrittenDestinationRequiredRangeLength(name) {
  return validateRangeLength(name, 1, 50);
}

// あて名ご名義人*	絵文字・機種依存文字が含まれていない
export function validateInvoiceWrittenDestinationRequiredHasEmoji(name) {
  return hasEmoji(name);
}

// ご担当部署*	文字数が1文字以上50文字以内であるか
export function validateInvoiceDepartmentRequiredRangeLength(pic) {
  return validateRangeLength(pic, 1, 50);
}

// ご担当部署*	絵文字・機種依存文字が含まれていない
export function validateInvoiceDepartmentRequiredHasEmoji(pic) {
  return hasEmoji(pic);
}

// システム利用料振込人名義	文字数が50文字以内であるか
export function validateInvoicePayerNameLength(name) {
  return validateRangeLength(name, 0, 50);
}

// システム利用料振込人名義	絵文字・機種依存文字が含まれていない
export function validateInvoicePayerNameHasEmoji(name) {
  return hasEmoji(name);
}

// 市区町村	文字数が50文字以内であるか
export function validateCertDocumentCityLength(city) {
  return validateRangeLength(city, 0, 50);
}

// 市区町村	絵文字・機種依存文字が含まれていない
export function validateCertDocumentCityHasEmoji(city) {
  return hasEmoji(city);
}

// 住所（続き）	文字数が50文字以内であるか
export function validateCertDocumentAddressLength(address) {
  return validateRangeLength(address, 0, 50);
}

// 住所（続き）	絵文字・機種依存文字が含まれていない
export function validateCertDocumentAddressHasEmoji1(address) {
  return hasEmoji(address);
}

// あて先	文字数が50文字以内であるか
export function validateCertDocumentDestinationLength(contact) {
  return validateRangeLength(contact, 0, 50);
}

// あて先	絵文字・機種依存文字が含まれていない
export function validateCertDocumentDestinationHasEmoji1(contact) {
  return hasEmoji(contact);
}

// あて名ご名義人	文字数が50文字以内であるか
export function validateCertDocumentWrittenDestinationLength(name) {
  return validateRangeLength(name, 0, 50);
}

// あて名ご名義人	絵文字・機種依存文字が含まれていない
export function validateCertDocumentWrittenDestinationHasEmoji(name) {
  return hasEmoji(name);
}

// 追加入札未落札者通知メール	絵文字・機種依存文字が含まれていない
export function validateAddBidLoserEmailHasEmoji(email) {
  return hasEmoji(email);
}

// 追加入札非該当者通知メール	絵文字・機種依存文字が含まれていない
export function validateAddNonEligibleEmailHasEmoji(email) {
  return hasEmoji(email);
}

// 終了通知メール	文字数が1000文字以内であること
export function validateBidEndMailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 終了通知メール	絵文字・機種依存文字が含まれていない
export function validateBidEndMailHasEmoji(email) {
  return hasEmoji(email);
}

// (公売)クレジットカード仮申し込み完了メール	文字数が1000文字以内であること
export function validatePublicCreditTemporaryApplyEmailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// (公売)クレジットカード仮申し込み完了メール	絵文字・機種依存文字が含まれていない
export function validatePublicCreditTemporaryApplyEmailHasEmoji(email) {
  return hasEmoji(email);
}

// (公売)クレジットカード本申し込み完了メール	文字数が1000文字以内であること
export function validatePublicCreditApplyEmailLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// (公売)クレジットカード本申し込み完了メール	絵文字・機種依存文字が含まれていない
export function validatePublicCreditApplyEmailHasEmoji(email) {
  return hasEmoji(email);
}

// 執行機関名*	文字数が1文字以上50文字以内であるか
export function validateDivisionNameRequiredRangeLength(name) {
  return validateRangeLength(name, 1, 50);
}

// 執行機関名*	文字列に半角のカタカナが含まれていない
export function validateDivisionNameRequiredNotInclude(name) {
  return includesHalftwidthKatakana(name);
}

// 執行機関名*	絵文字・機種依存文字が含まれていない
export function validateDivisionNameRequiredHasEmoji(name) {
  return hasEmoji(name);
}

// 執行機関名（カナ）*	文字数が1文字以上50文字以内であるか
export function validateDivisionNameKanaRequiredRangeLength(name_kana) {
  return validateRangeLength(name_kana, 1, 50);
}

// &&&&
// 執行機関名（カナ）*	文字列に全角のカナ以外含まれない
export function validateDivisionNameKanaRequiredFullwidthKatakana1(name_kana) {
  return includesHalftwidthKatakana(name_kana);
}

// 執行機関名（カナ）*	半角全角のカタカナは全角のカタカナに置換する
export function validateDivisionNameKanaRequiredFullwidthKatakana2(corp_name) {
  return replaceFullwidthKatakana(corp_name);
}

// 執行機関名（カナ）*	絵文字・機種依存文字が含まれていない
export function validateDivisionNameKanaRequiredHasEmoji(name_kana) {
  return hasEmoji(name_kana);
}

// 金融機関名* 	文字数が1文字以上50文字以内であるか
export function validateFinancialInstitutionNameRequiredRangeLength(name) {
  return validateRangeLength(name, 1, 50);
}

// 金融機関名* 	絵文字・機種依存文字が含まれていない
export function validateFinancialInstitutionNameRequiredHasEmoji(name) {
  return hasEmoji(name);
}

// 支店名*	文字数が1文字以上50文字以内であるか
export function validateBranchNameRequiredRangeLength(name) {
  return validateRangeLength(name, 1, 50);
}

// 支店名*	絵文字・機種依存文字が含まれていない
export function validateBranchNameRequiredHasEmoji(name) {
  return hasEmoji(name);
}

// 口座種別*	口座種別が選択されている
export function validateAccountTypeRequiredRequired(account_type) {
  return validateSelected(account_type);
}

// 口座番号*	文字列が半角数字以外含まれない
export function validateAccountNumberRequiredHalfwidthNumber(number) {
  return validateNumber(number);
}

// 口座番号*	文字数が1文字以上8文字以内であるか
export function validateAccountNumberRequiredRangeLength(number) {
  return validateRangeLength(number, 1, 8);
}

// 口座名義*	文字数が1文字以上50文字以内であるか
export function validateAccountNameRequiredRangeLength(name) {
  return validateRangeLength(name, 1, 50);
}

// 口座名義*	絵文字・機種依存文字が含まれていない
export function validateAccountNameRequiredHasEmoji(name) {
  return hasEmoji(name);
}

// 口座名義（カナ）*	文字数が1文字以上50文字以内であるか
export function validateAccountNameKanaRequiredRangeLength(name_kana) {
  return validateRangeLength(name_kana, 1, 50);
}

// 口座名義（カナ）*	文字列に全角のカタカナ以外含まれない
export function validateAccountNameKanaRequiredFullKatakana(name_kana) {
  return isOnlyFullwidthKatakana(name_kana);
}

// 口座名義（カナ）*	半角全角のカタカナは全角のカタカナに置換する
export function validateAccountNameKanaRequiredFullwidthKatakana(name_kana) {
  return replaceFullwidthKatakana(name_kana);
}

// 住所（続き）*	文字数が1文字以上50文字以内であるか
export function validateCertDocumentAddressRangeLength(address) {
  return validateRangeLength(address, 1, 50);
}

// 住所（続き）*	絵文字・機種依存文字が含まれていない
export function validateCertDocumentAddressHasEmoji2(address) {
  return hasEmoji(address);
}

// あて先*	文字数が1文字以上50文字以内であるか
export function validateCertDocumentDestinationRangeLength(address) {
  return validateRangeLength(address, 1, 50);
}

// あて先*	絵文字・機種依存文字が含まれていない
export function validateCertDocumentDestinationHasEmoji2(address) {
  return hasEmoji(address);
}

// 書面あて名*	文字数が1文字以上50文字以内であるか
export function validateWrittenDestinationRequiredRangeLength(name) {
  return validateRangeLength(name, 1, 50);
}

// 書面あて名*	絵文字・機種依存文字が含まれていない
export function validateWrittenDestinationRequiredHasEmoji(name) {
  return hasEmoji(name);
}

// 「確認する」ボタンクリック	必須項目が全て入力されているか
export function validateIsConfirmButtonClickedRangeNumber(
  confirm,
  required_list,
) {
  return validateRequiredFields(confirm, required_list);
}

// 画像ファイル	画像が選択されているか
export function validateImageFileRequired(image_file) {
  return validateSelected(image_file);
}

// 画像ファイル	拡張子が.jpg .jpeg .png .gif であるか
export function validateImageFileValidExtension(image_file) {
  return isValidImageExtension({ name: image_file });
}

// 画像ファイル	画像のサイズが5MB以下であるか
export function validateImageSize(image_file) {
  return validateImageSizeCheck(image_file);
}

// コメント	文字数が30文字以内であるか
export function validateCommentLength(comment) {
  return validateRangeLength(comment, 0, 30);
}

// コメント	絵文字・機種依存文字が含まれていない
export function validateCommentHasEmoji(comment) {
  return hasEmoji(comment);
}

// 説明*	文字数が15000文字以内であるか
export function validateDescriptionLength2(discription) {
  return validateRangeLength(discription, 0, 15000);
}

// 公売保証金*	半角数字以外が含まれていないか
export function validateDepositRequiredNotInclude(guarantee) {
  return validateNumber(guarantee);
}

// 公売保証金*	"保証金納付方法
// カードが含まれる：2円以上100万円以内
// 銀行振込などの：1円以上11桁以内
// 保証金なし：入力不可（0円表示）"
export function validateDepositRequiredRangeLengthAndIsInput(guarantee, type) {
  return validateDeposit(guarantee, type);
}

// （公有: 入札保証金）入札保証金*	半角数字以外が含まれていないか
export function validateBidDepositRequiredNotInclude(bid_deposit) {
  return validateNumber(bid_deposit);
}

// （公有: 入札保証金）入札保証金*	"保証金納付方法
// カードが含まれる：2円以上100万円以内
// 銀行振込などの：1円以上11桁以内
// 保証金なし：入力不可（0円表示）"
export function validateBidDepositRequiredRangeLengthAndIsInput(
  bid_deposit,
  type,
) {
  return validateDeposit(bid_deposit, type);
}

// 証明書発行日*	年月日が選択されている
export function validateEffectiveDateRequiredRequired(data) {
  return validateSelected(data);
}

// 自動車登録番号*(2分割)	(前半)文字数が30文字以内であるか
export function validateRegistrationNumberRequiredLength1(number_first) {
  return validateRangeLength(number_first, 0, 30);
}

// 自動車登録番号*(2分割)	(後半)半角数字が4文字以内であるか
export function validateRegistrationNumberRequiredLength2(number_last) {
  return validateNumberLength(number_last, 4);
}

// 登録年月日	年月日が選択されている
export function validateRegistrationDateRequired(registration_data) {
  return validateSelected(registration_data);
}

// 初度登録年月	年月が選択されている
export function validateFirstRegistDateRequired(date_first_registration) {
  return validateSelected(date_first_registration);
}

// 自動車の種別*	文字数が30文字以内であるか
export function validateCarTypeRequiredLength(car_kind) {
  return validateRangeLength(car_kind, 0, 30);
}

// 自動車の種別*	絵文字・機種依存文字が含まれていない
export function validateCarTypeRequiredHasEmoji(car_kind) {
  return hasEmoji(car_kind);
}

// 用途*	用途が選択されている
export function validatePurposeRequiredRequired(purpose) {
  return validateSelected(purpose);
}

// 用途その他*	用途が選択されている
export function validatePurposeLength(body_shape) {
  return validateRangeLength(body_shape, 0, 50);
}

// 用途その他*	用途が選択されている
export function validatePurposeHasEmoji(body_shape) {
  return hasEmoji(body_shape);
}

// 車体の形状*	文字数が30文字以内であるか
export function validateShapeRequiredLength(body_shape) {
  return validateRangeLength(body_shape, 0, 30);
}

// 車体の形状*	絵文字・機種依存文字が含まれていない
export function validateShapeRequiredHasEmoji(body_shape) {
  return hasEmoji(body_shape);
}

// 車名（メーカー）*	車名（メーカー）が選択されている
export function validateManufactureRequiredRequired(car_maker) {
  return validateSelected(car_maker);
}

// 型式*	文字数が30文字以内であるか
export function validateFormRequiredLength(model_type) {
  return validateRangeLength(model_type, 0, 30);
}

// 型式*	絵文字・機種依存文字が含まれていない
export function validateFormRequiredHasEmoji(model_type) {
  return hasEmoji(model_type);
}

// 乗車定員*	半角数字以外が含まれていないか
export function validateSeatingCapacityRequiredNotInclude(number) {
  return validateNumber(number);
}

// 乗車定員*	数字が2桁以内であるか
export function validateSeatingCapacityRequiredNumberLength(number) {
  return validateRangeLength(number, 0, 2);
}

// 乗車定員*	小数点以下が入力されていないか
export function validateSeatingCapacityRequiredDecimal(number) {
  return validateDecimal(number, 0);
}

// 車両重量*	半角数字以外が含まれていないか
export function validateWeightRequiredNotInclude(car_weight) {
  return validateNumber(car_weight);
}

// 車両重量*	数字が5桁以内であるか
export function validateWeightRequiredNumberLength(car_weight) {
  return validateRangeLength(car_weight, 0, 5);
}

// 車両重量*	小数点以下が入力されていないか
export function validateWeightRequiredDecimal(car_weight) {
  return validateDecimal(car_weight, 0);
}

// 車両総重量*	半角数字以外が含まれていないか
export function validateTotalWeightRequiredNotInclude(car_gross_weight) {
  return validateNumber(car_gross_weight);
}

// 車両総重量*	数字が5桁以内であるか
export function validateTotalWeightRequiredNumberLength(car_gross_weight) {
  return validateRangeLength(car_gross_weight, 0, 5);
}

// 車両総重量*	小数点以下が入力されていないか
export function validateTotalWeightRequiredDecimal(car_gross_weight) {
  return validateDecimal(car_gross_weight, 0);
}

// 車台番号* (2分割)	半角英数字以外が含まれていないか
export function validateProductNumberRequiredNotInclude(vin) {
  return validateAlphanum(vin);
}

// 車台番号* (2分割)	文字数が30文字以内であるか
export function validateProductNumberRequiredLength(vin) {
  return validateRangeLength(vin, 0, 30);
}

// 原動機の型式*	半角英数字以外が含まれていないか
export function validateEngineTypeRequiredNotInclude(prime_mover) {
  return validateAlphanum(prime_mover);
}

// 原動機の型式*	文字数が30文字以内であるか
export function validateEngineTypeRequiredLength(prime_mover) {
  return validateRangeLength(prime_mover, 0, 30);
}

// 長さ*	半角数字以外が含まれていないか
export function validateLengthRequiredNotInclude(length) {
  return validateNumber(length);
}

// 長さ*	数字が4桁以内であるか
export function validateLengthRequiredNumberLength(length) {
  return validateRangeLength(length, 0, 4);
}

// 長さ*	小数点以下が入力されていないか
export function validateLengthRequiredDecimal(length) {
  return validateDecimal(length, 0);
}

// 幅*	半角数字以外が含まれていないか
export function validateWidthRequiredNotInclude(range) {
  return validateNumber(range);
}

// 幅*	数字が4桁以内であるか
export function validateWidthRequiredNumberLength(range) {
  return validateRangeLength(range, 0, 4);
}

// 幅*	小数点以下が入力されていないか
export function validateWidthRequiredDecimal(range) {
  return validateDecimal(range, 0);
}

// 高さ*	半角数字以外が含まれていないか
export function validateHeightRequiredNotInclude(height) {
  return validateNumber(height);
}

// 高さ*	数字が4桁以内であるか
export function validateHeightRequiredNumberLength(height) {
  return validateRangeLength(height, 0, 4);
}

// 高さ*	小数点以下が入力されていないか
export function validateHeightRequiredDecimal(height) {
  return validateDecimal(height, 0);
}

// 総排気量*	半角数字以外が含まれていないか
export function validateDisplacementRequiredNotInclude(total_displacement) {
  return validateNumber(total_displacement);
}

// 総排気量*	数字が2桁以内であるか(小数点以下を除く)
export function validateDisplacementRequiredNumberLength(total_displacement) {
  return validateRangeLength(total_displacement, 0, 2);
}

// 総排気量*	小数点第2位以下が入力されていないか
export function validateDisplacementRequiredDecimalLength(total_displacement) {
  return validateDecimal(total_displacement, 2);
}

// 燃料の種類*	文字数が30文字以内であるか
export function validateFuelTypeRequiredLength(kind) {
  return validateRangeLength(kind, 0, 30);
}

// 燃料の種類*	絵文字・機種依存文字が含まれていない
export function validateFuelTypeRequiredHasEmoji(kind) {
  return hasEmoji(kind);
}

// 前前軸重*	半角数字以外が含まれていないか
export function validateFrontWeightRequiredNotInclude(front_front_axle_weight) {
  return validateNumber(front_front_axle_weight);
}

// 前前軸重*	数字が5桁以内であるか
export function validateFrontWeightRequiredNumberLength(
  front_front_axle_weight,
) {
  return validateRangeLength(front_front_axle_weight, 0, 5);
}

// 前前軸重*	小数点以下が入力されていないか
export function validateFrontWeightRequiredDecimal(front_front_axle_weight) {
  return validateDecimal(front_front_axle_weight, 0);
}

// 後後軸重*	半角数字以外が含まれていないか
export function validateRearWeightRequiredNotInclude(rear_rear_axle_weight) {
  return validateNumber(rear_rear_axle_weight);
}

// 後後軸重*	数字が5桁以内であるか
export function validateRearWeightRequiredNumberLength(rear_rear_axle_weight) {
  return validateRangeLength(rear_rear_axle_weight, 0, 5);
}

// 後後軸重*	小数点以下が入力されていないか
export function validateRearWeightRequiredDecimal(rear_rear_axle_weight) {
  return validateDecimal(rear_rear_axle_weight, 0);
}

// 自動車検査証有効期限* ("あり"を選択した場合)	年月日が選択されている
export function validateSafetyTermRequiredRequired(car_expiration_date) {
  return validateSelected(car_expiration_date);
}

// 型式指定番号*	文字数が30文字以内であるか
export function validateFormalNumberRequiredLength(number) {
  return validateRangeLength(number, 0, 30);
}

// 型式指定番号*	半角英数字であるか
export function validateFormalNumberRequiredAlphanum(number) {
  return validateAlphanum(number);
}

// 類型区分番号*	文字数が30文字以内であるか
export function validateClassificationNumberRequiredLength(number) {
  return validateRangeLength(number, 0, 30);
}

// 類型区分番号*	半角英数字であるか
export function validateClassificationNumberRequiredAlphanum(number) {
  return validateAlphanum(number);
}

// 車体の色*	文字数が1文字以上20文字以内であるか
export function validateBodyColorRequiredRangeLength(color) {
  return validateRangeLength(color, 1, 20);
}

// 車体の色*	半角全角のカタカナは全角のカタカナに置換する
export function validateBodyColorRequiredFullwidthKatakana(car_color) {
  return replaceFullwidthKatakana(car_color);
}

// 車体の色*	絵文字・機種依存文字が含まれていない
export function validateBodyColorRequiredHasEmoji(car_color) {
  return hasEmoji(car_color);
}

// 走行距離*	半角数字以外が含まれていないか
export function validateCarMileageRequiredNotInclude(mileage) {
  return validateNumber(mileage);
}

// 走行距離*	数字が1桁以上10桁以内であるか
export function validateCarMileageRequiredRangeLength(mileage) {
  return validateRangeLength(mileage, 1, 10);
}

// 走行距離*	小数点以下が入力されていないか
export function validateCarMileageRequiredDecimal(mileage) {
  return validateDecimal(mileage, 0);
}

// 引渡時保管場所*(住所)	文字数が1文字以上、60文字以内であるか
export function validateInventoryLocationRequiredRangeLength(address) {
  return validateRangeLength(address, 1, 60);
}

// 引渡時保管場所*(地図URL)	"URL	文字列に「https://」が含まれているか"
export function validateLocationMapRequiredValidUrl(url) {
  return validateInclude(url, ['https://', 'http://']);
}

// 引渡時保管場所*(地図URL)	文字数が2048文字以内であるか
export function validateLocationMapRequiredLength(url) {
  return validateRangeLength(url, 0, 2048);
}

// 車両等の状況*	文字数が1文字以上、100文字以内であるか
export function validateCircumstanceRequiredRangeLength(condition) {
  return validateRangeLength(condition, 1, 100);
}

// 車両等の状況*	絵文字・機種依存文字が含まれていない
export function validateCircumstanceRequiredHasEmoji(condition) {
  return hasEmoji(condition);
}

// 引き渡し条件*	文字数が1文字以上、100文字以内であるか
export function validateImportantTermsDeliveryRangeLength(condition) {
  return validateRangeLength(condition, 1, 100);
}

// 引き渡し条件*	絵文字・機種依存文字が含まれていない
export function validateImportantTermsDeliveryHasEmoji2(condition) {
  return hasEmoji(condition);
}

// その他	文字数が500文字以内であるか
export function validateImportantOtherLength2(etc) {
  return validateRangeLength(etc, 0, 500);
}

// 説明	文字数が15000文字以内であるか
export function validateDescriptionLength3(discription) {
  return validateRangeLength(discription, 0, 15000);
}

// 公売保証金*「クレジットカード、銀行振込など両方」か「クレジットカード」を選択した場合  	半角数字以外が含まれていないか
export function validatePubSaleGuaranteeAmountBothRequiredNotInclude(
  guarantee,
) {
  return validateNumber(guarantee);
}

// &&&&
// 公売保証金*「クレジットカード、銀行振込など両方」か「クレジットカード」を選択した場合	2円以上、1,000,000円以内であるか
export function validatePubSaleGuaranteeAmountBothRequiredCreditDeposit(
  guarantee,
) {
  return isValidAmount(guarantee, 2, 1000000);
}

// 公売保証金*「銀行振込など」を選択した場合"	半角数字以外が含まれていないか
export function validatePubBankTransferRequiredNotInclude(guarantee) {
  return validateNumber(guarantee);
}

// 公売保証金*「銀行振込など」を選択した場合"	1円以上11桁以内であるか
export function validatePubBankTransferRequiredRangeLength(guarantee) {
  return validateRangeLength(guarantee, 1, 11);
}

// 公売保証金*「保証金なし」を選択した場合"	入力不可であるか
export function validatePubSaledepositOperationsNothingRequiredIsInput(
  guarantee,
) {
  return isInvalidInput(guarantee);
}

// 入札保証金*「クレジットカード、銀行振込など両方」か「クレジットカード」を選択した場合"	半角数字以外が含まれていないか
export function validateAssetSaleGuaranteeAmountBothRequiredNotInclude(
  bid_bond,
) {
  return validateNumber(bid_bond);
}

// 入札保証金*「クレジットカード、銀行振込など両方」か「クレジットカード」を選択した場合"	2円以上、1,000,000円以内であるか
export function validateAssetSaleGuaranteeAmountBothRequiredCreditDeposit(
  bid_bond,
) {
  return isValidAmount(bid_bond, 2, 1000000);
}

// 入札保証金*「銀行振込など」を選択した場合	半角数字以外が含まれていないか
export function validateAssetSaleBankTransferRequiredNotInclude(bid_bond) {
  return validateNumber(bid_bond);
}

// 入札保証金*「銀行振込など」を選択した場合	1円以上11桁以内であるか
export function validateAssetSaleBankTransferRequiredRangeLength(bid_bond) {
  return validateRangeLength(bid_bond, 1, 11);
}

// 入札保証金*「保証金なし」を選択した場合"	入力不可であるか
export function validateAssetdepositOperationsNothingRequiredIsInput(bid_bond) {
  return isInvalidInput(bid_bond);
}

// 物件の種類*	物件の種類が選択されている
export function validateEstateTypeRequiredRequired(propaty_type) {
  return validateSelected(propaty_type);
}

// 物件の所在*	物件の所在が選択されている
export function validateLocationRequiredRequired(address) {
  return validateSelected(address);
}

// 登記簿の表示	文字数が2000文字以内であるか
export function validatePropertyPrintLength(display) {
  return validateRangeLength(display, 0, 2000);
}

// 登記簿の表示	絵文字・機種依存文字が含まれていないか
export function validatePropertyPrintHasEmoji(display) {
  return hasEmoji(display);
}

// 所在地*	都道府県が選択されている
export function validateLocationTextRequiredRequired(prefecture) {
  return validatePrefecture(prefecture);
}

// 所在地*	文字数が100文字以内であるか
export function validateLocationTextRequiredLength(address) {
  return validateRangeLength(address, 0, 100);
}

// 所在地*	絵文字・機種依存文字が含まれていないか
export function validateLocationTextRequiredHasEmoji(address) {
  return hasEmoji(address);
}

// 住居表示	都道府県が選択されている
export function validateResidenceLocationRequired(prefecture) {
  return validatePrefecture(prefecture);
}

// 住居表示	文字数が100文字以内であるか
export function validateResidenceLocationLength(display) {
  return validateRangeLength(display, 0, 100);
}

// 住居表示	絵文字・機種依存文字が含まれていないか
export function validateResidenceLocationHasEmoji(display) {
  return hasEmoji(display);
}

// 交通、最寄りの駅など	文字数が400文字以内であるか
export function validateNearestStationLength(station) {
  return validateRangeLength(station, 0, 400);
}

// 交通、最寄りの駅など	絵文字・機種依存文字が含まれていないか
export function validateNearestStationHasEmoji(station) {
  return hasEmoji(station);
}

// 所在図	文字列に「https://」が含まれている
export function validateLocationUrlValidUrl(url) {
  return validateInclude(url, ['https://', 'http://']);
}

// 所在図	文字数が2048文字以内であるか
export function validateLocationUrlLength(address) {
  return validateRangeLength(address, 0, 2048);
}

// 現況	文字数が400文字以内であるか
export function validateEstateStatusLength(condition) {
  return validateRangeLength(condition, 0, 400);
}

// 現況	絵文字・機種依存文字が含まれていないか
export function validateEstateStatusHasEmoji(condition) {
  return hasEmoji(condition);
}

// その他事項	文字数が200文字以内であるか
export function validateOtherLength(etc) {
  return validateRangeLength(etc, 0, 200);
}

// その他事項	絵文字・機種依存文字が含まれていないか
export function validateOtherHasEmoji(etc) {
  return hasEmoji(etc);
}

// 説明	文字数が1000文字以内であるか
export function validateDescriptionLength5(description) {
  return validateRangeLength(description, 0, 1000);
}

// 説明	文字数が15000文字以内であるか
export function validateDescriptionLength4(description) {
  return validateRangeLength(description, 0, 15000);
}

// 土地面積	半角数字以外が含まれていないか
export function validateLandSpaceNotInclude(land_area) {
  return validateNumber(land_area);
}

// 土地面積	数字が10桁以内であるか
export function validateLandSpaceNumberLength(land_area) {
  return validateRangeLength(land_area, 0, 10);
}

// 土地面積	小数点第二位以下が入力されていないか
export function validateLandSpaceDecimalLength(land_area) {
  return validateDecimal(land_area, 2);
}

// 土地権利	文字数が30文字以内であるか
export function validateLandRightsLength(land_right) {
  return validateRangeLength(land_right, 0, 30);
}

// 土地権利	絵文字・機種依存文字が含まれていないか
export function validateLandRightsHasEmoji(land_right) {
  return hasEmoji(land_right);
}

// 土地持分(2分割 前半)	半角数字以外が含まれていないか
export function validateLandShareFirstNotInclude(land_equity_first) {
  return validateNumber(land_equity_first);
}

// 土地持分(2分割 前半)	数字が10桁以内であるか
export function validateLandShareFirstNumberLength(land_equity_first) {
  return validateRangeLength(land_equity_first, 0, 10);
}

// 土地持分(2分割 前半)	小数点以下が入力されていないか
export function validateLandShareFirstDecimal(land_equity_first) {
  return validateDecimal(land_equity_first, 0);
}

// 土地持分(2分割 後半)	半角数字以外が含まれていないか
export function validateLandShareLastNotInclude(land_equity_last) {
  return validateNumber(land_equity_last);
}

// 土地持分(2分割 後半)	数字が10桁以内であるか
export function validateLandShareLastNumberLength(land_equity_last) {
  return validateRangeLength(land_equity_last, 0, 10);
}

// 土地持分(2分割 後半)	小数点以下が入力されていないか
export function validateLandShareLastDecimal(land_equity_last) {
  return validateDecimal(land_equity_last, 0);
}

// 地目	文字数が30文字以内であるか
export function validateLandCategoryLength(area_name) {
  return validateRangeLength(area_name, 0, 30);
}

// 地目	絵文字・機種依存文字が含まれていないか
export function validateLandCategoryHasEmoji(area_name) {
  return hasEmoji(area_name);
}

// 都市計画地域	文字数が30文字以内であるか
export function validateCityPlanningAreaLength(urban_area) {
  return validateRangeLength(urban_area, 0, 30);
}

// 都市計画地域	絵文字・機種依存文字が含まれていないか
export function validateCityPlanningAreaHasEmoji(urban_area) {
  return hasEmoji(urban_area);
}

// 用途地域	文字数が100文字以内であるか
export function validateRestrictedAreaLength(zoning_area) {
  return validateRangeLength(zoning_area, 0, 100);
}

// 用途地域	絵文字・機種依存文字が含まれていないか
export function validateRestrictedAreaHasEmoji(zoning_area) {
  return hasEmoji(zoning_area);
}

// 建ぺい率	半角数字以外が含まれていないか
export function validateBuildRatioNotInclude(building_coverage_ratio) {
  return validateNumber(building_coverage_ratio);
}

// 建ぺい率	数字が4桁以内であるか
export function validateBuildRatioNumberLength(building_coverage_ratio) {
  return validateRangeLength(building_coverage_ratio, 0, 4);
}

// 建ぺい率	小数点以下が入力されていないか
export function validateBuildRatioDecimal(building_coverage_ratio) {
  return validateDecimal(building_coverage_ratio, 1);
}

// 容積率	半角数字以外が含まれていないか
export function validateFloorAreaRatioNotInclude(floor_area_ratio) {
  return validateNumber(floor_area_ratio);
}

// 容積率	数字が4桁以内であるか
export function validateFloorAreaRatioNumberLength(floor_area_ratio) {
  return validateRangeLength(floor_area_ratio, 0, 4);
}

// 容積率	小数点以下が入力されていないか
export function validateFloorAreaRatioDecimal(floor_area_ratio) {
  return validateDecimal(floor_area_ratio, 1);
}

// 高度地区	半角数字以外が含まれていないか
export function validateHighDistrictNotInclude(altitude_zone) {
  return validateNumber(altitude_zone);
}

// 高度地区	数字が1桁であるか
export function validateHighDistrictOnlyNumberLength(altitude_zone) {
  return validateRangeLength(altitude_zone, 0, 1);
}

// 高度地区	小数点以下が入力されていないか
export function validateHighDistrictDecimal(altitude_zone) {
  return validateDecimal(altitude_zone, 1);
}

// 防火地域	防火地域が選択されている
export function validateFirebreakAreaRequired(fire_protection_area) {
  return validateSelected(fire_protection_area);
}

// 日影規制	文字数が250文字以内であるか
export function validateShadeRegulationLength(shading_regulations) {
  return validateRangeLength(shading_regulations, 0, 250);
}

// 日影規制	絵文字・機種依存文字が含まれていないか
export function validateShadeRegulationHasEmoji(shading_regulations) {
  return hasEmoji(shading_regulations);
}

// その他法令などの規制	文字数が1000文字以内であるか
export function validateLawRegulationLength(laws_regulations) {
  return validateRangeLength(laws_regulations, 0, 1000);
}

// その他法令などの規制	絵文字・機種依存文字が含まれていないか
export function validateLawRegulationHasEmoji(laws_regulations) {
  return hasEmoji(laws_regulations);
}

// 地勢など	文字数が200文字以内であるか
export function validateGeographicalLength(topography) {
  return validateRangeLength(topography, 0, 200);
}

// 地勢など	絵文字・機種依存文字が含まれていないか
export function validateGeographicalHasEmoji(topography) {
  return hasEmoji(topography);
}

// 幅員、接道状況	文字数が400文字以内であるか
export function validateNeighboringRoadLength(width) {
  return validateRangeLength(width, 0, 400);
}

// 幅員、接道状況	絵文字・機種依存文字が含まれていないか
export function validateNeighboringRoadHasEmoji(width) {
  return hasEmoji(width);
}

// 私道の負担など	文字数が400文字以内であるか
export function validatePrivateRoadLength(burden) {
  return validateRangeLength(burden, 0, 400);
}

// 私道の負担など	絵文字・機種依存文字が含まれていないか
export function validatePrivateRoadHasEmoji(burden) {
  return hasEmoji(burden);
}

// 供給処理施設	文字数が1000文字以内であるか
export function validateProvideEquipmentLength(facility) {
  return validateRangeLength(facility, 0, 1000);
}

// 供給処理施設	絵文字・機種依存文字が含まれていないか
export function validateProvideEquipmentHasEmoji(facility) {
  return hasEmoji(facility);
}

// その他土地に関する物件情報	文字数が2000文字以内であるか
export function validateLandOtherLength(land_info) {
  return validateRangeLength(land_info, 0, 2000);
}

// その他土地に関する物件情報	絵文字・機種依存文字が含まれていないか
export function validateLandOtherHasEmoji(land_info) {
  return hasEmoji(land_info);
}

// 建物の名称	文字数が100文字以内であるか
export function validateBuildNameLength(name) {
  return validateRangeLength(name, 0, 100);
}

// 建物の名称	絵文字・機種依存文字が含まれていないか
export function validateBuildNameHasEmoji(name) {
  return hasEmoji(name);
}

// 建物面積	半角数字以外が含まれていないか
export function validateBuildSpaceNotInclude(building_area) {
  return validateNumber(building_area);
}

// 建物面積	数字が10桁以内であるか
export function validateBuildSpaceNumberLength(building_area) {
  return validateRangeLength(building_area, 0, 10);
}

// 建物面積	小数点第二位以下が入力されていないか
export function validateBuildSpaceDecimalLength(building_area) {
  return validateDecimal(building_area, 2);
}

// 専有面積	半角数字以外が含まれていないか
export function validateExclusiveSpaceNotInclude(exclusive_area) {
  return validateNumber(exclusive_area);
}

// 専有面積	数字が10桁以内であるか
export function validateExclusiveSpaceNumberLength(exclusive_area) {
  return validateRangeLength(exclusive_area, 0, 10);
}

// 専有面積	小数点第二位以下が入力されていないか
export function validateExclusiveSpaceDecimalLength(exclusive_area) {
  return validateDecimal(exclusive_area, 2);
}

// 延床面積	半角数字以外が含まれていないか
export function validateFloorSpaceNotInclude(extended_area) {
  return validateNumber(extended_area);
}

// 延床面積	数字が10桁以内であるか
export function validateFloorSpaceNumberLength(extended_area) {
  return validateRangeLength(extended_area, 0, 10);
}

// 延床面積	小数点第二位以下が入力されていないか
export function validateFloorSpaceDecimalLength(extended_area) {
  return validateDecimal(extended_area, 2);
}

// 構造	構造が選択されている
export function validateStructureTypeRequired(structure) {
  return validateSelected(structure);
}

// 建築年月	年月が選択されている
export function validateBuildDateRequired(construction_year) {
  return validateSelected(construction_year);
}

// 階建/階	文字数が30文字以内であるか
export function validateFloorLength(floors) {
  return validateRangeLength(floors, 0, 30);
}

// 階建/階	絵文字・機種依存文字が含まれていないか
export function validateFloorHasEmoji(floors) {
  return hasEmoji(floors);
}

// 総戸数	半角数字以外が含まれていないか
export function validateTotalHousesNotInclude(total_number_houses) {
  return validateNumber(total_number_houses);
}

// 総戸数	数字が4桁以内であるか
export function validateTotalHousesNumberLength(total_number_houses) {
  return validateRangeLength(total_number_houses, 0, 4);
}

// 総戸数	小数点以下が入力されていないか
export function validateTotalHousesDecimal(total_number_houses) {
  return validateDecimal(total_number_houses, 2);
}

// 間取り	文字数が30文字以内であるか
export function validateLayoutLength(plan_house) {
  return validateRangeLength(plan_house, 0, 30);
}

// 間取り	絵文字・機種依存文字が含まれていないか
export function validateLayoutHasEmoji(plan_house) {
  return hasEmoji(plan_house);
}

// その他建物に関する物件情報	文字数が20000字以内であるか
export function validateBuildOtherLength(property_info) {
  return validateRangeLength(property_info, 0, 20000);
}

// その他建物に関する物件情報	絵文字・機種依存文字が含まれていないか
export function validateBuildOtherHasEmoji(property_info) {
  return hasEmoji(property_info);
}

// (公有：入札保証金) 公売保証金*「クレジットカード、銀行振込など両方」 または「クレジットカード」を選択   半角数字以外が含まれていないか
export function validateGuaranteeAmountBothRequiredNotInclude(guarantee) {
  return validateNumber(guarantee);
}

// &&&&
// (公有：入札保証金) 公売保証金*「クレジットカード、銀行振込など両方」または「クレジットカード」を選択	2円以上、1,000,000円以内であるか
export function validateGuaranteeAmountBothRequiredCreditDeposit(guarantee) {
  return isValidAmount(guarantee, 2, 1000000);
}

// 公売保証金*「銀行振込など」を選択	半角数字以外が含まれていないか
export function validateBankTransferRequiredNotInclude(guarantee) {
  return validateNumber(guarantee);
}

// 公売保証金*「銀行振込など」を選択	1円以上、10,000,000,000円以内であるか
export function validateBankTransferRequiredRangeLength(guarantee) {
  return isValidAmount(guarantee, 1, 10000000000);
}

// 公売保証金*「公売保証金なし」を選択"	入力不可 (0円表示)
export function validatedepositOperationsNothingRequiredIsInput(guarantee) {
  return isInvalidInput(guarantee);
}

// ブロックリスト登録メモ	文字数が50文字以内であるか
export function validateBlockListMemoLength(list) {
  return validateRangeLength(list, 0, 50);
}

// ブロックリスト登録メモ	絵文字・機種依存文字が含まれていないか
export function validateBlockListMemoHasEmoji(list) {
  return hasEmoji(list);
}

// 共通説明文	文字数が5000文字以内であるか
export function validateCommonDescriptionLength(discription) {
  return validateRangeLength(discription, 0, 5000);
}

// 共通説明文	絵文字・機種依存文字が含まれていないか
export function validateCommonDescriptionHasEmoji(discription) {
  return hasEmoji(discription);
}

// 登録先*	登録先が選択されている
export function validateRegistDestinationRequiredRequired(registered_at) {
  return validateSelected(registered_at);
}

// 買受代金納付期限*	買受代金納付期限が選択されている
export function validatePaymentDeadlineRequiredRequired2(
  purchase_price_payment_due,
) {
  return validateSelected(purchase_price_payment_due);
}
