import AuctionEntry from "../../../components/Auction/kmanager/AuctionEntry/AuctionEntry";

const KmanagerAuctionEntry = () => {
    return (
        <div data-testid="kmanager-auction-government">
            <AuctionEntry/>
        </div>
    );
}

export default KmanagerAuctionEntry;