import { ReactComponent as PencilIcon } from "interfaces/assets/svg/pencil.svg";
import useModalControl from "shared/hooks/useModalControl";

import MemoModal from "./MemoModal";
import FlatButton from "../common/Button/FlatButton";
import SmallLightButton from "../common/Button/SmallLightButton";

const Memo = ({ text, isSmallLightButton = false, isFlatButton = false }) => {
  const { isOpen, handleOpen, handleClose } = useModalControl();

  return (
    <div data-testid="memo" className="d-inline-block navi_memo">
      { isSmallLightButton && <SmallLightButton text={text} onClick={handleOpen}/> }
      { isFlatButton && <FlatButton text={text} icon={<PencilIcon/>} onClick={handleOpen}/> }
      <MemoModal
        isOpen={isOpen}
        label="メモ登録"
        maxTextLimit={50}
        closeModal={handleClose}
      />
    </div>
  )
}
export default Memo;