const PropertyInfoFormCautionDescription = ({data}) => {
    return (
        data?.map((data) => {
            return (
                <div className="pt-2" key={data.id}>
          <span
              className="property-info-form-caution_description flex"
              data-testid="property-info-form-caution_description"
          >
            { data.marked === false ? '' : <span>※</span> }
              <div>{data.text}</div>
          </span>
                </div>
            );
        }))
}
export default PropertyInfoFormCautionDescription
