// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.organization {
  padding-bottom: 28px;
}

.organization-description {
  margin-top: 30px;
}

.organization .new-registration-wrap {
  margin-top: 30px;
  text-align: right;
}
.organization .organization-table-wrap {
  margin-top: 40px;
}

.organization .complete-message {
  margin: 30px 0;
}

.organization .modal-title {
  background-color: #e2e1da;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 16px 24px 10px;
  width: 100%;
}

.organization .modal-description-area {
  padding: 24px 20px;
  width: 100%;
}
.organization .modal-description-area p {
  margin: 0;
}

.organization .modal-border {
  background-color: #cccccc;
  border: none;
  height: 1px;
  margin: 0;
  width: 100%;
}

.organization .cancel-button {
  border: 1px solid #ccc;
}

.organization .delete-button {
  color: white;
  background-color: red;
  border: 1px solid #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Organization/organization.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,SAAS;EACT,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,sBAAsB;AACxB","sourcesContent":[".organization {\n  padding-bottom: 28px;\n}\n\n.organization-description {\n  margin-top: 30px;\n}\n\n.organization .new-registration-wrap {\n  margin-top: 30px;\n  text-align: right;\n}\n.organization .organization-table-wrap {\n  margin-top: 40px;\n}\n\n.organization .complete-message {\n  margin: 30px 0;\n}\n\n.organization .modal-title {\n  background-color: #e2e1da;\n  font-size: 1.25rem;\n  font-weight: 700;\n  padding: 16px 24px 10px;\n  width: 100%;\n}\n\n.organization .modal-description-area {\n  padding: 24px 20px;\n  width: 100%;\n}\n.organization .modal-description-area p {\n  margin: 0;\n}\n\n.organization .modal-border {\n  background-color: #cccccc;\n  border: none;\n  height: 1px;\n  margin: 0;\n  width: 100%;\n}\n\n.organization .cancel-button {\n  border: 1px solid #ccc;\n}\n\n.organization .delete-button {\n  color: white;\n  background-color: red;\n  border: 1px solid #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
