// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-icon,
.tiles-icon {
  display: inline-block;
  height: 1em;
  font-size: 1.33333em;
  line-height: .75em;
  text-align: center;
  margin-right: 4px;
  vertical-align: -0.225em;
  width: 1.25em;
  overflow: visible;
}

.facebook--text {
  color: #1877f2;
  caret-color: #1877f2;
}

.twitter--text {
  color: #1da1f2;
  caret-color: #1da1f2;
}

.add-mylist-button {
  height: 29px;
  fill: var(--primary-base);
  margin: 0 4px;
}

.primary--text {
  color: var(--primary-base2);
  caret-color: var(--primary-base2);
}

.svg-inline--fa, svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa.fa-fw {
  width: 1.25em;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  vertical-align: -0.125em;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/svg.css"],"names":[],"mappings":"AAAA;;EAEE,qBAAqB;EACrB,WAAW;EACX,oBAAoB;EACpB,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,wBAAwB;EACxB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,2BAA2B;EAC3B,iCAAiC;AACnC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,WAAW;EACX,wBAAwB;AAC1B","sourcesContent":[".detail-icon,\n.tiles-icon {\n  display: inline-block;\n  height: 1em;\n  font-size: 1.33333em;\n  line-height: .75em;\n  text-align: center;\n  margin-right: 4px;\n  vertical-align: -0.225em;\n  width: 1.25em;\n  overflow: visible;\n}\n\n.facebook--text {\n  color: #1877f2;\n  caret-color: #1877f2;\n}\n\n.twitter--text {\n  color: #1da1f2;\n  caret-color: #1da1f2;\n}\n\n.add-mylist-button {\n  height: 29px;\n  fill: var(--primary-base);\n  margin: 0 4px;\n}\n\n.primary--text {\n  color: var(--primary-base2);\n  caret-color: var(--primary-base2);\n}\n\n.svg-inline--fa, svg:not(:root).svg-inline--fa {\n  overflow: visible;\n}\n\n.svg-inline--fa.fa-fw {\n  width: 1.25em;\n}\n\n.svg-inline--fa {\n  display: inline-block;\n  font-size: inherit;\n  height: 1em;\n  vertical-align: -0.125em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
