import { validateSelected } from "../../../../application/validations.js";

export class DivisionId {
  #divisionId

  constructor(divisionId) {
    this.#divisionId = divisionId;
  }

  get() {
    return this.#divisionId
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#divisionId)
  }
}
