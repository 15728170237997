import PropertyInfoFormCautionDescription from "./PropertyInfoFormCautionDescription.jsx";
import PropertyInfoFormLabel from "./PropertyInfoFormLabel.jsx";
import ManagedCheckBox from "../../components/common/ManagedCheckBox";
import "../../css/common/property-info-general-form-input-check.css"

const GeneralStateInputCheck = ({
                                    checkbox_label,
                                    caution_description,
                                    label,
                                    class_name,
                                    required,
                                    stateHandler,
                                    data
                                }) => {
    const handleCheckboxChange = (event) => {
        const name = event.target.name
        const checked = event.target.checked

        if (stateHandler) {
            stateHandler({ [name]: checked })
        }

    };
    return (
        <div data-testid="property-info-general-form-input-check" className="property-info-general-form-table-row">
            <PropertyInfoFormLabel label={label} required={required}/>
            <div className="property-info-general-form-table-cell">
                <div
                    className={class_name ? "property-info-general-form-input-check-" + class_name : "property-info-general-form-input-check"}>
                    {
                        checkbox_label?.map((label) => {
                            return (
                                <div className={"flex"} key={label.id}>
                                    <ManagedCheckBox
                                        id={label.name}
                                        name={label.name ?? ''}
                                        handleCheckboxChange={handleCheckboxChange}
                                        data={data[label.name] ?? false}
                                    />
                                    <label htmlFor={label.name}
                                           data-testid="property-info-general-form-input-check-label">{label.text}</label>
                                </div>
                            )
                        })
                    }
                </div>
                <PropertyInfoFormCautionDescription data={caution_description}/>
            </div>
        </div>
    );
}
export default GeneralStateInputCheck
