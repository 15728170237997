// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import {CharacterPathName} from "./Files/characterPathName";
import {EmblemPathName} from "./Files/emblemPathName";
import {Id} from "./Files/id";
import {PropertyImagePath} from "./Files/propertyImagePath";
import { NotUndefined } from "../../../shared/utils/helper/NotUndefined.js";

export class GovernmentFilesGet {
  // 必要なデータ

  constructor(data) {
    this.id = data.id
    this.emblemPathName = data.emblemPathName
    this.characterPathName = data.characterPathName
  }

  get isValid() {
    return NotUndefined(this)
  }
}


export class PropertyFilesGet {
  // 必要なデータ

  constructor(data) {
    this.id = data.id
    this.propertyImagePath = data.propertyImagePath
    this.propertyImageThumbnailPath = data.propertyImageThumbnailPath
  }

  get isValid() {
    return NotUndefined(this)
  }
}

export class GovernmentFilesCreate {
  // 必要なデータ

  constructor(data) {
    this.id = new Id(data.id)
    this.emblemPathName = new EmblemPathName(data.emblemPathName)
    this.characterPathName = new CharacterPathName(data.characterPathName)
  }

  get isValid() {
    return this.id.isValid &&
      this.emblemPathName.isValid &&
      this.characterPathName.isValid
  }
}

export class PropertyFilesCreate {
  // 必要なデータ

  constructor(data) {
    this.id = new Id(data.id)
    this.propertyImagePath = new PropertyImagePath(data.propertyImagePath)
  }

  get isValid() {
    return this.id.isValid &&
      this.propertyImagePath.isValid
  }
}
