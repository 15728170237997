import { DATA_EMPTY } from 'shared/utils/helper/constants';

import ValidationMessage from './ValidationMessage';

import 'interfaces/css/common/property-form-input-text.css';
import styled from 'styled-components';

const TextInput = styled.input`
  background-color: rgb(255, 255, 255);
  border: ${(props) =>
    props.$validation
      ? '2px solid #ff5252!important'
      : '1px solid rgba(0, 0, 0, .42)'};
  border-radius: 4px;
  caret-color: ${(props) => (props.$validation ? '#ff5252' : '#ff7f00')};
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 16px;
  line-height: 20px;
  max-width: 100%;
  min-width: 0;
  outline: none;
  #padding: 8px 0;
  padding: 8px 12px;
  width: 100%;
`;

export const convertEmptyCharacters = (value) => {
  const emptyCondition = [
    DATA_EMPTY,
    '-9999',
    '1000-01-01 00:00:00',
    '1000-01-01',
    '00:00:00',
    null,
    undefined,
  ];
  if (emptyCondition.includes(value)) {
    return '';
  }
  return value;
};

const FormText = ({
  invalidMessage,
  number_remaining_characters,
  className,
  data = '',
  handler1 = () => {},
  handler2 = () => {},
  maxCharacters = undefined,
  isDisable = false,
}) => {
  function onInputChange(event) {
    handler1(event);
    handler2(event);
  }

  const calcRemainCharacters = () => {
    if (maxCharacters === undefined) {
      return '';
    }
    if (data === undefined) {
      return maxCharacters;
    } else {
      return maxCharacters - data.length;
    }
  };

  return (
    <div className={className}>
      <TextInput
        onChange={onInputChange}
        type="text"
        value={convertEmptyCharacters(data)}
        disabled={isDisable}
        $validation={invalidMessage !== 'TRUE' && invalidMessage !== null}
      />
      <ValidationMessage invalidMessage={invalidMessage} />
      {number_remaining_characters ? (
        <div className="property-form-input-text-absolute-area">
          <p
            className="number-remaining-characters"
            style={{ color: number_remaining_characters < 0 ? '#ff5252' : '' }}>
            {'残り' + number_remaining_characters + '文字'}
          </p>
        </div>
      ) : (
        ''
      )}
      {maxCharacters ? (
        <div className="property-form-input-text-absolute-area">
          <p
            className="number-remaining-characters"
            style={{ color: calcRemainCharacters() < 0 ? '#ff5252' : '' }}>
            {'残り' + calcRemainCharacters() + '文字'}
          </p>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default FormText;
