// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topic-card {
  background-color: var(--white);
  border: 1px solid var(--secondary-base);
  border-radius: 5px;
  margin-bottom: 15px;
}

.topic-card-title {
  padding: 0 12px;
  font-size: .875rem;
  font-weight: 700;
  line-height: 2.25rem;
  letter-spacing: .09em;
  font-family: "Roboto",sans-serif;
  text-transform: uppercase;
  background-color: var(--secondary-base);
}

.topic-card-body {
  padding: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/topic-card.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,uCAAuC;EACvC,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;EACpB,qBAAqB;EACrB,gCAAgC;EAChC,yBAAyB;EACzB,uCAAuC;AACzC;;AAEA;EACE,YAAY;AACd","sourcesContent":[".topic-card {\n  background-color: var(--white);\n  border: 1px solid var(--secondary-base);\n  border-radius: 5px;\n  margin-bottom: 15px;\n}\n\n.topic-card-title {\n  padding: 0 12px;\n  font-size: .875rem;\n  font-weight: 700;\n  line-height: 2.25rem;\n  letter-spacing: .09em;\n  font-family: \"Roboto\",sans-serif;\n  text-transform: uppercase;\n  background-color: var(--secondary-base);\n}\n\n.topic-card-body {\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
