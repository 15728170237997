import MyPageProvider from './MyPageProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { myPage } from "../entities/componentMapping.js";

const MyPage = ({ type }) => {
  return (
    <MyPageProvider type={type}>
      {getComponent(myPage, type)}
    </MyPageProvider>
  );
};

export default MyPage;
