import NotificationList from './NotificationList.jsx';
import TopicCard from '../../../../../common/TopicCard.jsx';

const NotificationTopic = () => {
  return (
    <div data-testid="notification-topic">
      <TopicCard title="お知らせ" body={<NotificationList />} />
    </div>
  );
};

export default NotificationTopic;
