import Heading3 from 'interfaces/components/common/Heading/Heading3';
import PropertyInfoGeneralFormInputMarkDown from 'interfaces/components/common/PropertyInfoForm/PropertyInfoGeneralFormInputMarkDown';
import { local_government_management_radio1 } from 'interfaces/components/common/RadioLabel';
import Form from 'interfaces/components/LocalGovernmentManagement/guideline/Form';
import { guideline_pledge_body } from 'shared/utils/helper/DummyEditFormData';

import FormRadioWithCaution from '../../Form/navi/FormRadioWithCaution';

const Both = ({ data, procedureType }) => {
  return (
    <form data-testid="both-form">
      <div className="property-info-general-form-table">
        {procedureType === 'ASSET_SALE' && (
          <div className="property-info-general-form-table">
            <Heading3 text={'誓約書'} />
            <FormRadioWithCaution
              label={'誓約書の表示'}
              radio_label={local_government_management_radio1}
              data={data.data.isDisplayPledge}
              handler1={(e) => {
                data.setters.handleChangeIsDisplayPledge(e);
                data.setters.handleChangePledgeBody(
                  { text: data.data.pledgeBody },
                  e.target.value == 'DISPLAY',
                );
              }}
            />
            <PropertyInfoGeneralFormInputMarkDown
              label={'誓約書本文'}
              required={data.data.isDisplayPledge == 'DISPLAY'}
              caution_description={guideline_pledge_body}
              data={data.data.pledgeBody}
              maxCharacters={2000}
              handler1={(e) =>
                data.setters.handleChangePledgeBody(
                  e,
                  data.data.isDisplayPledge == 'DISPLAY',
                )
              }
              invalidMessage={data.validationMessage.guidelinePledgeBodyValid}
            />
          </div>
        )}
        <Form
          data={data.data}
          handler1={data.setters}
          invalidMessage={data.validationMessage}
          itemNo={1}
        />
        <Form
          data={data.data}
          handler1={data.setters}
          invalidMessage={data.validationMessage}
          itemNo={2}
        />
        <Form
          data={data.data}
          handler1={data.setters}
          invalidMessage={data.validationMessage}
          itemNo={3}
        />
        <Form
          data={data.data}
          handler1={data.setters}
          invalidMessage={data.validationMessage}
          itemNo={4}
        />
        <Form
          data={data.data}
          handler1={data.setters}
          invalidMessage={data.validationMessage}
          itemNo={5}
        />
        <Form
          data={data.data}
          handler1={data.setters}
          invalidMessage={data.validationMessage}
          itemNo={6}
        />
        <Form
          data={data.data}
          handler1={data.setters}
          invalidMessage={data.validationMessage}
          itemNo={7}
        />
        <Form
          data={data.data}
          handler1={data.setters}
          invalidMessage={data.validationMessage}
          itemNo={8}
        />
      </div>
    </form>
  );
};

export default Both;
