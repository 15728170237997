import PropertyFormInputFile from "interfaces/components/common/PropertyInfoForm/PropertyFormInputFile";
import PropertyInfoFormCautionDescription from "interfaces/components/common/PropertyInfoForm/PropertyInfoFormCautionDescription";
import PropertyInfoFormLabel from "interfaces/components/common/PropertyInfoForm/PropertyInfoFormLabel";

const PropertyInfoGeneralFormInputFile = ({label,caution_description}) => {
  return (
    <div data-testid="property-info-general-form-input-file" className="property-info-general-form-table-row">
      <PropertyInfoFormLabel label={label}/>
      <div className="property-info-general-form-table-cell">
        <PropertyFormInputFile/>
        <PropertyInfoFormCautionDescription data={caution_description}/>
      </div>
    </div>
  );
}
export default PropertyInfoGeneralFormInputFile
// import ImageUploader from '/components/Image/ImageUploader.jsx';
// <ImageUploader setState={setState}/>
