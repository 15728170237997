import { notInclude, validateNumber, validateRangeLength } from "../../../../application/validations.js";

export class InvoiceTel {
  #invoiceTel

  constructor(invoiceTel) {
    this.#invoiceTel = invoiceTel;
  }

  get() {
    return this.#invoiceTel
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.notIncludeSpace,
      this.validateNumber,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get notIncludeSpace() {
    return notInclude(this.#invoiceTel, [' '])
  }

  // バリデーションルールの適用
  get validateNumber() {
    return validateNumber(this.#invoiceTel)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#invoiceTel, 10, 11)
  }
}
