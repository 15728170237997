import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import BasicInfoTable from 'interfaces/components/Property/service/PropertyDetail/BasicInfo/BasicInfoTable.jsx';

const ApplicationPropertyInformationTable = ({ data }) => {
  return (
    <div>
      <ArticleDefaultBody>
        <BasicInfoTable title="申し込み物件情報" data={data} />
      </ArticleDefaultBody>
    </div>
  );
};
export default ApplicationPropertyInformationTable;
