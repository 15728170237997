// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-topic-title-wrap {
  position: relative;
  padding-left: 15px;
}

.small-topic-title::before {
  content: "";
  position: absolute;
  left: 0;
  width: 10px;
  height: 10px;
  background: var(--primary-base);
  top: 7px;
}

.small-topic-caption {
  font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/small-topic-title.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,WAAW;EACX,YAAY;EACZ,+BAA+B;EAC/B,QAAQ;AACV;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".small-topic-title-wrap {\n  position: relative;\n  padding-left: 15px;\n}\n\n.small-topic-title::before {\n  content: \"\";\n  position: absolute;\n  left: 0;\n  width: 10px;\n  height: 10px;\n  background: var(--primary-base);\n  top: 7px;\n}\n\n.small-topic-caption {\n  font-size: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
