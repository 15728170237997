import SampleGuidelineCautionProvider from './SampleGuidelineCautionProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { sampleGuidelineCaution } from "../entities/componentMapping.js";

const SampleGuidelineCaution = ({ type }) => {
  return (
    <SampleGuidelineCautionProvider type={type}>
      {getComponent(sampleGuidelineCaution, type)}
    </SampleGuidelineCautionProvider>
  );
};

export default SampleGuidelineCaution;
