import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class TransRightAttention {
  #transRightAttention

  constructor(transRightAttention) {
    this.#transRightAttention = transRightAttention;
  }

  get() {
    return this.#transRightAttention
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#transRightAttention, 0, 500)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#transRightAttention)
  }
}
