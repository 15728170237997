// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.governments .government-list {
  position: relative;
  background: #fff;
  border: 1px solid var(--line-base);
}

.governments .in-progress-government-table {
  width: 100%;
}

.governments .in-progress-government-table-th {
  vertical-align: top;
  position: static;
  width: 64px;
}

.governments .government-group {
  text-align: left;
  border-bottom: 2px solid var(--line-base);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Governments/government-table.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,yCAAyC;AAC3C","sourcesContent":[".governments .government-list {\n  position: relative;\n  background: #fff;\n  border: 1px solid var(--line-base);\n}\n\n.governments .in-progress-government-table {\n  width: 100%;\n}\n\n.governments .in-progress-government-table-th {\n  vertical-align: top;\n  position: static;\n  width: 64px;\n}\n\n.governments .government-group {\n  text-align: left;\n  border-bottom: 2px solid var(--line-base);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
