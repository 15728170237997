import { useEffect, useState } from 'react';

//import { getAllowPropertyCategories } from '../../../../application/use_cases/getAllowPropertyCategories';
import {
  get as getAuctions,
  getAuctionProgressSchedule,
} from 'domain/Auction/services/Auction';
import { findByCategory as getCategories } from 'domain/Category/services/PropertyCategory';
import {
  findByAuctionIds as getServiceInvitations,
  findByGovernmentId as getInvitations,
} from 'domain/Auction/services/Invitation';
import { findById as getGovernment } from 'domain/Government/services/Government';
import { get as getMessages } from 'domain/News/services/Message';
import { get as getNotices } from 'domain/News/services/Notices';
import { get as getRemindMessage } from 'domain/News/services/RemindMessage';
import { findByIsActive as getActivePromotionFrames } from 'domain/Promotion/services/PromotionFrame';
import { findByFrameIds as getActivePromotions } from 'domain/Promotion/services/PromotionProperty';
import { findByAuctionIds as getProperties } from 'domain/Property/services/Property';
import { findByPropertyIds as getBidders } from 'domain/List/services/BidderList';
import { whereById as getMylists } from 'domain/Favorite/services/Favorite';
import { whereByAuctionIds as getRecommendProperties } from 'domain/Property/services/RecommendProperty';

import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { LOGIN, SIGN_UP, FORGET_PASSWORD } from 'shared/utils/helper/authState';
import { KMANAGER, NAVI, SERVICE } from 'shared/utils/helper/constants';
import { DashboardContext } from '../entities/DashboardContext';
import searchProperty from '../../../../shared/utils/helper/searchProperty';
import { createGeneralCategoryData } from '../../../../shared/utils/helper/createGeneralCategoryData';

const DashboardProvider = ({ type, children }) => {
  const { localStorageGovernmentId, localStorageUserId } = useLocalStorage();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [managementMessages, setManagementMessages] = useState([]);
  const [remindMessages, setRemindMessages] = useState([]);
  const [notices, setNotices] = useState([]);
  const [allowPropertyCategories, setAllowPropertyCategories] = useState([]);
  const [propertiesNum, setPropertiesNum] = useState({});
  const [auctions, setAuctions] = useState([]);
  const [progressSchedules, setProgressSchedules] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [government, setGovernment] = useState(undefined);
  const [error, setError] = useState({});
  const [properties, setProperties] = useState([]);
  const [promotionProperties, setPromotionProperties] = useState([]);

  if (type === SERVICE) {
    useEffect(() => {
      getNotices(type).then((result) => {
        result = result.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
        setNotices(result);
      });

      // 開催中のオークション情報を取得
      getAuctionProgressSchedule(type).then((auctions) => {
        setProgressSchedules(auctions);

        var auctionIds = auctions.map((auction) => {
          return auction.id;
        });

        getProperties(type, auctionIds).then((properties) => {
          getServiceInvitations(type, auctionIds).then((invitations) => {
            getBidders(
              type,
              properties.map((property) => property.id),
            ).then((bidders) => {
              properties.forEach((property) => {
                // 全ての情報を取得する必要はないかもしれない。不要なら削除
                property.auction = auctions.find(
                  (auction) => auction.id === property.auctionId,
                );

                let targetDeadline = '';
                if (property.status === 'APPLYING') {
                  targetDeadline = property.auction.aplEnd;
                }
                if (property.status === 'WAIT_START_BID') {
                  targetDeadline = property.auction.bidStart;
                }
                if (property.status === 'DURING_BIDDING') {
                  targetDeadline = property.auction.bidEnd;
                }
                if (property.status === 'ADDITIONAL_DURING_BIDDING') {
                  targetDeadline = property.auction.addBidDuration;
                }
                property.deadline = targetDeadline;

                property.bidCount = bidders.filter(
                  (bidder) => bidder.propertyId === property.id,
                ).length;

                let tags = [];
                tags.push(
                  property.auction.procedureType === 'ASSET_TYPE'
                    ? '公有財産売却'
                    : 'インターネット公売',
                );
                tags.push(
                  property.publicSaleType === 'AUCTION'
                    ? 'せり売形式'
                    : '入札形式',
                );

                let invitation = invitations.find(
                  (invitation) =>
                    invitation.auctionId === property.auction.id &&
                    invitation.governmentId === property.governmentId,
                );
                if (invitation) {
                  let tag = '';
                  if (
                    invitation.paymentCreditCard === 'TRUE' ||
                    invitation.paymentBankTransfer === 'TRUE'
                  ) {
                    tag = '保証金あり';
                    if (invitation.paymentCreditCard === 'TRUE') {
                      tag += '(クレカ納付OK)';
                    }
                  } else {
                    tag = '保証金なし';
                  }
                  tags.push(tag);
                }

                property.tags = tags;
              });

              setProperties(properties);
            });
          });

          // 物件数の取得
          let estateCount = properties.filter(
            (property) => property.propertyType === 'ESTATE',
          ).length;
          let carCount = properties.filter(
            (property) => property.propertyType === 'CAR',
          ).length;

          let counts = {
            propertiesNum: properties.length,
            estatePropertiesNum: estateCount,
            carPropertiesNum: carCount,
          };
          setPropertiesNum(counts);

          // プロモーション用の物件を取得
          getActivePromotionFrames(type).then((frames) => {
            getActivePromotions(
              type,
              frames.map((frame) => frame.id),
            ).then((promotions) => {
              // 行政機関イチ推し
              getRecommendProperties(type, auctionIds).then(
                (recommendProperties) => {
                  // マイリスト
                  getMylists(type, localStorageUserId).then((mylists) => {
                    let promotionProperties = [];

                    if (mylists.length > 0) {
                      promotionProperties.push({
                        promotion: {
                          theme: 'マイリスト',
                          propertyIdList: mylists.map(
                            (mylist) => mylist.propertyId,
                          ),
                        },
                      });
                    }

                    // プロモーション用の物件
                    frames.forEach((frame) => {
                      var tmpPromotionProperty = promotions.find(
                        (promotion) => promotion.promotionFrameId === frame.id,
                      );
                      var queryString = tmpPromotionProperty.url.substr(
                        tmpPromotionProperty.url.indexOf('?'),
                      );

                      const query = new URLSearchParams(queryString);

                      let tmp = searchProperty(query, properties);

                      let promotionProperty = { promotion: {} };
                      promotionProperty.promotion.theme = frame.theme;
                      promotionProperty.promotion.propertyIdList = tmp.map(
                        (property) => property.id,
                      );
                      promotionProperties.push(promotionProperty);
                    });

                    promotionProperties.push({
                      promotion: {
                        theme: '行政機関イチ推し',
                        propertyIdList: recommendProperties.map(
                          (recommendProperty) => recommendProperty.propertyId,
                        ),
                      },
                    });

                    setPromotionProperties(promotionProperties);
                  });
                },
              );
            });
          });

          getCategories(type, 'GENERAL').then((categories) => {
            let result = createGeneralCategoryData(categories, properties);
            setAllowPropertyCategories(result);
          });
        });
      });
    }, [localStorageUserId]);

    //useEffect(() => {
    //  getAllowPropertyCategories(localStorageUserId).then((result) => {
    //    console.log(result);
    //    setAllowPropertyCategories(result);
    //  });
    //}, [localStorageUserId]);
  }

  if (type === NAVI) {
    useEffect(() => {
      getNotices(type).then((result) => {
        if (result.length > 10) {
          result = result.slice(0, 10);
        }
        setNotices(result);
      });
      getMessages(type).then((result) => {
        setManagementMessages(result);
      });
    }, []);
    useEffect(() => {
      let auctions = [];
      let invitations = [];
      getInvitations(type, localStorageGovernmentId).then(
        (resultInvitations) => {
          invitations = resultInvitations;
          getAuctions(type).then((resultAuctions) => {
            auctions = resultAuctions;
            setInvitations(invitations);
            setAuctions(auctions);
          });
        },
      );
    }, [localStorageGovernmentId]);
    useEffect(() => {
      getGovernment(type, localStorageGovernmentId).then((government) => {
        setGovernment(government);
      });
    }, [localStorageGovernmentId]);
    useEffect(() => {
      getRemindMessage(type, localStorageGovernmentId).then((result) => {
        setRemindMessages(result);
      });
    }, [localStorageGovernmentId]);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getAuctions(type)
        .then((auctions) => {
          setAuctions(auctions);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
        });
    }, []);
  }

  return (
    <DashboardContext.Provider
      value={{
        //allowProperties,
        allowPropertyCategories,
        auctions,
        authState,
        error,
        government,
        invitations,
        managementMessages,
        reminderMessages: remindMessages,
        notices,
        properties,
        propertiesNum,
        progressSchedules,
        promotionProperties,
      }}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
