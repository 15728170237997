import { findById as getAuction } from 'domain/Auction/services/Auction';
import { findById as getInvitation } from 'domain/Auction/services/Invitation';
import { get as getDivisions } from 'domain/Division/services/Division';
import { get as getIndividualMailTemplate } from 'domain/Email/services/Email';
import { get as getCarProperties } from 'domain/Property/services/CarProperty';
import { get as getEstateProperties } from 'domain/Property/services/EstateProperty';
import { findByAuctionIdAndGovernmentId as getProperty } from 'domain/Property/services/Property.js';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { NAVI } from 'shared/utils/helper/constants.js';

import { PublishedObjectContext } from '../entities/PublishedObjectContext.js';

const PublishedObjectProvider = ({ type, children }) => {
  const [addDescriptions] = useState([]);
  const [auction, setAuction] = useState([]);
  const [carProperties, setCarProperties] = useState([]);
  const [estateProperties, setEstateProperties] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [governmentId, setGovernmentId] = useState('');
  const [governmentSubCategory] = useState([]);
  const [individualMailTemplate, setIndividualMailTemplate] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [procedureType, setProcedureType] = useState('');
  const [propertyCategories] = useState([]);
  const [publishObjects, setPublishObject] = useState([]);
  const [sortParam, setSortParam] = useState({ sortId: 1, order: 'asc' });

  const { auction_id } = useParams();
  const { localStorageGovernmentId, localStorageProcedureType } =
    useLocalStorage();

  const properties = [];

  // データの取得、加工等を行う
  if (type === NAVI) {
    useEffect(() => {
      getAuction(type, auction_id).then((result) => {
        setAuction(result);
      });
      getInvitation(type, auction_id, localStorageGovernmentId).then(
        (invitation) => {
          setInvitations(invitation);
          getProperty(type, auction_id, localStorageGovernmentId).then(
            (result) => {
              let sortedProperties = sortProperties(result);
              setPublishObject(sortedProperties);
            },
          );
        },
      );
      getCarProperties(type).then((result) => {
        setCarProperties(result);
      });
      getEstateProperties(type).then((result) => {
        setEstateProperties(result);
      });
      getDivisions(type).then((result) => {
        setDivisions(result);
      });
      getIndividualMailTemplate(type).then((result) => {
        setIndividualMailTemplate(result);
      });
      setGovernmentId(localStorageGovernmentId);
      setProcedureType(localStorageProcedureType);
    }, [localStorageGovernmentId, localStorageProcedureType, sortParam]);
  }

  const sortProperties = (properties) => {
    if (sortParam.sortId === 1) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          a.saleNumber > b.saleNumber ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          b.saleNumber > a.saleNumber ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 2) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) => (a.name > b.name ? 1 : -1));
      } else {
        return properties.sort((a, b) => (b.name > a.name ? 1 : -1));
      }
    }
    if (sortParam.sortId === 3) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          Number(a.estimatePrice) > Number(b.estimatePrice) ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          Number(b.estimatePrice) > Number(a.estimatePrice) ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 4) {
      if (sortParam.order === 'asc') {
        return properties.sort((a, b) =>
          a.bidEndPrice > b.bidEndPrice ? 1 : -1,
        );
      } else {
        return properties.sort((a, b) =>
          b.bidEndPrice > a.bidENdPrice ? 1 : -1,
        );
      }
    }
    return properties;
  };

  return (
    <PublishedObjectContext.Provider
      value={{
        governmentId,
        procedureType,
        auction,
        invitations,
        properties,
        divisions,
        individualMailTemplate,
        addDescriptions,
        governmentSubCategory,
        carProperties,
        estateProperties,
        propertyCategories,
        publishObjects,
        setSortParam,
      }}>
      {children}
    </PublishedObjectContext.Provider>
  );
};

export default PublishedObjectProvider;
