
export class ImportantObligationDelivery {
  #importantObligationDelivery

  constructor(importantObligationDelivery) {
    this.#importantObligationDelivery = importantObligationDelivery;
  }

  get() {
    return this.#importantObligationDelivery
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
