import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { datePickerSlotProps } from 'shared/utils/helper/muiSlotProps';

import PropertyInfoFormCautionDescription from './PropertyInfoFormCautionDescription';
import PropertyInfoFormLabel from './PropertyInfoFormLabel';
const PropertyInfoGeneralFormInputDate = ({
  label,
  caution_description,
  required,
  date,
  handleChange,
}) => {
  const changeHandler = (e) => {
    handleChange(e);
  };
  const [open, setOpen] = useState(false);
  return (
    <div className="property-info-general-form-table-row">
      <PropertyInfoFormLabel label={label} required={required} />
      <div className="property-info-general-form-table-cell">
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            value={DateTime.fromJSDate(new Date(date))}
            onChange={(selectDate) => changeHandler(selectDate.toJSDate())}
            format="yyyy-MM-dd"
            slotProps={
              date !== undefined ? datePickerSlotProps(date, setOpen) : {}
            }
            open={open}
            onClose={() => setOpen(false)}
          />
        </LocalizationProvider>
        <PropertyInfoFormCautionDescription data={caution_description} />
      </div>
    </div>
  );
};
export default PropertyInfoGeneralFormInputDate;
