
export class LocationMap {
    #locationMap

    constructor(locationMap) {
        this.#locationMap = locationMap;
    }

    get() {
        return this.#locationMap
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
