import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Button from 'interfaces/components/common/Button.jsx';
import ConfirmSection from 'interfaces/components/common/Form/ConfirmSection.jsx';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import { breadcrumbs } from 'interfaces/pages/PropertyInfoEstate/entities/breadcrumbs';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { bidded_contact_info } from './PropertyInfoEstateConfirmSet/bidded_contact_info.js';
import { contact_info } from './PropertyInfoEstateConfirmSet/contact_info.js';
import { estate_basic_info } from './PropertyInfoEstateConfirmSet/estate_basic_info.js';
import { estate_build_info } from './PropertyInfoEstateConfirmSet/estate_build_info.js';
import { estate_land_info } from './PropertyInfoEstateConfirmSet/estate_land_info.js';
import {
  property_detail_asset_sale,
  property_detail_public_sale,
} from './PropertyInfoEstateConfirmSet/property_detail.js';
import {
  property_info_asset_sale,
  property_info_public_sale,
} from './PropertyInfoEstateConfirmSet/property_info.js';
import { useLocalStorage } from '../../../../../shared/hooks/useLocalStorage';
import { PropertyInfoEstateContext } from '../../../../pages/PropertyInfoEstate/entities/PropertyInfoEstateContext';

// あとで変更したい
//import {NaviUserConfirmConst} from "interfaces/components/User/Create/NaviUserConfirmConst.js";

const PropertyInfoEstateConfirm = ({
  formData,
  handleComplete,
  handleBackForm,
  openWay,
}) => {
  const { auction_id, property_id } = useParams();
  const { invitation, property, divisions, selectedCategory } = useContext(
    PropertyInfoEstateContext,
  );
  const { localStorageProcedureType } = useLocalStorage();

  const publishSet = [
    {
      text: '公開設定',
      state: formData?.data.isDraft === 'TRUE' ? '下書き' : '公開',
    },
  ];
  const images = [
    {
      text: '画像データ',
      state:
        formData?.image === undefined ? '画像が登録されていません。' : '設定済',
    },
  ];

  let property_info = [];
  let property_detail = [];

  if (localStorageProcedureType === 'PUBLIC_SALE') {
    property_info = property_info_public_sale(
      formData.data,
      divisions.find(
        (data) =>
          data?.id.toString() === formData?.data?.divisionId?.toString(),
      ),
      selectedCategory,
    );
    property_detail = property_detail_public_sale(formData.data);
  } else {
    property_info = property_info_asset_sale(
      formData.data,
      divisions.find(
        (data) =>
          data?.id?.toString() === formData?.data?.divisionId?.toString(),
      ),
      selectedCategory,
    );
    property_detail = property_detail_asset_sale(formData.data);
  }
  //const BUTTONS_DATA = NaviUserConfirmConst();

  const getMethod = () => {
    if (property_id !== undefined) {
      return 'update';
    }
    return 'create';
  };

  const getTitle = () => {
    if (getMethod() === 'create') {
      return `登録内容確認：物件情報（不動産）登録：${invitation?.name}`;
    }
    return `編集内容確認：${invitation?.name}：${property?.name}`;
  };

  return (
    <div
      className="new-style_navi_object_confirm"
      data-testid="property-info-estate-confirm">
      <Breadcrumb
        breadcrumbs={breadcrumbs(
          `${getMethod()}`,
          'confirm',
          invitation?.name,
          auction_id,
          openWay,
          property?.name,
        )}
      />
      <Heading2 title={getTitle()} />
      {property_id === undefined ? (
        <div>
          登録する物件内容を確認してください。このまま登録する場合は「登録」ボタンを押し、再度編集する場合は「編集」ボタンを押してください。
        </div>
      ) : (
        <div>
          編集した物件内容を確認してください。このまま更新する場合は「更新」ボタンを押し、再度編集する場合は「編集」ボタンを押してください。
        </div>
      )}
      <div className="new-style_navi_confirm-section_first">
        <ConfirmSection inputsData={publishSet} />
      </div>
      <ConfirmSection
        headings={[{ type: 'h3', title: '1. 画像の選択' }]}
        inputsData={images}
      />
      <ConfirmSection
        headings={[{ type: 'h3', title: '2. 物件情報' }]}
        inputsData={property_info}
      />
      <ConfirmSection
        headings={[{ type: 'h3', title: '3. 基本情報' }]}
        inputsData={estate_basic_info(formData.data)}
      />
      <ConfirmSection
        headings={[{ type: 'h3', title: '4. 土地の情報' }]}
        inputsData={estate_land_info(formData.data)}
      />
      <ConfirmSection
        headings={[{ type: 'h3', title: '5. 建物の情報' }]}
        inputsData={estate_build_info(formData.data)}
      />
      <ConfirmSection
        headings={[{ type: 'h3', title: '6. 物件に関するお問い合わせ先' }]}
        inputsData={contact_info(formData.data)}
      />
      <ConfirmSection
        headings={[{ type: 'h3', title: '7. 物件の詳細設定' }]}
        inputsData={property_detail}
      />
      <ConfirmSection
        headings={[{ type: 'h3', title: '8. 落札後の連絡先' }]}
        inputsData={bidded_contact_info(formData.data)}
      />

      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              type="button"
              text={property_id === undefined ? '登録' : '更新'}
              isOutline={false}
              height={'44px'}
              clickHandler={handleComplete}
              className={
                'property-info-general-form-button new-style_navi_primary-button'
              }
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              type="button"
              text={'編集'}
              isOutline={false}
              height={'44px'}
              clickHandler={handleBackForm}
              className={
                'property-info-general-form-button common-button-bg-whitesmoke new-style_navi_normal-button'
              }
            />
          </div>
          {property_id === undefined ? (
            <> </>
          ) : (
            <div className="new-style_navi_right-button-wrap">
              <Button
                type="button"
                text={'新しい物件として登録'}
                isOutline={false}
                height={'44px'}
                clickHandler={handleComplete}
                className={
                  'property-info-general-form-button new-style_navi_primary-button'
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default PropertyInfoEstateConfirm;
