import 'interfaces/css/ApplicantList/block-target-user-info.css'

const BlockTargetUserInfo = ({ userData }) => {
  return (
    <table data-testid="block-target-user-info">
      <thead>
      <tr>
        <th className="block-target-user-info-th">状況</th>
        <th className="block-target-user-info-th">申し込み方法</th>
        <th className="block-target-user-info-th">申し込み者</th>
        <th className="block-target-user-info-th">申し込み日</th>
        <th className="block-target-user-info-th">メモ</th>
      </tr>
      </thead>
      <tbody>
        <tr>
          <td className="block-target-user-info-td" data-testid="user-info-record">{userData.status}</td>
          <td className="block-target-user-info-td" data-testid="user-info-record">{userData.paymentMethod}</td>
          <td className="block-target-user-info-td" data-testid="user-info-record">{userData.identificationNum}</td>
          <td className="block-target-user-info-td" data-testid="user-info-record">{userData.applicationDate}</td>
          <td className="block-target-user-info-td" data-testid="user-info-record">{userData.memo}</td>
        </tr>
      </tbody>
    </table>
  );
}
export default BlockTargetUserInfo;