import TotalAmountBreakdownTable from "interfaces/components/Billing/TotalAmountBreakdownTable";
import {useContext} from "react";

import {BillingContext} from "../../pages/Billing/entities/BillingContext";

const TotalAmountBreakdown = () => {
  const { invitation } = useContext(BillingContext);

  return (
    <TotalAmountBreakdownTable invitation={invitation} />
  );
};

export default TotalAmountBreakdown;
