import 'interfaces/css/SampleGuideline/guideline-modal-caution-list.css';

const GuidelineModalCautionList = () => {
  return (
    <div className="guideline-modal-caution-list-wrap">
      <div className="guideline-modal-caution-list-inner">
        <div className="guideline-modal-caution-list-title">ご注意</div>
        <ul data-testid="guideline-modal-caution-list" className="guideline-modal-caution-list pl-6 pt-2">
          <li className="guideline-modal-caution-list-item">ガイドラインサンプルの太字やフォントサイズは、コピーして貼り付けても引き継がれませんので、官公庁ナビで設定をしてください。</li>
          <li className="guideline-modal-caution-list-item">各項目の「タイトル」はコピーできません。官公庁ナビで入力してください。</li>
          <li className="guideline-modal-caution-list-item">官公庁ナビで設定後、必ずプレビュー画面でご確認ください。</li>
        </ul>
      </div>
    </div>
  );
};
export default GuidelineModalCautionList;