// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading1 {
    margin-left: auto;
    margin-right: auto;
    background-color: var(--secondary-base);
    padding: 4px 8px;
    position: relative;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.76;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/heading.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,uCAAuC;IACvC,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".heading1 {\n    margin-left: auto;\n    margin-right: auto;\n    background-color: var(--secondary-base);\n    padding: 4px 8px;\n    position: relative;\n    font-size: 18px;\n    font-weight: 700;\n    line-height: 1.76;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
