import PropertyInfoFormCautionDescription from 'interfaces/components/common/PropertyInfoForm/PropertyInfoFormCautionDescription';

import CautionDescription from '../../../Form/navi/CautionDescription';
import FormText from '../../../Form/navi/FormText';
import Label from '../../../Form/navi/Label';

const FormStorageLocation = ({
  formData,
  label,
  address_caution_description,
  url_caution_description,
}) => {
  return (
    <div className="property-info-general-form-table-row">
      <Label label={label} required={true} />
      <div className="property-info-general-form-table-cell">
        <label>
          住所
          <span style={{ color: 'var(--required)' }}>*</span>:
        </label>
        <FormText
          className={`property-input-text-size-100`}
          data={formData.data.inventoryLocation}
          handler1={formData.setters.handleChangeInventoryLocation}
          handler2={() => {}}
          invalidMessage={formData.validationMessage.inventoryLocationValid}
          maxCharacters={60}
        />
        <PropertyInfoFormCautionDescription
          data={address_caution_description}
        />
        <div className="pt-6">
          <label>地図URL：</label>
        </div>
        <FormText
          className={`property-input-text-size-100`}
          data={formData.data.mapUrl}
          handler1={formData.setters.handleChangeMapUrl}
          handler2={() => {}}
          invalidMessage={formData.validationMessage.mapUrlValid}
        />
        <CautionDescription data={url_caution_description} />
      </div>
    </div>
  );
};
export default FormStorageLocation;
