import CopyRight from "./CopyRight.jsx";
import FooterNav from "./FooterNav.jsx";

const GovernmentFooter = () => {
  return (
    <footer className="footer" data-testid="footer">
      <FooterNav/>
      <CopyRight/>
    </footer>
  );
}

export default GovernmentFooter