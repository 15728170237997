import { useEffect, useContext } from 'react';
import { PromotionContext } from 'interfaces/pages/Promotion/entities/PromotionContext';

import Button from 'interfaces/components/common/Button';
import PageTitle from 'interfaces/components/common/PageTitle';

import PromotionAccordion from './PromotionAccordion';
import PromotionForm from './PromotionForm';

//const UsePromotionEditInputsValidation = ({ promotionFrames, fetchPromotionFrames, activeIndex, setActiveIndex }) => {
const PromotionEdit = ({ activeIndex, setActiveIndex }) => {
  const {
    promotionFrames,
    promotionAccordionControls,
    setPromotionAccordionControls,
  } = useContext(PromotionContext);

  const AccordionLabel = (promotion) => {
    return (
      <>
        <p>{promotion.theme}</p>
        <p className="ml-auto">表示枠:{promotion.displayOrder}</p>
      </>
    );
  };

  const addPromotion = () => {
    promotionFrames.setFrames([
      ...promotionFrames,
      {
        id: promotionFrames.length + 1,
        theme: '',
        isDisplayDeadline: 'HIDDEN',
        displayDeadline: '',
        displayOrder: '',
        isActive: 'ACTIVE',
        sourceType: 'INPUT_URL',
        properties: {
          properties: [],
        },
        isNewPromotion: 'TRUE',
      },
    ]);
  };

  useEffect(() => {
    // プロモーションパーツを追加ボタンでpromotionFrames.promotionFramesの数が増えた場合
    if (promotionAccordionControls.length > 0) {
      return setPromotionAccordionControls([
        ...promotionAccordionControls,
        {
          id: promotionFrames.length.toString(),
          isOpen: false,
        },
      ]);
    }

    // 初期処理, 渡されたpromotionFrames.promotionFramesの数だけアコーディオンの開閉を管理するオブジェクトを追加する
    const array = [];
    promotionFrames?.map((promotion) => {
      array.push({
        id: promotion.id.toString(),
        isOpen: false,
      });
    });
    setPromotionAccordionControls(array);
  }, [promotionFrames]);

  // 「保存」ボタンがクリックされた場合、全てのアコーディオンを閉じる
  // promotionAccordionControlsを初期化し、再度fetchPromotionFramesの結果分だけ開閉管理のオブジェクトを生成する
  const closeAllAccordion = () => {
    promotionAccordionControls.map((item) => (item.isOpen = false));
    setPromotionAccordionControls([]);
  };

  return (
    <div
      className="kmanager-promotion-edit box-shadow ma-2 pa-2"
      data-testid="kmanager-promotion-edit">
      <div className="flex align-center">
        <PageTitle text="編集" />
      </div>
      {promotionFrames.length > 0 &&
        promotionFrames.map((promotion) => {
          return (
            <PromotionAccordion
              key={promotion.id}
              label={AccordionLabel(promotion)}
              id={promotion.id}
              promotionAccordionControls={promotionAccordionControls}
              setPromotionAccordionControls={setPromotionAccordionControls}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              contents={
                <PromotionForm
                  promotionFrame={promotion}
                  frameLength={promotionFrames.length}
                  closeAllAccordion={closeAllAccordion}
                />
              }
            />
          );
        })}
      <Button
        className="secondary my-3"
        text="プロモーションパーツを追加"
        clickHandler={addPromotion}
      />
    </div>
  );
};

export default PromotionEdit;
