import Heading2 from 'interfaces/components/common/Heading/Heading2.jsx';
import {GovernmnetsettingList} from "interfaces/pages/Dashboard/entities/GovernmentSettingList";
import { Link } from 'react-router-dom';
import 'interfaces/css/Dashboard/government-settings.css'

const GovernmentSettings = () => {
  return (
    <div className="government-settings" data-testid="government-settings">
      <Heading2 title="行政機関情報設定"/>
      <div className="text-link-wrap">
        {GovernmnetsettingList.map(setting =>
          <Link key={setting.id} to={setting.link} className="text-link" data-testid="text-link">{setting.text}</Link>
        )}
      </div>
    </div>
  );
};

export default GovernmentSettings;
