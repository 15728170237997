import { useEffect, useState } from 'react';
import {
  switchInputClassName,
  switchInputMessage,
} from 'shared/utils/helper/inputHelper.js';
import 'interfaces/css/MyPage/reset-form-input.css';

const ResetFormInput = ({
  label,
  type,
  value,
  handleChange,
  validateMessage,
  caption,
}) => {
  const [borderClassName, setBorderClassName] = useState('normal-border');
  const [isFocus, setIsFocus] = useState(false);
  const [message, setMessage] = useState(caption);
  const isVisibleMessage = validateMessage !== 'NO_MESSAGE';

  useEffect(() => {
    switchInputClassName(isFocus, validateMessage, setBorderClassName);
  }, [isFocus, validateMessage]);

  useEffect(() => {
    switchInputMessage(validateMessage, setMessage, caption, isVisibleMessage);
  }, [validateMessage]);

  return (
    <div className="reset-form-input-wrap">
      <p className="reset-form-input-label">{label}</p>
      <input
        className={`reset-form-input ${borderClassName}`}
        type={type}
        value={value}
        onChange={handleChange}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      />
      <div
        className={`reset-form-input-message ${validateMessage ? 'validate-message' : 'caption-message'}`}>
        {message}
      </div>
    </div>
  );
};

export default ResetFormInput;
