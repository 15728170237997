import axios from 'axios';

import {API_BASE_URL} from "../../config/envConstants.js";

export const createBidData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/bidder`,
    body, {headers: headers}
  );

};
export const updateBidData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/bidder/${id}`,
    body, {headers: headers}
  );
};