import AuctionEdit from "interfaces/components/Auction/kmanager/AuctionEdit/AuctionEdit";

const KmanagerAuctionEdit = () => {
    return (
        <div data-testid="kmanager-auction-government">
            <AuctionEdit/>
        </div>
    );
}

export default KmanagerAuctionEdit;