import { useState } from 'react';
import * as Validation from 'shared/utils/helper/managerValidators.js';

export const useNoticeInputsValidation = () => {
  const [displayServiceTypeValid, setDisplayServiceTypeValid] =
    useState('TRUE');
  const [displayProcedureTypeValid, setDisplayProcedureTypeValid] =
    useState('TRUE');
  const [displayGovernmentTypeValid, setDisplayGovernmentTypeValid] =
    useState('TRUE');
  const [titleValid, setTitleValid] = useState('TRUE');
  const [openAtValid, setOpenAtValid] = useState('TRUE');
  const [closeAtValid, setCloseAtValid] = useState('TRUE');
  const [bodyValid, setBodyValid] = useState('TRUE');

  function handleDisplayServiceTypeValid(e, displayServiceType) {
    // logInput(e.target.name, e.target.value);
    const invalidService = Validation.validateSelectDisplayPlace(
      displayServiceType.service,
    );
    const invalidNavi = Validation.validateSelectDisplayPlace(
      displayServiceType.navi,
    );
    if (invalidService !== 'TRUE' && invalidNavi !== 'TRUE') {
      setDisplayServiceTypeValid(invalidService); // 状態更新
    } else {
      setDisplayServiceTypeValid('TRUE'); // 状態更新
    }
  }

  function handleDisplayProcedureTypeValid(e, displayProcedureType) {
    // logInput(e.target.name, e.target.value);;
    const invalidPublicSale = Validation.validateSelectContractType(
      displayProcedureType.publicSale,
    );
    const invalidAssetSale = Validation.validateSelectContractType(
      displayProcedureType.assetSale,
    );
    if (invalidPublicSale !== 'TRUE' && invalidAssetSale !== 'TRUE') {
      setDisplayProcedureTypeValid(invalidPublicSale); // 状態更新
    } else {
      setDisplayProcedureTypeValid('TRUE'); // 状態更新
    }
  }

  function handleDisplayGovernmentTypeValid(e, displayGovernmentType) {
    // logInput(e.target.name, e.target.value);;
    const invalidNationalTaxAgency = Validation.validateSelectContractType(
      displayGovernmentType.nationalTaxAgency,
    );
    const invalidLocalGovernment = Validation.validateSelectContractType(
      displayGovernmentType.localGovernment,
    );
    if (
      invalidNationalTaxAgency !== 'TRUE' &&
      invalidLocalGovernment !== 'TRUE'
    ) {
      setDisplayGovernmentTypeValid(invalidNationalTaxAgency); // 状態更新
    } else {
      setDisplayGovernmentTypeValid('TRUE'); // 状態更新
    }
  }

  function handleOpenAtValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateSelectOpenDate(e);
    if (invalid !== 'TRUE') {
      setOpenAtValid(invalid); // 状態更新
      return;
    }
    invalid = Validation.validateSetOpenDateAfterCurrentTime(e);
    if (invalid !== 'TRUE') {
      setOpenAtValid(invalid); // 状態更新
      return;
    }
    setOpenAtValid(invalid); // 状態更新
  }

  function handleCloseAtValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateSelectOpenDate(e);
    if (invalid !== 'TRUE') {
      setCloseAtValid(invalid); // 状態更新
      return;
    }
    invalid = Validation.validateSetOpenDateAfterCurrentTime(e);
    if (invalid !== 'TRUE') {
      setCloseAtValid(invalid); // 状態更新
      return;
    }
    setCloseAtValid(invalid); // 状態更新
  }

  function handleTitleValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateTitleLength(e.target.value);
    if (invalid !== 'TRUE') {
      setTitleValid(invalid); // 状態更新
      return;
    }
    invalid = Validation.validateIncludeEmojiTitle1(e.target.value);
    if (invalid !== 'TRUE') {
      setTitleValid(invalid); // 状態更新
      return;
    }
    setTitleValid(invalid); // 状態更新
  }

  function handleBodyValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateRangeBodyLength(e.text);
    if (invalid !== 'TRUE') {
      setBodyValid(invalid); // 状態更新
      return;
    }
    invalid = Validation.validateIncludeEmojiBody(e.text);
    if (invalid !== 'TRUE') {
      setBodyValid(invalid); // 状態更新
      return;
    }
    setBodyValid(invalid); // 状態更新
  }

  const validations = {
    // validationの処理
    setters: {
      displayServiceType: handleDisplayServiceTypeValid,
      displayProcedureType: handleDisplayProcedureTypeValid,
      displayGovernmentType: handleDisplayGovernmentTypeValid,
      openAt: handleOpenAtValid,
      closeAt: handleCloseAtValid,
      title: handleTitleValid,
      body: handleBodyValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      displayServiceType: displayServiceTypeValid,
      displayProcedureType: displayProcedureTypeValid,
      displayGovernmentType: displayGovernmentTypeValid,
      openAt: openAtValid,
      closeAt: closeAtValid,
      title: titleValid,
      body: bodyValid,
    },
  };

  return validations;
};
