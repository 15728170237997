import GovernmentDetailProvider from './GovernmentDetailProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { governmentDetail } from "../entities/componentMapping.js";

const GovernmentDetail = ({ type }) => {
  return (
    <GovernmentDetailProvider type={type}>
      {getComponent(governmentDetail, type)}
    </GovernmentDetailProvider>
  );
};

export default GovernmentDetail;
