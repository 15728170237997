import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class Destination {
  #destination

  constructor(destination) {
    this.#destination = destination;
  }

  get() {
    return this.#destination
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#destination, 1, 50)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#destination)
  }
}
