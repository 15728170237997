import Tab from 'interfaces/components/common/Tab.jsx';
import 'interfaces/css/common/switcher.css';

const EmailSwitcher = ({ labels, activeTabIndex }) => {
  return (
    <div data-testid="kmanager-switcher" className="kmanager-switcher d-flex">
      {labels.map((label, index) => (
        <Tab
          key={index}
          label={label.label}
          isActive={index === activeTabIndex}
          setActiveTab={label.handleClick}
        />
      ))}
    </div>
  );
};
export default EmailSwitcher;
