import Heading2 from 'interfaces/components/common/Heading/Heading2';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel';
import { validateInputsProcedureNewEntryForm } from 'interfaces/components/common/validateInputs/ValidationNewEntryForm';
import { breadcrumbs } from 'interfaces/pages/NewEntry/entities/breadcrumbs';
import NaviNewEntryConfirm from 'interfaces/pages/NewEntry/services/NaviNewEntryConfirm';
import NaviNewEntryForm from 'interfaces/pages/NewEntry/services/NaviNewEntryForm';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppliableAuctions } from 'shared/hooks/useAppliableAuctions';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { DataEmptyConvert } from 'shared/utils/helper/CreateConfirmData';
import PageCautionDescription from 'interfaces/components/common/PageCautionDescription';
import { textExtractionByProcedureType } from 'shared/utils/helper/TextExtraction';

import NaviNewEntryComplete from './NaviNewEntryComplete';
import Breadcrumb from '../../../components/common/Breadcrumb';
import { NewEntryContext } from '../entities/NewEntryContext';

//import {allFetch} from "infrastructure/api/storage/properties";

const NaviNewEntry = () => {
  const { localStorageProcedureType } = useLocalStorage();
  const [onConfirm, setOnConfirm] = useState(false);
  const [onComplete, setOnComplete] = useState(false);
  const { auctions, government, invitations, invitation, auction } =
    useContext(NewEntryContext);
  const iauctions = useAppliableAuctions(auctions, invitations);
  const { auction_id } = useParams();
  const inputs = validateInputsProcedureNewEntryForm(auction_id !== undefined);

  const [isValid, setIsValid] = useState(true);
  useEffect(() => {
    const notRequiredKeys = [
      'departmentValid',
      'contactEmailValid',
      'contactTelValid',
      'tel1Valid',
      'tel2Valid',
      'tel3Valid',
    ];
    const validationResults = Object.entries(inputs.validationMessage).map(
      ([key, validationMessage]) => {
        const isValid = notRequiredKeys.includes(key)
          ? validationMessage === null || validationMessage === 'TRUE'
          : validationMessage === 'TRUE';
        return { key, validationMessage, isValid };
      },
    );
    console.log(validationResults);
    const allValid = validationResults.every(({ isValid }) => isValid);
    setIsValid(allValid);
  }, [inputs.validationMessage]);

  useEffect(() => {
    inputs.init.setArea(government?.location);
  }, [government]);

  useEffect(() => {
    inputs.init.setAuctionName(
      iauctions?.find((object) => object.id === invitation?.auctionId)?.text,
    );
    inputs.init.setName(DataEmptyConvert(invitation?.name));
    inputs.init.setAplCountAuctionDisplay(invitation?.aplCountAuctionDisplay);
    inputs.init.setAplCountBidDisplay(invitation?.aplCountBidDisplay);
    inputs.init.setBidCountDisplay(invitation?.bidCountDisplay);
    inputs.init.setAuctionId(invitation?.auctionId);
    inputs.init.setBidEndType(invitation?.bidEndType);
    inputs.init.setMailCertDocument(invitation?.mailCertDocument);
    inputs.init.setMailReductionDocument(invitation?.mailReductionDocument);
    inputs.init.setDepositDeliveryStatus({
      paymentCreditCard:
        invitation?.paymentCreditCard === 'TRUE' ? 'on' : 'off',
      paymentBankTransfer:
        invitation?.paymentBankTransfer === 'TRUE' ? 'on' : 'off',
      noneDeposit: invitation?.noneDeposit === 'TRUE' ? 'on' : 'off',
    });
    inputs.init.setArea(DataEmptyConvert(invitation?.area));
    inputs.init.setContactOffice(DataEmptyConvert(invitation?.contactOffice));
    inputs.init.setContactEmail(invitation?.contactEmail);
    inputs.init.setContactTel({
      telNum1: invitation?.contactTel.split('-')[0],
      telNum2: invitation?.contactTel.split('-')[1],
      telNum3: invitation?.contactTel.split('-')[2],
    });
  }, [invitation]);

  const handleConfirm = (e) => {
    e.preventDefault();
    setOnConfirm(true);
  };

  const cancel = (e) => {
    e.preventDefault();
    setOnConfirm(false);
  };

  const handleComplete = (e) => {
    e.preventDefault();
    setOnComplete(true);
  };

  //    const toRegister = async (e) => {
  //        e.preventDefault();
  //        const headers = {
  //            'Content-Type': 'application/json',
  //            government_id: localStorageGovernmentId,
  //        };
  //        const configs = {
  //            aplCountAuctionDisplay: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/apl-count-auction-display`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.aplCountAuctionDisplay
  //                }
  //            },
  //
  //            aplCountBidDisplay: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/apl-count-bid-display`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.aplCountBidDisplay
  //                }
  //            },
  //
  //            area: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/area`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.area
  //                }
  //            },
  //
  //            auction_id: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/auction-id`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: String(inputs.data.auctionId),
  //                }
  //            },
  //
  //            bidCountDisplay: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/bid-count-display`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.bidCountDisplay
  //                }
  //            },
  //
  //            bidEndType: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/bid-end-type`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.bidEndType
  //                }
  //            },
  //
  //            contactEmail: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/contact-email`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.contactEmail
  //                }
  //            },
  //
  //            contactOffice: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/contact-office`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.contactOffice
  //                }
  //            },
  //
  //            contactTel: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/contact-tel`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: `${inputs.data.contactTel.telNum1}-${inputs.data.contactTel.telNum2}-${inputs.data.contactTel.telNum3}`
  //                }
  //            },
  //
  //            governmentId: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/government-id`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: localStorageGovernmentId
  //                }
  //            },
  //
  //            mailCertDocument: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/mail-cert-document`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.mailCertDocument
  //                }
  //            },
  //
  //            mailReductionDocument: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/mail-reduction-document`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.mailCertDocument
  //                }
  //            },
  //
  //            name: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/name`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.name
  //                }
  //            },
  //
  //            noneDeposit: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/none-deposit`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.depositDeliveryStatus.noneDeposit ? "TRUE" : "FALSE"
  //                }
  //            },
  //
  //            paymentBankTransfer: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/payment-bank-transfer`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.depositDeliveryStatus.paymentBankTransfer ? "TRUE" : "FALSE"
  //                }
  //            },
  //
  //            paymentCreditCard: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/payment-credit-card`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.depositDeliveryStatus.paymentCreditCard ? "TRUE" : "FALSE"
  //                }
  //            },
  //
  //            taxId: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/create/tax-id`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: String(inputs.data.auctionId),
  //                }
  //            },
  //        }
  //        await allFetch(configs);
  //        console.log('create');
  //    }

  //    const toUpdate = async (e) => {
  //        e.preventDefault();
  //        const headers = {
  //            'Content-Type': 'application/json',
  //            government_id: localStorageGovernmentId,
  //        };
  //        const configs = {
  //            aplCountAuctionDisplay: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/apl-count-auction-display`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.aplCountAuctionDisplay
  //                }
  //            },
  //
  //            aplCountBidDisplay: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/apl-count-bid-display`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.aplCountBidDisplay
  //                }
  //            },
  //
  //            area: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/area`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.area
  //                }
  //            },
  //            id: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/id`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: String(data.id)
  //                }
  //            },
  //
  //            auction_id: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/auction-id`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: String(inputs.data.auctionId),
  //                }
  //            },
  //
  //            bidCountDisplay: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/bid-count-display`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.bidCountDisplay
  //                }
  //            },
  //
  //            bidEndType: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/bid-end-type`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.bidEndType
  //                }
  //            },
  //
  //            contactEmail: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/contact-email`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.contactEmail
  //                }
  //            },
  //
  //            contactOffice: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/contact-office`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.contactOffice
  //                }
  //            },
  //
  //            contactTel: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/contact-tel`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: `${inputs.data.contactTel.telNum1}-${inputs.data.contactTel.telNum2}-${inputs.data.contactTel.telNum3}`
  //                }
  //            },
  //
  //            governmentId: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/government-id`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: localStorageGovernmentId
  //                }
  //            },
  //
  //            mailCertDocument: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/mail-cert-document`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.mailCertDocument
  //                }
  //            },
  //
  //            mailReductionDocument: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/mail-reduction-document`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.mailCertDocument
  //                }
  //            },
  //
  //            name: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/name`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.name
  //                }
  //            },
  //
  //            noneDeposit: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/none-deposit`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.depositDeliveryStatus.noneDeposit ? "TRUE" : "FALSE"
  //                }
  //            },
  //
  //            paymentBankTransfer: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/payment-bank-transfer`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.depositDeliveryStatus.paymentBankTransfer ? "TRUE" : "FALSE"
  //                }
  //            },
  //
  //            paymentCreditCard: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/payment-credit-card`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: inputs.data.depositDeliveryStatus.paymentCreditCard ? "TRUE" : "FALSE"
  //                }
  //            },
  //
  //            taxId: {
  //                path: `${process.env.REACT_APP_API_BASE_URL}/api/v1/navi/invitations/update/tax-id`,
  //                headers: headers,
  //                body: {
  //                    auction_id: String(inputs.data.auctionId),
  //                    government_id: localStorageGovernmentId,
  //                    value: String(inputs.data.auctionId),
  //                }
  //            },
  //        }
  //        await allFetch(configs);
  //        console.log('update');
  //    }

  const getMethod = () => {
    if (auction_id !== undefined) {
      return 'update';
    }
    return 'create';
  };

  const getTitle = () => {
    if (getMethod() !== 'update') {
      return `${localStorageProcedureType === 'PUBLIC_SALE' ? 'インターネット公売' : '公有財産売却'}参加申込フォーム`;
    }
    return `${localStorageProcedureType === 'PUBLIC_SALE' ? 'インターネット公売' : '公有財産売却'}参加申込情報修正`;
  };

  return (
    <div className="new-style_navi_form" data-testid="new-entry-create">
      {onComplete ? (
        <NaviNewEntryComplete method={getMethod()} />
      ) : onConfirm ? (
        invitation ? (
          <NaviNewEntryConfirm
            inputs={inputs}
            procedureType={localStorageProcedureType}
            cancel={cancel}
            toRegister={handleComplete}
            auction={auction}
          />
        ) : (
          <NaviNewEntryConfirm
            inputs={inputs}
            procedureType={localStorageProcedureType}
            cancel={cancel}
            toRegister={handleComplete}
          />
        )
      ) : (
        <>
          <Breadcrumb
            breadcrumbs={breadcrumbs(
              getMethod(),
              'form',
              localStorageProcedureType,
              auction_id,
            )}
          />
          <Heading2 title={getTitle()} />
          <p className="pt-3 pb-3">
            <RequiredLabel />
            は入力、選択必須項目です。
            <br />
            ※半角カタカナと機種依存文字は使用できません。
          </p>
          {auction_id !== undefined && (
            <PageCautionDescription
              content={`※${textExtractionByProcedureType(localStorageProcedureType)}参加申込開始日以降は修正できません。`}
            />
          )}
          <NaviNewEntryForm
            auctions={iauctions}
            auction={auction}
            inputs={inputs}
            procedureType={localStorageProcedureType}
            toConfirm={handleConfirm}
            isValid={isValid}
          />
        </>
      )}
    </div>
  );
};

export default NaviNewEntry;
