import "interfaces/css/common/button.css";
import { ReactComponent as IconArrowDown } from "interfaces/assets/svg/arrow-down.svg";
import { ReactComponent as IconArrowUp } from "interfaces/assets/svg/arrow-up.svg";

const SmallLightIconButton = ({children, active, isSortDesc, onClick}) => {
  return (
    <button
      className={`button button-small-light-icon ${ active ? "active" : "" }`}
      data-testid="small-light-icon-button"
      onClick={onClick}
    >
      <span className="button-content">
        {children}
        { active &&
          <span className="icon icon-right" data-testid={`${ isSortDesc ? "arrow-up" : "arrow-down" }`}>
            { isSortDesc ? <IconArrowUp/> : <IconArrowDown/> }
          </span>
        }
      </span>
    </button>
  );
}

export default SmallLightIconButton;