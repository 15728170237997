export const parent_category = [
  { id: 1, text: "コンピュータ" },
  { id: 2, text: "家電、AV、カメラ" },
]
export const pulldown_year = [
  { id: 1, text: "令和5年(2023年)" },
  { id: 2, text: "令和4年(2022年)" },
]
// export const pulldown_year = [
//   {id: 1, text: "令和5年(2023年), value: 2023"},
//   {id: 2, text: "令和4年(2022年), value: 2022"},
// ]
export const pulldown_month = [
  { id: 1, text: "1" },
  { id: 2, text: "2" },
  { id: 3, text: "3" },
  { id: 4, text: "4" },
  { id: 5, text: "5" },
  { id: 6, text: "6" },
  { id: 7, text: "7" },
  { id: 8, text: "8" },
  { id: 9, text: "9" },
  { id: 10, text: "10" },
  { id: 11, text: "11" },
  { id: 12, text: "12" },
]
export const pulldown_day = [
  { id: 1, text: "1" },
  { id: 2, text: "2" },
]
export const prefectures = [
  { id: 1, text: "北海道" },
  { id: 2, text: "青森" },
  { id: 3, text: "岩手" },
]

export const car_purpose = [
  { id: 1, text: "乗用", value: 'PASSENGER' },
  { id: 2, text: "特殊", value: 'SPECIAL' },
  { id: 3, text: "貨物", value: 'FREIGHT' },
  { id: 4, text: "乗合", value: 'BUS' },
  { id: 5, text: "その他", value: 'OTHER' },
]

export const car_manufactures = [
  { id: 1, text: "トヨタ", value: 'TOYOTA' },
  { id: 2, text: "レクサス", value: 'LEXUS' },
  { id: 3, text: "ホンダ", value: 'HONDA' },
  { id: 4, text: "日産", value: 'NISSAN' },
  { id: 5, text: "スズキ", value: 'SUZUKI' },
  { id: 6, text: "ダイハツ", value: 'DAIHATSU' },
  { id: 7, text: "マツダ", value: 'MATSUDA' },
  { id: 8, text: "スバル", value: 'SUBARU' },
  { id: 9, text: "三菱", value: 'MITSUBISHI' },
  { id: 10, text: "ヤマハ", value: 'YAMAHA' },
  { id: 11, text: "日野", value: 'HINO' },
  { id: 12, text: "メルセデス・ベンツ", value: 'MERCEDES_BENZ' },
  { id: 13, text: "BMW", value: 'BMW' },
  { id: 14, text: "フォルクスワーゲン", value: 'VOLKSWAGEN' },
  { id: 15, text: "アウディ", value: 'AUDI' },
  { id: 16, text: "ミニ", value: 'MINI' },
  { id: 17, text: "ポルシェ", value: 'PORSCHE' },
  { id: 18, text: "ボルボ", value: 'VOLVO' },
  { id: 19, text: "プジョー", value: 'PEUGEOT' },
  { id: 20, text: "ランドローパー", value: 'LAND_ROVER' },
  { id: 21, text: "その他", value: 'OTHER' },
]

export const pulldown_prefecture = [
  { id: 1, text: "北海道", value: 1 },
  { id: 2, text: "青森県", value: 2 },
  { id: 3, text: "岩手県", value: 3 },
  { id: 4, text: "宮城県", value: 4 },
  { id: 5, text: "秋田県", value: 5 },
  { id: 6, text: "山形県", value: 6 },
  { id: 7, text: "福島県", value: 7 },
  { id: 8, text: "茨城県", value: 8 },
  { id: 9, text: "栃木県", value: 9 },
  { id: 10, text: "群馬県", value: 10 },
  { id: 11, text: "埼玉県", value: 11 },
  { id: 12, text: "千葉県", value: 12 },
  { id: 13, text: "東京都", value: 13 },
  { id: 14, text: "神奈川県", value: 14 },
  { id: 15, text: "新潟県", value: 15 },
  { id: 16, text: "富山県", value: 16 },
  { id: 17, text: "石川県", value: 17 },
  { id: 18, text: "福井県", value: 18 },
  { id: 19, text: "山梨県", value: 19 },
  { id: 20, text: "長野県", value: 20 },
  { id: 21, text: "岐阜県", value: 21 },
  { id: 22, text: "静岡県", value: 22 },
  { id: 23, text: "愛知県", value: 23 },
  { id: 24, text: "三重県", value: 24 },
  { id: 25, text: "滋賀県", value: 25 },
  { id: 26, text: "京都府", value: 26 },
  { id: 27, text: "大阪府", value: 27 },
  { id: 28, text: "兵庫県", value: 28 },
  { id: 29, text: "奈良県", value: 29 },
  { id: 30, text: "和歌山県", value: 30 },
  { id: 31, text: "鳥取県", value: 31 },
  { id: 32, text: "島根県", value: 32 },
  { id: 33, text: "岡山県", value: 33 },
  { id: 34, text: "広島県", value: 34 },
  { id: 35, text: "山口県", value: 35 },
  { id: 36, text: "徳島県", value: 36 },
  { id: 37, text: "香川県", value: 37 },
  { id: 38, text: "愛媛県", value: 38 },
  { id: 39, text: "高知県", value: 39 },
  { id: 40, text: "福岡県", value: 40 },
  { id: 41, text: "佐賀県", value: 41 },
  { id: 42, text: "長崎県", value: 42 },
  { id: 43, text: "熊本県", value: 43 },
  { id: 44, text: "大分県", value: 44 },
  { id: 45, text: "宮崎県", value: 45 },
  { id: 46, text: "鹿児島県", value: 46 },
  { id: 47, text: "沖縄県", value: 47 }
];

export const pulldown_account_types = [
  { id: 1, text: '普通', value: 'SAVINGS'},
  { id: 2, text: '当座', value: 'CHECKING'},
  { id: 3, text: 'その他', value: 'OTHER'},
]

export const estate_structure_types = [
  { id: 1, text: "鉄筋鉄骨コンクリート", value: "鉄筋鉄骨コンクリート"},
  { id: 2, text: "鉄筋コンクリート", value: "鉄筋コンクリート"},
  { id: 3, text: "鉄骨", value: "鉄骨"},
  { id: 4, text: "木造", value: "木造"},
  { id: 5, text: "その他", value: "その他"},
]

export const estate_types = [
  { id: 1, text: "土地付き建物", value: "BUILDING_WITH_LAND" },
  { id: 2, text: "区分所有物件", value: "SECTIONAL_OWNERSHIP_BUILDING" },
  { id: 3, text: "宅地", value: "RESIDENTIAL_LAND" },
  { id: 4, text: "山林", value: "MOUNTAIN_FOREST" },
  { id: 5, text: "その他土地", value: "OTHER_LAND" },
  { id: 6, text: "底地", value: "BOTTOM_LAND" },
  { id: 7, text: "その他", value: "OTHER" },
];

