import 'interfaces/css/common/checkbox.css';

const CheckBox = ({ id, isChecked, onClick }) => {
  return (
    <button
      data-testid={`${id}-checkbox`}
      className={`checkbox checkbox-icon ${isChecked ? 'checked' : ''}`}
      onClick={onClick}></button>
  );
};

export default CheckBox;
