import SignUpDescriptionImage from 'interfaces/assets/image/sign_up_description.png';
import Button from 'interfaces/components/common/Button/Button.jsx';
import { Link } from 'react-router-dom';
import { LOGIN } from 'shared/utils/helper/authState.js';

import SignUpForm from './SignUpForm.jsx';
import 'interfaces/css/common/sign-up-modal-contents.css';

const SignUpModalContents = ({ handleClose, switchAuthStatus }) => {
  return (
    <div
      className="sign-up-modal-contents"
      data-testid="sign-up-modal-contents">
      <div className="sign-up-modal-title" data-testid="sign-up-modal-title">
        新規登録
      </div>
      <img
        src={SignUpDescriptionImage}
        alt="新規登録についての説明"
        className="sign-up-description-image"
        data-testid="sign-up-description-image"
      />
      <div className="sign-up-modal-contents-body">
        <SignUpForm />
        <div className="text-center" data-testid="to-login-form">
          登録済みの方は{' '}
          <Link onClick={() => switchAuthStatus(LOGIN)}>こちら</Link>{' '}
          からログインしてください
        </div>
        <div className="close-sign-up-modal-button-wrap">
          <Button
            text="閉じる"
            onClick={handleClose}
            className="close-sign-up-modal-button"
          />
        </div>
      </div>
    </div>
  );
};

export default SignUpModalContents;
