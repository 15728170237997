// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-group {
  flex: 1 1 auto;
  max-width: 100%;
}

.link-group .link-item {
  color: rgba(0,0,0,.87);
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 0;
  outline: none;
  padding: 0;
  position: relative;
  text-decoration: none;
}

.link-group .link-item .link-wrap {
  line-height: 1.125rem;
  font-weight: 700;
}

.link-group .link-item .link-wrap .link-item-text {
  font-size: .75rem;
  font-family: "Roboto",sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/link-group.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,cAAc;EACd,sBAAsB;EACtB,aAAa;EACb,aAAa;EACb,UAAU;EACV,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,gCAAgC;AAClC","sourcesContent":[".link-group {\n  flex: 1 1 auto;\n  max-width: 100%;\n}\n\n.link-group .link-item {\n  color: rgba(0,0,0,.87);\n  align-items: center;\n  display: flex;\n  flex: 1 1 100%;\n  letter-spacing: normal;\n  min-height: 0;\n  outline: none;\n  padding: 0;\n  position: relative;\n  text-decoration: none;\n}\n\n.link-group .link-item .link-wrap {\n  line-height: 1.125rem;\n  font-weight: 700;\n}\n\n.link-group .link-item .link-wrap .link-item-text {\n  font-size: .75rem;\n  font-family: \"Roboto\",sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
