import PropertyListProvider from './PropertyListProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { propertyList } from "../entities/componentMapping.js";

const PropertyList = ({ type }) => {
  return (
    <PropertyListProvider type={type}>
      {getComponent(propertyList, type)}
    </PropertyListProvider>
  );
};

export default PropertyList;
