// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input {
  position: relative;
  padding-top: 12px;
}

.search-input > div:after {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  content: "";
  transition: .3s cubic-bezier(.25, .8, .5, 1);
  border-bottom: 1px solid rgba(0, 0, 0, .42);
}

.search-input > div {
  display: flex;
}

.search-input input {
  flex: 1 1 auto;
  padding: 8px 0;
}

.search-input svg {
  width: 18px;
  height: 18px;
}

.kmanager .search-input > div {
  align-items: center;
}
.kmanager .search-input input {
  border: none;
  margin-right: 4px;
}
.kmanager .search-input svg {
  box-sizing: content-box;
  color: rgba(0,0,0,.54);
  padding: 3px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/search-input.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,OAAO;EACP,WAAW;EACX,WAAW;EACX,4CAA4C;EAC5C,2CAA2C;AAC7C;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,uBAAuB;EACvB,sBAAsB;EACtB,YAAY;AACd","sourcesContent":[".search-input {\n  position: relative;\n  padding-top: 12px;\n}\n\n.search-input > div:after {\n  position: absolute;\n  bottom: -1px;\n  left: 0;\n  width: 100%;\n  content: \"\";\n  transition: .3s cubic-bezier(.25, .8, .5, 1);\n  border-bottom: 1px solid rgba(0, 0, 0, .42);\n}\n\n.search-input > div {\n  display: flex;\n}\n\n.search-input input {\n  flex: 1 1 auto;\n  padding: 8px 0;\n}\n\n.search-input svg {\n  width: 18px;\n  height: 18px;\n}\n\n.kmanager .search-input > div {\n  align-items: center;\n}\n.kmanager .search-input input {\n  border: none;\n  margin-right: 4px;\n}\n.kmanager .search-input svg {\n  box-sizing: content-box;\n  color: rgba(0,0,0,.54);\n  padding: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
