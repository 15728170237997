import RegistrationUserProvider from './RegistrationUserProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { registrationUser } from "../entities/componentMapping.js";

const RegistrationUser = ({ type }) => {
  return (
    <RegistrationUserProvider type={type}>
      {getComponent(registrationUser, type)}
    </RegistrationUserProvider>
  );
};

export default RegistrationUser;
