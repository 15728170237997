
export class PrivateOrBusiness {
  #privateOrBusiness

  constructor(privateOrBusiness) {
    this.#privateOrBusiness = privateOrBusiness;
  }

  get() {
    return this.#privateOrBusiness
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
