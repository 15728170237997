
export class Form {
    #form

    constructor(form) {
        this.#form = form;
    }

    get() {
        return this.#form
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
