// ロジックを含む処理
// フロントから呼ぶ関数

import { createNoticesData, updateNoticesData, deleteNoticesData } from "../../../infrastructure/api/Notices.js";
import { compareData } from "../../../shared/utils/helper/compareData.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { NoticeCreate } from "../entities/News.js";
import { getNotices } from "../repositories/Notices.js";

export async function get(type) {
  return await getNotices(type)
}

export async function findById(type, id) {
  const News = await getNotices(type);
  return News.find(data => data.id === id)
}

export async function findByService(type) {
  const News = await getNotices(type);
  return News.filter(data => data.isDisplayService === "TRUE")
}

export async function BySlice(type) {
  const News = await getNotices(type);
  return News.slice(0, 5)
}


export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (News)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (News)');
  }
  if (type === KMANAGER) {
    const notice = new NoticeCreate(data)
    response = await createNoticesData(notice);
  }
  if (response instanceof Error) {
    throw new CustomError('Error create News', response);
  }
  return response
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (News)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (News)');
  }
  if (type === KMANAGER) {
    response = await updateNoticesData(data, data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error update News', response);
  }
  return response
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (News)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (News)');
  }
  if (type === KMANAGER) {
    response = await deleteNoticesData(data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete News', response);
  }
  return response
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]))
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]))
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value)
}
