import { isNaturalNumber } from 'shared/utils/helper/isNaturalNumber';

import {
  PropertyInfo,
  PropertyInfoLabel,
  PropertyInfoValue,
} from 'interfaces/components/common/PropertyInfo/index';

const BidCountBody = ({ data }) => {
  return (
    <div data-testid="bid-count-body">
      <hr role="separator" aria-orientation="horizontal" className="divider" />
      <PropertyInfo className="flex justify-space-between align-center py-1">
        <PropertyInfoLabel className="ma-0">入札件数</PropertyInfoLabel>
        <PropertyInfoValue
          className="ma-0 text-right text-caption"
          data-testid="bid-count">
          {isNaturalNumber(data.bidCount) ? data.bidCount : '0'}
        </PropertyInfoValue>
      </PropertyInfo>
    </div>
  );
};

export default BidCountBody;
