
export class IsDeliveryLock {
    #isDeliveryLock

    constructor(isDeliveryLock) {
        this.#isDeliveryLock = isDeliveryLock;
    }

    get() {
        return this.#isDeliveryLock
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
