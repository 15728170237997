import GovernmentPropertiesProvider from './GovernmentPropertiesProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { governmentProperties } from "../entities/componentMapping.js";

const GovernmentProperties = ({ type }) => {
  return (
    <GovernmentPropertiesProvider type={type}>
      {getComponent(governmentProperties, type)}
    </GovernmentPropertiesProvider>
  );
};

export default GovernmentProperties;
