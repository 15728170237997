import Pagination from 'interfaces/components/common/Pagination';
import { Fragment, useContext, useState } from 'react';

import { SortableTh } from 'interfaces/components/common/Table/Th/SortableTh';
import ManagementUserTableRecord from './ManagementUserTableRecord';
import { UserContext } from 'interfaces/pages/User/entities/UserContext';
import usePagination from 'shared/hooks/usePagination';
import { useSortableData } from 'shared/hooks/useSort.js';

import 'interfaces/css/Dashboard/kmanager-auction-table.css';

const ManagementUserTable = ({ isAdmin }) => {
  const { managementUsers } = useContext(UserContext);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    items: sortedManagementUsers,
    requestSort,
    sortConfig,
  } = useSortableData(managementUsers);

  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(sortedManagementUsers, rowsPerPage);

  const headerColumns = [
    { id: 1, column: 'familyName', text: '担当者名(姓)' },
    { id: 2, column: 'firstName', text: '担当者名(名)' },
    { id: 3, column: 'familyNameKana', text: '担当者名(セイ)' },
    { id: 4, column: 'firstNameKana', text: '担当者名(メイ)' },
    { id: 5, column: 'department', text: '部署名' },
    { id: 6, column: 'officePosition', text: '役職名' },
    { id: 7, column: 'tel', text: '電話番号' },
    { id: 8, column: 'extension', text: '内線' },
    { id: 9, column: 'email', text: 'メール' },
    { id: 10, column: 'userType', text: 'ユーザ区分' },
    { id: 11, column: '', text: '編集/削除' },
  ];
  return (
    <div>
      <div className="kmanager-auction-table-wrap kmanager-table-wrap">
        <table
          className="kmanager-auction-table kmanager-table-hover kmanager-table"
          data-testid="kmanager-auction-table">
          <thead>
            <tr>
              {headerColumns.map((header) => (
                <SortableTh
                  key={header.id}
                  header={header}
                  onClick={() => requestSort(header.column)}
                  sortConfig={sortConfig}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((managementUser) => (
                <Fragment key={managementUser.id}>
                  <ManagementUserTableRecord
                    managementUser={managementUser}
                    isAdmin={isAdmin}
                  />
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        DATA_COUNT={managementUsers.length}
      />
    </div>
  );
};

export default ManagementUserTable;
