import { validatePrefecture, validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class ResidenceLocation {
  #residenceLocation

  constructor(residenceLocation) {
    this.#residenceLocation = residenceLocation;
  }

  get() {
    return this.#residenceLocation
  }

  get isValid() {
    return [
      this.validatePrefecture,
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validatePrefecture() {
    return validatePrefecture(this.#residenceLocation)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#residenceLocation, 0, 100)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#residenceLocation)
  }
}
