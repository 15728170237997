
export class Bidders {
    #bidders

    constructor(bidders) {
        this.#bidders = bidders;
    }

    get() {
        return this.#bidders
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
