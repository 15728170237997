import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SnackBarProvider } from 'interfaces/components/Auth/kmanager/SnackBarProvider';
import CommonUI from '../../interfaces/components/Auth/kmanager/CommonUI';
import AuctionDeposit from '../../interfaces/pages/AuctionDeposit/services/AuctionDeposit.jsx';
import AuctionEdit from '../../interfaces/pages/AuctionEdit/services/AuctionEdit.jsx';
import AuctionEntry from '../../interfaces/pages/AuctionEntry/services/AuctionEntry.jsx';
import AuctionGovernment from '../../interfaces/pages/AuctionGovernment/services/AuctionGovernment.jsx';
import AuctionGovernmentSettings from '../../interfaces/pages/AuctionGovernmentSettings/services/AuctionGovernmentSettings.jsx';
import AuctionNew from '../../interfaces/pages/AuctionNew/services/AuctionNew.jsx';
import AuctionUsageFee from '../../interfaces/pages/AuctionUsageFee/services/AuctionUsageFee.jsx';
import Banner from '../../interfaces/pages/Banner/services/Banner.jsx';
import Category from '../../interfaces/pages/Category/services/Category.jsx';
import Dashboard from '../../interfaces/pages/Dashboard/services/Dashboard.jsx';
import GovernmentDeposit from '../../interfaces/pages/GovernmentDeposit/services/GovernmentDeposit.jsx';
import GovernmentDetail from '../../interfaces/pages/GovernmentDetail/services/GovernmentDetail.jsx';
import GovernmentList from '../../interfaces/pages/GovernmentList/services/GovernmentList.jsx';
import GovernmentNew from '../../interfaces/pages/GovernmentNew/services/GovernmentNew.jsx';
import GovernmentPayment from '../../interfaces/pages/GovernmentPayment/services/GovernmentPayment.jsx';
import GovernmentProperties from '../../interfaces/pages/GovernmentProperties/services/GovernmentProperties.jsx';
import GovernmentUserEntry from '../../interfaces/pages/GovernmentUserEntry/services/GovernmentUserEntry.jsx';
import Login from '../../interfaces/pages/Login/services/Login.jsx';
import Message from '../../interfaces/pages/Message/services/Message.jsx';
import News from '../../interfaces/pages/News/services/News.jsx';
import Promotion from '../../interfaces/pages/Promotion/services/Promotion.jsx';
import ServiceProvider from '../../interfaces/pages/ServiceProvider/services/ServiceProvider.jsx';
import User from '../../interfaces/pages/User/services/User.jsx';
//import ArticleDefaultContainer from "../../interfaces/components/Auth/kmanager/ArticleDefaultContainer";
import { useLocalStorage } from '../../shared/hooks/useLocalStorage';

import 'interfaces/css/common/kmanager-main.css';

const appRouter = (type) => [
  { path: '/', element: <Dashboard type={type} /> },
  { path: '/category', element: <Category type={type} /> },
  {
    path: '/auction/:auctionId/:governmentId/payment',
    element: <GovernmentPayment type={type} />,
  },
  {
    path: '/auction/:auctionId/:governmentId/deposit',
    element: <GovernmentDeposit type={type} />,
  },
  {
    path: '/auction/:auctionId/:governmentId/property',
    element: <GovernmentProperties type={type} />,
  },
  { path: '/auction/:auctionId/entry', element: <AuctionEntry type={type} /> },
  {
    path: '/auction/:auctionId/gov',
    element: <AuctionGovernment type={type} />,
  },
  {
    path: '/auction/:auctionId/deposit',
    element: <AuctionDeposit type={type} />,
  },
  {
    path: '/auction/:auctionId/usage_fee',
    element: <AuctionUsageFee type={type} />,
  },
  { path: '/auction/edit/:auctionId', element: <AuctionEdit type={type} /> },
  { path: '/auction/edit/new_auction', element: <AuctionNew type={type} /> },
  {
    path: '/auction/:auctionId/gov_setting',
    element: <AuctionGovernmentSettings type={type} />,
  },
  { path: '/notice', element: <News type={type} /> },
  { path: '/message', element: <Message type={type} /> },
  { path: '/gov_list', element: <GovernmentList type={type} /> },
  {
    path: '/gov_list/:governmentId',
    element: <GovernmentDetail type={type} />,
  },
  {
    path: '/gov_list/:governmentId/user_entry/:procedureType',
    element: <GovernmentUserEntry type={type} />,
  },
  {
    path: '/gov_list/:governmentId/edit',
    element: <GovernmentNew type={type} />,
  },
  { path: '/gov_list/new', element: <GovernmentNew type={type} /> },
  { path: '/settings', element: <User type={type} /> },
  { path: '/banner', element: <Banner type={type} /> },
  { path: '/promotion', element: <Promotion type={type} /> },
  { path: '/service_provider', element: <ServiceProvider type={type} /> },
];

const KManagerRoutes = ({ type }) => {
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false);

  const { localStorageUserId } = useLocalStorage();

  const handlerLeftMenu = () => {
    setIsLeftMenuOpen((isLeftMenuOption) => !isLeftMenuOption);
  };

  return (
    <>
      <SnackBarProvider>
        <Routes>
          <>
            <Route key="0" path="/login" element={<Login type={type} />} />
          </>
          <>
            {appRouter(type).map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  localStorageUserId != null ? (
                    <span key={index + 1} className="kmanager">
                      {/* header, leftside menu, rightside menu */}
                      <CommonUI
                        handlerLeftMenu={handlerLeftMenu}
                        isLeftMenuOpen={isLeftMenuOpen}
                      />
                      {/* main area */}
                      <span
                        className={
                          isLeftMenuOpen
                            ? 'kmanager-main-area'
                            : 'kmanager-main-area not-active'
                        }>
                        {route.element}
                      </span>
                    </span>
                  ) : (
                    <Navigate to={`/kmanager/login`} />
                  )
                }
              />
            ))}
          </>
        </Routes>
      </SnackBarProvider>
    </>
  );
};

export default KManagerRoutes;
