// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sample-guideline .sample-after-winning-bid-lead-text {
  font-size: 15px;
}

.sample-guideline .sample-after-winning-bid .small-topic-title {
  font-size: 17px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/SampleGuideline/sample-after-winning-bid.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".sample-guideline .sample-after-winning-bid-lead-text {\n  font-size: 15px;\n}\n\n.sample-guideline .sample-after-winning-bid .small-topic-title {\n  font-size: 17px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
