// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-provisional-apply-completion-page-description{
    margin-top: 8px;
    margin-bottom: 16px;
}
.card-provisional-apply-completion-page-description ul{
    padding-left: 1em;
}
.card-provisional-apply-completion-page-description li {
    list-style: revert;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/CardProvisionalApplyCompletion/card-provisional-apply-completion.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".card-provisional-apply-completion-page-description{\n    margin-top: 8px;\n    margin-bottom: 16px;\n}\n.card-provisional-apply-completion-page-description ul{\n    padding-left: 1em;\n}\n.card-provisional-apply-completion-page-description li {\n    list-style: revert;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
