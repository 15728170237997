import { useContext, useState, useEffect } from 'react';

import HorizontalLinkGroup from './HorizontalLinkGroup.jsx';
import { AuctionFooterContext } from 'interfaces/pages/Settings/entities/AuctionFooterContext.js';

const HorizontalLinkGroupGrid = () => {
  const { realEstateLinkItems, carLinkItems, generalLinkItems } =
    useContext(AuctionFooterContext);

  const [realEstateItems, setRealEstateItems] = useState([]);
  const [carItems, setCarItems] = useState([]);
  const [generalItems, setGeneralItems] = useState([]);
  useEffect(() => {
    let id = 1;
    let linkItems = [];
    ['マンション', '土地', '一戸建て', 'その他'].forEach((label) => {
      linkItems.push({
        id: id,
        label: label,
        link: realEstateLinkItems[label],
      });
      id++;
    });
    setRealEstateItems(linkItems);
  }, [realEstateLinkItems]);

  useEffect(() => {
    let id = 1;
    let linkItems = [];
    ['救急車', '消防車', 'バス', 'その他'].forEach((label) => {
      linkItems.push({
        id: id,
        label: label,
        link: carLinkItems[label],
      });
      id++;
    });
    setCarItems(linkItems);
  }, [carLinkItems]);

  useEffect(() => {
    let id = 1;
    let linkItems = [];
    [
      'コンピュータ',
      '家電、AV、カメラ',
      '音楽',
      '本、雑誌',
      '映画、ビデオ',
    ].forEach((label) => {
      linkItems.push({
        id: id,
        label: label,
        link: generalLinkItems[label],
      });
      id++;
    });
    setGeneralItems(linkItems);
  }, [generalLinkItems]);

  const helpLinkItems = [
    {
      id: 1,
      label: '初めての方へ',
      link: 'https://www.pages.kankocho.jp/guide',
    },
    { id: 2, label: 'ヘルプ', link: 'https://help.kankocho.jp/knowledge' },
    {
      id: 3,
      label: 'よくある質問',
      link: 'https://help.kankocho.jp/knowledge/%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F',
    },
  ];

  return (
    <div
      data-testid="horizontal-link-group-grid"
      className="flex justify-center">
      <HorizontalLinkGroup groupTitle="不動産を探す" items={realEstateItems} />
      <HorizontalLinkGroup groupTitle="自動車を探す" items={carItems} />
      <HorizontalLinkGroup groupTitle="人気のカテゴリ" items={generalItems} />
      <HorizontalLinkGroup groupTitle="ヘルプ関連" items={helpLinkItems} />
    </div>
  );
};

export default HorizontalLinkGroupGrid;
