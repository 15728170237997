import { fetchGovernmentTopPages } from 'infrastructure/api/storage/government_top_pages';
import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Button from 'interfaces/components/common/Button/Button';
import Toggle from 'interfaces/components/common/Toggle/Toggle';
import { validateInputsLocalGovernmentManagementTopForm } from 'interfaces/components/common/validateInputs/ValidationLocalGovernmentManagementTop';
import LocalGovernmentManagementModal from 'interfaces/components/LocalGovernmentManagement/LocalGovernmentManagementModal';
import BothForm from 'interfaces/components/LocalGovernmentManagement/top/BothForm';
import IndividualForm from 'interfaces/components/LocalGovernmentManagement/top/IndividualForm';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import { breadcrumbs } from 'interfaces/pages/LocalGovernmentManagementTop/entities/breadcrumbs';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { convertEmptyCharacters } from 'shared/utils/helper/convertEmptyCharacters';
import PageCautionDescription from 'interfaces/components/common/PageCautionDescription';
import { textExtractionByProcedureType } from 'shared/utils/helper/TextExtraction';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

import 'interfaces/css/LocalGovernmentManagement/local-government-management-top.css';

const TopForm = ({ governmentId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { state } = useLocation();
  const { governmentType, procedureType } = state || {};
  const { localStorageProcedureType } = useLocalStorage();
  const navigate = useNavigate();
  const [indivisualSetting, setIndivisualSetting] = useState(false);
  const [auctionData, setAuctionData] = useState([]);
  const [bidData, setBidData] = useState([]);
  const auction = validateInputsLocalGovernmentManagementTopForm();
  const bid = validateInputsLocalGovernmentManagementTopForm();

  // const [isValid, setIsValid] = useState(true);
  // useEffect(() => {
  //   setIsValid(true);
  // });

  // TODO: 本番用：実装が終わったら戻す
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const validateMessages = (form) => {
      const { validationMessage, data } = form;
      return Object.entries(validationMessage).every(
        ([key, validationMessage]) => {
          if (
            [
              'contactEmailValid',
              'telValid',
              'extensionValid',
              'referenceAcceptValid',
              'inquiryNameHiddenValid',
              'homePageUrlHiddenValid',
              data?.displayPreview === 'DISPLAY' ? '' : 'previewUrlValid',
              data?.displayInquiry === 'DISPLAY' ? '' : 'inquiryNameValid',
              data?.displayHomePage === 'DISPLAY' ? '' : 'hpNameValid',
              data?.displayHomePage === 'DISPLAY' ? '' : 'homePageUrlValid',
            ].includes(key)
          ) {
            return validationMessage === null || validationMessage === 'TRUE';
          }
          return validationMessage === 'TRUE';
        },
      );
    };

    if (indivisualSetting) {
      setIsValid(validateMessages(auction) && validateMessages(bid));
    } else {
      setIsValid(validateMessages(auction));
    }
  }, [auction, bid, indivisualSetting]);
  useEffect(() => {
    const data = Object.keys(auctionData).reduce((result, key) => {
      result[key] = convertEmptyCharacters(auctionData[key]);
      return result;
    }, {});
    auction.init.setDisplayPreview(
      convertEmptyCharacters(data?.displayPreview) === ''
        ? 'DISPLAY'
        : data?.displayPreview,
    );
    auction.init.setPreviewUrl(data?.previewUrl);
    auction.init.setDisplayInquiry(
      convertEmptyCharacters(data?.displayInquiry) === ''
        ? 'DISPLAY'
        : data?.displayInquiry,
    );
    auction.init.setInquiryName(data?.inquiryName);
    auction.init.setInquiryEmail(data?.inquiryEmail);
    auction.init.setInquiryTel({
      telNum1: convertEmptyCharacters(data?.inquiryTel?.split('-')[0]),
      telNum2: convertEmptyCharacters(data?.inquiryTel?.split('-')[1]),
      telNum3: convertEmptyCharacters(data?.inquiryTel?.split('-')[2]),
    });
    auction.init.setInquiryExtension(data?.inquiryExtension);
    auction.init.setInquiryAccept(data?.inquiryAccept);
    auction.init.setDisplayHomePage(
      convertEmptyCharacters(data?.displayHomePage) === ''
        ? 'DISPLAY'
        : data?.displayHomePage,
    );
    auction.init.setHomePageName(data?.homePageName);
    auction.init.setHomePageUrl(data?.homePageUrl);
  }, [auctionData]);

  useEffect(() => {
    const data = Object.keys(bidData).reduce((result, key) => {
      result[key] = convertEmptyCharacters(bidData[key]);
      return result;
    }, {});
    bid.init.setDisplayPreview(
      convertEmptyCharacters(data?.displayPreview) === ''
        ? 'DISPLAY'
        : data?.displayPreview,
    );
    bid.init.setPreviewUrl(data?.previewUrl);
    bid.init.setDisplayInquiry(
      convertEmptyCharacters(data?.displayInquiry) === ''
        ? 'DISPLAY'
        : data?.displayInquiry,
    );
    bid.init.setInquiryName(data?.inquiryName);
    bid.init.setInquiryEmail(data?.inquiryEmail);
    bid.init.setInquiryTel({
      telNum1: convertEmptyCharacters(data?.inquiryTel?.split('-')[0]),
      telNum2: convertEmptyCharacters(data?.inquiryTel?.split('-')[1]),
      telNum3: convertEmptyCharacters(data?.inquiryTel?.split('-')[2]),
    });
    bid.init.setInquiryExtension(data?.inquiryExtension);
    bid.init.setInquiryAccept(data?.inquiryAccept);
    bid.init.setDisplayHomePage(
      convertEmptyCharacters(data?.displayHomePage) === ''
        ? 'DISPLAY'
        : data?.displayHomePage,
    );
    bid.init.setHomePageName(data?.homePageName);
    bid.init.setHomePageUrl(data?.homePageUrl);
  }, [bidData]);

  async function onRegisterClick(e) {
    e.preventDefault();
    //        const Configs = configs(governmentId, procedureType, auction, public, toggle);
    //        await allFetch(Configs.auction);
    //        await allFetch(Configs.public);

    setIsOpen(true);
  }

  const backToLocalGovernmentManagement = () => {
    navigate('/navi/local-government-management');
  };

  useEffect(() => {
    if (governmentId !== undefined) {
      const fetch = async () => {
        const data = await fetchGovernmentTopPages(
          governmentId,
          indivisualSetting,
        );
        setAuctionData(
          data?.find((object) => object?.publicSaleType === 'AUCTION'),
        );
        setBidData(data?.find((object) => object?.publicSaleType === 'BID'));
      };
      fetch().then();
    }
  }, [indivisualSetting, governmentId]);

  return (
    <span>
      {isOpen ? (
        <LocalGovernmentManagementModal
          handleClose={() => setIsOpen(false)}
          procedureType={procedureType}
        />
      ) : (
        <></>
      )}
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <PropertyInfoGeneralPageDescription
        title={'行政機関ページ管理：行政機関トップページ'}
        content={
          '行政機関トップページに関する項目を作成します。入力が終わったら「登録」ボタンをクリックしてください。'
        }
        displayRequired={true}
      />
      <PageCautionDescription
        content={`※${textExtractionByProcedureType(localStorageProcedureType)}の参加申込開始日以降は掲載終了まで修正ができません。`}
      />
      <div
        className="new-style_navi_local-government-management-top-form"
        data-testid="local-government-management-top-form">
        {governmentType !== 'NATIONAL_TAX_AGENCY' && (
          <>
            <Toggle
              message={'「せり売形式」と「入札形式」で個別に情報を登録する'}
              handler={() => setIndivisualSetting(!indivisualSetting)}
            />
            <div className="sub-message">
              ※
              KSI官公庁オークションでは、行政機関トップページは「せり売形式」と「入札形式」の物件をまとめたページに統合されています。
              <br />
              閲覧者が表示する形式を切り替えた際の表示内容を個別に設定したい場合にご利用ください。
              <br />
            </div>
          </>
        )}
        {!indivisualSetting && <BothForm auctionData={auction} />}
        {indivisualSetting && (
          <IndividualForm auctionData={auction} bidData={bid} />
        )}
        <div className="pt-9">
          <div className="property-info-general-form-button-box ">
            <div className="pr-1 pl-1">
              <Button
                text={'登録'}
                isOutline={false}
                height={'44px'}
                className={`property-info-general-form-button ${isValid ? 'new-style_navi_primary-button' : 'new-style_navi_disabled-button'}`}
                onClick={onRegisterClick}
                isDisabled={!isValid}
              />
            </div>
            <div className="pr-1 pl-1">
              <Button
                text={'キャンセル'}
                isOutline={false}
                height={'44px'}
                className={
                  'property-info-general-form-button common-button-bg-whitesmoke new-style_navi_normal-button'
                }
                onClick={backToLocalGovernmentManagement}
              />
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};
export default TopForm;
