import Modal2 from 'interfaces/components/common/Modal/Modal2';
import { useState } from 'react';
import { useError } from 'shared/hooks/useError';
import useTextCounter from 'shared/hooks/useTextCounter';

import EmailTemplateConfirm from './EmailTemplateConfirm';
import EmailTemplateEdit from './EmailTemplateEdit';
import Button from '../common/Button/Button';
import Heading2 from '../common/Heading/Heading2';

import 'interfaces/css/AutomaticEmailManagement/email-template-modal.css';

const EmailTemplateModal = ({
  isOpen,
  title,
  closeModal,
  description,
  maxTextLimit,
  emailTemplateText = '',
  mailTemplateText = '',
}) => {
  const { text, count, isOverCount, isWithinLimit, changeHandler } =
    useTextCounter(maxTextLimit);
  const { setError } = useError();

  const submitHandler = (e) => {
    e.preventDefault();

    if (!isWithinLimit(text, maxTextLimit)) {
      return;
    }

    setModalType('COMPLETE');
    try {
      // TODO: CreateのAPIを叩く
      console.log('ok');
    } catch (e) {
      setError(e);
    }
  };

  const [modalType, setModalType] = useState('EDIT');
  return (
    <Modal2
      isOpen={isOpen}
      handleClose={closeModal}
      modalSize="M"
      modalHeight="90%"
      padding={false}
      className={modalType === 'COMPLETE' ? 'no-bg' : ''}
      closeClickOutside={true}>
      {modalType === 'COMPLETE' ? (
        <>
          <div className="text-center no-bg" style={{ color: 'white' }}>
            <h3 className="font-weight-bold">登録が完了しました</h3>
            <Button
              className="property-info-general-form-button new-style_navi_primary-button"
              text="戻る"
              onClick={closeModal}
            />
          </div>
        </>
      ) : (
        <>
          <Heading2 title={title} />
          <div className="email-template-body">
            <p
              className="email-template-modal-description"
              data-testid="email-template-modal-description">
              {description}
            </p>
            {modalType === 'EDIT' && (
              <EmailTemplateEdit
                emailTemplateText={emailTemplateText}
                text={text}
                initText={mailTemplateText}
                changeHandler={changeHandler}
                count={count}
                isOverCount={isOverCount}
                closeModal={closeModal}
                navigateToConfirm={() => setModalType('CONFIRM')}
              />
            )}
            {modalType === 'CONFIRM' && (
              <EmailTemplateConfirm
                text={text || mailTemplateText}
                closeModal={closeModal}
                navigateToEdit={() => setModalType('EDIT')}
                submitHandler={submitHandler}
              />
            )}
          </div>
        </>
      )}
    </Modal2>
  );
};
export default EmailTemplateModal;
// import configs from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/configs/CAMEL_TABLE_NAME";
// import { allFetch } from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/newEntry";
//
// const create = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.create);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const update = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.update);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const destroy = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.destroy);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }

// import configs from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/configs/CAMEL_TABLE_NAME";
// import { allFetch } from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/newEntry";
//
// const create = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.create);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const update = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.update);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const destroy = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.destroy);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
