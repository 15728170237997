import "interfaces/css/PropertyList/property-list-search-input.css"

const PropertyListSearchInput = ({label, style, inputRef = null, handler = () => {}}) => {
  function onInputChange(event) {
    handler(event);
  }

  return (
    <div style={style}>
      <span className='property-list'>
        <span className="property-list-search-input">
          <input
            ref={inputRef}
            className='text-field'
            data-testid="property-list-search-input"
            placeholder={label}
            onChange={(e) => onInputChange(e.target.value)}
          />
        </span>
      </span>
    </div>
  );
}
export default PropertyListSearchInput