import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class CostContractDescription2 {
  #costContractDescription2

  constructor(costContractDescription2) {
    this.#costContractDescription2 = costContractDescription2;
  }

  get() {
    return this.#costContractDescription2
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#costContractDescription2, 0, 500)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#costContractDescription2)
  }
}
