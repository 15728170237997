// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.after-winning-bid-text {
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: .0071428571em;
  color: rgba(0, 0, 0, .6);
  font-family: "Noto Sans JP", -apple-system, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", meiryo, sans-serif;
}

.after-winning-bid-text caption {
  white-space: nowrap;
  text-align: left;
  border-bottom: 1px solid #e8e6e6;
  font-weight: 700;
  font-size: .9rem;
  padding-bottom: 4px;
  color: rgba(0, 0, 0, .6);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/SampleAfterWinningBid/after-winning-bid.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;EACrB,6BAA6B;EAC7B,wBAAwB;EACxB,mHAAmH;AACrH;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,gCAAgC;EAChC,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,wBAAwB;AAC1B","sourcesContent":[".after-winning-bid-text {\n  font-size: .875rem;\n  font-weight: 400;\n  line-height: 1.375rem;\n  letter-spacing: .0071428571em;\n  color: rgba(0, 0, 0, .6);\n  font-family: \"Noto Sans JP\", -apple-system, \"ヒラギノ角ゴ Pro W3\", \"Hiragino Kaku Gothic Pro\", \"メイリオ\", meiryo, sans-serif;\n}\n\n.after-winning-bid-text caption {\n  white-space: nowrap;\n  text-align: left;\n  border-bottom: 1px solid #e8e6e6;\n  font-weight: 700;\n  font-size: .9rem;\n  padding-bottom: 4px;\n  color: rgba(0, 0, 0, .6);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
