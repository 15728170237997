import { createContext } from 'react';

export const AuthContext = createContext({
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  userId: null,
  procedureType: null,
  governmentType: null,
  userType: null,
  governmentId:null
});
