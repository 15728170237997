// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-margin-wrap {
  max-width: 1200px;
  width: 90%;
}

@media (max-width: 959px) {
  .side-margin-wrap {
    max-width: 100%;
    width: 100%;
    padding: 0 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/side-margin-wrap.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE;IACE,eAAe;IACf,WAAW;IACX,eAAe;EACjB;AACF","sourcesContent":[".side-margin-wrap {\n  max-width: 1200px;\n  width: 90%;\n}\n\n@media (max-width: 959px) {\n  .side-margin-wrap {\n    max-width: 100%;\n    width: 100%;\n    padding: 0 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
