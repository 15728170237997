// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import { AuctionId } from './Bidders/auctionId';
import { AuctionUserId } from './Bidders/auctionUserId';
import { GovernmentId } from './Bidders/governmentId';
import { Id } from './Bidders/id';
import { PropertyId } from './Bidders/propertyId';
import { PublicSaleType } from './Bidders/publicSaleType';
import { Price } from './BiddingDialog/price';
import CustomError from '../../../shared/utils/helper/customError.js';
import { NotUndefined } from '../../../shared/utils/helper/NotUndefined.js';

export class BidGet {
  constructor(data) {
    this.propertyId = data.propertyId;
    this.auctionUserId = data.auctionUserId;
    this.price = data.price;
    this.time = data.time;
    this.isNextBuy = data.isNextBuy;
    //if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class BidInfoGet {
  constructor(data) {
    this.id = data.id;
    this.data = new BidInfoDataGet(data.data);
    if (this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class BidInfoDataGet {
  constructor(data) {
    this.bidCount = data.bidCount;
    this.bidCountDisplay = data.bidCountDisplay;
    this.bidHistory = data.bidHistory;
    this.biddedPrice = data.biddedPrice;
    this.biddedUser = data.biddedUser;
    this.currentTopPrice = data.currentTopPrice;
    this.currentTopUser = data.currentTopUser;
    this.nextWinner = data.nextWinner;
    this.nextWinnerPrice = data.nextWinnerPrice;
    if (this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class BidderHistoryGet {
  constructor(data) {
    this.auctionUserId = data.auctionUserId;
    this.bidInfo = data.bidInfo;
    this.bidStatus = data.bidStatus;
    this.isNextBuy = data.isNextBuy;
    this.price = data.price;
    this.propertyId = data.propertyId;
    this.time = data.time;
    //if (!this.isValid) throw new CustomError("BidderHistory の値が不正です")
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class BidCreate {
  constructor(data) {
    this.propertyId = new PropertyId(data.propertyId);
    this.auctionUserId = new AuctionUserId(data.auctionUserId);
    this.price = new Price(data.price);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.propertyId.isValid &&
      this.auctionUserId.isValid &&
      this.price.isValid
    );
  }
}

export class BidInfoCreate {
  constructor(data) {
    this.id = data.id;
    this.data = new BidInfoDataCreate(data.data);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return this.id.isValid && this.data.isValid;
  }
}

export class BidInfoDataCreate {
  constructor(data) {
    this.bidCount = data.bidCount;
    this.bidCountDisplay = data.bidCountDisplay;
    this.bidHistory = data.bidHistory;
    this.biddedPrice = data.biddedPrice;
    this.biddedUser = data.biddedUser;
    this.currentTopPrice = data.currentTopPrice;
    this.currentTopUser = data.currentTopUser;
    this.nextWinner = data.nextWinner;
    this.nextWinnerPrice = data.nextWinnerPrice;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.bidCount.isValid &&
      this.bidCountDisplay.isValid &&
      this.bidHistory.isValid &&
      this.biddedPrice.isValid &&
      this.biddedUser.isValid &&
      this.currentTopPrice.isValid &&
      this.currentTopUser.isValid &&
      this.nextWinner.isValid &&
      this.nextWinnerPrice.isValid
    );
  }
}

export class OpenBid {
  constructor(data) {
    this.id = new Id(data.id);
    this.auctionId = new AuctionId(data.auctionId);
    this.governmentId = new GovernmentId(data.governmentId);
  }
}

export class CloseBid {
  constructor(data) {
    this.auctionId = new AuctionId(data.auctionId);
    this.publicSaleType = new PublicSaleType(data.publicSaleType);
  }
}

export class CallUp {
  constructor(data) {
    this.auctionId = new AuctionId(data.auctionId);
    this.publicSaleType = new PublicSaleType(data.publicSaleType);
  }
}
