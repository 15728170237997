import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
//import {DATA_EMPTY} from "../../constants/property.js";

const SelectedValue = ({ displayType, getQueryParam, convertTable }) => {
  return (
    <div data-testid="selected-value" className="selected-value-wrap">
      {displayType === 'normal' && (
        <Normal getQueryParam={getQueryParam} convertTable={convertTable} />
      )}
      {displayType === 'commaSplit' && (
        <CommaSplit getQueryParam={getQueryParam} convertTable={convertTable} />
      )}
      {displayType === 'between' && (
        <Between getQueryParam={getQueryParam} convertTable={convertTable} />
      )}
    </div>
  );
};

const Normal = ({ getQueryParam, convertTable }) => {
  const [label, setLabel] = useState('');
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const queryParam = searchParams.get(getQueryParam) ?? '';
    const obj = { ...convertTable };
    const keyName = Object.keys(obj).find((key) => obj[key] === queryParam);
    setLabel(keyName);
  }, [searchParams]);
  return <div className="selected-value">{label}</div>;
};

const CommaSplit = ({ getQueryParam, convertTable }) => {
  const [label, setLabel] = useState('');
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const queryParam = searchParams.getAll(getQueryParam) ?? '';
    const obj = { ...convertTable };
    let result = [];
    queryParam.forEach((param) => {
      result.push(Object.keys(obj).find((key) => obj[key] === param));
    });
    setLabel(result.join(','));
  }, [searchParams]);

  return <div className="selected-value">{label}</div>;
};

const Between = ({ getQueryParam, convertTable }) => {
  const [label, setLabel] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let queryParam = [];
    getQueryParam?.forEach((param) => {
      queryParam.push(searchParams.get(param) ?? '');
    });
    const obj = { ...convertTable };
    let result = [];
    queryParam.forEach((param) => {
      result.push(
        Object.keys(obj).find((key) => obj[key].toString() === param),
      );
    });
    setLabel(`${result[0]}〜${result[1]}`);
  }, [searchParams]);

  return <div className="selected-value">{label}</div>;
};

export default SelectedValue;
