import Heading3 from 'interfaces/components/common/Heading/Heading3';
import {
  organization_register_description1,
  organization_register_description2,
  organization_register_description3,
  organization_register_description4,
  organization_register_description5,
  organization_register_description6,
  organization_register_description7,
} from 'interfaces/components/Division/DummyOrganizationRegisterForm';
import FormPostalWithCaution from 'interfaces/components/Form/navi/FormPostalWithCaution';
import FormPullDownWithCaution from 'interfaces/components/Form/navi/FormPullDownWithCaution';
import FormRadioWithCaution from 'interfaces/components/Form/navi/FormRadioWithCaution';
import FormTelWithCaution from 'interfaces/components/Form/navi/FormTelWithCaution';
import FormTextWithCaution from 'interfaces/components/Form/navi/FormTextWithCaution';
import {
  caution_description11,
  caution_description12,
  caution_description_char_30,
  caution_description_char_50,
  caution_description_half_number,
} from 'shared/utils/helper/DummyEditFormData';

import {
  pulldown_account_types,
  pulldown_prefecture,
} from '../../../../../shared/utils/helper/Pulldown';

export const local_government_management_radio1 = [
  { id: 1, text: '利用する', value: 'YES' },
  { id: 2, text: '利用しない', value: 'NO' },
];

const OrganizationForm = ({
  procedureType,
  governmentType,
  formData: formData,
}) => {
  return (
    <form
      className="new-style_navi_organization-register-form"
      data-testid="organization-register-form">
      <div className="property-info-general-form-table">
        <FormTextWithCaution
          label={'執行機関名'}
          maxCharacters={50}
          caution_description={organization_register_description1}
          required={true}
          handler1={formData.setters.handleDivisionName}
          data={formData.data?.divisionName}
          invalidMessage={formData.validationMessage.divisionName}
        />
        <FormTextWithCaution
          label={'執行機関名（カナ）'}
          maxCharacters={50}
          caution_description={organization_register_description2}
          required={true}
          handler1={formData.setters.handleDivisionNameKana}
          data={formData.data?.divisionNameKana}
          invalidMessage={formData.validationMessage.divisionNameKana}
        />
        <FormTextWithCaution
          label={'担当者名'}
          maxCharacters={30}
          caution_description={caution_description_char_30}
          required={true}
          handler1={formData.setters.handleContactName}
          data={formData.data?.contactName}
          invalidMessage={formData.validationMessage.contactName}
        />
        <FormTelWithCaution
          label={'電話番号'}
          required={true}
          caution_description={caution_description12}
          telData={formData.data?.contactTel}
          setTelData={(tel) => formData.setters.handleContactTel(tel)}
          setTel1Data={(tel) => formData.setters?.handleChangeTelNum1(tel)}
          setTel2Data={(tel) => formData.setters?.handleChangeTelNum2(tel)}
          setTel3Data={(tel) => formData.setters?.handleChangeTelNum3(tel)}
          extensionData={formData.data?.contactExtension}
          setExtensionData={formData.setters.handleContactExtension}
          hasExtension={true}
          invalidMessage={formData.validationMessage.contactTel}
          invalid1Message={formData.validationMessage.telNum1}
          invalid2Message={formData.validationMessage.telNum2}
          invalid3Message={formData.validationMessage.telNum3}
          extensionMessage={formData.validationMessage.contactExtension}
        />
        <FormTextWithCaution
          label={'担当者メールアドレス'}
          caution_description={caution_description11}
          data={formData.data?.contactEmail}
          handler1={formData.setters.handleContactEmail}
          invalidMessage={formData.validationMessage.contactEmail}
        />
        <FormRadioWithCaution
          label={'保証金の納付にクレジットカードを利用する'}
          radio_label={local_government_management_radio1}
          data={formData.data.useCreditCard}
          handler1={formData.setters.handleUseCreditCard}
        />
      </div>

      {formData.data?.useCreditCard.toString() === 'NO' ? (
        ''
      ) : (
        <>
          <div
            className="pt-9"
            data-testid="organization-register-form-deposit-payment-account">
            <Heading3 text="1. 保証金納付口座" />
            <div className="property-info-general-form-table">
              <FormTextWithCaution
                label={'金融機関名'}
                maxCharacters={50}
                caution_description={caution_description_char_50}
                required={true}
                handler1={formData.setters.handleFinancialInstitutionName}
                data={formData.data?.financialInstitutionName}
                invalidMessage={
                  formData.validationMessage.financialInstitutionName
                }
              />
              <FormTextWithCaution
                label={'支店名'}
                maxCharacters={50}
                caution_description={caution_description_char_50}
                required={true}
                handler1={formData.setters?.handleBranchName}
                data={formData.data?.branchName}
                invalidMessage={formData.validationMessage.branchName}
              />
              <FormPullDownWithCaution
                label={'口座種別'}
                listItemData={pulldown_account_types}
                selectedData={pulldown_account_types.find(
                  (item) => item.value === formData.data.accountType,
                )}
                formStateHandler={formData.setters.handleAccountType}
                width={'20%'}
              />
              <FormTextWithCaution
                label={'口座番号'}
                caution_description={caution_description_half_number}
                required={true}
                handler1={formData.setters.handleAccountNumber}
                data={formData.data?.accountNumber}
                invalidMessage={formData.validationMessage.accountNumber}
              />
              <FormTextWithCaution
                label={'口座名義'}
                maxCharacters={50}
                caution_description={caution_description_char_50}
                required={true}
                handler1={formData.setters.handleAccountName}
                data={formData.data?.accountName}
                invalidMessage={formData.validationMessage.accountName}
              />
              <FormTextWithCaution
                label={'口座名義（カナ）'}
                maxCharacters={50}
                caution_description={organization_register_description2}
                required={true}
                handler1={formData.setters.handleAccountNameKana}
                data={formData.data?.accountNameKana}
                invalidMessage={formData.validationMessage.accountNameKana}
              />
            </div>
          </div>

          {procedureType === 'PUBLIC_SALE' &&
          governmentType !== 'NATIONAL_TAX_AGENCY' ? (
            <div
              className="pt-9"
              data-testid="organization-register-form-billing-address">
              <Heading3 text="2. 返還請求書送付先" />
              <div>
                ※
                KSIより貴庁に不要となった保証金の返還請求書を郵送させていただくための送付先を設定してください。
              </div>
              <div className="property-info-general-form-table">
                <FormPostalWithCaution
                  postData={formData.data.postalCode}
                  postData1={formData.data.postalCode.postalCode1}
                  postData2={formData.data.postalCode.postalCode2}
                  setPostData={(e) => formData.setters.handleSetPostalCode(e)}
                  handlerPost1={(e) => formData.setters.handleSetPostalCode1(e)}
                  handlerPost2={(e) => formData.setters.handleSetPostalCode2(e)}
                  caution_description={caution_description12}
                  required={true}
                  invalidMessage={formData.validationMessage.postalCode}
                  invalid1Message={formData.validationMessage.postalCode1}
                  invalid2Message={formData.validationMessage.postalCode2}
                />
                <FormPullDownWithCaution
                  label={'都道府県'}
                  listItemData={pulldown_prefecture}
                  selectedData={pulldown_prefecture.find(
                    (item) =>
                      item.value.toString() === formData.data.prefectureId,
                  )}
                  formStateHandler={formData.setters.handlePrefectureId}
                  width={'50%'}
                />
                <FormTextWithCaution
                  label={'市区町村'}
                  maxCharacters={50}
                  caution_description={organization_register_description3}
                  required={true}
                  handler1={formData.setters.handleCity}
                  data={formData.data?.city}
                  invalidMessage={formData.validationMessage.city}
                />
                <FormTextWithCaution
                  label={'住所（続き）'}
                  maxCharacters={50}
                  caution_description={organization_register_description4}
                  required={true}
                  handler1={formData.setters.handleAddress}
                  data={formData.data?.address}
                  invalidMessage={formData.validationMessage.address}
                />
                <FormTextWithCaution
                  label={'建物名'}
                  maxCharacters={50}
                  caution_description={organization_register_description5}
                  handler1={formData.setters.handleBuildName}
                  data={formData.data?.buildName}
                  invalidMessage={formData.validationMessage.buildName}
                />
                <FormTextWithCaution
                  label={'あて先'}
                  maxCharacters={50}
                  caution_description={organization_register_description6}
                  required={true}
                  handler1={formData.setters.handleDestination}
                  data={formData.data?.destination}
                  invalidMessage={formData.validationMessage.destination}
                />
                <FormTextWithCaution
                  label={'書面あて名'}
                  maxCharacters={50}
                  caution_description={organization_register_description7}
                  required={true}
                  handler1={formData.setters.handleWrittenDestination}
                  data={formData.data?.writtenDestination}
                  invalidMessage={formData.validationMessage.writtenDestination}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </form>
  );
};

export default OrganizationForm;
