// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yearly-schedules .annual-schedule-monthly-schedule {
  margin-bottom: 24px;
}

.yearly-schedules .annual-schedule-month-name {
  width: 40px;
  writing-mode: vertical-rl;
  text-orientation: upright;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--schedule-prefix);
  margin-right: 16px
}

.yearly-schedules .annual-schedule-auction-schedule-wrap {
  width: calc(100% - 56px); /* month-nameのwidthとmargin分のpxを引く */
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/YearlySchedules/monthly-schedule.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,wCAAwC;EACxC;AACF;;AAEA;EACE,wBAAwB,EAAE,mCAAmC;AAC/D","sourcesContent":[".yearly-schedules .annual-schedule-monthly-schedule {\n  margin-bottom: 24px;\n}\n\n.yearly-schedules .annual-schedule-month-name {\n  width: 40px;\n  writing-mode: vertical-rl;\n  text-orientation: upright;\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: var(--schedule-prefix);\n  margin-right: 16px\n}\n\n.yearly-schedules .annual-schedule-auction-schedule-wrap {\n  width: calc(100% - 56px); /* month-nameのwidthとmargin分のpxを引く */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
