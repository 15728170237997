import CardProvisionalApplyCompletionForm from "interfaces/components/common/CardProvisionalApplyCompletionForm";
import { useCardProvisionalInputs } from "shared/utils/helper/useCardProvisionalInputs";

export const validateInputsCardProvisionalApplyCompletion = () => {
  const formInputs = useCardProvisionalInputs()
  const validate = CardProvisionalApplyCompletionForm()

  function handleAuctionDocumentSubmissionUrl(e, required) {
    validate.setters.handleAuctionDocumentSubmissionUrlValid(e, required)
    formInputs.setters.setAuctionDocumentSubmissionUrl(e.target.value)
  }

  function handleBidDocumentSubmissionUrl(e, required) {
    validate.setters.handleBidDocumentSubmissionUrlValid(e, required)
    formInputs.setters.setBidDocumentSubmissionUrl(e.target.value)
  }

  function handleBidJointBiddingUrl(e) {
    validate.setters.handleBidJointBiddingUrlValid(e)
    formInputs.setters.setBidJointBiddingUrl(e.target.value)
  }


  const validateInputs = {
    data: formInputs.data,
    setters: {
      handleAuctionDocumentSubmissionUrl,
      handleBidDocumentSubmissionUrl,
      handleBidJointBiddingUrl,
    },
    init: {
      setAuctionDocumentSubmissionUrl: formInputs.setters.setAuctionDocumentSubmissionUrl,
      setBidDocumentSubmissionUrl: formInputs.setters.setBidDocumentSubmissionUrl,
      setBidJointBiddingUrl: formInputs.setters.setBidJointBiddingUrl,
    },
    validationMessage: validate.validationMessage,
  }

  return validateInputs
}