// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-float-modal-overlay{
    z-index: 100;
    position: relative;
}
@media (min-width: 720px) {
    .item-float-modal{
        width: 220px;
        bottom: 10%;
        position: fixed;
        right: 0;
        height: auto;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        box-shadow: 0 3px 3px -2px rgba(0,0,0,.2),0 3px 4px 0 rgba(0,0,0,.14),0 1px 8px 0 rgba(0,0,0,.12);
    }
}
@media (max-width: 719px) {
    .item-float-modal {
        background-color: #fff;
        width: 100%;
        height: auto;
        position: fixed;
        bottom: 0;
        right: 0;
        padding: 16px;
        box-shadow: 0 3px 3px -2px rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12);
    }
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/ItemFloatModal.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI;QACI,YAAY;QACZ,WAAW;QACX,eAAe;QACf,QAAQ;QACR,YAAY;QACZ,2BAA2B;QAC3B,8BAA8B;QAC9B,iGAAiG;IACrG;AACJ;AACA;IACI;QACI,sBAAsB;QACtB,WAAW;QACX,YAAY;QACZ,eAAe;QACf,SAAS;QACT,QAAQ;QACR,aAAa;QACb,mGAAmG;IACvG;AACJ","sourcesContent":[".item-float-modal-overlay{\n    z-index: 100;\n    position: relative;\n}\n@media (min-width: 720px) {\n    .item-float-modal{\n        width: 220px;\n        bottom: 10%;\n        position: fixed;\n        right: 0;\n        height: auto;\n        border-top-left-radius: 8px;\n        border-bottom-left-radius: 8px;\n        box-shadow: 0 3px 3px -2px rgba(0,0,0,.2),0 3px 4px 0 rgba(0,0,0,.14),0 1px 8px 0 rgba(0,0,0,.12);\n    }\n}\n@media (max-width: 719px) {\n    .item-float-modal {\n        background-color: #fff;\n        width: 100%;\n        height: auto;\n        position: fixed;\n        bottom: 0;\n        right: 0;\n        padding: 16px;\n        box-shadow: 0 3px 3px -2px rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
