import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class TransRightCarDisplay {
  #transRightCarDisplay

  constructor(transRightCarDisplay) {
    this.#transRightCarDisplay = transRightCarDisplay;
  }

  get() {
    return this.#transRightCarDisplay
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#transRightCarDisplay, 1, 1000)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#transRightCarDisplay)
  }
}
