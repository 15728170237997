import { useCategoryInputs } from "shared/hooks/useCategoryInputs.jsx";

import { Category } from "../Category.jsx";

export const validateInputsCategory = () => {
  const formInputs = useCategoryInputs();
  const validate = Category();

  function handleParentId(e) {
    formInputs.setters.parentId(e.target.value)
  }

  function handleName(e) {
    validate.setters.name(e)
    formInputs.setters.name(e.target.value)
    console.log(validate.validationMessage.name)
  }

  function handleDepth(depth) {
    formInputs.setters.depth(depth)
    return depth
  }

  function handleDisplayService(e) {
    const nextValue = e.target.checked ? 'DISPLAY' : 'HIDDEN'
    formInputs.setters.displayService(nextValue)
    return nextValue
  }

  function handleDisplayProperty(e) {
    const nextValue = e.target.checked ? 'DISPLAY' : 'HIDDEN'
    formInputs.setters.displayProperty(nextValue)
    return nextValue
  }

  function handleDisplayEstate(e) {
    const nextValue = e.target.checked ? 'DISPLAY' : 'HIDDEN'
    formInputs.setters.displayEstate(nextValue)
    return nextValue
  }

  function handleDisplayCar(e) {
    const nextValue = e.target.checked ? 'DISPLAY' : 'HIDDEN'
    formInputs.setters.displayCar(nextValue)
    return nextValue
  }

  const validateInputs = [
    {
      type: formInputs.type.parentId,
      column: 'parentId',
      text: '親の物件カテゴリーid',
      validationMessage: '',
      state: formInputs.data.parentId,
      init: (value) => formInputs.setters.parentId(value),
      setters: handleParentId
    },
    {
      type: formInputs.type.name,
      column: 'name',
      text: '物件カテゴリー名',
      validationMessage: validate.validationMessage.name,
      state: formInputs.data.name,
      init: (value) => formInputs.setters.name(value),
      setters: handleName
    },
    {
      type: formInputs.type.depth,
      column: 'depth',
      text: '階層',
      validationMessage: '',
      state: formInputs.data.depth,
      init: (value) => formInputs.setters.depth(value),
      setters: handleDepth
    },
    {
      type: formInputs.type.displayService,
      column: 'displayService',
      text: 'サイトへの表示',
      validationMessage: '',
      state: formInputs.data.displayService,
      init: (value) => formInputs.setters.displayService(value),
      setters: handleDisplayService
    },
    {
      type: formInputs.type.displayProperty,
      column: 'displayProperty',
      text: '動産',
      validationMessage: '',
      state: formInputs.data.displayProperty,
      init: (value) => formInputs.setters.displayProperty(value),
      setters: handleDisplayProperty
    },
    {
      type: formInputs.type.displayEstate,
      column: 'displayEstate',
      text: '不動産',
      validationMessage: '',
      state: formInputs.data.displayEstate,
      init: (value) => formInputs.setters.displayEstate(value),
      setters: handleDisplayEstate
    },
    {
      type: formInputs.type.displayCar,
      column: 'displayCar',
      text: '自動車',
      validationMessage: '',
      state: formInputs.data.displayCar,
      init: (value) => formInputs.setters.displayCar(value),
      setters: handleDisplayCar
    },
  ];

  return validateInputs;
}