import CheckBox from 'interfaces/components/common/CheckBox/CheckBox';
import { useEffect, useState } from 'react';
import { convertEmptyCharacters } from 'shared/utils/helper/convert';

import Label from '../../../Form/navi/Label';
import ValidationMessage from 'interfaces/components/Form/navi/ValidationMessage';

import 'interfaces/css/common/property-info-car-form-reg-number.css';

import styled from 'styled-components';

const TextInput = styled.input`
  font-size: 16px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  line-height: 20px;
  #padding: 8px 0;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border: ${(props) =>
    props.$validation
      ? '2px solid #ff5252 !important'
      : '1px solid rgba(0, 0, 0, .42)'};
  border-radius: 4px;
  padding: 8px 12px;
`;

const FormRegNumber = ({
  displayRegNumberData,
  regNumberData,
  handlerDisplayRegNumberData,
  handlerRegNumberData,
  handlerRegNumberFirst,
  handlerRegNumberLatter,
  invalidMessage,
  label,
}) => {
  const [checkboxStates, setCheckBoxStates] = useState(false);

  useEffect(() => {
    if (displayRegNumberData === 'DISPLAY') {
      setCheckBoxStates(true);
    }
  }, [displayRegNumberData]);

  const regNumberHandler = (updateParams) => {
    let hashData = regNumberData;
    hashData = { ...hashData, ...updateParams };
    handlerRegNumberData(hashData);
  };

  const regNumberFirstHandler = (e) => {
    handlerRegNumberFirst(e.target.value);
    if (e.target.value !== '' && regNumberData.latter !== '') {
      let updateParams = { first: e.target.value };
      regNumberHandler(updateParams);
    }
  };
  const regNumberLatterHandler = (e) => {
    handlerRegNumberLatter(e.target.value);
    if (regNumberData.first !== '' && e.target.value !== '') {
      let updateParams = { latter: e.target.value };
      regNumberHandler(updateParams);
    }
  };

  const handleCheckboxChange = (e) => {
    e.preventDefault();
    let updateValue = 'DISPLAY';
    if (checkboxStates === true) {
      updateValue = 'HIDDEN';
    }
    handlerDisplayRegNumberData(updateValue);
    setCheckBoxStates(!checkboxStates);
  };

  return (
    <div className="property-info-general-form-table-row">
      <Label label={label} required={true} />
      <div className="property-info-general-form-table-cell">
        <div className="flex new-style_navi_form-reg-number">
          <TextInput
            type={'text'}
            name={'first'}
            value={convertEmptyCharacters(regNumberData?.first)}
            onChange={regNumberFirstHandler}
            placeholder={'品川500わ'}
          />
          <TextInput
            className="ml-4"
            type={'text'}
            name={'latter'}
            value={convertEmptyCharacters(regNumberData?.latter)}
            onChange={regNumberLatterHandler}
            placeholder={'1234'}
          />
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
        <div className="property-info-car-form-reg-number-checkbox flex new-style_navi_form-reg-number-checkbox">
          <CheckBox
            isChecked={checkboxStates}
            onClick={(e) => handleCheckboxChange(e)}
          />
          <label>
            閲覧者に対し自動車登録番号の下4桁を＊＊＊＊と隠して表示する
          </label>
        </div>
      </div>
    </div>
  );
};

export default FormRegNumber;
