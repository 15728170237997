import {useContext} from 'react';

import AnnualSchedule from './AnnualSchedule.jsx';
import {YearlySchedulesContext} from "../../../pages/YearlySchedules/entities/YearlySchedulesContext";
//import {useError} from 'shared/utils/hooks/useError.js';
//import getYearlySchedules from "../../read_storage/yearly_schedules.js";

const AllSchedule = () => {
  const { yearlySchedules } = useContext(YearlySchedulesContext);
  //const [yearlySchedules, setYearlySchedules] = useState({
  //  thisYear: {
  //    year: null,
  //    schedule: {
  //      monthly: null
  //    }
  //  },
  //  nextYear: {
  //    year: null,
  //    schedule: {
  //      monthly: null
  //    }
  //  }
  //});
  //const { setError } = useError();

  //useEffect(() => {
  //  const yearlySchedulesData = async () => {
  //    try {
  //      const yearlySchedules = await getYearlySchedules();
  //      setYearlySchedules(yearlySchedules);
  //    } catch (err) {
  //      setError(err);
  //    }
  //  };
  //  yearlySchedulesData().then();
  //}, []);

  return (
    <div data-testid="all-schedule">
      <div data-testid="annual-schedule">
        <AnnualSchedule year={yearlySchedules.auctions?.year} data={yearlySchedules.thisYear?.auctions.monthly}/>
      </div>
      <div data-testid="annual-schedule">
        <AnnualSchedule year={yearlySchedules.auctions?.year} data={yearlySchedules.nextYear?.auctions.monthly}/>
      </div>
    </div>
  );
};

export default AllSchedule;
