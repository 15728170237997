import { useEffect, useState } from 'react';
import { useLocalStorage } from 'shared/hooks/useLocalStorage.js';

import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { PropertyDetailContext } from '../entities/PropertyDetailContext.js';
import { findById as findProperty } from 'domain/Property/services/Property.js';
import { findById as findAuction } from 'domain/Auction/services/Auction.js';
import { findByIds as findAuctionUsers } from 'domain/User/services/ServiceUser.js';
import { findById as findGovernment } from 'domain/Government/services/Government.js';
import { findByIdAndUserId as findProposal } from 'domain/Applicant/services/Proposal.js';
import { findUserMaxBidPrice as findMaxBidderHistory } from 'domain/Bid/services/BidderHistory.js';
import { findById as findInvitation } from 'domain/Auction/services/Invitation.js';
import { findByPropertyId as findPaymentMethods } from 'domain/Property/services/PaymentMethod.js';
import { findById as findCarProperty } from 'domain/Property/services/CarProperty.js';
import { findByPropertyId as findEstateProperty } from 'domain/Property/services/EstateProperty.js';
import { useParams } from 'react-router-dom';

const PropertyDetailProvider = ({ type, children }) => {
  const [property, setProperty] = useState(undefined);
  const { id } = useParams();
  const { localStorageUserId } = useLocalStorage();

  //const mockProperty = {
  //  addDescription: 'DATA_EMPTY',
  //  additionalBidEnd: 'DATA_EMPTY',
  //  aplEnd: '2023-12-10 18:00:00',
  //  aplStart: '2023-12-03 08:00:00',
  //  auctionEnd: '2024-01-15 18:00:00',
  //  //aplStatus: NULL,
  //  //aplStatus: TRIAL,
  //  aplStatus: APPLICATION,
  //  //aplStatus: PROPOSAL_CANCEL,
  //  bidEnd: 'DATA_EMPTY',
  //  bidStart: '2023-12-25 08:00:00',
  //  bidders: [],
  //  bodyColor: 'DATA_EMPTY',
  //  buildDate: 'DATA_EMPTY',
  //  buildDateOther: 'DATA_EMPTY',
  //  buildName: 'DATA_EMPTY',
  //  buildOther: 'DATA_EMPTY',
  //  buildRatio: 'DATA_EMPTY',
  //  buildSpace: 'DATA_EMPTY',
  //  carCondition: 'DATA_EMPTY',
  //  carDescription: 'DATA_EMPTY',
  //  carMileage: 'DATA_EMPTY',
  //  carType: 'DATA_EMPTY',
  //  categoryId: '600',
  //  circumstance: 'DATA_EMPTY',
  //  cityPlanningArea: 'DATA_EMPTY',
  //  classificationNumber: 'DATA_EMPTY',
  //  commonDescription: 'これはテスト用のオークションです。',
  //  contractDeadline: 'DATA_EMPTY',
  //  deadline: '2024-01-15 18:00:00',
  //  deposit: 1000,
  //  depositPayment: '銀行振込',
  //  description: 'テスト用の物件です。',
  //  displacement: 'DATA_EMPTY',
  //  divisionName: 'テスト執行機関',
  //  effectiveDate: 'DATA_EMPTY',
  //  engineType: 'DATA_EMPTY',
  //  estateDescription: 'DATA_EMPTY',
  //  estateStatus: 'DATA_EMPTY',
  //  estateType: 'DATA_EMPTY',
  //  estimatePrice: 10000,
  //  exclusiveSpace: 'DATA_EMPTY',
  //  existsCarMaintenanceSheet: 'DATA_EMPTY',
  //  firebreakArea: 'DATA_EMPTY',
  //  firstRegisterDate: 'DATA_EMPTY',
  //  floor: 'DATA_EMPTY',
  //  floorAreaRatio: 'DATA_EMPTY',
  //  floorSpace: 'DATA_EMPTY',
  //  form: 'DATA_EMPTY',
  //  formalNumber: 'DATA_EMPTY',
  //  frontWeight: 'DATA_EMPTY',
  //  fuelType: 'DATA_EMPTY',
  //  geographical: 'DATA_EMPTY',
  //  governmentId: 1,
  //  governmentName: '行政機関（国税）',
  //  governmentSubCategoryId: '27',
  //  governmentType: 'NATIONAL_TAX_AGENCY',
  //  handle: 'DATA_EMPTY',
  //  height: 'DATA_EMPTY',
  //  highDistrict: 'DATA_EMPTY',
  //  id: 54,
  //  individualEnterprise: 'DATA_EMPTY',
  //  inquiryAccept: '平日 8:00 ~ 17:00',
  //  inquiryEmail: 'auction@sample.com',
  //  inquiryExtension: 'DATA_EMPTY',
  //  inquiryName: 'テスト機関',
  //  inquiryNote: '繋がらない場合がございます。',
  //  inquiryOffice: 'オークション部',
  //  inquiryTel: '1234567890',
  //  inventoryLocation: 'DATA_EMPTY',
  //  isDeliveryLock: 'UNLOCK',
  //  landCategory: 'DATA_EMPTY',
  //  landOther: 'DATA_EMPTY',
  //  landRight: 'DATA_EMPTY',
  //  landShare: 'DATA_EMPTY',
  //  landSpace: 'DATA_EMPTY',
  //  lawRegulation: 'DATA_EMPTY',
  //  layout: 'DATA_EMPTY',
  //  length: 'DATA_EMPTY',
  //  location: 'DATA_EMPTY',
  //  locationMap: 'DATA_EMPTY',
  //  locationText: 'DATA_EMPTY',
  //  locationUrl: 'DATA_EMPTY',
  //  manufacture: 'DATA_EMPTY',
  //  name: '国税-一般-54',
  //  nearestStation: 'DATA_EMPTY',
  //  neighboringRoad: 'DATA_EMPTY',
  //  note: 'DATA_EMPTY',
  //  other: 'DATA_EMPTY',
  //  paymentDeadline: '2024-06-27 18:00:00',
  //  payments: [''],
  //  privateRoad: 'DATA_EMPTY',
  //  procedureType: 'インターネット公売',
  //  procedureTypeEn: 'PUBLIC_SALE',
  //  productNumber: 'DATA_EMPTY',
  //  propertyPrint: 'DATA_EMPTY',
  //  propertyType: 'REAL_ESTATE',
  //  provideEquipment: 'DATA_EMPTY',
  //  publicSaleType: 'せり売形式',
  //  purpose: 'DATA_EMPTY',
  //  rearWeight: 'DATA_EMPTY',
  //  registrationDate: 'DATA_EMPTY',
  //  registrationNumber: 'DATA_EMPTY',
  //  remainingLabel: '入札締切まであと',
  //  residenceLocation: 'DATA_EMPTY',
  //  restrictedArea: 'DATA_EMPTY',
  //  safetyTerm: 'DATA_EMPTY',
  //  saleNumber: 'property-054',
  //  seatingCapacity: 'DATA_EMPTY',
  //  shadeRegulation: 'DATA_EMPTY',
  //  shape: 'DATA_EMPTY',
  //  sketchUrl: 'DATA_EMPTY',
  //  //status: APPLYING,
  //  //status: WAIT_START_BID,
  //  //status: DURING_BIDDING,
  //  //status: WAIT_OPEN_BID,
  //  //status: ADDITIONAL_DURING_BIDDING,
  //  status: END_BIDDING,
  //  //status: CANCEL,
  //  statusEn: 'DURING_BIDDING',
  //  structure: 'DATA_EMPTY',
  //  summaryBuildDate: '-',
  //  summaryBuildSpace: '-',
  //  summaryCarCategories: '動産＞音楽＞DVD＞ロック、ポップス（洋楽）',
  //  summaryCarMileage: '-',
  //  summaryDisplacement: '-',
  //  summaryEstateType: '',
  //  summaryExclusiveSpace: '-',
  //  summaryFirstRegisterDate: '年月',
  //  summaryFloor: '',
  //  summaryFloorSpace: '-',
  //  summaryLayout: '',
  //  summaryLocationText: '',
  //  summaryManufacture: '-',
  //  summaryNearestStation: '',
  //  summaryPurpose: '-',
  //  summarySafetyTerm: '年月',
  //  summarySeatingCapacity: '-',
  //  summaryTotalHouses: '-',
  //  summaryTransmissionType: '-',
  //  tags: ['インターネット公売', 'せり売形式', '保証金あり'],
  //  totalHouses: 'DATA_EMPTY',
  //  totalWeight: 'DATA_EMPTY',
  //  transmissionType: 'DATA_EMPTY',
  //  videoUrl: 'DATA_EMPTY',
  //  weight: 'DATA_EMPTY',
  //  width: 'DATA_EMPTY',
  //};

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      // 物件情報取得
      findProperty(type, id).then((property) => {
        // オークション情報取得
        findCarProperty(type, property.id).then((retCarProperty) => {
          findEstateProperty(type, property.id).then((retEstateProperty) => {
            findPaymentMethods(type, id).then((paymentMethods) => {
              findAuction(type, property.auctionId).then((auction) => {
                findGovernment(type, property.governmentId).then(
                  (government) => {
                    findInvitation(
                      type,
                      property.auctionId,
                      property.governmentId,
                    ).then((invitation) => {
                      // 入札申込情報取得（申込ステータス確認のため）
                      findProposal(type, id, localStorageUserId).then(
                        (proposal) => {
                          // 入札履歴情報取得（物件の最大入札額・ユーザが入札した最大入札額・ユーザが入札した追加入札の最大入札額）
                          findMaxBidderHistory(
                            type,
                            id,
                            localStorageUserId,
                          ).then((maxBidderHistories) => {
                            let tmpUserId =
                              maxBidderHistories.bidderHistories.map(
                                (history) => history.auctionUserId,
                              );
                            let userIds = [...new Set(tmpUserId)];
                            findAuctionUsers(type, userIds).then(
                              (auctionUsers) => {
                                let myBidder = {
                                  myBidPrice:
                                    maxBidderHistories?.maxPriceUserBidder
                                      ?.price,
                                  myAdditionalPrice:
                                    maxBidderHistories?.maxPriceUserAddBidder
                                      ?.price,
                                  bidCount: maxBidderHistories?.bidderCount,
                                };

                                let myAuctionUser = {
                                  membershipId: auctionUsers.find(
                                    (user) => user.id === localStorageUserId,
                                  )?.membershipId,
                                };

                                // 入札期間中の最高額の入札者
                                let currentTopUser = {
                                  currentTopUser: auctionUsers.find(
                                    (user) =>
                                      user.id ===
                                      maxBidderHistories.maxPriceBidder
                                        .auctionUserId,
                                  )?.membershipId,
                                  currentTopPrice:
                                    maxBidderHistories?.maxPriceBidder?.price,
                                };

                                // 入札後の最高額の入札者
                                let biddedUser = {
                                  bidderUser: auctionUsers.find(
                                    (user) =>
                                      user.id === property.auctionUserId,
                                  )?.membershipId,
                                };

                                // 入札後の最高額の次順位の入札者
                                let nextWinner = {
                                  nextWinner: auctionUsers.find(
                                    (user) =>
                                      user.id === property.nextAuctionUserId,
                                  )?.membershipId,
                                };

                                let paymentMethod = {
                                  payments: paymentMethods,
                                };

                                if (property.status === 'APPLYING') {
                                  property.remainingLabel = '申込締切まであと';
                                  property.deadline = auction.aplEnd;
                                } else if (
                                  property.status === 'DURING_BIDDING'
                                ) {
                                  property.remainingLabel = '入札締切まであと';
                                  property.deadline = auction.bidEnd;
                                } else if (
                                  property.status === 'WAIT_START_BID'
                                ) {
                                  property.remainingLabel = '入札開始まであと';
                                  property.deadline = auction.bidStart;
                                } else if (
                                  property.status ===
                                  'ADDITIONAL_DURING_BIDDING'
                                ) {
                                  property.remainingLabel =
                                    '追加入札締切まであと';
                                  property.deadline = auction.bidEnd;
                                }

                                let carProperty = {
                                  carProperty: retCarProperty,
                                };
                                let estateProperty = {
                                  estateProperty: retEstateProperty,
                                };

                                let bidHistories = [];
                                maxBidderHistories.bidderHistories?.forEach(
                                  (history) => {
                                    history.membershipId = auctionUsers.find(
                                      (user) =>
                                        user.id === history.auctionUserId,
                                    )?.membershipId;
                                    bidHistories.push(history);
                                  },
                                );

                                let bidderHistories = {
                                  bidHistory: bidHistories,
                                };

                                government.governmentName = government.name;

                                let mergedProperty = {
                                  ...government,
                                  ...auction,
                                  ...invitation,
                                  ...currentTopUser,
                                  ...myAuctionUser,
                                  ...myBidder,
                                  ...proposal,
                                  ...property,
                                  ...carProperty,
                                  ...estateProperty,
                                  ...paymentMethod,
                                  ...biddedUser,
                                  ...nextWinner,
                                  ...bidderHistories,
                                };
                                console.log(mergedProperty);

                                setProperty(mergedProperty);
                              },
                            );
                          });
                        },
                      );
                    });
                  },
                );
              });
            });
          });
        });
      });
    }, [id, localStorageUserId]);
  }

  if (type === NAVI) {
    useEffect(() => {}, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <PropertyDetailContext.Provider
      value={{
        property,
      }}>
      {children}
    </PropertyDetailContext.Provider>
  );
};

export default PropertyDetailProvider;
