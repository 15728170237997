import SmallTopicTitle from 'interfaces/components/common/Heading/SmallTopicTitle';
import { useEffect, useState } from 'react';
import 'interfaces/css/Faq/faq-list.css';

const auctionFaqData = [
  {
    text: 'KSI官公庁オークションとYahoo!官公庁オークションの主な違いについて',
    caption: '新旧の主な仕様の違い、変更点について一覧で解説します',
    link: '/documents/auction-differences-between-ksi-and-yahoo.pdf',
    isAboutBlank: true,
  },
  {
    text: '官公庁ナビ関連',
    caption: '物件作成、画像、公開物件管理、ブロックリストについて',
    link: '/documents/auction-related-to-government-navigation.pdf',
    isAboutBlank: true,
  },
  {
    text: '運用業務関連',
    caption: '実施中、入札期間終了後の運用や、公売保証金、請求について',
    link: '/documents/auction-related-to-operational-operations.pdf',
    isAboutBlank: true,
  },
  {
    text: '官公庁オークション参加方法',
    caption:
      '会員識別番号の取得方法や官公庁オークションへの一般的な参加方法、法人での参加方法について',
    link: '/documents/auction-participate-in-the-government-auction.pdf',
    isAboutBlank: true,
  },
  {
    text: 'クレジットカードでの参加申し込み方法',
    caption: 'クレジットカードでの参加申し込み方法について',
    link: '/documents/auction-apply-for-participation-by-credit-card.pdf',
    isAboutBlank: true,
  },
  {
    text: 'その他',
    caption:
      '官公庁オークション用のロゴやバナー等、広報（プレス）よくあるお問い合わせ',
    link: '/navi/faq/other',
    isAboutBlank: false,
  },
  {
    text: 'Markdownサンプル',
    caption: '下見会、物件説明用のMarkdownサンプル',
    link: '/documents/auction-markdown-sample.pdf',
    isAboutBlank: true,
  },
  {
    text: 'ガイドラインサンプル',
    caption: 'ガイドラインのサンプル',
    link: '/navi/faq/sample-guideline',
    isAboutBlank: false,
  },
  {
    text: '落札後の注意事項サンプル',
    caption: '落札後の注意事項サンプル',
    link: '/navi/faq/sample-after-winning-bid',
    isAboutBlank: false,
  },
];

const assetSaleFaqData = [
  {
    text: 'KSI官公庁オークションとYahoo!官公庁オークションの主な違いについて',
    caption: '新旧の主な仕様の違い、変更点について一覧で解説します',
    link: '/documents/asset-sale-differences-between-ksi-and-yahoo.pdf',
    isAboutBlank: true,
  },
  {
    text: '官公庁ナビ関連',
    caption:
      '物件作成、画像、公開物件管理、ブロックリスト、ガイドラインの登録について',
    link: '/documents/asset-sale-related-to-government-navigation.pdf',
    isAboutBlank: true,
  },
  {
    text: '運用業務関連',
    caption: '実施中、入札期間終了後の運用や、入札保証金、請求について',
    link: '/documents/asset-sale-related-to-operational-operations.pdf',
    isAboutBlank: true,
  },
  {
    text: '官公庁オークション参加方法',
    caption:
      '会員識別番号の取得方法や官公庁オークションへの一般的な参加方法、法人での参加方法について',
    link: '/documents/asset-sale-participate-in-the-government-auction.pdf',
    isAboutBlank: true,
  },
  {
    text: 'クレジットカードでの参加申し込み方法',
    caption: 'クレジットカードでの参加申し込み方法について',
    link: '/documents/asset-sale-apply-for-participation-by-credit-card.pdf',
    isAboutBlank: true,
  },
  {
    text: 'その他',
    caption:
      '官公庁オークション用のロゴやバナー等、広報（プレス）よくあるお問い合わせ',
    link: '/navi/faq/other',
    isAboutBlank: false,
  },
  {
    text: 'Markdownサンプル',
    caption: '下見会、物件説明用のMarkdownサンプル',
    link: '/documents/asset-sale-markdown-sample.pdf',
    isAboutBlank: true,
  },
  {
    text: 'ガイドラインサンプル',
    caption: 'ガイドラインのサンプル',
    link: '/navi/faq/sample-guideline',
    isAboutBlank: false,
  },
  {
    text: '落札後の注意事項サンプル',
    caption: '落札後の注意事項サンプル',
    link: '/navi/faq/sample-after-winning-bid',
    isAboutBlank: false,
  },
];

const FaqList = ({ procedureType }) => {
  const [faqData, setFaqData] = useState([]);
  useEffect(() => {
    if (procedureType === 'ASSET_SALE') {
      setFaqData(assetSaleFaqData);
      return;
    }
    setFaqData(auctionFaqData);
  }, [procedureType]);
  return (
    <span data-testid="faq-list" className="faq-list mt-6">
      <h3 className="faq-headline mt-4">よくある質問</h3>
      {faqData.map((faq, index) => (
        <SmallTopicTitle
          key={index}
          text={faq.text}
          caption={faq.caption}
          link={faq.link}
          isAboutBlank={faq.isAboutBlank}
        />
      ))}
    </span>
  );
};
export default FaqList;
