import { validateInputsAuctionForm } from 'interfaces/components/Auction/kmanager/common/validateInputsAuctionForm';
import PageTitle from 'interfaces/components/common/PageTitle.jsx';
import { useState } from 'react';

import AuctionConfirm from '../common/AuctionConfirm.jsx';
import AuctionForm from '../common/AuctionForm.jsx';

//import { dummyEditHeader } from "../helper/test_helper/dummy_kmanager_auction_data.js";
export const dummyEditHeader = [
  { id: 1, column: 'name', text: '開催名' },
  { id: 2, column: 'governmentTypeGroup', text: '対象行政機関種別' },
  { id: 3, column: 'procedureType', text: '手続き種別' },
  { id: 4, column: 'publicSaleType', text: '公売方法選択' },
  { id: 5, column: 'govAplStart', text: '行政機関申込開始日時' },
  { id: 6, column: 'govAplEnd', text: '行政機関申込締切日時' },
  { id: 7, column: 'aplStart', text: '参加申込開始日時' },
  { id: 8, column: 'aplEnd', text: '参加申込締切日時' },
  { id: 9, column: 'bidStart', text: '入札開始日時' },
  { id: 10, column: 'auctionEnd', text: '入札終了日時(せり)' },
  { id: 11, column: 'bidEnd', text: '入札終了日時(入札)' },
  { id: 12, column: 'addBidDuration', text: '追加入札時間' },
  { id: 13, column: 'bidDecision', text: '入札確定処理日時' },
  {
    id: 14,
    column: 'nextPaymentLimit',
    text: '次順位買受代金納付期限（動産・自動車）',
  },
  {
    id: 15,
    column: 'nextPaymentLimitEstate',
    text: '次順位買受代金納付期限（不動産）',
  },
  { id: 16, column: 'openEnd', text: '公開終了日' },
  { id: 17, column: 'depositType', text: '保証金の取り扱い' },
];

const AuctionNew = () => {
  const [onConfirm, setOnConfirm] = useState(false);
  const auctionInputs = validateInputsAuctionForm();

  return (
    <div className="kmanager-container" data-testid="kmanager-auction-create">
      <div className="kmanager-row">
        <div className="kmanager-col-12">
          <PageTitle text="オークション編集" />
        </div>
        {onConfirm ? (
          <AuctionConfirm
            cancel={() => setOnConfirm(false)}
            type="create"
            data={auctionInputs}
          />
        ) : (
          <AuctionForm
            inputs={auctionInputs}
            toConfirm={() => setOnConfirm(true)}
          />
        )}
      </div>
    </div>
  );
};

export default AuctionNew;
//   id: {
//     path: 'id',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.id
//     }
//   },
//   name: {
//     path: 'name',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.name
//     }
//   },
//   governmentTypeGroup: {
//     path: 'government-type-group',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.governmentTypeGroup
//     }
//   },
//   procedureType: {
//     path: 'procedure-type',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.procedureType
//     }
//   },
//   publicSaleType: {
//     path: 'public-sale-type',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.publicSaleType
//     }
//   },
//   govAplStart: {
//     path: 'gov-apl-start',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.govAplStart
//     }
//   },
//   govAplEnd: {
//     path: 'gov-apl-end',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.govAplEnd
//     }
//   },
//   aplStart: {
//     path: 'apl-start',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.aplStart
//     }
//   },
//   aplEnd: {
//     path: 'apl-end',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.aplEnd
//     }
//   },
//   bidStart: {
//     path: 'bid-start',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.bidStart
//     }
//   },
//   auctionEnd: {
//     path: 'auction-end',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.auctionEnd
//     }
//   },
//   bidEnd: {
//     path: 'bid-end',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.bidEnd
//     }
//   },
