// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-controls .btn.grey .btn-content {
  color: rgba(0,0,0,.26);
}

.step-controls .btn.grey {
  background-color: rgba(0,0,0,.12);
  box-shadow: none;
}

.step-controls .cancel-or-back-button.theme-light.btn {
  color: var(--anchor-base);
}

`, "",{"version":3,"sources":["webpack://./src/interfaces/components/Guidelines/GuidelineAgreement/css/step-controls.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".step-controls .btn.grey .btn-content {\n  color: rgba(0,0,0,.26);\n}\n\n.step-controls .btn.grey {\n  background-color: rgba(0,0,0,.12);\n  box-shadow: none;\n}\n\n.step-controls .cancel-or-back-button.theme-light.btn {\n  color: var(--anchor-base);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
