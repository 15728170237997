import Button from 'interfaces/components/common/Button';
import PageTitle from 'interfaces/components/common/PageTitle';
import SearchInput from 'interfaces/components/common/SearchInput';
import Toolbar from 'interfaces/components/common/Toolbar';
import { useState, useContext, useEffect } from 'react';
import { MessageContext } from '../../../pages/Message/entities/MessageContext';
import MessageFormModal from './MessageFormModal';
import MessageTable from './MessageTable';

const Messages = () => {
  const { managementMessages } = useContext(MessageContext);
  const [searchInput, setSearchInput] = useState('');
  const [filteredManagementMessages, setFilteredManagementMessages] =
    useState(managementMessages);

  useEffect(() => {
    setFilteredManagementMessages(
      managementMessages.filter((managementMessage) => {
        return (
          managementMessage.body.includes(searchInput) ||
          managementMessage.title.includes(searchInput)
        );
      }),
    );
  }, [searchInput, managementMessages]);

  // メッセージ作成モーダル制御
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="kmanager-messages kmanager-auction-operation"
      data-testid="kmanager-messages">
      {isOpen ? (
        <MessageFormModal
          closeModal={() => {
            setIsOpen(false);
          }}
        />
      ) : (
        ''
      )}
      <div className="kmanager-row">
        <div className="kmanager-col kmanager-col-6">
          <PageTitle text="緊急メッセージ管理" />
        </div>
        <div className="kmanager-col">
          <SearchInput onChange={(e) => setSearchInput(e.target.value)} />
        </div>
        <div className="kmanager-col kmanager-col-12">
          <div className="box-shadow">
            <Toolbar>
              <Button
                className="primary"
                text="新規作成"
                clickHandler={() => setIsOpen(true)}
              />
            </Toolbar>
            <MessageTable managementMessages={filteredManagementMessages} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
