import { PROPERTY_LIST_SIZE } from 'config/naviConstants';
import Breadcrumb from "interfaces/components/common/Breadcrumb.jsx";
import Pagination from 'interfaces/components/common/Pagination/Pagination.jsx';
import PropertyListPageDescription from "interfaces/components/Property/List/PropertyListPageDescription.jsx";
import PropertyListSettingHeader from "interfaces/components/Property/List/PropertyListSettingHeader";
import PropertyListTable from "interfaces/components/Property/List/PropertyListTable";
import {breadcrumbs} from "interfaces/pages/PropertyList/entities/breadcrumbs";
import {useContext} from "react";
import {useLocation} from "react-router-dom";
import usePagination from 'shared/hooks/usePagination.js';

import {PropertyListContext} from "../entities/PropertyListContext";

const content = <>
    公開予定物件の新規作成や管理、削除を行います。<br/>
    新規作成を行う場合は「物件種別」から種別を選択し「新規作成」ボタンをクリックしてください。<br/>
    公開状況が「公開」となった物件のみ一般公開されます。下書き状態の物件は公開されませんのでご注意ください。<br/>
    登録した物件情報を他の公告で下書きとして再利用されたい場合は、該当物件の選択欄にチェックを入れ「コピー」ボタンをクリックしてください。
</>

const PropertyList = () => {
    const search = useLocation().search;
    const { auction, property, procedureType } = useContext(PropertyListContext);
    const {
        currentPage,
        currentItems,
        pageNumbers,
        setCurrentPage
    } = usePagination(property, PROPERTY_LIST_SIZE);

    let queryParams = new URLSearchParams(search);
    let openWay = queryParams.get('open-way') === 'AUCTION' ? 'せり売形式' : '入札形式'

    return (
        <div className="new-style_navi_form new-style_navi_object_list_open-way_auction">
            <Breadcrumb breadcrumbs={breadcrumbs(auction, openWay)}/>
            <PropertyListPageDescription title={`物件一覧 / ${auction.name}`} content={content}/>
            <PropertyListSettingHeader/>
            <PropertyListTable data={currentItems} procedureType={procedureType}/>
            <Pagination
                currentPage={currentPage}
                pageCount={pageNumbers.length}
                setCurrentPage={setCurrentPage}
                marginTop="40px"
            />
        </div>
    );
}

export default PropertyList
