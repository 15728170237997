import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  SEARCH_PROPERTY_CATEGORY_ID,
  DATA_EMPTY,
} from 'shared/utils/constants/queryParam';

import AccordionWithCheckBox from './AccordionWithCheckBox';
import ChildCategories from './ChildCategories';

const ParentCategories = ({
  isSeeAll,
  showCheckBox,
  showCount,
  propertyCategories = [],
}) => {
  //const { allowPropertyCategories } = useContext(DashboardContext);
  const initialArray = new Array(propertyCategories?.length ?? 0).fill(false);

  // チェックボックスのチェックの状態を保持
  const [parentCheckBoxStates, setParentCheckBoxStates] =
    useState(initialArray);
  const [searchParams] = useSearchParams();
  const searchPropertyCategoryId =
    searchParams.get(SEARCH_PROPERTY_CATEGORY_ID) ?? DATA_EMPTY;
  const setParentCheckBoxState = (index, value) => {
    const newState = [...parentCheckBoxStates];
    newState[index] = value;
    setParentCheckBoxStates(newState);
  };

  // searchにカテゴリー情報が出ない理由は、useEffectの再描画条件に含まれないため。
  // providerに移行する（対象：dashboard, search
  useEffect(() => {
    const updateArray = [...parentCheckBoxStates];
    if (searchParams.has(SEARCH_PROPERTY_CATEGORY_ID)) {
      const searchCategoryIds = searchParams.get(SEARCH_PROPERTY_CATEGORY_ID);
      if (searchCategoryIds !== '') {
        const searchPropertyCategoryId = searchCategoryIds.split(',');
        propertyCategories.forEach((category, index) => {
          searchPropertyCategoryId.includes(category.id.toString())
            ? (updateArray[index] = true)
            : null;
        });
        setParentCheckBoxStates(updateArray);
      }
    }
  }, [propertyCategories, searchPropertyCategoryId]);

  return propertyCategories?.map((category, index) => {
    if (category.displayService !== 'DISPLAY') {
      return null;
    }
    if (index > 4) {
      return (
        <AccordionWithCheckBox
          key={category.id}
          id="parent-category"
          categoryData={category}
          marginLeft={25}
          style={isSeeAll ? {} : { display: 'none' }}
          onClick={() => {
            setParentCheckBoxState(index, !parentCheckBoxStates[index]);
          }}
          showCheckBox={showCheckBox}
          showCount={showCount}
          checkboxActive={parentCheckBoxStates[index]}
          contents={
            <ChildCategories
              categories={category.childCategory}
              isSeeAll={isSeeAll}
              showCheckBox={showCheckBox}
              showCount={showCount}
              checkBoxState={parentCheckBoxStates[index]}
            />
          }
        />
      );
    } else {
      return (
        <AccordionWithCheckBox
          key={category.id}
          id="parent-category"
          categoryData={category}
          marginLeft={25}
          style={{}}
          onClick={() => {
            setParentCheckBoxState(index, !parentCheckBoxStates[index]);
          }}
          showCheckBox={showCheckBox}
          showCount={showCount}
          checkboxActive={parentCheckBoxStates[index]}
          contents={
            <ChildCategories
              categories={category.childCategory}
              isSeeAll={isSeeAll}
              showCheckBox={showCheckBox}
              showCount={showCount}
              checkBoxState={parentCheckBoxStates[index]}
            />
          }
        />
      );
    }
  });
};

export default ParentCategories;
