import { ReactComponent as DeleteIcon } from 'interfaces/assets/svg/delete.svg';
import { ReactComponent as PencilIcon } from 'interfaces/assets/svg/pencil.svg';
import Button from 'interfaces/components/common/Button';
import { useState } from 'react';
import { useSnackBar } from 'interfaces/components/Auth/kmanager/SnackBarProvider';

import 'interfaces/css/Dashboard/kmanager-auction-table-record.css';
import ManagementUserFormModal from './ManagementUserFormModal';
import { KmanagerUserTypes } from 'shared/utils/constants/user';

const ManagementUserTableRecord = ({ managementUser, isAdmin }) => {
  // 編集用モーダル制御
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { openSnackBar } = useSnackBar();
  const Delete = () => {
    console.log('todo: fix management user delete process');
    openSnackBar('削除しました');
  };

  return (
    <>
      {isEditModalOpen ? (
        <ManagementUserFormModal
          closeModal={() => setIsEditModalOpen(false)}
          data={managementUser}
        />
      ) : (
        ''
      )}
      <tr
        className="kmanager-auction-table-record"
        data-testid="kmanager-auction-table-record">
        <td data-testid="kmanager-auction-table-td">
          <div>{managementUser?.familyName}</div>
        </td>
        <td data-testid="kmanager-auction-table-td">
          <div>{managementUser?.firstName}</div>
        </td>
        <td data-testid="kmanager-auction-table-td">
          <div>{managementUser?.familyNameKana}</div>
        </td>
        <td data-testid="kmanager-auction-table-td">
          <div>{managementUser?.firstNameKana}</div>
        </td>
        <td data-testid="kmanager-auction-table-td">
          <div>{managementUser?.department}</div>
        </td>
        <td data-testid="kmanager-auction-table-td">
          <div>{managementUser?.officePosition}</div>
        </td>
        <td data-testid="kmanager-auction-table-td">
          <div>{managementUser?.tel}</div>
        </td>
        <td data-testid="kmanager-auction-table-td">
          <div>{managementUser?.extension}</div>
        </td>
        <td data-testid="kmanager-auction-table-td">
          <div>{managementUser?.email}</div>
        </td>
        <td data-testid="kmanager-auction-table-td">
          <div>{KmanagerUserTypes[managementUser?.userType]?.jp}</div>
        </td>
        <td data-testid="kmanager-auction-table-td">
          <div>
            {isAdmin && (
              <>
                <Button
                  className="kmanager-common-button text-no-wrap edit"
                  text={<PencilIcon />}
                  clickHandler={() => setIsEditModalOpen(true)}
                />
                <Button
                  className="kmanager-common-button text-no-wrap warning"
                  text={<DeleteIcon />}
                  clickHandler={() => Delete()}
                />
              </>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default ManagementUserTableRecord;
