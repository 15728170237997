import { HYPHEN_SYMBOL } from 'domain/Property/entities/constants/property';

import { isNaturalNumber } from 'shared/utils/helper/isNaturalNumber';
import toYenFrom from 'shared/utils/helper/toYenString';

export const bidPrice = (bidPrice) => {
  if (!isNaturalNumber(bidPrice)) {
    return HYPHEN_SYMBOL;
  }
  return `${toYenFrom(bidPrice)}円`;
};
