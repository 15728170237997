import Button from 'interfaces/components/common/Button';
import Confirm from 'interfaces/components/common/Confirm';
import { useState, useEffect } from 'react';

import { validateInputsBanner } from './validateInputsBanner';

//import {useDeleteSubmit} from "shared/hooks/useSubmit";
import InputType from '../common/Form/InputType';
import InputFile from '../common/Form/InputFile';
//import {useBannerRegister, useBannerUpdate} from "./Register";

const BannerForm = ({ banner = undefined, index = 1 }) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const inputs = validateInputsBanner();
  useEffect(() => {
    const validationResults = inputs
      .filter((input) => input.validationMessage !== undefined)
      .map((input) => {
        const validationMessage = input.validationMessage;
        const column = input.column;
        const isValid = validationMessage === 'TRUE';
        return { column, validationMessage, isValid };
      });
    console.log(validationResults);
    const allValid = validationResults.every(({ isValid }) => isValid);
    setIsValid(allValid);
  }, [inputs]);

  //  const submitHandler = useDeleteSubmit(banner);

  const status = inputs.find(
    (object) => object.column === 'displayDeadline',
  ).state;
  let input = inputs;
  if (status === 'HIDDEN') {
    input = inputs.filter((object) => object.column !== 'deadline');
  }
  //const register = useBannerRegister(input);
  //const update = useBannerUpdate(input, banner);

  return (
    <div
      className="kmanager-banner-form box-shadow pa-3"
      data-testid="kmanager-banner-form"
      style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
      <Confirm
        type="delete"
        isOpen={isDeleteOpen}
        closeModal={() => setIsDeleteOpen(false)}
        Submit={() => {}}
      />
      <Confirm
        type="submit"
        isOpen={isCreateOpen}
        closeModal={() => setIsCreateOpen(false)}
        Submit={() => {}}
      />
      <div className="flex flex-wrap">
        <div>
          <div>
            {banner && (
              <img
                src={`/storage/current/banners/${banner.id}/data.jpg`}
                alt="image-preview"
                data-testid="input-file-preview"
              />
            )}
            <InputFile
              setState={() => {
                console.log('hoge');
              }}
              index={index}
            />
          </div>
        </div>
        <div className="kmanager-form flex-grow-1">
          {input &&
            input.map((input, index) => {
              return InputType(input, index);
            })}
        </div>
        <div className="flex justify-center mt-3" style={{ width: '100%' }}>
          <Button
            className="primary"
            text="保存"
            clickHandler={() => setIsCreateOpen(true)}
            isDisabled={!isValid}
          />
          <Button
            className="warning"
            text="削除"
            clickHandler={() => setIsDeleteOpen(true)}
          />
        </div>
      </div>
    </div>
  );
};
export default BannerForm;

{
  /*
<CommonAccordion
    key={banner.id}
    label={banner.title}
    contents={
      <div className="kmanager-banner-form box-shadow pa-3" data-testid="kmanager-banner-form"
           style={{backgroundColor: 'rgb(245, 245, 245)'}}>
        <Confirm
            type="delete"
            isOpen={isDeleteOpen}
            closeModal={() => setIsDeleteOpen(false)}
            //Submit={submitHandler}
        />
        <Confirm
            type="submit"
            isOpen={isCreateOpen}
            closeModal={() => setIsCreateOpen(false)}
            //Submit={data?.id ? update : register}
        />
        <div className="flex flex-wrap">
<div>
  <div>
  </div>
  <input type="file" accept="image/*" data-testid="input-file"/>
</div>

<div className="kmanager-form flex-grow-1">
  {
      input && input.map((input, index) => {
        return InputType(input, index)
      })
  }
</div>

<div className="flex justify-center mt-3" style={{width: '100%'}}>
  <Button className="primary" text="保存" clickHandler={() => setIsCreateOpen(true)}/>
  <Button className="warning" text="削除" clickHandler={() => setIsDeleteOpen(true)}/>
</div>
</div>
</div>
}
/>
*/
}

// import configs from "../../../../../work/members_student/project/auction_matching/react/src/kmanager/api/configs/CAMEL_TABLE_NAME";
// import { allFetch } from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/newEntry";
//
// const create = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.create);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const update = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.update);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const destroy = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.destroy);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
