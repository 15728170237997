
export class ActiveStatus {
  #activeStatus

  constructor(activeStatus) {
    this.#activeStatus = activeStatus;
  }

  get() {
    return this.#activeStatus
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
