import { useGuidelineInputs } from 'shared/utils/helper/useGuidelineInputs';

// validation
import { GuidelinePublicSaleForm } from './GuidelinesPublicSaleForm.jsx';

export function validateInputsGuidelinePublicSaleForm() {
  const formInputs = useGuidelineInputs();
  const validate = GuidelinePublicSaleForm();

  function handleChangeIsDisplayPledge(e) {
    formInputs.setters.setIsDisplayPledge(e.target.value);
  }

  function handleChangeIsDisplay1(e) {
    formInputs.setters.setIsDisplay1(e.target.value);
  }
  function handleChangeIsDisplay2(e) {
    formInputs.setters.setIsDisplay2(e.target.value);
  }
  function handleChangeIsDisplay3(e) {
    formInputs.setters.setIsDisplay3(e.target.value);
  }
  function handleChangeIsDisplay4(e) {
    formInputs.setters.setIsDisplay4(e.target.value);
  }
  function handleChangeIsDisplay5(e) {
    formInputs.setters.setIsDisplay5(e.target.value);
  }
  function handleChangeIsDisplay6(e) {
    formInputs.setters.setIsDisplay6(e.target.value);
  }
  function handleChangeIsDisplay7(e) {
    formInputs.setters.setIsDisplay7(e.target.value);
  }
  function handleChangeIsDisplay8(e) {
    formInputs.setters.setIsDisplay8(e.target.value);
  }
  function handleChangePledgeBody(e, required) {
    console.log(e.text, required);
    validate.setters.handleGuidelinePledgeBodyValid(e.text, required);
    formInputs.setters.setPledgeBody(e.text);
  }

  function handleChangeTitle1(e, required) {
    validate.setters.handleGuidelineTitleRequiredValid1(e, required);
    formInputs.setters.setTitle1(e.target.value);
  }

  function handleChangeTitle2(e, required) {
    validate.setters.handleGuidelineTitleRequiredValid2(e, required);
    formInputs.setters.setTitle2(e.target.value);
  }

  function handleChangeTitle3(e, required) {
    validate.setters.handleGuidelineTitleRequiredValid3(e, required);
    formInputs.setters.setTitle3(e.target.value);
  }

  function handleChangeTitle4(e, required) {
    validate.setters.handleGuidelineTitleRequiredValid4(e, required);
    formInputs.setters.setTitle4(e.target.value);
  }

  function handleChangeTitle5(e, required) {
    validate.setters.handleGuidelineTitleRequiredValid5(e, required);
    formInputs.setters.setTitle5(e.target.value);
  }

  function handleChangeTitle6(e, required) {
    validate.setters.handleGuidelineTitleRequiredValid6(e, required);
    formInputs.setters.setTitle6(e.target.value);
  }

  function handleChangeTitle7(e, required) {
    validate.setters.handleGuidelineTitleRequiredValid7(e, required);
    formInputs.setters.setTitle7(e.target.value);
  }

  function handleChangeTitle8(e, required) {
    validate.setters.handleGuidelineTitleRequiredValid8(e, required);
    formInputs.setters.setTitle8(e.target.value);
  }

  function handleChangeBody1({ text }, required) {
    validate.setters.handleGuidelineBodyValid1(text, required);
    formInputs.setters.setBody1(text);
  }

  function handleChangeBody2({ text }, required) {
    validate.setters.handleGuidelineBodyValid2(text, required);
    formInputs.setters.setBody2(text);
  }

  function handleChangeBody3({ text }, required) {
    validate.setters.handleGuidelineBodyValid3(text, required);
    formInputs.setters.setBody3(text);
  }

  function handleChangeBody4({ text }, required) {
    validate.setters.handleGuidelineBodyValid4(text, required);
    formInputs.setters.setBody4(text);
  }

  function handleChangeBody5({ text }, required) {
    validate.setters.handleGuidelineBodyValid5(text, required);
    formInputs.setters.setBody5(text);
  }

  function handleChangeBody6({ text }, required) {
    validate.setters.handleGuidelineBodyValid6(text, required);
    formInputs.setters.setBody6(text);
  }

  function handleChangeBody7({ text }, required) {
    validate.setters.handleGuidelineBodyValid7(text, required);
    formInputs.setters.setBody7(text);
  }

  function handleChangeBody8({ text }, required) {
    validate.setters.handleGuidelineBodyValid8(text, required);
    formInputs.setters.setBody8(text);
  }

  const validateInputs = {
    data: formInputs.data,
    init: formInputs.setters,
    setters: {
      handleChangeIsDisplayPledge,
      handleChangeIsDisplay1,
      handleChangeIsDisplay2,
      handleChangeIsDisplay3,
      handleChangeIsDisplay4,
      handleChangeIsDisplay5,
      handleChangeIsDisplay6,
      handleChangeIsDisplay7,
      handleChangeIsDisplay8,
      handleChangeTitle1,
      handleChangeTitle2,
      handleChangeTitle3,
      handleChangeTitle4,
      handleChangeTitle5,
      handleChangeTitle6,
      handleChangeTitle7,
      handleChangeTitle8,
      handleChangePledgeBody,
      handleChangeBody1,
      handleChangeBody2,
      handleChangeBody3,
      handleChangeBody4,
      handleChangeBody5,
      handleChangeBody6,
      handleChangeBody7,
      handleChangeBody8,
    },
    validationMessage: validate.validationMessage,
  };

  return validateInputs;
}
