import CancelButton from "interfaces/components/common/Button/CancelButton";
import ConfirmButton from "interfaces/components/common/Button/ConfirmButton";
import ButtonList from "interfaces/components/common/ButtonList";
import InputType from "interfaces/components/common/Form/InputType";
import "interfaces/css/common/form.css"

const ServiceProvidersCreate = ({ inputs, closeModal, toConfirm }) => {

  return (
    <div className="kmanager-form" data-testid="kmanager-form">
      {
        inputs && inputs.map((input, index) => {
          return InputType(input, index)
        })
      }
      <ButtonList>
        <div className="d-flex justify-center mt-10">
          <ConfirmButton inputs={inputs} toConfirm={toConfirm} />
          <CancelButton className={'warning'} clickHandler={closeModal}/>
        </div>
      </ButtonList>
    </div>
  );
};

export default ServiceProvidersCreate;
