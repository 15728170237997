// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-form-textarea-box {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, .42);
  border-radius: 4px;
  padding: 0 12px;
}

.property-form-textarea {
  width: 100%;
  border-style: none;
  outline: none;
  margin-top: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/property-form-textarea.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,oCAAoC;EACpC,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB","sourcesContent":[".property-form-textarea-box {\n  background-color: rgb(255, 255, 255);\n  border: 1px solid rgba(0, 0, 0, .42);\n  border-radius: 4px;\n  padding: 0 12px;\n}\n\n.property-form-textarea {\n  width: 100%;\n  border-style: none;\n  outline: none;\n  margin-top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
