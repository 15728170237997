import Tab from 'interfaces/components/common/Tab';
import { useNavigate } from 'react-router-dom';

import { useRef, useState } from 'react';
import { ReactComponent as ChevronLeftIcon } from 'interfaces/assets/svg/chevron_left_icon.svg';
import { ReactComponent as ChevronRightIcon } from 'interfaces/assets/svg/chevron_right_icon.svg';

const AuctionSwitcher = ({ id, activeIndex, auctionGovernmentTypeGroup }) => {
  const navigation = useNavigate();
  const tabRef = useRef(null);
  const [isInit, setIsInit] = useState(true);

  const TAB_LABELS = [
    {
      index: 0,
      label: '設定状況確認',
      handleClick: () => navigation(`/kmanager/auction/${id}/gov_setting`),
    },
    {
      index: 1,
      label: '行政機関管理',
      handleClick: () => navigation(`/kmanager/auction/${id}/gov`),
    },
    {
      index: 2,
      label: '公売(入札)保証金管理',
      handleClick: () => navigation(`/kmanager/auction/${id}/deposit`),
    },
    {
      index: 3,
      label: 'システム利用料管理',
      handleClick: () => navigation(`/kmanager/auction/${id}/usage_fee`),
    },
  ];

  const NATIONAL_TAX_AGENCY_TAB_LABELS = [
    {
      index: 0,
      label: '設定状況確認',
      handleClick: () => navigation(`/kmanager/auction/${id}/gov_setting`),
    },
    {
      index: 1,
      label: '行政機関管理',
      handleClick: () => navigation(`/kmanager/auction/${id}/gov`),
    },
    {
      index: 3,
      label: 'システム利用料管理',
      handleClick: () => navigation(`/kmanager/auction/${id}/usage_fee`),
    },
  ];

  const labels =
    auctionGovernmentTypeGroup === 'NATIONAL_TAX_AGENCY'
      ? NATIONAL_TAX_AGENCY_TAB_LABELS
      : TAB_LABELS;

  const scrollLeft = () => {
    if (tabRef.current) {
      tabRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
    setIsInit(true);
  };

  const scrollRight = () => {
    if (tabRef.current) {
      tabRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
    setIsInit(false);
  };

  return (
    <div className="kmanager-tab">
      {!isInit && (
        <ChevronLeftIcon
          width={30}
          height={30}
          className="kmanager-tab_arrow"
          onClick={scrollLeft}
        />
      )}
      <div className="kmanager-tab_wrap" ref={tabRef}>
        <div className="kmanager-tab_content">
          {labels.map((label, index) => (
            <Tab
              key={index}
              label={label.label}
              isActive={label.index === activeIndex}
              setActiveTab={label.handleClick}
              className={`kmanager-tab_menu ${index === activeIndex ? 'kmanager-tab_menu_active' : ''}`}
            />
          ))}
        </div>
      </div>
      {isInit && (
        <ChevronRightIcon
          width={30}
          height={30}
          className="kmanager-tab_arrow"
          onClick={scrollRight}
        />
      )}
    </div>
  );
};
export default AuctionSwitcher;
