import { useState } from "react";

export const useBankProvisionalApplyCompletionInputs = () =>  {
  const [auctionBankTransferUrl,setAuctionBankTransferUrl] = useState('');
  const [auctionJointBiddingUrl,setAuctionJointBiddingUrl] = useState('');
  const [bidBankTransferUrl,setBidBankTransferUrl] = useState('');
  const [bidJointBiddingUrl,setBidJointBiddingUrl] = useState('');

  const inputs = {
    data: {
      auctionBankTransferUrl,
      auctionJointBiddingUrl,
      bidBankTransferUrl,
      bidJointBiddingUrl,
    },
    setters:{
      setAuctionBankTransferUrl,
      setAuctionJointBiddingUrl,
      setBidBankTransferUrl,
      setBidJointBiddingUrl,
    }
  }
  return inputs;
}