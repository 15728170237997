import { ReactComponent as MenuDown } from 'interfaces/assets/svg/menu-down.svg';
import 'interfaces/css/common/property-list-selector.css';

const PropertyListSelector = ({ content, onClick, width }) => {
  return (
    <div
      className="property-list-selector text-field-outlined"
      data-testid="property-list-selector"
      style={{ width: width }}>
      <div
        className="input-control"
        data-testid="input-control"
        onClick={onClick}>
        <div className="input-slot">
          <div className="select-slot">
            <div className="select-selections">
              <div
                className="select-selection comma"
                data-testid="select-selection">
                {content}
              </div>
            </div>
            <div
              className="input-append-inner"
              data-testid="property-list-selector-icon">
              <div className="input-icon">
                <MenuDown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PropertyListSelector;
