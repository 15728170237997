import './css/property-info.css'

const PropertyInfo = ({ label, info, isBold = false, isImportant = false }) => {
  return (
    <tr className='flex' data-testid="property-info">
      <th className="property-info-label" data-testid="property-info-label">{isBold ? <b>{label}</b> : label}</th>
      <td className={`${isImportant ? 'important' : ''}`} data-testid="property-info-value">{isBold ? <b>{info}</b> : info}</td>
    </tr>
  );
};

export default PropertyInfo;
