import { useFixResolutionDateInputs } from "./hook/useFixResolutionDateInputs";
import { useFixResolutionDateInputsValidation } from "./hook/useFixResolutionDateInputsValidation";

export function validateInputsFixResolutionDateForm() {
  const formInputs = useFixResolutionDateInputs();
  const validate = useFixResolutionDateInputsValidation();

  function handleFixResolutionDate(e) {
    validate.setters.fixResolutionDate(e)
    formInputs.setters.fixResolutionDate(e)
  }

  const validateInputs = [
    {
      type: formInputs.type.fixResolutionDate,
      column: 'fixResolutionDate',
      text: '',
      validationMessage: validate.validationMessage.fixResolutionDate,
      state: formInputs.data.fixResolutionDate,
      init: (val) => formInputs.setters.fixResolutionDate(val),
      setters: handleFixResolutionDate
    },
  ]

  return validateInputs;
}