import { Link } from 'react-router-dom';

const LinkGroup = ({ items }) => {
  return (
    <div data-testid="link-group" className="link-group">
      {
        items && items.map((item) => (
          <div key={item.id} data-testid="link-item" className="link-item mb-2">
            <div className="link-wrap">
              <Link
                to={item.link}
                onClick={item.onClick}
                data-testid="link"
                className="link-item-text"
              >
                <span data-testid="link-title">{item.title}</span>
              </Link>
            </div>
          </div>
        ))
      }
    </div>
  );
}

export default LinkGroup;