
export class SummarySeatingCapacity {
    #summarySeatingCapacity

    constructor(summarySeatingCapacity) {
        this.#summarySeatingCapacity = summarySeatingCapacity;
    }

    get() {
        return this.#summarySeatingCapacity
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
