import { useState } from 'react';
import styled from 'styled-components';

import ParentCategories from './ParentCategories.jsx';

const Wrapper = styled.div`
  font-size: 0.8rem;
`;

const Toggle = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const ToggleLink = styled.a`
  color: var(--black);
`;

const Category = ({ isOpen, showCheckBox, showCount }) => {
  const [isSeeAll, setIsSeeAll] = useState(isOpen);

  const handleClick = () => {
    setIsSeeAll(!isSeeAll);
  };

  return (
    <Wrapper>
      <ParentCategories
        isSeeAll={isSeeAll}
        showCheckBox={showCheckBox}
        showCount={showCount}
      />
      <Toggle
        className="pt-2 text-center"
        onClick={handleClick}
        data-testid="see-all-toggle">
        {isSeeAll ? (
          <ToggleLink>{'> 閉じる'}</ToggleLink>
        ) : (
          <ToggleLink>{'> すべて見る'}</ToggleLink>
        )}
      </Toggle>
    </Wrapper>
  );
};

export default Category;
