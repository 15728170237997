// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.organization .organization-table {
    background-color: #fff;
    color: rgba(0,0,0,.87);
}

.organization .organization-table-inner {
    overflow-x: auto;
    overflow-y: hidden;
}

.organization .organization-table-inner > table {
    width: 100%;
    border: 1px solid #ccc !important;
    border-spacing: 0;
}

.organization .organization-table-inner > table > thead > tr > th {
    background-color: #fbe2ca;
    border: 1px solid #ccc !important;
    -webkit-user-select: none;
            user-select: none;
    font-size: .75rem;
    color: rgba(0,0,0,.6);
}

.organization .organization-table-inner > table > tbody > tr > td {
    border: 1px solid #ccc !important;
    -webkit-user-select: none;
            user-select: none;
    font-size: .875rem;
    color: rgba(0,0,0,.87);
    padding: 0 16px;
}

.organization .organization-table-body .action-button {
    font-size: 18px;
    text-align: center;
    padding: 10px 16px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Organization/organization-table.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,iCAAiC;IACjC,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,iCAAiC;IACjC,yBAAiB;YAAjB,iBAAiB;IACjB,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,iCAAiC;IACjC,yBAAiB;YAAjB,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".organization .organization-table {\n    background-color: #fff;\n    color: rgba(0,0,0,.87);\n}\n\n.organization .organization-table-inner {\n    overflow-x: auto;\n    overflow-y: hidden;\n}\n\n.organization .organization-table-inner > table {\n    width: 100%;\n    border: 1px solid #ccc !important;\n    border-spacing: 0;\n}\n\n.organization .organization-table-inner > table > thead > tr > th {\n    background-color: #fbe2ca;\n    border: 1px solid #ccc !important;\n    user-select: none;\n    font-size: .75rem;\n    color: rgba(0,0,0,.6);\n}\n\n.organization .organization-table-inner > table > tbody > tr > td {\n    border: 1px solid #ccc !important;\n    user-select: none;\n    font-size: .875rem;\n    color: rgba(0,0,0,.87);\n    padding: 0 16px;\n}\n\n.organization .organization-table-body .action-button {\n    font-size: 18px;\n    text-align: center;\n    padding: 10px 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
