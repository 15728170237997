const fetchUpdateMyList = async (id, userId, btnRef, setIsActive) => {
  try {
    btnRef.current.setAttribute('disabled', true);
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/properties/${id}/favorite`, {
      method: 'PUT',
      headers: {
        'user_id': userId
      },
    })
    if (!response.ok) {
      throw new Error("fetch did not work properly", response);
    }
    setIsActive(prevState => !prevState)
    btnRef.current.removeAttribute('disabled');
    console.log('update response', response);
  } catch (err) {
    // TODO: 各エラーに対する処理は後々追加検討
    console.error(err);
  }
}

export default fetchUpdateMyList;