import { useCalcScheduleMagnification } from 'interfaces/components/Schedule/service/hook/useCalcScheduleMagnification.js';
import { useDragScroll } from 'interfaces/components/Schedule/service/hook/useDragScroll.js';
import { useRef, useState } from 'react';

import ScheduleBody from './ScheduleBody.jsx';
import SelectMagnification from './SelectMagnification.jsx';
import 'interfaces/css/common/schedule.css';

const Schedule = ({ prefix, title, titleColor, termData, scheduleType }) => {
  const INIT_VIEW_MONTH_LENGTH = 2;
  const today = new Date();
  const thisYear = today.getFullYear();
  const scrollableRef = useRef(null);
  const { magnification, setViewMonthLength } = useCalcScheduleMagnification(
    scrollableRef,
    INIT_VIEW_MONTH_LENGTH,
  );
  const [targetYear, setTargetYear] = useState(today.getFullYear());
  const [selectMonth, setSelectMonth] = useState(INIT_VIEW_MONTH_LENGTH);
  useDragScroll(scrollableRef, 1);

  const status = '開催中のオークションはありません';
  if (termData?.status === status) {
    return (
      <div className="schedule">
        {title && (
          <div className="schedule-title" style={{ color: titleColor }}>
            {title}
          </div>
        )}
        <p className="text-center py-8 my-10">{status}</p>
      </div>
    );
  }

  const handleClick = (month) => {
    setSelectMonth(month);
    setViewMonthLength(month);
  };

  return (
    <div className="schedule">
      {title && (
        <div className="schedule-title" style={{ color: titleColor }}>
          {title}
        </div>
      )}
      <div className="flex" style={{ width: '100%' }}>
        {prefix && <div className="schedule-prefix">{prefix}</div>}
        <div className="schedule-wrap">
          <div className="flex justify-space-between">
            <div>{targetYear}年</div>
            <SelectMagnification
              year={thisYear}
              selectMonth={selectMonth}
              handleClick={handleClick}
            />
          </div>
          <ScheduleBody
            today={today}
            thisYear={thisYear}
            setTargetYear={setTargetYear}
            scrollableRef={scrollableRef}
            scheduleType={scheduleType}
            termData={termData}
            magnification={magnification}
            title={title}
          />
        </div>
      </div>
    </div>
  );
};

export default Schedule;
