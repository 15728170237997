
export class TaxType {
  #taxType

  constructor(taxType) {
    this.#taxType = taxType;
  }

  get() {
    return this.#taxType
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
