import styled from 'styled-components';

export const ModalButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  width: 100%;
  .new-style_navi_important-button,
  .new-style_navi_normal-button {
    font-size: 14px;
    height: 44px;
    padding: 0 8px;
}
`;