// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-btn {
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 500;
    letter-spacing: .0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0.0892857143em;
    text-transform: uppercase;
    transition-duration: .28s;
    transition-property: box-shadow,transform,opacity;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/components/Property/service/PropertyDetail/css/property-detail-item.css"],"names":[],"mappings":"AAAA;IAGI,mBAAmB;IACnB,kBAAkB;IAGlB,oBAAoB;IAGpB,cAAc;IACd,gBAAgB;IAChB,6BAA6B;IAG7B,uBAAuB;IACvB,UAAU;IACV,kBAAkB;IAClB,qBAAqB;IACrB,2BAA2B;IAC3B,yBAAyB;IAEzB,yBAAyB;IAGzB,iDAAiD;IAGjD,mDAAmD;IACnD,yBAAyB;IAGzB,iBAAiB;IACjB,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[".v-btn {\n    -webkit-box-align: center;\n    -ms-flex-align: center;\n    align-items: center;\n    border-radius: 4px;\n    display: -webkit-inline-box;\n    display: -ms-inline-flexbox;\n    display: inline-flex;\n    -webkit-box-flex: 0;\n    -ms-flex: 0 0 auto;\n    flex: 0 0 auto;\n    font-weight: 500;\n    letter-spacing: .0892857143em;\n    -webkit-box-pack: center;\n    -ms-flex-pack: center;\n    justify-content: center;\n    outline: 0;\n    position: relative;\n    text-decoration: none;\n    text-indent: 0.0892857143em;\n    text-transform: uppercase;\n    -webkit-transition-duration: .28s;\n    transition-duration: .28s;\n    -webkit-transition-property: opacity,-webkit-box-shadow,-webkit-transform;\n    transition-property: opacity,-webkit-box-shadow,-webkit-transform;\n    transition-property: box-shadow,transform,opacity;\n    transition-property: box-shadow,transform,opacity,-webkit-box-shadow,-webkit-transform;\n    -webkit-transition-timing-function: cubic-bezier(.4,0,.2,1);\n    transition-timing-function: cubic-bezier(.4,0,.2,1);\n    -webkit-user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n    vertical-align: middle;\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
