import ConfirmationBidsProvider from './ConfirmationBidsProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { confirmationBids } from "../entities/componentMapping.js";

const ConfirmationBids = ({ type }) => {
  return (
    <ConfirmationBidsProvider type={type}>
      {getComponent(confirmationBids, type)}
    </ConfirmationBidsProvider>
  );
};

export default ConfirmationBids;
