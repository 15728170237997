import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {ErrorContext} from './shared/context/ErrorContext.js';
import {KMANAGER, NAVI, SERVICE} from "./shared/utils/helper/constants.js";
import {MULTIPLE_CHOICES, OK, SERVICE_UNAVAILABLE} from "./shared/utils/helper/httpStatusCodes.js";

const ErrorProvider = ({type, children}) => {
  const navigate = useNavigate();
  const [statusCode, setStatusCode] = useState(null);
  const [message, setMessage] = useState('');

  const cleanup = () => {
    setStatusCode(null);
    setMessage('');
  };

  useEffect(() => {
    if (!statusCode) {
      return;
    }
    if (statusCode >= OK && statusCode < MULTIPLE_CHOICES) {
      return;
    }
    if (type === SERVICE) {
      navigate('/error-page', {state: {statusCode: statusCode}});
    }

    if (type === NAVI) {
      navigate('/error-page', {state: {statusCode: statusCode}});
    }

    if (type === KMANAGER) {
      navigate('/error-page', {state: {statusCode: statusCode}});
    }


    cleanup();

  }, [statusCode, message]);

  const setError = (err) => {
    if (err.response) {
      setStatusCode(err.response.status);
      setMessage(err.response.data.message);
    } else {
      setStatusCode(SERVICE_UNAVAILABLE);
    }
  };

  return (
    <ErrorContext.Provider value={{setError}}>
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;
