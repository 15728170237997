import { ReactComponent as ArrowUpwardIcon } from 'interfaces/assets/svg/arrow_upward_icon.svg';
import { ReactComponent as ArrowDownIcon } from 'interfaces/assets/svg/arrow-down.svg';
import React from 'react';
import styled from 'styled-components';

const SortArrow = styled.span`
  .arrow {
    visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
    width: 25px;
    height: 25px;
    padding-left: 5px;
  }
  &:hover .arrow {
    visibility: visible;
  }
`;
export const SortableTh = ({ header, sortConfig, onClick }) => {
  const selected = sortConfig.key === header.column;
  return (
    <th className="kmanager-th kmanager-table-th text-center">
      <div onClick={onClick}>
        <div className="align-center justify-center d-flex">
          {header.text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
          <SortArrow selected={selected}>
            {selected ? (
              sortConfig.direction === 'ascending' ? (
                <ArrowUpwardIcon className="arrow" title="arrow-upward-icon" />
              ) : (
                <ArrowDownIcon className="arrow" title="arrow-down-icon" />
              )
            ) : (
              <ArrowUpwardIcon className="arrow" title="arrow-upward-icon" />
            )}
          </SortArrow>
        </div>
      </div>
    </th>
  );
};
