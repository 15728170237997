// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sample-guideline .platform-dependent-characters {
  max-width: 850px;
}

.sample-guideline .platform-dependent-characters-image-wrap:not(:last-child):after {
  content: '、';
  margin-right: 3px;
}

.sample-guideline .platform-dependent-characters-image-wrap:last-child:after {
  content: '';
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/SampleGuideline/platform-dependent-characters.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".sample-guideline .platform-dependent-characters {\n  max-width: 850px;\n}\n\n.sample-guideline .platform-dependent-characters-image-wrap:not(:last-child):after {\n  content: '、';\n  margin-right: 3px;\n}\n\n.sample-guideline .platform-dependent-characters-image-wrap:last-child:after {\n  content: '';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
