import CommonInput from 'interfaces/components/common/Input/CommonInput';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel';
import handleValidationMessage from 'shared/utils/helper/handleValidationMessage';

const FullNameKanaInput = ({
  familyNameKana,
  firstNameKana,
  handleFamilyNameKanaChange,
  handleFirstNameKanaChange,
  familyNameKanaValidationMessage,
  firstNameKanaValidationMessage,
}) => {
  return (
    <tr>
      <th>
        氏名 カナ
        <RequiredLabel />
        <br />
        <small>法人の場合は代表者名</small>
      </th>
      <td>
        <div className="w-expand flex">
          <p className="flex align-center mr-4">姓</p>
          <div className="flex-grow-1">
            <CommonInput
              name="family-name-kana"
              value={familyNameKana}
              handleChange={handleFamilyNameKanaChange}
            />
            <small className="validate-message">
              {handleValidationMessage(familyNameKanaValidationMessage)}
            </small>
          </div>
          <div className="mx-3" />
          <p className="flex align-center mr-4">名</p>
          <div className="flex-grow-1">
            <CommonInput
              name="first-name-kana"
              value={firstNameKana}
              handleChange={handleFirstNameKanaChange}
            />
            <small className="validate-message">
              {handleValidationMessage(firstNameKanaValidationMessage)}
            </small>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default FullNameKanaInput;
