import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { findById } from '../../../../domain/Auction/services/Auction.js';
import { findByAuctionId as getInvitations } from '../../../../domain/Auction/services/Invitation.js';
import { findByIds as getGovernments } from '../../../../domain/Government/services/Government.js';
import { findByAuctionId as getProperties } from '../../../../domain/Property/services/Property.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { KMANAGER } from '../../../../shared/utils/helper/constants.js';
import { AuctionUsageFeeContext } from '../entities/AuctionUsageFeeContext.js';

const AuctionUsageFeeProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const { auctionId } = useParams();
  const [auction, setAuction] = useState([]);
  const [invitation, setInvitation] = useState([]);
  const [property, setProperty] = useState([]);
  const [government, setGovernment] = useState([]);
  //const [invoice, setInvoice] = useState([]);
  const [invoice] = useState([]);
  const [usageFees, setUsageFees] = useState([]);

  if (type === KMANAGER) {
    useEffect(() => {
      findById(type, auctionId).then((resultAuction) => {
        setAuction(resultAuction);
        getInvitations(type, auctionId).then((resultInvitations) => {
          setInvitation(resultInvitations);
          let governmentIds = [];
          resultInvitations.forEach((invitation) =>
            governmentIds.push(invitation.governmentId),
          );
          getGovernments(type, governmentIds).then((resultGovernments) => {
            setGovernment(resultGovernments);
            getProperties(type, auctionId).then((resultProperties) => {
              setProperty(resultProperties);

              // 表示用データ作成
              let usageFees = [];
              resultGovernments.forEach((government) => {
                let usageFee = {};
                usageFee.governmentId = government.id;
                usageFee.location = government.location;
                usageFee.governmentSubCategoryId =
                  government.governmentSubCategoryId;
                usageFee.region = government.region;
                usageFee.name = government.name;

                let invitation = resultInvitations.find(
                  (invitation) =>
                    invitation.governmentId.toString() ===
                    government.id.toString(),
                );
                usageFee.billingDate = invitation.billingDate;
                usageFee.estimatedSystemUsagePaymentDate =
                  invitation.estimatedSystemUsagePaymentDate;
                usageFee.systemUsagePaymentDate =
                  invitation.systemUsagePaymentDate;

                // システム利用料算出
                let feeAuction = 0;
                let feeBid = 0;
                let feeAuctionTax = 0;
                let feeBidTax = 0;
                let total = 0;
                let applyReductionCount = 0;
                let properties = resultProperties.filter(
                  (property) =>
                    property.governmentId.toString() ===
                    invitation.governmentId.toString(),
                );
                properties.forEach((property) => {
                  console.log(property);
                  if (property.publicSaleType === 'AUCTION') {
                    feeAuction += Number(property.bidSystemFee);
                    feeAuctionTax += Number(property.bidSystemFeeTax);
                  } else {
                    feeBid += Number(property.bidSystemFee);
                    feeBidTax += Number(property.bidSystemFeeTax);
                  }
                  total +=
                    Number(property.bidSystemFee) +
                    Number(property.bidSystemFeeTax);
                  if (property.isApplyReduction === 'TRUE') {
                    applyReductionCount += 1;
                  }
                });
                usageFee.systemUsageFeeAuction = feeAuction;
                usageFee.systemUsageFeeBid = feeBid;
                usageFee.systemUsageFeeAuctionTax = feeAuctionTax;
                usageFee.systemUsageFeeBidTax = feeBidTax;
                usageFee.systemUsageFeeTotal = total;
                usageFee.applyReductionCount = applyReductionCount;
                usageFees.push(usageFee);
              });
              setUsageFees(usageFees);
            });
          });
        });
      });
    }, [auctionId]);

    // TODO: domain/Property/entityにInvoiceを追加する
    // useEffect(() => {
    //   getInvoice(type).then(result => {
    //     setInvoice(result);
    //   });
    // }, []);
  }

  return (
    <AuctionUsageFeeContext.Provider
      value={{
        authState,
        governmentId,
        governmentType,
        procedureType,
        auction,
        invitation,
        property,
        government,
        invoice,
        usageFees,
      }}>
      {children}
    </AuctionUsageFeeContext.Provider>
  );
};

export default AuctionUsageFeeProvider;
