// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-input-wrap {
  flex: 1 1 auto;
}

.common-input-wrap-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.common-input-label {
  text-align: left;
  font-size: 0.875rem;
  color: var(--text-base);
  line-height: 1.375rem;
  margin-right: 14px;
}

.common-input-body {
  flex: 1 1 auto;
}

.common-input {
  flex: 1 1 auto;
  width: 100%;
  min-height: 56px;
  font-size: 1rem;
  padding: 0 12px;
  border-radius: 4px;
}

.common-input.normal-border {
  border: solid 1px rgba(0, 0, 0, 0.38);
}

.common-input.focus-border {
  border: solid 2px var(--primary-base);
}

.common-input.focus-error-border {
  border: solid 2px var(--error-base);
}

.common-input.blur-error-border {
  border: solid 1px var(--error-base);
}

.common-input-message {
  text-align: left;
  font-size: 12px;
  line-height: 1.4em;
}

.application-info-form-validate-message {
  color: var(--error-base)
}

.application-info-form-caption-message {
  color: rgba(0,0,0,.6);
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/common-input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE;AACF;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".common-input-wrap {\n  flex: 1 1 auto;\n}\n\n.common-input-wrap-flex {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n}\n\n.common-input-label {\n  text-align: left;\n  font-size: 0.875rem;\n  color: var(--text-base);\n  line-height: 1.375rem;\n  margin-right: 14px;\n}\n\n.common-input-body {\n  flex: 1 1 auto;\n}\n\n.common-input {\n  flex: 1 1 auto;\n  width: 100%;\n  min-height: 56px;\n  font-size: 1rem;\n  padding: 0 12px;\n  border-radius: 4px;\n}\n\n.common-input.normal-border {\n  border: solid 1px rgba(0, 0, 0, 0.38);\n}\n\n.common-input.focus-border {\n  border: solid 2px var(--primary-base);\n}\n\n.common-input.focus-error-border {\n  border: solid 2px var(--error-base);\n}\n\n.common-input.blur-error-border {\n  border: solid 1px var(--error-base);\n}\n\n.common-input-message {\n  text-align: left;\n  font-size: 12px;\n  line-height: 1.4em;\n}\n\n.application-info-form-validate-message {\n  color: var(--error-base)\n}\n\n.application-info-form-caption-message {\n  color: rgba(0,0,0,.6);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
