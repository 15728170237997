import Button from 'interfaces/components/common/Button/Button';
import { useState } from 'react';
import useModalControl from 'shared/hooks/useModalControl';

import ResetEmailModal from './ResetEmailModal';

const ResetEmailButton = () => {
  const { isOpen, handleOpen, handleClose } = useModalControl();
  const [modalType, setModalType] = useState('EMAIL');

  return (
    <div>
      <Button text="変更する" height="44px" onClick={handleOpen} />
      <ResetEmailModal
        isOpen={isOpen}
        handleClose={handleClose}
        modalType={modalType}
        setModalType={setModalType}
      />
    </div>
  );
};

export default ResetEmailButton;
