import SmallLightButton from 'interfaces/components/common/Button/SmallLightButton';
import Memo from 'interfaces/components/Memo/Memo';
import { BidderListContext } from 'interfaces/pages/BidderList/entities/BidderListContext';
import { useContext } from 'react';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { dateFormat } from 'shared/utils/helper/date_format';

import 'interfaces/css/BidderList/bidder-list-table.css';

const BidStatusList = {
  NOT_SELECTED: '未選択',
  ADD_APPLICABLE: '追加入札該当',
  ADD_DONE: '追加入札済み',
  TOP_PRICE: '最高価申込者',
  NEXT_PRICE: '次順位申込者',
  LOT_TOP_PRICE: 'くじ選定最高価申込者',
  LOT_NEXT_PRICE: 'くじ選定次順位申込者',
  ADD_TOP_PRICE: '追加入札最高価申込者',
  ADD_NEXT_PRICE: '追加入札次順位申込者',
  ADD_LOT_TOP_PRICE: '追加入札くじ選定最高価申込者',
  ADD_LOT_NEXT_PRICE: '追加入札くじ選定次順位申込者',
  CANCEL: '入札取り消し',
};

const BidderListTable = ({ tableData, type }) => {
  const { auction, government } = useContext(BidderListContext);
  const { localStorageProcedureType } = useLocalStorage();

  const today = new Date();
  let todayYear = today.getFullYear();
  let todayMonth = today.getMonth() + 1;
  let todayDate = today.getDate();
  let todayHour = today.getHours();
  let todayMinute = today.getMinutes();
  let todayString = `${todayYear}-${('00' + todayMonth).slice(-2)}-${('00' + todayDate).slice(-2)} ${('00' + todayHour).slice(-2)}:${('00' + todayMinute).slice(-2)}`;
  let displayLimitDateString = `${todayYear}-${('00' + (todayMonth + 1)).slice(-2)}-${('00' + todayDate).slice(-2)} ${('00' + todayHour).slice(-2)}:${('00' + todayMinute).slice(-2)}`;

  const cancelButton = (bidderData) => {
    if (
      government.governmentType === 'NATIONAL_TAX_AGENCY' ||
      localStorageProcedureType === 'ASSET_SALE'
    ) {
      if (auction.bidDecision < todayString) {
        return <></>;
      }

      // 公売の時
    } else {
      if (['TOP_PRICE', 'NEXT_PRICE'].includes(bidderData.bidStatus)) {
        if (auction.next_payment_limit < todayString) {
          return <></>;
        }
      } else {
        if (auction.bidDecision < todayString) {
          return <></>;
        }
      }
    }

    return <SmallLightButton text="入札取り消し" />;
  };

  return (
    <table className="bidder-list-table">
      <thead>
        <tr>
          <th>ステータス</th>
          <th>入札情報</th>
          <th>入札者情報</th>
        </tr>
      </thead>
      <tbody>
        {tableData &&
          tableData.map((d) => (
            <tr key={d.membershipId}>
              <td className="status">{BidStatusList[d.bidStatus]}</td>
              <td className="bidding-info">
                <dl>
                  <dt>トラッキングID</dt>
                  <dd>{d.trackingId}</dd>
                </dl>
                <dl>
                  <dt>申し込み日時</dt>
                  <dd>{d.tempAplDate}</dd>
                </dl>
                <dl>
                  <dt>入札価格</dt>
                  <dd>{d.price}</dd>
                </dl>
                <dl>
                  <dt>入札日時</dt>
                  <dd>{dateFormat(new Date(d.time), 'YYYY-MM-DD-hh-mm')}</dd>
                </dl>
                <dl>
                  <dt>納付方法</dt>
                  <dd>
                    {d.aplType === 'ONLINE' ? 'クレジットカード' : '銀行振込'}
                  </dd>
                </dl>
                <dl>
                  <dt>メモ</dt>
                  <dd>
                    <div className="memo-break-word">
                      <p className="memo-fixed-layout">{d.memo}</p>
                      <Memo isSmallLightButton={true} text="編集" />
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt>取り消し</dt>
                  <dd>
                    <div className="bid-cancel">{cancelButton(d)}</div>
                  </dd>
                </dl>
              </td>

              <td className="bidder-info">
                {auction.openEnd < displayLimitDateString ? (
                  <>
                    <dl>
                      <dt>会員識別番号</dt>
                      <dd>{d.membershipId}</dd>
                    </dl>
                    <dl>
                      <dt>住所</dt>
                      <dd>
                        {type !== 'CLIENT'
                          ? d.applicant.address
                          : d.client.address}
                      </dd>
                    </dl>
                    <dl>
                      <dt>氏名</dt>
                      <dd>
                        {type !== 'CLIENT' ? d.applicant.name : d.client.name}
                      </dd>
                    </dl>
                    <dl>
                      <dt>法人名</dt>
                      <dd>
                        {type !== 'CLIENT'
                          ? d.applicant.corpName
                          : d.client.corpName}
                      </dd>
                    </dl>
                    <dl>
                      <dt>メール</dt>
                      <dd>{d.email}</dd>
                    </dl>
                    <dl className="tel">
                      <dt>電話番号</dt>
                      <dd className="td-tel">
                        <dl>
                          <dt>自宅</dt>
                          <dd>
                            {type !== 'CLIENT' ? d.applicant.tel : d.client.tel}
                          </dd>
                        </dl>
                        <dl>
                          <dt>携帯</dt>
                          <dd>
                            {type !== 'CLIENT'
                              ? d.applicant.mobileTel
                              : d.client.mobileTel}
                          </dd>
                        </dl>
                      </dd>
                    </dl>
                    <dl>
                      <dt>性別</dt>
                      <dd>
                        {type !== 'CLIENT' ? d.applicant.sex : d.client.sex}
                      </dd>
                    </dl>
                    <dl>
                      <dt>生年月日</dt>
                      <dd>
                        {type !== 'CLIENT'
                          ? d.applicant.birthDate !== undefined
                            ? dateFormat(
                                new Date(d.applicant.birthDate),
                                'YYYY-MM-DD',
                              )
                            : ''
                          : d.client.birthDate !== undefined
                            ? dateFormat(
                                new Date(d.client.birthDate),
                                'YYYY-MM-DD',
                              )
                            : ''}
                      </dd>
                    </dl>
                    <dl>
                      <dt>共同入札</dt>
                      <dd>{d.isJointBid ? 'はい' : 'いいえ'}</dd>
                    </dl>
                    <dl>
                      <dt>代理人への委任</dt>
                      <dd>{d.isRepresentative === 'TRUE' ? 'はい' : ''}</dd>
                    </dl>
                  </>
                ) : (
                  <>
                    <table className="applicant-user-table">
                      <tbody>
                        <tr>
                          <td className="applicant-user-table-header">
                            会員識別番号
                          </td>
                          <td rowSpan="8">表示期間が終了しました</td>
                        </tr>
                        <tr>
                          <td>住所</td>
                        </tr>
                        <tr>
                          <td>氏名</td>
                        </tr>
                        <tr>
                          <td>法人名</td>
                        </tr>
                        <tr>
                          <td>メール</td>
                        </tr>
                        <tr>
                          <td>電話番号</td>
                        </tr>
                        <tr>
                          <td>性別</td>
                        </tr>
                        <tr>
                          <td>生年月日</td>
                        </tr>
                        <tr>
                          <td>共同入札</td>
                          <td>{d.isJointBid === 'TRUE' ? 'はい' : 'いいえ'}</td>
                        </tr>
                        <tr>
                          <td>代理人への委任</td>
                          <td>{d.representation}</td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default BidderListTable;
