import { validateInclude, validateRangeLength } from "../../../../application/validations.js";

export class PreviewUrl {
  #previewUrl

  constructor(previewUrl) {
    this.#previewUrl = previewUrl;
  }

  get() {
    return this.#previewUrl
  }

  get isValid() {
    return [
      this.validateIncludeUrl,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateIncludeUrl() {
    return validateInclude(this.#previewUrl, ['https://', 'http://'])
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#previewUrl, 0, 2048)
  }
}
