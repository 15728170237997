import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody';
import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle';
import 'interfaces/css/Government/official-character.css';

const OfficialCharacter = ({ src, name }) => {
  return (
    <div data-testid="official-character">
      <ArticleDefaultTitle title="公式キャラクター" />
      <ArticleDefaultBody>
        <div className="official-character-wrap ma-auto">
          <img
            src={src}
            alt="公式キャラクター"
            className="official-character-img"
            data-testid="official-character-img"
          />
        </div>
        <div
          className="flex justify-center"
          data-testid="official-character-name">
          {name}
        </div>
      </ArticleDefaultBody>
    </div>
  );
};

export default OfficialCharacter;
