// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.local-government-management-modal-message {
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 16px;
  text-align: center;
}

.local-government-management-modal-container {
  justify-content: center;
}

.local-government-management-modal-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto 30px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/LocalGovernmentManagement/local-government-modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB","sourcesContent":[".local-government-management-modal-message {\n  font-size: 16px;\n  margin-top: 40px;\n  margin-bottom: 16px;\n  text-align: center;\n}\n\n.local-government-management-modal-container {\n  justify-content: center;\n}\n\n.local-government-management-modal-button {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 15px auto 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
