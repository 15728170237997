import numberButton from 'interfaces/css/common/Pagination/page-number-button.module.css';
import pagination from "interfaces/css/common/Pagination/pagination.module.css";

import PageNumberButtons from './PageNumberButtons.jsx';
import { ReactComponent as NextIcon } from '../../../assets/svg/next_icon.svg';
import { ReactComponent as PreviousIcon } from '../../../assets/svg/previous_icon.svg';


const Pagination = ({ currentPage, pageCount, setCurrentPage, marginTop }) => {
  if (pageCount < 1) {
    return <div data-testid="pagination"></div>;
  }

  const toPreviousPage = () => {
    if (currentPage === 1) {
      return;
    }
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const toNextPage = () => {
    if (currentPage === pageCount) {
      return;
    }
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="flex justify-center" style={{ marginTop: marginTop }} data-testid="pagination">
      <div className={`${pagination.move_page_button} ${numberButton.page_number_button}`} onClick={toPreviousPage} data-testid="previous-page-button">
        <PreviousIcon className={`${currentPage === 1 && pagination.disabled_move_page_button}`}/>
      </div>
      <PageNumberButtons currentPage={currentPage} pageCount={pageCount} setCurrentPage={setCurrentPage}/>
      <div className={`${pagination.next_page_button} ${pagination.move_page_button} ${numberButton.page_number_button}`} onClick={toNextPage} data-testid="next-page-button">
        <NextIcon className={`${currentPage === pageCount && pagination.disabled_move_page_button}`}/>
      </div>
    </div>
  );
};

export default Pagination;
