// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import {AgencyPrefecture} from "./PropertyCategories/agencyPrefecture";
import {Attribute} from "./PropertyCategories/attribute";
import {CategoryName} from "./PropertyCategories/categoryName";
import {ChildCategory} from "./PropertyCategories/childCategory";
import {Depth} from "./PropertyCategories/depth";
import {DisplayCar} from "./PropertyCategories/displayCar";
import {DisplayEstate} from "./PropertyCategories/displayEstate";
import {DisplayProperty} from "./PropertyCategories/displayProperty";
import {DisplayService} from "./PropertyCategories/displayService";
import {GovernmentCategoryId} from "./PropertyCategories/governmentCategoryId";
import {Id} from "./PropertyCategories/id";
import {MinistryRegion} from "./PropertyCategories/ministryRegion";
import {Name} from "./PropertyCategories/name";
import {ParentId} from "./PropertyCategories/parentId";
import {PropertyIds} from "./PropertyCategories/propertyIds";
import {RelationshipCategoryIds} from "./PropertyCategories/relationshipCategoryIds";
import {SubCategoryName} from "./PropertyCategories/subCategoryName";
import CustomError from "../../../shared/utils/helper/customError.js";
import { NotUndefined } from "../../../shared/utils/helper/NotUndefined.js";

export class CategoryGet {

  // サービス毎の違いをBuilderで出しわけ？
  constructor(data) {
    this.depth = data.depth;
    this.displayCar = data.displayCar;
    this.displayEstate = data.displayEstate;
    this.displayProperty = data.displayProperty;
    this.displayService = data.displayService;
    this.id = data.id;
    this.name = data.name;
    this.parentId = data.parentId;
    this.childCategory = data.childCategory;
    this.propertyIds = data.propertyIds;
    this.relationshipCategoryIds = data.relationshipCategoryIds;
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return NotUndefined(this)
  }
}

export class PropertyCategoryGet {

  // サービス毎の違いをBuilderで出しわけ？
  constructor(data) {
    // 共通
    this.depth = data.depth;
    this.displayService = data.displayService;
    this.id = data.id;
    this.name = data.name;
    this.parentId = data.parentId;
    // service
    this.childCategory = data.childCategory;
    this.propertyIds = data.propertyIds;
    this.relationshipCategoryIds = data.relationshipCategoryIds;
    // navi
    this.displayCar = data.displayCar;
    this.displayEstate = data.displayEstate;
    this.displayProperty = data.displayProperty;
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return NotUndefined(this)
  }
}

export class GovernmentCategoryGet {

  constructor(data) {
    this.category_name = data.category_name;
    this.id = data.id;
    this.ministry_region = data.ministry_region;
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return NotUndefined(this)
  }
}

export class GovernmentSubCategoryGet {

  constructor(data) {
    this.agency_prefecture = data.agency_prefecture;
    this.attribute = data.attribute;
    this.government_category_id = data.government_category_id;
    this.id = data.id;
    this.sub_category_name = data.sub_category_name;
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return NotUndefined(this)
  }
}


export class CategoryCreate {

  // サービス毎の違いをBuilderで出しわけ？
  constructor(data) {
    this.depth = new Depth(data.depth);
    this.displayCar = new DisplayCar(data.displayCar);
    this.displayEstate = new DisplayEstate(data.displayEstate);
    this.displayProperty = new DisplayProperty(data.displayProperty);
    this.displayService = new DisplayService(data.displayService);
    this.id = new Id(data.id);
    this.name = new Name(data.name);
    this.parentId = new ParentId(data.parentId);
    this.childCategory = new ChildCategory(data.childCategory);
    this.propertyIds = new PropertyIds(data.propertyIds);
    this.relationshipCategoryIds = new RelationshipCategoryIds(data.relationshipCategoryIds);
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return this.depth.isValid &&
      this.displayCar.isValid &&
      this.displayEstate.isValid &&
      this.displayProperty.isValid &&
      this.displayService.isValid &&
      this.id.isValid &&
      this.name.isValid &&
      this.parentId.isValid &&
      this.childCategory.isValid &&
      this.propertyIds.isValid &&
      this.relationshipCategoryIds.isValid
  }
}

export class PropertyCategoryCreate {

  // サービス毎の違いをBuilderで出しわけ？
  constructor(data) {
    // 共通
    this.depth = new Depth(data.depth);
    this.displayService = new DisplayService(data.displayService);
    this.id = new Id(data.id);
    this.name = new Name(data.name);
    this.parentId = new ParentId(data.parentId);
    this.childCategory = new ChildCategory(data.childCategory);
    this.propertyIds = new PropertyIds(data.propertyIds);
    this.relationshipCategoryIds = new RelationshipCategoryIds(data.relationshipCategoryIds);
    this.displayCar = new DisplayCar(data.displayCar);
    this.displayEstate = new DisplayEstate(data.displayEstate);
    this.displayProperty = new DisplayProperty(data.displayProperty);
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return this.depth.isValid &&
      this.displayService.isValid &&
      this.id.isValid &&
      this.name.isValid &&
      this.parentId.isValid &&
      this.childCategory.isValid &&
      this.propertyIds.isValid &&
      this.relationshipCategoryIds.isValid &&
      this.displayCar.isValid &&
      this.displayEstate.isValid &&
      this.displayProperty.isValid
  }
}

export class GovernmentCategoryCreate {

  constructor(data) {
    this.category_name = new CategoryName(data.category_name);
    this.id = new Id(data.id);
    this.ministry_region = new MinistryRegion(data.ministry_region);
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return this.category_name.isValid &&
      this.id.isValid &&
      this.ministry_region.isValid
  }
}

export class GovernmentSubCategoryCreate {

  constructor(data) {
    this.agency_prefecture = new AgencyPrefecture(data.agency_prefecture);
    this.attribute = new Attribute(data.attribute);
    this.government_category_id = new GovernmentCategoryId(data.government_category_id);
    this.id = new Id(data.id);
    this.sub_category_name = new SubCategoryName(data.sub_category_name);
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return this.agencyPrefecture.isValid &&
      this.attribute.isValid &&
      this.governmentCategoryId.isValid &&
      this.id.isValid &&
      this.subCategoryName.isValid

  }
}
