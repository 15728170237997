import RadioInput from 'interfaces/components/common/Input/RadioInput.jsx';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel.jsx';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault.jsx';
import { memo } from 'react';

export const packingMethods = [
  { id: 1, label: '出品機関の職員に梱包を依頼する', value: 'REQUEST' },
  { id: 2, label: '自分で業者に依頼する', value: 'MYSELF' },
];

const SelectPackingMethodInput = memo(function SelectPackingMethodInput({
  targetPackingMethod,
  setTargetPackingMethod,
}) {
  return (
    <>
      <CommonTableDefault>
        <tr>
          <th>
            梱包について
            <RequiredLabel />
          </th>
          <td className="flex" data-testid="topic-input">
            {packingMethods.map((packingMethod) => (
              <RadioInput
                key={packingMethod.id}
                item={packingMethod}
                label={packingMethod.label}
                value={targetPackingMethod}
                handleClick={() => setTargetPackingMethod(packingMethod.value)}
              />
            ))}
          </td>
        </tr>
      </CommonTableDefault>
      <div className="topic-input-caption" data-testid="topic-input-caption">
        <p>【留意事項】</p>
        <p>
          1.
          公売担当者は専門的な梱包を行えないことから、専門的な梱包を希望される場合は、買受人において梱包業者を手配してください。
        </p>
        <p>
          2.
          公売財産によっては、出品機関の公売担当者が梱包できない場合があります。
        </p>
      </div>
    </>
  );
});

export default SelectPackingMethodInput;
