
export class NextWinner {
    #nextWinner

    constructor(nextWinner) {
        this.#nextWinner = nextWinner;
    }

    get() {
        return this.#nextWinner
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
