import GovernmentUserEntryProvider from './GovernmentUserEntryProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { governmentUserEntry } from "../entities/componentMapping.js";

const GovernmentUserEntry = ({ type }) => {
  return (
    <GovernmentUserEntryProvider type={type}>
      {getComponent(governmentUserEntry, type)}
    </GovernmentUserEntryProvider>
  );
};

export default GovernmentUserEntry;
