import {
  bank_provisional_apply_completion_description1
} from "shared/utils/helper/DummyEditFormData";

import FormTextWithCaution from "../../Form/navi/FormTextWithCaution";

const AuctionForm = ({validations}) => {
  return(
    <div className="pt-9">
      <div className="property-info-general-form-table">
        <FormTextWithCaution
          label={"銀行振込など案内ページURL"}
          required={true}
          caution_description={bank_provisional_apply_completion_description1}
          data={validations.data.auctionBankTransferUrl}
          handler1={validations.setters.handleAuctionBankTransferUrl}
          invalidMessage={validations.validationMessage.auctionBankTransferUrlRequiredValid}
        />
      </div>
    </div>
  )
}

export default AuctionForm