import styled from 'styled-components';

import CautionDescription from './CautionDescription';
import Label from './Label';
import ValidationMessage from './ValidationMessage';

import 'interfaces/css/common/property-info-general-form-input-number.css';

const TableRow = styled.div`
  display: table-row;
`;

const TableContents = styled.div`
  padding: 12px;
  display: table-cell;
  width: 70%;
  background-color: var(--heading);
  vertical-align: middle;
  border-bottom: 1px solid #ccc;
  white-space: pre-wrap;
`;

const TextInput = styled.input`
  font-size: 16px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  line-height: 20px;
  #padding: 8px 0;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border: ${(props) =>
    props.$validation
      ? '2px solid #ff5252 !important'
      : '1px solid rgba(0, 0, 0, .42)'};
  border-radius: 4px;
  padding: 8px 12px;
`;

const FormArea = styled.div`
  display: flex;
  align-items: center;
`;

const AfterFormWord = styled.span`
  margin-left: 10px;
`;

const FormNumberWithCaution = ({
  invalidMessage,
  label,
  caution_description,
  required,
  width = '100',
  state,
  handler1 = () => {},
  afterFormWord = undefined,
  isDisabled = false,
  min = 0,
}) => {
  const changeHandler = (e) => {
    handler1(e);
  };

  return (
    <TableRow>
      <Label label={label} required={required} />
      <TableContents>
        <div className={`property-input-number-size-${width}`}>
          <FormArea>
            <TextInput
              onChange={changeHandler}
              min={min}
              type="number"
              value={state}
              disabled={isDisabled === true}
            />
            {afterFormWord !== undefined ? (
              <AfterFormWord>{`${afterFormWord}`}</AfterFormWord>
            ) : (
              <></>
            )}
          </FormArea>
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
        <CautionDescription data={caution_description} />
      </TableContents>
    </TableRow>
  );
};

export default FormNumberWithCaution;
