// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createNaviBlockListsData,
  deleteNaviBlockListsData,
  updateNaviBlockListsData
} from "../../../infrastructure/api/NaviBlockLists.js";
import { compareData } from "../../../shared/utils/helper/compareData.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { BlockListCreate } from "../entities/List.js";
import { getBlockList } from "../repositories/BlockList.js";

export async function get(type) {
  return await getBlockList(type)
}

export async function findByGovernmentId(type, governmentId) {
  const list = await getBlockList(type);
  return list.filter(data => data.governmentId.toString() === governmentId.toString())
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (type === NAVI) {
    const blockList = new BlockListCreate(data)
    response = await createNaviBlockListsData(blockList);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create List', response);
  }
  return response
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (type === NAVI) {
    const blockList = new BlockListCreate(data)
    response = await updateNaviBlockListsData(blockList, blockList.government_id, blockList.auction_user_id);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update List', response);
  }
  return response
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (type === NAVI) {
    response = deleteNaviBlockListsData(data.id);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (List)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete List', response);
  }
  return response
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]))
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]))
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value)
}
