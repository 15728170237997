import pino from 'pino';

import { BASE_URL_NAVIGATION } from '../../../config/envConstants.js';
import { fetchData } from '../../../shared/utils/helper/fetchData.js';

const logger = pino();
const EMAIL_TEMPLATES = `${BASE_URL_NAVIGATION}/storage/infos/mail_templates.json`;

export const fetchEmailTemplates = async (mail_type) => {
  try {
    const response = await fetchEmailTemplatesTopPage(EMAIL_TEMPLATES);
    const template = response.find(
      (template) => template.Template.TemplateName === mail_type,
    );
    return extractEmailTemplates(template);
  } catch (error) {
    logger.warn(
      { error },
      'テンプート（ mail-templates ）を取得できませんでした',
    );
  }
};

export const fetchEmailTemplatesTopPage = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    // console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    // throw error;
  }
};

export const extractEmailTemplates = (response) => {
  return response.Template.TextPart;
};
