import { CustomMdEditor } from 'interfaces/components/common/MarkDown/CustomMdEditor';
import ValidationMessage from 'interfaces/components/Form/navi/ValidationMessage';
import 'interfaces/css/common/property-form-input-text.css';
import 'react-markdown-editor-lite/lib/index.css';

const PropertyFormInputText = ({
  invalidMessage,
  number_remaining_characters,
  className,
  data = '',
  maxCharacters = undefined,
  handler1,
}) => {
  function onInputChange({ text }) {
    handler1({ text });
  }

  const calcRemainCharacters = () => {
    if (maxCharacters === undefined) {
      return '';
    }
    if (data === undefined) {
      return maxCharacters;
    } else {
      return maxCharacters - data.length;
    }
  };

  return (
    <div className={className}>
      <CustomMdEditor
        onChange={onInputChange}
        value={data}
        isValid={invalidMessage === 'TRUE' || invalidMessage === null}
      />
      <ValidationMessage invalidMessage={invalidMessage} />
      {number_remaining_characters ? (
        <div className="property-form-input-mark-down-absolute-area">
          <p
            data-testid="number-remaining-characters"
            className="number-remaining-characters">
            {'残り' + number_remaining_characters + '文字'}
          </p>
        </div>
      ) : (
        ''
      )}
      {maxCharacters ? (
        <div className="property-form-input-text-absolute-area">
          <p className="number-remaining-characters">
            {'残り' + calcRemainCharacters() + '文字'}
          </p>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default PropertyFormInputText;
