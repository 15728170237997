
export class PropertyBidSystemFeeRate {
  #propertyBidSystemFeeRate

  constructor(propertyBidSystemFeeRate) {
    this.#propertyBidSystemFeeRate = propertyBidSystemFeeRate;
  }

  get() {
    return this.#propertyBidSystemFeeRate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
