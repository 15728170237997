//import { useNavigate } from "react-router-dom";
import CancelButton from "interfaces/components/common/Button/CancelButton";
import CreateButton from "interfaces/components/common/Button/CreateButton";
import ButtonList from "interfaces/components/common/ButtonList";
import PageTitle from "interfaces/components/common/PageTitle";
import KmanagerTableRowHeaderData from "interfaces/components/common/Table/KmanagerTableRowHeaderData";
//import { putApiData } from "../../api/request";
//import { bodyToSnake, createBody } from "../../api/convertData";
import 'interfaces/css/common/kmanager-table.css'

const ServiceProvidersConfirm = ({ type = 'create', data, closeModal, endConfirm, id = 0, }) => {
  //const inputBody = createBody(data)
  //const navigation = useNavigate()

  const Put = async () => {
    console.log(`tood: put service provider id: ${id}`);
    //await putApiData(`management-service-provider/${id}`, bodyToSnake(inputBody))
    //  .then(() => {
    //    closeModal()
    //    // navigation(0)
    //  })
    //  .catch((err) => {
    //    console.log(err)
    //  })
    closeModal()
  }

  const TitleText = () => {
    if (type === 'create') {
      return '下記内容で作成します。よろしければ作成ボタンをクリックしてください'
    } else if (type === 'edit') {
      return '下記内容で編集します。よろしければ編集ボタンをクリックしてください'
    }
  }

  return (
    <div className="kmanager-col-12 pa-5 box-shadow" style={{ maxWidth: '1000px' }}>
      <PageTitle text={TitleText()}/>
      <div className="kmanager-table-wrap">
        <table className="kmanager-table kmanager-table-hover" data-testid="kmanager-government-user-confirm-table">
          <tbody>
          {
            data.map((data, index) => {
              return (<KmanagerTableRowHeaderData key={index} data={data}/>)
            })
          }
          </tbody>
        </table>
      </div>
      <ButtonList>
        <div className="d-flex justify-center mt-10">
          <CancelButton clickHandler={endConfirm}/>
          <CreateButton inputs={data} clickHandler={Put}/>
        </div>
      </ButtonList>
    </div>
  );
};

export default ServiceProvidersConfirm;
