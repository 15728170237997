import { createContext } from 'react';

import {
  gov_procedure_types,
  gov_procedure_types_for_national_tax_agency,
  gov_types
} from "./governmentRadioLabels.js";
import { government_categories, government_sub_categories } from "./governmentSelectBox.js";
import { FORGET_PASSWORD, LOGIN, SIGN_UP } from "../../../../shared/utils/helper/authState.js";
import { DATA_EMPTY } from "../../../../shared/utils/helper/constants.js";

export const GovernmentNewContext = createContext({
  // Default Value
  authState: {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD
  },
  radioLabelData: {
    govTypes: gov_types,
    govProcedureTypes: gov_procedure_types,
    govProcedureTypesForNationalTaxAgency: gov_procedure_types_for_national_tax_agency
  },
  selectBoxData: {
    governmentCategories: government_categories,
    governmentSubCategories: government_sub_categories,
  },
  government: DATA_EMPTY,
});
