import pino from 'pino';

import { BASE_URL_NAVIGATION } from '../../../config/envConstants.js';
import { fetchData } from '../../../shared/utils/helper/fetchData.js';
const logger = pino();

const CURRENT_INVITATIONS_PATH = `${BASE_URL_NAVIGATION}/storage/current/invitations/data.json`;
const PREVIOUS_INVITATIONS_PATH = `${BASE_URL_NAVIGATION}/storage/previous/invitations/data.json`;

export const getInvitations = async () => {
  try {
    const response = await fetchInvitations(CURRENT_INVITATIONS_PATH);
    return extractInvitations(response);
  } catch (error) {
    logger.warn({ error }, '現在の通知（ invitations ）を取得できませんでした');

    try {
      const response = await fetchInvitations(PREVIOUS_INVITATIONS_PATH);
      return extractInvitations(response);
    } catch (error) {
      logger.error(
        { error },
        '以前の通知（ invitations ）の取得にも失敗しました',
      );
      return [];
    }
  }
};

export const fetchInvitations = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
};

export const extractInvitations = (response) => {
  return response.map((invitation) => invitation);
};
