/*
  TODO: PopUpコンポーネントを、PopUpの範囲外をクリックした際に閉じるようにする
    ポップアップ外をクリックした際にsetDisplay(false)を実行する

  test: react/src/service/__tests__/components/../common/Header/HeaderTop/e_HeaderTop.test
 */

import { useContext, useState } from 'react';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

import AuthButton from './AuthButton';
import IconButton from './IconButton';
import PopUp from './Popup/PopUp';
import { ReactComponent as BeginnerIcon } from '../../../../../assets/svg/beginner_icon.svg';
import { ReactComponent as MyMenuIcon } from '../../../../../assets/svg/my_menu.svg';
import { DashboardContext } from '../../../../../pages/Dashboard/entities/DashboardContext';
import AuctionIcon from '../AuctionIcon';
import AuctionLogo from '../AuctionLogo';
//import { AuthContext } from '../../../../../../shared/context/AuthContext';

import { mediaQuery, useMediaQuery } from 'shared/hooks/useResponsive';
import 'interfaces/css/common/header-top.css';

const HeaderTop = () => {
  const { authState } = useContext(DashboardContext);
  const [display, setDisplay] = useState(false);
  const { localStorageUserId } = useLocalStorage();
  const widthOver720 = useMediaQuery(mediaQuery.widthOver720);

  const isLoggedIn = () => {
    return localStorageUserId !== null;
  };

  const handleClick = () => {
    setDisplay((prev) => !prev);
  };

  return (
    <div className="header-top flex align-items-center flex-wrap-nowrap justify-space-between">
      <AuctionLogo />
      <div className="flex">
        <div className="flex">
          {!isLoggedIn() && (
            <AuthButton text="新規登録" authStatus={authState.SIGN_UP} />
          )}
          {!isLoggedIn() && (
            <AuthButton
              text="ログイン"
              isOutline={true}
              authStatus={authState.LOGIN}
            />
          )}
        </div>
        {widthOver720 && (
          <div className="ml-6">
            <AuctionIcon
              svg={<BeginnerIcon title="beginner" />}
              testId="icon-beginner"
              label="初めての方へ"
              link={'https://www.pages.kankocho.jp/guide'}
              isTargetBlank={true}
            />
          </div>
        )}

        {isLoggedIn() === true ? (
          <IconButton
            handleClick={handleClick}
            svg={<MyMenuIcon title="my-menu" />}
            label="マイメニュー"
            className="ml-7"
          />
        ) : (
          <></>
        )}
      </div>
      {display && <PopUp handleClose={handleClick} />}
    </div>
  );
};

export default HeaderTop;
