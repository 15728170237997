// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application-info-form-input-group {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.application-info-form-postalcode-input-group {
  display: flex;
  align-items: flex-start;
}

.application-info-form-postalcode-input-group .input-wrap {
  max-width: 120px;
}

.application-info-form-postalcode-input-group .input-wrap:first-child {
  max-width: 100px;
}

.application-info-form-tel-input-group .input-wrap {
  width: 100%;
}

.application-info-form-tel-input-group .input-wrap:first-child {
  margin-bottom: 14px;
}

.application-info-form-divide-hyphen {
  display: flex;
  height: 56px;
  align-items: center;
  margin: 0 14px;
}

.application-info-form-input-group .input-wrap:first-child {
  margin-right: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/application-info-form-input.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".application-info-form-input-group {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n\n.application-info-form-postalcode-input-group {\n  display: flex;\n  align-items: flex-start;\n}\n\n.application-info-form-postalcode-input-group .input-wrap {\n  max-width: 120px;\n}\n\n.application-info-form-postalcode-input-group .input-wrap:first-child {\n  max-width: 100px;\n}\n\n.application-info-form-tel-input-group .input-wrap {\n  width: 100%;\n}\n\n.application-info-form-tel-input-group .input-wrap:first-child {\n  margin-bottom: 14px;\n}\n\n.application-info-form-divide-hyphen {\n  display: flex;\n  height: 56px;\n  align-items: center;\n  margin: 0 14px;\n}\n\n.application-info-form-input-group .input-wrap:first-child {\n  margin-right: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
