import { useState } from 'react';

import AuthModal from './AuthModal.jsx';
import useModalControl from '../../../../../../shared/hooks/useModalControl.js';
import Button from '../../../../common/Button/Button.jsx';


const AuthButton = ({ text, isOutline, authStatus }) => {
  const { isOpen, handleOpen, handleClose } = useModalControl();
  const [currentAuthStatus, setCurrentAuthStatus] = useState(null);

  const handleClick = () => {
    setCurrentAuthStatus(authStatus);
    handleOpen();
  };

  const switchAuthStatus = (authStatus) => {
    setCurrentAuthStatus(authStatus);
  };

  const className = text === '新規登録' ? 'sign-up-button' : 'login-button';

  return (
    <div data-testid="auth-button-wrap">
      <Button
        text={text}
        onClick={handleClick}
        isOutline={isOutline}
        className={className}
      />
      <AuthModal
        isOpen={isOpen}
        handleClose={handleClose}
        currentAuthStatus={currentAuthStatus}
        switchAuthStatus={switchAuthStatus}
      />
    </div>
  );
};

export default AuthButton;
