import LocalGovernmentManagementCreditProvider from './LocalGovernmentManagementCreditProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { localGovernmentManagementCredit } from "../entities/componentMapping.js";

const LocalGovernmentManagementCredit = ({ type }) => {
  return (
    <LocalGovernmentManagementCreditProvider type={type}>
      {getComponent(localGovernmentManagementCredit, type)}
    </LocalGovernmentManagementCreditProvider>
  );
};

export default LocalGovernmentManagementCredit;
