// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
  display: block;
  flex: 1 1;
  max-width: 100%;
  height: 0;
  max-height: 0;
  border: solid;
  border-width: thin 0 0;
  border-color: rgba(0,0,0,.12);
  transition: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/divider.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,SAAW;EACX,eAAe;EACf,SAAS;EACT,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,mBAAmB;AACrB","sourcesContent":[".divider {\n  display: block;\n  flex: 1 1 0;\n  max-width: 100%;\n  height: 0;\n  max-height: 0;\n  border: solid;\n  border-width: thin 0 0;\n  border-color: rgba(0,0,0,.12);\n  transition: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
