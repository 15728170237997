import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class PrivateRoad {
  #privateRoad

  constructor(privateRoad) {
    this.#privateRoad = privateRoad;
  }

  get() {
    return this.#privateRoad
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#privateRoad, 0, 400)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#privateRoad)
  }
}
