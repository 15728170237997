import { useState } from "react";

export function useAuctionEntryInputs() {
  const [invitationName, setInvitationName] = useState("");
  const [bidEndType, setBidEndType] = useState("AUTO");
  const [mailCertDocument, setMailCertDocument] = useState("NO_MAIL");
  const [depositDeliveryStatus, setDepositDeliveryStatus] = useState({
    paymentCreditCard: "",
    paymentBankTransfer: "",
    noneDeposit: "",
  });
  const [aplCountAuctionDisplay, setAplCountAuctionDisplay] = useState("DISPLAY");
  const [governmentId, setGovernmentId] = useState("");
  const [area, setArea] = useState("");
  const [contactOffice, setContactOffice] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactTel, setContactTel] = useState("");

  const auctionEntryInputs = {
    type: {
      invitationName: 'text',
      bidEndType: 'radio',
      mailCertDocument: 'radio',
      depositDeliveryStatus: 'check',
      aplCountAuctionDisplay: 'radio',
      governmentId: 'select',
      area: 'text',
      contactOffice: 'text',
      contactEmail: 'text',
      contactTel: 'text',
    },
    data: {
      invitationName,
      bidEndType,
      mailCertDocument,
      depositDeliveryStatus,
      aplCountAuctionDisplay,
      governmentId,
      area,
      contactOffice,
      contactEmail,
      contactTel,
    },
    setters: {
      invitationName: setInvitationName,
      bidEndType: setBidEndType,
      mailCertDocument: setMailCertDocument,
      depositDeliveryStatus: setDepositDeliveryStatus,
      aplCountAuctionDisplay: setAplCountAuctionDisplay,
      governmentId: setGovernmentId,
      area: setArea,
      contactOffice: setContactOffice,
      contactEmail: setContactEmail,
      contactTel: setContactTel,
    },
  }
  return auctionEntryInputs;
}

