import { ReactComponent as ArrowRightIcon } from 'interfaces/assets/svg/arrow_back_icon.svg';
import BackButton from 'interfaces/components/common/BackButton';
import PageTitle from 'interfaces/components/common/PageTitle';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import GovernmentDepositTable from './GovernmentDepositTable';
import { GovernmentDepositContext } from '../../../../pages/GovernmentDeposit/entities/GovernmentDepositContext';

import Toolbar from 'interfaces/components/common/Toolbar';
import PropertySwitcher from 'interfaces/components/common/Switcher/PropertySwitcher';

const GovernmentDeposits = () => {
  const navigation = useNavigate();
  const { auctionId } = useParams();
  const { auction, government } = useContext(GovernmentDepositContext);
  const AUCTION_PATH = `/kmanager/auction/${auctionId}/gov`;

  //const setting_url = (url) => {
  //  return `/kmanager/auction/${auctionId}/${governmentId}/${url}`
  //}

  //const AUCTION_GOVERNMENT_PROPERTY_PATH = setting_url('property');
  //const AUCTION_GOVERNMENT_DEPOSIT_PATH = setting_url('deposit');
  //const AUCTION_GOVERNMENT_PAYMENT_PATH = setting_url('payment');
  //const TAB_LABELS = [
  //  {
  //    text: '物件一覧',
  //    handleClick: () => {
  //      navigation(AUCTION_GOVERNMENT_PROPERTY_PATH)
  //    }
  //  },
  //  {
  //    text: '保証金確認',
  //    handleClick: () => {
  //      navigation(AUCTION_GOVERNMENT_DEPOSIT_PATH)
  //    }
  //  },
  //  {
  //    text: '請求管理確認',
  //    handleClick: () => {
  //      navigation(AUCTION_GOVERNMENT_PAYMENT_PATH)
  //    }
  //  },
  //];

  return (
    <div
      className="kmanager-government-deposits kmanager-auction-operation"
      data-testid="kmanager-government-deposits">
      <div className="kmanager-row">
        <div className="kmanager-col kmanager-col-6">
          <BackButton
            text={auction.name}
            svg={<ArrowRightIcon title="back-to-page" />}
            handleClick={() => navigation(AUCTION_PATH)}
          />
          <PageTitle text={government.name} />
          <Toolbar>
            <PropertySwitcher
              auctionId={auction.id}
              governmentId={government.id}
              activeIndex={1}
            />
          </Toolbar>
        </div>
        <div className="kmanager-col kmanager-col-12">
          <div className="box-shadow">
            <GovernmentDepositTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GovernmentDeposits;
