import Switcher from "interfaces/components/common/Switcher";
import AuctionForm from "interfaces/components/LocalGovernmentManagement/credit/AuctionForm";
import BidForm from "interfaces/components/LocalGovernmentManagement/credit/BidForm";
import { useState } from 'react';

const IndividualForm = ({validations, procedureType, governmentType}) => {
  const [activeBidTab, setActiveBidTab] = useState(0);
  const labels = [
    'せり売形式',
    '入札形式'
  ];

  return (
    <form data-testid="card-provisional-apply-completion-form">
      <Switcher labels={labels} activeTabIndex={activeBidTab} setActiveTab={setActiveBidTab}/>
      { activeBidTab === 0 && <AuctionForm validations={validations} procedureType={procedureType} governmentType={governmentType}/> }
      { activeBidTab === 1 && <BidForm validations={validations} procedureType={procedureType} governmentType={governmentType}/> }
    </form>
  );
}

export default IndividualForm