
export class SummaryPurpose {
    #summaryPurpose

    constructor(summaryPurpose) {
        this.#summaryPurpose = summaryPurpose;
    }

    get() {
        return this.#summaryPurpose
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
