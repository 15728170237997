
export class Type {
  #type

  constructor(type) {
    this.#type = type;
  }

  get() {
    return this.#type
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
