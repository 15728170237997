import Button from 'interfaces/components/common/Button/Button';
import Toggle from 'interfaces/components/common/Toggle/Toggle';
import { validateInputsAfterWinningPublicSaleBidCaution } from 'interfaces/components/common/validateInputs/validateInputsAfterWinningPublicSaleBidCaution';
import BothForm from 'interfaces/components/LocalGovernmentManagement/after_winning/public/BothForm';
import IndividualForm from 'interfaces/components/LocalGovernmentManagement/after_winning/public/IndividualForm';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePublicSaleCautionPages } from 'shared/hooks/usePublicSaleCautionPages';
import { useLocalStorage } from '../../../shared/hooks/useLocalStorage';
//import configs from "../../api/configs/afterWinningBidCaution";
//import { allFetch } from "../../api/newEntry";

const AfterWinningPublicSaleBidCautionForm = ({ setIsOpen }) => {
  const auction = validateInputsAfterWinningPublicSaleBidCaution();
  const bid = validateInputsAfterWinningPublicSaleBidCaution();

  const { localStorageGovernmentId, localStorageGovernmentType } =
    useLocalStorage();

  // eslint-disable-next-line no-unused-vars
  const { auctionPublicSaleCautionPages, bidPublicSaleCautionPages, loading } =
    usePublicSaleCautionPages(Number(localStorageGovernmentId));
  const [indivisualSetting, setIndivisualSetting] = useState(false);

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const validateMessages = (form) => {
      const { validationMessage, data } = form;
      return Object.entries(validationMessage).every(
        ([key, validationMessage]) => {
          const requiredKeys = [
            data?.auctionCostPropertyDisplay === 'DISPLAY' &&
              'auctionMovableDescriptionValid',
            data?.auctionCostCarDisplay === 'DISPLAY' &&
              'auctionCarDescriptionValid',
            data?.auctionCostEstateDisplay === 'DISPLAY' &&
              'auctionEstateDescriptionValid',
            data?.auctionDocumentPropertyDisplay === 'DISPLAY' &&
              'auctionDocumentPropertyDisplay',
            data?.auctionDocumentCarDisplay === 'DISPLAY' &&
              'auctionDocumentCarDisplay',
            data?.auctionDocumentEstateDisplay === 'DISPLAY' &&
              'auctionDocumentEstateDisplay',
            data?.auctionTransRightPropertyDisplay === 'DISPLAY' &&
              'auctionTransRightPropertyDisplay',
            data?.auctionTransRightCarDisplay === 'DISPLAY' &&
              'auctionTransRightCarDisplay',
            data?.auctionTransRightEstateDisplay === 'DISPLAY' &&
              'auctionTransRightEstateDisplay',
            data?.auctionInquiryDisplay === 'DISPLAY' &&
              'auctionInquiryNameValid',
            data?.auctionDocumentPageDisplay === 'DISPLAY' &&
              'auctionDocumentNameValid',
            data?.auctionDocumentPageDisplay === 'DISPLAY' && 'auctionUrlValid',
          ].filter(Boolean);
          if (requiredKeys.includes(key)) {
            return validationMessage === 'TRUE';
          }
          return validationMessage === null || validationMessage === 'TRUE';
        },
      );
    };

    if (indivisualSetting) {
      setIsValid(validateMessages(auction) && validateMessages(bid));
    } else {
      setIsValid(validateMessages(auction));
    }
  }, [auction, bid, indivisualSetting]);

  // 入力欄の初期値を設定する
  useEffect(() => {
    auction.init.setAuctionTransRightDescription(
      auctionPublicSaleCautionPages[0]?.transRightDescription,
    );
    auction.init.setAuctionCostPropertyDisplay(
      auctionPublicSaleCautionPages[0]?.costPropertyDisplay ?? 'DISPLAY',
    );
    auction.init.setAuctionCostPropertyDescription(
      auctionPublicSaleCautionPages[0]?.costPropertyDescription ?? '',
    );
    auction.init.setAuctionCostCarDisplay(
      auctionPublicSaleCautionPages[0]?.costCarDisplay ?? 'DISPLAY',
    );
    auction.init.setAuctionCostCarDescription(
      auctionPublicSaleCautionPages[0]?.costCarDescription ?? '',
    );
    auction.init.setAuctionCostEstateDisplay(
      auctionPublicSaleCautionPages[0]?.costEstateDisplay ?? 'DISPLAY',
    );
    auction.init.setAuctionCostEstateDescription(
      auctionPublicSaleCautionPages[0]?.costEstateDescription ?? '',
    );
    auction.init.setAuctionCostAttention(
      auctionPublicSaleCautionPages[0]?.costAttention ?? '',
    );
    auction.init.setAuctionDocumentPageDisplay(
      auctionPublicSaleCautionPages[0]?.documentPageDisplay ?? 'DISPLAY',
    );
    auction.init.setAuctionDocumentPageName(
      auctionPublicSaleCautionPages[0]?.documentPageName ?? '',
    );
    auction.init.setAuctionDocumentPageUrl(
      auctionPublicSaleCautionPages[0]?.documentPageUrl ?? '',
    );
    auction.init.setAuctionDocumentPropertyDisplay(
      auctionPublicSaleCautionPages[0]?.documentPropertyDisplay ?? 'DISPLAY',
    );
    auction.init.setAuctionDocumentPropertyDescription(
      auctionPublicSaleCautionPages[0]?.documentPropertyDescription ?? '',
    );
    auction.init.setAuctionDocumentCarDisplay(
      auctionPublicSaleCautionPages[0]?.documentCarDisplay ?? 'DISPLAY',
    );
    auction.init.setAuctionDocumentCarDescription(
      auctionPublicSaleCautionPages[0]?.documentCarDescription ?? '',
    );
    auction.init.setAuctionDocumentEstateDisplay(
      auctionPublicSaleCautionPages[0]?.documentEstateDisplay ?? 'DISPLAY',
    );
    auction.init.setAuctionDocumentEstateDescription(
      auctionPublicSaleCautionPages[0]?.documentEstateDescription ?? '',
    );
    auction.init.setAuctionDocumentAttention(
      auctionPublicSaleCautionPages[0]?.documentAttention,
    );
    auction.init.setAuctionTransRightPropertyDisplay(
      auctionPublicSaleCautionPages[0]?.transRightPropertyDisplay ?? 'DISPLAY',
    );
    auction.init.setAuctionTransRightPropertyDescription(
      auctionPublicSaleCautionPages[0]?.transRightPropertyDescription ?? '',
    );
    auction.init.setAuctionTransRightCarDisplay(
      auctionPublicSaleCautionPages[0]?.transRightCarDisplay ?? 'DISPLAY',
    );
    auction.init.setAuctionTransRightCarDescription(
      auctionPublicSaleCautionPages[0]?.transRightCarDescription ?? '',
    );
    auction.init.setAuctionTransRightEstateDisplay(
      auctionPublicSaleCautionPages[0]?.transRightEstateDisplay ?? 'DISPLAY',
    );
    auction.init.setAuctionTransRightEstateDescription(
      auctionPublicSaleCautionPages[0]?.transRightEstateDescription ?? '',
    );
    auction.init.setAuctionTransRightAttention(
      auctionPublicSaleCautionPages[0]?.transRightAttention,
    );
    auction.init.setAuctionOtherTransRightDescription(
      auctionPublicSaleCautionPages[0]?.otherTransRightDescription,
    );
    auction.init.setAuctionOtherTransRightAttention(
      auctionPublicSaleCautionPages[0]?.otherTransRightAttention,
    );
    auction.init.setAuctionTransRightDateDescription(
      auctionPublicSaleCautionPages[0]?.transRightDateDescription,
    );
    auction.init.setAuctionImportantRiskBearing(
      auctionPublicSaleCautionPages[0]?.importantRiskBearing,
    );
    auction.init.setAuctionImportantNonConformingContractsLiability(
      auctionPublicSaleCautionPages[0]
        ?.importantNonConformingContractsLiability,
    );
    auction.init.setAuctionImportantTermsDelivery(
      auctionPublicSaleCautionPages[0]?.importantTermsDelivery,
    );
    auction.init.setAuctionImportantObligationDelivery(
      auctionPublicSaleCautionPages[0]?.importantObligationDelivery,
    );
    auction.init.setAuctionImportantReturnItems(
      auctionPublicSaleCautionPages[0]?.importantReturnItems,
    );
    auction.init.setAuctionImportantStorageCost(
      auctionPublicSaleCautionPages[0]?.importantStorageCost,
    );
    auction.init.setAuctionImportantReturnDeposit(
      auctionPublicSaleCautionPages[0]?.importantReturnDeposit,
    );
    auction.init.setAuctionImportantOther(
      auctionPublicSaleCautionPages[0]?.importantOther,
    );
    auction.init.setAuctionImportantAttention(
      auctionPublicSaleCautionPages[0]?.importantAttention,
    );
    auction.init.setAuctionInquiryDisplay(
      auctionPublicSaleCautionPages[0]?.inquiryDisplay ?? 'DISPLAY',
    );
    auction.init.setAuctionInquiryName(
      auctionPublicSaleCautionPages[0]?.inquiryName ?? '',
    );
    auction.init.setAuctionInquiryEmail(
      auctionPublicSaleCautionPages[0]?.inquiryEmail,
    );
    const splitAuctionInquiryTel =
      auctionPublicSaleCautionPages[0]?.inquiryTel?.split('-');
    auction.init.setAuctionInquiryTel({
      telNum1: splitAuctionInquiryTel ? splitAuctionInquiryTel[0] : '',
      telNum2: splitAuctionInquiryTel ? splitAuctionInquiryTel[1] : '',
      telNum3: splitAuctionInquiryTel ? splitAuctionInquiryTel[2] : '',
    });
    auction.init.setAuctionInquiryExtension(
      auctionPublicSaleCautionPages[0]?.inquiryExtension,
    );

    bid.init.setAuctionInquiryAccept(
      bidPublicSaleCautionPages[0]?.inquiryAccept,
    );
    bid.init.setAuctionTransRightDescription(
      bidPublicSaleCautionPages[0]?.transRightDescription,
    );
    bid.init.setAuctionCostPropertyDisplay(
      bidPublicSaleCautionPages[0]?.costPropertyDisplay ?? 'DISPLAY',
    );
    bid.init.setAuctionCostPropertyDescription(
      bidPublicSaleCautionPages[0]?.costPropertyDescription ?? '',
    );
    bid.init.setAuctionCostCarDisplay(
      bidPublicSaleCautionPages[0]?.costCarDisplay ?? 'DISPLAY',
    );
    bid.init.setAuctionCostCarDescription(
      bidPublicSaleCautionPages[0]?.costCarDescription ?? '',
    );
    bid.init.setAuctionCostEstateDisplay(
      bidPublicSaleCautionPages[0]?.costEstateDisplay ?? 'DISPLAY',
    );
    bid.init.setAuctionCostEstateDescription(
      bidPublicSaleCautionPages[0]?.costEstateDescription ?? '',
    );
    bid.init.setAuctionCostAttention(
      bidPublicSaleCautionPages[0]?.costAttention,
    );
    bid.init.setAuctionDocumentPageDisplay(
      bidPublicSaleCautionPages[0]?.documentPageDisplay ?? 'DISPLAY',
    );
    bid.init.setAuctionDocumentPageName(
      bidPublicSaleCautionPages[0]?.documentPageName ?? '',
    );
    bid.init.setAuctionDocumentPageUrl(
      bidPublicSaleCautionPages[0]?.documentPageUrl ?? '',
    );
    bid.init.setAuctionDocumentPropertyDisplay(
      bidPublicSaleCautionPages[0]?.documentPropertyDisplay ?? 'DISPLAY',
    );
    bid.init.setAuctionDocumentPropertyDescription(
      bidPublicSaleCautionPages[0]?.documentPropertyDescription ?? '',
    );
    bid.init.setAuctionDocumentCarDisplay(
      bidPublicSaleCautionPages[0]?.documentCarDisplay ?? 'DISPLAY',
    );
    bid.init.setAuctionDocumentCarDescription(
      bidPublicSaleCautionPages[0]?.documentCarDescription ?? '',
    );
    bid.init.setAuctionDocumentEstateDisplay(
      bidPublicSaleCautionPages[0]?.documentEstateDisplay ?? 'DISPLAY',
    );
    bid.init.setAuctionDocumentEstateDescription(
      bidPublicSaleCautionPages[0]?.documentEstateDescription ?? '',
    );
    bid.init.setAuctionDocumentAttention(
      bidPublicSaleCautionPages[0]?.documentAttention,
    );
    bid.init.setAuctionTransRightPropertyDisplay(
      bidPublicSaleCautionPages[0]?.transRightPropertyDisplay ?? 'DISPLAY',
    );
    bid.init.setAuctionTransRightPropertyDescription(
      bidPublicSaleCautionPages[0]?.transRightPropertyDescription ?? '',
    );
    bid.init.setAuctionTransRightCarDisplay(
      bidPublicSaleCautionPages[0]?.transRightCarDisplay ?? 'DISPLAY',
    );
    bid.init.setAuctionTransRightCarDescription(
      bidPublicSaleCautionPages[0]?.transRightCarDescription ?? '',
    );
    bid.init.setAuctionTransRightEstateDisplay(
      bidPublicSaleCautionPages[0]?.transRightEstateDisplay ?? 'DISPLAY',
    );
    bid.init.setAuctionTransRightEstateDescription(
      bidPublicSaleCautionPages[0]?.transRightEstateDescription ?? '',
    );
    bid.init.setAuctionTransRightAttention(
      bidPublicSaleCautionPages[0]?.transRightAttention,
    );
    bid.init.setAuctionOtherTransRightDescription(
      bidPublicSaleCautionPages[0]?.otherTransRightDescription,
    );
    bid.init.setAuctionOtherTransRightAttention(
      bidPublicSaleCautionPages[0]?.otherTransRightAttention,
    );
    bid.init.setAuctionTransRightDateDescription(
      bidPublicSaleCautionPages[0]?.transRightDateDescription,
    );
    bid.init.setAuctionImportantRiskBearing(
      bidPublicSaleCautionPages[0]?.importantRiskBearing,
    );
    bid.init.setAuctionImportantNonConformingContractsLiability(
      bidPublicSaleCautionPages[0]?.importantNonConformingContractsLiability,
    );
    bid.init.setAuctionImportantTermsDelivery(
      bidPublicSaleCautionPages[0]?.importantTermsDelivery,
    );
    bid.init.setAuctionImportantObligationDelivery(
      bidPublicSaleCautionPages[0]?.importantObligationDelivery,
    );
    bid.init.setAuctionImportantReturnItems(
      bidPublicSaleCautionPages[0]?.importantReturnItems,
    );
    bid.init.setAuctionImportantStorageCost(
      bidPublicSaleCautionPages[0]?.importantStorageCost,
    );
    bid.init.setAuctionImportantReturnDeposit(
      bidPublicSaleCautionPages[0]?.importantReturnDeposit,
    );
    bid.init.setAuctionImportantOther(
      bidPublicSaleCautionPages[0]?.importantOther,
    );
    bid.init.setAuctionImportantAttention(
      bidPublicSaleCautionPages[0]?.importantAttention,
    );
    bid.init.setAuctionInquiryDisplay(
      bidPublicSaleCautionPages[0]?.inquiryDisplay ?? 'DISPLAY',
    );
    bid.init.setAuctionInquiryName(
      bidPublicSaleCautionPages[0]?.inquiryName ?? '',
    );
    bid.init.setAuctionInquiryEmail(bidPublicSaleCautionPages[0]?.inquiryEmail);
    const splitBidInquiryTel =
      bidPublicSaleCautionPages[0]?.inquiryTel?.split('-');
    bid.init.setAuctionInquiryTel({
      telNum1: splitBidInquiryTel ? splitBidInquiryTel[0] : '',
      telNum2: splitBidInquiryTel ? splitBidInquiryTel[1] : '',
      telNum3: splitBidInquiryTel ? splitBidInquiryTel[2] : '',
    });
    bid.init.setAuctionInquiryExtension(
      bidPublicSaleCautionPages[0]?.inquiryExtension,
    );
    bid.init.setAuctionInquiryAccept(
      bidPublicSaleCautionPages[0]?.inquiryAccept,
    );
  }, [auctionPublicSaleCautionPages, bidPublicSaleCautionPages]);

  const navigate = useNavigate();

  const backToLocalGovernmentManagement = () => {
    navigate('/navi/local-government-management');
  };

  const update = async (e) => {
    e.preventDefault();

    //const Configs = configs(localStorageGovernmentId, validations, toggle)
    //await allFetch(Configs.publicSale.auction);
    //await allFetch(Configs.publicSale.bid);
    setIsOpen(true);
  };

  return (
    <div className="after-winning-bid-caution">
      {localStorageGovernmentType !== 'NATIONAL_TAX_AGENCY' && (
        <Toggle
          message={'「せり売形式」と「入札形式」で個別に情報を登録する'}
          handler={() => setIndivisualSetting(!indivisualSetting)}
        />
      )}
      {!indivisualSetting && <BothForm validations={auction} />}
      {indivisualSetting && (
        <IndividualForm auctionData={auction} bidData={bid} />
      )}
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'登録'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_primary-button'
              }
              onClick={update}
              isDisabled={!isValid}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button common-button-bg-whitesmoke new-style_navi_normal-button'
              }
              onClick={backToLocalGovernmentManagement}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AfterWinningPublicSaleBidCautionForm;
