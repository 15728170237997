import { ReactComponent as ExpandMoreIcon } from 'interfaces/assets/svg/expand_more_icon.svg';
import { useRef, useState } from 'react';

const CommonAccordion = ({ label, contents }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="common-accordion" data-testid="common-accordion">
      <div
        className="common-accordion-panel flex align-center justify-space-between"
        onClick={toggleAccordion}
        data-testid="common-accordion-toggle"
      >
        <div className="common-accordion-label" data-testid="common-accordion-label">{label}</div>
        <ExpandMoreIcon/>
      </div>
      <div
        className="common-accordion-container"
        ref={contentRef}
        style={{ display: isOpen ? 'inline-block' : 'none' }}
        data-testid="common-accordion-container"
      >
        {contents}
      </div>
    </div>
  );
};

export default CommonAccordion;
