import { validateRangeLength, replaceFullwidthKatakana, hasEmoji } from "../../../../application/validations.js";

export class CarBodyColor {
  #carBodyColor

  constructor(carBodyColor) {
    this.#carBodyColor = carBodyColor;
  }

  get() {
    return this.#carBodyColor
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.replaceFullwidthKatakana,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#carBodyColor, 1, 20)
  }

  // バリデーションルールの適用
  get replaceFullwidthKatakana() {
    return replaceFullwidthKatakana(this.#carBodyColor)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#carBodyColor)
  }
}
