// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bidder-list-num-table-wrap {
    height: inherit;
    position: relative;
    transition: .3s cubic-bezier(.25,.8,.5,1);
}

.bidder-list-table-count {
    width: 35%;
    box-sizing: border-box;
    border: 1px solid #ccc;
}

.bidder-list-table-count dl {
    display: flex;
    margin-block-start: initial;
    margin-block-end: initial;
}

.bidder-list-table-count dl:last-child {
    border-bottom: 1px solid #cacaca;
}

.bidder-list-table-count dl > dt {
    width: 40%;
    background-color: #f1f0e8;
    padding: 5px;
    text-align: left;
}

.bidder-list-table-count dd {
    padding: 5px;
    margin-inline-start: initial;
}

.bidder-list-filter-buttons {
    display: flex;
    align-items: center;
}

.bidder-list-filter-buttons > * + * {
    margin-left: 10px;
}

.bidder-list-sort-toolbar-wrap {
    margin-top: 20px;
}

.bidder-list-sort-toolbar-wrap .sort-toolbar .sort-toolbar-inner p {
    margin-bottom: 0;
}

.bidder-list .property-list .property-list-search-input .text-field {
    height: 40px;
    max-height: inherit;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/BidderList/bidder-list.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,yCAAyC;AAC7C;;AAEA;IACI,UAAU;IACV,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,UAAU;IACV,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".bidder-list-num-table-wrap {\n    height: inherit;\n    position: relative;\n    transition: .3s cubic-bezier(.25,.8,.5,1);\n}\n\n.bidder-list-table-count {\n    width: 35%;\n    box-sizing: border-box;\n    border: 1px solid #ccc;\n}\n\n.bidder-list-table-count dl {\n    display: flex;\n    margin-block-start: initial;\n    margin-block-end: initial;\n}\n\n.bidder-list-table-count dl:last-child {\n    border-bottom: 1px solid #cacaca;\n}\n\n.bidder-list-table-count dl > dt {\n    width: 40%;\n    background-color: #f1f0e8;\n    padding: 5px;\n    text-align: left;\n}\n\n.bidder-list-table-count dd {\n    padding: 5px;\n    margin-inline-start: initial;\n}\n\n.bidder-list-filter-buttons {\n    display: flex;\n    align-items: center;\n}\n\n.bidder-list-filter-buttons > * + * {\n    margin-left: 10px;\n}\n\n.bidder-list-sort-toolbar-wrap {\n    margin-top: 20px;\n}\n\n.bidder-list-sort-toolbar-wrap .sort-toolbar .sort-toolbar-inner p {\n    margin-bottom: 0;\n}\n\n.bidder-list .property-list .property-list-search-input .text-field {\n    height: 40px;\n    max-height: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
