import { useState } from 'react';
import { validateRequired } from 'application/validations';

export const useEditUserInputsValidation = ({ isEdit = false }) => {
  const [familyNameValid, setFamilyNameValid] = useState(
    isEdit === true ? 'TRUE' : null,
  );
  const [familyNameKanaValid, setFamilyNameKanaValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [firstNameValid, setFirstNameValid] = useState(isEdit ? 'TRUE' : null);
  const [firstNameKanaValid, setFirstNameKanaValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [birthDateValid, setBirthDateValid] = useState(isEdit ? 'TRUE' : null);
  const [postalCode1Valid, setPostalCode1Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [postalCode2Valid, setPostalCode2Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [postalCodeValid, setPostalCodeValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [
    locationGovernmentSubCategoryIdValid,
    setLocationGovernmentSubCategoryIdValid,
  ] = useState(isEdit ? 'TRUE' : null);
  const [addressValid, setAddressValid] = useState(isEdit ? 'TRUE' : null);
  const [houseTelValid, setHouseTelValid] = useState(isEdit ? 'TRUE' : null);
  const [mobileTelValid, setMobileTelValid] = useState(isEdit ? 'TRUE' : null);

  function handleFamilyNameValid(e) {
    let invalid = null;
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setFamilyNameValid(invalid);
      return;
    }
    setFamilyNameValid(invalid);
  }

  function handleFamilyNameKanaValid(e) {
    let invalid = null;
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setFamilyNameKanaValid(invalid);
      return;
    }
    setFamilyNameKanaValid(invalid);
  }

  function handleFirstNameValid(e) {
    let invalid = null;
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setFirstNameValid(invalid);
      return;
    }
    setFirstNameValid(invalid);
  }

  function handleFirstNameKanaValid(e) {
    let invalid = null;
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setFirstNameKanaValid(invalid);
      return;
    }
    setFirstNameKanaValid(invalid);
  }

  function handleBirthDateValid(value) {
    let invalid = null;
    invalid = validateRequired(value);
    if (invalid !== 'TRUE') {
      setBirthDateValid(invalid);
      return;
    }
    setBirthDateValid(invalid);
  }

  function handlePostalCode1Valid(e) {
    let invalid = null;
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setPostalCode1Valid(invalid);
      return;
    }
    setPostalCode1Valid(invalid);
  }

  function handlePostalCode2Valid(e) {
    let invalid = null;
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setPostalCode2Valid(invalid);
      return;
    }
    setPostalCode2Valid(invalid);
  }

  function handlePostalCodeValid(e) {
    let invalid = null;
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setPostalCodeValid(invalid);
      return;
    }
    setPostalCodeValid(invalid);
  }

  function handleLocationGovernmentSubCategoryIdValid(value) {
    let invalid = null;
    invalid = validateRequired(value);
    if (invalid !== 'TRUE') {
      setLocationGovernmentSubCategoryIdValid(invalid);
      return;
    }
    setLocationGovernmentSubCategoryIdValid(invalid);
  }

  function handleAddressValid(value) {
    let invalid = null;
    invalid = validateRequired(value);
    if (invalid !== 'TRUE') {
      setAddressValid(invalid);
      return;
    }
    setAddressValid(invalid);
  }

  function handleHouseTelValid(e) {
    let invalid = null;
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setHouseTelValid(invalid);
      return;
    }
    setHouseTelValid(invalid);
  }

  function handleMobileTelValid(e) {
    let invalid = null;
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setMobileTelValid(invalid);
      return;
    }
    setMobileTelValid(invalid);
  }

  return {
    setters: {
      handleFamilyNameValid,
      handleFamilyNameKanaValid,
      handleFirstNameValid,
      handleFirstNameKanaValid,
      handleBirthDateValid,
      handlePostalCode1Valid,
      handlePostalCode2Valid,
      handlePostalCodeValid,
      handleLocationGovernmentSubCategoryIdValid,
      handleAddressValid,
      handleHouseTelValid,
      handleMobileTelValid,
    },
    validationMessage: {
      familyNameValid,
      familyNameKanaValid,
      firstNameValid,
      firstNameKanaValid,
      birthDateValid,
      postalCode1Valid,
      postalCode2Valid,
      postalCodeValid,
      locationGovernmentSubCategoryIdValid,
      addressValid,
      houseTelValid,
      mobileTelValid,
    },
  };
};
