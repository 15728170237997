import { validateSelected } from "../../../../application/validations.js";

export class FirstRegisterDate {
  #firstRegisterDate

  constructor(firstRegisterDate) {
    this.#firstRegisterDate = firstRegisterDate;
  }

  get() {
    return this.#firstRegisterDate
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#firstRegisterDate)
  }
}
