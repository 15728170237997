import AuctionGovernmentSettingsProvider from './AuctionGovernmentSettingsProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { auctionGovernmentSettings } from "../entities/componentMapping.js";

const AuctionGovernmentSettings = ({ type }) => {
  return (
    <AuctionGovernmentSettingsProvider type={type}>
      {getComponent(auctionGovernmentSettings, type)}
    </AuctionGovernmentSettingsProvider>
  );
};

export default AuctionGovernmentSettings;
