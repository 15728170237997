import Modal2 from 'interfaces/components/common/Modal/Modal2';
import Button from 'interfaces/components/common/Button/Button.jsx';
import { ModalButtonArea } from 'interfaces/components/common/ModalButtonArea';

import 'interfaces/css/BlockList/delete-block-list-modal.css';

const DeleteBlockListModal = ({
  isOpen,
  handleClose,
  deleteId = null,
  deleteIds = [],
  onDelete,
}) => {
  return (
    <div className="delete-block-list-modal">
      <Modal2
        isOpen={isOpen}
        handleClose={handleClose}
        modalSize={'500px'}
        padding={false}>
        <div className="modal-title">ブロックリストからの削除</div>
        <div className="modal-description-area">
          <p>
            ブロックリストから &quot;
            {deleteId ? deleteId : deleteIds.join(', ')}&quot;
            を削除してよろしいですか？
          </p>
        </div>
        <hr className="modal-border" />
        <ModalButtonArea>
          <Button
            text={'キャンセル'}
            isOutline={false}
            height={'44px'}
            className={'cancel-button new-style_navi_normal-button'}
            onClick={handleClose}
          />

          <Button
            text={'削除'}
            isOutline={false}
            height={'44px'}
            onClick={onDelete}
          />
        </ModalButtonArea>
      </Modal2>
    </div>
  );
};

export default DeleteBlockListModal;
