import { ValidationMessage } from 'interfaces/components/common/ValidationMessage';
import React from 'react';

import FormLabel from './FormLabel.jsx';

const InputText = React.memo(function InputText({
  invalidMessage,
  required,
  label,
  state,
  setState,
}) {
  return (
    <div className="kmanager-row">
      <div className="kmanager-col-4">
        <FormLabel label={label} required={required} />
      </div>
      <div className="kmanager-col form-area flex-column">
        <div style={{ position: 'relative' }} className={'form-input'}>
          <input
            type="text"
            placeholder={label}
            data-testid="input-text"
            value={state}
            onChange={(e) => setState(e)}
          />
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
      </div>
    </div>
  );
});
export default InputText;
