import LocalGovernmentManagementTopProvider from './LocalGovernmentManagementTopProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { localGovernmentManagementTop } from "../entities/componentMapping.js";

const LocalGovernmentManagementTop = ({ type }) => {
  return (
    <LocalGovernmentManagementTopProvider type={type}>
      {getComponent(localGovernmentManagementTop, type)}
    </LocalGovernmentManagementTopProvider>
  );
};

export default LocalGovernmentManagementTop;
