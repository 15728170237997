import { useState } from 'react';

export const useAfterWinningAssetSaleBidCautionInputs = () => {
  const [auctionTransRightDescription, setAuctionTransRightDescription] =
    useState('');

  const [auctionCostContractTitle1, setAuctionCostContractTitle1] =
    useState('');
  const [auctionCostContractDescription1, setAuctionCostContractDescription1] =
    useState('');
  const [auctionCostContractTitle2, setAuctionCostContractTitle2] =
    useState('');
  const [auctionCostContractDescription2, setAuctionCostContractDescription2] =
    useState('');
  const [auctionCostContractTitle3, setAuctionCostContractTitle3] =
    useState('');
  const [auctionCostContractDescription3, setAuctionCostContractDescription3] =
    useState('');

  const [auctionCostPaymentTitle1, setAuctionCostPaymentTitle1] = useState('');
  const [auctionCostPaymentDescription1, setAuctionCostPaymentDescription1] =
    useState('');
  const [auctionCostPaymentTitle2, setAuctionCostPaymentTitle2] = useState('');
  const [auctionCostPaymentDescription2, setAuctionCostPaymentDescription2] =
    useState('');
  const [auctionCostPaymentTitle3, setAuctionCostPaymentTitle3] = useState('');
  const [auctionCostPaymentDescription3, setAuctionCostPaymentDescription3] =
    useState('');
  const [auctionCostPaymentTitle4, setAuctionCostPaymentTitle4] = useState('');
  const [auctionCostPaymentDescription4, setAuctionCostPaymentDescription4] =
    useState('');
  const [auctionCostPaymentTitle5, setAuctionCostPaymentTitle5] = useState('');
  const [auctionCostPaymentDescription5, setAuctionCostPaymentDescription5] =
    useState('');
  const [auctionCostAttention, setAuctionCostAttention] = useState('');

  const [auctionDocumentPageDisplay, setAuctionDocumentPageDisplay] =
    useState('DISPLAY');
  const [auctionDocumentPageName, setAuctionDocumentPageName] = useState('');
  const [auctionDocumentPageUrl, setAuctionDocumentPageUrl] = useState('');
  const [auctionDocumentDescription, setAuctionDocumentDescription] =
    useState('');
  const [
    auctionOtherTransRightDescription,
    setAuctionOtherTransRightDescription,
  ] = useState('');
  const [auctionOtherTransRightAttention, setAuctionOtherTransRightAttention] =
    useState('');
  const [auctionTransRightDate, setAuctionTransRightDate] = useState('');
  const [auctionTransRightCompletedTime, setAuctionTransRightCompletedTime] =
    useState('');
  const [auctionImportantRiskBearing, setAuctionImportantRiskBearing] =
    useState('');
  const [auctionImportantTermsDelivery, setAuctionImportantTermsDelivery] =
    useState('');
  const [
    auctionImportantHandlingBidDeposit,
    setAuctionImportantHandlingBidDeposit,
  ] = useState('');
  const [
    auctionImportantHandlingContractDeposit,
    setAuctionImportantHandlingContractDeposit,
  ] = useState('');
  const [auctionImportantRestrictionUse, setAuctionImportantRestrictionUse] =
    useState('');
  const [
    auctionImportantRestrictionUseBefore,
    setAuctionImportantRestrictionUseBefore,
  ] = useState('');
  const [auctionImportantOther1, setAuctionImportantOther1] = useState('');
  const [auctionImportantDescription1, setAuctionImportantDescription1] =
    useState('');
  const [auctionImportantOther2, setAuctionImportantOther2] = useState('');
  const [auctionImportantDescription2, setAuctionImportantDescription2] =
    useState('');
  const [auctionImportantOther3, setAuctionImportantOther3] = useState('');
  const [auctionImportantDescription3, setAuctionImportantDescription3] =
    useState('');
  const [auctionImportantAttention, setAuctionImportantAttention] =
    useState('');
  const [auctionInquiryDisplay, setAuctionInquiryDisplay] = useState('DISPLAY');
  const [auctionInquiryName, setAuctionInquiryName] = useState('');
  const [auctionInquiryEmail, setAuctionInquiryEmail] = useState('');
  const [auctionInquiryTel, setAuctionInquiryTel] = useState({
    telNum1: '',
    telNum2: '',
    telNum3: '',
  });
  const [auctionInquiryTelNum1, setAuctionInquiryTelNum1] = useState(null);
  const [auctionInquiryTelNum2, setAuctionInquiryTelNum2] = useState(null);
  const [auctionInquiryTelNum3, setAuctionInquiryTelNum3] = useState(null);
  const [auctionInquiryExtension, setAuctionInquiryExtension] = useState('');
  const [auctionInquiryAccept, setAuctionInquiryAccept] = useState('');


  const inputs = {
    data: {
      auctionTransRightDescription,
      auctionCostContractTitle1,
      auctionCostContractDescription1,
      auctionCostContractTitle2,
      auctionCostContractDescription2,
      auctionCostContractTitle3,
      auctionCostContractDescription3,
      auctionCostPaymentTitle1,
      auctionCostPaymentDescription1,
      auctionCostPaymentTitle2,
      auctionCostPaymentDescription2,
      auctionCostPaymentTitle3,
      auctionCostPaymentDescription3,
      auctionCostPaymentTitle4,
      auctionCostPaymentDescription4,
      auctionCostPaymentTitle5,
      auctionCostPaymentDescription5,
      auctionCostAttention,
      auctionDocumentPageDisplay,
      auctionDocumentPageName,
      auctionDocumentPageUrl,
      auctionDocumentDescription,
      auctionOtherTransRightDescription,
      auctionOtherTransRightAttention,
      auctionTransRightDate,
      auctionTransRightCompletedTime,
      auctionImportantRiskBearing,
      auctionImportantTermsDelivery,
      auctionImportantHandlingBidDeposit,
      auctionImportantHandlingContractDeposit,
      auctionImportantRestrictionUse,
      auctionImportantRestrictionUseBefore,
      auctionImportantOther1,
      auctionImportantDescription1,
      auctionImportantOther2,
      auctionImportantDescription2,
      auctionImportantOther3,
      auctionImportantDescription3,
      auctionImportantAttention,
      auctionInquiryDisplay,
      auctionInquiryName,
      auctionInquiryEmail,
      auctionInquiryTelNum1,
      auctionInquiryTelNum2,
      auctionInquiryTelNum3,
      auctionInquiryTel,
      auctionInquiryExtension,
      auctionInquiryAccept,

    },
    setters: {
      setAuctionTransRightDescription,
      setAuctionCostContractTitle1,
      setAuctionCostContractDescription1,
      setAuctionCostContractTitle2,
      setAuctionCostContractDescription2,
      setAuctionCostContractTitle3,
      setAuctionCostContractDescription3,
      setAuctionCostPaymentTitle1,
      setAuctionCostPaymentDescription1,
      setAuctionCostPaymentTitle2,
      setAuctionCostPaymentDescription2,
      setAuctionCostPaymentTitle3,
      setAuctionCostPaymentDescription3,
      setAuctionCostPaymentTitle4,
      setAuctionCostPaymentDescription4,
      setAuctionCostPaymentTitle5,
      setAuctionCostPaymentDescription5,
      setAuctionCostAttention,
      setAuctionDocumentPageDisplay,
      setAuctionDocumentPageName,
      setAuctionDocumentPageUrl,
      setAuctionDocumentDescription,
      setAuctionOtherTransRightDescription,
      setAuctionOtherTransRightAttention,
      setAuctionTransRightDate,
      setAuctionTransRightCompletedTime,
      setAuctionImportantRiskBearing,
      setAuctionImportantTermsDelivery,
      setAuctionImportantHandlingBidDeposit,
      setAuctionImportantHandlingContractDeposit,
      setAuctionImportantRestrictionUse,
      setAuctionImportantRestrictionUseBefore,
      setAuctionImportantOther1,
      setAuctionImportantDescription1,
      setAuctionImportantOther2,
      setAuctionImportantDescription2,
      setAuctionImportantOther3,
      setAuctionImportantDescription3,
      setAuctionImportantAttention,
      setAuctionInquiryDisplay,
      setAuctionInquiryName,
      setAuctionInquiryEmail,
      setAuctionInquiryTel,
      setAuctionInquiryTelNum1,
      setAuctionInquiryTelNum2,
      setAuctionInquiryTelNum3,
      setAuctionInquiryExtension,
      setAuctionInquiryAccept,
    },
  };
  return inputs;
};
