import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import { AuctionScheduleContext } from 'interfaces/pages/AuctionSchedule/entities/AuctionScheduleContext';
import { breadcrumbs } from 'interfaces/pages/AuctionSchedule/entities/breadcrumbs';
import { useContext } from 'react';
import { ProcedureTypeToJP } from 'shared/utils/helper/ProcedureTypeToJp';

import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import Heading2 from '../../../components/common/Heading/Heading2';
import ScheduleTable from '../../../components/Schedule/Annual/ScheduleTable';

const Schedule = () => {
  const { localStorageProcedureType } = useLocalStorage();
  const { schedules } = useContext(AuctionScheduleContext);

  return (
    <div data-testid="navi-auction_schedule">
      <Breadcrumb breadcrumbs={breadcrumbs(localStorageProcedureType)} />
      {schedules.nextYear.auctions.length > 0 && (
        <>
          <Heading2
            title={`${schedules.nextYear.year}年度 ${ProcedureTypeToJP(localStorageProcedureType)} 開催スケジュール`}
          />
          <ScheduleTable
            tableData={schedules.nextYear.auctions}
            procedureType={localStorageProcedureType}
          />
        </>
      )}

      {schedules.thisYear.auctions.length > 0 && (
        <>
          <Heading2
            title={`${schedules.thisYear.year}年度 ${ProcedureTypeToJP(localStorageProcedureType)} 開催スケジュール`}
          />
          <ScheduleTable
            tableData={schedules.thisYear.auctions}
            procedureType={localStorageProcedureType}
          />
        </>
      )}

      <div>
        <p className="description" data-testid="description">
          こちらから開催スケジュール表をダウンロードできます。
          <br />
          <br />
          {localStorageProcedureType === 'PUBLIC_SALE' ? (
            <>
              上記の表には記載されていない不動産の買受代金納付期限などのスケジュールは、こちらのファイルでご確認いただけます。
              <br />
            </>
          ) : (
            ''
          )}
          下記のリンクにマウスを合わせ、右クリックで「対象をファイルに保存」でファイルを保存してください。
        </p>
      </div>

      <div className="pt-4">
        <div data-testid="schedule-download">
          <a
            href={`/documents/schedule/${schedules.thisYear.year - 1}-auction-schedule-${
              localStorageProcedureType === 'PUBLIC_SALE'
                ? 'auction'
                : 'asset-sale'
            }.pdf`}
            target="_blank"
            rel="noopener noreferrer">
            {`${schedules.thisYear.year - 1}年度 ${ProcedureTypeToJP(localStorageProcedureType)} 開催スケジュール`}
          </a>
        </div>
      </div>
      <div className="pt-4">
        <div data-testid="schedule-download">
          <a
            href={`/documents/schedule/${schedules.nextYear.year - 1}-auction-schedule-${
              localStorageProcedureType === 'PUBLIC_SALE'
                ? 'auction'
                : 'asset-sale'
            }.pdf`}
            target="_blank"
            rel="noopener noreferrer">
            {`${schedules.nextYear.year - 1}年度 ${ProcedureTypeToJP(localStorageProcedureType)} 開催スケジュール`}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
