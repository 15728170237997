
export class Subject {
  #subject

  constructor(subject) {
    this.#subject = subject;
  }

  get() {
    return this.#subject
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
