import { UserKeyByService } from "shared/utils/constants/service.js";

export const useLogout = () => {
  const logout = (service) => {
    localStorage.removeItem(UserKeyByService[service])
    localStorage.removeItem("procedureType")
    localStorage.removeItem("governmentId")
    localStorage.removeItem("governmentType")
    localStorage.removeItem('requestAccessToken');
    localStorage.removeItem('requestRefreshToken');
  };

  return {
    logout,
  };
};
