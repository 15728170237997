// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navi .footer {
  padding: 24px;
  background: none;
  border: none;
}

.kmanager-footer {
  padding: 6px 16px;
  background-color: #f5f5f5;
  color: rgba(0,0,0,.87);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 3;
}

.footer-top,
.footer-bottom {
  max-width: 1200px;
  width: 90%;
}

.footer-top {
  padding-top: 16px;
  padding-bottom: 4px;
}

.footer-bottom {
  margin-top: 12px;
}

.footer-bottom-link-item {
  margin-right: 16px;
  margin-left: 16px;
}

.footer-nav {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0 auto;
}

.footer-nav-item {
  font-size: 14px;
  line-height: 1.571;
}

.footer-nav-item:not(:last-child):after {
  content: "|";
  margin: 0 8px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,sBAAsB;EACtB,eAAe;EACf,SAAS;EACT,WAAW;EACX,UAAU;AACZ;;AAEA;;EAEE,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf","sourcesContent":[".navi .footer {\n  padding: 24px;\n  background: none;\n  border: none;\n}\n\n.kmanager-footer {\n  padding: 6px 16px;\n  background-color: #f5f5f5;\n  color: rgba(0,0,0,.87);\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  z-index: 3;\n}\n\n.footer-top,\n.footer-bottom {\n  max-width: 1200px;\n  width: 90%;\n}\n\n.footer-top {\n  padding-top: 16px;\n  padding-bottom: 4px;\n}\n\n.footer-bottom {\n  margin-top: 12px;\n}\n\n.footer-bottom-link-item {\n  margin-right: 16px;\n  margin-left: 16px;\n}\n\n.footer-nav {\n  display: flex;\n  justify-content: center;\n  list-style: none;\n  padding: 0;\n  margin: 0 auto;\n}\n\n.footer-nav-item {\n  font-size: 14px;\n  line-height: 1.571;\n}\n\n.footer-nav-item:not(:last-child):after {\n  content: \"|\";\n  margin: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
