import { validateNumber, validateRangeLength, validateDecimal } from "../../../../application/validations.js";

export class BuildRatio {
  #buildRatio

  constructor(buildRatio) {
    this.#buildRatio = buildRatio;
  }

  get() {
    return this.#buildRatio
  }

  get isValid() {
    return [
      this.validateNumber,
      this.validateRangeLength,
      this.validateDecimal,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateNumber() {
    return validateNumber(this.#buildRatio)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#buildRatio, 0, 4)
  }

  // バリデーションルールの適用
  get validateDecimal() {
    return validateDecimal(this.#buildRatio, 1)
  }
}
