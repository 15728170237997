// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-info-car .complete-message {
    padding: 30px 0;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertyInfoCar/property-info-car.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB","sourcesContent":[".property-info-car .complete-message {\n    padding: 30px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
