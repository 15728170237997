
export class ImportantReturnDeposit {
  #importantReturnDeposit

  constructor(importantReturnDeposit) {
    this.#importantReturnDeposit = importantReturnDeposit;
  }

  get() {
    return this.#importantReturnDeposit
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
