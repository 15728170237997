import { useContext, useEffect } from 'react';
import NewsItem from './NewsItem';
import { NOTICE_PAGE_SIZE } from '../../../../config/naviConstants';
import usePagination from '../../../../shared/hooks/usePagination';
import { NewsContext } from '../../../pages/News/entities/NewsContext';
import Pagination from '../../common/Pagination/Pagination';
import styled, { keyframes } from 'styled-components';

const timestampToJp = (timestamp) => {
  const date = new Date(timestamp);
  const yyyy = date.getFullYear();
  const mm = `0${date.getMonth() + 1}`.slice(-2);
  const dd = `0${date.getDate()}`.slice(-2);
  return `${yyyy}年${mm}月${dd}日`;
};

const fadeBackground = keyframes`
  0% {
    background-color: transparent;
  }
  50% {
    background-color: var(--primary-base);
  }
  100% {
    background-color: transparent;
  }
`;

const NewsItemWrap = styled.div`
  &.highlight {
    animation: ${fadeBackground} 0.5s ease-out;
  }
`;

const NewsList = () => {
  const { notices } = useContext(NewsContext);

  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(notices, NOTICE_PAGE_SIZE);

  useEffect(() => {
    const hash = window.location.hash.substring(1); // "#"を取り除く
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        const yOffset = -100; // 追加のオフセット（px単位）
        const yPosition =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yPosition, behavior: 'smooth' });

        element.classList.add('highlight');
        setTimeout(() => {
          element.classList.remove('highlight');
        }, 2000);
      }
    }
  }, [currentItems]);

  return (
    <div data-testid="notice-list">
      {currentItems.map((notice, index) => (
        <NewsItemWrap
          key={index}
          id={`notice-${index + (currentPage - 1) * NOTICE_PAGE_SIZE + 1}`}
          className="news-item">
          <NewsItem
            date={timestampToJp(notice.openAt)}
            title={notice.title}
            body={notice.body}
            isImportant={notice.isImportant === 'TRUE'}
          />
        </NewsItemWrap>
      ))}
      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
        marginTop="100px"
      />
    </div>
  );
};

export default NewsList;
