import Button from 'interfaces/components/common/Button/Button.jsx';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel.jsx';

import ApplicationInfoForm from './ApplicationInfoForm.jsx';
import FormAttentionSection from './FormAttentionSection.jsx';

import styled from 'styled-components';

const CautionBlock = styled.div`
  border: 2px solid var(--primary-base);
  background-color: #ffffff;
  padding: 10px;
  margin: 5px;
  margin-bottom: 40px;
`;

//import { useApplicationInfo } from 'shared/hooks/useApplicationInfo.js';

const ApplicationInfoRegistration = ({
  data,
  applicantInformation,
  delegatorInformation,
  setApplicantInformation = () => {},
  setDelegatorInformation = () => {},
  previousStep,
  nextStep,
}) => {
  //const { isValid } = useApplicationInfo();
  // mock data
  const isValid = true;

  return (
    <div>
      <CautionBlock>
        <p>
          以下の項目をすべて入力または選択して「上記内容に同意の上、次のステップへ」ボタンを押してください。
        </p>
        <p>
          （<RequiredLabel />
          は必須項目）
        </p>
      </CautionBlock>
      <ApplicationInfoForm
        data={data}
        applicantInformation={applicantInformation}
        delegatorInformation={delegatorInformation}
        setApplicantInformation={setApplicantInformation}
        setDelegatorInformation={setDelegatorInformation}
      />
      <FormAttentionSection
        procedureType={data.procedureType}
        propertyType={data.propertyType}
      />
      <div className="flex justify-center mb-14">
        <Button
          text={
            <p>
              上記内容に同意のうえ、
              <br />
              次のステップへ
            </p>
          }
          height="52px"
          width="300px"
          onClick={nextStep}
          className="mr-4"
          isDisabled={!isValid}
        />
        <Button
          text="前に戻る"
          height="52px"
          width="120px"
          onClick={previousStep}
          className="cancel-common-button"
        />
      </div>
    </div>
  );
};

export default ApplicationInfoRegistration;
