import { ReactComponent as HelpIcon } from 'interfaces/assets/svg/detail_help_icon.svg';
import { Link } from 'react-router-dom';

export const AuctionHelpBtn = ({ label, url }) => {
  return (
    <Link
      to={url}
      target="_blank"
      className="pa-0 mt-3 box grow text-caption btn--frame v-btn v-btn--depressed v-btn--flat v-btn--outlined v-size--large text--text"
      data-testid="auction-help">
      <span className="v-btn__content" data-testid="auction-help-label">
        <HelpIcon />
        {label}
      </span>
    </Link>
  );
};
