import { createContext } from 'react';

import { DATA_EMPTY } from 'shared/utils/helper/constants.js';

export const ReceptionCompletedContext = createContext({
  applicantInformations: DATA_EMPTY,
  auction: DATA_EMPTY,
  auctionUser: DATA_EMPTY,
  bankTransferPages: DATA_EMPTY,
  creditCardPages: DATA_EMPTY,
  government: DATA_EMPTY,
  property: DATA_EMPTY,
  proposal: DATA_EMPTY,
  responsive: DATA_EMPTY,
});
