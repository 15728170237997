// 文字列で渡されるJSONの値に対して使用する
import { DATA_EMPTY } from 'domain/Property/entities/constants/property.js';
const EMPTY_CODE = [
  '1000年01月',
  '1000年(1000年)1月',
  '1000年(1000年)1月1日',
  -9999,
  DATA_EMPTY,
];

export const isFalsy = (val) => {
  if (!val) {
    return true;
  }

  return EMPTY_CODE.some((code) => code === val);
};
