import { GovernmentTypes } from 'shared/utils/constants/government.js';
import { SortableTh } from 'interfaces/components/common/Table/Th/SortableTh.jsx';

const KmanagerAuctionTableColumn = ({ auction, requestSort, sortConfig }) => {
  const headers = [
    { id: 1, column: '', text: '操作など' },
    { id: 2, column: 'region', text: '地域' },
    { id: 3, column: 'location', text: '都道府県' },
    { id: 4, column: 'name', text: '行政機関名' },
    { id: 5, column: 'participationStatus', text: '不参加' },
    {
      id: 6,
      column: 'bidEndButtonDownAuctionDate',
      text: '入札終了処理（せり）',
    },
    ...(auction.governmentTypeGroup !==
    GovernmentTypes.NATIONAL_TAX_AGENCY.string
      ? [
          {
            id: 7,
            column: 'bidEndButtonDownBidDate',
            text: '入札終了処理（入札）',
          },
          { id: 8, column: 'mailCertDocument', text: '認証書面（表紙）郵送' },
        ]
      : []),
  ];

  return (
    <thead data-testid="kmanager-auction-table-column">
      {headers.map((header) => (
        <SortableTh
          key={header.id}
          header={header}
          onClick={() => requestSort(header.column)}
          sortConfig={sortConfig}
        />
      ))}
    </thead>
  );
};

export default KmanagerAuctionTableColumn;
