import { calcXCoordinate } from 'shared/utils/helper/calcXCoordinate.js';
import { dateFormat } from 'shared/utils/helper/date_format.js';
import 'interfaces/css/common/government-term-row.css';

const GovernmentScheduleRow = ({ termData, magnification }) => {
  const aplStart = new Date(
    //termData.auctionInfo?.aplStart ?? termData.bidInfo?.aplStart,
    termData.aplStart,
  );
  const aplEnd = new Date(
    //termData.auctionInfo?.aplEnd ?? termData.bidInfo?.aplEnd,
    termData.aplEnd,
  );
  //const bidBidStart = new Date(termData.auctionInfo?.bidStart);
  //const bidBidEnd = new Date(termData.auctionInfo?.bidEnd);
  //const auctionBidStart = new Date(termData.bidInfo?.bidStart);
  //const auctionBidEnd = new Date(termData.bidInfo?.bidEnd);
  const bidBidStart = new Date(termData.bidStart);
  const bidBidEnd = new Date(termData.bidEnd);
  const auctionBidStart = new Date(termData.bidStart);
  const auctionBidEnd = new Date(termData.bidEnd);

  const aplTermStyle = {
    width:
      (calcXCoordinate(aplEnd) - calcXCoordinate(aplStart)) * magnification,
    left: calcXCoordinate(aplStart) * magnification,
  };
  const bidBidTermStyle = {
    width:
      (calcXCoordinate(bidBidEnd) - calcXCoordinate(bidBidStart)) *
      magnification,
    left: calcXCoordinate(bidBidStart) * magnification,
  };
  const auctionBidTermStyle = {
    width:
      (calcXCoordinate(auctionBidEnd) - calcXCoordinate(auctionBidStart)) *
      magnification,
    left: calcXCoordinate(auctionBidStart) * magnification,
  };

  return (
    <div data-testid="government-schedule-row">
      <div className="government-term-row" data-testid="government-term-row">
        <div
          className="government-apl-term government-schedule-term"
          data-testid="government-apl-term"
          style={aplTermStyle}>
          参加申し込み受付期間
          <br />
          {`${dateFormat(termData.aplStart, 'MM-DD')} ~ ${dateFormat(termData.aplEnd, 'MM-DD')}`}
        </div>
      </div>
      {termData.auctionStatus && (
        <div className="government-term-row" data-testid="government-term-row">
          <div
            className="government-bid-term government-schedule-term"
            data-testid="government-bid-term"
            style={bidBidTermStyle}>
            入札期間(せり売)
            <br />
            {`${dateFormat(termData.bidStart, 'MM-DD')} ~ ${dateFormat(termData.auctionEnd, 'MM-DD')}`}
          </div>
        </div>
      )}
      {termData.bidStatus && (
        <div className="government-term-row" data-testid="government-term-row">
          <div
            className="government-auction-term government-schedule-term"
            data-testid="government-auction-term"
            style={auctionBidTermStyle}>
            入札期間(入札)
            <br />
            {`${dateFormat(termData.bidStart, 'MM-DD')} ~ ${dateFormat(termData.bidEnd, 'MM-DD')}`}
          </div>
        </div>
      )}
    </div>
  );
};

export default GovernmentScheduleRow;
