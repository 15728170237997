// infrastructure/api/AuthAPI.js

import axios from "axios";
import { API_BASE_URL, APP_ENV } from "config/envConstants.js";
import CustomError from "shared/utils/helper/customError.js";

export async function postLogin(body) {
  if ((API_BASE_URL || '').startsWith('http://') && APP_ENV !== 'development') {
    throw new CustomError('セキュアでないAPIエンドポイントです');
  }

  const headers = {
    'Content-Type': 'application/json'
  };

  try {
    const response = await axios.post(`${API_BASE_URL}/api/v1/login`, body, { headers: headers });
    return response;
  } catch (error) {
    console.error('ログインに失敗しました', error.response.data.errors);
    throw new CustomError(error.response.data.errors);
  }
}