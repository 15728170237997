import { dateFormat } from 'shared/utils/helper/date_format.js';

import CalendarIconLink from './CalendarIconLink.jsx';

const NearlyScheduleTerm = ({ style, termLabel, startLabel, endLabel, startDate, endDate, title, subTitle }) => {
  return (
    <div className="nearly-schedule-term" data-testid="nearly-schedule-term" style={style}>
      <div><b>{termLabel}</b></div>
      <div className="flex">
        <div>{startLabel}<br/>{`${dateFormat(startDate, 'MM-DD-hh')} 〜 `}</div>
        <div>
          <p>{endLabel}</p>
          <div className="flex">
            {`${dateFormat(endDate, 'MM-DD-hh')}`}
            <div className="flex align-center ml-3">
              <CalendarIconLink
                title={title}
                subtitle={subTitle}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NearlyScheduleTerm;
