import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle';
import SimpleSchedule from 'interfaces/components/common/SimpleSchedule/SimpleSchedule';

import TotalApplications from './TotalApplications';

const SimpleScheduleSection = ({
  governmentData,
  propertiesCount,
  invitatiosns,
}) => {
  return (
    <div>
      <ArticleDefaultTitle title="簡易スケジュール" />
      <TotalApplications
        propertiesCount={propertiesCount}
        invitations={invitatiosns}
      />
      <SimpleSchedule data={governmentData} scheduleType="GOVERNMENT" />
    </div>
  );
};

export default SimpleScheduleSection;
