// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.published-object-description {
    font-size: 14px;
}

.published-object .column {
    padding: 20px;
    justify-content: space-between;
}

.published-object .column > * + * {
    margin-left: 10px;
}

.published-object .search-form-area {
    align-items: center;
    display: flex;
}
.published-object .search-form-area > div,
.published-object .search-form-area > button {
    margin-left: 10px;
}
.published-object .search-form-area > div:first-child {
    margin-left: 0;
}
.published-object .search-form-area .property-list .property-list-search-input .text-field,
.published-object .search-form-area .text-field-outlined {
    height: 40px;
}
.published-object .search-form-area .property-list .property-list-search-input .text-field {
    max-height: inherit;
    padding: 0 12px;
}

.navi .published-object .column ul {
    padding: 0 0 0 24px;
}
.navi .published-object .column ul > li {
    list-style: disc;
}
.navi .published-object .column ul > li p {
    margin: 0 0 16px;
}

.navi .published-object .sort-toolbar-wrap {
    padding-top: 20px;
}

.navi .published-object .sort-toolbar-label {
    margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PublishedObject/published-object.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,aAAa;AACjB;AACA;;IAEI,iBAAiB;AACrB;AACA;IACI,cAAc;AAClB;AACA;;IAEI,YAAY;AAChB;AACA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".published-object-description {\n    font-size: 14px;\n}\n\n.published-object .column {\n    padding: 20px;\n    justify-content: space-between;\n}\n\n.published-object .column > * + * {\n    margin-left: 10px;\n}\n\n.published-object .search-form-area {\n    align-items: center;\n    display: flex;\n}\n.published-object .search-form-area > div,\n.published-object .search-form-area > button {\n    margin-left: 10px;\n}\n.published-object .search-form-area > div:first-child {\n    margin-left: 0;\n}\n.published-object .search-form-area .property-list .property-list-search-input .text-field,\n.published-object .search-form-area .text-field-outlined {\n    height: 40px;\n}\n.published-object .search-form-area .property-list .property-list-search-input .text-field {\n    max-height: inherit;\n    padding: 0 12px;\n}\n\n.navi .published-object .column ul {\n    padding: 0 0 0 24px;\n}\n.navi .published-object .column ul > li {\n    list-style: disc;\n}\n.navi .published-object .column ul > li p {\n    margin: 0 0 16px;\n}\n\n.navi .published-object .sort-toolbar-wrap {\n    padding-top: 20px;\n}\n\n.navi .published-object .sort-toolbar-label {\n    margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
