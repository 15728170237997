// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kmanager-right-side-menu-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.64);
}

.kmanager-right-side-menu {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 100%;
  width: 250px;
  height: 100%;
  transition-duration: .3s;
  transition-property: transform;
  transform: translateX(0px);
  background-color: white;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
}

.kmanager-right-side-menu.active {
  transform: translateX(-250px);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/kmanager-right-side-menu.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,MAAM;EACN,UAAU;EACV,YAAY;EACZ,YAAY;EACZ,wBAAwB;EACxB,8BAA8B;EAC9B,0BAA0B;EAC1B,uBAAuB;EACvB,oHAAoH;AACtH;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".kmanager-right-side-menu-overlay {\n  position: fixed;\n  z-index: 100;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(33, 33, 33, 0.64);\n}\n\n.kmanager-right-side-menu {\n  position: fixed;\n  z-index: 101;\n  top: 0;\n  left: 100%;\n  width: 250px;\n  height: 100%;\n  transition-duration: .3s;\n  transition-property: transform;\n  transform: translateX(0px);\n  background-color: white;\n  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);\n}\n\n.kmanager-right-side-menu.active {\n  transform: translateX(-250px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
