
export class PaymentDate {
  #paymentDate

  constructor(paymentDate) {
    this.#paymentDate = paymentDate;
  }

  get() {
    return this.#paymentDate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
