import ResetPasswordForm from './ResetPasswordForm.jsx';

const ResetPasswordModalContents = ({ handleClose, setModalType }) => {
  return (
    <div className="reset-password-modal-contents" data-testid="reset-password-modal-contents">
      <p className="reset-password-modal-contents-title" data-testid="reset-password-modal-contents-title">
        パスワード変更
      </p>
      <ResetPasswordForm handleClose={handleClose} setModalType={setModalType}/>
    </div>
  );
};

export default ResetPasswordModalContents;
