import BlockListProvider from './BlockListProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { blockList } from "../entities/componentMapping.js";

const BlockList = ({ type }) => {
  return (
    <BlockListProvider type={type}>
      {getComponent(blockList, type)}
    </BlockListProvider>
  );
};

export default BlockList;
