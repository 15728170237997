import { get as getAuctions } from 'domain/Auction/services/Auction.js';
import { get as getInvitations } from 'domain/Auction/services/Invitation.js';
import { findById as getGovernment } from 'domain/Government/services/Government.js';

import { useEffect, useState } from 'react';

import { useAuth } from '../../../../shared/hooks/useAuth.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { NAVI } from '../../../../shared/utils/helper/constants.js';
import { NewEntryContext } from '../entities/NewEntryContext.js';
import { useParams } from 'react-router-dom';

const NewEntryProvider = ({ type, children }) => {
  const { governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [auctions, setAuctions] = useState([]);
  const [auction, setAuction] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [government, setGovernment] = useState([]);
  const [invitation, setInvitation] = useState();
  const { localStorageGovernmentId, localStorageProcedureType } =
    useLocalStorage();
  const { auction_id } = useParams(); // Move useParams inside the component body

  if (type === NAVI) {
    useEffect(() => {
      getInvitations(type).then((result) => {
        setInvitations(result);
        if (auction_id) {
          let data = result.find((invitation) => {
            return (
              invitation.governmentId.toString() ===
                localStorageGovernmentId.toString() &&
              invitation.auctionId.toString() === auction_id.toString()
            );
          });
          setInvitation(data);
        }
      });
    }, [localStorageGovernmentId, auction_id]);

    useEffect(() => {
      getAuctions(type).then((auctions) => {
        auctions = auctions.filter(
          (auction) =>
            auction.procedureType.toString() ===
            localStorageProcedureType.toString(),
        );
        setAuctions(auctions);
        setAuction(auctions.find((auction) => auction.id === auction_id));
      });
    }, [localStorageProcedureType, auction_id]);

    useEffect(() => {
      getGovernment(type, localStorageGovernmentId).then((government) => {
        setGovernment(government);
      });
    }, []);
  }

  return (
    <NewEntryContext.Provider
      value={{
        authState,
        government,
        governmentType,
        procedureType,
        auctions,
        auction,
        invitations,
        invitation,
      }}>
      {children}
    </NewEntryContext.Provider>
  );
};

export default NewEntryProvider;
