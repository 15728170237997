// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-other .faq-other-list .disc-list {
  list-style-type: disc;
}

.faq-other .faq-other-list .list-item {
  list-style-type: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/FaqOther/faq-other-list.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".faq-other .faq-other-list .disc-list {\n  list-style-type: disc;\n}\n\n.faq-other .faq-other-list .list-item {\n  list-style-type: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
