// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shipping-info-modal {
  width: 100%;
  padding: 20px;
  pointer-events: auto
}

.shipping-info-modal-contents-body {
  width: 100%;
  height: calc(90vh - 60px - 40px);
  overflow-y: auto;
  pointer-events: auto;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/components/Property/service/PropertyDetail/DeliveryRegistrationInfo/css/read-delivery-registration-info.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb;AACF;;AAEA;EACE,WAAW;EACX,gCAAgC;EAChC,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":[".shipping-info-modal {\n  width: 100%;\n  padding: 20px;\n  pointer-events: auto\n}\n\n.shipping-info-modal-contents-body {\n  width: 100%;\n  height: calc(90vh - 60px - 40px);\n  overflow-y: auto;\n  pointer-events: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
