import { ActionAreaPrice } from './ActionAreaPrice.jsx';

import 'interfaces/css/PropertyDetail/auction-item.css';
import { DATA_EMPTY } from 'domain/Property/entities/constants/property.js';

import React from 'react';
//import { AuthContext } from 'shared/context/AuthContext.js';

export const AuctionItem = ({ data, userId }) => {
  const isLoggedIn = () => {
    return ![null, ''].includes(userId);
  };

  const result = [];
  if (data?.status === 'DURING_BIDDING' || data?.status === 'WAIT_OPEN_BID') {
    if (data?.publicSaleType === 'AUCTION') {
      result.push(
        <React.Fragment key={1}>
          <ActionAreaPrice
            label={
              data?.procedureType === 'PUBLIC_SALE' ? '現在価額' : '現在価格'
            }>
            {data?.currentTopPrice
              ? data?.currentTopPrice
              : Number(data?.estimatePrice).toLocaleString()}
            <small>円</small>
          </ActionAreaPrice>
          <ActionAreaPrice label={'最高額入札者'}>
            <small>
              {data?.currentTopUser === DATA_EMPTY ||
              data?.currentTopUser === undefined
                ? '-'
                : data?.currentTopUser}
            </small>
          </ActionAreaPrice>
          <ActionAreaPrice label={'入札件数'}>
            <small>
              {data?.bidCount === 0 || data?.bidCount === DATA_EMPTY
                ? 0
                : data?.bidCount}
            </small>
          </ActionAreaPrice>
        </React.Fragment>,
      );
    }

    if (
      isLoggedIn &&
      data?.myBidPrice !== undefined &&
      data?.myBidPrice !== DATA_EMPTY
    ) {
      result.push(
        <ActionAreaPrice label={'あなたの入札額'} key={2}>
          {data?.myBidPrice === DATA_EMPTY
            ? '-'
            : Number(data?.myBidPrice).toLocaleString()}
          <small>円</small>
        </ActionAreaPrice>,
      );
    }
  }

  if (data?.status === 'ADDITIONAL_DURING_BIDDING' && isLoggedIn) {
    if (
      data?.myAdditionalPrice !== undefined &&
      data?.myAdditionalPrice !== DATA_EMPTY
    ) {
      result.push(
        <ActionAreaPrice label={'あなたの追加入札後の入札額'} key={3}>
          {data?.myAdditionalPrice === DATA_EMPTY
            ? '-'
            : Number(data?.myAdditionalPrice).toLocaleString()}
          <small>円</small>
        </ActionAreaPrice>,
      );
    }
    if (data?.myBidPrice !== undefined && data?.myBidPrice !== DATA_EMPTY) {
      result.push(
        <ActionAreaPrice label={'あなたの追加入札前の入札額'} key={4}>
          {data?.myBidPrice === DATA_EMPTY
            ? '-'
            : Number(data?.myBidPrice).toLocaleString()}
          <small>円</small>
        </ActionAreaPrice>,
      );
    }
  }
  if (data?.status === 'END_BIDDING') {
    result.push(
      <React.Fragment key={8}>
        <ActionAreaPrice
          label={
            data?.procedureType === 'PUBLIC_SALE'
              ? '落札価額(最高入札価額)'
              : '落札価格(最高入札価格)'
          }>
          {data?.biddedPrice === DATA_EMPTY
            ? '-'
            : Number(data?.bidEndPrice).toLocaleString()}
          <small>円</small>
        </ActionAreaPrice>
        <ActionAreaPrice label={'落札者(最高価買受申込者)'}>
          <small>
            {data?.bidderUser === DATA_EMPTY ? '-' : data?.bidderUser}
          </small>
        </ActionAreaPrice>
      </React.Fragment>,
    );
    if (data?.isLot) {
      return (
        <p className="ma-0 pa-0 mt-n2 mb-2 text-caption">
          ※同額複数のため、くじで選定
        </p>
      );
    }
    if (
      data?.publicSaleType === 'AUCTION' ||
      data?.bidCountDisplay === 'DISPLAY'
    ) {
      result.push(
        <ActionAreaPrice label={'入札件数'} key={5}>
          {data?.bidCount === DATA_EMPTY ? 0 : data?.bidCount}
          <small>件</small>
        </ActionAreaPrice>,
      );
    }
    if (
      isLoggedIn &&
      data?.myBidPrice !== undefined &&
      data?.myBidPrice !== DATA_EMPTY
    ) {
      result.push(
        <ActionAreaPrice label={'あなたの入札額'} key={6}>
          {data?.myBidPrice === DATA_EMPTY
            ? 0
            : Number(data?.myBidPrice).toLocaleString()}
          <small>円</small>
        </ActionAreaPrice>,
      );
    }
    if (
      data?.procedureType === 'PUBLIC_SALE' &&
      data?.publicSaleType === 'BID' &&
      data?.nextWinnerPrice !== undefined &&
      data?.nextWinnerPrice !== DATA_EMPTY
    ) {
      result.push(
        <React.Fragment key={7}>
          <ActionAreaPrice label={'次順位買受申込価額'}>
            {data?.nextWinnerPrice === DATA_EMPTY
              ? '-'
              : Number(data?.nextWinnerPrice).toLocaleString()}
            <small>円</small>
          </ActionAreaPrice>
          <ActionAreaPrice label={'次順位買受申込者'}>
            {data?.nextWinner === DATA_EMPTY ? '該当なし' : data?.nextWinner}
          </ActionAreaPrice>
        </React.Fragment>,
      );
    }
  }
  result.push(
    <React.Fragment key={9}>
      {/*
        <ActionAreaPrice
        label={data?.procedureType === 'PUBLIC_SALE' ? '見積価額' : '予定価格'}>
      */}
      <ActionAreaPrice
        label={data?.procedureType === 'PUBLIC_SALE' ? '見積価額' : '見積価格'}>
        {data?.estimatePrice === DATA_EMPTY
          ? ''
          : Number(data?.estimatePrice).toLocaleString()}
        <small>円</small>
      </ActionAreaPrice>
      <ActionAreaPrice
        label={
          data?.procedureType === 'PUBLIC_SALE' ? '公売保証金' : '入札保証金'
        }>
        {data?.deposit === DATA_EMPTY
          ? ''
          : Number(data?.deposit).toLocaleString()}
        <small>円</small>
      </ActionAreaPrice>
    </React.Fragment>,
  );
  return result;
};
