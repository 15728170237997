import { createContext } from 'react';

import { breadcrumbs } from './breadcrumbs.js';
import { PREFECTURE } from './prefecture.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import { DATA_EMPTY } from '../../../../shared/utils/helper/constants.js';

export const EditUserInfoContext = createContext({
  // Default Value
  responsive: DATA_EMPTY,
  auctionUser: DATA_EMPTY,
  authState: {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  },
  breadcrumbs: breadcrumbs,
  managementMessages: DATA_EMPTY,
  userId: DATA_EMPTY,
  queryParams: DATA_EMPTY,
  preregistrationApplicantInformation: DATA_EMPTY,
  selectBox: {
    prefectures: PREFECTURE,
  },
});
