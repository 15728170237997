import PopUpInput from 'interfaces/components/common/Input/PopUpInput.jsx';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  DIRECTION,
  FIELD,
  PAGE,
  PAGE_SIZE,
  SEARCH_GOVERNMENT_SUB_CATEGORY_ID,
  SEARCH_PROPERTY_CATEGORY_ID,
} from 'shared/utils/constants/queryParam.js';

import { useQueryParamController } from '../../../../../shared/hooks/useQueryParamController.js';

import 'interfaces/css/Properties/between-value-group.css';

const BetweenValueGroup = ({ data, validation = () => {} }) => {
  const [searchParams] = useSearchParams();
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [result, setResult] = useState('');

  useEffect(() => {
    setResult(validation(max, min));
  }, [min, max]);

  useEffect(() => {}, [searchParams]);

  const checkMinValidation = (min) => {
    return validation(max, min);
  };

  const checkMaxValidation = (max) => {
    return validation(max, min);
  };

  return (
    <div className="between-value-group">
      {data &&
        data.map((d, index) => (
          <div key={d.id} className="between-value-group-item">
            {data.length > 1 ? (
              <BetweenValue
                data={d}
                valueSet={index === 0 ? setMin : setMax}
                checkValidation={
                  index === 0 ? checkMinValidation : checkMaxValidation
                }
                otherValue={index === 0 ? max : min}
              />
            ) : (
              <BetweenValue data={d} />
            )}
          </div>
        ))}
      {result !== 'TRUE' && min !== '' && max !== '' ? (
        <div className="validate-message">{result}</div>
      ) : (
        ''
      )}
    </div>
  );
};

const BetweenValue = ({
  data,
  valueSet = () => {},
  //checkValidation = () => {},
  //otherValue = '',
}) => {
  const [value, setValue] = useState(data.value);
  const [searchParams] = useSearchParams();
  const { applyQueryParam, appendQueryParam } = useQueryParamController();

  useEffect(() => {
    if (searchParams.has(data.key)) {
      setValue(() => {
        const obj = { ...data.queryParam };
        return Object.keys(obj).find((key) => {
          return obj[key].toString() === searchParams.get(data.key);
        });
      });
    } else {
      setValue(data.value);
      valueSet('');
    }
  }, [searchParams]);

  const handleSelect = (item) => {
    setValue(item);
    valueSet(data.queryParam[item]);

    // ここの条件がおかしい。片方のフォームに値が含まれるとき、もう片方に値が入らないような動きをしている
    //if (
    //  checkValidation(data.queryParam[item]) !== 'TRUE' ||
    //  item === '指定なし' ||
    //  otherValue === ''
    //) {
    //  applyQueryParam(data.key, data.queryParam[item]);
    //}
    // 一旦常にパラメータを設定するようにする。
    applyQueryParam(data.key, data.queryParam[item]);

    // 初期化を行う（パラメータが含まれていないときにデフォルト値を入れる。検索トップから検索した時に使用）
    appendQueryParam(SEARCH_PROPERTY_CATEGORY_ID, '');
    appendQueryParam(SEARCH_GOVERNMENT_SUB_CATEGORY_ID, '');
    appendQueryParam(DIRECTION, 'ASC');
    appendQueryParam(FIELD, 'PRICE');
    appendQueryParam(PAGE, 1);
    appendQueryParam(PAGE_SIZE, 50);
  };

  return (
    <>
      <label data-testid="input-label" className="input-label">
        {data.label}
      </label>
      <PopUpInput
        items={data.items}
        value={value}
        handleSelect={handleSelect}
      />
      <div className="between-value-group-item-detail"></div>
    </>
  );
};

export default BetweenValueGroup;
