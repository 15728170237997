// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category .accordion-panel {
  min-height: 30px;
}

.category .kmanager-accordion-label {
  width: 100%;
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.category .kmanager-accordion-label > p {
  flex-shrink: 0;
}

.category .kmanager-accordion-label > svg {
  width: 25px;
  height: 25px;
  margin: 0 10px;
  fill: rgba(0,0,0,.54);
}

.category .kmanager-accordion-label > .kmanager-button {
  margin-right: 10px;
}

.category .form-input {
  font-size: 16px;
}

.category .form-input > label {
  margin: 0 10px;
}

.category .accordion-toggle-button {
  height: 24px;
  width: 24px;
  outline: none;
  position: relative;
  display: inline-block;
  /*padding-left: 12px;*/
  color: var(--line-darken4);
  text-decoration: none;
}

.category .accordion-toggle-button:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 5px;
  border-color: transparent transparent transparent var(--line-darken4);
  position: absolute;
  left: 10px;
  margin-top: -3px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Category/category_accordion.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,0BAA0B;EAC1B,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,QAAQ;EACR,SAAS;EACT,mBAAmB;EACnB,2BAA2B;EAC3B,qEAAqE;EACrE,kBAAkB;EAClB,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".category .accordion-panel {\n  min-height: 30px;\n}\n\n.category .kmanager-accordion-label {\n  width: 100%;\n  font-size: 16px;\n  margin-left: 5px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.category .kmanager-accordion-label > p {\n  flex-shrink: 0;\n}\n\n.category .kmanager-accordion-label > svg {\n  width: 25px;\n  height: 25px;\n  margin: 0 10px;\n  fill: rgba(0,0,0,.54);\n}\n\n.category .kmanager-accordion-label > .kmanager-button {\n  margin-right: 10px;\n}\n\n.category .form-input {\n  font-size: 16px;\n}\n\n.category .form-input > label {\n  margin: 0 10px;\n}\n\n.category .accordion-toggle-button {\n  height: 24px;\n  width: 24px;\n  outline: none;\n  position: relative;\n  display: inline-block;\n  /*padding-left: 12px;*/\n  color: var(--line-darken4);\n  text-decoration: none;\n}\n\n.category .accordion-toggle-button:before {\n  content: '';\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 4px 0 4px 5px;\n  border-color: transparent transparent transparent var(--line-darken4);\n  position: absolute;\n  left: 10px;\n  margin-top: -3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
