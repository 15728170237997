import GovernmentPayment from "interfaces/components/Government/kmanager/Payment/GovernmentPayment";

const KmanagerGovernmentPayments = () => {
    return (
        <div data-testid="kmanager-payment">
            <GovernmentPayment/>
        </div>
    );
}

export default KmanagerGovernmentPayments;