// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-toolbar {
  background: var(--secondary-base);
}

.sort-toolbar-inner {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}

.sort-toolbar .group {
  display: flex;
  flex-wrap: wrap;
}

.sort-toolbar > * + * {
  margin-left: 10px;
}

.sort-toolbar .delegation-info {
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0 40px 0 0;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/sort-toolbar.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".sort-toolbar {\n  background: var(--secondary-base);\n}\n\n.sort-toolbar-inner {\n  display: flex;\n  padding: 10px;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.sort-toolbar .group {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.sort-toolbar > * + * {\n  margin-left: 10px;\n}\n\n.sort-toolbar .delegation-info {\n  max-width: 100%;\n  display: flex;\n  justify-content: flex-end;\n  margin: 0 40px 0 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
