import pino from 'pino';

import { BASE_URL_NAVIGATION } from '../../../config/envConstants.js';
import { fetchData } from '../../../shared/utils/helper/fetchData.js';

const logger = pino();
const CURRENT_PREREGISTRATION_APPLICANT_INFORMATION = `${BASE_URL_NAVIGATION}/storage/current/preregistration_applicant_information/data.json`;
const PREVIOUS_PREREGISTRATION_APPLICANT_INFORMATION = `${BASE_URL_NAVIGATION}/storage/previous/preregistration_applicant_information/data.json`;

export const getPreregistrationApplicantInformation = async () => {
  try {
    const response = await fetchCurrentPreregistrationApplicantInformation();
    return extractPreregistrationApplicantInformation(response);
  } catch (error) {
    logger.warn(
      { error },
      '現在の通知（ preregistration_applicant_information ）を取得できませんでした',
    );

    try {
      const response = await fetchPreviousPreregistrationApplicantInformation();
      return extractPreregistrationApplicantInformation(response);
    } catch (error) {
      logger.error(
        { error },
        '以前の通知（ preregistration_applicant_information ）の取得にも失敗しました',
      );
      return [];
    }
  }
};

export const fetchCurrentPreregistrationApplicantInformation = async () => {
  return fetchPreregistrationApplicantInformation(
    CURRENT_PREREGISTRATION_APPLICANT_INFORMATION,
  );
};

export const fetchPreviousPreregistrationApplicantInformation = async () => {
  return fetchPreregistrationApplicantInformation(
    PREVIOUS_PREREGISTRATION_APPLICANT_INFORMATION,
  );
};

export const fetchPreregistrationApplicantInformation = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
};

export const extractPreregistrationApplicantInformation = (response) => {
  return response.map(
    (preregistration_applicant_information) =>
      preregistration_applicant_information,
  );
};
