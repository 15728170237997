import { useEffect, useState, useContext } from 'react';
import { GovernmentContext } from 'interfaces/pages/Government/entities/GovernmentContext';
import {
  ASSET_SALE,
  PUBLIC_SALE,
} from 'domain/Property/entities/constants/property';

//import PageLoading from 'interfaces/components/common/PageLoading/PageLoading';
import ServiceSwitcher from 'interfaces/components/common/Switcher/ServiceSwitcher';
import PropertyList from 'interfaces/components/Property/service/PropertyList/PropertyList';
import {
  //Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import GovernmentHead from './GovernmentHead';
import Information from './Information';
import OfficialCharacter from './OfficialCharacter';
import SimpleScheduleSection from './SimpleScheduleSection';

const Government = () => {
  const { government_id } = useParams();
  const location = useLocation();
  const procedureTypeParam = new URLSearchParams(location.search).get('p');
  const navigation = useNavigate();
  const [procedureType, setProcedureType] = useState('');
  const {
    auction,
    allProperties,
    auctionProperties,
    bidProperties,
    invitations,
    government,
  } = useContext(GovernmentContext);

  //const governmentData = {
  //  name: 'mock auction data',
  //  auctionInfo: {
  //    aplEnd: '2023-12-10 18:00:00',
  //    aplStart: '2023-12-03 08:00:00',
  //    bidEnd: '2024-01-15 18:00:00',
  //    bidStart: '2023-12-25 08:00:00',
  //    govHpName: '',
  //    govHpUrl: '',
  //    inquiryAccept: '',
  //    inquiryEmail: '',
  //    inquiryExtension: '',
  //    inquiryName: '',
  //    inquiryTel: '',
  //    name: '行政機関（公売）',
  //    previewHpName: '',
  //    previewUrl: '',
  //    propertyIds: [],
  //    status: '入札期間中（せり売形式）',
  //  },
  //};
  const tabLabels = ['すべて', 'せり売形式', '入札形式'];
  const tabLabelsKokuzei = ['せり売形式'];

  //const isBothProperties =
  //  !!bidProperties?.length && !!auctionProperties?.length;

  const isBothProperties = true;

  const [isImageList, setIsImageList] = useState(true);

  //const invitations = useInvitations(id);
  //const invitations = [
  //  [
  //    {
  //      data: {
  //        auction_id: '4',
  //        government_id: '2',
  //        name: '一般参加申込（公売）',
  //        bid_end_type: 'MANUAL',
  //        mail_cert_document: 'NO_MAIL',
  //        mail_reduction_document: 'NO_MAIL',
  //        none_deposit: 'TRUE',
  //        payment_credit_card: 'TRUE',
  //        payment_bank_transfer: 'TRUE',
  //        payment_deadline: '2024-06-21 18:00',
  //        apl_count_auction_display: 'HIDDEN',
  //        apl_count_bid_display: 'DISPLAY',
  //        bid_count_display: 'DISPLAY',
  //        area: '北海道',
  //        open_button_down_date: '1000-01-01 00:00',
  //        call_up_button_down_auction_date: '1000-01-01 0:00:00',
  //        call_up_button_down_bid_date: '1000-01-01 0:00:00',
  //        bid_end_button_down_auction_date: '1000-01-01 0:00:00',
  //        bid_end_button_down_bid_date: '1000-01-01 00:00',
  //        call_up_auction_status: 'BEFORE',
  //        call_up_bid_status: 'BEFORE',
  //        common_description: 'これはテスト用のオークションです。',
  //        tax_type: 'TOTAL_TAXATION',
  //        management_registration_status: 'REGISTRATION',
  //        participation_status: 'PARTICIPATION',
  //        tax_id: '1',
  //        created_user: 'テスト太郎',
  //        created_at: '2023-11-08 11:00:01',
  //        updated_user: 'テスト太郎',
  //        updated_at: '2023-11-08 11:00:01',
  //      },
  //    },
  //  ],
  //];

  const Character = `${process.env.REACT_APP_BASE_URL_SERVICE}/image_storage/${government_id}/character.jpg`;

  //const propertiesCount = {
  //  auctionPropertiesCount: auctionProperties.length,
  //  bidPropertiesCount: bidProperties.length,
  //};
  // todo: mock
  const propertiesCount = {
    auctionPropertiesCount: 10,
    bidPropertiesCount: 20,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (procedureTypeParam === 'au') {
      setProcedureType(PUBLIC_SALE);
      return;
    }
    if (procedureTypeParam === 'as') {
      setProcedureType(ASSET_SALE);
      return;
    }
    navigation('/');
  }, [procedureTypeParam]);

  if (government?.data === undefined) {
    console.log('aiueo');
    //return <Navigate replace to="/" />;
  }

  //const isFetchGovernmentData = !(
  //  Object.keys(governmentData).length === 0 &&
  //  governmentData.constructor === Object
  //);

  return (
    <div className="kservice_government" data-testid="government">
      <div className="inner">
        <GovernmentHead
          governmentId={government_id}
          procedureType={procedureType}
        />
        <ServiceSwitcher
          labels={
            government.governmentType === 'NATIONAL_TAX_AGENCY'
              ? tabLabelsKokuzei
              : tabLabels
          }
          alwaysVisibleComponent={
            <SimpleScheduleSection
              governmentData={auction}
              propertiesCount={propertiesCount}
              invitatiosns={invitations}
            />
          }>
          <PropertyList
            dataList={allProperties}
            isImageList={isImageList}
            setIsImageList={setIsImageList}
          />
          {isBothProperties ? (
            <PropertyList
              dataList={auctionProperties}
              isImageList={isImageList}
              setIsImageList={setIsImageList}
            />
          ) : null}
          {isBothProperties ? (
            <PropertyList
              dataList={bidProperties}
              isImageList={isImageList}
              setIsImageList={setIsImageList}
            />
          ) : null}
        </ServiceSwitcher>
        <Information
          publicSaleType={'AUCTION'}
          procedureType={auction.procedureType}
        />
        <Information
          publicSaleType={'BID'}
          procedureType={auction.procedureType}
        />
        <OfficialCharacter src={Character} name="KSI" />
      </div>
    </div>
  );
};

export default Government;
