import Button from 'interfaces/components/common/Button/Button.jsx';
import Toggle from 'interfaces/components/common/Toggle/Toggle.jsx';
import BothForm from 'interfaces/components/LocalGovernmentManagement/bank_transfer/BothForm.jsx';
import IndividualForm from 'interfaces/components/LocalGovernmentManagement/bank_transfer/IndividualForm.jsx';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import { allFetch } from "../../api/newEntry.js";
//import configs from "../../api/configs/bankProvisionalApplyCompletion.js";

const BankTransferForm = ({
  validations,
  toggle,
  setToggle,
  setIsOpen,
  governmentType,
}) => {
  const navigate = useNavigate();

  const update = async (e) => {
    e.preventDefault();
    //const Configs = configs(GOVERNMENT_ID, PROCEDURE_TYPE, validations, auctionBankTransferPages, toggle);
    //await allFetch(Configs.auction);
    //await allFetch(Configs.public);

    setIsOpen(true);
  };

  const backToLocalGovernmentManagement = () => {
    navigate('/navi/local-government-management');
  };

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const validateMessages = (form) => {
      const { validationMessage } = form;

      return Object.entries(validationMessage).every(
        ([key, validationMessage]) => {
          if (
            key === 'bidJointBiddingUrlValid' ||
            key === 'auctionJointBiddingUrlValid'
          ) {
            return validationMessage === null || validationMessage === 'TRUE';
          }
          return validationMessage === 'TRUE';
        },
      );
    };

    setIsValid(validateMessages(validations));
  }, [validations]);

  return (
    <div
      className="new-style_navi_local-government-management-top-form"
      data-testid="bank-provisional-apply-completion-form">
      {governmentType !== 'NATIONAL_TAX_AGENCY' && (
        <>
          <Toggle
            message={'「せり売形式」と「入札形式」で個別に情報を登録する'}
            handler={() => setToggle(!toggle)}
          />
          <div className="sub-message">
            ※
            参加者が仮申し込みを完了した際の表示を「せり売形式」と「入札形式」の物件で個別に設定したい場合にご利用ください。
            <br />
          </div>
        </>
      )}
      {!toggle && <BothForm validations={validations} governmentType={governmentType} />}
      {toggle && <IndividualForm validations={validations} governmentType={governmentType} />}
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'登録'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_primary-button'
              }
              onClick={update}
              isDisabled={!isValid}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button common-button-bg-whitesmoke new-style_navi_normal-button'
              }
              onClick={backToLocalGovernmentManagement}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankTransferForm;
