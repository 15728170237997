import { ValidationMessage } from 'interfaces/components/common/ValidationMessage';
import { convertEmptyCharacters } from 'shared/utils/helper/convert';

import 'interfaces/css/common/property-form-input-text.css';

const PropertyFormInputText = ({
  invalidMessage,
  number_remaining_characters,
  className,
  data = '',
  handler1 = () => {},
  handler2 = () => {},
  placeholder = '',
  disabled = false,
}) => {
  function onInputChange(event) {
    handler1(event);
    handler2(event);
  }

  return (
    <div data-testid="property-form-input-text-classname" className={className}>
      <input
        type="text"
        className="property-form-input-text-input"
        value={convertEmptyCharacters(data)}
        onChange={onInputChange}
        placeholder={placeholder}
        disabled={disabled}
      />
      <ValidationMessage invalidMessage={invalidMessage} />
      {number_remaining_characters ? (
        <div className="property-form-input-text-absolute-area">
          <p
            data-testid="number-remaining-characters"
            className="number-remaining-characters">
            {'残り' + number_remaining_characters + '文字'}
          </p>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default PropertyFormInputText;
