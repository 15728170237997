import { HYPHEN_SYMBOL } from 'domain/Property/entities/constants/property';

import { bidPrice } from '../../helper/bidPrice';
import { depositToYen } from '../../helper/deposit';
import { isFalsy } from '../../helper/isFalsy';
import { joinArrayWhiteSpace } from '../../helper/joinArrayWhiteSpace';

export const CurrentTopUserBody = ({ currentTopUser }) => {
  return (
    <div
      className="detail-item box nogrow flex pa-0 mb-0 gaps"
      data-testid="current-top-user">
      <div className="box text-no-wrap no-bg property-sheet theme-light">
        最高額入札者
      </div>
      <div className="box no-bg property-sheet theme-light">
        <p className="mb-0">
          <b>{isFalsy(currentTopUser) ? HYPHEN_SYMBOL : currentTopUser}</b>
        </p>
      </div>
      <div className="box mr-4 property-sheet theme-light"> |</div>
    </div>
  );
};

export const DepositBody = ({ deposit }) => {
  return (
    <div
      className="detail-item box nogrow flex pa-0 mb-0 gaps"
      data-testid="deposit">
      <div className="box text-no-wrap no-bg property-sheet theme-light">
        保証金
      </div>
      <div className="box no-bg property-sheet theme-light">
        <p className="mb-0">
          <b>{depositToYen(deposit)}</b>
        </p>
      </div>
      <div className="box mr-4 property-sheet theme-light"> |</div>
    </div>
  );
};

export const DepositPaymentBody = ({ depositPayment }) => {
  return (
    <div
      className="detail-item box nogrow flex pa-0 mb-0 gaps"
      data-testid="deposit-payment-method">
      <div className="box text-no-wrap no-bg property-sheet theme-light">
        保証金納付方法
      </div>
      <div className="box no-bg property-sheet theme-light">
        <p className="mb-0">
          <b>{depositPayment}</b>
        </p>
      </div>
      <div className="box mr-4 property-sheet theme-light"> |</div>
    </div>
  );
};

export const PaymentBody = ({ payments }) => {
  return (
    <div
      className="detail-item box nogrow flex pa-0 mb-0 gaps"
      data-testid="payment-method">
      <div className="box text-no-wrap no-bg property-sheet theme-light">
        代金納付方法
      </div>
      <div className="box no-bg property-sheet theme-light">
        <p className="mb-0">
          <b>{joinArrayWhiteSpace(payments)}</b>
        </p>
      </div>
      <div className="box mr-4 property-sheet theme-light"> |</div>
    </div>
  );
};

export const BidCountBody = ({ bidCount }) => {
  return (
    <div
      className="detail-item box nogrow flex pa-0 mb-0 gaps"
      data-testid="bid-count">
      <div className="box text-no-wrap no-bg property-sheet theme-light">
        入札件数
      </div>
      <div className="box no-bg property-sheet theme-light">
        <p className="mb-0">
          <b>{isFalsy(bidCount) ? '0' : bidCount}</b>
        </p>
      </div>
      <div className="box mr-4 property-sheet theme-light"> |</div>
    </div>
  );
};

export const MyBidPriceBody = ({ myBidPrice }) => {
  return (
    <div
      className="detail-item box nogrow flex pa-0 mb-0 gaps"
      data-testid="my-bid-price">
      <div className="box text-no-wrap no-bg property-sheet theme-light">
        あなたの入札額
      </div>
      <div className="box no-bg property-sheet theme-light">
        <p className="mb-0">
          <b>{bidPrice(myBidPrice)}</b>
        </p>
      </div>
      <div className="box mr-4 property-sheet theme-light"> |</div>
    </div>
  );
};

export const RemainingLabel = ({ remainingLabel, deadline }) => {
  return (
    <div
      className="detail-item box nogrow flex pa-0 mb-0 gaps"
      data-testid="application-dead-line">
      <div className="box text-no-wrap no-bg property-sheet theme-light">
        {remainingLabel}
      </div>
      <div className="box no-bg property-sheet theme-light">
        <p className="mb-0">
          <b>{deadline}</b>
        </p>
      </div>
      <div className="box mr-4 property-sheet theme-light"> |</div>
    </div>
  );
};

const PropertyCardCaption = ({ label, value }) => {
  return (
    <div
      className="detail-item box nogrow flex pa-0 mb-0 gaps"
      data-testid="property-card-caption">
      <div className="box text-no-wrap no-bg property-sheet theme-light">
        {label}
      </div>
      <div className="box no-bg property-sheet theme-light">
        <p className="mb-0">
          <b>{value}</b>
        </p>
      </div>
      <div className="box mr-4 property-sheet theme-light"> |</div>
    </div>
  );
};

export default PropertyCardCaption;
