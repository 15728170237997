import { ValidationMessage } from 'interfaces/components/common/ValidationMessage';
import MarkdownIt from 'markdown-it';
import React from 'react';
import MdEditor from 'react-markdown-editor-lite';

import FormLabel from './FormLabel.jsx';

const InputMarkDown = React.memo(function InputMarkDown({
  invalidMessage,
  required,
  label,
  state,
  setState,
  width = '700px',
}) {
  const mdParser = new MarkdownIt();
  return (
    <div className="kmanager-row">
      <div className="kmanager-col-4">
        <FormLabel label={label} required={required} />
      </div>
      <div className="kmanager-col form-area flex-column">
        <div
          style={{ position: 'relative' }}
          className={'form-input'}
          data-testid="input-mark-down">
          <MdEditor
            style={{ height: '300px', wordBreak: 'break-all', width: width }}
            renderHTML={(text) => mdParser.render(text)}
            onChange={(e) => setState(e)}
            value={state}
          />
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
      </div>
    </div>
  );
});
export default InputMarkDown;
