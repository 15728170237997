import GovernmentDetail from 'interfaces/components/Government/Detail/GovernmentDetail'

const KmanagerGovernmentDetail = () => {
    return (
        <div data-testid="kmanager-government-list">
            <GovernmentDetail/>
        </div>
    );
}

export default KmanagerGovernmentDetail;
