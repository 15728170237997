import { useState, useEffect } from 'react';
//import { logInput } from "../../utils/log/logInputs.js";
import * as Validation from 'shared/utils/helper/naviValidators';

export const NaviUserRegisterForm = (isEdit) => {
  const [contactEmailValid, setContactEmailValid] = useState(null);
  const [extensionValid, setExtensionValid] = useState(null);
  const [telValid, setTelValid] = useState(null);
  const [tel1Valid, setTel1Valid] = useState(null);
  const [tel2Valid, setTel2Valid] = useState(null);
  const [tel3Valid, setTel3Valid] = useState(null);
  const [positionValid, setPositionValid] = useState(null);
  const [departmentValid, setDepartmentValid] = useState(null);
  const [firstNameKanaRequiredValid, setFirstNameKanaRequiredValid] = useState(null);
  const [firstNameRequiredValid, setFirstNameRequiredValid] = useState(null);
  const [familyNameKanaRequiredValid, setFamilyNameKanaRequiredValid] = useState(null);
  const [familyNameRequiredValid, setFamilyNameRequiredValid] = useState(null);
  
  useEffect(() => {
    setContactEmailValid(isEdit ? "TRUE" : null);
    setExtensionValid(isEdit ? "TRUE" : null);
    setTelValid(isEdit ? "TRUE" : null);
    setTel1Valid(isEdit ? "TRUE" : null);
    setTel2Valid(isEdit ? "TRUE" : null);
    setTel3Valid(isEdit ? "TRUE" : null);
    setPositionValid(isEdit ? "TRUE" : null);
    setDepartmentValid(isEdit ? "TRUE" : null);
    setFirstNameKanaRequiredValid(isEdit ? "TRUE" : null);
    setFirstNameRequiredValid(isEdit ? "TRUE" : null);
    setFamilyNameKanaRequiredValid(isEdit ? "TRUE" : null);
    setFamilyNameRequiredValid(isEdit ? "TRUE" : null);
  }, [isEdit]);

  function handleFamilyNameValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateRangeLength(e.target.value, 1, 30);
    if (invalid !== 'TRUE') {
      setFamilyNameRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setFamilyNameRequiredValid(invalid);
      return;
    }
    setFamilyNameRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleFirstNameValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateRangeLength(e.target.value, 1, 30);
    if (invalid !== 'TRUE') {
      setFirstNameRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setFirstNameRequiredValid(invalid);
      return;
    }
    setFirstNameRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleFamilyNameKanaValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateRangeLength(e.target.value, 1, 30);
    if (invalid !== 'TRUE') {
      setFamilyNameKanaRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateFullKatakana(e.target.value);
    if (invalid !== 'TRUE') {
      setFamilyNameKanaRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setFamilyNameKanaRequiredValid(invalid);
      return;
    }
    setFamilyNameKanaRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleFirstNameKanaValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateRangeLength(e.target.value, 1, 30);
    if (invalid !== 'TRUE') {
      setFirstNameKanaRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateFullKatakana(e.target.value);
    if (invalid !== 'TRUE') {
      setFirstNameKanaRequiredValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setFirstNameKanaRequiredValid(invalid);
      return;
    }
    setFirstNameKanaRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleDepartmentValid(e) {
    let invalid = null;
    invalid = Validation.validateRangeLength(e.target.value, 1, 30);
    if (invalid !== 'TRUE') {
      setDepartmentValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setDepartmentValid(invalid);
      return;
    }
    setDepartmentValid(invalid); // エラーメッセージのリセット
  }

  function handleOfficePositionValid(e) {
    let invalid = null;
    invalid = Validation.validateRangeLength(e.target.value, 1, 30);
    if (invalid !== 'TRUE') {
      setPositionValid(invalid);
      return;
    }
    invalid = Validation.validateHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setDepartmentValid(invalid);
      return;
    }

    setPositionValid(invalid); // エラーメッセージのリセット
  }

  function handleTelValid(tel) {
    let invalid = null;
    const telAll = tel.telNum1 + tel.telNum2 + tel.telNum3;
    invalid = Validation.validateRangeLength(telAll, 10, 11);
    if (invalid !== 'TRUE') {
      setTelValid(invalid);
      return;
    }
    setTelValid(invalid); // エラーメッセージのリセット
  }

  function handleTel1Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setTel1Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setTel1Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setTel1Valid(invalid);
      return;
    }
    setTel1Valid(invalid); // エラーメッセージのリセット
  }

  function handleTel2Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setTel2Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setTel2Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setTel2Valid(invalid);
      return;
    }
    setTel2Valid(invalid); // エラーメッセージのリセット
  }

  function handleTel3Valid(tel) {
    let invalid = null;
    invalid = Validation.validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setTel3Valid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setTel3Valid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(tel);
    if (invalid !== 'TRUE') {
      setTel3Valid(invalid);
      return;
    }
    setTel3Valid(invalid); // エラーメッセージのリセット
  }

  function handleExtensionValid(e) {
    let invalid = null;
    invalid = Validation.validateRangeLength(e.target.value, 0, 10);
    if (invalid !== 'TRUE') {
      setExtensionValid(invalid);
      return;
    }
    invalid = Validation.validateHasNoSpace(e.target.value);
    if (invalid !== 'TRUE') {
      setExtensionValid(invalid);
      return;
    }
    invalid = Validation.validateOnlyNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setExtensionValid(invalid);
      return;
    }
    setExtensionValid(invalid); // エラーメッセージのリセット
  }

  function handleEmailValid(e, required = false) {
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setContactEmailValid(invalid);
        return;
      }
    }
    invalid = Validation.validateEmailFormat(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    invalid = Validation.validateEmailLength(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    setContactEmailValid(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      handleEmailValid,
      handleExtensionValid,
      handleTelValid,
      handleTel1Valid,
      handleTel2Valid,
      handleTel3Valid,
      handleOfficePositionValid,
      handleDepartmentValid,
      handleFirstNameKanaValid,
      handleFirstNameValid,
      handleFamilyNameKanaValid,
      handleFamilyNameValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      contactEmailValid,
      extensionValid,
      telValid,
      tel1Valid,
      tel2Valid,
      tel3Valid,
      positionValid,
      departmentValid,
      firstNameKanaRequiredValid,
      firstNameRequiredValid,
      familyNameKanaRequiredValid,
      familyNameRequiredValid,
    },
  };

  return validations;
};
