// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page {
  padding-top: 15%;
  height: calc(100vh - 120px);
}

.error-page-status-code {
  height: 96px;
  font-size: 4rem;
  text-align: center;
  color: var(--error-code);
  margin-bottom: 8px;
}

.error-page-caption {
  height: 24px;
  font-size: 1rem;
  text-align: center;
  color: var(--error-code);
  margin-bottom: 44px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/error-page.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,wBAAwB;EACxB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,wBAAwB;EACxB,mBAAmB;AACrB","sourcesContent":[".error-page {\n  padding-top: 15%;\n  height: calc(100vh - 120px);\n}\n\n.error-page-status-code {\n  height: 96px;\n  font-size: 4rem;\n  text-align: center;\n  color: var(--error-code);\n  margin-bottom: 8px;\n}\n\n.error-page-caption {\n  height: 24px;\n  font-size: 1rem;\n  text-align: center;\n  color: var(--error-code);\n  margin-bottom: 44px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
