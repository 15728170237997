// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq .faq-list .small-topic-title-wrap {
  margin-bottom: 16px;
}

.faq .faq-list .small-topic-title-wrap:last-child {
  margin-bottom: 0;
}

.faq .faq-list .small-topic-link {
  font-weight: 700;
}

.faq .faq-list .heading3 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.faq-list .faq-headline {
  padding: 8px 0 8px 15px;
  position: relative;
  font-size: 18px;
  line-height: 1.222;
  margin-inline-start: initial;
  margin-inline-end: initial;
}

.faq-list .faq-headline:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background: #ff7f00;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Faq/faq-list.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,4BAA4B;EAC5B,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,OAAO;EACP,UAAU;EACV,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".faq .faq-list .small-topic-title-wrap {\n  margin-bottom: 16px;\n}\n\n.faq .faq-list .small-topic-title-wrap:last-child {\n  margin-bottom: 0;\n}\n\n.faq .faq-list .small-topic-link {\n  font-weight: 700;\n}\n\n.faq .faq-list .heading3 {\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n\n.faq-list .faq-headline {\n  padding: 8px 0 8px 15px;\n  position: relative;\n  font-size: 18px;\n  line-height: 1.222;\n  margin-inline-start: initial;\n  margin-inline-end: initial;\n}\n\n.faq-list .faq-headline:before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  width: 5px;\n  height: 100%;\n  background: #ff7f00;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
