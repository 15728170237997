import BidderListProvider from './BidderListProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { bidderList } from "../entities/componentMapping.js";

const BidderList = ({ type }) => {
  return (
    <BidderListProvider type={type}>
      {getComponent(bidderList, type)}
    </BidderListProvider>
  );
};

export default BidderList;
