
import Button from "interfaces/components/common/Button";
import PageTitle from "interfaces/components/common/PageTitle";
import Toolbar from "interfaces/components/common/Toolbar";
import {useContext} from "react";
import { useNavigate } from "react-router-dom";

import KmanagerAuctionTable from "./KmanagerAuctionTable";
import {DashboardContext} from "../../../pages/Dashboard/entities/DashboardContext";

const KmanagerAuctions = () => {
  const navigation = useNavigate();
  const { auctions, error } = useContext(DashboardContext);

  //useEffect((data, column) => {
  //  if (!isLoading) {
  //    result.sort((a, b) => a.aplEnd - b.aplEnd)
  //    console.log(result);
  //  }
  //}, [isLoading]);

  return (
    <div className="kmanager-auctions" data-testid="kmanager-auctions">
      <div className="kmanager-row">
        <div className="kmanager-col kmanager-col-12">
          <PageTitle text="実施オークション一覧"/>
        </div>
        <div className="kmanager-col kmanager-col-12">
          <div className="box-shadow">
            <Toolbar>
              <Button className="primary" text="新規オークション作成"
                      clickHandler={() => navigation('/kmanager/auction/edit/new_auction')}/>
              <hr className="kmanager-toolbar-separating-line"/>
            </Toolbar>
            {error?.code && <PageTitle text={error.message}/>}
            {/*isLoading && <LoadingMessage/>*/}
            <KmanagerAuctionTable auctions={auctions}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KmanagerAuctions;
