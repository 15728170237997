import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { get as getGuidelines } from '../../../../domain/Guidelines/services/Guidelines.js';
import { findById } from '../../../../domain/Property/services/Property.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
  DATA_EMPTY,
} from '../../../../shared/utils/helper/constants.js';
import { ApplicationContext } from '../entities/ApplicationContext.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { whereByUserId as findByPreregistrationApplicantInfo } from 'domain/Applicant/services/PreregistrationApplicantInformation';
import { findById as findByAuctionUserId } from 'domain/User/services/ServiceUser';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage.js';

const ApplicationProvider = ({ type, children }) => {
  const { isLoggedIn } = useAuth();
  const { id } = useParams();
  const [procedureTypeEn, setProcedureTypeEn] = useState(DATA_EMPTY);
  const [publicSaleType, setPublicSaleType] = useState([]);
  const [governmentId, setGovernmentId] = useState([]);
  const [guidelineInformation, setGuidelineInformation] = useState([]);
  const [property, setProperty] = useState([]);
  const [auctionUser, setAuctionUser] = useState({});
  const [
    preregistrationApplicantInformation,
    setPreregistrationApplicantInformation,
  ] = useState([]);

  const { localStorageUserId } = useLocalStorage();

  console.log(property);

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      setProcedureTypeEn(property.procedureTypeEn);
      setPublicSaleType(property?.publicSaleType);
      setGovernmentId(property?.governmentId);
    }, [property]);

    useEffect(() => {
      getGuidelines(type).then((result) => {
        setGuidelineInformation(result);
      });
    }, []);

    useEffect(() => {
      findById(type, id).then((result) => {
        setProperty(result);
      });
    }, []);

    useEffect(() => {
      findByPreregistrationApplicantInfo(type, localStorageUserId).then(
        (applicants) => {
          setPreregistrationApplicantInformation(applicants);
        },
      );

      findByAuctionUserId(type, localStorageUserId).then((user) => {
        setAuctionUser(user);
      });
    }, [localStorageUserId]);
  }

  if (type === NAVI) {
    useEffect(() => {}, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <ApplicationContext.Provider
      value={{
        breadcrumbs,
        isLoggedIn,
        procedureTypeEn,
        publicSaleType,
        governmentId,
        guidelineInformation,
        property,
        preregistrationApplicantInformation,
        auctionUser,
      }}>
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationProvider;
