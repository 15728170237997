
export class ContractedLimit {
  #contractedLimit

  constructor(contractedLimit) {
    this.#contractedLimit = contractedLimit;
  }

  get() {
    return this.#contractedLimit
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
