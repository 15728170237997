import { useEffect, useState } from 'react';

import Button from '../Button.jsx';

const ConfirmButton = ({ inputs, toConfirm, notRequiredColumns = [] }) => {
  const [isValid, setIsValid] = useState(false);

  const Confirm = () => {
    // TODO: クリック時のバリデーションチェック
    toConfirm();
  };

  useEffect(() => {
    setIsValid(
      inputs.every((input) => {
        if (notRequiredColumns.includes(input.column)) {
          return (
            input?.validationMessage === 'TRUE' ||
            input?.validationMessage === null
          );
        }
        return input?.validationMessage === 'TRUE';
      }),
    );
  }, [inputs]);

  return (
    <Button
      text={'確認'}
      clickHandler={Confirm}
      isDisabled={!isValid}
      className={'primary'}
    />
  );
};

export default ConfirmButton;
