import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle';

const ApplicationInfoSectionHeader = ({ title, caption }) => {
  return (
    <div>
      <ArticleDefaultTitle title={title} />
      <div className="mb-4">
        <small>{caption}</small>
      </div>
    </div>
  );
};

export default ApplicationInfoSectionHeader;
