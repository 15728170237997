import 'interfaces/css/common/icon-toggle-button.css'

const IconToggleButton = ({ svg, onClick }) => {
  return (
    <button data-testid="kmanager-header-icon-toggle-button"
            className="kmanager-header-icon-toggle-button d-flex align-center justify-center" onClick={onClick}>
      {svg}
    </button>
  );
}
export default IconToggleButton;