// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service {
  background-color: var(--bgcolor-base);
}

.inner {
  max-width: 1200px;
  width: 90%;
  padding: 24px 0 40px 0;
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/layout.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;AACvC;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,sBAAsB;EACtB,cAAc;AAChB","sourcesContent":[".service {\n  background-color: var(--bgcolor-base);\n}\n\n.inner {\n  max-width: 1200px;\n  width: 90%;\n  padding: 24px 0 40px 0;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
