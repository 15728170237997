import { useAfterWinningAssetSaleBidCautionInputs } from 'shared/utils/helper/useAfterWinningAssetSaleBidCautionInputs.jsx';

import { AfterWinningAssetSaleBidCautionForm } from '../AfterWinningAssetSaleBidCautionForm.jsx';

export const validateInputsAfterWinningPublicSaleBidCaution = () => {
  const formInputs = useAfterWinningAssetSaleBidCautionInputs();
  const validate = AfterWinningAssetSaleBidCautionForm();

  // せり形式
  // 1. 権利移転手続き
  // 権利移転手続きについての説明
  function handleAuctionTransRightDescription(e) {
    validate.setters.handleAuctionTransRightDescriptionValid(e);
    formInputs.setters.setAuctionTransRightDescription(e.target.value);
  }

  // 必要な費用
  // ■ 契約締結期限までに
  // タイトル1
  function handleAuctionCostContractTitle1(e) {
    validate.setters.handleAuctionCostContractTitle1Valid(e);
    formInputs.setters.setAuctionCostContractTitle1(e.target.value);
  }

  // 説明1
  function handleAuctionCostContractDescription1(e) {
    validate.setters.handleAuctionCostContractDescription1Valid(e);
    formInputs.setters.setAuctionCostContractDescription1(e.target.value);
  }

  // タイトル2
  function handleAuctionCostContractTitle2(e) {
    validate.setters.handleAuctionCostContractTitle2Valid(e);
    formInputs.setters.setAuctionCostContractTitle2(e.target.value);
  }

  // 説明2
  function handleAuctionCostContractDescription2(e) {
    validate.setters.handleAuctionCostContractDescription2Valid(e);
    formInputs.setters.setAuctionCostContractDescription2(e.target.value);
  }

  // タイトル3
  function handleAuctionCostContractTitle3(e) {
    validate.setters.handleAuctionCostContractTitle3Valid(e);
    formInputs.setters.setAuctionCostContractTitle3(e.target.value);
  }

  // 説明3
  function handleAuctionCostContractDescription3(e) {
    validate.setters.handleAuctionCostContractDescription3Valid(e);
    formInputs.setters.setAuctionCostContractDescription3(e.target.value);
  }

  // ■ 代金納付期限までに
  // タイトル1
  function handleAuctionCostPaymentTitle1(e) {
    validate.setters.handleAuctionCostPaymentTitle1Valid(e);
    formInputs.setters.setAuctionCostPaymentTitle1(e.target.value);
  }

  // 説明1
  function handleAuctionCostPaymentDescription1(e) {
    validate.setters.handleAuctionCostPaymentDescription1Valid(e);
    formInputs.setters.setAuctionCostPaymentDescription1(e.target.value);
  }

  // タイトル2
  function handleAuctionCostPaymentTitle2(e) {
    validate.setters.handleAuctionCostPaymentTitle2Valid(e);
    formInputs.setters.setAuctionCostPaymentTitle2(e.target.value);
  }

  // 説明2
  function handleAuctionCostPaymentDescription2(e) {
    validate.setters.handleAuctionCostPaymentDescription2Valid(e);
    formInputs.setters.setAuctionCostPaymentDescription2(e.target.value);
  }

  // タイトル3
  function handleAuctionCostPaymentTitle3(e) {
    validate.setters.handleAuctionCostPaymentTitle3Valid(e);
    formInputs.setters.setAuctionCostPaymentTitle3(e.target.value);
  }

  // 説明3
  function handleAuctionCostPaymentDescription3(e) {
    validate.setters.handleAuctionCostPaymentDescription3Valid(e);
    formInputs.setters.setAuctionCostPaymentDescription3(e.target.value);
  }

  // タイトル4
  function handleAuctionCostPaymentTitle4(e) {
    validate.setters.handleAuctionCostPaymentTitle4Valid(e);
    formInputs.setters.setAuctionCostPaymentTitle4(e.target.value);
  }

  // 説明4
  function handleAuctionCostPaymentDescription4(e) {
    validate.setters.handleAuctionCostPaymentDescription4Valid(e);
    formInputs.setters.setAuctionCostPaymentDescription4(e.target.value);
  }

  // タイトル5
  function handleAuctionCostPaymentTitle5(e) {
    validate.setters.handleAuctionCostPaymentTitle5Valid(e);
    formInputs.setters.setAuctionCostPaymentTitle5(e.target.value);
  }

  // 説明5
  function handleAuctionCostPaymentDescription5(e) {
    validate.setters.handleAuctionCostPaymentDescription5Valid(e);
    formInputs.setters.setAuctionCostPaymentDescription5(e.target.value);
  }

  // ご注意
  function handleAuctionCostAttention(e) {
    validate.setters.handleAuctionCostAttentionValid(e);
    formInputs.setters.setAuctionCostAttention(e.target.value);
  }

  // 必要な書類
  // 必要な書類の案内ページ
  // ページの表示
  function handleAuctionDocumentPageDisplay(e) {
    formInputs.setters.setAuctionDocumentPageDisplay(e.target.value);
  }

  // 案内ページ名
  function handleAuctionDocumentPageName(e, required) {
    validate.setters.handleAuctionDocumentPageNameValid(e, required);
    formInputs.setters.setAuctionDocumentPageName(e.target.value);
  }

  // URL
  function handleAuctionDocumentPageUrl(e, required) {
    validate.setters.handleAuctionDocumentPageUrlValid(e, required);
    formInputs.setters.setAuctionDocumentPageUrl(e.target.value);
  }

  // 説明
  function handleAuctionDocumentDescription(e) {
    validate.setters.handleAuctionDocumentDescriptionValid(e);
    formInputs.setters.setAuctionDocumentDescription(e.target.value);
  }

  // 落札者（落札者が法人の場合は代表者）以外の方が権利移転手続きを行う場合
  // 説明
  function handleAuctionOtherTransRightDescription(e) {
    validate.setters.handleAuctionOtherTransRightDescriptionValid(e);
    formInputs.setters.setAuctionOtherTransRightDescription(e.target.value);
  }

  // ご注意
  function handleAuctionOtherTransRightAttention(e) {
    validate.setters.handleAuctionOtherTransRightAttentionValid(e);
    formInputs.setters.setAuctionOtherTransRightAttention(e.target.value);
  }

  // 権利移転の時期と要する期間
  // 権利移転の時期
  function handleAuctionTransRightDate(e) {
    validate.setters.handleAuctionTransRightDateValid(e);
    formInputs.setters.setAuctionTransRightDate(e.target.value);
  }

  // 登記手続き完了までの期間
  function handleAuctionTransRightCompletedTime(e) {
    validate.setters.handleAuctionTransRightCompletedTimeValid(e);
    formInputs.setters.setAuctionTransRightCompletedTime(e.target.value);
  }

  // 2. 重要事項
  // 危険負担
  function handleAuctionImportantRiskBearing(e) {
    validate.setters.handleAuctionImportantRiskBearingValid(e);
    formInputs.setters.setAuctionImportantRiskBearing(e.target.value);
  }

  // 引き渡し条件
  function handleAuctionImportantTermsDelivery(e) {
    validate.setters.handleAuctionImportantTermsDeliveryValid(e);
    formInputs.setters.setAuctionImportantTermsDelivery(e.target.value);
  }

  // 入札保証金の取り扱い
  function handleAuctionImportantHandlingBidDeposit(e) {
    validate.setters.handleAuctionImportantHandlingBidDepositValid(e);
    formInputs.setters.setAuctionImportantHandlingBidDeposit(e.target.value);
  }

  // 契約保証金の取り扱い
  function handleAuctionImportantHandlingContractDeposit(e) {
    validate.setters.handleAuctionImportantHandlingContractDepositValid(e);
    formInputs.setters.setAuctionImportantHandlingContractDeposit(
      e.target.value,
    );
  }

  // 使用用途の制限
  function handleAuctionImportantRestrictionUse(e) {
    validate.setters.handleAuctionImportantRestrictionUseValid(e);
    formInputs.setters.setAuctionImportantRestrictionUse(e.target.value);
  }

  // 移転登記前の使用制限
  function handleAuctionImportantRestrictionUseBefore(e) {
    validate.setters.handleAuctionImportantRestrictionUseBeforeValid(e);
    formInputs.setters.setAuctionImportantRestrictionUseBefore(e.target.value);
  }

  // タイトル1
  function handleAuctionImportantOther1(e) {
    validate.setters.handleAuctionImportantOther1Valid(e);
    formInputs.setters.setAuctionImportantOther1(e.target.value);
  }

  // 説明1
  function handleAuctionImportantDescription1(e) {
    validate.setters.handleAuctionImportantDescription1Valid(e);
    formInputs.setters.setAuctionImportantDescription1(e.target.value);
  }

  // タイトル2
  function handleAuctionImportantOther2(e) {
    validate.setters.handleAuctionImportantOther2Valid(e);
    formInputs.setters.setAuctionImportantOther2(e.target.value);
  }

  // 説明2
  function handleAuctionImportantDescription2(e) {
    validate.setters.handleAuctionImportantDescription2Valid(e);
    formInputs.setters.setAuctionImportantDescription2(e.target.value);
  }

  // タイトル3
  function handleAuctionImportantOther3(e) {
    validate.setters.handleAuctionImportantOther3Valid(e);
    formInputs.setters.setAuctionImportantOther3(e.target.value);
  }

  // 説明3
  function handleAuctionImportantDescription3(e) {
    validate.setters.handleAuctionImportantDescription3Valid(e);
    formInputs.setters.setAuctionImportantDescription3(e.target.value);
  }

  // ご注意
  function handleAuctionImportantAttention(e) {
    validate.setters.handleAuctionImportantAttentionValid(e);
    formInputs.setters.setAuctionImportantAttention(e.target.value);
  }

  // 3. お問い合わせ先
  // お問い合わせ先の表示
  function handleAuctionInquiryDisplay(e) {
    formInputs.setters.setAuctionInquiryDisplay(e.target.value);
  }

  // お問い合わせ先
  function handleAuctionInquiryName(e, required) {
    validate.setters.handleAuctionInquiryNameValid(e, required);
    formInputs.setters.setAuctionInquiryName(e.target.value);
  }

  // メールアドレス
  function handleAuctionInquiryEmail(e) {
    validate.setters.handleAuctionInquiryEmailValid(e);
    formInputs.setters.setAuctionInquiryEmail(e.target.value);
  }

  // 電話番号
  function handleAuctionInquiryTel(e, telData) {
    const val = {
      telNum1: e.telNum1,
      telNum2: e.telNum2,
      telNum3: e.telNum3,
    };
    validate.setters.handleAuctionInquiryTelValid(e, { ...telData, ...val });
    formInputs.setters.setAuctionInquiryTel({ ...telData, ...val });
  }
  function handleAuctionInquiryTelNum1(tel) {
    validate.setters.handleAuctionInquiryTelNum1Valid(tel);
    formInputs.setters.setAuctionInquiryTelNum1(tel);
  }

  function handleAuctionInquiryTelNum2(tel) {
    validate.setters.handleAuctionInquiryTelNum2Valid(tel);
    formInputs.setters.setAuctionInquiryTelNum2(tel);
  }

  function handleAuctionInquiryTelNum3(tel) {
    validate.setters.handleAuctionInquiryTelNum3Valid(tel);
    formInputs.setters.setAuctionInquiryTelNum3(tel);
  }

  // 内線
  function handleAuctionInquiryExtension(e) {
    validate.setters.handleAuctionInquiryExtensionValid(e);
    formInputs.setters.setAuctionInquiryExtension(e.target.value);
  }

  // 電話受付時間
  function handleAuctionInquiryAccept(e) {
    validate.setters.handleAuctionInquiryAcceptValid(e);
    formInputs.setters.setAuctionInquiryAccept(e.target.value);
  }

  const validateInputs = {
    data: formInputs.data,
    setters: {
      handleAuctionTransRightDescription,
      handleAuctionCostContractTitle1,
      handleAuctionCostContractDescription1,
      handleAuctionCostContractTitle2,
      handleAuctionCostContractDescription2,
      handleAuctionCostContractTitle3,
      handleAuctionCostContractDescription3,
      handleAuctionCostPaymentTitle1,
      handleAuctionCostPaymentDescription1,
      handleAuctionCostPaymentTitle2,
      handleAuctionCostPaymentDescription2,
      handleAuctionCostPaymentTitle3,
      handleAuctionCostPaymentDescription3,
      handleAuctionCostPaymentTitle4,
      handleAuctionCostPaymentDescription4,
      handleAuctionCostPaymentTitle5,
      handleAuctionCostPaymentDescription5,
      handleAuctionCostAttention,
      handleAuctionDocumentPageDisplay,
      handleAuctionDocumentPageName,
      handleAuctionDocumentPageUrl,
      handleAuctionDocumentDescription,
      handleAuctionOtherTransRightDescription,
      handleAuctionOtherTransRightAttention,
      handleAuctionTransRightDate,
      handleAuctionTransRightCompletedTime,
      handleAuctionImportantRiskBearing,
      handleAuctionImportantTermsDelivery,
      handleAuctionImportantHandlingBidDeposit,
      handleAuctionImportantHandlingContractDeposit,
      handleAuctionImportantRestrictionUse,
      handleAuctionImportantRestrictionUseBefore,
      handleAuctionImportantOther1,
      handleAuctionImportantDescription1,
      handleAuctionImportantOther2,
      handleAuctionImportantDescription2,
      handleAuctionImportantOther3,
      handleAuctionImportantDescription3,
      handleAuctionImportantAttention,
      handleAuctionInquiryDisplay,
      handleAuctionInquiryName,
      handleAuctionInquiryEmail,
      handleAuctionInquiryTel,
      handleAuctionInquiryTelNum1,
      handleAuctionInquiryTelNum2,
      handleAuctionInquiryTelNum3,
      handleAuctionInquiryExtension,
      handleAuctionInquiryAccept,
    },
    init: formInputs.setters,
    validationMessage: validate.validationMessage,
  };

  return validateInputs;
};
