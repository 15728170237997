// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.64);
}

.modal {
  position: fixed;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  background-color: var(--white);
  border-radius: 5px;
}

.modal-content {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.close-button-wrap {
  width: 100%;
  padding: 8px;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: auto;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,QAAQ;EACR,SAAS;EACT,4CAA4C;EAC5C,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,oBAAoB;EACpB,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".modal-overlay {\n  position: fixed;\n  z-index: 100;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(33, 33, 33, 0.64);\n}\n\n.modal {\n  position: fixed;\n  z-index: 101;\n  top: 50%;\n  left: 50%;\n  transform: translateY(-50%) translateX(-50%);\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  pointer-events: none;\n  background-color: var(--white);\n  border-radius: 5px;\n}\n\n.modal-content {\n  overflow-y: auto;\n  width: 100%;\n  height: 100%;\n  pointer-events: auto;\n}\n\n.close-button-wrap {\n  width: 100%;\n  padding: 8px;\n  height: 60px;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  pointer-events: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
