//import { isDefinedDateTime, isAfterSet } from "../../../../application/validations.js";
import { isDefinedDateTime } from "../../../../application/validations.js";

export class NextPaymentLimit {
  #nextPaymentLimit

  constructor(nextPaymentLimit) {
    this.#nextPaymentLimit = nextPaymentLimit;
  }

  get() {
    return this.#nextPaymentLimit
  }

  get isValid() {
    return [
      this.isDefinedDateTime,
      this.isAfterSet,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get isDefinedDateTime() {
    return isDefinedDateTime(this.#nextPaymentLimit)
  }

  // バリデーションルールの適用
  get isAfterSet() {
    return 'TRUE'
    // TOOD: 比較対象が引き渡されていないため一時コメントアウト
    // return isAfterSet(this.#nextPaymentLimit, confirmationDateTime)
  }
}
