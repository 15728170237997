import SelectBox from 'interfaces/components/common/Form/kmanager/SelectBox.jsx';
import { ValidationMessage } from 'interfaces/components/common/ValidationMessage';
import React from 'react';

import FormLabel from './FormLabel.jsx';

const InputPullDown = React.memo(function InputPullDown({
  invalidMessage,
  required,
  label,
  itemList,
  state,
  setState,
}) {
  return (
    <div className="kmanager-row">
      <div className="kmanager-col-4">
        <FormLabel label={label} required={required} />
      </div>
      <div className="kmanager-col form-area flex-column">
        <div data-testid="input-pulldown" style={{ position: 'relative' }}>
          <SelectBox
            listItemData={itemList}
            state={state}
            setState={setState}
          />
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
      </div>
    </div>
  );
});
export default InputPullDown;
