import Footer from 'interfaces/components/common/Footer/Footer';
import Header from 'interfaces/components/common/Header/Header';
import Application from 'interfaces/pages/Application/services/Application';
import ConfirmationBids from 'interfaces/pages/ConfirmationBids/services/ConfirmationBids';
import Dashboard from 'interfaces/pages/Dashboard/services/Dashboard';
import EditUserInfo from 'interfaces/pages/EditUserInfo/services/EditUserInfo';
import Government from 'interfaces/pages/Government/services/Government';
import Governments from 'interfaces/pages/Governments/services/Governments';
import Mylist from 'interfaces/pages/Mylist/services/Mylist';
import MyPage from 'interfaces/pages/MyPage/services/MyPage';
import News from 'interfaces/pages/News/services/News';
import Properties from 'interfaces/pages/Properties/services/Properties';
import PropertyDetail from 'interfaces/pages/PropertyDetail/services/PropertyDetail';
import ReceptionCompleted from 'interfaces/pages/ReceptionCompleted/services/ReceptionCompleted';
import ReceptionCompletedBids from 'interfaces/pages/ReceptionCompletedBids/services/ReceptionCompletedBids';
import Shipping from 'interfaces/pages/Shipping/services/Shipping';
import YearlySchedules from 'interfaces/pages/YearlySchedules/services/YearlySchedules';
import OneTimeToken from 'interfaces/pages/TestPayment/TestPayment';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

export const serviceRoutes = (type) => [
  { path: '/', element: <Dashboard type={type} /> },
  { path: '/schedules', element: <YearlySchedules type={type} /> },
  { path: '/gov', element: <Governments type={type} /> },
  { path: '/gov/:government_id', element: <Government type={type} /> },
  { path: '/search', element: <Properties type={type} /> },
  { path: '/items/:id', element: <PropertyDetail type={type} /> },
  { path: '/items/:id/application', element: <Application type={type} /> },
  { path: '/items/:id/shipping', element: <Shipping type={type} /> },
  { path: '/mypage', element: <MyPage type={type} /> },
  {
    path: '/mypage/edit/user',
    element: <EditUserInfo type={type} target="user" />,
  },
  {
    path: '/mypage/edit/delegator',
    element: <EditUserInfo type={type} target="delegator" />,
  },
  { path: '/mylist', element: <Mylist type={type} /> },
  { path: '/news', element: <News type={type} /> },
  { path: '/reception-completed', element: <ReceptionCompleted type={type} /> }, // 参加申込完了
  { path: '/confirmation-bids', element: <ConfirmationBids type={type} /> }, // 入札確認
  {
    path: '/reception-completed-bids',
    element: <ReceptionCompletedBids type={type} />,
  }, // 入札完了
  {
    path: '/test-one-time',
    element: <OneTimeToken type={type} />,
  }, // 入札完了
];

const ServiceBackGround = styled.div`
  background-color: #f7f6f6;
`;

const AppRouter = ({ type }) => {
  return (
    <>
      <div className="kservice">
        <Header type={type} />
        <ServiceBackGround>
          <Routes>
            {serviceRoutes(type).map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </ServiceBackGround>
        <Footer type={type} />
      </div>
    </>
  );
};

export default AppRouter;
