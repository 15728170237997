//import { useAuth } from '../../hooks/useAuth.js';
import RegistrationInfoPageFooter from 'interfaces/components/common/RegistrationInfo/RegistrationInfoPageFooter.jsx';
import RegistrationInfoPageHeading from 'interfaces/components/common/RegistrationInfo/RegistrationInfoPageHeading.jsx';
import ShippingStepComponent from 'interfaces/components/Shipping/ShippingStepComponent.jsx';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useLocalStorage } from 'shared/hooks/useLocalStorage.js';
import useStep from 'shared/hooks/useStep.js';
import { steps } from 'shared/utils/constants/shipping_information_registration_steps.js';

const Shipping = () => {
  const { id } = useParams();
  const location = useLocation();
  const locationState = location?.state;
  const { step, previousStep, nextStep } = useStep(locationState?.step ?? 1);
  const { localStorageUserId } = useLocalStorage();

  const isLoggedIn = () => {
    return localStorageUserId !== null;
  };

  if (!isLoggedIn() || !locationState) {
    return <Navigate replace to={`/items/${id}`} />;
  }

  console.log(locationState);

  return (
    <div className="inner">
      <RegistrationInfoPageHeading
        locationState={locationState}
        requestSteps={steps}
        step={step}
      />
      <ShippingStepComponent
        step={step}
        previousStep={previousStep}
        nextStep={nextStep}
        data={locationState}
      />
      <RegistrationInfoPageFooter data={locationState.data} />
    </div>
  );
};

export default Shipping;

//   id: {
//     path: 'id',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.id
//     }
//   },
//   auctionId: {
//     path: 'auction-id',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.auctionId
//     }
//   },
//   governmentId: {
//     path: 'government-id',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.governmentId
//     }
//   },
//   categoryId: {
//     path: 'category-id',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.categoryId
//     }
//   },
//   divisionId: {
//     path: 'division-id',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.divisionId
//     }
//   },
//   governmentSubCategoryId: {
//     path: 'government-sub-category-id',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.governmentSubCategoryId
//     }
//   },
//   printOrder: {
//     path: 'print-order',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.printOrder
//     }
//   },
//   propertyType: {
//     path: 'property-type',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.propertyType
//     }
//   },
//   saleNumber: {
//     path: 'sale-number',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.saleNumber
//     }
//   },
//   name: {
//     path: 'name',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.name
//     }
//   },
//   publicSaleType: {
//     path: 'public-sale-type',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.publicSaleType
//     }
//   },
//   status: {
//     path: 'status',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.status
//     }
//   },
//   deposit: {
//     path: 'deposit',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.deposit
//     }
//   },
//   depositPayment: {
//     path: 'deposit-payment',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.depositPayment
//     }
//   },
//   depositProxy: {
//     path: 'deposit-proxy',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.depositProxy
//     }
//   },
//   depositRefundScheduled: {
//     path: 'deposit-refund-scheduled',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.depositRefundScheduled
//     }
//   },
//   depositRefundClaim: {
//     path: 'deposit-refund-claim',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.depositRefundClaim
//     }
//   },
//   bidPrice: {
//     path: 'bid-price',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.bidPrice
//     }
//   },
//   estimatePrice: {
//     path: 'estimate-price',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.estimatePrice
//     }
//   },
//   paymentScheduledPrice: {
//     path: 'payment-scheduled-price',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.paymentScheduledPrice
//     }
//   },
//   restitutionScheduledPrice: {
//     path: 'restitution-scheduled-price',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.restitutionScheduledPrice
//     }
//   },
//   auctionUserId: {
//     path: 'auction-user-id',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.auctionUserId
//     }
//   },
//   nextAuctionUserId: {
//     path: 'next-auction-user-id',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.nextAuctionUserId
//     }
//   },
//   bidEndPrice: {
//     path: 'bid-end-price',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.bidEndPrice
//     }
//   },
//   nextPrice: {
//     path: 'next-price',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.nextPrice
//     }
//   },
//   isPaid: {
//     path: 'is-paid',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.isPaid
//     }
//   },
//   paymentLimit: {
//     path: 'payment-limit',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.paymentLimit
//     }
//   },
//   paymentDate: {
//     path: 'payment-date',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.paymentDate
//     }
//   },
//   paymentScheduledDate: {
//     path: 'payment-scheduled-date',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.paymentScheduledDate
//     }
//   },
//   contractedLimit: {
//     path: 'contracted-limit',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.contractedLimit
//     }
//   },
//   cancelDate: {
//     path: 'cancel-date',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.cancelDate
//     }
//   },
//   isDraft: {
//     path: 'is-draft',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.isDraft
//     }
//   },
//   isCopied: {
//     path: 'is-copied',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.isCopied
//     }
//   },
//   isLock: {
//     path: 'is-lock',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.isLock
//     }
//   },
//   isNextBuyApl: {
//     path: 'is-next-buy-apl',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.isNextBuyApl
//     }
//   },
//   videoUrl: {
//     path: 'video-url',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.videoUrl
//     }
//   },
//   description: {
//     path: 'description',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.description
//     }
//   },
//   inquiryName: {
//     path: 'inquiry-name',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.inquiryName
//     }
//   },
//   inquiryOffice: {
//     path: 'inquiry-office',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.inquiryOffice
//     }
//   },
//   inquiryEmail: {
//     path: 'inquiry-email',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.inquiryEmail
//     }
//   },
//   inquiryTel: {
//     path: 'inquiry-tel',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.inquiryTel
//     }
//   },
//   inquiryExtension: {
//     path: 'inquiry-extension',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.inquiryExtension
//     }
//   },
//   inquiryAccept: {
//     path: 'inquiry-accept',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.inquiryAccept
//     }
//   },
//   inquiryNote: {
//     path: 'inquiry-note',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.inquiryNote
//     }
//   },
//   contactName: {
//     path: 'contact-name',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.contactName
//     }
//   },
//   contactOffice: {
//     path: 'contact-office',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.contactOffice
//     }
//   },
//   contactEmail: {
//     path: 'contact-email',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.contactEmail
//     }
//   },
//   contactTel: {
//     path: 'contact-tel',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.contactTel
//     }
//   },
//   contactAccept: {
//     path: 'contact-accept',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.contactAccept
//     }
//   },
//   contactExtension: {
//     path: 'contact-extension',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.contactExtension
//     }
//   },
//   isDeliveryRequestDisplayed: {
//     path: 'is-delivery-request-displayed',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.isDeliveryRequestDisplayed
//     }
//   },
//   deliveryType: {
//     path: 'delivery-type',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.deliveryType
//     }
//   },
//   carrierNameList: {
//     path: 'carrier-name-list',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.carrierNameList
//     }
//   },
//   carrierName: {
//     path: 'carrier-name',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.carrierName
//     }
//   },
//   carrierTel: {
//     path: 'carrier-tel',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.carrierTel
//     }
//   },
//   arrangement: {
//     path: 'arrangement',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.arrangement
//     }
//   },
//   packing: {
//     path: 'packing',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.packing
//     }
//   },
//   receiver: {
//     path: 'receiver',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.receiver
//     }
//   },
//   receiverName: {
//     path: 'receiver-name',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.receiverName
//     }
//   },
//   deliveryDate: {
//     path: 'delivery-date',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.deliveryDate
//     }
//   },
//   deliveryTime: {
//     path: 'delivery-time',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.deliveryTime
//     }
//   },
//   deliveryText: {
//     path: 'delivery-text',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.deliveryText
//     }
//   },
//   registrationDate: {
//     path: 'registration-date',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.registrationDate
//     }
//   },
//   isApplyReduction: {
//     path: 'is-apply-reduction',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.isApplyReduction
//     }
//   },
//   isNotClaimable: {
//     path: 'is-not-claimable',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.isNotClaimable
//     }
//   },
//   isApprovalRequired: {
//     path: 'is-approval-required',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.isApprovalRequired
//     }
//   },
//   reductionApproval: {
//     path: 'reduction-approval',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.reductionApproval
//     }
//   },
//   reductionApplicant: {
//     path: 'reduction-applicant',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.reductionApplicant
//     }
//   },
//   reductionReason: {
//     path: 'reduction-reason',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.reductionReason
//     }
//   },
//   reductionReasonOther: {
//     path: 'reduction-reason-other',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.reductionReasonOther
//     }
//   },
//   isExtensionSurveyTerm: {
//     path: 'is-extension-survey-term',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.isExtensionSurveyTerm
//     }
//   },
//   resolutionDate: {
//     path: 'resolution-date',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.resolutionDate
//     }
//   },
//   fixResolutionDate: {
//     path: 'fix-resolution-date',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.fixResolutionDate
//     }
//   },
//   bidSystemFee: {
//     path: 'bid-system-fee',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.bidSystemFee
//     }
//   },
//   bidSystemFeeTax: {
//     path: 'bid-system-fee-tax',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.bidSystemFeeTax
//     }
//   },
//   corpName: {
//     path: 'corp-name',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.corpName
//     }
//   },
//   corpNameKana: {
//     path: 'corp-name-kana',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.corpNameKana
//     }
//   },
//   familyName: {
//     path: 'family-name',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.familyName
//     }
//   },
//   familyNameKana: {
//     path: 'family-name-kana',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.familyNameKana
//     }
//   },
//   firstName: {
//     path: 'first-name',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.firstName
//     }
//   },
//   firstNameKana: {
//     path: 'first-name-kana',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.firstNameKana
//     }
//   },
//   sex: {
//     path: 'sex',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.sex
//     }
//   },
//   birthDate: {
//     path: 'birth-date',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.birthDate
//     }
//   },
//   postalCode: {
//     path: 'postal-code',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.postalCode
//     }
//   },
//   locationGovernmentSubCategoryId: {
//     path: 'location-government-sub-category-id',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.locationGovernmentSubCategoryId
//     }
//   },
//   address: {
//     path: 'address',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.address
//     }
//   },
//   houseTel: {
//     path: 'house-tel',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.houseTel
//     }
//   },
//   mobileTel: {
//     path: 'mobile-tel',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.mobileTel
//     }
//   },
//   tmpAplDate: {
//     path: 'tmp-apl-date',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.tmpAplDate
//     }
//   },
//   receiverPostalCode: {
//     path: 'receiver-postal-code',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.receiverPostalCode
//     }
//   },
//   receiverGovernmentSubCategoryId: {
//     path: 'receiver-government-sub-category-id',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.receiverGovernmentSubCategoryId
//     }
//   },
//   receiverAddress: {
//     path: 'receiver-address',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.receiverAddress
//     }
//   },
//   receiverTel: {
//     path: 'receiver-tel',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.receiverTel
//     }
//   },
//   deliveryReason: {
//     path: 'delivery-reason',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.deliveryReason
//     }
//   },
//   propertyId: {
//     path: 'property-id',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.propertyId
//     }
//   },
//   auctionUserId: {
//     path: 'auction-user-id',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.auctionUserId
//     }
//   },
//   type: {
//     path: 'type',
//     headers: headers,
//     body: {
//       id: inputs.data.id,
//       value: inputs.data.type
//     }
//   },
