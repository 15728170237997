import Button from 'interfaces/components/common/Button/Button';

const EmailTemplateConfirm = ({
  text,
  closeModal,
  navigateToEdit,
  submitHandler,
}) => {
  const closeModalHandler = () => {
    closeModal();
    navigateToEdit();
  };
  return (
    <div
      className="email-template-confirm"
      data-testid="email-template-confirm">
      <h4>
        <span style={{ color: '#ff7f00' }}>■</span>追加説明通知メール内容
      </h4>
      <form
        id="email-template-confirm-form"
        className="email-template-text-wrap"
        onSubmit={submitHandler}>
        <p className="email-template-text" data-testid="email-template-text">
          {text === '' ? '未入力' : text}
        </p>
      </form>
      <div className="test-submit-button-wrapper">
        <Button
          text="メールのテスト送信"
          className="property-info-general-form-button new-style_navi_normal-button mt-4"
        />
        <p className="test-submit-caution">
          ※
          「ナビユーザー管理」で登録しているメールアドレス宛に送信します。メールアドレスの登録を行っていない場合は送信されません。
        </p>
      </div>

      <div className="email-template-button-wrapper">
        <Button
          text="キャンセル"
          className="property-info-general-form-button new-style_navi_normal-button"
          onClick={closeModalHandler}
        />
        <Button
          text="再編集"
          className="property-info-general-form-button new-style_navi_normal-button ml-2"
          onClick={navigateToEdit}
        />
        <Button
          form="email-template-confirm-form"
          type="submit"
          text="登録"
          className="ml-2 property-info-general-form-button new-style_navi_primary-button"
        />
      </div>
    </div>
  );
};
export default EmailTemplateConfirm;
