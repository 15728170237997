
export class AccessPersonalInfo {
  #accessPersonalInfo

  constructor(accessPersonalInfo) {
    this.#accessPersonalInfo = accessPersonalInfo;
  }

  get() {
    return this.#accessPersonalInfo
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
