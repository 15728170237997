//import { bodyToSnake, createBody } from "../../api/convertData";
//import { putApiData } from "../../api/request";
import Button from 'interfaces/components/common/Button/Button';
//import { dateFormat } from "../../../service/helper/date_format";
import ButtonList from 'interfaces/components/common/ButtonList';
import InputType from 'interfaces/components/common/Form/InputType';
import Modal2 from 'interfaces/components/common/Modal/Modal2';

import { validateInputsFixResolutionDateForm } from './validateInputsFixResolutionDateForm';

//const FixResolutionDateFormModal = ({ propertyId, properties, setProperties, isOpen, handleClose, setId }) => {
const FixResolutionDateFormModal = ({ isOpen, handleClose }) => {
  const createInputs = validateInputsFixResolutionDateForm();
  //  const formDataAdd = (inputBody) => {
  //    let snakeBody = bodyToSnake(createBody(inputBody))
  //    return snakeBody
  //  }

  const Put = async () => {
    console.log('todo: set resolution date');
    //    await putApiData(`properties/${propertyId}/register-fix-resolution-date`, formDataAdd(createInputs))
    //      .then(() => {
    //        handleClose();
    //        properties.map((item) => {
    //          if (item.id !== propertyId) {
    //            return;
    //          }
    //          item.fixResolutionDate = <Button
    //            text={dateFormat(createInputs[0].state, 'Y-M-D')}
    //            isOutline={true}
    //            onClick={() => setId(item.id)}
    //          />;
    //        });
    //        setProperties(properties);
    //      })
    //      .catch((err) => {
    //        // TODO: エラーハンドリング
    //        console.log(err)
    //      })
  };

  const FORM_BUTTONS_DATA = [
    {
      text: '登録する',
      clickHandler: Put,
      className: 'primary',
    },
  ];

  return (
    <Modal2
      isOpen={isOpen}
      handleClose={handleClose}
      modalSize="S"
      padding={false}
      closeClickOutside={true}>
      <div className="memo-modal-header-label mb-4">議決日登録</div>
      {createInputs &&
        createInputs.map((input, index) => {
          return InputType(input, index);
        })}
      <ButtonList>
        <div className="flex justify-flex-end mt-2 mb-2 mr-4">
          {FORM_BUTTONS_DATA &&
            FORM_BUTTONS_DATA.map((item, index) => (
              <>
                <Button
                  key={index}
                  text={item.text}
                  onClick={item.clickHandler}
                  isOutline={item.isOutline}
                  isDisabled={false}
                  className={item.className}
                />
              </>
            ))}
        </div>
      </ButtonList>
    </Modal2>
  );
};
export default FixResolutionDateFormModal;
