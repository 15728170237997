import { validateSelected } from "../../../../application/validations.js";

export class Purpose {
  #purpose

  constructor(purpose) {
    this.#purpose = purpose;
  }

  get() {
    return this.#purpose
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#purpose)
  }
}
