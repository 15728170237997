import { PrefectureToJP } from '../../../../../shared/utils/helper/convert/prefectureToJp';
import 'interfaces/css/Dashboard/kmanager-auction-table-record.css';
import { dateFormat } from 'shared/utils/helper/date_format';

const AuctionDepositTableRecord = ({
  deposit,
  auction,
  index,
  checked,
  checkedList,
}) => {
  return (
    <tr
      key={index}
      className="kmanager-auction-table-record"
      data-testid="kmanager-auction-table-record">
      <td
        className="kmanager-auction-table-control kmanager-auction-table-control-checkbox"
        data-testid="kmanager-auction-table-td">
        <div className="d-flex align-center justify-center kmanager-table-th-check-box-icon">
          <input
            role="checkbox"
            type="checkbox"
            value={deposit.governmentId}
            onChange={() => checkedList(deposit.governmentId)}
            checked={checked.includes(deposit.governmentId)}
          />
        </div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{deposit?.region}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{PrefectureToJP(deposit?.location)}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{deposit?.governmentName}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{deposit?.divisionName}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{deposit?.depositProxy.toLocaleString()}円</div>
      </td>
      {auction.procedureType === 'PUBLIC_SALE' && (
        <>
          <td data-testid="kmanager-auction-table-td">
            <div>{dateFormat(deposit?.estimatedInvoiceDate, 'YYYY-MM-DD')}</div>
          </td>
          <td data-testid="kmanager-auction-table-td">
            <div>{dateFormat(deposit?.estimatedReturnDate, 'YYYY-MM-DD')}</div>
          </td>
          <td data-testid="kmanager-auction-table-td">
            <div>
              <span>{dateFormat(deposit?.returnDate, 'YYYY-MM-DD')}</span>
              <span style={{ margin: '0 10px' }}></span>
              <span>{deposit?.depositRefundClaim.toLocaleString()}円</span>
            </div>
          </td>
        </>
      )}
    </tr>
  );
};

export default AuctionDepositTableRecord;
