// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-counter {
  text-align: right;
  font-size: 12px;
  line-height: 1.833;
}

.text-counter.over-count {
  color: #ff5252;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/text-counter.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".text-counter {\n  text-align: right;\n  font-size: 12px;\n  line-height: 1.833;\n}\n\n.text-counter.over-count {\n  color: #ff5252;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
