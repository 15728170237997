import URL_LINKS from 'interfaces/pages/Dashboard/entities/externalLinks';

import { Message, MessageLink } from './WaitOpenBid';

export const TmpApply = ({ data, setIsNoMessage }) => {
  if (
    data.status === 'APPLYING' ||
    data.status === 'WAIT_START_BID' ||
    data.status === 'DURING_BIDDING'
  ) {
    return (
      <p className="ma-0" data-testid="announce-message">
        この物件へは現在<a href={URL_LINKS.ABOUT_TRIAL_APPL_URL}>仮申し込み</a>
        中です。
        <br />
        必要な手続きが完了している場合は、実施行政機関からの承認によって申し込み完了となります。
        <br />
      </p>
    );
  }

  if (data.status === 'WAIT_OPEN_BID') {
    return (
      <Message
        content={
          '現在執行機関による入札結果確認中です。落札者は入札結果の確認完了後に確定されます。しばらくお待ちください。'
        }
      />
    );
  }

  if (data.status === 'END_BIDDING') {
    if (data.bidCount === 0)
      return <Message content={'この物件には入札がありませんでした。'} />;
  }

  if (data.status === 'ADDITIONAL_DURING_BIDDING') {
    return (
      <MessageLink
        prevText={'この物件は現在'}
        href={URL_LINKS.ABOUT_ADD_BID_URL}
        content={'追加入札'}
        laterText={'中です。'}
      />
    );
  }

  setIsNoMessage(true);
};
