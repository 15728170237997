export const NaviUserConfirmList = ({ data }) => [
  {
    text: '担当者名（姓）',
    state: data?.familyName == "DATA_EMPTY" ? "未入力" : data?.familyName || '未入力',
  },
  {
    text: '担当者名（名）',
    state: data?.firstName == "DATA_EMPTY" ? "未入力" : data?.firstName || '未入力',
  },
  {
    text: '担当者名（姓カナ）',
    state: data?.familyNameKana == "DATA_EMPTY" ? "未入力" : data?.familyNameKana || '未入力',
  },
  {
    text: '担当者名（名カナ）',
    state: data?.firstNameKana == "DATA_EMPTY" ? "未入力" : data?.firstNameKana || '未入力',
  },
  {
    text: '部署名',
    state: data?.department == "DATA_EMPTY" ? "未入力" : data?.department || '未入力',
  },
  {
    text: '役職名',
    state: data?.officePosition == "DATA_EMPTY" ? "未入力" : data?.officePosition || '未入力',
  },
  {
    text: '電話番号',
    state:
      data?.tel.telNum1 && data?.tel.telNum2 && data?.tel.telNum3
        ? `${data?.tel.telNum1}-${data?.tel.telNum2}-${data?.tel.telNum3}${data?.extension == "DATA_EMPTY" ? "" : data?.extension ? `  内線: ${data?.extension}` : ''}`
        : '未入力',
  },
  {
    text: '権限',
    state: displayUserType(data?.userType),
  },
  {
    text: 'メールアドレス',
    state: data?.email == "DATA_EMPTY" ? "未入力" : data?.email || '未入力',
  },
  {
    text: '個人情報閲覧権限',
    state: displayAccessPermission(data?.accessPersonalInfo),
  },
  {
    text: '運営からのメールの受信',
    state: displayWantReceiveEmail(data?.wantReceiveEmail),
  },
];

// TODO：ほかで使う場合は共通化してね
const displayUserType = (userType) => {
  switch (userType) {
    case 'PARENT':
      return '親';
    case 'CHILD':
      return '子';
    case 'MANAGER':
      return '管理者';
    default:
      return '未入力';
  }
};

const displayAccessPermission = (accessPermittion) => {
  switch (accessPermittion) {
    case 'AUTHORIZED':
      return 'あり';
    case 'NO_AUTHORIZED':
      return 'なし';
    default:
      return '未入力';
  }
};

const displayWantReceiveEmail = (wantReceiveEmail) => {
  switch (wantReceiveEmail) {
    case 'RECEIVE':
      return '受信する';
    case 'NO_RECEIVE':
      return '受信しない';
    default:
      return '未入力';
  }
};
