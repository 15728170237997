import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class NearestStation {
  #nearestStation

  constructor(nearestStation) {
    this.#nearestStation = nearestStation;
  }

  get() {
    return this.#nearestStation
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#nearestStation, 0, 400)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#nearestStation)
  }
}
