import {
  ASSET_SALE,
  PUBLIC_SALE,
} from 'domain/Property/entities/constants/property';

export const procedureTypeParam = (procedureType) => {
  if (procedureType === PUBLIC_SALE) {
    return 'au';
  }
  if (procedureType === ASSET_SALE) {
    return 'as';
  }
  return '';
};
