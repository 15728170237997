import { validateInclude, validateRangeLength } from "../../../../application/validations.js";

export class GovHpUrl {
    #govHpUrl

    constructor(govHpUrl) {
        this.#govHpUrl = govHpUrl;
    }

    get() {
        return this.#govHpUrl
    }

    get isValid() {
        return [
            this.validateIncludeUrl,
            this.validateRangeLength,
        ].every(result => result === 'TRUE');
    }

    // バリデーションルールの適用
    get validateIncludeUrl() {
        return validateInclude(this.#govHpUrl, ['https://', 'http://'])
    }

    // バリデーションルールの適用
    get validateRangeLength() {
        return validateRangeLength(this.#govHpUrl, 0, 2048)
    }
}
