// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shipping .point-to-note {
  margin: 40px 0;
}

.shipping .point-to-note-main-text dt {
  font-weight: bold;
}

.shipping .point-to-note-main-text dd {
  margin-bottom: 4px;
}

.shipping .point-to-note-main-text ul {
  list-style-type: none;
  padding-left: 0;
}

.shipping .point-to-note-main-text li {
  text-indent: -3em;
  margin-left: 3em;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Shipping/point-to-note.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".shipping .point-to-note {\n  margin: 40px 0;\n}\n\n.shipping .point-to-note-main-text dt {\n  font-weight: bold;\n}\n\n.shipping .point-to-note-main-text dd {\n  margin-bottom: 4px;\n}\n\n.shipping .point-to-note-main-text ul {\n  list-style-type: none;\n  padding-left: 0;\n}\n\n.shipping .point-to-note-main-text li {\n  text-indent: -3em;\n  margin-left: 3em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
