/**
 * 文字列入力をサニタイズする。
 * @param {string} input - サニタイズする文字列。
 * @return {string} サニタイズされた文字列。
 */

// 外部ライブラリのインポート
import { decode } from 'html-entities';

import CustomError from './customError.js';

// スクリプトタグをマッチする正規表現
const SCRIPT_TAG_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;

// 禁止文字列のリスト
const DANGEROUS_STRINGS = ['javascript:', 'vbscript:', 'data:'];

// サニタイズ処理のメイン関数
export function sanitizeInput(input) {
  // 入力の検証
  if (typeof input !== 'string') {
    throw new CustomError('入力は文字列でなければならない');
  }

  // デコード処理
  let sanitized = decodeHtmlEntities(input);

  // スクリプトタグとHTMLタグの除去
  sanitized = removeScriptAndHtmlTags(sanitized);

  // 文字参照エンコーディングの削除
  sanitized = removeHexEncoding(sanitized);

  // 追加チェックとエスケープ
  sanitized = validateAndEscape(sanitized);

  return sanitized;
}

// デコード関数
export function decodeHtmlEntities(input) {
  return decode(input);
}

// サニタイズのサブロジックを関数に分割
function removeScriptAndHtmlTags(input) {
  let sanitized = input.replace(SCRIPT_TAG_REGEX, '');
  // スクリプトタグとHTMLタグの削除ロジック
  sanitized = sanitized.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
  // 混在エンコーディングのスクリプトタグを削除する新しいロジックを追加
  sanitized = sanitized.replace(/&lt;script&gt;.*?&lt;\/script&gt;/gi, '');
  return sanitized;
}

function removeHexEncoding(input) {
  // 16進数エンコーディングの削除
  return input.replace(/&#x[0-9A-Fa-f]+;/gim, '');
}

function validateAndEscape(input) {
  // 入力引数の検証
  if (typeof input !== 'string') {
    throw new CustomError('Invalid input');
  }

  // 入力の禁止文字列の検証
  DANGEROUS_STRINGS.forEach((str) => {
    if (input.includes(str)) {
      throw new CustomError('Forbidden input detected');
    }
  });

  // HTML文字のエスケープ
  let escaped = input
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&quot;');

  // 安全な文字列のみを返却
  return escaped;
}
