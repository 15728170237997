import { useLocation } from 'react-router-dom';

import { ReactComponent as RadioBlank } from '../../../assets/svg/radio-button-blank.svg';
import { ReactComponent as RadioMarked } from '../../../assets/svg/radio-button-marked.svg';

import '../../../css/common/radio-button.css';

const RadioButton = ({ data, isText, handleChange, isEstate = false }) => {
  const path = useLocation().pathname;
  const radioIcon = (text) => {
    if (isText === text) {
      return (
        <i className="radio-button-marked">
          <RadioMarked />
        </i>
      );
    }
    return (
      <i className="radio-button-blank">
        <RadioBlank />
      </i>
    );
  };

  const radioDisabled = (text) => {
    if (isEstate) {
      if (text === 'AUCTION') {
        return (
          <input
            type={'radio'}
            name={'radio'}
            disabled={true}
            value={text}
            onChange={handleChange}
          />
        );
      }
    }
    return (
      <input
        type={'radio'}
        name={'radio'}
        value={text}
        onChange={handleChange}
      />
    );
  };

  return data?.map((data) => {
    return (
      <div className="radio-button" key={data.id}>
        <div className={'mr-2 d-inline-flex'}>
          {radioIcon(data.value ?? data.text)}
          {radioDisabled(data.value ?? data.text)}
        </div>
        <label
          className={
            data?.text === 'せり売り形式' && path?.endsWith('estate')
              ? 'radio-button-estate'
              : ''
          }>
          {data.text}
        </label>
      </div>
    );
  });
};
export default RadioButton;
