// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-list .property-list-page-description .description {
    font-size: 14px;
}

.property-list .property-list-page-description .description p {
    margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertyList/property-list-page-description.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".property-list .property-list-page-description .description {\n    font-size: 14px;\n}\n\n.property-list .property-list-page-description .description p {\n    margin-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
