import PropertyFormInputMarkDown from "interfaces/components/common/PropertyInfoForm/PropertyFormInputMarkDown";

import PropertyInfoFormCautionDescription from "./PropertyInfoFormCautionDescription.jsx";
import PropertyInfoFormLabel from "./PropertyInfoFormLabel.jsx";
import "interfaces/css/common/property-info-general-form-input-email.css"

const PropertyInfoGeneralFormInputMarkDown = ({invalidMessage,caution_description,label,number_remaining_characters,maxCharacters,required, validationType, data = "", handler1= () => {}, handler2= () => {}}) => {
  return (
    <div data-testid="property-info-general-form-input-markdown" className="property-info-general-form-input-text property-info-general-form-table-row">
      <PropertyInfoFormLabel label={label} required={required}/>
      <div className="property-info-general-form-table-cell">
        <PropertyFormInputMarkDown
          number_remaining_characters={number_remaining_characters}
          validationType={validationType}
          data={data}
          maxCharacters={maxCharacters}
          handler1={handler1}
          handler2={handler2}
          invalidMessage={invalidMessage}
        />
        <PropertyInfoFormCautionDescription data={caution_description}/>
      </div>
    </div>
  );
}
export default PropertyInfoGeneralFormInputMarkDown