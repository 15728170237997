import 'interfaces/css/Mylist/mylist-all-select.css';

const MylistAllSelect = () => {
  return (
    <div className="mylist-all-select">
      <div className="mylist-left">
        <div className="mylist-checkbox">
          <i className="mylist-checkbox-icon mdi-checkbox-blank-outline"></i>
          <input
            aria-checked="false"
            id="input-733"
            role="checkbox"
            type="checkbox"
            value=""
            className="mylist-input"
          />
        </div>
        <label className="mylist-select-label">すべて選択</label>
        <p className="mylist-separating-line"> | </p>
        <a className="mylist-delete-link">選択した物件を削除する</a>
      </div>
      <p className="mylist-update-date">最終更新日時:2023年9月15日9時48分</p>
    </div>
  );
};
export default MylistAllSelect;
