import PropertyInfoEstateProvider from './PropertyInfoEstateProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { propertyInfoEstate } from "../entities/componentMapping.js";

const PropertyInfoEstate = ({ type }) => {
  return (
    <PropertyInfoEstateProvider type={type}>
      {getComponent(propertyInfoEstate, type)}
    </PropertyInfoEstateProvider>
  );
};

export default PropertyInfoEstate;
