import GovernmentsProvider from './GovernmentsProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { governments } from "../entities/componentMapping.js";

const Governments = ({ type }) => {
  return (
    <GovernmentsProvider type={type}>
      {getComponent(governments, type)}
    </GovernmentsProvider>
  );
};

export default Governments;
