// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sample-guideline .guideline-modal-body {
  padding-top: 15px;
  padding-bottom: 19px;
  border-top: 1px solid rgba(0,0,0,.12);
  border-bottom: 1px solid rgba(0,0,0,.12);
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/SampleGuideline/guideline-modal-body.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,qCAAqC;EACrC,wCAAwC;AAC1C","sourcesContent":[".sample-guideline .guideline-modal-body {\n  padding-top: 15px;\n  padding-bottom: 19px;\n  border-top: 1px solid rgba(0,0,0,.12);\n  border-bottom: 1px solid rgba(0,0,0,.12);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
