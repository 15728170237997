
export class UseCreditCard {
  #useCreditCard

  constructor(useCreditCard) {
    this.#useCreditCard = useCreditCard;
  }

  get() {
    return this.#useCreditCard
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
