
import Pagination from 'interfaces/components/common/Pagination/Pagination';
import SearchPropertyCount from 'interfaces/components/common/PropertyList/SearchPropertyCount';
import GeneralDetailPropertyCard from 'interfaces/components/Property/service/PropertyCard/GeneralDetailPropertyCard/GeneralDetailPropertyCard';
import { useState } from 'react';
import usePagination from 'shared/hooks/usePagination';

import { useMyListQueryParamChangeProcessing } from './hooks/useMyListQueryParamChangeProcessing';
import MylistAllSelect from './MylistAllSelect';
import MylistSortBox from './MylistSortBox';

const MyListContents = ({
  properties,
  sortBoxType,
  isDeleteFeature = false,
}) => {
  const itemsPerPage = 10;
  const [displayProperties, setDisplayProperties] = useState(properties);
  useMyListQueryParamChangeProcessing(properties, setDisplayProperties);
  const {
    allItemCount,
    currentPage,
    currentItems,
    pageNumbers,
    setCurrentPage,
  } = usePagination(displayProperties, itemsPerPage);

  const MyListContentsBottom = () => {
    if (!currentItems.length) {
      return (
        <p className="text-center mb-4 grey-text">該当する物件がありません。</p>
      );
    }
  };

  return (
    <>
      <MylistSortBox sortBoxType={sortBoxType} />
      {isDeleteFeature && <MylistAllSelect />}
      <div className="mb-6">
        <div className="search-list flex flex-wrap-wrap mr-n2 justify-space-between">
          {currentItems.map((item) => (
            <GeneralDetailPropertyCard key={item.id} data={item.data} />
          ))}
        </div>
        <Pagination
          currentPage={currentPage}
          pageCount={pageNumbers.length}
          setCurrentPage={setCurrentPage}
        />
        <div className="text-center">
          <SearchPropertyCount
            itemCount={allItemCount}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
          />
        </div>
        {MyListContentsBottom()}
      </div>
      {isDeleteFeature && <MylistAllSelect />}
    </>
  );
};

export default MyListContents;
