//import { isDefinedDateTime, isAfterSet, isAfterEnd } from "../../../../application/validations.js";
import { isDefinedDateTime } from "../../../../application/validations.js";

export class BidDecision {
  #bidDecision

  constructor(bidDecision) {
    this.#bidDecision = bidDecision;
  }

  get() {
    return this.#bidDecision
  }

  get isValid() {
    return [
      this.isDefinedDateTime,
      this.isAfterSet,
      this.isAfterEnd,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get isDefinedDateTime() {
    return isDefinedDateTime(this.#bidDecision)
  }

  // バリデーションルールの適用
  get isAfterSet() {
    return 'TRUE'
    // TOOD: 比較対象が引き渡されていないため一時コメントアウト
    // return isAfterSet(this.#bidDecision, endDateTime)
  }

  // バリデーションルールの適用
  get isAfterEnd() {
    return 'TRUE'
    // TOOD: 比較対象が引き渡されていないため一時コメントアウト
    // return isAfterEnd(this.#bidDecision, endDateTime, addingTime)
  }
}
