import PropertyInfoFormLabel from './PropertyInfoFormLabel.jsx';

const ConfirmInputText = ({ label, state = '' }) => {
  console.log(state);
  return (
    <div
      className="property-info-general-form-input-text property-info-general-form-table-row"
      data-testid="confirm-input-text">
      <PropertyInfoFormLabel label={label} />
      <div
        className="property-info-general-form-table-cell"
        data-testid="property-info-general-form-table-cell">
        {state}
      </div>
    </div>
  );
};
export default ConfirmInputText;
