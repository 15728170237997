import pino from "pino";

import { BASE_URL_NAVIGATION, BASE_URL_SERVICE } from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_PROPOSALS = `${BASE_URL_NAVIGATION}/storage/current/proposals/data.json`;
const PREVIOUS_PROPOSALS = `${BASE_URL_NAVIGATION}/storage/previous/proposals/data.json`;

export const fetchProposalsStorage = async () => {
  try {
    const response = await fetchProposals(CURRENT_PROPOSALS);
    return extractProposals(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ proposals ）を取得できませんでした");

    try {
      const response = await fetchProposals(PREVIOUS_PROPOSALS);
      return extractProposals(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ proposals ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchProposals = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractProposals = (response) => {
  return response.map(proposal => proposal);
}


export async function getProposals(userId) {
  if (!userId) {
    return [];
  }

  const CURRENT_PROPOSALS = `${BASE_URL_SERVICE}/storage/current/users/user${userId}/proposals.json`
  const PREVIOUS_PROPOSALS = `${BASE_URL_SERVICE}/storage/previous/users/user${userId}/proposals.json`

  try {
    return await fetchData(CURRENT_PROPOSALS);
  } catch (error) {
    try {
      return await fetchData(PREVIOUS_PROPOSALS);
    } catch (error) {
      return [];
    }
  }
}
