// ロジックを含む処理
// フロントから呼ぶ関数

import { compareData } from '../../../shared/utils/helper/compareData.js';
import { getRemindMessages } from '../repositories/RemindMessage.js';

export async function get(type, governmentId) {
  const messages = await getRemindMessages(type);
  return messages.filter(
    (message) =>
      message.isDisplay && message.governmentID.toString() === governmentId,
  );
}

export async function findById(type, id) {
  const News = await getRemindMessages(type);
  return News.find((data) => data.id === id);
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
