//import { validatePasswordFormat, validateRangeLength, validateParamsMatch } from "../../../../application/validations.js";
import { validatePasswordFormat, validateRangeLength } from "../../../../application/validations.js";
//import { API_BASE_URL } from "../../../../config/envConstants.js";
//import axios from "axios";

export class Password {
  #password

  constructor(password) {
    this.#password = password;
  }

  get() {
    return this.#password
  }

  get isValid() {
    return [
      this.validatePasswordFormat,
      this.validateRangeLength,
      this.validateParamsMatch,
      this.validateCurrentPasswordCheck,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validatePasswordFormat() {
    return validatePasswordFormat(this.#password)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#password, 0, 128)
  }

  // バリデーションルールの適用
  get validateParamsMatch() {
    return 'TRUE'
    // todo: 必要になったら元に戻す
    // return validateParamsMatch(this.#password, check_password)
  }

  // バリデーションルールの適用
  get validateCurrentPasswordCheck() {
    return 'TRUE'
    // todo: 必要になったら元に戻す
    // return axios.get(`${API_BASE_URL}/api/v1/auction-users/confirm_password`, { headers: { user_id: user_id, password: this.#password } })
  }
}
