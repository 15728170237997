// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sample-guideline .template-guideline-topic-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: solid 5px var(--primary-base2);
}

.sample-guideline .template-guideline-topic-title {
  padding-left: 12px;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.294;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/SampleGuideline/template-guideline-topic-title.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".sample-guideline .template-guideline-topic-wrap {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-left: solid 5px var(--primary-base2);\n}\n\n.sample-guideline .template-guideline-topic-title {\n  padding-left: 12px;\n  font-size: 17px;\n  font-weight: 700;\n  line-height: 1.294;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
