import SmallTopicTitle from '../../../common/Heading/SmallTopicTitle.jsx';
import ManualMarkedList from '../../ManualMarkedList.jsx';
import CautionTopicTable from '../../CautionTopicTable.jsx';
import CautionManualMarkedList from '../../CautionManualMarkedList.jsx';

const requiredDocumentsData = [
  {
    headline: '動産',
    content: <ManualMarkedList data={[
      {
        mark: '・',
        text: '執行機関から落札者などへ送信したメールをプリントアウトしたもの',
      },
      {
        mark: '・',
        text: <>住所証明書<br/>-落札者が法人：商業登記簿抄本<br/>-落札者が個人：住民票など</>,
      },
      {
        mark: '・',
        text: '保管依頼書（保管を希望する場合）',
      },
      {
        mark: '・',
        text: '送付依頼書（送付を希望する場合）',
      },
    ]}/>,
  },
  {
    headline: '自動車',
    content: <ManualMarkedList data={[
      {
        mark: '・',
        text: '執行機関から落札者などへ送信したメールをプリントアウトしたもの',
      },
      {
        mark: '・',
        text: <>住所証明書<br/>-落札者が法人：商業登記簿抄本<br/>-落札者が個人：住民票など</>,
      },
      {
        mark: '・',
        text: '所有権移転登録請求書',
      },
      {
        mark: '・',
        text: '自動車保管場所証明書',
      },
      {
        mark: '・',
        text: '移転登録等申請書（第1号様式（OCRシート））など',
      },
      {
        mark: '・',
        text: '自動車検査登録印紙を貼付した手数料納付書',
      },
      {
        mark: '・',
        text: '郵便切手1500円程度（ただし、落札者の「使用の本拠の位置」を管轄する運輸支局、自動車検査登録事務所が●●運輸局●●運輸支局および●●自動車検査登録事務所以外の場合のみ）',
      },
    ]}/>,
  },
  {
    headline: '不動産',
    content: <ManualMarkedList data={[
      {
        mark: '・',
        text: '執行機関から落札者などへ送信したメールをプリントアウトしたもの',
      },
      {
        mark: '・',
        text: <>住所証明書<br/>-落札者が法人：商業登記簿抄本<br/>-落札者が個人：住民票など</>,
      },
      {
        mark: '・',
        text: '所有権移転登記請求書',
      },
      {
        mark: '・',
        text: '共有合意書（共同入札の場合のみ）',
      },
      {
        mark: '・',
        text: '権利移転の許可書または届出受理書（農地の場合）',
      },
      {
        mark: '・',
        text: '郵便切手1500円程度',
      },
    ]}/>,
  }
]
const RequiredDocumentsAuction = () => {
  return (
    <div data-testid="required-documents" className="pt-9">
      <SmallTopicTitle text='必要な書類' isFontBold={true}/>
      <ManualMarkedList className="pt-2" data={[
        {
          mark: '・',
          text: '必要な書類の一部は●●●ホームページからダウンロードできます。',
          isImportant: true,
        }
      ]}/>
      <CautionTopicTable recordData={requiredDocumentsData} className='mt-3'/>
      <CautionManualMarkedList title="ご注意" className="pt-3" data={[
        {
          mark: '・',
          text: '上記書類は、買受代金納付期限までに執行機関へ提出してください。',
        },
      ]}/>
    </div>
  );
};
export default RequiredDocumentsAuction;