import Modal2 from 'interfaces/components/common/Modal/Modal2.jsx';
import { useState, useContext } from 'react';
import 'interfaces/css/Dashboard/reminder-modal.css';
import Button from 'interfaces/components/common/Button/Button.jsx';
import { DashboardContext } from '../../../../pages/Dashboard/entities/DashboardContext';
import { ModalButtonArea } from 'interfaces/components/common/ModalButtonArea';

const ReminderModal = () => {
  const [displayReminder, setDisplayReminder] = useState(true);
  const { reminderMessages } = useContext(DashboardContext);

  if (reminderMessages.length === 0) {
    return null;
  }

  return (
    <div className="reminder-modal">
      <Modal2
        isOpen={displayReminder}
        handleClose={() => setDisplayReminder(false)}
        modalSize="1000px"
        modalHeight="90%"
        padding={false}
        closeClickOutside={true}>
        <div className="modal-content">
          <div className="modal-title">
            オークションにご参加予定の場合は
            <br />
            一般の参加申込開始日時までに必ず下記の内容を確認してください！
          </div>
          <ul className="list-box">
            {reminderMessages.map((reminder, index) => (
              <li key={index}>{reminder.message}</li>
            ))}
          </ul>
        </div>

        <hr className="modal-border" />
        <ModalButtonArea>
          <Button
            text={'閉じる'}
            isOutline={false}
            height={'44px'}
            onClick={() => setDisplayReminder(false)}
          />
        </ModalButtonArea>
      </Modal2>
    </div>
  );
};

export default ReminderModal;
