import {display_active, display_deadline, display_target} from "shared/utils/helper/radio_labels.js";

import { useBannerInputs } from "./hook/useBannerInputs"
import { useBannerInputsValidation } from "./hook/useBannerInputsValidation.jsx";

export const validateInputsBanner = () => {
  const formInputs = useBannerInputs();
  const validate = useBannerInputsValidation();

  function handleTitle(e) {
    validate.setters.title(e)
    formInputs.setters.title(e.target.value)
    console.log(validate.validationMessage.title)
  }

  function handleDestinationUrl(e) {
    validate.setters.destinationUrl(e)
    formInputs.setters.destinationUrl(e.target.value)
    console.log(validate.validationMessage.destinationUrl)
  }

  function handleDisplayDeadline(e) {
    formInputs.setters.displayDeadline(e.target.value)
  }
  function handleDeadline(e) {
    formInputs.setters.deadline(e)
  }

  function handleDisplayTarget(e) {
    formInputs.setters.displayTarget(e.target.value)
  }

  function handleActiveStatus(e) {
    formInputs.setters.activeStatus(e.target.value)
  }

  const validateInputs = [
  {
    type: formInputs.type.title,
    column: 'title',
    text: 'タイトル',
    validationMessage: validate.validationMessage.title,
    state: formInputs.data.title,
    init: (e) => formInputs.setters.title(e),
    setters: handleTitle,
    required: true
  },
  {
    type: formInputs.type.destinationUrl,
    column: 'destinationUrl',
    text: '遷移先URL',
    validationMessage: validate.validationMessage.destinationUrl,
    state: formInputs.data.destinationUrl,
    init: (e) => formInputs.setters.destinationUrl(e),
    setters: handleDestinationUrl,
    required: true
  },
  {
    type: formInputs.type.displayDeadline,
    column: 'displayDeadline',
    text: '有効期限',
    validationMessage: validate.validationMessage.displayDeadline,
    state: formInputs.data.displayDeadline,
    init: (e) => formInputs.setters.displayDeadline(e),
    setters: handleDisplayDeadline,
    list:display_deadline
  },
    {
    type: formInputs.type.deadline,
    column: 'deadline',
    text: '有効期限',
    validationMessage: validate.validationMessage.displayDeadline,
    state: formInputs.data.deadline,
    init: (e) => formInputs.setters.deadline(e),
    setters: handleDeadline
  },
  {
    type: formInputs.type.displayTarget,
    column: 'displayTarget',
    text: 'ターゲット',
    validationMessage: validate.validationMessage.displayTarget,
    state: formInputs.data.displayTarget,
    init: (e) => formInputs.setters.displayTarget(e),
    setters: handleDisplayTarget,
    list: display_target,
    required: true
  },
  {
    type: formInputs.type.activeStatus,
    column: 'activeStatus',
    text: 'アクティブ',
    validationMessage: validate.validationMessage.activeStatus,
    state: formInputs.data.activeStatus,
    init: (e) => formInputs.setters.activeStatus(e),
    setters: handleActiveStatus,
    list: display_active,
    required: true
  },

  ];

  return validateInputs;
}