
export class TwoStepVerificationStatus {
  #twoStepVerificationStatus

  constructor(twoStepVerificationStatus) {
    this.#twoStepVerificationStatus = twoStepVerificationStatus;
  }

  get() {
    return this.#twoStepVerificationStatus
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
