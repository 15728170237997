// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-list .property-list-setting-header .setting-header,
.property-list .property-list-setting-header .setting-header .actions-left,
.property-list .property-list-setting-header .setting-header .actions-right {
    display: flex;
    margin: 20px 0 0;
    padding: 10px 0;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.property-list .property-list-setting-header .setting-header .actions-left,
.property-list .property-list-setting-header .setting-header .actions-right {
    margin-top: 0;
}

.property-list .property-list-setting-header .setting-header .actions-left > * + *,
.property-list .property-list-setting-header .setting-header .actions-right > * + * {
    margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/PropertyList/property-list-setting-header.css"],"names":[],"mappings":"AAAA;;;IAGI,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;;IAEI,iBAAiB;AACrB","sourcesContent":[".property-list .property-list-setting-header .setting-header,\n.property-list .property-list-setting-header .setting-header .actions-left,\n.property-list .property-list-setting-header .setting-header .actions-right {\n    display: flex;\n    margin: 20px 0 0;\n    padding: 10px 0;\n    align-items: center;\n    justify-content: space-between;\n    position: relative;\n}\n\n.property-list .property-list-setting-header .setting-header .actions-left,\n.property-list .property-list-setting-header .setting-header .actions-right {\n    margin-top: 0;\n}\n\n.property-list .property-list-setting-header .setting-header .actions-left > * + *,\n.property-list .property-list-setting-header .setting-header .actions-right > * + * {\n    margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
