import { validateAlphanum, validateRangeLength } from "../../../../application/validations.js";

export class ProductNumber {
  #productNumber

  constructor(productNumber) {
    this.#productNumber = productNumber;
  }

  get() {
    return this.#productNumber
  }

  get isValid() {
    return [
      this.validateAlphanum,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateAlphanum() {
    return validateAlphanum(this.#productNumber)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#productNumber, 0, 30)
  }
}
