import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class ImportantRestrictionUseBefore {
  #importantRestrictionUseBefore

  constructor(importantRestrictionUseBefore) {
    this.#importantRestrictionUseBefore = importantRestrictionUseBefore;
  }

  get() {
    return this.#importantRestrictionUseBefore
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#importantRestrictionUseBefore, 0, 1000)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#importantRestrictionUseBefore)
  }
}
