import 'interfaces/css/common/common-table-default.css';
import { DATA_EMPTY } from 'domain/Property/entities/constants/property';

import CustomPrice from './CustomPrice';

const BasicInfoTable = ({ title, data }) => {
  return (
    <table data-testid="basic-table" className="common-table-default">
      <caption data-testid="caption">{title}</caption>
      <tbody>
        {data?.map((data) => {
          return (
            data.value !== undefined &&
            data.value !== '' &&
            data.value !== 'NULL' &&
            data.value !== DATA_EMPTY && (
              <tr key={data.id} data-testid="sub-title">
                <th>{data.title}</th>
                <CustomPrice data={data} />
              </tr>
            )
          );
        })}
      </tbody>
    </table>
  );
};
export default BasicInfoTable;
