
export class EstateBidSystemFeeRate {
  #estateBidSystemFeeRate

  constructor(estateBidSystemFeeRate) {
    this.#estateBidSystemFeeRate = estateBidSystemFeeRate;
  }

  get() {
    return this.#estateBidSystemFeeRate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
