// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-box {
  max-width: 1200px;
  width: 90%;
}

.news-heading1 {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: .1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.news-title {
  position: relative;
  padding-left: 12px;
}

.news-subtitle {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.75rem;
  letter-spacing: .009375em;
  font-family: "Roboto", sans-serif;
}

.news-title:before {
  position: absolute;
  display: block;
  content: "";
  background-color: var(--primary-base2);
  width: 4px;
  height: 100%;
  left: 0;
}

.news-substance {
  border: 1px solid #e8e6e6;
  background-color: #ffffff;
}

.news-context {
  font-family: "Noto Sans JP", -apple-system, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", meiryo, sans-serif;
  font-size: 1rem;

}

.news-context:not(table) {
  line-height: 160%;
}

.news-context p {
  font-size: .875rem;
  word-wrap: break-word;
  margin: 10px 0;
  color: #222;
}

@media (max-width: 959px) {
  .news-box {
    max-width: 100%;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/News/news.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;EACpB,aAAa;EAEb,2BAA2B;EAE3B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,yBAAyB;EACzB,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,sCAAsC;EACtC,UAAU;EACV,YAAY;EACZ,OAAO;AACT;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,mHAAmH;EACnH,eAAe;;AAEjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,cAAc;EACd,WAAW;AACb;;AAEA;EACE;IACE,eAAe;IACf,WAAW;EACb;AACF","sourcesContent":[".news-box {\n  max-width: 1200px;\n  width: 90%;\n}\n\n.news-heading1 {\n  font-size: 1.5rem;\n  font-weight: 700;\n  letter-spacing: .1em;\n  display: flex;\n  -webkit-box-pack: start;\n  justify-content: flex-start;\n  -webkit-box-align: center;\n  align-items: center;\n}\n\n.news-title {\n  position: relative;\n  padding-left: 12px;\n}\n\n.news-subtitle {\n  font-size: 1rem;\n  font-weight: 700;\n  line-height: 1.75rem;\n  letter-spacing: .009375em;\n  font-family: \"Roboto\", sans-serif;\n}\n\n.news-title:before {\n  position: absolute;\n  display: block;\n  content: \"\";\n  background-color: var(--primary-base2);\n  width: 4px;\n  height: 100%;\n  left: 0;\n}\n\n.news-substance {\n  border: 1px solid #e8e6e6;\n  background-color: #ffffff;\n}\n\n.news-context {\n  font-family: \"Noto Sans JP\", -apple-system, \"ヒラギノ角ゴ Pro W3\", \"Hiragino Kaku Gothic Pro\", \"メイリオ\", meiryo, sans-serif;\n  font-size: 1rem;\n\n}\n\n.news-context:not(table) {\n  line-height: 160%;\n}\n\n.news-context p {\n  font-size: .875rem;\n  word-wrap: break-word;\n  margin: 10px 0;\n  color: #222;\n}\n\n@media (max-width: 959px) {\n  .news-box {\n    max-width: 100%;\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
