import CustomError from './customError.js';

export const newArrayClass = (response, Class) => {
  if (response instanceof Error) {
    throw new CustomError('Error get Category', response);
  }

  let results = [];
  response.map((data) => {
    const result = new Class(data);
    results.push(result);
  });
  return results;
};

export const newObjectClass = (response, Class) => {
  if (response instanceof Error) {
    throw new CustomError('Error get Category', response);
  }
  return new Class(response);
};
