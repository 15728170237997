import { CAR, ESTATE } from 'domain/Property/entities/constants/property';

import { ReactComponent as CarIcon } from 'interfaces/assets/svg/car_icon.svg';
import { ReactComponent as EstateIcon } from 'interfaces/assets/svg/estate_icon.svg';
import { ReactComponent as SearchIcon } from 'interfaces/assets/svg/search_icon.svg';
import RectangleButton from 'interfaces/components/common/LeftSideMenu/RectangleButton';
import TopicCard from 'interfaces/components/common/TopicCard/TopicCard';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
//import { useAuth } from '../../../hooks/useAuth';
//import { useError } from 'shared/hooks/useError';
//import getGeneralCategories from '../../read_storage/general_categories';
import { QUERY_PARAM_FREE_SEARCH } from 'shared/utils/constants/queryParam';

import CategoryButtonList from './CategoryButtonList';

//import Category from 'interfaces/components/common/Category/ServiceCategory';

//import { CarCategoryData } from './helper/carCategories';
import { CarSearchConditionsData } from './helper/carSearchConditions';
//import { EstateCategoryData } from './helper/estateCategories';
import { EstateSearchConditionsData } from './helper/estateSearchConditions';
import { GeneralSearchConditionsData } from './helper/generalSearchConditions';
import SearchConditions from './SearchConditions';
import SearchEstateConditions from './SearchEstateConditions';
import { PropertiesContext } from '../../../pages/Properties/entities/PropertiesContext';
import SearchCategoryMenu from '../../Category/service/SearchCategoryMenu';

const TopicCardSwitcher = ({
  category,
  estatePropertiesNum,
  carPropertiesNum,
}) => {
  const navigation = useNavigate();
  const { carCategoryData, estateCategoryData, generalCategories } =
    useContext(PropertiesContext);
  //  const { userId } = useAuth();
  //const userId = 1;

  //const [generalCategories, setGeneralCategories] = useState([]);
  //const { setError } = useError();

  //useEffect(() => {
  //  const generalCategories = async () => {
  //    try {
  //      //const generalCategories = await getGeneralCategories(userId);
  //      //setGeneralCategories(generalCategories);
  //      setGeneralCategories([]);
  //    } catch (error) {
  //      setError(error);
  //    }
  //  };
  //  generalCategories().then();
  // }, [userId]);

  if (category === CAR) {
    return (
      <div>
        <button
          type="button"
          className="back-to-top-button d-inline-flex justify-center align-center font-weight-bold mb-4 theme--light"
          onClick={() => navigation('/search')}
          data-testid="back-to-top-button"
          style={{ height: '40px', minWidth: '100%' }}>
          <span className="back-to-top-button-content flex align-center">
            <SearchIcon />
            <p className="text-button font-weight-bold mt-n1 mb-n1">
              検索TOPへ
            </p>
          </span>
        </button>
        <CategoryButtonList
          title="カテゴリを選ぶ"
          categoryList={carCategoryData}
        />
        <TopicCard
          title="条件で絞り込む"
          body={
            <SearchConditions
              data={CarSearchConditionsData}
              category={category}
            />
          }
        />
      </div>
    );
  }

  if (category === ESTATE) {
    return (
      <div>
        <button
          type="button"
          className="back-to-top-button d-inline-flex justify-center align-center font-weight-bold mb-4 theme--light"
          onClick={() => navigation('/search')}
          data-testid="back-to-top-button"
          style={{ height: '40px', minWidth: '100%' }}>
          <span className="back-to-top-button-content flex align-center">
            <SearchIcon />
            <p className="text-button font-weight-bold mt-n1 mb-n1">
              検索TOPへ
            </p>
          </span>
        </button>
        <CategoryButtonList
          title="カテゴリを選ぶ"
          categoryList={estateCategoryData}
        />
        <TopicCard
          title="条件で絞り込む"
          body={
            <SearchEstateConditions
              data={EstateSearchConditionsData}
              category={category}
            />
          }
        />
      </div>
    );
  }

  return (
    <div>
      <div style={{ marginBottom: '15px' }}>
        <RectangleButton
          svg={<EstateIcon title="estate" />}
          label="不動産検索"
          count={estatePropertiesNum}
          onClick={() =>
            navigation(`/search?category=ESTATE&${QUERY_PARAM_FREE_SEARCH}`)
          }
        />
        <RectangleButton
          svg={<CarIcon title="car" />}
          label="自動車検索"
          count={carPropertiesNum}
          onClick={() =>
            navigation(`/search?category=CAR&${QUERY_PARAM_FREE_SEARCH}`)
          }
        />
      </div>
      <div className="property-search-box">
        <TopicCard
          title="カテゴリから探す"
          body={
            <>
              <SearchCategoryMenu
                propertyCategories={generalCategories}
                isOpen={true}
                showCheckBox={true}
                showCount={false}
              />
            </>
          }
        />
        <TopicCard
          title="条件で絞り込む"
          body={<SearchConditions data={GeneralSearchConditionsData} />}
        />
      </div>
    </div>
  );
};

export default TopicCardSwitcher;
