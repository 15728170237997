
export class DisplayEstate {
  #displayEstate

  constructor(displayEstate) {
    this.#displayEstate = displayEstate;
  }

  get() {
    return this.#displayEstate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
