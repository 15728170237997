import BidsDetailTable from "interfaces/components/Bid/service/BidsDetailTable.jsx";
import Button from "interfaces/components/common/Button/Button.jsx";
import GovernmentLabel from "interfaces/components/Guidelines/GuidelineAgreement/GovernmentLabel.jsx";
import HorizontalPairLinks from "interfaces/components/Guidelines/GuidelineAgreement/HorizontalPairLinks.jsx";
import Stepper from "interfaces/components/Guidelines/GuidelineAgreement/Stepper.jsx";
import StepTitle from "interfaces/components/Guidelines/GuidelineAgreement/StepTitle.jsx";
import ReceptionCompletionBidsMessage from "interfaces/components/Reception/ReceptionCompletionBidsMessage.jsx";


const stepData = {
    steps: [
        {
            label: "入札内容の確認"
        },
        {
            label: "受付完了"
        }
    ]
};
const stepTitleData = {
    num: 2,
    title: '受付完了'
}
const bidsDetailTableData = {
    tableData: [
        {
            "id": 1,
            "title": "物件名",
            "value": "レンダリングテスト"
        },
        {
            "id": 2,
            "title": "見積価格",
            "value": "1,000,000円"
        },
        {
            "id": 3,
            "title": "入札価格",
            "value": "1,200,000円（120万円）"
        }
    ]
};
const horizontalPairLinksData = {
    left_link: {
        label: "物件一覧",
        link: "#"
    },
    right_link: {
        label: "KSI官公庁オークショントップ",
        link: "/"
    }
}
const ReceptionCompletedBids = () => {
    return (
        <div className="common-side-margin-wrap no-bg mx-auto box grow property-sheet theme-light mx-auto" style={{ maxWidth: "1200px", width: "90%" }}>
            <div data-testid="reception-completed-bids" className="mb-10">
                <GovernmentLabel label="ラベルレンダリングテスト"/>
                <Stepper steps={stepData.steps} active={2}/>
                <StepTitle data={stepTitleData}/>
                <ReceptionCompletionBidsMessage email={"test@test.co.jp"} property_farmat={"インターネット公売"}/>
                <BidsDetailTable data={bidsDetailTableData.tableData}/>
                <div className="flex justify-center mb-10">
                <Button text={"物件詳細ページに戻る"}/>
                </div>
                <HorizontalPairLinks data={horizontalPairLinksData}/>
            </div>
        </div>
    );
}
export default ReceptionCompletedBids