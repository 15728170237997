import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
// 動作確認用
const CURRENT_DOCUMENT_SEND_PAGES = `${BASE_URL_NAVIGATION}/storage/current/document_send_pages/data.json`;
const PREVIOUS_DOCUMENT_SEND_PAGES = `${BASE_URL_NAVIGATION}/storage/previous/document_send_pages/data.json`;

export const fetchDocumentSendPagesStorage = async () => {
  try {
    const response = await fetchDocumentSendPages(CURRENT_DOCUMENT_SEND_PAGES);
    return extractDocumentSendPages(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ document_send_pages ）を取得できませんでした");

    try {
      const response = await fetchDocumentSendPages(PREVIOUS_DOCUMENT_SEND_PAGES);
      return extractDocumentSendPages(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ document_send_pages ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchDocumentSendPages = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractDocumentSendPages = (response) => {
  return response.map(document_send_page => document_send_page);
}