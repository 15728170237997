import { ValidationMessage } from 'interfaces/components/common/ValidationMessage';
import { useState } from 'react';
import styled from 'styled-components';
import FormLabel from './FormLabel';
import { ReactComponent as ImageIcon } from 'interfaces/assets/svg/image_icon.svg';
import TextField from '@mui/material/TextField';

const InputFileForm = styled.div`
  svg {
    margin-right: 10px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputFile = ({
  invalidMessage,
  required,
  label,
  state,
  setState,
  index = 1,
}) => {
  const handleFileClick = () => {
    document.getElementById(`hidden-file-input-${index}`).click();
  };

  const [preview, setPreview] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        setState(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="kmanager-row">
      {label && (
        <div className="kmanager-col-4">
          <FormLabel label={label} required={required} />
        </div>
      )}
      <div className="kmanager-col form-area flex-column">
        <div style={{ position: 'relative' }}>
          <InputFileForm>
            <ImageIcon />
            <TextField
              variant="standard"
              value={state}
              onClick={handleFileClick}
              label="画像ファイルをアップロードしてください"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
            <input
              type="file"
              id={`hidden-file-input-${index}`}
              data-testid="input-file"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </InputFileForm>
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
        {preview && (
          <img
            width="400px"
            src={preview}
            alt="Preview"
            data-testid="image-preview"
            className="image-preview mt-2"
          />
        )}
      </div>
    </div>
  );
};
export default InputFile;
