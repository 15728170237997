// ロジックを含まない処理の実装

import fetchGeneralCategoriesStorage from 'infrastructure/api/storage/general_categories';
import { getPropertyCategories } from 'infrastructure/api/storage/property_categories';
import { KMANAGER, NAVI, SERVICE } from 'shared/utils/helper/constants';
import CustomError from 'shared/utils/helper/customError';

import { CategoryGet } from '../entities/Category';

// getメソッドの実装
export const getPropertyCategory = async (type) => {
  // SAMPLE
  let response = [];
  if (type === SERVICE) {
    response = await getPropertyCategories();
  }
  if (type === NAVI) {
    response = await getPropertyCategories();
  }
  if (type === KMANAGER) {
    response = await getPropertyCategories();
  }

  if (response instanceof Error) {
    throw new CustomError('Error get Category', response);
  }

  let results = [];
  response.map((data) => {
    const result = new CategoryGet(data);
    results.push(result);
  });

  return results;
};

// getメソッドの実装
export const getV1PropertyCategory = async (type) => {
  // SAMPLE
  let response = [];
  if (type === SERVICE) {
    response = await fetchGeneralCategoriesStorage();
  }
  if (type === NAVI) {
    response = await getPropertyCategories();
  }
  if (type === KMANAGER) {
    response = await getPropertyCategories();
  }

  if (response instanceof Error) {
    throw new CustomError('Error get Category', response);
  }

  let results = [];
  response.map((data) => {
    const result = new CategoryGet(data);
    results.push(result);
  });

  return results;
};
