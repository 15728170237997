import MessageProvider from './MessageProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { message } from "../entities/componentMapping.js";

const Message = ({ type }) => {
  return (
    <MessageProvider type={type}>
      {getComponent(message, type)}
    </MessageProvider>
  );
};

export default Message;
