// ロジックを含まない処理の実装

import { getAuctions } from "infrastructure/api/storage/auctions";

import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { AuctionGet } from "../entities/Auction.js";


// getメソッドの実装
export async function getAuction(type) {

  let response = [];
  if (type === SERVICE) {
    response = await getAuctions()
  }
  if (type === NAVI) {
    response = await getAuctions()
  }
  if (type === KMANAGER) {
    response = await getAuctions()
  }

  if (response instanceof Error) {
    throw new CustomError('Error get Auction', response);
  }

  let results = [];
  response.map((data) => {
    const result = new AuctionGet(data);
    results.push(result)
  })

  return results
}
