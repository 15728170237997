import IconToggleButton from "./IconToggleButton.jsx";
import TextLink from "./TextLink.jsx";
import { ReactComponent as MenuIcon } from "../../../../assets/svg/menu-icon.svg";

const ManagementHeader = ({ leftMenuHandler, rightMenuHandler }) => {
  return (
    <header data-testid="kmanager-header" className="kmanager-header d-flex align-center justify-space-between">
      <div className="d-flex align-center">
        <IconToggleButton svg={<MenuIcon title='left-menu-icon'/>} onClick={leftMenuHandler}/>
        <TextLink text='官公庁オークション&emsp;運営管理ツール' link='/kmanager'/>
      </div>
      <IconToggleButton svg={<MenuIcon title='right-menu-icon'/>} onClick={rightMenuHandler}/>
    </header>
  );
};
export default ManagementHeader;