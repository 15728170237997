export const YAMATO = {
  ja: 'ヤマト運輸',
  en: 'YAMATO'
};

export const SAGAWA = {
  ja: '佐川急便',
  en: 'SAGAWA'
};

export const JAPAN_POST = {
  ja: '日本郵便（ゆうパック）',
  en: 'JAPAN_POST'
};

export const OTHER = {
  ja: 'その他',
  en: 'OTHER'
};
