import {CreatedAt} from "./ManagementUsers/createdAt";
import {CreatedUser} from "./ManagementUsers/createdUser";
import {Department} from "./ManagementUsers/department";
import {Email} from "./ManagementUsers/email";
import {Extension} from "./ManagementUsers/extension";
import {FamilyName} from "./ManagementUsers/familyName";
import {FamilyNameKana} from "./ManagementUsers/familyNameKana";
import {FirstName} from "./ManagementUsers/firstName";
import {FirstNameKana} from "./ManagementUsers/firstNameKana";
import {Id} from "./ManagementUsers/id";
import {OfficePosition} from "./ManagementUsers/officePosition";
import {Password} from "./ManagementUsers/password";
import {Tel} from "./ManagementUsers/tel";
import {UpdatedAt} from "./ManagementUsers/updatedAt";
import {UpdatedUser} from "./ManagementUsers/updatedUser";
import {UserType} from "./ManagementUsers/userType";
import CustomError from "../../../shared/utils/helper/customError.js";
import { NotUndefined } from "../../../shared/utils/helper/NotUndefined.js";

export class ManagementUserGet {

  constructor(user) {
    this.id = user.id
    this.userType = user.userType
    this.status = user.status
    this.familyName = user.familyName
    this.familyNameKana = user.familyNameKana
    this.firstName = user.firstName
    this.firstNameKana = user.firstNameKana
    this.department = user.department
    this.officePosition = user.officePosition
    this.tel = user.tel
    this.extension = user.extension
    this.email = user.email
    this.createdUser = user.createdUser
    this.createdAt = user.createdAt
    this.updatedUser = user.updatedUser
    this.updatedAt = user.updatedAt
    //if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return NotUndefined(this)
  }
}

export class ManagementUserCreate {

  constructor(user) {
    this.id = new Id(user.id)
    this.password = new Password(user.password)
    this.userType = new UserType(user.userType)
    this.familyName = new FamilyName(user.familyName)
    this.familyNameKana = new FamilyNameKana(user.familyNameKana)
    this.firstName = new FirstName(user.firstName)
    this.firstNameKana = new FirstNameKana(user.firstNameKana)
    this.department = new Department(user.department)
    this.officePosition = new OfficePosition(user.officePosition)
    this.tel = new Tel(user.tel)
    this.extension = new Extension(user.extension)
    this.email = new Email(user.email)
    this.createdUser = new CreatedUser(user.createdUser)
    this.createdAt = new CreatedAt(user.createdAt)
    this.updatedUser = new UpdatedUser(user.updatedUser)
    this.updatedAt = new UpdatedAt(user.updatedAt)
    if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return this.id.isValid &&
      this.password.isValid &&
      this.userType.isValid &&
      this.familyName.isValid &&
      this.familyNameKana.isValid &&
      this.firstName.isValid &&
      this.firstNameKana.isValid &&
      this.department.isValid &&
      this.officePosition.isValid &&
      this.tel.isValid &&
      this.extension.isValid &&
      this.email.isValid &&
      this.createdUser.isValid &&
      this.createdAt.isValid &&
      this.updatedUser.isValid &&
      this.updatedAt.isValid
  }
}