import 'interfaces/css/common/thumbnail.css';

const Thumbnail = ({ url, isActive, clickHandler }) => {
  return (
    <div
      className={`items-hero-viewer-thumbnail swiper-slide ${isActive ? 'swiper-slide-active' : ''}`}
      onClick={clickHandler}
      data-testid="thumbnail-image">
      <div className="items-hero-viewer-thumbnail__card no-bg v-card v-sheet theme--light rounded-0">
        <div
          className="v-image v-responsive items-hero-viewer-thumbnail__image theme--light"
          style={{ height: '100px', width: '100px' }}>
          <div className="v-responsive__sizer"></div>
          <div
            className="v-image__image v-image__image--cover"
            style={{
              backgroundImage: `url(${url})`,
              backgroundPosition: 'center',
            }}></div>
          <div
            className="v-responsive__content"
            style={{ width: '301px' }}></div>
        </div>
      </div>
    </div>
  );
};
export default Thumbnail;
