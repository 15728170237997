
export class Price {
  #price

  constructor(price) {
    this.#price = price;
  }

  get() {
    return this.#price
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
