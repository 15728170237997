//import { isDefinedDateTime, isAfterSet } from "../../../../application/validations.js";
import { isDefinedDateTime } from "../../../../application/validations.js";

export class AuctionEnd {
  #auctionEnd

  constructor(auctionEnd) {
    this.#auctionEnd = auctionEnd;
  }

  get() {
    return this.#auctionEnd
  }

  get isValid() {
    return [
      this.isDefinedDateTime,
      this.isAfterSet,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get isDefinedDateTime() {
    return isDefinedDateTime(this.#auctionEnd)
  }

  // バリデーションルールの適用
  get isAfterSet() {
    return 'TRUE'
    // TOOD: startDateTimeが引き渡されていないため一時コメントアウト
    // return isAfterSet(this.#auctionEnd, endDateTime)
  }
}
