// ロジックを含まない処理の実装

import {fetchBidderStorage} from "../../../infrastructure/api/storage/bidder.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { BidderListGet } from "../entities/List.js";

// getメソッドの実装
export async function getBidderList(type) {
  // SAMPLE
  let response = [];
  if (type === SERVICE) {
    response = await fetchBidderStorage()
  }
  if (type === NAVI) {
    response = await fetchBidderStorage()
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (List)');
  }

  if (response instanceof Error) {
    throw new CustomError('Error get List', response);
  }

  let results = [];
  response.map((data) => {
    const result = new BidderListGet(data);
    results.push(result)
  })

  return results
}
