import { useEffect, useState } from "react";

import Button from "../Button.jsx";

const CreateButton = ({ inputs, clickHandler }) => {
  const [isValid, setIsValid] = useState(false)

  const Create = (e) => {
    // TODO: クリック時のバリデーションチェック
    clickHandler(e)
  }

  useEffect(() => {
    setIsValid(inputs.every((input) => (input?.validationMessage ?? 'TRUE') === 'TRUE'))
  }, [inputs]);

  return (
    <Button
      text={'確定'}
      clickHandler={Create}
      isDisabled={!isValid}
      className={'primary'}
    />
  )
}

export default CreateButton