import { useState } from "react";
import * as Validation from "shared/utils/helper/managerValidators";

export const UsePromotionEditInputsValidation = () => {
  const [themeValid, setThemeValid] = useState('TRUE');
  const [displayOrderValid, setDisplayOrderValid] = useState('TRUE');
  const [searchUrlValid, setSearchUrlValid] = useState('TRUE');
  const [source1Valid, setSource1Valid] = useState('TRUE');
  const [source2Valid, setSource2Valid] = useState('TRUE');
  const [source3Valid, setSource3Valid] = useState('TRUE');
  const [source4Valid, setSource4Valid] = useState('TRUE');
  const [source5Valid, setSource5Valid] = useState('TRUE');
  const [source6Valid, setSource6Valid] = useState('TRUE');
  const [source7Valid, setSource7Valid] = useState('TRUE');
  const [source8Valid, setSource8Valid] = useState('TRUE');
  const [source9Valid, setSource9Valid] = useState('TRUE');
  const [source10Valid, setSource10Valid] = useState('TRUE');
  const [source11Valid, setSource11Valid] = useState('TRUE');
  const [source12Valid, setSource12Valid] = useState('TRUE');
  const [source13Valid, setSource13Valid] = useState('TRUE');
  const [source14Valid, setSource14Valid] = useState('TRUE');
  const [source15Valid, setSource15Valid] = useState('TRUE');
  const [source16Valid, setSource16Valid] = useState('TRUE');
  const [source17Valid, setSource17Valid] = useState('TRUE');
  const [source18Valid, setSource18Valid] = useState('TRUE');

  function handleThemeValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateThemeLength(e.target.value);
    if (invalid !== "TRUE") {
      setThemeValid(invalid); // 状態更新
      return
    }
    invalid = Validation.validateThemeCharacter(e.target.value);
    if (invalid !== "TRUE") {
      setThemeValid(invalid); // 状態更新
      return
    }
    setThemeValid(invalid); // 状態更新
  }

  function handleDisplayOrderValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateDisplaySelect(e.target.value);
    if (invalid !== "TRUE") {
      setDisplayOrderValid(invalid); // 状態更新
      return
    }
    setDisplayOrderValid(invalid); // 状態更新
  }

  function handleSearchUrlValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSearchUrlHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSearchUrlValid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSearchUrlLength(e.target.value);
    if (invalid !== "TRUE") {
      setSearchUrlValid(invalid); // 状態更新
      return
    }
    setSearchUrlValid(invalid); // 状態更新
  }

  function handleSource1Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource1Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource1Valid(invalid); // 状態更新
      return
    }
    setSource1Valid(invalid); // 状態更新
  }

  function handleSource2Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource2Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource2Valid(invalid); // 状態更新
      return
    }
    setSource2Valid(invalid); // 状態更新
  }

  function handleSource3Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource3Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource3Valid(invalid); // 状態更新
      return
    }
    setSource3Valid(invalid); // 状態更新
  }

  function handleSource4Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource4Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource4Valid(invalid); // 状態更新
      return
    }
    setSource4Valid(invalid); // 状態更新
  }

  function handleSource5Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource5Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource5Valid(invalid); // 状態更新
      return
    }
    setSource5Valid(invalid); // 状態更新
  }

  function handleSource6Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource6Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource6Valid(invalid); // 状態更新
      return
    }
    setSource6Valid(invalid); // 状態更新
  }

  function handleSource7Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource7Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource7Valid(invalid); // 状態更新
      return
    }
    setSource7Valid(invalid); // 状態更新
  }

  function handleSource8Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource8Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource8Valid(invalid); // 状態更新
      return
    }
    setSource8Valid(invalid); // 状態更新
  }

  function handleSource9Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource9Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource9Valid(invalid); // 状態更新
      return
    }
    setSource9Valid(invalid); // 状態更新
  }

  function handleSource10Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource10Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource10Valid(invalid); // 状態更新
      return
    }
    setSource10Valid(invalid); // 状態更新
  }

  function handleSource11Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource11Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource11Valid(invalid); // 状態更新
      return
    }
    setSource11Valid(invalid); // 状態更新
  }

  function handleSource12Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource12Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource12Valid(invalid); // 状態更新
      return
    }
    setSource12Valid(invalid); // 状態更新
  }

  function handleSource13Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource13Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource13Valid(invalid); // 状態更新
      return
    }
    setSource13Valid(invalid); // 状態更新
  }

  function handleSource14Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource14Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource14Valid(invalid); // 状態更新
      return
    }
    setSource14Valid(invalid); // 状態更新
  }

  function handleSource15Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource15Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource15Valid(invalid); // 状態更新
      return
    }
    setSource15Valid(invalid); // 状態更新
  }

  function handleSource16Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource16Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource16Valid(invalid); // 状態更新
      return
    }
    setSource16Valid(invalid); // 状態更新
  }

  function handleSource17Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource17Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource17Valid(invalid); // 状態更新
      return
    }
    setSource17Valid(invalid); // 状態更新
  }

  function handleSource18Valid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateSourceHttps(e.target.value);
      if (invalid !== "TRUE") {
        setSource18Valid(invalid); // 状態更新
        return
      }
    }
    invalid = Validation.validateSourceLength(e.target.value);
    if (invalid !== "TRUE") {
      setSource18Valid(invalid); // 状態更新
      return
    }
    setSource18Valid(invalid); // 状態更新
  }

  const validations = {
    // validationの処理
    setters: {
      theme: handleThemeValid,
      displayOrder: handleDisplayOrderValid,
      searchUrl: handleSearchUrlValid,
      source1: handleSource1Valid,
      source2: handleSource2Valid,
      source3: handleSource3Valid,
      source4: handleSource4Valid,
      source5: handleSource5Valid,
      source6: handleSource6Valid,
      source7: handleSource7Valid,
      source8: handleSource8Valid,
      source9: handleSource9Valid,
      source10: handleSource10Valid,
      source11: handleSource11Valid,
      source12: handleSource12Valid,
      source13: handleSource13Valid,
      source14: handleSource14Valid,
      source15: handleSource15Valid,
      source16: handleSource16Valid,
      source17: handleSource17Valid,
      source18: handleSource18Valid,
    },

    // invalidの時のメッセージ
    validationMessage: {
      theme: themeValid,
      displayOrder: displayOrderValid,
      searchUrl: searchUrlValid,
      source1: source1Valid,
      source2: source2Valid,
      source3: source3Valid,
      source4: source4Valid,
      source5: source5Valid,
      source6: source6Valid,
      source7: source7Valid,
      source8: source8Valid,
      source9: source9Valid,
      source10: source10Valid,
      source11: source11Valid,
      source12: source12Valid,
      source13: source13Valid,
      source14: source14Valid,
      source15: source15Valid,
      source16: source16Valid,
      source17: source17Valid,
      source18: source18Valid,
    }
  }
  return validations;
}
