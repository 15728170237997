// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard .kmanager-auction-th {
  font-size: .75rem;
  height: 48px;
  padding: 0 16px;
  -webkit-user-select: none;
          user-select: none;
  white-space: nowrap;
  color: rgba(0, 0, 0, .6);
  border-right: thin solid hsla(0, 0%, 50.2%, .12);
  border-bottom: thin solid rgba(0, 0, 0, .12);
  background-color: #f5f5f5;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Dashboard/kmanager-auction-table-th.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;EACjB,mBAAmB;EACnB,wBAAwB;EACxB,gDAAgD;EAChD,4CAA4C;EAC5C,yBAAyB;AAC3B","sourcesContent":[".dashboard .kmanager-auction-th {\n  font-size: .75rem;\n  height: 48px;\n  padding: 0 16px;\n  user-select: none;\n  white-space: nowrap;\n  color: rgba(0, 0, 0, .6);\n  border-right: thin solid hsla(0, 0%, 50.2%, .12);\n  border-bottom: thin solid rgba(0, 0, 0, .12);\n  background-color: #f5f5f5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
