import ServiceProviders from "../../../components/Setting/kmanager/ServiceProvider/ServiceProviders";

const ServiceProvider = () => {
    return (
        <div className="kmanager-service-provider" data-testid="kmanager-service-provider">
            <ServiceProviders/>
        </div>
    );
}

export default ServiceProvider
