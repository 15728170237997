
export class ManufactureOther {
  #manufactureOther

  constructor(manufactureOther) {
    this.#manufactureOther = manufactureOther;
  }

  get() {
    return this.#manufactureOther
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
