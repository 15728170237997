
export class StatusEn {
    #statusEn

    constructor(statusEn) {
        this.#statusEn = statusEn;
    }

    get() {
        return this.#statusEn
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
