
export class TransRightEstateDescription {
  #transRightEstateDescription

  constructor(transRightEstateDescription) {
    this.#transRightEstateDescription = transRightEstateDescription;
  }

  get() {
    return this.#transRightEstateDescription
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
