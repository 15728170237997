import BannerProvider from './BannerProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { banner } from "../entities/componentMapping.js";

const Banner = ({ type }) => {
  return (
    <BannerProvider type={type}>
      {getComponent(banner, type)}
    </BannerProvider>
  );
};

export default Banner;
