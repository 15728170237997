// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import { AplCountAuctionDisplay } from './AuctionEntries/aplCountAuctionDisplay';
import { AplCountBidDisplay } from './AuctionEntries/aplCountBidDisplay';
import { Area } from './AuctionEntries/area';
import { AuctionId } from './AuctionEntries/auctionId';
import { BidCommitDate } from './AuctionEntries/bidCommitDate';
import { BidCountDisplay } from './AuctionEntries/bidCountDisplay';
import { BidEndButtonDownAuctionDate } from './AuctionEntries/bidEndButtonDownAuctionDate';
import { BidEndButtonDownBidDate } from './AuctionEntries/bidEndButtonDownBidDate';
import { BidEndType } from './AuctionEntries/bidEndType';
import { BillingDate } from './AuctionEntries/billingDate';
import { CallUpAuctionStatus } from './AuctionEntries/callUpAuctionStatus';
import { CallUpBidStatus } from './AuctionEntries/callUpBidStatus';
import { CallUpButtonDownAuctionDate } from './AuctionEntries/callUpButtonDownAuctionDate';
import { CallUpButtonDownBidDate } from './AuctionEntries/callUpButtonDownBidDate';
import { CarBidSystemFee } from './AuctionEntries/carBidSystemFee';
import { CarBidSystemFeeRate } from './AuctionEntries/carBidSystemFeeRate';
import { CommonDescription } from './AuctionEntries/commonDescription';
import { ContactEmail } from './AuctionEntries/contactEmail';
import { ContactOffice } from './AuctionEntries/contactOffice';
import { ContactTel } from './AuctionEntries/contactTel';
import { EstateBidSystemFee } from './AuctionEntries/estateBidSystemFee';
import { EstateBidSystemFeeRate } from './AuctionEntries/estateBidSystemFeeRate';
import { EstimatedSystemUsagePaymentDate } from './AuctionEntries/estimatedSystemUsagePaymentDate';
import { GovernmentId } from './AuctionEntries/governmentId';
import { Id } from './AuctionEntries/id';
import { InvoiceOutputDate } from './AuctionEntries/invoiceOutputDate';
import { MailCertDocument } from './AuctionEntries/mailCertDocument';
import { MailReductionDocument } from './AuctionEntries/mailReductionDocument';
import { ManagementRegistrationStatus } from './AuctionEntries/managementRegistrationStatus';
import { Name } from './AuctionEntries/name';
import { NoneDeposit } from './AuctionEntries/noneDeposit';
import { OpenButtonDownDate } from './AuctionEntries/openButtonDownDate';
import { ParticipationStatus } from './AuctionEntries/participationStatus';
import { PaymentBankTransfer } from './AuctionEntries/paymentBankTransfer';
import { PaymentCreditCard } from './AuctionEntries/paymentCreditCard';
import { PaymentDeadline } from './AuctionEntries/paymentDeadline';
import { PropertyBidSystemFee } from './AuctionEntries/propertyBidSystemFee';
import { PropertyBidSystemFeeRate } from './AuctionEntries/propertyBidSystemFeeRate';
import { SellRealEstate } from './AuctionEntries/sellRealEstate';
import { SystemUsageFeeAuction } from './AuctionEntries/systemUsageFeeAuction';
import { SystemUsageFeeBid } from './AuctionEntries/systemUsageFeeBid';
import { SystemUsagePaymentDate } from './AuctionEntries/systemUsagePaymentDate';
import { SystemUsageTax } from './AuctionEntries/systemUsageTax';
import { SystemUsageTaxAuction } from './AuctionEntries/systemUsageTaxAuction';
import { SystemUsageTaxBid } from './AuctionEntries/systemUsageTaxBid';
import { TaxId } from './AuctionEntries/taxId';
import { TaxType } from './AuctionEntries/taxType';
import CustomError from '../../../shared/utils/helper/customError.js';
import { NotUndefined } from '../../../shared/utils/helper/NotUndefined.js';

export class InvitationGet {
  constructor(data) {
    this.aplCountAuctionDisplay = data.aplCountAuctionDisplay;
    this.aplCountBidDisplay = data.aplCountBidDisplay;
    this.area = data.area;
    this.auctionId = data.auctionId;
    this.bidCommitDate = data.bidCommitDate;
    this.bidCountDisplay = data.bidCountDisplay;
    this.bidEndButtonDownAuctionDate = data.bidEndButtonDownAuctionDate;
    this.bidEndButtonDownBidDate = data.bidEndButtonDownBidDate;
    this.bidEndType = data.bidEndType;
    this.billingDate = data.billingDate;
    this.callUpAuctionStatus = data.callUpAuctionStatus;
    this.callUpBidStatus = data.callUpBidStatus;
    this.callUpButtonDownAuctionDate = data.callUpButtonDownAuctionDate;
    this.callUpButtonDownBidDate = data.callUpButtonDownBidDate;
    this.carBidSystemFee = data.carBidSystemFee;
    this.carBidSystemFeeRate = data.carBidSystemFeeRate;
    this.commonDescription = data.commonDescription;
    this.contactEmail = data.contactEmail;
    this.contactOffice = data.contactOffice;
    this.contactTel = data.contactTel;
    this.estateBidSystemFee = data.estateBidSystemFee;
    this.estateBidSystemFeeRate = data.estateBidSystemFeeRate;
    this.estimatedSystemUsagePaymentDate = data.estimatedSystemUsagePaymentDate;
    this.governmentId = data.governmentId;
    this.id = data.id;
    this.invoiceOutputDate = data.invoiceOutputDate;
    this.mailCertDocument = data.mailCertDocument;
    this.mailReductionDocument = data.mailReductionDocument;
    this.managementRegistrationStatus = data.managementRegistrationStatus;
    this.name = data.name;
    this.noneDeposit = data.noneDeposit;
    this.openButtonDownDate = data.openButtonDownDate;
    this.participationStatus = data.participationStatus;
    this.paymentBankTransfer = data.paymentBankTransfer;
    this.paymentCreditCard = data.paymentCreditCard;
    this.paymentDeadline = data.paymentDeadline;
    this.propertyBidSystemFee = data.propertyBidSystemFee;
    this.propertyBidSystemFeeRate = data.propertyBidSystemFeeRate;
    this.sellRealEstate = data.sellRealEstate;
    this.systemUsageFeeAuction = data.systemUsageFeeAuction;
    this.systemUsageFeeBid = data.systemUsageFeeBid;
    this.systemUsagePaymentDate = data.systemUsagePaymentDate;
    this.systemUsageTax = data.systemUsageTax;
    this.systemUsageTaxAuction = data.systemUsageTaxAuction;
    this.systemUsageTaxBid = data.systemUsageTaxBid;
    this.taxId = data.taxId;
    this.taxType = data.taxType;
    // if (this.isValid) throw new CustomError("Invitation の値が不正です")
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class InvitationCreate {
  constructor(data) {
    this.aplCountAuctionDisplay = new AplCountAuctionDisplay(
      data.aplCountAuctionDisplay,
    );
    this.aplCountBidDisplay = new AplCountBidDisplay(data.aplCountBidDisplay);
    this.area = new Area(data.area);
    this.auctionId = new AuctionId(data.auctionId);
    this.bidCommitDate = new BidCommitDate(data.bidCommitDate);
    this.bidCountDisplay = new BidCountDisplay(data.bidCountDisplay);
    this.bidEndButtonDownAuctionDate = new BidEndButtonDownAuctionDate(
      data.bidEndButtonDownAuctionDate,
    );
    this.bidEndButtonDownBidDate = new BidEndButtonDownBidDate(
      data.bidEndButtonDownBidDate,
    );
    this.bidEndType = new BidEndType(data.bidEndType);
    this.billingDate = new BillingDate(data.billingDate);
    this.callUpAuctionStatus = new CallUpAuctionStatus(
      data.callUpAuctionStatus,
    );
    this.callUpBidStatus = new CallUpBidStatus(data.callUpBidStatus);
    this.callUpButtonDownAuctionDate = new CallUpButtonDownAuctionDate(
      data.callUpButtonDownAuctionDate,
    );
    this.callUpButtonDownBidDate = new CallUpButtonDownBidDate(
      data.callUpButtonDownBidDate,
    );
    this.carBidSystemFee = new CarBidSystemFee(data.carBidSystemFee);
    this.carBidSystemFeeRate = new CarBidSystemFeeRate(
      data.carBidSystemFeeRate,
    );
    this.commonDescription = new CommonDescription(data.commonDescription);
    this.contactEmail = new ContactEmail(data.contactEmail);
    this.contactOffice = new ContactOffice(data.contactOffice);
    this.contactTel = new ContactTel(data.contactTel);
    this.estateBidSystemFee = new EstateBidSystemFee(data.estateBidSystemFee);
    this.estateBidSystemFeeRate = new EstateBidSystemFeeRate(
      data.estateBidSystemFeeRate,
    );
    this.estimatedSystemUsagePaymentDate = new EstimatedSystemUsagePaymentDate(
      data.estimatedSystemUsagePaymentDate,
    );
    this.governmentId = new GovernmentId(data.governmentId);
    this.id = new Id(data.id);
    this.invoiceOutputDate = new InvoiceOutputDate(data.invoiceOutputDate);
    this.mailCertDocument = new MailCertDocument(data.mailCertDocument);
    this.mailReductionDocument = new MailReductionDocument(
      data.mailReductionDocument,
    );
    this.managementRegistrationStatus = new ManagementRegistrationStatus(
      data.managementRegistrationStatus,
    );
    this.name = new Name(data.name);
    this.noneDeposit = new NoneDeposit(data.noneDeposit);
    this.openButtonDownDate = new OpenButtonDownDate(data.openButtonDownDate);
    this.participationStatus = new ParticipationStatus(
      data.participationStatus,
    );
    this.paymentBankTransfer = new PaymentBankTransfer(
      data.paymentBankTransfer,
    );
    this.paymentCreditCard = new PaymentCreditCard(data.paymentCreditCard);
    this.paymentDeadline = new PaymentDeadline(data.paymentDeadline);
    this.propertyBidSystemFee = new PropertyBidSystemFee(
      data.propertyBidSystemFee,
    );
    this.propertyBidSystemFeeRate = new PropertyBidSystemFeeRate(
      data.propertyBidSystemFeeRate,
    );
    this.sellRealEstate = new SellRealEstate(data.sellRealEstate);
    this.systemUsageFeeAuction = new SystemUsageFeeAuction(
      data.systemUsageFeeAuction,
    );
    this.systemUsageFeeBid = new SystemUsageFeeBid(data.systemUsageFeeBid);
    this.systemUsagePaymentDate = new SystemUsagePaymentDate(
      data.systemUsagePaymentDate,
    );
    this.systemUsageTax = new SystemUsageTax(data.systemUsageTax);
    this.systemUsageTaxAuction = new SystemUsageTaxAuction(
      data.systemUsageTaxAuction,
    );
    this.systemUsageTaxBid = new SystemUsageTaxBid(data.systemUsageTaxBid);
    this.taxId = new TaxId(data.taxId);
    this.taxType = new TaxType(data.taxType);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.aplCountAuctionDisplay.isValid &&
      this.aplCountBidDisplay.isValid &&
      this.area.isValid &&
      this.auctionId.isValid &&
      this.bidCommitDate.isValid &&
      this.bidCountDisplay.isValid &&
      this.bidEndButtonDownAuctionDate.isValid &&
      this.bidEndButtonDownBidDate.isValid &&
      this.bidEndType.isValid &&
      this.billingDate.isValid &&
      this.callUpAuctionStatus.isValid &&
      this.callUpBidStatus.isValid &&
      this.callUpButtonDownAuctionDate.isValid &&
      this.callUpButtonDownBidDate.isValid &&
      this.carBidSystemFee.isValid &&
      this.carBidSystemFeeRate.isValid &&
      this.commonDescription.isValid &&
      this.contactEmail.isValid &&
      this.contactOffice.isValid &&
      this.contactTel.isValid &&
      this.estateBidSystemFee.isValid &&
      this.estateBidSystemFeeRate.isValid &&
      this.estimatedSystemUsagePaymentDate.isValid &&
      this.governmentId.isValid &&
      this.invoiceOutputDate.isValid &&
      this.mailCertDocument.isValid &&
      this.mailReductionDocument.isValid &&
      this.managementRegistrationStatus.isValid &&
      this.name.isValid &&
      this.noneDeposit.isValid &&
      this.openButtonDownDate.isValid &&
      this.participationStatus.isValid &&
      this.paymentBankTransfer.isValid &&
      this.paymentCreditCard.isValid &&
      this.paymentDeadline.isValid &&
      this.propertyBidSystemFee.isValid &&
      this.propertyBidSystemFeeRate.isValid &&
      this.sellRealEstate.isValid &&
      this.systemUsageFeeAuction.isValid &&
      this.systemUsageFeeBid.isValid &&
      this.systemUsagePaymentDate.isValid &&
      this.systemUsageTax.isValid &&
      this.systemUsageTaxAuction.isValid &&
      this.systemUsageTaxBid.isValid &&
      this.taxId.isValid &&
      this.taxType.isValid
    );
  }
}

export class CommonDescriptionCreate {
  constructor(data) {
    this.auctionId = new AuctionId(data.auctionId);
    this.commonDescription = new CommonDescription(data.commonDescription);
    this.governmentId = new GovernmentId(data.governmentId);
    // todo: 確認これいる
    //this.invitationId = new InvitationId(data.id);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.invitationId.isValid &&
      this.auctionId.isValid &&
      this.commonDescription.isValid &&
      this.governmentId.isValid
    );
  }
}
