// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createNaviPublicSaleCautionPagesData,
  updateNaviPublicSaleCautionPagesData,
  deleteNaviPublicSaleCautionPagesData,
} from '../../../infrastructure/api/NaviPublicSaleCautionPages.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  PUBLIC_SALE,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { SettingPublicCautionPageCreate } from '../entities/Setting.js';
import { getCaution } from '../repositories/Caution.js';

export async function get(type) {
  return await getCaution(type);
}

export async function findByGovernmentId(type, governmentId) {
  const PublicSaleCaution = await getCaution(type, PUBLIC_SALE);
  return PublicSaleCaution.filter(
    (data) => data.governmentId.toString() === governmentId.toString(),
  );
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (PublicSaleCaution)');
  }
  if (type === NAVI) {
    const publicSaleCaution = new SettingPublicCautionPageCreate(data);
    response = await createNaviPublicSaleCautionPagesData(publicSaleCaution);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (PublicSaleCaution)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create PublicSaleCaution', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (PublicSaleCaution)');
  }
  if (type === NAVI) {
    const publicSaleCaution = new SettingPublicCautionPageCreate(data);
    response = await updateNaviPublicSaleCautionPagesData(publicSaleCaution);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (PublicSaleCaution)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update PublicSaleCaution', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (PublicSaleCaution)');
  }
  if (type === NAVI) {
    response = await deleteNaviPublicSaleCautionPagesData(data.id);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (PublicSaleCaution)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete PublicSaleCaution', response);
  }
  return response;
}

// function createPublicSaleCautionArray(data) {
//   let settings = []
//   data.forEach((setting) => {
//     settings.push(new PublicSaleCaution(setting))
//   })
//   return settings
// }

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
