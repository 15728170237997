
export class DisplayOrder {
  #displayOrder

  constructor(displayOrder) {
    this.#displayOrder = displayOrder;
  }

  get() {
    return this.#displayOrder
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
