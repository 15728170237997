import { useState, useEffect } from 'react';
import { editLockStatus } from 'domain/Property/entities/constants/isDeliveryLock';

import EditDeliveryRegistrationInfo from './EditDeliveryRegistrationInfo';
import ReadDeliveryRegistrationInfo from './ReadDeliveryRegistrationInfo';

const DeliveryRegistrationInfoCaption = ({ property }) => {
  const [isDeliveryLock, setIsDeliveryLock] = useState(editLockStatus.LOCK);

  const today = new Date();
  let todayYear = today.getFullYear();
  let todayMonth = today.getMonth() + 1;
  let todayDate = today.getDate();
  let todayHour = today.getHours();
  let todayMinute = today.getMinutes();
  let todayString = `${todayYear}-${('00' + todayMonth).slice(-2)}-${('00' + todayDate).slice(-2)} ${('00' + todayHour).slice(-2)}:${('00' + todayMinute).slice(-2)}`;

  useEffect(() => {
    if (property.paymentDeadline > todayString) {
      setIsDeliveryLock(editLockStatus.UNLOCK);
    }
  }, [property]);

  if (isDeliveryLock === editLockStatus.LOCK) {
    return <ReadDeliveryRegistrationInfo property={property} />;
  }

  // 編集できる条件
  //   invitations.payment_deadlineを超えていない
  //   properties.delivery_typeが登録されている
  if (isDeliveryLock === editLockStatus.UNLOCK) {
    return <EditDeliveryRegistrationInfo property={property} />;
  }

  return <></>;
};

export default DeliveryRegistrationInfoCaption;
