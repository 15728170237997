import KmanagerTableColumn from 'interfaces/components/common/Table/KmanagerTableColumn';

import GovernmentListTableRecord from './GovernmentListTableRecord';
import 'interfaces/css/common/kmanager-table.css';

const GovernmentListTable = ({ data, headers, requestSort, sortConfig }) => {
  return (
    <div className="kmanager-table-wrap">
      <table
        className="kmanager-table kmanager-table-hover"
        data-testid="kmanager-table">
        <KmanagerTableColumn
          headers={headers}
          requestSort={requestSort}
          sortConfig={sortConfig}
        />
        <tbody>
          {data.map((item, count) => (
            <GovernmentListTableRecord
              key={count}
              id={item.id}
              data={item}
              headers={headers}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GovernmentListTable;
