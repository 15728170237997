// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kmanager-menu-link {
  min-height: 48px;
  margin: 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.kmanager-menu-link:hover {
  background-color: #00000010;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/kmanager-menu-link.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".kmanager-menu-link {\n  min-height: 48px;\n  margin: 10px 0;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.kmanager-menu-link:hover {\n  background-color: #00000010;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
