import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class TransRightCarDescription {
  #transRightCarDescription

  constructor(transRightCarDescription) {
    this.#transRightCarDescription = transRightCarDescription;
  }

  get() {
    return this.#transRightCarDescription
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#transRightCarDescription, 0, 50)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#transRightCarDescription)
  }
}
