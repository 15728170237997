
export class ContractDeadline {
    #contractDeadline

    constructor(contractDeadline) {
        this.#contractDeadline = contractDeadline;
    }

    get() {
        return this.#contractDeadline
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
