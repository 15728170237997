import { BASE_URL_SERVICE } from "../../../config/envConstants.js";
import { fetchData } from "../../../shared/utils/helper/fetchData.js";

const getArrayFilteredByIds = (Array, ids) => Array.filter(id => ids.includes(id));

const isLoginUser = (userId) => {
  return Boolean(userId);
};

const getJSONPaths = (userId) => {
  if (isLoginUser(userId)) {
    // ログイン済みユーザに表示するJSONファイル
    return {
      current: {
        allowPropertyIds: `${BASE_URL_SERVICE}/storage/current/users/user${userId}/allow_property_ids.json`,
        generalCategories: `${BASE_URL_SERVICE}/storage/current/categories/general.json`
      },
      previous: {
        allowPropertyIds: `${BASE_URL_SERVICE}/storage/previous/users/user${userId}/allow_property_ids.json`,
        generalCategories: `${BASE_URL_SERVICE}/storage/previous/categories/general.json`
      }
    }
  } else {
    // 非ログインユーザに表示するJSONファイル
    return {
      current: {
        allowPropertyIds: `${BASE_URL_SERVICE}/storage/current/users/unauthorized_user/allow_property_ids.json`,
        generalCategories: `${BASE_URL_SERVICE}/storage/current/categories/general.json`
      },
      previous: {
        allowPropertyIds: `${BASE_URL_SERVICE}/storage/previous/users/unauthorized_user/allow_property_ids.json`,
        generalCategories: `${BASE_URL_SERVICE}/storage/previous/categories/general.json`
      }
    };
  }
}

const fetchGeneralCategoriesStorage = async (userId) => {
  const paths = getJSONPaths(userId);
  try {
    const generalCategories = await fetchData(paths.current.generalCategories);
    const allowPropertyIds = await fetchData(paths.current.allowPropertyIds);

    generalCategories.forEach(parentCategories => {
      let childPropertyIds = [];
      parentCategories.childCategory.forEach(childCategories => {
        let grandChildPropertyIds = [];
        childCategories.childCategory.forEach(grandChildCategories => {
          grandChildCategories.propertyIds = getArrayFilteredByIds(grandChildCategories.propertyIds, allowPropertyIds.ids);
          grandChildPropertyIds.push(...grandChildCategories.propertyIds);
        })
        childCategories.propertyIds = Array.from(new Set(grandChildPropertyIds));
        childPropertyIds.push(...childCategories.propertyIds);
      })
      parentCategories.propertyIds = Array.from(new Set(childPropertyIds));
    })

    return generalCategories;
  } catch (error) {
    console.warn(`Failed to fetch current general categories: ${error.message}`);
    try {
      const generalCategories = await fetchData(paths.previous.generalCategories);
      const allowPropertyIds = await fetchData(paths.previous.allowPropertyIds);

      generalCategories.forEach(parentCategories => {
        let childPropertyIds = [];
        parentCategories.childCategory.forEach(childCategories => {
          let grandChildPropertyIds = [];
          childCategories.childCategory.forEach(grandChildCategories => {
            grandChildCategories.propertyIds = getArrayFilteredByIds(grandChildCategories.propertyIds, allowPropertyIds.ids);
            grandChildPropertyIds.push(...grandChildCategories.propertyIds);
          })
          childCategories.propertyIds = Array.from(new Set(grandChildPropertyIds));
          childPropertyIds.push(...childCategories.propertyIds);
        })
        parentCategories.propertyIds = Array.from(new Set(childPropertyIds));
      })

      return generalCategories;
    } catch (error) {
      console.warn(`Failed to fetch previous general categories: ${error.message}`);
      return [];
    }
  }
}

export default fetchGeneralCategoriesStorage;