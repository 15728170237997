// ロジックを含む処理
// フロントから呼ぶ関数

import { compareData } from '../../../shared/utils/helper/compareData.js';
import { getPromotionProperty } from '../repositories/PromotionProperty.js';

export async function get(type) {
  return await getPromotionProperty(type);
}

export async function findById(type, id) {
  const Promotion = await getPromotionProperty(type);
  return Promotion.find((data) => data.id === id);
}

export async function findByFrameIds(type, ids) {
  const promotionProperties = await getPromotionProperty(type);
  return promotionProperties.filter((data) =>
    ids.includes(data.promotionFrameId),
  );
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
