import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class BuildName {
  #buildName

  constructor(buildName) {
    this.#buildName = buildName;
  }

  get() {
    return this.#buildName
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#buildName, 0, 100)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#buildName)
  }
}
