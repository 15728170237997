import { validateSelected } from "../../../../application/validations.js";

export class DisplayProcedureType {
  #displayProcedureType

  constructor(displayProcedureType) {
    this.#displayProcedureType = displayProcedureType;
  }

  get() {
    return this.#displayProcedureType
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#displayProcedureType)
  }
}
