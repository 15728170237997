import { manual } from 'interfaces/pages/Manual/entities/componentMapping';
import ManualProvider from 'interfaces/pages/Manual/services/ManualProvider';
import { getComponent } from 'shared/utils/helper/get_component';

const Manual = ({ type }) => {
  return (
    <ManualProvider type={type}>{getComponent(manual, type)}</ManualProvider>
  );
};

export default Manual;
