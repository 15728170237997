import Button from 'interfaces/components/common/Button';
import ButtonList from 'interfaces/components/common/ButtonList';
import Pagination from 'interfaces/components/common/Pagination';
import { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuctionGovernmentTableColumn from './AuctionGovernmentTableColumn';
//import KmanagerAuctionTableRecord from "./KmanagerAuctionTableRecord";

import 'interfaces/css/Dashboard/kmanager-auction-table.css';
import AuctionGovernmentTableRecord from './AuctionGovernmentTableRecord';
import { AuctionGovernmentContext } from '../../../../pages/AuctionGovernment/entities/AuctionGovernmentContext';
import AuctionGovernmentInvitationModal from './AuctionGovernmentInvitationModal';
import AuctionGovernmentNationalTaxAgencyInvitationModal from './AuctionGovernmentNationalTaxAgencyInvitationModal';
import usePagination from 'shared/hooks/usePagination';
import { useSortableData } from 'shared/hooks/useSort.js';
import { GovernmentTypes } from 'shared/utils/constants/government';

const AuctionGovernmentTable = ({ auction, governments }) => {
  const navigate = useNavigate();

  const { invitations } = useContext(AuctionGovernmentContext);

  const [isOpen, setIsOpen] = useState(false);
  const [invitation, setInvitation] = useState(undefined);
  const [government, setGovernment] = useState(undefined);

  const {
    items: sortedGovernments,
    requestSort,
    sortConfig,
  } = useSortableData(governments);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(sortedGovernments, rowsPerPage);

  const [visibleRow, setVisibleRow] = useState(null);

  const toggleRowVisibility = (id) => {
    setVisibleRow((prev) => (prev === id ? null : id));
  };
  // TODO: 設定状況確認、行政機関管理、公売(入金)保証金管理、システム利用料管理,統計出力の画面遷移をするように実装する
  function property(governmentId) {
    navigate(`/kmanager/auction/${auction.id}/${governmentId}/property/`);
  }

  function deposit(governmentId) {
    navigate(`/kmanager/auction/${auction.id}/${governmentId}/deposit`);
  }

  function payment(governmentId) {
    navigate(`/kmanager/auction/${auction.id}/${governmentId}/payment`);
  }

  const BUTTONS_DATA = [
    {
      text: '物件一覧',
      clickHandler: (government) => property(government.id),
      isOutline: true,
      className: 'small primary',
    },
    {
      text: '公売保証金',
      clickHandler: (government) => deposit(government.id),
      isOutline: true,
      className: 'small primary',
    },
    {
      text: '請求管理',
      clickHandler: (government) => payment(government.id),
      isOutline: true,
      className: 'small primary',
    },
    {
      text: '認証書面（保証契約書）',
      clickHandler: () => {},
      isOutline: true,
      className: 'small primary',
    },
    {
      text:
        auction.governmentTypeGroup ===
        GovernmentTypes.NATIONAL_TAX_AGENCY.string
          ? '参加申込内容確認・編集'
          : '参加申込内容確認',
      clickHandler: (government) => {
        let invitation = invitations.find(
          (tmp) => tmp.governmentId.toString() === government.id.toString(),
        );
        setGovernment(government);
        setInvitation(invitation);
        setIsOpen(true);
      },
      isOutline: true,
      className: 'small primary',
    },
  ];

  return (
    <div>
      {isOpen &&
        (auction.governmentTypeGroup ===
        GovernmentTypes.NATIONAL_TAX_AGENCY.string ? (
          <AuctionGovernmentNationalTaxAgencyInvitationModal
          isStartApply={new Date(auction.aplStart) < new Date()}
            invitation={invitation}
            government={government}
            handleClose={() => setIsOpen(false)}
          />
        ) : (
          <AuctionGovernmentInvitationModal
            invitation={invitation}
            government={government}
            handleClose={() => setIsOpen(false)}
          />
        ))}
      <div className="kmanager-table-wrap">
        <table
          className="kmanager-table kmanager-table-hover"
          data-testid="kmanager-auction-table">
          <AuctionGovernmentTableColumn
            auction={auction}
            requestSort={requestSort}
            sortConfig={sortConfig}
          />
          <tbody>
            {currentItems &&
              currentItems.map((government) => (
                <Fragment key={auction.id}>
                  <AuctionGovernmentTableRecord
                    auction={auction}
                    government={government}
                    onClick={() => toggleRowVisibility(government.id)}
                    isVisibleRow={visibleRow === government.id}
                  />
                  {visibleRow === government.id && (
                    <tr className="kmanager-auction-table-record kmanager-table-hover_operation-buttons">
                      <td colSpan="11">
                        <ButtonList>
                          <div className="d-flex">
                            {BUTTONS_DATA.map((item, index) => (
                              <Button
                                key={index}
                                text={item.text}
                                clickHandler={() =>
                                  item.clickHandler(government)
                                }
                                isOutline={item.isOutline}
                                isDisabled={false}
                                className={item.className}
                              />
                            ))}
                          </div>
                        </ButtonList>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        DATA_COUNT={governments.length}
      />
    </div>
  );
};

export default AuctionGovernmentTable;
