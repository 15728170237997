import Button from 'interfaces/components/common/Button';
import ConfirmSection from 'interfaces/components/common/ConfirmSection';
import {
  OrganizationInfo,
  CreditInfo,
  RefundInfo,
} from 'interfaces/components/Division/OrganizationRegisterFormSet';
import { breadcrumbs } from 'interfaces/pages/OrganizationEdit/entities/confirm/breadcrumbs';
import { useContext } from 'react';

import { OrganizationEditContext } from '../../../../pages/OrganizationEdit/entities/OrganizationEditContext';
import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Heading2 from 'interfaces/components/common/Heading/Heading2';
import Heading3 from 'interfaces/components/common/Heading/Heading3';

const OrganizationEditConfirm = ({
  formData,
  governmentType,
  handleComplete,
  handleBackForm,
}) => {
  const { procedureType } = useContext(OrganizationEditContext);
  const organizationInfo = OrganizationInfo(formData.data);
  const creditInfo = CreditInfo(formData.data);
  const refundInfo = RefundInfo(formData.data);

  return (
    <div
      className="new-style_navi_organization-edit-confirm"
      data-testid="organization-confirm">
      <Breadcrumb breadcrumbs={breadcrumbs(formData.data.divisionName)} />
      <Heading2
        title={'執行機関管理：' + formData.data.divisionName + '：更新内容確認'}
      />
      <div className="pt-3">
        更新する執行機関を確認してください。このまま更新する場合は「更新」ボタン、再度編集する場合は「編集」ボタンをクリックしてください。
      </div>
      <div className="new-style_navi_organization-edit-confirm_pt-9 pt-9">
        <ConfirmSection inputsData={organizationInfo} />
        {formData.data.useCreditCard.toString() === 'NO' ? (
          ''
        ) : (
          <>
            <Heading3 text={'1. 保証金納付口座'} />
            <ConfirmSection inputsData={creditInfo} />
            {procedureType === 'PUBLIC_SALE' &&
            governmentType !== 'NATIONAL_TAX_AGENCY' ? (
              <>
                <Heading3 text={'2. 返還請求書送付先'} />
                <div>
                  {' '}
                  ※
                  KSIより貴庁に不要となった保証金の返還請求書を郵送させていただくための送付先を設定してください。{' '}
                </div>
                <ConfirmSection inputsData={refundInfo} />
              </>
            ) : (
              ''
            )}
          </>
        )}
      </div>
      <div className="pt-9 property-info-general-form-button-box">
        <div className="pr-1 pl-1">
          <Button
            text="更新"
            clickHandler={handleComplete}
            className="property-info-general-form-button new-style_navi_primary-button"
          />
        </div>
        <div className="pr-1 pl-1">
          <Button
            text="編集"
            clickHandler={handleBackForm}
            className="property-info-general-form-button new-style_navi_normal-button"
          />
        </div>
      </div>
    </div>
  );
};
export default OrganizationEditConfirm;
