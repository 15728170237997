import pino from "pino";

import { BASE_URL_NAVIGATION } from "../../../config/envConstants.js";
import { extractData } from "../../../shared/utils/helper/extractData.js";
import { fetchData } from "../../../shared/utils/helper/fetchData.js";

const logger = pino();

const CURRENT_NOTICES = `${BASE_URL_NAVIGATION}/storage/current/notices/data.json`;
const PREVIOUS_NOTICES = `${BASE_URL_NAVIGATION}/storage/previous/notices/data.json`;

export const fetchNotices = async () => {
  try {
    const response = await fetchData(CURRENT_NOTICES);
    return extractData(response);
  } catch (error) {
    logger.warn(`現在の通知（ notices ）を取得できませんでした ${error.message}`);
    try {
      const response = await fetchData(PREVIOUS_NOTICES);
      return extractData(response);
    } catch (error) {
      logger.warn(`以前の通知（ notices ）を取得できませんでした ${error.message}`);
      return [];
    }
  }
};
