import RadioButton from "interfaces/components/common/Button/RadioButton";
import { exhibit_radio } from "interfaces/components/common/RadioLabel";

import CautionDescription from "./CautionDescription";
import Label from "./Label";

import "interfaces/css/common/property-info-general-form-radio.css";

const FormRadioWithCaution = ({
  caution_description,
  label_class_name = '',
  class_name,
  data = "",
  exhibitData = "",
  handler1 = () => {},
  handler2 = () => {},
  label,
  procedureType,
  radio_label,
  required = true,
  isEstate = false,
}) => {
  const handleChange = (event) => {
    handler1(event);
  };

  const Exhibit = () => {
    if (data === "BID") {
      const handleChangeExhibit = (event) => {
        handler2(event);
      };
      return (
        <div className="property-info-general-form-table-row">
          <Label required={true} label={"次順位買受申込者の決定"} className={label_class_name} />
          <div className="property-info-general-form-radio-table-button">
            <div
              className={
                class_name
                  ? "property-info-general-form-radio-" + class_name
                  : "flex"
              }
            >
              <RadioButton
                isText={exhibitData}
                data={exhibit_radio}
                handleChange={handleChangeExhibit}
              />
            </div>
            <CautionDescription data={caution_description} />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="property-info-general-form-table-row">
        <Label required={required} label={label} className={label_class_name} />
        <div className="property-info-general-form-radio-table-button">
          <div
            className={
              class_name
                ? "property-info-general-form-radio-" + class_name
                : "flex"
            }
          >
            <RadioButton
              isText={data}
              data={radio_label}
              handleChange={handleChange}
              isEstate={isEstate}
            />
          </div>
          <CautionDescription data={caution_description} />
        </div>
      </div>
      {procedureType === "インターネット公売" ? <Exhibit /> : ""}
    </>
  );
};
export default FormRadioWithCaution;
