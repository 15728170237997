import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class LandCategory {
  #landCategory

  constructor(landCategory) {
    this.#landCategory = landCategory;
  }

  get() {
    return this.#landCategory
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#landCategory, 0, 30)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#landCategory)
  }
}
