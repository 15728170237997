import { DATA_EMPTY } from 'domain/Property/entities/constants/property.js';

import Button from 'interfaces/components/common/Button/Button';
import { useEffect } from 'react';
import { useError } from 'shared/hooks/useError.js';

import ShippingInfos from './ShippingInfos';
import { OTHER } from '../../../../shared/utils/constants/carrier.js';

import styled from 'styled-components';
const CautionBlock = styled.div`
  border: 3px solid #ff0000;
  background-color: #ffffff;
  padding: 10px;
  margin: 5px;
`;

const ConfirmShippingInfo = ({
  previousStep,
  nextStep,
  shippingInfo,
  formattedInfo,
  setFormattedInfo,
}) => {
  const { setError } = useError();

  useEffect(() => {
    const carrier = shippingInfo.carrier;
    const address = shippingInfo.address;
    const paymentData = shippingInfo.paymentData;
    const deliverySchedule = shippingInfo.deliverySchedule;

    console.log(shippingInfo);

    setFormattedInfo({
      carrierNameList: carrier.target,
      carrierName:
        carrier.target === OTHER.en ? carrier.carrierName : DATA_EMPTY,
      carrierTel: carrier.target === OTHER.en ? carrier.carrierTel : DATA_EMPTY,
      arrangement: shippingInfo.targetArrangement,
      packing: shippingInfo.targetPackingMethod,
      receiver: address.target,
      receiverName:
        address.target === 'OTHER'
          ? address.receiver.name.name
          : address.applicant.name,
      receiverRole:
        address.target === 'OTHER' ? address.receiver.name.role : 'CLIENT',
      zipCode:
        address.target === 'OTHER'
          ? `${address.receiver.postalCode1}-${address.receiver.postalCode2}`
          : address.applicant.postalCode,
      receiverGovernmentSubCategoryId:
        address.target === 'OTHER'
          ? address.receiver.prefectureId
          : address.applicant.prefectureId,
      receiverAddress:
        address.target === 'OTHER'
          ? address.receiver.address
          : address.applicant.address,
      receiverTel:
        address.target === 'OTHER'
          ? address.receiver.tel
          : address.applicant.houseTel || address.applicant.mobileTel,
      deliveryReason:
        address.target === 'OTHER'
          ? address.receiver.deliveryReason
          : DATA_EMPTY,
      isPaid: paymentData.target,
      paymentScheduledDate: paymentData.paymentScheduledDate,
      deliverySchedule: deliverySchedule.target,
      deliveryDate:
        deliverySchedule.target === 'SPECIFY'
          ? deliverySchedule.deliveryDate
          : DATA_EMPTY,
      deliveryTime:
        deliverySchedule.target === 'SPECIFY'
          ? deliverySchedule.deliveryTime
          : DATA_EMPTY,
      deliveryText: shippingInfo.deliveryRequest,
    });
  }, []);

  const handleClick = () => {
    try {
      // TODO: APIを叩く
    } catch (e) {
      setError(e);
    }

    nextStep();
  };

  return (
    <div>
      <CautionBlock>
        入力内容を確認し、正しければ「登録」ボタンを押してください。
        <br />
        内容を修正する場合は、「修正する」ボタンを押してください。
      </CautionBlock>
      <div className="mt-10">
        <ShippingInfos shippingInfo={formattedInfo} />
        <div className="flex justify-center mb-14">
          <Button
            text="登録"
            height="52px"
            width="200px"
            onClick={handleClick}
            className="important-button mr-4"
          />
          <Button
            text="修正する"
            height="52px"
            width="120px"
            onClick={previousStep}
            className="cancel-common-button"
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmShippingInfo;
