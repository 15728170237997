import "interfaces/css/common/heading4.css";

const Heading4 = ({ title }) => {
  return (
    <div className="heading4" data-testid="heading4">
      <h4 className="heading left">{title}</h4>
      <div className="right"></div>
    </div>
  );
}

export default Heading4;