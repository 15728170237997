import Modal2 from 'interfaces/components/common/Modal/Modal2.jsx';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from 'shared/utils/helper/authState.js';

import ForgetPasswordModalContents from './ForgetPassword/ForgetPasswordModalContents.jsx';
import LoginModalContents from './Login/LoginModalContents.jsx';
import SignUpModalContents from './SignUp/SignUpModalContents.jsx';

const AuthModal = ({
  isOpen,
  handleClose,
  currentAuthStatus,
  switchAuthStatus,
}) => {
  const targetChild = () => {
    if (currentAuthStatus === LOGIN) {
      return (
        <LoginModalContents
          switchAuthStatus={switchAuthStatus}
          handleClose={handleClose}
        />
      );
    }
    if (currentAuthStatus === SIGN_UP) {
      return (
        <SignUpModalContents
          switchAuthStatus={switchAuthStatus}
          handleClose={handleClose}
        />
      );
    }
    if (currentAuthStatus === FORGET_PASSWORD) {
      return <ForgetPasswordModalContents />;
    }
  };

  return (
    <Modal2 isOpen={isOpen} handleClose={handleClose} modalSize="S">
      {targetChild()}
    </Modal2>
  );
};

export default AuthModal;
