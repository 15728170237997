import pino from 'pino';

import { BASE_URL_NAVIGATION } from '../../../config/envConstants.js';
import { fetchData } from '../../../shared/utils/helper/fetchData.js';

const logger = pino();
const CURRENT_RECOMMEND_PROPERTIES = `${BASE_URL_NAVIGATION}/storage/current/recommend_properties/data.json`;
const PREVIOUS_RECOMMEND_PROPERTIES = `${BASE_URL_NAVIGATION}/storage/previous/recommend_properties/data.json`;

export const fetchRecommendPropertiesStorage = async () => {
  try {
    const response = await fetchCurrentRecommendProperties();
    return extractRecommendProperties(response);
  } catch (error) {
    logger.warn(
      { error },
      '現在の通知（ recommend_properties ）を取得できませんでした',
    );

    try {
      const response = await fetchPreviousRecommendProperties();
      return extractRecommendProperties(response);
    } catch (error) {
      logger.error(
        { error },
        '以前の通知（ recommend_properties ）の取得にも失敗しました',
      );
      return [];
    }
  }
};

export const fetchCurrentRecommendProperties = async () => {
  return fetchRecommendProperties(CURRENT_RECOMMEND_PROPERTIES);
};

export const fetchPreviousRecommendProperties = async () => {
  return fetchRecommendProperties(PREVIOUS_RECOMMEND_PROPERTIES);
};

export const fetchRecommendProperties = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const extractRecommendProperties = (response) => {
  return response.map((recommend_property) => recommend_property);
};
