
export class Email {
  #email

  constructor(email) {
    this.#email = email;
  }

  get() {
    return this.#email
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
