import { ReactComponent as CheckBoxIcon } from 'interfaces/assets/svg/checkbox_icon.svg';
import { ReactComponent as CheckBoxOutlineBlankIcon } from 'interfaces/assets/svg/checkbox_outline_blank.svg';
import 'interfaces/css/common/checkbox.css';

const ApplicationInfoFormCheckBox = ({ value, label, handleClick }) => {
  return (
    <div
      className={`application-info-form-checkbox ${value ? 'checked' : 'unchecked'}`}
      data-testid="application-info-form-checkbox">
      {value ? (
        <CheckBoxIcon data-testid="checkbox-icon" />
      ) : (
        <CheckBoxOutlineBlankIcon data-testid="checkbox-icon" />
      )}
      <input
        className="application-info-form-checkbox-input"
        type="checkbox"
        value={value}
        onClick={handleClick}
        data-testid="checkbox"
      />
      <label
        className={`application-info-form-label-${value && 'checked'}`}
        data-testid="checkbox-label">
        {label}
      </label>
    </div>
  );
};

export default ApplicationInfoFormCheckBox;
