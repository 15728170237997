const LargeSecondaryButton = ({ text, disabled, onClick }) => {
  return (
    <button
      className={`button button-secondary button-shadow ${disabled ? "button-disabled" : ""}`}
      data-testid="secondary-button"
      disabled={disabled}
      onClick={onClick}
    >
      <span className="button-content">{text}</span>
    </button>
  );
}

export default LargeSecondaryButton;