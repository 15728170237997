import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Category from './LeftSideMenu/Category/Category.jsx';
import FreeSearch from './LeftSideMenu/FreeSearch.jsx';
import PropertyCount from 'interfaces/components/common/LeftSideMenu/PropertyCount';
import RectangleButton from 'interfaces/components/common/LeftSideMenu/RectangleButton';
import { ReactComponent as CarIcon } from '../../../../assets/svg/car_icon.svg';
import { ReactComponent as EstateIcon } from '../../../../assets/svg/estate_icon.svg';
import { DashboardContext } from '../../../../pages/Dashboard/entities/DashboardContext.js';
import { queryParams } from '../../../../pages/Dashboard/entities/queryParam.js';
import TopicCard from '../../../common/TopicCard.jsx';

const LeftSideMenuArea = styled.span`
  width: 200px;
  flex-shrink: 0;
  margin: 0 16px 0 0;

  @media (max-width: 719px) {
    .kservice & {
      margin: 0;
      width: 100%;
    }
  }
`;

const RectangleButtonWrapper = styled.div`
  margin-bottom: 15px;

  @media (max-width: 719px) {
    .kservice & {
      display: flex;
    }
  }
`;

const TopLeftSideMenu = () => {
  const navigation = useNavigate();
  const { propertiesNum } = useContext(DashboardContext);

  return (
    <LeftSideMenuArea data-testid="top-left-side-menu">
      <FreeSearch />
      <PropertyCount count={propertiesNum.propertiesNum} />
      <RectangleButtonWrapper>
        <RectangleButton
          svg={<EstateIcon title="estate" />}
          label="不動産検索"
          count={propertiesNum.estatePropertiesNum}
          onClick={() =>
            navigation(
              `/search?category=ESTATE&${queryParams.QUERY_PARAM_FREE_SEARCH}`,
            )
          }
        />
        <RectangleButton
          svg={<CarIcon title="car" />}
          label="自動車検索"
          count={propertiesNum.carPropertiesNum}
          onClick={() =>
            navigation(
              `/search?category=CAR&${queryParams.QUERY_PARAM_FREE_SEARCH}`,
            )
          }
        />
      </RectangleButtonWrapper>
      <TopicCard
        title="カテゴリから探す"
        body={<Category isOpen={false} showCount={false} />}
      />
      <RectangleButton
        label="行政機関一覧"
        onClick={() => navigation('/gov')}
      />
    </LeftSideMenuArea>
  );
};

export default TopLeftSideMenu;
