
export class AssetSaleAuctionData {
    #assetSaleAuctionData

    constructor(assetSaleAuctionData) {
        this.#assetSaleAuctionData = assetSaleAuctionData;
    }

    get() {
        return this.#assetSaleAuctionData
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
