// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shipping .government-label {
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.shipping .agreement-points-to-note-button {
  box-shadow: none;
  margin-right: 16px;
}

.shipping .links-partition {
  width: 2px;
  background-color: var(--bgcolor-darken2);
  margin: 4px 24px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Shipping/points-to-note-agreement.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,wCAAwC;EACxC,gBAAgB;AAClB","sourcesContent":[".shipping .government-label {\n  font-size: 1.2rem;\n  margin-bottom: 8px;\n}\n\n.shipping .agreement-points-to-note-button {\n  box-shadow: none;\n  margin-right: 16px;\n}\n\n.shipping .links-partition {\n  width: 2px;\n  background-color: var(--bgcolor-darken2);\n  margin: 4px 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
