import DashboardProvider from './DashboardProvider';
import { getComponent } from "../../../../shared/utils/helper/get_component";
import { dashboard } from "../entities/componentMapping.js";

const Dashboard = ({ type }) => {
  return (
    <DashboardProvider type={type}>
      {getComponent(dashboard, type)}
    </DashboardProvider>
  );
};

export default Dashboard;
