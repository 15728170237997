export function getBaseUrl() {
  switch(window.location.hostname) {
    case process.env.REACT_APP_BASE_URL_SERVICE:
      return process.env.REACT_APP_BASE_URL_SERVICE;
    case process.env.REACT_APP_BASE_URL_NAVIGATION:
      return process.env.REACT_APP_BASE_URL_NAVIGATION;
    case process.env.REACT_APP_BASE_URL_OPEMGR:
      return process.env.REACT_APP_BASE_URL_OPEMGR;
    default:
      return process.env.REACT_APP_BASE_URL_SERVICE;
  }
}
