import TableItem from './TableItem.jsx';

const ContactTable = ({ data }) => {
  return (
    <table className="common-table-default">
      <caption>物件に関するお問い合わせ先</caption>
      <tbody>
      {data.map((data) => <TableItem {...data} key={data.id}/>)}
      </tbody>
    </table>
  );
};

export default ContactTable;
