// ロジックを含む処理
// フロントから呼ぶ関数

import { compareData } from '../../../shared/utils/helper/compareData.js';
import { getApplicantInformations } from '../repositories/ApplicantInformation.js';

export async function get(type) {
  return await getApplicantInformations(type);
}

export async function findByPropertyId(type, id) {
  const informations = await getApplicantInformations(type);
  return informations.filter(
    (info) => info.propertyId.toString() === id.toString(),
  );
}

export async function findByPropertyIdAndUserId(type, propertyId, userId) {
  const informations = await getApplicantInformations(type);
  return informations.filter(
    (info) =>
      info?.propertyId?.toString() === propertyId.toString() &&
      info?.auctionUserId?.toString() === userId?.toString(),
  );
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
