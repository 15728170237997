
export class NoneDeposit {
  #noneDeposit

  constructor(noneDeposit) {
    this.#noneDeposit = noneDeposit;
  }

  get() {
    return this.#noneDeposit
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
