import { validateInclude, validateRangeLength } from "../../../../application/validations.js";

export class MapUrl {
  #mapUrl

  constructor(mapUrl) {
    this.#mapUrl = mapUrl;
  }

  get() {
    return this.#mapUrl
  }

  get isValid() {
    return [
      this.validateInclude,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateInclude() {
    return validateInclude(this.#mapUrl, ['https://', 'http://'])
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#mapUrl, 0, 2048)
  }
}
