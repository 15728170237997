import { useState } from 'react';
//import { logInput } from "../../utils/log/logInputs";
import * as Validation from 'shared/utils/helper/naviValidators';

const CardProvisionalApplyCompletionForm = () => {
  const [auctionDocumentSubmissionUrlRequiredValid, setAuctionDocumentSubmissionUrlRequiredValid] = useState(null);
  const [bidDocumentSubmissionUrlRequiredValid, setBidDocumentSubmissionUrlRequiredValid] = useState(null);
  const [bidJointBiddingUrlValid, setBidJointBiddingUrlValid] = useState(null);

  function handleAuctionDocumentSubmissionUrlValid(e, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setAuctionDocumentSubmissionUrlRequiredValid(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateDocumentSubmissionUrlRequiredValidUrl(
        e.target.value,
      );
      if (invalid !== 'TRUE') {
        setAuctionDocumentSubmissionUrlRequiredValid(invalid);
        return;
      }
      invalid = Validation.validateDocumentSubmissionUrlRequiredLength(
        e.target.value,
      );
      if (invalid !== 'TRUE') {
        setAuctionDocumentSubmissionUrlRequiredValid(invalid);
        return;
      }
    }
    setAuctionDocumentSubmissionUrlRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleBidDocumentSubmissionUrlValid(e, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setBidDocumentSubmissionUrlRequiredValid(invalid);
        return;
      }
    }

    if (e.target.value.length > 0) {
      invalid = Validation.validateDocumentSubmissionUrlRequiredValidUrl(
        e.target.value,
      );
      if (invalid !== 'TRUE') {
        setBidDocumentSubmissionUrlRequiredValid(invalid);
        return;
      }
      invalid = Validation.validateDocumentSubmissionUrlRequiredLength(
        e.target.value,
      );
      if (invalid !== 'TRUE') {
        setBidDocumentSubmissionUrlRequiredValid(invalid);
        return;
      }
    }
    setBidDocumentSubmissionUrlRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleBidJointBiddingUrlValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateJointBiddingUrlValidUrl1(e.target.value);
      if (invalid !== 'TRUE') {
        setBidJointBiddingUrlValid(invalid);
        return;
      }
      invalid = Validation.validateJointBiddingUrlLength1(e.target.value);
      if (invalid !== 'TRUE') {
        setBidJointBiddingUrlValid(invalid);
        return;
      }
    }
    setBidJointBiddingUrlValid(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      handleBidJointBiddingUrlValid,
      handleAuctionDocumentSubmissionUrlValid,
      handleBidDocumentSubmissionUrlValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      bidJointBiddingUrlValid,
      auctionDocumentSubmissionUrlRequiredValid,
      bidDocumentSubmissionUrlRequiredValid,
    },
  };

  return validations;
};

export default CardProvisionalApplyCompletionForm;
