import './css/property-detail-item.css';
import DetailSlider from './DetailSlider/DetailSlider';
import HeroArea from './HeroArea/HeroArea';
//import { ImageDummy } from '../../helper/image_dummy.js';
//import { usePropertyImages } from 'shared/hooks/usePropertyImages';

const PropertyDetailItem = ({ data, isContainBlockList }) => {
  // TODO: 画像パスが書かれているJSONが完成した後結合再開
  //const { propertyImages } = usePropertyImages();
  const mainImages = [
    {
      id: '1',
      image_url:
        `react/public/image_storage/${data?.governmentId}/${data?.id}/001_main.jpg`.replace(
          'react/public',
          '',
        ),
      image_caption_title: 'カメラ',
    },
    {
      id: '2',
      image_url:
        `react/public/image_storage/${data?.governmentId}/${data?.id}/002_main.jpg`.replace(
          'react/public',
          '',
        ),
      image_caption_title: '車',
    },
    {
      id: '3',
      image_url:
        `react/public/image_storage/${data?.governmentId}/${data?.id}/003_main.jpg`.replace(
          'react/public',
          '',
        ),
      image_caption_title: '車',
    },
    {
      id: '4',
      image_url:
        `react/public/image_storage/${data?.governmentId}/${data?.id}/004_main.jpg`.replace(
          'react/public',
          '',
        ),
      image_caption_title: 'PC',
    },
  ];

  return (
    <section className="mb-16">
      <div className="flex">
        {data?.status === 'CANCEL' ? (
          <div className="detail-slider flex align-center justify-center">
            <div className="no-image" data-testid="no-image">
              中止
            </div>
          </div>
        ) : (
          <DetailSlider images={mainImages} />
        )}
        <HeroArea data={data} isContainBlockList={isContainBlockList} />
      </div>
    </section>
  );
};

export default PropertyDetailItem;
