import { findByPropertyId as findApplicantInformations } from 'domain/Applicant/services/ApplicantInformation';
import { all as findAuctionUsers } from 'domain/Applicant/services/AuctionUser';
import { findByPropertyId as findProposals } from 'domain/Applicant/services/Proposal';
import { findById as findAuction } from 'domain/Auction/services/Auction';
import { findByPropertyId as findBidderHistories } from 'domain/Bid/services/BidderHistory';
import { findById as findGovernment } from 'domain/Government/services/Government.js';
import { findByPropertyId as getBidderList } from 'domain/List/services/BidderList.js';
import { findById } from 'domain/Property/services/Property.js';
import { findById as findGovernmentUser } from 'domain/User/services/GovernmentUser.js';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from 'shared/hooks/useAuth.js';
import { NAVI } from 'shared/utils/helper/constants.js';
import { PrefectureToJP } from 'shared/utils/helper/convert/prefectureToJp.js';

import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage.js';
import { BidderListContext } from '../entities/BidderListContext.js';

const BidderListProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const { auction_id, property_id } = useParams();
  const [proposals] = useState([]);
  const [myUserInformation] = useState([]);
  const [property, setProperty] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchParam, setSearchParam] = useState({ membershipId: '' });
  const [sortParam, setSortParam] = useState({ sortId: 1, order: 'asc' });
  const [auction, setAuction] = useState(undefined);
  const [government, setGovernment] = useState(undefined);
  const [governmentUser, setGovernmentUser] = useState(undefined);

  const { localStorageUserId, localStorageGovernmentId } = useLocalStorage();

  if (type === NAVI) {
    useEffect(() => {
      let data = [];
      findProposals(type, property_id).then((resultProposals) => {
        findAuctionUsers(type).then((resultUsers) => {
          findApplicantInformations(type, property_id).then(
            (resultApplicantInformations) => {
              getBidderList(type, property_id).then((resultBidders) => {
                findBidderHistories(type, property_id).then(
                  (resultBidderHistories) => {
                    resultProposals.forEach((proposal) => {
                      let item = {
                        applicant: {},
                        client: {},
                      };
                      let user = resultUsers.find(
                        (user) =>
                          user.id.toString() ===
                          proposal.auctionUserId.toString(),
                      );
                      if (searchParam.membershipId !== '') {
                        if (
                          !user.membershipId?.includes(searchParam.membershipId)
                        ) {
                          return;
                        }
                      }
                      let applicant = resultApplicantInformations.find(
                        (info) =>
                          info.auctionUserId.toString() ===
                            user.id.toString() && info.type === 'APPLICANT',
                      );
                      let client = resultApplicantInformations.find(
                        (info) =>
                          info.auctionUserId.toString() ===
                            user.id.toString() && info.type === 'CLIENT',
                      );
                      let bidder = resultBidders.find(
                        (bidder) =>
                          bidder.auctionUserId.toString() ===
                          user.id.toString(),
                      );
                      let bidderHistory = resultBidderHistories.find(
                        (history) =>
                          history.auctionUserId.toString() ===
                          user.id.toString(),
                      );

                      item.trackingId = proposal.trackingId;
                      item.tmpAplDate = applicant.tmpAplDate;
                      item.bidStatus = bidderHistory?.bidStatus;
                      item.price = bidderHistory?.price;
                      item.time = bidderHistory?.time;
                      item.aplType = proposal.aplType;
                      item.memo = proposal.memo;
                      item.aplStatus = proposal.aplStatus;
                      item.membershipId = user.membershipId;

                      item.applicant.address = `${applicant.postalCode} ${PrefectureToJP(applicant.locationGovernmentSubCategoryId)} ${applicant.address}`;
                      item.client.address =
                        client !== undefined
                          ? `${client.postalCode} ${PrefectureToJP(client.locationGovernmentSubCategoryId)} ${client.address}`
                          : item.applicant.address;

                      item.applicant.name = `${applicant.familyName} ${applicant.firstName}`;
                      item.client.name =
                        client !== undefined
                          ? `${client.familyName} ${client.firstName}`
                          : `${applicant.familyName} ${applicant.firstName}`;
                      item.applicant.corpName = applicant.corpName;
                      item.client.corpName =
                        client !== undefined
                          ? client.corpName
                          : applicant.corpName;
                      item.email = user.email;
                      item.applicant.tel = applicant.tel;
                      item.client.tel =
                        client !== undefined ? client.tel : applicant.tel;
                      item.applicant.mobileTel = applicant.mobileTel;
                      item.client.mobileTel =
                        client !== undefined
                          ? client.mobileTel
                          : applicant.mobileTel;
                      item.applicant.sex =
                        applicant.sex === 'MAN'
                          ? '男性'
                          : applicant.sex === 'WOMAN'
                            ? '女性'
                            : '未入力';
                      item.client.sex =
                        client !== undefined
                          ? client.sex === 'MAN'
                            ? '男性'
                            : client.sex === 'WOMAN'
                              ? '女性'
                              : '未入力'
                          : item.applicant.sex;
                      item.applicant.birthDate = applicant.birthDate;
                      item.client.birthDate =
                        client !== undefined
                          ? client.birthDate
                          : applicant.birthDate;
                      item.isJointBid = proposal.isJointBid;
                      item.isNextBuy = bidder?.isNextBuy;
                      item.isRepresentative = proposal.isRepresentative;

                      data.push(item);
                    });

                    data = sortItems(data);

                    setTableData(data);
                  },
                );
              });
            },
          );
        });
      });
    }, [property_id, searchParam, sortParam]);

    useEffect(() => {
      findAuction(type, auction_id).then((result) => {
        setAuction(result);
      });
    }, [auction_id]);

    useEffect(() => {
      findGovernmentUser(type, localStorageUserId).then((user) => {
        setGovernmentUser(user);
      });
    }, [localStorageUserId]);

    useEffect(() => {
      findGovernment(type, localStorageGovernmentId).then((government) => {
        setGovernment(government);
      });
    }, [localStorageGovernmentId]);

    useEffect(() => {
      findById(type, property_id).then((result) => {
        setProperty(result);
      });
    }, []);

    //useEffect(() => {
    //  getBidderList(type).then(result => {
    //    setBidderList(result);
    //  });
    //}, []);

    // TODO: domain/User/servicesにMyUserInfoのデータを取得する処理を追加する
    // useEffect(() => {
    //   getMyUserInformation(type).then(result => {
    //     setMyUserInformation(result);
    //   });
    // }, []);
    //}
  }

  const sortItems = (items) => {
    if (sortParam.sortId === 1) {
      if (sortParam.order === 'asc') {
        return items.sort((a, b) =>
          Number(a.price) > Number(b.price) ? 1 : -1,
        );
      } else {
        return items.sort((a, b) =>
          Number(b.price) > Number(a.price) ? 1 : -1,
        );
      }
    }
    if (sortParam.sortId === 2) {
      if (sortParam.order === 'asc') {
        return items.sort((a, b) => (a.time > b.time ? 1 : -1));
      } else {
        return items.sort((a, b) => (b.time > a.time ? 1 : -1));
      }
    }
    if (sortParam.sortId === 3) {
      if (sortParam.order === 'asc') {
        return items.sort((a, b) => (a.aplType > b.aplType ? 1 : -1));
      } else {
        return items.sort((a, b) => (b.aplType > a.aplType ? 1 : -1));
      }
    }
    if (sortParam.sortId === 4) {
      if (sortParam.order === 'asc') {
        return items.sort((a, b) =>
          a.isRepresentative > b.isRepresentative ? 1 : -1,
        );
      } else {
        return items.sort((a, b) =>
          b.isRepresentative > a.isRepresentative ? 1 : -1,
        );
      }
    }
    return items;
  };

  return (
    <BidderListContext.Provider
      value={{
        auction,
        government,
        governmentId,
        governmentType,
        governmentUser,
        myUserInformation,
        procedureType,
        proposals,
        property,
        tableData,
        setSortParam,
        setSearchParam,
      }}>
      {children}
    </BidderListContext.Provider>
  );
};

export default BidderListProvider;
