import pino from 'pino';

import { BASE_URL_NAVIGATION } from '../../../config/envConstants.js';
import { fetchData } from '../../../shared/utils/helper/fetchData.js';

const logger = pino();
const CURRENT_BANK_TRANSFER_PAGES = `${BASE_URL_NAVIGATION}/storage/current/public_sale_caution_pages/data.json`;
const PREVIOUS_BANK_TRANSFER_PAGES = `${BASE_URL_NAVIGATION}/storage/previous/public_sale_caution_pages/data.json`;

export const fetchPublicSaleCautionPagesStorage = async () => {
  try {
    const response = await fetchPublicSaleCautionPages(
      CURRENT_BANK_TRANSFER_PAGES,
    );
    return extractBankTransferPages(response);
  } catch (error) {
    logger.warn(
      { error },
      '現在の落札後の注意事項（ public_sale_caution_pages ）を取得できませんでした',
    );

    try {
      const response = await fetchPublicSaleCautionPages(
        PREVIOUS_BANK_TRANSFER_PAGES,
      );
      return extractBankTransferPages(response);
    } catch (error) {
      logger.error(
        { error },
        '以前の落札後の注意事項（ public_sale_caution_pages ）の取得にも失敗しました',
      );
      return [];
    }
  }
};

export const fetchPublicSaleCautionPages = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
};

export const extractBankTransferPages = (response) => {
  return response.map((bank_transfer_page) => bank_transfer_page);
};
