// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mylist .mylist-sort-checkbox {
  margin-right: 16px;
  padding-top: 4px;
  display: flex;
}

.mylist .mylist-sort-checkbox-body {
  margin-right: 4px;
  transform: scale(0.8);
  position: relative;
}

.mylist .mylist-sort-checkbox-label {
  font-size: .75rem;
  font-family: "Roboto", sans-serif;
  padding-top: 3px;
}

.mylist .mylist-sort-checkbox-icon {
  font-size: 0.5rem;
  color: rgba(0, 0, 0, .54);
  height: 24px;
}

.mylist .mylist-sort-checkbox-icon::before {
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
}

.mylist .mylist-sort-checkbox-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  width: 100%;
  height: 100%;
  max-height: 32px;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Mylist/mylist-sort-checkbox.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,iCAAiC;EACjC,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,oBAAoB;EACpB,oBAAoB;EACpB,mCAAmC;AACrC;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,UAAU;EACV,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".mylist .mylist-sort-checkbox {\n  margin-right: 16px;\n  padding-top: 4px;\n  display: flex;\n}\n\n.mylist .mylist-sort-checkbox-body {\n  margin-right: 4px;\n  transform: scale(0.8);\n  position: relative;\n}\n\n.mylist .mylist-sort-checkbox-label {\n  font-size: .75rem;\n  font-family: \"Roboto\", sans-serif;\n  padding-top: 3px;\n}\n\n.mylist .mylist-sort-checkbox-icon {\n  font-size: 0.5rem;\n  color: rgba(0, 0, 0, .54);\n  height: 24px;\n}\n\n.mylist .mylist-sort-checkbox-icon::before {\n  display: inline-block;\n  font-size: inherit;\n  text-rendering: auto;\n  line-height: inherit;\n  -webkit-font-smoothing: antialiased;\n}\n\n.mylist .mylist-sort-checkbox-input {\n  position: absolute;\n  top: 0;\n  left: 0;\n  opacity: 0;\n  cursor: pointer;\n  user-select: none;\n  width: 100%;\n  height: 100%;\n  max-height: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
