import Button from 'interfaces/components/common/Button/Button.jsx';
import { useNavigate } from 'react-router-dom';
import { DepositPayment } from 'shared/utils/constants/property';
import { ProcedureType } from 'shared/utils/constants/auction';
import { useContext } from 'react';
import { ReceptionCompletedContext } from 'interfaces/pages/ReceptionCompleted/entities/ReceptionCompletedContext';

const ReceptionCompletionMessage = ({ email, auction, property, proposal }) => {
  const { bankTransferPages, creditCardPages, government } = useContext(
    ReceptionCompletedContext,
  );
  const navigate = useNavigate();

  const mylistMessage = () => {
    return (
      <p>
        マイリストに登録しました。画面右上「
        <span className="warning-text">マイリスト</span>
        」ボタンからご確認ください。
      </p>
    );
  };

  const jointBidLink = (url) => {
    if (proposal?.isJointBid === 'TRUE') {
      return (
        <div className="mb-4 joint-bit-link">
          <a href={url}>共同入札の場合はこちら</a>
        </div>
      );
    }

    return <></>;
  };

  let bankTransferPage = bankTransferPages?.find(
    (bank) =>
      bank?.procedureType === auction?.procedureType &&
      bank?.publicSaleType === property?.publicSaleType,
  );

  let creditCardPage = creditCardPages?.find(
    (credit) =>
      credit?.procedureType === auction?.procedureType &&
      credit?.publicSaleType === property?.publicSaleType,
  );

  // 保証金なし
  if (property?.depositPayment === DepositPayment.NONE.string) {
    return (
      <div className="my-10 text-center">
        <p className="mb-5">
          <span className="warning-text">
            お申し込みありがとうございます。下記の物件の参加申し込みを受け付けました。
          </span>
        </p>
        <p className="mb-5">
          申込内容の確認メールを {email} あてにお送りします。
        </p>
        {mylistMessage()}
      </div>
    );
  }

  // クレジットカード
  if (property?.depositPayment === DepositPayment.CREDIT_CARD.string) {
    if (property?.isApprovalRequired === 'TRUE') {
      return (
        <div className="flex justify-center">
          {creditCardPage !== undefined ? (
            <div className="my-10 text-center">
              <p className="mb-5">
                <span className="warning-text">
                  お申し込みありがとうございます。下記の物件の参加仮申し込みを受け付けました。
                </span>
              </p>
              <p>
                現在仮申し込みの状態です。手続きが完了するまで入札をすることはできません。
              </p>
              <p>
                引き続き実施行政機関の案内に従って必要な手続きを行なってください。
              </p>
              <p className="mb-5">手続き方法は下記ページでご確認ください。</p>
              <p className="mb-5">
                <Button
                  height="70px"
                  onClick={() => {
                    navigate(creditCardPage?.documentSubmissionUrl);
                  }}
                  text={
                    <>
                      <p>{government?.name}ホームページ</p>
                      <p>{ProcedureType[auction?.procedureType].jp}</p>
                      <p>参加申し込み手続きページへ</p>
                    </>
                  }
                />
              </p>
              {jointBidLink(creditCardPage?.jointBiddingUrl)}
              {mylistMessage()}
            </div>
          ) : (
            <div className="my-10 text-center">
              <p className="mb-5">
                <span className="warning-text">
                  お申し込みありがとうございます。下記の物件の参加申し込みを受け付けました。
                </span>
              </p>
              <p>現在仮申し込みの状態です。手続きはございません。</p>
              <p className="mb-5">自治体の承認作業完了までお待ち下さい。</p>
              {jointBidLink(creditCardPage?.jointBiddingUrl)}
              {mylistMessage()}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="my-10 text-center">
          <p className="mb-5">
            <span className="warning-text">
              お申し込みありがとうございます。下記の物件の参加申し込みを受け付けました。
            </span>
          </p>
          <p className="mb-5">
            申込内容の確認メールを {email} あてにお送りします。
          </p>
          {mylistMessage()}
        </div>
      );
    }
  }

  // 銀行振込
  return (
    <div className="flex justify-center">
      <div className="my-10 text-center">
        <p className="mb-5">
          <span className="warning-text">
            お申し込みありがとうございます。下記の物件の参加仮申し込みを受け付けました。
          </span>
        </p>
        <p>
          現在仮申し込みの状態です。手続きが完了するまで入札をすることはできません。
        </p>
        <p>
          引き続き実施行政機関の案内に従って必要な手続きを行なってください。
        </p>
        <p className="mb-5">手続き方法は下記ページでご確認ください。</p>
        <p className="mb-5">
          <Button
            height="70px"
            onClick={() => {
              navigate(bankTransferPage?.bankTransferUrl);
            }}
            text={
              <>
                <p>{government?.name}ホームページ</p>
                <p>{ProcedureType[auction?.procedureType]?.jp}</p>
                <p>参加申し込み手続きページへ</p>
              </>
            }
          />
        </p>
        {jointBidLink(bankTransferPage?.jointBiddingUrl)}
        {mylistMessage()}
      </div>
    </div>
  );
};

export default ReceptionCompletionMessage;
