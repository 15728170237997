import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_PAYMENT_METHODS = `${BASE_URL_NAVIGATION}/storage/current/payment_methods/data.json`;
const PREVIOUS_PAYMENT_METHODS = `${BASE_URL_NAVIGATION}/storage/previous/payment_methods/data.json`;


export const fetchPaymentMethodsStorage = async () => {
  try {
    const response = await fetchPaymentMethods(CURRENT_PAYMENT_METHODS);
    return extractPaymentMethods(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ properties ）を取得できませんでした");

    try {
      const response = await fetchPaymentMethods(PREVIOUS_PAYMENT_METHODS);
      return extractPaymentMethods(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ properties ）の取得にも失敗しました");
      return [];
    }
  }
};

export const getPaymentMethods = async () => {
  try {
    const response = await fetchPaymentMethods(CURRENT_PAYMENT_METHODS);
    return extractPaymentMethods(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ payment_methods ）を取得できませんでした");

    try {
      const response = await fetchPaymentMethods(PREVIOUS_PAYMENT_METHODS);
      return extractPaymentMethods(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ payment_methods ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchPaymentMethods = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractPaymentMethods = (response) => {
  return response.map(payment_method => payment_method);
}