
export class CallUpAuctionStatus {
  #callUpAuctionStatus

  constructor(callUpAuctionStatus) {
    this.#callUpAuctionStatus = callUpAuctionStatus;
  }

  get() {
    return this.#callUpAuctionStatus
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
