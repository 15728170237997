import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel';
import { DateTime } from 'luxon';
import { useState } from 'react';
import handleValidationMessage from 'shared/utils/helper/handleValidationMessage';
import { datePickerSlotProps } from 'shared/utils/helper/muiSlotProps';
import { ja } from 'date-fns/locale';

const BirthDateInput = ({ birthDate, handleChange, validationMessage }) => {
  const [open, setOpen] = useState(false);

  return (
    <tr>
      <th>
        生年月日
        <RequiredLabel />
      </th>
      <td>
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
            <DatePicker
              value={DateTime.fromJSDate(new Date(birthDate))}
              views={['year', 'month', 'day']}
              openTo="year"
              onChange={(selectDate) => handleChange(selectDate)}
              format="yyyy-MM-dd"
              slotProps={datePickerSlotProps({
                date: birthDate,
                setOpen: setOpen,
              })}
              open={open}
              onClose={() => setOpen(false)}
            />
          </LocalizationProvider>
        </div>
        <small className="validate-message">
          {handleValidationMessage(validationMessage)}
        </small>
      </td>
    </tr>
  );
};

export default BirthDateInput;
