import { useState } from 'react';

export function useOrganizationInputs() {
  const [divisionName, setDivisionName] = useState('');
  const [divisionNameKana, setDivisionNameKana] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactTel, setContactTel] = useState({
    telNum1: '',
    telNum2: '',
    telNum3: '',
  });
  const [telNum1, setTelNum1] = useState('');
  const [telNum2, setTelNum2] = useState('');
  const [telNum3, setTelNum3] = useState('');

  const [contactExtension, setContactExtension] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [useCreditCard, setUseCreditCard] = useState('YES');
  const [financialInstitutionName, setFinancialInstitutionName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNameKana, setAccountNameKana] = useState('');
  const [postalCode, setPostalCode] = useState({
    postalCode1: '',
    postalCode2: '',
  });
  const [postalCode1, setPostalCode1] = useState('');
  const [postalCode2, setPostalCode2] = useState('');
  const [prefectureId, setPrefectureId] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [buildName, setBuildName] = useState('');
  const [destination, setDestination] = useState('');
  const [writtenDestination, setWrittenDestination] = useState('');

  return {
    data: {
      divisionName,
      divisionNameKana,
      contactName,
      contactTel,
      telNum1,
      telNum2,
      telNum3,
      contactExtension,
      contactEmail,
      useCreditCard,
      financialInstitutionName,
      branchName,
      accountType,
      accountNumber,
      accountName,
      accountNameKana,
      postalCode,
      postalCode1,
      postalCode2,
      prefectureId,
      city,
      address,
      buildName,
      destination,
      writtenDestination,
    },
    setters: {
      setDivisionName,
      setDivisionNameKana,
      setContactName,
      setContactTel,
      setTelNum1,
      setTelNum2,
      setTelNum3,
      setContactExtension,
      setContactEmail,
      setUseCreditCard,
      setFinancialInstitutionName,
      setBranchName,
      setAccountType,
      setAccountNumber,
      setAccountName,
      setAccountNameKana,
      setPostalCode,
      setPostalCode1,
      setPostalCode2,
      setPrefectureId,
      setCity,
      setAddress,
      setBuildName,
      setDestination,
      setWrittenDestination,
    },
  };
}
