import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { get as getInvitations } from 'domain/Auction/services/Invitation.js';
import { get as getGovernments } from 'domain/Government/services/Government.js';

import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from 'shared/utils/helper/authState.js';
import { SERVICE, NAVI, KMANAGER } from 'shared/utils/helper/constants.js';
import { AuctionEntryContext } from '../entities/AuctionEntryContext.js';
import { GovernmentTypes } from 'shared/utils/constants/government.js';

const AuctionEntryProvider = ({ type, children }) => {
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [invitations, setInvitations] = useState([]);
  const [governments, setGovernments] = useState([]);
  const { auctionId } = useParams();

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {}, []);
  }

  if (type === NAVI) {
    useEffect(() => {}, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getInvitations(type).then((result) => {
        const filteredInvitations = result.filter(
          (invitation) => invitation.auctionId === auctionId,
        );
        setInvitations(filteredInvitations);
      });
    }, [auctionId]);
    useEffect(() => {
      const invitationGovernmentIds = invitations.map(
        (invitation) => invitation.governmentId,
      );

      getGovernments(type).then((governmentsData) => {
        const filteredGovernments = governmentsData.filter(
          (government) =>
            government.governmentType ===
              GovernmentTypes.NATIONAL_TAX_AGENCY.string &&
            !invitationGovernmentIds.includes(government.id),
        );
        setGovernments(filteredGovernments);
      });
    }, [invitations]);
  }

  return (
    <AuctionEntryContext.Provider
      value={{
        authState,
        auctionId,
        invitations,
        governments,
      }}>
      {children}
    </AuctionEntryContext.Provider>
  );
};

export default AuctionEntryProvider;
