import { validateInclude, validateRangeLength } from "../../../../application/validations.js";

export class DestinationUrl {
  #destinationUrl

  constructor(destinationUrl) {
    this.#destinationUrl = destinationUrl;
  }

  get() {
    return this.#destinationUrl
  }

  get isValid() {
    return [
      this.validateInclude,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateInclude() {
    return validateInclude(this.#destinationUrl, ['https://', 'http://'])
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#destinationUrl, 0, 2048)
  }
}
