
export class DisplayProductNumber {
  #displayProductNumber

  constructor(displayProductNumber) {
    this.#displayProductNumber = displayProductNumber;
  }

  get() {
    return this.#displayProductNumber
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
