// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

// import { ChangeOfProcedureForPayment } from './GetRemindMessage/changeOfProcedureForPayment.js';
// import { InitialSettingRegistration } from './GetRemindMessage/initialSettingRegistration.js';
// import { PropertyRegistration } from './GetRemindMessage/propertyRegistration.js';
// import { SettingDivision } from './GetRemindMessage/settingDivision.js';
// import { SettingFinancialInstitution } from './GetRemindMessage/settingFinancialInstitution.js';
import { DisplayGovernmentType } from './Messages/displayGovernmentType';
import { DisplayProcedureType } from './Messages/displayProcedureType';
import { DisplayServiceType } from './Messages/displayServiceType';
import { SeverityType } from './Messages/severityType';
import { Body } from './Notices/body.js';
import { CloseAt } from './Notices/closeAt.js';
import { Id } from './Notices/id.js';
import { IsDisplayAuction } from './Notices/isDisplayAuction.js';
import { IsDisplayBid } from './Notices/isDisplayBid.js';
import { IsDisplayLocalGov } from './Notices/isDisplayLocalGov.js';
import { IsDisplayNavi } from './Notices/isDisplayNavi.js';
import { IsDisplayService } from './Notices/isDisplayService.js';
import { IsDisplayTaxAgency } from './Notices/isDisplayTaxAgency.js';
import { IsFarmlandInfo } from './Notices/isFarmlandInfo.js';
import { IsImportant } from './Notices/isImportant.js';
import { OpenAt } from './Notices/openAt.js';
import { Title } from './Notices/title.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { NotUndefined } from '../../../shared/utils/helper/NotUndefined.js';

export class NoticeGet {
  constructor(data) {
    this.id = data.id;
    this.openAt = data.openAt;
    this.closeAt = data.closeAt;
    this.title = data.title;
    this.body = data.body;
    this.isImportant = data.isImportant;
    this.isDisplayService = data.isDisplayService;
    this.isDisplayNavi = data.isDisplayNavi;
    this.isDisplayAuction = data.isDisplayAuction;
    this.isDisplayBid = data.isDisplayBid;
    this.isDisplayTaxAgency = data.isDisplayTaxAgency;
    this.isDisplayLocalGov = data.isDisplayLocalGov;
    this.isFarmlandInfo = data.isFarmlandInfo;
    this.createdUser = data.createdUser;
    this.createdAt = data.createdAt;
    this.updatedUser = data.updatedUser;
    this.updatedAt = data.updatedAt;
    //if (!this.isValid) throw new CustomError("Notice の値が不正です")
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class NoticeCreate {
  constructor(data) {
    this.id = new Id(data.id);
    this.openAt = new OpenAt(data.openAt);
    this.closeAt = new CloseAt(data.closeAt);
    this.title = new Title(data.title);
    this.body = new Body(data.body);
    this.isImportant = new IsImportant(data.isImportant);
    this.isDisplayService = new IsDisplayService(data.isDisplayService);
    this.isDisplayNavi = new IsDisplayNavi(data.isDisplayNavi);
    this.isDisplayAuction = new IsDisplayAuction(data.isDisplayAuction);
    this.isDisplayBid = new IsDisplayBid(data.isDisplayBid);
    this.isDisplayTaxAgency = new IsDisplayTaxAgency(data.isDisplayTaxAgency);
    this.isDisplayLocalGov = new IsDisplayLocalGov(data.isDisplayLocalGov);
    this.isFarmlandInfo = new IsFarmlandInfo(data.isFarmlandInfo);
    if (!this.isValid) throw new CustomError('Notice の値が不正です');
  }

  get isValid() {
    return (
      this.id.isValid &&
      this.openAt.isValid &&
      this.closeAt.isValid &&
      this.title.isValid &&
      this.body.isValid &&
      this.isImportant.isValid &&
      this.isDisplayService.isValid &&
      this.isDisplayNavi.isValid &&
      this.isDisplayAuction.isValid &&
      this.isDisplayBid.isValid &&
      this.isDisplayTaxAgency.isValid &&
      this.isDisplayLocalGov.isValid &&
      this.isFarmlandInfo.isValid
    );
  }
}

export class MessageGet {
  constructor(data) {
    this.body = data.body;
    this.closeAt = data.closeAt;
    this.displayGovernmentType = data.displayGovernmentType;
    this.displayProcedureType = data.displayProcedureType;
    this.displayServiceType = data.displayServiceType;
    this.id = data.id;
    this.openAt = data.openAt;
    this.severityType = data.severityType;
    this.title = data.title;
    //if (this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class MessageCreate {
  constructor(data) {
    this.body = Body(data.body);
    this.closeAt = CloseAt(data.closeAt);
    this.displayGovernmentType = DisplayGovernmentType(
      data.displayGovernmentType,
    );
    this.displayProcedureType = DisplayProcedureType(data.displayProcedureType);
    this.displayServiceType = DisplayServiceType(data.displayServiceType);
    this.id = data.id;
    this.openAt = OpenAt(data.openAt);
    this.severityType = SeverityType(data.severityType);
    this.title = Title(data.title);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.body.isValid &&
      this.closeAt.isValid &&
      this.displayGovernmentType.isValid &&
      this.displayProcedureType.isValid &&
      this.displayServiceType.isValid &&
      this.openAt.isValid &&
      this.severityType.isValid &&
      this.title.isValid
    );
  }
}

// リマインドメッセージ（ナビ）
// auction_matching/batch/src/periodic/e_confirm_participation/e_get_remind_info.rs
export class RemindMessageGet {
  constructor(data) {
    this.id = data.id;
    this.governmentID = data.governmentId;
    this.message = data.message;
    this.isDisplay = data.isDisplay;
  }

  get hasNoMessage() {
    return this.message.isEmpty && this.government_id.isEmpty;
  }
}
