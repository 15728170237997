import { createContext } from "react";
import { DATA_EMPTY } from "shared/utils/helper/constants.js";

export const PublishedObjectContext = createContext({
  // Default Value
  responsive: DATA_EMPTY,
  managementMessages: DATA_EMPTY,
  government: undefined,
  governmentId: DATA_EMPTY,
  governmentType: DATA_EMPTY,
  procedureType: DATA_EMPTY,
  queryParams: DATA_EMPTY,
  auction: DATA_EMPTY,
  invitations: DATA_EMPTY,
  properties: DATA_EMPTY,
  divisions: DATA_EMPTY,
  individualMailTemplate: DATA_EMPTY,
  addDescriptions: DATA_EMPTY,
  governmentSubCategory: DATA_EMPTY,
  carProperties: DATA_EMPTY,
  estateProperties: DATA_EMPTY,
  propertyCategories: DATA_EMPTY,
  publishObjects: DATA_EMPTY,
  setSortParam: () => {},
});
