// ロジックを含む処理
// フロントから呼ぶ関数

//import { BidCreate, CallUp, CloseBid, OpenBid } from "../entities/Bid.js";
import {
  createBidData,
  updateBidData,
} from '../../../infrastructure/api/Bid.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { BidCreate } from '../entities/Bid.js';
import { getBid } from '../repositories/Bid.js';

export async function get(type) {
  return await getBid(type);
}

export async function findByPropertyId(type, propertyId) {
  let bidders = await getBid(type);
  return bidders.filter(
    (bidder) => bidder.propertyId.toString() === propertyId.toString(),
  );
}

// bidders テーブルは入札額を保持していないため取得情報の誤り。後で削除
// bidders テーブルで保持する情報は自動入札で使用する最大金額として登録された値を保持
export async function findMaxBid(type, propertyId) {
  let bidders = await getBid(type);
  bidders = bidders.filter(
    (bidder) => bidder.propertyId.toString() === propertyId.toString(),
  );
  let maxBidder = bidders[0];
  bidders.forEach((bidder) => {
    if (Number(bidder.price) > Number(maxBidder.price)) {
      maxBidder = bidder;
    }
  });
  return maxBidder;
}

// bidders テーブルは入札額を保持していないため取得情報の誤り。後で削除
export async function findUserMaxBid(type, propertyId, userId) {
  let bidders = await getBid(type);
  bidders = bidders.filter(
    (bidder) =>
      bidder.propertyId.toString() === propertyId.toString() &&
      bidder.auctionUserId.toString() === userId.toString(),
  );
  let maxBidder = bidders[0];
  bidders.forEach((bidder) => {
    if (Number(bidder.price) > Number(maxBidder.price)) {
      maxBidder = bidder;
    }
  });
  return maxBidder;
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    const bid = new BidCreate(data);
    response = await createBidData(bid, bid.propertyId, bid.auctionUserId);
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Bid)');
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Bid)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Bid', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    const bid = new BidCreate(data);
    response = await updateBidData(bid, bid.propertyId, bid.auctionUserId);
  }
  if (type === NAVI) {
    const bid = new BidCreate(data);
    response = await updateBidData(bid, bid.propertyId, bid.auctionUserId);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Bid)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Bid', response);
  }
  return response;
}

/*
export async function openBidCreate(type, data) {
  let response;

  if (type === NAVI) {
    const openBid = new OpenBid(data);
    response = openBidData(openBid);
  }

  if (response instanceof Error) {
    throw new CustomError('Error open public', response);
  }
  return response
}

export async function callUpCreate(type, data) {
  let response;

  if (type === NAVI) {
    const callUp = new CallUp(data);
    response = callUpData(callUp);
  }

  if (response instanceof Error) {
    throw new CustomError('Error call up', response);
  }
  return response
}

export async function closeBidCreate(type, data) {
  let response;

  if (type === NAVI) {
    const callUp = new CloseBid(data);
    response = closeBidData(callUp);
  }

  if (response instanceof Error) {
    throw new CustomError('Error close public', response);
  }
  return response
}
 */

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
