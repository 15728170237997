import { validateAlphanum, validateNotIncludes, validateRangeLength } from "../../../../application/validations.js";

export class Email {
  #email

  constructor(email) {
    this.#email = email;
  }

  get() {
    return this.#email
  }

  get isValid() {
    return [
      this.validateNotIncludes,
      this.validateAlphanum,
      this.validateNotIncludes,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  // get validateNotIncludes() {
  //   return validateNotIncludes(this.#email, '@')
  // }

  // バリデーションルールの適用
  get validateAlphanum() {
    return validateAlphanum(this.#email)
  }

  // バリデーションルールの適用
  get validateNotIncludes() {
    return validateNotIncludes(this.#email, ['.', '-', '_', '@'])
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#email, 0, 128)
  }
}
