import { validateSelected } from "../../../../application/validations.js";

export class PaymentLimit {
  #paymentLimit

  constructor(paymentLimit) {
    this.#paymentLimit = paymentLimit;
  }

  get() {
    return this.#paymentLimit
  }

  get isValid() {
    return [
      this.validateSelected,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateSelected() {
    return validateSelected(this.#paymentLimit)
  }
}
