import AuctionGovernment from "interfaces/components/Auction/kmanager/AuctionGovernment/AuctionGovernments";

const KmanagerAuctionGovernment = () => {
    return (
        <div data-testid="kmanager-auction-government">
            <AuctionGovernment/>
        </div>
    );
}

export default KmanagerAuctionGovernment;