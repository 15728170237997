// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-form-input-file {
  display: flex;
  align-items: center;
}

.property-form-input-file input {
  font-size: 16px;
  line-height: 20px;
  padding: 8px 0;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/property-form-input-file.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,YAAY;EACZ,WAAW;EACX,UAAU;AACZ","sourcesContent":[".property-form-input-file {\n  display: flex;\n  align-items: center;\n}\n\n.property-form-input-file input {\n  font-size: 16px;\n  line-height: 20px;\n  padding: 8px 0;\n  max-width: 100%;\n  min-width: 0;\n  width: 100%;\n  opacity: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
