
export class SummaryTransmissionType {
    #summaryTransmissionType

    constructor(summaryTransmissionType) {
        this.#summaryTransmissionType = summaryTransmissionType;
    }

    get() {
        return this.#summaryTransmissionType
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
