//import { isDefinedDateTime, isAfterSet } from "../../../../application/validations.js";
import { isDefinedDateTime } from "../../../../application/validations.js";

export class AplEnd {
  #aplEnd

  constructor(aplEnd) {
    this.#aplEnd = aplEnd;
  }

  get() {
    return this.#aplEnd
  }

  get isValid() {
    return [
      this.isDefinedDateTime,
      this.isAfterSet,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get isDefinedDateTime() {
    return isDefinedDateTime(this.#aplEnd)
  }

  // バリデーションルールの適用
  get isAfterSet() {
    return 'TRUE';
    // TOOD: startDateTimeが引き渡されていないため一時コメントアウト
    // return isAfterSet(this.#aplEnd, startDateTime)
  }
}
