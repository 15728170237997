import { ReactComponent as RadioButtonIcon } from 'interfaces/assets/svg/radiobox-blank.svg';
import { ReactComponent as RadioButtonMarked } from 'interfaces/assets/svg/radiobox-marked.svg';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  DIRECTION,
  FIELD,
  PAGE,
  PAGE_SIZE,
  SEARCH_GOVERNMENT_SUB_CATEGORY_ID,
  SEARCH_PROPERTY_CATEGORY_ID,
} from 'shared/utils/constants/queryParam';

import { useQueryParamController } from '../../../../../shared/hooks/useQueryParamController';

import 'interfaces/css/Properties/radio-button-group.css';

const RadioButtonGroup = ({ data }) => {
  const [isMarked, setIsMarked] = useState('');
  return (
    <div data-testid="radio-button-group" className="radio-button-group">
      {data &&
        data.map((d) => (
          <div key={d.id} className="radio-button-group-item">
            <RadioButton
              name={d.name}
              value={d.value}
              label={d.label}
              isMarked={isMarked}
              setIsMarked={setIsMarked}
            />
          </div>
        ))}
    </div>
  );
};

const RadioButton = ({ name, value, label, isMarked, setIsMarked }) => {
  const inputRef = useRef(null);
  const [searchParams] = useSearchParams();
  const { applyQueryParam, appendQueryParam } = useQueryParamController();
  useEffect(() => {
    const param = searchParams.get(name) ?? '';
    if (param === value) {
      setIsMarked(value);
    } else if (param === '') {
      setIsMarked('');
    }
  }, [searchParams]);
  const changeHandle = (value) => {
    setIsMarked(value);
  };
  return (
    <>
      <div className="svg-and-input-wrap">
        {isMarked === value ? (
          <RadioButtonMarked
            className="radio-button-marked-svg"
            data-testid="radio-button-marked-svg"
          />
        ) : (
          <RadioButtonIcon
            className="radio-button-svg"
            data-testid="radio-button-svg"
          />
        )}
        <input
          data-testid="radio-button-input"
          className="radio-button-input"
          type="radio"
          ref={inputRef}
          name={name}
          value={value}
          checked={isMarked === value}
          onChange={(e) => {
            changeHandle(e.target.value);
            applyQueryParam(name, value);
            appendQueryParam(SEARCH_PROPERTY_CATEGORY_ID, '');
            appendQueryParam(SEARCH_GOVERNMENT_SUB_CATEGORY_ID, '');
            appendQueryParam(DIRECTION, 'ASC');
            appendQueryParam(FIELD, 'PRICE');
            appendQueryParam(PAGE, 1);
            appendQueryParam(PAGE_SIZE, 50);
          }}
        />
      </div>
      <label
        data-testid="radio-button-label"
        className="radio-button-label"
        onClick={() => {
          inputRef.current.click();
        }}>
        {label}
      </label>
    </>
  );
};

export default RadioButtonGroup;
