import Button from '../Button/Button.jsx';
import 'interfaces/css/common/heading3.css';

const Heading3 = ({
  text,
  isLeftBorderStyle = false,
  isBgGray = false,
  hasCopyButton = false,
  clickHandler,
}) => {
  return (
    <h3
      className={`pb-3 heading3 ${isLeftBorderStyle ? 'heading3-left-border' : ''} ${isBgGray ? 'heading3-bg-gray' : ''} ${hasCopyButton ? 'd-flex align-center justify-space-between' : ''}`}>
      {text}
      {hasCopyButton && (
        <Button text="コピー用テキスト" height="28px" onClick={clickHandler} />
      )}
    </h3>
  );
};
export default Heading3;
