// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.items-hero-viewer-thumbnail {
    width: 100px;
    cursor: pointer;
}
.v-image__image--cover {
    background-size: cover;
}
.items-hero-viewer-thumbnail.swiper-slide-active .items-hero-viewer-thumbnail__card {
    border-color: var(--primary-base2);
}
.items-hero-viewer-thumbnail__card {
    transition: all .5s cubic-bezier(.25,.1,.2,1);
    -webkit-transition: all .5s cubic-bezier(.25,.1,.2,1);
    overflow: hidden;
    background: 50% 50% no-repeat;
    background-size: cover;
    border: 4px solid transparent;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/thumbnail.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;AACnB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,kCAAkC;AACtC;AACA;IACI,6CAA6C;IAC7C,qDAAqD;IACrD,gBAAgB;IAChB,6BAA6B;IAC7B,sBAAsB;IACtB,6BAA6B;AACjC","sourcesContent":[".items-hero-viewer-thumbnail {\n    width: 100px;\n    cursor: pointer;\n}\n.v-image__image--cover {\n    background-size: cover;\n}\n.items-hero-viewer-thumbnail.swiper-slide-active .items-hero-viewer-thumbnail__card {\n    border-color: var(--primary-base2);\n}\n.items-hero-viewer-thumbnail__card {\n    transition: all .5s cubic-bezier(.25,.1,.2,1);\n    -webkit-transition: all .5s cubic-bezier(.25,.1,.2,1);\n    overflow: hidden;\n    background: 50% 50% no-repeat;\n    background-size: cover;\n    border: 4px solid transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
