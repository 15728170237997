import { validateNumber, validateRangeLength } from "../../../../application/validations.js";

export class EstimatePrice {
  #estimatePrice

  constructor(estimatePrice) {
    this.#estimatePrice = estimatePrice;
  }

  get() {
    return this.#estimatePrice
  }

  get isValid() {
    return [
      this.validateNumber,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateNumber() {
    return validateNumber(this.#estimatePrice)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#estimatePrice, 0, 11)
  }

}
