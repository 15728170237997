export const asset_sale_important = (data) => {
  return [
    {
      id: 1,
      title: "危険負担",
      value: data?.importantRiskBearing,
    },
    {
      id: 2,
      title: "引き渡し条件",
      value: data?.importantTermsDelivery,
    },
    {
      id: 3,
      title: "入札保証金の取り扱い",
      value: data?.importantHandlingBidDeposit,
    },
    {
      id: 4,
      title: "契約保証金の取り扱い",
      value: data?.importantHandlingContractDeposit,
    },
    {
      id: 5,
      title: "使用用途の制限",
      value: data?.importantRestrictionUse,
    },
    {
      id: 6,
      title: "移転登記前の使用制限",
      value: data?.importantRestrictionUseBefore,
    },
    {
      id: 7,
      title: data?.importantOther1,
      value: data?.importantDescription1,
    },
    {
      id: 8,
      title: data?.importantOther2,
      value: data?.importantDescription2,
    },
    {
      id: 9,
      title: data?.importantOther3,
      value: data?.importantDescription3,
    },
  ]
}