// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.government .official-character-wrap {
  max-width: 70px;
}

.government .official-character-img {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Government/official-character.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".government .official-character-wrap {\n  max-width: 70px;\n}\n\n.government .official-character-img {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
