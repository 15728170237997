
export class BidCommitDate {
  #bidCommitDate

  constructor(bidCommitDate) {
    this.#bidCommitDate = bidCommitDate;
  }

  get() {
    return this.#bidCommitDate
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
