
export class NextWinnerPrice {
    #nextWinnerPrice

    constructor(nextWinnerPrice) {
        this.#nextWinnerPrice = nextWinnerPrice;
    }

    get() {
        return this.#nextWinnerPrice
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
