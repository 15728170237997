import { validateNumber, validateRangeLength, validateDecimal } from "../../../../application/validations.js";

export class TotalWeight {
  #totalWeight

  constructor(totalWeight) {
    this.#totalWeight = totalWeight;
  }

  get() {
    return this.#totalWeight
  }

  get isValid() {
    return [
      this.validateNumber,
      this.validateRangeLength,
      this.validateDecimal,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateNumber() {
    return validateNumber(this.#totalWeight)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#totalWeight, 0, 5)
  }

  // バリデーションルールの適用
  get validateDecimal() {
    return validateDecimal(this.#totalWeight, 0)
  }
}
