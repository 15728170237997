import axios from 'axios';

import {API_BASE_URL} from "../../config/envConstants.js";


export const fetchInvitationsData = async () => {
  return await axios.get(
    `${API_BASE_URL}/api/v1/management/invitations`,
  );
};
export const createInvitationsData = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/invitations`,
    body, { headers: headers }
  );

};
export const updateInvitationsData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/invitations/${id}`,
    body, { headers: headers }
  );
};
export const deleteInvitationsData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/management/invitations/${id}`, {
      headers: headers,
    },);
};
