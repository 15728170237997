export const TemplateGuidelineData = {
  templateGuidelineAuction1: {
    title: '第1 インターネット公売の参加条件など',
    content:  `1. インターネット公売の参加条件
（以下のいずれかに該当する方は、公売へ参加することおよび財産を買い受けることができません。また、（1）から（4）に該当する方は、代理人を通じて参加することもできません）
（1）国税徴収法第92条（買受人の制限）または同法第108条第1項（公売実施の適正化のための措置）に該当する方。
（2）●●●が定める本ガイドラインおよびKSI官公庁オークションに関連する規約・ガイドラインの内容を承諾せず、順守できない方。
（3）公売財産の買受について一定の資格、その他の条件を必要とする場合でこれらの資格などを有していない方
（4）暴力団員による不当な行為の防止等に関する法律に規定する暴力団員等に該当する方
＊暴力団員等とは、「暴力団員による不当な行為の防止等に関する法律（平成３年法律第77号）第２条第６号に規定する暴力団員又は暴力団員でなくなった日から５年を経過しない者」を指します。
（5）18歳未満の方。ただし、その親権者などが代理人として参加する場合を除きます。
（6）日本語を完全に理解できない方。ただし、その代理人が日本語を理解できる場合は除きます。
（7）日本国内に住所、連絡先がいずれもない方。ただし、その代理人が日本国内に住所または連絡先がある場合を除きます。


2. インターネット公売参加にあたっての注意事項
（1）インターネット公売は、国税徴収法などの規定にのっとって●●●が執行する公売手続きの一部です。KSI官公庁オークションに関連する規約・ガイドラインについては、本ガイドラインおよび国税徴収法の規定に反しない限り、インターネット公売の手続きにおいて公売参加者またはその代理人（以下、「公売参加者など」といいます）を拘束するものとします。
（2）公売参加者などが国税徴収法第108条第1項に掲げる行為をしたとき、執行機関は同条に基づき、入札をなかったものとするなどの処分を行うことがあります。当該処分を受けた公売参加者などは、以後2年間、当該執行機関の実施する公売に参加することまたは代理人となることができません。また、処分を受けた公売参加者などの納付した公売保証金があるときは、その公売保証金は没収し、返還しません。
なお、以下は第108条第1項に掲げる行為に該当します。
ア．売却決定を受けても買受代金の納付期限までにその代金を故意に納付しない行為。
イ．偽りの名義によりまたは第三者をかたって公売に参加する行為。
ウ．公売を妨害する意思をもって行う、第1の7「代理人などによる自己のための公売参加手続きの禁止」において禁止する行為。
エ．公売を妨害する意思をもって行う、第6の3「システム利用における禁止事項」に掲げる行為。
（3）入札に先立って公売保証金を納付してください。
（4）公売参加者などは、あらかじめインターネット公売システム（以下「公売システム」といいます）上の公売物件詳細画面や執行機関において閲覧に供されている公売公告などを確認し、登記・登録制度のある財産については、関係公簿などを閲覧するほか、十分な調査を行ったうえで公売に参加してください。また、●●●が下見会を実施する財産については、下見会で財産を確認してください。なお、公売財産が不動産の場合、内覧会などは行いませんので、現地確認などはご自身で行ってください。現地確認などの際には、公売財産の所有者、占有者などの権利を侵害してはならないことに留意してください。
（5）インターネット公売は、紀尾井町戦略研究所株式会社の提供する公売システムを採用しています。公売参加者などは、公売システムの画面上で公売参加申し込みなど一連の手続きを行ってください。
（6）インターネット公売においては、特定の売却区分（公売財産の出品区分）の公売が中止になること、もしくは公売全体が中止になることがあります。


3. 公売財産の権利移転などについての注意事項
（1）公売財産は●●税滞納者などの財産であり、●●●の所有する財産ではありません。
（2）公売財産に財産の種類又は品質に関する不適合があっても、現所有者および●●●には担保責任は生じません。
（3）売却決定を受けた最高価申込者または次順位買受申込者（以下、「買受人」といいます）ならびにその代理人（以下、「買受人など」といいます）が公売財産にかかる買受代金の全額を納付したとき（農地など一定の要件が満たされなければ権利移転の効力が生じない財産については、当該要件が満たされ、権利が移転したとき）、買受人に危険負担が移転します。その後に発生した財産の破損、盗難および焼失などによる損害の負担は、買受人が負うこととなります。
（4）公売財産が登記・登録を要する財産の場合、執行機関は、買受代金を納付した買受人などの請求により、権利移転の登記・登録を関係機関に嘱託します。
（5）公売財産が動産、自動車などである場合、執行機関はその公売財産の引渡を買受代金納付時の現況有姿で行います。
（6）公売財産が不動産の場合、執行機関は引渡の義務を負いません。公売財産内の動産類やゴミなどの撤去、占有者の立退き、前所有者からの鍵などの引渡などは、すべて買受人自身で行ってください。また、隣地との境界確定は、買受人と隣地所有者との間で行ってください。●●●は関与いたしません。
（7）買受人は、買受人に対抗することができる公売財産上の負担（マンションの未納管理費など）を引き受けなければなりません。
（8）買受人は、買受代金の納付後に公売財産の返品および買受代金の返還を求めることができません。


4. 個人情報の取り扱いについて
（1）公売参加者などは、以下のすべてに同意するものとします。
ア．公売参加申し込みを行う際に、住民登録などのされている住所、氏名（法人の場合は、商業登記簿などに登記されている所在地、名称、法人代表者氏名）および電話番号を公売参加者情報として登録すること。
イ．公売参加者などの公売参加者情報およびKSI官公庁オークションのログインID（以下、「ログインID」といいます）に登録されているメールアドレスを●●●に開示されること。
・●●●は、公売参加者などに対し、ログインIDで認証済みのメールアドレスに、公売財産に関するお知らせなどを電子メールにて送信することがあります。
ウ．最高価申込者または次順位買受申込者に決定された公売参加者のログインIDに紐づく会員識別番号（代理人による参加の場合は代理人のログインIDに紐づく会員識別番号、共同入札の場合は代表者のログインIDに紐づく会員識別番号）を公売システム上において一定期間公開されること。
（2）●●●は、公売参加者などから直接または●●●が公売システムで収集した個人情報を、●●●文書管理規則に基づき、5年間保管します。●●●は、収集した個人情報を国税徴収法第106条の2に定める調査の嘱託、第108条に定める公売実施の適正化のための措置などを行うことを目的として利用します。
（3）公売財産が登記・登録を要する財産の場合、公売参加者情報の登録内容が住民登録や商業登記簿の内容などと異なる場合（転居などにより異なる場合で、住所証明書によりその経緯などが確認できる場合を除きます）は、買受人となっても所有権移転などの権利移転登記・登録を行うことができません。


5. 代理人による参加について
インターネット公売では、代理人に公売参加の手続きをさせることができます。代理人には、少なくとも公売参加申し込み、公売保証金の納付および返還にかかる受領、入札並びにこれらに附帯する事務を委任することとします。

（1）代理人の資格
代理人は、「第1 1.インターネット公売の参加条件」を満たさなければなりません。

（2）代理人による参加の手続き
ア．代理人に公売参加の手続きをさせる場合、代理人のログインIDにより、代理人が公売参加申し込みおよび入札などを行ってください。
イ．代理人に公売参加の手続きをさせる場合、公売参加者は、委任状および公売参加者の住所証明書（公売参加者が法人の場合は商業登記簿謄本など）を入札開始2開庁日前までに●●●に提出することが必要です。委任状は、●●●ホームページより印刷することができます。 原則として、入札開始2開庁日前までに●●●が委任状などの提出を確認できない場合、入札をすることができません。公売参加者以外の方から委任状などが提出された場合も、入札をすることができません。
ウ．代理人による公売参加申し込み手続きおよび入札手続きの詳細については、「第2 公売参加申し込みについて」、「第3 せり売形式で行うインターネット公売手続き」および「第4 入札形式で行うインターネット公売手続き」をご覧ください。

（3）復代理人の選任の権限
任意代理人を選任した場合、公売参加者はその代理人に復代理人を選任する権限を付与したものとみなします。

（4）代理人による参加における注意事項
ア．代理人に国税徴収法第108条第1項に該当すると認められる事実がある場合、公売参加者およびその代理人は同法第108条第1項に該当し、以後2年間当該執行機関の実施する公売に参加できません。
イ．国税徴収法第108条第1項に該当すると認められる事実があった後2年を経過しない者を代理人とした方は、同法第108条第1項に該当し、以後2年間当該執行機関の実施する公売に参加できません。
ウ．アおよびイの場合、納付された公売保証金は没収し、返還しません。


6. 共同入札について
公売財産が不動産の場合、共同入札することができます。

（1）共同入札とは
一つの財産を複数の方で共有する目的で入札することを共同入札といいます。

（2）共同入札における注意事項
ア．共同入札する場合は、共同入札者のなかから1名の代表者を決める必要があります。実際の公売参加申し込み手続きおよび入札手続きをすることができるのは、当該代表者のみです。したがって、公売参加申し込みおよび入札などは、代表者のログインIDで行うこととなります。手続きの詳細については、「第2 公売参加申し込みについて」および「第4 入札形式で行うインターネット公売手続き」をご覧ください。
イ．共同入札する場合は、代表者以外の方全員から代表者に対する委任状、共同入札者全員の住所証明書（共同入札者が法人の場合は商業登記簿謄本など）および共同入札者全員の住所（所在地）と氏名（名称）を記入し、各共同入札者の持分を記載した「共同入札者持分内訳書」を入札開始2開庁日前までに●●●に提出することが必要です。原則として、入札開始2開庁日前までに●●●が提出を確認できない場合、入札をすることができません。なお、委任状および「共同入札者持分内訳書」は●●●ホームページより印刷することができます。
ウ．委任状および「共同入札者持分内訳書」に記載された内容が共同入札者の住民登録や商業登記簿の内容などと異なる場合（転居などにより異なる場合で、住所証明書によりその経緯などが確認できる場合を除きます）は、共同入札者が買受人となっても所有権移転などの権利移転登記を行うことができません。


7. 代理人などによる自己のための公売参加手続きの禁止
（1）代理人および共同入札における代表者（以下、「代理人など」といいます）は、公売参加者、共同入札における代表者を除く共同入札者（以下「本人など」といいます）のために公売参加の手続きをする公売財産について、本人などのために行う公売参加の手続きとは別に、自己のために公売参加の手続きをすることはできません。
（2）代理人などが、一つの公売財産に対し複数の本人などから公売参加の手続きなどについて委任を受けた場合は、その委任を受けたすべての公売参加の手続きをすることができません。
（3）本人などは、代理人などに公売参加の手続きを委任した公売財産について、代理人などが行う買受申込みとは別に、自己のために公売参加の手続きまたはほかの代理人などに委任して公売参加の手続きを行うことはできません。 なお、ほかの方と共同して、別に公売参加の手続きを行うこともできません。
（4）法人が公売に参加する場合、当該法人の代表権限のある方（以下、「法人代表者」といいます）は、法人のために行う公売参加の手続きとは別に、自己のためまたはほかの本人などの委任を受けて公売参加の手続きをすることはできません。`
  },
  templateGuidelineAuction2: {
    title: '第2 公売参加申し込みについて',
    content:  `入札に先立って、公売参加申し込みを行ってください。公売参加申し込みには、公売参加者など情報の入力、公売保証金の納付および必要に応じて委任状などの書類提出が必要です。公売参加申し込みが完了したログインIDでのみ入札できます。

1. 公売参加申し込みについて
公売参加者などは、公売公告により定められた公売参加申し込み期間内に、入札しようとする売却区分を指定のうえ、公売システムの画面上で、住民登録などのされている住所、氏名（法人の場合は、商業登記簿などに登記されている所在地、名称、代表者氏名）および電話番号を公売参加者など情報として登録してください。
・法人が公売に参加する場合は、法人代表者名でログインIDを取得したうえで、法人代表者が公売参加の手続きを行ってください。なお、法人代表者以外の方に公売参加の手続きをさせる場合は、その方を代理人とする必要があります。
・代理人に公売参加の手続きをさせる場合は、代理人のログインIDにより、代理人が公売参加の手続きを行ってください。代理人は、公売システムの画面上で、代理人による手続きの欄の「する」を選択してください。また、公売参加者は、委任状および公売参加者の住所証明書（公売参加者が法人の場合は商業登記簿謄本など）を入札開始2開庁日前までに●●●に提出することが必要です。原則として、入札開始2開庁日前までに●●●が委任状などの提出を確認できない場合、入札をすることができません。公売参加者以外の方から委任状などが提出された場合も、入札をすることができません。
・共同入札する場合は、代表者のログインIDにより、代表者が公売参加の手続きを行ってください。代表者は、公売システムの画面上で、共同入札の欄の「する」を選択してください。また、代表者以外の方全員から代表者に対する委任状、共同入札者全員の住所証明書（共同入札者が法人の場合は商業登記簿謄本など）および「共同入札者持分内訳書」を入札開始2開庁日前までに●●●に提出することが必要です。原則として、入札開始2開庁日前までに●●●が提出を確認できない場合、入札をすることができません。
・公売財産が農地である場合は、農業委員会などの発行する買受適格証明書を入札開始2開庁日前までに●●●に提出することが必要です。原則として、入札開始2開庁日前までに●●●が提出を確認できない場合、入札をすることができません。

2. 陳述書について（不動産の参加申し込みの場合）
不動産の買受申込みをする場合、買受申込者は、次のいずれにも該当しない旨の陳述書を提出する必要があります（ただし、自己の計算において買受申込みをさせようとする者がいる場合には、陳述書別紙を併せて提出する必要があります。）。
（1） 買受申込者（その者が法人である場合には、その役員）が、暴力団員（暴力団員による不当な行為の防止等に関する法律（平成３年法律第77号）第２条第６号（定義）に規定する暴力団員をいう。）又は暴力団員でなくなった日から５年を経過していない者（以下「暴力団員等」という。）であること
（2） 自己の計算において買受申込みをさせようとする者（その者が法人である場合には、その役員）が暴力団員等であること
なお、買受申込者又は自己の計算において買受申込みをさせようとする者が法人である場合には、法人の役員を証する書面（商業登記簿に係る登記事項証明書等）を提出する必要があります。

また、買受申込者又は自己の計算において買受申込みをさせようとする者が宅地建物取引業又は債権回収管理業の事業者である場合には、その許認可等を受けていることを証する書面（宅地建物取引業の免許証等）の写しを併せて提出する必要があります。

3. 公売保証金の納付について
（1）公売保証金とは

国税徴収法により定められている、入札する前に納付しなければならない金員です。公売保証金は、執行機関が、売却区分ごとに、見積価額（最低入札価格）の100分の10以上の金額を定めます。

（2）公売保証金の納付方法

公売保証金の納付は、売却区分ごとに必要です。公売保証金は、執行機関が売却区分ごとに指定する方法で納付してください。指定する方法は、下記のアのみ、イのみ、アまたはイの3通りです。売却区分ごとに、公売システムの公売物件詳細画面でどの方法が指定されているかを確認してください。
ア．クレジットカードによる納付
クレジットカードで公売保証金を納付する場合は、公売システムの公売物件詳細画面より公売参加申し込みを行い、公売保証金を所定の手続きに従って、クレジットカードにて納付してください。クレジットカードにより公売保証金を納付する公売参加者などは、紀尾井町戦略研究所株式会社に対し、クレジットカードによる公売保証金納付および返還事務に関する代理権を付与し、クレジットカードによる請求処理をSBペイメントサービス株式会社に委託することを承諾します。公売参加者などは、インターネット公売が終了し、公売保証金の返還が終了するまでこの承諾を取り消せないことに同意するものとします。
また、公売参加者などは、紀尾井町戦略研究所株式会社が公売保証金取り扱い事務に必要な範囲で、公売参加者などの個人情報をSBペイメントサービス株式会社に開示することに同意するものとします。
・VISA、マスターカード、JCB、ダイナース、アメリカン・エキスプレスのマークがついていないクレジットカードなど、ごく一部ご利用いただけないカードがございます。
・法人で公売に参加する場合、法人代表者名義のクレジットカードをご使用ください。
・代理人に公売参加の手続きをさせる場合、代理人名義のクレジットカードをご使用ください。
イ．銀行振込などによる納付
銀行振込などで公売保証金を納付する場合は、公売システムの公売物件詳細画面より公売参加仮申し込みを行ってください。その後、●●●ホームページから「公売保証金納付書兼支払請求書兼口座振替依頼書」を印刷し、必要事項を記入・なつ印のうえ、●●●に書留郵便にて送付してください。次に●●●から公売参加仮申し込みを行った公売参加者などに対し、公売参加者などが「公売保証金納付書兼支払請求書兼口座振替依頼書」に記入したメールアドレスに送信する電子メールにて公売保証金納付方法をご案内します。当該電子メールに従って、銀行口座への振込、現金書留（50万円以下の場合のみ）による送付、郵便為替（ゆうちょ銀行が取り扱う普通為替および定額小為替をいう。以下同じ）による納付、または直接持参にて公売保証金を納付してください。
・銀行口座への振込により公売保証金を納付する場合は、●●●が納付を確認できるまで3開庁日程度要することがあります。
・原則として、入札開始2開庁日前までに●●●が公売保証金の納付を確認できない場合、入札することができません。
・現金書留による送付または直接持参により公売保証金を納付する場合、現金もしくは銀行振出の小切手（電子交換所に加入する銀行が振り出したもので、かつ振出日から起算して8日を経過していないものに限る）で●●●に納付してください。
・郵便為替により公売保証金を納付する場合、郵便為替証書は、発行日から起算して175日を経過していないものに限ります。
・銀行振込の際の振込手数料や現金書留の郵送料などは公売参加者などの負担となります。
・代理人に公売参加の手続きをさせる場合、代理人は「公売保証金納付書兼支払請求書兼口座振替依頼書」に公売参加者の住所および氏名など並びに代理人であることを明記した上で、代理人名で公売保証金を納付してください。
・共同入札する場合は、仮申し込みを行った代表者名で公売保証金を納付する必要があります。
・「公売保証金納付書兼支払請求書兼口座振替依頼書」に記入する振込先金融機関は、●●●公金収納取扱金融機関に限ります。

（3）公売保証金の買受代金への充当

公売参加者などは、買受人などとなり買受代金から公売保証金を差し引いた金額を納付した場合、公売保証金を買受代金に充当することに同意するものとします。

（4）公売保証金の没収

公売参加者などが納付した公売保証金は、以下の場合に没収し、返還しません。
ア．最高価申込者または次順位買受申込者となり売却決定されたが、納付期限までに買受代金を納付しない場合
イ．公売参加者などが、国税徴収法第108条第1項の規定に該当する場合`
  },
  templateGuidelineAuction3: {
    title: '第3 せり売形式で行うインターネット公売手続き',
    content:  `せり売形式の公売システムは、紀尾井町戦略研究所株式会社の提供する自動入札システムおよび入札単位を使用しています。本章における入札とは、公売システム上の「入札価額」欄へ希望落札金額の上限を入力することおよび入力した上限以下の範囲で行われる自動入札をいいます。また、本章においては、「入札」はせり売にかかる買受の申し込み、「入札者」は買受申込者、「入札期間」はせり売期間を指します。

1. インターネット公売への入札
（1）入札

公売参加申し込み、公売保証金の納付および必要に応じて委任状などの書類提出が完了したログインIDでのみ、入札が可能です。入札は、入札期間中であれば何回でも可能です。ただし、公売システム上の「現在価額」または一度「入札価額」欄に入力した金額を下回る金額を「入札価額」欄に入力することはできません。一度行った入札は、公売参加者などの都合による取り消しや変更はできませんので、ご注意ください。なお、入札期間の自動延長は行いません。

（2）入札をなかったものとする取り扱い

執行機関は、国税徴収法第108条第1項の規定に該当する者またはその代理人などが行った入札について、当該入札を取り消し、なかったものとして取り扱うことがあります。入札期間中にその時点における最高価額の入札をなかったものとした場合、当該入札に次ぐ価額の入札を最高価額の入札とし、せり売を続行します。

2. 最高価申込者の決定など
（1）最高価申込者の決定

●●●は入札期間終了後、公売公告により定められた最高価申込者決定の日において、売却区分ごとに、インターネット公売上の入札における入札価額が見積価額以上でかつ最高価額である入札者を、最高価申込者として決定します。
また、インターネット公売では、2人以上が同額の入札価額（上限）を設定した場合、先に設定した人を最高価申込者として決定します。

（2）せり売終了の告知など

●●●は、最高価申込者を決定したときは、最高価申込者のログインIDに紐づく会員識別番号と落札価額（最高価申込価額）を公売システム上に一定期間公開することによって告げ、せり売終了を告知します。

（3）●●●から最高価申込者などへの連絡

最高価申込者またはその代理人など（以下、「最高価申込者など」といいます）には、●●●から入札終了後、あらかじめログインIDで認証されたメールアドレスに、最高価申込者として決定された旨の電子メールを送信します。
・●●●が最高価申込者などに送信した電子メールが、最高価申込者などのメールアドレスの変更やプロバイダの不調などの理由により到着しないために、●●●が買受代金納付期限までに最高価申込者などによる買受代金の納付を確認できない場合、その原因が最高価申込者などの責に帰すべきものであるか否かを問わず、公売保証金を没収し、返還しません。

（4）最高価申込者決定の取り消し

以下の場合に、最高価申込者の決定が取り消されます。この場合、公売財産の所有権は最高価申込者に移転しません。アまたはウの場合は、納付された公売保証金を返還します。
ア．売却決定前、公売財産にかかる差押徴収金（●税など）について完納の事実が証明されたとき。
イ．最高価申込者などが国税徴収法第108条第1項の規定に該当するとき。
ウ．最高価申込者などが暴力団員等であることが認められるとき。

3. 売却決定
執行機関は、公売公告に記載した日時に、最高価申込者に対して売却決定を行います。

（1）売却決定金額

売却決定金額は、落札価額を売却決定金額とします。

（2）買受人などが買受代金を納付しなかった場合

買受人などが買受代金を納付しなかった場合、納付された公売保証金は返還しません。

（3）売却決定の取り消し

以下の場合に、売却決定が取り消されます。この場合、公売財産の所有権は買受人に移転しません。ただし、公売財産が動産の場合で、善意の買受人などが買受代金を納付した場合は、公売財産の所有権は当該買受人に移転します。
なお、アの場合にのみ、納付された公売保証金を返還します。
ア．売却決定後、買受人などが買受代金を納付する前に、公売財産にかかる差押徴収金（●税など）について完納の事実が証明されたとき。
イ．買受人などが買受代金を納付期限までに納付しなかったとき。
ウ．買受人などが、国税徴収法第108条第1項の規定に該当するとき。

（4）公売不動産にかかる売却決定の日時および買受代金納付期限の変更

不動産の最高価申込者等については、国税徴収法第106条の2に基づく調査の嘱託を行います。売却決定の日時までに、最高価申込者等が暴力団員等に該当しないことの調査の結果が明らかにならない場合は、売却決定の日時及び買受代金の納付期限が変更されます。

4. 買受代金の納付
（1）買受代金納付期限について

買受人などは、買受代金納付期限までに●●●が納付を確認できるよう買受代金（買受代金に充当される公売保証金額を除く）を一括で納付してください。買受代金納付期限までに買受代金全額の納付が確認できない場合、納付された公売保証金を没収し、返還しません。

（2）買受代金の納付方法

買受代金は次の方法で納付してください。なお、買受代金の納付にかかる費用は、買受人などが負担します。なお、買受代金納付期限までに●●●が納付を確認できることが必要です。
ア．●●●の指定する口座へ銀行振込。
イ．現金書留による送付（金額が50万円以下の場合のみ）。
ウ．郵便為替による納付。
＊発行日から起算して175日を経過していないものに限る。
エ．現金もしくは銀行振出の小切手を●●●へ直接持参。
＊銀行振出の小切手は、電子交換所に加入する銀行が振り出したもので振出日から起算して8日を経過していないものに限る。

（3）買受代金の納付の効果

ア．買受人などが公売財産にかかる買受代金の全額を納付したとき、買受人に当該公売財産の権利が移転します。ただし、公売財産を買い受けるために関係機関の承認や許可または登録が必要な場合は、それらの要件が満たされたときに買受人への権利移転の効力が生じます。
イ．公売財産の権利が買受人に移転したとき、危険負担が買受人に移転します。危険負担が移転した後に発生した財産の破損、盗難および焼失などによる損害の負担は、その財産の現実の引渡の有無などにかかわらず、買受人が負うことになります。

5. 公売保証金の返還
（1）最高価申込者など以外の方への公売保証金の返還

最高価申込者または国税徴収法第108条第1項の規定に該当し、同条第2項の処分を受けた者（その代理人などを含む）以外の納付した公売保証金は、入札終了後全額返還します。
なお、公売参加申し込みを行ったものの入札を行わない場合にも、公売保証金の返還は入札終了後となります。
公売保証金返還の方法および返還に要する期間は次のとおりです。
ア．クレジットカードによる納付の場合
紀尾井町戦略研究所株式会社は、クレジットカードにより納付された公売保証金を返還する場合は、クレジットカードからの公売保証金の引き落としを行いません。
ただし、公売参加者などのクレジットカードの引き落としの時期などの関係上、いったん実際に公売保証金の引き落としを行い、翌月以降に返還を行う場合がありますので、ご了承ください。
イ．銀行振込などによる納付の場合
公売保証金の返還方法は、公売参加者などが指定する金融機関の預金口座（●●●公金収納取扱金融機関の口座に限ります）への振込のみとなります。公売参加者など（公売保証金返還請求者）名義の口座のみ指定可能です。
なお、公売保証金の返還には、入札終了後4週間程度要することがあります。

（2）国税徴収法第114条に該当する場合

買受代金納付期限以前に滞納者などから不服申立てなどがあり、滞納処分の続行が停止された場合、その停止期間は、最高価申込者などまたは買受人などは国税徴収法第114条の規定によりその入札または買受を取り消すことができます。この場合、納付された公売保証金は全額返還します。

（3）国税徴収法第117条に該当する場合

売却決定後、買受人などが買受代金を納付する前に、公売財産にかかる差押徴収金（●税など）について完納の事実が証明され、国税徴収法第117条の規定により売却決定が取り消された場合は、納付された公売保証金は全額返還します。`
  },
  templateGuidelineAuction4: {
    title: '第4 入札形式で行うインターネット公売手続き',
    content:  `本章における入札とは、公売システム上で入札価額を登録することをいいます。この登録は、一度しか行うことができません。

1. インターネット公売への入札
（1）入札

公売参加申し込み、公売保証金の納付および必要に応じて委任状などの書類提出が完了したログインIDでのみ、入札が可能です。入札は一度のみ可能です。一度行った入札は、公売参加者などの都合による取り消しや変更はできませんので、ご注意ください。なお、入札期間の自動延長は行いません。

（2）入札をなかったものとする取り扱い

執行機関は、国税徴収法第108条第1項の規定に該当する者またはその代理人などが行った入札について、当該入札を取り消し、なかったものとして取り扱うことがあります。

（3）追加入札

ア．追加入札とは
最高価額での入札者が複数存在する場合は、その方々（追加入札該当者またはその代理人など。以下、「追加入札該当者など」といいます）のみによる追加の入札を行い、最高価申込者を決定します。これを追加入札といいます。追加入札においても、入札は一度のみ可能です。なお、追加入札は期日入札により行います。
イ．追加入札の周知方法
追加入札該当者などへは、入札期間終了後、電子メールにて追加入札該当者であることおよび追加入札期間をお知らせします。
ウ．その他
（ア）追加入札該当者などが追加入札期間中に追加入札を行わなかった場合は、当初の入札と同額で追加入札したものとみなします。
（イ）共同入札者が追加入札該当者となった場合、代表者のログインIDでのみ追加入札が可能です。

2. 最高価申込者の決定など
（1）最高価申込者の決定

入札期間終了後、●●●は開札を行い、売却区分ごとに、インターネット公売上の入札において、入札価額が見積価額以上でかつ最高価額である入札者を最高価申込者として決定します。
追加入札が行われた場合は、追加入札において追加入札価額が当初の入札価額以上でかつ最高価額である入札者を最高価申込者として決定します。
ただし、追加入札終了後も最高価額での入札者が複数存在する場合は、くじ（自動抽選）で最高価申込者を決定します。

（2）入札終了の告知など

●●●は、最高価申込者を決定したときは、最高価申込者のログインIDに紐づく会員識別番号と落札価額（最高価申込価額）を公売システム上に一定期間公開することによって告げ、入札終了を告知します。

（3）●●●から最高価申込者などへの連絡

最高価申込者などには、●●●から入札終了後、あらかじめログインIDで認証されたメールアドレスに、最高価申込者として決定された旨の電子メールを送信します。
・●●●が最高価申込者などに送信した電子メールが、最高価申込者などによるメールアドレスの変更やプロバイダの不調などの理由により到着しないために、●●●が最高価申込者などによる買受代金の納付を買受代金納付期限までに確認できない場合、その原因が最高価申込者などの責に帰すべきものであるか否かを問わず、公売保証金を没収し、返還しません。

（4）最高価申込者決定の取り消し

以下の場合に、最高価申込者の決定が取り消されます。この場合、公売財産の所有権は最高価申込者に移転しません。なお、アまたはウの場合は、納付された公売保証金を返還します。
ア．売却決定前に、公売財産にかかる差押徴収金（●税など）について完納の事実が証明されたとき。
イ．最高価申込者などが国税徴収法第108条第1項の規定に該当するとき。
ウ．最高価申込者などが暴力団員等であることが認められるとき。

3. 次順位買受申込者の決定
（1）次順位買受申込者の決定

最高価申込者などが買受代金を納付しなかった場合などにおいて、次順位買受申込者がいる場合に、次順位買受申込者に売却決定します。●●●は最高価申込者決定後、以下の条件をすべて満たす入札者を次順位買受申込者として決定します。
・最高価申込者の入札価額に次ぐ高い価額で入札していること。
・入札価額が最高価申込者の入札価額から公売保証金額を差し引いた金額以上であること。
・入札時に次順位買受申し込みを行っていること。
上記の条件をすべて満たす入札者が複数存在する場合は、くじ（自動抽選）により次順位買受申込者を決定します。なお、入札時に次順位買受申し込みを行った場合、この申し込みは取り消すことができませんのでご注意ください。また、●●●は、次順位買受申込者を決定したときは、次順位買受申込者の会員識別番号と次順位買受申込価額を、公売システム上に一定期間公開することによって告げます。

（2）●●●から次順位買受申込者などへの連絡

次順位買受申込者またはその代理人など（以下、「次順位買受申込者など」といいます）には、●●●から入札終了後、あらかじめ会員識別番号で認証された次順位買受申込者などのメールアドレスに、次順位買受申込者として決定された旨の電子メールを送信します。
・●●●が次順位買受申込者などに送信した電子メールが、次順位買受申込者などのメールアドレスの変更やプロバイダの不調などの理由により到着しないために、●●●が売却決定を受けて買受人となった次順位買受申込者などによる買受代金の納付を買受代金納付期限までに確認できない場合、その原因が次順位買受申込者などの責に帰すべきものであるか否かを問わず、公売保証金を没収し、返還しません。

（3）次順位買受申込者決定の取り消し

以下の場合に、次順位買受申込者の決定が取り消されます。この場合、公売財産の所有権は次順位買受申込者に移転しません。なお、アまたはウの場合は、納付された公売保証金を返還します。
ア．売却決定前に、公売財産にかかる差押徴収金（●税など）について完納の事実が証明されたとき。
イ．次順位買受申込者などが国税徴収法第108条第1項の規定に該当するとき。
ウ．次順位買受申込者などが暴力団員等であることが認められるとき。

4. 売却決定
（1）最高価申込者に対する売却決定

執行機関は、公売公告に記載した日時に、最高価申込者に対して売却決定を行います。
ア．売却決定金額
売却決定金額は、落札価額を売却決定金額とします。
イ．売却決定を受けた最高価申込者などが買受代金を納付しなかった場合
売却決定を受けた最高価申込者などが買受代金を納付しなかった場合、納付された公売保証金は返還しません。

（2）次順位買受申込者に対する売却決定

執行機関は、最高価申込者などが買受代金を納付しなかった場合などにおいて、次順位買受申込者がいる場合に、次順位買受申込者に対して売却決定を行います。
最高価申込者の決定を取り消し、次順位買受申込者がいない場合は、当該公売は成立しません。
ア．次順位買受申込者の売却決定金額
次順位買受申込者などの売却決定金額は、次順位買受申込者などの入札価額を売却決定金額とします。
イ．売却決定を受けた次順位買受申込者などが買受代金を納付しなかった場合
売却決定を受けた次順位買受申込者などが買受代金を納付しない場合、納付された公売保証金は返還しません。
この場合、当該公売は成立しません。

（3）売却決定の取り消し

以下の場合に、売却決定が取り消されます。この場合、公売財産の所有権は買受人に移転しません。なお、アの場合にのみ、納付された公売保証金を返還します。 ア．売却決定後、買受人などが買受代金を納付する前に、公売財産にかかる差押徴収金（●税など）について完納の事実が証明されたとき。 イ．買受人などが買受代金を納付期限までに納付しなかったとき。 ウ．買受人などが、国税徴収法第108条第1項の規定に該当するとき。

（4）公売不動産にかかる売却決定の日時および買受代金納付期限の変更

不動産の最高価申込者等については、国税徴収法第106条の2に基づく調査の嘱託を行います。売却決定の日時までに、最高価申込者等が暴力団員等に該当しないことの調査の結果が明らかにならない場合は、売却決定の日時及び買受代金の納付期限が変更されます。

5. 買受代金の納付
（1）買受代金の金額

買受代金の金額は、売却決定金額です。

（2）買受代金納付期限について

買受人などは、買受代金納付期限までに●●●が納付を確認できるよう買受代金（買受代金に充当される公売保証金額を除く）を一括で納付してください。（次順位買受申込者が売却決定を受けた場合の買受代金納付期限は、通常は売却決定の7日後です）。
買受代金納付期限までに買受代金全額の納付が確認できない場合、納付された公売保証金を没収し、返還しません。

（3）買受代金の納付方法

買受代金は次の方法で納付してください。なお、買受代金の納付にかかる費用は、買受人などが負担します。なお、買受代金納付期限までに●●●が納付を確認できることが必要です。
ア．●●●の指定する口座へ銀行振込。
イ．現金書留による送付（金額が50万円以下の場合のみ）。
ウ．郵便為替による納付。
＊発行日から起算して175日を経過していないものに限る。
エ．現金もしくは銀行振出の小切手を●●●へ直接持参。
＊銀行振出の小切手は、電子交換所に加入する銀行が振り出したもので振出日から起算して8日を経過していないものに限る。

（4）買受代金の納付の効果

ア．買受人などが公売財産にかかる買受代金の全額を納付したとき、買受人に当該公売財産の権利が移転します。ただし、公売財産を買い受けるために関係機関の承認や許可または登録が必要な場合は、それらの要件が満たされたときに買受人への権利移転の効力が生じます。
イ．公売財産の権利が買受人に移転したとき、危険負担が買受人に移転します。危険負担が移転した後に発生した財産の破損、盗難および焼失などによる損害の負担は、その財産の現実の引渡の有無などにかかわらず、買受人が負うことになります。

6. 公売保証金の返還
（1）最高価申込者および次順位買受申込者など以外の方への公売保証金の返還

最高価申込者、次順位買受申込者または国税徴収法第108条第1項の規定に該当し同条第2項の処分を受けた者並びにその代理人など以外の納付した公売保証金は、入札終了後全額返還します。
なお、公売参加申し込みを行ったものの入札を行わない場合にも、公売保証金の返還は入札終了後となります。
公売保証金返還の方法および返還に要する期間は次のとおりです
ア．クレジットカードによる納付の場合
紀尾井町戦略研究所株式会社は、クレジットカードにより納付された公売保証金を返還する場合は、クレジットカードからの公売保証金の引き落としを行いません。
ただし、公売参加者などのクレジットカードの引き落としの時期などの関係上、いったん実際に公売保証金の引き落としを行い、翌月以降に返還を行う場合がありますので、ご了承ください。
イ．銀行振込などによる納付の場合
公売保証金の返還方法は、公売参加者などが指定する金融機関の預金口座（●●●公金収納取扱金融機関の口座に限ります）への振込のみとなります。公売参加者など（公売保証金返還請求者）名義の口座のみ指定可能です。なお、公売保証金の返還には、入札終了後4週間程度要することがあります。

（2）次順位買受申込者などへの公売保証金の返還

次順位買受申込者などの納付した公売保証金は、最高価申込者などが買受代金納付期限までに買受代金全額を納付した場合に、全額返還します。
公売保証金返還の方法および返還に要する期間は次のとおりです。
ア．クレジットカードによる納付の場合
紀尾井町戦略研究所株式会社は、クレジットカードにより納付された公売保証金を返還する場合は、クレジットカードからの公売保証金の引き落としを行いません。
ただし、次順位買受申込者などのクレジットカードの引き落としの時期などの関係上、いったん実際に公売保証金の引き落としを行い、翌月以降に返還を行う場合がありますので、ご了承ください。
イ．銀行振込などによる納付の場合
なお、公売保証金の返還には、入札終了後4週間程度要することがあります。

（3）国税徴収法第114条に該当する場合

買受代金の納付期限以前に滞納者などから不服申立てなどがあり、滞納処分の続行が停止された場合、その停止期間は、最高価申込者など、次順位買受申込者などおよび買受人などは国税徴収法第114条の規定によりその入札または買受を取り消すことができます。この場合、納付された公売保証金は全額返還します。

（4）国税徴収法第117条に該当する場合

売却決定後、買受人などが買受代金を納付する前に、公売財産にかかる差押徴収金（●税など）について完納の事実が証明され、国税徴収法第117条の規定により売却決定が取り消された場合は、納付された公売保証金は全額返還します。`
  },
  templateGuidelineAuction5: {
    title: '第5 公売財産の権利移転および引渡について',
    content:  `1. 公売財産の権利移転手続きについて（通則）
（1）権利移転手続きについて

公売財産の権利移転手続きについては、財産の種類に応じ、第5の2から4までに定めるところによります。ガイドラインに定めのない財産の権利移転手続きについては、これらの定めるところに準じることとします。ただし、執行機関がその財産の特殊な事情などを考慮して必要と認める場合は、第5の2から4までの規定を必要と認める範囲において変更することができるものとします。

（2）権利移転手続きにおける注意事項

ア．公売財産に財産の種類又は品質に関する不適合があっても、現所有者および●●●には担保責任は生じません。
イ．買受人などが公売財産にかかる買受代金の全額を納付したとき、買受人に当該公売財産の権利が移転します。ただし、公売財産を買い受けるために関係機関の承認や許可または登録が必要な場合は、それらの要件が満たされたときに買受人への権利移転の効力が生じます。
ウ．公売財産の権利が買受人に移転したとき、危険負担が買受人に移転します。危険負担が移転した後に発生した財産の破損、盗難および焼失などによる損害の負担は、その財産の現実の引渡の有無などにかかわらず、買受人が負うことになります。
エ．権利移転に伴う費用は、買受人などの負担となります。

2. 公売財産が動産の場合の権利移転および引渡について
執行機関は、買受代金の納付を確認した後、公売財産の引渡を行います。

（1）公売財産の引渡

ア．公売財産の引渡は、買受代金納付時の現況有姿で行います。
イ．公売財産の引渡は、原則として●●●の事務室内で行います。
ウ．執行機関が公売財産を第三者に保管させている場合は、買受人は執行機関から交付される「売却決定通知書」を提示し、保管人から財産の引渡を受けてください。この場合、「売却決定通知書」の交付により、執行機関から買受人に対して公売財産の引渡は完了したことになります。保管人が財産の現実の引渡を拒否しても、執行機関はその現実の引渡を行う義務を負いません。
エ．公売財産または「売却決定通知書」を直接受け取る場合は、買受人の本人確認のため、下記（ア）から（ウ）をお持ちください。なお、買受人が法人である場合には、商業登記簿謄本などと法人代表者の方の下記（ア）から（ウ）をお持ちください。
（ア）身分証明書。
運転免許証、住民基本台帳カードなど、住所および氏名が明記されご本人の写真が添付されている本人確認書類を提示してください。なお、運転免許証などをお持ちでない方は、住民票などの住所および氏名を証する書面およびパスポートなどの写真付き本人確認書類を提示してください。
（イ）●●●より買受人などへ送信した電子メールを印刷したもの。
（ウ）印鑑。
オ．買受人は、送付による公売財産の引渡を希望する場合、「送付依頼書」や住民票などの提出が必要です。「送付依頼書」は、インターネット公売終了後、●●●ホームページより印刷して必要事項を記入・なつ印のうえ、●●●に提出してください。送付による引渡を希望する場合、輸送途中での事故などによって公売財産が破損、紛失などの被害を受けても、●●●は一切責任を負いません。また、極端に重い財産、大きな財産、壊れやすい財産は送付による引渡はできない場合があります。なお、送付先住所が買受人の住所（所在地）と異なる場合は、その旨を「送付依頼書」に記載してください。送付先の受取人となりうるのは、買受人のみです。
カ．買受人は、買受代金納付時に公売財産の引渡を受けない場合、「保管依頼書」や住民票などの提出が必要です。「保管依頼書」は、インターネット公売終了後、●●●ホームページより印刷して必要事項を記入・なつ印のうえ、●●●に提出してください。
キ．一度引き渡された財産は、いかなる理由があっても返品、交換はできません。

（2）注意事項

ア．買受人が自ら登録や名義変更などを行う必要がある財産については、引渡後、速やかに登録や名義変更の手続きを行ってください。
イ．買受代金の持参、公売財産の受取または「売却決定通知書」の受取などを代理人が行う場合は、下記（ア）から（エ）をお持ちください。
（ア）代理権限を証する委任状。
（イ）買受人本人の住所証明書（買受人が法人の場合は商業登記簿謄本など）。
（ウ）代理人の身分証明書。
（エ）代理人の印鑑。
＊委任状は●●●ホームページより印刷することができます。

（3）引渡および権利移転に伴う費用について

ア．落札された公売財産の保管費用が必要な場合、買受代金納付後の保管費用は買受人の負担となります。
イ．買受人が送付による公売財産の引渡を希望する場合、送付費用は買受人の負担となります。
ウ．その他、公売財産の権利移転に伴い費用を要する場合には、その費用は買受人の負担となります。

3. 公売財産が自動車の場合の権利移転および引渡について
本項の「自動車」は、道路運送車両法の規定により登録を受けた自動車をいいます。したがって、軽自動車および登録のない自動車などの権利移転手続きは、原則として第5の2に定めるところによります。
執行機関は、買受代金の納付を確認後、買受人に対して売却決定通知書を交付し、公売財産の引渡を行います。また、買受人からの請求に基づいて権利移転の手続きを行います。

（1）公売財産の引渡

ア．公売財産の引渡は、買受代金納付時の現況有姿で行います。
イ．執行機関が公売財産を第三者に保管させている場合は、買受人は執行機関から交付される「売却決定通知書」を提示し、保管人から財産の引渡を受けてください。この場合、「売却決定通知書」の交付により、執行機関から買受人に対して公売財産の引渡は完了したことになります。保管人が財産の現実の引渡を拒否しても、執行機関はその現実の引渡を行う義務を負いません。
ウ．買受人は、買受代金納付時に公売財産の引渡を受けない場合、「保管依頼書」の提出が必要です。「保管依頼書」は、インターネット公売終了後、●●●ホームページより印刷して必要事項を記入・なつ印のうえ、●●●に提出してください。
エ．一度引き渡された財産は、いかなる理由があっても返品、交換はできません。

（2）権利移転の手続きについて

ア．●●●ホームページより「所有権移転登録請求書」を印刷した後、必要事項を記入・署名・なつ印のうえ、自動車保管場所証明書、印鑑証明書などの必要書類を添えて、買受代金納付期限までに●●●へ提出してください。
イ．買受人の「使用の本拠の位置」を管轄する運輸支局、自動車検査登録事務所が、対象財産を管轄する運輸支局などと異なる場合などには、買受人の「使用の本拠の位置」を管轄する運輸支局などに当該自動車を持ち込んでいただくことが必要です。また、買受人の「使用の本拠の位置」を管轄する運輸支局などが、●●運輸局●●運輸支局および●●自動車検査登録事務所以外の場合、所有権の移転登録および差押登録の抹消登録は、郵送で行います。
ウ．自動車検査証有効期限切れの自動車は、所有権移転登録と同時に一時抹消登録をすることとなります。使用される場合は、買受人が自ら新規検査および新規登録の手続きを行う必要があります。

（3）売却決定通知書の交付

執行機関は、買受代金の納付を確認後、買受人に対して「売却決定通知書」を交付します。「売却決定通知書」を直接受け取る際は、買受人の本人確認のため、下記アからウをお持ちください。なお、買受人が法人である場合には、商業登記簿謄本などと法人代表者の方の下記アからウをお持ちください。
ア．身分証明書。
運転免許証、住民基本台帳カードなど、住所および氏名が明記されご本人の写真が添付されている本人確認書類を提示してください。なお、運転免許証などをお持ちでない方は、住民票などの住所および氏名を証する書面およびパスポートなどの写真付き本人確認書類を提示してください。
イ．●●●より買受人などへ送信した電子メールを印刷したもの。
ウ．印鑑。

（4）注意事項

買受代金の持参、公売財産の受取または「売却決定通知書」の受取などを代理人が行う場合は、下記アからエをお持ちください。
ア．代理権限を証する委任状。
イ．買受人本人の住所証明書（買受人が法人の場合は商業登記簿謄本など）。
ウ．代理人の身分証明書。
エ．代理人の印鑑。
＊委任状は●●●ホームページより印刷することができます。

（5）引渡および権利移転に伴う費用について

ア．権利移転に伴う費用（登録手数料など）は買受人の負担となります。
イ．自動車税環境性能割は、買受人が自ら申告、納税してください。
ウ．買受人の「使用の本拠の位置」を管轄する運輸支局などが、●●運輸局●●運輸支局および●●自動車検査登録事務所以外の場合、所有権の移転登録および差押登録の抹消登録は郵送で行いますので、郵送料（切手1500円程度）が必要です。
エ．落札された公売財産の保管費用が必要な場合、買受代金納付期限の翌日以降の保管費用は、買受人の負担となります。

4. 公売財産が不動産の場合の権利移転について
執行機関は、買受人の請求に基づいて不動産登記簿上の権利移転のみを行います。

（1）権利移転の時期

公売財産は、買受代金の全額を納付したとき、買受人に権利移転します。ただし、買受代金を納付しても、農地の場合は農業委員会などの許可などを受けるまで、その他法令の規定による登録を要する場合は関係機関の登録が完了するまで権利移転の効力は生じません。

（2）権利移転の手続きについて

ア．●●●ホームページより「所有権移転登記請求書」を印刷した後、必要事項を記入・署名・なつ印して、住所証明書などの必要書類を添えて、買受代金納付期限までに●●●へ提出してください。
イ．共同入札の場合は、共同入札者全員の住所証明書（共同入札者が法人の場合は商業登記簿謄本など）および共同入札者全員が署名・なつ印した「共有合意書」の提出が必要です。「共有合意書」の持分割合は、入札前に提出した「共同入札者持分内訳書」と同じものを記載してください。なお、共有合意書は、●●●ホームページより印刷することができます。
ウ．公売財産が農地である場合などは、農業委員会などの発行する権利移転の許可書または届出受理書のいずれかが必要です。
エ．所有権移転の登記が完了するまで、入札終了後●か月程度の期間を要することがあります。

（3）売却決定通知書の交付

執行機関は、買受代金の納付を確認後、買受人に対して「売却決定通知書」を交付します。共同入札者が買受人になった場合は、買受人全員に対しそれぞれの持分に応じた「売却決定通知書」を交付します。「売却決定通知書」を直接受け取る際は、買受人の本人確認のため、下記アからウをお持ちください。なお、買受人が法人である場合には、商業登記簿謄本などと法人代表者の方の下記アからウをお持ちください。
ア．身分証明書。
運転免許証、住民基本台帳カードなど、住所および氏名が明記されご本人の写真が添付されている本人確認書類を提示してください。なお、運転免許証などをお持ちでない方は、住民票などの住所および氏名を証する書面およびパスポートなどの写真付き本人確認書類を提示してください。
イ．●●●より買受人などへ送信した電子メールを印刷したもの。
ウ．印鑑。
なお、所有権移転登記の際に「売却決定通知書」正本が必要な場合がありますので、●●●でいったん「売却決定通知書」をお預かりすることがあります。

（2）権利移転の手続きについて

ア．●●●ホームページより「所有権移転登記請求書」を印刷した後、必要事項を記入・署名・なつ印して、住所証明書などの必要書類を添えて、買受代金納付期限までに●●●へ提出してください。
イ．共同入札の場合は、共同入札者全員の住所証明書（共同入札者が法人の場合は商業登記簿謄本など）および共同入札者全員が署名・なつ印した「共有合意書」の提出が必要です。「共有合意書」の持分割合は、入札前に提出した「共同入札者持分内訳書」と同じものを記載してください。なお、共有合意書は、●●●ホームページより印刷することができます。
ウ．公売財産が農地である場合などは、農業委員会などの発行する権利移転の許可書または届出受理書のいずれかが必要です。
エ．所有権移転の登記が完了するまで、入札終了後1か月半程度の期間を要することがあります。

（3）売却決定通知書の交付

執行機関は、買受代金の納付を確認後、買受人に対して「売却決定通知書」を交付します。共同入札者が買受人になった場合は、買受人全員に対しそれぞれの持分に応じた「売却決定通知書」を交付します。「売却決定通知書」を直接受け取る際は、買受人の本人確認のため、下記アからウをお持ちください。なお、買受人が法人である場合には、商業登記簿謄本などと法人代表者の方の下記アからウをお持ちください。
ア．身分証明書。
運転免許証、住民基本台帳カードなど、住所および氏名が明記されご本人の写真が添付されている本人確認書類を提示してください。なお、運転免許証などをお持ちでない方は、住民票などの住所および氏名を証する書面およびパスポートなどの写真付き本人確認書類を提示してください。
イ．●●●より買受人などへ送信した電子メールを印刷したもの。
ウ．印鑑。
なお、所有権移転登記の際に「売却決定通知書」正本が必要な場合がありますので、●●●でいったん「売却決定通知書」をお預かりすることがあります。

（4）注意事項

ア．執行機関は公売財産の引渡の義務を負いません。公売財産内の動産類やゴミなどの撤去、占有者の立退き、前所有者からの鍵などの引渡などは、すべて買受人自身で行ってください。
また、隣地との境界確定は、買受人と隣地所有者との間で行ってください。●●●は関与しません。
イ．買受代金の持参または「売却決定通知書」の受取などを代理人が行う場合は、下記（ア）から（エ）をお持ちください。
（ア）代理権限を証する委任状。
（イ）買受人本人の住所証明書（買受人が法人の場合は商業登記簿謄本など）。
（ウ）代理人の身分証明書。
（エ）代理人の印鑑。
＊委任状は●●●ホームページより印刷することができます。

（5）引渡および権利移転に伴う費用について

ア．権利移転に伴う費用（移転登記の登録免許税、登記嘱託書の郵送料など）は買受人の負担となります。
イ．所有権移転などの登記を行う際は、登録免許税法に定める登録免許税を納付したことを証する領収証書が必要となります。登録免許税額については、入札終了後に●●●よりお知らせします。買受代金を直接持参する場合は、登録免許税相当額をあわせて持参し、納付してください。買受代金を銀行振込などで納付する場合は、登録免許税相当額もあわせて振込もしくは送付してください。共同入札者が買受人となった場合、登録免許税の領収証書は、共同入札者の人数分だけ必要となります。共同入札者は、各々の持分に応じた登録免許税相当額を納付してください。
・所有権移転登記を行う際に、執行機関と所管の法務局との間で登記嘱託書などの書類を送付するために郵送料（切手1500円程度）が必要です。`
  },
  templateGuidelineAuction6: {
    title: '第6 注意事項',
    content:  `1. 公売システムに不具合などが生じた場合の対応
公売システムなどに不具合が生じたために次に掲げる事態が発生した場合、執行機関は公売手続きを中止することがあります。

（1）入札期間前

公売参加申し込み期間の始期に公売参加申し込み受付が開始されない場合、公売参加申し込み受付ができない状態が相当期間継続した場合、公売参加申し込み受付が入札開始までに終了しない場合または公売参加申し込み期間の終期後になされた公売参加申し込みを取り消すことができない場合。

（2）入札期間中

入札期間の始期に入札の受付が開始されない場合、入札できない状態が相当期間継続した場合または入札の受付が入札期間の終期に終了しない場合

（3）入札期間後

せり売形式において執行機関が入札終了後相当期間経過後も最高価申込者などを決定できない場合並びに入札形式において入札終了後相当期間経過後も開札ができない場合、追加入札が必要な場合で追加入札の開始または終了ができない場合またはくじ（自動抽選）が必要な場合でくじ（自動抽選）が適正に行えない場合。

2. 公売の中止および中止時の公売保証金の返還
公売参加申し込み開始後に公売を中止することがあります。公売財産の公開中であっても、公売にかかる差押徴収金が納付された場合などにインターネット公売を中止します。

（1）特定の公売財産の中止時の公売保証金の返還

特定の公売財産の公売が中止となった場合、当該公売財産について納付された公売保証金は中止後返還します。なお、銀行振込などにより公売保証金を納付した場合、返還まで中止後4週間程度要することがあります。

（2）インターネット公売中止時の公売保証金の返還

インターネット公売全体が中止となった場合、公売保証金は中止後返還します。なお、銀行振込などにより公売保証金を納付した場合、返還まで中止後4週間程度要することがあります。

3. システム利用における禁止事項
公売システムの利用にあたり、次に掲げる行為を禁止します。
（1）公売システムをインターネット公売の手続き以外の目的で不正に利用すること。
（2）公売システムに不正にアクセスをすること。
（3）公売システムの管理および運営を故意に妨害すること。
（4）公売システムにウイルスに感染したファイルを故意に送信すること。
（5）法令もしくは公序良俗に違反する行為またはそのおそれのある行為をすること。
（6）その他公売システムの運用に支障を及ぼす行為またはそのおそれのある行為をすること。

4. 公売参加者などに損害などが発生した場合
次に掲げる事由などにより公売参加者など（公売システムにアクセスした方、公売参加者などまたは第三者）に損害が発生した場合、●●●はその損害の種類・程度にかかわらず責任を負いません。
（1）公売が中止になったこと。
（2）公売システムに不具合などが生じたこと。
（3）公売参加者など（公売システムにアクセスした方、公売参加者などまたは第三者）の使用する機器およびネットワークなどの不備、不調その他の理由により、公売参加申し込みまたは入札が行えなかったこと。
（4）公売に参加したことに起因して、公売参加者などが使用する機器およびネットワークなどに不備、不調などが生じたこと。
（5）公売参加者などが公売保証金を自己名義（代理人の場合は代理人名義、法人の場合は法人代表者名義）のクレジットカードで納付する場合で、クレジットカード決済システムの不備により、公売保証金の納付ができず公売参加申し込みができなかったこと。
（6）公売参加者などのメールアドレスの変更や公売参加者などの使用する機器およびネットワークなどの不備、不調その他の理由により、●●●から送信される電子メールが到着しなかったこと。
（7）公売参加者など（公売システムにアクセスした方、公売参加者などまたは第三者）の発信もしくは受信するデータが不正アクセスおよび改変などを受けたこと。
（8）公売参加者など（公売システムにアクセスした方、公売参加者などまたは第三者）が、自身のログインIDおよびパスワードなどを紛失もしくは、ログインIDおよびパスワードなどが第三者に漏えいしたこと。
（9）公売参加者など（公売システムにアクセスした方、公売参加者などまたは第三者）が、公売参加の手続きに関する権限の一部を代理人などに委任した場合において、その委任を受けた代理人などがした行為により被害を受けたこと。
（10）買受人などとなった公売参加者などが送付による公売財産の引渡を希望した場合、輸送途中での事故などによって公売財産に破損、紛失などの事態が発生したこと。

5. 準拠法
このガイドラインには、日本法が適用されるものとします。

6. インターネット公売において使用する通貨、言語、時刻など
（1）インターネット公売の手続きにおいて使用する通貨

インターネット公売の手続きにおいて使用する通貨は、日本国通貨に限り、入札価額などの金額は、日本国通貨により表記しなければならないものとします。

（2）インターネット公売の手続きにおいて使用する言語

インターネット公売の手続きにおいて使用する言語は、日本語に限ります。公売システムにおいて使用する文字は、JIS第1第2水準漢字（JIS（産業標準化法（昭和24年法律第185号）第20条第1項の日本産業規格）X0208をいいます）であるため、不動産登記簿上の表示などと異なることがあります。

（3）インターネット公売の手続きにおいて使用する時刻

インターネット公売の手続きにおいて使用する時刻は、日本国の標準時によります。

7. 公売参加申し込み期間および入札期間
公売参加申し込み期間および入札期間は、公売システム上の公売物件詳細画面上に示された期間となります。ただし、システムメンテナンスなどの期間を除きます。

8. ●●●インターネット公売ガイドラインの改正
●●●は、必要があると認めるときは、このガイドラインを改正することができるものとします。
なお、改正を行った場合には、●●●は公売システム上に掲載することにより公表します。改正後のガイドラインは、公表した日以降に公売参加申し込みの受付を開始するインターネット公売から適用します。

9. リンクの制限など
●●●が公売システム上に情報を掲載しているウェブページへのリンクについては、●●●物件一覧のページ以外のページへの直接のリンクはできません。
また、公売システム上において、●●●が公開している情報（文章、写真、図面など）について、●●●に無断で転載・転用することは一切できません。

10. その他
官公庁オークションサイトに掲載されている情報で、●●●が掲載したものでない情報については、●●●インターネット公売に関係する情報ではありません。`
  },
  writtenOathTemplateGuidelineAssetSale: {
    title: '誓約書',
    content:  `以下を誓約いたします。
今般、●●●の公有財産売却に参加するに当たっては、以下の事項に相違ない旨確約のうえ、公有財産売却ガイドラインおよび貴庁における入札、契約などに係る諸規定を厳守し、公正な入札をいたします。もし、これらに違反するようなことが生じた場合には、直ちに貴庁の指示に従い、当該執行機関に損害が発生したときは補償その他一切の責任をとることはもちろん、貴庁に対し一切異議、苦情などは申しません。

1. 私は、地方自治法施行令（昭和22年政令第16号）第167条の4第1項に規定する一般競争入札に参加させることができない者および同条第2項各号に該当すると認められる者のいずれにも該当しません。

2. 私は、次に掲げる不当な行為は行いません。
（1）正当な理由がなく、当該入札に参加しないこと。 （2）入札において、その公正な執行を妨げ、または公正な価格の成立を害し、もしくは不正な利益を得るために連合すること。 （3）落札者が契約を締結することまたは契約者が契約を履行することを妨げること。 （4）契約の履行をしないこと。 （5）契約に違反し、契約の相手方として不適当と●●●に認められること。 （6）入札に関し贈賄などの刑事事件を起こすこと。 （7）社会的信用を失墜する行為をなし、契約の相手方として不適当と認められること。 （8）天災その他不可抗力の事由がなく、履行遅延をすること。

3. 私は、貴庁の公有財産売却に係る「公有財産売却ガイドライン」、「入札説明書」、「入札公告」、「売買契約書」の各条項を熟覧し、および貴庁の現地説明、入札説明などを傾聴し、これらについてすべて承知のうえ参加しますので、後日これらの事柄について貴庁に対し一切異議、苦情などは申しません。`
  },
  templateGuidelineAssetSale1: {
    title: '第1 公有財産売却の参加条件など',
    content:  `1. 公有財産売却の参加条件
（以下のいずれかに該当する方は、公有財産売却へ参加することができません）
（1）地方自治法施行令第167条の4第1項各号または第2項各号該当すると認められる方
（参考：地方自治法施行令（抄））
（一般競争入札の参加者の資格）
第百六十七条の四 普通地方公共団体は、特別の理由がある場合を除くほか、一般競争入札に次の各号のいずれかに該当する者を参加させることができない。
一 当該入札に係る契約を締結する能力を有しない者
二 破産手続開始の決定を受けて復権を得ない者
三 暴力団員による不当な行為の防止等に関する法律（平成三年法律第七十七号）第三十二条第一項各号に掲げる者
２ 普通地方公共団体は、一般競争入札に参加しようとする者が次の各号のいずれかに該当すると認められるときは、その者について三年以内の期間を定めて一般競争入札に参加させないことができる。その者を代理人、支配人その他の使用人又は入札代理人として使用する者についても、また同様とする。
一 契約の履行に当たり、故意に工事若しくは製造その他役務を粗雑に行い、又は物件の品質若しくは数量に関して不正の行為をしたとき。
二 競争入札又はせり売りにおいて、その公正な執行を妨げたとき又は公正な価格の成立を害し、若しくは不正の利益を得るために連合したとき。
三 落札者が契約を締結すること又は契約者が契約を履行することを妨げたとき。
四 地方自治法第二百三十四条の二第一項の規定による監督又は検査の実施に当たり職員の職務の執行を妨げたとき。
五 正当な理由がなくて契約を履行しなかつたとき。
六 契約により、契約の後に代価の額を確定する場合において、当該代価の請求を故意に虚偽の事実に基づき過大な額で行つたとき。
七 この項（この号を除く。）の規定により一般競争入札に参加できないこととされている者を契約の締結又は契約の履行に当たり代理人、支配人その他の使用人として使用したとき。
（2）日本語を完全に理解できない方
（3）●●●が定める本ガイドラインおよびKSI官公庁オークションに関連する規約・ガイドラインの内容を承諾せず、順守できない方
（4）公有財産の買受について一定の資格、その他の条件を必要とする場合でこれらの資格などを有していない方

2. 公有財産売却の参加に当たっての注意事項
（1）公有財産売却は、地方自治法などの規定にのっとって●●●が執行する一般競争入札およびせり売り（以下「入札」という）の手続きの一部です。
（2）売払代金の残金の納付期限までにその代金を正当な理由なく納付しない落札者は、地方自治法施行令第167条の4第2項第5号に該当すると見なされ、一定期間●●●の実施する入札に参加できなくなることがあります。
（3）公有財産売却に参加される方は入札保証金を納付してください。
（4）公有財産売却に参加される方は、あらかじめインターネット公有財産売却システム（以下「売却システム」といいます）上の公有財産売却の物件詳細画面や●●●において閲覧に供されている入札の公告などを確認し、関係公簿などの閲覧などにより十分に調査を行ったうえで公有財産売却に参加してください。
また、入札の前に●●●が実施する現地説明会において、購入希望の財産を確認してください。
（5）売却システムは、紀尾井町戦略研究所株式会社の提供する売却システムを採用しています。公有財産売却の参加者は、売却システムの画面上で公有財産売却の参加申し込みなど一連の手続きを行ってください。
ア．参加仮申し込み
売却システムの売却物件詳細画面より公有財産売却の参加仮申し込みを行ってください。
イ．参加申し込み（本申し込み）
売却システムの公有財産売却の物件詳細画面より仮申し込みを行った後、●●●のホームページより「公有財産売却一般競争入札参加申込書兼入札保証金返還請求書兼口座振替依頼書（以下「申込書」といいます）」を印刷し、必要事項を記入・押印後、次のいずれかの書類（以下「必要書類」という）を添付のうえ、●●●に送付または持参してください。（郵送の場合は、申込締切日の消印有効）
（必要書類）
※動産・自動車の場合：住民票の写し（法人の場合は商業登記簿謄本の写し）、印鑑登録証明書（印鑑証明書）の写し、免許証のコピー、住民基本台帳カードのコピー、パスポートのコピーのうちいずれか1通
※不動産の場合：住民票（参加者が法人の場合は、商業登記簿謄本）および印鑑登録証明書
・公有財産売却の各物件について入札保証金の納付方法をご確認のうえ、申込書の入札保証金納付方法欄にある「クレジットカード」「銀行振込」「その他」のうちご希望の方法いずれか一つに「○」をしてください。
・複数の物件について申し込みをされる場合、公有財産売却の物件ごとに申込書が必要になりますが、添付書類である住民票および印鑑登録証明書などは1通のみ提出してください。
（6）公有財産売却においては、特定の物件（売却区分）の売却が中止になること、もしくは公有財産売却の全体が中止になることがあります。

3. 公有財産売却の財産の権利移転などについての注意事項
（1）落札後、契約を締結した時点で、落札者に公有財産売却の財産にかかる危険負担が移転します。したがって、契約締結後に発生した財産の破損、焼失など●●●の責に帰すことのできない損害の負担は、落札者が負うこととなり、売払代金の減額を請求することはできません。
（2）落札者が売払代金の残金を納付した時点で、所有権は落札者に移転します。
（動産・自動車の場合）
（3）公有財産が動産、自動車などである場合、●●●はその公有財産の引渡しを売払代金納付時の現状有姿で行います。
（4）公有財産が自動車の場合、落札者は「使用の本拠の位置」を管轄する運輸支局または自動車検査登録事務所に当該自動車を持ち込み、移転登録（名義変更）の手続き等を行ってください。
（不動産の場合）
（5）●●●は、売払代金の残金を納付した落札者の請求により、権利移転の登記を関係機関に嘱託します。
（6）原則として、物件にかかわる調査、土壌調査およびアスベスト調査などは行っておりません 。また、開発など（建築など）に当たっては、都市計画法、建築基準法および条例などの法令により、規制がある場合があるので、事前に関係機関にご確認ください。

4. 個人情報の取り扱いについて
（1）公有財産売却に参加される方は、以下のすべてに同意するものとします。
ア．公有財産売却の参加申し込みを行う際に、住民登録などのされている住所、氏名など（参加者が法人の場合は、商業登記簿謄本に登記されている所在地、名称、代表者氏名）を公有財産売却の参加者情報として登録すること。
イ．入札者の公有財産売却の参加者情報およびログインIDに登録されているメールアドレスを●●●に開示され、かつ●●●がこれらの情報を●●●公文書管理規程に基づき、5年間保管すること。
・●●●から公有財産売却の参加者に対し、ログインIDで認証されているメールアドレスに、公有財産売却の財産に関するお知らせなどを電子メールにて送信することがあります。
ウ．落札者に決定された公有財産売却の参加者のログインIDに紐づく会員識別番号を売却システム上において一定期間公開されること。
エ．●●●は収集した個人情報を地方自治法施行令第167条の4第1項に定める参加条件の確認または同条第2項に定める一般競争入札の参加者の資格審査のための措置などを行うことを目的として利用します。（地方自治法施行令第167条の14で準用する「せり売り」の場合も含みます）
（2）公有財産売却の参加者情報の登録内容が住民登録や商業登記簿謄本の内容などと異なる場合は、落札者となっても所有権移転などの権利移転登記を行うことができません。

5. 共同入札について
（1）共同入札とは

一つの財産（不動産）を複数の者で共有する目的で入札することを共同入札といいます。

（2）共同入札における注意事項

ア．共同入札する場合は、共同入札者のなかから1名の代表者を決める必要があります。実際の公有財産売却の参加申し込み手続きおよび入札手続きをすることができるのは、当該代表者のみです。したがって、公有財産売却の参加申し込み手続きおよび入札手続きなどについては、代表者のログインIDで行うこととなります。手続きの詳細については、「第2 公有財産売却の参加申し込みおよび入札保証金の納付について」および「第3 入札形式で行う公有財産売却の手続き」をご覧ください。
イ．共同入札する場合は、共同入札者全員の印鑑登録証明書および共同入札者全員の住所（所在地）と氏名（名称）を連署した申込書を入札開始までに●●●に提出することが必要です。なお、申込書は●●●のホームページより印刷することができます。
ウ．申込書などに記載された内容が共同入札者の住民登録や商業登記簿の内容などと異なる場合は、共同入札者が落札者となっても権利移転登記を行うことができません。`
  },
  templateGuidelineAssetSale2: {
    title: '第2 公有財産売却の参加申し込みおよび入札保証金の納付について',
    content:  `入札するには、公有財産売却の参加申し込みと入札保証金の納付が必要です。公有財産売却の参加申し込みと入札保証金の納付が確認できたログインIDでのみ入札できます。

1. 公有財産売却の参加申し込みについて
売却システムの画面上で、住民登録などのされている住所、氏名など（参加者が法人の場合は、商業登記簿謄本に登記されている所在地、名称、代表者氏名）を公有財産売却の参加者情報として登録してください。
・法人で公有財産売却の参加申し込みする場合は、法人代表者名でログインIDを取得する必要があります。
・共同入札する場合は、売却システムの画面上で、共同入札の欄の「する」を選択し、公有財産売却の参加申し込みを行ってください。また、共同入札者全員の印鑑登録証明書および申込書を入札開始2開庁日前までに●●●に提出することが必要です。原則として、入札開始2開庁日前までに●●●が提出を確認できない場合、入札をすることができません。

2. 入札保証金の納付について
（1）入札保証金とは

地方自治法施行令第167条の7で定められている、入札する前に納付しなければならない金員です。入札保証金は、●●●が売却区分（公有財産売却の財産の出品区分）ごとに予定価格（最低落札価格）の〇分の〇以上の金額を定めます。

（2）入札保証金の納付方法

入札保証金の納付は、売却区分ごとに必要です。入札保証金は、●●●が売却区分ごとに指定する方法で納付してください。指定する方法は、下記のアのみ、イのみ、ウのみ、ア、イまたはウの3通りです。売却区分ごとに、売却システムの公有財産売却の物件詳細画面でどの方法が指定されているかを確認してください。
・入札保証金には利息を付しません。
・原則として、入札開始2開庁日前までに●●●が入札保証金の納付を確認できない場合、入札することができません。
ア．クレジットカードによる納付
クレジットカードで入札保証金を納付する場合は、売却システムの売却物件詳細画面より公有財産売却の参加仮申し込みを行い、入札保証金を所定の手続きに従って、クレジットカードにて納付してください。クレジットカードにより入札保証金を納付する公有財産売却の参加申込者は、紀尾井町戦略研究所株式会社に対し、クレジットカードによる入札保証金納付および返還事務に関する代理権を付与し、クレジットカードによる請求処理をSBペイメントサービス株式会社に委託することを承諾します。公有財産売却の参加申込者は、公有財産売却が終了し、入札保証金の返還が終了するまでこの承諾を取り消せないことに同意するものとします。
また、公有財産売却の参加申込者は、紀尾井町戦略研究所株式会社が入札保証金取り扱い事務に必要な範囲で、公有財産売却の参加申込者の個人情報をSBペイメントサービス株式会社に開示することに同意するものとします。
売却システムの公有財産売却の物件詳細画面より仮申し込みを行った後、●●●のホームページより申込書を印刷し、必要事項を記入・押印後、必要書類を添付のうえ、●●●に送付または持参してください。（郵送の場合は申込締切日の消印有効）
・申込書の入札保証金納付方法欄の「クレジット」に「○」をしてください。
・VISA、マスターカード、JCB、ダイナースカード、アメリカンエキスプレスカードの各クレジットカードを利用できます。（各クレジットカードでもごく一部利用できないクレジットカードがあります）
・法人で公有財産売却に参加する場合、当該法人の代表者名義のクレジットカードをご使用ください。
イ．銀行振込による納付
銀行振込などで入札保証金を納付する場合は、売却システムの公有財産売却の物件詳細画面より公有財産売却の参加仮申し込みを行ってください。売却システムの公有財産売却の物件詳細画面より仮申し込みを行った後、●●●のホームページより申込書を印刷し、必要事項を記載・押印後、必要書類を添付のうえ、●●●に送付または持参してください。（郵送の場合は申込締切日の消印有効）
なお、銀行振込の場合は、公有財産売却の参加者より必要書類が●●●に到着後、●●●から「歳入歳出外現金収納済通知書」を送付しますので、必要事項を記入のうえ、●●●が指定する金融機関に入札保証金を納付してください。
・銀行振込の際の振込手数料は公有財産売却の参加申込者の負担となります。
・銀行口座への振込により入札保証金を納付する場合は、●●●が納付を確認できるまで3開庁日程度要することがあります。
・申込書の入札保証金納付方法欄の「銀行振込」に「○」をしてください。
・●●●が指定する金融機関については、下記を参照してください。
1 指定金融機関
●●●
2 指定代理金融機関
●●●
3 収納代理金融機関
株式会社●●●銀行、株式会社●●●銀行、株式会社●●●銀行
※いずれも日本国内で業務を営むすべての店舗（代理店を除く）
ウ．その他の方法による納付
納付方法はクレジットカードおよび銀行振込以外に、現金書留、郵便為替（ゆうちょ銀行が取り扱う普通為替および定額小為替をいう。以下同じ）および直接持参があります。（物件ごとに指定されている場合があります）
※売却システムで参加仮申し込みされる際には「銀行振り込みなど」を選択していただくことになります。
売却システムの公有財産売却の物件詳細画面より仮申し込みを行った後、●●●のホームページより申込書を印刷し、必要事項を記入・押印後、住民票（法人の場合は商業登記簿謄本）および印鑑登録証明書を添付のうえ、●●●に送付または持参してください。（郵送の場合は、申込締切日の消印有効）
（ア）現金書留または郵便為替の場合
最寄りのゆうちょ銀行で納付してください。
（イ）直接持参の場合
●●●に直接持参してください。
・申込書の入札保証金納付方法欄の「その他」に「○」をしてください。
・現金書留による送付または直接持参により入札保証金を納付する場合、現金もしくは銀行振出の小切手（電子交換所に加入する銀行が振り出し、振出日より5日以内のもので、受取人は持参人払いとしたものに限ります） で●●●に納付してください。
・郵便為替により入札保証金を納付する場合、郵便為替証書は、発行日から起算して175日を経過していないものに限ります。
・現金書留の郵送料または為替手数料などについては、公有財産売却の参加申込者の負担となります。
・直接持参の場合は、持ち込んだその日に金融機関に納入手続を行いますので、14時までに持ち込む必要があります。
・郵便為替または現金書留により入札保証金を納付する場合は、原則として申込書、必要書類を同封してください。

（3）入札保証金の没収

公有財産売却の参加申込者が納付した入札保証金は、落札者が契約締結期限までに●●●の定める契約を締結しない場合は没収し、返還しません。

（4）入札保証金の契約保証金への充当

公有財産売却の参加申込者が納付した入札保証金は、落札者が契約を締結した場合、申請書に基づき、地方自治法施行令第167条の16に定める契約保証金に全額充当します。`
  },
  templateGuidelineAssetSale3: {
    title: '第3 入札形式で行う公有財産売却の手続き',
    content:  `本章における入札とは、売却システム上で入札価格を登録することをいいます。この登録は、一度しか行うことができません。

1. 公有財産売却への入札
（1）入札

入札保証金の納付が完了したログインIDでのみ、入札が可能です。入札は一度のみ可能です。一度行った入札は、入札者の都合による取り消しや変更はできませんので、ご注意ください。

（2）入札をなかったものとする取り扱い

●●●は、地方自治法施行令第167条の4第1項などに規定する一般競争入札に参加できない要件に該当する者が行った入札について、当該入札を取り消し、なかったものとして取り扱うことがあります。

2. 落札者の決定
（1）落札者の決定

入札期間終了後、●●●は開札を行い、売却区分（公有財産売却の財産の出品区分）ごとに、売却システム上の入札において、入札価格が予定価格（最低落札価格）以上でかつ最高価格である入札者を落札者として決定します。ただし、最高価格での入札者が複数存在する場合は、くじ（自動抽選）で落札者を決定します。
なお、落札者の決定に当たっては、落札者のログインIDに紐づく会員識別番号を落札者の氏名（名称）とみなします。
ア．落札者の告知
落札者のログインIDに紐づく会員識別番号と落札価格については、売却システム上に一定期間公開します。
イ．●●●から落札者への連絡
落札者には、●●●から入札終了後、あらかじめログインIDで認証されたメールアドレスに、落札者として決定された旨の電子メールを送信します。共同入札者が落札者となった場合は、代表者にのみ落札者として決定された旨の電子メールを送信します。
・●●●が落札者に送信した電子メールが、落札者によるメールアドレスの変更やプロバイダの不調などの理由により到着しないために、●●●が落札者による売払代金の残金の納付を売払代金の残金納付期限までに確認できない場合、その原因が落札者の責に帰すべきものであるか否かを問わず、契約保証金を没収し、返還しません。

（2）落札者決定の取り消し

入札金額の入力間違いなどの場合は、落札者の決定が取り消されることがあります。この場合、売却物件の所有権は落札者に移転しません。また、納付された入札保証金は原則返還しません。

3. 売却の決定
（1）落札者に対する売却の決定

●●●は、落札後、落札者に対し電子メールなどにより契約締結に関する案内を行い、落札者と契約を交わします。
契約の際には●●●より契約書を送付しますので、落札者は必要事項を記入・押印のうえ、次の書類などを添付して●●●に直接持参または郵送してください。
ア．必要な書類
（動産の場合）
（ア）○○○○
（イ）○○○○
・・・・
（自動車の場合）
（ア）○○○○
（イ）○○○○
・・・・
（不動産の場合）
（ア）○○○○
（イ）○○○○
・・・・
イ．売却の決定金額
落札者が入札した金額を売却の決定金額とします。
ウ．落札者が契約を締結しなかった場合
落札者が契約締結期限までに契約を締結しなかった場合、落札者が納付した入札保証金は返還しません。

（2）売却の決定の取り消し

落札者が契約締結期限までに契約しなかったときおよび落札者が公有財産売却の参加仮申込みの時点で18歳未満の方など公有財産売却に参加できない者の場合に、売却の決定が取り消されます。
この場合、公有財産売却の財産の所有権は落札者に移転しません。また、納付された入札保証金は返還されません。

4. 売払代金の残金の納付
（1）売払代金の残金の金額

売払代金の残金は、落札金額から事前に納付した契約保証金（契約保証金に充当した入札保証金）を差し引いた金額となります。

（2）売払代金の残金納付期限について

落札者は、売払代金の残金納付期限までに●●●が納付を確認できるよう売払代金の残金を一括で納付してください。
売払代金の残金が納付された時点で、公有財産売却の財産の所有権が落札者に移転します。売払代金の残金納付期限までに売払代金の残金全額の納付が確認できない場合、事前に納付された契約保証金を没収し、返還しません。

（3）売払代金の残金の納付方法

売払代金の残金は次の方法で納付してください。なお、売払代金の残金の納付にかかる費用は、落札者の負担となります。また、売払代金の残金納付期限までに●●●が納付を確認できることが必要です。
ア．●●●が用意する納付書による納付
イ．現金書留による送付（金額が50万円以下の場合のみ）
ウ．郵便為替による納付
・発行日から起算して175日を経過していないものに限ります。
エ．現金もしくは銀行振出の小切手を●●●へ直接持参
・銀行振出の小切手は、電子交換所に加入する銀行が振り出し、振出日より5日以内のもので、受取人は持参人払いとしたものに限ります。

5. 入札保証金の返還
（1）落札者以外への入札保証金の返還

落札者以外の納付した入札保証金は、入札終了後全額返還します。
なお、公有財産売却の参加申し込みを行ったものの入札を行わない場合にも、入札保証金の返還は入札終了後となります。
入札保証金返還の方法および返還に要する期間は次のとおりです。
ア．クレジットカードによる納付の場合
SBペイメントサービス株式会社は、クレジットカードにより納付された入札保証金を返還する場合、クレジットカードからの入札保証金の引き落としを行いません。
ただし、公有財産売却の参加者などのクレジットカードの引き落としの時期などの関係上、いったん実際に入札保証金の引き落としを行い、翌月以降に返還を行う場合がありますので、ご了承ください。
イ．銀行振込などによる納付の場合
入札保証金の返還方法は、公有財産売却の参加者が指定する銀行口座への振込のみとなります。公有財産売却の参加者（入札保証金返還請求者）名義の口座のみ指定可能です。共同入札の場合は、仮申し込みを行った代表者名義の口座のみ指定可能です。
なお、入札保証金の返還には、入札期間終了後4週間程度要することがあります。`
  },
  templateGuidelineAssetSale4: {
    title: '第4 せり売形式で行う公有財産売却の手続き',
    content:  `せり売形式の売却システムは、紀尾井町戦略研究所株式会社の提供する自動入札システムおよび入札単位を使用しています。
本章における入札とは、売却システム上の「入札額」欄へ希望落札金額の上限を入力することおよび入力した上限以下の範囲で行われる自動入札をいいます。また、本章においては、「入札」はせり売形式の入札を、「入札者」はせり売りの参加申込者を、「入札期間」はせり売期間を指します。

1. 公有財産売却への入札
（1）入札

入札保証金の納付が完了したログインIDでのみ、入札が可能です。入札は、入札期間中であれば何回でも可能です。ただし、売却システム上の「現在価格」または一度「入札額」欄に入力した金額を下回る金額を「入札額」欄に入力することはできません。一度行った入札は、入札参加者などの都合による取り消しや変更はできませんので、ご注意ください。なお、入札期間の自動延長は行いません。

（2）入札をなかったものとする取り扱い

●●●は、地方自治法施行令第167条の4第1項などに規定する一般競争入札に参加できない要件に該当する者が行った入札について、当該入札を取り消し、なかったものとして取り扱うことがあります。入札期間中にその時点における最高価格の入札をなかったものとした場合、当該入札に次ぐ価格の入札を最高価格の入札とし、せり売りを続行します。

2. 落札者の決定など
（1）落札者の決定

入札期間終了後、●●●は開札を行い、売却区分（公有財産売却の財産の出品区分）ごとに、売却システム上の入札において、入札価格が予定価格（最低落札価格）以上でかつ最高価格である入札者を落札者として決定します。また、売却システム上では、2人以上が同額の入札価格（上限）を設定した場合、先に設定した人を落札者として決定します。

（2）せり売終了の告知など

●●●は、落札者を決定したときは、落札者のログインIDに紐づく会員識別番号と落札価格を売却システム上に一定期間公開することによって告げ、せり売終了を告知します。

（3）●●●から落札者への連絡

落札者には、●●●から入札終了後、あらかじめログインIDで認証されたメールアドレスに、落札者として決定された旨の電子メールを送信します。共同入札者が落札者となった場合は、代表者にのみ落札者として決定された旨の電子メールを送信します。
・●●●が落札者に送信した電子メールが、落札者によるメールアドレスの変更やプロバイダの不調などの理由により到着しないために、●●●が落札者による売払代金の残金の納付を売払代金の残金納付期限までに確認できない場合、その原因が落札者の責に帰すべきものであるか否かを問わず、保証金を没収し、返還しません。

（4）落札者決定の取り消し

入札金額の入力間違いなどの場合は、落札者の決定が取り消されることがあります。この場合、売却物件の所有権は落札者に移転しません。また、納付された入札保証金は原則返還しません。

3. 売却の決定
（1）落札者に対する売却の決定

●●●は、落札後、落札者に対し電子メールなどにより契約締結に関する案内を行い、落札者と契約を交わします。
契約の際には●●●より契約書を送付しますので、落札者は必要事項を記入・押印のうえ、次の書類などを添付して●●●に直接持参または郵送してください。
ア．必要な書類
（ア）〇〇〇〇
（イ）○○○○
・・・・
イ．売却の決定金額
落札者が入札した金額を売却の決定金額とします。
ウ．落札者が契約を締結しなかった場合
落札者が契約締結期限までに契約を締結しなかった場合、落札者が納付した入札保証金は返還しません。

（2）売却の決定の取り消し

落札者が契約締結期限までに契約しなかったときおよび落札者が公有財産売却の参加仮申込みの時点で18歳未満の方など公有財産売却に参加できない者の場合に、売却の決定が取り消されます。
この場合、公有財産売却の財産の所有権は落札者に移転しません。また、納付された入札保証金は返還されません。

4. 売払代金の残金の納付
（1）売払代金の残金の金額

売払代金の残金は、落札金額から事前に納付した契約保証金（契約保証金に充当した入札保証金）を差し引いた金額となります。

（2）売払代金の残金納付期限について

落札者は、売払代金の残金納付期限までに●●●が納付を確認できるよう売払代金の残金を一括で納付してください。
売払代金の残金が納付された時点で、公有財産売却の財産の所有権が落札者に移転します。売払代金の残金納付期限までに売払代金の残金全額の納付が確認できない場合、事前に納付された保証金を没収し、返還しません。

（3）売払代金の残金の納付方法

売払代金の残金は次の方法で納付してください。なお、売払代金の残金の納付にかかる費用は、落札者の負担となります。また、売払代金の残金納付期限までに●●●が納付を確認できることが必要です。
ア．●●●が用意する納付書による納付
イ．現金書留による送付（金額が50万円以下の場合のみ）
ウ．郵便為替による納付
・発行日から起算して175日を経過していないものに限ります。
エ．現金もしくは銀行振出の小切手を●●●へ直接持参
・銀行振出の小切手は、電子交換所に加入する銀行が振り出し、振出日より5日以内のもので、受取人は持参人払いとしたものに限ります。

5. 入札保証金の返還
（1）落札者以外への入札保証金の返還

落札者以外の納付した入札保証金は、入札終了後全額返還します。
なお、公有財産売却の参加申し込みを行ったものの入札を行わない場合にも、入札保証金の返還は入札終了後となります。
入札保証金返還の方法および返還に要する期間は次のとおりです。
ア．クレジットカードによる納付の場合
SBペイメントサービス株式会社は、クレジットカードにより納付された入札保証金を返還する場合、クレジットカードからの入札保証金の引き落としを行いません。
ただし、公有財産売却の参加者などのクレジットカードの引き落としの時期などの関係上、いったん実際に入札保証金の引き落としを行い、翌月以降に返還を行う場合がありますので、ご了承ください。
イ．銀行振込などによる納付の場合
入札保証金の返還方法は、公有財産売却の参加者が指定する銀行口座への振込のみとなります。公有財産売却の参加者（入札保証金返還請求者）名義の口座のみ指定可能です。共同入札の場合は、仮申し込みを行った代表者名義の口座のみ指定可能です。
なお、入札保証金の返還には、入札期間終了後4週間程度要することがあります。`
  },
  templateGuidelineAssetSale5: {
    title: '第5 公有財産売却の財産の権利移転および引き渡しについて',
    content:  `●●●は、落札後、落札者と売買契約を交わします。
契約の際には●●●より契約書を送付しますので、落札者は必要事項を記入・押印のうえ、契約金額に応じた収入印紙を貼付し、直接持参または郵送してください。（自動車の場合は、収入印紙は不要です。）
自動車・物品は、売払代金の残金納付確認後、売却代金納付時の現状のままで、●●●が指定する場所において直接引渡します。指定場所まで来られない場合は、落札者負担で対応してください。
不動産は、権利移転登記完了後、登記完了を証明する書類をお渡しします。現地での引渡しは行いません。

1. 権利移転の時期
公有財産売却の財産は、売払代金の残金を納付したときに権利移転します。

2. 権利移転の手続きについて
（1）不動産の場合

ア．売払代金の残金納付確認後、落札者の請求に基づいて●●●が不動産登記簿上の権利移転のみを行いますので、●●●のホームページより「所有権移転登記請求書」を印刷した後、必要事項を記入・押印して、売払代金の残金納付期限までに提出してください。
なお、売払代金の残金納付期限は●●●が指定する日となります。
イ．共同入札の場合は、共同入札者全員が記入・押印した「所有権移転登記請求書」および共同入札者全員の住民票（法人の場合は、法人登記事項証明書（現在事項証明書又は代表者事項証明書））の提出が必要です。また、公有財産売却の財産の持分割合は、移転登記前に●●●に対して任意の書式にて申請してください。
ウ．所有権移転の登記が完了するまで、所有権移転登記請求書提出後２週間程度の期間を要することがあります。

（2）自動車の場合

ア．落札者は、「使用の本拠の位置」を管轄する運輸支局または自動車検査登録事務所に当該自動車を持ち込んでいただくことが必要です。
イ．譲渡証明書に記載する譲受人の名義は、落札者本人となります。落札者本人以外の名義にはできません。

3. 注意事項
落札後、契約を締結した時点で、落札者に公有財産売却の財産にかかる危険負担は落札者に移転します。したがって、契約締結後に発生した財産の破損、焼失など●●●の責に帰すことのできない損害の負担は、落札者が負うこととなり、売払代金の減額を請求することはできません。
なお、落札代金の残金を納付した時点で所有権は落札者に移転します。

4. 引き渡しおよび権利移転に伴う費用について
（不動産の場合）
（1）権利移転に伴う費用（移転登記の登録免許税、登記嘱託書の郵送料など）は落札者の負担となります。
（2）所有権移転などの登記を行う際は、登録免許税法に定める登録免許税相当分の収入印紙または登録免許税を納付したことを証する領収証書が必要となります。
ア．売払代金の残金を銀行振込で納付する場合
売払代金の残金を納付後、収入印紙などを●●●に送付してください。
イ．売払代金の残金を持参する場合
収入印紙などを併せて持参してください。
共同入札者が落札者となった場合、登録免許税相当分の収入印紙または登録免許税を納付したことを証する領収証書は、共同入札者の人数分だけ必要となります。共同入札者は、各々の持分に応じた登録免許税相当額を納付してください。（実際に持参または送付する場合は全共同入札者の合計で構いません）
・所有権移転登記を行う際に、●●●と所管の法務局との間で登記嘱託書などの書類を送付するために郵送料（切手1500円程度）が必要です。
（自動車の場合）
（1）権利移転に伴う費用（自動車検査登録印紙および自動車審査証紙、自動車税環境性能割など）は落札者の負担となります。
ア. 移転登録などの手数料として自動車検査登録印紙および自動車審査証紙が必要です。
イ. 自動車税環境性能割及び自動車税は落札者が自ら申告、納税してください。`
  },
  templateGuidelineAssetSale6: {
    title: '第6 注意事項',
    content:  `1. 売却システムに不具合などが生じた場合の対応
（1）公有財産売却の参加申し込み期間中

売却システムに不具合などが生じたために、以下の状態となった場合は公有財産売却の手続きを中止することがあります。
ア．公有財産売却の参加申し込み受付が開始されない場合
イ．公有財産売却の参加申し込み受付ができない状態が相当期間継続した場合
ウ．公有財産売却の参加申し込み受付が入札開始までに終了しない場合
エ．公有財産売却の参加申し込み受付終了時間後になされた公有財産売却の参加申し込みを取り消すことができない場合

（2）入札期間中

売却システムに不具合などが生じたために、以下の状態となった場合は公有財産売却の手続きを中止することがあります。
ア．入札の受付が開始されない場合
イ．入札できない状態が相当期間継続した場合
ウ．入札の受付が入札期間終了時刻に終了しない場合

（3）入札期間終了後

売却システムに不具合などが生じたために、以下の状態となった場合は公有財産売却の手続きを中止することがあります。
ア．一般競争入札形式において入札期間終了後相当期間経過後も開札ができない場合
イ．くじ（自動抽選）が必要な場合でくじ（自動抽選）が適正に行えない場合
ウ．せり売形式において入札終了後相当期間経過後も落札者を決定できない場合

2. 公有財産売却の中止
公有財産売却の参加申し込み開始後に公有財産売却を中止することがあります。公有財産売却の財産の公開中であっても、やむを得ない事情により、公有財産売却を中止することがあります。

（1）特定の公有財産売却の特定の売却区分（売却財産の出品区分）の中止時の入札保証金の返還

特定の公有財産売却の物件の公有財産売却が中止となった場合、当該公有財産売却の物件について納付された入札保証金は中止後返還します。なお、銀行振込などにより入札保証金を納付した場合、返還まで中止後4週間程度要することがあります。

（2）公有財産売却の中止時の入札保証金の返還

公有財産売却の全体が中止となった場合、入札保証金は中止後返還します。なお、銀行振込などにより入札保証金を納付した場合、返還まで中止後4週間程度要することがあります。

3. 公有財産売却の参加を希望する者、公有財産売却の参加申込者および入札者など（以下「入札者など」という）に損害などが発生した場合
（1）公有財産売却が中止になったことにより、入札者などに損害が発生した場合、●●●は損害の種類・程度にかかわらず責任を負いません。
（2）売却システムの不具合などにより、入札者などに損害が発生した場合、●●●は損害の種類・程度にかかわらず責任を負いません。
（3）入札者などの使用する機器および公有財産売却の参加者などの使用するネットワークなどの不備、不調その他の理由により、公有財産売却の参加申し込みまたは入札に参加できない事態が生じた場合においても、●●●は代替手段を提供せず、それに起因して生じた損害について責任を負いません。
（4）公有財産売却に参加したことに起因して、入札者などが使用する機器およびネットワークなどに不備、不調などが生じたことにより入札者などに損害が発生した場合、●●●は損害の種類・程度にかかわらず責任を負いません。
（5）公有財産売却の参加者などが入札保証金を自己名義（法人の場合は当該法人代表者名義）のクレジットカードで納付する場合で、クレジットカード決済システムの不備により、入札保証金の納付ができず公有財産売却の参加申し込みができないなどの事態が発生したとき、それに起因して入札者などに生じた損害について、●●●は損害の種類・程度にかかわらず責任を負いません。
（6）公有財産売却の参加者などの発信もしくは受信するデータが不正アクセスおよび改変などを受け、公有財産売却の参加続行が不可能となるなどの被害を受けた場合、その被害の種類・程度にかかわらず、●●●は責任を負いません。
（7）公有財産売却の参加者などが、自身のログインIDおよびパスワードなどを紛失もしくは、ログインIDおよびパスワードなどが第三者に漏えいするなどして被害を受けた場合、その被害の種類・程度にかかわらず●●●は責任を負いません。

4. 公有財産売却の参加申し込み期間および入札期間
公有財産売却の参加申し込み期間および入札期間は、売却システム上の公有財産売却の物件詳細画面上に示された期間となります。ただし、システムメンテナンスなどの期間を除きます。

5. リンクの制限など
●●●が売却システム上に情報を掲載しているウェブページへのリンクについては、●●●物件一覧のページ以外のページへの直接のリンクはできません。
また、売却システム上において、●●●が公開している情報（文章、写真、図面など）について、●●●に無断で転載・転用することは一切できません。

6. システム利用における禁止事項
売却システムの利用にあたり、次に掲げる行為を禁止します。
（1）売却システムをインターネット公有財産売却の手続き以外の目的で不正に利用すること。
（2）売却システムに不正にアクセスをすること。
（3）売却システムの管理および運営を故意に妨害すること。
（4）売却システムにウイルスに感染したファイルを故意に送信すること。
（5）法令もしくは公序良俗に違反する行為またはそのおそれのある行為をすること。
（6）その他売却システムの運用に支障を及ぼす行為またはそのおそれのある行為をすること。

7. 準拠法
このガイドラインには、日本法が適用されるものとします。

8. インターネット公有財産売却において使用する通貨、言語、時刻など
（1）インターネット公有財産売却の手続きにおいて使用する通貨

インターネット公有財産売却の手続きにおいて使用する通貨は、日本国通貨に限り、入札価格などの金額は、日本国通貨により表記しなければならないものとします。

（2）インターネット公有財産売却の手続きにおいて使用する言語

インターネット公有財産売却の手続きにおいて使用する言語は、日本語に限ります。売却システムにおいて使用する文字は、JIS第1第2水準漢字（JIS（産業標準化法（昭和24年法律第185号）第20条第1項の日本産業規格）X0208をいいます）であるため、不動産登記簿上の表示などと異なることがあります。

（3）インターネット公有財産売却の手続きにおいて使用する時刻

インターネット公有財産売却の手続きにおいて使用する時刻は、日本国の標準時によります。

9. ●●●インターネット公有財産売却ガイドラインの改正
●●●は、必要があると認めるときは、このガイドラインを改正することができるものとします。
なお、改正を行った場合には、●●●は売却システム上に掲載することにより公表します。改正後のガイドラインは、公表した日以降に売却参加申し込みの受付を開始するインターネット公有財産売却から適用します。

10. その他
官公庁オークションサイトに掲載されている情報で、●●●が掲載したものでない情報については、●●●インターネット公有財産売却に関係する情報ではありません。`
  }
}