import { validateInputsNotices } from 'interfaces/components/News/kmanager/validateInputsNotices.jsx';

const validateInputFilter = (condition, isIncludes) => {
  const inputs = validateInputsNotices();

  if (isIncludes) {
    return inputs.filter(object => condition.includes(object.column));
  }
  return inputs.filter(object => !condition.includes(object.column));
};

export default validateInputFilter;
