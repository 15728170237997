
export class Deadline {
    #deadline

    constructor(deadline) {
        this.#deadline = deadline;
    }

    get() {
        return this.#deadline
    }

    get isValid() {
        return [
        ].every(result => result === 'TRUE');
    }
}
