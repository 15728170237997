import { propertiesManagement } from "interfaces/pages/PropertiesManagement/entities/componentMapping.js";
import PropertiesManagementProvider from 'interfaces/pages/PropertiesManagement/services/PropertiesManagementProvider';
import { getComponent } from "shared/utils/helper/get_component";

const PropertiesManagement = ({ type }) => {
  return (
    <PropertiesManagementProvider type={type}>
      {getComponent(propertiesManagement, type)}
    </PropertiesManagementProvider>
  );
};

export default PropertiesManagement;
