const propertyStatusToNum = (status) => {
  switch (status) {
    case 'CANCEL':
      return 0;
    case 'APPLYING':
      return 1;
    case 'WAIT_START_BID':
      return 2;
    case 'DURING_BIDDING':
      return 3;
    case 'WAIT_OPEN_BID':
      return 4;
    case 'ADDITIONAL_DURING_BIDDING':
      return 5;
    case 'DONE_BIDDING':
      return 6;
    case 'END_BIDDING':
      return 7;
    case 'CLOSE':
      return 8;
  }
};

export default propertyStatusToNum;
