import { DATA_EMPTY } from 'shared/utils/helper/constants';

const isEmpty = [DATA_EMPTY, undefined, ''];

export const estate_land_info = (data) => [
  {
    text: '土地面積',
    state: isEmpty.includes(data?.landSpace) ? '未入力' : data?.landSpace,
  },
  {
    text: '土地権利',
    state: isEmpty.includes(data?.landRights) ? '未入力' : data?.landRights,
  },
  {
    text: '土地持分',
    state: isEmpty.includes(data?.landShare.first)
      ? '未入力'
      : data?.landShare.first,
  },
  {
    text: '地目',
    state: isEmpty.includes(data?.landCategory) ? '未入力' : data?.landCategory,
  },
  {
    text: '都市計画地域',
    state: isEmpty.includes(data?.cityPlanningArea)
      ? '未入力'
      : data?.cityPlanningArea,
  },
  {
    text: '用途地域',
    state: isEmpty.includes(data?.restrictedArea)
      ? '未入力'
      : data?.restrictedArea,
  },
  {
    text: '建ぺい率(%)',
    state: isEmpty.includes(data?.buildRatio) ? '未入力' : data?.buildRatio,
  },
  {
    text: '容積率(%)',
    state: isEmpty.includes(data?.floorAreaRatio)
      ? '未入力'
      : data?.floorAreaRatio,
  },
  {
    text: '高度地区(種)',
    state: isEmpty.includes(data?.highDistrict) ? '未入力' : data?.highDistrict,
  },
  {
    text: '防火地域',
    state: isEmpty.includes(data?.firebreakArea)
      ? '未入力'
      : data?.firebreakArea,
  },
  {
    text: '日影規制',
    state: isEmpty.includes(data?.shadeRegulation)
      ? '未入力'
      : data?.shadeRegulation,
  },
  {
    text: 'その他法令などの規制',
    state: isEmpty.includes(data?.lawRegulation)
      ? '未入力'
      : data?.lawRegulation,
  },
  {
    text: '地勢など',
    state: isEmpty.includes(data?.geographical) ? '未入力' : data?.geographical,
  },
  {
    text: '幅員、接道状況',
    state: isEmpty.includes(data?.neighboringRoad)
      ? '未入力'
      : data?.neighboringRoad,
  },
  {
    text: '私道の負担など',
    state: isEmpty.includes(data?.provideEquipment)
      ? '未入力'
      : data?.provideEquipment,
  },
  {
    text: '供給処理施設',
    state: isEmpty.includes(data?.provideEquipment)
      ? '未入力'
      : data?.provideEquipment,
  },
  {
    text: 'その他土地に関する物件情報',
    state: isEmpty.includes(data?.landOther) ? '未入力' : data?.landOther,
  },
];
