import Modal2 from 'interfaces/components/common/Modal/Modal2';
import { useEffect, useState } from 'react';

import ConfirmNumberModalContents from './ConfirmNumberModalContents';
import ResetEmailModalContents from './ResetEmailModalContents';
import CompleteModalContents from '../CompleteModalContents';

const ResetEmailModal = ({ isOpen, handleClose, modalType, setModalType }) => {
  const [targetEmail, setTargetEmail] = useState('');

  useEffect(() => {
    setModalType('EMAIL');
  }, [isOpen]);

  const targetChild = () => {
    if (modalType === 'EMAIL') {
      return (
        <ResetEmailModalContents
          handleClose={handleClose}
          setModalType={setModalType}
          setTargetEmail={setTargetEmail}
        />
      );
    }

    if (modalType === 'CONFIRM') {
      return (
        <ConfirmNumberModalContents
          handleClose={handleClose}
          setModalType={setModalType}
          targetEmail={targetEmail}
        />
      );
    }

    if (modalType === 'COMPLETE') {
      return (
        <CompleteModalContents
          title="メールアドレス変更完了"
          body="メールアドレスを変更しました。"
          buttonText="OK"
          handleClose={handleClose}
        />
      );
    }
  };

  return (
    <Modal2 isOpen={isOpen} modalSize={'420px'}>
      {targetChild()}
    </Modal2>
  );
};

export default ResetEmailModal;
