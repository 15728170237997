import "../../../../interfaces//css/common/heading.css"

const Heading1 = ({ children }) => {
  return (
    <h1 className="heading1" data-testid="heading-1">
      {children}
    </h1>
  );
}

export default Heading1;