export const ServiceTypes = {
  NAVI: { string: 'NAVI', jp: 'ナビ' },
  KMANAGER: { string: 'KMANAGER', jp: '運営管理' },
  SERVICE: { string: 'SERVICE', jp: 'KSIオークション' },
};

export const UserKeyByService = {
  [ServiceTypes.NAVI.string]: "naviUserId",
  [ServiceTypes.KMANAGER.string]: "kmanagerUserId",
  [ServiceTypes.SERVICE.string]: "auctionUserId",
};