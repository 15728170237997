import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle.jsx';
import { useProgress } from 'shared/hooks/useProgress.js';

import ScheduleSection from './ScheduleSection.jsx';


const NearlySchedule = () => {
  const { progressSchedules } = useProgress();

  const nearlySchedule = [
    {
      title: 'インターネット公売（国税）',
      titleColor: 'var(--accent-base2)',
      termData: progressSchedules?.nationalTax,
      className: 'national-tax-auction-schedule'
    },
    {
      title: '公有財産売却',
      titleColor: 'var(--accent-base3)',
      termData: progressSchedules?.assetSale,
      className: 'asset-sale-schedule'
    },
    {
      title: 'インターネット公売',
      titleColor: 'var(--accent-base4)',
      termData: progressSchedules?.publicSale,
      className: 'auction-schedule'
    }
  ];

  return (
    <div data-testid="nearly-schedule" className="pt-6">
      <ArticleDefaultTitle title="開催中のスケジュール"/>
      <ArticleDefaultBody>
        {nearlySchedule.map((schedule, index) =>
          <ScheduleSection key={index} schedule={schedule} index={index}/>
        )}
      </ArticleDefaultBody>
    </div>
  );
};

export default NearlySchedule;
