import RadioInput from 'interfaces/components/common/Input/RadioInput.jsx';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel.jsx';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault.jsx';
import { memo } from 'react';

export const arrangements = [
  { id: 1, label: '出品機関の職員に手配を依頼する', value: 'REQUEST' },
  { id: 2, label: '自分で手配する', value: 'MYSELF' },
];

const SelectArrangementInput = memo(function SelectArrangementInput({
  targetArrangement,
  setTargetArrangement,
}) {
  return (
    <CommonTableDefault>
      <tr>
        <th>
          手配について
          <RequiredLabel />
        </th>
        <td className="flex" data-testid="topic-input">
          {arrangements.map((arrangement) => (
            <RadioInput
              key={arrangement.id}
              item={arrangement}
              label={arrangement.label}
              value={targetArrangement}
              handleClick={() => setTargetArrangement(arrangement.value)}
            />
          ))}
        </td>
      </tr>
    </CommonTableDefault>
  );
});

export default SelectArrangementInput;
