// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import CustomError from '../../../shared/utils/helper/customError.js';
import { NotUndefined } from '../../../shared/utils/helper/NotUndefined.js';

export class DepositGet {
  constructor(data) {
    this.auctionId = data.auctionId;
    this.divisionId = data.divisionId;
    this.returnDate = data.returnDate;
    this.estimatedInvoiceDate = data.estimatedInvoiceDate;
    this.estimatedReturnDate = data.estimatedReturnDate;
    //if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class DepositDetailGet {
  constructor(data) {
    this.governmentSubCategoryId = data.governmentSubCategoryId;
    this.location = data.location;
    this.GovernmentName = data.GovernmentName;
    this.DivisionName = data.DivisionName;
    this.DepositPayment = data.DepositPayment;
    this.FinancialInstitutionName = data.FinancialInstitutionName;
    this.BranchName = data.BranchName;
    this.AccountType = data.AccountType;
    this.AccountNumber = data.AccountNumber;
    this.AccountName = data.AccountName;
    this.AccountNameKana = data.AccountNameKana;
    this.PaymentProxy = data.PaymentProxy;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

// 運営管理の公売（入札）保証金管理 参考：/auction_matching/rust/src/management_controller/auctions/show/deposits/divisions_list.rs
export class OPEMGRDepositListGet {
  constructor(props) {
    this.divisionId = props.id;
    this.location = props.location;
    this.prefecture = props.prefecture;
    this.governmentName = props.governmentName;
    this.divisionName = props.divisionName;
    this.depositProxy = props.depositProxy;
    this.estimatedInvoiceDate = props.estimatedInvoiceDate;
    this.estimatedReturnDate = props.estimatedReturnDate;
    this.depositRefundClaim = props.depositRefundClaim;
    if (!this.isValid) throw new CustomError('DepositList の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

//export class DepositCreate {
//
//  constructor(data) {
//    this.auctionId = new AuctionId(data.auctionId);
//    this.divisionId = new DivisionId(data.divisionId);
//    this.returnDate = new ReturnDate(data.returnDate);
//    this.estimatedInvoiceDate = new EstimatedInvoiceDate(data.estimatedInvoiceDate);
//    this.estimatedReturnDate = new EstimatedReturnDate(data.estimatedReturnDate);
//    if (!this.isValid) throw new CustomError("Message の値が不正です")
//  }
//
//  get isValid() {
//    return this.auctionId.isValid &&
//      this.divisionId.isValid &&
//      this.returnDate.isValid &&
//      this.estimatedInvoiceDate.isValid &&
//      this.estimatedReturnDate.isValid
//  }
//}
