import { ReactComponent as EyeIcon } from "interfaces/assets/svg/eye.svg";
import FlatButton from "interfaces/components/common/Button/FlatButton.jsx";
import "interfaces/css/PropertyList/property-table.css";

const PropertyTable = () => {
  return (
    <div className="property-table-wrap">
      <div className="property-table" data-testid="copy-dialog-property-table">
        <table>
          <thead>
            <tr>
              <th>区分番号</th>
              <th>タイトル</th>
              <th>見積価格</th>
              <th>公売保証金額</th>
              <th>更新日</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1111111</td>
              <td>サンプル</td>
              <td>1,000,000円</td>
              <td>1,000円</td>
              <td>2023年08月18日 15時35分</td>
              <td>
                <FlatButton icon={<EyeIcon/>}>プレビュー</FlatButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PropertyTable;