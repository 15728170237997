import { validateNumber, validateRangeLength } from "../../../../application/validations.js";

export class AccountNumber {
  #accountNumber

  constructor(accountNumber) {
    this.#accountNumber = accountNumber;
  }

  get() {
    return this.#accountNumber
  }

  get isValid() {
    return [
      this.validateNumber,
      this.validateRangeLength,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateNumber() {
    return validateNumber(this.#accountNumber)
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#accountNumber, 1, 8)
  }
}
