import {
  findById as auctionFindById,
  get as getAuctions,
} from 'domain/Auction/services/Auction.js';
import {
  findById as invitationFindById,
  get as getInvitations,
} from 'domain/Auction/services/Invitation.js';
import {
  get as getGovernments,
  findById as getGovernment,
} from 'domain/Government/services/Government.js';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from 'shared/utils/helper/authState.js';
import { SERVICE, NAVI, KMANAGER } from 'shared/utils/helper/constants.js';

import { breadcrumbs } from '../entities/breadcrumbs.js';
import { PropertiesManagementContext } from '../entities/PropertiesManagementContext.js';

const PropertiesManagementProvider = ({ type, children }) => {
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [invitation, setInvitation] = useState([]);
  const [auction, setAuction] = useState([]);
  const [governments, setGovernments] = useState([]);
  const [government, setGovernment] = useState(undefined);
  const { auction_id } = useParams();
  const { localStorageGovernmentId } = useLocalStorage();

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getInvitations(type).then((result) => {
        setInvitation(result);
      });
    }, []);
    useEffect(() => {
      getAuctions(type).then((result) => {
        setAuction(result);
      });
    }, []);
    useEffect(() => {
      getGovernments(type).then((result) => {
        setGovernments(result);
      });
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      invitationFindById(type, auction_id, localStorageGovernmentId).then(
        (invitation) => {
          if (invitation !== undefined) {
            setInvitation(invitation);
            auctionFindById(type, invitation.auctionId).then((result) => {
              setAuction(result);
            });
          }
        },
      );

      getGovernment(type, localStorageGovernmentId).then((government) => {
        setGovernment(government);
      });
    }, [localStorageGovernmentId]);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getInvitations(type).then((result) => {
        setInvitation(result);
      });
    }, []);
    useEffect(() => {
      getAuctions(type).then((result) => {
        setAuction(result);
      });
    }, []);
    useEffect(() => {
      getGovernments(type).then((result) => {
        setGovernments(result);
      });
    }, []);
  }

  return (
    <PropertiesManagementContext.Provider
      value={{
        authState,
        breadcrumbs,
        government,
        invitation,
        auction,
        governments,
      }}>
      {children}
    </PropertiesManagementContext.Provider>
  );
};

export default PropertiesManagementProvider;
