import { DATA_EMPTY } from 'domain/Property/entities/constants/property';

import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  SEARCH_GOVERNMENT_SUB_CATEGORY_ID,
  //SEARCH_PROPERTY_CATEGORY_ID,
} from 'shared/utils/constants/queryParam';

import { IdAndNameData } from './helper/searchGovernmentSubCategoryId';
import TreeViewNodeChildren from './TreeViewNodeChildren';

const TreeViewNode = ({ keyName, queryParam, setQueryParam }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isPartialActive, setIsPartialActive] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const isFirstRender = useRef(true);
  const checkBoxRefs = IdAndNameData[keyName].map(() => useRef(null));
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const toggleCheckBox = () => {
    setIsChecked(!isChecked);
    setIsPartialActive(false);
    const isStateActive = (ref) => {
      if (ref.current?.classList.contains('checkbox-icons')) {
        return true;
      } else if (
        ref.current?.classList.contains('checkbox-outline-blank-icon')
      ) {
        return false;
      }
      return null;
    };

    checkBoxRefs.forEach((ref) => {
      if (isStateActive(ref) === isChecked) ref.current.click();
    });
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const noDuplicated = Array.from(new Set(queryParam));
    const strIds = noDuplicated.sort((a, b) => a - b).join(',');
    searchParams.set(SEARCH_GOVERNMENT_SUB_CATEGORY_ID, strIds);
    setSearchParams(searchParams);
  }, [queryParam]);

  useEffect(() => {
    if (
      checkBoxRefs.every((ref) =>
        ref.current?.classList.contains('checkbox-icons'),
      )
    ) {
      setIsChecked(true);
      setIsPartialActive(false);
    } else if (
      checkBoxRefs.some((ref) =>
        ref.current?.classList.contains('checkbox-icons'),
      )
    ) {
      setIsChecked(false);
      setIsPartialActive(true);
    } else {
      setIsChecked(false);
      setIsPartialActive(false);
    }
  }, [checkBoxRefs]);

  useEffect(() => {
    const Ids =
      searchParams.get(SEARCH_GOVERNMENT_SUB_CATEGORY_ID) ?? DATA_EMPTY;
    if (Ids === DATA_EMPTY || Ids === '') {
      setIsChecked(false);
      setIsPartialActive(false);
    }
  }, [searchParams]);

  return (
    <div
      aria-expanded="false"
      className="treeview-node treeview-node--selected"
      data-testid="treeview-node">
      <div className="treeview-node-root d-flex align-center">
        <button
          type="button"
          onClick={toggleAccordion}
          className="d-inline-flex align-center justify-center icon notranslate treeview-node-toggle--open theme--light drop-down-icon"
          data-testid="root-accordion-btn"></button>
        <button
          type="button"
          onClick={toggleCheckBox}
          className={`d-inline-flex align-center justify-center icon theme--light ${isChecked ? 'checkbox-icons' : isPartialActive ? 'checkbox-minus-icon' : 'checkbox-outline-blank-icon'}`}
          data-testid="root-checkbox"></button>
        <div className="treeview-node-content">
          <div
            className="treeview-node-label"
            data-testid="treeview-node-label">
            {keyName}
          </div>
        </div>
      </div>
      {IdAndNameData[keyName].map((data, index) => (
        <div
          key={index}
          className={`treeview-node-children-wrap ${isOpen ? 'open' : ''}`}>
          <TreeViewNodeChildren
            data={data}
            checkBoxRef={checkBoxRefs[index]}
            queryParam={queryParam}
            setQueryParam={setQueryParam}
          />
        </div>
      ))}
    </div>
  );
};

export default TreeViewNode;
