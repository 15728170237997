import {useContext} from "react";

import BeforeInvoiceSystemUsage from "./BeforeInvoiceSystemUsage";
//import AfterInvoiceSystemUsage from "./AfterInvoiceSystemUsage";

import {BillingContext} from "../../pages/Billing/entities/BillingContext";

const SystemUsages = () => {
  const { property, bidders } = useContext(BillingContext);

  let bidData = []
  let auctionData = []
  property?.map((p) => {
    const propertyBidData = bidders.find((item) => item.propertyId.toString() === p.id.toString())
    if (propertyBidData !== undefined) {
      p.bidInfo = propertyBidData.price
    }
    if (p.publicSaleType === "BID") {
      bidData.push(p)
    } else if (p.publicSaleType === "AUCTION") {
      auctionData.push(p)
    }
  });

  function RenderSystemUsage() {
    //if (invoice) {
    //  return <AfterInvoiceSystemUsage data={properties}/>
    //}
    return <BeforeInvoiceSystemUsage data={{bidData: bidData, auctionData: auctionData}}/>
  }

  return (
    <RenderSystemUsage/>
  );
};

export default SystemUsages;
