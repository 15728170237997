// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.internal-server-error {
    margin-top: 15%;
}

.internal-server-error-status-code {
    height: 96px;
    font-size: 4rem;
    text-align: center;
    color: var(--error-code);
    margin-bottom: 8px;
}

.internal-server-error-caption {
    height: 24px;
    font-size: 1rem;
    text-align: center;
    color: var(--error-code);
    margin-bottom: 44px;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/internal-server-error.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,wBAAwB;IACxB,mBAAmB;AACvB","sourcesContent":[".internal-server-error {\n    margin-top: 15%;\n}\n\n.internal-server-error-status-code {\n    height: 96px;\n    font-size: 4rem;\n    text-align: center;\n    color: var(--error-code);\n    margin-bottom: 8px;\n}\n\n.internal-server-error-caption {\n    height: 24px;\n    font-size: 1rem;\n    text-align: center;\n    color: var(--error-code);\n    margin-bottom: 44px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
