// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard .kmanager-auction-table-wrap {
  overflow-x: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.dashboard .kmanager-auction-table {
  border-spacing: 0;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/Dashboard/kmanager-auction-table.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,iCAAiC;AACnC;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".dashboard .kmanager-auction-table-wrap {\n  overflow-x: auto;\n  width: 100%;\n  -webkit-overflow-scrolling: touch;\n}\n\n.dashboard .kmanager-auction-table {\n  border-spacing: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
