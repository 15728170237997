import { validateRangeLength, hasEmoji } from "../../../../application/validations.js";

export class Area {
  #area

  constructor(area) {
    this.#area = area;
  }

  get() {
    return this.#area
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.hasEmoji,
    ].every(result => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.#area, 1, 30)
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(this.#area)
  }
}
