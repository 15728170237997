export const breadcrumbs = (method, target, invitationName, id, openWay, propertyName = '') => {
  if (method === 'create') {
    if (target === 'form') {
      return [
        { title: "官公庁ナビトップ", to: "/navi" },
        { title: invitationName, to: `/navi/object/${id}/list?open-way=${openWay}` },
        { title: '物件情報（不動産）登録' }
      ]
    } else if (target === 'confirm') {
      return [
        { title: "官公庁ナビトップ", to: "/navi" },
        { title: invitationName, to: `/navi/object/${id}/list?open-way=${openWay}` },
        { title: '物件情報（不動産）登録' },
        { title: '登録内容確認' }
      ]
    } else {
      return [
        { title: "官公庁ナビトップ", to: "/navi" },
        { title: invitationName, to: `/navi/object/${id}/list?open-way=${openWay}` },
        { title: '物件情報（不動産）登録' },
        { title: '登録完了' }
      ]
    }
  } else {
    if (target === 'form') {
      return [
        { title: "官公庁ナビトップ", to: "/navi" },
        { title: invitationName, to: `/navi/object/${id}/list?open-way=${openWay}` },
        { title: '物件登録情報編集' }
      ]
    } else if (target === 'confirm') {
      return [
        { title: "官公庁ナビトップ", to: "/navi" },
        { title: invitationName, to: `/navi/object/${id}/list?open-way=${openWay}` },
        { title: propertyName },
        { title: '登録内容確認' }
      ]
    } else {
      return [
        { title: "官公庁ナビトップ", to: "/navi" },
        { title: invitationName, to: `/navi/object/${id}/list?open-way=${openWay}` },
        { title: propertyName },
        { title: '編集完了' }
      ]
    }
  }
}
