import { useEffect, useState } from 'react';
import { useCloseClickOutside } from 'shared/hooks/useCloseClickOutside';

import ListBox from './ListBox.jsx';
import PropertyListSelector from '../../components/NewEntry/PropertyListSelector';

export const SelectBox = ({
  listItemData,
  hasInitialValue = true,
  formStateHandler,
  width = '350px',
  selectedData,
}) => {
  const [showListBox, setShowListBox] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const selectBoxClickHandler = () => {
    setShowListBox(!showListBox);
  };
  const { ref } = useCloseClickOutside(() => setShowListBox(false));

  useEffect(() => {
    setSelectedValue(selectedData.text);
    if (hasInitialValue && selectedData === '') {
      setSelectedValue(listItemData ? listItemData[0]?.text : '');
    }
  }, [listItemData, selectedData]);

  return (
    <div data-testid="select-box" style={{ position: 'relative' }}>
      <PropertyListSelector
        content={selectedValue}
        onClick={selectBoxClickHandler}
        width={width}
      />
      {showListBox && (
        <div ref={ref}>
          <ListBox
            data={listItemData}
            setShowListBox={setShowListBox}
            setSelectedValue={setSelectedValue}
            formStateHandler={formStateHandler}
            width={width}
          />
        </div>
      )}
    </div>
  );
};

export default SelectBox;
