import pino from "pino";

import {BASE_URL_NAVIGATION} from "../../../config/envConstants.js";
import {fetchData} from "../../../shared/utils/helper/fetchData.js";

const logger = pino();
const CURRENT_ASSET_SALE_CAUTION_PAGE = `${BASE_URL_NAVIGATION}/storage/current/caution_pages/data.json`;
const PREVIOUS_ASSET_SALE_CAUTION_PAGE = `${BASE_URL_NAVIGATION}/storage/previous/caution_pages/data.json`;

export const fetchAssetSaleCautionPageStorage = async () => {
  try {
    const response = await fetchAssetSaleCautionPage(CURRENT_ASSET_SALE_CAUTION_PAGE);
    return extractAssetSaleCautionPage(response);
  } catch (error) {
    logger.warn({ error }, "現在の通知（ asset_sale_caution_page ）を取得できませんでした");

    try {
      const response = await fetchAssetSaleCautionPage(PREVIOUS_ASSET_SALE_CAUTION_PAGE);
      return extractAssetSaleCautionPage(response);
    } catch (error) {
      logger.error({ error }, "以前の通知（ asset_sale_caution_page ）の取得にも失敗しました");
      return [];
    }
  }
};

export const fetchAssetSaleCautionPage = async (path) => {
  try {
    return await fetchData(path);
  } catch (error) {
    console.error(error);
    // TODO: エラーハンドリングする(https://eslint.org/docs/latest/rules/no-useless-catch)
    throw error;
  }
}

export const extractAssetSaleCautionPage = (response) => {
  return response.map(asset_sale_caution_page => asset_sale_caution_page);
}