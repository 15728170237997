import { useEffect, useState } from 'react';
import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody.jsx';

import { AnnounceMessage } from './PropertyDetailAnnounce/AnnounceMessage.jsx';
import 'interfaces/css/PropertyDetail/property-detail-announce.css';

const PropertyDetailAnnounce = ({ data, isContainBlockList, userId }) => {
  // メッセージを’出力するかどうかのフラグ
  const [isNoMessage, setIsNoMessage] = useState(false);

  useEffect(() => {
    setIsNoMessage(false);
  }, [data]);

  return (
    <div className="mt-4">
      {!isNoMessage === true ? (
        <ArticleDefaultBody>
          <div className="announce-body">
            <AnnounceMessage
              data={data}
              isContainBlockList={isContainBlockList}
              setIsNoMessage={setIsNoMessage}
              userId={userId}
              //setIsNoMessage={() => {}}
            />
          </div>
        </ArticleDefaultBody>
      ) : null}
    </div>
  );
};

export default PropertyDetailAnnounce;
